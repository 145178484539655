export class Package {
    id: string;
    created: Date;
    updated: Date;

    memberId : string;

    manifestNumber : string;
    shipmentId :string;
    externalId : string;

    packageLabel : string;
    packageType : string;
    productName : string;
    productionBatchNumber: string;
    productCategory: string;
    shippedQuantity : string;
    receivedQuantity : string;

    shipperWholesalePrice: string;
    receiverWholesalePrice : string;
    quantityUnits: string;

    isDonation : boolean;
    isTradeSample : boolean;
    isTestingSample : boolean;

    itemStrainName: string;
    sourcePackageLabels: string;
    sourceHarvestNames: string;
    shipmentPackageState: string;
    remediationDate: Date;
    labTestingState: string;
}
