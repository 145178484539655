import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'pc-onboard-tab',
    templateUrl: './onboard-tab.component.html',
    styleUrls: ['./onboard-tab.component.scss']
})
export class OnboardTabComponent {

    @Input() title: string;
    @Input() tabId: string;
    @Input() tabValid: () => boolean;
    @Input() tabDirty: () => boolean;
    @Input() tabClass: (tabId: string) => string;

    @Output() tabChange: EventEmitter<string> = new EventEmitter<string>();

    getTabClass() {
        return this.tabClass(this.tabId);
    }

    onSwitchTabs() {
        this.tabChange.emit(this.tabId);
    }

    getIconClass() {
        if (this.tabDirty && this.tabDirty()) {
            return 'fa-exclamation-circle';
        } else if (this.tabValid()) {
            return 'fa-check-circle';
        }
        return 'fa-question-circle';
    }
}
