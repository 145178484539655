<div class="col-12" *ngIf="isAuthority()">
    <pt-audit-table-header
        [action]="action"
        [auditEntityType]="auditEntityType"
        [memberId]="memberId"
        (activeListChanged)="activeListChanged($event)">
    </pt-audit-table-header>
    <pt-audit-table-list
        [memberId]="memberId"
        [action]="action"
        [auditEntityType]="auditEntityType">
    </pt-audit-table-list>
</div>
