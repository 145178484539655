import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http.service';

/**
 * @deprecated remove after all member accounts have been transferred to their corporate entity
 */
@Injectable({
    providedIn: 'root'
})
export class MemberMergeService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.EntityAPIEndpoint + '/member/merge/';
    }

    getRelatedMembers(memberId: string) {
        return this.http.get(`${this.baseUrl}${memberId}/related`);
    }

    validateMemberMergeable(memberId: string) {
        return this.http.get(`${this.baseUrl}${memberId}/validate`);
    }

    mergeMembers(sourceMemberId: string, targetMemberId: string) {
        return this.http.put(`${this.baseUrl}${sourceMemberId}/${targetMemberId}`, {});
    }
}
