/**
 * Kyc search match status used to identify the match status of a search
 * @author David Teles
 */
export enum SearchMatchStatus {
    NO_MATCH = 'NO_MATCH',
    FALSE_POSITIVE = 'FALSE_POSITIVE',
    POTENTIAL_MATCH = 'POTENTIAL_MATCH',
    TRUE_POSITIVE = 'TRUE_POSITIVE',
    UNKNOWN = 'UNKNOWN',
    TRUE_POSITIVE_APPROVE = 'TRUE_POSITIVE_APPROVE',
    TRUE_POSITIVE_REJECT = 'TRUE_POSITIVE_REJECT'
}