import { Injectable, Inject } from '@angular/core';
import { LicenseStatus } from '../public-api';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class LicenseService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.DataroomAPIEndpoint + '/';

    }

    saveLicense(body: any) {
        return this.http.post(`${this.baseUrl}license`, body);
    }

    loadMemberLicenses(memberId: string, status: LicenseStatus[], start: number, size: number, sort: string, sortDir: string) {
        const params = `?memberId=${memberId}&status=${status}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}license${params}`);
    }

    updateLicense(licenseId: string, updates: any) {
        return this.http.put(`${this.baseUrl}license/${licenseId}`, updates);
    }
}
