import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthService, DataroomService, MemberService, MemberFormService, Member } from 'projects/services/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

class ImageSnippet {
    constructor(public src: string,
        public file: File) {}
}

@Component({
    selector: 'pc-corporate-profile',
    templateUrl: './corporate-profile.component.html',
    styleUrls: ['./corporate-profile.component.scss']
})
export class CorporateProfileComponent implements OnInit {

    memberForm: UntypedFormGroup;
    imageSrc: any;
    selectedFile: ImageSnippet;
    show = true;
    errorSize = false;
    success = false;
    imageAvailable = false;
    imagePreview = false;
    businessTypeOpts: string[] = ['Corporation',
        'Limited Liability Company',
        'Limited Partnership',
        'General Partnership',
        'Limited Liability Partnership',
        'Sole Proprietorship'];
    memberId: string;

    constructor(private authService: AuthService,
                private memberService: MemberService,
                private memberFormService: MemberFormService,
                private notificationService: NotificationService,
                private dataroomService: DataroomService) {
    }

    ngOnInit() {
        this.formEnabled = this.formEnabled.bind(this);
        this.onSaveUpdates = this.onSaveUpdates.bind(this);
        this.logoFormEnabled = this.logoFormEnabled.bind(this);
        this.submit = this.submit.bind(this);
        this.imageSrc = {
            key: this.authService.getProfile().memberId,
            src: this.dataroomService.getImage(this.authService.getProfile().memberId)
        };
        this.success = false;
        this.memberService.loadMember(this.authService.getProfile().memberId).subscribe((member: Member) => {
            this.memberId = member.id;
            this.memberForm = this.memberFormService.initializeForm(member);
            this.memberForm.get('naicsCodeCtrl').setValidators(null);
            this.memberForm.get('nameCtrl').disable();
        });
    }

    onSaveUpdates(reset: any) {
        this.memberService.saveMember(this.memberFormService.getMember(this.memberForm)).subscribe((member: Member) => {
            this.memberForm = this.memberFormService.initializeForm(member);
            this.memberForm.get('naicsCodeCtrl').setValidators(null);
            this.memberForm.get('nameCtrl').disable();
            reset();
        });
    }

    formEnabled() {
        return this.memberForm && this.memberForm.valid && this.memberForm.dirty;
    }

    logoFormEnabled() {
        return this.selectedFile && this.selectedFile.file && !this.errorSize && this.imagePreview;
    }

    submit(reset: any) {
        const path = `${this.memberId}/logo/`;
        this.dataroomService.uploadImage(this.selectedFile.file, this.memberId, path).subscribe(() => {
            this.imageSrc = {
                key: this.authService.getProfile().memberId,
                src: this.dataroomService.getImage(this.memberId)
            };
            this.success = true;
            this.show = false;
            this.imagePreview = false;
            reset();
        }, (error: any) => {
            reset();
            this.notificationService.showError('Document upload was unsuccessful. Please check your connection and try again.');
        });
    }

    processFile(imageInput: any) {
        this.imageAvailable = false;
        this.errorSize = false;
        this.success = false;
        if (imageInput.files && imageInput.files.length) {
            const file: File = imageInput.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.imageSrc = {
                    key: 'IMAGE_UPLOAD_PREVIEW',
                    src: reader.result as string
                };
                this.imagePreview = true;
            };
            reader.addEventListener('load', (event: any) => {
                this.selectedFile = new ImageSnippet(event.target.result, file);
                if (file.size > 250000) {
                    this.errorSize = true;
                }
            });
        }
    }

    updateImageAvailability(available: boolean) {
        this.imageAvailable = available;
    }
}
