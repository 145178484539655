import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LendingFinancingRequest, LenderService, PagedResponse, RequestAccess, Task, WorkflowService, AuthService } from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AcceptedFinancingRequestModalComponent, ApproveAccessModalComponent, ConfirmModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pc-work-gadget',
    templateUrl: './work-gadget.component.html',
    styleUrls: ['./work-gadget.component.scss']
})
export class WorkGadgetComponent implements OnChanges {

    @Input() changeTrigger: number;

    tasks: Task[];
    taskCount: number;
    myTaskCount: number = 0;
    assignee: string;

    constructor(private workflowService: WorkflowService,
                private lenderService: LenderService,
                private authService: AuthService,
                private dialog: MatDialog) {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.assignee = this.authService.getProfile().userId;
        if (changes.changeTrigger) {
            this.refreshList();
        }
    }

    refreshList() {
        // retrieve all the tasks and add
        this.workflowService.getTasksSorted([], '', 0, 3, 'created', 'desc').subscribe((tasks: PagedResponse<Task>) => {
            this.tasks = tasks.content;
            this.workflowService.getTaskCount().subscribe((response: any) => {
                const taskCount = response.headers.get('Content-Length');
                if (taskCount >= 3) {
                    this.taskCount = taskCount;
                }
            });
            if (this.authService.isAuthority()) {
                this.workflowService.getTaskCount(this.assignee).subscribe((response: any) => {
                    this.myTaskCount = response.headers.get('Content-Length');
                });
            }
        });
    }

    loadTask(taskId: string) {
        this.workflowService.getTask(taskId).subscribe((task: Task) => {
            if (task.formKey === 'request_financing') {
                this.lenderService.getFinancingById(task.variables.value).subscribe((financingResponse: LendingFinancingRequest) => {
                    this.openAcceptedFinancingRequest(taskId, financingResponse);
                });
            } else if (task.formKey === 'request_access') {
                this.openApproveAccessRequest(task);
            } else {
                this.workflowService.setCurrentTask(task);
            }
        });
    }

    openAcceptedFinancingRequest(taskId: string, financingResponse: LendingFinancingRequest) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            financingRequest: financingResponse
        };
        const dialog = this.dialog.open(AcceptedFinancingRequestModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((result: 'DECLINED' | 'CLOSE' | 'PROCEED') => {
            if (result === 'DECLINED') {
                const confirmDialogConfig: MatDialogConfig = {};
                confirmDialogConfig.panelClass = 'normal-modal';
                confirmDialogConfig.data = {
                    title: 'Are you sure to decline this financing request?',
                    confirmText: 'Confirm'
                };
                const confirmDialog = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
                confirmDialog?.afterClosed().subscribe((confirmAction: any) => {
                    if (confirmAction === 'confirmed') {
                        this.lenderService.updateLoanStatus(financingResponse.loanId, 'DECLINED').subscribe(() => {
                            this.completeTask(taskId);
                        });
                    }
                });
            } else if (result === 'PROCEED') {
                this.completeTask(taskId);
            } else {
                this.workflowService.clearCurrentTask();
            }
        });
    }

    openApproveAccessRequest(task: Task) {
        const access: RequestAccess = {
            id: task.variables.requestId,
            memberId: task.variables.memberId,
            lenderId: task.variables.lenderId,
            startDate: task.variables.startDate,
            endDate: task.variables.endDate,
            note: task.variables.note
        };
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        let userId = task.variables.userId;
        // deprecated - remove after MSO - legacy workflow variable
        if (!userId) {
            userId = task.variables.individualId;
        }

        dialogConfig.data = {
            requestAccess: access,
            lenderName: task.variables.lenderName,
            userId
        };
        const dialog = this.dialog.open(ApproveAccessModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.completeTask(task.id);
            } else {
                this.workflowService.clearCurrentTask();
            }
        });
    }

    completeTask(taskId: string) {
        this.workflowService.completeTask(taskId, {}, this.refreshList).subscribe(() => {
            this.refreshList();
        });
    }
}
