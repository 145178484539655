import { WatchlistAddress } from './watchlist-address';
import { WatchlistAlternateName } from './watchlist-alternate-name';
import { WatchlistUserNote } from './watchlist-user-note';

export class WatchlistEntity {
    id: string;
    created: string;
    updated: string;
    externalWatchlistEntityId: string;
    watchlistEntityName: string;
    watchlistEntityType: string;
    watchlistPrograms: string[];
    addresses: WatchlistAddress[];
    alternateNames: WatchlistAlternateName[];
    birthDates: string[];
    matchedName: string;
    matchPercentage: number;
    matchType: WatchlistMatchType;
    remarks: string;
    alternateId: string;
    status: WatchlistStatus;
    userNotes: WatchlistUserNote[];
    acknowledgedDate: string;
    watchlistSearchId: string;
}

export enum WatchlistStatus {
    PENDING = 'PENDING',
    CLEARED = 'CLEARED',
    AUTO_CLEARED = 'AUTO_CLEARED',
    MATCHED = 'MATCHED'
}

export enum WatchlistMatchType {
    OFAC_SDN = 'OFAC_SDN',
    OFAC_FOREIGN_SANCTION_EVADERS = 'OFAC_FOREIGN_SANCTION_EVADERS',
    OFAC_SECTORAL_SANCTIONS = 'OFAC_SECTORAL_SANCTIONS',
    OFAC_PALESTINIAN_COUNCIL = 'OFAC_PALESTINIAN_COUNCIL',
    OFAC_CAPTA_LIST = 'OFAC_CAPTA_LIST',
    OFAC_NON_SDN_LIST = 'OFAC_NON_SDN_LIST',
    OFAC_NON_SDN_CHINESE_MILITARY = 'OFAC_NON_SDN_CHINESE_MILITARY'
}
