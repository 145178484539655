<div class="col-12 dashboard-info">
    <div class="row">
        <div class="col-3 dashboard-header">
            <span>Check Availability</span>
        </div>
        <div class="col-9">
            <button class="blue-button pull-right" type="button" (click)="onSubmit('Order Placed')"><i class="fa fa-check pull-left dashboard-icon"></i><div class="pull-right-button">Confirm Availability</div></button>
            <button class="blue-button pull-right" type="button" (click)="onSubmit('Order Cancelled')"><i class="fa fa-times pull-left dashboard-icon"></i><div class="pull-right-button">Cancel Order</div></button>
            <button class="blue-button pull-right" type="button" (click)="toMyContracts()"><i class="fa fa-arrow-left pull-left dashboard-icon"></i><div class="pull-right-button">Back to My Contracts</div></button>
        </div>
    </div>
    <div class="row col-12">
        <pc-sc-sales-order class="sales-order" *ngIf="contract" [order]="contract.contractTemplate.purchaseOrder"></pc-sc-sales-order>
    </div>
</div>
