import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/public-api';
import {
    AddressOccupant, AddressService, AuthService, BankAccount, BankAccountService, Consumer, CorporateEntity, Member, MemberService, MemberType, PagedResponse, WatchlistEntity,
    WatchlistStatus, WatchlistSearchType} from 'projects/services/src/public-api';
import { UntypedFormGroup } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';


@Component({
    selector: 'pt-watchlist-review-modal',
    templateUrl: './watchlist-review-modal.component.html',
    styleUrls: ['./watchlist-review-modal.component.scss']
})
export class WatchlistReviewModalComponent extends BaseModalComponent<WatchlistReviewModalComponent> implements OnInit {

    readonly displayedColumns = ['data_name', 'watchlist_data', 'member_data'];

    bankAccount: BankAccount;
    WatchlistStatus = WatchlistStatus;
    bankAccountId: string;
    watchlistEntity: WatchlistEntity;
    member: Member;
    memberId: string;
    addressOccupants: AddressOccupant[] = [];
    source: { dataName: string, watchlistData: string, memberData: string}[] = [];
    watchlistForm: UntypedFormGroup;
    closingStatus: WatchlistStatus;
    role: string;
    watchListSearchType: WatchlistSearchType;
    activeTab: string = 'WATCHLIST_DETAILS';

    @Output() emitService = new EventEmitter();

    constructor(public authService: AuthService,
                private memberService: MemberService,
                private bankAccountService: BankAccountService,
                private addressService: AddressService,
                private translatePipe: TranslatePipe,
                protected breakpointObserver: BreakpointObserver,
                dialogRef: MatDialogRef<WatchlistReviewModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.bankAccountId = data.bankAccountId,
        this.watchlistEntity = data.watchlistEntity;
        this.memberId = data.memberId;
        this.watchlistForm = data.watchlistForm;
        this.role = data.role;
        this.watchListSearchType = data.watchlistSearchType;
    }

    ngOnInit() {
        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            this.member = member;
            if (this.isMember(this.watchListSearchType)) {
                this.addressService.getAllAddressOccupantLinks(this.memberId, 0, -1, 'created', 'ASC').subscribe((response: PagedResponse<AddressOccupant>) => {
                    this.addressOccupants = response.content;
                    this.createDataSource();
                });
            } else {
                this.bankAccountService.getBankAccountById(this.bankAccountId).subscribe((bankAccount: BankAccount) => {
                    this.bankAccount = bankAccount;
                    this.createDataSource();
                });
            }
        });
    }

    createDataSource() {
        let watchlistEntityName = this.watchlistEntity.watchlistEntityName;
        if (this.watchlistEntity.alternateId) {
            const matchedWatchlistAlternateNames = this.watchlistEntity.alternateNames.filter((watchlistAlternateName) => {
                return watchlistAlternateName.alternateId === this.watchlistEntity.alternateId;
            });
            if (matchedWatchlistAlternateNames.length) {
                watchlistEntityName += ` (${matchedWatchlistAlternateNames[0].alternateName})`;
            }
        }

        const memberEntityName = this.getNameBySearchType();
        const entityType = this.getWatchlistTitleOrType(false);
        const watchlistProgramData = `<b>Programs:</b> ${this.watchlistEntity.watchlistPrograms}</br><b>ID:</b> ${this.watchlistEntity.externalWatchlistEntityId}</br><b>Source:</b> ${this.translatePipe.transform(this.watchlistEntity.matchType)}`;
        const memberTypeData = `<b>Type:</b> ${ entityType ? entityType : this.translatePipe.transform(this.member.memberType) }</br><b>Party:</b> ${ this.role }${ this.watchListSearchType ? `<br/><b>Category: </b>${ this.watchListSearchType?.toString()?.split('_').join(' ') }` : '' }`;

        let watchlistAlternateNameData = '';
        if (this.watchlistEntity.alternateNames.length) {
            watchlistAlternateNameData = '<ol>';
            watchlistAlternateNameData += this.watchlistEntity.alternateNames.map((alternateName) => {
                return `<li class="bold-marker"> ${alternateName.alternateName} </li>`;
            }).join('').concat('</ol>');
        }

        let memberAlternateNameData = '';
        let memberDateOfBirthData = '';
        if (this.member.memberType === MemberType.BUSINESS || this.member.memberType === MemberType.BUSINESS_MERCHANT) {
            const memberDbaName = (this.member as CorporateEntity)?.dbaName;
            if (memberDbaName) {
                memberAlternateNameData = `<ol><li class="bold-marker"> ${memberDbaName} </li></ol>`;
            }
        } else if (this.member.memberType === MemberType.CONSUMER || this.member.memberType === MemberType.INDIVIDUAL_MERCHANT && (this.member as Consumer).dateOfBirth)  {
            memberDateOfBirthData = `<b>DOB:</b> ${(this.member as Consumer).dateOfBirth}`;
        }

        let watchlistDateOfBirthData = '';
        if (this.watchlistEntity.birthDates.length) {
            watchlistDateOfBirthData = '<ol class="no-marker">';
            watchlistDateOfBirthData += this.watchlistEntity.birthDates.map((birthDate) => {
                return `<li> ${birthDate} </li>`;
            }).join('').concat('</ol>');
        }

        let watchlistLocationData = '';
        if (this.watchlistEntity.addresses.length) {
            watchlistLocationData = '<ol>';
            watchlistLocationData += this.watchlistEntity.addresses.map((watchlistAddress) => {
                if (watchlistAddress.fullAddress) {
                    return `<li class="bold-marker">${watchlistAddress.fullAddress}</li>`;
                }
                return '';
            }).join('').concat('</ol>');
        }

        let memberLocationData = '';
        if (this.addressOccupants.length) {
            memberLocationData = '<ol>';
            memberLocationData += this.addressOccupants.map((addressOccupant) => {
                return addressOccupant.address;
            }).map((address) => {
                return `<li class="bold-marker"> ${address.streetAddressOne}, ${address.city}, ${address.stateProvince}, ${address.zipPostalCode}, ${address.country}</li>`;
            }).join('').concat('</ol>');
        }

        this.source = [{
            dataName: 'Name:',
            watchlistData: watchlistEntityName,
            memberData: memberEntityName
        },
        {
            dataName: '',
            watchlistData: watchlistProgramData,
            memberData: memberTypeData
        }];
        if (watchlistAlternateNameData || memberAlternateNameData) {
            this.source.push({
                dataName: 'Alternate Names:',
                watchlistData: watchlistAlternateNameData,
                memberData: this.isMember(this.watchListSearchType) ? memberAlternateNameData : ''
            });
        }
        if (watchlistLocationData || memberLocationData) {
            this.source.push({
                dataName: 'Locations:',
                watchlistData: watchlistLocationData,
                memberData: this.isMember(this.watchListSearchType) ? memberLocationData : ''
            });
        }
        if (this.member.memberType === MemberType.CONSUMER || this.member.memberType === MemberType.INDIVIDUAL_MERCHANT) {
            this.source.push({
                dataName: 'Birthdates:',
                watchlistData: watchlistDateOfBirthData,
                memberData: this.isMember(this.watchListSearchType) ? memberDateOfBirthData : ''
            });
        }
        if (this.watchlistEntity.remarks) {
            this.source.push({
                dataName: 'Remarks:',
                watchlistData: `${this.watchlistEntity.remarks}`,
                memberData: ''
            });
        }
    }

    getTabClass(tabOption: string) {
        if (this.activeTab === tabOption) {
            return 'tab-navigation-selected';
        } else {
            return 'tab-navigation';
        }
    }

    setActiveTab(activeTab: string) {
        if (activeTab !== this.activeTab) {
            this.activeTab = activeTab;
        }
    }

    clearHit() {
        this.closingStatus = WatchlistStatus.CLEARED;
    }

    confirmHit() {
        this.closingStatus = WatchlistStatus.MATCHED;
    }

    changeHitStatus() {
        this.close(this.closingStatus);
    }

    cancelEdit() {
        this.closingStatus = null;
        this.watchlistForm.controls['notesCtrl'].setValue('');
    }

    formValid() {
        return this.watchlistForm.valid;
    }

    isNotRemarks(index: number, rowData: any) {
        return rowData.dataName !== 'Remarks:';
    }

    isRemarks(index: number, rowData: any) {
        return rowData.dataName === 'Remarks:';
    }

    getWatchlistTitleOrType(isWatchlistTitle: boolean): string {
        switch (this.watchListSearchType) {
            case WatchlistSearchType.EXTERNAL_FINANCIAL_INSTITUTION:
            case WatchlistSearchType.CONFIA_FINANCIAL_INSTITUTION:
                return isWatchlistTitle ? 'Financial Institution Details' : 'Financial Institution';
            case WatchlistSearchType.EXTERNAL_FINANCIAL_HOLDER:
            case WatchlistSearchType.CONFIA_FINANCIAL_HOLDER:
                return isWatchlistTitle ? 'Bank Account Holder Details' : 'Account Holder';
            case WatchlistSearchType.EXTERNAL_PARTY:
                return isWatchlistTitle ? 'Merchant Details' : null;
            case WatchlistSearchType.CONFIA_MEMBER:
            default:
                return isWatchlistTitle ? 'Member Details' : null;
        }
    }

    getNameBySearchType(): string {
        switch (this.watchListSearchType) {
            case WatchlistSearchType.EXTERNAL_FINANCIAL_INSTITUTION:
            case WatchlistSearchType.CONFIA_FINANCIAL_INSTITUTION:
                return this.bankAccount.financialInstitution;
            case WatchlistSearchType.EXTERNAL_FINANCIAL_HOLDER:
            case WatchlistSearchType.CONFIA_FINANCIAL_HOLDER:
                return this.bankAccount.holder;
            case WatchlistSearchType.CONFIA_MEMBER:
            case WatchlistSearchType.EXTERNAL_PARTY:
            default:
                return this.member.name;
        }
    }

    isMember(type: WatchlistSearchType): boolean {
        return !type || type === WatchlistSearchType.CONFIA_MEMBER || type === WatchlistSearchType.EXTERNAL_PARTY;
    }
}