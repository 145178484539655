export class FieldMetadata {

    id: string;
    label = '';
    placeholder = '';

    stringValue = '';
    numericValue: string;
    booleanValue: boolean;
    dateValue: Date;
    orderValue: any;
    sectionValue: any;

    type: FieldDataType;
    tooltip = '';
    visible = true;
    event: string;
    required = false;
    validValues: string[];

    public hasValue() {
        switch (this.type) {
            case FieldDataType.DATE:
                return !!this.dateValue;
            case FieldDataType.ICON:
            case FieldDataType.LINK:
            case FieldDataType.TEXTAREA:
            case FieldDataType.TEXTINPUT:
                return !!this.stringValue;

            case FieldDataType.NUMBER:
                // eslint-disable-next-line no-undefined
                return this.numericValue !== null && this.numericValue !== undefined;

            default:
                return true;
        }
    }

}

export enum FieldDataType {
    SECTION = 'SECTION',
    TEXTAREA = 'TEXTAREA',
    TEXTINPUT = 'TEXTINPUT',
    CHECKBOX = 'CHECKBOX',
    DATE = 'DATE',
    ICON = 'ICON',
    LINK = 'LINK',
    NUMBER = 'NUMBER',
    RADIO = 'RADIO',
    ORDER = 'ORDER',
    TERMREFERENCE = 'TERMREFERENCE',
    PROGRAMMATIC = 'PROGRAMMATIC',
    ENUMERATED = 'ENUMERATED'
}
