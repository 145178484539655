import { Component } from '@angular/core';
import { BaseBadgeComponent } from '../badge.component';
import { SearchRiskLevel } from 'projects/services/src/public-api';

/**
 * Display badge for search risk level.
 *
 * @type {SearchRiskLevel} - enum
 * @author David Teles
 */
@Component({
    selector: 'pc-search-risk-level-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss', 'search-badge.component.scss']
})
export class SearchRiskLevelBadgeComponent extends BaseBadgeComponent<SearchRiskLevel> {

    setBadgeData() {
        const text = this.parent.replace('_', ' ');
        const type: SearchRiskLevel = SearchRiskLevel[this.parent.toUpperCase()];

        this.badgeToolTip = text;
        this.badgeText = text;

        switch (type) {
            case SearchRiskLevel.LOW:
                this.badgeClass = 'green-badge';
                break;
            case SearchRiskLevel.MEDIUM:
                this.badgeClass = 'orange-badge';
                break;
            case SearchRiskLevel.HIGH:
                this.badgeClass = 'red-badge';
                break;
            default:
                this.badgeClass = 'grey-badge';
                break;
        }
    }
}
