<div class="col-12">
    <div class="row">
        <div class="row dashboard-header mb-3">
            <div class="col-sm-4">
                <img class="dashboard-icon" src="assets/images/account-2.svg" alt="">
                <span>Accounts</span>
            </div>
            <div class="col-sm-8">
                <button class="blue-button pull-right" *ngIf="authService.isAuthorityOrCorporateAdmin() && member?.status === MemberStatus.ACTIVE && member?.memberType === MemberType.BUSINESS && member?.memberRegistrationType === MemberRegistrationType.PREMIUM_MEMBER" (click)="onAddAccount()" type="button">
                    <i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Request Account</div>
                </button>
                <button class="blue-button pull-right" *ngIf="authService.isAdmin() && activeBusinessAccounts.length > 1" (click)="onTransferFunds()" type="button">
                    <div>Transfer Funds</div>
                </button>
                <button class="blue-button pull-right" *ngIf="authService.isAuthoritySuperUser() && relatedMembers.length > 0 && canTransferAccounts()" (click)="onTransferAccounts()" type="button">
                    <div>Transfer Account(s)</div>
                </button>
            </div>
        </div>
        <div *ngIf="member && showMemberDetails(member.memberType) && authService.isAuthorityOrReviewer()" class="row mb-3">
            <div class="col-12">
                <div class="member_type">
                    <dl *ngIf="member.memberType === MemberType.BUSINESS || member.memberType === MemberType.CONSUMER" class="row">
                        <dt class="col-sm-6 col-lg-6">Member Type:</dt>
                        <dd class="col-6">{{ member.memberRegistrationType | translate }}</dd>
                    </dl>
                    <dl class="row">
                        <dt class="col-sm-6 col-lg-6">Member Since:</dt>
                        <dd class="col-6">{{ member.approvedDate ? (member.approvedDate | date:'mediumDate') : 'N/A' }}</dd>
                    </dl>
                    <dl *ngIf="isDisabledOrBlockedOrRejected(member.status)" class="row">
                        <dt class="col-sm-6 col-lg-6">Offboarding Date:</dt>
                        <dd class="col-6">{{ member.disabledDate | date:'mediumDate' }}</dd>
                    </dl>
                    <dl *ngIf="member.memberType === MemberType.BUSINESS" class="row">
                        <dt class="col-sm-6 col-lg-6">Referral Code:</dt>
                        <dd class="col-6">{{ member.referralCode }}</dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngIf="memberAccounts?.length === 0">
                You have no active accounts.
            </div>
            <div class="col-12" *ngFor="let account of memberAccounts">
                <pt-member-account-card [memberAccount]="account" [authorityView]="authorityView" [changeTrigger]="changeTrigger"></pt-member-account-card>
            </div>
        </div>
    </div>
</div>
