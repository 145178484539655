import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IMyDate } from 'angular-mydatepicker';
import { BaseModalComponent, ErrorType, LoaderComponent } from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import {
    Address, AddressService, AddressStatus, AddressType, CurrencyWithdrawal, MemberAccount, PagedResponse, PaymentService, Transaction, TransactionFormService, WorkflowService
} from 'projects/services/src/public-api';

@Component({
    selector: 'pt-currency-withdrawal-modal',
    templateUrl: './currency-withdrawal-modal.component.html'
})
export class CurrencyWithdrawalModalComponent extends BaseModalComponent<CurrencyWithdrawalModalComponent> implements OnInit {

    memberAccount: MemberAccount;
    currencyWithdrawalForm: UntypedFormGroup;
    transactionTotal: string;
    errorMessage: { message: string, type: ErrorType };
    addresses: Address[];
    currencyWithdrawalDetails: CurrencyWithdrawal[];
    transactionDetailsColumns = ['date', 'location', 'amount'];
    disabledDates = [];

    constructor(private transactionFormService: TransactionFormService,
                private addressService: AddressService,
                private workflowService: WorkflowService,
                private notifier: NotificationService,
                private paymentService: PaymentService,
                private overlay: Overlay,
                dialog: MatDialogRef<CurrencyWithdrawalModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialog);
        this.memberAccount = data.memberAccount;
    }

    ngOnInit() {
        this.submit = this.submit.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.currencyWithdrawalForm = this.transactionFormService.initializeCurrencyWithdrawal(this.memberAccount.accountName);
        this.currencyWithdrawalForm.get('memberAccountNameCtrl').disable();
        this.addressService.getAllAddresses(this.memberAccount.memberId, [AddressStatus.ACTIVE]).subscribe((locations: PagedResponse<Address>) => {
            this.addresses = locations.content;
            const primaryAddress = this.addresses.filter((address: Address) => {
                return address.type === AddressType.PRIMARY;
            })[0];
            if (primaryAddress) {
                this.currencyWithdrawalForm.controls['cashDropOffLocationIdCtrl'].setValue(primaryAddress.id);
            }
        });
        this.initializeCurrencyWithdrawalDisabledDates();
    }

    createErrorMessage(message: string) {
        this.errorMessage = {
            message: message,
            type: ErrorType.ERROR
        };
    }

    submit() {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        componentRef.instance.title = 'Submitting transaction...';
        const currencyWithdrawalRequest = this.transactionFormService.getCurrencyWithdrawal(this.currencyWithdrawalForm);
        currencyWithdrawalRequest.memberId = this.memberAccount.memberId;
        currencyWithdrawalRequest.memberAccountId = this.memberAccount.id;
        this.workflowService.recordCurrencyWithdrawal(currencyWithdrawalRequest).subscribe((result: Transaction) => {
            this.close(true);
            overlayRef.dispose();
            this.notifier.showSuccess(`Your currency withdrawal request for the amount of <b>$${result.totalAmount}</b> has been submitted successfully.`);
        }, (error: any) => {
            overlayRef.dispose();
            throw error;
        });
    }

    onTotalChange(event: any) {
        this.transactionTotal = this.currencyWithdrawalForm.controls['amountCtrl'].value;
        this.initializeCurrencyWithdrawalDetails();
    }

    dateSelected(event: any) {
        this.currencyWithdrawalForm.controls['cashDropOffDateCtrl'].setValue(event);
        this.initializeCurrencyWithdrawalDetails();
    }

    initializeCurrencyWithdrawalDetails() {
        this.currencyWithdrawalDetails = [this.transactionFormService.getCurrencyWithdrawal(this.currencyWithdrawalForm)];
    }

    getCashDropOffLocation(addressId: string) {
        const selectedAddress = this.addresses.filter((address) => {
            return address.id === addressId;
        })[0];
        if (!selectedAddress) {
            return '';
        }
        return `${selectedAddress.streetAddressOne}, ${selectedAddress.city}, ${selectedAddress.stateProvince}`;
    }

    isFormValid() {
        return this.currencyWithdrawalForm.valid && !this.errorMessage;
    }

    initializeCurrencyWithdrawalDisabledDates() {
        this.paymentService.getCurrencyWithdrawalDisabledDates().subscribe((bankHolidayDates: Array<string>) => {
            bankHolidayDates.forEach((bankHolidayDate) => {
                this.disabledDates.push(this.createIMyDate(bankHolidayDate));
            });
        });
    }

    createIMyDate(holidayDate: string) {
        return { year: Number(holidayDate.substring(0, 4)), month: Number(holidayDate.substring(5, 7)), day: Number(holidayDate.substring(8, 10))};
    }
}
