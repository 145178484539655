<div class="col-12 dashboard-info">
    <div class="col-sm d-flex justify-content-end">
        <button class="blue-button pull-right" (click)="onNewContract()" type="button"><i class="fa fa-folder-o pull-left dashboard-icon"></i><div class="pull-right-button">New Contract</div></button>
        <button class="blue-button pull-right"  (click)="onMyTemplates()" type="button"><i class="fa fa-file-text-o pull-left dashboard-icon"></i><div class="pull-right-button">My Templates</div></button>
    </div>
    <div class="row mb-3">
        <div class="col-auto dashboard-header">
            <img class="dashboard-icon" src="assets/images/files-icon-2.svg" alt="">
            <span>Smart Contracts</span>
        </div>
    </div>
    <div class="row d-flex justify-content-start mb-3 mt-3">
        <div class="col-auto">
            <select class="form-control form-input-table-header form-select-sm" type="text" (change)="onSelectChanged($event)">
                <option value="my_contracts">My Contracts</option>
                <option value="shared_templates">Available Contracts</option>
            </select>
        </div>
    </div>
    <div class="row col-12">
            <div class="col-12">
                <div *ngIf="activeType === 'MY_CONTRACTS'">
                    <pc-sc-my-contracts></pc-sc-my-contracts>
                </div>
                <div class="col-12 tab-body" *ngIf="activeType === 'AVAILABLE_CONTRACTS'">
                    <pc-sc-shared-templates-list></pc-sc-shared-templates-list>
                </div>
            </div>
    </div>
    <router-outlet></router-outlet>
</div>
