export class Comment {

    id: string;
    created: Date;
    updated: Date;

    memberId: string;
    type: CommentType;
    userId: string;
    authorName: string;
    authorId: string;
    comment: string;
    closed: boolean;
    authoritySubmitted: boolean;

}

export enum CommentType {
    QUESTION = 'QUESTION',
    USER = 'USER',
    INTERNAL = 'INTERNAL',
    WORKFLOW = 'WORKFLOW'
}
