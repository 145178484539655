<div class="row mb-3">
    <div class="col-12">
        <div *ngIf="memberAccount" class="row">
            <div class="col-6">
                <div class="mb-3">
                    <p class="form-title">Account Overview</p>
                    <div class="member_type">
                        <dl class="row">
                            <dt class="col-6">Member:</dt>
                            <dd class="col-6"> {{ memberAccount.member.name }} </dd>
                        </dl>
                        <dl class="row">
                            <dt class="col-6">Friendly Name:</dt>
                            <dd class="col-6">{{ memberAccount.accountFriendlyName }}</dd>
                        </dl>
                        <dl class="row" *ngIf="memberAccount.accountNumber">
                            <dt class="col-sm-6 col-lg-6">Account Number:</dt>
                            <dd class="col-6">{{ memberAccount.accountNumber }}</dd>
                        </dl>
                        <dl class="row" *ngIf="memberAccount.id && isPartner()">
                            <dt class="col-sm-6 col-lg-6">Account ID:</dt>
                            <dd class="col-6">{{ memberAccount.id }}</dd>
                        </dl>
                        <dl class="row">
                            <dt class="col-6">Account Type:</dt>
                            <dd class="col-6">{{ memberAccount.accountType | translate }}</dd>
                        </dl>
                        <button *ngIf="displayVoidCheckButton()" class="blue-button check_button" type="button" (click)="getVoidCheckForMemberAccount()">Download Void Check</button>
                    </div>
                    <div class="mb-3">
                        <p class="form-title">Wallet Balance</p>
                        <div class="member_type">
                            <span>
                                <dl class="row">
                                    <dt class="col-8">Available Balance<sup><i class="fa fa-question-circle-o" matTooltip="The available balance includes all completed payments and deposits, as well as any pending ACH/WIRE payments. Pending checks and scheduled payments have not been deducted from the available balance."></i></sup>:</dt>
                                    <dd class="col-4 text-end" [ngClass]="isAmountNegative(availableBalance) ? 'negative-amount' : ''">{{ availableBalance | currency }}</dd>
                                </dl>
                                <dl class="row">
                                    <dt class="col-8">Pending Incoming Amount<sup><i class="fa fa-question-circle-o" matTooltip="Pending incoming funds have not yet completed. They have not yet been added to your available balance."></i></sup>:</dt>
                                    <dd class="col-4 text-end">{{ fundsPending | currency }}</dd>
                                </dl>
                                <dl class="row" *ngIf="isDepositorAccount">
                                    <dt class="col-8">Outstanding Check Amount<sup><i class="fa fa-question-circle-o" matTooltip="Outstanding amount of all pending checks that have not yet been cashed. They have not been deducted from your available balance."></i></sup>:</dt>
                                    <dd class="col-4 text-end" [ngClass]="isOutstandingAmountPresent() ? 'negative-amount' : ''">{{ outstandingCheckAmount | currency }}</dd>
                                </dl>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div *ngIf="billingProfile && authService.isAdmin()">
                    <p class="form-title">Billing Profile</p>
                    <div class="member_type">
                        <span>
                            <dl class="row">
                                <dt class="col-sm-6 col-lg-6">Initial Billing Credit:</dt>
                                <dd class="col-6">{{ billingProfile?.initialBillingCreditMonths }} {{(billingProfile?.initialBillingCreditMonths > 1 ? 'Months' : 'Month')}}  </dd>
                            </dl>
                            <dl class="row" *ngIf="consumerPaymentsAccepted()">
                                <dt class="col-sm-6 col-lg-6">Retailer Fee %:</dt>
                                <dd class="col-6">{{ billingProfile?.retailTransactionFeePercentage }} %</dd>
                            </dl>
                            <dl class="row" *ngIf="consumerPaymentsAccepted()">
                                <dt class="col-sm-6 col-lg-6">Consumer Convenience Fee:</dt>
                                <dd class="col-6">{{ billingProfile?.retailConvenienceFeeAmount | currency }}</dd>
                            </dl>
                            <dl class="row" *ngIf="cashDepositsEnabled()">
                                <dt class="col-sm-6 col-lg-6">Cash Deposit Base Fee:</dt>
                                <dd class="col-6">{{ billingProfile?.cashDepositBaseFee | currency }}</dd>
                            </dl>
                            <dl class="row" *ngIf="cashDepositsEnabled()">
                                <dt class="col-sm-6 col-lg-6">Cash Deposit Fee %:</dt>
                                <dd class="col-6">{{ billingProfile?.cashDepositFeePercentage }} %</dd>
                            </dl>
                            <dl class="row">
                                <dt class="col-sm-6 col-lg-6">Monthly Account Fee:</dt>
                                <dd class="col-6">{{ billingProfile?.monthlyAccountFee | currency }}</dd>
                            </dl>
                            <dl class="row">
                                <dt class="col-sm-6 col-lg-6">ACH Withdrawal Fee:</dt>
                                <dd class="col-6">{{ billingProfile?.achWithdrawalFee | currency }}</dd>
                            </dl>
                            <dl class="row">
                                <dt class="col-sm-6 col-lg-6">ACH Express Payment Fee:</dt>
                                <dd class="col-6">{{ billingProfile?.achExpressPaymentFee | currency }}</dd>
                            </dl>
                            <dl class="row">
                                <dt class="col-sm-6 col-lg-6">Overdraft Fee:</dt>
                                <dd class="col-6">{{ billingProfile?.overdraftFee | currency }}</dd>
                            </dl>
                            <dl class="row">
                                <dt class="col-sm-6 col-lg-6">Wire Payment Fee:</dt>
                                <dd class="col-6">{{ billingProfile?.wirePaymentFee | currency }}</dd>
                            </dl>
                            <dl class="row">
                                <dt class="col-sm-6 col-lg-6 wallet_margin">Incoming Wire Fee:</dt>
                                <dd class="col-6">{{ billingProfile?.incomingWireFee | currency }}</dd>
                            </dl>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <p class="mt-3 form-title">Incoming Wire Instructions</p>
                <p>Please register the incoming wire (select ‘New Deposit’ from the home page) prior to sending these instructions to the payor:</p>
                <p><strong>Beneficiary Financial Institution:</strong> CONFIA FINANCIAL</p>
                <p><strong>Beneficiary Bank Address:</strong> 397 Aviation Blvd Ste L, Santa Rosa, CA 95403</p>
                <p><strong>Beneficiary Routing Number:</strong> 121182894</p>
                <p><strong>Beneficiary Account Holder Name:</strong> {{ memberAccount.member.name }}</p>
                <p><strong>Beneficiary Account Number:</strong> {{ memberAccount.accountNumber }}</p>
            </div>
            <div class="col-6">
                <p class="mt-3 form-title">Incoming ACH Instructions</p>
                <p>Please register the incoming ACH (select ‘New Deposit’ from the home page) prior to sending these instructions to the payor:</p>
                <p><strong>Routing Number:</strong> 121182894</p>
                <p *ngIf="memberAccount.accountNumber"><strong>Account Number:</strong> {{ memberAccount.accountNumber }}</p>
                <p class="mt-3 form-title">Check Deposit Instructions</p>
                <p>1. Please register the check (select ‘New Deposit’ from the home page) prior to submitting with the mobile check deposit app.</p>
                <p>2. Please write the following on the back of the check:</p>
                <p><strong>{{ memberAccount?.member.name }}<br/>
                    Pay to the Order of Confia<br/>
                    For mobile deposit only</strong>
                </p>
            </div>
            <div class="col-12">
                <p class="form-title">Member Agreements</p>
            </div>
            <div class="col-6">
                <div class="terms-link mb-2">
                    <a href="/static/Confia_Financial_Client_Agreement_04-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="fa fa-paperclip"></i>Confia Financial Member Account Terms and Conditions</a>
                </div>
                <div class="terms-link mb-2">
                    <a href="https://www.confia.fi/online-privacy-policy/" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-external-link"></i> Confia Financial Privacy Policy</a>
                </div>
                <div class="terms-link mb-2">
                    <a href="https://confia.fi/mobile-end-user-license-agreement/" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-external-link"></i> Mobile End User License Agreement</a>
                </div>
            </div>
            <div class="col-6">
                <div class="terms-link mb-2">
                    <a href="https://www.confia.fi/platform-use/" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-external-link"></i> Confia Financial Platform Use Agreement</a>
                </div>
                <div class="terms-link mb-2">
                    <a href="https://www.confia.fi/privacy-notice/" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-external-link"></i> Confia Financial Privacy Notice</a>
                </div>
                <div class="terms-link mb-2">
                    <a href="/static/Remote_Deposit_Terms_04-2024.pdf" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-paperclip"></i> Remote Deposit Capture</a>
                </div>
            </div>
        </div>
    </div>
</div>

<a class="report-download-link" #downloadVoidCheckLink></a>
