import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SmartContractService, WorkflowService, ContractTemplate, Contract, Task, ContractTemplateType } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-sc-buyer-review-agreement',
    templateUrl: './buyer-review-agreement.component.html',
    styleUrls: ['./buyer-review-agreement.component.scss']
})
export class BuyerReviewAgreementComponent implements OnInit {

    ContractTemplateType = ContractTemplateType;

    activeTab = 'PREVIEW_TEMPLATE';
    task: Task;
    contract: Contract;
    showCompare = false;

    constructor(private smartContractService: SmartContractService,
                private router: Router,
                private workflowService: WorkflowService) {
    }

    ngOnInit() {
        this.task = this.workflowService.getCurrentTask();
        this.smartContractService.getContractById(this.task.variables.contract).subscribe((contract: Contract) => {
            this.contract = contract;
        });
    }

    setActiveTab(activeTab: string) {
        this.activeTab = activeTab;
    }

    getTabClass(tabOption: string) {
        if (this.activeTab === tabOption) {
            return 'tab-option-selected';
        } else {
            return 'tab-option';
        }
    }

    onToggleCompare() {
        this.showCompare = !this.showCompare;
    }

    submit(status: string) {
        this.workflowService.completeTask(this.task.id, this.requestBody(status)).subscribe(() => {
            this.router.navigate(['/smart_contracts'], {queryParams: { _activeTab: 'my_contracts' }});
        });
    }

    requestBody(status: string): any {
        return {
            variables: {
                status: {
                    value: status,
                    type: 'String'
                }
            }
        };
    }

    templateChanged(contractTemplate: ContractTemplate) {
        this.contract.contractTemplate = contractTemplate;
        this.contract.sellerAccepted = false;
        this.smartContractService.saveContract(this.contract).subscribe(
            // eslint-disable-next-line no-empty-function
            () => {}
        );
    }

    templateValid() {
        return this.contract.contractTemplate.valid && this.contract.contractTemplate.purchaseOrder.shippingCost !== null;
    }
}
