import { AfterViewInit, ViewChild, Component, Input, OnInit, SimpleChanges, OnDestroy, EventEmitter, ChangeDetectorRef, OnChanges } from '@angular/core';
import { LenderService, Loan, PagedResponse, LoanStatus, MemberService, Member } from 'projects/services/src/public-api';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pc-lending-list',
    templateUrl: './lending-list.component.html'
})
export class LendingListComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @Input() memberAccountId = '';
    @Input() status: LoanStatus;
    @Input() changeTrigger = 1;
    @Input() isMemberLendingView = false;

    loans: Loan[] = [];
    loanStatusMap = new Map();
    pageTracking: PageTracking;
    resultsLength = 0;
    isLoadingResults = true;
    displayedColumns: string[] = ['name', 'lenderName', 'status', 'loan_amount', 'interest_rate', 'recurring_payment_amount', 'payment_start_date', 'payment_frequency', 'maturity', 'view_access', 'financial_data' ];
    filterEvent: EventEmitter<null> = new EventEmitter<null>();
    subscription: any;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private lenderService: LenderService,
                private memberService: MemberService,
                private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.loadDisplayedColumns();
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'paymentStartDate', 'desc', 100);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.status && !changes.status.firstChange) || (changes.changeTrigger && !changes.changeTrigger.firstChange) || (changes.memberAccountId && !changes.memberAccountId.firstChange)) {
            this.filterEvent.emit();
        }
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                if (this.isMemberLendingView && this.memberAccountId) {
                    return this.lenderService.loadLoansByMemberIdAndStatuses(this.memberAccountId, this.status ? [this.status] : [], this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
                }
                return this.lenderService.loadLoansByLenderIdAndStatuses('', this.status ? [this.status] : [], this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<Loan>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((loans: Loan[]) => {
            this.loans = loans;
            for (let loan of loans) {
                this.loadMemberAccountsForLoans(loan);
            }
        });
    }

    loadMemberAccountsForLoans(loan: Loan) {
        this.memberService.loadMember(loan.memberId).subscribe((member: Member) => {
            loan.name = member.name;
            this.loanStatusMap.set(member.id, member.status);
        });
        this.memberService.loadMember(loan.lenderId).subscribe((lender: Member) => {
            loan.lenderName = lender.name;
        });
    }

    isEnableFinancing(loan: Loan) {
        return loan.status !== LoanStatus.CLOSED && loan.status !== LoanStatus.DECLINED && !!loan.financialDataAccess;
    }

    financialData(currentLoan: Loan) {
        this.router.navigate(['/lending/member/' + currentLoan.memberId], {queryParams: { _activeTab: 'financial_data' }});
    }

    loadDisplayedColumns() {
        if (this.isMemberLendingView) {
            this.displayedColumns = ['lenderName', 'status', 'loan_amount', 'interest_rate', 'recurring_payment_amount', 'payment_start_date', 'payment_frequency', 'maturity', 'view_access'];
        }
    }

    handlePageBottom(event: PageEvent) {
        this.paginator.pageSize = event.pageSize;
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.page.emit(event);
    }
}
