<div *ngIf="address">
    <div *ngIf="address.streetAddressOne">
        {{ address.streetAddressOne }}
    </div>
    <div *ngIf="address.streetAddressTwo">
        {{ address.streetAddressTwo }}
    </div>
    <div *ngIf="address.city">
        {{ address.city }}<span *ngIf="address.stateProvince">, {{ address.stateProvince }}</span>
    </div>
    <div *ngIf="address.country && address.country !== 'United States'">
        {{ address.country }}
    </div>
    <div *ngIf="address.zipPostalCode">
        {{ address.zipPostalCode }}
    </div>
    <div *ngIf="address.phoneNumber">
        {{ address.phoneNumber | phone}}
    </div>
</div>
