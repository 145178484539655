<form *ngIf="partnerSettingsForm" [formGroup]="partnerSettingsForm">
    <div *ngIf="partnerSettingsForm && !isPartner()" class="row">
        <p class="col-12 form-title">Partner Settings</p>
        <div class="col-3">
            <pc-simple-checkbox id="enable_sms_checkbox" [label]="'Require SMS validation'"
                [formCtrl]="'enableSMSValidation'"
                [formGroup]="partnerSettingsForm"
                [invert]="true"
                (changed)="onPartnerSettingsUpdate('enableSMSValidation')">
            </pc-simple-checkbox>
        </div>
        <div class="col-3">
            <pc-simple-checkbox [label]="'Partner can accept payments'"
                [formCtrl]="'acceptPayments'"
                [formGroup]="partnerSettingsForm"
                [invert]="true"
                (changed)="onPartnerSettingsUpdate('acceptPayments')">
            </pc-simple-checkbox>
        </div>
        <div class="col-3">
            <pc-simple-checkbox [label]="'Partner can send payments'"
                [formCtrl]="'sendPayments'"
                [formGroup]="partnerSettingsForm"
                [invert]="true"
                (changed)="onPartnerSettingsUpdate('sendPayments')">
            </pc-simple-checkbox>
        </div>
    </div>
</form>
<div>
    <p><span class="form-title">API Keys</span></p>
    <p>Your accessId: {{memberId}}</p>
    <div class="col-sm-12 mt-3 mb-3 horizontal-scroll mat-table-container">
        <table mat-table [dataSource]="keys">
            <ng-container matColumnDef="api_key">
                <th class="centered" mat-header-cell *matHeaderCellDef>  API Key (the 'accessKey' in the request header) </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    {{ element.key }}
                </td>
            </ng-container>
            <ng-container matColumnDef="secret">
                <th class="centered" mat-header-cell *matHeaderCellDef> Secret (the 'accessSecret' used to generate the 'shaHash' for payments) </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span>  {{ element.secret || 'Not available'}} </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="issued_date">
                <th class="centered" mat-header-cell *matHeaderCellDef>  Issued Date </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span>  {{ element.created | date:'mediumDate' }} </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="disable">
                <th class="centered" mat-header-cell *matHeaderCellDef> Disable </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <i *ngIf="element.status === PartnerKeyStatus.ENABLED" class="fa fa-times" id="disable_key_btn" (click)="onDisableKey(element)"></i>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedApiKeysColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedApiKeysColumns;"></tr>
        </table>
    </div>
    <div class="col-sm-12 mt-3">
        <div class="row mt-3">
            <div class="col-sm-8">
                <b>Please note that keys and secrets are only visible when created.<br> Please store your keys securely.</b>
            </div>
            <div class="col-sm-4 text-end">
                <pc-submit-button pc-no-dbl-click [submit]="onCreateKey" [enabled]="canCreatable" [text]="'Create'"></pc-submit-button>
            </div>
        </div>
    </div>
</div>

<div class="mt-3">
    <p class="form-title">Permitted Redirect Urls</p>
    <div class="col-sm-12 mt-3 mb-3 horizontal-scroll mat-table-container">
        <table mat-table [dataSource]="domains">
            <ng-container matColumnDef="redirecting_url">
                <th mat-header-cell *matHeaderCellDef> Re-Direct URL </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.domain}}
                </td>
            </ng-container>
            <ng-container matColumnDef="issued_date">
                <th class="centered" mat-header-cell *matHeaderCellDef>  Issued Date </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span>  {{ element.created | date:'mediumDate' }} </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th class="centered" mat-header-cell *matHeaderCellDef> Action </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <i class="fa fa-times" id="delete_domain_btn" (click)="onDomainDelete(element.id)"></i>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedRedirectingUrlsColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedRedirectingUrlsColumns;"></tr>
        </table>
    </div>
    <div class="col-sm-12 mt-3">
        <div class="row mt-3" *ngIf="domains && domains.length < 10">
            <div class="col-sm-8">
                <pc-text-input id="input_domain" [formCtrl]="'domainCtrl'" [formGroup]="domainForm" [placeholder]="'New Domain'"></pc-text-input>
            </div>
            <div class="col-sm-4">
                <div class="pt-4 float-end">
                    <pc-submit-button [submit]="onCreateDomain" [enabled]="domainFormValid" [text]="'Add'" [leftIcon]="'plus'"></pc-submit-button>
                </div>
            </div>
        </div>
        <div class="row centered" *ngIf="domains && domains.length >= 10">
            <span>Note: Maximum domains limit reached.</span>
        </div>
    </div>
</div>
<div  class="mt-3">
    <p class="form-title">Customer Onboarding Branding</p>
    <div>
        <button class="blue-button" (click)="onShowEditStyleWindow()" [disabled]="readOnly">Style</button>
    </div>
</div>

<div class="mt-3">
    <p class="form-subtitle">Preview:</p>
    <div class="default-background-color" *ngIf="landingCustomizationForm">
        <div class="card-header default-color"
             [ngStyle]="{'background': landingCustomizationForm.controls['primaryColorCtrl'].value}">
            <pc-secure-image [imgSrc]="imageSrc" *ngIf="imageSrc" alt="Logo" [class]="'img-icon-small'"></pc-secure-image>
        </div>
        <p class="default-background-color" [ngStyle]="{'font-family': landingCustomizationForm.controls['fontCtrl'].value}">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. A accusamus consectetur debitis eligendi eum ex
            explicabo harum hic omnis, placeat porro, quibusdam quidem quo quod ratione saepe vel vitae voluptas. Ad,
            adipisci deserunt dolore eos fugit harum molestias totam voluptatem. Amet at atque explicabo id illum iusto
            necessitatibus quod saepe ullam voluptatibus. Mollitia, officiis.
        </p>
        <button class="blue-button"
                [ngStyle]="{'background': landingCustomizationForm.controls['buttonsColorCtrl'].value,
    'color': landingCustomizationForm.controls['buttonsTextColorCtrl'].value}" [disabled]="readOnly">Cancel
        </button>
        <button class="blue-button" [ngStyle]="{'background': landingCustomizationForm.controls['buttonsColorCtrl'].value,
    'color': landingCustomizationForm.controls['buttonsTextColorCtrl'].value}" [disabled]="readOnly">Accept and Continue
        </button>
        <div class="card-footer text-center default-color"
             [ngStyle]="{'background': landingCustomizationForm.controls['primaryColorCtrl'].value}">
            <p [ngStyle]="{'color': landingCustomizationForm.controls['secondaryColorCtrl'].value}">Footer</p>
        </div>
    </div>
</div>

<div class="mt-3" *ngIf="partnerSettingsForm">
    <p class="form-title">Notification Settings</p>
    <div class="row">
        <div class="col-4">
            <pc-form-text-input (change)="onBasicAuthChanged()" [label]="'Callback basic authentication username'" [placeholder]="'Callback Username'" [formCtrl]="'usernameCtrl'"
                [formGroup]="partnerSettingsForm" [colSize]="6">
            </pc-form-text-input>

        </div>
        <div class="col-8">
            <pc-form-text-input [label]="'Callback URI for payment status changes'" [placeholder]="'Callback URI for payment status changes'" [formCtrl]="'paymentCallbackUriCtrl'"
                [formGroup]="partnerSettingsForm" [colSize]="6">
            </pc-form-text-input>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <pc-form-text-input (change)="onBasicAuthChanged()" [label]="'Callback basic authentication password'" [placeholder]="'Callback Password'" [formCtrl]="'passwordCtrl'"
                [formGroup]="partnerSettingsForm" [colSize]="6">
            </pc-form-text-input>
        </div>
        <div class="col-8">
            <pc-form-text-input [label]="'Callback URI for member status changes'" [placeholder]="'Callback URI for member status changes'" [formCtrl]="'memberCallbackUriCtrl'"
                [formGroup]="partnerSettingsForm" [colSize]="6">
            </pc-form-text-input>
        </div>
    </div>
    <div class="pull-right">
        <pc-submit-button [enabled]="isPartnerWebhooksValid" [submit]="onPartnerSettingsUpdateUriAndCredentials" [text]="'Save'"></pc-submit-button>
    </div>
</div>
