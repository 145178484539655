import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IMyDateModel } from 'angular-mydatepicker';
import { DatePipe } from '@angular/common';
import { BalanceSheetReport, AccountingLinkedMember, ProfitAndLossReport, MemberService, Member, LenderService } from 'projects/services/src/public-api';
import { TableUtils } from 'projects/components/src/lib/table-utils.service';

@Component({
    selector: 'pt-financial-data',
    templateUrl: './financial-data.component.html',
    styleUrls: ['./financial-data.component.scss']
})
export class FinancialDataComponent implements OnInit {

    @Input() memberId: string;

    formGroup: UntypedFormGroup;
    memberName: string;

    isAccountingLinked: boolean;
    isLoaded: boolean;

    balanceReports: BalanceSheetReport[];
    profitReports: ProfitAndLossReport[];

    totalCustomersOwed: number;
    totalSuppliersOwed: number;

    netProfit: number;
    operatingProfit: number;

    assets: number;
    equity: number;

    income: number;
    expenses: number;
    netProfitMargin: number;

    debtToAssets: number;
    assetsAll: number;
    liabilities: number;

    selectedProfitDate: Date;
    selectedBalanceDate: Date;
    startProfitMonth: string;
    startBalanceMonth: string;
    periodLength = 12;
    periodsToCompare = 2;

    financialTab = 'summary';

    constructor(private route: ActivatedRoute,
                private router: Router,
                private memberService: MemberService,
                private lenderService: LenderService,
                private formBuilder: UntypedFormBuilder,
                private datePipe: DatePipe) {}

    ngOnInit() {
        const currentDate = new Date();
        this.startProfitMonth = this.datePipe.transform(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'yyyy-MM-dd');
        this.startBalanceMonth = this.datePipe.transform(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'yyyy-MM-dd');

        this.formGroup =  this.formBuilder.group({
            profitDateCtrl : new UntypedFormControl(),
            balanceDateCtrl : new UntypedFormControl()
        });

        const memberAccountId = this.route.snapshot.params.memberAccountId;
        if (memberAccountId) {
            this.memberId = memberAccountId;
        }
        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['_financialTab']) {
            this.financialTab = queryParams['_financialTab'];
        } else {
            this.financialTab = 'summary';
            this.updateQueryParams(true);
        }
        this.route.queryParams.subscribe((params) => {
            this.financialTab = params['_financialTab'] || 'summary';
        });
        this.setActiveTab(this.financialTab);
    }

    getCodatData() {
        this.setSelectedDate();
        this.lenderService.getProfitAndLoss(this.memberId, this.startProfitMonth, this.periodLength, this.periodsToCompare).subscribe((data: any) => {
            this.profitReports = data.reports;
            this.netProfit = this.profitReports[0].netProfit;
            this.operatingProfit = this.profitReports[0].netOperatingProfit;
            this.income = this.profitReports[0].income.value;
            this.expenses = this.profitReports[0].expenses.value;
            if (this.income === 0) {
                this.netProfitMargin = null;
            } else {
                this.netProfitMargin = Math.round((this.netProfit * 100) / this.income);
            }
        });

        this.lenderService.getBalanceSheet(this.memberId, this.startBalanceMonth, this.periodLength, this.periodsToCompare).subscribe((data: any) => {
            this.balanceReports = data.reports;
            this.assets = this.balanceReports[0].netAssets;
            this.equity = this.balanceReports[0].equity.value;
            this.assetsAll = this.balanceReports[0].assets.value;
            this.liabilities = this.balanceReports[0].liabilities.value;
            if (this.assetsAll === 0) {
                this.debtToAssets = null;
            } else {
                this.debtToAssets = Math.round((this.liabilities * 100) / this.assetsAll);
            }
        });

        this.lenderService.getSuppliers(this.memberId, 1, 1).subscribe((data: any) => {
            this.totalSuppliersOwed = data.totalOwed;
        });

        this.lenderService.getCustomers(this.memberId, 1, 1).subscribe((data: any) => {
            this.totalCustomersOwed = data.totalOwed;
        });
    }

    setSelectedDate() {
        if (this.selectedProfitDate) {
            this.formGroup.controls['profitDateCtrl'].patchValue(this.selectedProfitDate);
        } else {
            this.formGroup.controls['profitDateCtrl'].patchValue(new Date());
        }
        if (this.selectedBalanceDate) {
            this.formGroup.controls['balanceDateCtrl'].patchValue(this.selectedBalanceDate);
        } else {
            this.formGroup.controls['balanceDateCtrl'].patchValue(new Date());
        }
    }

    setActiveTab(financialTab: string) {
        this.lenderService.getAccountingSystemStatus(this.memberId).subscribe((status: AccountingLinkedMember) => {
            if (status) {
                if (status.linked) {
                    this.getCodatData();
                    this.financialTab = financialTab;
                    this.isAccountingLinked = true;
                } else {
                    this.isAccountingLinked = false;
                }
            } else {
                this.isAccountingLinked = false;
            }
            this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
                this.memberName = member.name;
            });
            this.isLoaded = true;
            this.updateQueryParams(false);
        });
    }

    getTabClass(tabOption: string) {
        if (this.financialTab === tabOption) {
            return 'tab-option-selected px-3';
        } else {
            return 'tab-option px-3';
        }
    }

    onProfitDateChanged(event: IMyDateModel) {
        if (event) {
            this.selectedProfitDate = event.singleDate.jsDate;
            this.startProfitMonth = this.datePipe.transform(new Date(this.selectedProfitDate.getFullYear(), this.selectedProfitDate.getMonth(), 1), 'yyyy-MM-dd');
            this.getCodatData();
        }
    }

    onBalanceDateChanged(event: IMyDateModel) {
        if (event) {
            this.selectedBalanceDate = event.singleDate.jsDate;
            this.startBalanceMonth = this.datePipe.transform(new Date(this.selectedBalanceDate.getFullYear(), this.selectedBalanceDate.getMonth(), 1), 'yyyy-MM-dd');
            this.getCodatData();
        }
    }

    updateQueryParams(replace: boolean) {
        const queryParams = { _financialTab: this.financialTab, sort: null, dir: null };
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }
}
