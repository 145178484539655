import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { UntypedFormGroup } from '@angular/forms';
import { AuthService, LenderService, RequestAccess, RequestAccessFormService } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-request-access-modal',
    templateUrl: './request-access-modal.component.html'
})
export class RequestAccessModalComponent extends BaseModalComponent<RequestAccessModalComponent> implements OnInit {

    memberName: string;
    lenderId: string;
    memberId: string;
    formGroup: UntypedFormGroup;

    constructor(private authService: AuthService,
                private lenderService: LenderService,
                private requestAccessFormService: RequestAccessFormService,
                dialogRef: MatDialogRef<RequestAccessModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberName = data.memberName;
        this.lenderId = data.lenderId;
        this.memberId = data.memberId;
    }

    ngOnInit() {
        this.formGroup = this.requestAccessFormService.initializeForm();
    }

    submit() {
        const requestAccess: RequestAccess = {
            lenderId: this.lenderId,
            memberId: this.memberId,
            startDate: this.formGroup.get('startDateCtrl').value.singleDate.jsDate,
            endDate: this.formGroup.get('endDateCtrl').value.singleDate.jsDate,
            note: this.formGroup.get('noteCtrl').value
        };
        this.lenderService.createRequestAccess(requestAccess)
            .subscribe(() => {
                super.close(true);
            });
    }

    close() {
        super.close(false);
    }
}
