import { Component } from '@angular/core';
import { Bill, BusinessClient, Invoice, Member } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-merchant-source-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class MerchantSourceBadgeComponent extends BaseBadgeComponent<BusinessClient<Bill | Invoice> | Member> {

    setBadgeData() {
        this.badgeToolTip = this.parent.platform;
        switch (this.parent.platform) {
            case 'CONFIA':
                this.badgeText = 'Confia';
                this.badgeClass = 'blue-badge';
                break;
            default:
                this.badgeText = this.translatePipe.transform(this.parent.platform);
                this.badgeClass = 'green-badge';
                break;
        }
    }

}
