import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { BankAccount, BankAccountFormService, BankAccountService, BankAccountSource, BankAccountStatus, CustomValidators, LinkedBankAccount, MemberType, MerchantAccountConfig, MerchantAccountService } from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-add-member-bank-account-modal',
    templateUrl: './add-member-bank-account-modal.component.html'
})
export class AddMemberBankAccountModalComponent extends BaseModalComponent<AddMemberBankAccountModalComponent> implements OnInit {

    @Input() memberId: string;

    account: BankAccount;
    newBankAccountForm: UntypedFormGroup;
    accountTypeOpts: string[] = ['SAVINGS', 'CHECKING'];
    existingAccount = false;
    accountNameRequired = false;
    memberType: MemberType;

    constructor(private bankAccountFormService: BankAccountFormService,
                private bankAccountService: BankAccountService,
                private merchantAccountService: MerchantAccountService,
                private notifier: NotificationService,
                dialogRef: MatDialogRef<AddMemberBankAccountModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberId = data.memberId;
        this.memberType = data.memberType;
        this.account = data.account;
        this.accountNameRequired = data.accountNameRequired;
    }

    ngOnInit() {
        this.isAccountFormValid = this.isAccountFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.rejectAccount = this.rejectAccount.bind(this);

        this.newBankAccountForm = this.bankAccountFormService.initializeForm(this.account);
        this.existingAccount = !!this.account;
        this.validateACHAndWireRoutingNumbers();
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    validateACHAndWireRoutingNumbers() {
        if (this.newBankAccountForm.get('achRoutingNumberCtrl').value && this.newBankAccountForm.get('achRoutingNumberCtrl').valid) {
            this.newBankAccountForm.get('wireRoutingNumberCtrl').setValidators([Validators.minLength(9), Validators.maxLength(9), CustomValidators.onlyNumeric]);
        } else if (this.newBankAccountForm.get('wireRoutingNumberCtrl').value && this.newBankAccountForm.get('wireRoutingNumberCtrl').valid) {
            this.newBankAccountForm.get('achRoutingNumberCtrl').setValidators([Validators.minLength(9), Validators.maxLength(9), CustomValidators.onlyNumeric]);
        } else {
            this.newBankAccountForm.get('wireRoutingNumberCtrl').setValidators([Validators.required, Validators.minLength(9), Validators.maxLength(9), CustomValidators.onlyNumeric]);
            this.newBankAccountForm.get('achRoutingNumberCtrl').setValidators([Validators.required, Validators.minLength(9), Validators.maxLength(9), CustomValidators.onlyNumeric]);
        }
        this.newBankAccountForm.get('wireRoutingNumberCtrl').updateValueAndValidity();
        this.newBankAccountForm.get('achRoutingNumberCtrl').updateValueAndValidity();
    }

    onSubmit(reset: any) {
        const bankAccount = this.bankAccountFormService.getBankAccount(this.newBankAccountForm);
        bankAccount.memberId = this.memberId;
        bankAccount.source = BankAccountSource.AUTHORITY_VERIFIED;
        if (this.existingAccount) {
            this.approveBankAccount(bankAccount, reset);
        } else {
            this.addNewBankAccount(bankAccount, reset);
        }
    }

    approveBankAccount(bankAccount: BankAccount, reset: any) {
        this.bankAccountService.saveAndRegisterBankAccount(bankAccount).subscribe((financialAccount: BankAccount) => {
            if (financialAccount.status === BankAccountStatus.ACTIVE) {
                if (this.memberType === MemberType.BUSINESS_MERCHANT || this.memberType === MemberType.INDIVIDUAL_MERCHANT) {
                    // TODO CN-4466: We need to change this logic when multiple merchant member accounts gets introduced.
                    this.merchantAccountService.getMerchantAccountConfigByMerchantMemberId(this.memberId).subscribe((merchantAccountConfig: MerchantAccountConfig) => {
                        this.bankAccountService.getLinkedBankAccount(this.memberId, merchantAccountConfig.merchantMemberAccount.id).subscribe((linkedBankAccount: LinkedBankAccount) => {
                            if (!linkedBankAccount || linkedBankAccount.bankAccountId !== financialAccount.id) {
                                const linkedAccount = {
                                    memberId: this.memberId,
                                    memberAccountId: merchantAccountConfig.merchantMemberAccount.id,
                                    bankAccountId: financialAccount.id
                                };
                                this.bankAccountService.linkBankAccount(linkedAccount).subscribe((linkedAccount: LinkedBankAccount) => {
                                    this.close(true);
                                    this.notifier.showSuccess(`Bank account for ${financialAccount.holder} successfully added and linked.`);
                                }, (error: any) => {
                                    reset();
                                    throw error;
                                });
                            } else {
                                if (this.account.routingNumber && !financialAccount.routingNumber && financialAccount.wireRoutingNumber) {
                                    const updates = {
                                        defaultOutgoingTransactionType: 'OUTGOING_WIRE_TO_MERCHANT'
                                    };
                                    this.merchantAccountService.updateMerchantAccountConfig(merchantAccountConfig.id, updates).subscribe(() => {
                                        this.close(true);
                                        this.notifier.showSuccess(`Bank account for ${financialAccount.holder} successfully added.`);
                                    });
                                } else if (!this.account.routingNumber && this.account.wireRoutingNumber && financialAccount.routingNumber) {
                                    const updates = {
                                        defaultOutgoingTransactionType: 'OUTGOING_ACH_TO_MERCHANT'
                                    };
                                    this.merchantAccountService.updateMerchantAccountConfig(merchantAccountConfig.id, updates).subscribe(() => {
                                        this.close(true);
                                        this.notifier.showSuccess(`Bank account for ${financialAccount.holder} successfully added.`);
                                    });
                                } else {
                                    this.close(true);
                                    this.notifier.showSuccess(`Bank account for ${financialAccount.holder} successfully added.`);
                                }
                            }
                        });
                    }, (error: any) => {
                        reset();
                        throw error;
                    });
                } else {
                    reset();
                    this.notifier.showError('We encountered an error while saving this account.  Please try again.');
                }
            } else {
                reset();
                this.notifier.showError('We encountered an error while saving this account.  Please try again.');
            }
        }, (error: any) => {
            reset();
            throw error;
        });
    }

    addNewBankAccount(bankAccount: BankAccount, reset: any) {
        this.bankAccountService.saveAndRegisterBankAccount(bankAccount).subscribe((financialAccount: BankAccount) => {
            if (financialAccount.status === BankAccountStatus.ACTIVE) {
                if (this.memberType === MemberType.BUSINESS_MERCHANT || this.memberType === MemberType.INDIVIDUAL_MERCHANT) {
                    // TODO CN-4466: We need to change this logic when multiple merchant member accounts gets introduced.
                    this.merchantAccountService.getMerchantAccountConfigByMerchantMemberId(this.memberId).subscribe((merchantAccountConfig: MerchantAccountConfig) => {
                        const linkedAccount = {
                            memberId: this.memberId,
                            memberAccountId: merchantAccountConfig.merchantMemberAccount.id,
                            bankAccountId: financialAccount.id
                        };
                        this.bankAccountService.linkBankAccount(linkedAccount).subscribe((linkedAccount: LinkedBankAccount) => {
                            this.close(true);
                            this.notifier.showSuccess(`Bank account for ${financialAccount.holder} successfully added and linked.`);
                        }, (error: any) => {
                            reset();
                            throw error;
                        });
                    }, (error: any) => {
                        reset();
                        throw error;
                    });
                } else {
                    this.close(true);
                    this.notifier.showSuccess(`Bank account for ${financialAccount.holder} successfully added.`);
                }
            } else {
                reset();
                this.notifier.showError('We encountered an error while saving this account.  Please try again.');
            }
        }, (error: any) => {
            reset();
            throw error;
        });
    }

    rejectAccount(reset: any) {
        const account = this.bankAccountFormService.getBankAccount(this.newBankAccountForm);
        const updates = {
            status: BankAccountStatus.REJECTED
        };
        this.bankAccountService.updateBankAccount(account.id, updates).subscribe((financialAccount: BankAccount) => {
            if (financialAccount.status === BankAccountStatus.REJECTED) {
                this.close(true);
                this.notifier.showSuccess('Bank account has been rejected.');
            } else {
                reset();
                this.notifier.showError('We encountered an error while saving this account.  Please try again.');
            }
        }, (error: any) => {
            reset();
            throw error;
        });
    }

    isAccountFormValid() {
        return !this.newBankAccountForm.invalid;
    }
}
