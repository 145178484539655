import { Component, Input, OnInit } from '@angular/core';
import { ContractTemplate, SmartContractService, Task, ContractSection, AuthService, ContractTemplateType } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-sc-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {

    @Input() task: Task;
    @Input() mode = 'VIEW';

    contractTemplate: ContractTemplate;

    constructor(private authService: AuthService,
                private smartContractService: SmartContractService) {
    }

    ngOnInit() {
        this.smartContractService.getTemplateById(this.task.variables.template).subscribe((template: ContractTemplate) => {
            this.contractTemplate = template;
        });
    }

    sectionSelected(section: ContractSection) {
        section.terms.forEach((term) => {
            if (!section.included) {
                term.included = false;
            }
        });
        this.smartContractService.saveTemplate(this.contractTemplate).subscribe((contractTemplate: ContractTemplate) => {
            this.contractTemplate = contractTemplate;
        });
    }

    getViewType() {
        if (this.contractTemplate) {
            if (this.authService.getProfile().memberId === this.contractTemplate.creator) {
                return this.contractTemplate.templateType;
            } else {
                return this.contractTemplate.templateType === ContractTemplateType.BUYER ? 'SELLER' : 'BUYER';
            }
        }
    }
}
