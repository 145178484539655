import { Component, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
    SmartContractService, ContractTemplate, Task, WorkflowService, ContractTemplateVisibility, MemberService, Member
} from 'projects/services/src/public-api';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { NewTemplateModalComponent } from '../new-template-modal/new-template-modal.component';

@Component({
    selector: 'pc-sc-list-contract-templates',
    templateUrl: './list-contract-templates.component.html',
    styleUrls: ['./list-contract-templates.component.scss']
})
export class ListContractTemplatesComponent implements AfterViewInit, OnDestroy {

    ContractTemplateVisibility = ContractTemplateVisibility;

    contractTemplateList: ContractTemplate[];
    resultsLength = 1;

    isLoadingResults = false;
    displayedColumns: string[] = ['framework', 'name', 'created', 'templateType', 'description', 'sharedWith'];
    subscription: any;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private router: Router,
                private memberService: MemberService,
                private smartContractService: SmartContractService,
                private workflowService: WorkflowService,
                private dialog: MatDialog) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.smartContractService.getMyTemplates(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((data: any) => {
                this.isLoadingResults = false;
                this.resultsLength = data.content.length || 0;
                return data.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((data) => {
            this.contractTemplateList = data;
            this.contractTemplateList.forEach((template) => {
                template.templateAccess.forEach((access) => {
                    this.memberService.loadMember(access.memberId).subscribe((member: Member) => {
                        access.memberName = member.name;
                    });
                });
            });
        });
    }

    refreshList() {
        this.paginator.firstPage();
    }

    loadDetails(contractTemplate: ContractTemplate) {
        if (contractTemplate.visibility === ContractTemplateVisibility.DRAFT) {
            this.workflowService.getTaskByProcessDefinitionWithVariable(['create_template_v2', 'share_template_v2'], 'templateId', contractTemplate.id).subscribe((task: Task) => {
                this.workflowService.loadTask(task.id);
            });
        } else {
            this.router.navigate(['/smart_contracts/my_template/', contractTemplate.id]);
        }
    }

    onNewTemplate() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        const dialog = this.dialog.open(NewTemplateModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.refreshList();
            }
        });
    }

    cancel() {
        this.router.navigate(['/smart_contracts'], {queryParams: { _activeTab: 'my_contracts' }});
    }
}
