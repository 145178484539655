<div class="dashboard-list">
    <div class="row">
        <div class="col-8">
            <div class="dashboard-info-icon">
                <i class="fa fa-envelope-o tile-icon"></i>
                <a href="/communication/messages">Unread Messages</a>
            </div>
        </div>
        <div class="col-4 d-flex align-items-center justify-content-end">
            <div class="dashboard-list-summary"><strong>{{messageCount}}</strong> messages</div>
        </div>
    </div>
    <hr class="separator-thick">
    <div class="row" *ngFor="let message of messages">
        <div class="col-12">
            <div class="inlineBlockTop">
                <a class="dashboard-list-a" [routerLink]="['/communication/messages']"
                    [routerLinkActive]="['router-link-active']">
                    {{message.subject}}
                </a>
                <br>
                <div class="message-list-summary">
                    {{message.created | date: 'medium'}}
                </div>
                <small><br></small>
            </div>
        </div>
    </div>
</div>
