import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'projects/services/src/public-api';
import { TableUtils } from 'projects/components/src/lib/table-utils.service';

@Component({
    selector: 'pc-account-management',
    templateUrl: './account-management.component.html'
})
export class AccountManagementComponent implements OnInit {

    activeTab = 'myprofile';

    constructor(public authService: AuthService,
                private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['_activeTab'] ) {
            this.activeTab = queryParams['_activeTab'];
        } else {
            this.activeTab = 'myprofile';
            this.updateQueryParams(true);
        }

        this.route.queryParams.subscribe((params) => {
            this.activeTab = params['_activeTab'] || 'myprofile';
        });
    }

    getTabClass(tabOption: string) {
        if (this.activeTab === tabOption) {
            return 'tab-option-selected';
        } else {
            return 'tab-option';
        }
    }

    tabChanged(tabChanged: string) {
        if (this.activeTab === tabChanged) {
            return;
        }
        this.activeTab = tabChanged;
        this.updateQueryParams(false);
    }

    updateQueryParams(replace: boolean) {
        const queryParams = { _activeTab: this.activeTab, page: null, sort: null, dir: null, num: null };
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }
}
