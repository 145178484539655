import {
    AuthService, MemberService, Member
} from 'projects/services/src/public-api';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'pt-member-overview',
    templateUrl: './member-overview.component.html',
    styleUrls: ['./member-overview.component.scss']
})
export class MemberOverviewComponent implements OnInit {

    member: Member;

    constructor(private memberService: MemberService,
                private authService: AuthService) {}

    ngOnInit() {
        this.memberService.loadMember(this.authService.getProfile().memberId).subscribe((member: Member) => {
            this.member = member;
        });
    }
}
