import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http.service';
import { switchMap } from 'rxjs/operators';
import { Address, AddressType } from './models/address';
import { of } from 'rxjs';
import { AddressStatus } from './models/address-occupant';

@Injectable({
    providedIn: 'root'
})
export class AddressService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.EntityAPIEndpoint + '/';
    }

    // address endpoints
    saveAddress(address: Address) {
        return this.http.post(`${this.baseUrl}address`, address);
    }

    loadAddress(addressId: string) {
        return this.http.get(`${this.baseUrl}address/${addressId}`);
    }

    getAddressesByOccupantIdAndType(occupantId: string, type: AddressType) {
        const types = [type];
        return this.http.get(`${this.baseUrl}address?occupantId=${occupantId}&types=${types}`);
    }

    getAllAddresses(occupantId: string, statuses = []) {
        return this.http.get(`${this.baseUrl}address?occupantId=${occupantId}&statuses=${statuses}`);
    }

    getMainAndOperationsAddresses(occupantId: string) {
        return this.http.get(`${this.baseUrl}address?occupantId=${occupantId}&statuses=ACTIVE&types=PRIMARY,OPERATIONS`);
    }

    // address occupant endpoints
    saveAddressOccupant(addressOccupant: any) {
        return this.http.post(`${this.baseUrl}occupant`, addressOccupant);
    }

    updateAddressOccupant(id: string, body: any) {
        return this.http.put(`${this.baseUrl}occupant/${id}`, body);
    }

    getPrimaryAddress(occupantId: string) {
        return this.getAddressesByOccupantIdAndType(occupantId, AddressType.PRIMARY).pipe(
            switchMap(
                (response: any) => {
                    if (response.content.length) {
                        return of(response.content[0]);
                    }
                    return of(null);
                }
            ));
    }

    getRetailAddressOccupantLinks(memberId: string, statuses: AddressStatus[]) {
        const types = [AddressType.RETAIL];
        const params = `?occupantId=${memberId}&types=${types}&statuses=${statuses}&sort=nickname&sortDir=asc`;
        return this.http.get(`${this.baseUrl}occupant${params}`);
    }

    getAllAddressOccupantLinks(occupantId: string, start: number, size: number, sort: string, sortDir: string) {
        const params = `?occupantId=${occupantId}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}occupant${params}`);
    }

    linkAddressToOccupant(address: Address, occupantId: string, memberId: string, nickname?: string, accountId?: string) {
        return this.saveAddress(address).pipe(
            switchMap(
                (saved: Address) => {
                    const occupant = {
                        occupantId,
                        type: address.type,
                        addressId: saved.id,
                        address: saved,
                        memberId: memberId,
                        status: AddressStatus.ACTIVE,
                        nickname: nickname || null,
                        memberAccountId: accountId || null,
                        created: saved.created
                    };
                    return this.saveAddressOccupant(occupant);
                }
            ));
    }

}
