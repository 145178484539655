<fieldset class="form-group" *ngIf="formCtrl">
    <div class="row">
        <div *ngIf="label" class="col-12">
            <label class="form-label">{{ label | translate }}</label>
        </div>
        <div [formGroup]="formGroup" [ngClass]="getClass()">
            <input [attr.id]="id ? id : null" type="text" [formControlName]="formCtrl" class="form-control" [placeholder]="placeholder" [required]="required"
                (change)="onAmountChanged()" (input)="updateValidators()"/>
        </div>
    </div>
</fieldset>
