<div class="col-12 pull-left mb-3">
    <span class="form-title">Available Systems</span>
</div>
<div *ngIf="requiresMrb" class="button-panel centered">
    <div class="col-md-6">
        <pt-link-metrc-system [memberId]="memberId" (metrcChanged)="metrcChanged($event)">
        </pt-link-metrc-system>
    </div>

    <div class="col-md-6">
        <pt-link-accounting-system *ngIf="requiresAccounting" [memberId]="memberId" (accountingChanged)="accountingChanged($event)">
        </pt-link-accounting-system>
    </div>
</div>
<div *ngIf="!requiresMrb" class="button-panel centered">
    <div class="col-md-3"></div>
    <div class="col-md-6">
        <pt-link-accounting-system *ngIf="requiresAccounting" [memberId]="memberId" (accountingChanged)="accountingChanged($event)">
        </pt-link-accounting-system>
    </div>
    <div class="col-md-3"></div>
</div>
