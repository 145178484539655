<div>
    <div>
        <div class="modal-header">
            <label class="form-title">Merge Member</label>
            <i class="modal-close" (click)="close()">&times;</i>
        </div>
        <div class="modal-content-small">
            <div class="row mb-3">
                <div *ngIf="memberMergeForm" class="col-12" >
                    <div>
                        <pc-form-selector [label]="'Target Member'" [formCtrl]="'targetMemberIdCtrl'" [formGroup]="memberMergeForm" [options]="relatedMembers"
                            [idValue]="'id'" [displayValue]="'name'" (selected)="onSelectTargetMember($event)">
                        </pc-form-selector>
                    </div>
                </div>
                <div *ngIf="!memberValid" class="error-message mb-4">
                    <div class="form-error" [innerHTML]="'Member has pending transactions and cannot be merged.'"></div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="blue-button" (click)="close()">Cancel</button>
            <pc-submit-button [enabled]="isFormValid" [submit]="onSubmit" [text]="'Transfer'"></pc-submit-button>
        </div>
    </div>
</div>
