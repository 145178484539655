import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthService, Configuration, MetrcAccountFormService, MetrcLicense, MetrcService, MetrcStatus, Country, MemberService, Member } from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-metrc-account-modal',
    templateUrl: './metrc-account-modal.component.html'
})
export class MetrcAccountModalComponent extends BaseModalComponent<MetrcAccountModalComponent> implements OnInit {

    memberId: string;
    metrcForm: UntypedFormGroup;
    countries: Country[];
    error: any;
    authorityState: string;
    jurisdiction : string;

    constructor(private metrcAcountFormService: MetrcAccountFormService,
                private metrcService: MetrcService,
                private authService: AuthService,
                private notifier: NotificationService,
                private memberService: MemberService,
                dialogRef: MatDialogRef<MetrcAccountModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberId = data.memberId;
    }

    ngOnInit() {
        this.isMetrcFormValid = this.isMetrcFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        const metrcLicense = new MetrcLicense();
        metrcLicense.memberId = this.authService.getProfile().memberId;
        this.metrcForm = this.metrcAcountFormService.initializeForm(metrcLicense);
        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            this.jurisdiction = member.jurisdiction;
            this.countries = Configuration.getConfig().countries;
            const stateDefinition = this.countries.find((c) => {
                return c.name === 'United States';
            }).divisions.find((state) => {
                return state.code === this.jurisdiction;
            });
            this.authorityState = stateDefinition.name;
        });
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    onSubmit() {
        const metrcLicense = this.metrcAcountFormService.getMetrcLicense(this.metrcForm);
        metrcLicense.status = MetrcStatus.ACTIVE;
        metrcLicense.state = this.authorityState;
        metrcLicense.stateCode = this.jurisdiction;
        this.metrcService.saveMetrcLicense(metrcLicense).subscribe((savedMetrcLicense: MetrcLicense) => {
            this.metrcService.initializeAccount(savedMetrcLicense).subscribe(() => {
                this.close(true);
                this.notifier.showSuccess('Your METRC account has been successfully linked.');
            });
        }, (errorResponse: any) => {
            if (errorResponse.status === 409) {
                this.notifier.showError('User key already exist for this state. Please try with new user key or contact support.');
            } else if (errorResponse.status === 401) {
                this.notifier.showError('The key was invalid or not authorized for use in this state. Please try with new user key or contact support.');
            } else if (errorResponse.status === 400) {
                this.notifier.showError('The key was invalid. It should be 48 characters. Please try with new key or contact support.');
            } else if (errorResponse.status === 403) {
                this.notifier.showError('Maximum keys exceeded for selected state. Please try with new state or contact support.');
            } else {
                this.notifier.showError(`An unknown error occurred with Metrc: ${errorResponse.message}. Please contact clientservices@confia.io.`);
            }
            super.close(true);
        });
    }

    isMetrcFormValid() {
        return !this.metrcForm.invalid;
    }
}
