import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { CommentService, Alert, PagedResponse, AuthService, AlertType, AlertStatus } from 'projects/services/src/public-api';
import { AlertsModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pc-alerts-gadget',
    templateUrl: './alerts-gadget.component.html'
})
export class AlertsGadgetComponent implements OnInit {

    AlertType = AlertType;
    AlertStatus = AlertStatus;

    alerts: Alert[];
    displayedColumns: string[] = ['description', 'date', 'status', 'action'];
    isLoadingResults = true;

    constructor(private alertService: CommentService,
                private dialog: MatDialog,
                public authService: AuthService) {
    }

    ngOnInit() {
        this.alertService.getAlertsByStatusAndType(
            '',
            [AlertStatus.ACTIVE, AlertStatus.INFORMATIONAL],
            AlertType.ALL_ALERT_TYPES,
            0,
            5,
            'created',
            'DESC')
            .subscribe(
                (response: PagedResponse<Alert>) => {
                    this.isLoadingResults = false;
                    this.alerts = response.content;
                }
            );
    }

    reviewAlert(alert: Alert, event:MouseEvent) {
        event.cancelBubble = true;
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.disableClose = true;
        dialogConfig.data = {
            alert
        };
        const dialog = this.dialog.open(AlertsModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.alertService.getAlertsByStatusAndType(
                    '',
                    [AlertStatus.ACTIVE, AlertStatus.INFORMATIONAL],
                    AlertType.ALL_ALERT_TYPES,
                    0,
                    5,
                    'created',
                    'DESC').subscribe((response: PagedResponse<Alert>) => {
                    this.alerts = response.content;
                });
            }
        });
    }

    navigatesToMember(type: AlertType) {
        return type === AlertType.CONSUMER || type === AlertType.MEMBER_ACCOUNT || type === AlertType.EXTERNAL_SYSTEM || type === AlertType.PERIODIC_REVIEW || type === AlertType.MEMBER;
    }

}
