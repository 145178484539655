import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseModalComponent } from 'projects/components/src/lib/modal/index';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { CustomValidators, KeyParty, KycSearchService } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-kyc-extra-search-modal',
    templateUrl: './kyc-extra-search-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KycExtraSearchModalComponent extends BaseModalComponent<KycExtraSearchModalComponent> implements OnInit {

    extraSearchForm: UntypedFormGroup;
    keyParties: KeyParty[] = [];

    selectedKeyParty: KeyParty;

    constructor(private kycSearchService: KycSearchService,
                private notificationService: NotificationService,
                private formBuilder: UntypedFormBuilder,
                dialogRef: MatDialogRef<KycExtraSearchModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);

        this.keyParties = data.keyParties;
    }

    ngOnInit() {
        this.onRunSearch = this.onRunSearch.bind(this);
        this.isSubmitEnabled = this.isSubmitEnabled.bind(this);
        this.extraSearchForm = this.formBuilder.group({
            keyPartyCtrl: ['', [Validators.required]],
            searchTerm: ['', [Validators.required, CustomValidators.noLeadingOrTrailingWhitespace, Validators.maxLength(250)]]
        });
    }

    onRunSearch(resetSubmit) {
        const searchTerm = this.extraSearchForm.controls.searchTerm.value.trim();

        if (searchTerm === this.selectedKeyParty.name) {
            this.notificationService.showError('The "search term" must be different than the member\'s name');
            resetSubmit();
            return;
        }

        const body = {
            searchTerm,
            keyParties: [{
                memberId: this.selectedKeyParty.individualKeyPartyId,
                individualKeyPartyId: this.selectedKeyParty.individualKeyPartyId,
                corporateKeyPartyId: this.selectedKeyParty.corporateKeyPartyId,
                type: this.selectedKeyParty.type
            }],
            extra: true
        };

        this.kycSearchService.createSearch(body).subscribe((result) => {
            this.notificationService.showSuccess(`A compliance search for ${this.selectedKeyParty.name} has been successfully registered.`);
            super.close({
                action: 'COMPLETE',
                search: result
            });
        });
    }

    onKeyPartySelected(keyParty: KeyParty) {
        this.selectedKeyParty = keyParty;
    }

    isSubmitEnabled() {
        return this.extraSearchForm?.valid;
    }
}
