import { Component, Input } from '@angular/core';
import { MemberFunction, MemberStatus } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-users',
    templateUrl: './member-users.component.html'
})
export class MemberUsersComponent {

    @Input() memberId: string;
    @Input() memberFunctions: MemberFunction[] = [];
    @Input() readOnly = false;
    @Input() status: MemberStatus;
    @Input() changeTrigger: number;

    activeTab = 'members';

    constructor() {}

    refreshUsers() {
        this.changeTrigger = Math.random();
    }
}
