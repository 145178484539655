import { Component, Input } from '@angular/core';

@Component({
    selector: 'pc-loader',
    templateUrl: './loader.component.html'
})
export class LoaderComponent {

    @Input() title: string;

}