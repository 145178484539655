<div class="horizontal-scroll mat-table-container" *ngIf="memberAccounts">
    <table mat-table [dataSource]="memberAccounts">
        <ng-container matColumnDef="selected">
            <th mat-header-cell *matHeaderCellDef> Selected </th>
            <td mat-cell *matCellDef="let element">
                <i class="fa" (click)="selectMemberAccount(element)" [class.fa-check-square-o]="element.selected" [class.fa-square-o]="!element.selected"></i>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element">
                {{element.name}}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
