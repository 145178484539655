<div class="row" *ngIf="filter">
    <div class="col-12">
        <pt-transaction-table-header [filter]="filter" [isActivityListAvailable]="false" [memberId]="memberId"
            (filterChanged)="filterChanged($event)" (transactionSubmitted)="transactionSubmitted()" [readOnly]="readOnly">
        </pt-transaction-table-header>
    </div>
</div>

<div class="row mt-3" *ngIf="filter && filter.transactionTab === 'TRANSACTION_LIST'">
    <span class="col-12 span-header"> <strong>Transactions by Date</strong></span>
    <div class="col-12">
        <pc-transaction-charts-gadgets [filter]="filter" [changeTrigger]="changeTrigger">
        </pc-transaction-charts-gadgets>
    </div>
</div>

<div class="row" *ngIf="filter">
    <div class="col-12" *ngIf="filter.transactionTab === 'TRANSACTION_LIST'">
        <pc-transaction-list [filter]="filter" [memberId]="memberId" [changeTrigger]="changeTrigger" [readOnly]="readOnly">
        </pc-transaction-list>
    </div>
</div>
