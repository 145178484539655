export class TrackingSystemSupportEvidence {
    id: string;
    transactionId: string;
    accountActivityAmount: number;
    totalEstimatedCost: number;
    supportEvidenceItems: SupportEvidenceItem[];
}

export class SupportEvidenceItem {
    id: string;
    trackingSystemSupportEvidenceId: string;
    manifestCounterPartyName: string;
    productCategory: string;
    unitsOfMeasurement: string;
    quantity: number;
    estimatedCost: number;
}