export class RequestAccess {
    id?: string;
    lenderId: string;
    memberId: string;
    startDate: string;
    endDate: string;
    status?: DataAccessStatus;
    note?: string;
}

export enum DataAccessStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    EXPIRED = 'EXPIRED'
}
