import { Component, OnInit } from '@angular/core';
import { Configuration, WorkflowService, Task, UserAccountService, Role, UserAccount, MemberService, Utils } from 'projects/services/src/public-api';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentPortal } from '@angular/cdk/portal';
import { ConfirmModalComponent, LoaderComponent } from 'projects/components/src/public-api';
import { Overlay } from '@angular/cdk/overlay';

@Component({
    selector: 'pt-member-invite',
    templateUrl: './member-invite.component.html'
})
export class MemberInviteComponent implements OnInit {

    Role = Role;
    Utils = Utils;

    task: Task;
    taskVars: any;
    userAccount: UserAccount;

    constructor(private router: Router,
                private memberService: MemberService,
                private userAccountService: UserAccountService,
                private workflowService: WorkflowService,
                private overlay: Overlay,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        if (this.workflowService.getCurrentTask()) {
            this.task = this.workflowService.getCurrentTask();
            this.taskVars = this.task.variables;
            if (this.taskVars.userAccountId) {
                this.userAccountService.getUserAccountById(this.taskVars.userAccountId).subscribe((userAccount: UserAccount) => {
                    this.userAccount = userAccount;
                });
            }
        }
    }

    isExpired() {
        const diff = Math.abs(new Date().getTime() - new Date(this.task.created).getTime());
        return Math.round(diff / 60000) > Configuration.getConfig().registrationLinkExpirationInMinutes;
    }

    revokeInvitation() {
        if (this.dialog.openDialogs?.length) {
            return;
        }
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        let description;
        if (this.taskVars.memberId) {
            description = `This will revoke the application sent to <b>${this.userAccount.user.firstName} ${this.userAccount.user.lastName}</b> for <b>${this.taskVars.memberName}</b>.  Any registration links sent to this user will be expired immediately.`;
        } else {
            description = `This will revoke the invitation sent to <b>${this.userAccount.user.firstName} ${this.userAccount.user.lastName}</b> to join <b>${this.taskVars.memberName}</b>.  Any invitation links sent to this user will be expired immediately.`;
        }
        dialogConfig.data = {
            title: `Revoke ${this.taskVars.memberId ? 'Application' : 'Invitation'}`,
            description,
            confirmMessage: `Do you want to revoke this ${this.taskVars.memberId ? 'application' : 'invitation'}?`,
            confirmText: 'Revoke'
        };
        const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((confirmAction: any) => {
            if (confirmAction === 'confirmed') {
                const overlayRef = this.overlay.create({
                    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                    hasBackdrop: true
                });
                const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
                if (this.taskVars.memberId) {
                    componentRef.instance.title = 'Deleting application...';
                    this.memberService.deleteApplicant(this.taskVars.memberId).subscribe(() => {
                        overlayRef.dispose();
                        this.returnToTasks();
                    }, (error: Error) => {
                        overlayRef.dispose();
                        throw error;
                    });
                } else {
                    componentRef.instance.title = 'Deleting invitation...';
                    this.userAccountService.deleteUserInvitation(this.taskVars.userAccountId).subscribe(() => {
                        overlayRef.dispose();
                        this.returnToTasks();
                    }, (error: Error) => {
                        overlayRef.dispose();
                        throw error;
                    });
                }
            }
        });
    }

    returnToTasks() {
        this.router.navigate(['/communication/notifications']);
    }
}
