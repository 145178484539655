<div class="horizontal-scroll mat-table-container">
    <div class="table mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="memberAccounts" matSort matSortDisableClear [matSortActive]="pageTracking.sort" [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="account_owner">
            <th mat-header-cell *matHeaderCellDef> Account Owner </th>
            <td mat-cell *matCellDef="let element">
                <a (click)="memberDetails($event, element)" class="link">
                    <b> {{ element.member.name }} </b>
                    <br>
                    <b *ngIf="element.member.dbaName"> DBA: {{ element.member.dbaName }} </b>
                </a>
                <div>
                    <pc-business-address *ngIf="element.member.address" [address]="element.member.address">
                    </pc-business-address>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="account_info">
            <th mat-header-cell *matHeaderCellDef> Account Info </th>
            <td mat-cell *matCellDef="let element">
                <div><b>Account Number:</b> {{ element.accountNumber }}</div>
                <div><b>Account Name:</b> <a (click)="memberAccountDetails($event, element)" class="link"> <b> {{ element.accountName }} </b> </a></div>
                <div><b>Friendly Name:</b> {{ element.accountFriendlyName }}</div>
                <div><b>Account Type:</b> {{ element.accountType | translate }}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="contact_info">
            <th mat-header-cell *matHeaderCellDef> Contact Info </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.member.email"><b>Email:</b> {{ element.member.email }}</div>
                <div *ngIf="element.member.phone"><b>Phone:</b> {{ element.member.phone | phone }} </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="centered"> Member Status </th>
            <td mat-cell *matCellDef="let element" class="centered">
                <pc-member-badge [parent]="element.member"></pc-member-badge>
                <div *ngIf="element?.member.jurisdiction">{{ Utils.getJurisdictionName(element.member.jurisdiction) }}</div>
                <div *ngIf="element.member.tier === RiskTier.TIER_1">Tier 1</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef class="centered" mat-sort-header> Created Date </th>
            <td mat-cell *matCellDef="let element" class="centered"> {{ element.created | date:'mediumDate' }} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.disabled]="row.status === MemberAccountStatus.DISABLED || row.member.status !== MemberStatus.ACTIVE"></tr>
    </table>
    <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"></mat-paginator>
</div>
