<div class="col-12 dashboard-info">
    <div class="row" *ngIf="filter">
        <div class="col-12">
            <pt-transaction-table-header [isActivityListAvailable]="isActivityListAvailable" [filter]="filter" [memberId]="memberId" [exportAccountName]="exportAccountName"
                (filterChanged)="filterChanged($event)" (transactionSubmitted)="transactionSubmitted()" >
            </pt-transaction-table-header>
        </div>
    </div>

    <div class="row mt-3" *ngIf="filter && authService.hasAnyRole([Role.AUTHORITY])">
        <span class="col-12 span-header"> <strong>Transactions by Date</strong></span>
        <div class="col-12">
            <pc-transaction-charts-gadgets [filter]="filter" [changeTrigger]="changeTrigger">
            </pc-transaction-charts-gadgets>
        </div>
    </div>
    <div class="row" *ngIf="filter">
        <div class="col-12" *ngIf="filter.transactionTab === 'ACCOUNT_ACTIVITY'">
            <pt-account-activity-details [memberId]="memberId" [changeTrigger]="changeTrigger"></pt-account-activity-details>
        </div>
        <div class="col-12" *ngIf="filter.transactionTab === 'TRANSACTION_LIST'">
            <pc-transaction-list [filter]="filter" [memberId]="memberId" [changeTrigger]="changeTrigger">
            </pc-transaction-list>
        </div>
    </div>
</div>
