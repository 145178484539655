<div class="row">
    <div class="col-12 dashboard-info">
        <div class="row">
            <div class="dashboard-header mb-2">Periodic Compliance Review</div>

            <p>
                As part of our regulatory requirements, we are required to update your records and refresh your profile
                on a
                periodic basis.

                Please review your profile and let us know if there are any changes to the business structure, key
                parties,
                licensing, or any other information (such as address, contact details, etc). In addition, please upload
                any
                updated documentation, including your memberAccount’s Statement of Information filed with the Secretary of
                State and
                license renewals.
            </p>
        </div>

        <div class="row my-5">
            <div class="dashboard-header mb-2">Key Parties Review</div>

            <p>
                It is therefore important that you complete this form, and we receive this information no later than 30
                days from
                the date of this notification.
            </p>

            <pt-active-key-party-list
                [memberRegistrationType]="memberRegistrationType"
                [memberId]="memberId"
                [memberType]="memberType"
                [memberStatus]="memberStatus">
            </pt-active-key-party-list>
        </div>

        <hr/>

        <div class="row my-5">
            <pt-member-other-documents *ngIf="businessCategory"
                title="Documents Review"
                subtitle="Upload any updated documentation, including your memberAccount's Statement of Information
                    filed with the Secretary of State, corporate structure, business structure, financial info,
                    Key Party's Passport or State Issued ID, and license renewals or changes, indicating that
                    you are in good standing"
                [memberId]="memberId"
                [businessCategory]="businessCategory">
            </pt-member-other-documents>
        </div>

        <hr/>

        <div class="row my-5">
            <pt-location-list
                title="Location Review"
                subtitle="Please review the current locations, and add any new locations for compliance review."
                [memberId]="memberId">
            </pt-location-list>
        </div>

        <hr/>

        <div class="row my-5">
            <div class="dashboard-header mb-2">Financial Projections Review</div>

            <div class="row my-1">
                <div class="col-lg-7 mb-1">
                    <span class="span-header fs-5">Financial Details</span>
                </div>
                <div class="col-lg-5 mb-1">
                    <span class="span-header fs-5">Estimate of Your Monthly Confia Account Activity</span>
                </div>
            </div>

            <form *ngIf="financialDetailsForm" [formGroup]="financialDetailsForm" class="row">
                <div class="col-lg-7">
                    <pc-form-amount-input [required]="true" [label]="'Projected Total Annual Revenue'"
                        [formCtrl]="'projectedAnnualRevenueCtrl'" [formGroup]="financialDetailsForm"
                        [placeholder]="''" [includeCents]="false">
                    </pc-form-amount-input>
                    <pc-percentage-input [required]="true"
                        [label]="'Revenue % from Cannabis (Plant Touching) Sales'"
                        [formCtrl]="'percentOfRevenueFromCannabisCtrl'"
                        [formGroup]="financialDetailsForm">
                    </pc-percentage-input>
                    <pc-percentage-input [required]="true"
                        [label]="'Revenue % from Cannabis-Related Businesses'"
                        [formCtrl]="'percentOfRevenueFromCannabisPartnersCtrl'"
                        [formGroup]="financialDetailsForm">
                    </pc-percentage-input>
                    <pc-percentage-input [required]="true" [label]="'Revenue % in Cash'"
                        [formCtrl]="'percentOfRevenueFromCashCtrl'" [formGroup]="financialDetailsForm">
                    </pc-percentage-input>
                </div>

                <div class="col-lg-5">
                    <pc-form-amount-input [required]="true"
                        [label]="'Total Amount of Outgoing Funds (e.g. payments, withdrawals)'"
                        [formCtrl]="'expectedMonthlyPaymentsCtrl'" [formGroup]="financialDetailsForm"
                        [placeholder]="'payments made from Confia'"
                        [includeCents]="false">
                    </pc-form-amount-input>
                    <pc-form-amount-input [required]="true"
                        [label]="'Total Amount of Incoming Funds (e.g. deposits, incoming wires)'"
                        [formCtrl]="'expectedMonthlyDepositsCtrl'" [formGroup]="financialDetailsForm"
                        [placeholder]="'all deposits into Confia'"
                        [includeCents]="false">
                    </pc-form-amount-input>
                    <pc-form-amount-input [required]="true"
                        [label]="'Total Amount of Cash Deposited'"
                        [formCtrl]="'expectedMonthlyCashDepositCtrl'"
                        [formGroup]="financialDetailsForm"
                        [placeholder]="'cash deposits into Confia'"
                        [includeCents]="false">
                    </pc-form-amount-input>
                    <pc-form-text-input [required]="true"
                        [label]="'Number of Incoming Transactions'"
                        [formCtrl]="'expectedMonthlyNumberOfReceiptsCtrl'"
                        [formGroup]="financialDetailsForm"
                        [placeholder]="'# of payments received and deposits into Confia'"
                        [colSize]="3">
                    </pc-form-text-input>
                    <pc-form-text-input [label]="'Number of Outgoing Transactions'"
                        [formCtrl]="'expectedMonthlyNumberOfPaymentsCtrl'"
                        [formGroup]="financialDetailsForm"
                        [placeholder]="'# of payments and withdrawals from Confia'"
                        [colSize]="3">
                    </pc-form-text-input>
                </div>
            </form>
        </div>

        <div class="button-panel pull-right">
            <pc-submit-button [enabled]="isFinancialDetailsValid" [submit]="onSave" [text]="'Save'"></pc-submit-button>
            <pc-submit-button [enabled]="isPeriodicReviewValid" [submit]="onSubmit" [text]="'Complete Review'"></pc-submit-button>
        </div>

    </div>
</div>
