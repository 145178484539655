import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService, CountNotificationService, MessageService, WorkflowService } from 'projects/services/src/public-api';
import { MatDialog } from '@angular/material/dialog';
@Component({
    selector: 'pt-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

    @Input() expandedMenu: boolean;

    @Output() toggleExpandSideMenu: EventEmitter<any> = new EventEmitter<any>();

    profile: any;

    constructor(public authService: AuthService,
                private messageService: MessageService,
                private matDialog: MatDialog,
                private workflowService: WorkflowService,
                private countNotificationService: CountNotificationService) {}

    ngOnInit() {
        this.profile = this.authService.getProfile();
        // set a time to check if still should be logged in
        this.checkSecurityStatus();
    }

    onToggleExpandSideMenu() {
        this.toggleExpandSideMenu.emit('toggle');
    }

    checkSecurityStatus() {
        this.authService.setInterval(setInterval(() => {
            this.authService.getAuthenticatedProfile().subscribe(() => {
                // still logged in - no action needed
                this.messageService.countReceivedMessages().subscribe((resp) => {
                    this.countNotificationService.setUnreadMessageCount(parseInt(resp.headers.get('Content-Length'), 10));
                });
                this.workflowService.getTaskCount().subscribe((resp) => {
                    this.countNotificationService.setOpenItemCount(parseInt(resp.headers.get('Content-Length'), 10));
                });
            }, (error: any) => {
                if (error.status === 426) {
                    throw error;
                } else {
                    // not logged in - log out completely
                    const redirectUrl = window.location.pathname + window.location.search;
                    this.matDialog.closeAll();
                    if (redirectUrl !== '/' && redirectUrl !== '/login') {
                        this.authService.logout(redirectUrl);
                    } else {
                        this.authService.logout();
                    }
                }
            });
        }, 2 * 60 * 1000));
    }
}
