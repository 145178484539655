import { Injectable, Inject, ElementRef} from '@angular/core';
import { HttpService } from './http.service';
import { DataroomService } from './dataroom.service';
import { BsaReport, FilingInstitution } from './models/bsa-report';
import { HttpResponse } from '@angular/common/http';
import { WeeklyReport } from './models/weekly-report';
import { PostedTransactionType, TransactionSubType } from './models/transaction';
import { Observable } from 'rxjs';
import { AccountActivity, Member, MemberService, PagedResponse } from 'projects/services/src/public-api';

@Injectable({
    providedIn: 'root'
})
export class ReportsService {

    baseUrl: string;
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    constructor(private http: HttpService,
                private memberService: MemberService,
                private dataroomService: DataroomService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.ReportsAPIEndpoint + '/report';
    }

    getReportById(id: string) {
        return this.http.get(`${this.baseUrl}/${id}`);
    }

    generateInitialReport(member: Member) {
        return this.http.post(`${this.baseUrl}/initial`, member);
    }

    getBsaReportsBySubmissionType(submissionType: string,
        status: string[],
        memberId: string,
        filingInstitution: FilingInstitution,
        start: number,
        size: number,
        sort: string,
        sortDir: string) {

        const params = `?submissionType=${submissionType}&status=${status}&memberId=${memberId}&filingInstitution=${filingInstitution}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}${params}`);
    }

    getPartyInformation(submissionType: string, partyTypeCode: string, filingInstitution: FilingInstitution) {
        const param = `?submissionType=${submissionType}&partyCode=${partyTypeCode}&filingInstitution=${filingInstitution}`;
        return this.http.get(`${this.baseUrl}/party${param}`);
    }

    getDailyReconciliationReports(start: number,
        size: number,
        sort: string,
        sortDir: string) {

        const params = `?start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}/daily${params}`);
    }

    getMonthlyReportsByMemberIdAndAccountId(memberId: string,
        memberAccountId: string,
        start: number,
        size: number,
        sort: string,
        sortDir: string) {

        const params = `?memberId=${memberId}&memberAccountId=${memberAccountId}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}/monthly${params}`);
    }

    getWeeklyReportsByMemberId(memberId: string,
        memberAccountId: string,
        start: number,
        size: number,
        sort: string,
        sortDir: string) {

        const params = `?memberId=${memberId}&memberAccountId=${memberAccountId}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}/weekly${params}`);
    }

    viewPdf(reportId: string) {
        const params = `?id=${reportId}`;
        return this.http.getDownload(`${this.baseUrl}/monthly/pdf${params}`).subscribe(
            (response: HttpResponse<Blob>) => {
                const binaryData = [];
                binaryData.push(response);
                const url = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/pdf'}));
                window.open(url, '_blank');
            }
        );
    }

    downloadResource(weeklyReport: WeeklyReport, element: ElementRef) {
        const params = `?id=${weeklyReport.id}`;
        return this.http.getDownload(`${this.baseUrl}/weekly/export${params}`).subscribe(
            (response: HttpResponse<Blob>) => {
                const binaryData = [];
                binaryData.push(response);
                const url = window.URL.createObjectURL(new Blob(binaryData, {type: 'blob'}));
                const link = element.nativeElement;
                link.href = url;
                link.download = weeklyReport.memberId + '.csv';
                link.click();
                window.URL.revokeObjectURL(url);
            }
        );
    }

    loadBsaReport(bsaReport: BsaReport) {
        if (bsaReport.id) {
            if (!bsaReport.memberName) {
                this.memberService.loadMember(bsaReport.memberId).subscribe((member: Member) => {
                    if (member) {
                        bsaReport.memberName = member.name;
                    }
                });
            }

            this.dataroomService.getDocumentById(bsaReport.documentId).subscribe((upload: any) => {
                bsaReport.documentDetails = upload;
            });
        }
    }

    viewCsv(reportId: string) {
        const params = `?id=${reportId}`;
        return this.http.getDownload(`${this.baseUrl}/monthly/csv${params}`).subscribe(
            (response: HttpResponse<Blob>) => {
                const binaryData = [];
                binaryData.push(response);
                const url = window.URL.createObjectURL(new Blob(binaryData, {type: 'text/csv'}));
                window.open(url, '_blank');
            }
        );
    }

    uploadReportToServer(uploadRequest: any) {
        return this.http.post(`${this.baseUrl}/upload`, uploadRequest);
    }

    updateTrackingDetails(trackingDetails: any) {
        return this.http.put(`${this.baseUrl}`, trackingDetails);
    }

    updateFincenFiledReport(fincenFiledReportId: string, updates: any) {
        return this.http.put(`${this.baseUrl}/${fincenFiledReportId}`, updates);
    }

    getAccountActivityListing(memberId: string, memberAccountId: string, start: number, size: number, sort: string, sortDir: string) {
        const params = `?timezone=${this.timezone}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}/account-activity/${memberId}/${memberAccountId}${params}`) as Observable<PagedResponse<AccountActivity>>;
    }

    exportAccountActivityList(memberId: string, memberAccountId: string) {
        const params = `?memberId=${memberId}&memberAccountId=${memberAccountId}&timezone=${this.timezone}`;
        return this.http.getDownload(`${this.baseUrl}/account-activity/export${params}`);
    }

    exportTransactionActivityList(reportId: string) {
        return this.http.getDownload(`${this.baseUrl}/${reportId}/export`);
    }

    getSARContinuingReportPostedTransactionsList(reportId: string, postedTxnType: PostedTransactionType) {
        return this.http.get(`${this.baseUrl}/continuing/related/${reportId}?postedTxnType=${postedTxnType}`);
    }

    getCTRReportPostedTransactionsList(reportId: string, subType: TransactionSubType) {
        return this.http.get(`${this.baseUrl}/ctr/related/${reportId}?subType=${subType}`);
    }

    uploadInvestigationFiles(personsFile: File, companiesFile: File) {
        const formData = new FormData();
        formData.append('personsFile', personsFile);
        formData.append('companiesFile', companiesFile);

        return this.http.post(`${this.baseUrl}/investigation`, formData);
    }

    getFincenInvestigationReports(start: number,
        size: number,
        sort: string,
        sortDir: string) {

        const params = `?start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}/investigation${params}`);
    }

    regenerateReport(reportId: String) {
        return this.http.post(`${this.baseUrl}/${reportId}/regenerate`, null);
    }
}
