import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BankingIntegrationService, BankingIdentificationFormService, CustomerInfo } from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-banking-id-modal',
    templateUrl: './banking-id-modal.component.html'
})
export class BankingIdentificationModalComponent extends BaseModalComponent<BankingIdentificationModalComponent> implements OnInit {

    bankingIdentificationForm: UntypedFormGroup;

    constructor(private bankingIntegrationService: BankingIntegrationService,
                private bankingIdentificationFormService: BankingIdentificationFormService,
                private notifier: NotificationService,
                dialogRef: MatDialogRef<BankingIdentificationModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.bankingIdentificationForm = this.bankingIdentificationFormService.initializeForm(data.customerInfo, data.memberAccount);
    }

    ngOnInit() {
        this.isBankingIdentificationFormValid = this.isBankingIdentificationFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    close(customerInfo?: CustomerInfo) {
        super.close(customerInfo);
    }

    onSubmit(reset: any) {
        const customerInfo = this.bankingIdentificationFormService.getCustomerInfo(this.bankingIdentificationForm);
        this.bankingIntegrationService.setCustomerInfo(customerInfo).subscribe((customerInfo: CustomerInfo) => {
            this.close(customerInfo);
        }, (errorResponse: any) => {
            reset();
            if (errorResponse.status === 409) {
                this.notifier.showError('Account id is already in use.  Please check and re-enter.');
            } else {
                throw errorResponse;
            }
        });
    }

    isBankingIdentificationFormValid() {
        return !this.bankingIdentificationForm.invalid;
    }
}
