import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import {
    PagedResponse, SearchService, UserAccountStatus, WorkflowService, Utils, RiskTier, UserAccount, Member, MemberStatus
} from 'projects/services/src/public-api';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pt-user-account-search-results',
    templateUrl: './user-account-search-results.component.html',
    styleUrls: ['./user-account-search-results.component.scss']
})
export class UserAccountSearchResultsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    UserAccountStatus = UserAccountStatus;
    RiskTier = RiskTier;
    Utils = Utils;

    @Input() searchTerm: string;

    isLoadingResults = true;
    resultsLength = 0;
    subscription: any;
    pageTracking: PageTracking;
    defaultSize = 10;
    displayedColumns = ['name', 'status', 'member_status', 'created'];
    userAccounts : UserAccount[] = [];

    filterEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private workflowService: WorkflowService,
                private cdr: ChangeDetectorRef,
                private searchService: SearchService) { }

    ngOnInit() {
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'lastName', 'asc', this.defaultSize);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.searchTerm && this.pageTracking) {
            this.filterEvent.emit();
        }
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                return this.searchService.searchUserAccountsBySearchTerm(this.searchTerm, this.paginator.pageIndex,
                    this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<UserAccount>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((userAccounts : UserAccount[]) => {
            this.userAccounts = userAccounts;
        });
    }

    memberDetails(event: any, member: Member) {
        if (event.target && event.target.tagName !== 'I') {
            if (member.status === MemberStatus.UNDER_REVIEW) {
                this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_member_v2', 'onboard_pt_member'], `memberId_eq_${member.id}`);
            } else {
                this.router.navigate(['/administration/member/', member.id], { queryParams: { _activeTab: 'users' } });
            }
        }
    }
}
