import { Component, OnInit } from '@angular/core';
import { AuthService, MemberFunction} from 'projects/services/src/public-api';

@Component({
    selector: 'pc-member-users',
    templateUrl: './member-users.component.html'
})
export class MemberUsersComponent implements OnInit {

    memberId: string;
    memberFunctions: MemberFunction[] = [];
    changeTrigger = 1;


    constructor(private authService: AuthService) { }

    ngOnInit() {
        this.memberId = this.authService.getProfile().memberId;
        this.memberFunctions = this.authService.getProfile().memberFunctions;
    }

    onUserChanged() {
        this.changeTrigger = Math.random();
    }
}
