import { Component, Input } from '@angular/core';

@Component({
    selector: 'pc-expense-line',
    templateUrl: './expense-row.component.html'
})
export class ExpensesRowComponent {

    @Input() expense: any;

}
