import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'pc-search-text-input',
    templateUrl: './search-text-input.component.html',
    styleUrls: ['./search-text-input.component.scss']
})
export class SearchTextInputComponent {

    @Input() placeholder = '';
    @Input() searchClass = 'search';
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;

    @Output() search: EventEmitter<string> = new EventEmitter<string>();

    onSearch() {
        this.search.emit(this.formGroup.controls[this.formCtrl].value);
    }

    onBlur() {
        this.search.emit(this.formGroup.controls[this.formCtrl].value);
    }

    isSearchTermSet() {
        return this.formGroup.controls[this.formCtrl].value;
    }

    onIconClick(event: any) {
        if (this.formGroup.controls[this.formCtrl].value) {
            this.formGroup.controls[this.formCtrl].setValue('');
            this.search.emit('');
        }
        event.stopPropagation();
    }

    onInputChanged(event: any) {
        if (event.keyCode === 220 || event.keyCode === 222) {
            event.preventDefault();
            return;
        }
    }

}
