<div class="dashboard-list">
    <div class="row">
        <div class="col-8">
            <div class="dashboard-info-icon">
                <img class="dashboard-icon" src="assets/images/task2-icon.svg" alt="">
                <a [routerLink]="['/communication/notifications']"
                   [routerLinkActive]="['router-link-active']">Open Items</a>
            </div>
        </div>
        <div class="col-4 d-flex align-items-center justify-content-end row">
            <div class="dashboard-list-summary col-12" *ngIf="tasks?.length"><strong>{{tasks?.length}}<span *ngIf="taskCount"> of {{taskCount}}</span></strong> Open Items</div>
            <div class="dashboard-list-summary col-12 priority mt-1" *ngIf="myTaskCount">
                <a [routerLink]="['/communication/notifications']" [queryParams]="{assignee: assignee}" [routerLinkActive]="['router-link-active']">{{myTaskCount}} Assigned to Me</a>
            </div>
        </div>
    </div>
    <hr class="separator-thick">
    <div class="row" *ngFor="let task of tasks">
        <div class="col-12">
            <div class="workGadgetIcon">
                <img class="task-icon" src="assets/images/pencil-icon.svg" alt="Task">
            </div>
            <div class="taskName">
                <a class="dashboard-list-a" (click)="loadTask(task.id)">
                    {{task.name}}
                </a>
                <div class="task-list-summary">
                    {{task.created | date: 'medium'}}
                </div>
                <small><br></small>
            </div>
        </div>
    </div>
</div>
