import { Component, Input, ElementRef, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'pc-email-input',
    templateUrl: './email-input.component.html'
})
export class EmailInputComponent {

    @Input() required: boolean;
    @Input() label: string;
    @Input() placeholder = '';
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() class = '';

    @Output() changed: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('inputField')inputField: ElementRef;

    focus() {
        this.inputField.nativeElement.focus();
    }

    onInputChanged(event: any) {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    }

    emailChanged() {
        this.changed.emit(this.formGroup.controls[this.formCtrl].value);
    }

}
