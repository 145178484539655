import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {
    Address, AuthService, Consumer, CorporateEntity, CustomValidators, Member, MemberAccount, MemberAccountService, MemberAccountStatus, MemberSource, MemberType, MerchantAccount,
    MerchantAccountConfig, MerchantAccountService, MerchantAccountSource, MerchantAccountStatus, MerchantAchInfo, MerchantFormService, MerchantNotificationRequest, PagedResponse,
    SupportedTransactionType, Task, TransactionType, Utils, WorkflowService
} from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent, ErrorType } from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { Observable } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { MerchantSearcherComponent } from 'projects/components/src/lib/input';

@Component({
    selector: 'pt-add-merchant-modal',
    templateUrl: './add-merchant-modal.component.html',
    styleUrls: ['./add-merchant-modal.component.scss']
})
export class AddMerchantModalComponent extends BaseModalComponent<AddMerchantModalComponent> implements OnInit {

    TransactionType = TransactionType;
    SupportedTransactionType = SupportedTransactionType;
    MemberType = MemberType;

    readonly ACCOUNTING_LINKED_WARNING = {
        message: 'Your accounting system is linked.  Confia merchants will not be linked to your accounting system.  Please click on \'Add Accounting Merchant\' to link merchants from your accounting system.',
        type: ErrorType.WARN
    };

    memberId: string;
    jurisdiction: string;
    merchantAccount: MemberAccount;
    merchantForm: UntypedFormGroup;
    merchantMemberAccountForm: UntypedFormGroup;
    newMerchant = false;
    showAchInfo = false;
    existingAccountConfig: MerchantAccountConfig;
    merchantAccountExistsError: { message: string, type: ErrorType };
    merchantAccountVerificationInProgress = false;
    file: File;
    isAuthority = false;
    shared = true;
    isAccountingLinked = false;
    merchantTypeSelected = false;
    merchantMemberType = MemberType.BUSINESS_MERCHANT;
    buttonOrientation: Observable<string>;
    searchLabel = 'Search Business Merchants';

    @ViewChild('searcherCtrl') searcherCtrlField: MerchantSearcherComponent;

    constructor(private authService: AuthService,
        private merchantFormService: MerchantFormService,
        private merchantAccountService: MerchantAccountService,
        private memberAccountService: MemberAccountService,
        private workflowService: WorkflowService,
        private notifier: NotificationService,
        protected breakpointObserver: BreakpointObserver,
        dialogRef: MatDialogRef<AddMerchantModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.shared = data.shared;
        this.memberId = data.memberId;
        this.isAccountingLinked = data.accountingLinked;
        this.jurisdiction = data.jurisdiction;
    }

    ngOnInit() {
        this.buttonOrientation = this.breakpointObserver.observe('(min-width: 799px)').pipe(
            map((matches: BreakpointState) => {
                return matches.matches ? 'false' : 'true';
            })
        );
        this.isAuthority = this.authService.isAuthority();
        this.isMerchantFormValid = this.isMerchantFormValid.bind(this);
        this.onBusinessMerchantSubmit = this.onBusinessMerchantSubmit.bind(this);
        this.onIndividualMerchantSubmit = this.onIndividualMerchantSubmit.bind(this);
    }

    onBusinessMerchantSubmit(reset: any) {
        if (this.newMerchant) {
            const merchantMember = new Member();
            merchantMember.source = MemberSource.CONFIA;
            merchantMember.name = this.merchantForm.controls['merchantCtrl'].value.member.name;
            merchantMember.briefDescription = this.merchantMemberAccountForm.controls['briefDescriptionCtrl'].value;
            const orgAddress = this.merchantFormService.getAddress(this.merchantMemberAccountForm);
            const merchantAccountConfig = new MerchantAccountConfig();
            // When authority added new merchant in members account section which is public
            merchantAccountConfig.shared = this.isAuthority ? true : this.shared;
            merchantAccountConfig.merchantAccountType = this.merchantForm.controls['allowedTransactionsCtrl'].value;
            merchantAccountConfig.source = MerchantAccountSource.CONFIA;
            const merchantAccount = new MerchantAccount();
            const merchantAchInfo = new MerchantAchInfo();
            merchantAccount.merchantAccountConfig = merchantAccountConfig;
            if (merchantAccountConfig.merchantAccountType !== SupportedTransactionType.INCOMING) {
                merchantAchInfo.accountHolder = this.merchantForm.controls['achAccountHolderCtrl'].value;
                merchantAchInfo.accountNumber = this.merchantForm.controls['achAccountNumberCtrl'].value;
                merchantAchInfo.routingNumber = this.merchantForm.controls['achRoutingNumberCtrl'].value;
                merchantAchInfo.wireRoutingNumber = this.merchantForm.controls['wireRoutingNumberCtrl'].value;
            }
            const licenseHolder = this.merchantMemberAccountForm.controls['licenseHolderCtrl'].value || '';
            const contactInfo = this.merchantFormService.getContactInfo(this.merchantMemberAccountForm);
            const registerBusinessMerchant = {
                contactInfo,
                merchantAddress: orgAddress,
                merchantMember: merchantMember,
                merchantAccount,
                merchantAchInfo,
                licenseHolder
            };

            this.workflowService.registerBusinessMerchant(registerBusinessMerchant, this.file).subscribe((savedMerchantAccountConfig: MerchantAccountConfig) => {
                if (this.isAuthority) {
                    this.workflowService.findTaskByTaskDefinitionsAndProcessVariable(['user_review_business_merchant'], 'merchantAccountConfigId', savedMerchantAccountConfig.id).subscribe((taskQueryResponse: PagedResponse<Task>) => {
                        if (this.memberId) {
                            this.existingAccountConfig = savedMerchantAccountConfig;
                            this.saveExistingMerchantAccount();
                        }
                        this.close(true);
                        const taskId = taskQueryResponse.content[0].id;
                        this.workflowService.loadTask(taskId);
                    });
                } else {
                    this.close(true);
                    this.notifier.showSuccess(`Your request to add ${registerBusinessMerchant.merchantMember.name} as an external business merchant has been submitted.`);
                }
            }, (error: any) => {
                reset();
                if (error.error instanceof ProgressEvent && error.error.loaded === 0 && error.error.type === 'error') {
                    // Client-side error
                    this.notifier.showError('Document upload was unsuccessful. Please check your connection and try again.');
                } else {
                    // Server-side error
                    throw error;
                }
            });
        } else {
            this.saveExistingMerchantAccount();
        }
    }

    onIndividualMerchantSubmit(reset: any) {
        if (!this.newMerchant) {
            this.saveExistingMerchantAccount();
            return;
        }
        const merchantAddress = this.merchantFormService.getAddress(this.merchantMemberAccountForm);
        const merchantAchInfo = new MerchantAchInfo();
        if (this.merchantForm.controls['allowedTransactionsCtrl'].value !== SupportedTransactionType.INCOMING) {
            merchantAchInfo.accountHolder = this.merchantForm.controls['achAccountHolderCtrl'].value;
            merchantAchInfo.accountNumber = this.merchantForm.controls['achAccountNumberCtrl'].value;
            merchantAchInfo.routingNumber = this.merchantForm.controls['achRoutingNumberCtrl'].value;
            merchantAchInfo.wireRoutingNumber = this.merchantForm.controls['wireRoutingNumberCtrl'].value;
        }
        const registerIndividualMerchant = {
            firstName: this.merchantForm.controls['merchantFirstNameCtrl'].value,
            middleName: this.merchantForm.controls['merchantMiddleNameCtrl'].value,
            lastName: this.merchantForm.controls['merchantLastNameCtrl'].value,
            birthDate: this.merchantForm.controls['birthDateCtrl'].value ? this.merchantForm.controls['birthDateCtrl'].value : null,
            phoneNumber: this.merchantMemberAccountForm.controls['ownerPhoneCtrl'].value,
            email: this.merchantMemberAccountForm.controls['ownerEmailCtrl'].value,
            relationshipToCompany: this.merchantMemberAccountForm.controls['briefDescriptionCtrl'].value,
            ssn: this.merchantForm.controls['ssnCtrl'].value,
            merchantAccountType: this.merchantForm.controls['allowedTransactionsCtrl'].value,
            shared: this.isAuthority ? true : this.shared,
            merchantAddress,
            merchantAchInfo
        };
        this.workflowService.registerIndividualMerchant(registerIndividualMerchant, this.file).subscribe((savedMerchantAccountConfig: MerchantAccountConfig) => {
            if (this.isAuthority) {
                this.workflowService.findTaskByTaskDefinitionsAndProcessVariable(['user_review_individual_merchant'], 'merchantAccountConfigId', savedMerchantAccountConfig.id).subscribe((taskQueryResponse: PagedResponse<Task>) => {
                    if (this.memberId) {
                        this.existingAccountConfig = savedMerchantAccountConfig;
                        this.saveExistingMerchantAccount();
                    }
                    this.close(true);
                    const taskId = taskQueryResponse.content[0].id;
                    this.workflowService.loadTask(taskId);
                });
            } else {
                this.close(true);
                this.notifier.showSuccess(`Your request to add ${registerIndividualMerchant.firstName} ${registerIndividualMerchant.middleName ? registerIndividualMerchant.middleName + ' ' : ''}${registerIndividualMerchant.lastName} as an external individual merchant has been submitted.`);
            }
        }, (error: any) => {
            reset();
            throw error;
        });
    }

    saveExistingMerchantAccount() {
        const merchantAccount = new MerchantAccount();
        merchantAccount.merchantAccountConfig = this.existingAccountConfig;
        merchantAccount.memberId = this.memberId;
        merchantAccount.status = this.existingAccountConfig.status;
        this.memberAccountService.loadMemberAccount(this.existingAccountConfig.merchantMemberAccount.id).subscribe((merchantMemberAccount: MemberAccount) => {
            merchantAccount.merchantMemberAccount = merchantMemberAccount;
            this.merchantAccountService.linkMerchantAndMember(merchantAccount).subscribe((response: MerchantAccount) => {
                this.close(true);
                if (response.status === MerchantAccountStatus.ACTIVE) {
                    let merchantNotificationRequest = new MerchantNotificationRequest();
                    merchantNotificationRequest.memberId = this.memberId;
                    merchantNotificationRequest.merchantAccountId = merchantMemberAccount.id;
                    if (this.isAuthority) {
                        this.workflowService.notifyExternalMerchantUpdate(merchantNotificationRequest).subscribe();
                    }
                    this.notifier.showSuccess(`${merchantMemberAccount.member.name} has been added as an external merchant.`);
                } else if (response.status === MerchantAccountStatus.PENDING) {
                    this.notifier.showSuccess(`${merchantMemberAccount.member.name} is currently under review. Access will be given when the merchant is approved.`);
                }
            });
        });
    }

    isMerchantFormValid() {
        return this.merchantForm && this.merchantForm.valid &&
            (!this.newMerchant || (this.newMerchant && this.merchantMemberAccountForm.valid))
            && !this.merchantAccountExistsError
            && !this.merchantAccountVerificationInProgress;
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    updateBankAccountValidity(_event: any) {
        this.merchantFormService.validateAndUpdateMerchantForm(this.merchantForm);
    }

    setMerchant(merchant: MemberAccount) {
        this.merchantAccount = merchant;
        this.newMerchant = this.merchantAccount.status === MemberAccountStatus.NEW;
        this.merchantForm.controls['merchantCtrl'].setValue(this.merchantAccount);
        this.merchantAccountExistsError = null;
        this.merchantAccountVerificationInProgress = false;
        this.resetForm();
        if (!this.newMerchant && this.merchantAccount.memberId) {
            // we need to retrieve the merchant config for this merchant and do some stuff
            this.merchantAccountService.getMerchantAccountConfigByMerchantMemberId(this.merchantAccount.memberId).subscribe((merchantAccountConfig: MerchantAccountConfig) => {
                this.existingAccountConfig = merchantAccountConfig;
                this.merchantForm.controls['merchantIdCtrl'].setValue(this.merchantAccount.memberId);
                this.showAchInfo = true;
                this.merchantForm.controls['allowedTransactionsCtrl'].setValue(merchantAccountConfig.merchantAccountType);
                this.merchantAccountService.getMerchantAccountConfigAddress(merchantAccountConfig.id).subscribe((address: Address) => {
                    if (address) {
                        this.merchantMemberAccountForm.controls['streetAddressOneCtrl'].setValue(address.streetAddressOne);
                        this.merchantMemberAccountForm.controls['cityCtrl'].setValue(address.city);
                        this.merchantMemberAccountForm.controls['stateProvinceCtrl'].setValue(address.stateProvince);
                        this.merchantMemberAccountForm.controls['countryCtrl'].setValue(address.country);
                        this.merchantMemberAccountForm.controls['zipPostalCodeCtrl'].setValue(address.zipPostalCode);
                    }
                    this.merchantMemberAccountForm.disable();
                });
                this.setIndividualMerchantMemberDetails(merchantAccountConfig.merchantMemberAccount.member);
                if (this.memberId) {
                    this.verifyMerchantNotAttached();
                } else {
                    this.merchantAccountExistsError = {
                        message: `This ${this.merchantMemberType === MemberType.BUSINESS_MERCHANT ? 'business' : 'individual'} merchant has already been registered.`,
                        type: ErrorType.ERROR
                    };
                }
            });
        } else if (this.newMerchant) {
            this.file = null;
            this.merchantForm.controls['merchantIdCtrl'].setValue('');
            this.merchantForm.controls['allowedTransactionsCtrl'].setValue(SupportedTransactionType.BOTH);
            this.existingAccountConfig = null;
            this.showAchInfo = true;
            this.merchantMemberAccountForm.enable();
            this.merchantMemberAccountForm.controls['countryCtrl'].setValue('United States');
            this.merchantMemberAccountForm.controls['countryCtrl'].disable();
            this.merchantMemberAccountForm.markAsPristine();
            this.merchantForm.markAsPristine();
        } else {
            this.existingAccountConfig = null;
            this.showAchInfo = false;
            this.merchantForm.reset();
            this.merchantMemberAccountForm.reset();
            this.merchantMemberAccountForm.enable();
            this.merchantForm.controls['allowedTransactionsCtrl'].setValue(SupportedTransactionType.BOTH);
            this.merchantMemberAccountForm.controls['countryCtrl'].setValue('United States');
            this.merchantMemberAccountForm.controls['countryCtrl'].disable();
            this.merchantMemberAccountForm.markAsPristine();
            this.merchantForm.markAsPristine();
        }
    }

    resetForm() {
        this.merchantForm.controls['achAccountHolderCtrl'].setValue('');
        this.merchantForm.controls['achAccountNumberCtrl'].setValue('');
        this.merchantForm.controls['achRoutingNumberCtrl'].setValue('');
        this.merchantForm.controls['wireRoutingNumberCtrl'].setValue('');
        this.merchantForm.controls['merchantFirstNameCtrl'].setValue('');
        this.merchantForm.controls['merchantMiddleNameCtrl'].setValue('');
        this.merchantForm.controls['merchantLastNameCtrl'].setValue('');
        this.merchantFormService.validateAndUpdateMerchantForm(this.merchantForm);
        this.merchantMemberAccountForm.controls['ownerPhoneCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['ownerEmailCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['ownerNameCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['licenseHolderCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['streetAddressOneCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['cityCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['stateProvinceCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['zipPostalCodeCtrl'].setValue('');
        this.merchantMemberAccountForm.controls['briefDescriptionCtrl'].setValue('');
        this.merchantMemberAccountForm.markAsPristine();
        this.merchantForm.markAsPristine();
    }

    verifyMerchantNotAttached() {
        if (this.existingAccountConfig) {
            this.merchantAccountExistsError = null;
            this.merchantAccountVerificationInProgress = true;
            this.merchantAccountService.getAllMerchantAccountsByMerchantMemberAccountIdAndMemberId(this.existingAccountConfig.merchantMemberAccount.id, this.memberId).subscribe((merchantAccounts: PagedResponse<MerchantAccount>) => {
                if (merchantAccounts.totalElements > 0) {
                    this.merchantAccountExistsError = {
                        message: 'This merchant has already been linked.',
                        type: ErrorType.ERROR
                    };
                }
                this.merchantAccountVerificationInProgress = false;
            });
        }
    }

    selectFile(event: any) {
        if (event.target.files && event.target.files.length) {
            for (const file of event.target.files) {
                Utils.validateFile(file, event);
            }
            this.file = event.target.files[0];
        }
        event.target.value = '';
    }

    deleteDocument() {
        this.file = null;
    }

    onTypeChange(type: MemberType) {
        this.merchantMemberType = type;
    }

    selectMerchantType() {
        this.merchantTypeSelected = true;
        if (this.merchantMemberType === MemberType.BUSINESS_MERCHANT) {
            this.searchLabel = 'Search Business Merchants';
            this.merchantForm = this.merchantFormService.initializeNewMerchantForm();
            this.merchantMemberAccountForm = this.merchantFormService.initializeNewMerchantMemberAccountForm(this.shared);
            setTimeout(() => {
                this.searcherCtrlField.focus();
            }, 500);
        } else {
            this.searchLabel = 'Search Individual Merchants';
            this.merchantForm = this.merchantFormService.initializeNewMerchantForm(null, MemberType.INDIVIDUAL_MERCHANT);
            this.merchantMemberAccountForm = this.merchantFormService.initializeNewMerchantMemberAccountForm(this.shared, null, MemberType.INDIVIDUAL_MERCHANT);
        }
        this.updateValidations();
    }

    updateValidations() {
        if (this.merchantMemberType === MemberType.BUSINESS_MERCHANT) {
            this.merchantForm.get('merchantFirstNameCtrl').setValidators(null);
            this.merchantForm.get('merchantLastNameCtrl').setValidators(null);
            this.merchantForm.get('merchantFirstNameCtrl').updateValueAndValidity();
            this.merchantForm.get('merchantLastNameCtrl').updateValueAndValidity();
        } else {
            this.merchantForm.get('merchantNameCtrl').setValidators(null);
            this.merchantMemberAccountForm.get('streetAddressOneCtrl').setValidators([Validators.required, Validators.minLength(3)]);
            this.merchantMemberAccountForm.get('cityCtrl').setValidators(null);
            this.merchantMemberAccountForm.get('stateProvinceCtrl').setValidators([Validators.required, Validators.minLength(2)]);
            this.merchantMemberAccountForm.get('countryCtrl').setValidators(null);
            this.merchantMemberAccountForm.get('zipPostalCodeCtrl').setValidators([Validators.required, CustomValidators.zipCode]);
            this.merchantForm.get('merchantNameCtrl').updateValueAndValidity();
            this.merchantMemberAccountForm.get('streetAddressOneCtrl').updateValueAndValidity();
            this.merchantMemberAccountForm.get('cityCtrl').updateValueAndValidity();
            this.merchantMemberAccountForm.get('stateProvinceCtrl').updateValueAndValidity();
            this.merchantMemberAccountForm.get('countryCtrl').updateValueAndValidity();
            this.merchantMemberAccountForm.get('zipPostalCodeCtrl').updateValueAndValidity();
        }
    }

    setIndividualMerchantMemberDetails(merchantMember: Member) {
        const individualMerchantMember = (merchantMember as Consumer);
        this.merchantForm.controls['merchantFirstNameCtrl'].setValue(individualMerchantMember.firstName);
        this.merchantForm.controls['merchantMiddleNameCtrl'].setValue(individualMerchantMember.middleName);
        this.merchantForm.controls['merchantLastNameCtrl'].setValue(individualMerchantMember.lastName);
        this.merchantForm.controls['merchantFirstNameCtrl'].updateValueAndValidity();
        this.merchantForm.controls['merchantMiddleNameCtrl'].updateValueAndValidity();
        this.merchantForm.controls['merchantLastNameCtrl'].updateValueAndValidity();
    }

    getDbaName(member: Member) {
        return (member as CorporateEntity).dbaName;
    }
}
