<div>
    <form *ngIf="withdrawalForm" [formGroup]="withdrawalForm">
        <div>
            <div class="modal-header">
                <div class="row no-gutters col-12">
                    <div class="col-1 pull-left"><img class="dashboard-icon" src="assets/images/account-2.svg" alt=""></div>
                    <div class="col-10">
                        <label class="form-title">Withdraw Funds from Account: {{memberAccount.accountName}}</label>
                        <div class="row">
                            <div class="row mt-1">
                                <div class="col-6 centered">Available Balance:<strong>&nbsp;{{memberAccount.wallet.availableBalance | currency}}</strong></div>
                                <div class="col-6 centered">Pending Funds:<strong>&nbsp;{{memberAccount.wallet.pendingBalance | currency}}</strong></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 pull-right"><i class="modal-close" (click)="close()">&times;</i></div>
                </div>
            </div>

            <div class="modal-content-medium">
                <div>
                    <pc-bank-account-selector [required]="true" [label]="'To'" [formCtrl]="'bankAccountIdCtrl'" [formGroup]="withdrawalForm" [bankAccounts]="bankAccounts">
                    </pc-bank-account-selector>

                    <pc-form-amount-input [required]="true" [label]="'Withdrawal Amount'" [formCtrl]="'amountCtrl'"
                        [formGroup]="withdrawalForm" [placeholder]="'0.00'" (change)="onCalculateTotal()">
                    </pc-form-amount-input>

                    <pc-form-date-picker [label]="'Withdrawal Date'" [formCtrl]="'transactionDateCtrl'" [formGroup]="withdrawalForm">
                    </pc-form-date-picker>

                    <pc-form-text-area [required]="false"  [label]="'Notes'" [formCtrl]="'notesCtrl'" [formGroup]="withdrawalForm">
                    </pc-form-text-area>
                </div>
            </div>

            <div class="modal-footer">
                <div class="bottom-total">
                    <strong class="form-title">Withdrawal Amount:  {{totalAmount | currency}}</strong>
                    <pc-error-message [error]="errorMessage" [width]="'full'"></pc-error-message>
                </div>
                <pc-submit-button [enabled]="isWithdrawalFormValid" [submit]="onSubmit" [text]="'Submit Withdrawal'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
