import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class HttpHeaderService {

    getAuthHeader(): any;

    getAuthHeader(...args: any[]): HttpHeaders {
        if (args.length === 1) {
            return new HttpHeaders().set('Authorization', args[0]);
        }
        if (localStorage.getItem('token')) {
            return new HttpHeaders().set('Authorization', `${localStorage.getItem('token')}`);
        }
        return new HttpHeaders();
    }

}
