import { Component, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import {
    SmartContractService, Contract, AuthService, WorkflowService, Task, Transaction, TransactionSubType, MemberService, Member
} from 'projects/services/src/public-api';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'pc-sc-my-contracts',
    templateUrl: './my-contracts.component.html',
    styleUrls: ['./my-contracts.component.scss']
})
export class MyContractsComponent implements AfterViewInit, OnDestroy {

    contracts: Contract[];

    resultsLength = 1;

    isLoadingResults = false;
    displayedColumns: string[] = ['name', 'created', 'amount', 'currentState', 'buyer', 'seller'];
    subscription: any;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private smartContractService: SmartContractService,
                private authService: AuthService,
                private router: Router,
                private workflowService: WorkflowService,
                private memberService: MemberService) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.smartContractService.getMyContracts(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((data: any) => {
                this.isLoadingResults = false;
                this.resultsLength = data.content.length || 0;
                return data.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((data) => {
            this.contracts = data;
            this.contracts.forEach((contract) => {
                this.memberService.loadMember(contract.buyerId).subscribe((buyer: Member) => {
                    contract.buyer = buyer;
                });
                this.memberService.loadMember(contract.sellerId).subscribe((seller: Member) => {
                    contract.seller = seller;
                });
                const transaction = new Transaction();
                transaction.payorMemberId = contract.buyerId;
                transaction.recipientMemberId = contract.sellerId;
                // this is odd...
                transaction.subType = TransactionSubType.PAYMENT;
                transaction.totalAmount = contract.contractTemplate.purchaseOrder.totalAmount;
                contract.transaction = transaction;
            });
        });
    }

    viewContract(contract: Contract) {
        if ((contract.buyerId === this.authService.getProfile().memberId && contract.currentState === 'BUYER_REVIEW') ||
            (contract.sellerId === this.authService.getProfile().memberId && contract.currentState === 'SELLER_REVIEW' ||
            contract.currentState === 'EXECUTING')) {
            this.workflowService.getTaskByProcessDefinitionWithVariable([''], 'contractId', contract.id).subscribe((task: Task) => {
                if (task) {
                    this.workflowService.loadTask(task.id);
                } else {
                    this.router.navigate(['/smart_contracts/shared_template/', contract.contractTemplate.id]);
                }
            });
        } else {
            this.router.navigate(['/smart_contracts/shared_template/', contract.contractTemplate.id]);
        }
    }
}
