import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { PartnerKey } from './models/partner-key';
import { KeyRequest } from './models/key-request';

@Injectable({
    providedIn: 'root'
})
export class PartnerSettingsService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.PartnerAPIEndpoint + '/api/';
    }

    updatePartnerSettings(id: string, partnerSetting: any) {
        return this.http.put(`${this.baseUrl}setting/${id}`, partnerSetting);
    }

    getByMemberId(memberId: string) {
        const params = `?memberId=${memberId}`;
        return this.http.get(`${this.baseUrl}setting${params}`);
    }

    loadKeysByMemberId(memberId: string): Observable<any> {
        const params = `?memberId=${memberId}`;
        return this.http.get(`${this.baseUrl}key${params}`);
    }

    updateKey(key: PartnerKey) {
        return this.http.delete(`${this.baseUrl}key/${key.id}`);
    }

    createKey(keyReq: KeyRequest) {
        return this.http.post(`${this.baseUrl}key?memberId=${keyReq.memberId}`, null);
    }
}
