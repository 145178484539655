import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableUtils } from 'projects/components/src/lib/table-utils.service';
import { AuthService, ReferencedEntityType } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-audit',
    templateUrl: './member-audit.component.html'
})
export class MemberAuditComponent implements OnInit {

    @Input() memberId = '';

    action = '';
    auditEntityType = ReferencedEntityType.ALL;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private authService: AuthService) {
    }

    ngOnInit() {
        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['entityType'] || queryParams['action']) {
            this.auditEntityType = queryParams['entityType'];
            this.action = queryParams['action'];
        } else {
            this.auditEntityType = ReferencedEntityType.ALL;
            this.action = '';
            this.updateQueryParams(true);
        }

        this.route.queryParams.subscribe((params: any) => {
            this.auditEntityType = params['entityType'] || '';
            this.action = params['action'] || '';
        });
    }

    isAuthority() {
        return this.authService.isAuthorityOrReviewer();
    }

    activeListChanged(activeListChanged: { memberId: string, action: string, auditEntityType: ReferencedEntityType }) {
        this.auditEntityType = activeListChanged.auditEntityType;
        this.action = activeListChanged.action;
        this.updateQueryParams(false);
    }

    updateQueryParams(replace: boolean) {
        const queryParams = { entityType: this.auditEntityType, action: this.action, page: 1 };
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }
}
