import { Component, Inject, OnInit } from '@angular/core';
import { AccountingPartyType, ExternalAccountingId, MerchantAccount, MerchantAccountService } from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-edit-merchant-accounting-id-modal',
    templateUrl: './edit-merchant-accounting-id-modal.component.html'
})
export class EditMerchantAccountingIdModalComponent extends BaseModalComponent<EditMerchantAccountingIdModalComponent> implements OnInit {

    AccountingPartyType = AccountingPartyType;

    merchantAccount: MerchantAccount;

    supplierIds: ExternalAccountingId[] = [];
    customerIds: ExternalAccountingId[] = [];

    constructor(private merchantAccountService: MerchantAccountService,
                private notifier: NotificationService,
                dialogRef: MatDialogRef<EditMerchantAccountingIdModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.merchantAccount = data.merchantAccount;
        this.merchantAccount.accountingExternalIds.forEach((externalAccountingId) => {
            if (externalAccountingId.accountingPartyType === AccountingPartyType.SUPPLIER) {
                this.supplierIds.push(new ExternalAccountingId(externalAccountingId.accountingExternalId, AccountingPartyType.SUPPLIER));
            } else {
                this.customerIds.push(new ExternalAccountingId(externalAccountingId.accountingExternalId, AccountingPartyType.CUSTOMER));
            }
        });
        if (!this.supplierIds.length) {
            this.supplierIds.push(new ExternalAccountingId('', AccountingPartyType.SUPPLIER));
        }
        if (!this.customerIds.length) {
            this.customerIds.push(new ExternalAccountingId('', AccountingPartyType.CUSTOMER));
        }

    }

    ngOnInit() {
        this.onSubmit = this.onSubmit.bind(this);
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    onSubmit(reset: any) {
        let accountingIds: ExternalAccountingId[] = [];
        let supplierIdChanged = false;
        let customerIdChanged = false;
        this.supplierIds.forEach((supplierAccountingId) => {
            if (supplierAccountingId.accountingExternalId) {
                accountingIds.push(supplierAccountingId);
                supplierIdChanged ||= !this.merchantAccount.accountingExternalIds.find((existingAccountingIds) => {
                    return existingAccountingIds.accountingPartyType === AccountingPartyType.SUPPLIER && existingAccountingIds.accountingExternalId === supplierAccountingId.accountingExternalId;
                });
            } else {
                supplierIdChanged ||= !this.merchantAccount.accountingExternalIds.find((existingAccountingIds) => {
                    return existingAccountingIds.accountingPartyType === AccountingPartyType.SUPPLIER && existingAccountingIds.accountingExternalId === supplierAccountingId.accountingExternalId;
                });
            }
        });
        this.merchantAccount.accountingExternalIds.filter((externalAccountingId) => {
            return externalAccountingId.accountingPartyType === AccountingPartyType.SUPPLIER;
        }).forEach((externalAccountingId) => {
            supplierIdChanged ||= !accountingIds.find((currentAccountingIds) => {
                return currentAccountingIds.accountingPartyType === AccountingPartyType.SUPPLIER && currentAccountingIds.accountingExternalId === externalAccountingId.accountingExternalId;
            });
        });

        this.customerIds.forEach((customerAccountingId) => {
            if (customerAccountingId.accountingExternalId) {
                accountingIds.push(customerAccountingId);
                customerIdChanged ||= !this.merchantAccount.accountingExternalIds.find((existingAccountingIds) => {
                    return existingAccountingIds.accountingPartyType === AccountingPartyType.CUSTOMER && existingAccountingIds.accountingExternalId === customerAccountingId.accountingExternalId;
                });
            } else {
                customerIdChanged ||= !this.merchantAccount.accountingExternalIds.find((existingAccountingIds) => {
                    return existingAccountingIds.accountingPartyType === AccountingPartyType.CUSTOMER && existingAccountingIds.accountingExternalId === customerAccountingId.accountingExternalId;
                });
            }
        });

        this.merchantAccount.accountingExternalIds.filter((externalAccountingId) => {
            return externalAccountingId.accountingPartyType === AccountingPartyType.CUSTOMER;
        }).forEach((externalAccountingId) => {
            customerIdChanged ||= !accountingIds.find((currentAccountingIds) => {
                return currentAccountingIds.accountingPartyType === AccountingPartyType.CUSTOMER && currentAccountingIds.accountingExternalId === externalAccountingId.accountingExternalId;
            });
        });

        let message = '';
        const hasSupplierIds = accountingIds.filter((externalAccountingId) => {
            return externalAccountingId.accountingExternalId && externalAccountingId.accountingPartyType === AccountingPartyType.SUPPLIER;
        }).length > 0;
        const hasCustomerIds = accountingIds.filter((externalAccountingId) => {
            return externalAccountingId.accountingExternalId && externalAccountingId.accountingPartyType === AccountingPartyType.CUSTOMER;
        }).length > 0;
        if (supplierIdChanged || customerIdChanged) {
            message = `${this.merchantAccount.merchantMemberAccount.member.name} has been updated.<br>`;
            if (!hasSupplierIds && !hasCustomerIds) {
                message = `${this.merchantAccount.merchantMemberAccount.member.name} has been updated to unlink their accounting system ids.`;
            } else if (hasSupplierIds && !hasCustomerIds) {
                message += `<br>Supplier id(s) are now ${this.getAccountingIdList(this.supplierIds)}`;
            } else if (!hasSupplierIds && hasCustomerIds) {
                message += `<br>Customer id(s) are now ${this.getAccountingIdList(this.customerIds)}`;
            } else {
                message += `<br>Supplier id(s) are now ${this.getAccountingIdList(this.supplierIds)}<br>Customer id(s) are now ${this.getAccountingIdList(this.customerIds)}`;
            }
            this.merchantAccountService.updateMerchantAccount(this.merchantAccount.id, {accountingExternalIds: accountingIds}).subscribe(() => {
                this.notifier.showSuccessCloseRequired(message);
                this.close(true);
            }, (error) => {
                reset();
                throw error;
            });
        } else {
            this.close(false);
        }
    }

    getAccountingIdList(accountingIds: ExternalAccountingId[]) {
        let idList = '<ul>';
        accountingIds.forEach((externalAccountingId) => {
            if (externalAccountingId.accountingExternalId) {
                idList += `<li>${externalAccountingId.accountingExternalId}</li>`;
            }
        });
        idList += '</ul>';
        return idList;
    }

    addAccountingId(accountingPartyType: AccountingPartyType) {
        if (accountingPartyType === AccountingPartyType.SUPPLIER) {
            this.supplierIds.push(new ExternalAccountingId('', AccountingPartyType.SUPPLIER));
        } else {
            this.customerIds.push(new ExternalAccountingId('', AccountingPartyType.CUSTOMER));
        }
    }
}
