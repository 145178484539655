import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Utils } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-form-selector',
    templateUrl: './form-selector.component.html'
})
export class FormSelectorComponent implements OnInit {

    @Input() options: any[];
    @Input() idValue: string;
    @Input() displayValue: string;
    @Input() required: boolean;
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() defaultValue: string;
    @Input() class = '';

    @Output() selected: EventEmitter<any> = new EventEmitter<any>();

    safariFix: boolean;

    ngOnInit() {
        this.safariFix = Utils.isSafari() && !this.defaultValue;
    }

    onSelected() {
        this.selected.emit(this.formGroup.controls[this.formCtrl].value);
    }

    getValue(option: any) {
        if (this.idValue) {
            return option[this.idValue];
        }
        return option;
    }

    getDisplayValue(option: any) {
        if (this.displayValue) {
            return option[this.displayValue];
        }
        return option;
    }

}
