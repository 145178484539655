import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HomeModule } from '../home/home.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConsumerRoutingModule } from './consumer-routing.module';
import { YodleeOnboardingComponent } from './yodlee-onboarding/yodlee-onboarding.component';
import { SharedComponentsModule } from 'projects/components/src/lib/shared-components.module';
import { CustomScrollDirective } from './custom-scroll.directive';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { ConsumerOnboardingComponent } from './consumer-onboarding/consumer-onboarding.component';
import { RetailPaymentComponent } from './retail-payment/retail-payment.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ConsumerTermsComponent } from './consumer-terms/consumer-terms.component';

@NgModule({
    declarations: [
        ConsumerOnboardingComponent,
        RetailPaymentComponent,
        YodleeOnboardingComponent,
        CustomScrollDirective,
        ConsumerTermsComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        SharedComponentsModule,
        TranslateModule,
        HomeModule,
        NgbModule,
        ConsumerRoutingModule,
        MatStepperModule,
        MatIconModule,
        MatButtonToggleModule
    ]
})
export class ConsumerModule { }
