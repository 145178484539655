import { Component, Input, ViewChild, OnInit, AfterViewInit, OnDestroy, SimpleChanges, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { AccountActivity, AuthService, MemberType, PagedResponse, ReportsService } from 'projects/services/src/public-api';
import { catchError, map, merge, startWith, switchMap, of as observableOf } from 'rxjs';

@Component({
    selector: 'pt-account-activity-details',
    templateUrl: './account-activity-details.component.html'
})
export class AccountActivityDetailsComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    @Input() memberId: string;
    @Input() memberAccountId: string;
    @Input() changeTrigger: number;
    @Input() readOnly = false;

    accountActivityList: AccountActivity[] = [];
    isLoadingResults = true;
    resultsLength = 0;
    subscription: any;
    pageTracking: PageTracking;
    filterEvent: EventEmitter<null> = new EventEmitter<null>();

    displayedColumns: string[] = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private reportsService: ReportsService,
                private route: ActivatedRoute,
                private authService: AuthService,
                private cdr: ChangeDetectorRef,
                private router: Router) { }

    ngOnInit() {
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'created', 'desc', 100);
        this.loadDisplayColumns();
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes?.changeTrigger && !changes?.changeTrigger.firstChange)) {
            this.filterEvent.emit();
        }
    }

    addTableLoadListener() {
        this.subscription = merge(this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.reportsService.getAccountActivityListing(this.memberId, this.memberAccountId, this.paginator.pageIndex, this.paginator.pageSize, 'created', 'desc');
            }),
            map((response: PagedResponse<AccountActivity>) => {
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((accountActivityList: AccountActivity[]) => {
            this.accountActivityList = accountActivityList;
            this.isLoadingResults = false;
            UIUtils.scrollDashboardToTop();
        });
    }

    loadDisplayColumns() {
        if (this.authService.getProfile().memberType ===  MemberType.AUTHORITY) {
            this.displayedColumns = ['date', 'description', 'withdrawal', 'deposit', 'pt_score', 'balance'];
        } else {
            this.displayedColumns = ['date', 'description', 'withdrawal', 'deposit', 'balance'];
        }
    }
}
