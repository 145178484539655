import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { RecordsService, PackageSummary, PagedResponse } from 'projects/services/src/public-api';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { catchError, map, merge, of as observableOf, startWith, switchMap } from 'rxjs';

@Component({
    selector: 'pc-member-metrc-package-summary',
    templateUrl: './member-metrc-package-summary.component.html',
    styleUrls: ['./member-metrc-package-summary.component.scss']
})
export class MemberMetrcPackageSummaryComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @Input() memberId: string;
    @Input() startDate = '';
    @Input() endDate = '';

    packageSummaries: PackageSummary[] = [];
    displayedColumns = ['manifest#', 'package#', 'productType', 'quantity', 'quantityUnits', 'transfer', 'transferDate', 'sender', 'receiver', 'value'];
    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    subscription: any;
    pageTracking: PageTracking;
    isLoadingResults = true;
    resultsLength = 0;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private recordsService: RecordsService,
        private route: ActivatedRoute,
        private router: Router,
        private cdr: ChangeDetectorRef) { }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(change: SimpleChanges): void {
        if ((change.startDate && !change.startDate.firstChange) || (change.endDate && !change.endDate.firstChange)) {
            this.refreshEvent.emit();
        }
    }

    ngOnInit() {
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router);
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.recordsService.findPackageSummaries(this.memberId, this.startDate, this.endDate, this.paginator.pageIndex, this.paginator.pageSize);

            }),
            map((response: PagedResponse<PackageSummary>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((packageSummaries: PackageSummary[]) => {
            this.packageSummaries = packageSummaries;
            UIUtils.scrollDashboardToTop();
        });
    }
}