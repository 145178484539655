import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators, AuthService, CommentService, CommentType, WorkflowService } from 'projects/services/src/public-api';
import { BaseModalComponent } from '../base-modal.component';

@Component({
    selector: 'pc-new-note-modal',
    templateUrl: './new-note-modal.component.html'
})
export class NewNoteModalComponent extends BaseModalComponent<NewNoteModalComponent> implements OnInit {

    memberId: string;
    userId: string;
    commentForm: UntypedFormGroup;
    type: CommentType;
    processInstanceId: string;
    taskId: string;

    constructor(private authService: AuthService,
                private commentService: CommentService,
                private workflowService: WorkflowService,
                private formbuilder: UntypedFormBuilder,
                dialogRef: MatDialogRef<NewNoteModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberId = data.memberId;
        this.userId = data.userId;
        this.type = data.type;
        this.processInstanceId = data.processInstanceId;
        this.taskId = data.taskId;
    }

    ngOnInit() {
        this.onSubmit = this.onSubmit.bind(this);
        this.isEnabled = this.isEnabled.bind(this);
        let commentLength = 65534;
        if (this.type === CommentType.WORKFLOW) {
            commentLength = 3500;
        }
        this.commentForm = this.formbuilder.group(
            {
                commentCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.noLeadingOrTrailingWhitespace, Validators.maxLength(commentLength)])
            });
    }

    close(notifyUser?: boolean) {
        super.close(notifyUser);
    }

    onSubmit() {
        const note: any = {
            comment: this.commentForm.controls['commentCtrl'].value,
            authorName: this.authService.getProfile().name,
            authorId: this.authService.getProfile().userId
        };
        if (this.type !== CommentType.WORKFLOW) {
            note.memberId = this.memberId;
            note.userId = this.userId;
            note.type = (this.type === CommentType.INTERNAL ? CommentType.INTERNAL : CommentType.QUESTION);
            note.authoritySubmitted = this.authService.isAuthority();
        }
        if (this.type === CommentType.WORKFLOW) {
            this.workflowService.addWorkflowComment(this.processInstanceId, this.taskId, note).subscribe(() => {
                this.close(true);
            });
        } else if (this.type === CommentType.INTERNAL) {
            this.commentService.saveComment(note).subscribe(() => {
                this.close(true);
            });
        } else {
            this.workflowService.recordNote(note).subscribe(() => {
                this.close(!this.authService.isAuthority());
            });
        }
    }

    getType() {
        if (this.type === CommentType.QUESTION) {
            return 'Question';
        } else if (this.type === CommentType.USER) {
            return 'Response';
        }
        return 'Note';
    }

    isEnabled() {
        return this.commentForm.valid;
    }
}
