<fieldset class="form-group" *ngIf="autoCompleteControl">
    <div class="row">
        <div class="col-12" [formGroup]="formGroup">
            <label *ngIf="displayLabel" class="form-label">{{ label | translate }}</label>
            <div [formGroup]="formGroup" class="inputGroup" [ngClass]="class">
                <input #memberAccountSelect [formControl]="autoCompleteControl" type="text" [placeholder]="placeholder" [ngClass]="class"
                    class="form-control form-input-editable min-width" [matAutocomplete]="auto" (keydown)="onInputChanged($event)" (click)="$event.target.select()">
                <i class="fa" [ngClass]="selected() ? 'fa-times-circle-o' : 'fa-chevron-down'" (click)="onIconClick($event)"></i>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
                    <mat-option [ngClass]="searching ? 'hidden' : 'member-entry'" *ngFor="let memberAccount of memberAccountAutoComplete | async;" [value]="memberAccount.member.name"
                                (onSelectionChange)="memberAccountChanged(memberAccount)">
                        <div class="row">
                            <div class="col-12">
                                <img *ngIf="memberAccount.accountType === MemberAccountType.CONSUMER" class="member-type-icon" src="assets/images/profile-icon.svg" title="Individual">
                                {{ getAccountName(memberAccount) }}
                            </div>
                        </div>
                        <div *ngIf="memberAccount?.address" class="row address-info">
                            <div class="col-7 text-truncate">{{ (memberAccount.address.streetAddressOne || "") + " " +  (memberAccount.address.streetAddressTwo || "")}}</div>
                            <div class="col-4 text-start text-truncate">{{ (memberAccount.address.city ? memberAccount.address.city + ", " : "") + (memberAccount.address.stateProvince || "") }}</div>
                            <div class="col-1 text-end ps-1">{{ (memberAccount.address.country || "") }}</div>
                        </div>
                    </mat-option>
                    <mat-option *ngIf="searching">
                        <i class="fa fa-spin fa-spinner"></i>Searching
                    </mat-option>
                    <mat-option *ngIf="!searching && totalResults > searchLimit" [disabled]="true">
                        There are {{totalResults - searchLimit}} additional results - please refine your search.
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>
</fieldset>
