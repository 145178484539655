import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
    Configuration, KeyParty, KycSearchService, PagedResponse, KycSearchParams, OnboardSearchParamsBuilder, SearchResult
} from 'projects/services/src/public-api';

@Component({
    selector: 'pc-kyc-search-button',
    templateUrl: './kyc-search-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KycSearchButtonComponent implements OnInit {

    @Input() keyParty: KeyParty;

    searchResult: Observable<PagedResponse<SearchResult>>;

    constructor(private kycService: KycSearchService) {}

    ngOnInit() {
        this.fetchKycSearch();
    }

    isEnabled() {
        return Configuration.getConfig().complyAdvantageEnabled;
    }

    fetchKycSearch() {
        if (!this.isEnabled) {
            return;
        }

        const params: KycSearchParams = OnboardSearchParamsBuilder.builder()
            .keyParty({
                memberId: this.keyParty.memberId,
                individualKeyPartyId: this.keyParty.individualKeyPartyId,
                corporateKeyPartyId: this.keyParty.corporateKeyPartyId,
                type: this.keyParty.type
            })
            .build();

        this.searchResult = this.kycService.findSearches(params);
    }

    onSearchCreated(_event: SearchResult) {
        this.fetchKycSearch();
    }

    onSearchUpdated(_event: SearchResult) {
        this.fetchKycSearch();
    }
}
