<div class="col-12">
    <div class="row mb-3">
        <div class="col-8">
            <span class="form-title">Bank Accounts</span>
        </div>
    </div>
    <div>
        <dl class="row" *ngIf="bankAccountForm">
            <dt class="col-3 mt-2">Linked Bank Account:</dt>
            <dd class="col-9">
                <pc-bank-account-selector [placeholder]="'Select Bank Account'" [formCtrl]="'bankAccountIdCtrl'" [formGroup]="bankAccountForm" [bankAccounts]="bankAccounts"
                    (bankAccountSelected)="onSelectBankAccount($event)" >
                </pc-bank-account-selector>
            </dd>
        </dl>
        <dl class="row" *ngIf="addressForm">
            <dt class="col-3 mt-2">Linked Address:</dt>
            <dd class="col-9">
                <fieldset class="form-group">
                    <div [formGroup]="addressForm">
                        <select class="form-control" [formControlName]="'addressIdCtrl'" type="text" (change)="onSelectAddress($event)">
                            <option value=""></option>
                            <option *ngFor="let address of addresses" [value]="address.id">{{address.streetAddressOne}}, {{address.city}}, {{address.stateProvince}}</option>
                        </select>
                    </div>
                </fieldset>
            </dd>
        </dl>
    </div>
    <div class="col-sm-12 horizontal-scroll mat-table-container">
        <div class="mat-table-spinner" *ngIf="isLoadingResults">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table [dataSource]="linkedBankAccounts" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
            <ng-container matColumnDef="status">
                <th class="centered" mat-header-cell  *matHeaderCellDef mat-sort-header="bankAccount.status"> Status </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <pc-bank-account-badge [parent]="element.bankAccount"></pc-bank-account-badge>
                </td>
            </ng-container>
            <ng-container matColumnDef="account_number">
                <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header="bankAccount.accountNumber"> Account Number </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span *ngIf="element.bankAccount.accountNumber"> {{ getAccountNumber(element.bankAccount.accountNumber) }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="ach_routing_number">
                <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header="bankAccount.routingNumber"> ACH Routing Number </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span *ngIf="element.bankAccount.routingNumber"> {{ getRoutingNumber(element.bankAccount.routingNumber) }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="wire_routing_number">
                <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header="bankAccount.wireRoutingNumber"> Wire Routing Number </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span *ngIf="element.bankAccount.wireRoutingNumber"> {{ getRoutingNumber(element.bankAccount.wireRoutingNumber) }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="holder">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bankAccount.holder"> Holder </th>
                <td mat-cell *matCellDef="let element">
                    <span>
                        <b *ngIf="element.bankAccount.holder">{{element.bankAccount.holder}}</b>
                        <span *ngIf="element.bankAccount.externalAccountDetails?.phone">
                            <br>
                            {{element.bankAccount.externalAccountDetails.phone | phone}}
                        </span>
                        <span *ngIf="element.bankAccount.externalAccountDetails?.email">
                            <br>
                            {{element.bankAccount.externalAccountDetails.email}}
                        </span>
                    </span>
                    <pc-business-address *ngIf="element.bankAccount.externalAccountDetails?.addressId" [addressId]="element.bankAccount.externalAccountDetails.addressId">
                    </pc-business-address>
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell  *matHeaderCellDef mat-sort-header="bankAccount.name"> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.bankAccount.name}} </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bankAccount.type"> Type </th>
                <td mat-cell *matCellDef="let element"> {{element.bankAccount.type}} </td>
            </ng-container>
            <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef> Source </th>
                <td mat-cell *matCellDef="let element"> {{element.bankAccount.source | translate}} </td>
            </ng-container>
            <ng-container matColumnDef="date_added">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bankAccount.created"> Date Added </th>
                <td mat-cell *matCellDef="let element"> {{ element.bankAccount.created | date:'mediumDate'}} </td>
            </ng-container>
            <ng-container matColumnDef="financial_institution">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="bankAccount.financialInstitution"> Bank </th>
                <td mat-cell *matCellDef="let element">  {{element.bankAccount.financialInstitution}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="!isEnabled(row) ? 'disabled' : ''"></tr>
        </table>
        <mat-paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>
</div>
