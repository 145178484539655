import { Component, Input, OnInit } from '@angular/core';
import { Member, MemberService, MemberStatus, MemberType, WorkflowService } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-load-member-details',
    templateUrl: './load-member-details.component.html',
    styleUrls: ['./load-member-details.component.scss']
})
export class LoadMemberDetailsComponent implements OnInit {

    @Input() memberId: string;
    @Input() linkDescription: string;
    @Input() activeTab: string;
    @Input() linkType: 'normal' | 'table' | 'header' | 'large' = 'normal';
    @Input() directLink = false;

    isBusinessMember = false;
    isBusinessMerchant = false;
    isIndividualMerchant = false;
    isPending = false;
    member: Member;

    constructor(private memberService: MemberService,
                private workflowService: WorkflowService) { }

    ngOnInit() {
        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            if (member) {
                this.member = member;
                this.isPending = (member.status === MemberStatus.PENDING || member.status === MemberStatus.APPLIED || member.status === MemberStatus.UNDER_REVIEW);
                const memberType = member.memberType;
                // FIXME CN-3880 Consumer is an individual, we need to load individual details component for consumers.
                if (memberType === MemberType.BUSINESS || memberType === MemberType.CONSUMER || memberType === MemberType.FEE_ACCOUNT) {
                    this.isBusinessMember = true;
                } else if (memberType === MemberType.BUSINESS_MERCHANT) {
                    this.isBusinessMerchant = true;
                } else if (memberType === MemberType.INDIVIDUAL_MERCHANT) {
                    this.isIndividualMerchant = true;
                }
            }
        });
    }

    pendingMemberDetails() {
        if (this.isIndividualMerchant) {
            this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_individual_merchant_v2'], `merchantMemberId_eq_${this.member.id}`);
        } else if (this.isBusinessMerchant) {
            this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_business_merchant_v2'], `merchantMemberId_eq_${this.member.id}`);
        } else {
            this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_member_v2', 'onboard_pt_member'], `memberId_eq_${this.member.id}`);
        }
    }
}
