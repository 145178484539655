<div>
    <form [formGroup]="registerUserForm">
        <div>
            <div class="modal-header">
                <label class="form-title">Consumer Registration</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <pc-auto-complete-selector *ngIf="jurisdictions.length" [required]="true" [label]="'State'"  [formCtrl]="'jurisdictionCtrl'"
                    [formGroup]="registerUserForm" [options]="jurisdictions" [displayValue]="'name'">
                </pc-auto-complete-selector>
                <pc-email-input [required]="true" [label]="'Email'"
                    [formCtrl]="'emailCtrl'" [formGroup]="registerUserForm" (changed)="onEmailChange($event)">
                </pc-email-input>
                <pc-text-input *ngIf="userType === 'NEW'" [required]="true" [label]="'First Name'"
                    [formCtrl]="'firstNameCtrl'" [formGroup]="registerUserForm">
                </pc-text-input>
                <pc-text-input *ngIf="userType === 'NEW'" [required]="true" [label]="'Last Name'"
                    [formCtrl]="'lastNameCtrl'" [formGroup]="registerUserForm">
                </pc-text-input>
                <pc-phone-input *ngIf="userType === 'NEW'" [label]="'Mobile Number'"
                    [formCtrl]="'mobilePhoneCtrl'" [formGroup]="registerUserForm">
                </pc-phone-input>
                <pc-form-selector *ngIf="isMfaEnabled() && userType === 'NEW'" [required]="true" [label]="'Two Factor Authentication'" [formCtrl]="'mfaCtrl'"
                    [formGroup]="registerUserForm" [options]="mfaTypes" [idValue]="'id'" [displayValue]="'name'" [defaultValue]="''">
                </pc-form-selector>
                <pc-password-input *ngIf="userType === 'EXISTING'" [required]="true" [label]="'Password'" [formCtrl]="'passwordCtrl'" [formGroup]="registerUserForm">

                </pc-password-input>
                <div *ngIf="errorMessage" class="error-message">
                    <label class="form-error">{{errorMessage}}</label>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button *ngIf="userType" [enabled]="formValid" [submit]="onSubmit" [text]="'Begin Application'"></pc-submit-button>
                <button *ngIf="!userType" type="button" class="blue-button" [disabled]="nextDisabled()">Next</button>
            </div>
        </div>
    </form>
</div>
