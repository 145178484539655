import { Component, OnInit, Inject } from '@angular/core';
import { Transaction, QrCodeService } from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pt-qr-tracking-modal',
    templateUrl: './qr-tracking-modal.component.html',
    styleUrls: ['./qr-tracking-modal.component.scss']
})
export class QrTrackingModalComponent extends BaseModalComponent<QrTrackingModalComponent> implements OnInit {

    transaction: Transaction;

    constructor(private qrCodeService: QrCodeService,
                protected dialogRef: MatDialogRef<QrTrackingModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.transaction = data.transaction;
    }

    ngOnInit() {
        this.close = this.close.bind(this);
    }

    getQrCode() {
        const urlFragment = `/transaction/complete/${this.transaction.id}`;
        return {
            key: urlFragment,
            src: this.qrCodeService.createQrCodeFromFragment(urlFragment)
        };
    }

    close() {
        super.close();
    }
}
