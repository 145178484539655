<div class="col-sm-12 horizontal-scroll mat-table-container">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="members" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="business_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="sortName"> {{ consumers ? 'Consumer' : 'Member'}} </th>
            <td mat-cell *matCellDef="let element">
                <img *ngIf="element.member.memberType === MemberType.CONSUMER" class="member-type-icon" src="assets/images/profile-icon.svg" title="Consumer">
                <a [routerLink]="['/member/details/' + element.member.id]" [routerLinkActive]="['router-link-active']">
                    <span *ngIf="!consumers">{{ element.member.name }}</span>
                    <span *ngIf="consumers"> {{ getConsumerName(element.member) }} </span>
                </a>
                <div>
                    <pc-business-address *ngIf="element.address" [address]="element.address">
                    </pc-business-address>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> Id </th>
            <td mat-cell *matCellDef="let element">
                <div><b>Account ID:</b> {{ element.id }}<br></div>
                <div><b>Account Name:</b> {{ element.accountFriendlyName }}<br></div>
            </td>
        </ng-container>
        <ng-container matColumnDef="contact_info">
            <th mat-header-cell *matHeaderCellDef> Contact Info </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.member.email"><b>Email:</b> {{ element.member.email }}<br></span>
                <span *ngIf="element.member.phone"><b>Phone:</b> {{ element.member.phone | phone }} </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell class="centered" *matCellDef="let element">
                <span *ngIf="element.member.status">
                    <pc-member-badge [parent]="element.member"></pc-member-badge>
                </span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="!isEnabled(row) ? 'disabled' : ''"></tr>
    </table>
    <mat-paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
</div>
