export class Referral {

    referrerName: string;
    email: string;
    message: string;
    referrerCode: string;
    referrerMemberName: string;
    refererMemberId: string;
    refereeMemberName: string;
    refereeMemberId: string;

}
