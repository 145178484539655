import { Component } from '@angular/core';
import { BusinessClient, BusinessClientStatus, Member, MemberStatus } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-member-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class MemberBadgeComponent extends BaseBadgeComponent<Member | BusinessClient<any>> {

    setBadgeData() {
        this.badgeToolTip = this.parent.status;
        switch (this.parent.status) {
            case MemberStatus.ACTIVE:
                this.badgeText = 'ACTIVE';
                this.badgeClass = 'green-badge';
                break;
            case MemberStatus.APPLIED:
                this.badgeText = 'APPLYING';
                this.badgeClass = 'orange-badge';
                break;
            case MemberStatus.UNDER_REVIEW:
                this.badgeText = 'UNDER REVIEW';
                this.badgeClass = 'orange-badge';
                break;
            case BusinessClientStatus.DILIGENCE_REQUIRED:
                this.badgeText = 'DILIGENCE';
                this.badgeClass = 'grey-badge';
                break;
            case MemberStatus.PENDING:
                this.badgeText = 'PENDING';
                this.badgeClass = 'orange-badge';
                break;
            case MemberStatus.BLOCKED:
                this.badgeText = 'BLOCKED';
                this.badgeClass = 'red-badge';
                break;
            case MemberStatus.FROZEN:
                this.badgeText = 'FROZEN';
                this.badgeClass = 'red-badge';
                break;
            case BusinessClientStatus.REJECTED:
                this.badgeText = 'REJECTED';
                this.badgeClass = 'red-badge';
                break;
            case MemberStatus.UNAVAILABLE:
                this.badgeText = 'UNAVAILABLE';
                this.badgeClass = 'red-badge';
                break;
            case MemberStatus.INACTIVE:
                this.badgeText = 'INACTIVE';
                this.badgeClass = 'red-badge';
                break;
            case MemberStatus.DISABLED:
                this.badgeText = 'DISABLED';
                this.badgeClass = 'red-badge';
                break;
            case BusinessClientStatus.VERIFICATION_REQUIRED:
                this.badgeText = 'VERIFY';
                this.badgeClass = 'green-badge';
                break;
            case BusinessClientStatus.PENDING_VERIFICATION_REQUIRED:
                this.badgeText = 'VERIFY';
                this.badgeClass = 'orange-badge';
                break;
            case BusinessClientStatus.LINKED:
                this.badgeText = 'LINKED';
                this.badgeClass = 'green-badge';
                break;
            case BusinessClientStatus.LINKED_PENDING:
                this.badgeText = 'LINKED';
                this.badgeClass = 'orange-badge';
                break;
            case BusinessClientStatus.AVAILABLE:
                this.badgeText = 'AVAILABLE';
                this.badgeClass = 'grey-badge';
                break;
        }
    }
}
