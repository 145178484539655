import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppInjector } from 'projects/services/src/public-api';
import { Subscription } from 'rxjs';

@Component({
    selector: 'pc-modal',
    template: ''
})
export class BaseModalComponent<T> {

    protected router: Router;
    private backdropSub: Subscription;

    constructor(private dialog: MatDialogRef<T>) {
        const injector = AppInjector.getInjector();
        this.router = injector.get(Router);
        this.backdropSub = dialog.backdropClick().subscribe(() => {
            if (!this.dialog.disableClose) {
                this.close();
            }
        });
    }

    close(dialogResult?: any) {
        this.backdropSub?.unsubscribe();
        this.dialog.close(dialogResult);
    }
}
