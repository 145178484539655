<div *ngIf="newLocationForm">
    <form [formGroup]="newLocationForm">
        <div>
            <div class="modal-header">
                <label class="form-title">Add New Location</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div>
                    <pc-address-input [addressTypeOpts]="addressTypeOpts" [addressForm]="newLocationForm" [showLocationName]="true" [showAccountLink]="true"
                        [memberAccountOptions]="memberAccountOptions"></pc-address-input>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="isLocationFormValid" [submit]="onSubmit" [text]="'Add Location'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
