<div class="dashboard-list">
    <div class="row">
        <div class="col-12">
            <div class="dashboard-info-icon">
                <img class="dashboard-icon" src="assets/images/calendar-icon-2.svg" alt="">
                <a href="#">{{'Transactions Score'}}</a>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12">
            <div *ngIf="!tableView">
                <pc-charts-gadgets *ngIf="chartData.length > 0"
                    [chartData]="chartData"
                    [chartType]="ChartType.GROUPED_VERTICAL_BAR_CHART"
                    [colorScheme]="colorScheme">
                </pc-charts-gadgets>
            </div>
        </div>
        <div class="col-12" *ngIf="tableView">
            <div class="col-sm-12 mt-3 mb-3 horizontal-scroll mat-table-container"> 
                <table mat-table [dataSource]="transactionScores">
                    <ng-container matColumnDef="date">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Date </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.date }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="score_0">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Score 0 </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.scoreZero }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="score_1">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Score 1 </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.scoreOne }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="score_2">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Score 2 </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.scoreTwo }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="score_3">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Score 3 </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.scoreThree }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="score_4">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Score 4 </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.scoreFour }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="score_5">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Score 5 </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.scoreFive }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
    <div>
        <pc-view-decision 
            [tableView]="tableView"
            (viewChanged)="viewChanged($event)">
        </pc-view-decision>
    </div>
</div>
