import { Component, Input, EventEmitter, OnInit, ViewChild, ElementRef, Output } from '@angular/core';
import { Message, DataroomService, AuthService, Role, MessageType } from 'projects/services/src/public-api';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MessageModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pc-message-row',
    templateUrl: './message-row.component.html',
    styleUrls: ['./message-row.component.scss']
})
export class MessageRowComponent implements OnInit {

    Role = Role;

    @Input() message: Message;
    @Input() messageType: MessageType;
    @Output() refreshPage: EventEmitter<void> = new EventEmitter<void>();

    currentMemberId: string;

    @ViewChild('downloadMessageLink') downloadMessageLink: ElementRef;

    constructor(private dataroomService: DataroomService,
                private dialog: MatDialog,
                public authService: AuthService) {}

    ngOnInit() {
        this.currentMemberId = this.authService.getProfile().memberId;
    }

    replyToMessage() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        const subject = !this.message.parentMessageId ? 'Re: ' + (this.message.subject.length > 96 ? this.message.subject.substring(0, 96) : this.message.subject) : this.message.subject;
        dialogConfig.data = {
            parentMessageId: this.message.messageId,
            recipientMemberId: this.message.senderMemberId,
            recipientMemberName: this.message.senderMemberName,
            recipientId: this.message.senderId ? this.message.senderId : '',
            subject

        };
        const dialog = this.dialog.open(MessageModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((result) => {
            if (result) {
                this.refreshPage.emit();
            }
        });
    }

    downloadFile(document: any) {
        this.dataroomService.downloadResource(document, this.downloadMessageLink);
    }
}
