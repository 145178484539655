import { WhitelistRelationshipParty } from '../models/whitelist-relationship-party';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Utils } from '../util.service';
import { Member } from '../models/member';

@Injectable({
    providedIn: 'root'
})
export class WhitelistRelationshipPartyFormService {

    constructor(private formbuilder: UntypedFormBuilder) { }

    initializeForm(member: Member, whitelistPartyType: string, whitelistTxnType: string): UntypedFormGroup {
        return this.formbuilder.group({
            whitelistTypeCtrl: new UntypedFormControl(whitelistTxnType, Validators.required),
            whitelistSearchTypeCtrl: new UntypedFormControl(whitelistPartyType, Validators.required),
            memberIdCtrl: new UntypedFormControl(member.id, Validators.required),
            memberNameCtrl: new UntypedFormControl(member.name, Validators.required),
            whitelistedMemberIdCtrl: new UntypedFormControl('', Validators.required),
            whitelistedMemberNameCtrl: new UntypedFormControl('', Validators.required),
            maximumMonthlyTransactionAmountCtrl: new UntypedFormControl('', [Validators.min(0), Validators.max(1000000)]),
            maximumMonthlyNumberOfTransactionsCtrl: new UntypedFormControl('', [Validators.minLength(0), Validators.maxLength(6)]),
            notesCtrl: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)])
        });
    }

    getWhitelistRelationShipParty(form: UntypedFormGroup, whitelistedMemberId: string): WhitelistRelationshipParty {
        const whitelistRelationshipParty = new WhitelistRelationshipParty();
        whitelistRelationshipParty.memberId = form.get('memberIdCtrl').value;
        whitelistRelationshipParty.memberName = form.get('memberNameCtrl').value;
        whitelistRelationshipParty.whitelistedMemberId = whitelistedMemberId;
        whitelistRelationshipParty.whitelistedMemberName = form.get('whitelistedMemberNameCtrl').value;
        whitelistRelationshipParty.memberName = form.get('memberNameCtrl').value;
        whitelistRelationshipParty.whitelistedTransactionType = form.get('whitelistTypeCtrl').value;
        whitelistRelationshipParty.note = form.get('notesCtrl').value;
        const maximumTransactionAmount = form.get('maximumMonthlyTransactionAmountCtrl').value;
        if (maximumTransactionAmount && Number(maximumTransactionAmount) !== 0.00) {
            whitelistRelationshipParty.maximumTransactionAmount = maximumTransactionAmount;
        } else {
            whitelistRelationshipParty.maximumTransactionAmount = null;
        }
        const maximumMonthlyNumberOfTransactions = Utils.parseFloatIgnoreCommas(form.get('maximumMonthlyNumberOfTransactionsCtrl').value);
        if (maximumMonthlyNumberOfTransactions && Number(maximumMonthlyNumberOfTransactions) !== 0) {
            whitelistRelationshipParty.maximumMonthlyNumberOfTransactions = maximumMonthlyNumberOfTransactions;
        } else {
            whitelistRelationshipParty.maximumMonthlyNumberOfTransactions = null;
        }

        return whitelistRelationshipParty;
    }

}
