import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { LendingFinancingRequest, LenderService, Loan, LoanFormService, LoanStatus, WorkflowService } from 'projects/services/src/public-api';
import { IMyDateModel } from 'angular-mydatepicker';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'pc-loan-modal',
    templateUrl: './loan-modal.component.html'
})
export class LoanModalComponent extends BaseModalComponent<LoanModalComponent> implements OnInit {

    isStartDateRequired = false;
    isMaturityDateRequired = false;
    paymentStartDate = '';
    maturityDate = '';
    loanFormGroup: UntypedFormGroup;
    loan: Loan;
    companyType: string;
    paymentFrequencies = [
        'WEEKLY',
        'MONTHLY',
        'YEARLY'
    ];
    changeTrigger: number = 1;

    constructor(private loanFormService: LoanFormService,
                private lenderService: LenderService,
                private workflowService: WorkflowService,
                private datePipe: DatePipe,
                dialogRef: MatDialogRef<LoanModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.loan = data.loan;
        this.paymentStartDate = this.loan.paymentStartDate;
        this.maturityDate = this.loan.maturity;
    }

    ngOnInit() {
        this.confirm = this.confirm.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.close = this.close.bind(this);
        this.loanFormGroup = this.loanFormService.initializeForm(this.loan);
        this.lenderService.getFinancingByLoanId(this.loan.id)
            .subscribe((financingRequest: LendingFinancingRequest) => {
                this.companyType = financingRequest.companyType;
            });
    }

    confirm() {
        const loanRequest = this.loanFormService.getLoanRequest(this.loanFormGroup);
        loanRequest.paymentStartDate = this.paymentStartDate;
        loanRequest.maturity = this.maturityDate;
        this.updateLoan(loanRequest);
    }

    updateLoan(loanRequest: any) {
        this.lenderService.updateLoan(this.loan.id, loanRequest).subscribe((loan: Loan) => {
            this.workflowService.getTaskByProcessDefinitionWithVariable(['request_financing_v2'], 'loanId', loan.id).subscribe((task: any) => {
                if (task) {
                    this.workflowService.completeTask(task.id, {}).subscribe(() => {
                        super.close(true);
                    });
                } else {
                    super.close(true);
                }
            });
        });
    }

    onMaturityChanged(event: IMyDateModel) {
        if (event) {
            this.maturityDate = this.datePipe.transform(event.singleDate.jsDate, 'yyyy-MM-dd');
            this.loanFormGroup.get('paymentStartDateCtrl').setValidators([Validators.required]);
            this.isStartDateRequired = true;
            this.loanFormGroup.get('paymentStartDateCtrl').updateValueAndValidity();
        } else {
            this.maturityDate = '';
            if (this.loanFormGroup.get('statusCtrl').value !== LoanStatus.ACTIVE) {
                this.loanFormGroup.get('paymentStartDateCtrl').setValidators(null);
                this.isStartDateRequired = false;
                this.loanFormGroup.get('paymentStartDateCtrl').updateValueAndValidity();
            }
        }
        this.changeTrigger = Math.random();
    }

    onPaymentStartDateChanged(event: IMyDateModel) {
        if (event) {
            this.paymentStartDate = this.datePipe.transform(event.singleDate.jsDate, 'yyyy-MM-dd');
            if (this.loanFormGroup.get('statusCtrl').value !== LoanStatus.ACTIVE) {
                this.loanFormGroup.get('maturityCtrl').setValidators([Validators.required]);
                this.isMaturityDateRequired = true;
                this.loanFormGroup.get('maturityCtrl').updateValueAndValidity();
            }
        } else {
            this.paymentStartDate = '';
            if (this.loanFormGroup.get('statusCtrl').value !== LoanStatus.ACTIVE) {
                this.loanFormGroup.get('maturityCtrl').setValidators(null);
                this.isMaturityDateRequired = false;
                this.loanFormGroup.get('maturityCtrl').updateValueAndValidity();
            }
        }
        this.changeTrigger = Math.random();
    }

    close() {
        super.close(false);
    }

    arrayOfStatusesOnCurrentStatus(status: LoanStatus): LoanStatus[] {
        if (status === LoanStatus.CLOSED || status === LoanStatus.DECLINED) {
            return [status];
        } else if (status === LoanStatus.PENDING) {
            return [LoanStatus.PENDING, LoanStatus.DECLINED, LoanStatus.DILIGENCE];
        } else if (status === LoanStatus.DILIGENCE) {
            return [LoanStatus.DECLINED, LoanStatus.DILIGENCE, LoanStatus.ACTIVE];
        } else if (status === LoanStatus.ACTIVE) {
            return [LoanStatus.CLOSED, LoanStatus.ACTIVE];
        } else {
            return [];
        }
    }

    isClosedOrDeclined() {
        return this.loanFormGroup.get('statusCtrl').value === LoanStatus.DECLINED || this.loanFormGroup.get('statusCtrl').value === LoanStatus.CLOSED;
    }

    onStatusChange() {
        this.isMaturityDateRequired = false;
        this.isStartDateRequired = false;
        this.loanFormGroup.get('paymentStartDateCtrl').setValidators(null);
        this.loanFormGroup.get('maturityCtrl').setValidators(null);

        if (this.isClosedOrDeclined()) {
            this.loanFormGroup.get('acknowledgedCtrl').setValidators(Validators.requiredTrue);
        } else {
            this.loanFormGroup.get('acknowledgedCtrl').clearValidators();
        }
        this.loanFormGroup.get('acknowledgedCtrl').setValue(false);
        this.loanFormGroup.get('acknowledgedCtrl').updateValueAndValidity();
        this.updateDateValidations();
    }

    updateDateValidations() {
        if (this.loanFormGroup.get('statusCtrl').value === LoanStatus.ACTIVE) {
            this.loanFormGroup.get('paymentStartDateCtrl').setValidators([Validators.required]);
            this.loanFormGroup.get('maturityCtrl').setValidators([Validators.required]);
            this.isMaturityDateRequired = true;
            this.isStartDateRequired = true;
        }

        if (this.loanFormGroup.get('statusCtrl').value !== LoanStatus.ACTIVE && this.paymentStartDate && !this.maturityDate) {
            this.loanFormGroup.get('maturityCtrl').setValidators([Validators.required]);
            this.isMaturityDateRequired = true;
        } else if (this.loanFormGroup.get('statusCtrl').value !== LoanStatus.ACTIVE && !this.paymentStartDate && this.maturityDate) {
            this.loanFormGroup.get('paymentStartDateCtrl').setValidators([Validators.required]);
            this.isStartDateRequired = true;
        }
        this.loanFormGroup.get('paymentStartDateCtrl').updateValueAndValidity();
        this.loanFormGroup.get('maturityCtrl').updateValueAndValidity();
    }

    validateForm() {
        return !this.loanFormGroup.invalid && !(this.loanFormGroup.get('statusCtrl').value !== LoanStatus.ACTIVE && (this.paymentStartDate && !this.maturityDate) || (!this.paymentStartDate && this.maturityDate));
    }
}
