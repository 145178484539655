import { Address } from '../address';

export class LendingSupplier {

    externalId: string;
    name: string;
    dbaName: string;
    contactName: string;
    email: string;
    officePhone: string;
    defaultCurrency: string;
    phone: string;
    billAddress: Address;
    shipAddress: Address;
    registrationNumber?: string;
    taxNumber?: string;
    status?: string;

    avgBillAmount: number;
    avgPaymentTerms: number;
    concentration: number;
    daysPayableOutstanding?: number;
    noBillsOutstanding?: number;
    outstanding?: number;
    paid?: number;

}
