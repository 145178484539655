export class RetailTransactionInitiationRequest {

    phone : string;
    amount: number;
    payorAccountId?: string;
    recipientAccountId?: string;
    retailLocationId?: string;
    bnplAmount: number;

}
