<div class="col-12 dashboard-info">
    <div class="row">
        <div class="col-12 dashboard-header">
            <span>Transaction Completion</span>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-2"></div>
                <div class="col-8" *ngIf="transaction">
                    <div class="row">
                        <div class="col-5 expandedLabel">
                            Type
                        </div>
                        <div class="col-7 expandedValue">
                            {{transaction.transactionType | translate}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5 expandedLabel">
                            Recipient
                        </div>
                        <div class="col-7 expandedValue">
                            <a *ngIf="transaction.recipientMemberName && transaction.recipientMemberId" (click)="navigateToMember(transaction.recipientMemberId)">
                                <span *ngIf="transaction.recipientMemberName">{{transaction.recipientMemberName}}</span>
                            </a>
                            <span *ngIf="transaction.recipientMemberName && !transaction.recipientMemberId">{{transaction.recipientMemberName}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5 expandedLabel">
                            Payment Amount
                        </div>
                        <div class="col-7 expandedValue">
                            <pc-transaction-amount [transaction]="transaction"></pc-transaction-amount>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5 expandedLabel">
                            Date of Payment
                        </div>
                        <div class="col-7 expandedValue">
                            {{transaction.transactionDate | date:'mediumDate'}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">&nbsp;</div>
                    </div>
                    <div class="row">
                        <div class="col-5 expandedLabel">
                            Transaction ID
                        </div>
                        <div class="col-7 expandedValue">
                            {{transaction.id}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5 expandedLabel">
                            Status
                        </div>
                        <div class="col-7 expandedValue">
                            <pc-transaction-badge [parent]="transaction"></pc-transaction-badge>
                        </div>
                    </div>
                </div>
                <div class="col-2"></div>
            </div>
        </div>
        <div class="col-12">
            <div class="button-panel">
                <pc-submit-button [submit]="cancel" [text]="'Cancel'"></pc-submit-button>
                <pc-submit-button [submit]="complete" [text]="'Confirm Delivery'"></pc-submit-button>
            </div>
        </div>
    </div>
</div>
