import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pt-message-modal',
    templateUrl: './bank-account-review-notes-modal.component.html'
})
export class BankAccountReviewNotesModalComponent extends BaseModalComponent<BankAccountReviewNotesModalComponent> implements OnInit {

    reviewNotesForm: UntypedFormGroup;
    buttonText: string;
    formTitle = 'Reject Bank Account And Inform Member';
    textLabel = 'Reasons For Rejection';
    constructor(@Inject(MAT_DIALOG_DATA) data: string,
                private formbuilder: UntypedFormBuilder,
                protected dialogRef: MatDialogRef<BankAccountReviewNotesModalComponent>) {
        super(dialogRef);
        this.buttonText = data;
        this.reviewNotesForm = this.formbuilder.group({
            reasonCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(256)])
        });
    }

    ngOnInit() {
        this.formEnabled = this.formEnabled.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        if (this.buttonText === 'Approve') {
            this.formTitle = 'Approve Bank Account';
            this.textLabel = 'Approval Notes';
        }
    }

    close(message?: any) {
        super.close(message);
    }

    onSubmit() {
        this.close(this.reviewNotesForm.controls['reasonCtrl'].value);
    }

    formEnabled() {
        return !this.reviewNotesForm.invalid;
    }
}
