import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxAutocomPlaceModule } from 'ngx-autocom-place';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import {
    AddressInputComponent, AutoCompleteSelectorComponent, AutoCompleteStreetAddressComponent, BusinessClientSelectorComponent, DOBInputComponent, EmailInputComponent,
    BankAccountSelectorComponent, FormAmountInputComponent, FormDatePickerComponent, FormSelectorComponent, FormTextAreaComponent, FormTextInputComponent,
    MerchantSearcherComponent, MerchantSelectorComponent, NumericInputComponent, MemberSelectorComponent, TrackingCounterPartySelectorComponent, PasswordInputComponent,
    PercentageInputComponent, PhoneInputComponent, PostalCodeInputComponent, SearchTextInputComponent, TextAreaComponent, TextInputComponent, TextOptionSelectorComponent,
    ZipCodeInputComponent, GlobalSearchInputComponent, MerchantCounterPartySearcherComponent, CorporateKeyPartySearcherComponent, IndividualKeyPartySearcherComponent,
    MemberAccountSelectorComponent, SimpleMemberAccountSelectorComponent
} from './input';
import {
    AcceptedFinancingRequestModalComponent, AlertsModalComponent, ApproveAccessModalComponent, BaseModalComponent, CancelTransactionModalComponent, ChangePasswordModalComponent,
    ConfirmModalComponent, NameChangeModalComponent, FinancingRequestModalComponent, FincenReportTrackingModalComponent, InfoModalComponent, LoanModalComponent,
    LoginModalComponent, MessageModalComponent, NewNoteModalComponent, NewUserModalComponent, MemberSwitchModalComponent, UserFieldUpdateModalComponent,
    ReferralModalComponent, RegisterConsumerModalComponent, RegisterMemberModalComponent, RequestAccessModalComponent, SetPasswordModalComponent, RefundModalComponent,
    LandingPageStyleModalComponent, LinkedReconciliationAccountModalComponent, LinkUserModalComponent, MemberAccountSelectModalComponent, UpdatedTermsModalComponent,
    TransferFundsModalComponent, MemberMergeModalComponent
} from './modal';
import { AuthorityHeaderComponent, GenericHeaderComponent, MemberHeaderComponent, NewMemberHeaderComponent, RetailerHeaderComponent } from './header';
import { MyMessagesComponent, NewMessageComponent, WorkListComponent } from './communication';
import {
    AuthorityUsersListComponent, DateTimeComponent, ExpensesRowComponent, ManifestRowComponent, MessageRowComponent, SortableColumnComponent, MemberUserListComponent,
    UserTableHeaderComponent, MemberAccountUserListComponent
} from './tables';
import { SubmitButtonComponent } from './buttons';
import { AccountManagementComponent, CorporateProfileComponent, LoadMemberDetailsComponent, MyProfileComponent, OnboardTabComponent, MemberUsersComponent } from './administration';
import { BusinessAddressComponent } from './address';
import { NotesComponent, NotesTableComponent } from './notes';
import { GenericSidebarComponent, NewMemberSidebarComponent, SideMenuLinkComponent } from './sidebar';
import { SimpleCheckboxComponent } from './input/simple-checkbox/simple-checkbox.component';
import { TransactionListComponent, TransactionAmountComponent } from './transaction';
import {
    MemberMetrcPackageListComponent, MemberMetrcTransfersListComponent, MemberMetrcTransfersRowComponent, MemberMetrcPackageSummaryComponent, MemberMetrcTraceComponent
} from './tracking';
import { SecureImageComponent } from './image';
import { LoaderComponent } from './loader/loader.component';
import { ReportsListComponent, ReportsPartyInformationComponent } from './reports';
import { AlertsGadgetComponent, AuthorityAssetsGadgetComponent, AuthorityTransactionScoreGadgetComponent, AuthorityTransactionTypeGadgetComponent, AuthorityTransactionVolumeGadgetComponent,
    LendingGadgetComponent, MessagesGadgetComponent, TransactionChartsGadgetsComponent, ViewDecisionComponent, WorkGadgetComponent, ChartsGadgetsComponent } from './gadgets';
import { PhonePipe } from './pipes/phone.pipe';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { SnackbarNotificationComponent } from './snackbar-notifications/snackbar-notification.component';
import { NoDoubleClickDirective } from './directives';
import { LenderLendingListComponent, LendingListComponent, BnplLoanDetailsComponent } from './lending';
import { UIUtils } from './ui-utils.service';
import { TableUtils } from './table-utils.service';
import { SessionRefreshComponent } from './session-refresh/session-refresh.component';
import {
    AlertsBadgeComponent, BankAccountBadgeComponent, LendingBadgeComponent, LicenseBadgeComponent, MemberBadgeComponent, MerchantAccountBadgeComponent, MerchantConfigBadgeComponent,
    ReportBadgeComponent, RfiBadgeComponent, ScenarioBadgeComponent, TransactionBadgeComponent, BNPLLoanBadgeComponent, AccountBadgeComponent, AdjudicateBadgeComponent,
    SearchMatchStatusBadgeComponent, SearchRiskLevelBadgeComponent, BooleanBadgeComponent, AccountingPartyTypeBadgeComponent, MerchantSourceBadgeComponent,
    MemberAccountBadgeComponent, WatchlistBadgeComponent, FincenPersonStatusBadgeComponent, FincenCompanyStatusBadgeComponent
} from './badges';
import { DashboardAlertComponent } from './dashboard';
import { AccountSummaryComponent, AccountSummaryListComponent } from 'projects/pt/src/app/administration/members';
import { DatatableComponent } from './tables/datatable/datatable.component';
import { KycRunSearchButtonComponent } from 'projects/components/src/lib/kyc/kyc-run-search-button/kyc-run-search-button.component';
import { KycSearchResultButtonComponent } from 'projects/components/src/lib/kyc/kyc-search-result-button/kyc-search-result-button.component';
import { KycSearchButtonComponent } from 'projects/components/src/lib/kyc/kyc-search-button/kyc-search-button.component';
import { KycSearchResultModalComponent } from './kyc/kyc-search-result-modal/kyc-search-result-modal.component';
import { KycExtraSearchComponent } from 'projects/components/src/lib/kyc/kyc-extra-search/kyc-extra-search.component';
import { KycExtraSearchModalComponent } from './kyc/kyc-extra-search-modal/kyc-extra-search-modal.component';
import { BankAccountReviewNotesComponent } from './tables/bank-account-review-notes/bank-account-review-notes.component';
import { AuthorityWorkflowAssignComponent, AuthorityWorkflowNotesComponent } from './workflow';
import { RfiListComponent } from './rfi';
import { MemberMetrcTraceRowListComponent } from './tracking/member-metrc-trace-row-list/member-metrc-trace-row-list.component';
import { MemberMetrcTraceTransferRowListComponent } from './tracking/member-metrc-trace-transfer-row-list/member-metrc-trace-transfer-row-list.component';
import { ConnectedMemberSelectorComponent } from './input/connected-member-selector/connected-member-selector.component';
import { MemberApplicationSubmitComponent } from './administration/member-application-submit/member-application-submit.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NgbModule,
        RouterModule,
        AngularMyDatePickerModule,
        NgxChartsModule,
        MatCardModule,
        MatProgressBarModule,
        MatListModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatTooltipModule,
        ColorPickerModule,
        MatBadgeModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSortModule,
        NgxAutocomPlaceModule
    ],
    declarations: [
        TextInputComponent,
        LendingListComponent,
        EmailInputComponent,
        PhoneInputComponent,
        PasswordInputComponent,
        TextAreaComponent,
        SearchTextInputComponent,
        AutoCompleteStreetAddressComponent,
        TextOptionSelectorComponent,
        FormTextInputComponent,
        FormTextAreaComponent,
        FormAmountInputComponent,
        FormDatePickerComponent,
        LoginModalComponent,
        RegisterMemberModalComponent,
        RegisterConsumerModalComponent,
        NewMemberHeaderComponent,
        MemberHeaderComponent,
        GenericHeaderComponent,
        AuthorityHeaderComponent,
        RetailerHeaderComponent,
        SortableColumnComponent,
        MessageRowComponent,
        MyMessagesComponent,
        NewMessageComponent,
        MessageModalComponent,
        ChangePasswordModalComponent,
        AccountManagementComponent,
        CorporateProfileComponent,
        MyProfileComponent,
        MemberUsersComponent,
        UserTableHeaderComponent,
        MemberUserListComponent,
        MemberAccountUserListComponent,
        BusinessAddressComponent,
        NotesComponent,
        SideMenuLinkComponent,
        NewMemberSidebarComponent,
        GenericSidebarComponent,
        BankAccountSelectorComponent,
        ExpensesRowComponent,
        ManifestRowComponent,
        MessagesGadgetComponent,
        WorkGadgetComponent,
        DashboardAlertComponent,
        SubmitButtonComponent,
        FormSelectorComponent,
        SimpleCheckboxComponent,
        AlertsGadgetComponent,
        PercentageInputComponent,
        NewNoteModalComponent,
        RfiListComponent,
        OnboardTabComponent,
        NotesTableComponent,
        NewUserModalComponent,
        LinkUserModalComponent,
        WorkListComponent,
        DOBInputComponent,
        ZipCodeInputComponent,
        SecureImageComponent,
        InfoModalComponent,
        LoaderComponent,
        SetPasswordModalComponent,
        ConfirmModalComponent,
        NameChangeModalComponent,
        BaseModalComponent,
        MemberSelectorComponent,
        ConnectedMemberSelectorComponent,
        ReportsListComponent,
        ReportsPartyInformationComponent,
        ChartsGadgetsComponent,
        ViewDecisionComponent,
        AuthorityAssetsGadgetComponent,
        AuthorityTransactionScoreGadgetComponent,
        AuthorityTransactionTypeGadgetComponent,
        AuthorityTransactionVolumeGadgetComponent,
        PhonePipe,
        MerchantSelectorComponent,
        TransactionListComponent,
        AddressInputComponent,
        AutoCompleteSelectorComponent,
        PostalCodeInputComponent,
        AuthorityUsersListComponent,
        ReferralModalComponent,
        ErrorMessageComponent,
        TransactionChartsGadgetsComponent,
        TransactionAmountComponent,
        SnackbarNotificationComponent,
        NoDoubleClickDirective,
        MerchantSearcherComponent,
        LandingPageStyleModalComponent,
        LendingGadgetComponent,
        RefundModalComponent,
        UserFieldUpdateModalComponent,
        AlertsModalComponent,
        UserFieldUpdateModalComponent,
        LenderLendingListComponent,
        LoanModalComponent,
        AcceptedFinancingRequestModalComponent,
        FinancingRequestModalComponent,
        RequestAccessModalComponent,
        ApproveAccessModalComponent,
        MemberSwitchModalComponent,
        NumericInputComponent,
        DateTimeComponent,
        SessionRefreshComponent,
        TransactionBadgeComponent,
        RfiBadgeComponent,
        AlertsBadgeComponent,
        LendingBadgeComponent,
        BNPLLoanBadgeComponent,
        BankAccountBadgeComponent,
        MemberBadgeComponent,
        AccountBadgeComponent,
        ReportBadgeComponent,
        DateTimeComponent,
        MerchantConfigBadgeComponent,
        MerchantSourceBadgeComponent,
        MerchantAccountBadgeComponent,
        AccountingPartyTypeBadgeComponent,
        ScenarioBadgeComponent,
        MemberMetrcTransfersListComponent,
        MemberMetrcTransfersRowComponent,
        MemberMetrcPackageListComponent,
        CancelTransactionModalComponent,
        LinkedReconciliationAccountModalComponent,
        LicenseBadgeComponent,
        BusinessClientSelectorComponent,
        FincenReportTrackingModalComponent,
        AccountSummaryListComponent,
        AccountSummaryComponent,
        BnplLoanDetailsComponent,
        DatatableComponent,
        AdjudicateBadgeComponent,
        TrackingCounterPartySelectorComponent,
        KycRunSearchButtonComponent,
        KycSearchResultButtonComponent,
        KycSearchButtonComponent,
        KycSearchResultModalComponent,
        SearchMatchStatusBadgeComponent,
        SearchRiskLevelBadgeComponent,
        LoadMemberDetailsComponent,
        GlobalSearchInputComponent,
        BooleanBadgeComponent,
        KycExtraSearchComponent,
        KycExtraSearchModalComponent,
        MerchantCounterPartySearcherComponent,
        BankAccountReviewNotesComponent,
        CorporateKeyPartySearcherComponent,
        IndividualKeyPartySearcherComponent,
        MemberAccountBadgeComponent,
        MemberAccountSelectorComponent,
        MemberAccountSelectModalComponent,
        AuthorityWorkflowAssignComponent,
        AuthorityWorkflowNotesComponent,
        MemberMetrcPackageSummaryComponent,
        MemberMetrcTraceComponent,
        MemberMetrcTraceRowListComponent,
        MemberMetrcTraceTransferRowListComponent,
        WatchlistBadgeComponent,
        MemberApplicationSubmitComponent,
        UpdatedTermsModalComponent,
        FincenPersonStatusBadgeComponent,
        FincenCompanyStatusBadgeComponent,
        TransferFundsModalComponent,
        SimpleMemberAccountSelectorComponent,
        MemberMergeModalComponent
    ],
    exports: [
        TextInputComponent,
        EmailInputComponent,
        LendingListComponent,
        PhoneInputComponent,
        PasswordInputComponent,
        TextAreaComponent,
        SearchTextInputComponent,
        TextOptionSelectorComponent,
        FormTextInputComponent,
        FormTextAreaComponent,
        FormAmountInputComponent,
        FormDatePickerComponent,
        LoginModalComponent,
        RegisterMemberModalComponent,
        RegisterConsumerModalComponent,
        NewMemberHeaderComponent,
        MemberHeaderComponent,
        GenericHeaderComponent,
        AuthorityHeaderComponent,
        RetailerHeaderComponent,
        SortableColumnComponent,
        MessageRowComponent,
        MyMessagesComponent,
        NewMessageComponent,
        MessageModalComponent,
        ChangePasswordModalComponent,
        AccountManagementComponent,
        CorporateProfileComponent,
        MyProfileComponent,
        MemberUsersComponent,
        UserTableHeaderComponent,
        MemberUserListComponent,
        MemberAccountUserListComponent,
        BusinessAddressComponent,
        NotesComponent,
        SideMenuLinkComponent,
        NewMemberSidebarComponent,
        GenericSidebarComponent,
        BankAccountSelectorComponent,
        ExpensesRowComponent,
        ManifestRowComponent,
        MessagesGadgetComponent,
        WorkGadgetComponent,
        DashboardAlertComponent,
        SubmitButtonComponent,
        FormSelectorComponent,
        SimpleCheckboxComponent,
        AlertsGadgetComponent,
        PercentageInputComponent,
        NewNoteModalComponent,
        RfiListComponent,
        OnboardTabComponent,
        NotesTableComponent,
        NewUserModalComponent,
        LinkUserModalComponent,
        WorkListComponent,
        DOBInputComponent,
        ZipCodeInputComponent,
        SecureImageComponent,
        InfoModalComponent,
        LoaderComponent,
        SetPasswordModalComponent,
        ConfirmModalComponent,
        NameChangeModalComponent,
        BaseModalComponent,
        MemberSelectorComponent,
        ConnectedMemberSelectorComponent,
        ReportsListComponent,
        ReportsPartyInformationComponent,
        ChartsGadgetsComponent,
        ViewDecisionComponent,
        AuthorityAssetsGadgetComponent,
        AuthorityTransactionScoreGadgetComponent,
        AuthorityTransactionTypeGadgetComponent,
        AuthorityTransactionVolumeGadgetComponent,
        PhonePipe,
        MerchantSelectorComponent,
        TransactionListComponent,
        AddressInputComponent,
        AutoCompleteSelectorComponent,
        PostalCodeInputComponent,
        AuthorityUsersListComponent,
        ReferralModalComponent,
        ErrorMessageComponent,
        TransactionChartsGadgetsComponent,
        TransactionAmountComponent,
        NoDoubleClickDirective,
        AlertsModalComponent,
        LenderLendingListComponent,
        LoanModalComponent,
        AcceptedFinancingRequestModalComponent,
        FinancingRequestModalComponent,
        MerchantSearcherComponent,
        RequestAccessModalComponent,
        ApproveAccessModalComponent,
        LendingGadgetComponent,
        MemberSwitchModalComponent,
        NumericInputComponent,
        TransactionBadgeComponent,
        RfiBadgeComponent,
        DateTimeComponent,
        AlertsBadgeComponent,
        LendingBadgeComponent,
        BNPLLoanBadgeComponent,
        BankAccountBadgeComponent,
        MemberBadgeComponent,
        AccountBadgeComponent,
        ReportBadgeComponent,
        MerchantConfigBadgeComponent,
        MerchantSourceBadgeComponent,
        MerchantAccountBadgeComponent,
        AccountingPartyTypeBadgeComponent,
        ScenarioBadgeComponent,
        CancelTransactionModalComponent,
        MemberMetrcTransfersListComponent,
        MemberMetrcTransfersRowComponent,
        MemberMetrcPackageListComponent,
        LinkedReconciliationAccountModalComponent,
        LicenseBadgeComponent,
        BusinessClientSelectorComponent,
        FincenReportTrackingModalComponent,
        AccountSummaryListComponent,
        AccountSummaryComponent,
        BnplLoanDetailsComponent,
        DatatableComponent,
        AdjudicateBadgeComponent,
        TrackingCounterPartySelectorComponent,
        KycRunSearchButtonComponent,
        KycSearchResultButtonComponent,
        KycSearchButtonComponent,
        KycSearchResultModalComponent,
        SearchMatchStatusBadgeComponent,
        SearchRiskLevelBadgeComponent,
        LoadMemberDetailsComponent,
        GlobalSearchInputComponent,
        BooleanBadgeComponent,
        KycExtraSearchComponent,
        MerchantCounterPartySearcherComponent,
        BankAccountReviewNotesComponent,
        CorporateKeyPartySearcherComponent,
        IndividualKeyPartySearcherComponent,
        MemberAccountBadgeComponent,
        MemberAccountSelectorComponent,
        MemberAccountSelectModalComponent,
        AuthorityWorkflowAssignComponent,
        AuthorityWorkflowNotesComponent,
        MemberMetrcPackageSummaryComponent,
        MemberMetrcTraceComponent,
        WatchlistBadgeComponent,
        MemberApplicationSubmitComponent,
        UpdatedTermsModalComponent,
        FincenPersonStatusBadgeComponent,
        FincenCompanyStatusBadgeComponent,
        TransferFundsModalComponent,
        SimpleMemberAccountSelectorComponent,
        MemberMergeModalComponent
    ],
    providers: [
        CurrencyPipe,
        UIUtils,
        TableUtils
    ]
})
export class SharedComponentsModule {
}
