<div class="row col-12">
            <div class="package-header title-bold-font" *ngIf="!lineItems || lineItems.length === 0"> No Line Items Available </div>
<ng-container *ngFor="let lineItem of lineItems; let i=index">
    <tr class="pc-click-row">
        <td>
            <em class="fa" [class.fa-plus-circle]="!showLineItemSummary[i]" [class.fa-minus-circle]="showLineItemSummary[i]" (click)="showLineItemSummary[i] = !showLineItemSummary[i]"></em>
            &nbsp; &nbsp;
            <span class="package-header">Receipt Transactions</span>
        </td>
    </tr>
    <tr *ngIf="showLineItemSummary[i]" class="boarder-width-left">
        <td>
            <div class="row row-padding">
                <div class="col-2">
                    <span class="title-bold-font">Package Label</span>
                </div>
                <div class="col-4">
                    {{lineItem.label}} 
                </div>
                <div class="col-2">
                    <span class="title-bold-font">Name</span>
                </div>
                <div class="col-4">
                    {{ lineItem.productName }} 
                </div>
                <div class="col-2">
                    <span class="title-bold-font">Category</span>
                </div>
                <div class="col-4">
                    {{lineItem.category}}
                </div>
                    <div class="col-2">
                    <span class="title-bold-font">Quantity</span>
                </div>
                <div class="col-4">
                    {{lineItem.quantity}}
                </div>
                <div class="col-2">
                    <span class="title-bold-font">Price</span>
                </div>
                <div class="col-4">
                    {{lineItem.amount | currency}}
                </div>
                <div class="col-12">
                </div>
                <div class="col-2 package-margin">
                    <span class="title-bold-font">Item Weight</span>
                </div>
                <div class="col-10 package-margin">
                    {{lineItem.itemWeight}}
                </div>
                <div class="col-2">
                    <span class="title-bold-font"> Measure </span>
                </div>
                <div class="col-10">
                    {{lineItem.measure}}
                </div>
                    <div class="col-2">
                    <span class="title-bold-font">Unit</span>
                </div>
                <div class="col-10">
                    {{lineItem.unit}}
                </div>
            </div>
        </td>
    </tr>
</ng-container>
</div>