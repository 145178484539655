import { ElementRef } from '@angular/core';
import { TransactionType, Transaction, TransactionStatus, TransactionState} from '../public-api';


export class TransactionUtils {

    static isExternallySubmittedTransaction(transactionType: TransactionType) {
        let result;

        switch (transactionType) {
            case TransactionType.ACH_DEPOSIT:
            case TransactionType.ACH_WITHDRAWAL:
            case TransactionType.OUTGOING_DIGITAL_CHECK_TO_MERCHANT:
            case TransactionType.OUTGOING_CHECK_TO_MERCHANT:
            case TransactionType.INCOMING_ACH_FROM_MERCHANT:
            case TransactionType.INCOMING_WIRE_FROM_MERCHANT:
            case TransactionType.WALLET_TO_ACH_PAYMENT:
            case TransactionType.ACH_TO_WALLET_PAYMENT:
            case TransactionType.OUTGOING_ACH_TO_MERCHANT:
            case TransactionType.EXTERNAL_AUTHORIZED_ACH_TO_MERCHANT:
            case TransactionType.ACH_REFUND:
            case TransactionType.WALLET_TO_ACH_REFUND:
            case TransactionType.WALLET_TO_EXPRESS_ACH_PAYMENT:
            case TransactionType.OUTGOING_WIRE_TO_MERCHANT:
            case TransactionType.BNPL_CONSUMER_REFUND:
                result = true;
                break;
            default:
                result = false;
        }

        return result;
    }

    static isExternalTransactionReferenceAvailable(transaction: Transaction) {
        return transaction.state === TransactionState.COMPLETED || transaction.state === TransactionState.CANCELLED || (transaction.transactionType === TransactionType.EXTERNAL_AUTHORIZED_ACH_TO_MERCHANT && transaction.status === TransactionStatus.PENDING_RFI_AUTHORITY_REVIEW) ||
            transaction.status === TransactionStatus.PENDING_ACH_CALLBACK || transaction.status === TransactionStatus.PENDING_RETURN || transaction.status === TransactionStatus.PENDING_RFI_WITH_FUNDS;
    }

    static downloadFile(response: any, fileName: string, element: ElementRef) {
        const binaryData = [];
        binaryData.push(response);
        const url = window.URL.createObjectURL(new Blob(binaryData, {type: 'blob'}));
        const link = element.nativeElement;
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
    }
}
