import { HttpResponse } from '@angular/common/http';
import { PagedResponse } from 'projects/services/src/public-api';
import { ElementRef, Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { TransactionState, Transaction, TransactionSource, TransactionType } from './models/transaction';
import { AccountToken } from './models/account-token';
import { SupportingDocument } from './models/supporting-document';
import { BillingProfile } from './models/billing-profile';
import { Observable } from 'rxjs';
import { CheckDetails } from './models/check-details';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    baseUrl: string;
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.PaymentAPIEndpoint + '/';
    }

    getTransaction(id: any) : Observable<Transaction> {
        return this.http.get(`${this.baseUrl}transaction/${id}`) as Observable<Transaction>;
    }

    getTransactionsByStatusAndType(memberId: string,
        memberAccountId: string,
        state: TransactionState,
        types: TransactionType[],
        sources: TransactionSource[],
        minAmount: string,
        maxAmount: string,
        startDate: string,
        endDate: string,
        retailLocationId: string,
        start: number,
        size: number,
        sort: string,
        sortDir: string) : Observable<PagedResponse<Transaction>> {

        let params = '?';
        if (memberId) {
            params += `memberId=${memberId}&`;
        }
        params += `memberAccountId=${memberAccountId}&states=${state ? [state] : ''}&types=${types}&sources=${sources}&minAmount=${minAmount}&maxAmount=${maxAmount}&startDate=${startDate}&endDate=${endDate}&retailLocationId=${retailLocationId || ''}&timezone=${this.timezone}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}transaction${params}`) as Observable<PagedResponse<Transaction>>;
    }

    countTransactionsInvolvingExternalId(externalEntityId: string) {
        return this.http.head(`${this.baseUrl}transaction?externalEntityId=${externalEntityId}`);
    }

    getBothInvolvedTransactions(memberId: string,
        memberAccountId: string,
        counterPartyId: string,
        start: number,
        size: number,
        sort: string,
        sortDir: string) : Observable<PagedResponse<Transaction>> {

        const params = `?memberId=${memberId}&memberAccountId=${memberAccountId}&counterPartyId=${counterPartyId}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}transaction${params}`) as Observable<PagedResponse<Transaction>>;
    }

    getTransactionsScores(memberId: string,
        memberAccountId: string,
        state: TransactionState,
        types: TransactionType[],
        sources: TransactionSource[],
        period: number,
        minAmount: string,
        maxAmount: string,
        startDate: string,
        endDate: string,
        retailLocationId: string) {

        const params = `?memberId=${memberId}&memberAccountId=${memberAccountId}&states=${state ? [state] : ''}&types=${types}&sources=${sources}&period=${period}&minAmount=${minAmount}&maxAmount=${maxAmount}&startDate=${startDate}&endDate=${endDate}&retailLocationId=${retailLocationId || ''}&timezone=${this.timezone}`;
        return this.http.get(`${this.baseUrl}transaction/reporting/scores${params}`);
    }

    getTransactionsTypesCount(period: number) {
        return this.http.get(`${this.baseUrl}transaction/reporting/typesCount?period=${period}&timezone=${this.timezone}`);
    }

    getTransactionsVolume(period: number) {
        return this.http.get(`${this.baseUrl}transaction/reporting/volume?period=${period}&timezone=${this.timezone}`);
    }

    getAssets(period: number) {
        return this.http.get(`${this.baseUrl}transaction/reporting/assets?period=${period}&timezone=${this.timezone}`);
    }

    getCashDeposits() {
        return this.http.get(`${this.baseUrl}transaction/reporting/deposits`);
    }

    getNumberOfTransactionsByPeriod(memberId: string,
        memberAccountId: string,
        state: TransactionState,
        types: TransactionType[],
        sources: TransactionSource[],
        period: number,
        minAmount: string,
        maxAmount: string,
        startDate: string,
        endDate: string,
        retailLocationId: string) {

        const params = `?memberId=${memberId}&memberAccountId=${memberAccountId}&states=${state ? [state] : ''}&types=${types}&sources=${sources}&period=${period}&minAmount=${minAmount}&maxAmount=${maxAmount}&startDate=${startDate}&endDate=${endDate}&retailLocationId=${retailLocationId || ''}&timezone=${this.timezone}`;
        return this.http.get(`${this.baseUrl}transaction/reporting/count${params}`);
    }

    getTransactionAmountByPeriod(memberId: string,
        memberAccountId: string,
        state: TransactionState,
        types: TransactionType[],
        sources: TransactionSource[],
        period: number,
        minAmount: string,
        maxAmount: string,
        startDate: string,
        endDate: string,
        retailLocationId: string) {

        const params = `?memberId=${memberId}&memberAccountId=${memberAccountId}&states=${state ? [state] : ''}&types=${types}&sources=${sources}&period=${period}&minAmount=${minAmount}&maxAmount=${maxAmount}&startDate=${startDate}&endDate=${endDate}&retailLocationId=${retailLocationId || ''}&timezone=${this.timezone}`;
        return this.http.get(`${this.baseUrl}transaction/reporting/amounts${params}`);
    }

    getRelatedTransactions(transactionId: string) : Observable<PagedResponse<Transaction>> {
        return this.http.get(`${this.baseUrl}transaction/${transactionId}/related`) as  Observable<PagedResponse<Transaction>>;
    }

    countAssignedRfis(period: number) {
        const params = `?period=${period}`;
        return this.http.get(`${this.baseUrl}transaction/reporting/rfiCount${params}&timezone=${this.timezone}`);
    }

    loadTransaction(transaction: Transaction, loadRelated?: boolean) {
        if (transaction.source === TransactionSource.EXTERNAL_TRANSACTION && !transaction.payorMemberId) {
            transaction.payorMemberName = 'Multiple Client Deposit';
        } else if (transaction.source === TransactionSource.RETAIL_TRANSACTION && !transaction.payorMemberId) {
            transaction.payorMemberName = 'Retail Deposit';
        }

        if (loadRelated) {
            this.getRelatedTransactions(transaction.id).subscribe((response: PagedResponse<Transaction>) => {
                if (response.totalElements > 0) {
                    transaction.relatedTransactions = response.content;
                }
            });
        }
    }

    getAccessToken(memberId: string) {
        return this.http.get(`${this.baseUrl}external_account?memberId=${memberId}`);
    }

    saveExternalAccount(accountToken: AccountToken) {
        return this.http.post(`${this.baseUrl}external_account`, accountToken);
    }

    getExternalAccountDetails(id: string) {
        return this.http.get(`${this.baseUrl}external_account/${id}`);
    }

    getTransactionSupportingDocuments(transactionId: string) : Observable<PagedResponse<SupportingDocument>> {
        return this.http.get(`${this.baseUrl}transaction/${transactionId}/document`) as Observable<PagedResponse<SupportingDocument>>;
    }

    saveTransactionSupportingDocument(transactionId: string, supportingDocument: SupportingDocument) : Observable<SupportingDocument> {
        return this.http.post(`${this.baseUrl}transaction/${transactionId}/document`, supportingDocument) as Observable<SupportingDocument>;
    }

    deleteTransactionSupportingDocument(transactionId: string, supportingDocumentId: string){
        return this.http.delete(`${this.baseUrl}transaction/${transactionId}/document/${supportingDocumentId}`);
    }

    exportTransactionList(memberId: string,
        memberAccountId: string,
        state: TransactionState,
        transactionTypes: TransactionType[],
        sources: TransactionSource[],
        startDate: string,
        endDate: string,
        retailLocationId: string,
        minAmount: string,
        maxAmount: string,
        exportAccountName: boolean) {

        const params = `?memberId=${memberId}&memberAccountId=${memberAccountId}&states=${state ? [state] : ''}&types=${transactionTypes}&sources=${sources}&startDate=${startDate}&endDate=${endDate}&retailLocationId=${retailLocationId}&minAmount=${minAmount}&maxAmount=${maxAmount}&exportAccountName=${exportAccountName}&timezone=${this.timezone}`;
        return this.http.getDownload(`${this.baseUrl}transaction/reporting/export${params}`);
    }

    saveBillingProfile(billingProfile: BillingProfile) {
        return this.http.post(`${this.baseUrl}billing-profile`, billingProfile);
    }

    getBillingProfileByMemberAccountId(memberAccountId: string) : Observable<BillingProfile> {
        return  this.http.get(`${this.baseUrl}billing-profile/account/${memberAccountId}`) as Observable<BillingProfile>;
    }

    getCheckDetailsById(checkId: string) : Observable<CheckDetails> {
        return this.http.get(`${this.baseUrl}check/${checkId}`) as Observable<CheckDetails>;
    }

    getVoidCheckForMemberAccount(memberAccountId: string, element: ElementRef, memberName: string, memberAccountName: string) {
        return this.http.getDownload(`${this.baseUrl}check/${memberAccountId}/void`).subscribe((response: HttpResponse<Blob>) => {
            const binaryData = [];
            binaryData.push(response);
            const url = window.URL.createObjectURL(new Blob(binaryData, {type: 'blob'}));
            const link = element.nativeElement;
            link.href = url;
            link.download = `${memberName} (${memberAccountName}) - Confia Void Check.pdf`;
            link.click();
            window.URL.revokeObjectURL(url);
        });
    }

    getCheckCancellationStatus(checkId: string) {
        return this.http.get(`${this.baseUrl}check/${checkId}/status`);
    }

    getStopPaymentFeeAmount() {
        return this.http.get(`${this.baseUrl}configuration/payments`);
    }

    getBNPLLoanDetails(start: number, size: number, sort: string, sortDir: string) {
        return this.http.get(`${this.baseUrl}bnpl?start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`);
    }

    getPaymentCompletionDate(transactionType: TransactionType, isScheduledPayment: boolean, scheduledPaymentDate: string) {
        return this.http.get(`${this.baseUrl}holidays?transactionType=${transactionType}&isScheduledPayment=${isScheduledPayment}&scheduledPaymentDate=${scheduledPaymentDate}`);
    }

    getSummaryReportForAccount(memberAccountId: string) {
        return this.http.get(`${this.baseUrl}transaction/reporting/summary?memberAccountId=${memberAccountId}`);
    }

    getExternalTransactionReference(transactionId: string) {
        return this.http.get(`${this.baseUrl}transaction/externalTransactionReference/${transactionId}`);
    }

    getOutstandingCheckAmountForMemberAccount(memberAccountId: string) {
        return this.http.get(`${this.baseUrl}transaction/${memberAccountId}/outstanding`);
    }

    getCurrencyWithdrawal(transactionId: string) {
        return this.http.get(`${this.baseUrl}transaction/currency_withdrawal/${transactionId}`);
    }

    getCurrencyWithdrawalDisabledDates() {
        return this.http.get(`${this.baseUrl}holidays/list`);
    }
}
