<div class="col-sm-12 horizontal-scroll mat-table-container mt-3">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="reconciliations" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="generatedDate">
            <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header> Date </th>
            <td mat-cell class="centered" *matCellDef="let element"> {{element.generatedDate | date}} </td>
        </ng-container>
        <ng-container matColumnDef="fboAccountBalance">
            <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header> FBO Account Balance </th>
            <td mat-cell class="centered" *matCellDef="let element"><span class="table-amount"> {{element.fboAccountBalance | currency}}</span></td>
        </ng-container>
        <ng-container matColumnDef="subLedgerBalance">
            <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header> SubLedger Balance </th>
            <td mat-cell class="centered" *matCellDef="let element"><span class="table-amount"> {{element.subLedgerBalance | currency}}</span></td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header> Reconciliation Status </th>
            <td mat-cell class="centered" *matCellDef="let element"> <pc-report-badge [parent]="element"></pc-report-badge> </td>
        </ng-container>
        <ng-container matColumnDef="reconFile">
            <th mat-header-cell class="centered" *matHeaderCellDef> Reconciliation File </th>
            <td mat-cell class="centered" *matCellDef="let element">
                <a *ngIf="element.documentDetails" (click)="downloadFile(element)">
                    <i class="fa fa-file-excel-o excel_icon"></i>
                </a>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
</div>
<a class="report-download-link" #downloadReportLink></a>
