<div class="session-expiry">
    <div class="modal-header">
        <div class="row no-gutters col-12 mb-3">
            <div class="col-11 pull-left">
                <label class="form-title">Session Expiry</label>
            </div>
            <div class="col-1 pull-right"><i class="modal-close" (click)="close()">&times;</i></div>
        </div>
    </div>
    <div class="m-5">
        Your session is about to expire.  Please click 'Continue Working' to extend your session.
    </div>
    <div class="modal-footer">
        <button class="blue-button" (click)="refreshSession()">Continue Working</button>
    </div>
</div>