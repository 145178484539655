import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
    Consumer, CorporateEntity, Member, MemberFunction, MemberMetaInfo, MemberType, RiskRating, RiskTier, TaxInfo, MemberRegistrationType, CustomValidators
} from 'projects/services/src/public-api';

@Injectable({
    providedIn: 'root'
})
export class MemberFormService {

    constructor(private formbuilder: UntypedFormBuilder) {
    }

    initializeForm(member: Member): UntypedFormGroup {
        const form = this.formbuilder.group({
            idCtrl: new UntypedFormControl(),
            createdCtrl: new UntypedFormControl(),
            approvedDateCtrl: new UntypedFormControl(),

            briefDescriptionCtrl: new UntypedFormControl('', member?.memberRegistrationType !== MemberRegistrationType.CONSUMER ? [Validators.required, Validators.minLength(5), Validators.maxLength(510), CustomValidators.noLeadingOrTrailingWhitespace] : [Validators.minLength(5), Validators.maxLength(510), CustomValidators.noLeadingOrTrailingWhitespace]),
            websiteCtrl: new UntypedFormControl(''),
            naicsCodeCtrl: new UntypedFormControl(''),
            nameCtrl: new UntypedFormControl('', member?.memberRegistrationType !== MemberRegistrationType.CONSUMER ? [Validators.required, CustomValidators.memberName, Validators.maxLength(250)] : []),
            dbaNameCtrl: new UntypedFormControl('', [CustomValidators.noLeadingOrTrailingWhitespace, Validators.maxLength(250)]),
            previousNameCtrl: new UntypedFormControl(''),
            taxIdCtrl: new UntypedFormControl('', [CustomValidators.noLeadingOrTrailingWhitespace, Validators.pattern('^([0-9]{9})$')]),
            businessTypeCtrl: new UntypedFormControl('', member?.memberRegistrationType !== MemberRegistrationType.CONSUMER ? [Validators.required] : []),
            emailCtrl: new UntypedFormControl('', [CustomValidators.email]),
            officePhoneCtrl: new UntypedFormControl('', member?.memberRegistrationType !== MemberRegistrationType.CONSUMER ? [Validators.required, CustomValidators.phone] : [CustomValidators.phone]),
            isPublicCtrl: new UntypedFormControl(),
            accountingSystemCtrl: new UntypedFormControl(),
            linkBankAccountCtrl: new UntypedFormControl(),
            businessCategoryCtrl: new UntypedFormControl(''),
            statusCtrl: new UntypedFormControl(),
            referralCtrl: new UntypedFormControl(),
            refereeCtrl: new UntypedFormControl(),
            typeCtrl: new UntypedFormControl(''),
            sourceCtrl: new UntypedFormControl(),
            dobCtrl: new UntypedFormControl(),
            memberFunctionCtrl: new UntypedFormControl(),
            tierCtrl: new UntypedFormControl(RiskTier.NOT_TIERED, [Validators.required]),
            ratingCtrl: new UntypedFormControl(RiskRating.NOT_RATED, [Validators.required]),
            jurisdictionCtrl: new UntypedFormControl(''),
            memberTypeCtrl: new UntypedFormControl(''),
            paymentProcessorCtrl: new UntypedFormControl(''),
            firstNameCtrl: new UntypedFormControl('', [CustomValidators.personName, Validators.maxLength(250)]),
            middleNameCtrl: new UntypedFormControl('', [CustomValidators.middleName, Validators.maxLength(250)]),
            lastNameCtrl: new UntypedFormControl('', [CustomValidators.personName, Validators.maxLength(250)]),

            eddRiskLevelCtrl: new UntypedFormControl('')
        });
        if (member) {
            let accountingSystem: string;
            let linkBankAccount: boolean;
            let paymentProcessor: string;

            if (member.metaInfo) {
                if (member.metaInfo.accountingSystem) {
                    accountingSystem = member.metaInfo.accountingSystem;
                }
                if (member.metaInfo.linkBankAccount !== null) {
                    linkBankAccount = member.metaInfo.linkBankAccount;
                }
                if (member.metaInfo?.paymentProcessor) {
                    paymentProcessor = member.metaInfo.paymentProcessor;
                }
            }
            form.patchValue({
                idCtrl: member.id,
                createdCtrl: member.created,
                approvedDateCtrl: member.approvedDate,

                taxIdCtrl: member.taxId,
                emailCtrl: member.email,
                officePhoneCtrl: member.phone,
                accountingSystemCtrl: accountingSystem || '',
                linkBankAccountCtrl: linkBankAccount,
                statusCtrl: member.status,
                referralCtrl: member.referralCode,
                refereeCtrl: member.referredCode,
                typeCtrl: member.memberType || '',
                sourceCtrl: member.source,
                memberFunctionCtrl: member.memberFunctions,
                tierCtrl: member.tier || RiskTier.NOT_TIERED,
                ratingCtrl: member.rating || RiskRating.NOT_RATED,
                jurisdictionCtrl: member.jurisdiction || '',
                memberTypeCtrl: member.memberRegistrationType,
                briefDescriptionCtrl: member.briefDescription,
                businessCategoryCtrl: member.businessCategory,
                paymentProcessorCtrl: paymentProcessor
            });
            if (member.memberType === MemberType.CONSUMER || member.memberType === MemberType.INDIVIDUAL_MERCHANT) {
                form.patchValue({
                    firstNameCtrl: (member as Consumer).firstName,
                    middleNameCtrl: (member as Consumer).middleName,
                    lastNameCtrl: (member as Consumer).lastName,
                    dobCtrl: (member as Consumer).dateOfBirth
                });
            } else {
                form.patchValue({
                    websiteCtrl: (member as CorporateEntity).companyUrl,
                    naicsCodeCtrl: (member as CorporateEntity).naicsCode,
                    nameCtrl: (member as CorporateEntity).businessName,
                    dbaNameCtrl: (member as CorporateEntity).dbaName,
                    previousNameCtrl: (member as CorporateEntity).previousName,
                    businessTypeCtrl: (member as CorporateEntity).businessType || '',
                    isPublicCtrl: (member as CorporateEntity).isPublic
                });
            }
            if (member?.memberRegistrationType !== MemberRegistrationType.CONSUMER) {
                form.controls['naicsCodeCtrl'].setValidators([Validators.required, Validators.pattern('^([0-9]{5,7})$'), Validators.min(10000), Validators.max(1000000)]);
            }
        }
        return form;
    }

    getMember(form: UntypedFormGroup): Member {
        const metaInfo: MemberMetaInfo = new MemberMetaInfo();
        const member = new Member();
        member.id = form.get('idCtrl').value;
        member.status = form.get('statusCtrl').value;
        member.memberType = form.get('typeCtrl').value;
        member.memberRegistrationType = form.get('memberTypeCtrl').value;
        member.referralCode = form.get('referralCtrl').value;
        member.referredCode = form.get('refereeCtrl').value;
        member.taxId = form.get('taxIdCtrl').value;
        member.email = form.get('emailCtrl').value;
        member.phone = form.get('officePhoneCtrl').value;
        member.tier = form.get('tierCtrl').value;
        member.rating = form.get('ratingCtrl').value;
        member.businessCategory = form.get('businessCategoryCtrl').value || null;
        member.briefDescription = form.get('briefDescriptionCtrl').value;
        if (form.get('approvedDateCtrl')) {
            member.approvedDate = form.get('approvedDateCtrl').value;
        }
        if (form.get('createdCtrl')) {
            member.created = form.get('createdCtrl').value;
        }

        if (member.memberType === MemberType.BUSINESS || member.memberType === MemberType.BUSINESS_MERCHANT || member.memberType === MemberType.FEE_ACCOUNT) {
            (member as CorporateEntity).companyUrl = form.get('websiteCtrl').value;
            (member as CorporateEntity).naicsCode = form.get('naicsCodeCtrl').value;
            (member as CorporateEntity).businessName = form.get('nameCtrl').value;
            (member as CorporateEntity).dbaName = form.get('dbaNameCtrl').value;
            (member as CorporateEntity).previousName = form.get('previousNameCtrl').value;
            (member as CorporateEntity).businessType = form.get('businessTypeCtrl').value || null;
            (member as CorporateEntity).isPublic = form.get('isPublicCtrl').value;
        } else if (member.memberType === MemberType.INDIVIDUAL_MERCHANT || member.memberType === MemberType.CONSUMER) {
            (member as Consumer).firstName = form.get('firstNameCtrl').value ? form.get('firstNameCtrl').value.trim() : null;
            (member as Consumer).middleName = form.get('middleNameCtrl').value ? form.get('middleNameCtrl').value.trim() : null;
            (member as Consumer).lastName = form.get('lastNameCtrl').value ? form.get('lastNameCtrl').value.trim() : null;
            (member as Consumer).dateOfBirth = form.get('dobCtrl').value;

        }
        member.source = form.get('sourceCtrl').value;
        if (form.get('accountingSystemCtrl').value) {
            metaInfo.accountingSystem = form.get('accountingSystemCtrl').value;
        }
        if (form.get('linkBankAccountCtrl').value !== null) {
            metaInfo.linkBankAccount = form.get('linkBankAccountCtrl').value;
        }
        if (form.get('paymentProcessorCtrl')?.value) {
            metaInfo.paymentProcessor = form.get('paymentProcessorCtrl').value;
        }

        member.metaInfo = metaInfo;

        member.memberFunctions = form.get('memberFunctionCtrl').value;
        member.jurisdiction = form.get('jurisdictionCtrl').value || null;
        return member;
    }

    initializeTaxForm(taxInfo: TaxInfo): UntypedFormGroup {
        const form = this.formbuilder.group({
            idCtrl: new UntypedFormControl(),
            memberIdCtrl: new UntypedFormControl(),
            einCtrl: new UntypedFormControl('', [Validators.required, Validators.pattern('^([0-9]{9})$')]),
            stateTaxIdCtrl: new UntypedFormControl('', [CustomValidators.noLeadingOrTrailingWhitespace]),
            taxPermitNoCtrl: new UntypedFormControl('', [CustomValidators.noLeadingOrTrailingWhitespace])
        });
        if (taxInfo) {
            form.patchValue({
                idCtrl: taxInfo.id,
                memberIdCtrl: taxInfo.memberId,
                einCtrl: taxInfo.ein,
                stateTaxIdCtrl: taxInfo.stateTaxId,
                taxPermitNoCtrl: taxInfo.taxPermitNo
            });
        }
        return form;
    }

    getTaxInfo(form: UntypedFormGroup): TaxInfo {
        const taxInfo = new TaxInfo();
        taxInfo.id = form.get('idCtrl').value;
        taxInfo.memberId = form.get('memberIdCtrl').value;
        taxInfo.ein = form.get('einCtrl').value;
        taxInfo.stateTaxId = form.get('stateTaxIdCtrl').value;
        taxInfo.taxPermitNo = form.get('taxPermitNoCtrl').value;
        return taxInfo;
    }

    initializeMemberFunctionForm(member: Member): UntypedFormGroup {
        return this.formbuilder.group({
            partnerCtrl: new UntypedFormControl(member.memberFunctions.indexOf(MemberFunction.PARTNER) >= 0),
            lenderCtrl: new UntypedFormControl(member.memberFunctions.indexOf(MemberFunction.LENDER) >= 0)
        });
    }

}
