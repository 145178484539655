<fieldset class="form-group" *ngIf="formCtrl">
  <div class="row">
    <div class="col-12">
      <label class="form-label">{{ label }}</label>
    </div>
    <div class="col-12" [formGroup]="formGroup">
        <input #inputField type="text" class="form-control form-input-editable" [required]="required" (keydown)="onInputChanged($event)" (blur)="onBlur($event)"
            [class.ng-invalid]="!isValid()" [class.ng-dirty]="isDirty()" [disabled]="isDisabled()"/>
        <input type="hidden" [formControlName]="formCtrl" (change)="onPhoneChanged()"/>
    </div>
  </div>
</fieldset>
