import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { UntypedFormGroup } from '@angular/forms';
import {
    Transaction,
    RefundRequest,
    RefundTransactionFormService,
    WorkflowService
} from 'projects/services/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pc-refund-modal',
    templateUrl: './refund-modal.component.html'
})
export class RefundModalComponent extends BaseModalComponent<RefundModalComponent> implements OnInit {

    transaction: Transaction;
    refundForm: UntypedFormGroup;
    reasonLength = 256;

    constructor(dialogRef: MatDialogRef<RefundModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any,
                private workflowService: WorkflowService,
                private notifier: NotificationService,
                private refundTransactionFormService: RefundTransactionFormService) {
        super(dialogRef);
        this.transaction = data.transaction;
    }

    ngOnInit() {
        this.confirm = this.confirm.bind(this);
        this.close = this.close.bind(this);
        this.cancel = this.cancel.bind(this);
        this.formValid = this.formValid.bind(this);
        this.isAmountGreaterThanTransaction = this.isAmountGreaterThanTransaction.bind(this);
        this.refundForm = this.refundTransactionFormService.initializeForm(this.transaction, this.reasonLength);
    }

    formValid() {
        return this.refundForm.valid;
    }

    isAmountGreaterThanTransaction() {
        return this.transaction.totalAmount < this.refundForm.controls['amountCtrl'].value;
    }

    isReasonLengthNotValid() {
        return this.refundForm.controls['reasonCtrl'].value.length > this.reasonLength;
    }

    confirm(reset: any) {
        if (this.refundForm.valid) {
            const refundTransaction = new RefundRequest();
            refundTransaction.transactionId = this.transaction.id;
            refundTransaction.reason = this.refundForm.controls['reasonCtrl'].value;
            refundTransaction.amount = this.refundForm.controls['amountCtrl'].value;
            this.workflowService.processWalletRefund(refundTransaction)
                .subscribe(() => {
                    this.close(true);
                }, (error: any) => {
                    reset();
                    this.notifier.showError(error.error);
                });
        }
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    cancel() {
        super.close(false);
    }
}
