
import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class DiligenceService {

    baseUrl: string;

    constructor(@Inject('environment') environment: any, private http: HttpService) {
        this.baseUrl = environment.EntityAPIEndpoint + '/diligence';
    }

    getHistory(memberId: string, start: number = 0, size: number = 100, sortField: string = 'created', sortDir: string = 'DESC') {
        const params = `start=${start}&size=${size}&sort=${sortField}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}/${memberId}/history?${params}`);
    }

    updateDiligenceHistory(id: string, updates: any) {
        return this.http.put(`${this.baseUrl}/history/${id}`, updates);
    }
}
