import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FieldMetadata } from 'projects/services/src/public-api';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';

@Component({
    selector: 'pc-sc-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {

    @Input() enforceRequired = true;
    @Input() formGroup: UntypedFormGroup;
    @Input() metadata: FieldMetadata;

    @Output() changed: EventEmitter<FieldMetadata> = new EventEmitter<FieldMetadata>();

    displayValue = new Date().toISOString();
    fieldControl: UntypedFormControl;
    curDate = new Date();

    myOptions: IAngularMyDpOptions = {
        dateRange: false,
        dateFormat: 'mm/dd/yyyy',
        disableUntil: { year: this.curDate.getFullYear(), month: this.curDate.getMonth() + 1, day: this.curDate.getDate() - 1 }
    };

    ngOnInit() {
        const validators = [];
        if (this.enforceRequired && this.metadata.required) {
            validators.push(Validators.required);
        }
        this.fieldControl = new UntypedFormControl('', validators);
        this.formGroup.addControl(this.metadata.id, this.fieldControl);
        if (this.metadata.dateValue) {
            const model: IMyDateModel = {isRange: false, singleDate: {jsDate: new Date(this.metadata.dateValue)}, dateRange: null};
            this.fieldControl.setValue(model);
        }
    }

    fieldChanged(event: IMyDateModel) {
        this.metadata.dateValue = event?.singleDate?.jsDate;
        this.changed.emit(this.metadata);
    }

    onClick(event: any) {
        event.target.blur();
        event.preventDefault();
    }
}
