<div>
    <div class="row">
        <div class="col-12 my-3">
            <span class="form-title">Profit And Loss</span>
        </div>
        <div class="row">
            <div class="col-4">
                <pc-form-date-picker [label]="'Latest Month'"
                    [formCtrl]="'dateCtrl'"
                    [formGroup]="formGroup"
                    [type]="'SINGLE'"
                    [historical]="true"
                    [defaultToday]="true"
                    (dateSelected)="onDateChanged($event)">
                </pc-form-date-picker>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">Period Length</label>
                    </div>
                    <div class="col-12">
                        <select class="form-control full" [(ngModel)]="periodLength"  (change)="changedPeriods()">
                            <option value="1">1 month</option>
                            <option value="2">2 month</option>
                            <option value="3">3 month</option>
                            <option value="6">6 month</option>
                            <option value="12">12 month</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">Periods To Compare</label>
                    </div>
                    <div class="col-12">
                        <select class="form-control full" [(ngModel)]="periodsToCompare"  (change)="changedPeriods()">
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="horizontal-scroll mat-table-container">
    <table class="mat-table no-wrap  mat-sort table-sm">
        <thead class="mat-sort-header mat-header-cell">
            <tr class="mat-header-row cdk-header-row">
                <th class="mat-header-cell"></th>
                <ng-container *ngFor="let profitAndLossReport of profitReports">
                    <th class="mat-header-cell">
                        <span class="text-nowrap">{{profitAndLossReport.fromDate | date:'mediumDate'}}</span> - <span class="text-nowrap">{{profitAndLossReport.toDate | date:'mediumDate'}}</span>
                    </th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr class="mat-row mat-cell">
                <td class="mat-cell">
                    <b>Income</b>
                    <i *ngIf="isIncomeShow && profitReports[0]?.income?.items?.length" (click)="expandIncome()"
                        class="fa fa-angle-up"></i>
                    <i *ngIf="!isIncomeShow && profitReports[0]?.income?.items?.length" (click)="expandIncome()"
                        class="fa fa-angle-down"></i>
                </td>
                <ng-container *ngFor="let profitAndLossReport of profitReports">
                    <td class="mat-cell centered">
                        <span class="table-amount">
                            <b>{{profitAndLossReport.income.value | currency}}</b>
                        </span>
                    </td>
                </ng-container>
            </tr>

            <ng-container *ngIf="profitReports && isIncomeShow">
                <tr *ngFor="let financialItem of profitReports[0].income.items; let i = index" class="mat-row">
                    <td class="mat-cell">
                        <div class="indent-sm">{{financialItem.name}}</div>
                    </td>
                    <ng-container *ngFor="let profitReport of profitReports">
                        <td class="mat-cell centered">
                            <span class="table-amount">
                                {{(profitReport.income.items[i] ? profitReport.income.items[i].value : 0) | currency}}
                            </span>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>

            <tr class="mat-row mat-cell">
                <td class="mat-cell">
                    <b>Cost of Sales</b>
                    <i *ngIf="isCostOfSalesShow && profitReports[0]?.costOfSales?.items?.length"
                        (click)="expandCostOfSales()" class="fa fa-angle-up"></i>
                    <i *ngIf="!isCostOfSalesShow && profitReports[0]?.costOfSales?.items?.length"
                        (click)="expandCostOfSales()" class="fa fa-angle-down"></i>
                </td>
                <ng-container *ngFor="let profitAndLossReport of profitReports">
                    <td class="mat-cell centered">
                        <span class="table-amount">
                            <b>{{profitAndLossReport.costOfSales.value | currency}}</b>
                        </span>
                    </td>
                </ng-container>
            </tr>

            <ng-container *ngIf="profitReports && isCostOfSalesShow">
                <tr *ngFor="let financialItem of profitReports[0].costOfSales.items; let i = index" class="mat-row">
                    <td class="mat-cell">
                        <div class="indent-sm">{{financialItem.name}}</div>
                    </td>
                    <ng-container *ngFor="let profitReport of profitReports">
                        <td class="mat-cell centered">
                            <span class="table-amount">
                                {{(profitReport.costOfSales.items[i] ? profitReport.costOfSales.items[i].value : 0) | currency}}
                            </span>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>

            <tr class="mat-row mat-cell">
                <td class="mat-cell">
                    <b>Gross Profit</b>
                </td>
                <ng-container *ngFor="let profitAndLossReport of profitReports" class="text-center">
                    <td class="mat-cell centered">
                        <span class="table-amount"><b>{{profitAndLossReport.grossProfit | currency}}</b></span>
                    </td>
                </ng-container>
            </tr>

            <tr class="mat-row mat-cell">
                <td class="mat-cell">
                    <b>Expenses</b>
                    <i *ngIf="isExpensesShow && profitReports[0]?.expenses?.items?.length" (click)="expandExpenses()"
                        class="fa fa-angle-up"></i>
                    <i *ngIf="!isExpensesShow && profitReports[0]?.expenses?.items?.length" (click)="expandExpenses()"
                        class="fa fa-angle-down"></i>
                </td>
                <ng-container *ngFor="let profitAndLossReport of profitReports">
                    <td class="mat-cell centered">
                        <span class="table-amount"><b>{{profitAndLossReport.expenses.value | currency}}</b></span>
                    </td>
                </ng-container>
            </tr>

            <ng-container *ngIf="profitReports && isExpensesShow">
                <tr *ngFor="let financialItem of profitReports[0].expenses.items; let i = index" class="mat-row">
                    <td class="mat-cell">
                        <div class="indent-sm">{{financialItem.name}}</div>
                    </td>
                    <ng-container *ngFor="let profitReport of profitReports">
                        <td class="mat-cell centered">
                            <span class="table-amount">{{(profitReport.expenses.items[i] ? profitReport.expenses.items[i].value : 0) | currency}}</span>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>

            <tr class="mat-row mat-cell">
                <td class="mat-cell">
                    <b>Net Operating Profit</b>
                </td>
                <ng-container *ngFor="let profitAndLossReport of profitReports">
                    <td class="mat-cell centered">
                        <span class="table-amount"><b>{{profitAndLossReport.netOperatingProfit | currency}}</b></span>
                    </td>
                </ng-container>
            </tr>

            <tr class="mat-row mat-cell">
                <td class="mat-cell">
                    <b>Other Expenses</b>
                    <i *ngIf="isOtherExpensesShow && profitReports[0]?.otherExpenses?.items?.length"
                        (click)="expandOtherExpenses()" class="fa fa-angle-up"></i>
                    <i *ngIf="!isOtherExpensesShow && profitReports[0]?.otherExpenses?.items?.length"
                        (click)="expandOtherExpenses()" class="fa fa-angle-down"></i>
                </td>
                <ng-container *ngFor="let profitAndLossReport of profitReports">
                    <td class="mat-cell centered">
                        <span class="table-amount"><b>{{profitAndLossReport.otherExpenses.value | currency}}</b></span>
                    </td>
                </ng-container>
            </tr>

            <ng-container *ngIf="profitReports && isOtherExpensesShow">
                <tr *ngFor="let financialItem of profitReports[0].otherExpenses.items; let i = index" class="mat-row">
                    <td class="mat-cell">
                        <div class="indent-sm">{{financialItem.name}}</div>
                    </td>
                    <ng-container *ngFor="let profitReport of profitReports">
                        <td class="mat-cell centered">
                            <span class="table-amount">{{(profitReport.otherExpenses.items[i] ? profitReport.otherExpenses.items[i].value : 0) | currency}}</span>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>

            <tr class="mat-row mat-cell">
                <td class="mat-cell">
                    <b>Other Income</b>
                </td>
                <ng-container *ngFor="let profitAndLossReport of profitReports">
                    <td class="mat-cell centered">
                        <span class="table-amount"><b>{{profitAndLossReport.otherIncome.value | currency}}</b></span>
                    </td>
                </ng-container>
            </tr>

            <tr class="mat-row mat-cell">
                <td class="mat-cell">
                    <b>Net Other Income</b>
                </td>
                <ng-container *ngFor="let profitAndLossReport of profitReports">
                    <td class="mat-cell centered">
                        <span class="table-amount"><b>{{profitAndLossReport.netOtherIncome | currency}}</b></span>
                    </td>
                </ng-container>
            </tr>

            <tr class="mat-row mat-cell">
                <td class="mat-cell">
                    <b>Net Profit</b>
                </td>
                <ng-container *ngFor="let profitAndLossReport of profitReports">
                    <td class="mat-cell centered">
                        <span class="table-amount"><b>{{profitAndLossReport.netProfit | currency}}</b></span>
                    </td>
                </ng-container>
            </tr>
        </tbody>
    </table>
</div>
