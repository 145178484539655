<div class="row">
    <div class="col-6">
        <pc-authority-transaction-score-gadget></pc-authority-transaction-score-gadget>
    </div>
    <div class="col-6">
        <pc-authority-transaction-type-gadget></pc-authority-transaction-type-gadget>
    </div>
    <div class="col-6">
        <pc-authority-transaction-volume-gadget></pc-authority-transaction-volume-gadget>
    </div>
    <div class="col-6">
        <pc-authority-assets-gadget></pc-authority-assets-gadget>
    </div>
    <div class="col-12">
        <pc-dashboard-alert [title]="'Cash Deposits'"
            [typeMeta]="'$'"
            [link]="'/dashboard'"
            [valueOne]="cashDeposits"
            [titleMeta]="'cumulative'"
            [icon]="'assets/images/gear-icon.svg'"></pc-dashboard-alert>
    </div>
</div>
