export class ExternalTransactionReference {

    externalTxnId: string;
    transactionId: string;
    paymentNote1: string;
    paymentNote2: string;
    paymentRemittanceInfo: string;
    fileName: string;
    fileCreationDate: string;
    fileCreationTime: string;
    fileIdModifier: string;
    batchNumber: number;
    standardEntryClassCode: string;
    traceId: string;
    transactionCode: number;
    cashLetterId: string;
    bundleId: string;
}
