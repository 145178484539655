import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Address, AddressService, OnboardingTrackingService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-locations',
    templateUrl: './locations.component.html'
})
export class MemberLocationsComponent implements OnInit {

    @Input() memberAccountId: string;
    @Input() task: any;
    @Input() readOnly = false;
    @Input() allowAdd = true;
    @Input() changeTrigger: number;

    @Output() next: EventEmitter<string> = new EventEmitter<string>();

    constructor(private addressService: AddressService) {}

    ngOnInit() {
        this.onNavigate = this.onNavigate.bind(this);
    }

    onNavigate() {
        this.next.emit();
    }

    setLocationDetailsValid(addresses: Address[]) {
        OnboardingTrackingService.setLocationInfo(addresses);
        this.addressService.getPrimaryAddress(this.memberAccountId).subscribe(
            (address: Address) => {
                OnboardingTrackingService.setMemberAddress(address);
            }
        );
    }
}
