import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowService, Task } from 'projects/services/src/public-api';
import { Router } from '@angular/router';

@Component({
    selector: 'pc-sc-invite-business-parties',
    templateUrl: './invite-business-parties.component.html',
    styleUrls: ['./invite-business-parties.component.scss']
})
export class InviteBusinessPartiesComponent implements OnInit {

    @Input() task: Task;

    @Output() taskChanged: EventEmitter<Task> = new EventEmitter<Task>();

    invitees: string[] = [];

    constructor(private router: Router,
                private workflowService: WorkflowService) {}

    ngOnInit() {
        if (this.workflowService.getCurrentTask()) {
            this.task = this.workflowService.getCurrentTask();
            this.taskChanged.emit(this.task);
        }
    }

    shareTemplate(inviteAll: boolean) {
        this.workflowService.completeTask(this.task.id, this.requestBody(inviteAll))
            .subscribe(() => {
                this.workflowService.getTaskByProcessInstanceId(this.task.processInstanceId)
                    .subscribe(
                        () => {
                            this.router.navigate(['/smart_contracts/my_templates']);
                        }
                    );
            });
    }

    requestBody(inviteAll: boolean): any {
        const inviteeIds = [];
        if (inviteAll) {
            inviteeIds.push('All');
        } else {
            this.invitees.forEach((invitee) => {
                return inviteeIds.push(invitee);
            });
        }
        return {
            variables: {
                action: {
                    value: 'invite',
                    type: 'String'
                },
                invitees: {
                    value: JSON.stringify(inviteeIds),
                    type: 'Object',
                    valueInfo: {
                        objectTypeName: 'java.util.List',
                        serializationDataFormat: 'application/json'
                    }

                }
            }
        };
    }

}
