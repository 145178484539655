<div class="py-4">
    <div class="row">
        <div class="col-auto me-auto">
            <p class="form-title">Other Searches</p>
        </div>
        <div class="col-auto">
            <button pc-no-dbl-click class="blue-button pull-right" type="button" [disabled]="isNewSearchButtonEnabled()" (click)="onNewSearchClicked()" submit-button> NEW SEARCH</button>
        </div>
    </div>

    <pc-datatable matSort matSortDisableClear
                  [dataSource]="searchResult | async"
                  [columns]="displayedColumns"
                  [isLoading]="isLoadingResults"
                  [totalElements]="totalElements"
                  [options]="tableOptions"
                  (paginationChanged)="loadData()"
                  (sortChanged)="loadData()">

        <ng-container matColumnDef="search_term">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="searchTerm"> Search Term </th>
            <td mat-cell *matCellDef="let row"> {{ row.searchTerm }} </td>
        </ng-container>

        <ng-container matColumnDef="subject_entity">
            <th mat-header-cell *matHeaderCellDef> Subject Entity </th>
            <td mat-cell *matCellDef="let row"> {{ findKeyPartyBySearchResult(row)?.name }} </td>
        </ng-container>

        <ng-container matColumnDef="comply_advantage">
            <th mat-header-cell *matHeaderCellDef class="centered"> Comply Advantage </th>
            <td mat-cell *matCellDef="let row" class="centered">
                <pc-kyc-search-result-button [searchResult]="row"
                    [keyParty]="findKeyPartyBySearchResult(row)"
                    (updated)="loadData()">
                </pc-kyc-search-result-button>
            </td>
        </ng-container>
    </pc-datatable>
</div>
