<div class="col-12">
    <pc-user-table-header
        [memberId]="memberId"
        (userChanged)="onUserChanged()">
    </pc-user-table-header>
    <pc-member-user-list
        [memberId]="memberId"
        [memberFunctions]="memberFunctions"
        [changeTrigger]="changeTrigger">
    </pc-member-user-list>
</div>
