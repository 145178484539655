import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { MerchantAccountConfig, MerchantAccountSource } from './models/merchant-account-config';
import { MerchantAccount } from './models/merchant-account';
import { MemberAccountStatus } from './models/member-account';
import { Observable } from 'rxjs';
import { PagedResponse } from './models/paged-response';
import { SupportedTransactionType } from './models/transaction';
import { BusinessClient } from './models/business-client';
import { MemberType } from '../public-api';

@Injectable({
    providedIn: 'root'
})
export class MerchantAccountService {
    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.EntityAPIEndpoint + '/';
    }

    findBusinessClients(name: string,
        memberId: string,
        types: SupportedTransactionType[],
        includeConfiaMerchants: boolean,
        searchLimit?: number): Observable<{error: string, businessClients: BusinessClient<any>[]}> {

        const merchantSources: MerchantAccountSource[] = [MerchantAccountSource.CONFIA, MerchantAccountSource.ACCOUNTING, MerchantAccountSource.EXTERNAL_TRANSACTION];
        const params = `?memberId=${memberId}&name=${encodeURIComponent(name)}&types=${types}&sources=${merchantSources}&includeConfiaMerchants=${includeConfiaMerchants}&size=${searchLimit || ''}`;
        return this.http.get(`${this.baseUrl}business_client/search${params}`) as Observable<{error: string, businessClients: BusinessClient<any>[]}>;
    }

    getMerchantAccountById(merchantAccountId: string) {
        return this.http.get(`${this.baseUrl}merchant_account/${merchantAccountId}`);
    }

    findMerchantAccounts(name: string,
        memberId: string,
        types: SupportedTransactionType[],
        status: MemberAccountStatus,
        sources: MerchantAccountSource[],
        merchantMemberType: MemberType,
        searchLimit?: number): Observable<PagedResponse<MerchantAccount>> {

        const params = `?memberId=${memberId || ''}&name=${encodeURIComponent(name)}&types=${types}&status=${status || ''}&sources=${sources}&merchantMemberType=${merchantMemberType || ''}&size=${searchLimit || ''}`;
        return this.http.get(`${this.baseUrl}merchant_account/search${params}`) as Observable<PagedResponse<MerchantAccount>>;
    }

    getMerchantAccounts(memberId: string, types: SupportedTransactionType[], start: number, size: number, sort: string, sortDir: string) {
        return this.http.get(`${this.baseUrl}merchant_account?memberId=${memberId}&types=${types}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`);
    }

    getAllMerchantAccountsByMerchantMemberIdAndMemberId(merchantMemberId: string, memberId: string) {
        return this.http.get(`${this.baseUrl}merchant_account?merchantMemberId=${merchantMemberId}&memberId=${memberId}`);
    }

    getAllMerchantAccountsByMerchantMemberAccountIdAndMemberId(merchantMemberAccountId: string, memberId: string) {
        return this.http.get(`${this.baseUrl}merchant_account?merchantMemberAccountId=${merchantMemberAccountId}&memberId=${memberId}`);
    }

    getAllMerchantAccountsByAccountingExternalId(memberId: string, accountingExternalId: string) {
        return this.http.get(`${this.baseUrl}merchant_account?memberId=${memberId}&accountingExternalId=${accountingExternalId}`);
    }

    getMerchantAccountsByMerchantMemberId(merchantMemberId: string,
        start: number,
        size: number,
        sort: string,
        sortDir: string) {

        return this.http.get(`${this.baseUrl}merchant_account?merchantMemberId=${merchantMemberId}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`);
    }

    getMerchantAccountConfigsByMerchantMemberId(merchantMemberId: string,
        start: number,
        size: number,
        sort: string,
        sortDir: string) {

        return this.http.get(`${this.baseUrl}merchant_account/config?merchantMemberId=${merchantMemberId}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`);
    }

    findMerchantsByFuzzyName(name: string, jurisdiction: string, merchantMemberType: MemberType, searchLimit?: number): Observable<PagedResponse<MerchantAccountConfig>> {
        let params = `?name=${encodeURI(name)}&size=${searchLimit || ''}&merchantMemberType=${merchantMemberType || ''}`;
        if (jurisdiction) {
            params += `&jurisdiction=${jurisdiction}`;
        }
        return this.http.get(`${this.baseUrl}merchant_account/config/search${params}`) as Observable<PagedResponse<MerchantAccountConfig>>;
    }

    getSimilarMerchantAccountConfigs(merchantMemberId: string, merchantMemberType: MemberType) {
        const params = `?merchantMemberType=${merchantMemberType}`;
        return this.http.get(`${this.baseUrl}merchant_account/config/${merchantMemberId}/similar${params}`);
    }

    linkMerchantAndMember(merchantAccount: MerchantAccount) {
        return this.http.post(`${this.baseUrl}merchant_account/link`, merchantAccount);
    }

    updateMerchantAccount(id: string, body: any) {
        return this.http.put(`${this.baseUrl}merchant_account/${id}`, body);
    }

    getMerchantAccountConfigByMerchantMemberId(merchantMemberId: string): Observable<MerchantAccountConfig> {
        return this.http.get(`${this.baseUrl}merchant_account/config/${merchantMemberId}`) as Observable<MerchantAccountConfig>;
    }

    getMerchantAccountConfigAddress(merchantAccountConfigId: string) {
        return this.http.get(`${this.baseUrl}merchant_account/config/${merchantAccountConfigId}/address`);
    }

    updateMerchantAccountConfig(id: string, body: any) {
        return this.http.put(`${this.baseUrl}merchant_account/config/${id}`, body);
    }

    countActiveAccounts(merchantMemberId: string,
        status: MemberAccountStatus,
        sources: MerchantAccountSource[]) {

        const params = `?merchantMemberId=${merchantMemberId}&status=${status}&sources=${sources}`;
        return this.http.head(`${this.baseUrl}merchant_account${params}`);
    }

    deleteMerchant(merchantAccountConfigId: string) {
        return this.http.delete(`${this.baseUrl}merchant_account/config/${merchantAccountConfigId}?audit=true`);
    }

    loadMerchantContactAndLicenseInfo(memberId: string) {
        return this.http.get(`${this.baseUrl}merchant_account/merchant-contact-info/${memberId}`);
    }
}
