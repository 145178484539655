import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, Role } from 'projects/services/src/public-api';
import { AuthorityAuditComponent } from './components';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'authority/list',
        pathMatch: 'full'
    },
    {
        path: 'authority/list',
        component: AuthorityAuditComponent,
        canActivate: [AuthGuard],
        data: { title: 'Auditing', roles: [Role.AUTHORITY] }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuditRoutingModule {
}
