<div [class]="getMenuClass()">
    <div class="row">
        <ng-container *ngFor="let action of menuActions">
            <div class="col-12">
                <pc-side-menu-link [text]="action.title"
                    [icon]="action.icon"
                    [link]="action.link"
                    [queryParams]="action.queryParams"
                    [expandedMenu]="expandedMenu"></pc-side-menu-link>
            </div>
        </ng-container>
        <div class="col-12">
            <div class="sidenav-arrow-content">
                    <div class="row" *ngIf="expandedMenu">
                        <div class="col-3 pull-right">
                            <img class="sidenav-arrow" src="assets/images/collapse-icon.svg" (click)="onToggleExpandSideMenu()" alt="*">
                        </div>
                    </div>
                    <div class="row" *ngIf="!expandedMenu">
                        <div class="col-12">
                            <img class="sidenav-arrow" src="assets/images/expand-icon.svg" (click)="onToggleExpandSideMenu()" alt="*">
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>
