<div>
    <table mat-table [dataSource]="contracts" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> 
                <a class="contract-link" (click)="viewContract(element)">{{element.name}}</a>
            </td>
        </ng-container>

        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="centered"> Created </th>
            <td mat-cell *matCellDef="let element" class="centered"> {{element.created | date}} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef class="centered"> Amount </th>
            <td mat-cell *matCellDef="let element"><span class="table-amount"><pc-transaction-amount [transaction]="element.transaction"></pc-transaction-amount></span> </td>
        </ng-container>

        <ng-container matColumnDef="currentState">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="centered"> Status </th>
            <td mat-cell *matCellDef="let element" class="centered"><pc-sc-contract-status [status]="element.currentState"></pc-sc-contract-status></td>
        </ng-container>

        <ng-container matColumnDef="buyer">
            <th mat-header-cell *matHeaderCellDef> Buyer </th>
            <td mat-cell *matCellDef="let element"> {{element.buyer?.name}} </td>
        </ng-container>

        <ng-container matColumnDef="seller">
            <th mat-header-cell *matHeaderCellDef> Seller </th>
            <td mat-cell *matCellDef="let element"> {{element.seller?.name}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="resultsLength" [pageSize]="20"></mat-paginator>

</div>
