<div>
    <form>
        <div>
            <div class="modal-header">
                <label class="form-title">Edit Loan</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content">
                <div class="row">
                    <div class="col-6">
                            <label class="form-label">Company Name</label>
                        <div class=" form-input-editable inputGroup">
                            {{ loan.name }}
                        </div>
                    </div>
                    <div class="col-6">
                            <label class="form-label">Company Type</label>
                        <div class=" form-input-editable inputGroup">
                            {{ companyType }}
                        </div>
                    </div>
                    <div class="col-6">
                        <pc-text-option-selector [required]="false" [label]="'Status'"
                                                 [options]="arrayOfStatusesOnCurrentStatus(loan.status)"
                                                 [formCtrl]="'statusCtrl'" [formGroup]="loanFormGroup"
                                                 [defaultValue]="loan.status" [translated]="true"
                                                 (change)="onStatusChange()">
                        </pc-text-option-selector>
                    </div>

                    <div class="col-6">
                        <pc-form-amount-input [required]="false" [label]="'Loan Amount'" [formCtrl]="'amountCtrl'"
                                              [formGroup]="loanFormGroup">
                        </pc-form-amount-input>
                    </div>
                    <div class="col-6">
                        <pc-form-amount-input [dollar]="false" [required]="false" [label]="'Interest Rate %'"
                                              [formCtrl]="'interestRateCtrl'"
                                              [formGroup]="loanFormGroup">
                        </pc-form-amount-input>
                    </div>
                    <div class="col-6">
                        <pc-form-amount-input [required]="false" [label]="'Recurring Payment Amount'"
                            [formCtrl]="'recurringPaymentAmountCtrl'"
                            [formGroup]="loanFormGroup">
                        </pc-form-amount-input>
                    </div>
                    <div class="col-6">
                        <pc-form-date-picker [label]="'Payment Start Date'"
                            [changeTrigger]="changeTrigger"
                            [formCtrl]="'paymentStartDateCtrl'"
                            [formGroup]="loanFormGroup"
                            [defaultEmpty]="!paymentStartDate"
                            (dateSelected)="onPaymentStartDateChanged($event)">
                        </pc-form-date-picker>
                    </div>
                    <div class="col-6">
                        <pc-form-date-picker [label]="'Maturity'"
                            [formCtrl]="'maturityCtrl'"
                            [changeTrigger]="changeTrigger"
                            [formGroup]="loanFormGroup"
                            [defaultEmpty]="!maturityDate"
                            (dateSelected)="onMaturityChanged($event)">
                        </pc-form-date-picker>
                    </div>
                    <div class="col-6">
                        <pc-text-option-selector [required]="false" [label]="'Payment Frequency'" [translated]="true"
                            [options]="paymentFrequencies" [formCtrl]="'paymentFrequencyCtrl'"
                            [formGroup]="loanFormGroup" [defaultValue]="loan.paymentFrequency">
                        </pc-text-option-selector>
                    </div>
                    <div class="col-12">
                        <pc-simple-checkbox *ngIf="isClosedOrDeclined()"
                            [label]="'I would like to close/decline this loan. This decision cannot be reversed.'"
                            [formCtrl]="'acknowledgedCtrl'" [formGroup]="loanFormGroup"
                            [invert]="true"></pc-simple-checkbox>
                    </div>
                </div>
                <div class="row">
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="validateForm" [submit]="confirm" [text]="'Submit'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
