<div id="container-fastlink">
</div>
<div class="results" *ngIf="results" [innerHTML]="results">
</div>
<div class="buttons" *ngIf="results">
    <div *ngIf="duplicate || rejected || unsupported || systemError">
        <button class="blue-button" (click)="onRetry()" type="button" [ngStyle]="buttonStyle()">Link Another Account</button>
        <button class="blue-button" (click)="onCancel()" type="button" [ngStyle]="buttonStyle()">Cancel</button>
    </div>
</div>
