<div>
    <form [formGroup]="newAccountForm">
        <div>
            <div class="modal-header">
                <label class="form-title">Request New Account</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div>
                    <pc-text-input [label]="'Account Name (Internal)'" [formCtrl]="'accountNameCtrl'" [formGroup]="newAccountForm">
                    </pc-text-input>
                    <pc-text-input [label]="'Account Friendly Name (Public)'" [formCtrl]="'accountFriendlyNameCtrl'" [formGroup]="newAccountForm">
                    </pc-text-input>
                    <pc-simple-checkbox [label]="'Accept payments from other members'" [formCtrl]="'isPublicCtrl'" [formGroup]="newAccountForm" [invert]="true">
                    </pc-simple-checkbox>
                    <pc-text-area *ngIf="!authService.isAuthorityOrReviewer()" [label]="'Purpose of Account'" [formCtrl]="'notesCtrl'" [formGroup]="newAccountForm" [placeholder]="''">
                    </pc-text-area>
                </div>
                <div *ngIf="!authService.isAuthorityOrReviewer()">
                    <pc-simple-checkbox [class]="isAcknowledged() ? '' : 'required'" [label]="'I acknowledge that there will be an additional $50 fee per month for subsequent accounts'" [formCtrl]="'acknowledgeCtrl'" [formGroup]="newAccountForm" [invert]="true">
                    </pc-simple-checkbox>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="isNewAccountFormValid" [submit]="onSubmit" [text]="'Submit'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
