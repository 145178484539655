import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Bill, BusinessClient, BusinessClientStatus, TransactionType } from 'projects/services/src/public-api';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { IMyDateModel } from 'angular-mydatepicker';
import { PaymentDateType } from '../review-transaction-step.component';

@Component({
    selector: 'pt-payment-method',
    templateUrl: './payment-method.component.html',
    styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit, OnChanges {

    readonly PAYMENT_DATE_TYPE_OPTIONS = [{
        id: PaymentDateType.PAY_NOW,
        name: 'Pay Now'
    }, {
        id: PaymentDateType.SCHEDULE_PAYMENT,
        name: 'Schedule Payment'
    }];

    @Input() businessClients: BusinessClient<Bill>[] = [];
    @Input() formGroup: UntypedFormGroup;
    @Input() paymentMethods: { id: TransactionType, name: string }[];

    @Output() dateSelected: EventEmitter<Date> = new EventEmitter<Date>();
    @Output() transactionTypeChange: EventEmitter<null> = new EventEmitter<null>();

    requiresDueDiligence = false;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        const currentBusinessClient = this.businessClients[0];
        this.requiresDueDiligence = currentBusinessClient.status !== BusinessClientStatus.ACTIVE;
        this.formGroup.controls['paymentDateTypeCtrl'].setValue(PaymentDateType.PAY_NOW);
        this.formGroup.controls['transactionDateCtrl'].setValue({ isRange: false, singleDate: { jsDate: new Date() }, dateRange: null });
        if (this.requiresDueDiligence) {
            this.formGroup.controls['paymentDateTypeCtrl'].disable();
        }
        this.formGroup.controls['transactionTypeCtrl'].setValue(currentBusinessClient.defaultTransactionType);
        this.formGroup.get('memberAccountNameCtrl').disable();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.requiresDueDiligence && !changes.requiresDueDiligence.firstChange) {
            if (this.requiresDueDiligence) {
                this.formGroup.controls['paymentDateTypeCtrl'].patchValue(PaymentDateType.PAY_NOW);
                this.formGroup.controls['paymentDateTypeCtrl'].disable();
                this.formGroup.controls['transactionDateCtrl'].setValidators(null);
                this.formGroup.controls['transactionDateCtrl'].disable();
                this.dateChanged({ isRange: false, singleDate: { jsDate: null }, dateRange: null });
            } else {
                this.formGroup.controls['paymentDateTypeCtrl'].enable();
                this.formGroup.controls['transactionDateCtrl'].disable();
            }
        }
        this.cdr.detectChanges();
        this.formGroup.updateValueAndValidity();
    }

    onPaymentDateTypeChange(payDateType: PaymentDateType) {
        if (payDateType === PaymentDateType.PAY_NOW) {
            this.formGroup.controls['transactionDateCtrl'].setValidators(null);
            this.formGroup.controls['transactionDateCtrl'].disable();
            this.dateChanged({ isRange: false, singleDate: { jsDate: null }, dateRange: null });
        } else if (payDateType === PaymentDateType.SCHEDULE_PAYMENT) {
            this.formGroup.controls['transactionDateCtrl'].setValidators([Validators.required]);
            this.formGroup.controls['transactionDateCtrl'].enable();
        }
        this.formGroup.controls['transactionDateCtrl'].patchValue(null);
        this.cdr.detectChanges();
        this.formGroup.updateValueAndValidity();
        this.dateChanged(this.formGroup.controls['transactionDateCtrl'].value);
    }

    showDatePicker() {
        return this.formGroup.controls['paymentDateTypeCtrl'].value === PaymentDateType.SCHEDULE_PAYMENT;
    }

    dateChanged(event: IMyDateModel) {
        this.dateSelected.emit(event?.singleDate?.jsDate);
    }

    transactionTypeChanged(_transactionType?: TransactionType) {
        this.transactionTypeChange.emit();
    }
}
