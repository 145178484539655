import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FieldMetadata, Utils } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-sc-enumerated-select',
    templateUrl: './enumeration.component.html'
})
export class EnumerationInputComponent implements OnInit {

    @Input() enforceRequired = true;
    @Input() formGroup: UntypedFormGroup;
    @Input() metadata: FieldMetadata;

    @Output() changed: EventEmitter<FieldMetadata> = new EventEmitter<FieldMetadata>();

    fieldControl: UntypedFormControl;
    safariFix: boolean;

    ngOnInit() {
        this.safariFix = Utils.isSafari();
        const validators = [];
        if (this.enforceRequired && this.metadata.required) {
            validators.push(Validators.required);
        }
        this.fieldControl = new UntypedFormControl('', validators);
        this.formGroup.addControl(this.metadata.id, this.fieldControl);
        this.fieldControl.setValue(this.metadata.stringValue);
    }

    fieldChanged(e: any) {
        this.metadata.stringValue = e.target.value;
        this.changed.emit(this.metadata);
    }

}
