<div class="col-lg-12 col-md-12 col-sm-12 dashboard-info">
    <div class="row d-flex justify-content-start mb-3 mt-3">
        <div class="col-auto dashboard-header">
            <img class="dashboard-icon" src="assets/images/email-icon-2.svg" alt="">
            <span>Messages</span>
        </div>
        <div class="col-auto">
            <select class="form-control form-input-table-header" type="text" [(ngModel)]="messageType" (change)="onTypeChanged()">
                <option [value]="MessageType.ALL">All</option>
                <option [value]="MessageType.RECEIVED">Received</option>
                <option [value]="MessageType.SENT">Sent</option>
            </select>
        </div>
        <div *ngIf="isMemberSelectorVisible()" class="col-auto">
            <pc-member-selector [formGroup]="formGroup" [excludeSelf]="true" [displayLabel]="false" [formCtrl]="'memberCtrl'" [placeholder]="'Search Members'"
                [memberTypes]="[MemberType.BUSINESS]" [statuses]="[MemberStatus.ACTIVE, MemberStatus.DISABLED]" (memberSelected)="memberSelected($event)">
            </pc-member-selector>
        </div>
        <div class="col-auto">
            <pc-form-date-picker [formCtrl]="'dateCtrl'"
                [formGroup]="formGroup" [type]="'RANGED'" (dateSelected)="onDateChanged($event)">
            </pc-form-date-picker>
        </div>
        <div *ngIf="memberStatus !== MemberStatus.DISABLED" class="col-auto d-flex justify-content-end">
            <button class="blue-button" *ngIf="authService.hasAnyRole([Role.AUTHORITY, Role.MEMBER])" (click)="onAddNewMessage()" type="button"><i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">New Message</div></button>&nbsp;&nbsp;
        </div>
    </div>
    <div class="col-sm-12 horizontal-scroll mat-table-container">
        <div class="mat-table-spinner" *ngIf="isLoadingResults">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table [dataSource]="messages" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir" multiTemplateDataRows>
            <ng-container matColumnDef="readStatus">
                <th class="centered unread-col" mat-header-cell *matHeaderCellDef mat-sort-header> Unread </th>
                <td class="centered unread-col" mat-cell *matCellDef="let element"><i *ngIf="element.recipientMemberId === currentMemberId" class="fa" [ngClass]="element.readStatus ? 'fa-star-o' : 'fa-star'" (click)="handleClick($event, element, true)"></i></td>
            </ng-container>
            <ng-container matColumnDef="subject">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Subject </th>
                <td mat-cell *matCellDef="let element" [ngClass]="getReadStatusClass(element)"> {{ element.subject }} </td>
            </ng-container>
            <ng-container matColumnDef="senderMember">
                <th mat-header-cell *matHeaderCellDef> Sender Member </th>
                <td mat-cell *matCellDef="let element" [ngClass]="getReadStatusClass(element)">
                    <div>
                        {{ element.senderName }}
                    </div>
                    <div>
                        {{ element.senderMemberName }}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="recipientMember">
                <th mat-header-cell *matHeaderCellDef> Recipient Member </th>
                <td mat-cell *matCellDef="let element" [ngClass]="getReadStatusClass(element)">
                    <div>
                        {{ element.recipientName }}
                    </div>
                    <div>
                        {{ element.recipientMemberName }}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="created">
                <th class="centered" mat-header-cell  *matHeaderCellDef mat-sort-header> Date </th>
                <td class="centered" mat-cell *matCellDef="let element" [ngClass]="getReadStatusClass(element)"> {{ element.created | date:'mediumDate'}} </td>
            </ng-container>
            <ng-container matColumnDef="attachment">
                <th mat-header-cell  *matHeaderCellDef mat-sort-header="fileName"> Attachment </th>
                <td mat-cell *matCellDef="let element" [ngClass]="getReadStatusClass(element)">
                    <a *ngIf="element.document" class="dashboard-list-a"
                       (click)="downloadFile(element.document)">
                        <i class="fa fa-paperclip"></i>{{element.document.fileName}}
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                        <ng-container *ngIf="expandedElement === element">
                            <pc-message-row [message]="element" (refreshPage)="refreshPage()" [messageType]="messageType"> </pc-message-row>
                        </ng-container>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-expand-row" [class.example-expanded-row]="expandedElement === element"
                (click)="handleClick($event, element, false)">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
        <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
        <a class="message-download-link" #downloadMessageLink></a>
    </div>
</div>
