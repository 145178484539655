import {Inject, Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Domain} from './models/domain';

@Injectable({
    providedIn: 'root'
})
export class DomainService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.PartnerAPIEndpoint + '/api/';
    }

    createNewDomain(domain: Domain) {
        return this.http.post(`${this.baseUrl}domain`, domain);
    }

    deleteDomainById(id: string) {
        return this.http.delete(`${this.baseUrl}domain/${id}`);
    }

    loadDomainsByMemberId(memberId: string) {
        return this.http.get(`${this.baseUrl}domain?memberId=${memberId}&start=0&size=10&sort=created&sortDir=DESC`);
    }
}
