import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Transaction, TransactionStatus, TransactionType } from '../models/transaction';
import { CustomValidators } from '../validators/custom.validators';
import { MemberAccount } from '../models/member-account';

@Injectable({
    providedIn: 'root'
})
export class AuthorityTransactionFormService {

    constructor(private formBuilder: UntypedFormBuilder) { }

    initializeForm(transactionType: TransactionType): UntypedFormGroup {
        return this.formBuilder.group({
            idCtrl: new UntypedFormControl(),
            memberAccountIdCtrl: new UntypedFormControl('', Validators.required),
            memberAccountNameCtrl: new UntypedFormControl('', Validators.required),
            memberNameCtrl: new UntypedFormControl('', Validators.required),
            transactionDateCtrl: new UntypedFormControl(),
            amountCtrl: new UntypedFormControl('', [Validators.required, Validators.min(0.01)]),
            transactionTypeCtrl: new UntypedFormControl(transactionType, Validators.required),
            sourceCtrl: new UntypedFormControl(),
            notesCtrl: new UntypedFormControl('', [Validators.required, Validators.maxLength(256), CustomValidators.noLeadingOrTrailingWhitespace]),
            reasonCodeCtrl: new UntypedFormControl()
        });
    }

    getTransaction(form: UntypedFormGroup, counterPartyMemberAccount: MemberAccount): Transaction {
        const transaction = new Transaction();
        transaction.totalAmount = form.get('amountCtrl').value;
        transaction.notes = form.get('notesCtrl').value;
        transaction.status = TransactionStatus.PENDING_SCORING;
        transaction.pointTrustScore = 0;
        let reasonCode = form.get('reasonCodeCtrl').value;
        if (reasonCode) {
            transaction.reasonCode =  reasonCode;
        }

        const paymentType: TransactionType = form.get('transactionTypeCtrl').value;
        const memberAccountId = form.get('memberAccountIdCtrl').value;
        if (paymentType === TransactionType.ACCOUNT_CREDIT || paymentType === TransactionType.TRANSACTION_CREDIT  || paymentType === TransactionType.DEPOSIT_ADJUSTMENT_ADDITION) {
            if (paymentType === TransactionType.DEPOSIT_ADJUSTMENT_ADDITION && counterPartyMemberAccount) {
                transaction.payorAccountId = counterPartyMemberAccount.id;
                transaction.payorMemberId = counterPartyMemberAccount.memberId;
                transaction.payorMemberName = counterPartyMemberAccount.member.name;
            }
            transaction.recipientAccountId = memberAccountId;
        } else {
            if (paymentType === TransactionType.DEPOSIT_ADJUSTMENT_DEDUCTION && counterPartyMemberAccount) {
                transaction.recipientAccountId = counterPartyMemberAccount.id;
                transaction.recipientMemberId = counterPartyMemberAccount.memberId;
                transaction.recipientMemberName = counterPartyMemberAccount.member.name;
            }
            transaction.payorAccountId = memberAccountId;
        }
        return transaction;
    }
}
