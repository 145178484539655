<div class="col-12">
    <div class="row dashboard-info">

        <div class="col-12 dashboard-header">
            <img class="dashboard-icon" src="assets/images/settings-icon-2.svg" alt="">
            <span>Settings</span>
        </div>

        <div class="row col-12">
            <div class="col-3 onboard-nav">
                <div class="col-12 tab-col" [ngClass]="getTabClass('bank_accounts')" (click)="activeTab = 'bank_accounts'">
                    Banking
                </div>
            </div>
            <div class="col-9 tab-body" *ngIf="activeTab === 'bank_accounts'">
                <pt-bank-accounts></pt-bank-accounts>
            </div>
        </div>
    </div>
</div>
