import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService, CommentService, MemberType, OnboardingTrackingService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-internal-alerts',
    templateUrl: './internal-alerts.component.html',
    styleUrls: ['./internal-alerts.component.scss']
})
export class InternalAlertsComponent implements OnInit {

    MemberType = MemberType;

    @Input() memberId: string;
    @Input() memberType: MemberType;
    @Input() task: any;
    @Input() changeTrigger: number;
    @Input() memberReadOnly = false;

    @Output() next: EventEmitter<string> = new EventEmitter<string>();

    constructor(public authService: AuthService,
                private commentService: CommentService) {}

    ngOnInit() {
        this.onNavigate = this.onNavigate.bind(this);
    }

    onNavigate() {
        this.next.emit();
    }

    onAlertReviewed() {
        this.commentService.countActiveAlerts(this.memberId).subscribe((response: any) => {
            OnboardingTrackingService.setInternalAlertsCount(Number(response.headers.get('Content-Length')));
        });
    }
}
