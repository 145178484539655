<div class="horizontal-scroll mat-table-container">
    <div class="table mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="merchantConfigs" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="business_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="merchantMemberAccount.member.sortName"> Merchant Name </th>
            <td mat-cell *matCellDef="let element">
                <img *ngIf="element.merchantMemberAccount.member.memberType === MemberType.INDIVIDUAL_MERCHANT" class="member-type-icon" src="assets/images/profile-icon.svg" title="Individual">
                <a (click)="merchantDetails($event, element.merchantMemberAccount.memberId, element.merchantMemberAccount.member.status, element.merchantMemberAccount.member.memberType)" class="link">
                    <b> {{element.merchantMemberAccount.member.name}} </b>
                    <br>
                    <b *ngIf="element.merchantMemberAccount.member.dbaName">
                        DBA: {{ element.merchantMemberAccount.member.dbaName }}
                    </b>
                </a>
                <pc-business-address *ngIf="element.merchantMemberAccount.member.address" [address]="element.merchantMemberAccount.member.address">
                </pc-business-address>
            </td>
        </ng-container>
        <ng-container matColumnDef="contact_info">
            <th mat-header-cell *matHeaderCellDef> Contact Info </th>
            <td mat-cell *matCellDef="let element">
                <div>
                    <span *ngIf="element.merchantMemberAccount.member.email"><b>Email:</b> {{element.merchantMemberAccount.member.email}}<br></span>
                    <span *ngIf="element.merchantMemberAccount.member.phone"><b>Phone:</b> {{element.merchantMemberAccount.member.phone | phone}}</span>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="transaction_types">
            <th mat-header-cell *matHeaderCellDef> Transaction Types </th>
            <td mat-cell *matCellDef="let element">
                <ul>
                    <li *ngIf="element.defaultOutgoingTransactionType"> {{ element.defaultOutgoingTransactionType | translate }} </li>
                    <li *ngIf="element.defaultIncomingTransactionType"> {{ element.defaultIncomingTransactionType | translate }} </li>
                </ul>
            </td>
        </ng-container>
        <ng-container matColumnDef="notifications">
            <th mat-header-cell *matHeaderCellDef class="centered"> Active Accounts </th>
            <td mat-cell *matCellDef="let element" class="centered"> {{ element.merchantMemberAccount.member.notificationCount }} </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="centered" mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let element" class="centered">
                <pc-merchant-config-badge [parent]="element"></pc-merchant-config-badge>
                <div *ngIf="element.merchantMemberAccount.member.jurisdiction">{{ Utils.getJurisdictionName(element.merchantMemberAccount.member.jurisdiction) }}</div>
                <div *ngIf="element.merchantMemberAccount.member.tier === RiskTier.TIER_1">Tier 1</div>
                <p> {{ element.shared ? '' : '(private)' }} </p>
            </td>
        </ng-container>
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="centered"> Action </th>
            <td mat-cell *matCellDef="let element" class="centered">
                <i *ngIf="(element.status === MemberAccountStatus.ACTIVE || element.status === MemberAccountStatus.DISABLED) && authService.isAuthority()" class="fa fa-2x" [class.fa-toggle-on]="isEnabled(element)" [class.fa-toggle-off]="!isEnabled(element)" (click)="toggleEnabled($event, element)"></i>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.disabled]="!isEnabled(row)"></tr>
    </table>
    <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
</div>
