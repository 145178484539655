import { Component, Input, OnInit } from '@angular/core';
import { Activity, LenderService, LoanStatus, MemberFunction, MemberService, Member } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-loans',
    templateUrl: './loans.component.html'
})
export class LoansComponent implements OnInit {

    @Input() memberId: string;

    member: Member;
    isLender = false;
    loanCount = 0;

    constructor(private memberService: MemberService,
                private lenderService: LenderService) {}

    ngOnInit() {
        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            this.member = member;
            this.isLender = member.memberFunctions.indexOf(MemberFunction.LENDER) >= 0;
        });

        this.lenderService.activityCountByLenderIdAndStatuses(this.memberId, [LoanStatus.PENDING, LoanStatus.DILIGENCE, LoanStatus.ACTIVE]).subscribe((opportunities: Activity) => {
            if (opportunities) {
                this.loanCount = opportunities.quantity;
            }
        });
    }

    hasLoans() {
        return this.loanCount > 0;
    }
}
