<div id="member-details" class="col-12 dashboard-info" *ngIf="memberId">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div *ngIf="isAuthorityOrReviewer() && member">
                    <div class="dashboard-header">
                        {{ member.name }} <span *ngIf="member.jurisdiction"> ({{member.jurisdiction}})</span>
                    </div>
                    <div class="pull-right" *ngIf="taskId && isAuthority()">
                        Assigned to: <pc-authority-workflow-assign [processInstanceId]="processInstanceId" [taskId]="taskId" [assignee]="assignee" [authorityUsers]="authorityUsers"
                            (noteAdded)="onNoteAdded()">
                        </pc-authority-workflow-assign>
                    </div>
                </div>
                <div *ngIf="!isAuthorityOrReviewer()" class="dashboard-header"><img class="dashboard-icon" src="assets/images/member-icon-2.svg" alt="">Member Details</div>
            </div>
        </div>
        <div class="col-3 pb-3">
            <div class="row">
                <div *ngIf="isAuthorityOrReviewer() && member"><pc-member-badge [parent]="member"></pc-member-badge></div>
            </div>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="memberId && (task === 'onboard' || task === 'adjudicate')" [title]="'Administrator'" [tabId]="'administrator'" [tabClass]="getTabClass" [tabValid]="isAdminDetailsValid" [tabDirty]="isAdminDetailsDirty" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="(!isApplyingOrUnderReview() || hasPendingAccounts) && isAuthorityOrReviewer()" [title]="'Accounts'" [tabId]="'accounts'" [tabClass]="getTabClass" [tabValid]="isAccountsDetailsValid" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" [title]="'Business'" [tabId]="'business'" [tabClass]="getTabClass" [tabValid]="isBusinessDetailsValid" [tabDirty]="isBusinessDetailsDirty" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="!isFeeAccount() && member?.memberType !== MemberType.CONSUMER" [title]="'Key Parties'" [tabId]="'key_parties'" [tabClass]="getTabClass" [tabValid]="isKeyPartiesValid" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" [title]="'Locations'" [tabId]="'locations'" [tabClass]="getTabClass" [tabValid]="isLocationDetailsValid" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="!isFeeAccount()"  [title]="'Licenses'" [tabId]="'licenses'" [tabClass]="getTabClass" [tabValid]="isLicenseDetailsValid" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="!isFeeAccount()" [title]="'Financial'" [tabId]="'financial'" [tabClass]="getTabClass" [tabValid]="isFinancialDetailsValid" [tabDirty]="isFinancialDetailsDirty" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="!isFeeAccount()" [title]="'Documents'" [tabId]="'documents'" [tabClass]="getTabClass" [tabValid]="isDocumentsValid" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="isAuthorityOrReviewer() && !isFeeAccount()" [title]="'Internal KYC'" [tabId]="'internal'" [tabClass]="getTabClass" [tabValid]="isInternalDetailsValid" [tabDirty]="isInternalDetailsDirty" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="isAuthorityOrReviewer() && !isFeeAccount()" [title]="'Internal Alerts'" [tabId]="'internal_alerts'" [tabClass]="getTabClass" [tabValid]="isInternalAlertsValid" [tabDirty]="isInternalAlertsDirty" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="!isFeeAccount()" [title]="'Questions'" [tabId]="'questions'" [tabClass]="getTabClass" [tabValid]="isQuestionsValid" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="task === 'onboard' && !isAuthorityOrReviewer()" [title]="'Submit'" [tabId]="'submit'" [tabClass]="getTabClass" [tabValid]="isAllValid" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="isAdjudicateTabVisible()" [title]="'Adjudicate'" [tabId]="'adjudicate'" [tabClass]="getTabClass" [tabValid]="isAdjudicateValid" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <div *ngIf="taskId && isAuthority()" [class]="getTabClass('workflow_notes')" (click)="onSwitchTabs('workflow_notes')">
                Assignment Notes
            </div>
            <pc-onboard-tab class="col-12 tab-col onboard-nav" *ngIf="!isApplyingOrUnderReview() && isAuthorityOrReviewer() && !isFeeAccount()" [title]="'Banking Details'" [tabId]="'banking_details'" [tabClass]="getTabClass" [tabValid]="isBankingDetailsValid" (tabChange)="onSwitchTabs($event)"></pc-onboard-tab>
            <div class="col-12 mt-3"></div>
            <div *ngIf="!isApplyingOrUnderReview() && isAuthorityOrReviewer() && !isFeeAccount()" [class]="getTabClass('linked_systems')" (click)="onSwitchTabs('linked_systems')">
                Linked Systems
            </div>
            <div *ngIf="!isApplyingOrUnderReview()" [class]="getTabClass('compliance')" (click)="onSwitchTabs('compliance')">
                RFIs
            </div>
            <div *ngIf="!isApplyingOrUnderReview()" [class]="getTabClass('transactions')" (click)="onSwitchTabs('transactions')">
                Transactions
            </div>
            <div *ngIf="isAuthorityOrReviewer() && (isMemberActive() || isMemberDisabled() || member?.status === MemberStatus.UNDER_REVIEW || member?.status === MemberStatus.APPLIED)" [class]="getTabClass('users')" (click)="onSwitchTabs('users')">
                Users
            </div>
            <div *ngIf="!isApplyingOrUnderReview() && isTrackingSystemVisible()" [class]="getTabClass('metrc')" (click)="onSwitchTabs('metrc')">
                Metrc
            </div>
            <div *ngIf="!task && (isMemberActive() || isMemberDisabled()) && !isMemberPartner() && isAuthorityOrReviewer() && !isFeeAccount()" [class]="getTabClass('partners')" (click)="onSwitchTabs('partners')">
                Partners
            </div>
            <div *ngIf="!isApplyingOrUnderReview() && !isFeeAccount() && member?.memberType !== MemberType.CONSUMER && member?.memberType !== MemberType.BUSINESS_MERCHANT" [class]="getTabClass('reports')" (click)="onSwitchTabs('reports')">
                Reports
            </div>
            <div *ngIf="isMemberPartner() && !isFeeAccount() && isAuthorityOrReviewer()" [class]="getTabClass('partner_settings')" (click)="onSwitchTabs('partner_settings')">
                Partner Settings
            </div>
            <div *ngIf="!isApplyingOrUnderReview() && isAuthorityOrReviewer() && !isFeeAccount() " [class]="getTabClass('merchant_accounts')" (click)="onSwitchTabs('merchant_accounts')">
                Merchant Accounts
            </div>
            <div *ngIf="!isApplyingOrUnderReview() && isAuthorityOrReviewer() && !isFeeAccount() " [class]="getTabClass('financial_data')" (click)="onSwitchTabs('financial_data')">
                Financial Data
            </div>
            <div *ngIf="!isApplyingOrUnderReview() && isAuthorityOrReviewer() && !isFeeAccount() " [class]="getTabClass('loans')" (click)="onSwitchTabs('loans')">
                Loans
            </div>
            <div *ngIf="isAuthorityOrReviewer() && !isFeeAccount() " [class]="getTabClass('auditing')" (click)="onSwitchTabs('auditing')">
                Auditing
            </div>
        </div>

        <div class="col-9 tab-body" *ngIf="activeTab === 'accounts' && isAuthorityOrReviewer()">
            <pt-member-accounts-list [memberId]="memberId" [authorityView]="true"></pt-member-accounts-list>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'administrator'">
            <pt-administrator-details [readOnly]="!authService.isAuthorityOrAdmin()" *ngIf="memberId && (task === 'onboard' || task === 'adjudicate')" [task]="task"
                [userId]="userId" [memberId]="memberId" [changeTrigger]="changeTrigger" (next)="next()">
            </pt-administrator-details>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'business'">
            <pt-member-business-details *ngIf="memberId" [readOnly]="!authService.isAuthorityOrAdmin()" [memberId]="memberId" [changeTrigger]="changeTrigger"
                [task]="task" (next)="next()" (memberUpdated)="updateMember($event)">
            </pt-member-business-details>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'key_parties'">
            <pt-member-key-party-details *ngIf="member" [memberRegistrationType]="memberRegistrationType" [memberId]="memberId" [changeTrigger]="changeTrigger"
                (next)="next()" [task]="task" [memberType]="member.memberType" [memberStatus]="member?.status">
            </pt-member-key-party-details>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'locations'">
            <pt-member-locations *ngIf="memberId" [memberAccountId]="memberId" [changeTrigger]="changeTrigger"
                (next)="next()" [task]="task">
            </pt-member-locations>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'licenses'">
            <pt-member-licenses *ngIf="memberId" [readOnly]="!authService.isAuthorityOrAdmin()" [memberAccountId]="memberId" [changeTrigger]="changeTrigger"
                (next)="next()" [task]="task">
            </pt-member-licenses>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'financial'">
            <pt-member-financial-details *ngIf="member" [readOnly]="!authService.isAuthorityOrAdmin()"
                [memberStatus]="member.status" [memberId]="memberId" [memberRegistrationType]="member.memberRegistrationType" [changeTrigger]="changeTrigger"
                (next)="next()" [task]="task">
            </pt-member-financial-details>
        </div>
        <div *ngIf="activeTab === 'documents'" class="col-9 tab-body">
            <pt-member-other-documents *ngIf="member" [memberId]="memberId" [businessCategory]="businessCategory" [changeTrigger]="changeTrigger"
                (next)="next()" [task]="task">
            </pt-member-other-documents>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'internal' && isAuthorityOrReviewer()">
            <pt-member-internal-details *ngIf="member" [memberId]="memberId" [memberReadOnly]="!authService.isAuthority()" [memberType]="member.memberType"
                [task]="task" [changeTrigger]="changeTrigger" (next)="next()" (tierChanged)="tierChanged($event)">
            </pt-member-internal-details>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'internal_alerts' && isAuthorityOrReviewer()">
            <pt-internal-alerts *ngIf="member" [memberId]="memberId"
                [memberReadOnly]="!authService.isAuthority()" [memberType]="member.memberType" [task]="task"
                [changeTrigger]="changeTrigger" (next)="next()">
            </pt-internal-alerts>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'questions'">
            <div class="mb-5">
                <pt-member-message [member]="member"></pt-member-message>
            </div>
            <pt-onboarding-notes [memberId]="memberId" [changeTrigger]="changeTrigger" [task]="task"
                [applicationComplete]="true" (next)="next()" (notesDetailChange)="updateNotesFields()">
            </pt-onboarding-notes>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'submit' && !isAuthorityOrReviewer()">
            <pc-member-application-submit *ngIf="member" [memberId]="memberId" [businessCategory]="businessCategory" [taskId]="taskId" >
            </pc-member-application-submit>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'adjudicate'">
            <pt-member-application-decision [changeTrigger]="changeTrigger" [memberId]="memberId" [taskId]="taskId" [isPeriodicReviewTask]="isPeriodicReviewTask">
            </pt-member-application-decision>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'banking_details' && isAuthorityOrReviewer()">
            <pt-member-banking-details *ngIf="memberId" [allowAddBankAccount]="addBankAccountAllowed()" [accountNameRequired]="true" [memberId]="memberId" [changeTrigger]="changeTrigger">
            </pt-member-banking-details>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'compliance'">
            <pt-member-rfi [changeTrigger]="changeTrigger" [memberId]="memberId">
            </pt-member-rfi>
        </div>
        <div class="col-9 tab-body button-alignment" *ngIf="activeTab === 'transactions'">
            <pt-member-transactions [memberId]="memberId">
            </pt-member-transactions>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'users'">
            <pt-member-users *ngIf="member"
                [changeTrigger]="changeTrigger"
                [status]="member.status"
                [memberId]="memberId"
                [memberFunctions]="member.memberFunctions">
            </pt-member-users>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'metrc'">
            <pt-member-metrc-details [memberId]="memberId">
            </pt-member-metrc-details>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'partners'">
            <pt-partner-list *ngIf="member" [changeTrigger]="changeTrigger" [memberId]="memberId" [memberType]="member.memberType">
            </pt-partner-list>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'partner_settings'">
            <pt-partner-settings [changeTrigger]="changeTrigger" [memberId]="memberId" [readOnly]="!authService.isAuthorityOrAdmin()">
            </pt-partner-settings>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'merchant_accounts'">
            <pt-member-merchant-list *ngIf="member" [merchantActionsAllowed]="merchantActionsAllowed() && isMemberActive()" [memberId]="memberId" [memberPageView]="true"
                [jurisdiction]="member.jurisdiction" [tier]="member.tier">
            </pt-member-merchant-list>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'financial_data'">
            <pt-financial-data [memberId]="memberId">
            </pt-financial-data>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'loans'">
            <pt-loans [memberId]="memberId">
            </pt-loans>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'auditing' && isAuthorityOrReviewer()">
            <pt-member-audit [memberId]="memberId">
            </pt-member-audit>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'reports' && isAuthorityOrReviewer()">
            <pt-member-reports-table-header *ngIf="memberId" [memberId]="memberId">
            </pt-member-reports-table-header>
        </div>
        <div class="col-9 tab-body" *ngIf="activeTab === 'linked_systems' && member && isAuthorityOrReviewer()">
            <pt-member-linked-systems [requiresMrb]="metrcEnabled" [requiresAccounting]="true" [memberId]="memberId">
            </pt-member-linked-systems>
        </div>
        <div class="col-9 tab-body" *ngIf="taskId && activeTab === 'workflow_notes' && isAuthority()">
            <pc-authority-workflow-notes [processInstanceId]="processInstanceId" [changeTrigger]="notesTrigger">
            </pc-authority-workflow-notes>
        </div>
    </div>
</div>
