import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerInfo } from '../models/customer-info';
import { MemberAccount } from '../models/member-account';

@Injectable({
    providedIn: 'root'
})
export class BankingIdentificationFormService {

    constructor(private formbuilder: UntypedFormBuilder) {
    }

    initializeForm(customerInfo: CustomerInfo, memberAccount: MemberAccount): UntypedFormGroup {
        const form = this.formbuilder.group({
            idCtrl: new UntypedFormControl(''),
            createdCtrl: new UntypedFormControl(''),
            memberIdCtrl: new UntypedFormControl(memberAccount.memberId, [Validators.required]),
            memberAccountIdCtrl: new UntypedFormControl(memberAccount.id, [Validators.required]),
            customerIdCtrl: new UntypedFormControl('', [Validators.required]),
            accountIdCtrl: new UntypedFormControl('', [Validators.required])
        });
        if (customerInfo) {
            form.patchValue({
                idCtrl: customerInfo.id,
                createdCtrl: customerInfo.created,
                memberIdCtrl: customerInfo.memberId,
                customerIdCtrl: customerInfo.customerId,
                accountIdCtrl: customerInfo.accountId
            });
        }
        return form;
    }

    getCustomerInfo(form: UntypedFormGroup): CustomerInfo {
        const customerInfo = new CustomerInfo();
        customerInfo.id = form.controls['idCtrl'].value;
        customerInfo.created = form.controls['createdCtrl'].value;
        customerInfo.memberId = form.controls['memberIdCtrl'].value;
        customerInfo.memberAccountId = form.controls['memberAccountIdCtrl'].value;
        customerInfo.customerId = form.controls['customerIdCtrl'].value;
        customerInfo.accountId = form.controls['accountIdCtrl'].value;

        return customerInfo;
    }
}
