<div>
    <form [formGroup]="documentForm">
        <div>
            <div class="modal-header">
                <label class="form-title">Generate FinCEN Investigation Report</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div>
                    <pc-text-input [required]="true" [label]="'Persons File'" [formCtrl]="'personsFileCtrl'" [formGroup]="documentForm" [placeholder]="'Click to select...'"
                        [fullClickable]="true" (click)="uploadPersons.click()">
                    </pc-text-input>
                    <input hidden type="file" id="uploadPersons" #uploadPersons (change)="selectFile('persons', $event)">

                    <pc-text-input [required]="true" [label]="'Companies File'" [formCtrl]="'companiesFileCtrl'" [formGroup]="documentForm" [placeholder]="'Click to select...'"
                        [fullClickable]="true" (click)="uploadCompanies.click()">
                    </pc-text-input>
                    <input hidden type="file" id="uploadCompanies" #uploadCompanies (change)="selectFile('companies', $event)">
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="isDocumentFormValid" [submit]="onSubmit" [text]="'Generate Report'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
