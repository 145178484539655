<div class="row display-inline-flex">
    <div>
        <div class="tab-row">
            <div [ngClass]="getTabClass('TRANSMITTER')" (click)="setActiveTab('TRANSMITTER')">
                Transmitter
            </div>
            <div [ngClass]="getTabClass('TRANSMITTER_CONTACT_INFORMATION')" (click)="setActiveTab('TRANSMITTER_CONTACT_INFORMATION')">
                Transmitter Contact Information
            </div>
            <div [ngClass]="getTabClass('FILING_INSTITUTION')" (click)="setActiveTab('FILING_INSTITUTION')">
                Filing Institution
            </div>
            <div [ngClass]="getTabClass('FILING_CONTACT_OFFICE')" (click)="setActiveTab('FILING_CONTACT_OFFICE')">
                Filing Contact Office
            </div>
            <div [ngClass]="getTabClass('FINANCIAL_INSTITUTION')" (click)="setActiveTab('FINANCIAL_INSTITUTION')">
                Financial Institution
            </div>
        </div>
    </div>
</div>
<pc-reports-party-information *ngIf="activeTab !== ''"
    [submissionType]="submissionType"
    [partyType]="activeTab"
    [filingInstitution]="filingInstitution">
</pc-reports-party-information>
<pc-reports-list
    [changeTrigger]="changeTrigger"
    [memberId]="memberId"
    [reportStatus]="reportStatus"
    [submissionType]="submissionType"
    [filingInstitution]="filingInstitution">
</pc-reports-list>
