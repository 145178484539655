<div>
    <form [formGroup]="newMessageForm">
        <div>
            <div class="modal-header">
                <label class="form-title">
                    {{!recipientMemberName ? 'Message Another Member' : 'Message to ' + recipientMemberName}}
                </label>

                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div>
                    <pc-text-input *ngIf="isReply" [required]="true" [label]="'Recipient'" [formCtrl]="'recipientNameCtrl'" [formGroup]="newMessageForm">
                    </pc-text-input>
                    <pc-member-selector *ngIf="!isReply" [formGroup]="newMessageForm" [label]="'Recipient'" [formCtrl]="'recipientCtrl'" [required]="true"
                        [placeholder]="''" [memberTypes]="[MemberType.BUSINESS]" (memberSelected)="setMember($event)" [excludeSelf]="true">
                    </pc-member-selector>
                    <pc-text-input [required]="true" [label]="'Subject'" [placeholder]="'Subject'" [formCtrl]="'subjectCtrl'" [formGroup]="newMessageForm">
                    </pc-text-input>
                    <pc-text-area [required]="true" [label]="'Message'" [placeholder]="'Message'" [formCtrl]="'bodyCtrl'" [formGroup]="newMessageForm">
                    </pc-text-area>
                </div>
            </div>
            <div *ngIf="files && files.length > 0">
                <hr class="separator">
                <span><i class="fa fa-paperclip"></i>{{files[0].name}}</span>
                <hr class="separator">
            </div>
            <div class="modal-footer">
                <button class="blue-button pull-left" type="button" (click)="upload.click()">Attach File</button>
                <input hidden type="file" id="upload" #upload (change)="selectFile($event)" placeholder="Upload file"
                    accept=".pdf,.doc,.docx">
                <pc-submit-button [enabled]="formEnabled" [submit]="onSubmit" [text]="'Send'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
