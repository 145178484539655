import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Scenario } from './models/scenario';
import { LendingFinancingRequest } from './models/lending/lending-financing-request';
import { RequestAccess } from './models/request-access';
import { LoanStatus } from './models/loan';

@Injectable({
    providedIn: 'root'
})
export class LenderService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.LenderAPIEndpoint + '/';
    }

    // scenarios
    loadScenarios(memberId: string) {
        return this.http.get(`${this.baseUrl}lending/scenarios?memberId=${memberId}`);
    }

    deleteScenario(scenarioId: string) {
        return this.http.delete(`${this.baseUrl}lending/scenarios/${scenarioId}`);
    }

    createScenario(scenario: Scenario) {
        return this.http.post(`${this.baseUrl}lending/scenarios`, scenario);
    }

    loadScenarioById(scenarioId: string) {
        return this.http.get(`${this.baseUrl}lending/scenarios/${scenarioId}`);
    }

    updateScenario(scenarioId: string, updates: any) {
        return this.http.put(`${this.baseUrl}lending/scenarios/${scenarioId}`, updates);
    }

    // financing
    createFinancingRequest(financingRequest: LendingFinancingRequest) {
        return this.http.post(`${this.baseUrl}lending/financing`, financingRequest);
    }

    getFinancingById(financingId: string) {
        return this.http.get(`${this.baseUrl}lending/financing/${financingId}`);
    }

    getFinancingByLoanId(loanId: string){
        return this.http.get(`${this.baseUrl}lending/financing?loanId=${loanId}`);
    }

    // loans
    loadLoansByMemberIdAndStatuses(memberId: string, statuses: LoanStatus[], start: number, size: number, sort: string, sortDir: string) {
        return this.http.get(`${this.baseUrl}lending/loans?memberId=${memberId}&statuses=${statuses}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`);
    }

    loadLoansByLenderIdAndStatuses(lenderId: string, statuses: LoanStatus[], start: number, size: number, sort: string, sortDir: string) {
        return this.http.get(`${this.baseUrl}lending/loans?lenderId=${lenderId}&statuses=${statuses}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`);
    }

    updateLoan(id: string, updates: any) {
        return this.http.put(`${this.baseUrl}lending/loans/${id}`, updates);
    }

    updateLoanStatus(id: string, loanStatus: string) {
        return this.updateLoan(id, {status: loanStatus});
    }

    activityCountByLenderIdAndStatuses(lenderId: string, statuses: LoanStatus[]){
        return this.http.get(`${this.baseUrl}lending/loans/activity?lenderId=${lenderId}&statuses=${statuses}`);
    }

    // borrower
    getAccountingSystemStatus(memberId: string) {
        return this.http.get(`${this.baseUrl}borrower/${memberId}`);
    }

    getBalanceSheet(memberId: string, startMonth: string, periodLength: number, periodsToCompare: number) {
        const params = `?startMonth=${startMonth}&periodLength=${periodLength}&periodsToCompare=${periodsToCompare}`;
        return this.http.get(`${this.baseUrl}borrower/${memberId}/balanceSheet${params}`, null);
    }

    getProfitAndLoss(memberId: string, startMonth: string, periodLength: number, periodsToCompare: number) {
        const params = `?startMonth=${startMonth}&periodLength=${periodLength}&periodsToCompare=${periodsToCompare}`;
        return this.http.get(`${this.baseUrl}borrower/${memberId}/profitAndLoss${params}`, null);
    }

    getKPI(memberId: string) {
        return this.http.get(`${this.baseUrl}borrower/${memberId}/kpi`, null);
    }

    getSuppliers(memberId: string, start: number, size: number) {
        const params = `?page=${start}&pageSize=${size}`;
        return this.http.get(`${this.baseUrl}borrower/${memberId}/suppliers${params}`, null);
    }

    getCustomers(memberId: string, start: number, size: number) {
        const params = `?page=${start}&pageSize=${size}`;
        return this.http.get(`${this.baseUrl}borrower/${memberId}/customers${params}`, null);
    }

    // access
    createRequestAccess(requestAccess: RequestAccess) {
        return this.http.post(`${this.baseUrl}financial/access`, requestAccess);
    }

    updateRequestAccess(id: string, updates: any) {
        return this.http.put(`${this.baseUrl}financial/access/${id}`, updates);
    }

}
