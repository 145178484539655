import { Component } from '@angular/core';
import { MerchantAccountConfig, MerchantAccountStatus} from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-merchant-config-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class MerchantConfigBadgeComponent extends BaseBadgeComponent<MerchantAccountConfig> {

    setBadgeData() {
        this.badgeToolTip = this.parent.status;
        switch (this.parent.status) {
            case MerchantAccountStatus.ACTIVE:
                this.badgeText = 'ACTIVE';
                this.badgeClass = 'green-badge';
                break;
            case MerchantAccountStatus.DISABLED:
                this.badgeToolTip = 'DEACTIVATED';
                this.badgeText = 'DEACTIVATED';
                this.badgeClass = 'red-badge';
                break;
            case MerchantAccountStatus.PENDING:
                this.badgeText = 'REVIEW';
                this.badgeClass = 'orange-badge';
                break;
            default:
                this.badgeText = 'REJECTED';
                this.badgeClass = 'red-badge';
                break;
        }
    }

}
