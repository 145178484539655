<div>
    <form [formGroup]="metrcForm">
        <div>
            <div class="modal-header">
                <label class="form-title">Link METRC Account</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div>
                    <pc-text-input *ngIf="authorityState" [required]="true" [label]= "authorityState +' User key'"
                        [formCtrl]="'userKeyCtrl'" [formGroup]="metrcForm">
                    </pc-text-input>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="isMetrcFormValid" [submit]="onSubmit" [text]="'Link METRC Account'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
