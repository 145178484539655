import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarTrackingService } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-side-menu-link',
    templateUrl: './side-menu-link.component.html'
})
export class SideMenuLinkComponent {

    @Input() expandedMenu: boolean;
    @Input() link: string;
    @Input() queryParams: any = {};
    @Input() text: string;
    @Input() icon: string;

    constructor(private router: Router) {}

    getClass() {
        if (this.text === SidebarTrackingService.getActiveNavItem()) {
            return 'sidenav-option-active';
        } else {
            return 'sidenav-option';
        }
    }

    disabled() {
        return this.router.url.indexOf(this.link) >= 0;
    }
}
