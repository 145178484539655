import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    AuthService, Role, AccountingService, AccountingLinkedMember, MemberService, Member
} from 'projects/services/src/public-api';

@Component({
    selector: 'pt-link-accounting-system',
    templateUrl: './link-accounting-system.component.html',
    styleUrls: ['./link-accounting-system.component.scss']
})
export class LinkAccountingSystemComponent implements OnInit {

    Role = Role;

    @Input() memberId = '';

    @Output() accountingChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    supportedPushPlatforms: string[];
    connected = false;
    loading = true;
    registering = false;
    accountingSystemDetails : AccountingLinkedMember;
    timeout: any;

    constructor(public authService: AuthService,
                private accountingService: AccountingService,
                private memberService: MemberService) {
    }

    ngOnInit() {
        this.checkAccountingLinkage();
        this.supportedPushPlatforms = this.accountingService.getSupportedPushPlatforms();
    }

    checkAccountingLinkage(retryCount?: number) {
        this.accountingService.getAccountingSystemInfo(this.memberId).subscribe((accountingSystemDetails: AccountingLinkedMember) => {
            this.accountingSystemDetails = accountingSystemDetails;
            if (!accountingSystemDetails) {
                this.accountingConnectionChanged(false);
            } else if (!accountingSystemDetails.linked && (accountingSystemDetails.status === 'Deauthorized' || (!this.registering && accountingSystemDetails.status === 'PendingAuth'))) {
                this.accountingConnectionChanged(false);
            } else if (accountingSystemDetails.linked) {
                // successful login
                this.accountingConnectionChanged(true);
            } else if (!retryCount) {
                // we have run out of retry attempts
                this.accountingConnectionChanged(false);
            } else {
                // wait for 5s and then retry
                this.timeout = setTimeout(() => {
                    this.checkAccountingLinkage(retryCount - 1);
                }, 5000);
            }
        });
    }

    registerAccountingSystem(retry: boolean) {
        this.registering = true;
        if (!this.connected) {
            this.accountingService.getAccountingSystemInfo(this.authService.getProfile().memberId).subscribe((accountingSystemDetails: AccountingLinkedMember) => {
                if (!accountingSystemDetails) {
                    this.memberService.loadMember(this.authService.getProfile().memberId).subscribe((member: Member) => {
                        this.accountingService.saveAccountingInformation(member).subscribe((savedStatus: AccountingLinkedMember) => {
                            window.open(savedStatus.redirect, '_blank');
                            this.checkAccountingLinkage(20);
                        }, (_error: any) => {
                            this.registering = false;
                            if (_error?.error?.statusCode === 402) {
                                throw new Error(`<p>Unable to contact accounting integration system: ${_error?.error?.error}.</p><p>Please try again later.</p>`);
                            } else {
                                throw new Error('Unable to contact accounting integration system.  Please try again later.');
                            }
                        });
                    });
                } else {
                    window.open(accountingSystemDetails.redirect, '_blank');
                    this.checkAccountingLinkage(20);
                }
            }, (_error: any) => {
                this.accountingService.deleteAccountingInformation(this.authService.getProfile().memberId).subscribe(() => {
                    this.checkAccountingLinkage(10);
                    if (retry) {
                        this.registerAccountingSystem(false);
                    }
                });
            });
        } else {
            this.accountingService.deleteAccountingInformation(this.authService.getProfile().memberId).subscribe(() => {
                this.checkAccountingLinkage(10);
            });
        }
    }

    accountingConnectionChanged(connected: boolean) {
        this.connected = connected;
        this.accountingChanged.emit(this.connected);
        this.loading = false;
        this.registering = false;
    }
}
