import { Component, OnInit } from '@angular/core';
import { ChartType, PaymentService, ChartService, TransactionTypeTableData, ChartData } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-authority-transaction-type-gadget',
    templateUrl: './authority-transaction-type-gadget.component.html'
})
export class AuthorityTransactionTypeGadgetComponent implements OnInit {
    ChartType = ChartType;

    period = 5;
    tableView = false;
    labels: string[] = [];
    chartData: ChartData[] = [];
    transactionTypeTableData: TransactionTypeTableData[] = [];
    displayedColumns: string[] = ['date', 'ach_deposit', 'cash_deposit', 'check_deposit', 'wire_deposit', 'ach_withdrawal', 'cash_withdrawal', 'check_withdrawal', 'wire_withdrawal', 'network'];

    constructor(private paymentsService: PaymentService,
                private chartService: ChartService) { }

    ngOnInit() {
        // default checked radio button
        this.paymentsService.getTransactionsTypesCount(this.period).subscribe((response: any) => {
            this.labels = Object.keys(response);
            this.chartData = this.chartService.getChartTransactionTypes(response, this.labels);
            this.transactionTypeTableData = this.chartService.getTableTransactionTypes(response, this.labels);
        });
    }

    viewChanged(type: string) {
        this.tableView = (type === 'table');
    }

}
