export class TaxInfo {
    id: string;
    memberId: string;
    businessType: string;
    ein: string;
    stateTaxId: string;
    taxPermitNo: string;
    salesTaxRate: number;
    businessTaxRate: number;
}
