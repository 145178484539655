import { Component } from '@angular/core';
import { BNPLLoan, LoanStatus } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-bnpl-loan-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class BNPLLoanBadgeComponent extends BaseBadgeComponent<BNPLLoan> {

    setBadgeData() {
        this.badgeToolTip = this.parent.status;
        switch (this.parent.status) {
            case LoanStatus.PENDING:
                this.badgeText = 'PENDING';
                this.badgeClass = 'grey-badge';
                break;
            case LoanStatus.DECLINED:
                this.badgeText = 'DECLINED';
                this.badgeClass = 'dark-grey-badge';
                break;
            case LoanStatus.CLOSED:
                this.badgeText = 'COMPLETED';
                this.badgeClass = 'green-badge';
                break;
            case LoanStatus.CANCELLED:
                this.badgeText = 'CANCELED';
                this.badgeClass = 'red-badge';
                break;
            case LoanStatus.DILIGENCE:
                this.badgeText = 'DILIGENCE';
                this.badgeClass = 'orange-badge';
                break;
            default:
                this.badgeText = 'ACTIVE';
                this.badgeClass = 'grey-badge';
                break;
        }
    }
}