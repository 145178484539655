import { BankingIntegrationProvider } from './bank-integration-provider';

export class Signature {
    id: string;
    memberId: string;
    signature: string;
    created: Date;
    title: string;
    warrantTruthful: boolean;
    ownersComplete: boolean;
    memberTermsReviewed: boolean;
    privacyTermsReviewed: boolean;
    platformTermsReviewed: boolean;
    mobileAgreementReviewed: boolean;
    remoteDepositReviewed: boolean;
    eSignReviewed: boolean;
    eStatementReviewed: boolean;
    partnerAttachTermsReviewed: boolean;
    atmPaymentProcessorAccessGranted: boolean;
    atmWithdrawalOnlyWarrant: boolean;
    atmSiteOwnerContractAccessGranted: boolean;
    bankTerms: BankingIntegrationProvider;
}
