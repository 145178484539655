<br>
<div class="row" *ngIf="partyInformation">
    <div class="col-12 horizontal-scroll">
        <div class="row" *ngIf="partyInformation.name">
            <div class="col-4 expandedLabel">
                Name
            </div>
            <div class="col-8 expandedValue">
               {{ partyInformation.name }}
            </div>
        </div>
        <div class="row" *ngIf="partyInformation.address">
            <div class="col-4 expandedLabel">
                Address
            </div>
            <div class="col-8 expandedValue">
                {{ partyInformation.address }}
            </div>
        </div>
        <div class="row" *ngIf="partyInformation.phoneNumber">
            <div class="col-4 expandedLabel">
                Phone Number
            </div>
            <div class="col-8 expandedValue">
                {{ partyInformation.phoneNumber  | phone }}
            </div>
        </div>
        <div class="row" *ngIf="partyInformation.memberType">
            <div class="col-4 expandedLabel">
                Member Type
            </div>
            <div class="col-8 expandedValue">
                {{ partyInformation.memberType }}
            </div>
        </div>
        <div class="row" *ngIf="partyInformation.transmitterControlCode">
            <div class="col-4 expandedLabel">
                Transmitter Control Code
            </div>
            <div class="col-8 expandedValue">
                {{ partyInformation.transmitterControlCode }}
            </div>
        </div>
        <div class="row" *ngIf="partyInformation.primaryRegulatorTypeText">
            <div class="col-4 expandedLabel">
                Primary Regulator Type Code
            </div>
            <div class="col-8 expandedValue">
                {{ partyInformation.primaryRegulatorTypeText }}
            </div>
        </div>
        <div class="row" *ngIf="partyInformation.employerIdentificationNumber">
            <div class="col-4 expandedLabel">
                Employer Identification Number
            </div>
            <div class="col-8 expandedValue">
                {{ partyInformation.employerIdentificationNumber }}
            </div>
        </div>
        <div class="row" *ngIf="partyInformation.taxpayerIdentificationNumber">
            <div class="col-4 expandedLabel">
                 Taxpayer Identification Number
            </div>
            <div class="col-8 expandedValue">
                {{ partyInformation.taxpayerIdentificationNumber }}
            </div>
        </div>
        <div class="row" *ngIf="partyInformation.partyIdentificationNumberTextForEIN">
            <div class="col-4 expandedLabel">
                Party Identification Number Text For EIN
            </div>
            <div class="col-8 expandedValue">
                {{ partyInformation.partyIdentificationNumberTextForEIN }}
            </div>
        </div>
        <div class="row" *ngIf="partyInformation.partyIdentificationNumberTextForTcc">
            <div class="col-4 expandedLabel">
                Party Identification Number Text For TCC
            </div>
            <div class="col-8 expandedValue">
                {{ partyInformation.partyIdentificationNumberTextForTcc }}
            </div>
        </div>
        <div class="row" *ngIf="partyInformation.partyIdentificationNumberTextForTaxpayer">
            <div class="col-4 expandedLabel">
                Party Identification Number Text For Taxpayer
            </div>
            <div class="col-8 expandedValue">
                {{ partyInformation.partyIdentificationNumberTextForTaxpayer }}
            </div>
        </div>
        <div class="row" *ngIf="partyInformation.noBranchActivityInvolvedIndicator">
            <div class="col-4 expandedLabel">
                No Branch Activity Involved Indicator
            </div>
            <div class="col-8 expandedValue">
                {{ partyInformation.noBranchActivityInvolvedIndicator }}
            </div>
        </div>
    </div>
</div>
