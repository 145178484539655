<div class="col-12 dashboard-info">
    <div class="row">
        <div class="col-3">
            <pt-members-table-header *ngIf="activeTab" [activeTab]="activeTab" (tabChanged)="onTabChange($event)">
            </pt-members-table-header>
        </div>
        <div class="col-9 pull-right">
            <div>
                <button *ngIf="activeTab === 'merchants' && authService.isAuthority()" class="blue-button pull-right" (click)="onRequestNewMerchant()" type="button">
                    <i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add Merchant</div>
                </button>
                <pc-member-selector *ngIf="!connectedMembers" [class]="'full'" [formGroup]="formGroup" [memberTypes]="memberTypes" [statuses]="statuses" [formCtrl]="'memberCtrl'" [includeStatus]="true"
                    [displayLabel]="false" [placeholder]="placeholder" [includeAddress]="true" (memberSelected)="memberSelected($event)" >
                </pc-member-selector>
                <pc-connected-member-selector *ngIf="connectedMembers" [class]="'full'" [formGroup]="formGroup" [memberAccountType]="memberAccountType" [formCtrl]="'memberAccountCtrl'" [includeStatus]="true"
                    [displayLabel]="false" [placeholder]="placeholder" [includeAddress]="true" (connectedMemberSelected)="connectedMemberSelected($event)" >
                </pc-connected-member-selector>
            </div>
            <pt-members-list *ngIf="activeTab !== 'merchants' && activeTab !== 'connected_members' && activeTab !== 'partner_consumers'"
                [activeTab]="activeTab"
                [memberId]="memberId">
            </pt-members-list>
            <pt-merchant-list *ngIf="activeTab === 'merchants'"
                [memberId]="memberId"
                [changeTrigger]="changeTrigger">
            </pt-merchant-list>
            <pt-connected-members-list *ngIf="connectedMembers"
                [memberAccountType]="memberAccountType"
                [memberId]="memberId"
                [memberAccountId]="memberAccountId">
            </pt-connected-members-list>
        </div>
    </div>
</div>
