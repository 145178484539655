import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Message } from '../models/message';
import { CustomValidators } from '../validators/custom.validators';

@Injectable({
    providedIn: 'root'
})
export class MessageFormService {

    constructor(private formbuilder: UntypedFormBuilder) {
    }

    initializeForm(message: Message): UntypedFormGroup {
        const form = this.formbuilder.group({
            recipientCtrl: new UntypedFormControl('', [Validators.required]),
            recipientNameCtrl: new UntypedFormControl(''),
            subjectCtrl: new UntypedFormControl('', [Validators.required, Validators.maxLength(100), CustomValidators.noLeadingOrTrailingWhitespace]),
            bodyCtrl: new UntypedFormControl('', [Validators.required, Validators.maxLength(1048575)])
        });
        if (message) {
            form.patchValue({
                recipientCtrl: message.recipientMemberId,
                recipientNameCtrl: message.recipientMemberName,
                subjectCtrl: message.subject,
                bodyCtrl: message.body
            });
        }
        return form;
    }

    getMessage(form: UntypedFormGroup): Message {
        const message = new Message();
        message.recipientMemberId = form.get('recipientCtrl').value;
        message.subject = form.get('subjectCtrl').value;
        message.body = form.get('bodyCtrl').value;
        return message;
    }

}
