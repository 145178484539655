import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MemberAccount, PagedResponse, Role, User, UserAccount, UserAccountService, UserAccountStatus } from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { v4 as uuidv4 } from 'uuid';
import { ErrorType } from 'projects/components/src/public-api';

@Component({
    selector: 'pc-link-user-modal',
    templateUrl: './link-user-modal.component.html'
})
export class LinkUserModalComponent extends BaseModalComponent<LinkUserModalComponent> implements OnInit {

    memberAccount: MemberAccount;
    linkUserForm: UntypedFormGroup;
    errorMessage: { message: string, type: ErrorType };
    existingUserAccounts: UserAccount[] = [];
    eligibleUsers: User[] = [];

    constructor(private userAccountService: UserAccountService,
                private notifier: NotificationService,
                private formBuilder: UntypedFormBuilder,
                dialog: MatDialogRef<LinkUserModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialog);
        this.memberAccount = data.memberAccount;
        this.existingUserAccounts = data.existingUserAccounts;
    }

    ngOnInit() {
        this.isLinkUserFormValid = this.isLinkUserFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        return this.userAccountService.getAllUserAccountsByMemberId(this.memberAccount.memberId).subscribe((accountResponse: PagedResponse<UserAccount>) => {
            let userMap = {};
            const availableUsers = accountResponse.content.filter((userAccount: UserAccount) => {
                return !this.existingUserAccounts.find((existingAccount: UserAccount) => {
                    return existingAccount.userId === userAccount.userId;
                });
            }).map((userAccount: UserAccount) => {
                return userAccount.user;
            });
            availableUsers.forEach((user: User) => {
                if (!userMap[user.id]) {
                    userMap[user.id] = user;
                }
            });

            this.eligibleUsers = Object.values(userMap);
            if (!this.eligibleUsers.length) {
                this.errorMessage = {
                    message: 'You have no active users that are not already linked to this account.',
                    type: ErrorType.ERROR
                };
            }
            this.linkUserForm = this.formBuilder.group({
                userIdCtrl: new UntypedFormControl('', [Validators.required]),
                positionCtrl: new UntypedFormControl('', [Validators.required])
            });
        });
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    onSubmit(reset: any) {
        const userId = this.linkUserForm.controls['userIdCtrl'].value;
        const selectedUser = this.eligibleUsers.find((user: User) => {
            return user.id === userId;
        });
        const userAccount = new UserAccount();
        userAccount.id = uuidv4();
        userAccount.memberId = this.memberAccount.memberId;
        userAccount.memberAccountId = this.memberAccount.id;
        userAccount.userId = userId;
        userAccount.position = this.linkUserForm.controls['positionCtrl'].value;
        userAccount.role = Role.MEMBER;
        userAccount.status = UserAccountStatus.ACTIVE;
        let expirationDate = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 10);
        userAccount.expirationDate = expirationDate;
        this.userAccountService.addAccess(userAccount).subscribe(
            () => {
                this.close(true);
                this.notifier.showSuccess(`<b>${selectedUser.firstName + ' ' + selectedUser.lastName}</b> has been granted access to <b>${this.memberAccount.accountName}</b> as an Administrator.`);
            },
            (errorResult: any) => {
                reset();
                throw errorResult;
            });
    }

    isLinkUserFormValid() {
        return this.linkUserForm.valid;
    }

}
