<div class="col-sm-12 horizontal-scroll mat-table-container">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="shipments" matSort [matSortActive]="pageTracking.sort" matSortDisableClear
        [matSortDirection]="pageTracking.sortDir" multiTemplateDataRows>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="manifestNumber"> Manifest Number </th>
            <td mat-cell *matCellDef="let element">
                <i class="fa" [class.fa-plus-circle]="expandedElement !== element"
                    [class.fa-minus-circle]="expandedElement === element"
                    (click)="expandedElement = (expandedElement === element) ? null : element"></i> {{element.manifestNumber}}
            </td>
        </ng-container>
        <ng-container matColumnDef="supplier">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="shipperFacilityName"> Supplier </th>
            <td mat-cell *matCellDef="let element"> {{ element.shipperFacilityName }} </td>
        </ng-container>
        <ng-container matColumnDef="recipient">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="recipientFacilityName"> Recipient </th>
            <td mat-cell *matCellDef="let element"> {{ element.recipientFacilityName }} </td>
        </ng-container>
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef class="centered" mat-sort-header> Created Date </th>
            <td mat-cell class="centered" *matCellDef="let element"> {{element.created ? (element.created | date:'mediumDate') : ""}} </td>
        </ng-container>
        <ng-container matColumnDef="received_date_time">
            <th mat-header-cell *matHeaderCellDef class="centered" mat-sort-header="receivedDateTime"> Received Date </th>
            <td mat-cell class="centered" *matCellDef="let element"> {{element.receivedDateTime ? (element.receivedDateTime | date:'mediumDate') : ""}} </td>
        </ng-container>
        <ng-container matColumnDef="transfer_type" class="centered">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="source" class="centered"> Type </th>
            <td mat-cell class="centered" *matCellDef="let element">
                {{ getSource(element.source) }}
            </td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                    <pc-member-metrc-transfers-row [shipment]="element"> </pc-member-metrc-transfers-row>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-expand-row"
            [class.example-expanded-row]="expandedElement === element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
</div>
<div *ngIf="displaySummary">
    <dl class="row">
        <div class="title-bold-font">
            Package Contents Summary:
        </div>
        <div *ngIf="shipments?.length > 0 && transfersSummaryList?.length === 0"> Filter more results to display total summary</div>
        <div class="row" *ngFor="let transfersSummary of transfersSummaryList">
            <dt class="col-sm-4 expandedLabel">{{ transfersSummary.category}} :</dt>
            <dd class="col-sm-8 expandedValue">{{ transfersSummary.total }} Units</dd>
        </div>
    </dl>
</div>
