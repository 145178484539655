 <div class="col-12 dashboard-info">
    <div class="row no-gutters">
        <div class="col-sm-12 col-md-12 col-lg-12 dashboard-header">
            <img class="dashboard-icon" src="assets/images/help-icon-2.svg" alt="">
            <span>{{title}}</span>
        </div>
    </div>

    <form class="column-centered" [formGroup]="newMessageForm">
        <div class="center-fields">
            <pc-text-input [required]="true" [label]="'Recipient'"
                [formCtrl]="'recipientNameCtrl'" [formGroup]="newMessageForm">
            </pc-text-input>

            <pc-text-input [required]="true" [label]="'Subject'" [placeholder]="'Subject'"
                [formCtrl]="'subjectCtrl'" [formGroup]="newMessageForm">
            </pc-text-input>

            <pc-text-area [required]="true" [label]="'Message'" [placeholder]="'Message'"
                [formCtrl]="'bodyCtrl'" [formGroup]="newMessageForm">
            </pc-text-area>
        </div>

        <span *ngIf="files && files.length > 0">{{files[0].name}}</span>
        <div class="mt-4">
            <button class="blue-button" type="button" (click)="upload.click()">Attach File</button>
            <input hidden type="file" id="upload" #upload (change)="selectFile($event)" placeholder="Upload file"
                accept=".pdf,.doc,.docx">
            <span class="pull-right">
                <pc-submit-button [enabled]="formEnabled" [submit]="onSubmit" [text]="'Send'"></pc-submit-button>
            </span>
        </div>
    </form>
 </div>
