import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WorkflowService } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-authority-workflow-notes',
    templateUrl: './authority-workflow-notes.component.html',
    styleUrls: ['./authority-workflow-notes.component.scss']
})
export class AuthorityWorkflowNotesComponent implements OnInit, OnChanges {

    @Input() processInstanceId: string;
    @Input() changeTrigger = 1;

    displayedColumns: string[] = ['date', 'user', 'comment'];

    isLoadingResults = true;
    workflowComments: any[];

    constructor(private workflowService: WorkflowService) {
    }

    ngOnInit() {
        this.loadComments();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.changeTrigger && !changes?.changeTrigger.firstChange)) {
            this.loadComments();
        }
    }

    loadComments() {
        this.isLoadingResults = true;
        this.workflowService.getWorkflowComments(this.processInstanceId).subscribe((comments: any[]) => {
            this.workflowComments = comments;
            this.isLoadingResults = false;
        });
    }
}
