import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators, Utils } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-zip-code-input',
    templateUrl: './zip-code-input.component.html'
})
export class ZipCodeInputComponent implements OnInit, AfterViewInit {

    @Input() required: boolean;
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;

    @Output() zipCodeChanged: EventEmitter<any> = new EventEmitter<any>();

    timeout: any;

    @ViewChild('zipCodeField') zipCodeField: ElementRef;

    constructor(private changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.required) {
            this.formGroup.controls[this.formCtrl].setValidators([Validators.required, CustomValidators.zipCode]);
        } else {
            this.formGroup.controls[this.formCtrl].setValidators([CustomValidators.zipCode]);
        }
    }

    ngAfterViewInit() {
        const existing: string = this.formGroup.controls[this.formCtrl].value;
        if (existing) {
            this.formGroup.controls[this.formCtrl].patchValue(this.getViewVal(existing));
        }
    }

    onBlur(event: any) {
        this.updateValue(event.currentTarget);
    }

    updateValue(target: any) {
        let newVal = target.value;
        if (!newVal.match(CustomValidators.ZIP_CODE_REGEXP)) {
            this.formGroup.controls[this.formCtrl].patchValue(newVal, {emitEvent: false});
        } else {
            newVal = newVal.replace(/\D/g, '');
            this.formGroup.controls[this.formCtrl].patchValue(this.getViewVal(newVal));
        }
        this.changeDetectorRef.detectChanges();
    }

    onInputChanged(event: any) {
        if (Utils.isNumericKeyCode(event.keyCode) || event.keyCode === 189 || event.keyCode === 32 ||
            event.keyCode === 37 || event.keyCode === 39 || event.keyCode === 17 || event.keyCode === 9 || event.keyCode === 8) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.updateValue(event.target);
            }, 500);
            return;
        } else if ((event.ctrlKey && event.keyCode === 86) || (event.metaKey && event.keyCode === 86) || (event.ctrlKey && event.keyCode === 65)) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.updateValue(event.target);
            }, 500);
            return;
        }
        event.preventDefault();
    }

    getViewVal(rawValue: string) {
        let viewVal = rawValue.replace(/\D/g, '');
        if (viewVal.length === 0) {
            viewVal = '';
        } else if (viewVal.length === 5) {
            viewVal = viewVal.replace(/^(\d{0,5})/, '$1');
        } else if (viewVal.length === 9) {
            viewVal = viewVal.replace(/^(\d{0,5})(\d{0,4})/, '$1-$2');
        }
        return viewVal;
    }

    onChange() {
        this.zipCodeChanged.emit(this.formGroup.controls[this.formCtrl].value);
    }
}
