import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
    Bill, BusinessClient, ManifestSelection, RemittanceInfo, TransactionEntrySelection, TransactionFormService, TransactionSource, TransactionType
} from 'projects/services/src/public-api';
import { v4 as uuidv4 } from 'uuid';
import { BaseTransactionComponent } from '../../common/base-transaction-component/base-transaction.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { PaymentDateType } from '../../common/review-transaction-step/review-transaction-step.component';

@Component({
    selector: 'pt-confia-payment',
    templateUrl: './confia-payment.component.html',
    styleUrls: ['./confia-payment.component.scss'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS,
        useValue: { displayDefaultIndicatorType: false }
    }]
})
export class ConfiaPaymentComponent extends BaseTransactionComponent<Bill> implements OnInit {

    readonly PAYMENT_METHODS = [{
        id: TransactionType.WALLET_TO_WALLET_PAYMENT,
        name: 'Confia Direct Payment'
    }];

    @Input() availableBalance: number;
    @Input() achDirectPaymentEnabled = false;
    @Input() achExpressPaymentFee: number;

    remittanceInfo = new RemittanceInfo();

    constructor(private transactionFormService: TransactionFormService,
                protected breakpointObserver: BreakpointObserver,
                protected cdr: ChangeDetectorRef) {
        super(breakpointObserver, cdr);
    }

    ngOnInit() {
        super.ngOnInit();

        this.formGroup = this.transactionFormService.initializePaymentForm();
        this.formGroup.get('memberAccountNameCtrl').setValue(this.memberAccount.accountName);
        this.formGroup.get('sourceCtrl').setValue(TransactionSource.EXTERNAL_TRANSACTION);
    }

    onSelectBusinessClient(selectedBusinessClient: BusinessClient<Bill>) {
        if (!this.currentBusinessClient || !selectedBusinessClient || this.currentBusinessClient.memberAccountId !== selectedBusinessClient.memberAccountId) {
            this.currentBusinessClient = selectedBusinessClient;
            this.manifests = [];
            this.manifestSupportingDocs = [];
            this.calculateTotal([]);
        }
        this.stepper.reset();
    }

    calculateTotal(selectedBills: TransactionEntrySelection<Bill>[]) {
        if (!this.currentBusinessClient) {
            this.transactionTotal = '0.00';
        } else if (selectedBills && selectedBills.length) {
            this.currentBusinessClient.transactionEntries = selectedBills;
            this.transactionTotal = this.currentBusinessClient.selectedTotal;
        } else {
            this.currentBusinessClient.transactionEntries = [];
            this.transactionTotal = '0.00';
        }
        this.totalChanged.emit(this.transactionTotal);
    }

    onTransactionReviewed(reset: any) {
        const transaction = this.transactionFormService.getTransaction(this.formGroup);
        transaction.submissionReference = uuidv4();
        transaction.recipientMemberId = this.currentBusinessClient.memberId;
        transaction.recipientAccountId = this.currentBusinessClient.memberAccountId;
        transaction.totalAmount = Number(this.transactionTotal);
        transaction.source = TransactionSource.NETWORK_TRANSACTION;
        transaction.transactionType = TransactionType.WALLET_TO_WALLET_PAYMENT;
        transaction.remittanceMemo = this.remittanceInfo?.remittanceMemo || null;
        transaction.remittanceFile = this.remittanceInfo?.remittanceFile || null;
        transaction.manifestSupportingDocuments = this.manifestSupportingDocs || [];

        if (transaction.transactionDate && this.formGroup.controls['paymentDateTypeCtrl'].value !== PaymentDateType.SCHEDULE_PAYMENT) {
            transaction.transactionDate = null;
        }
        transaction.expenses = this.currentBusinessClient.transactionEntries.map((transactionEntry: TransactionEntrySelection<Bill>) => {
            return transactionEntry.data;
        });
        transaction.shipmentIds = this.manifests.map((manifest: ManifestSelection) => {
            return manifest.data.id;
        });
        reset();
        this.transactionReviewed.emit(transaction);
    }

    // eslint-disable-next-line no-empty-function
    onSubmit(_reset: any) {}
}
