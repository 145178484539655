<div>
    <form *ngIf="depositForm" [formGroup]="depositForm">
        <div>
            <div class="modal-header">
                <div class="row no-gutters col-12">
                    <div class="col-1 pull-left"><img class="dashboard-icon" src="assets/images/account-2.svg" alt=""></div>
                    <div class="col-10">
                        <label class="form-title">Deposit Funds into Account: {{memberAccount.accountName}}</label>
                        <div class="row mt-1">
                            <div class="col-6 centered">Available Balance:<strong>&nbsp;{{memberAccount.wallet.availableBalance | currency}}</strong></div>
                            <div class="col-6 centered">Pending Funds:<strong>&nbsp;{{memberAccount.wallet.pendingBalance | currency}}</strong></div>
                        </div>
                    </div>
                    <div class="col-1 pull-right"><i class="modal-close" (click)="close()">&times;</i></div>
                </div>
            </div>

            <div class="modal-content-medium">
                <div>
                    <pc-bank-account-selector [required]="true" [label]="'From'" [formCtrl]="'bankAccountIdCtrl'"
                        [formGroup]="depositForm" [bankAccounts]="bankAccounts">
                    </pc-bank-account-selector>

                    <pc-form-amount-input [required]="true" [label]="'Deposit Amount'" [formCtrl]="'amountCtrl'"
                        [formGroup]="depositForm" [placeholder]="'0.00'" (change)="onCalculateTotal()">
                    </pc-form-amount-input>

                    <pc-form-date-picker [label]="'Deposit Date'" [formCtrl]="'transactionDateCtrl'"
                        [formGroup]="depositForm">
                    </pc-form-date-picker>

                    <pc-form-text-area [required]="false" [label]="'Notes'" [formCtrl]="'notesCtrl'"
                        [formGroup]="depositForm" [placeholder]="'Insert note here...'">
                    </pc-form-text-area>
                </div>
            </div>

            <div class="modal-footer">
                <div class="bottom-total">
                    <strong class="form-title">Deposit Amount:  {{totalAmount | currency}}</strong>
                </div>
                <pc-submit-button [enabled]="isDepositFormValid" [submit]="onSubmit" [text]="'Submit Deposit'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
