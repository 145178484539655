import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableUtils } from 'projects/components/src/lib/table-utils.service';
import { AuthService, FilingInstitution, Member, MemberStatus, MemberType, ReportStatus, Role } from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FincenInvestigationDocumentModalComponent, OtherDocumentModalComponent } from '../../../components';

@Component({
    selector: 'pt-authority-reports-table-header',
    templateUrl: './authority-reports-table-header.component.html',
    styleUrls: ['./authority-reports-table-header.component.scss']
})
export class AuthorityReportsTableHeaderComponent implements OnInit {

    reportType: string;
    reportStatus: ReportStatus = ReportStatus.ALL;
    filingInstitution: FilingInstitution;
    memberId = '';
    formGroup: UntypedFormGroup;
    placeHolder = 'Search Members';
    types = [MemberType.BUSINESS];
    statuses = [MemberStatus.ACTIVE, MemberStatus.DISABLED];
    changeTrigger = 1;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private formBuilder: UntypedFormBuilder,
                public authService: AuthService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.formGroup =  this.formBuilder.group({
            memberCtrl : new UntypedFormControl(this.memberId)
        });
        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['reportType'] || queryParams['memberId'] || queryParams['status'] || queryParams['filingInstitution']) {
            this.reportType = queryParams['reportType'];
            this.memberId = queryParams['memberId'];
            this.reportStatus = queryParams['status'];
            this.filingInstitution = queryParams['filingInstitution'];
        } else {
            this.reportType = 'SAR';
            this.reportStatus = ReportStatus.ALL;
            this.filingInstitution = FilingInstitution.NBCU;
            this.updateQueryParams(true);
        }
        this.route.queryParams.subscribe((params) => {
            this.reportType = params['reportType'] || 'SAR';
            this.memberId = params['memberId'] || '';
            this.reportStatus = params['status'] || ReportStatus.ALL;
            this.filingInstitution = params['filingInstitution'] || FilingInstitution.NBCU;
            this.filterChanged();
            this.formGroup.controls['memberCtrl'].setValue(this.memberId);
        });
    }

    memberSelected(member: Member) {
        if (member.id) {
            this.memberId = member.id;
        } else {
            this.memberId = '';
        }
    }

    reportTypeChange() {
        this.memberId = '';
        this.reportStatus = ReportStatus.ALL;
        this.filingInstitution = FilingInstitution.NBCU;
        this.cdr.detach();
        TableUtils.clearSubscriptions();
        this.cdr.reattach();
        this.filterChanged();
        this.updateQueryParams(false);
    }

    reportStatusChange() {
        this.updateQueryParams(false);
    }

    reportFilingInstitutionChange() {
        this.updateQueryParams(false);
    }

    filterChanged() {
        if (this.reportType === 'SAR' || this.reportType === 'CTR') {
            this.statuses = [MemberStatus.ACTIVE, MemberStatus.DISABLED];
        } else {
            this.statuses = [MemberStatus.ACTIVE];
        }
    }

    updateQueryParams(replace: boolean) {
        const queryParams = { reportType: this.reportType, memberId: null, status: null, filingInstitution: null, page: null, sort: null, dir: null, num: null, _activeTab: null };
        if (this.reportType !== 'OPERATIONAL') {
            queryParams.memberId = ((this.reportType === 'DAILY_REC_REPORTS' || this.reportType === 'FINCEN_INVESTIGATIONS') ? null : this.memberId);
            queryParams.status = (this.reportType === 'SAR' || this.reportType === 'CTR') ?  this.reportStatus : null;
            queryParams.filingInstitution = (this.reportType === 'SAR' || this.reportType === 'CTR') ?  this.filingInstitution : null;
            queryParams.page = 1;
            queryParams.sort = null;
            queryParams.dir = null;
            if (replace) {
                queryParams.num = 100;
            }
        }
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }

    isAuthorityReporter() {
        return this.authService.getProfile().role === Role.AUTHORITY_REPORTER;
    }

    uploadInvestigationFiles() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        const dialog = this.dialog.open(FincenInvestigationDocumentModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.changeTrigger = Math.random();
            }
        });
    }
}
