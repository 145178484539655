<div class="horizontal-scroll mat-table-container">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="accountActivityList" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="date">
            <th mat-header-cell class="centered" *matHeaderCellDef> Date </th>
            <td mat-cell class="centered" *matCellDef="let row"> {{ row.date | date:'mediumDate'}} </td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description</th>
            <td mat-cell *matCellDef="let row">
                <a *ngIf="!readOnly" [routerLink]="['/transaction/details/' + row.transactionId]" [routerLinkActive]="['router-link-active']">
                    {{ row.description }}
                </a>
                <span *ngIf="readOnly">{{ row.description }}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="withdrawal">
            <th mat-header-cell class="centered" *matHeaderCellDef> Withdrawal </th>
            <td mat-cell class="centered" *matCellDef="let row"><span class="table-amount"> {{ row.withdrawalAmount | currency }} </span></td>
        </ng-container>
        <ng-container matColumnDef="deposit">
            <th mat-header-cell class="centered" *matHeaderCellDef> Deposit </th>
            <td mat-cell class="centered" *matCellDef="let row"><span class="table-amount"> {{ row.depositAmount | currency }} </span></td>
        </ng-container>
        <ng-container matColumnDef="pt_score">
            <th mat-header-cell class="centered" *matHeaderCellDef> Risk Score </th>
            <td mat-cell class="centered" *matCellDef="let row"><span> {{ row.pointTrustScore }} </span></td>
        </ng-container>
        <ng-container matColumnDef="balance">
            <th mat-header-cell class="centered" *matHeaderCellDef> Available Balance </th>
            <td mat-cell class="centered" *matCellDef="let row"><span class="table-amount"> {{ row.balance | currency }} </span></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"></mat-paginator>
</div>
