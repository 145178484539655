import { Component, Input, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { ChartType } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-charts-gadgets',
    templateUrl: './charts-gadgets.component.html',
    styleUrls: ['./charts-gadgets.component.scss']
})
export class ChartsGadgetsComponent implements OnInit {

    ChartType = ChartType;

    @Input() chartType: ChartType;
    @Input() chartContainer = 'chart-container-dashboard';
    @Input() chartData: any[] = [];
    @Input() view: any[] = [];

    // options
    @Input() showXAxis: boolean = true;
    @Input() showYAxis: boolean = true;
    @Input() showLegend: boolean = true;
    @Input() showXAxisLabel: boolean = true;
    @Input() showYAxisLabel: boolean = true;
    @Input() legendPosition: string = 'below';
    @Input() legendTitle: string = '';
    @Input() yAxisTickFormatting: string = '';


    @Input() colorScheme = {
        domain: ['#612863', '#EBAAEC', '#283363', '#7981A2', '#6178D0', '#A8B5E8', '#00AEB0', '#AEDCDD', '#5A6C98']
    };

    constructor(private currencyPipe : CurrencyPipe) {}

    ngOnInit() {
        this.tickformat = this.tickformat.bind(this);
    }

    tickformat(val: any) {
        if (this.yAxisTickFormatting === 'dollar') {
            return this.currencyPipe.transform(val || '0.00');
        } else if (this.yAxisTickFormatting === 'whole' && val && val.toString().indexOf('.') > -1) {
            return '';
        }
        return val;
    }
}
