<div class="row dashboard-info justify-content-center col-12" *ngIf="userAccount">
    <div class="col-12 dashboard-header">
        <span>{{ taskVars.memberId ? 'Member Application' : 'User Invitation'}} Details</span>
    </div>
    <div class="col-8 mt-3">
        <dl class="row">
            <dt class="col-sm-3">Name:</dt>
            <dd class="col-sm-9">{{ userAccount.user.firstName }} {{ userAccount.user.lastName }}</dd>

            <dt *ngIf="taskVars.role !== Role.CONSUMER" class="col-sm-3">Member:</dt>
            <dd *ngIf="taskVars.role !== Role.CONSUMER" class="col-sm-9">{{userAccount.member.name}}</dd>

            <dt *ngIf="taskVars.memberRegistrationType" class="col-sm-3">Registration Type:</dt>
            <dd *ngIf="taskVars.memberRegistrationType" class="col-sm-9">{{taskVars.memberRegistrationType | translate}}</dd>

            <dt *ngIf="taskVars.jurisdiction" class="col-sm-3">Jurisdiction:</dt>
            <dd *ngIf="taskVars.jurisdiction" class="col-sm-9">{{Utils.getJurisdictionName(taskVars.jurisdiction)}}</dd>

            <dt class="col-sm-3">Email:</dt>
            <dd class="col-sm-9">{{ userAccount.user.email }}</dd>

            <dt class="col-sm-3">Mobile Phone:</dt>
            <dd class="col-sm-9">{{userAccount.user.mobilePhone | phone}}</dd>

            <dt *ngIf="userAccount.user.officePhone" class="col-sm-3">Office Phone:</dt>
            <dd *ngIf="userAccount.user.officePhone" class="col-sm-9">{{userAccount.user.officePhone | phone}}</dd>

            <dt *ngIf="userAccount.position" class="col-sm-3">Job Title:</dt>
            <dd *ngIf="userAccount.position" class="col-sm-9">{{ userAccount.position }}</dd>
        </dl>
    </div>

    <div class="col-12">
        <p *ngIf="isExpired()">
            <strong>NOTE:</strong> This link has expired, and is no longer valid. Please revoke the {{ taskVars.memberId ? 'application' : 'invitation'}} to allow the user to sign up again, or for their administrator to invite them again.
        </p>
        <hr class="separator-thick">
        <div class="button-panel">
            <button class="blue-button" type="button" (click)="revokeInvitation()">Revoke {{ taskVars.memberId ? 'Application' : 'Invitation'}}</button>
            <button class="blue-button" type="button" (click)="returnToTasks()">Return to Open Items</button>
        </div>
    </div>
</div>
