<div *ngIf="hasTransactions && merchantAccountConfig && similarMerchantConfigs.length === 0">
    This merchant has transactions and cannot be deleted, and no similar merchants were found.
</div>
<div *ngIf="!hasTransactions && canDeleteMerchant">
    This merchant has no transactions and can be safely deleted.
    <button pc-no-dbl-click class="blue-button" type="button" (click)="deleteExistingMerchant()" submit-button>Delete Merchant</button>
</div>
<div class="col-12 justify-content-center" *ngIf="merchantAccountConfig && similarMerchantConfigs.length">
    <div class="horizontal-scroll mat-table-container">
        <p>Possible Matching Merchants:</p>
        <table mat-table [dataSource]="similarMerchantConfigs">
            <ng-container matColumnDef="merchant_name">
                <th mat-header-cell *matHeaderCellDef> Merchant Name </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.merchantMemberAccount.member.memberType === MemberType.BUSINESS_MERCHANT">
                        <a target="_blank"  *ngIf="element.merchantMemberAccount.member.status === MemberStatus.UNDER_REVIEW"
                            [routerLink]="['/administration/merchant/onboard/' + element.merchantMemberAccount.memberId]" [routerLinkActive]="['router-link-active']">
                            {{ element.merchantMemberAccount.member.name }}
                        </a>
                        <a target="_blank"  *ngIf="element.merchantMemberAccount.member.status !== MemberStatus.UNDER_REVIEW"
                            [routerLink]="['/administration/merchant/' + element.merchantMemberAccount.memberId]" [routerLinkActive]="['router-link-active']">
                            {{ element.merchantMemberAccount.member.name }}
                        </a>
                    </span>
                    <span *ngIf="element.merchantMemberAccount.member.memberType === MemberType.INDIVIDUAL_MERCHANT">
                        <a target="_blank"  *ngIf="element.merchantMemberAccount.member.status === MemberStatus.UNDER_REVIEW"
                            [routerLink]="['/administration/individual/onboard/' + element.merchantMemberAccount.memberId]" [routerLinkActive]="['router-link-active']">
                            <img class="member-type-icon" src="assets/images/profile-icon.svg" title="Individual">
                            {{ element.merchantMemberAccount.member.name }}
                        </a>
                        <a target="_blank"  *ngIf="element.merchantMemberAccount.member.status !== MemberStatus.UNDER_REVIEW"
                            [routerLink]="['/administration/individual/' + element.merchantMemberAccount.memberId]" [routerLinkActive]="['router-link-active']">
                            <img class="member-type-icon" src="assets/images/profile-icon.svg" title="Individual">
                            {{ element.merchantMemberAccount.member.name }}
                        </a>
                    </span>
                    <div *ngIf="element.merchantMemberAccount.member.dbaName && element.merchantMemberAccount.member.dbaName !== element.merchantMemberAccount.member.name">DBA: {{element.merchantMemberAccount.member.dbaName}}</div>
                    <div>{{element.shared ? '' : '(private)'}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="contact_info">
                <th mat-header-cell *matHeaderCellDef> Contact Info </th>
                <td mat-cell *matCellDef="let element">
                    <pc-business-address *ngIf="element.merchantMemberAccount.address" [address]="element.merchantMemberAccount.address">
                    </pc-business-address>
                    <span *ngIf="element.merchantMemberAccount.member.email">Email: {{element.merchantMemberAccount.member.email}} <br></span>
                    <span *ngIf="element.merchantMemberAccount.member.officePhone">Office Phone: {{element.merchantMemberAccount.member.officePhone | phone}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="centered"> Status </th>
                <td mat-cell *matCellDef="let element" class="centered">
                    <pc-merchant-config-badge [parent]="element"></pc-merchant-config-badge>
                    <div *ngIf="element.merchantMemberAccount.member.jurisdiction">{{ Utils.getJurisdictionName(element.merchantMemberAccount.member.jurisdiction) }}</div>
                    <div *ngIf="element.merchantMemberAccount.member.tier === RiskTier.TIER_1">Tier 1</div>

                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="centered"></th>
                <td mat-cell *matCellDef="let element" class="centered">
                    <div>
                        <button *ngIf="element.status === MemberAccountStatus.ACTIVE" class="blue-button" type="button" (click)="useExistingMerchant(element.merchantMemberAccount)">Use Existing Merchant</button>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.disabled]="row.status !== MemberAccountStatus.ACTIVE"></tr>
        </table>
    </div>
</div>
