<div>
    <form [formGroup]="refundForm">
        <div>
            <div class="modal-header">
                <label class="form-title">Refund Transaction</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div class="row">
                    <div class="col-12">
                        <pc-form-amount-input [required]="true" [label]="'Amount'"  [formCtrl]="'amountCtrl'" [formGroup]="refundForm" ></pc-form-amount-input>
                        <label *ngIf="isAmountGreaterThanTransaction()" class="form-error">The amount cannot be more than the original payment.</label>
                    </div>
                    <div class="col-12">
                        <pc-text-area [required]="true" [label]="'Reason'" [placeholder]="''" [formCtrl]="'reasonCtrl'" [formGroup]="refundForm">
                        </pc-text-area>
                        <label *ngIf="isReasonLengthNotValid()" class="form-error">Reason size cannot be more than {{reasonLength}} symbols.</label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [submit]="confirm" [text]="'Submit'" [enabled]="formValid"></pc-submit-button>
                <pc-submit-button [submit]="cancel" [text]="'Cancel'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
