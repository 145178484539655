<fieldset class="form-group" *ngIf="formCtrl">
    <div class="row">
        <div class="col-12">
            <label class="form-label">{{ label | translate }}</label>
        </div>
        <div class="inputGroup col-12" [formGroup]="formGroup">
            <input #autoSelect [formControlName]="formCtrl" type="text" [placeholder]="placeholder"
                class="form-control form-input-editable" [matAutocomplete]="auto" (click)="$event.target.select()">
            <i *ngIf="!isDisabled()" class="fa" [ngClass]="isSelected() ? 'fa-times-circle-o' : 'fa-chevron-down'" (click)="onIconClick($event)"></i>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of autoComplete | async;" [value]="getDisplayValue(option)" (onSelectionChange)="selectionChanged(option, $event)">
                    {{ getDisplayValue(option) | titlecase }}
                </mat-option>
            </mat-autocomplete>
        </div>
    </div>
</fieldset>
