import { Component, OnInit, Input } from '@angular/core';
import { SmartContractService, ContractTemplateHistory, ContractTemplateType } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-sc-last-proposal',
    templateUrl: './last-proposal.component.html'
})
export class LastProposalComponent implements OnInit {

    @Input() party: ContractTemplateType;
    @Input() contractTemplateId: string;

    contractTemplateHistory: ContractTemplateHistory;

    constructor(private smartContractService: SmartContractService) {}

    ngOnInit() {
        this.smartContractService.getLatestTemplateHistory(this.contractTemplateId, this.party)
            .subscribe(
                (history: ContractTemplateHistory) => {
                    this.contractTemplateHistory = history;
                },
                (error: any) => {
                    if (error.status !== 404) {
                        throw error;
                    }
                }
            );
    }

}
