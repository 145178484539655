import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StepperOrientation } from '@angular/cdk/stepper';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatStepper } from '@angular/material/stepper';
import {
    Bill, BusinessClient, Invoice, ManifestSelection, MemberAccount, SupportingDocument, Transaction, TransactionEntrySelection, TransactionSource, TransactionSubType, TransactionType
} from 'projects/services/src/public-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'pt-transaction-modal',
    template: ''
})
export abstract class BaseTransactionComponent<U extends Invoice | Bill> implements OnInit {

    TransactionType = TransactionType;
    TransactionSubType = TransactionSubType;

    @Input() memberAccount: MemberAccount;
    @Input() isAccountingLinked = false;
    @Input() isMetrcLinked = false;

    @Output() transactionReviewed: EventEmitter<Transaction> = new EventEmitter<Transaction>();
    @Output() totalChanged: EventEmitter<string> = new EventEmitter<string>();

    currentBusinessClient: BusinessClient<U>;
    manifests: ManifestSelection[] = [];
    businessClients: BusinessClient<U>[] = [];
    manifestSupportingDocs: SupportingDocument[] = [];
    formGroup: UntypedFormGroup;
    transactionTotal = '0.00';
    viewTerms = false;
    changeTrigger = 1;
    isReviewStepValid = true;

    stepperOrientation: Observable<StepperOrientation>;

    @ViewChild('stepper') protected stepper: MatStepper;

    protected constructor(protected breakpointObserver: BreakpointObserver,
                protected cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.stepperOrientation = this.breakpointObserver.observe('(min-width: 800px)').pipe(
            map((matches: BreakpointState) => {
                return matches.matches ? 'horizontal' : 'vertical';
            })
        );
        this.onSubmit = this.onSubmit.bind(this);
        this.onAcceptTerms = this.onAcceptTerms.bind(this);
        this.onCancelTerms = this.onCancelTerms.bind(this);
    }

    abstract onSubmit(reset: any);
    abstract onTransactionReviewed(reset: any);
    abstract calculateTotal(selectedBills?: TransactionEntrySelection<U>[]);

    onAcceptTerms(reset: any) {
        reset();
        const contentContainer = document.querySelector('.mat-dialog-container');
        contentContainer.scrollTo(0, contentContainer.scrollHeight);
        this.viewTerms = false;
        this.onSubmit(reset);
    }

    /* Normally this cancel button is working independently.
        There is no validation on deposit/ payment modal for that when user clicks the submit button should be disable and form is valid.
        Then onwards form is always valid. for that marking the form as dirty and enable the submit button again */
    onCancelTerms(reset: any) {
        reset();
        this.viewTerms = false;
        this.isReviewStepValid = false;
        setTimeout(() => {
            this.isReviewStepValid = true;
        }, 50);
    }

    nextStep() {
        this.stepper.selected.completed = true;
        this.stepper.next();
    }

    onSelectManifest(selectedManifest: ManifestSelection) {
        this.stepper.selected.completed = false;
        if (this.isMetrcLinked) {
            this.stepper.steps.get(3).completed = false;
        }
        if (selectedManifest.included) {
            this.manifests.push(selectedManifest);
        } else {
            const index = this.manifests.findIndex((manifest) => {
                return manifest.data.id === selectedManifest.data.id;
            });
            if (index > -1) {
                this.manifests.splice(index, 1);
            }
        }
        this.manifests = this.manifests.slice();
    }

    onSelectManifestDocument(manifestSupportDoc: SupportingDocument) {
        this.manifestSupportingDocs.push(manifestSupportDoc);
        this.cdr.detectChanges();
    }

    isEntryStepEditable() {
        return !!this.currentBusinessClient;
    }

    isTrackingStepEditable() {
        return true;
    }

    isReviewStepEditable() {
        return this.currentBusinessClient?.transactionEntries?.length > 0;
    }

    onSelectInvoices(selectedInvoices?: TransactionEntrySelection<U>[]) {
        this.calculateTotal(selectedInvoices);
        this.changeTrigger = Math.random();
        this.stepper.selected.completed = false;
        this.stepper.steps.get(2).completed = false;
        if (this.isMetrcLinked) {
            this.stepper.steps.get(3).completed = false;
        }
        this.cdr.detectChanges();
    }

    getInvoiceLabel() {
        const isRetail = (this.formGroup.get('sourceCtrl').value === TransactionSource.RETAIL_TRANSACTION);
        if (this.currentBusinessClient?.transactionEntries?.length) {
            if (this.currentBusinessClient?.transactionEntries?.length === 1) {
                return `$${this.currentBusinessClient.selectedTotal} (1 ${isRetail ? 'receipt' : 'invoice'})`;
            }
            return `$${this.currentBusinessClient.selectedTotal} (${this.currentBusinessClient?.transactionEntries?.length} ${isRetail ? 'receipts' : 'invoices'})`;
        }
        if (isRetail) {
            return 'Select Receipt Listings';
        }
        return 'Select Invoices';
    }

    onSelectBills(selectedBills?: TransactionEntrySelection<U>[]) {
        this.calculateTotal(selectedBills);
        this.changeTrigger = Math.random();
        this.stepper.selected.completed = false;
        this.stepper.steps.get(2).completed = false;
        if (this.isMetrcLinked) {
            this.stepper.steps.get(3).completed = false;
        }
        this.cdr.detectChanges();
    }

    getBillLabel() {
        if (this.currentBusinessClient?.transactionEntries?.length) {
            if (this.currentBusinessClient?.transactionEntries?.length === 1) {
                return `$${this.currentBusinessClient.selectedTotal} (1 bill)`;
            }
            return `$${this.currentBusinessClient.selectedTotal} (${this.currentBusinessClient?.transactionEntries?.length} bills)`;
        }
        return 'Select Bills';
    }

    getTrackingLabel() {
        let trackingLabel = '';
        if (this.manifests?.length) {
            if (this.manifests.length === 1) {
                trackingLabel += '1 manifest';
            } else {
                trackingLabel += `${this.manifests.length} manifests`;
            }
            let packageCount = 0;
            this.manifests.forEach((manifest) => {
                packageCount += manifest.data.lineItems?.length;
            });
            if (packageCount === 1) {
                trackingLabel += ' (1 package)';
            } else {
                trackingLabel += ` (${packageCount} packages)`;
            }
        }
        if (this.manifestSupportingDocs.length > 0) {
            if (trackingLabel) {
                trackingLabel += ', ';
            }
            trackingLabel += `${this.manifestSupportingDocs.length} ${this.manifestSupportingDocs.length === 1 ? 'document' : 'documents'}`;
        }
        return trackingLabel || 'Tracking';
    }

    getInvoiceBusinessClientLabel() {
        return this.currentBusinessClient ? this.currentBusinessClient.name : 'Select Payor';
    }

    getBillBusinessClientLabel() {
        return this.currentBusinessClient ? this.currentBusinessClient.name : 'Select Recipient';
    }

    getTransactionType() {
        return this.formGroup.controls['transactionTypeCtrl'].value;
    }
}
