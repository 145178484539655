import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { LoaderComponent } from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import {
    AccountToken, AddressType, Bill, Configuration, LandingSettings, OnboardingFormService, MemberAccountStatus, PartnerService, Task, Utils, TransactionStatus, MemberStatus,
    BNPLConfiguration
} from 'projects/services/src/public-api';

@Component({
    selector: 'pt-retail-payment',
    templateUrl: './retail-payment.component.html',
    styleUrls: ['./retail-payment.component.scss'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS,
        useValue: { displayDefaultIndicatorType: false }
    }]
})
export class RetailPaymentComponent implements OnInit {

    AddressType = AddressType;
    RetailFlow = RetailFlow;

    partnerId: string;
    processInstanceId: string;
    paymentProcessInstanceId: string;
    transactionStatus: TransactionStatus;

    landingSettings: LandingSettings;
    imageSrc: any;
    errorMessage: string;
    task: Task;

    termsAccepted = true;
    consumerValid = false;
    isQaEnabled = false;
    useTestBankAccount = false;
    accountToken: AccountToken;
    selected : any =  'underline';
    selectedPercentage = '';

    contactInfoForm: UntypedFormGroup;
    addressForm: UntypedFormGroup;
    verificationForm: UntypedFormGroup;
    tipForm: UntypedFormGroup;
    accountLinked = false;
    isOtherTipPayment = true;
    accountLinkResult: string;
    expense: Bill;
    transactionTotal: number;
    tipError: string;
    selectedPaymentType = 'BANK_ACCOUNT';
    isBNPLPayment = false;
    isBNPLEnabled = false;
    recipientHasBnplAccess = false;
    bnplConfig: BNPLConfiguration;
    payLaterTotal: number;
    convenienceFee: number;
    initialPaymentToday: number;

    flow: RetailFlow = RetailFlow.PAY_WITH_CONFIA_BANKING_REQUIRED;

    stepperOrientation: StepperOrientation = 'horizontal';

    transactionCancelledStatuses = [TransactionStatus.CANCELLED_BY_AUTHORITY, TransactionStatus.CANCELLED_BY_CLIENT, TransactionStatus.CANCELLED_BY_SYSTEM, TransactionStatus.CANCELLED_INSUFFICIENT_FUNDS];

    @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef;
    @ViewChild('stepper') private stepper: MatStepper;

    constructor(private route: ActivatedRoute,
                private partnerService: PartnerService,
                private notificationService: NotificationService,
                private onboardingFormService: OnboardingFormService,
                private overlay: Overlay,
                private cdr: ChangeDetectorRef,
                private notifier: NotificationService,
                private breakpointObserver: BreakpointObserver) {}

    ngOnInit() {
        this.breakpointObserver.observe(['(max-width: 799px)']).subscribe((result: BreakpointState) => {
            this.stepperOrientation = result.matches ? 'vertical' : 'horizontal';
        });

        this.isOnboardingFormValid = this.isOnboardingFormValid.bind(this);
        this.onSave = this.onSave.bind(this);
        this.partnerId = this.route.snapshot.params.partnerId;
        this.processInstanceId = this.route.snapshot.params.processInstanceId;
        this.paymentProcessInstanceId = this.route.snapshot.params.paymentProcessInstanceId;
        this.isQaEnabled = Configuration.getConfig().qaEnabled;
        this.imageSrc = {
            key: this.partnerId,
            src: this.partnerService.getImage(this.partnerId)
        };
        this.partnerService.getCustomizationPageSettings(this.partnerId).subscribe((landingSettingsResponse: LandingSettings) => {
            this.landingSettings = landingSettingsResponse;
            this.loadTask();
        }, () => {
            this.loadTask();
        });
    }

    loadTask() {
        this.partnerService.getConsumerInstanceTaskVariables(this.partnerId, this.paymentProcessInstanceId).subscribe((variables: any) => {
            if (variables.registrationStatus === MemberStatus.ACTIVE) {
                this.accountLinked = true;
                this.loadPaymentTask();
            } else {
                this.loadOnboardingTask();
            }
        }, () => {
            this.task = null;
            this.termsAccepted = false;
            this.errorMessage = 'Link is not valid.';
        });
    }

    loadOnboardingTask() {
        this.partnerService.getConsumerTaskByPartnerId(this.partnerId, this.processInstanceId).subscribe((task: Task) => {
            this.task = task;
            this.accountLinked = (this.task.variables.bankAccountResponse === 'BANK_ACCOUNT_ACTIVE');
            if (!this.task.variables.consumerName) {
                this.contactInfoForm = this.onboardingFormService.initializeContactInfo(this.task.variables);
                if (this.task.variables.dateOfBirth) {
                    this.contactInfoForm.get('birthDateCtrl').disable();
                }
                if (this.task.variables.lastName) {
                    this.contactInfoForm.get('lastNameCtrl').disable();
                }
                if (this.task.variables.mobilePhone) {
                    this.contactInfoForm.get('mobilePhoneCtrl').disable();
                }
                if (this.task.variables.email) {
                    this.contactInfoForm.get('emailCtrl').disable();
                }
                this.addressForm = this.onboardingFormService.initializeAddress(this.task.variables);
                this.verificationForm = this.onboardingFormService.initializeVerificationForm();
                this.tipForm = this.onboardingFormService.initializeTipForm();
                this.flow = RetailFlow.PAY_WITH_CONFIA_ONBOARD;
            } else if (!this.accountLinked) {
                this.verificationForm = this.onboardingFormService.initializeVerificationForm();
                this.tipForm = this.onboardingFormService.initializeTipForm();
                this.termsAccepted = false;
                this.consumerValid = true;
                this.flow = RetailFlow.PAY_WITH_CONFIA_BANKING_REQUIRED;
            } else {
                this.accountLinked = true;
                this.termsAccepted = true;
                this.consumerValid = true;
            }
        }, () => {
            this.task = null;
            this.termsAccepted = false;
            this.errorMessage = 'Link is not valid.';
        });
    }

    loadPaymentTask() {
        this.partnerService.getConsumerTaskByPartnerId(this.partnerId, this.paymentProcessInstanceId).subscribe((task: Task) => {
            this.task = task;
            this.transactionTotal = this.task.variables.transactionTotal;
            this.verificationForm = this.onboardingFormService.initializeVerificationForm();
            this.tipForm = this.onboardingFormService.initializeTipForm();
            this.tipForm.controls['tipCtrl'].setValue(Utils.formatCurrency(this.task.variables.tipAmount, true));
            this.termsAccepted = true;
            this.consumerValid = true;
            this.accountLinkResult = 'Linked';
            this.onTipChange();
            if (this.task.variables.expenseId) {
                this.partnerService.getConsumerExpense(this.partnerId, this.task.variables.consumerId,  this.task.variables.expenseId).subscribe((expense: Bill) => {
                    this.expense = expense;
                });
            }
        }, () => {
            this.task = null;
            this.termsAccepted = false;
            this.errorMessage = 'Link is not valid.';
        });
    }

    isOnboardingFormValid() {
        return this.contactInfoForm.valid && this.addressForm.valid;
    }

    onAcceptTerms(useTestAccount: boolean) {
        this.useTestBankAccount = useTestAccount;

        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });

        overlayRef.attach(new ComponentPortal(LoaderComponent));

        this.partnerService.getConsumerAccessToken(this.task.variables.consumerId).subscribe((response: AccountToken) => {
            overlayRef.dispose();
            this.termsAccepted = true;
            this.consumerValid = true;
            this.cdr.detectChanges();
            if (this.flow === RetailFlow.PAY_WITH_CONFIA_ONBOARD) {
                this.stepper.selectedIndex = 2;
            } else {
                this.stepper.selectedIndex = 0;
            }
            this.accountToken = response;
        }, (error: any) => {
            overlayRef.dispose();
            throw error;
        });
    }

    onCancel() {
        window.location.href = this.task.variables.failureUrl || '';
    }

    onSuccess() {
        window.location.href = this.task.variables.successUrl || '';
    }

    checkTransactionStatusAndRedirect() {
        this.transactionCancelledStatuses.includes(this.transactionStatus) ? this.onCancel() : this.onSuccess();
    }

    onSelection(result: string) {
        if (result === 'Cancel') {
            this.onCancel();
        } else if (result === 'Retry') {
            const overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                hasBackdrop: true
            });

            overlayRef.attach(new ComponentPortal(LoaderComponent));

            this.partnerService.getConsumerAccessToken(this.task.variables.consumerId).subscribe((response: AccountToken) => {
                overlayRef.dispose();
                this.consumerValid = true;
                this.accountToken = response;
            }, (error: any) => {
                overlayRef.dispose();
                throw error;
            });
        } else {
            this.accountLinkResult = result;
            this.stepper.selected.completed = true;
            this.stepper.selected.editable = false;
            this.stepper.next();
            this.loadPaymentTask();
        }
    }

    onSave() {
        const body = this.onboardingFormService.getOnboardingInformation(this.contactInfoForm, this.addressForm);
        body.partnerId = this.partnerId;

        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        overlayRef.attach(new ComponentPortal(LoaderComponent));

        this.partnerService.completeConsumerRegistrationTask(this.task.id, body).subscribe((result: any) => {
            this.task.variables.consumerId = result.consumerId;
            this.accountLinked = (result.status === MemberAccountStatus.ACTIVE);
            if (this.accountLinked) {
                this.accountLinkResult = 'Linked';
                this.consumerValid = true;
                this.stepper.selected.completed = true;
                this.stepper.selected.editable = false;
                this.stepper.next();
                this.loadPaymentTask();
                overlayRef.dispose();
            } else {
                this.partnerService.getConsumerTaskByPartnerId(this.partnerId, this.processInstanceId).subscribe((task: Task) => {
                    this.task = task;
                    this.termsAccepted = false;
                    overlayRef.dispose();
                });
            }
        });
    }

    onTipChange() {
        const tipAmount = Utils.formatNonNegativeCurrency(this.tipForm.controls['tipCtrl'].value.toString(), true);
        if (tipAmount && (this.task.variables.transactionTotal / Number(tipAmount)) < 2.00) {
            this.tipForm.controls['tipCtrl'].setValue(Utils.formatCurrency(tipAmount, true));
            this.tipError = 'Tip cannot exceed 50%.';
            this.transactionTotal = this.task.variables.transactionTotal;
        } else {
            this.tipForm.controls['tipCtrl'].setValue(Utils.formatCurrency(tipAmount, true));
            this.tipError = '';
            this.transactionTotal = Number(this.task.variables.transactionTotal) + Number(this.tipForm.controls['tipCtrl'].value || 0);
        }
        this.recipientHasBnplAccess = this.task.variables.hasBnplAccess;
        if (this.recipientHasBnplAccess) {
            this.checkConsumerCreditLimit();
        }
    }

    onTipSelect(percentage: string) {
        if (percentage === 'other') {
            this.selectedPercentage = '';
            this.isOtherTipPayment = true;
            this.tipForm.controls['tipCtrl'].setValue('');
            this.onTipChange();
            return;
        }
        if (this.selectedPercentage === percentage) {
            return this.reset();
        }
        this.selectedPercentage = percentage;
        this.isOtherTipPayment = false;
        this.tipForm.controls['tipCtrl'].setValue(this.getPercentage(percentage));
        this.onTipChange();
    }

    getPercentage(percentage) {
        if (!this.task.variables.transactionTotal) {
            return;
        }
        const percentageAmount = Number(this.task.variables.transactionTotal) * Number(percentage) / 100;
        return percentageAmount.toFixed(2);
    }

    getTipAmount() {
        const tipAmount = this.tipForm.controls['tipCtrl'].value;
        if (isNaN(tipAmount)) {
            return 0;
        }
        return tipAmount;
    }

    reset() {
        this.selected = void 0;
        this.onTipSelect('other');
    }

    getClass() {
        if (this.isOtherTipPayment) {
            return 'other-tip-selected';
        }
        return 'other-tip-excluded';
    }

    onReviewed() {
        const body = {
            tipAmount: this.tipForm.controls['tipCtrl'].value || '0.00',
            partnerId: this.partnerId,
            action: 'TIP_AMOUNT',
            bnplAmount: this.isBNPLPayment ? this.payLaterTotal.toString() : '0.00'
        };

        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        overlayRef.attach(new ComponentPortal(LoaderComponent));

        this.partnerService.completeConsumerPaymentVerificationTask(this.task.id, body).subscribe((_result: any) => {
            overlayRef.dispose();
            this.stepper.selected.completed = true;
            this.stepper.selected.editable = false;
            this.stepper.next();
        }, (error: any) => {
            overlayRef.dispose();
            if (error.status === 410 || error.status === 404) {
                this.notificationService.showError('Payment flow has expired.');
            } else {
                throw error;
            }
        });
    }

    onResendCode() {
        this.notifier.clear();
        const body = {
            partnerId: this.partnerId,
            action: 'RESEND_CODE'
        };

        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        overlayRef.attach(new ComponentPortal(LoaderComponent));

        this.partnerService.getConsumerTaskByPartnerId(this.partnerId, this.paymentProcessInstanceId).subscribe((task: Task) => {
            this.partnerService.completeConsumerPaymentVerificationTask(task.id, body).subscribe((_result: any) => {
                overlayRef.dispose();
            }, (error: any) => {
                overlayRef.dispose();
                throw error;
            });
        },
        (error: any) => {
            overlayRef.dispose();
            if (error.status === 410 || error.status === 404) {
                this.notificationService.showError('Payment flow has expired.');
            } else {
                throw error;
            }
        });
    }

    onVerificationCode() {
        this.notifier.clear();
        const body = {
            verificationCode: this.verificationForm.controls['codeCtrl'].value,
            partnerId: this.partnerId,
            action: 'VERIFY'
        };

        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        overlayRef.attach(new ComponentPortal(LoaderComponent));

        this.partnerService.getConsumerTaskByPartnerId(this.partnerId, this.paymentProcessInstanceId).subscribe((task: Task) => {
            this.partnerService.completeConsumerPaymentVerificationTask(task.id, body).subscribe((_result: any) => {
                this.transactionStatus = _result.status;
                overlayRef.dispose();
                this.stepper.selected.completed = true;
                this.stepper.selected.editable = false;
                this.stepper.next();
            }, (error: any) => {
                overlayRef.dispose();
                this.waitAndRedirectUrl();
                if ((error.status === 403) && error.error.message) {
                    this.notificationService.showError(error.error.message);
                } else {
                    throw error;
                }
            });
        }, (error: any) => {
            overlayRef.dispose();
            this.waitAndRedirectUrl();
            if (error.status === 410 || error.status === 404) {
                this.notificationService.showError('Payment flow has expired.');
            } else {
                throw error;
            }
        });
    }

    buttonStyle() {
        return {
            'background': this.landingSettings?.buttonsColor,
            'color': this.landingSettings?.buttonsTextColor
        };
    }

    checkConsumerCreditLimit() {
        if (this.task.variables.bnplConfig) {
            this.bnplConfig = this.task.variables.bnplConfig;
            const tipAmount = Number(Utils.formatNonNegativeCurrency(this.tipForm.controls['tipCtrl'].value.toString(), true));
            const transactionAmount = this.task.variables.transactionTotal;
            const bnplLoanAmount = transactionAmount * (this.bnplConfig.maxAllowedBnplPercentage * 0.01);
            this.isBNPLEnabled = this.validateBnplAmount(this.task.variables, bnplLoanAmount);
            if (this.isBNPLEnabled) {
                this.convenienceFee = this.bnplConfig.bnplProcessingCharges;
                this.payLaterTotal = bnplLoanAmount;
                this.initialPaymentToday = tipAmount + this.convenienceFee + (transactionAmount - bnplLoanAmount);
            }
        }
    }

    validateBnplAmount(taskVariables: any, bnplLoanAmount: number) {
        return (taskVariables.consumerCreditLimit >= bnplLoanAmount) && (bnplLoanAmount >= taskVariables.bnplConfig.minAllowedBnplAmount);
    }

    onTypeChanged(type: string) {
        this.selectedPaymentType = type;
        this.isBNPLPayment = this.selectedPaymentType === 'BNPL';
    }

    waitAndRedirectUrl() {
        setTimeout(() => {
            this.onCancel();
        }, 5000);
    }
}

export enum RetailFlow {
    PAY_WITH_CONFIA = 'PAY_WITH_CONFIA',
    PAY_WITH_CONFIA_ONBOARD = 'PAY_WITH_CONFIA_ONBOARD',
    PAY_WITH_CONFIA_BANKING_REQUIRED = 'PAY_WITH_CONFIA_BANKING_REQUIRED'
}
