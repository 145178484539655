<div>
    <form [formGroup]="reviewNotesForm">
        <div>
            <div class="modal-header">
                <label class="form-title">{{ formTitle }}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div>
                    <pc-text-area [required]="true" [label]="textLabel"
                        [formCtrl]="'reasonCtrl'" [formGroup]="reviewNotesForm">
                    </pc-text-area>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="formEnabled" [submit]="onSubmit" [text]="buttonText"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
