import { Component, OnInit } from '@angular/core';
import { AuthService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-settings-management',
    templateUrl: './settings-management.component.html'
})
export class SettingsManagementComponent implements OnInit {

    profile: any;

    constructor(public authService: AuthService) {
    }

    ngOnInit() {
        this.profile = this.authService.getProfile();
    }
}
