<pc-user-table-header
    [status]="status"
    [memberId]="memberId"
    [readOnly]="readOnly"
    (userChanged)="refreshUsers()">
</pc-user-table-header>
<pc-member-user-list
    [memberId]="memberId"
    [memberFunctions]="memberFunctions"
    [changeTrigger]="changeTrigger"
    [readOnly]="readOnly">
</pc-member-user-list>
