import { Component, Input, OnChanges } from '@angular/core';
import { AccountSummary, PaymentService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-account-summary-list',
    templateUrl: './account-summary-list.component.html',
    styleUrls: ['./account-summary-list.component.scss']
})
export class AccountSummaryListComponent implements OnChanges {

    @Input() memberAccountId: string = '';
    @Input() changeTrigger: number;

    accountSummary: AccountSummary [] = [];

    constructor(private paymentService: PaymentService) {
    }

    ngOnChanges() {
        if (this.memberAccountId) {
            this.paymentService.getSummaryReportForAccount(this.memberAccountId).subscribe((accountSummary: AccountSummary[]) => {
                this.accountSummary = accountSummary;
            });
        }
    }
}
