import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { KycSearchParams } from './models/kyc/search/kyc-search-params';
import { PagedResponse } from './models/paged-response';
import { SearchResult } from './models/kyc/search/search-result';

@Injectable({
    providedIn: 'root'
})
export class KycSearchService {

    baseUrl: string;

    constructor(private http: HttpService, @Inject('environment') environment: any) {
        this.baseUrl = environment.EntityAPIEndpoint + '/kyc';
    }

    findSearches(params: KycSearchParams): Observable<PagedResponse<SearchResult>> {
        return this.http.post(`${this.baseUrl}/search`, params) as Observable<PagedResponse<SearchResult>>;
    }

    createSearch(body: any): Observable<SearchResult> {
        return this.http.post(`${this.baseUrl}`, body);
    }

    deleteSearch(id: string): Observable<any> {
        return this.http.delete(`${this.baseUrl}/${id}`);
    }

    updateMonitor(id: string, updates: any): Observable<any> {
        return this.http.put(`${this.baseUrl}/${id}/monitor`, updates);
    }
}
