import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowService, Task, SmartContractService, ContractTemplate } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-sc-edit-template-terms',
    templateUrl: './edit-template-terms.component.html'
})
export class EditTemplateTermsComponent implements OnInit {

    @Input() task: Task;

    @Output() taskChanged: EventEmitter<Task> = new EventEmitter<Task>();

    contractTemplate: ContractTemplate;

    constructor(private workflowService: WorkflowService,
                private smartContractService: SmartContractService) {}

    ngOnInit() {
        this.task = this.workflowService.getCurrentTask();
        this.taskChanged.emit(this.task);
        this.smartContractService.getTemplateById(this.task.variables.template).subscribe((template: ContractTemplate) => {
            this.contractTemplate = template;
        });
    }
}
