import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
    BusinessClient, Invoice, TransactionFormService, TransactionEntrySelection, TransactionSource, TransactionType
} from 'projects/services/src/public-api';
import { v4 as uuidv4 } from 'uuid';
import { BaseTransactionComponent } from '../../common/base-transaction-component/base-transaction.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

@Component({
    selector: 'pt-retail-cash-deposit',
    templateUrl: './retail-cash-deposit.component.html',
    styleUrls: ['./retail-cash-deposit.component.scss'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS,
        useValue: { displayDefaultIndicatorType: false }
    }]
})
export class RetailCashDepositComponent extends BaseTransactionComponent<Invoice> implements OnInit {

    readonly CASH_ONLY_DEPOSIT_METHODS = [{
        id: TransactionType.CASH_DEPOSIT,
        name: 'Cash Deposit'
    }];

    constructor(private transactionFormService: TransactionFormService,
                protected breakpointObserver: BreakpointObserver,
                protected cdr: ChangeDetectorRef) {
        super(breakpointObserver, cdr);
    }

    ngOnInit() {
        super.ngOnInit();

        this.formGroup = this.transactionFormService.initializeDepositForm();
        this.formGroup.get('memberAccountNameCtrl').setValue(this.memberAccount.accountName);
        this.formGroup.get('sourceCtrl').setValue(TransactionSource.RETAIL_TRANSACTION);
        this.formGroup.get('transactionTypeCtrl').setValue(TransactionType.CASH_DEPOSIT);
        this.formGroup.get('transactionTypeCtrl').disable();
        this.currentBusinessClient = new BusinessClient<Invoice>();
        this.currentBusinessClient.defaultTransactionType = TransactionType.CASH_DEPOSIT;
    }

    onSelectInvoices(selectedInvoices: TransactionEntrySelection<Invoice>[]) {
        if (selectedInvoices && selectedInvoices.length) {
            this.currentBusinessClient.transactionEntries = selectedInvoices;
            this.transactionTotal = this.currentBusinessClient.selectedTotal;
        } else {
            this.currentBusinessClient.transactionEntries = [];
            this.transactionTotal = '0.00';
        }
        this.totalChanged.emit(this.transactionTotal);
        this.stepper.selected.completed = false;
        this.stepper.steps.get(1).completed = false;
    }

    onTransactionReviewed() {
        this.viewTerms = true;
        const contentContainer = document.querySelector('.mat-dialog-container');
        contentContainer.scrollTo(0, 0);
    }

    onSubmit(reset: any) {
        const transaction = this.transactionFormService.getTransaction(this.formGroup);
        transaction.submissionReference = uuidv4();
        transaction.totalAmount = Number(this.transactionTotal);
        transaction.source = TransactionSource.RETAIL_TRANSACTION;
        transaction.transactionDate = null;
        transaction.incomes = this.currentBusinessClient.transactionEntries.map((transactionEntry: TransactionEntrySelection<Invoice>) => {
            return transactionEntry.data;
        });
        reset();
        this.viewTerms = false;
        this.transactionReviewed.emit(transaction);
    }

    // eslint-disable-next-line no-empty-function
    calculateTotal(_selectedBills?: TransactionEntrySelection<Invoice>[]) {}
}
