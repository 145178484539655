<div *ngIf="counterPartyFormGroup">
    <div class="my-2" *ngIf="currentBusinessClient">
        <div class="row">
            <div class="col-12 d-inline-flex">
                <div class="flex-grow-1">
                    <pc-tracking-counter-party-selector [class]="'full'" [formGroup]="counterPartyFormGroup" [counterParties]="counterParties"
                        [label]="'Tracking System Manifests for:'" [formCtrl]="'trackingCounterPartyCtrl'" [placeholder]="placeholder"
                        (counterPartySelected)="counterPartySelected($event)">
                    </pc-tracking-counter-party-selector>
                </div>
                <div class="flex-grow-0 ms-3">
                    <button class="blue-button button-small pull-right" type="button" (click)="addManifestSupportingDocument()">
                        <i class="fa fa-plus pull-left dashboard-icon"></i>
                        <div class="pull-right-button">Add Manifest Document</div>
                    </button>
                </div>
            </div>
            <div *ngIf="manifestSupportingDocs.length > 0" class="row">
                <div class="manifest-support-document col-12 w-100" *ngFor="let manifestSupportingDoc of manifestSupportingDocs; let documentNumber = index">
                    <div class="dashboard-list-a pull-right"><i class="fa fa-2x fa-paperclip"></i>{{ manifestSupportingDoc.file?.name }}</div><i class="fa fa-2x fa-times pull-right" (click)="removeManifestSupportingDocument(documentNumber)"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center loading" *ngIf="isCounterPartySelected() && manifestsLoading">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i><span>Loading manifests...</span>
    </div>
    <div class="d-flex justify-content-center loading" *ngIf="isCounterPartySelected() && !manifestsLoading && customerManifests.length === 0">
        No manifest data found for parties involved in this transaction.
    </div>
    <div class="manifest-selections" *ngIf="!manifestsLoading && customerManifests.length">
        <table #trackingManifestTable mat-table [dataSource]="customerManifests" multiTemplateDataRows>
            <ng-container matColumnDef="manifest_number">
                <th mat-header-cell *matHeaderCellDef>Manifest #</th>
                <td mat-cell *matCellDef="let element">
                    <i class="fa" [class.fa-plus-circle]="expandedElement !== element" [class.fa-minus-circle]="expandedElement === element"
                       (click)="expandedElement = (expandedElement === element && element.data.type !== 'OTHER') ? null : element"></i>{{element.data.manifestNumber}}</td>
            </ng-container>
            <ng-container matColumnDef="supplier">
                <th mat-header-cell *matHeaderCellDef>Supplier</th>
                <td mat-cell *matCellDef="let element"> {{element.data.shipperFacilityName}} </td>
            </ng-container>
            <ng-container matColumnDef="recipient">
                <th mat-header-cell *matHeaderCellDef> Recipient </th>
                <td mat-cell *matCellDef="let element"> {{element.data.recipientFacilityName}} </td>
            </ng-container>
            <ng-container matColumnDef="created">
                <th mat-header-cell class="centered" *matHeaderCellDef>Created Date</th>
                <td mat-cell class="centered" *matCellDef="let element"> {{element.data.created | date: 'mediumDate'}} </td>
            </ng-container>
            <ng-container matColumnDef="received_date">
                <th mat-header-cell class="centered" *matHeaderCellDef>Received Date</th>
                <td mat-cell class="centered" *matCellDef="let element"> {{element.data.receivedDateTime | date: 'mediumDate'}} </td>
            </ng-container>
            <ng-container matColumnDef="package_count">
                <th mat-header-cell class="centered" *matHeaderCellDef>Package Count</th>
                <td mat-cell class="centered" *matCellDef="let element"> {{element.data.lineItems?.length}} </td>
            </ng-container>
            <ng-container matColumnDef="included">
                <th mat-header-cell class="centered" *matHeaderCellDef>Include</th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <i class="fa" [class.fa-check-square-o]="element.included" [class.fa-square-o]="!element.included" (click)="onSelected(element)"></i>
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="manifestColumns.length">
                    <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                        <pc-manifest-line [packages]="element.data.lineItems"></pc-manifest-line>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="manifestColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: manifestColumns;"
                class="example-expand-row"
                [class.example-expanded-row]="expandedElement === element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="example-detail-row"></tr>
        </table>
        <mat-paginator #paginator class="mat-paginator-sticky" [pageIndex]="pageIndex" (page)="handlePageEvent($event)" [length]="resultsLength" [pageSizeOptions]="[10, 25]" [pageSize]="pageSize" showFirstLastButtons aria-label="Select page"></mat-paginator>
    </div>
    <input hidden type="file" id="manifestUpload" #manifestUpload (change)="selectManifestFile($event)" placeholder="Upload manifest file" accept=".pdf">
    <div class="mt-3 row">
        <div class="col-12 d-flex justify-content-end">
            <button class="blue-button" (click)="next()" type="button">Next</button>
        </div>
    </div>
</div>
