<div class="dashboard-info" *ngIf="accountThresholds">
    <div class="row">
        <div class="col-4">
            <div class="row">
                <div class="col-9 expandedLabel">
                    Expected Monthly Cash Deposit
                </div>
                <div class="col-3 expandedValue">
                    <span>{{accountThresholds.expectedMonthlyCashDepositAmount | currency}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-9 expandedLabel">
                    Expected Monthly Payments
                </div>
                <div class="col-3 expandedValue">
                    <span>{{accountThresholds.expectedMonthlyPaymentAmount | currency}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-9 expandedLabel">
                    Monthly Cash Deposit Limit
                </div>
                <div class="col-3 expandedValue">
                    <span>{{accountThresholds.monthlyCashDepositAmountLimit | currency}}</span>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="row">
                <div class="col-9 expandedLabel">
                    Maximum Transaction Total
                </div>
                <div class="col-3 expandedValue">
                    <span>{{accountThresholds.maxTransactionTotal | currency}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-9 expandedLabel">
                    Maximum Total of Monthly Transactions
                </div>
                <div class="col-3 expandedValue">
                    <span>{{accountThresholds.maxTransactionTotalPerMonth | currency}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-9 expandedLabel">
                    Maximum Account Size
                </div>
                <div class="col-3 expandedValue">
                    <span>{{accountThresholds.accountAmountLimit | currency}}</span>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="row">
                <div class="col-9 expandedLabel">
                    Expected Number of Monthly Transactions
                </div>
                <div class="col-3 expandedValue">
                    <span>{{accountThresholds.expectedMonthlyTransactions}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-9 expandedLabel">
                    Maximum Number of Daily Transactions
                </div>
                <div class="col-3 expandedValue">
                    <span>{{accountThresholds.maxNumberOfTransactionsPerDay}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-9 expandedLabel">
                    Maximum Number of Monthly Transactions
                </div>
                <div class="col-3 expandedValue">
                    <span>{{accountThresholds.maxNumberOfTransactionsPerMonth}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
