import { Component, OnInit } from '@angular/core';
import { Transaction, PaymentService, WorkflowService, Task, PagedResponse, TransactionStatus, MemberService } from 'projects/services/src/public-api';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'pt-complete-transaction',
    templateUrl: './transaction-completion.component.html',
    styleUrls: ['./transaction-completion.component.scss']
})
export class TransactionCompletionComponent implements OnInit {

    transaction: Transaction;
    task: any;

    constructor(private route: ActivatedRoute,
                private paymentService: PaymentService,
                private workflowService: WorkflowService,
                private memberService: MemberService,
                private router: Router) {
    }

    ngOnInit() {
        this.cancel = this.cancel.bind(this);
        this.complete = this.complete.bind(this);

        this.route.params.subscribe((params) => {
            const id = params['transactionId'];
            this.paymentService.getTransaction(id).subscribe((transaction: Transaction) => {
                if (transaction.status !== TransactionStatus.PENDING_DELIVERY) {
                    this.router.navigate(['/transaction/details/', transaction.id]);
                } else {
                    this.transaction = transaction;
                    this.paymentService.loadTransaction(this.transaction);
                    this.workflowService.findTaskByTaskDefinitionsAndProcessVariable(['confirm_deposit'], 'transactionId', transaction.id).subscribe((tasks: PagedResponse<Task>) => {
                        if (tasks.content.length !== 1) {
                            this.router.navigate(['/transaction/details/', this.transaction.id]);
                        } else {
                            this.task = tasks.content[0];
                        }
                    });
                }
            });
        });
    }

    complete() {
        this.workflowService.completeTask(this.task.id, {}).subscribe(() => {
            this.router.navigate(['/transaction/details/', this.transaction.id]);
        });
    }

    cancel() {
        this.router.navigate(['/transaction/list']);
    }

    navigateToMember(memberId: string) {
        this.memberService.navigateToMember(memberId);
    }
}
