import { BankAccountStatus } from './bank-account';

export class AccountToken {
    accountIds: string[];
    memberId: string;
    authToken: string;
    callbackUrl: string;
}

export class AccountResponse {
    results: AccountResult[];
}

export class AccountResult {
    accountName: string;
    accountStatus: BankAccountStatus;
}
