<div>
    <form [formGroup]="bankingIdentificationForm">
        <div>
            <div class="modal-header">
                <label class="form-title">Update Banking Identification</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div>
                    <pc-numeric-input [required]="true" [label]="'Customer ID'" [formCtrl]="'customerIdCtrl'" [formGroup]="bankingIdentificationForm">
                    </pc-numeric-input>
                    <pc-text-input [required]="true" [label]="'Account ID'" [formCtrl]="'accountIdCtrl'" [formGroup]="bankingIdentificationForm">
                    </pc-text-input>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="isBankingIdentificationFormValid" [submit]="onSubmit" [text]="'Update'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
