<pc-member-header [expandedMenu]="expandedMenu"
    (toggleExpandSideMenu)="onToggleExpandSideMenu()"
    *ngIf="authService.isMemberOrMemberReviewer()"></pc-member-header>
<pc-new-member-header [expandedMenu]="expandedMenu"
    (toggleExpandSideMenu)="onToggleExpandSideMenu()"
    *ngIf="authService.isNewRegistrant()"></pc-new-member-header>
<pc-retailer-header [expandedMenu]="expandedMenu"
    (toggleExpandSideMenu)="onToggleExpandSideMenu()"
    *ngIf="authService.isRetailer()"></pc-retailer-header>
<pc-authority-header [expandedMenu]="expandedMenu"
    (toggleExpandSideMenu)="onToggleExpandSideMenu()"
    *ngIf="authService.isAuthorityOrReviewer()"></pc-authority-header>
