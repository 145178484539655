import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
                private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot) {
        if (this.authService.isAuthenticated()) {
            if (route.data && route.data.roles && route.data.types) {
                if (route.data.functions) {
                    let hasMemberFunction = false;
                    for (const memberFunction of route.data.functions) {
                        if (this.authService.hasMemberFunction(memberFunction)) {
                            hasMemberFunction = true;
                        }
                    }
                    if (this.authService.hasAnyRole(route.data.roles) &&
                        (route.data.types.includes(this.authService.getProfile().memberType) || hasMemberFunction)) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                    }
                }  else {
                    if (this.authService.hasAnyRole(route.data.roles) && route.data.types.includes(this.authService.getProfile().memberType)) {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                    }
                }
            } else if (route.data && route.data.roles) {
                if (this.authService.hasAnyRole(route.data.roles)) {
                    return true;
                } else {
                    this.router.navigate(['/dashboard']);
                }
            } else if (route.data && route.data.types) {
                if (route.data.types.includes(this.authService.getProfile().memberType)) {
                    return true;
                } else {
                    this.router.navigate(['/dashboard']);
                }
            }
            return true;
        } else {
            const redirectUrl = window.location.pathname + window.location.search;
            if (redirectUrl === '/' || redirectUrl === '/login') {
                this.router.navigate(['/login']);
            } else {
                this.router.navigate(['/login'], { queryParams: { redirectUrl } });
            }
            return false;
        }
    }
}
