import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationLinks } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-retailer-sidebar',
    templateUrl: './retailer-sidebar.component.html'
})
export class RetailerSidebarComponent implements OnInit {

    @Input() expandedMenu: boolean;

    @Output() toggleExpandSideMenu: EventEmitter<any> = new EventEmitter<any>();

    menuActions: any[] = [];

    ngOnInit() {
        this.menuActions.push(NavigationLinks.transactionLink);
        this.menuActions.push(NavigationLinks.supportLink);
    }

    getMenuClass() {
        if (this.expandedMenu) {
            return 'sidenav-wide';
        } else {
            return 'sidenav-narrow';
        }
    }

    onToggleExpandSideMenu() {
        this.toggleExpandSideMenu.emit('toggle');
    }

}
