import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
    BusinessClient, BusinessClientStatus, Invoice, ManifestSelection,  TransactionEntrySelection, TransactionFormService, TransactionSource, TransactionType
} from 'projects/services/src/public-api';
import { v4 as uuidv4 } from 'uuid';
import { BaseTransactionComponent } from '../../common/base-transaction-component/base-transaction.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

@Component({
    selector: 'pt-business-cash-deposit',
    templateUrl: './business-cash-deposit.component.html',
    styleUrls: ['./business-cash-deposit.component.scss'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS,
        useValue: { displayDefaultIndicatorType: false }
    }]
})
export class BusinessCashDepositComponent extends BaseTransactionComponent<Invoice> implements OnInit {

    readonly CASH_ONLY_DEPOSIT_METHODS = [{
        id: TransactionType.CASH_DEPOSIT,
        name: 'Cash Deposit/Multiple Clients'
    }];

    clientContextTrigger = 1;

    @Output() closeTransactionModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private transactionFormService: TransactionFormService,
                protected breakpointObserver: BreakpointObserver,
                protected cdr: ChangeDetectorRef) {
        super(breakpointObserver, cdr);
    }

    ngOnInit() {
        super.ngOnInit();

        this.formGroup = this.transactionFormService.initializeDepositForm();
        this.formGroup.get('memberAccountNameCtrl').setValue(this.memberAccount.accountName);
        this.formGroup.get('sourceCtrl').setValue(TransactionSource.EXTERNAL_TRANSACTION);
        this.formGroup.get('transactionTypeCtrl').setValue(TransactionType.CASH_DEPOSIT);
        this.formGroup.get('transactionTypeCtrl').disable();
    }

    onChangeClientContext(businessClientContext: BusinessClient<Invoice>) {
        this.clearClientWithoutEntries();
        if (businessClientContext) {
            this.formGroup.controls['currentBusinessClientCtrl'].setValue(businessClientContext);
            this.currentBusinessClient = businessClientContext;
            this.stepper.reset();
            this.stepper.steps.get(1).editable = false;
            this.nextStep();
            this.stepper.steps.get(1).completed = true;
            this.stepper.steps.get(2).completed = true;
            if (this.isMetrcLinked) {
                this.stepper.steps.get(3).completed = false;
            }
        } else {
            this.formGroup.controls['currentBusinessClientCtrl'].setValue(null);
            this.currentBusinessClient = businessClientContext;
            this.stepper.reset();
            this.clientContextTrigger = Math.random();
        }
    }

    onRemoveClient(index: number) {
        this.clientContextTrigger = Math.random();
        const client = this.businessClients[index];
        this.businessClients.splice(index, 1);
        if (client.memberAccountId === this.currentBusinessClient.memberAccountId) {
            if (this.businessClients.length === 0) {
                this.formGroup.controls['currentBusinessClientCtrl'].setValue(null);
                this.currentBusinessClient = null;
                this.stepper.reset();
            } else {
                this.currentBusinessClient = this.businessClients[0];
                this.formGroup.controls['currentBusinessClientCtrl'].setValue(this.currentBusinessClient);
            }
        }
        this.calculateTotal();
    }

    onSelectBusinessClient(selectedBusinessClient: BusinessClient<Invoice>) {
        if (!selectedBusinessClient && this.currentBusinessClient) {
            // no business client selected, need to clear out current context
            this.businessClients = this.businessClients.filter((businessClient: BusinessClient<Invoice>) => {
                return businessClient.memberAccountId !== this.currentBusinessClient.memberAccountId;
            });
            this.currentBusinessClient = selectedBusinessClient;
        } else if (selectedBusinessClient) {
            if (!selectedBusinessClient.transactionEntries) {
                selectedBusinessClient.transactionEntries = [];
            }
            if (!selectedBusinessClient.selectedTotal) {
                selectedBusinessClient.selectedTotal = '0.00';
            }
            // we have selected a business client, but if it is already in the list, then use it
            const existingBusinessClient = this.businessClients.find((businessClient: BusinessClient<Invoice>) => {
                if (!businessClient.memberAccountId) {
                    return businessClient.accountingId === selectedBusinessClient.accountingId;
                }
                return businessClient.memberAccountId === selectedBusinessClient.memberAccountId;
            });
            // if not in the list, then add it and set the context
            if (selectedBusinessClient.status !== BusinessClientStatus.DILIGENCE_REQUIRED) {
                if (!existingBusinessClient) {
                    this.clearClientWithoutEntries();
                    if (this.currentBusinessClient && this.currentBusinessClient.memberAccountId !== selectedBusinessClient.memberAccountId) {
                        this.businessClients = this.businessClients.filter((businessClient: BusinessClient<Invoice>) => {
                            return businessClient.memberAccountId !== this.currentBusinessClient.memberAccountId;
                        });
                        this.currentBusinessClient = selectedBusinessClient;
                        this.businessClients.push(this.currentBusinessClient);
                    } else {
                        this.currentBusinessClient = selectedBusinessClient;
                        this.businessClients.push(this.currentBusinessClient);
                    }
                } else {
                    if (this.currentBusinessClient && this.currentBusinessClient.memberAccountId !== selectedBusinessClient.memberAccountId) {
                        this.clearClientWithoutEntries();
                    }
                    this.currentBusinessClient = existingBusinessClient;
                }
            }
        }
        this.calculateTotal();
        this.stepper.reset();
    }

    clearClientWithoutEntries() {
        if (this.currentBusinessClient && !this.currentBusinessClient.transactionEntries.length) {
            this.businessClients = this.businessClients.filter((businessClient: BusinessClient<Invoice>) => {
                return businessClient.memberAccountId !== this.currentBusinessClient.memberAccountId;
            });
        }
    }

    onSelectInvoices(selectedInvoices: TransactionEntrySelection<Invoice>[]) {
        if (selectedInvoices && selectedInvoices.length) {
            this.currentBusinessClient.transactionEntries = selectedInvoices;
        } else {
            this.currentBusinessClient.transactionEntries = [];
        }
        super.onSelectInvoices();
    }

    calculateTotal() {
        let total = 0.00;
        this.businessClients.forEach((businessClient: BusinessClient<Invoice>) => {
            total += Number(businessClient.selectedTotal);
        });
        this.transactionTotal = total.toFixed(2);
        this.totalChanged.emit(this.transactionTotal);
    }

    onTransactionReviewed() {
        this.viewTerms = true;
        const contentContainer = document.querySelector('.mat-dialog-container');
        contentContainer.scrollTo(0, 0);
    }

    onSubmit(reset: any) {
        const transaction = this.transactionFormService.getTransaction(this.formGroup);
        transaction.submissionReference = uuidv4();
        transaction.totalAmount = Number(this.transactionTotal);
        transaction.source = TransactionSource.EXTERNAL_TRANSACTION;
        transaction.incomes = [];
        transaction.transactionDate = null;
        transaction.manifestSupportingDocuments = this.manifestSupportingDocs || [];
        this.businessClients.forEach((businessClient: BusinessClient<Invoice>) => {
            businessClient.transactionEntries.forEach((selection: TransactionEntrySelection<Invoice>) => {
                if (selection.included) {
                    transaction.incomes.push(selection.data);
                }
            });
        });
        transaction.shipmentIds = this.manifests.map((manifest: ManifestSelection) => {
            return manifest.data.id;
        });
        reset();
        this.viewTerms = false;
        this.transactionReviewed.emit(transaction);
    }

    getAllSelectedInvoices() {
        const allSelectedInvoices: TransactionEntrySelection<Invoice>[] = [];
        this.businessClients.forEach((businessClient: BusinessClient<Invoice>) => {
            businessClient.transactionEntries.forEach((transactionEntry: TransactionEntrySelection<Invoice>) => {
                if (transactionEntry.included) {
                    allSelectedInvoices.push(transactionEntry);
                }
            });
        });
        return allSelectedInvoices;
    }

    closeModal(event: any) {
        this.closeTransactionModal.emit(event);
    }
}
