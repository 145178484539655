import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { AuthService, CommentService, Alert, AlertFormService } from 'projects/services/src/public-api';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
    selector: 'pc-alerts-modal',
    templateUrl: './alerts-modal.component.html'
})
export class AlertsModalComponent extends BaseModalComponent<AlertsModalComponent> implements OnInit {

    memberAccountId: string;
    alert: Alert;
    action: boolean;
    alertForm: UntypedFormGroup;

    constructor(dialogRef: MatDialogRef<AlertsModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any,
                private commentService: CommentService,
                private alertFormService: AlertFormService,
                private authService: AuthService) {
        super(dialogRef);
        this.alert = data.alert;
        this.action = data.action;
    }

    ngOnInit() {
        this.confirm = this.confirm.bind(this);
        this.close = this.close.bind(this);
        this.formValid = this.formValid.bind(this);
        this.alertForm = this.alertFormService.initializeForm();
        if (this.action === true) {
            this.alertForm.get('noteCtrl').addValidators([Validators.required, Validators.minLength(2)]);
        }
        this.memberAccountId = this.authService.getProfile().memberId;
    }

    formValid() {
        return this.alertForm.valid;
    }

    confirm() {
        const note = this.alertForm.controls['noteCtrl'].value;
        if (this.action === true) {
            this.commentService.updateAlert(this.alert.id, { status : 'ACTIONED', note, acknowledgedBy: this.authService.getProfile().name})
                .subscribe(() => {
                    this.close(true);
                });
        } else {
            this.commentService.updateAlert(this.alert.id, { status : 'REVIEWED', note, acknowledgedBy: this.authService.getProfile().name})
                .subscribe(() => {
                    this.close(true);
                });
        }
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }
}
