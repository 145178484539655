import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Event, ActivationEnd } from '@angular/router';
import { WorkflowService, Task, ContractTemplate, SmartContractService, PagedResponse, ContractTemplateVisibility } from 'projects/services/src/public-api';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pc-sc-create-template-main',
    templateUrl: './create-template-main.component.html',
    styleUrls: ['./create-template-main.component.scss']
})
export class CreateTemplateMainComponent implements OnInit {

    ContractTemplateVisibility = ContractTemplateVisibility;

    @Input() activeTab = 'PREVIEW_TEMPLATE';

    task: Task;
    contractTemplate: ContractTemplate;

    // FIXME: this needs changing in the workflow form fields
    tabs = {
        // eslint-disable-next-line camelcase
        select_terms: 'DESIGN_TEMPLATE',
        // eslint-disable-next-line camelcase
        edit_terms: 'EDIT_TERMS',
        // eslint-disable-next-line camelcase
        preview_template: 'PREVIEW_TEMPLATE',
        // eslint-disable-next-line camelcase
        share_template: 'SHARE_TEMPLATE'
    };

    constructor(private route: ActivatedRoute,
                private router: Router,
                private smartContractService: SmartContractService,
                private workflowService: WorkflowService,
                private dialog: MatDialog) {}

    ngOnInit() {
        this.activeTab = this.tabs[this.route.snapshot.params._activeTab];
        this.router.events.subscribe((event: Event) => {
            if (event instanceof  ActivationEnd) {
                if (event.snapshot.params._activeTab) {
                    this.activeTab = this.tabs[event.snapshot.params._activeTab];
                }
            }
        });
        this.task = this.workflowService.getCurrentTask();
    }

    setActiveTab(activeTab: string) {
        if (this.activeTab === 'DESIGN_TEMPLATE' && activeTab === 'EDIT_TERMS') {
            this.completeTask('edit_terms');
        } else if (this.activeTab === 'DESIGN_TEMPLATE' && activeTab === 'PREVIEW_TEMPLATE') {
            this.completeTask('preview');
        } else if (this.activeTab === 'EDIT_TERMS' && activeTab === 'DESIGN_TEMPLATE') {
            this.completeTask('select_terms');
        } else if (this.activeTab === 'EDIT_TERMS' && activeTab === 'PREVIEW_TEMPLATE') {
            this.completeTask('preview');
        } else if (this.activeTab === 'PREVIEW_TEMPLATE' && activeTab === 'DESIGN_TEMPLATE') {
            this.completeTask('select_terms');
        } else if (this.activeTab === 'PREVIEW_TEMPLATE' && activeTab === 'EDIT_TERMS') {
            this.completeTask('edit_terms');
        } else if (this.activeTab === 'PREVIEW_TEMPLATE' && activeTab === 'SHARE_TEMPLATE') {
            this.completeTask('share');
        } else if (this.activeTab === 'SHARE_TEMPLATE' && activeTab === 'PREVIEW_TEMPLATE') {
            this.completeTask('preview');
        }
    }

    getTabClass(tabOption: string) {
        if (this.activeTab === tabOption) {
            return 'tab-option-selected';
        } else {
            return 'tab-option';
        }
    }

    shareTemplate() {
        this.contractTemplate.visibility = ContractTemplateVisibility.SHARING;
        this.smartContractService.saveTemplate(this.contractTemplate).subscribe(
            () => {
                this.workflowService.completeTask(this.task.id, this.requestBody('share'))
                    .subscribe(() => {
                        this.workflowService.getTaskByProcessInstanceId(this.task.processInstanceId)
                            .subscribe(
                                (taskQueryResponse: PagedResponse<Task>) => {
                                    const taskId = taskQueryResponse.content[0].id;
                                    this.workflowService.loadTask(taskId);
                                }
                            );
                    });
            });
    }

    completeTask(action: string) {
        this.workflowService.completeTask(this.task.id, this.requestBody(action))
            .subscribe(() => {
                this.workflowService.getTaskByProcessInstanceId(this.task.processInstanceId)
                    .subscribe(
                        (taskQueryResponse: PagedResponse<Task>) => {
                            const taskId = taskQueryResponse.content[0].id;
                            this.workflowService.loadTask(taskId);
                        }
                    );
            });
    }

    requestBody(action: string): any {
        return {
            variables: {
                action: {
                    value: action,
                    type: 'String'
                }
            }
        };
    }

    updateTask(task: Task) {
        this.task = task;
        this.smartContractService.getTemplateById(task.variables.template).subscribe((template: ContractTemplate) => {
            this.contractTemplate = template;
        });
    }

    deleteTemplate() {
        const originalTab = this.activeTab;
        this.activeTab = 'DELETE_TEMPLATE';
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'Delete Template',
            description: 'This will delete the current template.  NOTE: existing contracts will not be affected.',
            confirmMessage: 'Do you want to delete this template?',
            confirmText: 'Delete'
        };
        const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (confirmAction: any) =>  {
                if (confirmAction === 'confirmed') {
                    this.workflowService.deleteProcessInstance(this.task.processInstanceId).subscribe(
                        () => {
                            this.smartContractService.deleteTemplate(this.contractTemplate.id).subscribe(
                                () => {
                                    this.router.navigate(['/smart_contracts/my_templates']);
                                }
                            );
                        }
                    );
                } else {
                    this.activeTab = originalTab;
                }
            }
        );

    }
}
