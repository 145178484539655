<div class="row justify-content-md-center">
    <div class="col-md-auto">
        <label class="label_margin"> <span>View:</span> </label>
    </div>
    <div class="col-md-auto">
        <input type="radio" value="bar" (change)="view($event.target.value)" [checked]="!tableView">
        <label class="label_margin"><span><b>Distribution </b></span></label>
    </div>
    <div class="col-md-auto">
        <input type="radio" value="table" (change)="view($event.target.value)" [checked]="tableView">
        <label class="label_margin"> <span><b>Table</b></span></label>
    </div>
</div>