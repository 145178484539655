import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, BrowserUtilsService, Configuration, CountNotificationService, MessageService, SidebarTrackingService, WorkflowService } from 'projects/services/src/public-api';
import { get } from 'scriptjs';

@Component({
    selector: 'pt-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    ngOnInit() {
        this.loadScripts();
        this.browserUtilsService.checkBrowserCompatibility();
    }

    constructor(private router: Router,
                protected translate: TranslateService,
                private messageService: MessageService,
                private workflowService: WorkflowService,
                private authService: AuthService,
                private countNotificationService: CountNotificationService,
                private browserUtilsService: BrowserUtilsService ) {
        translate.setDefaultLang('en');
        this.router.events.subscribe((data: any) => {
            if (data instanceof ActivationEnd) {
                const snapshot = data.snapshot;
                if (snapshot.component && snapshot.data.title && this.authService.getProfile()) {
                    SidebarTrackingService.setActiveNavItem(snapshot.data.title);
                    this.messageService.countReceivedMessages().subscribe((resp) => {
                        this.countNotificationService.setUnreadMessageCount(parseInt(resp.headers.get('Content-Length'), 10));
                    });
                    this.workflowService.getTaskCount().subscribe((resp) => {
                        this.countNotificationService.setOpenItemCount(parseInt(resp.headers.get('Content-Length'), 10));
                    });
                }
            }
        });
    }

    loadScripts() {
        get('https://cdn.yodlee.com/fastlink/v4/initialize.js', () => {
            // yodlee loaded
        });
        const googleMapKey = Configuration.getConfig().confiaGoogleMapApiKey;
        get('https://maps.googleapis.com/maps/api/js?key=' + googleMapKey + '&libraries=places&language=en&callback=Function.prototype', () => {
            // google map script loaded
        });
    }
}
