<div>
    <form>
        <div>
            <div class="modal-header">
                <label class="form-title">Update Merchant Account Accounting ID</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div>
                    <label class="form-label">Supplier Accounting System IDs</label><i class="fa fa-plus pull-right" title="Add Supplier ID" (click)="addAccountingId(AccountingPartyType.SUPPLIER)"></i>
                    <input *ngFor="let supplier of supplierIds" class="form-control form-input-editable" [ngModelOptions]="{standalone: true}" [(ngModel)]="supplier.accountingExternalId" type="text">
                    <label class="form-label pt-4">Customer Accounting System IDs</label><i class="fa fa-plus pt-4 pull-right" title="Add Customer ID" (click)="addAccountingId(AccountingPartyType.CUSTOMER)"></i>
                    <input *ngFor="let customer of customerIds" class="form-control form-input-editable" [ngModelOptions]="{standalone: true}" [(ngModel)]="customer.accountingExternalId" type="text">
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [submit]="onSubmit" [text]="'Update'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
