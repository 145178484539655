import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SidebarTrackingService {

    private static activeNavItem: string;

    static getActiveNavItem() {
        return this.activeNavItem;
    }

    static setActiveNavItem(activeItem: string) {
        this.activeNavItem = activeItem;
    }
}
