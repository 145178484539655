import { Component, Input } from '@angular/core';
import { ReceiptLineItem } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-metrc-receipt-line-item-row',
    templateUrl: './member-metrc-receipt-line-item-row.component.html',
    styleUrls: ['./member-metrc-receipt-line-item-row.component.scss']
})
export class MemberMetrcReceiptLineItemRowComponent {

    @Input() lineItems: ReceiptLineItem[];

    showLineItemSummary: boolean[] = [];

}
