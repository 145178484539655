import { LoanStatus } from './loan';
import { Transaction } from './transaction';

export class BNPLLoan {

    transaction: Transaction;
    paidAmount: number;
    outstandingAmount: number;
    remainingPaymentCount: number;
    bnplAmount: number;
    borrowerPhoneNumber: string;
    finalPaymentDate: Date;
    status: LoanStatus;
    totalAmount: number;
    refundAmount: number;
}
