import { v4 as uuidv4 } from 'uuid';
import { Shipment } from './shipment';

export class ManifestSelection {
    data: Shipment;

    id: string;
    included = false;
    error: string;

    constructor() {
        this.id = uuidv4();
    }

}
