import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { NewContractModalComponent } from 'projects/smart-contract-components/src/lib';

@Component({
    selector: 'pt-smart-contracts-main',
    templateUrl: './smart-contracts-main.component.html',
    styleUrls: ['./smart-contracts-main.component.scss']
})
export class SmartContractsMainComponent implements OnInit {

    @Input() activeType = 'MY_CONTRACTS';

    // FIXME: this needs changing in the workflow form fields
    tabs = {
        // eslint-disable-next-line camelcase
        my_contracts: 'MY_CONTRACTS',
        // eslint-disable-next-line camelcase
        shared_templates: 'AVAILABLE_CONTRACTS'
    };

    constructor(private route: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog) {}

    ngOnInit() {
        this.activeType = this.tabs[this.route.snapshot.queryParams['_activeTab'] || 'my_contracts'];
        this.route.queryParams.subscribe((params) => {
            this.activeType = this.tabs[params['_activeTab'] || 'my_contracts'];
        });
    }

    onSelectChanged(event: any) {
        this.router.navigate(['/smart_contracts'], {queryParams: { _activeTab: event.target.value }});
    }

    onMyTemplates() {
        this.router.navigate(['/smart_contracts/my_templates']);
    }

    onNewContract() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {};

        this.dialog.open(NewContractModalComponent, dialogConfig);
    }

}
