<fieldset class="form-group" *ngIf="formCtrl">
  <div class="row">
    <div class="col-12">
      <label class="form-label">{{ label | translate }}</label>
    </div>
    <div class="col-12" [formGroup]="formGroup">
        <input type="text" [formControlName]="formCtrl" class="form-control form-tel-input-wide" min="0" max="100" [maxlength]="getMaxLength()"
            (keydown)="onInputChanged($event)" [required]="required"/>
        <span *ngIf="ownership"> (100% allocated) </span>
    </div>
  </div>
</fieldset>
