import { ExpenseLine } from './expense-line';
import { Address } from './address';
import { SupportingDocument } from './supporting-document';

export class Bill {

    id: string;
    created: Date;
    updated: Date;
    source: string;
    systemType: string;
    type: string;
    description: string;

    externalId: string;
    memberId: string;
    memberAccountId: string;

    reasonCode: string;
    status: string;

    submittedAmount: string;
    paidAmount: string;
    unpaidAmount: number;
    totalAmount: number;

    txnDate: Date;
    dueDate: Date;
    lineItems: ExpenseLine[];

    externalReference: string;

    vendorExternalId: string;
    vendorName: string;
    vendorEmail: string;
    venderAddress: Address;

    memberName: string;
    supportingDocuments: SupportingDocument[] = [];
    lineItemId: string;
    synced: boolean;

}
