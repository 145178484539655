import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { NavigationLinks } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-new-member-sidebar',
    templateUrl: './new-member-sidebar.component.html'
})
export class NewMemberSidebarComponent implements OnInit {

    @Input() expandedMenu: boolean;

    @Output() toggleExpandSideMenu: EventEmitter<any> = new EventEmitter<any>();

    menuActions: any[];

    ngOnInit() {
        this.menuActions = [NavigationLinks.dashboardLink];
    }

    getMenuClass() {
        if (this.expandedMenu) {
            return 'sidenav-wide';
        } else {
            return 'sidenav-narrow';
        }
    }

    onToggleExpandSideMenu() {
        this.toggleExpandSideMenu.emit('toggle');
    }
}
