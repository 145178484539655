<div class="mt-4 mb-3" *ngIf="transaction && !contract && transactionSubType !== 'WITHDRAWAL'">
    <h6>
        Accounting System Details
    </h6>
    <div class="col-sm-12 mt-3 mb-4 horizontal-scroll mat-table-container" *ngIf="transaction.expenses">
        <table mat-table #billsTable [dataSource]="expenses">
            <ng-container matColumnDef="item">
                <th mat-header-cell class="centered" *matHeaderCellDef> Item </th>
                <td mat-cell class="centered" *matCellDef="let element; let i = index">
                    {{ i + 1 }}
                </td>
            </ng-container>
            <ng-container matColumnDef="verified_expense">
                <th mat-header-cell  *matHeaderCellDef> Verified Expense Id</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.id || 'No'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef> Source </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.source || 'Other' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="bill">
                <th mat-header-cell class="centered" *matHeaderCellDef> Bill # </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    {{ element.externalReference || 'N/A' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="vendor">
                <th mat-header-cell *matHeaderCellDef> Vendor </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.vendorName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.description}}
                </td>
            </ng-container>
            <ng-container matColumnDef="amount">
                <th mat-header-cell class="centered" *matHeaderCellDef> Amount </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <span class="table-amount">
                        {{ element.paidAmount | currency }}
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="expense_date">
                <th mat-header-cell class="centered" *matHeaderCellDef> Expense Date </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <span class="text-nowrap">{{ element.txnDate | date:'mediumDate' || 'N/A'}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="expense_details">
                <th mat-header-cell *matHeaderCellDef> Expense Details </th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        <ng-container *ngFor="let item of element.lineItems">
                            <div class="supporting-document">
                                {{item.amount | currency}} <span *ngIf="item.description">- {{item.description}}</span>
                            </div>
                        </ng-container>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="documents">
                <th mat-header-cell *matHeaderCellDef> Document </th>
                <td mat-cell *matCellDef="let element">
                    <div class="row">
                        <div *ngIf="isAuthorityOrAdmin() && rfi.status !== RfiStatus.PENDING_DEPOSIT_VERIFICATION && rfi.status !== RfiStatus.CLOSED && rfi.status !== RfiStatus.MEMBER_RECONCILIATION">
                            <button class="blue-button" (click)="addSupportingDocument(element.lineItemId)" type="button"><i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add Document</div></button>
                        </div>
                        <div>
                            <ng-container *ngFor="let supportingDocument of element.supportingDocuments">
                                <div class="supporting-document">
                                    <i class="fa fa-2x fa-times" (click)="removeSupportingDocument(supportingDocument)" *ngIf="rfi.status !== RfiStatus.PENDING_DEPOSIT_VERIFICATION && rfi.status !== RfiStatus.CLOSED && rfi.status !== RfiStatus.MEMBER_RECONCILIATION && isAuthorityOrAdmin()"></i>
                                    <a class="dashboard-list-a" *ngIf="supportingDocument.document" (click)="downloadFile(supportingDocument.document)">
                                        <i class="fa fa-paperclip"></i>{{supportingDocument.document.fileName}}
                                    </a>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="synced">
                <th mat-header-cell class="centered" *matHeaderCellDef> Synced </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <i *ngIf="element.synced" class="fa fa-check"></i>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="expenseDisplayedColumn"></tr>
            <tr mat-row *matRowDef="let row; columns: expenseDisplayedColumn;"></tr>
        </table>
    </div>
    <div class="col-sm-12 mt-3 mb-4 horizontal-scroll mat-table-container" *ngIf="transaction.incomes">
        <div class="mat-table-spinner" *ngIf="isIncomesDetails">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table #invoicesTable [dataSource]="transaction.incomes">
            <ng-container matColumnDef="item">
                <th mat-header-cell class="centered" *matHeaderCellDef> Item </th>
                <td mat-cell class="centered" *matCellDef="let element; let i = index">
                    {{ i+1 }}
                </td>
            </ng-container>
            <ng-container matColumnDef="verified_invoice">
                <th mat-header-cell  *matHeaderCellDef> Verified Invoice Id </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.id || 'No'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef> Source </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.source || 'Other' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="invoice">
                <th mat-header-cell class="centered" *matHeaderCellDef> Invoice # </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    {{ element.externalReference || 'N/A' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="customer">
                <th mat-header-cell *matHeaderCellDef> Customer </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.customerName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element"> {{ element.description }}  </td>
            </ng-container>
            <ng-container matColumnDef="amount">
                <th mat-header-cell class="centered" *matHeaderCellDef> Amount </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <span class="table-amount">
                        {{ element.paidAmount | currency}}
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="invoice_date">
                <th mat-header-cell class="centered" *matHeaderCellDef> Invoice Date </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <span class="text-nowrap">{{ element.txnDate | date:'mediumDate' || 'N/A'}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="invoice_details">
                <th mat-header-cell *matHeaderCellDef> Invoice Details </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngFor="let item of element.lineItems">
                        {{item.amount | currency}} <span *ngIf="item.description">- {{item.description}}</span>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="documents">
                <th mat-header-cell *matHeaderCellDef> Document </th>
                <td mat-cell *matCellDef="let element">
                    <div class="row">
                        <div>
                            <button class="blue-button" (click)="addSupportingDocument(element.lineItemId)" type="button" *ngIf="isAuthorityOrAdmin() && rfi.status !== RfiStatus.PENDING_DEPOSIT_VERIFICATION && rfi.status !== RfiStatus.CLOSED && rfi.status !== RfiStatus.MEMBER_RECONCILIATION"><i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add Document</div></button>
                        </div>
                        <div>
                            <ng-container *ngFor="let supportingDocument of element.supportingDocuments">
                                <div class="supporting-document">
                                    <i class="fa fa-2x fa-times" (click)="removeSupportingDocument(supportingDocument)" *ngIf="rfi.status !== RfiStatus.PENDING_DEPOSIT_VERIFICATION && rfi.status !== RfiStatus.CLOSED && rfi.status !== RfiStatus.MEMBER_RECONCILIATION && isAuthorityOrAdmin()"></i>
                                    <a class="dashboard-list-a" *ngIf="supportingDocument.document" (click)="downloadFile(supportingDocument.document)">
                                        <i class="fa fa-paperclip"></i>{{supportingDocument.document.fileName}}
                                    </a>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="synced">
                <th mat-header-cell class="centered" *matHeaderCellDef> Synced </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <i *ngIf="element.synced" class="fa fa-check"></i>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="incomeSystemDisplayedColumn"></tr>
            <tr mat-row *matRowDef="let row; columns: incomeSystemDisplayedColumn;"></tr>
        </table>
    </div>

    <h6 *ngIf="transactionSubType !== 'WITHDRAWAL'">
        Selected Manifests
    </h6>
    <div *ngIf="transactionSubType !== 'WITHDRAWAL'" class="col-sm-12 mt-3 mb-4 horizontal-scroll mat-table-container">
        <div class="mat-table-spinner" *ngIf="isTrackingSystemDetails">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table [dataSource]="shipments">
            <ng-container matColumnDef="item">
                <th mat-header-cell class="centered" *matHeaderCellDef> Item </th>
                <td mat-cell class="centered" *matCellDef="let element; let i = index">
                    {{ i+1 }}
                </td>
            </ng-container>
            <ng-container matColumnDef="manifest_number">
                <th mat-header-cell *matHeaderCellDef> Manifest Number </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.manifestNumber }}
                </td>
            </ng-container>
            <ng-container matColumnDef="supplier">
                <th mat-header-cell *matHeaderCellDef> Supplier </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.shipperFacilityName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="recipient">
                <th mat-header-cell *matHeaderCellDef> Recipient </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.recipientFacilityName }}
                </td>
            </ng-container>
            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef> Created Date </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.created ? (element.created | date: 'mediumDate') : ''}}
                </td>
            </ng-container>
            <ng-container matColumnDef="received_date">
                <th mat-header-cell *matHeaderCellDef> Received Date </th>
                <td mat-cell *matCellDef="let element">
                    {{element.receivedDateTime | date: 'mediumDate'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="manifest_contents">
                <th mat-header-cell *matHeaderCellDef> Manifest Contents </th>
                <td mat-cell *matCellDef="let element">
                    <pc-manifest-line [packages]="element.lineItems"></pc-manifest-line>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="trackingSystemDisplayedColumn"></tr>
            <tr mat-row *matRowDef="let row; columns: trackingSystemDisplayedColumn;"></tr>
        </table>
    </div>
    <h6 *ngIf="transaction && authService.isAuthorityOrReviewer() && transactionSubType !== 'WITHDRAWAL'">
        Possible Matching Manifests
    </h6>
    <div *ngIf="transaction && authService.isAuthorityOrReviewer() && transactionSubType !== 'WITHDRAWAL'" class="mb-4">
        <pc-member-metrc-transfers-list [transactionId]="transaction.id" [displaySummary]="false"></pc-member-metrc-transfers-list>
    </div>
    <div class="row mb-4" *ngIf="contract">
        <div class="col-12">
            <h6>Smart Contract Order Details</h6>
            <pc-sc-sales-order class="sales-order" [order]="contract.contractTemplate.purchaseOrder"></pc-sc-sales-order>
        </div>
    </div>
    <div class="row col-sm-12 horizontal-scroll mat-table-container" *ngIf="otherDocuments.length">
        <div class="col-12">
            <span class="mt-3 mb-3 ms-2"> Other Documentation</span>
            <div class="mat-table-spinner" *ngIf="isLoadOtherDocuments">
                <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
            <table mat-table [dataSource]="otherDocuments">
                <ng-container matColumnDef="document_name">
                    <th mat-header-cell *matHeaderCellDef> Document Name </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="supporting-document" *ngIf="element.document">
                            <i class="fa fa-2x fa-times" (click)="removeSupportingDocument(element)" *ngIf="isRemovableDocument(element)"></i>
                            <a class="dashboard-list-a" (click)="downloadFile(element.document)">
                                <i class="fa fa-paperclip"></i>{{element.document.fileName}}
                            </a>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> Description </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.document?.explanation }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th mat-header-cell class="centered" *matHeaderCellDef> Date </th>
                    <td mat-cell class="centered" *matCellDef="let element">
                        <span *ngIf="element.document?.created">
                            <pc-date-time [date]="element.document?.created"></pc-date-time>
                        </span>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="otherDocumentDisplayedColumn"></tr>
                <tr mat-row *matRowDef="let row; columns: otherDocumentDisplayedColumn;"></tr>
            </table>
        </div>
    </div>
    <a class="document-download-link" #downloadDocumentLink></a>
    <input hidden type="file" id="upload" #upload (change)="selectFile($event)" placeholder="Upload file" accept=".pdf,.doc,.docx">
</div>
