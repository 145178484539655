import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http.service';
import { Member, MemberFunction, MemberStatus, MemberType } from './models/member';
import { Signature } from './models/signature';
import { TaxInfo } from './models/tax-info';
import { FinancialDetails } from './models/financial-details';
import { Observable } from 'rxjs';
import { PagedResponse } from './models/paged-response';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class MemberService {

    baseUrl: string;

    constructor(private http: HttpService,
                private router: Router,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.EntityAPIEndpoint + '/';
    }

    loadMember(id: string) {
        return this.http.get(`${this.baseUrl}member/${id}`);
    }

    saveMember(body: any) {
        return this.http.post(`${this.baseUrl}member`, body);
    }

    updateMember(id: string, body: any) {
        return this.http.put(`${this.baseUrl}member/${id}`, body);
    }

    getActiveMembers() {
        return this.http.get(`${this.baseUrl}member?status=ACTIVE`);
    }

    getMembersByTypesAndStatus(memberId: string,
        types: MemberType[],
        statuses: MemberStatus[],
        memberFunctions: MemberFunction[],
        start: number,
        size: number,
        sort: string,
        sortDir: string) {

        const params = `?memberId=${memberId}&types=${types}&statuses=${statuses}&memberFunctions=${memberFunctions}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}member${params}`);
    }

    searchMembers(name: string,
        types: MemberType[],
        statuses: MemberStatus[],
        jurisdiction: string,
        excludeSelf: boolean,
        includeAddress: boolean,
        searchLimit?: number): Observable<PagedResponse<Member>>  {

        const params = `?name=${encodeURI(name)}&fuzzy=true&types=${types}&statuses=${statuses}&jurisdiction=${jurisdiction}&excludeSelf=${excludeSelf}&includeAddress=${includeAddress}&start=0&size=${searchLimit || ''}&sort=sortName&sortDir=ASC`;
        return this.http.get(`${this.baseUrl}member${params}`) as Observable<PagedResponse<Member>>;
    }

    saveSignature(signature: Signature) {
        return this.http.post(`${this.baseUrl}member/${signature.memberId}/signature`, signature);
    }

    saveTaxInfo(taxInfo: TaxInfo) {
        return this.http.post(`${this.baseUrl}member/${taxInfo.memberId}/tax`, taxInfo);
    }

    saveFinancialDetails(financialDetails: FinancialDetails) {
        return this.http.post(`${this.baseUrl}member/${financialDetails.memberId}/financials`, financialDetails);
    }

    checkForMatchingMembers(referral: any) {
        return this.http.post(`${this.baseUrl}referral/validate`, referral);
    }

    sendReferralToMember(referral: any) {
        return this.http.post(`${this.baseUrl}referral`, referral);
    }

    getReferralRequest(refereeMemberId: string) {
        return this.http.get(`${this.baseUrl}referral/request/${refereeMemberId}`);
    }

    getMemberByReferralCode(referralCode: string) {
        return this.http.get(`${this.baseUrl}referral/code/${referralCode}`);
    }

    getMemberReferrer(memberId: string) {
        return this.http.get(`${this.baseUrl}referral/referrer/${memberId}`);
    }

    updateMemberName(memberNameChangeRequest: any) {
        return this.http.post(`${this.baseUrl}member/rename`, memberNameChangeRequest);
    }

    deleteApplicant(id: string) {
        return this.http.delete(`${this.baseUrl}register/delete/applicant/${id}`);
    }

    getMostRecentSignatureOfMember(memberId: string) {
        return this.http.get(`${this.baseUrl}member/${memberId}/signature`);
    }

    blocklistConsumer(memberId: string) {
        return this.http.post(`${this.baseUrl}consumer/blocklist/${memberId}`, '');
    }

    unblockConsumer(memberId: string) {
        return this.http.delete(`${this.baseUrl}consumer/blocklist/${memberId}`);
    }

    navigateToMember(memberId: string) {
        if (memberId) {
            this.loadMember(memberId).subscribe((member: Member) => {
                if (member) {
                    this.router.navigate(['/member/details/', memberId]);
                } else {
                    throw new Error('The details for this member are no longer available.');
                }
            });
        }
    }
}
