<div class="row col-12 mb-2">
    <span class="form-title">Metrc</span>
</div>
<div class="col-12">
    <div class="tab-row mb-4">
        <div [ngClass]="getTabClass('transfers')" (click)="setMetrcTab('transfers')">
            Transfers
        </div>
        <div [ngClass]="getTabClass('receipts')" (click)="setMetrcTab('receipts')">
            Receipts
        </div>
        <div [ngClass]="getTabClass('tracking')" (click)="setMetrcTab('tracking')">
            Tracking
        </div>
        <div [ngClass]="getTabClass('trace')" (click)="setMetrcTab('trace')">
            Trace
        </div>
    </div>
    <div class="row d-flex">
        <div class="col-auto" *ngIf="metrcTab === 'transfers'">
            <pc-search-text-input [id]="'memberId'" [placeholder]="'Counter Party Filter'" [formCtrl]="'counterPartyCtrl'" [formGroup]="formGroup" (search)="search($event)"></pc-search-text-input>
        </div>
        <div class="col-auto">
            <pc-form-date-picker [formCtrl]="'dateCtrl'" [formGroup]="formGroup" [type]="'RANGED'" [changeTrigger]="changeTrigger" (dateSelected)="onDateChanged($event)">
            </pc-form-date-picker>
        </div>
        <div class="col-auto" *ngIf="metrcTab === 'transfers'">
            <select class="form-control-header form-input-table-header form-select-sm" [(ngModel)]="transferType" (change)="sourceChanged()">
                <option value="">All Transfers</option>
                <option value="Metrc Incoming">Incoming Only</option>
                <option value="Metrc Outgoing">Outgoing Only</option>
            </select>
        </div>
    </div>
</div>

<div *ngIf="metrcTab === 'receipts'">
    <pt-member-metrc-receipt-list [memberId]="memberId" [startDate]="startDate" [endDate]="endDate"></pt-member-metrc-receipt-list>
</div>
<div *ngIf="metrcTab === 'transfers'">
    <pc-member-metrc-transfers-list [memberId]="memberId" [counterParty]="counterParty" [startDate]="startDate" [endDate]="endDate" [source]="transferType"></pc-member-metrc-transfers-list>
</div>
<div *ngIf="metrcTab === 'tracking'">
    <pc-member-metrc-package-summary [memberId]="memberId" [startDate]="startDate" [endDate]="endDate"></pc-member-metrc-package-summary>
</div>
<div *ngIf="metrcTab === 'trace'">
    <pc-member-metrc-trace [memberId]="memberId" [startDate]="startDate" [endDate]="endDate"></pc-member-metrc-trace>
</div>
