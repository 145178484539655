import { Component, Input } from '@angular/core';
import { AccountSummary, AccountSummaryType } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-account-summary',
    templateUrl: './account-summary.component.html',
    styleUrls: ['./account-summary.component.scss']
})
export class AccountSummaryComponent {

    @Input() accountSummary: AccountSummary;

    isWarning() {
        const type = this.accountSummary.summaryType;
        if (type === AccountSummaryType.YEARLY_INCOMING_LIMIT || type === AccountSummaryType.MONTHLY_INCOMING_LIMIT) {
            return false;
        } else {
            return this.accountSummary.threshold && this.accountSummary.currentAmount > this.accountSummary.threshold;
        }
    }

    hasOtherAmount() {
        return this.accountSummary.otherAmount !== null;
    }

    hasThreshold() {
        return this.accountSummary.threshold !== null;
    }
}
