import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CodatSettings } from '../models/codat-settings';

@Injectable({
    providedIn: 'root'
})
export class CodatSettingsFormService {

    constructor(private formbuilder: UntypedFormBuilder) {
    }

    initializeForm(codatSettings: CodatSettings): UntypedFormGroup {
        return this.formbuilder.group({
            reconciliationAccountIdCtrl: new UntypedFormControl(codatSettings.reconciliationAccountId || ''),
            invoicePushCtrl: new UntypedFormControl(codatSettings.invoicePushEnabled),
            billPushCtrl: new UntypedFormControl(codatSettings.billPushEnabled)
        });
    }

    getCodatSettings(form: UntypedFormGroup): CodatSettings {
        const settings = new CodatSettings();
        settings.invoicePushEnabled = form.get('invoicePushCtrl').value;
        settings.billPushEnabled = form.get('billPushCtrl').value;
        settings.reconciliationAccountId = form.get('reconciliationAccountIdCtrl').value;
        return settings;
    }

}
