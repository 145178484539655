<fieldset class="form-group" *ngIf="autoCompleteControl">
    <div class="row">
        <div class="col-12" [formGroup]="formGroup">
        <label class="form-label">{{ label | translate }}</label>
        <div [formGroup]="formGroup" class="inputGroup">
            <input #merchantSelect [formControl]="autoCompleteControl" type="text" [placeholder]="placeholder"
                class="form-control form-input-editable" [matAutocomplete]="auto" (keydown)="onInputChanged($event)" (click)="$event.target.select()">
            <i class="fa" [ngClass]="selected() ? 'fa-times-circle-o' : 'fa-chevron-down'" (click)="onIconClick($event)"></i>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
                <mat-option [ngClass]="searching ? 'hidden' : 'merchant-entry'" *ngFor="let merchant of merchantAutoComplete | async;" [value]="merchant.merchantMemberName"
                            (onSelectionChange)="merchantChanged(merchant)">
                    <div class="row">
                        <div class="col-12">
                            <img *ngIf="merchant.merchantMemberAccount.member.memberType === MemberType.INDIVIDUAL_MERCHANT" class="imember-type-icon" src="assets/images/profile-icon.svg" title="Individual">
                            {{ merchant.merchantMemberName }}
                        </div>
                    </div>
                    <div *ngIf="includeAddress && merchant.merchantMemberAccount?.address" class="row address-info">
                        <div class="col-7 text-truncate">{{ (merchant.merchantMemberAccount.address.streetAddressOne || "") + " " +  (merchant.merchantMemberAccount.address.streetAddressTwo || "")}}</div>
                        <div class="col-5 text-end">{{ (merchant.merchantMemberAccount.address.city ? merchant.merchantMemberAccount.address.city + ", " : "") + (merchant.merchantMemberAccount.address.stateProvince || "") }}</div>
                    </div>
                </mat-option>
                <mat-option *ngIf="searching">
                    <i class="fa fa-spin fa-spinner"></i>Searching
                </mat-option>
                <mat-option *ngIf="!searching && totalResults > searchLimit" [disabled]="true">
                    There are {{totalResults - searchLimit}} additional results - please refine your search.
                </mat-option>
            </mat-autocomplete>
        </div>
        </div>
    </div>
</fieldset>
