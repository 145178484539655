import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { ProcessedCheckDetails } from 'projects/services/src/public-api';

@Injectable({
    providedIn: 'root'
})
export class CheckProcessorService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.NBCUAPIEndpoint + '/check_processor/processed_check';
    }

    getProcessedCheckDetailsByTransactionId(transactionId: any) : Observable<ProcessedCheckDetails> {
        return this.http.get(`${this.baseUrl}/transaction_id/${transactionId}`) as Observable<ProcessedCheckDetails>;
    }

    updateCheckDetailsById(processedCheckDetailsId: any, updatableCheckDetails: any) : Observable<ProcessedCheckDetails> {
        return this.http.put(`${this.baseUrl}/${processedCheckDetailsId}`, updatableCheckDetails) as Observable<ProcessedCheckDetails>;
    }
}