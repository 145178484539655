import { Inject, Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpService } from './http.service';
import { ContractPartyClassification } from './models/smart-contracts/contract-framework';
import { ContractTemplateType } from './models/smart-contracts/contract-template';

@Injectable({
    providedIn: 'root'
})
export class SmartContractService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.SmartContractsAPIEndpoint + '/';
    }

    /** **************************************************************************
     Smart Contract Framework Methods
     ****************************************************************************/

    getAllContractFrameworks(start: number, size: number, sort: string, sortDir: string) {
        const pagingParams = `?start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}framework${pagingParams}`);
    }

    uploadFramework(name: string, type: string, framework: File, sections: File, terms: File, variables: File) {
        const formData = new FormData();
        const buyerClassification = (type === 'Producer -> Distributor' ? ContractPartyClassification.DISTRIBUTOR : ContractPartyClassification.RETAILER);
        const sellerClassification = (type === 'Producer -> Distributor' ? ContractPartyClassification.PRODUCER : ContractPartyClassification.DISTRIBUTOR);
        formData.append('framework', framework);
        formData.append('sections', sections);
        formData.append('terms', terms);
        formData.append('variables', variables);
        const metadata = {
            name,
            buyerClassification,
            sellerClassification
        };
        formData.append('metadata', JSON.stringify(metadata));

        return this.http.post(`${this.baseUrl}framework/upload`, formData);
    }

    /** **************************************************************************
     Smart Contract Template Methods
     ****************************************************************************/

    getTemplateById(contractTemplateId: string) {
        return this.http.get(`${this.baseUrl}template/${contractTemplateId}`);
    }

    saveTemplate(contractTemplate: any) {
        return this.http.post(`${this.baseUrl}template`, contractTemplate);
    }

    deleteTemplate(contractTemplateId: string) {
        return this.http.delete(`${this.baseUrl}template/${contractTemplateId}`);
    }

    getMyTemplates(start: number, size: number, sort: string, sortDir: string) {
        const params = `?start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}template${params}`);
    }

    getSharedTemplates(start: number, size: number, sort: string, sortDir: string) {
        const params = `?start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}template/shared${params}`);
    }

    getLatestTemplateHistory(contractTemplateId: string, templateType: ContractTemplateType) {
        return this.http.get(`${this.baseUrl}template/${contractTemplateId}/history/latest?templateType=${templateType}`);
    }

    /** **************************************************************************
     Smart Contract Contract Methods
     ****************************************************************************/
    getContractById(contractId: string) {
        return this.http.get(`${this.baseUrl}contract/${contractId}`);
    }

    getContractByTransactionId(transactionId: string) {
        return this.http.get(`${this.baseUrl}contract?transaction=${transactionId}`).pipe(
            switchMap(
                (response: any) => {
                    if (response.content.length) {
                        return of(response.content[0]);
                    }
                    return of(null);
                }
            ));
    }

    getMyContracts(start: number, size: number, sort: string, sortDir: string) {
        const params = `?start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}contract${params}`);
    }

    saveContract(contract: any) {
        return this.http.post(`${this.baseUrl}contract`, contract);
    }

}
