import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddScenarioModalComponent } from 'projects/pt/src/app/components';
import { LendingKPI, LenderService, Scenario, ScenarioStatus } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-lending-management',
    templateUrl: './lending-management.component.html'
})
export class LendingManagementComponent implements OnInit {

    @Input() memberId: string;
    @Input() changeTrigger: number;

    @Output() navigate: EventEmitter<string> = new EventEmitter<string>();

    isLoadingResults = true;
    displayedColumns: string[] = ['scenario', 'kpi_list', 'status', 'action'];

    scenarios: Scenario[];

    constructor(private dialog: MatDialog,
                private lenderService: LenderService) {}

    ngOnInit() {
        this.loadScenarios();
    }

    loadScenarios() {
        this.isLoadingResults = true;
        this.lenderService.loadScenarios(this.memberId).subscribe((scenarios: Scenario[]) => {
            this.scenarios = scenarios;
            for (let scenario of scenarios) {
                scenario.status = scenario.active ? ScenarioStatus.ACTIVE : ScenarioStatus.DISABLE;
            }
            this.isLoadingResults = false;
        });
    }

    openAddScenarioWindow() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            edit: false
        };
        const dialog = this.dialog.open(AddScenarioModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(() => {
            this.loadScenarios();
        });
    }

    onEditScenario(id: string) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            edit: true,
            scenarioId: id
        };
        const dialog = this.dialog.open(AddScenarioModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(() => {
            this.loadScenarios();
        });
    }

    onDeleteScenario(id: string) {
        this.lenderService.deleteScenario(id).subscribe(() => {
            this.loadScenarios();
        });
    }

    onUpdateScenario(scenario: Scenario) {
        const updates = {
            active: !scenario.active
        };
        this.lenderService.updateScenario(scenario.id, updates).subscribe(() => {
            this.loadScenarios();
        });
    }

    arrToString(arr: LendingKPI[]) {
        return arr ? arr.map((kpi) => {
            return kpi.name;
        }).slice(0, 3).join(', ') : '';
    }
}
