import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class DomainFormService {
    constructor(private formbuilder: UntypedFormBuilder) { }

    initializeForm(): UntypedFormGroup {
        return  this.formbuilder.group({
            idCtrl: new UntypedFormControl(),
            memberIdCtrl: new UntypedFormControl(),
            domainCtrl: new UntypedFormControl('', [Validators.required,
                Validators.pattern('(?:^|[ \\t])((https?:\\/\\/)(?:localhost|[\\w-]+(?:\\.[\\w-]+)+)(:\\d+)?(\\/\\S*)?)')])
        });
    }

}
