<div class="col-12">
    <div class="row mb-2">
        <div class="col-4">
            <span class="form-title">Merchants</span>
        </div>
        <div *ngIf="((isAdmin() && isPremiumMember()) || merchantActionsAllowed)" class="col-8">
            <button class="blue-button pull-right" (click)="onRequestNewMerchant()" type="button">
                <i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add Confia Merchant</div>
            </button>
            <button *ngIf="isAdmin() && isPremiumMember() && accountingLinked" class="blue-button pull-right" (click)="onRequestNewAccountingMerchant()" type="button">
                <i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add Accounting Merchant</div>
            </button>
        </div>
    </div>
    <div class="horizontal-scroll mat-table-container" [ngClass]="memberPageView ? 'member-page-view' : ''">
        <div class="table mat-table-spinner" *ngIf="isLoadingResults">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table [dataSource]="merchantAccounts" matSort matSortDisableClear [matSortActive]="pageTracking.sort" [matSortDirection]="pageTracking.sortDir">
            <ng-container matColumnDef="business_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="merchantMemberAccount.member.sortName"> Merchant Name </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="authService.isAuthorityOrReviewer()">
                        <a (click)="merchantDetails($event, element.merchantMemberAccount.memberId, element.merchantMemberAccount.member.status, element.merchantMemberAccount.member.memberType)" class="link">
                            <img *ngIf="element.merchantMemberAccount.member.memberType === MemberType.INDIVIDUAL_MERCHANT" class="member-type-icon" src="assets/images/profile-icon.svg" title="Individual">
                            <b> {{ element.merchantMemberAccount.member.name }} <i class="fa fa-2x fa-link" *ngIf="authService.isAdmin() && element.accountingExternalIds.length" title="Linked to Accounting System"></i></b>
                            <br>
                            <b *ngIf="element.merchantMemberAccount.member.dbaName"> DBA: {{ element.merchantMemberAccount.member.dbaName }} </b>
                        </a>
                        <br>
                    </span>
                    <span *ngIf="!authService.isAuthorityOrReviewer()">
                        <a *ngIf="element.status !== MerchantAccountStatus.REJECTED" [routerLink]="['/member/details/' + element.merchantMemberAccount?.memberId]" [routerLinkActive]="['router-link-active']">
                            <img *ngIf="element.merchantMemberAccount.member.memberType === MemberType.INDIVIDUAL_MERCHANT" class="member-type-icon" src="assets/images/profile-icon.svg" title="Individual">
                            <b> {{ element.merchantMemberAccount.member.name }} <i class="fa fa-2x fa-link" *ngIf="authService.isAdmin() && element.accountingExternalIds.length" title="Linked to Accounting System"></i></b>
                            <br>
                            <b *ngIf="element.merchantMemberAccount.member.dbaName"> DBA: {{ element.merchantMemberAccount.member.dbaName }} </b>
                        </a>
                        <div *ngIf="element.status === MerchantAccountStatus.REJECTED">
                            <img *ngIf="element.merchantMemberAccount.member.memberType === MemberType.INDIVIDUAL_MERCHANT" class="member-type-icon" src="assets/images/profile-icon.svg" title="Individual">
                            <b> {{ element.merchantMemberAccount.member.name }} <i class="fa fa-2x fa-link" *ngIf="authService.isAdmin() && element.accountingExternalIds.length" title="Linked to Accounting System"></i></b>
                            <br>
                            <b *ngIf="element.merchantMemberAccount.member.dbaName"> DBA: {{ element.merchantMemberAccount.member.dbaName }} </b>
                        </div>
                        <br>
                    </span>
                    <pc-business-address *ngIf="element.merchantMemberAccount.member.address" [address]="element.merchantMemberAccount.member.address">
                    </pc-business-address>
                </td>
            </ng-container>
            <ng-container matColumnDef="contact_info">
                <th mat-header-cell *matHeaderCellDef> Contact Info </th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        <span *ngIf="element.merchantMemberAccount.member.email"><b>Email:</b> {{ element.merchantMemberAccount.member.email }} <br></span>
                        <span *ngIf="element.merchantMemberAccount.member.officePhone"><b>Office Phone:</b> {{ element.merchantMemberAccount.member.officePhone | phone }}</span>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Show Merchant For </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.merchantAccountConfig.merchantAccountType === SupportedTransactionType.BOTH"> Deposits and Payments </span>
                    <span *ngIf="element.merchantAccountConfig.merchantAccountType !== SupportedTransactionType.BOTH"> {{ element.merchantAccountConfig.merchantAccountType | translate }} </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="transaction_types">
                <th mat-header-cell *matHeaderCellDef> Transaction Types </th>
                <td mat-cell *matCellDef="let element">
                    <ul>
                        <li *ngIf="element.merchantAccountConfig.defaultOutgoingTransactionType"> {{ element.merchantAccountConfig.defaultOutgoingTransactionType | translate }} </li>
                        <li *ngIf="element.merchantAccountConfig.defaultIncomingTransactionType"> {{ element.merchantAccountConfig.defaultIncomingTransactionType | translate }} </li>
                    </ul>
                </td>
            </ng-container>
            <ng-container matColumnDef="accounting_ids">
                <th mat-header-cell *matHeaderCellDef>Accounting IDs</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="getAccountingIds(element, AccountingPartyType.SUPPLIER).length">Supplier IDs</span>
                    <ul *ngIf="getAccountingIds(element, AccountingPartyType.SUPPLIER).length">
                        <li *ngFor="let supplierId of getAccountingIds(element, AccountingPartyType.SUPPLIER)">{{supplierId.accountingExternalId}}</li>
                    </ul>
                    <span *ngIf="getAccountingIds(element, AccountingPartyType.CUSTOMER).length">Customer IDs</span>
                    <ul *ngIf="getAccountingIds(element, AccountingPartyType.CUSTOMER).length">
                        <li *ngFor="let supplierId of getAccountingIds(element, AccountingPartyType.CUSTOMER)">{{supplierId.accountingExternalId}}</li>
                    </ul>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="centered" mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let element" class="centered">
                    <pc-merchant-account-badge [parent]="element"></pc-merchant-account-badge>
                    <div *ngIf="element.merchantMemberAccount.member.jurisdiction">{{Utils.getJurisdictionName(element.merchantMemberAccount.member.jurisdiction)}}</div>
                    <div *ngIf="element.merchantMemberAccount.member.tier === RiskTier.TIER_1">Tier 1</div>
                    <p> {{ element.merchantAccountConfig.shared ? '' : '(private)' }} </p>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="centered"> Action </th>
                <td mat-cell *matCellDef="let element" class="centered">
                    <i class="fa fa-2x" *ngIf="(authService.isAuthority() || element.status !== MerchantAccountStatus.REJECTED) && canEnableDisableMerchant(element)" [class.fa-toggle-on]="isEnabled(element)" [class.fa-toggle-off]="!isEnabled(element)" (click)="toggleEnabled($event, element)"></i>
                    <i class="fa fa-2x fa-link" *ngIf="accountingLinked && authService.isAuthority()" (click)="editAccountingId($event, element)" title="Edit Accounting ID"></i>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns" [class.disabled]="!isEnabled(row)"></tr>
        </table>
        <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"></mat-paginator>
    </div>
</div>
