<div>
    <form [formGroup]="formGroup">
        <div>
            <div class="modal-header">
                <label class="form-title">Request Access</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <p>{{'To: ' + memberName}}</p>
                    </div>
                    <div class="col-6">
                        <pc-form-date-picker [label]="'Start Date'"
                            [formCtrl]="'startDateCtrl'"
                            [formGroup]="formGroup"></pc-form-date-picker>

                    </div>
                    <div class="col-6">
                        <pc-form-date-picker [label]="'End Date'"
                            [formCtrl]="'endDateCtrl'"
                            [formGroup]="formGroup"></pc-form-date-picker>
                    </div>
                    <div class="col-12">
                        <pc-form-text-area [required]="false" [label]="'Notes'"
                            [formCtrl]="'noteCtrl'"
                            [formGroup]="formGroup"></pc-form-text-area>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="blue-button" [disabled]="formGroup.invalid" (click)="submit()">Submit</button>
            </div>
        </div>
    </form>
</div>
