<fieldset class="form-group" *ngIf="formCtrl">
    <div class="row">
        <div class="col-12">
            <label class="form-label">{{ label | translate }}</label>
        </div>
        <div class="col-12" [formGroup]="formGroup">
            <input class="form-control form-input-narrow-editable" type="text" [formControlName]="formCtrl" [required]="required" (change)="onInputChanged()"/>
        </div>
    </div>
</fieldset>
