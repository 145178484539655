<div class="dashboard-info row">
    <div class="col-12">
        <h4>Please link your accounts to complete your registration</h4>
        <hr>
        <div class="manage-bank-accounts" *ngIf="requiresBankAccount">
            <div class="col-12 pull-left mb-3">
                <span class="form-title">Banking</span>
            </div>
            <div class="button-panel centered">
                <button class="blue-button" type="button" (click)="manageBankAccounts()">Manage Bank Accounts</button>
            </div>
        </div>

        <pt-member-linked-systems *ngIf="(requiresMrb && !mrbLinked) || (requiresAccounting && !accountingLinked)"
            [requiresMrb]="requiresMrb"
            [requiresAccounting]="requiresAccounting"
            [memberId]="memberId"
            (systemsLinked)="updateLinkedSystems($event)">
        </pt-member-linked-systems>
        <br>

        <div class="button-panel">
            <pc-submit-button class="pull-right" [enabled]="requiredSystemsLinked" [submit]="onCompleteTask" [text]="'Tasks Completed'"></pc-submit-button>
        </div>
    </div>
</div>
