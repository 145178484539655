import { Address, AddressType } from './address';

export class AddressOccupant {

    id: string;
    occupantId: string;
    type: AddressType;
    addressId: string;
    status: AddressStatus;
    moveInDate: Date;
    moveOutDate: Date;
    memberId: string;
    nickname: string;
    address: Address;
    memberAccountId: string;

    // ad hoc fields
    accountName: string;
}

export enum AddressStatus {
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED'
}
