import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AccountThresholds } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-account-thresholds',
    templateUrl: './account-thresholds.component.html'
})
export class AccountThresholdsComponent implements OnInit, OnChanges {


    @Input() jsonData: string;
    @Input() changeTrigger = 1;

    accountThresholds: AccountThresholds;

    ngOnInit() {
        this.accountThresholds = JSON.parse(this.jsonData);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.changeTrigger && !changes.changeTrigger.firstChange)) {
            this.accountThresholds = JSON.parse(this.jsonData);
        }
    }
}
