import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { BankAccount } from '../models/bank-account';
import { CustomValidators } from '../validators/custom.validators';

@Injectable({
    providedIn: 'root'
})
export class BankAccountFormService {

    constructor(private formbuilder: UntypedFormBuilder) {
    }

    initializeForm(bankAccount: BankAccount): UntypedFormGroup {
        const form = this.formbuilder.group({
            idCtrl: new UntypedFormControl(),
            createdCtrl: new UntypedFormControl(new Date()),
            updatedCtrl: new UntypedFormControl(new Date()),
            memberIdCtrl: new UntypedFormControl(''),
            billingAddressIdCtrl: new UntypedFormControl(),
            countryCodeCtrl: new UntypedFormControl(''),
            externalAccountIdCtrl: new UntypedFormControl(),
            sourceCtrl: new UntypedFormControl(),
            statusCtrl: new UntypedFormControl(),
            subTypeCtrl: new UntypedFormControl(''),
            validatedCtrl: new UntypedFormControl(false),
            accountNameCtrl: new UntypedFormControl('', [CustomValidators.noLeadingOrTrailingWhitespace]),
            accountHolderCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(3), CustomValidators.noLeadingOrTrailingWhitespace]),
            accountNumberCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(17), CustomValidators.onlyNumeric]),
            achRoutingNumberCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(9), CustomValidators.onlyNumeric]),
            wireRoutingNumberCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(9), CustomValidators.onlyNumeric]),
            accountTypeCtrl: new UntypedFormControl(''),
            financialInstitutionCtrl: new UntypedFormControl('', [Validators.required, Validators.maxLength(140), CustomValidators.noLeadingOrTrailingWhitespace])
        });
        if (bankAccount) {
            form.patchValue({
                idCtrl: bankAccount.id,
                createdCtrl: bankAccount.created,
                updatedCtrl: bankAccount.updated,
                memberIdCtrl: bankAccount.memberId,
                billingAddressIdCtrl: bankAccount.billingAddressId,
                countryCodeCtrl: bankAccount.countryCode,
                externalAccountIdCtrl: bankAccount.externalAccountId,
                sourceCtrl: bankAccount.source,
                statusCtrl: bankAccount.status,
                subTypeCtrl: bankAccount.subType,
                validatedCtrl: bankAccount.validated,
                accountNameCtrl: bankAccount.name,
                accountHolderCtrl: bankAccount.holder,
                accountNumberCtrl: bankAccount.accountNumber,
                achRoutingNumberCtrl: bankAccount.routingNumber,
                wireRoutingNumberCtrl: bankAccount.wireRoutingNumber,
                accountTypeCtrl: bankAccount.type,
                financialInstitutionCtrl: bankAccount.financialInstitution
            });
        }
        return form;
    }

    getBankAccount(form: UntypedFormGroup): BankAccount {
        const account = new BankAccount();

        account.id = form.controls['idCtrl'].value;
        account.memberId = form.controls['memberIdCtrl'].value;
        account.billingAddressId = form.controls['billingAddressIdCtrl'].value;
        account.countryCode = form.controls['countryCodeCtrl'].value;
        account.externalAccountId = form.controls['externalAccountIdCtrl'].value;
        account.source = form.controls['sourceCtrl'].value;
        account.status = form.controls['statusCtrl'].value;
        account.subType = form.controls['subTypeCtrl'].value;
        account.validated = form.controls['validatedCtrl'].value;
        account.name = form.controls['accountNameCtrl'].value;
        account.holder = form.controls['accountHolderCtrl'].value;
        account.accountNumber = form.controls['accountNumberCtrl'].value;
        account.routingNumber = form.controls['achRoutingNumberCtrl'].value;
        account.wireRoutingNumber = form.controls['wireRoutingNumberCtrl'].value;
        account.financialInstitution = form.controls['financialInstitutionCtrl'].value;
        account.type = form.controls['accountTypeCtrl'].value;
        account.created = form.controls['createdCtrl'].value;
        account.updated = form.controls['updatedCtrl'].value;
        return account;
    }
}
