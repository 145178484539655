import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HomeModule } from '../home/home.module';
import { AdministrationRoutingModule } from './administration-routing.module';
import { SharedComponentsModule } from 'projects/components/src/lib/shared-components.module';
import { SettingsManagementComponent, GenericSettingComponent, AuthoritySettingComponent, MemberSettingComponent, BankAccountsComponent, ConsumerSettingComponent,
    BankAccountReviewComponent, BankAccountReviewNotesModalComponent, PartnerListComponent, MemberMerchantListComponent, LocationListComponent, ActiveKeyPartyListComponent,
    HistoricalKeyPartyListComponent, LicenseListComponent } from './settings';
import {
    CorporateKeyPartyModalComponent,
    FincenInvestigationDocumentModalComponent,
    IndividualKeyPartyModalComponent,
    LicenseModalComponent,
    LocationModalComponent,
    OtherDocumentModalComponent
} from '../components';
import {
    AddMemberBankAccountModalComponent, AdministratorDetailsComponent, CompleteMemberRegistrationComponent, MemberManagementComponent, MembersTableHeaderComponent,
    MembersListComponent, MemberDetailsComponent, MemberBusinessDetailsComponent, MemberBankingDetailsComponent, MemberKeyPartyDetailsComponent, MemberInternalDetailsComponent,
    MemberInviteComponent, MemberApplicationDecisionComponent, MemberLocationsComponent, MemberLicensesComponent, MemberRfiComponent,
    MemberTransactionsComponent, MemberUsersComponent, MemberFinancialDetailsComponent, MemberOtherDocumentsComponent, OnboardingNotesComponent, PasswordResetTaskComponent,
    MemberMetrcDetailsComponent, MemberMetrcReceiptListComponent, WhitelistRelationshipPartyDetailsComponent, InternalAlertsComponent, SearchHeaderComponent,
    KeyPartySearchResultsComponent, MemberAccountSearchResultsComponent, UserAccountSearchResultsComponent, MemberOverviewComponent, MemberSearchResultsComponent, BankAccountSearchResultsComponent
} from './members';
import { SharedSmartContractsComponentsModule } from 'projects/smart-contract-components/src/public-api';
import { TransactionModule } from '../transaction/transaction.module';
import { PartnerSettingsComponent } from './members/components/partner-settings/partner-settings.component';
import { MerchantListComponent, MerchantDetailsComponent, MerchantContactInfoComponent, AuthorityMemberMerchantListComponent, MerchantAdministrationComponent } from './merchants';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AuditModule } from '../audit/audit.module';
import { AlertModule } from '../alert/alert.module';
import { ConnectedMembersListComponent } from './members/components/connected-members-list/connected-members-list.component';
import { LendingManagementComponent } from './settings/components/lending-management/lending-management.component';
import { FinancialDataComponent } from './members/components/financial-data/financial-data.component';
import { MatProgressBarModule  } from '@angular/material/progress-bar';
import { MemberKpiComponent } from './members/components/financial-data/member-kpi/member-kpi.component';
import { MemberProfitComponent } from './members/components/financial-data/member-profit/member-profit.component';
import { MemberBalanceComponent } from './members/components/financial-data/member-balance/member-balance.component';
import { MemberCustomersComponent } from './members/components/financial-data/member-customers/member-customers.component';
import { MemberSuppliersComponent } from './members/components/financial-data/member-suppliers/member-suppliers.component';
import { LoansComponent } from './members/components/loans/loans.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MemberMetrcReceiptLineItemRowComponent } from './members/components/member-metrc-receipt-line-item-row/member-metrc-receipt-line-item-row.component';
import { MemberMetrcReceiptSummaryComponent } from './members/components/member-metrc-receipt-summary/member-metrc-receipt-summary.component';
import { ReportsModule } from '../reports/reports.module';
import { MemberMessageComponent } from './members/components/member-message/member-message/member-message.component';
import { PeriodicReviewComponent } from './members/components/periodic-review/periodic-review.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
    MemberAccountsListComponent, MemberAccountCardComponent, MemberAccountDetailsComponent, MemberAccountOverviewComponent, MemberAccountBillingInfoComponent, MemberAccountLimitsComponent,
    MerchantMemberAccountBankingDetailsComponent, NewMemberAccountModalComponent
} from './member-accounts';
import { MatCardModule } from '@angular/material/card';
import { MemberAccountSettingsComponent } from './member-accounts/member-account-settings/member-account-settings.component';
import { IndividualDetailsComponent } from './individuals';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AdministrationRoutingModule,
        TranslateModule,
        SharedComponentsModule,
        NgbModule,
        HomeModule,
        SharedSmartContractsComponentsModule,
        TransactionModule,
        ReportsModule,
        MatButtonToggleModule,
        AuditModule,
        AlertModule,
        AuditModule,
        MatProgressBarModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatBadgeModule,
        MatTooltipModule,
        MatCardModule
    ],

    declarations: [
        SettingsManagementComponent,
        GenericSettingComponent,
        AuthoritySettingComponent,
        MemberSettingComponent,
        ConsumerSettingComponent,
        BankAccountsComponent,
        LocationListComponent,
        ActiveKeyPartyListComponent,
        HistoricalKeyPartyListComponent,
        LicenseListComponent,
        CorporateKeyPartyModalComponent,
        IndividualKeyPartyModalComponent,
        LicenseModalComponent,
        LocationModalComponent,
        MemberManagementComponent,
        MembersTableHeaderComponent,
        MembersListComponent,
        MemberDetailsComponent,
        PeriodicReviewComponent,
        MemberBusinessDetailsComponent,
        MemberKeyPartyDetailsComponent,
        MemberFinancialDetailsComponent,
        MemberInternalDetailsComponent,
        InternalAlertsComponent,
        MemberLocationsComponent,
        MemberLicensesComponent,
        MemberOtherDocumentsComponent,
        OnboardingNotesComponent,
        MemberRfiComponent,
        MemberInviteComponent,
        MemberTransactionsComponent,
        MemberUsersComponent,
        OtherDocumentModalComponent,
        MemberApplicationDecisionComponent,
        AdministratorDetailsComponent,
        CompleteMemberRegistrationComponent,
        MemberMetrcDetailsComponent,
        MemberMetrcReceiptListComponent,
        PasswordResetTaskComponent,
        BankAccountReviewComponent,
        MerchantListComponent,
        PartnerSettingsComponent,
        BankAccountReviewNotesModalComponent,
        PartnerListComponent,
        MemberMerchantListComponent,
        MerchantDetailsComponent,
        MerchantContactInfoComponent,
        MemberBankingDetailsComponent,
        AddMemberBankAccountModalComponent,
        AuthorityMemberMerchantListComponent,
        ConnectedMembersListComponent,
        WhitelistRelationshipPartyDetailsComponent,
        LendingManagementComponent,
        ConnectedMembersListComponent,
        FinancialDataComponent,
        MemberKpiComponent,
        MemberProfitComponent,
        MemberBalanceComponent,
        MemberCustomersComponent,
        MemberSuppliersComponent,
        LoansComponent,
        MemberAccountBillingInfoComponent,
        MemberMetrcReceiptLineItemRowComponent,
        MemberMetrcReceiptSummaryComponent,
        MemberMessageComponent,
        SearchHeaderComponent,
        UserAccountSearchResultsComponent,
        KeyPartySearchResultsComponent,
        MemberAccountSearchResultsComponent,
        MerchantAdministrationComponent,
        MemberAccountOverviewComponent,
        MemberAccountsListComponent,
        MemberAccountCardComponent,
        MemberAccountDetailsComponent,
        MemberAccountSettingsComponent,
        MemberOverviewComponent,
        IndividualDetailsComponent,
        MemberAccountLimitsComponent,
        MerchantMemberAccountBankingDetailsComponent,
        MemberSearchResultsComponent,
        BankAccountSearchResultsComponent,
        NewMemberAccountModalComponent,
        FincenInvestigationDocumentModalComponent
    ],

    exports: [
        MemberBusinessDetailsComponent,
        MemberKeyPartyDetailsComponent,
        MemberLocationsComponent,
        MemberLicensesComponent,
        MemberFinancialDetailsComponent,
        MemberOtherDocumentsComponent,
        MemberTransactionsComponent,
        MemberUsersComponent,
        FinancialDataComponent,
        MerchantAdministrationComponent,
        MemberAccountsListComponent,
        MemberAccountCardComponent,
        MemberAccountOverviewComponent,
        MemberAccountDetailsComponent,
        MemberAccountSettingsComponent,
        MemberOverviewComponent,
        NewMemberAccountModalComponent
    ],

    providers: [
    ]
})
export class AdministrationModule {
}
