<form [formGroup]="formGroup">
    <div class="row">
        <div class="col-4">
            <pt-deposit-method *ngIf="subType === TransactionSubType.DEPOSIT" [formGroup]="formGroup" [businessClients]="businessClients" [depositMethods]="transactionTypes"></pt-deposit-method>
            <pt-payment-method *ngIf="subType === TransactionSubType.PAYMENT" [formGroup]="formGroup" [businessClients]="businessClients" [paymentMethods]="transactionTypes"
                (dateSelected)="onDateSelected($event)" (transactionTypeChange)="onTransactionTypeChanged()"></pt-payment-method>
        </div>
        <div class="col-8">
            <div class="summary-content">
                <label class="form-label">{{subType === TransactionSubType.DEPOSIT ? 'Deposit' : 'Payment'}} Summary:</label>
                <table *ngIf="isRetailTransaction()" mat-table [dataSource]="businessClients[0].transactionEntries" class="mb-3">
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef> Description </th>
                        <td mat-cell *matCellDef="let element"> {{element.data.description}} </td>
                        <td mat-footer-cell class="centered" *matFooterCellDef><strong>Total:</strong></td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell class="centered" *matHeaderCellDef> Amount </th>
                        <td mat-cell class="centered" *matCellDef="let element">
                            <span class="table-amount">
                                {{element.data.paidAmount | currency}}
                            </span>
                        </td>
                        <td mat-footer-cell class="centered" *matFooterCellDef>
                            <div class="d-block centered">
                                <span class="table-amount">
                                    <strong>{{transactionTotal | currency}}</strong>
                                </span>
                            </div>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="transactionEntryColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: transactionEntryColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="transactionEntryColumns"></tr>
                </table>

                <table *ngIf="!isRetailTransaction()" #nonRetailTable mat-table [dataSource]="businessClients" class="mb-3" multiTemplateDataRows>
                    <ng-container matColumnDef="accounting_party">
                        <th mat-header-cell *matHeaderCellDef> {{subType === TransactionSubType.DEPOSIT ? 'Customer' : 'Vendor'}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>
                    <ng-container matColumnDef="entry">
                        <th mat-header-cell class="centered" *matHeaderCellDef> {{subType === TransactionSubType.DEPOSIT ? 'Invoice' : 'Bill'}} # </th>
                        <td mat-cell class="centered" *matCellDef="let element">
                            <div class="d-block centered" *ngFor="let transactionEntry of element.transactionEntries">
                                <span *ngIf="transactionEntry.data.type !== 'OTHER'">{{transactionEntry.data.externalReference || ''}}</span>
                                <span *ngIf="transactionEntry.data.type === 'OTHER'" [class]="isUniversalWhiteListedMerchant ? '' : transactionEntry.data.supportingDocuments.length === 0 ? 'error' : '' "><i class="fa fa-info-circle" [title]="transactionEntry.data.description"></i></span>
                            </div>
                        </td>
                        <td mat-footer-cell class="centered" *matFooterCellDef><strong>Total:</strong></td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell class="centered" *matHeaderCellDef> Amount </th>
                        <td mat-cell class="centered" *matCellDef="let element">
                            <div class="d-block centered" *ngFor="let transactionEntry of element.transactionEntries">
                                <span class="table-amount" [class]="isUniversalWhiteListedMerchant ? '' : transactionEntry.data.type === 'OTHER' && transactionEntry.data.supportingDocuments.length === 0 ? 'error' : '' ">
                                    {{transactionEntry.data.paidAmount | currency}}
                                </span>
                            </div>
                        </td>
                        <td mat-footer-cell class="centered" *matFooterCellDef>
                            <div class="d-block centered">
                                <span class="table-amount">
                                    <strong>{{transactionTotal | currency}}</strong>
                                </span>
                            </div>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell class="centered" *matHeaderCellDef> Action </th>
                        <td mat-cell class="centered" *matCellDef="let element; let clientIndex = dataIndex;">
                            <i class="fa fa-times" (click)="removeBusinessClient(clientIndex)" title="Remove Client"></i>
                            <i class="fa fa-pencil" (click)="editBusinessClient(element)" title="Edit Client Submission"></i>
                        </td>
                        <td mat-footer-cell class="centered" *matFooterCellDef>
                            <div class="d-block centered">
                                <button class="blue-button" (click)="addBusinessClient()" type="button"><i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add Client</div></button>
                            </div>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="transactionEntryColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: transactionEntryColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="transactionEntryColumns"></tr>
                </table>
                <label *ngIf="selectedManifests.length" class="form-label">Manifest Summary:</label>
                <table *ngIf="selectedManifests.length" #manifestTable mat-table [dataSource]="selectedManifests" class="mb-3">
                    <ng-container matColumnDef="manifest_number">
                        <th mat-header-cell *matHeaderCellDef>Manifest #</th>
                        <td mat-cell *matCellDef="let element">{{element.data.manifestNumber}}</td>
                    </ng-container>
                    <ng-container matColumnDef="supplier">
                        <th mat-header-cell *matHeaderCellDef>Supplier</th>
                        <td mat-cell *matCellDef="let element"> {{element.data.shipperFacilityName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="recipient">
                        <th mat-header-cell *matHeaderCellDef> Recipient </th>
                        <td mat-cell *matCellDef="let element"> {{element.data.recipientFacilityName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="created">
                        <th mat-header-cell class="centered" *matHeaderCellDef>Created Date</th>
                        <td mat-cell class="centered" *matCellDef="let element"> {{element.data.created | date: 'mediumDate'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="received_date">
                        <th mat-header-cell class="centered" *matHeaderCellDef>Received Date</th>
                        <td mat-cell class="centered" *matCellDef="let element"> {{element.data.receivedDateTime | date: 'mediumDate'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="package_count">
                        <th mat-header-cell class="centered" *matHeaderCellDef>Package Count</th>
                        <td mat-cell class="centered" *matCellDef="let element"> {{element.data.lineItems?.length}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="manifestColumns"></tr>
                    <tr mat-row *matRowDef="let element; columns: manifestColumns;"></tr>
                </table>
                <div *ngIf="manifestSupportingDocs.length > 0" class="mb-3 row no-gutters">
                    <label *ngIf="manifestSupportingDocs.length" class="col-12 form-label">Uploaded Manifest Documents:</label>
                    <div class="manifest-support-document col-12 w-100" *ngFor="let manifestSupportingDoc of manifestSupportingDocs; let documentNumber = index">
                        <div class="dashboard-list-a pull-right"><i class="fa fa-2x fa-paperclip"></i>{{ manifestSupportingDoc.file?.name }}</div><i class="fa fa-2x fa-times pull-right" (click)="removeManifestSupportingDocument(documentNumber)"></i>
                    </div>
                </div>
            </div>
            <div>
                <div class="mb-3" *ngIf="subType === TransactionSubType.PAYMENT && isCheckTransaction()">
                    <div class="row">
                        <div class="d-inline-flex col-6 pr-3">
                            <div>
                                Check Memo:
                            </div>
                            <textarea class="remittance-memo" maxlength="73" [ngModelOptions]="{standalone: true}" [(ngModel)]="remittanceInfo.remittanceMemo"></textarea>
                        </div>
                        <div class="col-6 row" *ngIf="isOutgoingCheckToMerchant()">
                            <div class="col-12">
                                <button class="blue-button" type="button" (click)="addRemittanceSupportingDocument()">
                                    <i class="fa fa-plus pull-left dashboard-icon"></i>
                                    <div class="pull-right-button">Add Remittance Form</div>
                                </button>
                            </div>
                            <div class="remittance-support-document col-12 w-100" *ngIf="remittanceInfo.remittanceFile">
                                <span class="dashboard-list-a"><i class="fa fa-2x fa-paperclip"></i>{{ remittanceInfo?.remittanceFile.file?.name }}</span><i class="fa fa-2x fa-times" (click)="removeRemittanceSupportingDocument()"></i>
                            </div>
                        </div>
                        <div class="mt-3 col-12 remittance-note-area" *ngIf="isOutgoingCheckToMerchant()">
                            <b>Note: </b>Remittance forms must be less than 5 pages and will be included with the check payment
                        </div>
                    </div>
                </div>
                <div class="mb-3" *ngIf="subType === TransactionSubType.PAYMENT && isOutgoingWireToMerchant()">
                    <div class="row">
                        <div class="d-inline-flex pr-3" [formGroup]="remittanceFormGroup">
                            <span class="col-4">
                                Notes To Payee :
                            </span>
                            <textarea class="remittance-memo" maxlength="135" formControlName="notesToReceiverCtrl" [(ngModel)]="remittanceInfo.remittanceMemo"></textarea>
                        </div>
                    </div>
                    <div class="row" *ngIf="remittanceFormGroup.controls['notesToReceiverCtrl'].invalid">
                        <div class="remittance-error">
                            Special Characters are not allowed
                        </div>
                    </div>
                </div>
                <div class="mt-3 mb-3 row" *ngIf="subType === TransactionSubType.PAYMENT">
                    <div *ngIf="paymentCompletionDate" class=" col-12 d-flex justify-content-center">
                        <strong>Expected Delivery Date: {{ paymentCompletionDate | date:'mediumDate' }} </strong>
                    </div>
                    <div *ngIf="getCurrentTransactionType() === TransactionType.WALLET_TO_EXPRESS_ACH_PAYMENT && achExpressPaymentFee" class="col-12 d-flex justify-content-center">
                        <strong>Payment Fee: {{ (achExpressPaymentFee | currency) }} </strong>
                    </div>
                    <div *ngIf="getCurrentTransactionType() === TransactionType.OUTGOING_WIRE_TO_MERCHANT && wirePaymentFee" class="col-12 d-flex justify-content-center">
                        <strong>Payment Fee: {{ (wirePaymentFee | currency) }} </strong>
                    </div>
                </div>
                <div class="mt-3 row">
                    <div class="col-12 d-flex justify-content-end">
                        <pc-submit-button [submit]="submit" [enabled]="isFormValid" [text]="'Submit'"></pc-submit-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <input hidden type="file" id="remittanceUpload" #remittanceUpload (change)="selectRemittanceFile($event)" placeholder="Upload file" accept=".pdf">
</form>
