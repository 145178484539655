<div class="horizontal-scroll mat-table-container">
    <div class="table mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <mat-paginator [pageIndex]="paginator.pageIndex" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page" #paginator></mat-paginator>
    <table mat-table [dataSource]="bsareports" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="memberName" class="col">
            <th mat-header-cell *matHeaderCellDef> Member Name </th>
            <td mat-cell *matCellDef="let element">
                <a *ngIf="element?.memberId" [routerLink]="['/administration/member/' + element?.memberId]"
                    [routerLinkActive]="['router-link-active']">
                    {{ element.memberName }}
                </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="reportType">
            <th class="centered" mat-header-cell  *matHeaderCellDef mat-sort-header> Type </th>
            <td class="centered" mat-cell *matCellDef="let element"> {{ element.reportType | translate }}</td>
        </ng-container>
        <ng-container matColumnDef="actualReportTriggerDate">
            <th class="centered" mat-header-cell  *matHeaderCellDef mat-sort-header> Date CTR Generated </th>
            <td class="centered" mat-cell *matCellDef="let element"> {{ element.actualReportTriggerDate | date : 'mediumDate' }} </td>
        </ng-container>
        <ng-container matColumnDef="startDate">
            <th class="centered" mat-header-cell  *matHeaderCellDef mat-sort-header> Reporting Start Date </th>
            <td class="centered" mat-cell *matCellDef="let element"> {{ element.startDate? (element.startDate | date:'mediumDate') : 'N/A' }} </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
            <th class="centered" mat-header-cell  *matHeaderCellDef mat-sort-header> Reporting End Date </th>
            <td class="centered" mat-cell *matCellDef="let element"> {{ element.endDate? (element.endDate | date:'mediumDate') : 'N/A' }} </td>
        </ng-container>
        <ng-container matColumnDef="dueDate">
            <th class="centered" mat-header-cell  *matHeaderCellDef> Submission Due Date </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <span *ngIf="element.reportType === ReportType.INITIAL_REPORT">
                    {{ getSubmissionDueDate(element) | date : 'mediumDate' }}
                </span>
                <span *ngIf="(element.reportType === ReportType.CTR_REPORT || element.reportType === ReportType.DEPOSIT_CTR_REPORT || element.reportType === ReportType.WITHDRAWAL_CTR_REPORT) && element.transactionId">
                    {{ 'N/A' }}
                </span>
                <span *ngIf="(element.reportType === ReportType.CONTINUING_REPORT) || (element.reportType === ReportType.CTR_REPORT && !element.transactionId && element.filingInstitution === FilingInstitution.NBCU)">
                    {{ getSubmissionDueDate(element) | date : 'mediumDate' }}
                </span>
        </ng-container>
        <ng-container matColumnDef="submissionDate">
            <th class="centered" mat-header-cell  *matHeaderCellDef mat-sort-header> Date Of Submission </th>
            <td class="centered" mat-cell *matCellDef="let element"> {{ element.submissionDate? (element.submissionDate | date:'mediumDate') : 'N/A' }} </td>
        </ng-container>
        <ng-container matColumnDef="trackingId">
            <th class="centered" mat-header-cell  *matHeaderCellDef mat-sort-header> BSA Tracking ID </th>
            <td class="centered" mat-cell *matCellDef="let element"> {{ element.trackingId ? element.trackingId : 'N/A' }} </td>
        </ng-container>
        <ng-container matColumnDef="suspiciousAmount">
            <th class="centered" mat-header-cell  *matHeaderCellDef mat-sort-header> Suspicious Amount </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <span *ngIf="element.reportType !==  ReportType.INITIAL_REPORT" class="table-amount"> {{ element.suspiciousAmount | currency }} </span>
                <span *ngIf="element.reportType === ReportType.INITIAL_REPORT"> N/A </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="total_amount">
            <th class="centered" mat-header-cell  *matHeaderCellDef> Total Amount </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <span class="table-amount" *ngIf="!element.transactionId && element.filingInstitution === FilingInstitution.NBCU">{{ element.suspiciousAmount | currency }}</span>
                <span class="table-amount" *ngIf="element.transactionId && element.reportType === ReportType.DEPOSIT_CTR_REPORT">{{ element.credit | currency }}</span>
                <span class="table-amount" *ngIf="element.transactionId && element.reportType === ReportType.WITHDRAWAL_CTR_REPORT">{{ element.debit | currency }}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="cumulativeAmount">
            <th class="centered" mat-header-cell  *matHeaderCellDef mat-sort-header> Cumulative Amount </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <span *ngIf="element.reportType !== ReportType.INITIAL_REPORT" class="table-amount"> {{ element.cumulativeAmount | currency }} </span>
                <span *ngIf="element.reportType === ReportType.INITIAL_REPORT"> N/A </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="bsaId">
            <th class="centered" mat-header-cell  *matHeaderCellDef mat-sort-header> BSA ID </th>
            <td class="centered" mat-cell *matCellDef="let element"> {{ element.bsaId? element.bsaId : 'N/A' }} </td>
        </ng-container>
        <ng-container matColumnDef="copyoffile">
            <th mat-header-cell *matHeaderCellDef> Copy Of File </th>
            <td mat-cell *matCellDef="let element">
                <a *ngIf="element.documentDetails" class="dashboard-list-a text-nowrap"
                    (click)="downloadFile(element,$event)">
                    <i class="fa fa-paperclip"></i>Download
                </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <pc-report-badge [parent]="element"></pc-report-badge>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="pc-click-row" (click)="loadBsaTransactionDetails(row.id, $event)"></tr>
    </table>
    <mat-paginator [pageIndex]="paginator.pageIndex" (page)="handlePageBottom($event)" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"></mat-paginator>
</div>
<a class="report-download-link" #downloadReportLink></a>
