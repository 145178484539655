 <div>
    <div class="modal-header">
        <label class="form-title">Add Bank Account</label>
        <i class="modal-close" (click)="close()">&times;</i>
    </div>
    <div>
        <div id="container-fastlink">
        </div>
    </div>
</div>

