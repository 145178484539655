import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http.service';
import { MetrcLicense } from './models/metrc-license';

@Injectable({
    providedIn: 'root'
})
export class MetrcService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.MetrcAPIEndpoint + '/';
    }

    saveMetrcLicense(metrcLicense: MetrcLicense) {
        return this.http.post(`${this.baseUrl}`, metrcLicense);
    }

    isAuthenticated(memberId: string) {
        const param = `?memberId=${memberId}`;
        return this.http.get(`${this.baseUrl}validate${param}`);
    }

    initializeAccount(metrcLicense: MetrcLicense) {
        return this.http.post(`${this.baseUrl}initialize`, metrcLicense);
    }

    getActiveMetrcLicenseInformation(status :string, memberId :string) {
        const params = `?status=${status}&memberId=${memberId}`;
        return this.http.get(`${this.baseUrl}status${params}`);
    }

    disableAllActiveLicenses(memberId: string) {
        return this.http.delete(`${this.baseUrl}all/${memberId}`);
    }

    updateMetrcLicense(id: string, body: any) {
        return this.http.put(`${this.baseUrl}${id}`, body);
    }

}
