<div class="row dashboard-info justify-content-center col-12" *ngIf="taskVars">
    <div class="col-12 dashboard-header">
        <span>Password Reset Details</span>
    </div>
    <div class="col-6">
        <dl class="row">
            <dt class="col-sm-3">Name:</dt>
            <dd class="col-sm-9">{{taskVars.firstName}} {{taskVars.lastName}}</dd>

            <dt class="col-sm-3">Email:</dt>
            <dd class="col-sm-9">{{taskVars.email}}</dd>
        </dl>
    </div>

    <div class="col-12">
        <p *ngIf="isExpired()">
            <strong>NOTE:</strong> This password reset link has expired, and is no longer valid. Please terminate the task, and resend the reset link if the user still wishes to reset their password.
        </p>
        <hr class="separator-thick">
        <div class="button-panel">
            <button class="blue-button" type="button" (click)="terminateProcess()">Terminate Process</button>
            <button class="blue-button" type="button" (click)="returnToTasks()">Return to Open Items</button>
        </div>
    </div>
</div>

