import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'projects/services/src/public-api';

import { TransactionListComponent, TransactionDetailsComponent, TransactionCompletionComponent } from './components';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: TransactionListComponent,
        canActivate: [AuthGuard],
        data: { title: 'Transactions' }
    },
    {
        path: 'details/:transactionId',
        component: TransactionDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Transactions' }
    },
    {
        path: 'complete/:transactionId',
        component: TransactionCompletionComponent,
        canActivate: [AuthGuard],
        data: { title: 'Transactions' }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TransactionRoutingModule {
}
