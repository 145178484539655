import { User } from './user';
import { RfiStatus } from './rfi';
import { Upload } from './upload';

export class RfiHistory {
    id: string;
    rfiId: string;
    status: RfiStatus;
    dueDate: Date;
    created: Date;
    transactionId: string;
    notes: string;
    documents: Upload[];
    performedBy: any;
    performedByName: string;
    performedByDetails: User;
    authorityOnly: boolean;
}
