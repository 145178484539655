import { Injectable } from '@angular/core';
import { Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

export interface DirtyComponent {
    isDirty(): boolean;
    canDeactivate(nextState: RouterStateSnapshot): Observable<boolean>;
}

@Injectable({
    providedIn: 'root'
})
export class DirtyCheckGuard implements CanDeactivate<DirtyComponent> {

    constructor(private router: Router) {
    }

    canDeactivate(component: DirtyComponent, _route: ActivatedRouteSnapshot, _currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) {
        const navObject = this.router.getCurrentNavigation();
        if (navObject && navObject.extras.state && navObject.extras.state.bypassGuard) {
            return of(true);
        }

        return component.canDeactivate(nextState);
    }
}
