import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
    AuthService, KeyParty, KeyPartyService, MemberAccountAccess, MemberAccountStatus, MemberFunction, MemberStatus, PagedResponse, Profile, Role, UserAccount, UserAccountService, UserAccountStatus,
    UserMemberAccess, UserStatus,
    UserUpdatableField, WorkflowService
} from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmModalComponent, InfoModalComponent, LoaderComponent, UserFieldUpdateModalComponent } from 'projects/components/src/public-api';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pc-member-user-list',
    templateUrl: './member-user-list.component.html',
    styleUrls: ['member-user-list.component.scss']
})
export class MemberUserListComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    UserAccountStatus = UserAccountStatus;
    UserStatus = UserStatus;
    Role = Role;
    MemberAccountStatus = MemberAccountStatus;
    MemberStatus = MemberStatus;

    @Input() changeTrigger: number;
    @Input() memberId: string;
    @Input() memberFunctions: MemberFunction[] = [];
    @Input() readOnly = false;

    profile: Profile;
    isLoadingResults = true;
    userMemberAccess: UserMemberAccess[] = [];
    displayedColumns: string[] = ['name', 'key_party', 'account_access', 'actions'];

    pageTracking: PageTracking;
    resultsLength = 0;
    subscription: any;
    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private userAccountService: UserAccountService,
                public authService: AuthService,
                private workflowService: WorkflowService,
                private overlay: Overlay,
                private dialog: MatDialog,
                private route: ActivatedRoute,
                private router: Router,
                private keyPartyService: KeyPartyService,
                private notifier: NotificationService,
                private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        if (this.readOnly || !this.authService.isAuthority()) {
            this.displayedColumns = ['name', 'key_party', 'account_access'];
        }
        this.profile = this.authService.getProfile();
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'user.lastName', 'asc', 100);
    }

    ngOnChanges(_changes: SimpleChanges) {
        this.refreshPage();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.userAccountService.getUserAccessByMemberId(this.memberId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<UserMemberAccess>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((memberAccesses: UserMemberAccess[]) => {
            const results: UserMemberAccess[] = [];
            const roleOrder = Object.values(Role);
            for (const memberAccess of memberAccesses) {
                this.initializeUserAccount(memberAccess);
                memberAccess.accountAccess.sort((a, b) => {
                    return roleOrder.indexOf(a.role) - roleOrder.indexOf(b.role);
                });
                results.push(memberAccess);
            }
            this.userMemberAccess = results;
            UIUtils.scrollDashboardToTop();
        });
    }

    initializeUserAccount(memberAccess: UserMemberAccess) {
        this.keyPartyService.getActiveIndividualKeyPartiesByEmail(memberAccess.memberId, memberAccess.user.email).subscribe((keyParties: PagedResponse<KeyParty>) => {
            if (keyParties.totalElements > 0) {
                memberAccess.isKeyParty = true;
            }
        });
    }

    isPartner() {
        return this.memberFunctions?.indexOf(MemberFunction.PARTNER) >= 0;
    }

    canUserBeReset(userMemberAccess: UserMemberAccess) {
        return userMemberAccess.userId
            && (userMemberAccess.user.status === UserStatus.ACTIVE
                || userMemberAccess.user.status === UserStatus.LOCKED)
            && (userMemberAccess.accountAccess.find((memberAccountAccess) => {
                return memberAccountAccess.status !== UserAccountStatus.INVITED && memberAccountAccess.status !== UserAccountStatus.DISABLED;
            }));
    }

    canUserBeUpdated(userMemberAccess: UserMemberAccess) {
        return this.canUserBeReset(userMemberAccess);
    }

    refreshPage () {
        this.refreshEvent.emit();
    }

    resetPassword(_event: any, userMemberAccess: UserMemberAccess) {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        overlayRef.attach(new ComponentPortal(LoaderComponent));
        const passwordResetRequest = {
            userId: userMemberAccess.user.id
        };
        this.workflowService.resetPassword(passwordResetRequest).subscribe(
            () => {
                overlayRef.dispose();
                const dialogConfig: MatDialogConfig = {};
                dialogConfig.autoFocus = true;
                dialogConfig.panelClass = 'normal-modal';

                dialogConfig.data = {
                    message: 'Password reset link has been sent, and will expire in 8 hours.'
                };
                const dialog = this.dialog.open(InfoModalComponent, dialogConfig);

                dialog?.afterClosed().subscribe(
                    (refresh: boolean) => {
                        if (refresh) {
                            this.refreshPage();
                        }
                    }
                );
            }
        );
    }

    revokeInvitation(userMemberAccess: UserMemberAccess) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'Revoke Invitation',
            description: `This will revoke the invitation sent to ${userMemberAccess.user.firstName} ${userMemberAccess.user.lastName}.  Any registration links sent to this user will be expired immediately.`,
            confirmMessage: 'Do you want to revoke this invitation?',
            confirmText: 'Revoke'
        };
        const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (confirmAction) =>  {
                if (confirmAction === 'confirmed') {
                    const overlayRef = this.overlay.create({
                        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                        hasBackdrop: true
                    });
                    const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
                    componentRef.instance.title = 'Revoking invitation...';
                    this.userAccountService.deleteUserInvitation(userMemberAccess.accountAccess[0].userAccountId).subscribe(() => {
                        overlayRef.dispose();
                        this.refreshPage();
                    }, (error: Error) => {
                        overlayRef.dispose();
                        throw error;
                    });
                }
            }
        );
    }

    updatePhoneNumber(_event: any, userMemberAccess: UserMemberAccess) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            user: userMemberAccess.user,
            fieldType: UserUpdatableField.PHONE
        };
        const dialog = this.dialog.open(UserFieldUpdateModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (refresh: boolean) => {
                if (refresh) {
                    this.refreshPage();
                }
            }
        );
    }

    updateEmail(_event: any, userMemberAccess: UserMemberAccess) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            user: userMemberAccess.user,
            fieldType: UserUpdatableField.EMAIL
        };
        const dialog = this.dialog.open(UserFieldUpdateModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (refresh: boolean) => {
                if (refresh) {
                    this.refreshPage();
                }
            }
        );
    }

    hasActiveAccountAccess(userMemberAccess: UserMemberAccess) {
        return !!userMemberAccess.accountAccess.find((memberAccountAccess: MemberAccountAccess) => {
            return memberAccountAccess.status === UserAccountStatus.ACTIVE;
        });
    }

    isPendingRegistration(userMemberAccess: UserMemberAccess) {
        return !!userMemberAccess.accountAccess.find((memberAccountAccess: MemberAccountAccess) => {
            return (memberAccountAccess.status === UserAccountStatus.PENDING_REGISTRATION || memberAccountAccess.status === UserAccountStatus.INVITED);
        });
    }

    isAdminWithoutKeyParty(userMemberAccess: UserMemberAccess) {
        if (userMemberAccess.isKeyParty) {
            return false;
        }
        return !!userMemberAccess.accountAccess.find((memberAccountAccess: MemberAccountAccess) => {
            return (memberAccountAccess.role === Role.CORPORATE_ADMIN || memberAccountAccess.role === Role.ADMIN);
        });
    }

    toggleEnabled(event: any, userMemberAccess: UserMemberAccess) {
        let userAccountUpdates: any = {};
        if (this.hasActiveAccountAccess(userMemberAccess)) {
            userMemberAccess.accountAccess.forEach((memberAccountAccess: MemberAccountAccess) => {
                if (memberAccountAccess.status === UserAccountStatus.ACTIVE) {
                    let body: any = {};
                    body.status = UserAccountStatus.DISABLED;
                    userAccountUpdates[memberAccountAccess.userAccountId] = body;
                }
            });
        } else {
            userMemberAccess.accountAccess.forEach((memberAccountAccess: MemberAccountAccess) => {
                if (memberAccountAccess.status === UserAccountStatus.DISABLED) {
                    let body: any = {};
                    body.status = UserAccountStatus.ACTIVE;
                    if (memberAccountAccess.role === Role.ADMIN && !userMemberAccess.isKeyParty) {
                        // if we enable a user who is an admin and not a key party, we need to flip their role
                        body.role = Role.MEMBER;
                    }
                    userAccountUpdates[memberAccountAccess.userAccountId] = body;
                }
            });
        }

        event.cancelBubble = true;
        if (event.stopImmediatePropagation) {
            event.stopImmediatePropagation();
        }
        if (Object.keys(userAccountUpdates).length) {
            const overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                hasBackdrop: true
            });
            const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
            componentRef.instance.title = 'Updating user accounts.  Please wait...';
            this.updateUserAccounts(userAccountUpdates).subscribe(() => {
                this.refreshPage();
                overlayRef.dispose();
            }, (error: any) => {
                this.refreshPage();
                overlayRef.dispose();
                throw error;
            });
        }
    }

    toggleEnabledCorporateAdminAccess(event: any, memberAccess: UserMemberAccess) {
        event.cancelBubble = true;
        if (event.stopImmediatePropagation) {
            event.stopImmediatePropagation();
        }
        if (this.hasCorporateAdminAccess(memberAccess)) {
            const dialogConfig: MatDialogConfig = {};
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'normal-modal';

            dialogConfig.data = {
                title: 'Delete Access',
                description: `This will remove the corporate access of ${memberAccess.user.name}.<p>If this user has no other access, they will need to be invited again.</p>`,
                confirmMessage: 'Do you want to remove access?',
                confirmText: 'Remove'
            };
            const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

            dialog?.afterClosed().subscribe(
                (confirmAction) =>  {
                    if (confirmAction === 'confirmed') {
                        const overlayRef = this.overlay.create({
                            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                            hasBackdrop: true
                        });
                        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
                        componentRef.instance.title = 'Removing access...';

                        const userAccountId = memberAccess.accountAccess.find((accountAccess) => {
                            return accountAccess.role === Role.CORPORATE_ADMIN && accountAccess.status === UserAccountStatus.ACTIVE;
                        }).userAccountId;
                        this.userAccountService.removeAccess(userAccountId).subscribe(() => {
                            this.refreshPage();
                            this.notifier.showSuccess('Corporate admin access removed for ' + memberAccess.user.name);
                            overlayRef.dispose();
                        }, (error: any) => {
                            this.refreshPage();
                            overlayRef.dispose();
                            throw error;
                        });
                    }
                }
            );
        } else {
            const overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                hasBackdrop: true
            });
            const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
            this.keyPartyService.getActiveIndividualKeyPartiesByEmail(memberAccess.memberId, memberAccess.user.email).subscribe((keyParties: PagedResponse<KeyParty>) => {
                if (keyParties.totalElements > 0) {
                    const userAccount = new UserAccount();
                    userAccount.id = uuidv4();
                    userAccount.memberId = memberAccess.memberId;
                    userAccount.userId = memberAccess.userId;
                    userAccount.position = 'Corporate Admin';
                    userAccount.role = Role.CORPORATE_ADMIN;
                    userAccount.status = UserAccountStatus.ACTIVE;
                    let expirationDate = new Date();
                    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
                    userAccount.expirationDate = expirationDate;
                    this.userAccountService.addAccess(userAccount).subscribe(() => {
                        this.refreshPage();
                        this.notifier.showSuccess('Corporate admin access added for ' + memberAccess.user.name);
                        overlayRef.dispose();
                    }, (error: any) => {
                        this.refreshPage();
                        overlayRef.dispose();
                        throw error;
                    });
                } else {
                    memberAccess.isKeyParty = false;
                    overlayRef.dispose();
                    this.notifier.showError(`${memberAccess.user.name} is not a key party, and cannot be made a corporate admin.`);
                }
            });
        }
    }

    updateUserAccounts(userAccountUpdates: any) {
        return forkJoin(Object.keys(userAccountUpdates).map((userAccountId: string) => {
            return this.userAccountService.updateUserAccount(userAccountId, userAccountUpdates[userAccountId]);
        }));
    }

    isAuthorityAccess(memberAccess: UserMemberAccess) {
        return memberAccess.accountAccess.length === 1 && (memberAccess.accountAccess[0].role === Role.AUTHORITY
            || memberAccess.accountAccess[0].role === Role.AUTHORITY_READONLY || memberAccess.accountAccess[0].role === Role.AUTHORITY_SUPERUSER);
    }

    hasCorporateAdminAccess(memberAccess: UserMemberAccess) {
        return memberAccess.accountAccess.find((accountAccess) => {
            return accountAccess.role === Role.CORPORATE_ADMIN && accountAccess.status === UserAccountStatus.ACTIVE;
        });
    }
}
