<div class="financial-data-dashboard">
    <div *ngIf="!isAccountingLinked" class="row">
        <div class="col-12 pl-0">
            <span class="form-title">Financial Data</span>
            <div class="mt-3" *ngIf="isLoaded">
                <p>{{memberName}} does not have an Accounting system attached to Confia and financial data cannot be provided.</p>
                <p>Continue working with this member at your own risk.</p>
            </div>
        </div>
    </div>
    <div *ngIf="isAccountingLinked" class="row">
        <div class="col-12 pl-0">
            <span class="form-title">Financial Data</span>
            <div class="tab-row float-right">
                <div [ngClass]="getTabClass('summary')" (click)="setActiveTab('summary')">
                    Summary
                </div>
                <div [ngClass]="getTabClass('profit_and_loss')" (click)="setActiveTab('profit_and_loss')">
                    Profit and Loss
                </div>
                <div [ngClass]="getTabClass('balance_sheet')" (click)="setActiveTab('balance_sheet')">
                    Balance Sheet
                </div>
                <div [ngClass]="getTabClass('kpi')" (click)="setActiveTab('kpi')">
                    KPI
                </div>
                <div [ngClass]="getTabClass('customers')" (click)="setActiveTab('customers')">
                    Customers
                </div>
                <div [ngClass]="getTabClass('suppliers')" (click)="setActiveTab('suppliers')">
                    Suppliers
                </div>
            </div>
        </div>
    </div>

    <div class="tab-body" *ngIf="financialTab === 'summary' && isAccountingLinked">
        <div class="row mt-3">
            <div class="col-2"></div>
            <div class="col-4">
                <div class="data-block">
                    <div class="text-center h4 mt-3">{{totalCustomersOwed | currency}}</div>
                    <div class="text-center small">Total Owed by Customers</div>
                </div>
            </div>
            <div class="ml-1 col-4">
                <div class="data-block">
                    <div class="text-center h4 mt-3">{{totalSuppliersOwed | currency}}</div>
                    <div class="text-center small">Total Owed to Suppliers</div>
                </div>
            </div>
            <div class="col-2"></div>
        </div>

        <div class="row mt-3">
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-6">
                        <span class="block-header">Profit and Loss</span>
                    </div>
                    <div class="col-6">
                        <pc-form-date-picker [formCtrl]="'profitDateCtrl'"
                            [formGroup]="formGroup"
                            [type]="'SINGLE'"
                            [historical]="true"
                            [defaultToday]="true"
                            (dateSelected)="onProfitDateChanged($event)">
                        </pc-form-date-picker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="data-block">
                            <div class="text-center h4 mt-3">{{netProfit | currency}}</div>
                            <div class="text-center small">Net Profit</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="data-block">
                            <div class="text-center h4 mt-3">{{operatingProfit | currency}}</div>
                            <div class="text-center small">Operating Profit</div>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <span class="small">Net Profit Margin</span>
                    <span class="float-right small">{{netProfitMargin ? (netProfitMargin + '%') : 'N/A'}}</span>
                    <div class="progress push">
                        <div class="progress-bar" role="progressbar" [style]="netProfitMargin ? ('width: ' + netProfitMargin + '%') : ''"
                             [attr.aria-valuenow]="netProfitMargin" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-9">
                        <span class="small">Income</span>
                        <span class="float-right small">{{income | currency}}</span>
                        <div class="progress push">
                            <div class="progress-bar bg-warning w-100" role="progressbar"
                                 [attr.aria-valuenow]="income" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-9">
                        <span class="small">Expenses</span>
                        <span class="float-right small">{{expenses | currency}}</span>
                        <div class="progress push">
                            <div class="progress-bar bg-success w-100" role="progressbar"
                                 [attr.aria-valuenow]="expenses" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
                <div class="button-panel mt-3">
                    <button class="blue-button ml-0" type="button" (click)="setActiveTab('profit_and_loss')">View more
                    </button>
                </div>

            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-6">
                        <span class="block-header">Balance Sheet</span>
                    </div>
                    <div class="col-6">
                        <pc-form-date-picker [formCtrl]="'balanceDateCtrl'"
                            [formGroup]="formGroup"
                            [type]="'SINGLE'"
                            [historical]="true"
                            [defaultToday]="true"
                            (dateSelected)="onBalanceDateChanged($event)">
                        </pc-form-date-picker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="data-block">
                            <div class="text-center h4 mt-3">{{assets | currency}}</div>
                            <div class="text-center small">Net Assets</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="data-block">
                            <div class="text-center h4 mt-3">{{equity | currency}}</div>
                            <div class="text-center small">Equity</div>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <span class="small">Debt to Assets</span>
                    <span class="float-right small">{{debtToAssets ? (debtToAssets + '%') : 'N/A'}}</span>
                    <div class="progress push">
                        <div class="progress-bar bg-secondary" role="progressbar" [style]="debtToAssets ? ('width: ' + debtToAssets + '%') : ''"
                             [attr.aria-valuenow]="debtToAssets" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-9">
                        <span class="small">Assets</span>
                        <span class="float-right small">{{assetsAll | currency}}</span>
                        <div class="progress push">
                            <div class="progress-bar bg-info w-100" role="progressbar"
                                 [attr.aria-valuenow]="assetsAll" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-9">
                        <span class="small">Liabilities</span>
                        <span class="float-right small">{{liabilities | currency}}</span>
                        <div class="progress push">
                            <div class="progress-bar bg-danger w-100" role="progressbar"
                                 [attr.aria-valuenow]="liabilities" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
                <div class="button-panel mt-3">
                    <button class="blue-button ml-0" type="button" (click)="setActiveTab('balance_sheet')">View more
                    </button>
                </div>

            </div>
        </div>
    </div>
    <div class="tab-body" *ngIf="financialTab === 'profit_and_loss' && isAccountingLinked">
        <pt-member-profit [memberAccountId]="memberId">
        </pt-member-profit>
    </div>
    <div class="tab-body" *ngIf="financialTab === 'balance_sheet' && isAccountingLinked">
        <pt-member-balance [memberAccountId]="memberId">
        </pt-member-balance>
    </div>
    <div class="tab-body" *ngIf="financialTab === 'kpi' && isAccountingLinked">
        <pt-member-kpi [memberAccountId]="memberId">
        </pt-member-kpi>
    </div>
    <div class="tab-body" *ngIf="financialTab === 'customers' && isAccountingLinked">
        <pt-member-customers [memberAccountId]="memberId">
        </pt-member-customers>
    </div>
    <div class="tab-body" *ngIf="financialTab === 'suppliers' && isAccountingLinked">
        <pt-member-suppliers [memberAccountId]="memberId">
        </pt-member-suppliers>
    </div>
</div>
