export class ReconciliationAccount {

    id: string;
    nominalCode: string;
    name: string;
    description: string;
    fullyQualifiedCategory: string;
    fullyQualifiedName: string;
    currency: string;
    currentBalance: number;
    type: string;
    status: string;
    isBankAccount: boolean;
}
