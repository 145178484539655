import { Component, OnInit } from '@angular/core';
import {PaymentService, ChartService, ChartType} from 'projects/services/src/public-api';

@Component({
    selector: 'pc-authority-assets-gadget',
    templateUrl: './authority-assets-gadget.component.html'
})
export class AuthorityAssetsGadgetComponent implements OnInit {

    ChartType = ChartType;
    tableView = false;
    period = 5;
    assets: Map<string, number>;
    labels: string[] = [];
    chartData: any[] = [];
    colorScheme = { domain: ['#239B56']};
    displayedColumns: string[] = ['date', 'assets'];

    constructor(private paymentsService: PaymentService,
                private chartService: ChartService) { }

    ngOnInit() {
        // default checked radio button
        this.paymentsService.getAssets(this.period).subscribe((response: any) => {
            this.assets = response;
            this.labels = Object.keys(response);
            this.chartData = this.chartService.getChartTransaction(response, this.labels);
        });
    }

    viewChanged(type: string) {
        this.tableView = (type === 'table');
    }

}
