import { ContractSection } from './contract-section';

export class ContractFramework {
    id: string;
    created: Date;
    name: string;
    description: string;
    processId: string;
    updated: Date;
    sections: ContractSection[];
    version: number;
    active: boolean;
    buyerClassification: ContractPartyClassification;
    sellerClassification: ContractPartyClassification;
}

export enum ContractPartyClassification {
    PRODUCER = 'PRODUCER',
    RETAILER = 'RETAILER',
    DISTRIBUTOR = 'DISTRIBUTOR'
}