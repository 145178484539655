<div class="inlineExpanded">
    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-4 title-bold-font">
                    Delivery Id
                </div>
                <div class="col-8">
                    {{shipment.deliveryId}}
                </div>
            </div>
            <div class="row">
                <div class="col-4 title-bold-font">
                    Recipient Facility License #
                </div>
                <div class="col-8">
                    {{shipment.recipientFacilityLicenseNumber}}
                </div>
            </div>
            <div class="row">
                <div class="col-4 title-bold-font">
                    Recipient Facility Name
                </div>
                <div class="col-8 ">
                    {{shipment.recipientFacilityName}}
                </div>
            </div>
            <div class="row">
                <div class="col-4 title-bold-font">
                    Shipper Facility License #
                </div>
                <div class="col-8 ">
                    {{shipment.shipperFacilityLicenseNumber}}
                </div>
            </div>
            <div class="row">
                <div class="col-4 title-bold-font">
                    Shipper Facility Name
                </div>
                <div class="col-8 ">
                    {{shipment.shipperFacilityName}}
                </div>
            </div>
            <div class="row">
                <div class="col-4 title-bold-font">
                    Transporter Name
                </div>
                <div class="col-8 ">
                    {{shipment.transporterName}}
                </div>
            </div>
            <div class="row">
                <div class="col-4 title-bold-font">
                    Transporter Tracking #
                </div>
                <div class="col-8 ">
                    {{shipment.transporterTrackingNumber}}
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-4 title-bold-font">
                    Actual Arrival Date
                </div>
                <div class="col-8 ">
                    {{shipment.actualArrivalDateTime ? (shipment.actualArrivalDateTime | date:'mediumDate') : ""}}
                </div>
            </div>
            <div class="row">
                <div class="col-4 title-bold-font">
                    Received Date
                </div>
                <div class="col-8 ">
                    {{shipment.receivedDateTime ? (shipment.receivedDateTime | date:'mediumDate') : ""}}
                </div>
            </div>
            <div class="row">
                <div class="col-4 title-bold-font">
                    Source
                </div>
                <div class="col-8 ">
                    {{shipment.source ? shipment.source : ""}}
                </div>
            </div>
            <div class="row">
                <div class="col-4 title-bold-font">
                    System Type
                </div>
                <div class="col-8 ">
                    {{ shipment.systemType | translate }}
                </div>
            </div>
        </div>
    </div>
    <hr/>
    <pc-member-metrc-package-list [packages]="packages"></pc-member-metrc-package-list>
</div>
