import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SmartContractsRoutingModule } from './smart-contracts-routing.module';
import { SharedComponentsModule } from 'projects/components/src/lib/shared-components.module';

import { HomeModule } from '../home/home.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SmartContractsMainComponent } from './components';
import { SharedSmartContractsComponentsModule } from 'projects/smart-contract-components/src/public-api';

@NgModule({
    imports: [
        CommonModule,
        SmartContractsRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        SharedComponentsModule,
        SharedSmartContractsComponentsModule,
        TranslateModule,
        HomeModule,
        NgbModule
    ],

    declarations: [
        SmartContractsMainComponent
    ],

    exports: [
        SmartContractsMainComponent
    ],

    providers: [
    ]
})
export class SmartContractsModule {
}
