import { AfterViewInit, ViewChild, SimpleChanges, OnDestroy, EventEmitter, Component, Input, OnInit, OnChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { RecordsService, PagedResponse, ProductBalance, ProductPackageDetails } from 'projects/services/src/public-api';
import { PageTracking } from 'projects/components/src/lib/table-utils.service';
import { catchError, map, merge, startWith, switchMap, of as observableOf } from 'rxjs';

@Component({
    selector: 'pc-member-metrc-trace-row-list',
    templateUrl: './member-metrc-trace-row-list.component.html',
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class MemberMetrcTraceRowListComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @Input() memberId: string;
    @Input() productBalance: ProductBalance;
    @Input() startDate: Date;
    @Input() endDate: Date;

    displayedColumns = ['packageId', 'packageLabel', 'productName', 'quantityUnits', 'quantityIn', 'quantityOut'];
    subscription: any;
    pageTracking: PageTracking;
    isLoadingResults = true;
    resultsLength = 0;
    expandedElement: ProductPackageDetails;

    refreshEvent: EventEmitter<null> = new EventEmitter<null>();
    productPackageDetails: ProductPackageDetails[];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private recordsService: RecordsService) { }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(change: SimpleChanges): void {
        if ((change.startDate && !change.startDate.firstChange) || (change.endDate && !change.endDate.firstChange)
            || (change.entityId && !change.entityId.firstChange) || (change.productBalance && !change.productBalance.firstChange)) {
            this.refreshEvent.emit();
        }
    }

    ngOnInit() {
        this.pageTracking = new PageTracking();
        this.pageTracking.page = 0;
        this.pageTracking.pageSize = 10;
        this.pageTracking.pageSizeOptions = [10, 25, 50, 100];
    }

    ngAfterViewInit() {
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.subscription = merge(this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                this.pageTracking.page = this.paginator.pageIndex;
                this.pageTracking.pageSize = this.paginator.pageSize;
                return this.recordsService.findProductPackageDetails(this.memberId, this.productBalance.productCategory, this.productBalance.quantityUnits, this.startDate, this.endDate, this.pageTracking.page, this.pageTracking.pageSize);
            }),
            map((response: PagedResponse<ProductPackageDetails>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((productPackageDetails: ProductPackageDetails[]) => {
            this.productPackageDetails = productPackageDetails;
        });
    }
}
