import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { MessageService, MessageFormService, Message, Utils, AuthService } from 'projects/services/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pc-new-message',
    templateUrl: './new-message.component.html'
})
export class NewMessageComponent implements OnInit {

    @Input() title = 'Contact Us for Support or Questions';

    newMessageForm: UntypedFormGroup;
    files: FileList;
    message: Message;

    constructor(private router: Router,
                private messageFormService: MessageFormService,
                private authService: AuthService,
                private messageService: MessageService,
                private notifier: NotificationService) {
    }

    ngOnInit() {
        this.formEnabled = this.formEnabled.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.message = new Message();
        this.message.recipientMemberName = 'Confia Customer Support';
        this.newMessageForm = this.messageFormService.initializeForm(this.message);
        this.newMessageForm.get('recipientCtrl').setValidators(null);
        this.newMessageForm.get('recipientNameCtrl').disable();

    }

    onSubmit(reset: any) {
        const message = this.messageFormService.getMessage(this.newMessageForm);
        const redirectUrl = this.authService.isRetailer() ? '/transaction/list' : '/dashboard';
        if (this.files && this.files.length > 0) {
            const file: File = this.files[0];
            this.messageService.sendMessage(file, file.name, message.subject, message.body, '', '', '').subscribe(() => {
                this.newMessageForm.reset();
                this.router.navigate([redirectUrl]);
                this.notifier.showSuccess('Thank you for contacting us. We will respond to your message within one business day.', 5000);
            }, (error: any) => {
                reset();
                this.notifier.showError('Document upload was unsuccessful. Please check your connection and try again.');
            });
        } else {
            // no attachment. Send the message as text
            this.messageService.sendMessageNoAttachment(message.subject, message.body, '', '', '').subscribe(() => {
                this.newMessageForm.reset();
                this.router.navigate([redirectUrl]);
                this.notifier.showSuccess('Thank you for contacting us. We will respond to your message within one business day.', 5000);
            });
        }
    }

    formEnabled() {
        return !this.newMessageForm.invalid;
    }

    selectFile(event: any) {
        for (const file of event.target.files) {
            Utils.validateFile(file, event);
        }
        this.files = event.target.files;
    }

}
