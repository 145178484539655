import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription, Observable, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { CurrencyPipe } from '@angular/common';
import { MemberAccount } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-simple-member-account-selector',
    templateUrl: './simple-member-account-selector.component.html',
    styleUrls: ['./simple-member-account-selector.component.scss']
})
export class SimpleMemberAccountSelectorComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() memberAccounts: MemberAccount[];
    @Input() required: boolean;
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() placeholder = '';

    @Output() memberAccountSelected: EventEmitter<any> = new EventEmitter<any>();

    autoSelect: Subscription;

    autoComplete: Observable<MemberAccount[]> = null;
    autoCompleteControl = new UntypedFormControl('');
    filteredOptions: MemberAccount[] = [];

    @ViewChild('autoSelect', {read: MatAutocompleteTrigger}) trigger: MatAutocompleteTrigger;

    constructor(private currencyPipe : CurrencyPipe) {
    }

    ngOnInit() {
        this.formGroup.addControl('autoCompleteCtrl', this.autoCompleteControl);
        if (this.required) {
            this.autoCompleteControl.setValidators(Validators.required);
        }

        if (this.formGroup.controls[this.formCtrl].disabled) {
            this.autoCompleteControl.disable();
        } else {
            this.autoComplete = this.autoCompleteControl.valueChanges.pipe(
                startWith(this.formGroup.controls[this.formCtrl].value),
                switchMap((value) => {
                    return this.filter(value || '');
                })
            );
        }
    }

    ngAfterViewInit() {
        this.subscribeToClosingActions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.memberAccounts && !changes.memberAccounts.firstChange) {
            this.filteredOptions = this.memberAccounts;
            if (this.formGroup.controls[this.formCtrl].disabled) {
                this.autoCompleteControl.setValue('');
                this.formGroup.controls[this.formCtrl].setValue(null);
                this.autoCompleteControl.disable();
            } else {
                this.autoCompleteControl.enable();
                this.autoComplete = this.autoCompleteControl.valueChanges.pipe(
                    startWith(this.formGroup.controls[this.formCtrl].value),
                    switchMap((value) => {
                        return this.filter(value || '');
                    })
                );
            }
        }
    }

    onFocus() {
        if (!this.formGroup.controls[this.formCtrl].value) {
            this.autoCompleteControl.setValue('');
        }
    }

    subscribeToClosingActions() {
        if (this.autoSelect && !this.autoSelect.closed) {
            this.autoSelect.unsubscribe();
        }

        this.autoSelect = this.trigger.panelClosingActions.subscribe((e) => {
            if (!e || !e.source) {
                const selectedOption = this.filteredOptions
                    .map((option) => {
                        return option.accountName;
                    })
                    .find((option) => {
                        return option === this.formGroup.controls[this.formCtrl].value;
                    });

                if (!selectedOption) {
                    this.formGroup.controls[this.formCtrl].markAsPristine();
                    this.formGroup.controls[this.formCtrl].setValue('');
                    this.autoCompleteControl.setValue('');
                    this.selectionChanged(new MemberAccount());
                }
            }
        }, () => {
            return this.subscribeToClosingActions();
        }, () => {
            return this.subscribeToClosingActions();
        });
    }

    filter(value: string): Observable<any[]> {
        const filterValue = value.toLowerCase();
        this.filteredOptions = this.memberAccounts.filter((option) => {
            return option.accountName.toLowerCase().indexOf(filterValue) >= 0;
        });
        return of(this.filteredOptions);
    }

    selectionChanged(memberAccount: MemberAccount, event?: any) {
        if (!event || event?.isUserInput) {
            this.formGroup.controls[this.formCtrl].setValue(memberAccount.id);
            this.setAccountName(memberAccount);
            this.memberAccountSelected.emit(memberAccount);
        }
    }

    isSelected() {
        return this.formGroup.controls[this.formCtrl].value;
    }

    isDisabled() {
        return this.formGroup.controls[this.formCtrl].disabled;
    }

    onIconClick(event: any) {
        if (this.isSelected()) {
            this.selectionChanged(new MemberAccount());
        }
        event.stopPropagation();
        this.trigger.openPanel();
    }

    setAccountName(memberAccount: MemberAccount) {
        if (memberAccount.id) {
            this.autoCompleteControl.setValue(`${memberAccount.accountName} (${this.currencyPipe.transform(memberAccount.wallet.availableBalance  || '0.00')})`);
        } else {
            this.autoCompleteControl.setValue('');
        }
    }

}
