import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SmartContractService, Contract, WorkflowService, Task } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-sc-confirm-receipt',
    templateUrl: './confirm-delivery.component.html',
    styleUrls: ['./confirm-delivery.component.scss']
})
export class ConfirmDeliveryComponent implements OnInit {

    task: Task;
    contractId: string;
    contract: Contract;

    constructor(private smartContractService: SmartContractService,
                private router: Router,
                private workflowService: WorkflowService) {
    }

    ngOnInit() {
        if (this.workflowService.getCurrentTask()) {
            this.task = this.workflowService.getCurrentTask();
            this.contractId = this.task.variables.contractId;
            this.smartContractService.getContractById(this.contractId).subscribe((contract: Contract) => {
                this.contract = contract;
            });
        }
    }

    onSubmit() {
        this.smartContractService.saveContract(this.contract).subscribe((contract: Contract) => {
            this.contract = contract;
            this.workflowService.completeTask(this.task.id, this.requestBody()).toPromise().then(() => {
                this.toMyContracts();
            });
        });
    }

    toMyContracts() {
        this.router.navigate(['/smart_contracts'], {queryParams: { _activeTab: 'my_contracts' }});
    }

    requestBody(): any {
        return {
            variables: {
                action: {
                    value: 'Order Delivered',
                    type: 'String'
                }
            }
        };
    }
}
