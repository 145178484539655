export class LendingFinancingRequest {
    id?: string;
    created?: Date;
    companyType: string;
    lenderId: string;
    memberId: string;
    loanId?: string;
    requestAmount: number;
    requestTerm: number;
    revenue: number;
    arTotal: number;
    note: string;
    ebitda: number;
}
