import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Address, CustomValidators, MemberType } from 'projects/services/src/public-api';

@Injectable({
    providedIn: 'root'
})
export class AddressFormService {

    constructor(private formbuilder: UntypedFormBuilder) {
    }

    initializeForm(address: Address, memberType = MemberType.BUSINESS): UntypedFormGroup {
        const form = this.formbuilder.group({
            idCtrl: new UntypedFormControl(),
            createdCtrl: new UntypedFormControl(),
            updatedCtrl: new UntypedFormControl(),
            streetAddressOneCtrl: new UntypedFormControl('', memberType === MemberType.INDIVIDUAL_MERCHANT ? [Validators.minLength(3), CustomValidators.noLeadingOrTrailingWhitespace] : [Validators.required, Validators.minLength(3), CustomValidators.noLeadingOrTrailingWhitespace]),
            cityCtrl: new UntypedFormControl('', memberType === MemberType.INDIVIDUAL_MERCHANT ? [CustomValidators.noLeadingOrTrailingWhitespace] : [Validators.required, CustomValidators.noLeadingOrTrailingWhitespace]),
            stateProvinceCtrl: new UntypedFormControl('', memberType === MemberType.INDIVIDUAL_MERCHANT ? [Validators.minLength(2)] : [Validators.required, Validators.minLength(2)]),
            countryCtrl: new UntypedFormControl('', memberType === MemberType.INDIVIDUAL_MERCHANT ? [] : [Validators.required]),
            zipPostalCodeCtrl: new UntypedFormControl('', memberType === MemberType.INDIVIDUAL_MERCHANT ? [] : [Validators.required]),
            phoneNumberCtrl: new UntypedFormControl(null, [CustomValidators.phone]),
            typeCtrl: new UntypedFormControl(''),
            nicknameCtrl: new UntypedFormControl('', memberType === MemberType.INDIVIDUAL_MERCHANT ? [] : [Validators.maxLength(128), CustomValidators.noLeadingOrTrailingWhitespace]),
            accountIdCtrl: new UntypedFormControl('')
        });
        if (address) {
            form.patchValue({
                idCtrl: address.id,
                createdCtrl: address.created,
                updatedCtrl: address.updated,
                streetAddressOneCtrl: address.streetAddressOne,
                cityCtrl: address.city,
                stateProvinceCtrl: address.stateProvince,
                countryCtrl: address.country,
                zipPostalCodeCtrl: address.zipPostalCode,
                phoneNumberCtrl: address.phoneNumber,
                typeCtrl: address.type || ''
            });
        } else {
            form.controls['countryCtrl'].setValue('United States');
            form.controls['countryCtrl'].markAsDirty();
        }
        form.updateValueAndValidity();
        return form;
    }

    getAddress(form: UntypedFormGroup): Address {
        const address = new Address();
        address.id = form.controls['idCtrl'].value;
        address.created = form.controls['createdCtrl'].value;
        address.updated = form.controls['updatedCtrl'].value;
        address.streetAddressOne = form.controls['streetAddressOneCtrl'].value;
        address.city = form.controls['cityCtrl'].value;
        address.stateProvince = form.controls['stateProvinceCtrl'].value;
        address.country = form.controls['countryCtrl'].value;
        address.zipPostalCode = form.controls['zipPostalCodeCtrl'].value;
        address.phoneNumber = form.controls['phoneNumberCtrl'].value;
        address.type = form.controls['typeCtrl'].value || null;
        return address;
    }
}
