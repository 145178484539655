<div>
    <div class="col-12">
        <div class="horizontal-scroll mat-table-container">
            <div class="mat-table-spinner" *ngIf="isLoadingResults">
                <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
            <mat-paginator #paginator [pageIndex]="paginator.pageIndex" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
            <table class="no-wrap table-sm" mat-table [dataSource]="transactions" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
                <ng-container matColumnDef="transactionType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Transaction Type </th>
                    <td mat-cell *matCellDef="let element">
                          {{ element.transactionType | translate }}
                        <span *ngIf="element.reasonCode"> ({{ element.reasonCode | translate }}) </span>
                        <span *ngIf="element.apiOwnerId"> (Partner) </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="payor">
                    <th mat-header-cell *matHeaderCellDef> Payor </th>
                    <td mat-cell *matCellDef="let element">
                        <a *ngIf="!readOnly && element?.payorMemberId" (click)="navigateToMember(element.payorMemberId)">  {{ element.payorMemberName }} </a>
                        <span *ngIf="!readOnly && element?.relatedPartyMemberId">
                            <a *ngIf="element.relatedPartyMemberId" (click)="navigateToMember(element.relatedPartyMemberId)"> {{ element.relatedPartyMemberName }} </a>
                            <a *ngIf="element.payorMemberId" (click)="navigateToMember(element.payorMemberId)">  ({{ element.payorMemberName }}) </a>
                        </span>
                        <span *ngIf="readOnly || (element.payorMemberName && !element.payorMemberId && !element.relatedPartyMemberId)"> {{element.payorMemberName}} </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="recipient">
                    <th mat-header-cell *matHeaderCellDef> Recipient </th>
                    <td mat-cell *matCellDef="let element">
                        <a *ngIf="!readOnly && element?.recipientMemberId" (click)="navigateToMember(element.recipientMemberId)"> {{ element.recipientMemberName }} </a>
                        <span *ngIf="readOnly || (element.recipientMemberName && !element.recipientMemberId)"> {{element.recipientMemberName}} </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="account">
                    <th mat-header-cell *matHeaderCellDef> Account </th>
                    <td mat-cell *matCellDef="let element">
                        <a class="dashboard-list-a" [routerLink]="['/member-accounts/details/' + getAccountId(element)]" [routerLinkActive]="['router-link-active']">
                            {{getAccountName(element)}}
                        </a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell class="centered" mat-sort-header *matHeaderCellDef> Status </th>
                    <td mat-cell  class="centered" *matCellDef="let element">
                        <pc-transaction-badge [parent]="element"></pc-transaction-badge>
                    </td>
                </ng-container>
                <ng-container matColumnDef="transaction_date">
                    <th mat-header-cell class="centered" mat-sort-header="transactionDate" *matHeaderCellDef> Date Initiated </th>
                    <td mat-cell class="centered" *matCellDef="let element">
                        <span *ngIf="element.transactionDate">
                            <pc-date-time [date]="element.transactionDate"></pc-date-time>
                        </span>
                        <span *ngIf="!element.transactionDate"> Pending </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="completion_date">
                    <th mat-header-cell class="centered"  mat-sort-header="completionDate" *matHeaderCellDef> Date Completed </th>
                    <td mat-cell  class="centered" *matCellDef="let element">
                        <span *ngIf="element.completionDate">
                            <pc-date-time [date]="element.completionDate"></pc-date-time>
                        </span>
                        <span *ngIf="!element.completionDate"> Pending </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="partner_location">
                    <th mat-header-cell *matHeaderCellDef> Location </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.transactionLocation"> {{ element.transactionLocation.retailLocationName }} </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="pt_score">
                    <th mat-header-cell class="centered"  *matHeaderCellDef> Risk Score </th>
                    <td mat-cell  class="centered" *matCellDef="let element">
                        {{ element.pointTrustScore | number: '1.0-1' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="amount">
                    <th mat-header-cell class="centered" mat-sort-header="totalAmount" *matHeaderCellDef> Amount </th>
                    <td mat-cell class="centered" *matCellDef="let element">
                        <span class="table-amount">
                            <pc-transaction-amount [transaction]="element" [memberId]="filter.memberId" [memberAccountId]="filter.memberAccountId"></pc-transaction-amount>
                        </span>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="pc-click-row" (click)="loadTransactionDetails(row, $event)"></tr>
            </table>
            <mat-paginator (page)="handlePageBottom($event)" [pageIndex]="paginator.pageIndex" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
        </div>
    </div>
</div>
