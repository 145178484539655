import { Component, Input, OnInit } from '@angular/core';
import { BusinessClient, Invoice, TransactionType } from 'projects/services/src/public-api';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'pt-deposit-method',
    templateUrl: './deposit-method.component.html'
})
export class DepositMethodComponent implements OnInit {

    @Input() businessClients: BusinessClient<Invoice>[] = [];
    @Input() formGroup: UntypedFormGroup;
    @Input() depositMethods: { id: TransactionType, name: string }[];

    constructor() {}

    ngOnInit() {
        if (this.formGroup.controls['transactionTypeCtrl'].value !== TransactionType.CASH_DEPOSIT && this.businessClients.length === 1) {
            let transactionType = this.businessClients[0].defaultTransactionType;
            if (transactionType === TransactionType.INCOMING_CHECK_FROM_MERCHANT) {
                // We shouldn't allow check deposit from UI
                // If the default transaction type for merchant is check deposit, we are updating it to be ach deposit
                transactionType = TransactionType.INCOMING_ACH_FROM_MERCHANT;
            }
            this.formGroup.controls['transactionTypeCtrl'].setValue(transactionType);
        }
        this.formGroup.get('transactionDateCtrl').disable();
        this.formGroup.get('memberAccountNameCtrl').disable();
    }

}
