import { Bill } from './bill';
import { Invoice } from './invoice';
import { SupportingDocument } from './supporting-document';
import { MemberAccount } from './member-account';
import { Member } from './member';
import { BankingIntegrationProvider } from './bank-integration-provider';

export class Transaction {
    id: string;
    status: TransactionStatus;
    submissionReference: string;

    payorMemberId: string;
    payorMemberName: string;
    payorAccountId: string;

    recipientMemberId: string;
    recipientMemberName: string;
    recipientAccountId: string;

    checkId: string;
    smartContractId: string;
    bankAccountId: string;

    relatedTransactionId: string;
    remittanceMemo: string;
    remittanceFile: SupportingDocument;
    cancellationMemo: string;
    transactionDate: Date;
    pointTrustScore: number;
    lineItems: LineItem[];
    expenses: Bill[];
    incomes: Invoice[];
    totalAmount: number;
    pendingAmount: number;
    transactionType: TransactionType;
    subType: TransactionSubType;
    state : TransactionState;
    notes: string;
    otherDescription: string;
    source: TransactionSource;

    scores: TransactionScore[] = [];
    manifestSupportingDocuments: SupportingDocument[] = [];
    supportingDocuments: SupportingDocument[] = [];
    relatedTransactions: Transaction[] = [];
    shipmentIds: string[] = [];
    apiOwnerId: string;
    apiOwnerName: string;
    relatedPartyMemberId: string;
    relatedPartyMemberName: string;
    relatedPartyAccountId: string;
    originatorId: string;
    originatorAccountId: string;
    completionDate : Date;
    reasonCode: string;
    paymentDateType: string;
    transactionLocation: TransactionLocation;

    // adhoc fields
    checkNumber?: string;

    payorMember: Member;
    payorAccount: MemberAccount;

    recipientMember: Member;
    recipientAccount: MemberAccount;

    bankProvider: BankingIntegrationProvider;
    legacyPayorMemberId: string;
    legacyRecipientMemberId: string;
}

export class TransactionScore {

    pointTrustScore: number;
    pointChainReasonCodes: any[] = [];
    otherDocumentationRequired: any[] = [];

}

export class LineItem {

    id: string;
    transactionEntryId: string;
    allocatedAmount: number;
    type: string;
    description: string;
    created: Date;
    externalEntityId: string;
    memberName: string;
    memberAccountId: string;
    supportingDocuments: SupportingDocument[] = [];

}

export class TransactionLocation {
    id: string;
    retailLocationId: string;
    retailLocationName: string;
}

export class RemittanceInfo {
    remittanceMemo: string;
    remittanceFile: SupportingDocument;
}

export enum TransactionType {
    ALL = '',
    CASH_DEPOSIT = 'CASH_DEPOSIT',
    CHECK_DEPOSIT = 'CHECK_DEPOSIT',
    ACH_DEPOSIT = 'ACH_DEPOSIT',
    CHECK_WITHDRAWAL = 'CHECK_WITHDRAWAL',
    ACH_WITHDRAWAL = 'ACH_WITHDRAWAL',
    CURRENCY_WITHDRAWAL = 'CURRENCY_WITHDRAWAL',
    INCOMING_WIRE_FROM_MERCHANT = 'INCOMING_WIRE_FROM_MERCHANT',
    INCOMING_ACH_FROM_MERCHANT = 'INCOMING_ACH_FROM_MERCHANT',
    INCOMING_CHECK_FROM_MERCHANT = 'INCOMING_CHECK_FROM_MERCHANT',
    WALLET_TO_WALLET_PAYMENT = 'WALLET_TO_WALLET_PAYMENT',
    WALLET_TO_ACH_PAYMENT = 'WALLET_TO_ACH_PAYMENT',
    ACH_TO_WALLET_PAYMENT = 'ACH_TO_WALLET_PAYMENT',
    SMART_CONTRACT_PAYMENT = 'SMART_CONTRACT_PAYMENT',
    EXTERNAL_AUTHORIZED_ACH_TO_MERCHANT = 'EXTERNAL_AUTHORIZED_ACH_TO_MERCHANT',
    OUTGOING_WIRE_TO_MERCHANT = 'OUTGOING_WIRE_TO_MERCHANT',
    OUTGOING_ACH_TO_MERCHANT = 'OUTGOING_ACH_TO_MERCHANT',
    WALLET_TO_EXPRESS_ACH_PAYMENT = 'WALLET_TO_EXPRESS_ACH_PAYMENT',
    OUTGOING_CHECK_TO_MERCHANT = 'OUTGOING_CHECK_TO_MERCHANT',
    OUTGOING_DIGITAL_CHECK_TO_MERCHANT = 'OUTGOING_DIGITAL_CHECK_TO_MERCHANT',
    WALLET_REFUND = 'WALLET_REFUND',
    ACH_REFUND = 'ACH_REFUND',
    BNPL_CONSUMER_REFUND = 'BNPL_CONSUMER_REFUND',
    WALLET_TO_ACH_REFUND = 'WALLET_TO_ACH_REFUND',
    ACH_WITHDRAWAL_FEE = 'ACH_WITHDRAWAL_FEE',
    ACH_EXPRESS_PAYMENT_FEE = 'ACH_EXPRESS_PAYMENT_FEE',
    STOP_PAYMENT_FEE = 'STOP_PAYMENT_FEE',
    INCOMING_WIRE_FEE = 'INCOMING_WIRE_FEE',
    OUTGOING_WIRE_FEE = 'OUTGOING_WIRE_FEE',
    CONSUMER_PAYMENT_FEE = 'CONSUMER_PAYMENT_FEE',
    DEPOSIT_FEE = 'DEPOSIT_FEE',
    ACCOUNT_FEE = 'ACCOUNT_FEE',
    TRANSACTION_FEE = 'TRANSACTION_FEE',
    CONSUMER_CONVENIENCE_FEE = 'CONSUMER_CONVENIENCE_FEE',
    DEPOSIT_ADJUSTMENT_DEDUCTION = 'DEPOSIT_ADJUSTMENT_DEDUCTION',
    DEPOSIT_ADJUSTMENT_ADDITION = 'DEPOSIT_ADJUSTMENT_ADDITION',
    TRANSACTION_CREDIT = 'TRANSACTION_CREDIT',
    ACCOUNT_CREDIT = 'ACCOUNT_CREDIT',
    BNPL_PAYMENT = 'BNPL_PAYMENT',
    OVERDRAFT_FEE = 'OVERDRAFT_FEE'
}

export enum TransactionStatus {
    COMPLETED = 'COMPLETED',
    SCHEDULED = 'SCHEDULED',
    PENDING_TRANSFER = 'PENDING_TRANSFER',
    PENDING_SCORING = 'PENDING_SCORING',
    PENDING_BALANCE_VERIFICATION = 'PENDING_BALANCE_VERIFICATION',
    PENDING_DEPOSIT_VERIFICATION = 'PENDING_DEPOSIT_VERIFICATION',
    PENDING_PAYMENT_VERIFICATION = 'PENDING_PAYMENT_VERIFICATION',
    PENDING_RECEIPT_VERIFICATION = 'PENDING_RECEIPT_VERIFICATION',
    PENDING_CONSUMER_VERIFICATION = 'PENDING_CONSUMER_VERIFICATION',
    PENDING_RFI_AUTHORITY_REVIEW = 'PENDING_RFI_AUTHORITY_REVIEW',
    PENDING_RFI_DUE_DILIGENCE = 'PENDING_RFI_DUE_DILIGENCE',
    PENDING_RFI_WITH_FUNDS ='PENDING_RFI_WITH_FUNDS',
    PENDING_DELIVERY = 'PENDING_DELIVERY',
    PENDING_RETURN = 'PENDING_RETURN',
    PENDING_ACH_CALLBACK = 'PENDING_ACH_CALLBACK',
    PENDING_WATCHLIST_REVIEW = 'PENDING_WATCHLIST_REVIEW',
    PENDING_WATCHLIST_SCREENING = 'PENDING_WATCHLIST_SCREENING',
    CANCELLED_INSUFFICIENT_FUNDS = 'CANCELLED_INSUFFICIENT_FUNDS',
    CANCELLED_BY_CLIENT = 'CANCELLED_BY_CLIENT',
    CANCELLED_BY_AUTHORITY = 'CANCELLED_BY_AUTHORITY',
    CANCELLED_BY_SYSTEM = 'CANCELLED_BY_SYSTEM',
    CANCELLED_BY_REFUND = 'CANCELLED_BY_REFUND'
}

export enum TransactionSource {
    RETAIL_TRANSACTION = 'RETAIL_TRANSACTION',
    NETWORK_TRANSACTION = 'NETWORK_TRANSACTION',
    EXTERNAL_TRANSACTION = 'EXTERNAL_TRANSACTION',
    SYSTEM_TRANSACTION = 'SYSTEM_TRANSACTION'
}

export enum TransactionState {
    ALL = '',
    COMPLETED = 'COMPLETED',
    SCHEDULED = 'SCHEDULED',
    PENDING = 'PENDING',
    CANCELLED = 'CANCELLED'
}

export enum TransactionSubType {
    WITHDRAWAL = 'WITHDRAWAL',
    DEPOSIT = 'DEPOSIT',
    PAYMENT = 'PAYMENT',
    FEE = 'FEE',
    CREDIT = 'CREDIT',
    ADJUSTMENT = 'ADJUSTMENT',
    REFUND = 'REFUND'
}

export enum SupportedTransactionType {
    NONE = '',
    INCOMING = 'INCOMING',
    OUTGOING = 'OUTGOING',
    BOTH = 'BOTH',
    EXTERNAL_OUTGOING = 'EXTERNAL_OUTGOING'
}

export enum PostedTransactionType {
    CREDIT = 'CREDIT',
    DEBIT = 'DEBIT',
    TRANSFER = 'TRANSFER'
}
