import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SalesOrder } from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pc-sc-shipping-modal',
    templateUrl: './shipping-modal.component.html'
})
export class ShippingModalComponent extends BaseModalComponent<ShippingModalComponent> implements OnInit {

    purchaseOrder: SalesOrder;
    shippingForm: UntypedFormGroup;

    constructor(private formbuilder: UntypedFormBuilder,
                protected dialogRef: MatDialogRef<ShippingModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.purchaseOrder = data.purchaseOrder;
    }

    ngOnInit() {
        this.isShippingFormValid = this.isShippingFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.shippingForm = this.formbuilder.group(
            {
                shippingCtrl: new UntypedFormControl(this.purchaseOrder.shippingCost, [Validators.required])
            });
    }

    onSubmit() {
        this.purchaseOrder.shippingCost = this.shippingForm.get('shippingCtrl').value;
        this.close(this.purchaseOrder);
    }

    isShippingFormValid() {
        return this.shippingForm && this.shippingForm.valid;
    }

    close(order?: SalesOrder) {
        super.close(order);
    }
}
