import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldDataType, FieldMetadata } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-sc-field',
    templateUrl: './field.component.html'
})
export class FieldComponent {

    FieldDataType = FieldDataType;

    @Input() enforceRequired = true;
    @Input() metadata: FieldMetadata;
    @Input() formGroup: UntypedFormGroup;

    @Output() changed: EventEmitter<FieldMetadata> = new EventEmitter<FieldMetadata>();

    onChanged(field: FieldMetadata) {
        this.metadata = field;
        this.changed.emit(this.metadata);
    }
}
