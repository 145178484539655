import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService, CustomValidators } from 'projects/services/src/public-api';
import { BaseModalComponent } from '../base-modal.component';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pc-change-password-modal',
    templateUrl: './change-password-modal.component.html',
    styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent extends BaseModalComponent<ChangePasswordModalComponent> implements OnInit {

    changePasswordForm: UntypedFormGroup;
    error: string;

    constructor(private formbuilder: UntypedFormBuilder,
                private authService: AuthService,
                private notifier: NotificationService,
                dialogRef: MatDialogRef<ChangePasswordModalComponent>) {
        super(dialogRef);
    }

    ngOnInit() {
        this.onChangePassword = this.onChangePassword.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.changePasswordForm = this.formbuilder.group({
            oldPasswordCtrl: new UntypedFormControl('', [Validators.required]),
            passwordCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(8), CustomValidators.password]),
            passwordConfirmCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(8), CustomValidators.password])
        });
    }

    onChangePassword(reset: any) {
        // validate that the old password is correct
        this.error = null;
        const passwordDetails: any = {};
        passwordDetails.oldPassword = this.changePasswordForm.controls['oldPasswordCtrl'].value;
        passwordDetails.newPassword = this.changePasswordForm.controls['passwordCtrl'].value;
        const confirmPassword = this.changePasswordForm.controls['passwordConfirmCtrl'].value;

        if (passwordDetails.newPassword && passwordDetails.newPassword === confirmPassword) {
            this.authService.updatePassword(passwordDetails).subscribe(() => {
                this.close();
                this.notifier.showSuccess('Your password has been successfully updated.');
            }, () => {
                this.error = 'Unable to update password';
                reset();
            });
        } else {
            this.error = 'Your new password must match the confirmation password';
            reset();
        }
    }

    passwordMismatch() {
        return this.changePasswordForm.get('passwordCtrl').value !== this.changePasswordForm.get('passwordConfirmCtrl').value;
    }

    validUppercase() {
        return CustomValidators.UPPERCASE_REGEX.test(this.changePasswordForm.get('passwordCtrl').value);
    }

    validLowercase() {
        return CustomValidators.LOWERCASE_REGEX.test(this.changePasswordForm.get('passwordCtrl').value);
    }

    validNumber() {
        return CustomValidators.NUMBER_REGEX.test(this.changePasswordForm.get('passwordCtrl').value);
    }

    validSpecial() {
        return CustomValidators.SPECIAL_REGEX.test(this.changePasswordForm.get('passwordCtrl').value);
    }

    isFormValid() {
        return this.changePasswordForm &&
        this.changePasswordForm.valid &&
        !this.passwordMismatch() &&
        this.validUppercase() &&
        this.validLowercase() &&
        this.validNumber() &&
        this.validSpecial();
    }

    close(message?: string) {
        super.close(message);
    }
}
