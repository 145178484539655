import { DatePipe } from '@angular/common';
import { IMyDateModel } from 'angular-mydatepicker';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
    Address, AddressFormService, AddressOccupant, AddressService, AddressType, User, UserFormService, IndividualKeyParty, UserService, KeyParty,
    KeyPartyFormService, KeyPartyService, KeyPartyStatus, KeyPartyType, OnboardingTrackingService, CustomValidators
} from 'projects/services/src/public-api';
import { ConfirmModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pt-administrator-details',
    templateUrl: './administrator-details.component.html',
    styleUrls: ['./administrator-details.component.scss']
})
export class AdministratorDetailsComponent implements OnInit {

    AddressType = AddressType;

    @Input() userId: string;
    @Input() memberId: string;
    @Input() task: any;
    @Input() changeTrigger: number;
    @Input() readOnly = false;

    @Output() next: EventEmitter<string> = new EventEmitter<string>();

    addressForm: UntypedFormGroup;
    userForm: UntypedFormGroup;
    keyPartyForm: UntypedFormGroup;
    isOwner = false;
    displayDefaultEmptyDate = true;
    isUsCitizen = true;
    passportExpirationDate: any;
    adminKeyParty: IndividualKeyParty;

    constructor(private userService: UserService,
                private userFormService: UserFormService,
                private addressService: AddressService,
                private addressFormService: AddressFormService,
                private keyPartyService: KeyPartyService,
                private datePipe: DatePipe,
                private keyPartyFormService: KeyPartyFormService,
                private dialog: MatDialog) {}

    ngOnInit() {
        this.isAdminDetailsValid = this.isAdminDetailsValid.bind(this);
        this.initUser = this.initUser.bind(this);
        this.initLocation = this.initLocation.bind(this);
        this.onNavigate = this.onNavigate.bind(this);
        this.onSave = this.onSave.bind(this);
        this.initForms();
    }

    initForms(callback?: any) {
        this.userService.loadUser(this.userId).subscribe((user: User) => {
            this.initUser(user);
            this.keyPartyService.getKeyPartyByMemberIdAndUserId(this.memberId, this.userId).subscribe((keyParty: KeyParty) => {
                if (keyParty) {
                    this.isOwner = keyParty.percentOwnership > 0;
                    this.adminKeyParty = keyParty.individualKeyParty;
                    this.keyPartyForm = this.keyPartyFormService.initializeKeyPartyForm(keyParty.type, keyParty.status, keyParty);
                } else {
                    this.adminKeyParty = new IndividualKeyParty();
                    this.keyPartyForm = this.keyPartyFormService.initializeKeyPartyForm(KeyPartyType.INDIVIDUAL, KeyPartyStatus.ACTIVE, null);
                }
                if (this.readOnly) {
                    this.keyPartyForm.disable();
                }
                this.keyPartyForm.markAsPristine();
                this.keyPartyForm.valueChanges.subscribe(() => {
                    this.updateValidity();
                });
                this.addressService.getPrimaryAddress(user.id).subscribe((address: Address) => {
                    this.initLocation(address, callback);
                });
            });
        });
    }

    initUser(user: User) {
        this.userForm = this.userFormService.initializeForm(user);
        if (user.passportExpirationDate) {
            this.displayDefaultEmptyDate = false;
            this.passportExpirationDate = user.passportExpirationDate;
        }
        if (this.readOnly) {
            this.userForm.disable();
        }
        this.userForm.get('firstNameCtrl').disable();
        this.userForm.get('middleNameCtrl').disable();
        this.userForm.get('lastNameCtrl').disable();
        this.userForm.get('mobilePhoneCtrl').disable();
        this.userForm.get('emailCtrl').disable();

        this.userForm.markAsPristine();
        this.userForm.valueChanges.subscribe(() => {
            this.updateValidity();
        });
    }

    initLocation(address: Address, callback: any) {
        this.addressForm = this.addressFormService.initializeForm(address);
        if (this.readOnly) {
            this.addressForm.disable();
        }
        this.addressForm.markAsPristine();
        this.onOwnershipChange();
        this.onCitizenshipChange();
        if (callback) {
            callback();
        }
        this.addressForm.valueChanges.subscribe(() => {
            this.updateValidity();
        });
    }

    onNavigate(reset: any) {
        if (this.isAdminDetailsValid()) {
            const dialogConfig: MatDialogConfig = {};
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'normal-modal';
            dialogConfig.disableClose = true;

            dialogConfig.data = {
                title: 'You have unsaved changes.',
                description: 'Click Save to save your changes and continue, or click Ignore to continue without saving.',
                confirmText: 'Save',
                confirmAction: 'save',
                rejectText: 'Ignore',
                rejectAction: 'ignore'
            };

            const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

            dialog?.afterClosed().subscribe(
                (confirmAction: any) => {
                    if (confirmAction === 'save') {
                        this.updateInformation(() => {
                            reset();
                            this.next.emit();
                        });
                    } else if (confirmAction === 'ignore') {
                        this.initForms(() => {
                            reset();
                            this.next.emit();
                        });
                    } else {
                        reset();
                    }
                }
            );
        } else {
            reset();
            this.next.emit();
        }
    }

    onSave(reset?: any) {
        this.updateInformation(reset);
    }

    updateInformation(callback?: any) {
        const userToSave = this.userFormService.getUser(this.userForm);
        userToSave.passportExpirationDate = this.passportExpirationDate;
        this.userService.saveUser(userToSave).subscribe(
            (user: User) => {
                const address = this.addressFormService.getAddress(this.addressForm);
                this.addressService.linkAddressToOccupant(address, user.id, this.memberId).subscribe((addressOccupant: AddressOccupant) => {
                    this.isOwner = this.keyPartyForm.get('ownerCtrl').value;
                    if (this.isOwner ||
                        this.keyPartyForm.get('isDirectorCtrl').value ||
                        this.keyPartyForm.get('isOfficerCtrl').value) {
                        this.adminKeyParty.firstName = user.firstName;
                        this.adminKeyParty.middleName = user.middleName;
                        this.adminKeyParty.lastName = user.lastName;
                        this.adminKeyParty.otherSurnameOrAlias = user.otherSurnameOrAlias;
                        this.adminKeyParty.birthDate = user.birthDate;
                        this.adminKeyParty.email = user.email;
                        this.adminKeyParty.phone = user.mobilePhone;
                        this.adminKeyParty.nationality = user.nationality;
                        this.adminKeyParty.passportNumber = user.passportNumber;
                        this.adminKeyParty.passportExpirationDate = user.passportExpirationDate;
                        this.adminKeyParty.taxId = user.taxId;
                        this.adminKeyParty.usCitizen = user.usCitizen;
                        this.adminKeyParty.address = addressOccupant.address;

                        this.keyPartyService.saveIndividualKeyParty(this.adminKeyParty).subscribe((individualKeyParty: IndividualKeyParty) => {
                            const keyParty = this.keyPartyFormService.getKeyParty(this.keyPartyForm);
                            keyParty.memberId = this.memberId;
                            keyParty.individualKeyPartyId = individualKeyParty.id;
                            keyParty.employer = user.employer;
                            keyParty.professionalTitle = user.professionalTitle;
                            keyParty.userId = user.id;
                            this.keyPartyService.saveKeyParty(keyParty).subscribe(
                                () => {
                                    this.initForms(callback);
                                }
                            );
                        });
                    } else {
                        this.initForms(callback);
                    }
                });
            }
        );
    }

    onDateChanged(event: IMyDateModel) {
        if (event && event.singleDate) {
            this.passportExpirationDate = this.datePipe.transform(event?.singleDate?.jsDate, 'yyyy-MM-dd');
        } else {
            this.passportExpirationDate = '';
        }
    }

    onCitizenshipChange() {
        this.isUsCitizen = this.userForm.get('usCitizenCtrl').value;
        if (this.isUsCitizen) {
            this.userForm.get('taxIdCtrl').setValidators([Validators.required, Validators.pattern('^([0-9]{9})$')]);
            this.userForm.get('citizenshipCtrl').setValidators(null);
            this.userForm.get('passportCtrl').setValidators(null);
            this.userForm.get('passportExpDateCtrl').setValidators(null);
        } else {
            this.userForm.get('taxIdCtrl').setValidators(null);
            this.userForm.get('citizenshipCtrl').setValidators([Validators.required]);
            this.userForm.get('passportCtrl').setValidators([Validators.required]);
            this.userForm.get('passportExpDateCtrl').setValidators([Validators.required]);
        }
        this.userForm.get('taxIdCtrl').updateValueAndValidity();
        this.userForm.get('citizenshipCtrl').updateValueAndValidity();
        this.userForm.get('passportCtrl').updateValueAndValidity();
        this.userForm.get('passportExpDateCtrl').updateValueAndValidity();

    }

    onOwnershipChange() {
        this.isOwner = this.keyPartyForm.get('ownerCtrl').value;
        if (this.isOwner) {
            this.keyPartyForm.get('percentOwnershipCtrl').setValidators([Validators.required, Validators.min(1), Validators.max(100), CustomValidators.onlyNumeric]);

        } else {
            this.keyPartyForm.get('percentOwnershipCtrl').setValidators(null);
            this.keyPartyForm.patchValue({percentOwnershipCtrl: ''});
        }
        this.keyPartyForm.get('percentOwnershipCtrl').updateValueAndValidity();
    }

    updateValidity() {
        OnboardingTrackingService.setAdminInfo(this.userFormService.getUser(this.userForm),
            this.addressFormService.getAddress(this.addressForm),
            this.keyPartyFormService.getKeyParty(this.keyPartyForm));
        OnboardingTrackingService.setAdminInfoDirty(this.userForm.dirty || this.addressForm.dirty || this.keyPartyForm.dirty);
    }

    isAdminDetailsValid() {
        const formsValid = this.userForm && this.userForm.valid
            && this.addressForm && this.addressForm.valid
            && (!this.isOwner || (this.isOwner && this.keyPartyForm && this.keyPartyForm.valid));
        return formsValid && OnboardingTrackingService.isAdminInfoValid() && OnboardingTrackingService.isAdminInfoDirty();
    }

    isBackgroundInfoChecked() {
        return this.keyPartyForm.get('ownerCtrl').value || this.keyPartyForm.get('isDirectorCtrl').value || this.keyPartyForm.get('isOfficerCtrl').value;
    }
}
