import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AuthService, CommentService, PagedResponse, Comment, CommentType } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-notes',
    templateUrl: './notes.component.html'
})
export class NotesComponent implements OnInit, OnChanges {

    @Input() memberId: string;
    @Input() userId: string;
    @Input() changeTrigger: number;

    noteGroup: Comment[];

    constructor(private authService: AuthService,
                private commentService: CommentService) {
    }

    ngOnInit() {
        this.refresh();
    }

    ngOnChanges() {
        this.refresh();
    }

    refresh() {
        this.noteGroup = [];
        this.commentService.loadComments(this.memberId, CommentType.USER, this.userId).subscribe((response: PagedResponse<Comment>) => {
            this.noteGroup = response.content;
            for (const note of this.noteGroup) {
                this.loadNoteDetails(note);
            }
        });
    }

    loadNoteDetails(note: any) {
        if (note) {
            // check if editable
            note.editable = note.createdByUser === this.authService.getProfile().userId;
            // get author
        }
    }

}
