<div *ngIf="viewTerms" class="interstitial" [class.view-terms]="viewTerms">
    <div *ngIf="getTransactionType() === TransactionType.INCOMING_WIRE_FROM_MERCHANT">
        <p>
            By clicking 'Accept Terms' I hereby represent and warrant that I am an Authorized Official authorized
            to request this Wire Deposit. I, and on behalf of the Member, hereby certify that this Incoming Wire Request
            is made pursuant to the terms and conditions of the Member Agreement, as may be amended, governing
            my Account. I, and on behalf of the Member, further certify and guarantee that:
        </p>
        <ul class="top-ul">
            <li>
                the funds, individually or in the aggregate, are proceeds obtained from lawful sources and lawful business
                operations and are not linked and/or derived from criminal origin, of whatsoever nature, and in particular
                do not constitute proceeds of money laundering or terrorist financing,
            </li>
            <li>
                the receipt of such funds by the Member complied with:
                <ul class="inner-ul">
                    <li>all applicable federal laws, rules and regulations, and</li>
                    <li>all state laws, rules and regulations in the state of the Member’s operations.</li>
                </ul>
            </li>
        </ul>
        <p>
            In connection with this Incoming Wire Transfer Request, the Member agrees to provide all information and documentary evidence
            of any nature whatsoever that Confia may request to understand the source of funds. I acknowledge and agree
            that Confia may determine, in its sole and absolute discretion, that all, some, or none of the Deposit funds
            may be deposited into the account.
        </p>
        <p>
            Please provide these wire instructions to the sending party:
        </p>
        <p>
            <b>Beneficiary Financial Institution:</b> CONFIA FINANCIAL<br>
            <b>Beneficiary Bank Address:</b> 397 Aviation Blvd Ste L, Santa Rosa, CA 95403<br>
            <b>Beneficiary Routing Number:</b> 121182894<br>
            <b>Beneficiary Account Holder Name:</b> {{ memberAccount.member.name }}<br>
            <b>Beneficiary Account Number:</b> {{ memberAccount.accountNumber }}
        </p>
        <div class="modal-footer">
            <pc-submit-button [submit]="onAcceptTerms" [text]="'Accept Terms'"></pc-submit-button>
            <pc-submit-button [submit]="onCancelTerms" [text]="'Cancel'"></pc-submit-button>
        </div>
    </div>
    <div *ngIf="getTransactionType() === TransactionType.INCOMING_ACH_FROM_MERCHANT">
        <p>
            By clicking 'Accept Terms' I hereby represent and warrant that I am an Authorized Official authorized
            to request this ACH Deposit. I, and on behalf of the Member, hereby certify that this Incoming ACH Request
            is made pursuant to the terms and conditions of the Member Agreement, as may be amended, governing
            my Account. I, and on behalf of the Member, further certify and guarantee that:
        </p>
        <ul class="top-ul">
            <li>
                the funds, individually or in the aggregate, are proceeds obtained from lawful sources and lawful business
                operations and are not linked and/or derived from criminal origin, of whatsoever nature, and in particular
                do not constitute proceeds of money laundering or terrorist financing,
            </li>
            <li>
                the receipt of such funds by the Member complied with:
                <ul class="inner-ul">
                    <li>all applicable federal laws, rules and regulations, and</li>
                    <li>all state laws, rules and regulations in the state of the Member’s operations.</li>
                </ul>
            </li>
        </ul>
        <p>
            In connection with this Incoming ACH Request, the Member agrees to provide all information and documentary evidence
            of any nature whatsoever that Confia may request to understand the source of funds. I acknowledge and agree
            that Confia may determine, in its sole and absolute discretion, that all, some, or none of the Deposit funds
            may be deposited into the account.
        </p>
        <p>
            Please provide these ACH instructions to the sending party:
        </p>
        <p>
            <b>Routing Number:</b> 121182894<br>
            <span *ngIf="memberAccount.accountNumber"><b>Account Number:</b> {{ memberAccount.accountNumber }}</span>
        </p>
        <div class="modal-footer">
            <pc-submit-button [submit]="onAcceptTerms" [text]="'Accept Terms'"></pc-submit-button>
            <pc-submit-button [submit]="onCancelTerms" [text]="'Cancel'"></pc-submit-button>
        </div>
    </div>
</div>

<div class="deposit-stepper" *ngIf="formGroup" [class.view-terms]="viewTerms">
    <mat-stepper [@.disabled]="true" [linear]="true" #stepper [orientation]="(stepperOrientation | async)!">
        <ng-template matStepperIcon="business_client">
            <mat-icon>store</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="invoice_selection">
            <mat-icon>request_quote</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="manifest_selection">
            <mat-icon>list_alt</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="review">
            <mat-icon>verified</mat-icon>
        </ng-template>
        <mat-step state="business_client" [completed]="false" [editable]="true" [label]="getInvoiceBusinessClientLabel()">
            <pt-business-client-step [memberAccount]="memberAccount" [formGroup]="formGroup" [currentBusinessClient]="currentBusinessClient" [isAccountingLinked]="isAccountingLinked"
                [subType]="TransactionSubType.DEPOSIT" (businessClientSelected)="onSelectBusinessClient($event)" (nextStep)="nextStep()" (closeTransactionModal)="closeModal($event)"></pt-business-client-step>
        </mat-step>
        <mat-step state="invoice_selection" [completed]="false" [editable]="isEntryStepEditable()" [label]="getInvoiceLabel()">
            <pt-transaction-entry-selection-step *ngIf="currentBusinessClient" [memberAccount]="memberAccount" [currentBusinessClient]="currentBusinessClient" [isAccountingLinked]="isAccountingLinked"
                [subType]="TransactionSubType.DEPOSIT" (transactionEntriesSelected)="onSelectInvoices($event)" (nextStep)="nextStep()"></pt-transaction-entry-selection-step>
        </mat-step>
        <mat-step *ngIf="isMetrcLinked" state="manifest_selection" [completed]="false" [editable]="isTrackingStepEditable()" [label]="getTrackingLabel()">
            <pt-tracking-selection-step *ngIf="currentBusinessClient?.transactionEntries?.length > 0" [memberAccount]="memberAccount" [currentBusinessClient]="currentBusinessClient"
                [changeTrigger]="changeTrigger" [isRecipient]="true" [formGroup]="formGroup" [subType]="TransactionSubType.DEPOSIT" [selectedManifests]="manifests"
                [manifestSupportingDocs]="manifestSupportingDocs" (manifestSelected)="onSelectManifest($event)" (manifestDocumentUploaded)="onSelectManifestDocument($event)" (nextStep)="nextStep()" (closeTransactionModal)="closeModal($event)"></pt-tracking-selection-step>
        </mat-step>
        <mat-step state="review" [completed]="false" [editable]="isReviewStepEditable()" label="Review">
            <pt-review-transaction-step *ngIf="currentBusinessClient?.transactionEntries?.length > 0" [businessClients]="[currentBusinessClient]" [transactionTypes]="NON_CASH_DEPOST_METHODS"
                [transactionTotal]="transactionTotal" [isReviewStepValid]="isReviewStepValid" [subType]="TransactionSubType.DEPOSIT" [selectedManifests]="manifests" [formGroup]="formGroup" [manifestSupportingDocs]="manifestSupportingDocs"
                (transactionReviewed)="onTransactionReviewed()"></pt-review-transaction-step>
        </mat-step>
    </mat-stepper>
</div>
