<div *ngIf="newBankAccountForm">
    <form [formGroup]="newBankAccountForm">
        <div>
            <div class="modal-header">
                <label class="form-title">{{existingAccount ? 'Review Account' : 'Add New Bank Account'}}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div>
                    <pc-text-option-selector [label]="'Account Type'" [options]="accountTypeOpts"
                        [formCtrl]="'accountTypeCtrl'" [formGroup]="newBankAccountForm" [translated]="true">
                    </pc-text-option-selector>
                    <pc-text-input [label]="'Financial Institution'"
                        [formCtrl]="'financialInstitutionCtrl'" [formGroup]="newBankAccountForm">
                    </pc-text-input>
                    <pc-text-input [label]="'Account Name'" [required]="accountNameRequired"
                        [formCtrl]="'accountNameCtrl'" [formGroup]="newBankAccountForm">
                    </pc-text-input>
                    <pc-text-input [required]="true" [label]="'Name of Account Holder'" [placeholder]="'Account Holder'"
                        [formCtrl]="'accountHolderCtrl'" [formGroup]="newBankAccountForm">
                    </pc-text-input>
                    <pc-text-input [required]="true" [label]="'Account Number'" [placeholder]="'Account Number - 5 to 17 digits'"
                        [formCtrl]="'accountNumberCtrl'" [formGroup]="newBankAccountForm">
                    </pc-text-input>
                    <pc-text-input [label]="'ACH Routing Number'" [placeholder]="'9 digit ACH routing number'"
                        [formCtrl]="'achRoutingNumberCtrl'" [formGroup]="newBankAccountForm" (change)="validateACHAndWireRoutingNumbers()">
                    </pc-text-input>
                    <pc-text-input [label]="'Wire Routing Number'" [placeholder]="'9 digit Wire routing number'"
                        [formCtrl]="'wireRoutingNumberCtrl'" [formGroup]="newBankAccountForm" (change)="validateACHAndWireRoutingNumbers()">
                    </pc-text-input>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button *ngIf="existingAccount" [enabled]="isAccountFormValid" [submit]="rejectAccount" [text]="'Reject Account'"></pc-submit-button>
                <pc-submit-button [enabled]="isAccountFormValid" [submit]="onSubmit" [text]="existingAccount ? 'Approve Account' : 'Add Account'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
