import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
    AuthorizationFormService, AuthService, Profile, Utils, MemberType, UserAccount, UserAccountService, Member, Role, WalletFunction
} from 'projects/services/src/public-api';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';

@Component({
    selector: 'pc-member-switch-modal',
    templateUrl: './member-switch-modal.component.html'
})
export class MemberSwitchModalComponent extends BaseModalComponent<MemberSwitchModalComponent> implements OnInit {

    loginForm: UntypedFormGroup;
    profile: Profile;
    membersForUser: Member[] = [];

    safariFix: boolean;

    @ViewChild('memberIdCtrl') memberField: ElementRef;

    constructor(private authorizationFormService: AuthorizationFormService,
                private authService: AuthService,
                private userAccountService: UserAccountService,
                private matDialog: MatDialog,
                dialogRef: MatDialogRef<MemberSwitchModalComponent>) {
        super(dialogRef);
    }

    ngOnInit() {
        this.safariFix = Utils.isSafari();
        this.profile = this.authService.getProfile();
        this.loginForm = this.authorizationFormService.switchOrgForm();
        this.close = this.close.bind(this);

        this.userAccountService.getUserAccountsAvailableForLogin(this.profile.userId).subscribe((userAccounts: UserAccount[]) => {
            let members = [...new Set(userAccounts.filter((userAccount: UserAccount) => {
                if (userAccount.role === Role.RETAILER) {
                    // do not allow switch to member where the user only has RETAILER access
                    return false;
                } else if (this.profile.memberId === userAccount.memberId) {
                    return false;
                }
                return true;
            }).map((userAccount: UserAccount) => {
                return userAccount.member;
            }))];

            if (members.length > 0) {
                for (const member of members) {
                    if (member.memberType === MemberType.CONSUMER) {
                        member.name = member.name + ' (Consumer)';
                    }
                }
                this.membersForUser = members;
            } else {
                const profile = this.authService.getProfile();
                profile.multiMember = false;
                this.authService.setProfile(profile);
                this.close();
                throw new Error('User has only one active member.');
            }
        });
    }

    onMemberSelected() {
        const memberId = this.loginForm.controls['memberIdCtrl'].value;
        this.authService.updateAuthentication(memberId, false).subscribe((updatedProfile: any) => {
            this.profile = updatedProfile;
            this.profile.multiMember = true;
            this.authService.initializeApplication(this.profile, this.close, 'dashboard', true);
        });
    }

    cancel() {
        this.close();
    }

    close() {
        this.matDialog.closeAll();
        super.close();
    }
}
