import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthService, BsaReport, ReportsFormService, ReportsService, ReportStatus, Role } from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pc-fincen-report-tracking-modal',
    templateUrl: './fincen-tracking-report-modal.component.html'
})
export class FincenReportTrackingModalComponent extends BaseModalComponent<FincenReportTrackingModalComponent> implements OnInit {

    fincenTrackingReportForm: UntypedFormGroup;
    report: BsaReport;
    formNameCtrl = '';
    isReporter = false;
    reportStatuses = [ReportStatus.AUTHORITY_REVIEW, ReportStatus.READY_FOR_SUBMISSION, ReportStatus.SUBMISSION_IN_PROGRESS, ReportStatus.SUBMISSION_SUCCESSFUL, ReportStatus.SUBMISSION_FAILED, ReportStatus.ACCEPTED, ReportStatus.ACCEPTED_WITH_WARNINGS, ReportStatus.REJECTED, ReportStatus.ACKNOWLEDGED, ReportStatus.ACKNOWLEDGEMENT_FAILED, ReportStatus.WILL_NOT_BE_SUBMITTED];

    constructor(private reportsService: ReportsService,
                private reportFormService: ReportsFormService,
                public authService: AuthService,
                dialogRef: MatDialogRef<FincenReportTrackingModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.report = data.report;
        this.fincenTrackingReportForm = this.reportFormService.initializeForm(this.report);
    }

    ngOnInit() {
        this.isReporter = this.authService.getProfile().role === Role.AUTHORITY_REPORTER;
        this.isFincenTrackingReportFormValid = this.isFincenTrackingReportFormValid.bind(this);
        this.formNameCtrl = this.isReporter ? 'externalProviderNotesCtrl' : 'notesCtrl';
        this.onSubmit = this.onSubmit.bind(this);
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    onSubmit(reset: any) {
        const body = this.reportFormService.getTrackingDetails(this.report, this.fincenTrackingReportForm);
        this.reportsService.updateTrackingDetails(body).subscribe((_report: BsaReport) => {
            reset();
            this.close(true);
        }, (error) => {
            reset();
            throw error;
        });
    }

    isFincenTrackingReportFormValid() {
        return !this.fincenTrackingReportForm.invalid;
    }
}
