import { Client } from '@stomp/stompjs';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StompService {

    baseUrl: string;
    stompClient: Client;

    constructor(private notificationService: NotificationService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.NotificationsAPIEndpoint + '/websocket';
        this.onMessageReceived = this.onMessageReceived.bind(this);
    }

    connect(token: string) {
        this.stompClient = new Client({
            connectHeaders: {
                token
            },
            brokerURL: this.baseUrl,
            reconnectDelay: 30000,
            heartbeatIncoming: 20000,
            heartbeatOutgoing: 20000
        });
        this.stompClient.onWebSocketError = (event: any) => {
            if (event.type === 'error') {
                this.disconnect();
            }
        };
        this.stompClient.onConnect = (frame: any) => {
            if (frame.command === 'CONNECTED') {
                this.stompClient.subscribe('/user/queue/updates', this.onMessageReceived);
                this.stompClient.subscribe('/queue/updates', this.onMessageReceived);
                // eslint-disable-next-line no-console
                console.log('Subscribed to notifications.');
            } else {
                // eslint-disable-next-line no-console
                console.log('Notifications status: ' + frame.command);
            }
        };
        this.stompClient.onStompError  = (frame: any) => {
            if (frame.headers.errorCode) {
                this.disconnect();
            }
        };
        this.stompClient.activate();
    }

    disconnect() {
        if (this.stompClient && this.stompClient.active) {
            this.stompClient.deactivate();
        }
        // eslint-disable-next-line no-console
        console.log('Not receiving notifications.');
    }

    /**
     * Send message to sever via web socket
     */
    // send(memberId: any) {
    //     if (this.stompClient && this.stompClient.active) {
    //         const body = {
    //             to: memberId,
    //             message: 'This is a message only for member with id:' + memberId
    //         }
    //         this.stompClient.publish({ destination: '/notify/update', body: JSON.stringify(body) });
    //     }
    // }

    onMessageReceived(message: any) {
        this.notificationService.showNotification(JSON.parse(message.body));
    }
}
