import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';

@Component({
    selector: 'pc-dob-input',
    templateUrl: './dob-input.component.html',
    styleUrls: ['./dob-input.component.scss']
})
export class DOBInputComponent implements OnInit {

    @Input() required: boolean;
    @Input() formGroup: UntypedFormGroup;
    @Input() formCtrl: string;

    internalForm: UntypedFormGroup;
    cutoffYear = new Date().getFullYear() - 18;
    age = 0;
    day = null;
    month = null;
    year = null;

    @ViewChild('dayField') dayField: ElementRef;
    @ViewChild('monthField') monthField: ElementRef;
    @ViewChild('yearField') yearField: ElementRef;

    constructor(private formbuilder: UntypedFormBuilder) {}

    ngOnInit() {
        const dateOfBirth = this.formGroup.controls[this.formCtrl].value;
        const birthdateParsed = this.parse(dateOfBirth);
        if (dateOfBirth) {
            this.age = this.getAge(dateOfBirth);
        }
        if (this.required) {
            this.internalForm = this.formbuilder.group({
                birthDayCtrl: new UntypedFormControl(birthdateParsed.day, [Validators.required, Validators.min(1), Validators.max(31), Validators.minLength(1), Validators.maxLength(2)]),
                birthMonthCtrl: new UntypedFormControl(birthdateParsed.month, [Validators.required, Validators.min(1), Validators.max(12), Validators.minLength(1), Validators.maxLength(2)]),
                birthYearCtrl: new UntypedFormControl(birthdateParsed.year, [Validators.required, Validators.min(1900), Validators.max(this.cutoffYear), Validators.minLength(4), Validators.maxLength(4)])
            });
        } else {
            this.internalForm = this.formbuilder.group({
                birthDayCtrl: new UntypedFormControl(birthdateParsed.day, [Validators.min(1), Validators.max(31), Validators.minLength(1), Validators.maxLength(2)]),
                birthMonthCtrl: new UntypedFormControl(birthdateParsed.month, [Validators.min(1), Validators.max(12), Validators.minLength(1), Validators.maxLength(2)]),
                birthYearCtrl: new UntypedFormControl(birthdateParsed.year, [Validators.min(1900), Validators.max(this.cutoffYear), Validators.minLength(4), Validators.maxLength(4)])
            });
        }
        if (this.formGroup.controls[this.formCtrl].disabled) {
            this.internalForm.disable();
        }

        this.formGroup.controls[this.formCtrl].valueChanges.subscribe((result) => {
            const updatedBirthdateParsed = this.parse(result);
            if (result) {
                this.age = this.getAge(result);
            }
            this.internalForm.patchValue({
                birthDayCtrl: updatedBirthdateParsed.day,
                birthMonthCtrl: updatedBirthdateParsed.month,
                birthYearCtrl: updatedBirthdateParsed.year
            });
            this.internalForm.updateValueAndValidity();
        });

        this.formGroup.controls[this.formCtrl].statusChanges.subscribe((result) => {
            if (result === 'DISABLED') {
                this.internalForm.disable();
            } else {
                this.internalForm.enable();
            }
        });
    }

    parse(birthDate: string): any {
        if (!birthDate) {
            return {
                day: '',
                month: '',
                year: ''
            };
        } else {
            return {
                day: birthDate.substring(8),
                month: birthDate.substring(5, 7),
                year: birthDate.substring(0, 4)
            };
        }
    }

    onChange(formControlName: string, length: number, maxValue: number) {
        this.age = 0;
        let oneValueChanged = false;
        let value = this.internalForm.controls[formControlName].value;
        if (!value && value !== 0 || value.toString().length > length || value > maxValue || value.toString().indexOf('.') !== -1) {
            this.internalForm.controls[formControlName].setValue('');
            this.onValueChange();
            return;
        }
        value = value.toString();
        if (formControlName === 'birthDayCtrl') {
            if (this.day === '0' && value !== '0') {
                value = '0' + value;
            }
            this.day = value;
        } else if (formControlName === 'birthMonthCtrl') {
            if (this.month === '0' && value !== '0') {
                value = '0' + value;
            }
            this.month = value;
        }
        this.internalForm.controls[formControlName].setValue(value);
        this.internalForm.controls[formControlName].updateValueAndValidity();
        const originalValue = value;
        if (value !== '0' && (formControlName === 'birthMonthCtrl' && this.internalForm.controls['birthDayCtrl'].value.length === 2 || formControlName === 'birthDayCtrl' && this.internalForm.controls['birthMonthCtrl'].value.length === 2) && this.internalForm.controls['birthYearCtrl'].value.length === 4) {
            this.onBlur();
            oneValueChanged = true;
        }
        this.onValueChange();
        if (length === value.toString().length && !oneValueChanged) {
            if (formControlName === 'birthMonthCtrl') {
                this.dayField.nativeElement.focus();
            } else if (formControlName === 'birthDayCtrl') {
                this.yearField.nativeElement.focus();
            }
        }
        if (oneValueChanged) {
            this.internalForm.controls[formControlName].setValue(originalValue);
        }
    }

    isAgeValid() {
        return (!this.required && !this.internalForm.controls['birthDayCtrl'].value && !this.internalForm.controls['birthMonthCtrl'].value && !this.internalForm.controls['birthYearCtrl'].value)
                || (this.age >= 18 && Number(this.internalForm.controls['birthYearCtrl'].value) >= 1900);
    }

    onBlur() {
        const birthday = this.internalForm.controls['birthDayCtrl'].value;
        if (birthday && birthday.length === 1) {
            this.internalForm.controls['birthDayCtrl'].patchValue('0' + birthday);
            this.internalForm.controls['birthDayCtrl'].updateValueAndValidity();
        }
        const birthmonth = this.internalForm.controls['birthMonthCtrl'].value;
        if (birthmonth && birthmonth.length === 1) {
            this.internalForm.controls['birthMonthCtrl'].patchValue('0' + birthmonth);
            this.internalForm.controls['birthMonthCtrl'].updateValueAndValidity();
        }
        this.onValueChange();
    }

    onValueChange() {
        const birthday = this.internalForm.controls['birthDayCtrl'].value;
        const birthmonth = this.internalForm.controls['birthMonthCtrl'].value;
        const birthyear = this.internalForm.controls['birthYearCtrl'].value;
        if (birthday && birthday.length === 2
            && birthmonth && birthmonth.length === 2
            && birthyear && birthyear.length === 4) {
            const birthdate = (birthyear + '-' + birthmonth + '-' + birthday);
            this.validateDayForMonth(parseInt(birthmonth, 10), parseInt(birthyear, 10));
            this.age = this.getAge(birthdate);
            if (this.age >= 18 && this.internalForm.get('birthDayCtrl').valid
                && this.internalForm.get('birthMonthCtrl').valid
                && this.internalForm.get('birthYearCtrl').valid) {
                this.formGroup.controls[this.formCtrl].patchValue(birthdate, { emitEvent: false });
                if (!this.internalForm.valid) {
                    this.formGroup.controls[this.formCtrl].setErrors({ invalid: true });
                } else {
                    this.formGroup.controls[this.formCtrl].updateValueAndValidity();
                }
            } else {
                this.formGroup.controls[this.formCtrl].patchValue(null, { emitEvent: false });
                this.formGroup.updateValueAndValidity();
            }
        } else {
            this.formGroup.controls[this.formCtrl].patchValue(null, { emitEvent: false });
            this.formGroup.updateValueAndValidity();
        }
        this.formGroup.controls[this.formCtrl].markAsDirty();
    }

    getAge(dob: string) {
        const today = new Date();
        const birthDate = this.parse(dob);
        let age = today.getFullYear() - birthDate.year;
        const month = today.getMonth() - (birthDate.month - 1);
        if (month < 0 || (month === 0 && today.getDate() < birthDate.day)) {
            age--;
        }
        return age;
    }

    validateDayForMonth(month: number, year: number) {
        const dayValidators = [Validators.min(1), Validators.minLength(1), Validators.maxLength(2)];
        if (this.required) {
            dayValidators.push(Validators.required);
        }
        dayValidators.push(Validators.max(this.getNumberOfDaysInMonth(month, year)));
        this.internalForm.get('birthDayCtrl').setValidators(dayValidators);
        this.internalForm.get('birthDayCtrl').updateValueAndValidity();
    }

    getNumberOfDaysInMonth(month: number, year: number) {
        if ((month === 4 || month === 6 || month === 9 || month === 11)) {
            return 30;
        } else if (month === 2) {
            const leapYear = ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
            if (leapYear) {
                return 29;
            } else if (!leapYear) {
                return 28;
            }
        } else {
            return 31;
        }
    }

}
