<fieldset class="form-group" *ngIf="formCtrl">
    <div class="row">
        <div class="col-12" [formGroup]="formGroup">
            <label class="form-label">{{ label | translate }}</label>
            <div [formGroup]="formGroup" class="inputGroup">
                <input #businessClientSelect [formControl]="autoCompleteControl" [formControlName]="merchantNameFormCtrl" type="text" [placeholder]="placeholder"
                    class="form-control form-input-editable" [class.empty-results]="emptyResults" [matAutocomplete]="auto" (keydown)="onInputChanged($event)" (click)="$event.target.select()">
                <i class="fa" [ngClass]="selected() ? 'fa-times-circle-o' : 'fa-chevron-down'" (click)="onIconClick($event)"></i>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
                    <mat-option class="business-client-entry" [ngClass]="searching ? 'hidden' : ''" *ngFor="let businessClient of businessClientAutoComplete | async;" [value]="businessClient.name"
                                [disabled]="businessClient.status === BusinessClientStatus.DISABLED || businessClient.status === BusinessClientStatus.REJECTED"
                                (onSelectionChange)="businessClientChanged(businessClient)">
                        <div class="row">
                            <div class="col-sm-7">
                                <img *ngIf="businessClient.type === MemberType.INDIVIDUAL_MERCHANT" class="imember-type-icon" src="assets/images/profile-icon.svg" title="Individual">
                                {{ getBusinessClientName(businessClient) }}
                            </div>
                            <div class="col-sm-2 text-center"><pc-member-badge [parent]="businessClient"></pc-member-badge></div>
                            <div *ngIf="includeConfiaMerchants" class="col-sm-3 text-end"><pc-merchant-source-badge [parent]="businessClient"></pc-merchant-source-badge></div>
                            <div *ngIf="!includeConfiaMerchants" class="col-sm-3 text-end"><pc-accounting-party-type-badge [parent]="businessClient"></pc-accounting-party-type-badge></div>
                        </div>
                        <div *ngIf="businessClient?.address" class="row address-info">
                            <div class="col-7">{{ (businessClient.address.streetAddressOne || "") + " " +  (businessClient.address.streetAddressTwo || "")}}</div>
                            <div class="col-2 text-center">{{ (businessClient.address.city ? businessClient.address.city + ", " : "") + (businessClient.address.stateProvince || "") }}</div>
                            <div class="col-3 text-end">{{ businessClient.address.country || "" }}</div>
                        </div>
                    </mat-option>
                    <mat-option *ngIf="searching">
                        <i class="fa fa-spin fa-spinner"></i>Searching
                    </mat-option>
                    <mat-option *ngIf="!searching && totalResults > searchLimit" [disabled]="true">
                        There are {{totalResults - searchLimit}} additional results - please refine your search.
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>
</fieldset>
