import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
    AccountingService, Address, AuthService, BillingProfile, AccountingLinkedMember, MemberAccountService, DataroomService, DocumentType, AddressService,
    WalletFunction, MetrcLicense, MetrcService, MetrcStatus, MemberAccount, PaymentService, Transaction, SupportingDocument, TransactionStatus,
    Upload, Wallet, WalletService, WorkflowService, Configuration
} from 'projects/services/src/public-api';
import { BaseModalComponent, ErrorType, LoaderComponent } from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { map } from 'rxjs/operators';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';

@Component({
    selector: 'pt-payment-modal',
    templateUrl: './payment-modal.component.html',
    styleUrls: ['./payment-modal.component.scss']
})
export class PaymentModalComponent extends BaseModalComponent<PaymentModalComponent> implements OnInit {

    PaymentType = PaymentType;

    readonly ACCOUNTING_NOT_LINKED_ERROR_BUSINESS_PAYMENT = {
        message: 'Please link an accounting system to make payments to your accounting clients',
        type: ErrorType.WARN
    };
    readonly ACCOUNTING_NOT_LINKED_ERROR_CONFIA_PAYMENT = {
        message: 'Please link an accounting system to see bills for Confia members',
        type: ErrorType.WARN
    };
    readonly METRC_NOT_LINKED_ERROR = {
        message: 'METRC is not linked, so manifest tracking is unavailable',
        type: ErrorType.WARN
    };

    memberId: string;
    memberAccount: MemberAccount;
    submitterId: string;
    isAccountingLinked = true;
    isMetrcLinked = true;
    transactionTotal = '0.00';
    availableBalance: number;
    achDirectPaymentEnabled = false;
    achExpressPaymentFee: number;
    wirePaymentFee: number;

    paymentTypeSelected = false;
    paymentType: PaymentType = PaymentType.BUSINESS_PAYMENT;
    errorMessage: { message: string, type: ErrorType };

    buttonOrientation: Observable<string>;

    @ViewChild('buttongroup') protected buttongroup: MatButtonToggleGroup;

    constructor(private authService: AuthService,
                private memberAccountService: MemberAccountService,
                private addressService: AddressService,
                private accountingService: AccountingService,
                private dataroomService: DataroomService,
                private workflowService: WorkflowService,
                private notifier: NotificationService,
                private metrcService: MetrcService,
                private walletService: WalletService,
                private paymentService: PaymentService,
                private notificationService: NotificationService,
                private overlay: Overlay,
                protected breakpointObserver: BreakpointObserver,
                dialog: MatDialogRef<PaymentModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialog);
        this.memberAccount = data.memberAccount;
        this.memberId = this.memberAccount.memberId;
    }

    ngOnInit() {
        this.buttonOrientation = this.breakpointObserver.observe('(min-width: 799px)').pipe(
            map((matches: BreakpointState) => {
                return matches.matches ? 'false' : 'true';
            })
        );
        this.submitterId = this.authService.getProfile().userId;
        this.loadMemberAccount();
        this.achDirectPaymentEnabled = this.memberAccountService.hasWalletFunction(this.memberAccount, WalletFunction.ACH_DIRECT_PAYMENT);
        if (this.achDirectPaymentEnabled) {
            this.paymentType = PaymentType.CONFIA_PAYMENT;
            this.paymentTypeSelected = true;
        }

        this.walletService.findByMemberAccountId(this.memberAccount.id).subscribe((wallet: Wallet) => {
            this.availableBalance = wallet.availableBalance;
        });

        this.accountingService.getAccountingSystemInfo(this.memberId).subscribe((accountingSystem: AccountingLinkedMember) => {
            if (!accountingSystem?.linked) {
                this.isAccountingLinked = false;
            }
        });
        if (Configuration.getConfig().metrcEnabled) {
            this.metrcService.getActiveMetrcLicenseInformation(MetrcStatus.ACTIVE, this.memberId).subscribe((response: MetrcLicense[]) => {
                this.isMetrcLinked = response.length > 0;
            });
        } else {
            this.isMetrcLinked = false;
        }
        this.getBillingProfileDetails();
        this.checkForAmountErrors();
    }

    loadMemberAccount() {
        this.addressService.getPrimaryAddress(this.memberAccount.id).subscribe((location: Address) => {
            this.memberAccount.address = location;
        });
    }

    onTotalChange(total: string) {
        this.transactionTotal = total;
        this.checkForAmountErrors();
    }

    checkForAmountErrors() {
        if (this.availableBalance < Number(this.transactionTotal) && !this.achDirectPaymentEnabled) {
            this.errorMessage = {
                message: 'Insufficient funds available for payment.',
                type: ErrorType.ERROR
            };
        } else {
            this.errorMessage = null;
        }
    }

    onTypeChange(type: PaymentType) {
        this.paymentType = type;
    }

    selectPaymentType() {
        this.paymentTypeSelected = true;
    }

    onSubmit(transaction: Transaction) {
        const filesToUpload: SupportingDocument[] = [];
        transaction.expenses.forEach((expense) => {
            expense.supportingDocuments.forEach((supportingDocument) => {
                if (!supportingDocument.documentId) {
                    supportingDocument.memberId = this.memberId;
                    supportingDocument.documentType = DocumentType.PAYMENT_SUPPORTING_DOCUMENT;
                    filesToUpload.push(supportingDocument);
                }
            });
        });

        if (transaction.remittanceFile) {
            transaction.remittanceFile.memberId = this.memberId;
            transaction.remittanceFile.explanation = 'Remittance Form';
            transaction.remittanceFile.documentType = DocumentType.REMITTANCE_FORM;
            filesToUpload.push(transaction.remittanceFile);
        }

        transaction.manifestSupportingDocuments.forEach((supportingDocument) => {
            if (!supportingDocument.documentId) {
                supportingDocument.memberId = this.memberId;
                supportingDocument.documentType = DocumentType.SEEDTOSALE_TRACK_MANIFEST;
                supportingDocument.explanation = 'Manifest Support Document';
                filesToUpload.push(supportingDocument);
            }
        });

        let transactionLevelDocuments = [];
        if (filesToUpload.length) {
            const overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                hasBackdrop: true
            });
            const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
            componentRef.instance.title = 'Uploading files.  Please wait...';
            this.uploadFiles(filesToUpload, transaction.submissionReference).subscribe((results: Upload[]) => {
                for (let i = 0; i < results.length; i++) {
                    filesToUpload[i].documentId = results[i].id;
                    filesToUpload[i].file = null;
                    if (filesToUpload[i].documentType === DocumentType.REMITTANCE_FORM || filesToUpload[i].documentType === DocumentType.SEEDTOSALE_TRACK_MANIFEST) {
                        const doc = new SupportingDocument();
                        doc.documentId = results[i].id;
                        doc.documentType = results[i].documentType;
                        doc.memberId = this.memberId;
                        transactionLevelDocuments.push(doc);
                    }
                }
                transaction.supportingDocuments = transactionLevelDocuments;
                overlayRef.dispose();
                this.makePayment(transaction);
            }, () => {
                overlayRef.dispose();
                this.notificationService.showError('Document upload was unsuccessful. Please check your connection and try again.');
            });
        } else {
            this.makePayment(transaction);
        }
    }

    uploadFiles(supportingDocuments: SupportingDocument[], submissionReference: string) {
        return forkJoin(supportingDocuments.map((supportingDocument) => {
            return this.dataroomService.uploadFile(this.memberId, `${this.memberId}/${this.submitterId}/transaction/${submissionReference}/${uuidv4()}`, supportingDocument.file,
                supportingDocument.file.name, supportingDocument.explanation, supportingDocument.documentType, this.memberId);
        }));
    }

    makePayment(transaction: Transaction) {
        transaction.payorAccountId = this.memberAccount.id;
        transaction.payorMemberId = this.memberId;
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        componentRef.instance.title = 'Submitting transaction...';

        this.workflowService.makePayment(transaction).subscribe((result: Transaction) => {
            this.close(true);
            overlayRef.dispose();
            if (result.status === TransactionStatus.PENDING_RFI_AUTHORITY_REVIEW || result.status === TransactionStatus.PENDING_RFI_DUE_DILIGENCE) {
                this.notifier.showSuccess(`Your payment in the amount of <b>$${transaction.totalAmount}</b> has been submitted and will be reviewed.`);
            } else {
                this.notifier.showSuccess(`A payment in the amount of <b>$${transaction.totalAmount}</b> has been submitted.`);
            }
        }, (error: any) => {
            overlayRef.dispose();
            if (error.status === 400) {
                this.close(true);
            }
            throw error;
        });
    }

    getBillingProfileDetails() {
        this.paymentService.getBillingProfileByMemberAccountId(this.memberAccount.id).subscribe((billingProfile: BillingProfile) => {
            this.achExpressPaymentFee = billingProfile.achExpressPaymentFee;
            this.wirePaymentFee = billingProfile.wirePaymentFee;
        });
    }
}

export enum PaymentType {
    CONFIA_PAYMENT = 'CONFIA_PAYMENT',
    BUSINESS_PAYMENT = 'BUSINESS_PAYMENT'
}
