<div class="col-12 dashboard-info" *ngIf="contractTemplate">
    <div class="row">
        <div class="col-6 dashboard-header">
            {{contractTemplate.name}} (as {{getViewType() | translate}})
        </div>
        <div class="col-6">
            <button *ngIf="contractTemplate.visibility === ContractTemplateVisibility.PUBLIC || contractTemplate.visibility === ContractTemplateVisibility.LIMITED" class="blue-button pull-right" (click)="initializeContract()" type="button"><i class="fa fa-share-alt pull-left dashboard-icon"></i><div class="pull-right-button">Initiate Contract</div></button>
            <button *ngIf="contractTemplate.visibility === ContractTemplateVisibility.PUBLIC || contractTemplate.visibility === ContractTemplateVisibility.LIMITED" class="blue-button pull-right" (click)="cancelToTemplates()" type="button"><i class="fa fa-arrow-left pull-left dashboard-icon"></i><div class="pull-right-button">Return to Available Contracts</div></button>
            <button *ngIf="contractTemplate.visibility === ContractTemplateVisibility.ACTIVE" class="blue-button pull-right" (click)="cancelToContracts()" type="button"><i class="fa fa-arrow-left pull-left dashboard-icon"></i><div class="pull-right-button">Return to My Contracts</div></button>
        </div>
    </div>
    <div *ngIf="contractTemplate" class="row wizard">
        <div class="col-12 clear">
            <pc-sc-section *ngFor="let section of contractTemplate.sections; let i = index" [contractTemplate]="contractTemplate" [index]="i">
            </pc-sc-section>
        </div>
    </div>
</div>
