import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContractPartyClassification, SalesOrder, SalesOrderLine } from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NewItemModalComponent } from './new-item-modal/new-item-modal.component';
import { ShippingModalComponent } from './shipping-modal/shipping-modal.component';

@Component({
    selector: 'pc-sc-sales-order',
    templateUrl: './sales-order.component.html',
    styleUrls: ['./sales-order.component.scss']
})
export class SalesOrderComponent implements OnInit {

    @Input() mode = 'VIEW';
    @Input() order: SalesOrder;
    @Input() enforceRequired = true;
    @Input() frameworkType: string;

    @Output() modified: EventEmitter<SalesOrder> = new EventEmitter<SalesOrder>();

    displayedColumns: string[] = ['item', 'description',
        'quantity', 'unitOfMeasure', 'pricePerUnit',
        'discount', 'preTaxAmount', 'taxType',
        'taxAmount', 'totalAmount', 'resale'];
    displayedShippingColumns: string[] = ['shippingTitle', 'empty',
        'empty', 'empty', 'empty',
        'empty', 'empty', 'empty',
        'empty', 'shippingAmount', 'empty'];

    constructor(private dialog: MatDialog) { }

    ngOnInit() {
        if (this.mode === 'EDIT') {
            this.displayedColumns.push('action');
            this.displayedShippingColumns.push('shippingAction');
        }
    }

    addItem(line: SalesOrderLine) {
        this.order.lineItems.push(line);
        this.modified.emit(this.order);
    }

    onAdd() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        const orderLine = new SalesOrderLine();
        orderLine.item = this.order.lineItems.length + 1;
        orderLine.taxType = (this.frameworkType === ContractPartyClassification.PRODUCER) ? '' : 'EXCISE';
        dialogConfig.data = {
            orderLine,
            frameworkType: this.frameworkType,
            buttonText: 'Add Item'
        };

        const dialog = this.dialog.open(NewItemModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (line: SalesOrderLine) => {
                if (line) {
                    this.addItem(line);
                }
            }
        );
    }

    removeOrderLine(line: SalesOrderLine) {
        this.order.lineItems.splice(line.item - 1, 1);
        let i = 1;
        for (line of this.order.lineItems) {
            line.item = i;
            i++;
        }
        this.modified.emit(this.order);
    }

    editOrderLine(orderLine: SalesOrderLine) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            orderLine,
            frameworkType: this.frameworkType,
            buttonText: 'Update Item'
        };

        const dialog = this.dialog.open(NewItemModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (line: SalesOrderLine) => {
                if (line) {
                    this.order.lineItems.splice(line.item - 1, 1, line);
                    this.modified.emit(this.order);
                }
            }
        );
    }

    editShippingCost() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            purchaseOrder: this.order
        };

        const dialog = this.dialog.open(ShippingModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (order: SalesOrder) => {
                if (order) {
                    this.order.shippingCost = order.shippingCost;
                    this.modified.emit(this.order);
                }
            }
        );

    }

    isRequired() {
        return this.enforceRequired && this.order.lineItems.length === 0;
    }
}
