import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WorkflowService, Task } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-sc-select-template-terms',
    templateUrl: './select-template-terms.component.html'
})
export class SelectTemplateTermsComponent implements OnInit {

    @Input() task: Task;

    @Output() taskChanged: EventEmitter<Task> = new EventEmitter<Task>();

    constructor(private workflowService: WorkflowService) {}

    ngOnInit() {
        if (this.workflowService.getCurrentTask()) {
            this.task = this.workflowService.getCurrentTask();
            this.taskChanged.emit(this.task);
        }
    }
}
