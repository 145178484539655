import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { BrowserNotSupportedComponent } from './browser-not-supported.component';
import { BrowserNotSupportedRoutingModule } from './browser-not-supported-routing.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        BrowserNotSupportedRoutingModule
    ],
    declarations: [
        BrowserNotSupportedComponent
    ]
})
export class BrowserNotSupportedModule {
}
