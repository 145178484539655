import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
    MemberAccount, MemberAccountService, WalletService, Wallet, PaymentService, AuthService, MemberType, MemberAccountStatus, Role, BillingProfile, WalletFunction, MemberStatus, MemberFunction
} from 'projects/services/src/public-api';


@Component({
    selector: 'pt-member-account-overview',
    templateUrl: './member-account-overview.component.html',
    styleUrls: ['./member-account-overview.component.scss']
})
export class MemberAccountOverviewComponent implements OnInit {

    MemberAccountType = MemberType;
    Role = Role;

    @Input() memberAccount: MemberAccount;

    isDepositorAccount: boolean;
    wallet: Wallet;
    availableBalance: number;
    fundsPending: number;
    outstandingCheckAmount: number;
    isFeeAccount = false;
    billingProfile: BillingProfile;

    @ViewChild('downloadVoidCheckLink') downloadVoidCheckLink: ElementRef;

    constructor(private memberAccountService: MemberAccountService,
                private walletService: WalletService,
                private paymentService: PaymentService,
                public authService: AuthService) {}

    ngOnInit() {
        this.isFeeAccount = this.memberAccount.member.memberType === MemberType.FEE_ACCOUNT;
        this.isDepositorAccount = !this.memberAccountService.hasWalletFunction(this.memberAccount, WalletFunction.ACH_DIRECT_PAYMENT);
        this.walletService.findByMemberAccountId(this.memberAccount.id).subscribe((wallet: Wallet) => {
            this.wallet = wallet;
            this.availableBalance = wallet.availableBalance;
            this.fundsPending = wallet.pendingBalance;
        });
        this.paymentService.getBillingProfileByMemberAccountId(this.memberAccount.id).subscribe((billingProfile: BillingProfile) => {
            this.billingProfile = billingProfile;
        });
        if (this.isDepositorAccount) {
            this.paymentService.getOutstandingCheckAmountForMemberAccount(this.memberAccount.id).subscribe((outstandingAmount: number) => {
                this.outstandingCheckAmount = outstandingAmount || 0;
            });
        }
    }

    displayVoidCheckButton() {
        return this.isDepositorAccount
            && this.memberAccount?.status === MemberAccountStatus.ACTIVE
            && this.memberAccount?.member.status === MemberStatus.ACTIVE;
    }

    getVoidCheckForMemberAccount() {
        this.paymentService.getVoidCheckForMemberAccount(this.memberAccount.id, this.downloadVoidCheckLink, this.memberAccount.member.name, this.memberAccount.accountName);
    }

    isAmountNegative(amount: number) {
        return amount < 0;
    }

    isOutstandingAmountPresent() {
        return this.outstandingCheckAmount > 0;
    }

    consumerPaymentsAccepted() {
        return this.memberAccount?.walletFunctions && this.memberAccount?.walletFunctions.indexOf(WalletFunction.ACCEPT_CONSUMER_PAYMENTS) >= 0;
    }

    cashDepositsEnabled() {
        return this.memberAccount?.walletFunctions && this.memberAccount?.walletFunctions.indexOf(WalletFunction.CASH_DEPOSIT) >= 0;
    }

    isPartner() {
        return this.memberAccount.member.memberFunctions?.indexOf(MemberFunction.PARTNER) >= 0;
    }
}
