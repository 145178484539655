export class Message {
    id: string;
    memberId: string;
    senderId: string;
    recipientId: string;
    recipientMemberId: string;
    senderMemberId: string;
    documentId: string;
    subject: string;
    body: string;
    created: Date;
    document: any;
    parentMessageId: string;
    messageId: string;
    readStatus: boolean;

    // non payload fields
    recipientMemberName: string;
    senderMemberName: string;
    senderName: string;
    recipientName: string;
}

export enum MessageType {
    ALL = '',
    SENT = 'SENT',
    RECEIVED = 'RECEIVED'
}
