<div class="col-sm-12 horizontal-scroll mat-table-container pt-2 pb-2">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="noteGroup">
        <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef class="note-comment"> Comment </th>
            <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
        </ng-container>
        <ng-container matColumnDef="author">
            <th mat-header-cell *matHeaderCellDef> Author </th>
            <td mat-cell *matCellDef="let element"> {{element.authorName}} </td>
        </ng-container>
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef> Created </th>
            <td mat-cell *matCellDef="let element"> {{element.created | date: 'medium'}} </td>
        </ng-container>
        <ng-container class="centered" matColumnDef="completed">
            <th mat-header-cell class="centered" *matHeaderCellDef> Completed </th>
            <td mat-cell class="centered" *matCellDef="let element">
                <i class="fa" [class.fa-check-square-o]="element.closed" [class.fa-square-o]="!element.closed" (click)="onEdit(element)"></i>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
