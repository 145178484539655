import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AccountToken, AuthService, PartnerService, AccountResponse, LandingSettings, BankAccountStatus } from 'projects/services/src/public-api';
import { LoaderComponent } from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

declare let fastlink: any;

@Component({
    selector: 'pt-yodlee-onboarding',
    templateUrl: './yodlee-onboarding.component.html',
    styleUrls: ['./yodlee-onboarding.component.scss']
})
export class YodleeOnboardingComponent implements OnChanges {

    @Input() accountToken: AccountToken;
    @Input() taskId: string;
    @Input() testAccount = false;
    @Input() landingSettings: LandingSettings;

    @Output() resultSelected: EventEmitter<string> = new EventEmitter<string>();

    results: string;
    successful = false;
    duplicate = false;
    rejected = false;
    unsupported = false;
    systemError = false;

    constructor(public authService: AuthService,
                private notificationService: NotificationService,
                private partnerService: PartnerService,
                private overlay: Overlay) {}

    ngOnChanges(changes: SimpleChanges) {
        if (((changes.accountToken && !changes.accountToken.firstChange) ||
            (changes.testAccount && !changes.testAccount.firstChange))
                && this.results !== '') {
            this.results = '';
            this.successful = false;
            this.duplicate = false;
            this.rejected = false;
            this.unsupported = false;
            if (!this.testAccount) {
                this.callFastLink();
            } else {
                this.saveAccount(['CONSUMER_TEST_ACCOUNT']);
            }
        }
    }

    callFastLink() {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        overlayRef.attach(new ComponentPortal(LoaderComponent));

        // Open FastLink
        fastlink.open({
            fastLinkURL: this.accountToken.callbackUrl,
            accessToken: 'Bearer ' + this.accountToken.authToken,
            params: {
                configName: 'Verification'
            },
            forceIframe: true,
            // eslint-disable-next-line no-empty-function
            onSuccess(_data: any) {
            },
            onExit(data: any) {
                window.postMessage(data, window.origin);
            },
            onEvent: function (_data: any) {
                if (overlayRef) {
                    overlayRef.dispose();
                }
            }
        },
        'container-fastlink');
        window.onmessage = (event: any) => {
            if (event.data && event.data.status === 'USER_CLOSE_ACTION') {
                this.rejected = true;
                this.showMessage('You have cancelled linking your account.');
            } else if (event.data && event.data.sites && event.data.sites.length && event.origin.match(/yodlee.com$/i)) {
                if (event.data.sites[0].status === 'ACTION_ABANDONED') {
                    this.rejected = true;
                    this.showMessage('You have cancelled linking your account.');
                } else {
                    const accountIds = event.data.sites.map((site: any) => {
                        return site.accountId;
                    });
                    this.saveAccount(accountIds);
                }
            }
        };
    }

    saveAccount(accountIds: string[]) {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        overlayRef.attach(new ComponentPortal(LoaderComponent));
        this.accountToken.accountIds = accountIds;
        this.successful = false;
        this.duplicate = false;
        this.rejected = false;
        this.partnerService.registerAccount(this.taskId, this.accountToken).subscribe((response: AccountResponse) => {
            overlayRef.dispose();
            let message = '<dl>';
            for (const result of response.results) {
                if (result.accountStatus === BankAccountStatus.ACTIVE) {
                    setTimeout(() => {
                        this.onContinue(`Your account ${result.accountName} was successfully linked.`);
                    }, 500);
                    this.successful = true;
                    break;
                } else if (result.accountStatus === BankAccountStatus.DUPLICATE_BANK_ACCOUNT) {
                    this.duplicate = true;
                } else if (result.accountStatus === BankAccountStatus.REJECTED) {
                    this.rejected = true;
                } else if (result.accountStatus === BankAccountStatus.UNSUPPORTED) {
                    this.unsupported = true;
                } else if (result.accountStatus === BankAccountStatus.SYSTEM_ERROR) {
                    this.systemError = true;
                }
                message += ('<dt>' + result.accountName + ':</dt>');
            }
            message += '</dl>';
            if (this.duplicate) {
                message += 'The account was not linked because it already exists.';
            } else if (this.rejected) {
                message += 'Your account could not be linked because your registration information did not match the bank account information on file.';
            } else if (this.unsupported) {
                message += 'The account could not be linked because the account type is not supported by the system.';
            } else if (this.systemError) {
                message += 'This account could not be linked an error occurred while fetching the account details.';
            }
            if (!this.successful) {
                this.showMessage(message);
            }
        }, (error: any) => {
            overlayRef.dispose();
            this.rejected = true;
            this.showMessage('There was a problem trying to link your account.');
            if (error.status === 402 && error.error.message) {
                this.notificationService.showError(error.error.message);
            }
        });
    }

    showMessage(message: string) {
        this.results = message;
    }

    onContinue(message: string) {
        this.resultSelected.emit(message);
    }

    onCancel() {
        this.resultSelected.emit('Cancel');
    }

    onRetry() {
        this.resultSelected.emit('Retry');
    }

    buttonStyle() {
        return {
            'background': this.landingSettings?.buttonsColor,
            'color': this.landingSettings?.buttonsTextColor
        };
    }
}
