<div class="dashboard-list">
    <div class="row">
        <div class="col-12">
            <div class="dashboard-info-icon">
                <img class="dashboard-icon" src="assets/images/rebate-icon-2.svg" alt="">
                <a href="#">{{'Assets'}}</a>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12">
            <div *ngIf="!tableView">
                <pc-charts-gadgets *ngIf="chartData.length > 0"
                    [chartData]="chartData"
                    [showLegend]="false"
                    [chartType]="ChartType.LINE_CHART"
                    [colorScheme]="colorScheme"
                    [yAxisTickFormatting]="'dollar'">
                </pc-charts-gadgets>
            </div>
        </div>
        <div class="col-12" *ngIf="tableView">
            <div class="col-sm-12 mt-3 mb-3 horizontal-scroll mat-table-container">
                <table mat-table [dataSource]="assets | keyvalue">
                    <ng-container matColumnDef="date">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Date </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.key }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="assets">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Assets </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            <span class="table-amount">
                                {{ element.value ? (element.value | currency) : ( '0.0' | currency) }}
                            </span>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
    <div>
        <pc-view-decision
            [tableView]="tableView"
            (viewChanged)="viewChanged($event)">
        </pc-view-decision>
    </div>
</div>
