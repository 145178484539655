export class CheckDetails {

    memberId: string;
    memberAccountId: string;
    apiOwnerId: string;
    checkCreationDate: string;
    transactionId: string;
    checkNumber: string;
    checkId: string;
    created: Date;

}
