<div>
    <form>
        <div>
            <div class="modal-header">
                <label class="form-title">Cancel Transaction</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div>
                    <pc-text-area [required]="true" [label]="'Reason for Transaction Cancellation:'" [placeholder]="''" [formCtrl]="'reasonCtrl'" [formGroup]="transactionCancellationForm">
                    </pc-text-area>
                </div>
            </div>
            <div class="modal-footer">
                <button class="blue-button" (click)="decline()">Back</button>
                <button class="blue-button" [disabled]="transactionCancellationForm.invalid" (click)="submit()">Confirm Cancellation</button>
            </div>

        </div>
    </form>
</div>
