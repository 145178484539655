<div>
    <form [formGroup]="referralForm" >
        <div>
            <div class="modal-header">
                <label class="form-title">Send Referral</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div>
                    <pc-email-input  [required]="true" [label]="'Email'"  [placeholder]="'Email'" [formCtrl]="'emailCtrl'" [formGroup]="referralForm">
                    </pc-email-input>
                    <pc-text-input [required]="true" [label]="'Company'" [placeholder]="'Company'" [formCtrl]="'memberNameCtrl'" [formGroup]="referralForm">
                    </pc-text-input>
                    <pc-text-area [required]="false" [label]="'Message'" [placeholder]="'Message'" [formCtrl]="'messageCtrl'" [formGroup]="referralForm">
                    </pc-text-area>
                    <pc-text-input [required]="true" [label]="'Referral Code'" [placeholder]="'Referral'" [formCtrl]="'referralCodeCtrl'" [formGroup]="referralForm">
                    </pc-text-input>
                </div>
            </div>
            <div class="modal-footer error-content" >
                <div class="error-message" *ngIf="consolidatedDetails">
                    <span><b>Note:</b> {{ message }}</span>
                    <ul class="comma-list error">
                        <li *ngFor="let referralMatch of referralMatches"> {{ referralMatch.matchingMemberName }} </li>
                    </ul>
                </div>
                <div>
                    <button type="button" class="blue-button pull-left" *ngIf="consolidatedDetails" (click)="close()"> {{ 'Cancel' }} </button>
                    <button type="button" class="blue-button pull-right" *ngIf="consolidatedDetails && !showProceedButton" (click)="reset()"> {{ 'Reset' }} </button>
                    <button type="button" class="blue-button pull-right" *ngIf="consolidatedDetails && showProceedButton" (click)="sendReferralToMember()"> {{ 'Invite Anyway '}} </button>
                    <pc-submit-button class="pull-right" *ngIf="!consolidatedDetails" [enabled]="formValid" [submit]="onSubmit" [text]="'Send'"></pc-submit-button>
                </div>
            </div>
        </div>
    </form>
</div>
