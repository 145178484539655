<div class="dashboard-info" *ngIf="member">
    <div class="dashboard-header">
        <img class="dashboard-icon" src="assets/images/settings-icon-2.svg" alt="">
        <span>Settings</span>
    </div>

    <div class="row col-12">
        <div class="col-3">
            <div class="col-12 tab-col" *ngIf="smartContractsEnabled()" [ngClass]="getTabClass('smart_contracts')" (click)="changedTab('smart_contracts')">
                Smart Contract Uploads
            </div>
            <div class="col-12 tab-col" [ngClass]="getTabClass('authority_users')" (click)="changedTab('authority_users')">
                Authority Users
            </div>
        </div>
        <div class="col-9 pull-right" *ngIf="activeTab === 'smart_contracts' && smartContractsEnabled()">
            <pc-sc-smart-contract-upload>
            </pc-sc-smart-contract-upload>
        </div>
        <div class="col-9 pull-right" *ngIf="activeTab === 'authority_users'">
            <pc-authority-users-list
                    [memberId]="member.id">
            </pc-authority-users-list>
        </div>
    </div>
</div>
