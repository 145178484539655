export class PartnerSettings{
    id?: string;
    created: Date;
    updated: Date;
    memberId?: string;
    enableSMSValidation: boolean;
    acceptPayments: boolean;
    sendPayments: boolean;
    memberCallbackUri: string;
    paymentCallbackUri: string;
    username: string;
    password: string;
}
