import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http.service';
import { AuthService } from './auth/auth.service';
import { MessageType } from '../public-api';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    baseUrl: string;
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    constructor(private http: HttpService,
                private authService: AuthService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.DataroomAPIEndpoint + '/';
    }

    countReceivedMessages() {
        const params = '?readStatus=false';
        return this.http.head(`${this.baseUrl}message${params}`);
    }

    loadSentMessages(counterParty: string, startDate: string, endDate: string, type: MessageType, start: number, size: number, sort: string, sortDir: string) {
        if (this.authService.isAuthority()) {
            const params = `?type=${type}&counterParty=${counterParty}&senderMemberId=${this.authService.getProfile().memberId}&startDate=${startDate}&endDate=${endDate}&timezone=${this.timezone}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
            return this.http.get(`${this.baseUrl}message${params}`);
        } else {
            const params = `?type=${type}&senderId=${this.authService.getProfile().userId}&counterParty=${counterParty}&startDate=${startDate}&endDate=${endDate}&timezone=${this.timezone}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
            return this.http.get(`${this.baseUrl}message${params}`);
        }
    }

    loadReceivedMessages(counterParty: string, startDate: string, endDate: string, type: MessageType,  start: number, size: number, sort: string, sortDir: string, readStatus: boolean) {
        let params = `?type=${type}&counterParty=${counterParty}&startDate=${startDate}&endDate=${endDate}&timezone=${this.timezone}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        if (readStatus !== null) {
            params += `&readStatus=${readStatus}`;
        }
        return this.http.get(`${this.baseUrl}message${params}`);
    }

    loadAllMessages(counterParty: string, type: MessageType, startDate: string, endDate: string, start: number, size: number, sort: string, sortDir: string) {
        if (this.authService.isAuthority()) {
            const params = `?type=${type}&counterParty=${counterParty}&startDate=${startDate}&endDate=${endDate}&timezone=${this.timezone}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
            return this.http.get(`${this.baseUrl}message${params}`);
        } else {
            const params = `?type=${type}&counterParty=${counterParty}&senderId=${this.authService.getProfile().userId}&startDate=${startDate}&endDate=${endDate}&timezone=${this.timezone}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
            return this.http.get(`${this.baseUrl}message${params}`);
        }
    }

    sendMessage(file: File, fileName: string, subject: string, body: string, recipientMemberId: string, recipientId: string, parentMessageId: string) {
        const formData = new FormData();
        formData.append('file', file);
        const metadata = {
            fileName,
            subject,
            body,
            recipientMemberId: recipientMemberId,
            parentMessageId,
            recipientId
        };
        formData.append('metadata', JSON.stringify(metadata));
        return this.http.post(`${this.baseUrl}message`, formData);
    }

    sendMessageNoAttachment(subject: string, body: string, recipientMemberId: string, recipientId: string, parentMessageId: string) {
        const formData = new FormData();
        const message = {
            subject,
            body,
            recipientMemberId: recipientMemberId,
            parentMessageId,
            recipientId
        };
        formData.append('metadata', JSON.stringify(message));
        return this.http.post(`${this.baseUrl}message`, formData);
    }

    updateMessageReadStatus(id: string, body: any) {
        return this.http.put(`${this.baseUrl}message/${id}`, body);
    }

}
