import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import {
    RetailTransactionInitiationRequest, PartnerService, WorkflowService, MemberAccount, AddressService, Address, PagedResponse,
    AuthService, ConnectedMemberService, Task, Member, MemberStatus, MemberService, CustomValidators
} from 'projects/services/src/public-api';
import { BaseModalComponent, NumericInputComponent } from 'projects/components/src/public-api';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pt-retail-payment-modal',
    styleUrls: ['./retail-payment-modal.component.scss'],
    templateUrl: './retail-payment-modal.component.html'
})
export class RetailPaymentModalComponent extends BaseModalComponent<RetailPaymentModalComponent> implements OnInit, OnDestroy {

    newPaymentForm: UntypedFormGroup;
    verificationForm: UntypedFormGroup;
    pageNumber = 1;
    pageTitle = 'New Retail Payment';
    amount: number;
    phone: string;
    onboardingRequired = false;
    status = '';
    processInstanceId = '';
    consumer: Member;
    submitting = false;
    interval: any;
    consumerAccounts: MemberAccount[] = [];
    displayedColumns: string[] = ['name', 'email', 'address'];

    paginationSize = 5;
    resultsLength = 0;
    searching = false;
    subscription: any;

    @ViewChild('verificationCodeCtrl') verificationCodeField: NumericInputComponent;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private partnerService: PartnerService,
                private authService: AuthService,
                private notificationService: NotificationService,
                private memberService: MemberService,
                private addressService: AddressService,
                private workflowService: WorkflowService,
                private connectedMemberService: ConnectedMemberService,
                dialogRef: MatDialogRef<RetailPaymentModalComponent>) {
        super(dialogRef);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngOnInit() {
        this.isPaymentFormValid = this.isPaymentFormValid.bind(this);
        this.isVerificationFormValid = this.isVerificationFormValid.bind(this);
        this.initiateRetailFlow = this.initiateRetailFlow.bind(this);
        this.verifyAndInitiateTransaction = this.verifyAndInitiateTransaction.bind(this);
        this.cancelTransaction = this.cancelTransaction.bind(this);
        this.isNotSubmitting = this.isNotSubmitting.bind(this);
        this.searchConsumers = this.searchConsumers.bind(this);
        this.isConsumerSelected = this.isConsumerSelected.bind(this);
        this.isConsumerNotSelected = this.isConsumerNotSelected.bind(this);

        this.newPaymentForm = new UntypedFormGroup({
            amountCtrl: new UntypedFormControl('', [Validators.required, Validators.min(1), Validators.max(10000)]),
            phoneCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.phone])
        });
        this.verificationForm = new UntypedFormGroup({
            codeCtrl: new UntypedFormControl('', [Validators.required, Validators.min(100000), Validators.max(999999)])
        });
    }

    initiateRetailFlow(reset?: any) {
        this.amount = this.newPaymentForm.get('amountCtrl').value;
        const payorAccountId = (this.consumerAccounts.find((consumerAccount) => {
            return consumerAccount.selected;
        }) || {}).id;
        const retailLocationId = this.authService.getProfile().retailLocationId;
        const recipientAccountId = this.authService.getProfile().retailAccountId;
        const retailTransactionRequest: RetailTransactionInitiationRequest = {
            amount: this.newPaymentForm.get('amountCtrl').value,
            phone: this.newPaymentForm.get('phoneCtrl').value,
            bnplAmount: 0.0,
            payorAccountId,
            retailLocationId,
            recipientAccountId
        };
        this.partnerService.initiateRetailTransaction(retailTransactionRequest).subscribe((response: any) => {
            this.onboardingRequired = response.onboardingRequired;
            this.processInstanceId = response.processInstanceId;
            if (this.onboardingRequired) {
                this.pageTitle = 'Wait for Customer Information';
                this.pageNumber = 3;
                this.pollForConsumer();
            } else {
                this.pageTitle = 'Verify Transaction';
                this.pageNumber = 4;
                this.loadConsumerInfo();
            }
        }, (error: any) => {
            if (reset) {
                reset();
            }
            throw error;
        });
    }

    verifyAndInitiateTransaction(reset?: any) {
        this.submitting = true;
        this.notificationService.clear();
        this.status = '';
        const verificationRequest = {
            code: this.verificationForm.get('codeCtrl').value
        };
        this.partnerService.verifyAndInitiateRetailTransaction(this.processInstanceId, verificationRequest).subscribe((response: any) => {
            this.submitting = false;
            this.status = response.status;
            this.pageNumber = 5;
        }, (error: any) => {
            this.submitting = false;
            if (reset) {
                reset();
            }
            if (error.status === 410) {
                this.pageNumber = 5;
                this.status = 'TIMEOUT';
            } else if (error.status !== 403 && error.status !== 402) {
                this.pageNumber = 5;
                this.status = 'CANCELLED';
            } else if ((error.status === 403 || error.status === 402) && error.error.message) {
                this.notificationService.showError(error.error.message);
            } else {
                throw error;
            }
        });
    }

    pollForConsumer() {
        this.interval = setInterval(() => {
            this.workflowService.getProcessInstance(this.processInstanceId).subscribe((_processInstance: any) => {
                this.workflowService.getTaskByProcessInstanceId(this.processInstanceId, false).subscribe((taskQueryResponse: PagedResponse<Task>) => {
                    if (taskQueryResponse.content.length > 0) {
                        this.clearInterval();
                        this.pageTitle = 'Verify Transaction';
                        this.pageNumber = 4;
                        this.loadConsumerInfo();
                    }
                }, (_error: any) => {
                    this.clearInterval();
                    this.pageNumber = 5;
                    this.status = 'CANCELLED';
                });
            }, (_error: any) => {
                this.clearInterval();
                this.pageNumber = 5;
                this.status = 'TIMEOUT';
            });
        }, 15 * 1000);
    }

    clearInterval() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    loadConsumerInfo() {
        this.workflowService.getTaskByProcessInstanceId(this.processInstanceId, false).subscribe((tasks: PagedResponse<Task>) => {
            const task = tasks.content[0];
            const consumerId = task.variables.consumerId;
            this.memberService.loadMember(consumerId).subscribe((consumer: Member) => {
                if (consumer.status === MemberStatus.BLOCKED) {
                    this.pageTitle = 'Transaction Cancelled';
                    this.pageNumber = 5;
                    this.status = 'CONSUMER_BLOCKLISTED';
                    this.workflowService.deleteProcessInstance(this.processInstanceId).subscribe();
                    this.clearInterval();
                } else {
                    this.consumer = consumer;
                    this.addressService.getPrimaryAddress(consumerId).subscribe((location: Address) => {
                        this.consumer.address = location;
                        setTimeout(() => {
                            this.verificationCodeField.focus();
                        }, 500);
                    });
                }
            });
        });
    }

    cancelTransaction() {
        this.clearInterval();
        this.workflowService.deleteProcessInstance(this.processInstanceId).subscribe(() => {
            this.pageNumber = 5;
            this.status = 'CANCELLED_BY_USER';
        }, () => {
            this.pageNumber = 5;
            this.status = 'CANCELLED_BY_USER';
        });
    }

    searchConsumers() {
        this.searching = true;
        this.consumerAccounts = [];
        this.pageTitle = 'Select Customer';
        this.pageNumber = 2;
        this.amount = this.newPaymentForm.get('amountCtrl').value;
        this.phone = this.newPaymentForm.get('phoneCtrl').value;
        setTimeout(() => {
            this.addTableLoadListener();
        }, 200);
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page).pipe(
            startWith({}),
            switchMap(() => {
                this.consumerAccounts = [];
                this.searching = true;
                return this.connectedMemberService.searchAvailableConsumers(this.authService.getProfile().memberId,
                    this.newPaymentForm.get('phoneCtrl').value,
                    this.paginator.pageIndex,
                    this.paginator.pageSize,
                    this.sort.active,
                    this.sort.direction);
            }),
            map((data: PagedResponse<MemberAccount>) => {
                this.searching = false;
                this.resultsLength = data.totalElements;
                return data.content || [];
            }),
            catchError(() => {
                this.searching = false;
                return observableOf([]);
            })
        ).subscribe((data: MemberAccount[]) => {
            this.consumerAccounts = data;
            this.consumerAccounts.forEach((consumerAccount) => {
                this.addressService.getPrimaryAddress(consumerAccount.member.id).subscribe(
                    (location: Address) => {
                        consumerAccount.address = location;
                    }
                );
                consumerAccount.member.email = this.mask(consumerAccount.member.email);
            });
        });
    }

    mask(email: string) {
        let emailValues = email.split('');
        let finalArr = [];
        let len = emailValues.indexOf('@');
        emailValues.forEach((_item, pos) => {
            (pos >= 2 && pos <= len - 3) ? finalArr.push('*') : finalArr.push(emailValues[pos]);
        });
        return finalArr.join('');
    }

    selectMemberAccount(memberAccount: MemberAccount) {
        this.consumerAccounts.forEach((consumerAccount) => {
            if (consumerAccount.id === memberAccount.id) {
                consumerAccount.selected = !consumerAccount.selected;
            } else {
                consumerAccount.selected = false;
            }
        });
    }

    isNotSubmitting() {
        return !this.submitting;
    }

    isPaymentFormValid() {
        return this.newPaymentForm.valid;
    }

    isVerificationFormValid() {
        return this.verificationForm.valid;
    }

    isConsumerSelected() {
        return this.consumerAccounts.filter((consumerAccount) => {
            return consumerAccount.selected;
        }).length > 0;
    }

    isConsumerNotSelected() {
        return this.consumerAccounts.filter((consumerAccount) => {
            return consumerAccount.selected;
        }).length === 0;
    }

    close() {
        super.close(true);
    }
}
