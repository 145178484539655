import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class AuditService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.AuditAPIEndpoint + '/';
    }

    getAuditRecords(memberId: string, action: string, auditEntityType: string, start: number, size: number, sort: string, sortDir: string) {
        const params = `?memberId=${memberId}&action=${action}&entityType=${auditEntityType}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}audit${params}`);
    }

    getAuditRecordsByReferenceId(referenceEntityId: string, auditEntityType: string) {
        const params = `?referenceEntityId=${referenceEntityId}&entityType=${auditEntityType}`;
        return this.http.get(`${this.baseUrl}audit${params}`);
    }

}
