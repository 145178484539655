import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Referral, ReferralFormService, ReferralMatches, PagedResponse, MemberService } from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pc-referral-modal',
    templateUrl: './referral-modal.component.html',
    styleUrls: ['./referral-modal.component.scss']
})
export class ReferralModalComponent extends BaseModalComponent<ReferralModalComponent> implements OnInit {

    memberName: string;
    referralCode: string;
    userName: string;

    referralForm: UntypedFormGroup;
    showProceedButton = true;
    validateForm = true;
    consolidatedDetails = false;

    header = '';
    message = '';
    referralMatches: ReferralMatches[] = [];
    referral: Referral;

    constructor(public referralFormService: ReferralFormService,
                public dialogRef: MatDialogRef<ReferralModalComponent>,
                public memberService: MemberService,
                private notifier: NotificationService,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberName = data.memberName;
        this.referralCode = data.referralCode;
        this.userName = data.userName;
    }

    ngOnInit() {
        this.formValid = this.formValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.referralForm = this.referralFormService.initializeForm();
        this.referralForm.patchValue({
            referralCodeCtrl: this.referralCode
        });
        this.referralForm.get('referralCodeCtrl').disable();

    }

    close(message?: any) {
        super.close(message);
    }

    onSubmit() {
        this.referral = new Referral();
        this.referral.email = this.referralForm.get('emailCtrl').value;
        this.referral.refereeMemberName = this.referralForm.get('memberNameCtrl').value;
        this.referral.message = this.referralForm.get('messageCtrl').value;
        this.referral.referrerMemberName = this.memberName;
        this.referral.referrerName = this.userName;
        this.referral.referrerCode = this.referralForm.get('referralCodeCtrl').value;
        this.memberService.checkForMatchingMembers(this.referral).subscribe((response: PagedResponse<ReferralMatches>) => {
            this.referralMatches = response.content;
            this.sendReferral(this.referralMatches);
        });
    }

    sendReferral(referralMatches: ReferralMatches[]) {
        if (referralMatches.length === 0) {
            this.sendReferralToMember();
        } else {
            this.referralMatchesByEmailAndCompany(referralMatches);
        }
    }

    sendReferralToMember() {
        this.memberService.sendReferralToMember(this.referral).subscribe(() => {
            this.close();
            this.notifier.showSuccess(`Your referral has been sent for <b>${this.referral.refereeMemberName}</b> to <b>${this.referral.email}</b>.`);
        },
        (errorResult: any) => {
            throw errorResult;
        });
    }

    formValid() {
        return !this.referralForm.disabled && !this.referralForm.invalid;
    }

    referralMatchesByEmailAndCompany(referralMatches: ReferralMatches[]) {
        this.consolidatedDetails = true;
        if (referralMatches[0].exactMatchByEmail && referralMatches.length === 1) {
            this.message = 'The following company with email ' + this.referral.email + ' has already been registered:';
        } else if (referralMatches[0].exactMatchByEmail && referralMatches.length > 1) {
            this.message = 'The following companies with email ' + this.referral.email + ' have already been registered:';
        } else if (!referralMatches[0].exactMatchByEmail && referralMatches.length > 1) {
            this.message = 'The following companies with similar names have already been registered:';
        } else if (!referralMatches[0].exactMatchByEmail && referralMatches.length === 1) {
            if (referralMatches[0].exactMatchByMember) {
                this.showProceedButton = false;
                this.message = 'A company with the same name has already been registered: ';
            } else {
                this.message = 'The following company with a similar name has already been registered:';
            }
        }
    }

    reset() {
        this.showProceedButton = true;
        this.validateForm = true;
        this.consolidatedDetails = false;
        this.referralForm.reset();
        this.ngOnInit();
    }

}
