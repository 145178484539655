<div>
    <div>
        <div class="modal-header">
            <label class="form-title">Select Account</label>
            <i class="modal-close" (click)="close()">&times;</i>
        </div>
        <div class="modal-content">
            <div *ngIf="memberAccounts.length > 0">
                <div class="row mb-3">
                    <div class="col-12" >
                        <mat-card class="shadow mb-2" *ngFor="let memberAccount of memberAccounts" [ngClass]="selectedMemberAccount && selectedMemberAccount.id === memberAccount.id ? 'selected' : ''" (click)="memberSelectionChanged(memberAccount)">
                            <div class="d-flex row mb-2">
                                <div class="col-6 account-name">
                                    <div><b>{{memberAccount.accountName}}</b></div>
                                    <div>{{memberAccount.accountFriendlyName}}</div>
                                </div>
                                <div class="balances col-6" *ngIf="memberAccount.wallet">
                                    <dl class="row">
                                        <dt class="col-8">Available Balance<i class="fa fa-question-circle-o" matTooltip="The available balance includes all completed payments and deposits, as well as any pending ACH/WIRE payments. Pending checks and scheduled payments have not been deducted from the available balance."></i>:</dt>
                                        <dd class="col-4 text-end" [ngClass]="memberAccount.wallet.availableBalance < 0 ? 'negative-amount' : ''">{{ memberAccount.wallet.availableBalance | currency }}</dd>
                                    </dl>
                                    <dl class="row">
                                        <dt class="col-8">Pending Incoming Amount<i class="fa fa-question-circle-o" matTooltip="Pending incoming funds have not yet completed. They have not yet been added to your available balance."></i>:</dt>
                                        <dd class="col-4 text-end">{{ memberAccount.wallet.pendingBalance | currency }}</dd>
                                    </dl>
                                    <dl class="row">
                                        <dt class="col-8">Outstanding Check Amount<i class="fa fa-question-circle-o" matTooltip="Outstanding amount of all pending checks that have not yet been cashed. They have not been deducted from your available balance."></i>:</dt>
                                        <dd class="col-4 text-end" [ngClass]="memberAccount.wallet.outstandingCheckAmount > 0 ? 'negative-amount' : ''">{{ memberAccount.wallet.outstandingCheckAmount | currency }}</dd>
                                    </dl>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="blue-button" (click)="close()">Cancel</button>
            <pc-submit-button [enabled]="isAccountSelected" [submit]="onSubmit" [text]="'Select'"></pc-submit-button>
        </div>
    </div>
</div>
