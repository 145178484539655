import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorityReportsDetailsComponent, AuthorityReportsTableHeaderComponent, MemberReportsTableHeaderComponent } from './components';
import { AuthGuard, Role } from 'projects/services/src/public-api';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: MemberReportsTableHeaderComponent,
        canActivate: [AuthGuard],
        data: { title: 'Reports' }
    },
    {
        path: 'authority/list',
        component: AuthorityReportsTableHeaderComponent,
        canActivate: [AuthGuard],
        data: { title: 'Reports', roles: [Role.AUTHORITY_READONLY] }
    },
    {
        path: 'details/:bsaReportId',
        component: AuthorityReportsDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Reports', roles: [Role.AUTHORITY_READONLY] }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ReportsRoutingModule { }
