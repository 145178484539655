export class RiskProfile {
    id: string;
    created: Date;
    memberId: string;
    memberAccountId: string;

    expectedMonthlyCashDepositAmount: number;
    monthlyCashDepositAmountLimit: number;
    accountAmountLimit: number;
    expectedMonthlyTransactions: number;
    expectedMonthlyPaymentAmount: number;

    maxNumberOfTransactionsPerDay: number;
    maxNumberOfTransactionsPerMonth: number;
    maxTransactionTotal: number;
    maxTransactionTotalPerMonth: number;

}
