import { Component, Input, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Utils } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-percentage-input',
    templateUrl: './percentage-input.component.html'
})
export class PercentageInputComponent implements AfterViewInit {

    @Input() required: boolean;
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() ownership = false;
    @Input() allowDecimals = false;
    @Input() class = 'form-input-editable';

    ngAfterViewInit() {
        const existing: any = this.formGroup.controls[this.formCtrl].value;
        if (existing !== null) {
            this.formGroup.controls[this.formCtrl].patchValue(existing);
        }
    }

    onInputChanged(event: any) {
        if (Utils.isNumericKeyCode(event.keyCode) || event.keyCode === 190 || event.keyCode === 37 ||
            event.keyCode === 39 || event.keyCode === 17 || event.keyCode === 9 || event.keyCode === 8) {
            return;
        } else if ((event.ctrlKey && event.keyCode === 86) || (event.metaKey && event.keyCode === 86) || (event.ctrlKey && event.keyCode === 65)) {
            return;
        }
        event.preventDefault();
    }

    getMaxLength() {
        if (this.allowDecimals) {
            return 7;
        }
        return 3;
    }

}
