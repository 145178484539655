import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarNotificationComponent } from 'projects/components/src/lib/snackbar-notifications/snackbar-notification.component';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private zone: NgZone,
                public snackBar: MatSnackBar) {
    }

    showError(message: string) {
        const error = !navigator.onLine ? 'Browser offline...' : message;
        this.zone.run(() => {
            this.snackBar.openFromComponent(SnackbarNotificationComponent, {
                data: {
                    notificationType: 'ERROR',
                    message: error
                }
            });
        });
    }

    showSuccessCloseRequired(message: string) {
        this.zone.run(() => {
            this.snackBar.openFromComponent(SnackbarNotificationComponent, {
                data: {
                    notificationType: 'SUCCESS',
                    closeRequired: true,
                    message
                }
            });
        });
    }

    showSuccess(message: string, duration?: number) {
        this.zone.run(() => {
            this.snackBar.openFromComponent(SnackbarNotificationComponent, {
                duration: duration || 3000,
                data: {
                    notificationType: 'SUCCESS',
                    closeRequired: false,
                    message
                }
            });
        });
    }

    showInfo(message: string) {
        this.zone.run(() => {
            this.snackBar.openFromComponent(SnackbarNotificationComponent, {
                horizontalPosition: 'right',
                verticalPosition: 'bottom',
                duration: 5000,
                data: {
                    notificationType: 'INFO',
                    message
                }
            });
        });
    }

    showNotification(notification: {message: string, type: 'ERROR' | 'INFO' | 'SUCCESS'}) {
        if (notification.type === 'ERROR') {
            this.showError(notification.message);
        } else if (notification.type === 'INFO') {
            this.showInfo(notification.message);
        } else {
            this.showSuccess(notification.message);
        }
    }

    clear() {
        this.snackBar.dismiss();
    }

}