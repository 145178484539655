<div>
    <div class="row">
        <div class="col-5 my-3">
            <span class="form-title">Balance Sheet</span>
        </div>
        <div class="row">
            <div class="col-4">
                <pc-form-date-picker [label]="'Latest Month'"
                    [formCtrl]="'dateCtrl'"
                    [formGroup]="formGroup"
                    [type]="'SINGLE'"
                    [historical]="true"
                    [defaultToday]="true"
                    (dateSelected)="onDateChanged($event)">
                </pc-form-date-picker>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">Period Length</label>
                    </div>
                    <div class="col-12">
                        <select class="form-control full" [(ngModel)]="periodLength"  (change)="changedPeriods()">
                            <option value="1">1 month</option>
                            <option value="2">2 month</option>
                            <option value="3">3 month</option>
                            <option value="6">6 month</option>
                            <option value="12">12 month</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <label class="form-label">Periods To Compare</label>
                    </div>
                    <div class="col-12">
                        <select class="form-control full" [(ngModel)]="periodsToCompare"  (change)="changedPeriods()">
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="horizontal-scroll">
    <table class="mat-table no-wrap mat-sort table-sm">
        <thead class="mat-sort-header mat-header-cell">
            <tr class="mat-header-row cdk-header-row">
                <th class="mat-header-cell"></th>
                <ng-container *ngFor="let balanceReport of balanceReports">
                    <th class="centered mat-header-cell">
                        {{balanceReport.date | date:'mediumDate'}}
                    </th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr class="mat-row mat-cell">
                <td class="mat-cell">
                    <b>Assets</b>
                    <i *ngIf="isAssetsShow" (click)="expandAssets()" class="fa fa-angle-up"></i>
                    <i *ngIf="!isAssetsShow" (click)="expandAssets()" class="fa fa-angle-down"></i>
                </td>
                <ng-container *ngFor="let balanceReport of balanceReports">
                    <td class="mat-cell centered">
                        <span class="table-amount"><b>{{balanceReport.assets.value | currency}}</b></span>
                    </td>
                </ng-container>
            </tr>

            <ng-container *ngIf="balanceReports && isAssetsShow">
                <tr *ngFor="let financialItem of balanceReports[0].assets.items; let i = index" class="mat-row">
                    <td class="mat-cell">
                        <div class="indent-sm pb-2">{{financialItem.name}}</div>
                        <ng-container *ngIf="financialItem.items">
                            <ng-container *ngFor="let subFinancialItem of financialItem.items">
                                <div class="indent-md">{{subFinancialItem.name}}</div>
                            </ng-container>
                        </ng-container>
                        <div class="indent-sm pt-2"><b>Total {{financialItem.name}}</b></div>
                    </td>
                    <ng-container *ngFor="let balanceReport of balanceReports">
                        <td class="mat-cell centered">
                            <div class="pt-3">
                                <ng-container *ngIf="balanceReport.assets.items[i] && balanceReport.assets.items[i].items">
                                    <div *ngFor="let subFinancialItem of balanceReport.assets.items[i].items">
                                        <span class="table-amount">{{(subFinancialItem.value ? subFinancialItem.value : 0) | currency}}</span>
                                    </div>
                                </ng-container>
                                <span class="table-amount pt-2"><b>{{(balanceReport.assets.items[i] ? balanceReport.assets.items[i].value : 0) | currency}}</b></span>
                            </div>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>

            <tr class="mat-row mat-cell">
                <td class="mat-cell">
                    <b>Liabilities</b>
                    <i *ngIf="isLiabilitiesShow" (click)="expandLiabilities()" class="fa fa-angle-up"></i>
                    <i *ngIf="!isLiabilitiesShow" (click)="expandLiabilities()" class="fa fa-angle-down"></i>
                </td>
                <ng-container *ngFor="let balanceReport of balanceReports">
                    <td class="mat-cell centered">
                        <span class="table-amount"><b>{{balanceReport.liabilities.value | currency}}</b></span>
                    </td>
                </ng-container>
            </tr>

            <ng-container *ngIf="balanceReports && isLiabilitiesShow">
                <tr *ngFor="let financialItem of balanceReports[0].liabilities.items; let i = index" class="mat-row">
                    <td class="mat-cell">
                        <div class="indent-sm pb-2">{{financialItem.name}}</div>
                        <ng-container *ngIf="financialItem.items">
                            <ng-container *ngFor="let subFinancialItem of financialItem.items">
                                <div class="indent-md">{{subFinancialItem.name}}</div>
                            </ng-container>
                        </ng-container>
                        <div class="indent-sm pt-2"><b>Total {{financialItem.name}}</b></div>
                    </td>
                    <ng-container *ngFor="let balanceReport of balanceReports">
                        <td class="mat-cell centered">
                            <div class="pt-3">
                                <ng-container *ngIf="balanceReport.liabilities.items[i] && balanceReport.liabilities.items[i].items">
                                    <div *ngFor="let subFinancialItem of balanceReport.liabilities.items[i].items">
                                        <span class="table-amount">{{(subFinancialItem.value ? subFinancialItem.value : 0) | currency}}</span>
                                    </div>
                                </ng-container>
                                <span class="table-amount pt-2"><b>{{(balanceReport.liabilities.items[i] ? balanceReport.liabilities.items[i].value : 0) | currency}}</b></span>
                            </div>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>

            <tr class="mat-row mat-cell">
                <td class="mat-cell">
                    <b>Net Assets</b>
                </td>
                <ng-container *ngFor="let balanceReport of balanceReports">
                    <td class="mat-cell centered">
                        <span class="table-amount"><b>{{balanceReport.netAssets | currency}}</b></span>
                    </td>
                </ng-container>
            </tr>

            <tr class="mat-row mat-cell">
                <td class="mat-cell">
                    <b>Equity</b>
                    <i *ngIf="isEquityShow" (click)="expandEquity()" class="fa fa-angle-up"></i>
                    <i *ngIf="!isEquityShow" (click)="expandEquity()" class="fa fa-angle-down"></i>
                </td>
                <ng-container *ngFor="let balanceReport of balanceReports">
                    <td class="mat-cell centered">
                        <span class="table-amount"><b>{{balanceReport.equity.value | currency}}</b></span>
                    </td>
                </ng-container>
            </tr>

            <ng-container *ngIf="balanceReports && isEquityShow">
                <tr *ngFor="let financialItem of balanceReports[0].equity.items; let i = index" class="mat-row">
                    <td class="mat-cell">
                        <div class="indent-sm pb-2">{{financialItem.name}}</div>
                        <ng-container *ngIf="financialItem.items">
                            <ng-container *ngFor="let subFinancialItem of financialItem.items">
                                <div class="indent-md">{{subFinancialItem.name}}</div>
                            </ng-container>
                        </ng-container>
                        <div class="indent-sm pt-2"><b>Total {{financialItem.name}}</b></div>
                    </td>
                    <ng-container *ngFor="let balanceReport of balanceReports">
                        <td class="mat-cell centered">
                            <div class="pt-3">
                                <ng-container *ngIf="balanceReport.equity.items[i] && balanceReport.equity.items[i].items">
                                    <div *ngFor="let subFinancialItem of balanceReport.equity.items[i].items">
                                        <span class="table-amount">{{(subFinancialItem.value ? subFinancialItem.value : 0) | currency}}</span>
                                    </div>
                                </ng-container>
                                <span class="table-amount pt-2"><b>{{(balanceReport.equity.items[i] ? balanceReport.equity.items[i].value : 0) | currency}}</b></span>
                            </div>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
