<fieldset class="form-group" *ngIf="formCtrl">
    <div class="row">
        <div class="col-12" *ngIf="label">
            <label class="form-label">{{ label | translate }}</label>
        </div>
        <div [formGroup]="formGroup" class="text-input">
            <input type="text" class="form-control form-input-editable" [ngClass]="class" [placeholder]="placeholder" [formControlName]="formCtrl" (change)="onAmountChanged()" />
        </div>
    </div>
</fieldset>
