import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MessageFormService, MessageService, Message, Utils, MemberType, Member } from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pc-message-modal',
    templateUrl: './message-modal.component.html'
})
export class MessageModalComponent extends BaseModalComponent<MessageModalComponent> implements OnInit {

    MemberType = MemberType;

    newMessageForm: UntypedFormGroup;
    files: FileList = null;
    recipientMemberId: string;
    recipientId: string;
    recipientMemberName: string;
    parentMessageId: string;
    isReply = false;

    constructor(private messageFormService: MessageFormService,
                private messageService: MessageService,
                private notifier: NotificationService,
                dialogRef: MatDialogRef<MessageModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.newMessageForm = this.messageFormService.initializeForm(data);
        this.newMessageForm.get('recipientNameCtrl').disable();

        if (data.recipientMemberId) {
            this.recipientMemberId = data.recipientMemberId;
            this.recipientMemberName = data.recipientMemberName;
            this.recipientId = data.recipientId;
            this.isReply = true;
        }
        this.parentMessageId = data.parentMessageId;
    }

    ngOnInit() {
        this.formEnabled = this.formEnabled.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.setMember = this.setMember.bind(this);
    }

    close(message?: any) {
        this.files = null;
        super.close(message);
    }

    onSubmit(reset: any) {
        const message = this.messageFormService.getMessage(this.newMessageForm);
        message.recipientMemberId = this.recipientMemberId;
        message.recipientId = this.recipientId;
        this.sendMessage(message, this.recipientMemberName, reset);
    }

    sendMessage(message: Message, recipient: string, reset: any) {
        if (this.files && this.files.length > 0) {
            const file: File = this.files[0];
            this.messageService.sendMessage(file, file.name, message.subject, message.body, message.recipientMemberId, message.recipientId, this.parentMessageId).subscribe(() => {
                this.close(true);
                this.notifier.showSuccess(`${this.isReply ? 'Response' : 'Message'} has successfully been sent to <b>${recipient}</b>.`);
            }, (error: any) => {
                reset();
                this.notifier.showError('Document upload was unsuccessful. Please check your connection and try again.');
            });
        } else {
            // no attachment. Send the message as text
            this.messageService.sendMessageNoAttachment(message.subject, message.body, message.recipientMemberId, message.recipientId, this.parentMessageId).subscribe(() => {
                this.close(true);
                this.notifier.showSuccess(`${this.isReply ? 'Response' : 'Message'} has successfully been sent to <b>${recipient}</b>.`);
            });
        }
    }

    formEnabled() {
        return this.newMessageForm && this.newMessageForm.valid;
    }

    selectFile(event: any) {
        for (const file of event.target.files) {
            Utils.validateFile(file, event);
        }
        this.files = event.target.files;
    }

    setMember(member: Member) {
        this.recipientMemberId = member.id;
        this.recipientMemberName = member.name;
    }

}
