import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import {
    Address, AddressService, BankAccount, BankAccountService, BankAccountStatus, Member, MemberService, MemberStatus, MemberType, PagedResponse, RiskTier, Utils, WorkflowService
} from 'projects/services/src/public-api';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pt-bank-account-search-results',
    templateUrl: './bank-account-search-results.component.html',
    styleUrls: ['./bank-account-search-results.component.scss']
})
export class BankAccountSearchResultsComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    BankAccountStatus = BankAccountStatus;
    MemberType = MemberType;
    MemberStatus = MemberStatus;
    Utils = Utils;
    RiskTier = RiskTier;

    @Input() searchTerm: string;

    filterEvent: EventEmitter<null> = new EventEmitter<null>();
    isLoadingResults = true;
    resultsLength = 0;
    subscription: any;
    pageTracking: PageTracking;
    defaultSize = 10;
    displayedColumns = ['business_name', 'member_status', 'account_number', 'ach_routing_number', 'wire_routing_number', 'status', 'financial_institution'];
    bankAccounts: BankAccount[] = [];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private addressService: AddressService,
                private workflowService: WorkflowService,
                private memberService: MemberService,
                private bankAccountService: BankAccountService,
                private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'created', 'asc', this.defaultSize);
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.searchTerm && this.pageTracking) {
            this.filterEvent.emit();
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.bankAccountService.searchBankAccounts(this.searchTerm,
                    this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<BankAccount>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((response: BankAccount[]) => {
            this.bankAccounts = response;
            for (const bankAccount of this.bankAccounts) {
                this.loadMemberDetails(bankAccount);
            }
        });
    }

    loadMemberDetails(bankAccount: BankAccount) {
        this.memberService.loadMember(bankAccount.memberId).subscribe((member: Member) => {
            if (member) {
                bankAccount.member = member;
                this.addressService.getPrimaryAddress(bankAccount.memberId).subscribe((location: Address) => {
                    bankAccount.member.address = location;
                });
            } else {
                member = new Member();
                member.name = 'Orphaned record';
                bankAccount.member = member;
            }
        });
    }

    memberDetails(event: any, member: Member) {
        if (event.target && event.target.tagName !== 'I') {
            if (member.memberType === MemberType.INDIVIDUAL_MERCHANT || member.memberType === MemberType.BUSINESS_MERCHANT) {
                if (member.status === MemberStatus.UNDER_REVIEW) {
                    this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_business_merchant_v2', 'register_individual_merchant_v2'], `merchantMemberId_eq_${member.id}`);
                } else {
                    const merchantType = member.memberType === MemberType.INDIVIDUAL_MERCHANT ? 'individual' : 'merchant';
                    this.router.navigate([`/administration/${merchantType}/`, member.id], {queryParams: {_activeTab: 'banking_details'}});
                }
            } else {
                if (member.status === MemberStatus.UNDER_REVIEW) {
                    this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_member_v2', 'onboard_pt_member'], `memberId_eq_${member.id}`);
                } else {
                    this.router.navigate(['/administration/member/', member.id], {queryParams: {_activeTab: 'banking_details'}});
                }
            }
        }
    }

    getBankAccountNumber(bankAccount: BankAccount) {
        if (bankAccount.member?.memberType !== MemberType.BUSINESS_MERCHANT && bankAccount.member?.memberType !== MemberType.INDIVIDUAL_MERCHANT) {
            return '*****' + bankAccount.accountNumber.substring(bankAccount.accountNumber.length - 4);
        }
        // unmasked number;
        return bankAccount.accountNumber;
    }

    getBankRoutingNumber(bankAccount: BankAccount) {
        if (bankAccount.member?.memberType !== MemberType.BUSINESS_MERCHANT && bankAccount.member?.memberType !== MemberType.INDIVIDUAL_MERCHANT) {
            return '*****' + bankAccount.routingNumber.substring(bankAccount.routingNumber.length - 4);
        }
        // unmasked number;
        return bankAccount.routingNumber;
    }

    getWireRoutingNumber(bankAccount: BankAccount) {
        if (bankAccount.member?.memberType !== MemberType.BUSINESS_MERCHANT && bankAccount.member?.memberType !== MemberType.INDIVIDUAL_MERCHANT) {
            return '*****' + bankAccount.wireRoutingNumber.substring(bankAccount.wireRoutingNumber.length - 4);
        }
        // unmasked number;
        return bankAccount.wireRoutingNumber;
    }
}
