import { User } from './user';
import { Member } from './member';

export class DiligenceHistory {
    id: string;
    memberId: string;
    member: Member;
    reviewerId: string;
    reviewer: User;
    reviewedDate: Date;
    created: Date;
    updated: Date;
    reviewType: ReviewType;
    status: ReviewStatus;
}

export enum ReviewType {
    ONBOARDING_REVIEW = 'ONBOARDING_REVIEW',
    PERIODIC_REVIEW = 'PERIODIC_REVIEW',
    TRIGGER_REVIEW = 'TRIGGER_REVIEW'
}

export enum ReviewStatus {
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    PENDING_CUSTOMER_SUBMISSION = 'PENDING_CUSTOMER_SUBMISSION',
    REJECTED = 'REJECTED',
    CANCELLED = 'CANCELLED'
}
