<div *ngIf="contractTemplate" class="row wizard">
    <div class="dashboard-header template-header">
        {{contractTemplate.name}} (as {{getViewType() | translate}})
    </div>
    <div class="col-12 clear">
        <pc-sc-section *ngFor="let section of contractTemplate.sections; let i = index" [contractTemplate]="contractTemplate" [index]="i" [mode]="mode" (sectionSelected)="sectionSelected($event)">
        </pc-sc-section>
    </div>
</div>

