<div class="splash-bg">
    <div class="splash-body">
        <img class="mb-5" src="assets/images/Confia.png">

        <div class="mb-5">
            <h5>{{'BROWSER_NOT_SUPPORTED' | translate}}</h5>
            <h5>{{'SUPPORTED_BROWSER' | translate}}</h5>
        </div>

        <div class="browser-container">
            <div *ngFor="let browser of SUPPORTED_BROWSERS " class="box">
                <img [src]="browser.img" alt="{{browser.name}}">
            </div>
        </div>
    </div>
</div>
