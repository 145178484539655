import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { PagedResponse } from './models/paged-response';
import { Comment, CommentType } from './models/comment';
import { AlertStatus, AlertType } from './models/alert';

@Injectable({
    providedIn: 'root'
})
export class CommentService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.CommentAPIEndpoint + '/';
    }

    saveComment(comment: Comment) {
        return this.http.post(`${this.baseUrl}comment`, comment);
    }

    loadComments(memberId: string, type: CommentType, userId?: string) : Observable<PagedResponse<Comment>> {
        let params = `?memberId=${memberId}&type=${type}&size=1000`;
        if (userId) {
            params += `&userId=${userId}`;
        }
        return this.http.get(`${this.baseUrl}comment${params}`) as Observable<PagedResponse<Comment>>;
    }

    countOpenComments(memberId: string, type: CommentType, userId?: string) {
        let params = `?memberId=${memberId}&type=${type}`;
        if (userId) {
            params += `&userId=${userId}`;
        }
        params += '&openOnly=true';
        return this.http.head(`${this.baseUrl}comment${params}`);
    }

    getAlertsByStatusAndType(memberId: string, statuses: AlertStatus[], type: AlertType, start: number, size: number, sort: string, sortDir: string) {
        const params = `?memberId=${memberId}&statuses=${statuses}&type=${type === AlertType.ALL_ALERT_TYPES ? '' : type}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}alert${params}`);
    }

    getAlert(id: string) {
        return this.http.get(`${this.baseUrl}alert/${id}`);
    }

    updateAlert(id: string, body: any) {
        return this.http.put(`${this.baseUrl}alert/${id}`, body);
    }

    countActiveAlerts(memberId: string) {
        return this.http.head(`${this.baseUrl}alert?memberId=${memberId}&status=ACTIVE`);
    }
}
