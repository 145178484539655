import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AuthService, KeyParty, KeyPartyService, KeyPartyStatus, MemberType, UserAccountService } from 'projects/services/src/public-api';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { of as observableOf, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PageTracking } from 'projects/components/src/lib/table-utils.service';
import { AbstractKeyPartyList } from 'projects/pt/src/app/administration/settings/components/key-parties/abstract-key-party-list';
import { DatatableComponent, DataTableOptions } from 'projects/components/src/lib/tables/datatable/datatable.component';

/**
 * Specialized component to only display the owners history table ordered by last updated date. There's no actions
 * buttons in this component, it's a pure table to display data.
 *
 */
@Component({
    selector: 'pt-historical-key-party-list',
    templateUrl: './historical-key-party-list.component.html'
})
export class HistoricalKeyPartyListComponent extends AbstractKeyPartyList implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    /**
     * Used to signal this component that the data need to be refreshed
     */
    @Input() changeTrigger: number;
    @Input() memberId: string;
    @Input() memberType: MemberType;

    @Output() keyPartyAdded: EventEmitter<null> = new EventEmitter<null>();

    tableOptions: DataTableOptions = new DataTableOptions({
        urlTracking: false,
        sortColumn: 'updated',
        rowClass: 'row-disabled'
    });

    displayedColumns: string[];

    subscription: Subscription;

    @ViewChild(DatatableComponent)
        dataTable: DatatableComponent<KeyParty>;

    constructor(authService: AuthService,
        keyPartyService: KeyPartyService,
        userAccountService: UserAccountService) {
        super(authService, keyPartyService, userAccountService);
    }

    ngOnInit() {
        this.displayedColumns = [...this.DEFINED_COLUMNS];
        if (!this.authService.isAuthority()) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('details'), 1);
        }
        if (!this.authService.isAuthorityOrAdmin()) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('action'), 1);
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.loadHistoricalKeyParties();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.changeTrigger && !changes.changeTrigger.firstChange) {
            this.loadHistoricalKeyParties();
        }
    }

    loadHistoricalKeyParties() {
        const pageTracking: PageTracking = this.dataTable.pageTracking;
        const page = pageTracking.page - 1;
        const pageSize = pageTracking.pageSize;
        const sort = pageTracking.sort;
        const sortDir = pageTracking.sortDir;

        this.subscription = this.getKeyParties(this.memberId, page, pageSize, sort, sortDir, KeyPartyStatus.DISABLED).pipe(
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((ownershipInfoDetails: KeyParty[]) => {
            this.keyParties = ownershipInfoDetails;
            UIUtils.scrollDashboardToTop();
            this.isLoadingResults = false;
        });
    }

    isMerchant() {
        return this.memberType === MemberType.BUSINESS_MERCHANT || this.memberType === MemberType.INDIVIDUAL_MERCHANT;
    }

    readdKeyParty(keyPartyId: string) {
        const body = {
            status: KeyPartyStatus.ACTIVE
        };
        this.keyPartyService.updateKeyParty(keyPartyId, body).subscribe(() => {
            this.loadHistoricalKeyParties();
            this.keyPartyAdded.emit();
        });
    }

}
