import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
    BankAccount, AuthService, WorkflowService, WalletService, BankAccountService, TransactionType, TransactionSource, TransactionFormService, MemberAccount, PagedResponse
} from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'pt-deposit-funds-modal',
    templateUrl: './deposit-funds-modal.component.html',
    styleUrls: ['./deposit-funds-modal.component.scss']
})
export class DepositFundsModalComponent extends BaseModalComponent<DepositFundsModalComponent> implements OnInit {

    memberAccount: MemberAccount;
    depositForm: UntypedFormGroup;
    bankAccounts: BankAccount[] = [];
    totalAmount = 0;
    submissionReference = uuidv4();

    constructor(private authService: AuthService,
                private workflowService: WorkflowService,
                private transactionFormService: TransactionFormService,
                private bankAccountService: BankAccountService,
                private walletService: WalletService,
                private notifier: NotificationService,
                dialogRef: MatDialogRef<DepositFundsModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberAccount = data.memberAccount;
    }

    ngOnInit() {
        this.isDepositFormValid = this.isDepositFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.depositForm = this.transactionFormService.initializeDepositWithdrawFundsForm();
        this.depositForm.get('transactionDateCtrl').disable();
        this.updateAccounts(false);
    }

    updateAccounts(reset: boolean) {
        this.bankAccountService.getAllActiveBankAccounts(this.authService.getProfile().memberId).subscribe((bankAccounts: PagedResponse<BankAccount>) => {
            if (reset) {
                this.depositForm.controls['bankAccountIdCtrl'].setValue(null);
            }
            this.bankAccounts = bankAccounts.content;
        });
    }

    onCalculateTotal() {
        this.totalAmount = this.depositForm.controls['amountCtrl'].value;
    }

    onSubmit(reset: any) {
        const transaction = this.transactionFormService.getDepositFundsTransaction(this.depositForm);
        transaction.transactionType = TransactionType.ACH_DEPOSIT;
        transaction.source = TransactionSource.SYSTEM_TRANSACTION;
        transaction.submissionReference = this.submissionReference;
        transaction.recipientAccountId = this.memberAccount.id;
        this.workflowService.depositToWallet(transaction).subscribe(() => {
            this.close(true);
            this.notifier.showSuccess(`Your request to deposit <b>$${transaction.totalAmount}</b> has been received, pending balance verification.`);
        }, (error: any) => {
            if (error.status === 422) {
                this.updateAccounts(true);
            }
            reset();
            throw error;
        });
    }

    isDepositFormValid() {
        return !this.depositForm.invalid && (this.totalAmount > 0);
    }

    close(refresh?: boolean) {
        this.totalAmount = 0;
        super.close(refresh);
    }
}
