<div>
    <form [formGroup]="whitelistPartyForm">
        <div>
            <div class="modal-header">
              <label class="form-title">Add Whitelisted Party</label>
              <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-medium">
                <div class="col-12">
                    <pc-text-option-selector [required]="true" [label]="'White List Party Type'" [formGroup]="whitelistPartyForm" [translated]="true"
                        [options]="searchWhitelistTypes" [formCtrl]="'whitelistSearchTypeCtrl'" (selected)="selectedPartyType($event)">
                    </pc-text-option-selector>
                    <pc-text-option-selector  [required]="true" [label]="'Transaction Type(s)'" [translated]="true"
                        [options]="whitelistTypes" [formCtrl]="'whitelistTypeCtrl'" [formGroup]="whitelistPartyForm"  (selected)="selectedWhitelistTxnType($event)" [defaultValue]="''">
                    </pc-text-option-selector>
                    <pc-member-selector *ngIf="whitelistPartyType === 'MEMBERS'" [formGroup]="whitelistPartyForm" [label]="'Select Member'" [formCtrl]="'whitelistedMemberIdCtrl'"
                        [memberTypes]="types" [jurisdiction]="member.jurisdiction || ''" [excludedId]="memberId" [includeAddress]="true"
                        (memberSelected)="setWhitelistedMember($event)">
                    </pc-member-selector>
                    <pc-merchant-selector *ngIf="whitelistPartyType === 'MERCHANTS'" [formGroup]="whitelistPartyForm" [label]="'Select Merchant'" [formCtrl]="'whitelistedMemberIdCtrl'" [types]="whitelistTxnTypes"
                        [sources]="[MerchantAccountSource.CONFIA, MerchantAccountSource.ACCOUNTING, MerchantAccountSource.EXTERNAL_TRANSACTION]" [memberId]="memberId"
                        [includeType]="true" [includeAddress]="true" [changeTrigger]="changeTrigger" (merchantSelected)="setWhitelistedMerchant($event)">
                    </pc-merchant-selector>
                    <pc-form-amount-input [label]="'Maximum Transaction Amount ($)'" [formCtrl]="'maximumMonthlyTransactionAmountCtrl'" [formGroup]="whitelistPartyForm"
                        [dollar]="true" [includeCents]="true" (amountChanged)="amountChanged($event)" [maxValueAllowed]="'1000000'">
                    </pc-form-amount-input>
                    <pc-form-amount-input [label]="'Maximum Transaction Volume in Last 30 Days (#)'" [formCtrl]="'maximumMonthlyNumberOfTransactionsCtrl'" [formGroup]="whitelistPartyForm"
                        [placeholder]="'# of maximum transaction volume in last 30 days'" [dollar]="false" [includeCents]="false" (amountChanged)="amountChanged($event)" [maxValueAllowed]="'100000'">
                    </pc-form-amount-input>
                    <pc-text-area [required]="true" [label]="'Note'" [placeholder]="''" [formCtrl]="'notesCtrl'" [formGroup]="whitelistPartyForm">
                    </pc-text-area>
                </div>
            </div>
            <span class="error" *ngIf="validateCurrentMember()">
            Same member can't be whitelisted with itself.
            </span>
            <div class="modal-footer">
              <pc-submit-button [enabled]="isWhitelistedFormValid" [submit]="onSubmit" [text]="'Add Party'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
