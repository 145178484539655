import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AuditRoutingModule } from './audit-routing.module';
import { SharedComponentsModule } from 'projects/components/src/lib/shared-components.module';

import { AuthorityAuditComponent, AuditTableHeaderComponent, AuditTableListComponent, MemberAuditComponent } from './components';
import { HomeModule } from '../home/home.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedSmartContractsComponentsModule } from 'projects/smart-contract-components/src/public-api';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    imports: [
        CommonModule,
        AuditRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        SharedComponentsModule,
        TranslateModule,
        HomeModule,
        NgbModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        SharedSmartContractsComponentsModule
    ],

    declarations: [
        AuthorityAuditComponent,
        MemberAuditComponent,
        AuditTableHeaderComponent,
        AuditTableListComponent
    ],

    exports: [
        AuditTableListComponent,
        MemberAuditComponent
    ],

    providers: [
    ]
})
export class AuditModule {
}
