import { Component, Input } from '@angular/core';
import { BankAccount } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-bank-account-review-notes',
    templateUrl: './bank-account-review-notes.component.html'
})
export class BankAccountReviewNotesComponent {

    @Input() bankAccount: BankAccount;

}
