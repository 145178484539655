import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';
import { AuthGuard } from 'projects/services/src/public-api';
import { MemberSummaryComponent } from '../members';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                // eslint-disable-next-line arrow-body-style
                loadChildren: () => import('../dashboard/dashboard.module').then((m) => m.DashboardModule),
                canActivate: [AuthGuard],
                data: { title: 'Dashboard' }
            },
            {
                path: 'communication',
                // eslint-disable-next-line arrow-body-style
                loadChildren: () => import('../communication/communication.module').then((m) => m.CommunicationModule),
                canActivate: [AuthGuard],
                data: { title: 'Communication' }
            },
            {
                path: 'support',
                // eslint-disable-next-line arrow-body-style
                loadChildren: () => import('../support/support.module').then((m) => m.SupportModule),
                canActivate: [AuthGuard],
                data: { title: 'Support' }
            },
            {
                path: 'administration',
                // eslint-disable-next-line arrow-body-style
                loadChildren: () => import('../administration/administration.module').then((m) => m.AdministrationModule),
                canActivate: [AuthGuard],
                data: { title: 'Administration' }
            },
            {
                path: 'lending',
                // eslint-disable-next-line arrow-body-style
                loadChildren: () => import('../lending/lending.module').then((m) => m.LendingModule),
                canActivate: [AuthGuard],
                data: { title: 'Lending' }
            },
            {
                path: 'transaction',
                // eslint-disable-next-line arrow-body-style
                loadChildren: () => import('../transaction/transaction.module').then((m) => m.TransactionModule),
                canActivate: [AuthGuard],
                data: { title: 'Transactions' }
            },
            {
                path: 'member-accounts',
                // eslint-disable-next-line arrow-body-style
                loadChildren: () => import('../member-account/member-account.module').then((m) => m.MemberAccountModule),
                canActivate: [AuthGuard],
                data: { title: 'Accounts' }
            },
            {
                path: 'reports',
                // eslint-disable-next-line arrow-body-style
                loadChildren: () => import('../reports/reports.module').then((m) => m.ReportsModule),
                canActivate: [AuthGuard],
                data: { title: 'Reports' }
            },
            {
                path: 'audit',
                // eslint-disable-next-line arrow-body-style
                loadChildren: () => import('../audit/audit.module').then((m) => m.AuditModule),
                canActivate: [AuthGuard],
                data: { title: 'Auditing' }
            },
            {
                path: 'rfi',
                // eslint-disable-next-line arrow-body-style
                loadChildren: () => import('../rfi/rfi.module').then((m) => m.RFIModule),
                canActivate: [AuthGuard],
                data: { title: 'Compliance' }
            },
            {
                path: 'member/details/:memberId',
                component: MemberSummaryComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'smart_contracts',
                // eslint-disable-next-line arrow-body-style
                loadChildren: () => import('../smart-contracts/smart-contracts.module').then((m) => m.SmartContractsModule),
                canActivate: [AuthGuard],
                data: { title: 'Smart Contracts' }
            },
            {
                path: 'alert',
                // eslint-disable-next-line arrow-body-style
                loadChildren: () => import('../alert/alert.module').then((m) => m.AlertModule),
                canActivate: [AuthGuard],
                data: { title: 'Alert' }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HomeRoutingModule {
}
