import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { AuthService, Configuration, CustomValidators, MfaType, RegistrationService } from 'projects/services/src/public-api';
import { throwError } from 'rxjs';
import { LoginModalComponent } from '../login/login-modal.component';

@Component({
    selector: 'pc-set-password-modal',
    templateUrl: './set-password-modal.component.html',
    styleUrls: ['./set-password-modal.component.scss']
})
export class SetPasswordModalComponent extends BaseModalComponent<SetPasswordModalComponent> implements OnInit {

    MfaType = MfaType;

    readonly MFA_TYPES = [{
        id: MfaType.SMS,
        name: 'SMS Text Message'
    }, {
        id: MfaType.TOTP,
        name: 'Time-based Token (TOTP)'
    }];

    passwordForm: UntypedFormGroup;
    errorMessage : string;
    email: string;
    processInstanceId: string;
    linkExpired = false;
    passwordReset = false;
    mfaType = MfaType.NONE;

    constructor(private formbuilder: UntypedFormBuilder,
                private registrationService: RegistrationService,
                private authService: AuthService,
                private matDialog: MatDialog,
                dialogRef: MatDialogRef<SetPasswordModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.email = data.email;
        this.processInstanceId = data.processInstanceId;
        this.passwordReset = data.passwordReset;
        this.mfaType = data.mfaType;
    }

    ngOnInit() {
        this.isFormValid = this.isFormValid.bind(this);
        this.onRegister = this.onRegister.bind(this);
        this.passwordForm = this.formbuilder.group(
            {
                passwordOneCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(8), CustomValidators.password]),
                passwordTwoCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(8), CustomValidators.password]),
                mfaCtrl: new UntypedFormControl(this.mfaType || MfaType.NONE, [Validators.required])
            }
        );
    }

    onRegister(reset: any) {
        const passwordOne = this.passwordForm.controls['passwordOneCtrl'].value;
        const passwordTwo = this.passwordForm.controls['passwordTwoCtrl'].value;
        const mfaType = this.passwordForm.controls['mfaCtrl'].value;

        if (passwordOne === passwordTwo) {
            const body = {
                processInstanceId: this.processInstanceId,
                mfaType,
                password: passwordOne
            };
            if (this.passwordReset) {
                this.registrationService.resetPassword(body).subscribe(
                    () => {
                        if (mfaType === MfaType.TOTP) {
                            // handle change to MFA type
                            this.signInUser(passwordOne, null, reset);
                        } else {
                            super.close(true);
                        }
                    }
                );
            } else {
                this.registrationService.completeRegistration(body).subscribe(
                    (result: any) => {
                        // wait 2 seconds for the service to register the user then redirect
                        setTimeout(() => {
                            this.signInUser(passwordOne, result.memberId, reset);
                        }, 1500);
                    },
                    (errorResult: any) => {
                        if (errorResult.status === 410) {
                            this.errorMessage = errorResult.error;
                            this.linkExpired = true;
                        } else {
                            reset();
                            this.errorMessage = errorResult.error;
                            throwError(errorResult);
                        }
                    }
                );
            }
        }
    }

    signInUser(password: string, memberId: string, reset: any) {
        const authRequest = {
            username: this.email,
            password: password
        };
        this.authService.signinUserOrg(authRequest, memberId,
            () => {
                super.close();
            },
            (errorResponse: any) => {
                if (errorResponse.status === 403) {
                    super.close(true);
                    setTimeout(() => {
                        this.openLoginDialog(password, errorResponse.error);
                    }, 200);
                } else {
                    reset();
                    this.errorMessage = errorResponse.error;
                    throwError(errorResponse);
                }
            });

    }

    close(dialogResult?: any) {
        super.close(dialogResult);
    }

    openLoginDialog(password: string, mfaData: any) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.disableClose = true;
        dialogConfig.data = {
            email: this.email,
            password,
            mfaData
        };
        this.matDialog.open(LoginModalComponent, dialogConfig);

    }

    passwordMismatch() {
        return this.passwordForm.get('passwordOneCtrl').value !== this.passwordForm.get('passwordTwoCtrl').value;
    }

    validUppercase() {
        return CustomValidators.UPPERCASE_REGEX.test(this.passwordForm.get('passwordOneCtrl').value);
    }

    validLowercase() {
        return CustomValidators.LOWERCASE_REGEX.test(this.passwordForm.get('passwordOneCtrl').value);
    }

    validNumber() {
        return CustomValidators.NUMBER_REGEX.test(this.passwordForm.get('passwordOneCtrl').value);
    }

    validSpecial() {
        return CustomValidators.SPECIAL_REGEX.test(this.passwordForm.get('passwordOneCtrl').value);
    }

    isFormValid() {
        return this.passwordForm &&
        this.passwordForm.valid &&
        !this.passwordMismatch() &&
        this.validUppercase() &&
        this.validLowercase() &&
        this.validNumber() &&
        this.validSpecial();
    }

    isMfaEnabled() {
        return Configuration.getConfig().mfaEnabled;
    }
}
