import { Component } from '@angular/core';
import { FincenInvestigation, FincenInvestigationStatus } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-fincen-company-investigation-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class FincenCompanyStatusBadgeComponent extends BaseBadgeComponent<FincenInvestigation> {

    setBadgeData() {
        this.badgeToolTip = this.parent.companiesStatus;
        switch (this.parent.companiesStatus) {
            case FincenInvestigationStatus.NO_MATCHES:
                this.badgeText = 'NO MATCHES';
                this.badgeClass = 'green-badge';
                break;
            case FincenInvestigationStatus.MATCHES:
                this.badgeText = 'REVIEW';
                this.badgeClass = 'red-badge';
                break;
            case FincenInvestigationStatus.FAILED:
                this.badgeText = 'FAILED';
                this.badgeClass = 'red-badge';
                break;
            case FincenInvestigationStatus.PENDING:
                this.badgeText = 'PENDING';
                this.badgeClass = 'orange-badge';
                break;
            case FincenInvestigationStatus.UNPARSEABLE:
                this.badgeText = 'UNPARSEABLE';
                this.badgeClass = 'red-badge';
                break;
        }
    }
}
