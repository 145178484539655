<div>
    <form [formGroup]="templateForm">
        <div>
            <div class="modal-header">
                <label class="form-title">Create New Template</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <pc-form-selector [required]="true" [label]="'Select Framework'"  [formCtrl]="'frameworkCtrl'"
                    [formGroup]="templateForm" [options]="contractFrameworks" [idValue]="'id'" [displayValue]="'name'">
                </pc-form-selector>

                <pc-form-selector [required]="true" [label]="'My Role'"  [formCtrl]="'templateTypeCtrl'"
                    [formGroup]="templateForm" [options]="roles" [idValue]="'id'" [displayValue]="'name'">
                </pc-form-selector>

                <pc-text-input [required]="true" [label]="'Template Name'" [placeholder]="'Template Name'"
                    [formCtrl]="'nameCtrl'" [formGroup]="templateForm">
                </pc-text-input>

                <pc-text-area [label]="'Description'" [placeholder]="'Description'"
                    [formCtrl]="'descriptionCtrl'" [formGroup]="templateForm">
                </pc-text-area>
            </div>
            <div *ngIf="errorMessage" class="error-message">
                <label class="form-error">{{errorMessage}}</label>
            </div>
            <div class="modal-footer">
                <pc-submit-button class="pull-right" [enabled]="isTemplateFormValid" [submit]="onSubmit" [text]="'Create Template'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
