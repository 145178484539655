<div>
    <form [formGroup]="uploadForm">
        <div class="row mb-3">
            <div class="col-6">
                <div>
                    <pc-text-input [required]="true" [label]="'Framework File'" [formCtrl]="'frameworkCtrl'" [formGroup]="uploadForm" [placeholder]="'Click to select...'"
                        [fullClickable]="true" (click)="uploadFramework.click()">
                    </pc-text-input>
                    <input hidden type="file" id="uploadFramework" #uploadFramework (change)="selectFile('framework', $event)">

                    <pc-text-input [required]="true" [label]="'Sections File'" [formCtrl]="'sectionsCtrl'" [formGroup]="uploadForm" [placeholder]="'Click to select...'"
                        [fullClickable]="true" (click)="uploadSections.click()">
                    </pc-text-input>
                    <input hidden type="file" id="uploadSections" #uploadSections (change)="selectFile('sections', $event)" placeholder="Upload Sections File">

                    <pc-text-input [required]="true" [label]="'Terms File'" [formCtrl]="'termsCtrl'" [formGroup]="uploadForm" [placeholder]="'Click to select...'"
                        [fullClickable]="true" (click)="uploadTerms.click()">
                    </pc-text-input>
                    <input hidden type="file" id="uploadTerms" #uploadTerms (change)="selectFile('terms', $event)" placeholder="Upload Terms File">

                    <pc-text-input [required]="true" [label]="'Variables File'" [formCtrl]="'variablesCtrl'" [formGroup]="uploadForm" [placeholder]="'Click to select...'"
                        [fullClickable]="true" (click)="uploadVariables.click()">
                    </pc-text-input>
                    <input hidden type="file" id="uploadVariables" #uploadVariables (change)="selectFile('variables', $event)" placeholder="Upload Variables File">

                </div>
            </div>
            <div class="col-6 ">
                <div class="mb-3">
                    <pc-text-input [required]="true" [label]="'Framework Name'" [formCtrl]="'nameCtrl'" [formGroup]="uploadForm">
                    </pc-text-input>

                    <pc-form-selector [required]="true" [label]="'Framework Type'" [formCtrl]="'typeCtrl'"
                        [formGroup]="uploadForm" [options]="frameworkTypes" [defaultValue]="''">
                    </pc-form-selector>

                </div>
                <span class="pull-right">
                    <pc-submit-button class="pull-right" [enabled]="isUploadFormValid" [submit]="onSubmit" [text]="'Upload Framework'"></pc-submit-button>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 horizontal-scroll mat-table-container">
                <table mat-table [dataSource]="contractFrameworks">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef> Description </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.description }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="version">
                        <th mat-header-cell class="centered" *matHeaderCellDef> Version </th>
                        <td mat-cell class="centered" *matCellDef="let element">
                            <div>{{element.version}}</div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef class="centered"> Type </th>
                        <td mat-cell class="centered" *matCellDef="let element"> {{ getType(element) }}</td>
                    </ng-container>
                    <ng-container matColumnDef="last_updated">
                        <th mat-header-cell class="centered" *matHeaderCellDef> Last Updated </th>
                        <td mat-cell class="centered" *matCellDef="let element">
                            {{ element.updated | date: 'medium'}}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </form>
</div>
