
export class LandingSettings {
    id?: string;
    memberId: string;
    primaryColor: string;
    secondaryColor: string;
    primaryFont: string;
    buttonsColor: string;
    buttonsTextColor: string;
}
