import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import {
    Address, AddressService, AuthService, BankAccount, BankAccountService, BusinessCategory, CommentService, CommentType, DataroomService, DocumentType, FinancialDetails,
    KeyParty, KeyPartyService, License, LicenseService, LicenseStatus, Member, MemberAccount, MemberAccountService, MemberAccountStatus, MemberFunction, MemberRegistrationType,
    MemberService, MemberStatus, MemberType, OnboardingTrackingService, Configuration, PagedResponse, PaymentService, RiskTier, Role, Task, TaxInfo, Upload, UploadStatus,
    User, UserAccount, UserAccountService, UserAccountStatus, UserService, Utils, WorkflowService
} from 'projects/services/src/public-api';
import { DirtyComponent } from 'projects/components/src/lib/administration';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'projects/components/src/public-api';
import { of } from 'rxjs';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';

@Component({
    selector: 'pt-member-details',
    templateUrl: './member-details.component.html',
    styleUrls: ['./member-details.component.scss']
})
export class MemberDetailsComponent implements OnInit, DirtyComponent {

    MemberType = MemberType;
    MemberStatus = MemberStatus;
    MemberRegistrationType = MemberRegistrationType;

    @Input() tabValid: () => boolean;

    activeTab: string;
    userId: string;
    memberId: string;
    member: Member;
    processInstanceId: string;
    changeTrigger = Math.random();
    notesTrigger = Math.random();
    task: string;
    taskId: string;
    businessCategory: BusinessCategory;
    memberRegistrationType: MemberRegistrationType;
    metrcEnabled = false;
    isPeriodicReviewTask = false;
    hasPendingAccounts = false;

    authorityUsers: {userId: string, user: User}[] = [];
    assignee: string;


    constructor(private route: ActivatedRoute,
                private router: Router,
                private memberService: MemberService,
                private memberAccountService: MemberAccountService,
                private keyPartyService: KeyPartyService,
                private addressService: AddressService,
                private licenseService: LicenseService,
                private workflowService: WorkflowService,
                private userService: UserService,
                private dataroomService: DataroomService,
                private commentService: CommentService,
                private bankAccountService: BankAccountService,
                private userAccountService: UserAccountService,
                private dialog: MatDialog,
                public authService: AuthService) {}

    ngOnInit() {
        this.metrcEnabled = Configuration.getConfig().metrcEnabled;
        this.route.queryParams.subscribe((params) => {
            const activeTab = params['_activeTab'];
            if (activeTab && activeTab !== this.activeTab && (this.task || this.memberId)) {
                this.onSwitchTabs(params['_activeTab']);
            }
        });
        this.route.params.subscribe((routeParams) => {
            const memberId = routeParams.memberId;
            if (this.member && memberId && memberId !== this.memberId && (this.task || this.memberId)) {
                this.memberId = memberId;
                this.activeTab = null;
                this.ngOnInit();
            }
        });
        this.onSwitchTabs = this.onSwitchTabs.bind(this);
        this.getTabClass = this.getTabClass.bind(this);
        this.isAdminDetailsValid = this.isAdminDetailsValid.bind(this);
        this.isAdminDetailsDirty = this.isAdminDetailsDirty.bind(this);
        this.isAccountsDetailsValid = this.isAccountsDetailsValid.bind(this);
        this.isWalletDetailsValid = this.isWalletDetailsValid.bind(this);
        this.isWalletDetailsDirty = this.isWalletDetailsDirty.bind(this);
        this.isBusinessDetailsValid = this.isBusinessDetailsValid.bind(this);
        this.isBusinessDetailsDirty = this.isBusinessDetailsDirty.bind(this);
        this.isKeyPartiesValid = this.isKeyPartiesValid.bind(this);
        this.isLocationDetailsValid = this.isLocationDetailsValid.bind(this);
        this.isLicenseDetailsValid = this.isLicenseDetailsValid.bind(this);
        this.isFinancialDetailsValid = this.isFinancialDetailsValid.bind(this);
        this.isFinancialDetailsDirty = this.isFinancialDetailsDirty.bind(this);
        this.isInternalDetailsValid = this.isInternalDetailsValid.bind(this);
        this.isInternalDetailsDirty = this.isInternalDetailsDirty.bind(this);
        this.isInternalAlertsDirty = this.isInternalAlertsDirty.bind(this);
        this.isInternalAlertsValid = this.isInternalAlertsValid.bind(this);
        this.isQuestionsValid = this.isQuestionsValid.bind(this);
        this.isDocumentsValid = this.isDocumentsValid.bind(this);

        const memberPathValue = this.route.snapshot.params.memberId;
        OnboardingTrackingService.setCurrentTask(null);
        if (!Utils.isUuid(memberPathValue) && this.workflowService.getCurrentTask()) {
            const task = this.workflowService.getCurrentTask();
            if (!task.formKey.startsWith('/administration/member')) {
                this.workflowService.clearCurrentTask();
                this.router.navigate(['/dashboard']);
                throw new Error('Task not valid.');
            }
            this.taskId = task.id;
            this.processInstanceId = task.processInstanceId;
            this.memberId = task.variables.memberId;

            if (this.authService.isAuthorityOrReviewer()) {
                this.assignee = task.variables.assignedAuthority;
                this.userId = task.variables.userId;
                this.userAccountService.loadAccountsByMemberIdAndStatusesAndRoles(this.authService.getProfile().memberId, [UserAccountStatus.ACTIVE], [Role.AUTHORITY, Role.AUTHORITY_SUPERUSER], 0, 1000, 'user.lastName', 'ASC').subscribe((response: PagedResponse<UserAccount>) => {
                    this.authorityUsers = response.content.map((userAccount: UserAccount) => {
                        return {
                            userId: userAccount.userId,
                            user: userAccount.user
                        };
                    });
                });
            } else {
                this.userId = this.authService.getProfile().userId;
            }
            if (this.route.snapshot.queryParams['_activeTab']) {
                this.activeTab = this.route.snapshot.queryParams['_activeTab'];
                switch (task.formKey) {
                    case ('/administration/member/onboard'): {
                        this.task = 'onboard';
                        break;
                    }
                    case ('/administration/member/adjudicate'): {
                        this.task = 'adjudicate';
                        break;
                    }
                }
                this.checkValidity();
            } else {
                switch (task.formKey) {
                    case ('/administration/member/onboard'): {
                        this.task = 'onboard';
                        this.checkValidity();
                        this.onSwitchTabs('administrator', true);
                        break;
                    }
                    case ('/administration/member/adjudicate'): {
                        this.task = 'adjudicate';
                        this.checkValidity();
                        if (this.isAuthorityOrReviewer()) {
                            this.onSwitchTabs('administrator', true);
                        } else {
                            this.onSwitchTabs('questions', true);
                        }
                        break;
                    }
                }
            }
            OnboardingTrackingService.setCurrentTask(this.task);
        } else if (Utils.isUuid(memberPathValue)) {
            if (this.authService.isNewRegistrant()) {
                this.router.navigate(['/dashboard']);
            } else {
                const memberId = memberPathValue;
                if (memberId) {
                    this.memberId = memberId;
                    this.checkValidity();
                    if (this.route.snapshot.queryParams['_activeTab']) {
                        this.activeTab = this.route.snapshot.queryParams['_activeTab'];
                        UIUtils.scrollToTop(document.querySelector('#member-details'));
                    } else {
                        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
                            if (!member) {
                                this.router.navigate(['/dashboard']);
                                throw new Error('Link is no longer valid');
                            } else if (member.status === MemberStatus.UNDER_REVIEW && this.authService.isAuthorityOrReviewer()) {
                                this.memberAccountService.getAllMemberBusinessAccounts(member.id).subscribe((result: PagedResponse<MemberAccount>) => {
                                    // FIXME: clean this up once all old onboarding flows are gone
                                    const isLegacyOnboarding = result.content.find((memberAccount) => {
                                        // legacy flows have an account that is UNDER_REVIEW with an account number already assigned
                                        return memberAccount.status === MemberAccountStatus.UNDER_REVIEW && memberAccount.accountNumber;
                                    });
                                    const isAccountPending = result.content.find((memberAccount) => {
                                        // members that have been approved, but the account has not have
                                        return memberAccount.status === MemberAccountStatus.PENDING || memberAccount.status === MemberAccountStatus.UNDER_REVIEW;
                                    });
                                    if (isLegacyOnboarding) {
                                        // open the old workflow application
                                        this.workflowService.loadTaskByProcessDefinitionWithVariables(['onboard_pt_member'], `memberId_eq_${member.id}`);
                                    } else if (isAccountPending) {
                                        // the application has been approved, but the account not approved yet
                                        this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_member_account_v2'], `memberId_eq_${member.id}`);
                                    } else {
                                        // we have a new application that has not yet been approved
                                        this.workflowService.loadTaskByProcessDefinitionWithVariables(['register_member_v2'], `memberId_eq_${member.id}`);
                                    }
                                });
                            }
                            this.onSwitchTabs(member.status === MemberStatus.APPLIED ? 'business' : 'accounts', false, true);
                        });
                    }
                }
            }
        }
    }

    onSwitchTabs(tabOption: string, skipLocationChange?: boolean, replaceUrl?: boolean) {
        if (this.activeTab === tabOption) {
            return;
        }
        if (!this.isDirty()) {
            this.activeTab = tabOption;
            if (replaceUrl) {
                this.router.navigateByUrl(`/administration/member/${this.task ? this.task : this.memberId}?_activeTab=${tabOption}`, {skipLocationChange, replaceUrl: true});
            } else {
                this.router.navigateByUrl(`/administration/member/${this.task ? this.task : this.memberId}?_activeTab=${tabOption}`, {skipLocationChange});
            }
            UIUtils.scrollToTop(document.querySelector('#member-details'));
        } else {
            const dialogConfig: MatDialogConfig = {};
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'normal-modal';
            dialogConfig.disableClose = true;

            dialogConfig.data = {
                title: 'You have unsaved changes',
                description: 'Click Return to finish entering information, or click Ignore to continue without saving.',
                confirmText: 'Ignore',
                confirmAction: 'ignore',
                rejectText: 'Return',
                rejectAction: 'return'
            };

            const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

            dialog?.afterClosed().subscribe((confirmAction: any) => {
                if (confirmAction === 'ignore') {
                    this.activeTab = tabOption;
                    this.clearTrackingState();
                    this.checkValidity();
                    this.router.navigateByUrl(`/administration/member/${this.task ? this.task : this.memberId}?_activeTab=${tabOption}`);
                    UIUtils.scrollToTop(document.querySelector('#member-details'));
                }
            });
        }
    }

    clearTrackingState() {
        OnboardingTrackingService.setAdminInfoDirty(false);
        OnboardingTrackingService.setBillingProfileInfoDirty(false);
        OnboardingTrackingService.setMemberInfoDirty(false);
        OnboardingTrackingService.setInternalInfoDirty(false);
        OnboardingTrackingService.setInternalAlertsDirty(false);
        OnboardingTrackingService.setFinancialInfoDirty(false);
        OnboardingTrackingService.setCurrentTask(this.task);
    }

    getTabClass(tabOption: string) {
        if (this.activeTab === tabOption) {
            return 'col-12 tab-col tab-option-selected';
        } else {
            return 'col-12 tab-col tab-option';
        }
    }

    next() {
        switch (this.activeTab) {
            case ('administrator'): {
                this.onSwitchTabs('business');
                break;
            }
            case ('accounts'): {
                this.onSwitchTabs('business');
                break;
            }
            case ('business'): {
                this.onSwitchTabs('key_parties');
                break;
            }
            case ('key_parties'): {
                this.onSwitchTabs('locations');
                break;
            }
            case ('locations'): {
                this.onSwitchTabs('licenses');
                break;
            }
            case ('licenses'): {
                this.onSwitchTabs('financial');
                break;
            }
            case ('financial'): {
                this.onSwitchTabs('documents');
                break;
            }
            case ('internal'): {
                this.onSwitchTabs('internal_alerts');
                break;
            }
            case ('internal_alerts'): {
                this.onSwitchTabs('questions');
                break;
            }
            case ('documents'): {
                if (this.isAuthorityOrReviewer()) {
                    this.onSwitchTabs('internal');
                } else {
                    this.onSwitchTabs('questions');
                }
                break;
            }
            case ('notes'): {
                this.onSwitchTabs('adjudicate');
                break;
            }
            case ('questions'): {
                if (this.task === 'adjudicate') {
                    this.onSwitchTabs('adjudicate');
                } else if (this.task === 'onboard') {
                    this.onSwitchTabs('submit');
                } else {
                    this.onSwitchTabs('banking_details');
                }
                break;
            }
        }
    }

    checkValidity() {
        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            this.member = member;
            this.businessCategory = member.businessCategory;
            this.memberRegistrationType = member.memberRegistrationType;

            this.addressService.getPrimaryAddress(member.id).subscribe((address: Address) => {
                OnboardingTrackingService.setMemberInfo(address, member);
            });

            this.memberService.searchMembers(member.name, [MemberType.BUSINESS, MemberType.BUSINESS_MERCHANT], [], '', false, false).subscribe((membersResponse: PagedResponse<Member>) => {
                const sameMembers = membersResponse.content.filter((similarMember) => {
                    return similarMember.name.toLowerCase() === member.name.toLowerCase() && ((member.id !== similarMember.id) && (!member.jurisdiction || !similarMember.jurisdiction || member.jurisdiction === similarMember.jurisdiction));
                });
                OnboardingTrackingService.setSameMembers(sameMembers);
            });
            this.checkAccountsValid();
            this.checkFinancialDetailsValid(member);
            this.checkLicensesValid();
            this.checkLocationsValid();
            this.checkKeyPartiesValid();
            this.checkAdminValid();
            this.checkDocumentsValid();
            this.checkQuestionsValid();
            if (this.isAuthorityOrReviewer()) {
                this.checkInternalDetailsValid();
                this.checkAdjudicateValid();
                this.checkInternalAlertsValid();
            }
            this.checkBankingValid();
        });
    }

    checkAccountsValid() {
        if (this.member.memberType === MemberType.BUSINESS) {
            this.memberAccountService.getAllMemberBusinessAccounts(this.memberId).subscribe((result: PagedResponse<MemberAccount>) => {
                this.hasPendingAccounts = !!result.content.find((memberAccount: MemberAccount) => {
                    return memberAccount.status === MemberAccountStatus.UNDER_REVIEW || memberAccount.status === MemberAccountStatus.PENDING;
                });
                OnboardingTrackingService.setAccountDetails(result.content);
            });
        } else {

        }
    }

    checkFinancialDetailsValid(member: Member) {
        OnboardingTrackingService.setFinancialDetails(member.taxInfo, member.financialDetails);
    }

    checkAdminValid() {
        if (this.task === 'onboard' || this.task === 'adjudicate') {
            this.userService.loadUser(this.userId).subscribe((user: User) => {
                this.keyPartyService.getKeyPartyByMemberIdAndUserId(this.memberId, this.userId).subscribe((keyParty: KeyParty) => {
                    if (keyParty) {
                        keyParty.owner = keyParty.percentOwnership > 0;
                    }
                    this.addressService.getPrimaryAddress(user.id).subscribe((address: Address | null) => {
                        OnboardingTrackingService.setAdminInfo(user, address, keyParty);
                    });
                });
            });
        }
    }

    checkQuestionsValid(){
        this.commentService.countOpenComments(this.memberId, CommentType.QUESTION).subscribe((response: any) => {
            OnboardingTrackingService.setQuestionsValid(Number(response.headers.get('Content-Length')));
        });
    }

    checkLicensesValid() {
        this.licenseService.loadMemberLicenses(this.memberId, [LicenseStatus.ACTIVE], 0, 1, 'id', 'DESC').subscribe((licensesResponse: PagedResponse<License>) => {
            OnboardingTrackingService.setLicenseInfo(licensesResponse.content);
        });
    }

    checkLocationsValid() {
        this.addressService.getAllAddresses(this.memberId).subscribe((locations: PagedResponse<Address>) => {
            OnboardingTrackingService.setLocationInfo(locations.content);
        });
    }

    checkKeyPartiesValid() {
        this.keyPartyService.getAllActiveKeyParties(this.memberId).subscribe((keyParties: PagedResponse<KeyParty>) => {
            OnboardingTrackingService.setKeyPartyInfo(keyParties.content);
            const authorityDocuments: Upload[] = [];
            keyParties.content.forEach((keyParty) => {
                this.dataroomService.getUploads('', [UploadStatus.ACTIVE], [DocumentType.ID_RISK_SCREEN, DocumentType.MEDIA_SCREEN],
                    keyParty.individualKeyPartyId || keyParty.corporateKeyPartyId).subscribe((screeningDocuments: Upload[]) => {
                    authorityDocuments.push(...screeningDocuments);
                    OnboardingTrackingService.setAuthorityDocuments(authorityDocuments);
                });
            });
        });
    }

    checkDocumentsValid() {
        this.dataroomService.getUploads(this.memberId, [UploadStatus.ACTIVE]).subscribe((memberDocuments: Upload[]) => {
            OnboardingTrackingService.setDocuments(memberDocuments);
        });
    }

    checkInternalDetailsValid() {
        OnboardingTrackingService.setInternalDetails(this.member.tier, this.member.rating, this.member.jurisdiction, null);
    }

    checkInternalAlertsValid() {
        this.commentService.countActiveAlerts(this.memberId).subscribe((response: any) => {
            OnboardingTrackingService.setInternalAlertsCount(Number(response.headers.get('Content-Length')));
        });
    }

    checkAdjudicateValid() {
        this.workflowService.findTaskByTaskDefinitionsAndProcessVariable(['user_authority_periodic_review'], 'memberId', `${this.memberId}`).subscribe((response: PagedResponse<Task>) => {
            if (response.content.length > 0) {
                this.isPeriodicReviewTask = true;
                const task = response.content[0];
                this.taskId = task.id;
                this.processInstanceId = task.processInstanceId;
                if (this.authService.isAuthorityOrReviewer()) {
                    this.assignee = task.variables.assignedAuthority;
                    this.userAccountService.loadAccountsByMemberIdAndStatusesAndRoles(this.authService.getProfile().memberId, [UserAccountStatus.ACTIVE], [Role.AUTHORITY, Role.AUTHORITY_SUPERUSER], 0, 1000, 'user.lastName', 'ASC').subscribe((response: PagedResponse<UserAccount>) => {
                        this.authorityUsers = response.content.map((userAccount: UserAccount) => {
                            return {
                                userId: userAccount.userId,
                                user: userAccount.user
                            };
                        });
                    });
                }

                OnboardingTrackingService.setCurrentTask('adjudicate');
            }
        });
    }

    checkBankingValid() {
        if (!this.task) {
            this.bankAccountService.getAllActiveBankAccounts(this.memberId).subscribe((response: PagedResponse<BankAccount>) => {
                OnboardingTrackingService.setBankingDetails('', response.content, []);
            });
        }
    }

    isAdminDetailsValid() {
        return OnboardingTrackingService.isAdminInfoValid();
    }

    isAdminDetailsDirty() {
        return OnboardingTrackingService.isAdminInfoDirty();
    }

    isAccountsDetailsValid() {
        return OnboardingTrackingService.isAccountDetailsValid();
    }

    isWalletDetailsValid() {
        return OnboardingTrackingService.isBillingProfileInfoValid();
    }

    isWalletDetailsDirty() {
        return OnboardingTrackingService.isBillingProfileInfoDirty();
    }

    isBusinessDetailsValid() {
        return OnboardingTrackingService.isMemberInfoValid();
    }

    isBusinessDetailsDirty() {
        return OnboardingTrackingService.isMemberInfoDirty();
    }

    isKeyPartiesValid() {
        return OnboardingTrackingService.isKeyPartyInfoValid();
    }

    isLocationDetailsValid() {
        return OnboardingTrackingService.isLocationInfoValid();
    }

    isLicenseDetailsValid() {
        return OnboardingTrackingService.isLicenseInfoValid();
    }

    isFinancialDetailsValid() {
        return OnboardingTrackingService.isFinancialDetailsValid();
    }

    isFinancialDetailsDirty() {
        return OnboardingTrackingService.isFinancialInfoDirty();
    }

    isInternalDetailsValid() {
        return OnboardingTrackingService.isInternalDetailsValid();
    }

    isInternalAlertsValid() {
        return OnboardingTrackingService.isInternalAlertsValid();
    }

    isInternalDetailsDirty() {
        return OnboardingTrackingService.isInternalInfoDirty();
    }

    isInternalAlertsDirty() {
        return OnboardingTrackingService.isInternalAlertsDirty();
    }

    isDocumentsValid() {
        return OnboardingTrackingService.isDocumentsValid();
    }

    isQuestionsValid(){
        return OnboardingTrackingService.isQuestionsValid();
    }

    isBankingDetailsValid() {
        return OnboardingTrackingService.isBankingDetailsValid();
    }

    isAdjudicateValid() {
        return OnboardingTrackingService.isAdjudicateValid();
    }

    isAllValid(){
        // if they have a submit field its always the last thing to get done
        return false;
    }

    updateNotesFields() {
        // this.checkQuestionsValid();
    }

    isAuthority() {
        return this.authService.isAuthority();
    }

    isAuthorityOrReviewer() {
        return this.authService.isAuthorityOrReviewer();
    }

    isMemberPartner() {
        return this.member?.memberFunctions.indexOf(MemberFunction.PARTNER) >= 0;
    }

    isMemberActive() {
        return this.member?.status === MemberStatus.ACTIVE;
    }

    isMemberDisabled() {
        return this.member?.status === MemberStatus.DISABLED;
    }

    isFeeAccount() {
        return this.member?.memberType === MemberType.FEE_ACCOUNT;
    }

    isTrackingSystemVisible() {
        return this.member?.memberType !== MemberType.FEE_ACCOUNT && this.member?.memberType !== MemberType.CONSUMER && this.isAuthorityOrReviewer()
            && this.businessCategory !== BusinessCategory.ATM_BUSINESS;
    }

    isDirty() {
        if (this.activeTab === 'administrator') {
            return OnboardingTrackingService.isAdminInfoDirty();
        } else if (this.activeTab === 'business') {
            return OnboardingTrackingService.isMemberInfoDirty();
        } else if (this.activeTab === 'accounts') {
            return OnboardingTrackingService.isBillingProfileInfoDirty();
        } else if (this.activeTab === 'financial') {
            return OnboardingTrackingService.isFinancialInfoDirty();
        } else if (this.activeTab === 'internal') {
            return OnboardingTrackingService.isInternalInfoDirty();
        } else if (this.activeTab === 'internal_alerts') {
            return OnboardingTrackingService.isInternalAlertsDirty();
        }
        return false;
    }

    addBankAccountAllowed() {
        return (this.member?.memberType !== MemberType.CONSUMER || Configuration.getConfig().qaEnabled);
    }

    merchantActionsAllowed() {
        return this.member?.memberType === MemberType.BUSINESS && this.authService.isAuthority() && this.memberRegistrationType === MemberRegistrationType.PREMIUM_MEMBER;
    }

    canDeactivate(nextState: RouterStateSnapshot) {
        if (!this.isDirty()) {
            return of(true);
        } else {
            const dialogConfig: MatDialogConfig = {};
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'normal-modal';
            dialogConfig.disableClose = true;

            dialogConfig.data = {
                title: 'You have unsaved changes',
                description: 'Click Return to finish entering information, or click Ignore to continue without saving.',
                confirmText: 'Ignore',
                confirmAction: 'ignore',
                rejectText: 'Return',
                rejectAction: 'return'
            };

            const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

            dialog?.afterClosed().subscribe(((confirmAction: any) => {
                if (confirmAction === 'ignore') {
                    this.clearTrackingState();
                    this.router.navigateByUrl(nextState.url, {state: {bypassGuard: true}});
                }
            }));
            return of(false);
        }
    }

    isAdjudicateTabVisible() {
        return this.isAuthorityOrReviewer() && !this.isFeeAccount();
    }

    tierChanged(tier: RiskTier) {
        this.member.tier = tier;
        this.changeTrigger = Math.random();
    }

    updateMember(member: Member) {
        this.member.tier = member.tier;
        this.member.jurisdiction = member.jurisdiction;
        if (this.businessCategory !== member.businessCategory) {
            this.businessCategory = member.businessCategory;
            this.checkDocumentsValid();
        }
        this.changeTrigger = Math.random();
    }

    isApplyingOrUnderReview() {
        return this.task || (this.member && (this.member.status === MemberStatus.APPLIED || this.member.status === MemberStatus.UNDER_REVIEW));
    }

    onNoteAdded() {
        this.notesTrigger = Math.random();
    }

}
