<div class="row" [formGroup]="formGroup" *ngIf="metadata">
    <div class="col-7 sc-input">
        <label class="form-label">{{ metadata.label | translate }}</label>
        <div [formGroup]="formGroup">
            <input [id]="metadata.id" type="text" class="form-control" angular-mydatepicker (click)="onClick($event);dp.toggleCalendar()"
                [options]="myOptions" [formControlName]="metadata.id" #dp="angular-mydatepicker" (dateChanged)="fieldChanged($event)"/>
        </div>
    </div>
    <div class="col-5 sc-tooltip">
        <p><strong>{{ metadata.label | translate }}</strong>: {{metadata.tooltip}}</p>
    </div>
</div>
