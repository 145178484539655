export class Reconciliation {
    id: string;
    documentId: string;
    generatedDate: Date;
    subLedgerBalance: string;
    fboAccountBalance: string;
    status: ReconciliationStatus;
    documentDetails:any;
}

export enum ReconciliationStatus {
    GOOD = 'GOOD',
    INVESTIGATE = 'INVESTIGATE'
}
