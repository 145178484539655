export class PartyInformation {
    name: string;
    address: string;
    phoneNumber: string;
    memberType: string;
    tinUnknownIndicator: string;
    transmitterControlCode: string;
    primaryRegulatorTypeText: string;
    taxpayerIdentificationNumber: string;
    employerIdentificationNumber: string;
    noBranchActivityInvolvedIndicator: string;
    partyIdentificationNumberTextForEIN: string;
    partyIdentificationNumberTextForTcc: string;
    partyIdentificationNumberTextForTaxpayer: string;
}
