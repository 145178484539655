<div class="data-block d-grid text-center align-items-center" [class.data-block-warning]="isWarning()">
    <div>
        <div class="h4">{{accountSummary.isCurrency ? (accountSummary.currentAmount | currency) : accountSummary.currentAmount}}</div>
        <div class="small">{{accountSummary.summaryType + '_CURRENT' | translate}}</div>
        <div *ngIf="hasOtherAmount()" class="h4 mt-3">{{accountSummary.isCurrency ? (accountSummary.otherAmount | currency) : accountSummary.otherAmount}}</div>
        <div *ngIf="hasOtherAmount()" class="small">{{accountSummary.summaryType + '_OTHER' | translate}}</div>
        <div *ngIf="hasThreshold()" class="h4 gray mt-3">{{accountSummary.isCurrency ? (accountSummary.threshold | currency) : accountSummary.threshold }}</div>
        <div *ngIf="hasThreshold()" class="small gray mb-2">{{accountSummary.summaryType + '_THRESHOLD' | translate}}</div>
    </div>
</div>
