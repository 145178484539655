import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DocumentType, ReportsService, Utils } from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentPortal } from '@angular/cdk/portal';
import { BaseModalComponent, LoaderComponent } from 'projects/components/src/public-api';
import { Overlay } from '@angular/cdk/overlay';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-fincen-investigation-document-modal',
    templateUrl: './fincen-investigation-document-modal.component.html'
})
export class FincenInvestigationDocumentModalComponent extends BaseModalComponent<FincenInvestigationDocumentModalComponent> implements OnInit {

    path: string;
    documentTypes: DocumentType[] = [];

    documentForm: UntypedFormGroup;
    files: File[] = new Array<File>(2);

    constructor(private reportService: ReportsService,
                private overlay: Overlay,
                private notificationService: NotificationService,
                private formBuilder: UntypedFormBuilder,
                dialogRef: MatDialogRef<FincenInvestigationDocumentModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
    }

    ngOnInit() {
        this.isDocumentFormValid = this.isDocumentFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.documentForm = this.formBuilder.group({
            personsFileCtrl: new UntypedFormControl(''),
            companiesFileCtrl: new UntypedFormControl('')
        });
        this.documentForm.disable();
    }

    close(saved?: boolean) {
        this.files = null;
        super.close(saved);
    }

    onSubmit(reset: any) {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        componentRef.instance.title = 'Uploading files.  Please wait...';

        this.reportService.uploadInvestigationFiles(this.files[0], this.files[1]).subscribe(() => {
            overlayRef.detach();
            this.notificationService.showSuccess('Document(s) were uploaded successfully.');
            this.close(true);
        }, () => {
            overlayRef.detach();
            reset();
            this.notificationService.showError('Document upload was unsuccessful. Please check your connection and try again.');
        });
    }

    selectFile(type: string, event: any) {
        const index = type === 'persons' ? 0 : 1;
        if (event.target.files.length) {
            const file = event.target.files[0];
            Utils.validateFile(file, event);
            this.files[index] = file;
            this.documentForm.controls[type + 'FileCtrl'].setValue(file.name);
            this.documentForm.controls[type + 'FileCtrl'].markAsDirty();
        } else {
            this.files[index] = null;
            this.documentForm.controls[type + 'FileCtrl'].setValue(null);
            this.documentForm.controls[type + 'FileCtrl'].markAsPristine();
        }
    }

    isDocumentFormValid() {
        return this.documentForm.controls['personsFileCtrl'].value ||  this.documentForm.controls['companiesFileCtrl'].value;
    }

}
