import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {
    DataroomService, RfiHistory, Rfi, RfiService, AuthService, PaymentService, SupportingDocument, Utils, WorkflowService, TransactionType, TransactionFormService, DocumentType
} from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { v4 as uuidv4 } from 'uuid';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-rfi-confirmation-modal',
    templateUrl: './rfi-confirmation-modal.component.html'
})
export class RfiConfirmationModalComponent extends BaseModalComponent<RfiConfirmationModalComponent> implements OnInit {

    rfi: Rfi;
    taskId: string;
    documentForm: UntypedFormGroup;
    files: File[];
    error: string;
    isCurrencyWithdrawal = false;

    constructor(private authService: AuthService,
                private transactionFormService: TransactionFormService,
                private paymentService: PaymentService,
                private dataroomService: DataroomService,
                private rfiService: RfiService,
                private workflowService: WorkflowService,
                private notificationService: NotificationService,
                dialogRef: MatDialogRef<RfiConfirmationModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.rfi = data.rfi;
        this.taskId = data.taskId;
    }

    ngOnInit() {
        this.isDocumentFormValid = this.isDocumentFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.documentForm = this.transactionFormService.initializeConfirmationForm(this.rfi.transaction);
        this.documentForm.get('requestedAmountCtrl').disable();
        this.documentForm.controls['actualAmountCtrl'].setValidators([Validators.required, Validators.min(0)]);
        this.documentForm.updateValueAndValidity();
        this.isCurrencyWithdrawal = this.rfi.transaction.transactionType === TransactionType.CURRENCY_WITHDRAWAL;
    }

    close(response?: any) {
        this.files = null;
        super.close(response);
    }

    onSubmit(reset: any) {
        let requestObservable: Observable<any> = this.submitRfiWithoutFilesToUpload();

        if (this.files?.length > 0) {
            requestObservable = this.submitRfiWithFilesToUpload(reset);
        }

        requestObservable.subscribe((result) => {
            const confirmedAmount = this.documentForm.controls['actualAmountCtrl'].value;
            this.close({
                confirmedAmount
            });
        });
    }

    submitRfiWithoutFilesToUpload() {
        return this.workflowService.getTask(this.taskId).pipe(
            switchMap(() => {
                return this.rfiSubmit(null);
            }),
            switchMap(() => {
                return this.completeTask();
            })
        );
    }

    submitRfiWithFilesToUpload(reset: any) {
        const filesToUpload: Array<Observable<any>> = this.files.map((file: File) => {
            return this.uploadFile(file);
        });

        return this.workflowService.getTask(this.taskId).pipe(
            switchMap((result) => {
                return this.uploadFilesAndCompleteTask(filesToUpload, reset);
            })
        );
    }

    uploadFilesAndCompleteTask(filesToUpload: Array<Observable<any>>, reset: any): Observable<SupportingDocument[]> {
        let uploadDocumentError = false;
        return forkJoin(filesToUpload).pipe(
            catchError((err) => {
                uploadDocumentError = true;
                reset();
                throw new Error('Document upload was unsuccessful. Please check your connection and try again.');
            }),
            switchMap((uploadResults) => {
                const rfiRequests = uploadResults.map((uploadResp: any) => {
                    return this.rfiSubmit(uploadResp.id);
                });
                return forkJoin(rfiRequests);
            }),
            switchMap((rfiResults) => {
                return this.completeTask().pipe(
                    map(() => {
                        return rfiResults;
                    })
                );
            }),
            switchMap((results) => {
                const transactionDocRequests = results.map((rfiResult: any) => {
                    return this.saveTransactionSupportingDocument(rfiResult.uploadId, rfiResult.historyId);
                });
                return forkJoin(transactionDocRequests);
            }),
            catchError((err) => {
                let message = err?.message;
                if (!uploadDocumentError) {
                    this.close();
                    this.router.navigate(['/dashboard']);
                    message = 'Task is no longer valid.';
                }
                throw new Error(message);
            })
        );
    }

    rfiSubmit(uploadId:string) {
        const rfiHistory = new RfiHistory();
        rfiHistory.notes = this.isCurrencyWithdrawal ? 'Withdrawal Confirmed.' : 'Deposit Confirmed.';
        if (this.documentForm.controls['notesCtrl'].value) {
            rfiHistory.notes = rfiHistory.notes + '{' + this.documentForm.controls['notesCtrl'].value + '}';
        }
        rfiHistory.transactionId = this.rfi.transactionId;
        rfiHistory.status = this.rfi.status;
        rfiHistory.rfiId = this.rfi.id;
        rfiHistory.performedBy = this.authService.getProfile().userId;

        return this.rfiService.saveRFIHistory(rfiHistory).pipe(
            map((history: RfiHistory) => {
                return {
                    historyId: history.id,
                    uploadId: uploadId
                };
            })
        );
    }

    private completeTask() {
        const confirmedAmount = this.documentForm.controls['actualAmountCtrl'].value;
        const newTransactionTotal = {
            value: confirmedAmount,
            type: 'String'
        };
        const approver = {
            value: this.authService.getProfile().userId,
            type: 'String'
        };

        const body = {
            variables : {
                newTransactionTotal: newTransactionTotal,
                approver: approver
            }
        };

        return this.workflowService.completeTask(this.taskId, body);
    }

    private saveTransactionSupportingDocument(uploadId: string, historyId: string) {
        const supportingDocument = new SupportingDocument();
        supportingDocument.documentId = uploadId;
        supportingDocument.memberId = this.rfi.memberId;
        supportingDocument.rfiHistoryId = historyId;
        return this.paymentService.saveTransactionSupportingDocument(this.rfi.transactionId, supportingDocument);
    }

    selectFile(event: any) {
        for (const file of event.target.files) {
            Utils.validateFile(file, event);
        }
        this.files = Array.from(event.target.files);
    }

    isDocumentFormValid() {
        return !this.documentForm.invalid && this.hasRequiredFile();
    }

    hasRequiredFile() {
        if (this.rfi.transaction.transactionType === TransactionType.CASH_DEPOSIT){
            return this.hasFile();
        }
        return true;
    }

    hasFile() {
        return this.files && this.files.length > 0;
    }

    onAmountChanged(amount: string) {
        if (Utils.formatNonNegativeCurrency(amount, true) !== Utils.formatNonNegativeCurrency(this.rfi.transaction.pendingAmount.toString(), true)) {
            this.error = 'Actual amount is different than pending amount.  Please confirm the amount and explain the discrepancy.';
            this.documentForm.controls['notesCtrl'].setValidators([Validators.required]);
        } else {
            this.error = null;
            this.documentForm.controls['notesCtrl'].setValidators(null);
        }
        this.documentForm.controls['notesCtrl'].updateValueAndValidity();
    }

    uploadFile(file: File) {
        const description = this.isCurrencyWithdrawal ? 'Withdrawal Confirmation' : 'Deposit Confirmation';
        const path = `${this.rfi.memberId}/${this.authService.getProfile().userId}/transaction/${this.rfi.transaction.submissionReference}/${uuidv4()}`;
        return this.dataroomService.uploadFile(this.rfi.memberId, path, file, file.name, description, this.isCurrencyWithdrawal ? DocumentType.WITHDRAWAL_CONFIRMATION : DocumentType.DEPOSIT_CONFIRMATION, this.rfi.memberId);
    }

    deleteFile(index: number) {
        this.files.splice(index, 1);
    }
}
