import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'pc-submit-button',
    templateUrl: './submit-button.component.html',
    styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent implements OnInit {

    @Input() text: string;
    @Input() enabled: any;
    @Input() submit: any;
    @Input() leftIcon: string;
    @Input() rightIcon: string;
    @Input() isSubmitButton = false;

    @Output() clickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    submitting = false;

    ngOnInit() {
        this.reset = this.reset.bind(this);
    }

    onClicked($event: any) {
        this.submitting = true;
        this.clickEvent.emit($event);
        this.submit(this.reset);
    }

    isDisabled() {
        if (this.enabled) {
            return !this.enabled() || this.submitting;
        }
        return this.submitting;
    }

    reset() {
        this.submitting = false;
    }
}
