import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { AuthService, MemberAccount, MemberAccountService, PagedResponse, PaymentService, Wallet, WalletFunction, WalletService } from 'projects/services/src/public-api';
import { MemberAccountSelectModalComponent } from 'projects/components/src/lib/modal';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AccountSelectorService {

    constructor(private authService: AuthService,
                private memberAccountService: MemberAccountService,
                private walletService: WalletService,
                private paymentService: PaymentService,
                private dialog: MatDialog) {
    }

    selectAccountAndPerform(memberId, includeCorporateAdmin, callback, requiredWalletFunction?: WalletFunction, invertWalletFunction?: boolean) {

        this.memberAccountService.getActiveMemberBusinessAccounts(memberId).subscribe((memberAccounts: PagedResponse<MemberAccount>) => {
            let filteredAccounts = memberAccounts.content.filter((memberAccount: MemberAccount) => {
                if (!this.memberAccountService.isAccountAdmin(memberAccount) && !this.authService.isCorporateAdmin() && !this.authService.isAuthority() && !this.authService.isRetailer()) {
                    return false;
                }
                if (requiredWalletFunction) {
                    if (invertWalletFunction) {
                        return !this.memberAccountService.hasWalletFunction(memberAccount, requiredWalletFunction);
                    } else {
                        return this.memberAccountService.hasWalletFunction(memberAccount, requiredWalletFunction);
                    }
                }
                return true;
            });
            if (filteredAccounts.length) {
                this.loadMemberAccounts(filteredAccounts).subscribe((results: MemberAccount[]) => {
                    filteredAccounts = results;
                    if (includeCorporateAdmin) {
                        const corporateAdminAccount = new MemberAccount();
                        corporateAdminAccount.accountName = 'Corporate Admin';
                        filteredAccounts.unshift(corporateAdminAccount);
                    }
                    if (filteredAccounts.length === 1) {
                        callback(filteredAccounts[0]);
                    } else if (filteredAccounts.length === 0) {
                        throw new Error('No available accounts match the required criteria.');
                    } else {
                        const dialogConfig: MatDialogConfig = {};
                        dialogConfig.autoFocus = true;
                        dialogConfig.panelClass = 'normal-modal';
                        dialogConfig.data = {
                            memberAccounts: filteredAccounts
                        };
                        const dialog = this.dialog.open(MemberAccountSelectModalComponent, dialogConfig);

                        dialog?.afterClosed().subscribe(
                            (memberAccount: MemberAccount) => {
                                if (memberAccount) {
                                    callback(memberAccount);
                                }
                            }
                        );
                    }
                });
            } else if (includeCorporateAdmin) {
                const corporateAdminAccount = new MemberAccount();
                corporateAdminAccount.accountName = 'Corporate Admin';
                filteredAccounts.unshift(corporateAdminAccount);
                callback(corporateAdminAccount);
            }
        });
    }

    loadMemberAccounts(memberAccounts: MemberAccount[]) : Observable<MemberAccount[]> {
        return forkJoin(memberAccounts.map((memberAccount: MemberAccount) => {
            return this.loadMemberAccount(memberAccount);
        }));
    }

    loadMemberAccount(memberAccount: MemberAccount) : Observable<MemberAccount> {
        return this.walletService.findByMemberAccountId(memberAccount.id).pipe(
            switchMap((wallet: Wallet) => {
                memberAccount.wallet = wallet;
                if (!this.memberAccountService.hasWalletFunction(memberAccount, WalletFunction.ACH_DIRECT_PAYMENT) && !this.authService.isRetailer()) {
                    return this.paymentService.getOutstandingCheckAmountForMemberAccount(memberAccount.id).pipe(
                        switchMap((outstandingAmount: number) => {
                            memberAccount.wallet.outstandingCheckAmount = outstandingAmount || 0;
                            return of(memberAccount);
                        })
                    );
                } else {
                    return of(memberAccount);
                }
            })
        );
    }
}
