import { Component, Input } from '@angular/core';
import { Receipt } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-metrc-receipt-summary',
    templateUrl: './member-metrc-receipt-summary.component.html'
})
export class MemberMetrcReceiptSummaryComponent {

  @Input() receipt: Receipt;

}
