import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { AuthService, BusinessCategory, DataroomService, DocumentType, OnboardingTrackingService, Upload, UploadStatus } from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OtherDocumentModalComponent } from 'projects/pt/src/app/components';
import { MatTable } from '@angular/material/table';

@Component({
    selector: 'pt-member-other-documents',
    templateUrl: './other-documents.component.html',
    styleUrls: ['./other-documents.component.scss']
})
export class MemberOtherDocumentsComponent implements OnInit {

    DocumentType = DocumentType;

    readonly DOCUMENT_TYPES = [
        DocumentType.ARTICLES_OF_INCORPORATION,
        DocumentType.AUTHORITY_RESOLUTION,
        DocumentType.OPERATING_AGREEMENT_OR_BYLAWS,
        DocumentType.CORPORATE_STRUCTURE,
        DocumentType.PASSPORT_OR_STATE_ISSUED_ID,
        DocumentType.EIN_SUPPORTING_EVIDENCE,
        DocumentType.BUSINESS_PLAN,
        DocumentType.CAPITALIZATION_TABLE,
        DocumentType.SHARE_REGISTRY,
        DocumentType.AR_AGING_REPORT,
        DocumentType.FINANCIAL_STATEMENTS,
        DocumentType.FINANCIAL_PROJECTIONS,
        DocumentType.TAX_RETURN,
        DocumentType.RECENT_BANK_STATEMENT,
        DocumentType.SAMPLE_ACH_INFO,
        DocumentType.OTHER_CORPORATE_DOCUMENT
    ];

    readonly ATM_DOCUMENT_TYPES = [
        DocumentType.ATM_LIST_AND_LOCATIONS,
        DocumentType.COPY_OF_SITE_OR_LOCATION_CONTRACT,
        DocumentType.CASH_MGNT_POLICY_AND_PROCEDURE,
        DocumentType.FLOW_OF_FUNDS_DESC
    ];

    @Input() memberId: string;
    @Input() businessCategory: string;
    @Input() task: any;
    @Input() changeTrigger: number;
    @Input() readOnly = false;
    @Input() allOptional = false;
    @Input() title: string = 'Documents';
    @Input() subtitle: string = 'If you have additional supporting information that would expedite your onboarding ' +
        'review process, please include them here.\n' +
        'Example documents include, capitalization table, incorporation documents, leases, etc.';

    @Output() next: EventEmitter<string> = new EventEmitter<string>();

    documents: Upload[];
    historicalDocuments: Upload[];
    uploadedDocumentTypes = [];
    displayedColumns = ['document_type', 'file', 'date_added', 'details'];
    displayedHistoricalColumns = ['document_type', 'file', 'date_added', 'details'];

    hasEditDocumentPrivilege = true;
    isATMBusiness = false;

    @ViewChild('downloadDocumentLink') downloadDocumentLink: ElementRef;
    @ViewChild('documentsTable') table: MatTable<Upload>;
    @ViewChild('historicalDocTable') historicalTable: MatTable<Upload>;

    constructor(public authService: AuthService,
                private dataroomService: DataroomService,
                private dialog: MatDialog) {}

    ngOnInit() {
        this.hasEditDocumentPrivilege = (!this.readOnly && (this.isAdmin() || this.isAuthority()));
        if (this.hasEditDocumentPrivilege) {
            this.displayedColumns = ['document_type', 'file', 'date_added', 'details', 'action'];
        }
        this.loadDocumentTypes();
        this.onNavigate = this.onNavigate.bind(this);
        this.isDocumentDetailsValid = this.isDocumentDetailsValid.bind(this);
        this.refreshList();
    }

    loadDocumentTypes() {
        if (this.businessCategory === BusinessCategory.ATM_BUSINESS) {
            this.isATMBusiness = true;
            this.DOCUMENT_TYPES.push(...this.ATM_DOCUMENT_TYPES);
        }
    }

    isAuthority() {
        return this.authService.isAuthority();
    }

    isAdmin() {
        return this.authService.isAdmin();
    }

    onNavigate() {
        this.next.emit();
    }

    refreshList() {
        this.documents = [];
        this.historicalDocuments = [];
        this.uploadedDocumentTypes = [];
        this.getDocuments();
        if (this.authService.isAuthorityOrReviewer()) {
            this.getHistoricalDocuments();
        }
    }

    getDocuments() {
        this.dataroomService.getUploads(this.memberId, [UploadStatus.ACTIVE], this.DOCUMENT_TYPES).subscribe((memberDocuments: Upload[]) => {
            this.documents = [];
            for (const document of memberDocuments) {
                if (!this.documents.some((doc) => {
                    return doc.id === document.id;
                })) {
                    this.documents.push(document);
                    if (!this.hasDocumentType(document.documentType)) {
                        this.uploadedDocumentTypes.push(document.documentType);
                    }
                }
                this.table.renderRows();
            }

            this.documents = this.documents.sort((x, y) => {
                return x.created > y.created ? -1 : (x.created > y.created ? 1 : 0);
            });

            OnboardingTrackingService.setDocuments(memberDocuments);
        });
    }

    getHistoricalDocuments() {
        this.dataroomService.getUploads(this.memberId, [UploadStatus.ARCHIVED], this.DOCUMENT_TYPES).subscribe((memberDocuments: Upload[]) => {
            this.historicalDocuments = [];
            for (const document of memberDocuments) {
                if (!this.historicalDocuments.some((doc) => {
                    return doc.id === document.id;
                })) {
                    this.historicalDocuments.push(document);
                }
                this.historicalDocuments = this.historicalDocuments.sort((x, y) => {
                    return x.created > y.created ? -1 : (x.created > y.created ? 1 : 0);
                });
            }
        });
    }

    getIconClass(required: boolean, documentType: DocumentType) {
        const isSubmitted = this.hasDocumentType(documentType);
        if (isSubmitted) {
            return 'fa submitted fa-check-circle-o';
        } else {
            if (required) {
                return 'fa required fa-question-circle-o';
            } else {
                return 'fa optional fa-question-circle-o';
            }
        }
    }

    hasDocumentType(documentType: DocumentType) {
        return this.uploadedDocumentTypes.indexOf(documentType) > -1;
    }

    downloadFile(document: any) {
        this.dataroomService.downloadResource(document, this.downloadDocumentLink);
    }

    onAddNewMemberDocument() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'Add Member Document',
            path: `${this.memberId}/`,
            memberId: this.memberId,
            referencedEntityId: this.memberId,
            documentTypes: this.DOCUMENT_TYPES,
            allowMultiple: true
        };
        const dialog = this.dialog.open(OtherDocumentModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.refreshList();
            }
        });
    }

    isDocumentDetailsValid() {
        return OnboardingTrackingService.isDocumentsValid();
    }

    deleteDocument(documentId: string) {
        this.dataroomService.deleteDocument(documentId).subscribe(() => {
            this.refreshList();
        });
    }
}
