<div *ngIf="viewTerms" class="interstitial" [class.view-terms]="viewTerms">
    <div>
        <p>
            By clicking 'Accept Terms' I hereby represent and warrant that I am an Authorized Official authorized
            to submit this Deposit Request. I, and on behalf of the Member, hereby certify that this Deposit Request
            is made pursuant to the terms and conditions of the Member Agreement, as may be amended, governing
            my Account. I, and on behalf of the Member, further certify and guarantee that:
        </p>
        <ul class="top-ul">
            <li>
                the funds, individually or in the aggregate, are proceeds obtained from lawful sources and lawful business
                operations and are not linked and/or derived from criminal origin, of whatsoever nature, and in particular
                do not constitute proceeds of money laundering or terrorist financing,
            </li>
            <li>
                the Deposit or a portion thereof is not made on behalf of a third party, and
            </li>
            <li>
                the receipt of such funds by the Member complied with:
                <ul class="inner-ul">
                    <li>all applicable federal laws, rules and regulations, and</li>
                    <li>all state laws, rules and regulations in the state of the Member’s operations.</li>
                </ul>
            </li>
        </ul>
        <p>
            In connection with this Deposit Request, the Member agrees to provide all information and documentary evidence
            of any nature whatsoever that Confia may request to understand the source of funds. I acknowledge and agree
            that Confia may determine, in its sole and absolute discretion, that all, some, or none of the Deposit funds
            may be deposited into the account.
        </p>
        <div class="modal-footer">
            <pc-submit-button [submit]="onAcceptTerms" [text]="'Accept Terms'"></pc-submit-button>
            <pc-submit-button [submit]="onCancelTerms" [text]="'Cancel'"></pc-submit-button>
        </div>
    </div>
</div>

<div class="deposit-stepper" *ngIf="formGroup" [class.view-terms]="viewTerms">
    <mat-stepper [@.disabled]="true" [linear]="true" #stepper [orientation]="(stepperOrientation | async)!">
        <ng-template matStepperIcon="invoice_selection">
            <mat-icon>request_quote</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="review">
            <mat-icon>verified</mat-icon>
        </ng-template>
        <mat-step state="invoice_selection" [completed]="false" [editable]="true" [label]="getInvoiceLabel()">
            <pt-retail-invoice-selection-step [currentBusinessClient]="currentBusinessClient" [memberAccount]="memberAccount" (invoicesSelected)="onSelectInvoices($event)" (nextStep)="nextStep()">
            </pt-retail-invoice-selection-step>
        </mat-step>
        <mat-step state="review" [completed]="false" [editable]="isReviewStepEditable()" label="Review">
            <pt-review-transaction-step *ngIf="currentBusinessClient?.transactionEntries?.length" [businessClients]="[currentBusinessClient]" [formGroup]="formGroup"
                [transactionTotal]="transactionTotal" [isReviewStepValid]="isReviewStepValid" [transactionTypes]="CASH_ONLY_DEPOSIT_METHODS" [subType]="TransactionSubType.DEPOSIT" (transactionReviewed)="onTransactionReviewed()">
            </pt-review-transaction-step>
        </mat-step>
    </mat-stepper>
</div>
