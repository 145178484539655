import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ReportsService, PartyInformation, FilingInstitution } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-reports-party-information',
    templateUrl: './reports-party-information.component.html'
})
export class ReportsPartyInformationComponent implements OnInit, OnChanges {

    @Input() submissionType: string;
    @Input() partyType: string;
    @Input() filingInstitution: FilingInstitution;

    partyInformation: PartyInformation;

    constructor(private reportsService: ReportsService) { }

    ngOnInit() {
        this.getPartyInformation();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes.partyType.firstChange || !changes.filingInstitution.firstChange) {
            this.getPartyInformation();
        }
    }

    getPartyInformation() {
        if (this.submissionType && this.partyType) {
            this.reportsService.getPartyInformation(this.submissionType, this.partyType, this.filingInstitution).subscribe((response: any) => {
                this.partyInformation = response;
            });
        }
    }

}
