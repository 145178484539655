<div class="splash-bg">
    <div class="splash-body">
        <img class="logo-login" src="assets/images/Confia.png">
        <div class="login-menu">
            <span class="login-link" (click)="login()">Login</span>
            <span *ngIf="consumerEnabled()" class="login-link" (click)="registerConsumer()">Consumer Sign Up</span>
            <span class="login-link" (click)="registerMemberAccount()">Register Business</span>
        </div>
    </div>
</div>
