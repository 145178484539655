import { Component, OnInit } from '@angular/core';
import { AuthService, BusinessCategory, Configuration, MemberFunction, MemberRegistrationType, Member, MemberService } from 'projects/services/src/public-api';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';

@Component({
    selector: 'pt-member-setting',
    templateUrl: './member-setting.component.html'
})
export class MemberSettingComponent implements OnInit {

    MemberRegistrationType = MemberRegistrationType;

    activeTab: string;
    memberId: string;
    isPartner: boolean;
    member: Member;
    memberRegistrationType: MemberRegistrationType;
    metrcEnabled = false;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private memberService: MemberService,
                public authService: AuthService) {}

    ngOnInit() {
        this.metrcEnabled = Configuration.getConfig().metrcEnabled;
        this.route.queryParams.subscribe((params) => {
            const activeTab = params['_activeTab'];
            if (activeTab && activeTab !== this.activeTab && this.memberId) {
                this.onSwitchTabs(params['_activeTab']);
            }
        });
        if (this.route.snapshot.queryParams['_activeTab']) {
            this.tabSwitch(this.route.snapshot.queryParams, false);
        } else {
            const queryParams: Params = {_activeTab: 'member-overview'};
            this.tabSwitch(queryParams, false, true);
        }
        this.memberId = this.authService.getProfile().memberId;
        this.isPartner = this.authService.hasMemberFunction(MemberFunction.PARTNER);
        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            if (member) {
                this.member = member;
                if (this.member?.businessCategory === BusinessCategory.ATM_BUSINESS) {
                    this.metrcEnabled = false;
                }
                this.memberRegistrationType = member.memberRegistrationType;
            }
        });
    }

    onSwitchTabs(activeTab: string) {
        const queryParams: Params = {_activeTab: activeTab};
        this.tabSwitch(queryParams);
    }

    tabSwitch(queryParams: Params, skipLocationChange?: boolean, replaceUrl?: boolean) {
        if (this.activeTab === queryParams['_activeTab']) {
            return;
        }
        this.activeTab = queryParams['_activeTab'];
        if (replaceUrl) {
            this.router.navigate(['/administration/settings'], {queryParams, skipLocationChange, replaceUrl: true});
        } else {
            this.router.navigate(['/administration/settings'], {queryParams});

        }
        UIUtils.scrollDashboardToTop();
    }

    getTabClass(tabOption: string) {
        if (this.activeTab === tabOption) {
            return 'tab-option-selected';
        } else {
            return 'tab-option';
        }
    }

    isAdmin() {
        return this.authService.isAdmin();
    }
}
