import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { HttpResponse } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import { IMyDateModel } from 'angular-mydatepicker';

import {
    AuthService, MessageType, MemberType, Role, MemberStatus, Member, Message, MessageService, DataroomService, MemberService, UserService, CountNotificationService, PagedResponse, User
} from 'projects/services/src/public-api';
import { MessageModalComponent } from 'projects/components/src/lib/modal';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';

@Component({
    selector: 'pc-my-messages',
    templateUrl: './my-messages.component.html',
    styleUrls: ['./my-messages.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class MyMessagesComponent implements OnInit, AfterViewInit, OnDestroy {

    MemberType = MemberType;
    MemberStatus = MemberStatus;
    Role = Role;
    MessageType = MessageType;

    @Input() memberId: string;
    @Input() memberStatus: MemberStatus;

    /**
     * This field also controls if the "member-selector" is enabled or not. If provided, then it's going to fill the field
     * with the provided member name.
     *
     * If provided, when submit the form, the provided input memberId will be used.
     */
    @Input() memberName: string;

    messageType = MessageType.ALL;
    startDate: string;
    endDate: string;
    formGroup: UntypedFormGroup;
    member: Member;

    messages : Message[] = [];
    currentMemberId: string;
    showSummary: boolean[] = [];
    expandedElement: Message;
    displayedColumns = ['readStatus', 'subject', 'senderMember', 'recipientMember', 'created', 'attachment'];

    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    subscription: any;
    pageTracking: PageTracking;
    isLoadingResults = true;
    resultsLength = 0;

    @ViewChild('downloadMessageLink') downloadMessageLink: ElementRef;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private dialog: MatDialog,
                private route: ActivatedRoute,
                private router: Router,
                public authService: AuthService,
                private datePipe: DatePipe,
                private messageService: MessageService,
                private dataroomService: DataroomService,
                private memberService: MemberService,
                private userService: UserService,
                private countNotificationService: CountNotificationService,
                private cdr: ChangeDetectorRef,
                private formBuilder: UntypedFormBuilder) {
    }

    ngOnInit() {
        this.currentMemberId = this.authService.getProfile().memberId;
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'created', 'desc', 100);
        this.loadDisplayColumns();

        this.formGroup =  this.formBuilder.group({
            memberCtrl : new UntypedFormControl(),
            dateCtrl : new UntypedFormControl()
        });
        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['messageType'] || queryParams['startDate'] || queryParams['endDate'] || queryParams['memberId']) {
            this.messageType = queryParams['messageType'];
            this.startDate = queryParams['startDate'];
            this.endDate = queryParams['endDate'];
            if (this.startDate && this.endDate) {
                this.formGroup.controls['dateCtrl'].setValue({ isRange: true, singleDate: null, dateRange: { beginJsDate: new Date(this.startDate + ' 00:00:00'), endJsDate: new Date(this.endDate + ' 00:00:00') } });
            } else {
                this.formGroup.controls['dateCtrl'].setValue(null);
            }
            this.memberId = queryParams['memberId'];
            if (this.memberId) {
                this.formGroup.controls['memberCtrl'].setValue(this.memberId);
            }
        } else {
            this.messageType = MessageType.ALL;
            this.startDate = '';
            this.endDate = '';
            this.memberId = this.memberId ? this.memberId : '';
            this.updateQueryParams(true);
        }
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort, this.refreshEvent);
        this.addTableLoadListener();
        setTimeout(() => {
            this.route.queryParams.subscribe((params) => {
                this.messageType = params['messageType'] || MessageType.ALL;
                this.startDate = params['startDate'] || '';
                this.endDate = params['endDate'] || '';
                if (this.startDate && this.endDate) {
                    this.formGroup.controls['dateCtrl'].setValue({
                        isRange: true,
                        singleDate: null,
                        dateRange: {beginJsDate: new Date(this.startDate + ' 00:00:00'), endJsDate: new Date(this.endDate + ' 00:00:00')}
                    });
                } else {
                    this.formGroup.controls['dateCtrl'].setValue(null);
                }
            });
        }, 100);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                UIUtils.scrollToTop(document.querySelector('.mat-table-container'));
                this.isLoadingResults = true;
                this.loadDisplayColumns();
                if (this.messageType === MessageType.SENT) {
                    return this.messageService.loadSentMessages(this.memberId, this.startDate, this.endDate, MessageType.SENT, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
                } else if (this.messageType === MessageType.RECEIVED) {
                    return this.messageService.loadReceivedMessages(this.memberId, this.startDate, this.endDate, MessageType.RECEIVED, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction, null);
                } else {
                    return this.messageService.loadAllMessages(this.memberId, this.messageType, this.startDate, this.endDate, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
                }
            }),
            map((response: PagedResponse<Message>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((messages: Message[]) => {
            this.messages = messages;
            for (const message of this.messages) {
                this.loadSender(message);
                this.getMemberAccountName(false, message);
                this.getMemberAccountName(true, message);
                this.loadMessageDocument(message);
            }
        });
    }

    getMemberAccountName(sender: boolean, message: Message) {
        if (sender) {
            this.memberService.loadMember(message.recipientMemberId).subscribe((recipient: Member) => {
                message.recipientMemberName = recipient.name;
            });
        } else {
            this.memberService.loadMember(message.senderMemberId).subscribe((sender: Member) => {
                message.senderMemberName = sender.name;
            });
        }
    }

    loadMessageDocument(message: Message) {
        if (message.documentId) {
            this.dataroomService.getDocumentById(message.documentId).subscribe((upload: any) => {
                message.document = upload;
            });
        }
        this.showSummary.push(false);
    }

    loadSender(message: Message) {
        if (message.senderId) {
            this.userService.loadUser(message.senderId).subscribe((user: User) => {
                message.senderName = user.firstName + ' ' + user.lastName;
            });
        }
        if (message.recipientId) {
            this.userService.loadUser(message.recipientId).subscribe((user: User) => {
                message.recipientName = user.firstName + ' ' + user.lastName;
            });
        }
    }

    downloadFile(document: any) {
        this.dataroomService.downloadResource(document, this.downloadMessageLink);
    }

    getReadStatusClass(message: Message) {
        return (this.messageType === MessageType.RECEIVED || (this.messageType === MessageType.ALL && message.recipientMemberId === this.currentMemberId)) && !message.readStatus ? 'unread-msg-font' : 'read-msg-font';
    }

    handleClick($event: any, message: Message, toggle: boolean) {
        if (toggle && $event.target.tagName === 'I') {
            this.updateReceivedMsgStatus(message, toggle);
            $event.stopPropagation();
        } else {
            if (this.expandedElement === message) {
                this.expandedElement = null;
            } else {
                this.expandedElement = message;
                this.updateReceivedMsgStatus(message, toggle);
            }
        }
    }

    refreshPage() {
        this.refreshEvent.emit();
    }

    updateReceivedMsgStatus(message: Message, toggle: boolean) {
        if ((this.messageType === MessageType.RECEIVED || (this.messageType === MessageType.ALL && message.recipientMemberId === this.currentMemberId)) &&
            ((!message?.readStatus && !toggle) || toggle)) {
            this.messageService.updateMessageReadStatus(message.id, { readStatus: toggle ? !message.readStatus : true }).subscribe(
                (updatedMessage: Message) => {
                    message.readStatus = updatedMessage.readStatus;
                    this.messageService.countReceivedMessages().subscribe((response: HttpResponse<Object>) => {
                        this.countNotificationService.setUnreadMessageCount(parseInt(response.headers.get('Content-Length'), 10));
                    });
                }
            );
        }
    }

    memberSelected(member: Member) {
        if (member.id) {
            this.memberId = member.id;
        } else {
            this.memberId = '';
        }
        this.updateQueryParams(false);
    }

    onAddNewMessage() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        if (this.memberName) {
            dialogConfig.data = {
                recipientMemberId: this.memberId,
                recipientMemberName: this.memberName
            };
        } else {
            dialogConfig.data = {};
        }

        const dialog = this.dialog.open(MessageModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(() => {
            this.refreshEvent.emit();
        });
    }

    onTypeChanged() {
        this.updateQueryParams(false, true);
    }

    onDateChanged(event: IMyDateModel) {
        if (event && event.dateRange) {
            this.startDate = this.datePipe.transform(event.dateRange.beginJsDate, 'yyyy-MM-dd');
            const endDateRange = event.dateRange.endJsDate.setDate(event.dateRange.endJsDate.getDate());
            this.endDate = this.datePipe.transform(endDateRange, 'yyyy-MM-dd');
        } else {
            this.startDate = '';
            this.endDate = '';
        }
        this.updateQueryParams(false);
    }

    updateQueryParams(replace: boolean, resetSort?: boolean) {
        let queryParams;
        if (resetSort) {
            queryParams = { messageType: this.messageType, memberId: this.memberId, startDate: this.startDate, endDate: this.endDate, page: 1, num: this.pageTracking.pageSize, sort: 'created', dir: 'desc' };
        } else {
            queryParams = { messageType: this.messageType, memberId: this.memberId, startDate: this.startDate, endDate: this.endDate, page: 1, num: this.pageTracking.pageSize, sort: this.pageTracking.sort, dir: this.pageTracking.sortDir};
        }
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }

    isMemberSelectorVisible() {
        // don't show the selector if we are looking at the messages section for a specific org
        return !this.memberName;
    }

    loadDisplayColumns() {
        if (this.messageType === MessageType.SENT) {
            this.displayedColumns = ['subject', 'senderMember', 'recipientMember', 'created', 'attachment'];
        } else {
            this.displayedColumns = ['readStatus', 'subject', 'senderMember', 'recipientMember', 'created', 'attachment'];
        }
    }
}
