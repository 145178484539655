import { Injectable, Inject, ElementRef } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { HttpService } from './http.service';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DocumentType, Upload, UploadStatus } from './models/upload';

@Injectable({
    providedIn: 'root'
})
export class DataroomService {

    baseUrl: string;

    constructor(private http: HttpService,
                private authService: AuthService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.DataroomAPIEndpoint + '/';
    }

    getDocumentById(id: string) : Observable<Upload> {
        return this.http.get(`${this.baseUrl}file/${id}`) as Observable<Upload>;
    }

    getUploads(memberId: string, statuses: UploadStatus[], types?: DocumentType[], referencedEntityId?: string) {
        const params = `?memberId=${memberId}&statuses=${statuses}&types=${types || ''}&referencedEntityId=${referencedEntityId || ''}`;
        return this.http.get(`${this.baseUrl}file${params}`);
    }

    uploadFile(memberId, path: string, file: File, fileName: string, explanation: string, documentType: DocumentType, referencedEntityId: string) {
        const formData = new FormData();
        formData.append('file', file);
        const metadata = {
            memberId: memberId,
            path,
            fileName,
            referencedEntityId,
            explanation: explanation ? explanation : '',
            documentType: documentType
        };
        formData.append('metadata', JSON.stringify(metadata));
        return this.http.post(`${this.baseUrl}file`, formData);
    }

    downloadResource(document: any, element: ElementRef) {
        return this.http.getDownload(`${this.baseUrl}file/${document.id}/download`).subscribe(
            (response: HttpResponse<Blob>) => {
                const binaryData = [];
                binaryData.push(response);
                const url = window.URL.createObjectURL(new Blob(binaryData, {type: 'blob'}));
                const link = element.nativeElement;
                link.href = url;
                link.download = document.fileName;
                link.click();
                window.URL.revokeObjectURL(url);
            }
        );
    }

    uploadImage(file: File, memberId: string, path: string) {
        const url = '';
        const fileName = file.name;
        const formData = new FormData();
        formData.append('image', file);
        const metadata = {
            fileName,
            memberId: memberId,
            url,
            path
        };
        formData.append('metadata', JSON.stringify(metadata));
        return this.http.post(`${this.baseUrl}image`, formData);
    }

    getImage(memberId: string) {
        return this.http.getDownload(`${this.baseUrl}image/${memberId}`);
    }

    deleteDocument(documentId: string) {
        return this.http.delete(`${this.baseUrl}file/${documentId}`);
    }

}
