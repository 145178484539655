import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
    License, DataroomService, LicenseService, AuthService, PagedResponse, OnboardingTrackingService, Address, AddressService, LicenseStatus, MemberType,
    MemberService, Member
} from 'projects/services/src/public-api';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { LicenseModalComponent } from 'projects/pt/src/app/components';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pt-license-list',
    templateUrl: './license-list.component.html'
})
export class LicenseListComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() memberId: string;
    @Input() readOnly = false;
    @Input() allowAdd = true;

    @Output() licensesUpdated: EventEmitter<License[]> = new EventEmitter<License[]>();

    displayedColumns = ['jurisdiction', 'license_number', 'license_holder', 'expiration_date', 'note', 'document', 'status', 'action'];
    licenses: License[];

    pageTracking: PageTracking;
    member: Member;
    resultsLength = 0;
    subscription: any;
    isLoadingResults = true;
    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('downloadLicenseLink') downloadLicenseLink: ElementRef;

    constructor(private licenseService: LicenseService,
                private dataroomService: DataroomService,
                private memberService: MemberService,
                private addressService: AddressService,
                public authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private dialog: MatDialog) {}

    ngOnInit() {
        if (this.readOnly) {
            this.displayedColumns = ['jurisdiction', 'license_number', 'license_holder', 'expiration_date', 'note', 'document', 'status'];
        }
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'expirationDate', 'desc');
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                this.loadMemberAccount();
                return this.licenseService.loadMemberLicenses(this.memberId, [], this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<License>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((licenses: License[]) => {
            this.licenses = licenses;
            this.setLicenseDetails();
            for (const license of this.licenses) {
                this.loadLicense(license);
            }
            this.setUpLicenseInfo();
            UIUtils.scrollDashboardToTop();
        });
    }

    setUpLicenseInfo() {
        if (this.member?.memberType === MemberType.BUSINESS_MERCHANT) {
            OnboardingTrackingService.setLicenseInfo(this.licenses.filter((license) => {
                return license.status === LicenseStatus.ACTIVE;
            }));
        }
    }

    loadMemberAccount() {
        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            this.addressService.getPrimaryAddress(member.id).subscribe((address: Address) => {
                this.member = member;
                OnboardingTrackingService.setMemberInfo(address, member);
            });
        });
    }

    loadLicense(license: License) {
        if (license.documentId) {
            this.dataroomService.getDocumentById(license.documentId).subscribe((upload: any) => {
                license.document = upload;
            });
        }
    }

    downloadFile(document: any) {
        this.dataroomService.downloadResource(document, this.downloadLicenseLink);
    }

    refreshList() {
        this.refreshEvent.emit();
    }

    onAddLicense() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'full-modal';

        dialogConfig.data = {
            memberId: this.memberId
        };
        const dialog = this.dialog.open(LicenseModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.refreshList();
            }
        });
    }

    onEditLicense(license: License) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'full-modal';

        dialogConfig.data = {
            memberId: this.memberId,
            license
        };
        const dialog = this.dialog.open(LicenseModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.refreshList();
            }
        });
    }

    setLicenseDetails() {
        this.licensesUpdated.emit(this.licenses);
    }

    deleteLicense(licenseId: string) {
        const updates = {
            status: LicenseStatus.DISABLED
        };
        this.licenseService.updateLicense(licenseId, updates).subscribe(() => {
            this.refreshList();
        });
    }

    isLicenseDisabled(license: License) {
        return license.status !== LicenseStatus.ACTIVE;
    }
}
