import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DataroomService, RfiHistory, Rfi, DocumentFormService, RfiService, AuthService, PaymentService, SupportingDocument, Utils, DocumentType } from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { v4 as uuidv4 } from 'uuid';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-rfi-document-modal',
    templateUrl: './rfi-document-modal.component.html'
})
export class RfiDocumentModalComponent extends BaseModalComponent<RfiDocumentModalComponent> implements OnInit {

    rfi: Rfi;
    documentForm: UntypedFormGroup;
    files: File[];
    authorityOnly = false;
    allowMultiple = false;

    constructor(private authService: AuthService,
                private documentFormService: DocumentFormService,
                private paymentService: PaymentService,
                private dataroomService: DataroomService,
                private rfiService: RfiService,
                private notificationService: NotificationService,
                dialogRef: MatDialogRef<RfiDocumentModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.rfi = data.rfi;
        this.authorityOnly = data.authorityOnly;
        this.allowMultiple = data.allowMultiple;
    }

    ngOnInit() {
        this.isDocumentFormValid = this.isDocumentFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.documentForm = this.documentFormService.initializeDocumentForm();
        this.documentForm.controls['documentTypeCtrl'].patchValue('Other');
    }

    close(rfiHistory?: RfiHistory) {
        this.files = null;
        super.close(rfiHistory);
    }

    onSubmit(reset: any) {
        if (this.files && this.files.length <= 0) {
            return;
        }

        const filesToUpload: Array<Observable<any>> = this.files.map((file: File) => {
            return this.uploadFile(file);
        });

        filesToUpload.push(this.saveRfiHistory(this.files));

        forkJoin(filesToUpload).pipe(
            switchMap((results: any) => {
                const rfiHistory = results.pop();
                const observables:Array<Observable<any>> = results.map((result: any) => {
                    return this.saveSupportingDocument(rfiHistory.id, result.id);
                });

                observables.push(of(rfiHistory));
                return forkJoin(observables);
            })
        ).subscribe((results) => {
            this.close(results.pop());
        }, () => {
            reset();
            this.notificationService.showError('Document upload was unsuccessful. Please check your connection and try again.');
        });
    }

    uploadFile(file: File) {
        const description = this.documentForm.controls['notesCtrl'].value;
        const path = `${this.rfi.memberId}/${this.authService.getProfile().userId}/transaction/${this.rfi.transaction.submissionReference}/${uuidv4()}`;
        return this.dataroomService.uploadFile(this.rfi.memberId, path, file, file.name, description, DocumentType.TRANSACTION_COMPLIANCE_DOCUMENT, this.rfi.memberId);
    }

    saveRfiHistory(files: File[]) {
        const rfiHistory = new RfiHistory();
        const description = this.documentForm.controls['notesCtrl'].value;

        const joinedFileNames = files.map((file) => {
            return file.name;
        }).join(', ');

        const singleFileName = 'Uploaded supporting document ' + `${files[0].name}`;
        const multipleFileNames = 'Uploaded supporting documents ' + `${joinedFileNames}`;

        rfiHistory.notes = description || this.files.length > 1 ? multipleFileNames : singleFileName;
        rfiHistory.transactionId = this.rfi.transactionId;
        rfiHistory.status = this.rfi.status;
        rfiHistory.rfiId = this.rfi.id;
        rfiHistory.authorityOnly = this.authorityOnly;
        rfiHistory.performedBy = this.authService.getProfile().userId;
        return this.rfiService.saveRFIHistory(rfiHistory);
    }

    saveSupportingDocument(rfiHistoryId, documentId) {
        const supportingDocument: SupportingDocument = new SupportingDocument();
        supportingDocument.documentId = documentId;
        supportingDocument.authorityOnly = this.authorityOnly;
        supportingDocument.memberId = this.rfi.memberId;
        supportingDocument.rfiHistoryId = rfiHistoryId;
        return this.paymentService.saveTransactionSupportingDocument(this.rfi.transactionId, supportingDocument);
    }

    selectFile(event: any) {
        for (const file of event.target.files) {
            Utils.validateFile(file, event);
        }
        this.files = Array.from(event.target.files);
        event.target.value = '';
    }

    deleteFile(index: number) {
        this.files.splice(index, 1);
    }

    isDocumentFormValid() {
        return !this.documentForm.invalid && this.files && this.files.length > 0;
    }

}
