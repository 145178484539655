 <div class="col-12">
    <div class="row mb-3">
        <div class="col-8">
            <span class="form-title">Bank Accounts</span>
        </div>
        <div class="col-4">
            <button pc-no-dbl-click *ngIf="authService.isAdmin() || authService.isConsumer()" class="blue-button pull-right" (click)="getAccountsYodlee()" type="button" submit-button><i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add Bank Account</div></button>
        </div>
    </div>
    <div class="col-sm-12 horizontal-scroll mat-table-container">
        <div class="mat-table-spinner" *ngIf="isLoadingResults">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table [dataSource]="accounts" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
            <ng-container matColumnDef="status">
                <th class="centered" mat-header-cell  *matHeaderCellDef mat-sort-header="status"> Status </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <pc-bank-account-badge [parent]="element"></pc-bank-account-badge>
                </td>
            </ng-container>
            <ng-container matColumnDef="account_number">
                <th class="centered" mat-header-cell *matHeaderCellDef> Account Number </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span *ngIf="element.accountNumber"> {{ getAccountNumber(element.accountNumber) }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="ach_routing_number">
                <th class="centered" mat-header-cell *matHeaderCellDef> ACH Routing Number </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span *ngIf="element.accountNumber"> {{ getRoutingNumber(element.routingNumber) }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="wire_routing_number">
                <th class="centered" mat-header-cell *matHeaderCellDef> Wire Routing Number </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span *ngIf="element.wireRoutingNumber"> {{ getRoutingNumber(element.wireRoutingNumber) }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="holder">
                <th mat-header-cell *matHeaderCellDef> Holder </th>
                <td mat-cell *matCellDef="let element">
                    <span>
                        <b *ngIf="element.holder">{{element.holder}}</b>
                        <span *ngIf="element.externalAccountDetails?.phone">
                            <br>
                            {{element.externalAccountDetails.phone | phone}}
                        </span>
                        <span *ngIf="element.externalAccountDetails?.email">
                            <br>
                            {{element.externalAccountDetails.email}}
                        </span>
                    </span>
                    <pc-business-address *ngIf="element.externalAccountDetails?.addressId" [addressId]="element.externalAccountDetails.addressId">
                    </pc-business-address>
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell  *matHeaderCellDef mat-sort-header="name"> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="type"> Type </th>
                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
            </ng-container>
            <ng-container matColumnDef="source">
                <th mat-header-cell *matHeaderCellDef> Source </th>
                <td mat-cell *matCellDef="let element"> {{element.source | translate}} </td>
            </ng-container>
            <ng-container matColumnDef="date_added">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Date Added </th>
                <td mat-cell *matCellDef="let element"> {{ element.created | date:'mediumDate'}} </td>
            </ng-container>
            <ng-container matColumnDef="financial_institution">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="financialInstitution"> Bank </th>
                <td mat-cell *matCellDef="let element">  {{element.financialInstitution}} </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell class="centered" *matHeaderCellDef> Actions </th>
                <td mat-cell  class="action centered" *matCellDef="let element">
                    <i *ngIf="isAdmin() && (element.status === BankAccountStatus.ACTIVE || element.status === BankAccountStatus.DISABLED)" class="fa" [class.fa-toggle-on]="isEnabled(element)" [class.fa-toggle-off]="!isEnabled(element)" (click)="toggleEnabled($event, element)"></i>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="!isEnabled(row) ? 'disabled' : ''"></tr>
        </table>
        <mat-paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>
 </div>
