<fieldset class="form-group" *ngIf="autoCompleteControl">
    <div class="row inline">
        <div class="col-12" [formGroup]="formGroup">
            <div class="inputGroup">
                <label *ngIf="label" class="form-label text-nowrap pt-1 px-2">{{ label | translate }}</label>
                <input #counterPartySelect [formControl]="autoCompleteControl" type="text" [placeholder]="placeholder" class="form-control form-input-editable min-width pl-1" [ngClass]="class" [matAutocomplete]="auto"
                       (keydown)="onInputChanged($event)" (click)="$event.target.select()">
                <i class="fa" [ngClass]="selected() ? 'fa-times-circle-o' : 'fa-chevron-down'" (click)="onIconClick($event)"></i>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="counterPartyChanged($event)">
                    <mat-option [ngClass]="searching ? 'hidden' : ''" *ngFor="let counterParty of counterPartiesAutoComplete | async;" [value]="counterParty">
                        {{ counterParty }}
                    </mat-option>
                    <mat-option *ngIf="searching">
                        <i class="fa fa-spin fa-spinner"></i>Searching
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>
</fieldset>
