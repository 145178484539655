import { Injectable, Inject } from '@angular/core';
import { HttpService } from '../http.service';

@Injectable({
    providedIn: 'root'
})
export class IDPService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.SecurityAPIEndpoint + '/security/';
    }

    signinUser(authRequest: any, callback: any, errorCallback: any) {
        this.http.post(`${this.baseUrl}authenticate`, authRequest).subscribe((authentication: any) => {
            callback(authentication.token);
        },
        (error: any) => {
            errorCallback(error);
        });
    }

    updateCurrentMember(memberId: any, feeAccount: boolean) {
        const request = {
            memberId: memberId,
            feeAccount
        };
        return this.http.put(`${this.baseUrl}authenticate`, request);
    }

    updateCurrentRetailLocation(memberId: string, retailLocationId: any) {
        const request = {
            memberId,
            retailLocationId
        };
        return this.http.put(`${this.baseUrl}authenticate`, request);
    }

    userExists(username: any) {
        return this.http.head(`${this.baseUrl}authenticate?username=${encodeURIComponent(username)}`);
    }

    updatePassword(passwordDetails: any) {
        return this.http.post(`${this.baseUrl}password`, passwordDetails);
    }

    refresh() {
        return this.http.get(`${this.baseUrl}refresh`);
    }

    logout() {
        this.http.post(`${this.baseUrl}logout`, {}).subscribe(
            (_response: any) => {
                // do nothing
            },
            (_error: any) => {
                // do nothing, but especially do not throw an error, just swallow it
            }
        );
    }
}
