import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PartnerSettings } from '../models/partner-settings';
import { CustomValidators } from '../validators/custom.validators';

@Injectable({
    providedIn: 'root'
})
export class PartnerSettingsFormService{

    constructor(private formbuilder: UntypedFormBuilder) { }

    initializeForm(partnerSettings: PartnerSettings): UntypedFormGroup {
        const form = this.formbuilder.group({
            idCtrl: new UntypedFormControl(),
            createdCtrl: new UntypedFormControl(),
            updatedCtrl: new UntypedFormControl(),
            memberIdCtrl: new UntypedFormControl(),
            enableSMSValidation: new UntypedFormControl(),
            acceptPayments: new UntypedFormControl(),
            sendPayments: new UntypedFormControl(),
            memberCallbackUriCtrl: new UntypedFormControl('', CustomValidators.absoluteURI),
            paymentCallbackUriCtrl: new UntypedFormControl('', CustomValidators.absoluteURI),
            usernameCtrl: new UntypedFormControl(),
            passwordCtrl: new UntypedFormControl()
        });
        if (partnerSettings) {
            form.patchValue({
                idCtrl: partnerSettings.id,
                createdCtrl: partnerSettings.created,
                updatedCtrl: partnerSettings.updated,
                memberIdCtrl: partnerSettings.memberId,
                enableSMSValidation: partnerSettings.enableSMSValidation,
                acceptPayments: partnerSettings.acceptPayments,
                sendPayments: partnerSettings.sendPayments,
                memberCallbackUriCtrl: partnerSettings.memberCallbackUri,
                paymentCallbackUriCtrl: partnerSettings.paymentCallbackUri,
                usernameCtrl: partnerSettings.username,
                passwordCtrl: partnerSettings.password
            });
        }
        return form;
    }
}
