<div class="horizontal-scroll mat-table-container">
    <div class="table mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="workflowComments">
        <ng-container matColumnDef="date">
            <th class="centered" mat-header-cell *matHeaderCellDef> Date </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <pc-date-time [date]="element.time"></pc-date-time>
            </td>
        </ng-container>
        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef> User </th>
            <td mat-cell *matCellDef="let element">
                <div class="px-2 text-nowrap">{{ element.message.authorName }}</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef> Comment </th>
            <td mat-cell *matCellDef="let element">
                {{ element.message.comment }}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
