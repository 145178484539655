export class Loan {
    id?: string;
    created?: Date;
    memberId: string;
    lenderId: string;
    name: string;
    lenderName?: string;
    status: LoanStatus;
    amount: number;
    interestRate: number;
    recurringPaymentAmount: number;
    paymentStartDate?: any;
    paymentFrequency: PaymentFrequency;
    maturity: any;
    financialDataAccess: Date;
}

export enum LoanStatus {
    ALL = '',
    PENDING = 'PENDING',
    CLOSED = 'CLOSED',
    DECLINED = 'DECLINED',
    DILIGENCE = 'DILIGENCE',
    ACTIVE = 'ACTIVE',
    CANCELLED = 'CANCELLED'
}

export enum PaymentFrequency {
    YEARLY = 'YEARLY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY'
}
