<div class="horizontal-scroll mat-table-container">
    <div class="table mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="members"  matSort matSortDisableClear [matSortActive]="pageTracking.sort" [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="business_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="sortName"> {{consumerView ? 'Consumer Name' : 'Business Name'}} </th>
            <td mat-cell *matCellDef="let element">
                <img *ngIf="element.memberType === MemberType.CONSUMER || element.memberType === MemberType.INDIVIDUAL_MERCHANT" class="member-type-icon" src="assets/images/profile-icon.svg" title="Individual">
                <a (click)="memberDetails($event, element)" class="link">
                    <b> {{ element.name }} </b>
                    <br>
                    <b *ngIf="element.dbaName && element.memberType !== MemberType.CONSUMER && element.memberType === MemberType.INDIVIDUAL_MERCHANT"> DBA: {{ element.dbaName }} </b>
                </a>
                <div>
                    <pc-business-address *ngIf="element.address" [address]="element.address">
                    </pc-business-address>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="contact_info">
            <th mat-header-cell *matHeaderCellDef> Contact Info </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.email"><b>Email:</b> {{ element.email }}<br></span>
                <span *ngIf="element.phone"><b>Phone:</b> {{ element.phone | phone }} </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="notifications">
            <th mat-header-cell *matHeaderCellDef class="centered"> Notifications </th>
            <td mat-cell *matCellDef="let element" class="centered"> {{ element.notificationCount }} </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="centered" mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let element" class="centered">
                <span *ngIf="element.status">
                    <pc-member-badge [parent]="element"></pc-member-badge>
                    <br>
                    <div *ngIf="element.jurisdiction">{{ Utils.getJurisdictionName(element.jurisdiction) }}</div>
                    <div *ngIf="!element.memberFunctions.length">{{ element.type | translate }}</div>
                    <div *ngIf="element.memberFunctions.length && element.memberFunctions.indexOf(MemberFunction.PARTNER) >= 0">
                        {{ MemberFunction.PARTNER | translate }}
                    </div>
                    <div *ngIf="element.memberFunctions.length && element.memberFunctions.indexOf(MemberFunction.LENDER) >= 0">
                        {{ MemberFunction.LENDER | translate }}
                    </div>
                    <div *ngIf="element.tier === RiskTier.TIER_1">Tier 1</div>
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef class="centered" mat-sort-header> Initiated Date </th>
            <td mat-cell *matCellDef="let element" class="centered"> {{ element.created | date:'mediumDate' }} </td>
        </ng-container>
        <ng-container matColumnDef="approved_date">
            <th mat-header-cell *matHeaderCellDef class="centered" mat-sort-header="approvedDate"> Approved Date </th>
            <td mat-cell *matCellDef="let element" class="centered">
                <span *ngIf="activeTab === 'members' || activeTab === 'consumers'"> {{ element.approvedDate | date:'mediumDate' }}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="submitted_date">
            <th mat-header-cell *matHeaderCellDef class="centered"> Submitted Date </th>
            <td mat-cell *matCellDef="let element" class="centered"> <span> {{ element.submittedDate | date:'mediumDate' }} </span> </td>
        </ng-container>
        <ng-container matColumnDef="enabled">
            <th mat-header-cell *matHeaderCellDef class="centered"> Enabled </th>
            <td mat-cell *matCellDef="let element" class="centered">
                <i *ngIf="element.status !== MemberStatus.BLOCKED && element.status !== MemberStatus.FROZEN && authService.isAuthority()" class="fa fa-2x" [class.fa-toggle-on]="isEnabled(element)" [class.fa-toggle-off]="!isEnabled(element)" (click)="toggleEnabled($event, element)"></i>
            </td>
        </ng-container>
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="centered"> Action </th>
            <td mat-cell *matCellDef="let element" class="centered">
                <span class="d-flex justify-content-center" (click)="deleteApplicant(element)" *ngIf="(element.status === MemberStatus.UNDER_REVIEW || element.status === MemberStatus.APPLIED) && authService.isAuthority()">
                    <i class="fa fa-trash"></i>
                </span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.disabled]="!isEnabled(row)"></tr>
    </table>
    <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"></mat-paginator>
</div>
