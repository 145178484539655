import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { License, LicenseStatus, OnboardingTrackingService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-licenses',
    templateUrl: './licenses.component.html'
})
export class MemberLicensesComponent implements OnInit {

    @Input() memberAccountId: string;
    @Input() task: any;
    @Input() readOnly = false;
    @Input() allowAdd = true;
    @Input() changeTrigger: number;
    @Input() isMrb: () => boolean;

    @Output() next: EventEmitter<string> = new EventEmitter<string>();

    ngOnInit() {
        this.onNavigate = this.onNavigate.bind(this);
    }

    onNavigate() {
        this.next.emit();
    }

    setLicenseDetails(licenses: License[]) {
        OnboardingTrackingService.setLicenseInfo(licenses.filter((license) => {
            return license.status === LicenseStatus.ACTIVE;
        }));
    }
}
