import {
    AuthService, IndividualKeyParty, KeyParty, KeyPartyService, KeyPartyStatus, MemberAccountAccess, PagedResponse, Role, UserAccountService, UserAccountStatus, UserMemberAccess, UserStatus
} from 'projects/services/src/public-api';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of as observableOf, tap } from 'rxjs';

/**
 * As owner-list and owner-list-history share a lot of common operations, this abstraction is used to avoid code
 * duplication.
 *
 */
export abstract class AbstractKeyPartyList {

    readonly DEFINED_COLUMNS = ['type', 'name', 'address', 'details', 'relationship', 'date_added', 'date_changed', 'action'];

    keyParties: KeyParty[];

    authService: AuthService;
    keyPartyService: KeyPartyService;
    userAccountService: UserAccountService;

    resultsLength = 0;
    isLoadingResults = true;
    activeAdminUserMap = {};

    protected constructor(authService: AuthService,
        keyPartyService: KeyPartyService,
        userAccountService: UserAccountService) {

        this.authService = authService;
        this.keyPartyService = keyPartyService;
        this.userAccountService = userAccountService;
    }

    /**
     * Fetch the owner's data and all the details after load the initial data.
     *
     * @param memberId
     * @param start
     * @param size
     * @param sort
     * @param sortDir
     * @param hidden - if fetch history data or not
     */
    protected getKeyParties(memberId: string, start: number, size: number, sort: string, sortDir: string, status: KeyPartyStatus) {

        return this.userAccountService.getAllUserAccessByMemberId(memberId).pipe(
            tap(() => {
                return this.isLoadingResults = true;
            }),
            switchMap((response: PagedResponse<UserMemberAccess>) => {
                this.activeAdminUserMap = {};
                response.content.map((userMemberAccess: UserMemberAccess) => {
                    const activeAdminUser = userMemberAccess.accountAccess.find((accountAccess: MemberAccountAccess) => {
                        return accountAccess.status !== UserAccountStatus.DISABLED
                            && (accountAccess.role === Role.CORPORATE_ADMIN || accountAccess.role === Role.ADMIN || accountAccess.role === Role.NEW_ADMIN_REGISTRANT);
                    });
                    this.activeAdminUserMap[userMemberAccess.user.email.toLowerCase()] = activeAdminUser;
                });
                return this.keyPartyService.getKeyPartiesByStatus(memberId, start, size, sort, sortDir, status);
            }),
            map((response: PagedResponse<KeyParty>) => {
                this.resultsLength = response.totalElements || 0;
                const keyParties = response.content || [];
                keyParties.forEach((keyParty) => {
                    keyParty.isDeleteable = !this.activeAdminUserMap[keyParty.individualKeyParty?.email?.toLowerCase()];
                });
                return keyParties;
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        );
    }

    isDetailsVisible(keyParty: KeyParty) {
        return keyParty.individualKeyParty && (keyParty.director || keyParty.officer || keyParty.percentOwnership);
    }

    isDetailSsnVisible(individualKeyParty: IndividualKeyParty) {
        return this.authService.isAuthority() && individualKeyParty.taxId && individualKeyParty.usCitizen;
    }

    isDetailPassportVisible(individualKeyParty: IndividualKeyParty) {
        return this.authService.isAuthority() && individualKeyParty.passportNumber && !individualKeyParty.usCitizen;
    }

    isDetailPassportExpirationVisible(individualKeyParty: IndividualKeyParty) {
        return this.authService.isAuthority() && individualKeyParty.passportExpirationDate && !individualKeyParty.usCitizen;
    }

    isOwnershipVisible(keyParty: KeyParty) {
        return keyParty.director || keyParty.officer || keyParty.percentOwnership;
    }

    pageTitle(isMerchant: boolean) {
        if (isMerchant) {
            return 'Contacts';
        } else {
            return 'Owners, Directors, Officers and Control Persons';
        }
    }
}
