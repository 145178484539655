import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { LoaderComponent } from 'projects/components/src/public-api';
import { AccountToken, AddressType, Configuration, LandingSettings, OnboardingFormService, MemberAccountStatus, PartnerService, Task } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-consumer-onboarding',
    templateUrl: './consumer-onboarding.component.html',
    styleUrls: ['./consumer-onboarding.component.scss'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS,
        useValue: { displayDefaultIndicatorType: false }
    }]
})
export class ConsumerOnboardingComponent implements OnInit {

    AddressType = AddressType;
    ConsumerOnboardFlow = ConsumerOnboardFlow;

    partnerId: string;
    consumerId: string;
    processInstanceId: string;

    landingSettings: LandingSettings;
    imageSrc: any;
    errorMessage: string;
    task: Task;

    termsAccepted = true;
    consumerValid = false;
    isQaEnabled = false;
    useTestBankAccount = false;
    accountToken: AccountToken;

    contactInfoForm: UntypedFormGroup;
    addressForm: UntypedFormGroup;
    accountLinked = false;
    accountLinkResult: string;

    flow: ConsumerOnboardFlow = ConsumerOnboardFlow.BANKING_INFO_REQUIRED;

    stepperOrientation: StepperOrientation = 'horizontal';

    @ViewChild('scroll', { read: ElementRef }) public scroll: ElementRef;
    @ViewChild('stepper') private stepper: MatStepper;

    constructor(private route: ActivatedRoute,
                private partnerService: PartnerService,
                private onboardingFormService: OnboardingFormService,
                private overlay: Overlay,
                private cdr: ChangeDetectorRef,
                private breakpointObserver: BreakpointObserver) {}

    ngOnInit() {
        this.breakpointObserver.observe(['(max-width: 799px)']).subscribe((result: BreakpointState) => {
            this.stepperOrientation = result.matches ? 'vertical' : 'horizontal';
        });

        this.isOnboardingFormValid = this.isOnboardingFormValid.bind(this);
        this.onSave = this.onSave.bind(this);

        this.partnerId = this.route.snapshot.params.partnerId;
        this.consumerId = this.route.snapshot.params.consumerId;
        this.processInstanceId = this.route.snapshot.params.processInstanceId;
        this.isQaEnabled = Configuration.getConfig().qaEnabled;
        this.imageSrc = {
            key: this.partnerId,
            src: this.partnerService.getImage(this.partnerId)
        };
        this.loadPartnerDetails();
    }

    loadPartnerDetails() {
        if (this.partnerId) {
            this.partnerService.getCustomizationPageSettings(this.partnerId).subscribe((landingSettingsResponse: LandingSettings) => {
                this.landingSettings = landingSettingsResponse;
                this.loadOnboardingTask();
            },
            () => {
                this.loadOnboardingTask();
            }
            );
        } else {
            this.partnerService.getDefaultPageSettings().subscribe((landingSettingsResponse: LandingSettings) => {
                this.landingSettings = landingSettingsResponse;
                this.loadOnboardingTask();
            },
            () => {
                this.loadOnboardingTask();
            }
            );
        }
    }

    loadOnboardingTask() {
        this.getConsumerTask(this.partnerId, this.consumerId, this.processInstanceId).subscribe((task: Task) => {
            this.task = task;
            this.accountLinked = (this.task.variables.bankAccountResponse === 'BANK_ACCOUNT_ACTIVE');
            if (!this.task.variables.consumerName) {
                this.contactInfoForm = this.onboardingFormService.initializeContactInfo(this.task.variables);
                if (this.task.variables.dateOfBirth) {
                    this.contactInfoForm.get('birthDateCtrl').disable();
                }
                if (this.task.variables.lastName) {
                    this.contactInfoForm.get('lastNameCtrl').disable();
                }
                if (this.task.variables.mobilePhone) {
                    this.contactInfoForm.get('mobilePhoneCtrl').disable();
                }
                if (this.task.variables.email) {
                    this.contactInfoForm.get('emailCtrl').disable();
                }
                this.addressForm = this.onboardingFormService.initializeAddress(this.task.variables);
                this.flow = ConsumerOnboardFlow.ONBOARD_REQUIRED;
            } else if (!this.accountLinked) {
                this.termsAccepted = false;
                this.consumerValid = true;
                this.flow = ConsumerOnboardFlow.BANKING_INFO_REQUIRED;
            } else {
                this.accountLinked = true;
                this.termsAccepted = true;
                this.consumerValid = true;
            }
        }, () => {
            this.task = null;
            this.termsAccepted = false;
            this.errorMessage = 'Link is not valid.';
        });
    }

    getConsumerTask(partnerId: string, consumerId: string, processInstanceId: string) {
        if (partnerId) {
            return this.partnerService.getConsumerTaskByPartnerId(partnerId, processInstanceId);
        } else if (consumerId) {
            return this.partnerService.getConsumerTaskByConsumerId(consumerId, processInstanceId);
        }

    }

    isOnboardingFormValid() {
        return this.contactInfoForm.valid && this.addressForm.valid;
    }

    onAcceptTerms(useTestAccount: boolean) {
        this.useTestBankAccount = useTestAccount;

        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });

        overlayRef.attach(new ComponentPortal(LoaderComponent));

        this.partnerService.getConsumerAccessToken(this.task.variables.consumerId).subscribe((response: AccountToken) => {
            overlayRef.dispose();
            this.termsAccepted = true;
            this.consumerValid = true;
            this.cdr.detectChanges();
            if (this.flow === ConsumerOnboardFlow.ONBOARD_REQUIRED) {
                this.stepper.selectedIndex = 2;
            } else {
                this.stepper.selectedIndex = 0;
            }
            this.accountToken = response;
        }, (error: any) => {
            overlayRef.dispose();
            throw error;
        });
    }

    onCancel() {
        window.location.href = this.task.variables.failureUrl || '';
    }

    onSuccess() {
        window.location.href = this.task.variables.successUrl || '';
    }

    onSelection(result: string) {
        if (result === 'Cancel') {
            this.onCancel();
        } else if (result === 'Retry') {
            const overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                hasBackdrop: true
            });

            overlayRef.attach(new ComponentPortal(LoaderComponent));

            this.partnerService.getConsumerAccessToken(this.task.variables.consumerId).subscribe((response: AccountToken) => {
                overlayRef.dispose();
                this.consumerValid = true;
                this.accountToken = response;
            }, (error: any) => {
                overlayRef.dispose();
                throw error;
            });
        } else {
            this.accountLinkResult = result;
            this.stepper.selected.completed = true;
            this.stepper.selected.editable = false;
            this.stepper.next();
        }
    }

    onSave() {
        const body = this.onboardingFormService.getOnboardingInformation(this.contactInfoForm, this.addressForm);
        body.partnerId = this.partnerId;

        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        overlayRef.attach(new ComponentPortal(LoaderComponent));

        this.partnerService.completeConsumerRegistrationTask(this.task.id, body).subscribe((result: any) => {
            this.accountLinked = (result.status === MemberAccountStatus.ACTIVE);
            if (this.accountLinked) {
                this.accountLinkResult = 'Linked';
                this.consumerValid = true;
                this.stepper.selected.completed = true;
                this.stepper.selected.editable = false;
                this.stepper.next();
                overlayRef.dispose();
            } else {
                this.getConsumerTask(this.partnerId, this.consumerId, this.processInstanceId).subscribe((task: Task) => {
                    this.task = task;
                    this.termsAccepted = false;
                    overlayRef.dispose();

                }, (error: any) => {
                    if (error.status === 400) {
                        throw new Error('Failure to onboard due to payment history.');
                    } else {
                        throw error;
                    }
                });
            }
        });
    }

    buttonStyle() {
        return {
            'background': this.landingSettings?.buttonsColor,
            'color': this.landingSettings?.buttonsTextColor
        };
    }
}

export enum ConsumerOnboardFlow {
    ONBOARD_REQUIRED = 'ONBOARD_REQUIRED',
    BANKING_INFO_REQUIRED = 'BANKING_INFO_REQUIRED'
}
