<div class="row">
    <div class="col-12 pl-0">
        <div>
            <span class="form-title">Loans</span>
        </div>
        <pc-lending-list *ngIf="!isLender && !hasLoans()"
            [isMemberLendingView]="true"
            [memberAccountId]="memberId">
        </pc-lending-list>
        <pc-lender-lending-list *ngIf="isLender || hasLoans()" [memberId]="memberId">
        </pc-lender-lending-list>
    </div>
</div>
