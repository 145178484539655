import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ContractPartyClassification, SalesOrderLine } from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pc-sc-new-item-modal',
    templateUrl: './new-item-modal.component.html'
})
export class NewItemModalComponent extends BaseModalComponent<NewItemModalComponent> implements OnInit {

    ContractPartyClassification = ContractPartyClassification;

    salesOrderLine: SalesOrderLine;
    newLineForm: UntypedFormGroup;
    buttonText: string;
    frameworkType: string;
    taxTypeOpts = [];

    producerTaxTypeOpts = [{
        id: 'CULTIVATION_FLOWERS',
        name: 'Cultivation: Flowers'
    }, {
        id: 'CULTIVATION_FRESH_PLANT',
        name: 'Cultivation: Fresh Plant'
    }, {
        id: 'CULTIVATION_LEAVES',
        name: 'Cultivation: Leaves'
    }];

    distributorTaxTypeOpts = [{
        id: 'EXCISE',
        name: 'Excise Tax'
    }, {
        id: 'SALES',
        name: 'Sales Tax'
    }];

    unitOfMeasureOpts = [{
        id: 'OZ',
        name: 'Oz'
    }, {
        id: 'LB',
        name: 'Lb'
    }, {
        id: 'KG',
        name: 'Kg'
    }, {
        id: 'ITEM',
        name: 'Item'
    }, {
        id: 'OTHER',
        name: 'Other'
    }];

    constructor(private formbuilder: UntypedFormBuilder,
                protected dialogRef: MatDialogRef<NewItemModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.salesOrderLine = data.orderLine;
        this.buttonText = data.buttonText;
        this.frameworkType = data.frameworkType;
    }

    ngOnInit() {
        this.isNewItemFormValid = this.isNewItemFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.taxTypeOpts = (this.frameworkType === ContractPartyClassification.PRODUCER) ? this.producerTaxTypeOpts : this.distributorTaxTypeOpts;
        this.newLineForm = this.formbuilder.group(
            {
                itemNumberCtrl: new UntypedFormControl(this.salesOrderLine.item, [Validators.required, Validators.minLength(1)]),
                descriptionCtrl: new UntypedFormControl(this.salesOrderLine.description, [Validators.required, Validators.minLength(1)]),
                unitOfMeasureCtrl: new UntypedFormControl(this.salesOrderLine.unitOfMeasure, [Validators.required, Validators.minLength(1)]),
                quantityCtrl: new UntypedFormControl(this.salesOrderLine.quantity, [Validators.required, Validators.minLength(1)]),
                pricePerUnitCtrl: new UntypedFormControl(this.salesOrderLine.pricePerUnit, [Validators.required, Validators.minLength(1)]),
                discountCtrl: new UntypedFormControl(this.salesOrderLine.discount),
                exemptCtrl: new UntypedFormControl(this.salesOrderLine.exempt),
                notForResaleCtrl: new UntypedFormControl(this.salesOrderLine.notForResale),
                taxTypeCtrl: new UntypedFormControl(this.salesOrderLine.taxType, [Validators.required, Validators.minLength(1)])
            });
        if (this.salesOrderLine.exempt) {
            this.newLineForm.get('taxTypeCtrl').setValidators(null);
            this.newLineForm.get('taxTypeCtrl').disable({ onlySelf: true });
            this.newLineForm.get('taxTypeCtrl').updateValueAndValidity();
        } else if (this.salesOrderLine.notForResale) {
            this.newLineForm.get('taxTypeCtrl').disable({ onlySelf: true });
            this.newLineForm.get('taxTypeCtrl').updateValueAndValidity();
        }
    }

    onSubmit() {
        const newLine = new SalesOrderLine();
        newLine.item = this.newLineForm.get('itemNumberCtrl').value;
        newLine.description = this.newLineForm.get('descriptionCtrl').value;
        newLine.unitOfMeasure = this.newLineForm.get('unitOfMeasureCtrl').value;
        newLine.quantity = this.newLineForm.get('quantityCtrl').value;
        newLine.pricePerUnit = this.newLineForm.get('pricePerUnitCtrl').value;
        newLine.discount = this.newLineForm.get('discountCtrl').value;
        newLine.exempt = !!this.newLineForm.get('exemptCtrl').value;
        newLine.taxType = this.newLineForm.get('taxTypeCtrl').value;
        newLine.notForResale = this.newLineForm.get('notForResaleCtrl').value;

        this.close(newLine);
    }

    onExemptChanged() {
        const exempt = this.newLineForm.get('exemptCtrl').value;
        if (exempt) {
            this.newLineForm.get('taxTypeCtrl').setValidators(null);
            this.newLineForm.get('taxTypeCtrl').patchValue(null);
            this.newLineForm.get('taxTypeCtrl').disable({ onlySelf: true });
        } else {
            this.newLineForm.get('taxTypeCtrl').setValidators([Validators.required]);
            this.newLineForm.get('taxTypeCtrl').enable({ onlySelf: true });
        }
        this.newLineForm.get('taxTypeCtrl').updateValueAndValidity();
    }

    onResaleChanged() {
        const notForResale = this.newLineForm.get('notForResaleCtrl').value;
        if (notForResale) {
            this.newLineForm.get('taxTypeCtrl').patchValue('SALES');
            this.newLineForm.get('taxTypeCtrl').disable({ onlySelf: true });
            this.newLineForm.get('exemptCtrl').disable({ onlySelf: true });
        } else {
            this.newLineForm.get('taxTypeCtrl').enable({ onlySelf: true });
            this.newLineForm.get('exemptCtrl').enable({ onlySelf: true });
        }
        this.newLineForm.get('taxTypeCtrl').updateValueAndValidity();
        this.newLineForm.get('exemptCtrl').updateValueAndValidity();
    }

    isNewItemFormValid() {
        return this.newLineForm && this.newLineForm.valid;
    }

    isNotForResale() {
        return !!this.newLineForm.get('notForResaleCtrl').value;
    }

    isTaxExempt() {
        return !!this.newLineForm.get('exemptCtrl').value;
    }

    close(line?: SalesOrderLine) {
        super.close(line);
    }
}
