import { Component, OnInit, Inject } from '@angular/core';
import { MemberAccount } from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pc-member-account-select-modal',
    templateUrl: './member-account-select-modal.component.html',
    styleUrls: ['./member-account-select-modal.component.scss']
})
export class MemberAccountSelectModalComponent extends BaseModalComponent<MemberAccountSelectModalComponent> implements OnInit {

    memberAccounts: MemberAccount[] = [];
    selectedMemberAccount: MemberAccount;

    constructor(dialogRef: MatDialogRef<MemberAccountSelectModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberAccounts = data.memberAccounts;
    }

    ngOnInit() {
        this.onSubmit = this.onSubmit.bind(this);
        this.isAccountSelected = this.isAccountSelected.bind(this);
    }

    close() {
        super.close();
    }

    onSubmit() {
        super.close(this.selectedMemberAccount);
    }

    isAccountSelected() {
        return !!this.selectedMemberAccount;
    }

    memberSelectionChanged(memberAccount: MemberAccount) {
        this.selectedMemberAccount = memberAccount;
    }
}
