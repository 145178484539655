import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BusinessClient, Invoice, MemberAccount, SupportingDocument, TransactionEntrySelection, Utils } from 'projects/services/src/public-api';
import { MatTable } from '@angular/material/table';

@Component({
    selector: 'pt-retail-invoice-selection-step',
    templateUrl: './retail-invoice-selection-step.component.html',
    styleUrls: ['./retail-invoice-selection-step.component.scss']
})
export class RetailInvoiceSelectionStepComponent implements OnInit {

    @Input() memberAccount: MemberAccount;
    @Input() currentBusinessClient: BusinessClient<Invoice>;

    @Output() invoicesSelected: EventEmitter<TransactionEntrySelection<Invoice>[]> = new EventEmitter<TransactionEntrySelection<Invoice>[]>();
    @Output() nextStep: EventEmitter<null> = new EventEmitter<null>();

    customerInvoices: TransactionEntrySelection<Invoice>[] = [];
    documentUploadContext: Invoice;

    otherInvoiceColumns: string[] = ['other_invoice_description', 'other_invoice_amount', 'other_invoice_include', 'other_invoice_document'];

    @ViewChild('customerInvoicesTable') table: MatTable<TransactionEntrySelection<Invoice>>;
    @ViewChild('upload') uploadLink: ElementRef;

    constructor() {}

    ngOnInit() {
        this.next = this.next.bind(this);
        this.invoicesValid = this.invoicesValid.bind(this);
        this.initializeInvoices();
    }

    initializeInvoices() {
        this.customerInvoices = [];
        this.addOtherInvoice();
        this.addOtherInvoice();
        this.addOtherInvoice();
    }

    calculateTotal() {
        const transactionEntries: TransactionEntrySelection<Invoice>[] = [];
        let runningCustomerTotal = '0.00';
        this.currentBusinessClient.transactionEntries = [];
        for (const invoice of this.customerInvoices) {
            if (invoice.included && invoice.data.paidAmount) {
                transactionEntries.push(invoice);
                runningCustomerTotal = (Number(runningCustomerTotal) + Number(invoice.data.paidAmount)).toFixed(2);
            }
        }
        this.currentBusinessClient.selectedTotal = runningCustomerTotal;
        this.invoicesSelected.emit(transactionEntries);
    }

    addOtherInvoice() {
        const otherAmount = new TransactionEntrySelection<Invoice>();
        otherAmount.data = new Invoice();
        otherAmount.data.type = 'OTHER';
        otherAmount.data.txnDate = new Date();
        otherAmount.data.customerExternalId = this.currentBusinessClient.accountingId || this.currentBusinessClient.memberAccountId;
        otherAmount.data.customerName =  this.currentBusinessClient.name;
        otherAmount.data.supportingDocuments = [];
        this.customerInvoices.push(otherAmount);
    }

    addNewInvoiceRow() {
        this.addOtherInvoice();
        this.table.renderRows();
        this.calculateTotal();
    }

    onInvoiceChange(invoice: TransactionEntrySelection<Invoice>) {
        invoice.validate();
        if (invoice.data.type === 'OTHER') {
            invoice.data.paidAmount = Utils.formatNonNegativeCurrency(invoice.data.paidAmount, true);
            invoice.included = (Number(invoice.data.paidAmount) > 0);
        }
        this.calculateTotal();
    }

    next() {
        this.nextStep.emit();
    }

    removeOtherAmount(index: number) {
        this.customerInvoices.splice(index, 1);
        this.calculateTotal();
        this.table.renderRows();
    }

    addSupportingDocument(invoice: Invoice) {
        this.calculateTotal();
        this.documentUploadContext = invoice;
        const link: HTMLElement = this.uploadLink.nativeElement;
        link.click();
    }

    removeSupportingDocument(invoice: Invoice, index: number) {
        this.calculateTotal();
        invoice.supportingDocuments.splice(index, 1);
    }

    selectFile(event: any) {
        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            Utils.validateFile(file, event);
            const supportingDocument = new SupportingDocument();
            supportingDocument.file = file;
            this.documentUploadContext.supportingDocuments.push(supportingDocument);
        }
        event.target.value = '';
        this.documentUploadContext = null;
    }

    invoicesValid() {
        let invoicesIncluded = false;
        for (const invoice of this.customerInvoices) {
            if (invoice.data.type === 'OTHER' && Number(invoice.data.paidAmount) > 0 && invoice.included && (!invoice.data.description || !invoice.data.supportingDocuments.length)) {
                return false;
            } else if (invoice.included) {
                invoicesIncluded = true;
            }
        }
        return invoicesIncluded;
    }

}
