import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http.service';
import { MemberType } from './models/member';
import { MemberAccountType } from './models/member-account';

@Injectable({
    providedIn: 'root'
})
export class SearchService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.EntityAPIEndpoint + '/';
    }

    searchMembersBySearchTerm(searchTerm:string, memberTypes: MemberType[], start: number, size: number, sort: string, sortDir: string) {
        const params = `?searchTerm=${encodeURIComponent(searchTerm)}&types=${memberTypes}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}entitySearch/member${params}`);
    }

    searchMemberAccountsBySearchTerm(searchTerm:string, memberAccountTypes: MemberAccountType[], start: number, size: number, sort: string, sortDir: string) {
        const params = `?searchTerm=${encodeURIComponent(searchTerm)}&types=${memberAccountTypes}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}entitySearch/member_account${params}`);
    }

    searchUserAccountsBySearchTerm(searchTerm: string, start: number, size: number, sort: string, sortDir: string) {
        const params = `?searchTerm=${encodeURIComponent(searchTerm)}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}entitySearch/user_account${params}`);
    }

    searchKeyPartiesBySearchTerm(searchTerm:string, start: number, size: number, sort: string, sortDir: string) {
        const params = `?searchTerm=${encodeURIComponent(searchTerm)}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}entitySearch/key_party${params}`);
    }

}
