import { Component, Input, OnInit } from '@angular/core';
import { Package, PagedResponse, RecordsService, Shipment } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-member-metrc-transfers-row',
    templateUrl: './member-metrc-transfers-row.component.html',
    styleUrls: ['./member-metrc-transfers-row.component.scss']
})
export class MemberMetrcTransfersRowComponent implements OnInit {

    @Input() shipment : Shipment;

    packages: Package[] = [];

    constructor(private recordsService : RecordsService) {
    }

    ngOnInit() {
        this.recordsService.getPackagesByOwnerAndManifestNumber(this.shipment.memberId, this.shipment.manifestNumber).subscribe(
            (response: PagedResponse<Package>) => {
                this.packages = response.content;
            }
        );
    }
}
