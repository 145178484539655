import {Address} from './address';

export class User {
    id: string;
    created: Date;
    updated: Date;

    professionalTitle: string;
    employer: string;
    firstName: string;
    middleName: string;
    lastName: string;
    otherSurnameOrAlias: string;
    sex: string;
    usCitizen: boolean;
    nationality: string;
    name: string;

    passportNumber: string;
    email: string;
    homePhone: string;
    mobilePhone: string;
    officePhone: string;
    birthDate: Date;
    passportExpirationDate: Date;
    status: UserStatus;

    taxId: string;

    accountRole: string;
    address: Address;
}

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    PENDING_REGISTRATION = 'PENDING_REGISTRATION',
    LOCKED = 'LOCKED'
}


export enum UserUpdatableField {
    EMAIL = 'EMAIL',
    PHONE = 'PHONE'
}
