<div class="row">
    <div class="col-lg-6 expandedValue">
        <span class="expandedLabel d-block">{{trackingInfoConsolidationDays}} Days Payment Total</span>
        <span [ngClass]="isAccountActivityAmountExceedingEstimatedCost() ? 'priority' : ''">{{transactionTrackingSupportEvidence.accountActivityAmount | currency}}</span>
    </div>
    <div class="col-lg-6 expandedValue">
        <span class="expandedLabel d-block">Estimated Cost Threshold</span>
        <span>{{transactionTrackingSupportEvidence.totalEstimatedCost | currency}}</span>
    </div>
</div>
<div class="col-sm-12 horizontal-scroll mat-table-container mt-3">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="supportEvidenceItems" matSort [matSortActive]="pageTracking.sort" matSortDisableClear
        [matSortDirection]="pageTracking.sortDir" multiTemplateDataRows>
        <ng-container matColumnDef="manifest_counter_party_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="manifestCounterPartyName">Counter Party Name</th>
            <td mat-cell *matCellDef="let row"> {{row.manifestCounterPartyName}} </td>
        </ng-container>
        <ng-container matColumnDef="product_category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="productCategory">Product Category</th>
            <td mat-cell *matCellDef="let row"> {{row.productCategory}} </td>
        </ng-container>
        <ng-container matColumnDef="units_of_measurement">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="unitsOfMeasurement">Units</th>
            <td mat-cell *matCellDef="let row"> {{row.unitsOfMeasurement}} </td>
        </ng-container>
        <ng-container matColumnDef="quantity">
            <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header="quantity">Quantity</th>
            <td mat-cell class="centered" *matCellDef="let row"> {{row.quantity}} </td>
        </ng-container>
        <ng-container matColumnDef="estimated_cost">
            <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header="estimatedCost">Estimated Cost</th>
            <td mat-cell class="centered" *matCellDef="let row">
                <span class="table-amount">{{row.estimatedCost | currency}}</span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions"
        [length]="resultsLength" showFirstLastButtons aria-label="Select page"></mat-paginator>
</div>