export class LendingDetails {

    label: string;
    numberOfMembers: number;
    dollarsValuePotential: string;
    constructor(label: string, numberOfMembers: number, dollarsValuePotential: string) {
        this.label = label;
        this.numberOfMembers = numberOfMembers;
        this.dollarsValuePotential = dollarsValuePotential;
    }

}
