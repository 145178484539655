<fieldset class="form-group" *ngIf="formCtrl">
    <div>
        <div class="row" *ngIf="label">
            <label class="form-label" [ngClass]="getClass()">{{ label | translate }}</label>
        </div>
        <div class="row">
            <div class="inputGroup" [formGroup]="formGroup">
                <select [attr.id]="id ? id : null" class="form-control form-input-editable" [formControlName]="formCtrl" [required]="required" type="text"
                    (change)="onSelected()" [ngClass]="getClass()">
                    <option *ngIf="safariFix" value="" disabled selected></option>
                    <option *ngFor="let option of options" [selected]="isDefault(option)" [value]="option">{{getOptionDisplayValue(option)}}</option>
                </select>
            </div>
        </div>
    </div>
</fieldset>
