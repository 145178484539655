import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormBuilder } from '@angular/forms';

@Component({
    selector: 'pc-sc-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

    @Input() metadata: any;

    @Output() changed: EventEmitter<any> = new EventEmitter<any>();

    fieldControl = new UntypedFormControl('', null);
    formGroup = this.formbuilder.group([]);

    constructor(private formbuilder: UntypedFormBuilder) { }

    ngOnInit() {
        this.fieldControl.setValue(this.metadata.included);
        this.formGroup.addControl(this.metadata.index, this.fieldControl);
    }

    fieldChanged() {
        if (!this.metadata.required) {
            this.metadata.included = !this.metadata.included;
            this.changed.emit(this.metadata);
        }
    }
}
