import { Injectable, Inject } from '@angular/core';
import { Config } from '../models/configuration';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class Configuration {

    private static config: Config;

    baseUrl: string;

    constructor(private http: HttpClient,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.SecurityAPIEndpoint + '/configuration';
    }

    static getConfig() {
        return this.config;
    }

    static setConfig(config: Config) {
        this.config = config;
    }

    static getMaxUploadSizeInMb() {
        // eslint-disable-next-line no-bitwise
        return (this.config.maxUploadSize / 1048576 >> 0) + 'MB';
    }

    load() {
        return new Promise((resolve) => {
            this.http.get(this.baseUrl)
                .subscribe((config: Config) => {
                    Configuration.setConfig(config);
                    resolve(true);
                });
        });
    }
}
