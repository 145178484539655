import { Component, Input, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { AddressType, AddressFormService, AddressService, MemberAccount, MemberAccountService, PagedResponse } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-location-modal',
    templateUrl: './location-modal.component.html'
})
export class LocationModalComponent extends BaseModalComponent<LocationModalComponent> implements OnInit {

    @Input() memberId: string;

    newLocationForm: UntypedFormGroup;
    addressTypeOpts: AddressType[] = [AddressType.OPERATIONS, AddressType.RETAIL, AddressType.OTHER];
    memberAccountOptions: MemberAccount[] = [];

    constructor(private addressFormService: AddressFormService,
                private addressService: AddressService,
                private memberAccountService: MemberAccountService,
                dialogRef: MatDialogRef<LocationModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberId = data.memberId;
    }

    ngOnInit() {
        this.isLocationFormValid = this.isLocationFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.memberAccountService.getActiveMemberBusinessAccounts(this.memberId, false).subscribe((response: PagedResponse<MemberAccount>) => {
            this.memberAccountOptions = response.content;
            if (!this.memberAccountOptions.length) {
                this.addressTypeOpts.splice(this.addressTypeOpts.indexOf(AddressType.RETAIL), 1);
            }
            this.newLocationForm = this.addressFormService.initializeForm(null);
        });
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    onSubmit() {
        const location = this.addressFormService.getAddress(this.newLocationForm);
        const nickname = this.newLocationForm.get('nicknameCtrl').value;
        const accountId = this.newLocationForm.get('accountIdCtrl').value;
        this.addressService.linkAddressToOccupant(location, this.memberId, this.memberId, nickname, accountId).subscribe(() => {
            this.close(true);
        });
    }

    isLocationFormValid() {
        return !this.newLocationForm.invalid;
    }

}
