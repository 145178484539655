import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { MemberAccountRoutingModule } from './member-account-routing.module';
import { SharedComponentsModule } from 'projects/components/src/lib/shared-components.module';

import { HomeModule } from '../home/home.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { AdministrationModule } from '../administration/administration.module';
import { MemberAccountsComponent } from './member-accounts/member-accounts.component';

@NgModule({
    imports: [
        CommonModule,
        MemberAccountRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        SharedComponentsModule,
        TranslateModule,
        HomeModule,
        NgbModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatButtonToggleModule,
        MatIconModule,
        MatTooltipModule,
        MatCardModule,
        AdministrationModule
    ],

    declarations: [
        MemberAccountsComponent
    ],

    exports: [
    ],

    providers: [
    ]
})
export class MemberAccountModule {
}
