<div *ngIf="task" class="row wizard">
    <div class="pull-right navigation">
        <button class="blue-button pull-right" (click)="shareTemplate(false)" type="button" [disabled]="!invitees.length"><i class="fa fa-share-alt pull-left dashboard-icon"></i><div class="pull-right-button">Share With Selected</div></button>
        <button class="blue-button pull-right" (click)="shareTemplate(true)" type="button"><i class="fa fa-share-alt pull-left dashboard-icon"></i><div class="pull-right-button">Share Publicly</div></button>
    </div>
    <div class="pull-left">
        <h3>Share Template</h3>
    </div>
    <div class="col-12 clear">
            <pc-sc-business-parties-list (membersInvited)="invitees = $event">
            </pc-sc-business-parties-list>
    </div>
</div>
