import { ContractSection } from './contract-section';
import { SalesOrder } from './sales-order';
import { ContractFramework } from './contract-framework';
import { ContractTemplateAccess } from './contract-template-access';

export class ContractTemplate {
    id: string;
    created: Date;
    name: string;
    description: string;
    contractFramework: ContractFramework;
    sections: ContractSection[];
    purchaseOrder: SalesOrder;
    valid: boolean;
    templateType: ContractTemplateType;
    visibility: ContractTemplateVisibility;
    templateAccess: ContractTemplateAccess[];
    creator: string;
    creatorName: string;
}

export enum ContractTemplateVisibility {
    DRAFT = 'DRAFT',
    SHARING = 'SHARING',
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
    PUBLIC = 'PUBLIC',
    LIMITED = 'LIMITED'
}

export enum ContractTemplateType {
    BUYER = 'BUYER',
    VENDOR = 'VENDOR'
}
