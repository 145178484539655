import { v4 as uuidv4 } from 'uuid';
import { Invoice } from './invoice';
import { Bill } from './bill';
import { Utils } from '../util.service';

export class TransactionEntrySelection<T extends Invoice | Bill> {
    data: T;

    id: string;
    included = false;
    error: string;

    constructor() {
        this.id = uuidv4();
    }

    checkboxDisabled() {
        return this.error || this.noPaidAmount();
    }

    inputDisabled() {
        return this.included;
    }

    noPaidAmount() {
        return Number(this.data.paidAmount) === 0 || this.data.paidAmount === null || this.data.paidAmount === '';
    }

    validate() {
        if (this.noPaidAmount()) {
            this.error = null;
            this.data.paidAmount = null;
        } else if (Utils.isNaN(this.data.paidAmount)) {
            this.error = 'Enter Valid Amount';
        } else if (Number(this.data.paidAmount) < 0) {
            this.error = 'Enter Valid Amount';
        } else if (Number(this.data.paidAmount) > Number(this.data.unpaidAmount)) {
            this.error = 'Exceeds Amount Owed';
        } else {
            this.error = null;
            this.data.paidAmount = Utils.formatNonNegativeCurrency(this.data.paidAmount, true);
        }
    }
}
