<div class="col-sm-11 mx-auto mb-3 horizontal-scroll mat-table-container">
    <div class="row mt-1 col-12">
        <span class="fs-6 title-bold-font">Product Package Transfer Details: </span>
    </div>
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <mat-paginator #paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    <table mat-table [dataSource]="productPackageTransferDetails" multiTemplateDataRows>
        <ng-container matColumnDef="transferId">
            <th mat-header-cell *matHeaderCellDef> Transfer ID </th>
            <td mat-cell *matCellDef="let element"> {{ element.transferId }} </td>
        </ng-container>
        <ng-container matColumnDef="quantityIn">
            <th mat-header-cell *matHeaderCellDef> Quantity In </th>
            <td mat-cell *matCellDef="let element"> {{element.quantityIn}} </td>
        </ng-container>
        <ng-container matColumnDef="quantityOut">
            <th mat-header-cell *matHeaderCellDef> Quantity Out </th>
            <td mat-cell *matCellDef="let element"> {{element.quantityOut}} </td>
        </ng-container>
        <ng-container matColumnDef="quantityUnits">
            <th mat-header-cell *matHeaderCellDef> Units </th>
            <td mat-cell *matCellDef="let element"> {{ element.quantityUnits }} </td>
        </ng-container>
        <ng-container matColumnDef="transferDate">
            <th mat-header-cell *matHeaderCellDef> Transfer Date </th>
            <td mat-cell *matCellDef="let element"> {{element.transferDate ? (element.transferDate | date:'mediumDate') : ""}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>