import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-postal-code-input',
    templateUrl: './postal-code-input.component.html'
})
export class PostalCodeInputComponent implements OnInit, OnChanges {

    @Input() required: boolean;
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() country: string;

    @Output() postalCodeChanged: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit() {
        this.updateValidators();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.country) {
            this.updateValidators();
        }
    }

    updateValidators() {
        const control = this.formGroup.controls[this.formCtrl];
        if (this.country === 'Canada') {
            control.setValidators([Validators.required, CustomValidators.postalCodeCA]);
        } else {
            control.setValidators([Validators.required]);
        }
        control.updateValueAndValidity();
    }

    onInputChanged() {
        this.postalCodeChanged.emit(this.formGroup.controls[this.formCtrl].value);
    }
}
