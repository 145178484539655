<div class="col-sm-11 mx-auto mb-3 horizontal-scroll mat-table-container">
    <div class="row mt-1 col-12">
        <span class="fs-6 title-bold-font">Product Package Details: </span>
    </div>
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <mat-paginator #paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    <table mat-table [dataSource]="productPackageDetails" multiTemplateDataRows>
        <ng-container matColumnDef="packageId">
            <th mat-header-cell *matHeaderCellDef> Package ID </th>
            <td mat-cell *matCellDef="let element"> <i class="fa" [class.fa-plus-circle]="expandedElement !== element"
                    [class.fa-minus-circle]="expandedElement === element"
                    (click)="expandedElement = (expandedElement === element) ? null : element"></i>{{ element.packageId }} </td>
        </ng-container>
        <ng-container matColumnDef="packageLabel">
            <th mat-header-cell *matHeaderCellDef> Package Label </th>
            <td mat-cell *matCellDef="let element"> {{ element.packageLabel }} </td>
        </ng-container>
        <ng-container matColumnDef="productName">
            <th mat-header-cell *matHeaderCellDef> Product Name </th>
            <td mat-cell *matCellDef="let element"> {{ element.productName }} </td>
        </ng-container>
        <ng-container matColumnDef="quantityUnits">
            <th mat-header-cell *matHeaderCellDef> Unit Type </th>
            <td mat-cell *matCellDef="let element"> {{ element.quantityUnits }} </td>
        </ng-container>
        <ng-container matColumnDef="quantityIn">
            <th mat-header-cell *matHeaderCellDef> Quantity In </th>
            <td mat-cell *matCellDef="let element"> {{element.quantityIn}} </td>
        </ng-container>
        <ng-container matColumnDef="quantityOut">
            <th mat-header-cell *matHeaderCellDef> Quantity Out </th>
            <td mat-cell *matCellDef="let element"> {{element.quantityOut}} </td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                    <pc-member-metrc-trace-transfer-row-list *ngIf="element === expandedElement" [memberId]="memberId" [startDate]="startDate" [endDate]="endDate" [productPackageDetails]="element"></pc-member-metrc-trace-transfer-row-list>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
</div>