<p>Confia provides fast, secure payments through your bank account. To continue, please accept the terms of service:</p>
<h2><b>Confia Consumer User Agreement</b></h2>
<h4><b>Terms of Service</b></h4>
<p class="date">Effective 04/2024</p>
<p>This User Agreement ("Agreement") is a contract between Confia Financial LLC ("Confia," "we," "us," "our," or "Company") and the individual
    or person(s) intending to open and maintain a Confia account ("you" or "your"). This Agreement and the terms and conditions contained herein
    govern your use of your Confia account with us. Please review the following terms carefully:</p>
<p>
    <b>
        CONFIA PROVIDES THE SERVICES SET FORTH HEREIN SOLELY ON THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT AND ON THE CONDITION THAT YOU,
        AS AN ACCOUNTHOLDER, ACCEPT AND COMPLY WITH THEM. BY CLICKING THE ACCEPTANCE BUTTON OR ACCESSING, USING, OR INSTALLING ANY PART OF THE
        SERVICES PROVIDED FOR HEREIN, YOU: (A) EXPRESSLY AGREE TO AND CONSENT TO BE BOUND BY ALL OF THE TERMS OF THIS AGREEMENT; AND (B) REPRESENT
        AND WARRANT THAT (I) YOU ARE 18 YEARS OF AGE OR OLDER; AND (II) HAVE THE RIGHT, POWER, AND AUTHORITY TO ENTER INTO THIS AGREEMENT AND BE
        BOUND TO ITS TERMS. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, THE BUTTON INDICATING NON-ACCEPTANCE MUST BE SELECTED, WE
        WILL PROMPTLY CANCEL THIS TRANSACTION AND YOU MAY NOT ACCESS, USE, OR INSTALL ANY PART OF OUR SERVICES.
    </b>
</p>
<p><b>General</b></p>
<p>
    You must be in the United States and have a U.S. bank account to use our services.  This Agreement is periodically revised and always
    available electronically for your reference through your account portal at account.confia.io.  You also agree to comply with the other
    agreements posted on legal page that apply to you, including, but not limited to our Privacy Policy at
    <a href="https://www.confia.fi/online-privacy-policy/" target="_blank">www.confia.fi/online-privacy-policy/</a> and Platform Use & License
    Agreement at <a href="https://www.confia.fi/platform-use/" target="_blank">www.confia.fi/platform-use/</a>.
</p>
<p>
    We may revise this Agreement and any of the agreements listed above from time to time. The revised version will be effective at the time we
    post it, unless otherwise noted. We reserve the right to amend this Agreement at any time without notice, subject to applicable law.
    By continuing to use our services after any changes to this Agreement become effective, you agree to abide and be bound by those changes.
    If you do not agree with any changes to this Agreement, you may close your account.
</p>
<p>
    Confia Financial is a financial technology company and is not a bank. We have selected a regulated and nationally insured sponsor bank who
    will facilitate payments.
</p>
<p>
    <b>
        Your Confia Account
    </b>
</p>
<p>
    <i>Your Confia Account:</i> Your Confia account is to be used as a wallet, personally.  To create a personal account, you must be a resident
    of the United States or one of its territories, be at least 18 years old or the age of majority in your state of residence, and use a
    cellular/wireless telephone number that you own.  Your account permits you to buy things directly through an authorized merchant of the Confia
    network only.  Keep confidential any credentials you use to access your account and the services. You must keep your phone number, email
    address and other contact information current in your account profile. As this is not a depository account, you will be restricted in the
    balance you are eligible to maintain in your Confia wallet.
</p>
<p>
    You may close your account and terminate your relationship with us without cost, but you will remain liable for all obligations related to
    your account even after your account is closed. Any incomplete transactions or transfers must be completed or canceled.  In certain cases,
    you may not close your account, including but not limited to:
</p>
<ol>
    <li>An attempt to evade an investigation.</li>
    <li>If you have a pending transaction or an open dispute or claim.</li>
    <li>If you owe amounts to us.</li>
    <li>If your account is subject to a hold, limitation or reserve.</li>
</ol>
<p>
    <i>Link a Payment Method:</i> You must have linked payment method in good standing stored in your account at any time that you initiate a
    purchase with an authorized merchant. You can access your account activity by logging into your Confia account. If available, you may use
    your linked payment method to transact directly with an authorized merchant. For transactions you make we will charge the entire cost of
    the payment to your preferred payment method, and additional fees (e.g., a convenience fee) may apply.
</p>
<p>
    When you use your bank account as a payment method, you are allowing us to initiate a transfer from your bank account. For these transactions,
    we will make electronic transfers from your bank account in the amount you authorize. You authorize us to try this transfer again if the initial
    transfer is rejected by your bank for any reason.
</p>
<p>
    <i>Payments from Your Account:</i> To use money from your linked payment method to make purchases with authorized merchants, we must verify the
    required identifying information you provide to us. The required identifying information is: name, physical address, phone number, email, and
    date of birth; and if required by us in certain circumstances, you agree to provide your social security or taxpayer identification number to
    further verify your identity.
</p>
<p>
    You authorize us, directly or through third parties, to make any inquiries we consider necessary to verify your identity. This may include:
    (i) asking you for further information, such as your date of birth, a social security or taxpayer identification number, your physical address
    and other information that will allow us to reasonably identify you; (ii) requiring you to take steps to confirm ownership of your email address,
    phone number or financial instruments; (iii) verifying your information against third party databases or through other sources; or (iv) requiring
    you to provide your driver’s license or other identifying documents.
</p>
<p>
    <i>Transactions with Authorized Merchants:</i> An "authorized merchant" means a merchant that has been authorized by us to accept payments via Confia
    as a payment method.  In certain circumstances, when you authorize a payment to an authorized merchant, delays may occur which will result in your
    payment appearing as a pending transaction in your account.
</p>
<p>
    A CONVENIENCE FEE MAY BE CHARGED BY US FOR PAYMENT TRANSACTIONS, AT THE DISCRETION OF THE MERCHANT, RETAILER, OR PAYMENT SERVICE PARTNER. A PORTION
    OF THE CONVENIENCE FEE IS REMITTED TO CONFIA AND MAY BE REMITTED BY CONFIA TO AN AUTHORIZED AGENT PURSUANT TO A SEPARATE ARRANGEMENT WITH YOUR AUTHORIZED
    AGENT, FOR THE COSTS ASSOCIATED WITH THE ACCEPTANCE OF PAYMENTS VIA THE PAYMENT SERVICE. HOWEVER, YOU HAVE THE RIGHT TO TERMINATE OR CANCEL THE PAYMENT
    TRANSACTION IF YOU DO NOT WISH TO PAY THE CONVENIENCE FEE BEFORE THE TRANSACTION IS PROCESSED.

</p>
<p>
    <i>Transaction Reviews:</i> Transaction monitoring patterns and anomaly detection services will be used to prevent suspicious or illegal activity. Activity
    reviews may result in: (i) delayed, blocked or cancelled transfers; (ii) money being held applied to amounts owed to us; (iii) or any other similar and
    appropriate action.  We reserve the right at our discretion to close your account for any reason if we deem your use of your account or our services in a
    manner that does not comply with this Agreement or any other agreement between you and us.
</p>
<p>
    You are responsible for all reversals, chargebacks, claims, fees, fines, penalties, and other liability incurred by us, any other customer, or a third party
    caused by or arising out of your breach of this Agreement, and/or your use of our services. For example, if you send a payment funded by your bank account
    and your bank informs us you do not have sufficient funds to cover the payment, you may be liable for the payment.
</p>
<p>
    <i>Legal Process:</i> If we are notified of a court order or other legal process (including garnishment or any equivalent process) affecting you, or if we
    otherwise believe we are required to do so in order to comply with applicable law or regulatory requirements, we may be required to take certain actions,
    including holding payments to/from your account, placing a hold or limitation on your account, or releasing your funds. We will decide, in our sole
    discretion, which action is required of us. We do not have an obligation to contest or appeal any court order or legal process involving you or your
    account. When we implement a hold or limitation as a result of a court order, applicable law, regulatory requirement or other legal process, the hold or
    limitation may remain in place longer than 180 days.
</p>
<p>
    <i>Unauthorized Transactions:</i> An "unauthorized transaction" occurs when money is sent from your account that you did not authorize and that did not
    benefit you. For example, if someone steals your password, uses the password to access your account, and sends a payment from your account, an unauthorized
    transaction has occurred.  Unauthorized transactions do not occur if you give someone access to your account.   Each transaction will require an account
    log in with a partner or merchant, and or SMS verification from Confia, confirming the release of funds.
</p>
<p>
    If you believe your login information has been lost or stolen, or that an electronic funds transfer has been made without your permission using your login
    or by other means, please first contact the e-commerce platform; if you do not have an account or if you do not have success in rectifying the issue, please
    contact us immediately.   If you tell us within 60 days after we provide you confirmation of transfers you did not make, you will be eligible for 100%
    protection for such unauthorized transactions.  If you do not tell us within 60 days after we provided a transaction confirmation or account statement to
    you, you may not get back any money you lost after the 60 days if we can prove that we could have stopped someone from taking the money if you had told us
    in time. If a good reason (such as a long trip or a hospital stay) kept you from telling us, we may extend the time periods.
</p>
<p>
    <i>Error Resolution:</i> An "error" occurs when the following has occurred: (i) when money is either incorrectly taken from your account or when a
    transaction is incorrectly recorded in your account, or (ii) you make a payment, and an incorrect amount is debited from your account payment method.
    If you believe that there is an error related to your account please contact us immediately. We must hear from you no later than 60 days from after we
    provide you a transaction confirmation or an account statement that you believe contains an error.
</p>
<p>
    We will attempt to determine whether an error occurred within 10 business days after you notify us that you believe an error has occurred. We may however
    take up to 45 business days to investigation your complaint or question.  If we determine that an error has occurred, we will promptly credit the amount
    that is considered to be an error back to the payment method linked to your account. If we determine that there was no error, we will provide you with
    a written response of our findings.
</p>
<p>
    <i>Communications between you and us:</i> If you provide us your mobile phone number, you agree that we may contact you at that number using autodialed
    or prerecorded message calls or text messages to: (i) service your accounts, (ii) investigate or prevent fraud, or (iii) collect amounts owed to us. We
    will not use autodialed or prerecorded message calls or texts to contact you for marketing purposes unless we receive your prior express written consent.
    We may share your mobile phone number with service providers with whom we contract to assist us with providing you our services, but we will not share
    your mobile phone number with third parties for their own purposes without your consent.
</p>
<p>
    <i>E-Sign Disclosure and Consent:</i> You agree that you have had an opportunity to review, and that you understand our E-Sign Disclosure and Consent
    form at <a href="https://www.confia.fi/esign-consent/" target="_blank">www.confia.fi/esign-consent/</a>. Unless such consent is withdrawn by you, you
    affirmatively consent to receiving electronic records and communications in accordance with the form.
</p>
<p>
    <b>Miscellaneous</b>
</p>
<p>
    <i>Indemnification; Limitation of Liability:</i> You agree to defend, indemnify and hold us harmless from any claim or demand (including reasonable legal
    fees) made or incurred by any third party due to or arising out of your breach of this user agreement, any other agreement between you and us, your
    improper use of the services, your violation of any law or the rights of a third party and/or the actions or inactions of any third party to whom you
    grant permissions to use your account or access our websites, software, systems (including any networks and servers used to provide any of the services)
    operated by us or on our behalf, or any of the services on your behalf.
</p>
<p>
    Our liability to you or any third parties in any circumstance is limited to the actual amount of direct damages. In addition, to the extent permitted
    by applicable law, we are not liable, and you agree not to hold us responsible, for any damages or losses (including, but not limited to, loss of money,
    goodwill, or reputation, profits, or other intangible losses or any special, indirect, or consequential damages) resulting directly or indirectly from:
    (1) your use of, or your inability to use, our websites, software, systems (including any networks and servers used to provide any of the services)
    operated by us or on our behalf, or any of the services; (2) delays or disruptions in our websites, software, systems (including any networks and servers
    used to provide any of the services) operated by us or on our behalf and any of the services; (3) viruses or other malicious software obtained by accessing
    our websites, software, systems (including any networks and servers used to provide any of the services) operated by us or on our behalf or any of the
    services or any website or service linked to our websites, software or any of the services; (4) glitches, bugs, errors, or inaccuracies of any kind in
    our websites, software, systems (including any networks and servers used to provide any of the services) operated by us or on our behalf or any of the
    services or in the information and graphics obtained from them; (5) the content, actions, or inactions of third parties; (6) a suspension or other action
    taken with respect to your account; or (7) your need to modify your practices, content, or behavior, or your loss of or inability to do business, as a
    result of changes to this user agreement or our policies.
</p>
<p>
    <i>Disclaimer of Warranty and Release:</i> Our services are provided "as-is" and without any representation or warranty, whether express, implied or
    statutory. We specifically disclaim any implied warranties of title, merchantability, fitness for a particular purpose and non-infringement.  We do
    not have any control over the products or services provided by merchants who accept Confia as a payment method. We do not guarantee continuous,
    uninterrupted or secure access to any part of the services, and operation of our websites, software, or systems (including any networks and servers
    used to provide any of the services) operated by us or on our behalf may be interfered with by numerous factors outside of our control. We will make
    reasonable efforts to ensure that requests for electronic debits and credits involving bank accounts, debit cards, credit cards, and check issuances
    are processed in a timely manner but we make no representations or warranties regarding the amount of time needed to complete processing because the
    services are dependent upon many factors outside of our control, such as delays in the banking system or the U.S. or international mail service.
</p>
<p>
    If you have a dispute with an authorized merchant, you release us from any and all claims, demands and damages (actual and consequential) of every
    kind and nature, known and unknown, arising out of or in any way connected with such disputes. In entering into this release you expressly waive any
    protections (whether statutory or otherwise, for example, California Civil Code § 1542) that would otherwise limit the coverage of this release to
    include only those claims which you may know or suspect to exist in your favor at the time of agreeing to this release.
</p>
<p>
    <i>Agreement to Arbitrate:</i> Any claims arising out of, relating to, or connected with this Agreement must be asserted individually in binding
    arbitration conducted by a single arbitrator with experience in consumer online payment services disputes administered by the American Arbitration
    Association ("AAA") in accordance with its commercial arbitration rules and the AAA supplementary procedures for consumer-related disputes. The
    forum for arbitration shall be in the city closest to your residence having a federal district courthouse. The arbitrator shall not conduct any form
    of class or collective arbitration nor join or consolidate claims by or for individuals. To the extent allowed by applicable law, the Arbitrator,
    and not any federal, state, or local court or agency, shall have exclusive authority to resolve any dispute relating to the interpretation,
    applicability, enforceability, or formation of this user agreement including, but not limited to, any claim that all or any part of this user
    agreement is void or voidable. Judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. For any
    non-frivolous claim, we will pay the costs of the arbitration (but not your attorney fees), up to $3,000.
</p>
<p>
    This user agreement and each of its parts evidence a transaction involving interstate commerce, and the United States Arbitration Act shall apply
    in all cases and govern the interpretation and enforcement of the arbitration rules and arbitration proceedings. There are only two exceptions to
    this agreement to arbitrate. First, if we reasonably believe that you have in any manner violated or threatened to violate our intellectual property
    rights, we may seek injunctive or other appropriate relief in any court of competent jurisdiction. Second, any claim of $1,000 or less may, at the
    option of the claiming party, be resolved in small claims court in Orange County, California, if the claim and the parties are within the jurisdiction
    of the small claims court. For these two exceptions, you agree to submit to the personal jurisdiction of the courts located within Orange County,
    California for the purpose of litigating such claims or disputes.
</p>
<p>
    To the extent allowed by law, you agree to irrevocably waive any right you may have to a trial by jury or other court trial (other than small claims
    court) or to serve as a representative, as a private attorney general, or in any other representative capacity, or to participate as a member of a
    class of claimants, in any lawsuit, arbitration, or other proceeding filed against us and/or related third parties.
</p>
<p>
    <i>Privacy:</i> You agree that you have had an opportunity to review and that you agree with our Privacy Policy relating to our website, the services
    we provide, and our third-party service providers.  Our privacy policy is available at
    <a href="https://www.confia.fi/online-privacy-policy/" target="_blank">www.confia.fi/online-privacy-policy/</a> and is incorporated herein by this
    reference.
</p>
<p>
    <i>Telephone Calls/Monitoring/Recording:</i> For our mutual protection, and to enable us to provide better service to you, we may monitor and/or
    record any of our telephone conversations.
</p>
<p>
    <i>Business Days:</i> Our business days are Monday through Friday, excluding U.S. federal holidays.
</p>
<p>
    <i>Unused Account Closure:</i> If you do not log in to your account for two or more years, we may close your account.
</p>
<p>
    <i>Choice of Law:</i> This Agreement will be construed, applied, and governed by the laws of the State of California exclusive of its conflict or
    choice of law rules except to the extent that U.S. federal law controls. All litigation will be brought in the state or federal courts located
    in Delaware.
</p>
<p>
    <i>Force Majeure:</i> Neither party to this Agreement will be deemed to be in default of any of its obligations under this Agreement if its performance
    is delayed, hindered, or becomes impossible because of any act of God or of any public enemy, hostilities, war (declared or undeclared), guerilla or
    terrorist activities, act of sabotage, blockade, earthquake, flood, landslide, avalanche, tremor, ground movement, hurricane, storm, explosion, fire,
    labor disturbance, riot, insurrection, strike, sickness, pandemic, accident, civil, commotion, epidemic, act of government or its agencies or officers,
    power interruption or transmission failure, or any cause beyond the control of either party.
</p>
<p>
    <i>Successors; Assignment:</i> This Agreement is for the sole benefit of the parties hereto and their respective successors and permitted assigns and
    nothing herein, express or implied, is intended to or shall confer on any other person any legal or equitable right, benefit, or remedy of any nature
    whatsoever under or by reason of this Agreement. Notwithstanding the foregoing, you may not transfer or assign any rights or obligations you have
    under this user agreement without our prior written consent. We may transfer or assign this user agreement, our privacy policy, and/or any right or
    obligation under this user agreement, in whole or in part, at any time.
</p>
<p>
    <i>Severability:</i> TThis Agreement may only be amended, modified, or supplemented by an agreement in writing signed by each party hereto. No waiver
    by any party of any of the provisions hereof shall be effective unless explicitly set forth in writing and signed by the party so waiving. Except as
    otherwise set forth in this Agreement, no failure to exercise, or delay in exercising, any right, remedy, power or privilege arising from this Agreement
    shall operate or be construed as a waiver thereof; nor shall any single or partial exercise of any right, remedy, power or privilege hereunder preclude
    any other or future exercise thereof or the exercise of any other right, remedy, power, or privilege. If any term of this Agreement is to any extent
    invalid, or incapable of being enforced, such term shall be excluded to the extent of such invalidity or unenforceability; all other terms hereof
    shall remain in full force and effect; and, to the extent permitted and possible, the invalid or unenforceable term shall be deemed replaced by a
    term that is valid and enforceable and that comes closest to expressing the intention of such invalid or unenforceable term.
</p>
<p>
    <b>
        YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE, UNDERSTAND THESE TERMS OF SERVICE, AND WILL BE BOUND BY THESE TERMS AND CONDITIONS. YOU
        FURTHER ACKNOWLEDGE THAT THESE TERMS OF USE TOGETHER WITH THE PRIVACY POLICY AT
        <a href="https://www.confia.fi/online-privacy-policy/" target="_blank">WWW.CONFIA.FI/ONLINE-PRIVACY-POLICY/</a> REPRESENT THE COMPLETE AND
        EXCLUSIVE STATEMENT OF THE AGREEMENT BETWEEN US AND THAT IT SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT ORAL OR WRITTEN, AND ANY OTHER COMMUNICATIONS
        BETWEEN US RELATING TO THE SUBJECT MATTER OF THIS AGREEMENT.
    </b>
</p>
<p>
    <b>
        FOR MORE INFORMATION OR OTHER INQUIRIES REGARDING YOUR ACCOUNT PLEASE CONTACT US AT 855-200-9533 x3.
    </b>
</p>
