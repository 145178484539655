import { Component } from '@angular/core';
import { Alert, AlertStatus } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-alerts-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class AlertsBadgeComponent extends BaseBadgeComponent<Alert> {

    setBadgeData() {
        this.badgeToolTip = this.parent.status;
        switch (this.parent.status) {
            case AlertStatus.ACTIVE:
                this.badgeText = 'ACTIVE';
                this.badgeClass = 'green-badge';
                break;
            case AlertStatus.REVIEWED:
                this.badgeText = 'REVIEWED';
                this.badgeClass = 'grey-badge';
                break;
            case AlertStatus.ACTIONED:
                this.badgeText = 'ACTIONED';
                this.badgeClass = 'grey-badge';
                break;
            default:
                this.badgeText = 'INFORMATION';
                this.badgeClass = 'grey-badge';
                break;
        }
    }

}
