import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http.service';
import { WalletFunction, MemberAccountStatus, MemberAccount, MemberAccountType } from './models/member-account';
import { Observable } from 'rxjs';
import { PagedResponse } from './models/paged-response';
import { AuthService } from './auth/auth.service';
import { RiskProfile } from './models/risk-profile';

@Injectable({
    providedIn: 'root'
})
export class MemberAccountService {

    baseUrl: string;

    constructor(private http: HttpService,
                private authService: AuthService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.EntityAPIEndpoint + '/';
    }

    loadMemberAccount(id: string) {
        return this.http.get(`${this.baseUrl}member_account/${id}`);
    }

    updateMemberAccount(id: string, body: any) {
        return this.http.put(`${this.baseUrl}member_account/${id}`, body);
    }

    getActiveMemberBusinessAccounts(memberId: string, publicOnly: boolean = false) {
        let params = `?memberId=${memberId}&statuses=ACTIVE&accountTypes=BUSINESS&excludeSelf=false`;
        if (publicOnly) {
            params += '&publicOnly=true';
        }
        return this.http.get(`${this.baseUrl}member_account${params}`);
    }

    getAllMemberBusinessAccounts(memberId: string) {
        return this.http.get(`${this.baseUrl}member_account?memberId=${memberId}&accountTypes=BUSINESS&excludeSelf=false`);
    }

    getMemberAccountsByTypesAndStatus(memberId: string,
        accountTypes: MemberAccountType[],
        statuses: MemberAccountStatus[],
        start: number,
        size: number,
        sort: string,
        sortDir: string) {

        const params = `?memberId=${memberId}&accountTypes=${accountTypes}&statuses=${statuses}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}&excludeSelf=false`;
        return this.http.get(`${this.baseUrl}member_account${params}`);
    }

    searchMemberAccounts(memberName: string,
        accountTypes: MemberAccountType[],
        statuses: MemberAccountStatus[],
        walletFunctions: WalletFunction[],
        jurisdiction: string,
        excludeSelf: boolean,
        publicOnly: boolean,
        limit?: number): Observable<PagedResponse<MemberAccount>>  {

        const params = `?memberName=${encodeURI(memberName)}&fuzzy=true&accountTypes=${accountTypes}&statuses=${statuses}&walletFunctions=${walletFunctions}&jurisdiction=${jurisdiction}&excludeSelf=${excludeSelf}&publicOnly=${publicOnly}&start=0&size=${limit || 0}&sort=member.sortName&sortDir=ASC`;
        return this.http.get(`${this.baseUrl}member_account/${params}`) as Observable<PagedResponse<MemberAccount>>;
    }

    hasWalletFunction(memberAccount: MemberAccount, walletFunction: WalletFunction) {
        if (memberAccount) {
            return (memberAccount.walletFunctions.indexOf(walletFunction) >= 0);
        }
        return false;
    }

    isAccountAdmin(memberAccount: MemberAccount) {
        return this.authService.isAdmin(memberAccount);
    }

    getRiskProfile(memberAccountId: string) {
        return  this.http.get(`${this.baseUrl}member_account/${memberAccountId}/risk`);
    }

    saveRiskProfile(riskProfile: RiskProfile) {
        return this.http.post(`${this.baseUrl}member_account/${riskProfile.memberAccountId}/risk`, riskProfile);
    }
}
