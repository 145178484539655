import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
    MemberAccountService, OnboardingTrackingService, AuthService, MemberType, RiskProfileFormService, RiskProfile, MemberAccountStatus
} from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-account-limits',
    templateUrl: './member-account-limits.component.html',
    styleUrls: ['./member-account-limits.component.scss']
})
export class MemberAccountLimitsComponent implements OnInit {

    MemberType = MemberType;
    MemberAccountStatus = MemberAccountStatus;

    @Input() memberAccountId: string;
    @Input() memberType: MemberType;
    @Input() task: any;
    @Input() changeTrigger: number;
    @Input() readOnly = false;
    @Input() status;

    riskProfileForm: UntypedFormGroup;

    constructor(private memberAccountService: MemberAccountService,
                public authService: AuthService,
                private riskProfileFormService: RiskProfileFormService) {
    }

    ngOnInit() {
        this.isInternalAlertsValid = this.isInternalAlertsValid.bind(this);
        this.onSave = this.onSave.bind(this);
        this.initForms();
    }

    initForms(callback?: any) {
        this.memberAccountService.getRiskProfile(this.memberAccountId).subscribe((riskProfile: RiskProfile) => {
            this.initRiskProfile(riskProfile);
            this.updateValidity();
            if (callback) {
                callback();
            }
        });
    }

    initRiskProfile(riskProfile: RiskProfile) {
        if (riskProfile) {
            this.riskProfileForm = this.riskProfileFormService.initializeForm(riskProfile);
            if (this.readOnly) {
                this.riskProfileForm.disable();
            }
            this.riskProfileForm.markAsPristine();
            this.riskProfileForm.valueChanges.subscribe(() => {
                this.updateValidity();
            });
        }
    }

    isInternalAlertsValid() {
        return this.riskProfileForm &&
            this.riskProfileForm.get('expectedMonthlyCashDepositAmountCtrl').valid &&
            this.riskProfileForm.get('monthlyCashDepositAmountLimitCtrl').valid &&
            this.riskProfileForm.get('accountAmountLimitCtrl').valid &&
            this.riskProfileForm.get('expectedMonthlyPaymentAmountCtrl').valid &&
            this.riskProfileForm.get('expectedMonthlyTransactionsCtrl').valid &&
            this.riskProfileForm.get('maxNumberOfTransactionsPerMonthCtrl').valid &&
            this.riskProfileForm.get('maxTransactionTotalPerMonthCtrl').valid &&
            this.riskProfileForm.get('maxNumberOfTransactionsPerDayCtrl').valid &&
            this.riskProfileForm.get('maxTransactionTotalCtrl').valid && OnboardingTrackingService.isInternalAlertsDirty();
    }

    updateInformation(callback?: any) {
        const riskProfile = this.riskProfileFormService.getRiskProfile(this.riskProfileForm);
        this.memberAccountService.saveRiskProfile(riskProfile).subscribe(() => {
            this.initForms(callback);
            this.changeTrigger = Math.random();
        });
    }

    onSave(reset?: any) {
        this.updateInformation(reset);
    }

    updateValidity() {
        OnboardingTrackingService.setInternalAlertsDirty(
            this.riskProfileForm.get('expectedMonthlyCashDepositAmountCtrl').dirty ||
            this.riskProfileForm.get('monthlyCashDepositAmountLimitCtrl').dirty ||
            this.riskProfileForm.get('accountAmountLimitCtrl').dirty ||
            this.riskProfileForm.get('expectedMonthlyPaymentAmountCtrl').dirty ||
            this.riskProfileForm.get('expectedMonthlyTransactionsCtrl').dirty ||
            this.riskProfileForm.get('maxNumberOfTransactionsPerMonthCtrl').dirty ||
            this.riskProfileForm.get('maxTransactionTotalPerMonthCtrl').dirty ||
            this.riskProfileForm.get('maxNumberOfTransactionsPerDayCtrl').dirty ||
            this.riskProfileForm.get('maxTransactionTotalCtrl').dirty);
    }

}
