import { Address } from '../address';

export class AccountingParty {

    source: string;
    externalId: string;
    name: string;
    dbaName: string;
    status: string;
    email: string;
    officePhone: string;
    type: AccountingPartyType;
    memberId: string;
    billAddress: Address;
    shipAddress: Address;
    contactName: string;
    description: string;
}

export enum AccountingPartyType {
    SUPPLIER = 'SUPPLIER',
    CUSTOMER = 'CUSTOMER'
}
