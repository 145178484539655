import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountManagementComponent, DirtyCheckGuard } from 'projects/components/src/lib/administration';
import { SettingsManagementComponent, BankAccountReviewComponent } from './settings';
import { MemberManagementComponent, MemberDetailsComponent, CompleteMemberRegistrationComponent, MemberInviteComponent, PasswordResetTaskComponent, SearchHeaderComponent } from './members';
import { MerchantDetailsComponent } from './merchants';
import { AuthGuard, MemberFunction, MemberType, Role } from 'projects/services/src/public-api';
import { PeriodicReviewComponent } from './members/components/periodic-review/periodic-review.component';
import { IndividualDetailsComponent } from './individuals';
import { MemberAccountDetailsComponent } from './member-accounts';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'account',
        pathMatch: 'full'
    },
    {
        path: 'account',
        component: AccountManagementComponent,
        canActivate: [AuthGuard],
        data: { title: 'Administration', roles: [Role.AUTHORITY_READONLY, Role.MEMBER_READONLY] }
    },
    {
        path: 'settings',
        component: SettingsManagementComponent,
        canActivate: [AuthGuard],
        data: { title: 'Settings', roles: [Role.AUTHORITY_READONLY, Role.MEMBER_READONLY] }
    },
    {
        path: 'members',
        component: MemberManagementComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.AUTHORITY_READONLY, Role.MEMBER_READONLY], types: [MemberType.AUTHORITY], functions: [MemberFunction.PARTNER] }
    },
    {
        path: 'member/invite',
        component: MemberInviteComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.AUTHORITY] }
    },
    {
        path: 'member/reset',
        component: PasswordResetTaskComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.AUTHORITY] }
    },
    {
        path: 'member/adjudicate',
        component: MemberDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.AUTHORITY_READONLY, Role.NEW_ADMIN_REGISTRANT], types: [MemberType.AUTHORITY, MemberType.BUSINESS] },
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'member/adjudicate/:memberId',
        component: MemberDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.AUTHORITY_READONLY, Role.NEW_ADMIN_REGISTRANT], types: [MemberType.AUTHORITY, MemberType.BUSINESS] },
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'member/onboard',
        component: MemberDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.NEW_ADMIN_REGISTRANT], types: [MemberType.BUSINESS] },
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'member/onboard/complete',
        component: CompleteMemberRegistrationComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.ADMIN], types: [MemberType.BUSINESS] }
    },
    {
        path: 'member/:memberId/periodic-review',
        component: PeriodicReviewComponent,
        canActivate: [AuthGuard],
        data: { title: 'Periodic Compliance Review', roles: [Role.ADMIN], types: [MemberType.BUSINESS] }
    },
    {
        path: 'member/:memberId',
        component: MemberDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.AUTHORITY_READONLY, Role.NEW_ADMIN_REGISTRANT], types: [MemberType.AUTHORITY, MemberType.BUSINESS] },
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'search',
        component: SearchHeaderComponent,
        canActivate: [AuthGuard],
        data: {roles: [Role.AUTHORITY_READONLY], types: [MemberType.AUTHORITY] }
    },
    {
        path: 'bank-account/review',
        component: BankAccountReviewComponent,
        canActivate: [AuthGuard],
        data: { title: 'Settings', roles: [Role.AUTHORITY] }
    },
    {
        path: 'bank-account/review/:bankAccountId',
        component: BankAccountReviewComponent,
        canActivate: [AuthGuard],
        data: { title: 'Settings', roles: [Role.AUTHORITY] }
    },
    {
        path: 'merchant/onboard',
        component: MerchantDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.AUTHORITY_READONLY] },
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'merchant/onboard/:merchantOnboardMemberId',
        component: MerchantDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.AUTHORITY_READONLY] },
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'merchant/adjudicate',
        component: MerchantDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.AUTHORITY_READONLY] },
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'merchant/:merchantMemberId',
        component: MerchantDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.AUTHORITY_READONLY] },
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'individual/onboard',
        component: IndividualDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.AUTHORITY_READONLY] },
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'individual/onboard/:individualOnboardMemberId',
        component: IndividualDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.AUTHORITY_READONLY] },
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'individual/adjudicate',
        component: IndividualDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.AUTHORITY_READONLY] },
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'individual/:individualMemberId',
        component: IndividualDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.AUTHORITY_READONLY] },
        canDeactivate: [DirtyCheckGuard]
    },
    {
        path: 'member-account/adjudicate',
        component: MemberAccountDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Members', roles: [Role.AUTHORITY_READONLY] }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdministrationRoutingModule {
}
