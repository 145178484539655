<div class="row mb-3">
    <div class="col-sm-12 col-lg-6">
        <div *ngIf="memberAccount">
            <p class="form-title">Account Overview</p>
            <div class="member_type">
                <span>
                    <dl class="row">
                        <dt class="col-6">Member:</dt>
                        <dd class="col-6">
                            <pc-load-member-details [linkDescription]="memberAccount.member.name" [memberId]="memberAccount.memberId" [activeTab]="'accounts'" [linkType]="'large'" [directLink]="memberUnderReview"></pc-load-member-details>
                        </dd>
                    </dl>
                    <dl class="row">
                        <dt class="col-6">Account Friendly Name:</dt>
                        <dd class="col-6">{{ memberAccount.accountFriendlyName }}</dd>
                    </dl>
                    <dl class="row">
                        <dt class="col-6">Account Type:</dt>
                        <dd class="col-6">{{ memberAccount.accountType | translate }}</dd>
                    </dl>
                    <dl class="row" *ngIf="accountNumber">
                        <dt class="col-sm-6 col-lg-6">Account Number:</dt>
                        <dd class="col-6">{{ accountNumber }}</dd>
                    </dl>
                    <dl class="row" *ngIf="memberAccount.accountNotes">
                        <dt class="col-sm-6 col-lg-6">Purpose of Account:</dt>
                        <dd class="col-6">{{ memberAccount.accountNotes }}</dd>
                    </dl>
                    <button pc-no-dbl-click *ngIf="displayVoidCheckButton()" class="blue-button check_button" type="button" (click)="getVoidCheckForMemberAccount()" submit-button>Download Void Check</button>
                </span>
            </div>
        </div>
        <div *ngIf="wallet">
            <p class="form-title">Wallet Balance</p>
            <div class="member_type">
                <span>
                    <dl class="row">
                        <dt class="col-8">Available Balance<sup><i class="fa fa-question-circle-o" matTooltip="The available balance includes all completed payments and deposits, as well as any pending ACH/WIRE payments. Pending checks and scheduled payments have not been deducted from the available balance."></i></sup>:</dt>
                        <dd class="col-4 pe-4 text-end" [ngClass]="isAmountNegative(availableBalance) ? 'negative-amount' : ''">{{ availableBalance | currency }}</dd>
                    </dl>
                    <dl class="row">
                        <dt class="col-8">Pending Incoming Amount<sup><i class="fa fa-question-circle-o" matTooltip="Pending incoming funds have not yet completed. They have not yet been added to your available balance."></i></sup>:</dt>
                        <dd class="col-4 pe-4 text-end">{{ fundsPending | currency }}</dd>
                    </dl>
                    <dl class="row" *ngIf="isDepositorAccount">
                        <dt class="col-8">Outstanding Check Amount<sup><i class="fa fa-question-circle-o" matTooltip="Outstanding amount of all pending checks that have not yet been cashed. They have not been deducted from your available balance."></i></sup>:</dt>
                        <dd class="col-4 pe-4 text-end" [ngClass]="isOutstandingAmountPresent() ? 'negative-amount' : ''">{{ outstandingCheckAmount | currency }}</dd>
                    </dl>
                </span>
            </div>
        </div>
        <div *ngIf="memberAccount && (wallet || accountUnderReview)">
            <div class="form-title">Wallet Functions </div>
            <div>
                <pc-simple-checkbox [label]="'Bank Account Deposits Enabled'" [class]="'no-margin-bottom'" [formCtrl]="'achDepositEnabledCtrl'" [formGroup]="walletFunctionForm" [invert]="true" (changed)="updateInformation()">
                </pc-simple-checkbox>
                <pc-simple-checkbox [label]="'Cash Deposits Enabled'" [class]="'no-margin-bottom'" [formCtrl]="'cashDepositEnabledCtrl'" [formGroup]="walletFunctionForm" [invert]="true" (changed)="updateInformation()">
                </pc-simple-checkbox>
                <pc-simple-checkbox *ngIf="walletFunctionForm.controls['achDepositEnabledCtrl'].value" [label]="'Currency Withdrawal Enabled'" [class]="'no-margin-bottom'" [formCtrl]="'currencyWithdrawalEnabledCtrl'" [formGroup]="walletFunctionForm" [invert]="true" (changed)="updateInformation()">
                </pc-simple-checkbox>
                <pc-simple-checkbox [label]="'Consumer Payments Accepted'" [class]="'no-margin-bottom'" [formCtrl]="'acceptConsumerCtrl'" [formGroup]="walletFunctionForm" [invert]="true" (changed)="updateInformation()">
                </pc-simple-checkbox>
                <pc-simple-checkbox *ngIf="walletFunctionForm.controls['acceptConsumerCtrl'].value" [label]="'Accept BNPL Payments'" [class]="'no-margin-bottom'" [formCtrl]="'acceptBNPLPaymentsCtrl'" [formGroup]="walletFunctionForm" [invert]="true" (changed)="updateInformation()">
                </pc-simple-checkbox>
                <pc-simple-checkbox *ngIf="walletFunctionForm.controls['acceptConsumerCtrl'].value" [label]="'Enable Debt Collection'" [class]="'no-margin-bottom'" [formCtrl]="'enableDebtCollectionCtrl'" [formGroup]="walletFunctionForm" [invert]="true" (changed)="updateInformation()">
                </pc-simple-checkbox>
                <pc-simple-checkbox [label]="'Business Payments Accepted'" [class]="'no-margin-bottom'" [formCtrl]="'acceptBusinessCtrl'" [formGroup]="walletFunctionForm" [invert]="true" (changed)="updateInformation()">
                </pc-simple-checkbox>
                <pc-simple-checkbox [label]="'Send Direct ACH Payments Only'" [class]="'no-margin-bottom'" [formCtrl]="'directPaymentEnabledCtrl'" [formGroup]="walletFunctionForm" [invert]="true" (changed)="updateInformation()">
                </pc-simple-checkbox>
                <pc-simple-checkbox [label]="'Daily ACH Push Enabled'" [class]="'no-margin-bottom'" [formCtrl]="'dailyAchPushEnabledCtrl'" [formGroup]="walletFunctionForm" [invert]="true" (changed)="updateInformation()">
                </pc-simple-checkbox>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-lg-6 overflow-auto">
        <div *ngIf="(isMember() || isFeeAccount()) && bankingIdentificationForm && memberAccount.status !== MemberAccountStatus.UNDER_REVIEW">
            <div class="row">
                <div class="col-6">Banking Identification</div>
                <div class="col-6 d-flex justify-content-end">
                    <button class="blue-button pull-right" *ngIf="authService.isAuthority()" type="button" (click)="updateBankingId()" [disabled]="readOnly">Update Banking ID</button>
                </div>
            </div>
            <div>
                <pc-text-input [label]="'Customer ID'" [formCtrl]="'customerIdCtrl'" [formGroup]="bankingIdentificationForm" [placeholder]="'Not Entered'">
                </pc-text-input>
                <pc-text-input [label]="'Account ID'" [formCtrl]="'accountIdCtrl'" [formGroup]="bankingIdentificationForm" [placeholder]="'Not Entered'">
                </pc-text-input>
            </div>
        </div>
        <div>
            <form *ngIf="billingProfileForm" [formGroup]="billingProfileForm">
                <div *ngIf="memberAccount.accountType === MemberAccountType.BUSINESS">
                    <p class="form-title">Billing Profile</p>
                    <div>
                        <pc-form-amount-input [label]="'Initial Billing Credit (in months)'" [formCtrl]="'initialBillingCreditMonthsCtrl'" [formGroup]="billingProfileForm"
                            [dollar]="false" [includeCents]="false">
                        </pc-form-amount-input>
                         <pc-percentage-input *ngIf="consumerPaymentsAccepted()" [label]="'Retailer Fee %'" [formCtrl]="'retailTransactionFeePercentageCtrl'"
                            [formGroup]="billingProfileForm" [allowDecimals]="true">
                        </pc-percentage-input>
                        <pc-form-amount-input *ngIf="consumerPaymentsAccepted()" [label]="'Consumer Convenience Fee'" [formCtrl]="'retailConvenienceFeeAmountCtrl'" [formGroup]="billingProfileForm"
                            [includeCents]="true">
                        </pc-form-amount-input>
                        <pc-form-amount-input *ngIf="cashDepositsEnabled()" [label]="'Cash Deposit Base Fee'" [formCtrl]="'cashDepositBaseFeeCtrl'" [formGroup]="billingProfileForm"
                            [includeCents]="true">
                        </pc-form-amount-input>
                         <pc-percentage-input *ngIf="cashDepositsEnabled()" [label]="'Cash Deposit Fee %'" [formCtrl]="'cashDepositFeePercentageCtrl'"
                            [formGroup]="billingProfileForm" [allowDecimals]="true">
                        </pc-percentage-input>
                        <pc-form-amount-input [label]="'Monthly Account Fee'" [formCtrl]="'monthlyAccountFeeAmountCtrl'" [formGroup]="billingProfileForm"
                            [includeCents]="true">
                        </pc-form-amount-input>
                        <pc-form-amount-input [label]="'ACH Withdrawal Fee'" [formCtrl]="'achWithdrawalFeeCtrl'" [formGroup]="billingProfileForm"
                            [includeCents]="true">
                        </pc-form-amount-input>
                        <pc-form-amount-input [label]="'ACH Express Payment Fee'" [formCtrl]="'achExpressPaymentFeeCtrl'" [formGroup]="billingProfileForm"
                            [includeCents]="true">
                        </pc-form-amount-input>
                        <pc-form-amount-input [label]="'Overdraft Fee'" [formCtrl]="'overdraftFeeCtrl'" [formGroup]="billingProfileForm"
                            [includeCents]="true">
                        </pc-form-amount-input>
                        <pc-form-amount-input [label]="'Wire Payment Fee'" [formCtrl]="'wirePaymentFeeCtrl'" [formGroup]="billingProfileForm"
                            [includeCents]="true">
                        </pc-form-amount-input>
                        <pc-form-amount-input [label]="'Incoming Wire Fee'" [formCtrl]="'incomingWireFeeCtrl'" [formGroup]="billingProfileForm"
                            [includeCents]="true">
                        </pc-form-amount-input>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="button-panel pull-right">
    <pc-submit-button [enabled]="isBillingProfileDetailsValid" [submit]="onSave" [text]="'Save'"></pc-submit-button>
</div>
<a class="report-download-link" #downloadVoidCheckLink></a>
