<div class="dashboard-info" *ngIf="report">
    <div class="row mb-2">
        <div class="col-6">
            <a [routerLink]="['/reports/authority/list']" [routerLinkActive]="['router-link-active']">
                <i class="fa fa-caret-left"></i> Return to Reports
            </a>
        </div>
        <div class="col-6">
            <div class="pull-right">
                <button *ngIf="authService.isAuthority() || isAuthorityReporter()" class="blue-button pull-right"
                    (click)="updateFincenReportTrackingDetails()" type="button" submit-button>
                    <i class="fa fa-plus pull-left dashboard-icon" aria-hidden="true"></i>
                    <div *ngIf="authService.isAuthority()" class="pull-right-button"> Update Tracking Details </div>
                    <div *ngIf="isAuthorityReporter()" class="pull-right-button"> Update Notes </div>
                </button>
                <button *ngIf="showRegenerateButton" type="button" class="blue-button" (click) = "regenerateReport()">Regenerate Report</button>
                <button
                    *ngIf="report.reportType === ReportType.CONTINUING_REPORT && report.filingInstitution === FilingInstitution.POINTCHAIN && authService.isAuthority()"
                    pc-no-dbl-click type="button" class="blue-button" [disabled]="report.status !== ReportStatus.AUTHORITY_REVIEW"
                    (click)="uploadFileToSDTMServer()" submit-button> Approve </button>
                <button *ngIf="!isApproveButtonEnabled && isAuthority() && report.filingInstitution === FilingInstitution.NBCU" pc-no-dbl-click type="button" class="blue-button" [disabled]="report.status !== ReportStatus.AUTHORITY_REVIEW" (click)="updateFincenFiledReport()" submit-button> Approve </button>
                <button *ngIf="!isSubmitButtonEnabled && isAuthorityReporter()" pc-no-dbl-click type="button" class="blue-button" [disabled]="report.status === ReportStatus.AUTHORITY_REVIEW" (click)="uploadFileToSDTMServer()" submit-button> Submit </button>
            </div>
        </div>
    </div>
    <div class="row" >
        <div class="col-6 inner-column">
            <div class="row" >
                <div class="col-3 expandedLabel">
                    Name Of Member
                </div>
                <div class="col-6 expandedValue">
                    <a *ngIf="report?.memberId" [routerLink]="['/administration/member/' + report?.memberId]" [routerLinkActive]="['router-link-active']">
                        {{ report?.memberName }}
                    </a>
                </div>
            </div>
            <div class="row" *ngIf="member">
                <div class="col-3 pt-1 expandedLabel">
                    Member Status
                </div>
                <div class="col-6 pt-1 expandedValue">
                    <pc-member-badge [parent]="member"></pc-member-badge>
                </div>
            </div>
            <div class="row" *ngIf="member">
                <div *ngIf="member.status === MemberStatus.DISABLED" class="col-3 pt-1 expandedLabel">
                    Disabled Date
                </div>
                <div class="col-6 expandedValue">
                    <dd *ngIf="member.status === MemberStatus.DISABLED" class="col-6 pt-1">{{ member.disabledDate | date:'mediumDate' }}</dd>
                </div>
            </div>
            <div class="row" >
                <div class="col-3 expandedLabel">
                    Report Type
                </div>
                <div class="col-6 expandedValue">
                    {{ report.reportType | translate }}
                </div>
            </div>
            <div class="row" *ngIf="report.reportType === ReportType.CONTINUING_REPORT">
                <div class="col-3 expandedLabel">
                    Start Date
                </div>
                <div class="col-9 expandedValue">
                    {{ report.startDate | date:'mediumDate' }}
                </div>
            </div>
            <div class="row" *ngIf="report.reportType === ReportType.CONTINUING_REPORT">
                <div class="col-3 expandedLabel">
                    End Date
                </div>
                <div class="col-9 expandedValue">
                    {{ report.endDate | date:'mediumDate'}}
                </div>
            </div>

            <div class="row" *ngIf="report.reportType === ReportType.CONTINUING_REPORT">
                <div class="col-3 expandedLabel">
                    Cumulative Amount
                </div>
                <div class="col-9 expandedValue">
                    {{report.cumulativeAmount | currency}}
                </div>
            </div>
            <div class="row" *ngIf="report.reportType === ReportType.CONTINUING_REPORT">
                <div class="col-3 expandedLabel">
                    Suspicious Amount
                </div>
                <div class="col-9 expandedValue">
                    {{report.suspiciousAmount | currency}}
                </div>
            </div>
            <div class="row" *ngIf="report.reportType === ReportType.CONTINUING_REPORT || (report.reportType === ReportType.CTR_REPORT 
                && !report.transactionId && report.filingInstitution === FilingInstitution.NBCU)">
                <div class="col-3 expandedLabel">
                    Submission Due Date:
                </div>
                <div class="col-9 expandedValue">
                    {{ getSubmissionDueDate(report) | date : 'mediumDate' }}
                </div>
            </div>
            <div class="row" *ngIf="report.reportType === ReportType.CTR_REPORT && !report.transactionId && report.filingInstitution === FilingInstitution.NBCU">
                <div class="col-3 expandedLabel">
                    CTR Report Generated Date:
                </div>
                <div class="col-9 expandedValue">
                    {{ report.actualReportTriggerDate | date : 'mediumDate' }}
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="clear">
                <div class="row">
                    <div class="col-3 expandedLabel">
                        Document
                    </div>
                    <div class="col-9 expandedValue">
                        <a class="dashboard-list-a" (click)="downloadFile(report?.documentDetails)">
                            <i class="fa fa-paperclip"></i> {{ report?.documentDetails?.fileName }}
                        </a>
                    </div>
                </div>
                <div class="row" >
                    <div class="col-3 expandedLabel">
                        Acknowledged
                    </div>
                    <div class="col-9 expandedValue">
                        {{ report.acknowledgedDate ? (report.acknowledgedDate | date:'mediumDate') : 'N/A'}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-3 expandedLabel">
                        BSA Tracking ID
                    </div>
                    <div class="col-9 expandedValue">
                        <a class="dashboard-list-a" *ngIf="report?.trackingId && report?.trackingDocumentId" (click)="downloadFile(report?.trackingDocumentDetails)">
                            <i class="fa fa-paperclip"></i> {{ report?.trackingId }}
                        </a>
                        <span *ngIf="!report?.trackingDocumentId">{{ report?.trackingId ? report?.trackingId : 'N/A' }}</span>
                    </div>
                </div>
                <div class="row" >
                    <div class="col-3 expandedLabel">
                        BSA ID
                    </div>
                    <div class="col-9 expandedValue">
                        <a class="dashboard-list-a" *ngIf="report?.bsaId && report?.acknowledgedDocumentId" (click)="downloadFile(report?.acknowledgedDocumentDetails)">
                            <i class="fa fa-paperclip"></i> {{ report?.bsaId }}
                        </a>
                        <span *ngIf="!report?.acknowledgedDocumentId">{{ report?.bsaId ? report?.bsaId : 'N/A' }}</span>
                    </div>
                </div>
                <div class="row" *ngIf="report.transactionId">
                    <div class="col-3 expandedLabel">
                        Transaction ID
                    </div>
                    <div class="col-9 expandedValue">
                        <a class="dashboard-list-a" [routerLink]="['/transaction/details/' + report?.transactionId]" [routerLinkActive]="['router-link-active']">
                            {{ report.transactionId }}
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3 expandedLabel">
                        Status
                    </div>
                    <div class="col-9 expandedValue">
                        <pc-report-badge [parent]="report"></pc-report-badge>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3 expandedLabel">
                        Confia Authority Notes
                    </div>
                    <div class="col-9 expandedValue text-break">
                        {{report.notes }}
                    </div>
                </div>
                <div class="row" *ngIf="report.filingInstitution === FilingInstitution.NBCU">
                    <div class="col-3 expandedLabel">
                        NBCU Authority Reporter Notes
                    </div>
                    <div class="col-9 expandedValue text-break">
                        {{report.externalProviderNotes }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="tab-row">
            <div *ngIf="report && report.filingInstitution === FilingInstitution.NBCU && report.narrativeText && (isAuthority() || isAuthorityReporter())" [class]="getTabClass('NARRATIVE')" (click)="setActiveTab('NARRATIVE')">
                Narrative
            </div>
            <div *ngIf="showTransactionsTab" [class]="getTabClass('TRANSACTIONS')" (click)="setActiveTab('TRANSACTIONS')">
                Transaction(s)
            </div>
            <div *ngIf="report && report.filingInstitution === FilingInstitution.NBCU && report.narrativeText && (isAuthority() || isAuthorityReporter())" [class]="getTabClass('HISTORY')" (click)="setActiveTab('HISTORY')">
                History
            </div>
        </div>
    </div>
    <div class="row" *ngIf="activeTab === 'NARRATIVE' && (isAuthority() || isAuthorityReporter())">
        <div class="col-12" >
            <div class="row">
                <div class="col-12 expandedValue text-break">
                    <div [innerHTML] ="report.narrativeText"></div>
                    <div class="pull-right p-3">
                        <button pc-no-dbl-click *ngIf="showRegenerateButton" type="button" class="blue-button" (click)="editNarrativeText()"
                            submit-button> Edit Narrative </button>
                    </div>
                </div>    
            </div>
        </div>
    </div>
    <div *ngIf="activeTab === 'TRANSACTIONS'">
        <div class="row">
            <div class="float-end">
                <div class="pull-right">
                    <button pc-no-dbl-click type="button" class="blue-button" (click)="exportTransactionActivityList()"
                    submit-button> Export </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="mt-3" *ngIf="report && report.reportType === ReportType.CONTINUING_REPORT">
                <pt-sar-continuing-report-list [report]="report" [postedTxnType]="PostedTransactionType.CREDIT">
                </pt-sar-continuing-report-list>
                <pt-sar-continuing-report-list [report]="report" [postedTxnType]="PostedTransactionType.DEBIT">
                </pt-sar-continuing-report-list>
                <div class="centered mb-3">
                    <b> Total Suspicious Amount : <span [ngClass]="report.suspiciousAmount > 0 ? 'error' : ''">{{
                            report.suspiciousAmount | currency}}</span> </b>
                </div>
                <pt-sar-continuing-report-list *ngIf="report.transfer !== null" [report]="report"
                    [postedTxnType]="PostedTransactionType.TRANSFER">
                </pt-sar-continuing-report-list>
            </div>
            <div class="mt-3" *ngIf="report && report.reportType === ReportType.CTR_REPORT && !report.transactionId">
                <pt-sar-continuing-report-list [report]="report" [subType]="TransactionSubType.DEPOSIT">
                </pt-sar-continuing-report-list>
                <pt-sar-continuing-report-list [report]="report" [subType]="TransactionSubType.WITHDRAWAL">
                </pt-sar-continuing-report-list>
                <div class="centered mb-3">
                    <b> Total Amount : <span [ngClass]="report.suspiciousAmount > 0 ? 'error' : ''">{{ report.suspiciousAmount |
                            currency}}</span> </b>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="activeTab === 'HISTORY' && (isAuthority() || isAuthorityReporter())">
        <div class="col-12 expandedValue text-break">
            <table mat-table [dataSource]="auditDetails">
                <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Performed By </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.performedByUser">{{element.performedByUser}}</div>
                    <div *ngIf="!element.performedByUser">{{element.user?.name}}</div>
                </td>
                </ng-container>
                <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="centered"> Date </th>
                <td mat-cell *matCellDef="let element" class="centered">
                    <pc-date-time [date]="element.created"></pc-date-time>
                </ng-container>
                <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="centered"> Action </th>
                <td mat-cell *matCellDef="let element" class="centered"> {{element.action}} </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> Description </th>
                    <td mat-cell *matCellDef="let element">
                        <div>{{element.description}}</div>
                    </td>
                    </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>
<a class="report-download-link" #downloadReportLink></a>