export class Task {
    id: string;
    name: string;
    assignee: string;
    created: Date;
    due: Date;
    description: string;
    owner: string;
    delegationState: string;
    priority: number;
    processInstanceId: string;
    processDefinitionName: string;
    processDefinitionDescription: string;
    processDefinitionId: string;
    formKey: string;
    variables: any;
}
