<form *ngIf="userForm" [formGroup]="userForm">
    <div class="row">
        <div class="form-standard col-md-6">
            <span class="subtitle mt-2">User Information</span>
            <pc-text-input [required]="true" [label]="'First Name'"
                            [formCtrl]="'firstNameCtrl'" [formGroup]="userForm">
            </pc-text-input>
            <pc-text-input [required]="false" [label]="'Middle Name'"
                           [formCtrl]="'middleNameCtrl'" [formGroup]="userForm">
            </pc-text-input>
            <pc-text-input [required]="true" [label]="'Last Name'"
                            [formCtrl]="'lastNameCtrl'" [formGroup]="userForm">
            </pc-text-input>
            <pc-phone-input [required]="true" [label]="'Mobile Number'"
                             [formCtrl]="'mobilePhoneCtrl'" [formGroup]="userForm">
            </pc-phone-input>
            <pc-email-input [label]="'Email Address'"
                            [formCtrl]="'emailCtrl'" [formGroup]="userForm">
            </pc-email-input>
            <pc-dob-input [formGroup]="userForm" [formCtrl]="'birthDateCtrl'" [required]="true">
            </pc-dob-input>
        </div>
            <div class="form-standard col-md-6">
                <span class="subtitle">Account Information</span>
                <pc-text-input [label]="'Account Role'" [formCtrl]="'accountRoleCtrl'" [formGroup]="userForm">
                </pc-text-input>
            </div>
    </div>

    <div class="row mt-5 mb-3">
        <div class="col-sm-6 no-px">
            <button class="blue-button" type="button" (click)="onChangePassword()"> Change Password </button>
        </div>
        <div class="col-sm-6">
            <pc-submit-button class="pull-right" [enabled]="formEnabled" [submit]="onSaveProfile" [text]="'Update'"></pc-submit-button>
        </div>
    </div>
</form>
