import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReferralModalComponent } from 'projects/components/src/public-api';
import { AuthService, SidebarTrackingService, Profile, CountNotificationService, MemberType, MemberService, Member, MemberStatus } from 'projects/services/src/public-api';
import { MemberSwitchModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pc-member-header',
    templateUrl: './member-header.component.html',
    styleUrls: ['./member-header.component.scss']
})
export class MemberHeaderComponent implements OnInit {

    @Input() expandedMenu: boolean;

    @Output() toggleExpandSideMenu: EventEmitter<any> = new EventEmitter<any>();

    profile: Profile;
    referralCode: string;
    member: Member;

    constructor(public authService: AuthService,
                private router: Router,
                private dialog: MatDialog,
                private memberService: MemberService,
                private countNotificationService: CountNotificationService) {
    }

    ngOnInit() {
        this.profile = this.authService.getProfile();
        if (this.profile.memberType === MemberType.BUSINESS) {
            this.memberService.loadMember(this.profile.memberId).subscribe(
                (member: Member) => {
                    this.referralCode = member.referralCode;
                    this.member = member;
                }
            );
        }
    }

    getMenuClass() {
        if (this.expandedMenu) {
            return 'top-header-icon-wide';
        } else {
            return 'top-header-icon-narrow';
        }
    }

    getBodyClass() {
        if (this.expandedMenu) {
            return 'top-header-body-narrow';
        } else {
            return 'top-header-body-wide';
        }
    }

    onToggleExpandSideMenu() {
        this.toggleExpandSideMenu.emit('toggle');
    }

    onLoggedOut() {
        this.authService.logout();
    }

    isActive(item: string) {
        return SidebarTrackingService.getActiveNavItem() === item;
    }

    sendReferral() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            referralCode:  this.referralCode,
            memberName:  this.profile.memberName,
            userName: this.profile.name

        };
        this.dialog.open(ReferralModalComponent, dialogConfig);
    }

    isAdmin() {
        return this.authService.isAdmin();
    }

    isMultiMemberUser() {
        return this.authService.getProfile().multiMember;
    }

    switchMember() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.disableClose = true;
        this.dialog.open(MemberSwitchModalComponent, dialogConfig);

    }

    getUnreadMessageCount() {
        return this.countNotificationService.getUnreadMessageCount();
    }

    getOpenItemCount() {
        return this.countNotificationService.getOpenItemCount();
    }

    getMessageClass() {
        if (this.getUnreadMessageCount() > 0) {
            return 'fa fa-envelope';
        } else {
            return 'fa fa-envelope-o';
        }
    }

    isDisabled(link: string) {
        return this.router.url.indexOf(link) >= 0;
    }

    isApplyingOrUnderReview() {
        return this.member && (this.member.status === MemberStatus.APPLIED || this.member.status === MemberStatus.UNDER_REVIEW);
    }
}
