import { SalesOrderLine } from './sales-order-line';

export class SalesOrder {
    lineItems: SalesOrderLine[] = [];
    shipping = false;
    shippingTaxable = false;
    shippingCost = 0;
    preTaxAmount: number;
    taxType = '';
    taxableSubtotal = 0;
    taxableSales = 0;
    totalTrasnaction = 0;
    taxAmount: number;
    totalAmount: number;
}
