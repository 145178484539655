import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class WhitelistRelationshipPartyService {

    baseUrl: string;

    constructor(private http: HttpService,
        @Inject('environment') environment: any) {
        this.baseUrl = environment.PaymentAPIEndpoint + '/';
    }

    getWhitelistedMembers(memberId: string, start: number, size: number, sort: string, sortDir: string) {
        const params = `?memberId=${memberId}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}whitelist-party${params}`);
    }

    getMerchantWhitelistingDetails(memberId: string) {
        return this.http.get(`${this.baseUrl}whitelist-party/merchant/${memberId}`);
    }

    updateWhitelistedParty(id: string, updates: any) {
        return this.http.put(`${this.baseUrl}whitelist-party/${id}`, updates);
    }

    saveWhitelistedParty(whitelistRelationship: any) {
        return this.http.post(`${this.baseUrl}whitelist-party`, whitelistRelationship);
    }

    deleteWhitelistParty(id: string) {
        return this.http.delete(`${this.baseUrl}whitelist-party/${id}`);
    }
}
