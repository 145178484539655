<div class="horizontal-scroll mat-table-container">
    <table mat-table [dataSource]="contractTemplateList" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element">
                <a class="template-link" (click)="viewTemplate(element)">{{element.name}}</a>
            </td>
        </ng-container>

        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
            <td mat-cell *matCellDef="let element"> {{element.created | date}} </td>
        </ng-container>

        <ng-container matColumnDef="creatorName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Offered By </th>
            <td mat-cell *matCellDef="let element"> {{element.creatorName}} </td>
        </ng-container>

        <ng-container matColumnDef="templateType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Available to </th>
            <td mat-cell *matCellDef="let element"> {{(element.templateType === ContractTemplateType.BUYER ? 'SELLER' : 'BUYER') | translate}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="resultsLength" [pageSize]="20"></mat-paginator>
</div>
