export class AccountSummary {
    currentAmount: number;
    otherAmount: number;
    threshold: number;
    summaryType: AccountSummaryType;
    isCurrency: boolean;
}

export enum AccountSummaryType {
    YEARLY_TRANSACTION_LIMIT = 'YEARLY_TRANSACTION_LIMIT',
    MONTHLY_TRANSACTION_LIMIT = 'MONTHLY_TRANSACTION_LIMIT',
    YEARLY_LARGEST_TRANSACTION = 'YEARLY_LARGEST_TRANSACTION',
    MONTHLY_LARGEST_TRANSACTION = 'MONTHLY_LARGEST_TRANSACTION',
    YEARLY_OUTGOING_LIMIT = 'YEARLY_OUTGOING_LIMIT',
    MONTHLY_OUTGOING_LIMIT = 'MONTHLY_OUTGOING_LIMIT',
    YEARLY_INCOMING_LIMIT = 'YEARLY_INCOMING_LIMIT',
    MONTHLY_INCOMING_LIMIT = 'MONTHLY_INCOMING_LIMIT'
}
