import { Component } from '@angular/core';
import { License, LicenseStatus } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-license-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class LicenseBadgeComponent extends BaseBadgeComponent<License> {

    setBadgeData() {
        this.badgeToolTip = this.parent.status;
        switch (this.parent.status) {
            case LicenseStatus.ACTIVE:
                this.badgeText = 'ACTIVE';
                this.badgeClass = 'green-badge';
                break;
            case LicenseStatus.DISABLED:
                this.badgeText = 'DISABLED';
                this.badgeClass = 'red-badge';
                break;
            case LicenseStatus.EXPIRED:
                this.badgeText = 'EXPIRED';
                this.badgeClass = 'red-badge';
                break;
        }
    }
}
