import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService, Role, MetrcService } from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MetrcAccountModalComponent } from 'projects/pt/src/app/components/modal';

@Component({
    selector: 'pt-link-metrc-system',
    templateUrl: './link-metrc-system.component.html',
    styleUrls: ['./link-metrc-system.component.scss']
})
export class LinkMetrcSystemComponent implements OnInit {

    Role = Role;

    @Input() memberId = '';

    @Output() metrcChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    connected = false;
    loading = true;
    changeTrigger = 1;

    constructor(public authService: AuthService,
                private metrcService: MetrcService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.checkAuthentication();
    }

    onRegister() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            memberId: this.memberId
        };
        const dialog = this.dialog.open(MetrcAccountModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.checkAuthentication();
            }
        });
    }

    checkAuthentication() {
        this.metrcService.isAuthenticated(this.memberId).subscribe((authenticated: string) => {
            this.connected = !!authenticated;
            this.metrcChanged.emit(this.connected);
            this.loading = false;
            this.changeTrigger = Math.random();
        });
    }

    onMetrcDeactivated() {
        this.connected = false;
        this.metrcChanged.emit(this.connected);
    }
}
