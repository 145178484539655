<div>
    <form [formGroup]="fieldUpdateForm">
        <div>
            <div class="modal-header">
                <label class="form-title">{{title}}</label>
                <i class="modal-close" (click)="cancel()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div class="row">
                    <div class="col-12">
                        <pc-text-input [label]="'Old ' + label" [formCtrl]="'oldValueCtrl'" [formGroup]="fieldUpdateForm" >
                        </pc-text-input>
                        <pc-phone-input *ngIf="fieldType === UserUpdatableField.PHONE" [label]="'New ' + label" [formCtrl]="'newValueCtrl'" [formGroup]="fieldUpdateForm">
                        </pc-phone-input>
                        <pc-email-input *ngIf="fieldType === UserUpdatableField.EMAIL" [label]="'New ' + label" [formCtrl]="'newValueCtrl'" [formGroup]="fieldUpdateForm">
                        </pc-email-input>
                    </div>
                </div>
            </div>
            <div class="m-3">
                <pc-error-message [error]="warning" [width]="'full'"></pc-error-message>
            </div>
            <div class="modal-footer">
                <pc-submit-button [submit]="confirm" [text]="'Update'" [enabled]="formValid"></pc-submit-button>
                <pc-submit-button [submit]="cancel" [text]="'Cancel'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
