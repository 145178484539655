import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class FinancingRequestFormService {

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    initializeForm(): UntypedFormGroup {
        return this.formBuilder.group({
            idCtrl: new UntypedFormControl(),
            companyTypeCtrl: new UntypedFormControl(),
            lenderCtrl: new UntypedFormControl('', Validators.required),
            requestAmountCtrl: new UntypedFormControl('', [Validators.required, Validators.min(100)]),
            requestTermCtrl: new UntypedFormControl('', [Validators.required, Validators.min(1)]),
            revenueCtrl: new UntypedFormControl(''),
            arTotalCtrl: new UntypedFormControl(''),
            noteCtrl: new UntypedFormControl(''),
            ebitdaCtrl: new UntypedFormControl(''),
            accessToConfiaAgreementCtrl: new UntypedFormControl(false, Validators.requiredTrue)
        });
    }
}
