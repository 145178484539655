import { Address } from './address';

export class ReferralRequest {
    email: string;
    refereeMemberName: string;
    referrerCode: string;
    partnerMemberId: string;
    refereeMemberId: string;
    refereeAddress: Address;
}
