<div>
    <form [formGroup]="loginForm">
        <div class="login-modal">
            <div class="modal-header">
                <label class="form-title">Switch Member</label>
                <i class="modal-close" (click)="cancel()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div class="form-content">
                    <fieldset *ngIf="membersForUser.length" class="form-group">
                        <div class="row">
                            <div class="col-12">
                                <label class="form-label">Select Member</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <select #memberIdCtrl class="form-control form-input-editable" [formControlName]="'memberIdCtrl'"
                                        [required]="true" type="text" (change)="onMemberSelected()">
                                    <option *ngIf="safariFix" value="" disabled selected></option>
                                    <option *ngFor="let member of membersForUser; let i = index;" [value]="member.id">{{member.name}}</option>
                                </select>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    </form>
</div>
