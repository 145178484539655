import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContractSection, ContractSectionTerm, ContractTemplate } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-sc-section',
    templateUrl: './section.component.html',
    styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {

    @Input() mode = 'VIEW';
    @Input() contractTemplate: ContractTemplate;
    @Input() index: number;

    @Output() sectionSelected: EventEmitter<ContractSection> = new EventEmitter<ContractSection>();

    section: ContractSection;

    ngOnInit() {
        this.section = this.contractTemplate.sections[this.index];
    }

    termSelected(_term: ContractSectionTerm) {
        this.sectionSelected.emit(this.section);
    }

    sectionChanged() {
        this.section.terms.forEach((term) => {
            term.included = this.section.included;
            term.subTerms.forEach((subterm) => {
                subterm.included = term.included;
            });
        });
        this.sectionSelected.emit(this.section);
    }
}
