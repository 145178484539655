import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { PageTracking } from 'projects/components/src/lib/table-utils.service';
import { LendingCustomer, LendingCustomerSummary, LenderService } from 'projects/services/src/public-api';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';


@Component({
    selector: 'pt-member-customers',
    templateUrl: './member-customers.component.html'
})
export class MemberCustomersComponent implements  OnInit, AfterViewInit, OnDestroy {

    @Input() memberAccountId: string;

    customers: LendingCustomer[] = [];
    pageTracking: PageTracking;
    totalOwed = 0;
    resultsLength = 0;
    noInvoicesOutstanding = 0;
    daysSalesOutstanding = 0;
    isLoadingResults = true;
    subscription: any;
    filterEvent: EventEmitter<null> = new EventEmitter<null>();

    displayedColumns: string[] = ['customer_name', 'concentration', 'total_owed', 'average_days_receivable', 'no_invoices_outstanding'];

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private lenderService: LenderService) {}

    ngOnInit() {
        this.pageTracking = new PageTracking();
        this.pageTracking.page = 1;
        this.pageTracking.pageSize = 20;
        this.pageTracking.pageSizeOptions = [10, 20, 50, 100];
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.subscription =  merge(this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                const currentPage = ((this.paginator.pageIndex + 1));
                return this.lenderService.getCustomers(this.memberAccountId, currentPage, this.paginator.pageSize);
            }),
            map((financialCustomers: LendingCustomerSummary) => {
                this.resultsLength = financialCustomers.totalResults || 0;
                return financialCustomers || new LendingCustomerSummary();
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf(new LendingCustomerSummary());
            })
        ).subscribe((financialCustomers: LendingCustomerSummary) => {
            this.customers = financialCustomers.results;
            this.totalOwed = financialCustomers.totalOwed;
            this.noInvoicesOutstanding = financialCustomers.noInvoicesOutstanding;
            this.daysSalesOutstanding = financialCustomers.daysSalesOutstanding;
            this.isLoadingResults = false;
        });
    }

    formatConcentration(amount: number) {
        return amount === 0 ? '' : amount + '%';
    }
}
