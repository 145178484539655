import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import {
    AuthService, DiligenceHistory, DiligenceService, OnboardingTrackingService, MemberType, PagedResponse, Task, WorkflowService, MemberStatus
} from 'projects/services/src/public-api';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pt-member-application-decision',
    templateUrl: './member-application-decision.component.html'
})
export class MemberApplicationDecisionComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() memberType: MemberType = MemberType.BUSINESS;
    @Input() changeTrigger: number;
    @Input() memberId: string;
    @Input() taskId: string;
    @Input() isPeriodicReviewTask = false;


    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    isLoadingResults = true;
    resultsLength = 0;
    pageTracking: PageTracking;
    displayedColumns = ['type', 'date', 'reviewed_by', 'status', 'action'];
    history: DiligenceHistory[] = [];
    approveButtonLabel: string = 'Approve Applicant';
    rejectButtonLabel: string = 'Reject Applicant';

    private tableSubs: Subscription;
    private refreshEvent: EventEmitter<null> = new EventEmitter<null>();
    private selectedHistory: DiligenceHistory;

    constructor(private workflowService: WorkflowService,
                private authService: AuthService,
                private diligenceService: DiligenceService,
                private cdr: ChangeDetectorRef,
                private activateRoute: ActivatedRoute,
                private router: Router) {}

    ngOnInit() {
        this.onAccept = this.onAccept.bind(this);
        this.onReject = this.onReject.bind(this);
        this.applicationValid = this.applicationValid.bind(this);
        this.pageTracking = TableUtils.initializeTableValues(this.activateRoute, this.router, 'created', 'desc');
        if (!this.taskId) {
            this.workflowService.findTaskByTaskDefinitionsAndProcessVariable(['user_authority_periodic_review'], 'memberId', `${this.memberId}`).subscribe((response: PagedResponse<Task>) => {
                if (response.content.length > 0) {
                    this.isPeriodicReviewTask = true;
                    this.setAdjudicateButtonLabels();
                    this.taskId = response.content[0]?.id;
                    OnboardingTrackingService.setCurrentTask('adjudicate');
                }
            });
        } else {
            OnboardingTrackingService.setCurrentTask('adjudicate');
        }
        this.setAdjudicateButtonLabels();
    }

    ngOnDestroy() {
        if (this.tableSubs) {
            this.tableSubs.unsubscribe();
        }
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.activateRoute, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    setAdjudicateButtonLabels() {
        if (this.memberType === MemberType.BUSINESS_MERCHANT || this.memberType === MemberType.INDIVIDUAL_MERCHANT) {
            this.approveButtonLabel = 'Approve Merchant';
            this.rejectButtonLabel = 'Reject Merchant';
        } else if (this.isPeriodicReviewTask) {
            this.approveButtonLabel = 'Approve Review';
            this.rejectButtonLabel = 'Reject Review';
        }
    }

    onAccept(reset: any) {
        this.onComplete(MemberStatus.ACTIVE, reset);
    }

    onReject(reset: any) {
        this.onComplete(MemberStatus.REJECTED, reset);
    }

    onActionClicked(history: DiligenceHistory) {
        this.selectedHistory = history;
    }

    onComplete(status: MemberStatus, reset: any) {
        /**
         * @deprecated onboardingStatus can be removed once there are no more onboarding flows
         */
        const body = {
            variables: {
                registrationStatus : {
                    value: status,
                    type: 'String'
                },
                onboardingStatus: {
                    value: status === MemberStatus.ACTIVE ? 'Pending-Member-Accepted' : 'Pending-Member-Rejected',
                    type: 'String'
                },
                reviewerUserId : {
                    value: this.authService.getProfile().userId,
                    type: 'String'
                },
                historyId : {
                    value: this.selectedHistory.id,
                    type: 'String'
                }
            }
        };

        this.workflowService.completeTask(this.taskId, body).subscribe(() => {
            this.workflowService.clearCurrentTask();
            // reload the profile and go to the dashboard
            this.router.navigate(['/dashboard']);
        }, (error: any) => {
            reset();
            throw error;
        });
    }

    applicationValid() {
        return OnboardingTrackingService.isApplicationValid(this.memberType, this.authService.isAuthority());
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
        });

        this.tableSubs = merge(this.sort.sortChange, this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.diligenceService.getHistory(this.memberId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<DiligenceHistory>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((result) => {
            this.history = result;
            UIUtils.scrollDashboardToTop();
        });
    }

    isAuthority() {
        return this.authService.isAuthority();
    }
}
