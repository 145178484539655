<fieldset class="form-group" *ngIf="formCtrl">
    <div class="row">
        <div class="col-12" *ngIf="label">
            <label class="form-label">{{ label }}</label>
        </div>
        <div class="col-12" [formGroup]="formGroup">
            <input #inputField class="form-control form-input-editable" [ngClass]="getClass()" [placeholder]="placeholder" (change)="onChange()"
                [formControlName]="formCtrl" [required]="required" type="text">
            <i *ngIf="inlineEdit" class="p-2 fa fa-pencil" role="button" (click)="edit()"></i>
        </div>
    </div>
</fieldset>
