import { Address } from './address';

export class Shipment {
    id: string;
    created: Date;
    updated: Date;

    memberId : string;

    externalId : string;
    deliveryId: string;
    manifestNumber : string;
    source :string;
    systemType : string;

    shipperFacilityLicenseNumber : string;
    shipperFacilityName : string;
    shipperAddress : Address;

    recipientFacilityLicenseNumber: string;
    recipientFacilityName : string;
    recipientAddress : Address;

    actualArrivalDateTime: Date;
    createdDateTime : Date;
    departedArrivalDateTime: Date;
    receivedDateTime : Date;

    transporterName : string;
    transporterLicenseNumber : string;
    transporterTrackingNumber: string;

    lineItems : LabelledPackage[] = [];
}

export class LabelledPackage {
    id: string;
    created: Date;
    updated: Date;

    memberId : string;
    shipmentId : string;
    externalId : string;
    manifestNumber : string;

    packageLabel :string;
    packageType : string;

    productName : string;
    productCategory : string;

    quantityUnits : string;
    receivedQuantity : string;
    shippedQuantity: string;
}
