<div>
    <form [formGroup]="keyPartyForm">
        <div>
            <div class="modal-header">
                <label class="form-title">{{isExistingKeyParty ? 'Update Individual (Shareholder/Director/Officer/Contact)': 'New Individual (Shareholder/Director/Officer/Contact)'}}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content">
                <pc-error-message [error]="errorMessage" [width]="'full'"></pc-error-message>
                <div class="row">
                    <div class="col-6" >
                        <span class="subtitle">Individual Details</span>
                        <pc-email-input *ngIf="authService.isAdmin() || isExistingKeyParty" [label]="'Email Address'"
                            [formCtrl]="'emailCtrl'" [formGroup]="individualKeyPartyForm" (changed)="onChangeEmail($event)">
                        </pc-email-input>
                        <pc-individual-key-party-searcher *ngIf="authService.isAuthority() && !isExistingKeyParty" [formGroup]="individualKeyPartyForm" [label]="'Email Address'" [formCtrl]="'idCtrl'"
                            [placeholder]="'Search individual key parties by name or email'" [existingKeyPartyIds]="existingKeyPartyIds" (individualKeyPartySelected)="setIndividualKeyParty($event)">
                        </pc-individual-key-party-searcher >
                        <pc-text-input [required]="true" [label]="'First Name'" [placeholder]="'First Name'"
                            [formCtrl]="'firstNameCtrl'" [formGroup]="individualKeyPartyForm">
                        </pc-text-input>
                        <pc-text-input *ngIf="!isContactOnly()" [required]="false" [label]="'Middle Name'" [placeholder]="'Middle Name'"
                            [formCtrl]="'middleNameCtrl'" [formGroup]="individualKeyPartyForm">
                        </pc-text-input>
                        <pc-text-input [required]="true" [label]="'Last Name'" [placeholder]="'Last Name'"
                            [formCtrl]="'lastNameCtrl'" [formGroup]="individualKeyPartyForm">
                        </pc-text-input>
                        <pc-text-input *ngIf="!isContactOnly()" [required]="false" [label]="'Other Surname / Alias'" [placeholder]="'Maiden Name/Other Surname used within last 10 years'"
                            [formCtrl]="'otherSurnameOrAliasCtrl'" [formGroup]="individualKeyPartyForm">
                        </pc-text-input>
                        <pc-phone-input [required]="true" [label]="'Mobile Number'"
                            [formCtrl]="'phoneCtrl'" [formGroup]="individualKeyPartyForm">
                        </pc-phone-input>
                        <pc-dob-input *ngIf="(authService.isAuthority() || !isExistingKeyParty) && !isContactOnly()" [required]="!isContactOnly()" [formGroup]="individualKeyPartyForm" [formCtrl]="'birthDateCtrl'">
                        </pc-dob-input>
                        <br />
                        <span class="subtitle">Relationship to Company</span>
                        <pc-simple-checkbox *ngIf="isMerchant" [label]="'Contact Info Only'" [formCtrl]="'isContactCtrl'"
                            [formGroup]="keyPartyForm" [invert]="true" [class]="isRelationshipsValid() ? '' : 'required'" (changed)="updateFormValidators()">
                        </pc-simple-checkbox>

                        <pc-simple-checkbox *ngIf="!isContactOnly()" [label]="'Director'" [formCtrl]="'isDirectorCtrl'" [formGroup]="keyPartyForm"
                            [invert]="true" [class]="isRelationshipsValid() ? '' : 'required'" (changed)="updateFormValidators()">
                        </pc-simple-checkbox>

                        <pc-simple-checkbox *ngIf="!isContactOnly()" [label]="'Officer and/or Control Person'" [formCtrl]="'isOfficerCtrl'"
                            [formGroup]="keyPartyForm" [invert]="true" [class]="isRelationshipsValid() ? '' : 'required'" (changed)="updateFormValidators()">
                        </pc-simple-checkbox>

                        <pc-simple-checkbox *ngIf="!isContactOnly()" [label]="'Owner / Shareholder'" [formCtrl]="'ownerCtrl'" [formGroup]="keyPartyForm"
                            [invert]="true" [class]="isRelationshipsValid() ? '' : 'required'" (changed)="updateFormValidators()">
                        </pc-simple-checkbox>
                        <pc-percentage-input *ngIf="isOwner" [required]="true" [label]="'Ownership %'"
                            [formCtrl]="'percentOwnershipCtrl'" [formGroup]="keyPartyForm">
                        </pc-percentage-input>
                    </div>
                    <div class="col-6" *ngIf="!isContactOnly()">
                        <span class="subtitle">Home Address (not work)</span>
                        <pc-address-input [addressTypeOpts]="[AddressType.PRIMARY]" [addressForm]="addressForm"></pc-address-input>

                        <div *ngIf="!isExistingKeyParty || authService.isAuthority()">
                            <span class="subtitle">Identification</span>
                            <pc-simple-checkbox [label]="'US Citizen'" [formCtrl]="'usCitizenCtrl'" [formGroup]="individualKeyPartyForm"
                                [invert]="true" (changed)="updateFormValidators()">
                            </pc-simple-checkbox>
                            <pc-form-text-input *ngIf="isUsCitizenShip()" [required]="true" [label]="'Social Security Number'"
                                [formCtrl]="'taxIdCtrl'" [formGroup]="individualKeyPartyForm" [placeholder]="'numbers only (no hyphens)'" [restrictToDigits]="true">
                            </pc-form-text-input>
                            <pc-text-input *ngIf="!isUsCitizenShip()" [required]="false" [label]="'Citizenship'"
                                [formCtrl]="'citizenshipCtrl'" [formGroup]="individualKeyPartyForm">
                            </pc-text-input>
                            <pc-text-input *ngIf="!isUsCitizenShip()" [required]="false" [label]="'Passport Number'"
                                [formCtrl]="'passportCtrl'" [formGroup]="individualKeyPartyForm">
                            </pc-text-input>
                            <div *ngIf="!isUsCitizenShip()" class="col-6">
                                <pc-form-date-picker [label]="'Passport Expiration Date'" [formCtrl]="'passportExpDateCtrl'" [defaultEmpty]="!individualKeyPartyForm.get('passportExpDateCtrl').value"
                                    [formGroup]="individualKeyPartyForm" [type]="'SINGLE'" (dateSelected)="onDateChanged($event)" [historical]="false">
                                </pc-form-date-picker>
                            </div>
                            <span class="subtitle">Employment Details</span>
                            <pc-text-input [required]="true" [label]="'Employer'" [placeholder]="'Employer\'s Name'"
                                [formCtrl]="'employerCtrl'" [formGroup]="keyPartyForm">
                            </pc-text-input>
                            <pc-text-input [required]="true" [label]="'Title'" [placeholder]="'Job Title'"
                                [formCtrl]="'professionalTitleCtrl'" [formGroup]="keyPartyForm">
                            </pc-text-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="formsValid" [submit]="onSubmit" [text]="isExistingKeyParty ? 'Save Changes': 'Add Party'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
