import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription, Observable, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'pc-auto-complete-selector',
    templateUrl: './auto-complete.component.html',
    styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteSelectorComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() options: any[];
    @Input() displayValue: string;
    @Input() required: boolean;
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() placeholder = '';

    @Output() selected: EventEmitter<any> = new EventEmitter<any>();

    autoSelect: Subscription;

    autoComplete: Observable<any[]> = null;
    filteredOptions: any[] = [];

    @ViewChild('autoSelect', {read: MatAutocompleteTrigger}) trigger: MatAutocompleteTrigger;

    ngOnInit() {
        this.init();
    }

    init() {
        if (this.formGroup.controls[this.formCtrl].enabled) {
            this.autoComplete = this.formGroup.controls[this.formCtrl].valueChanges.pipe(
                startWith(this.formGroup.controls[this.formCtrl].value),
                switchMap((value) => {
                    return this.filter(value || '');
                })
            );
        }
    }

    ngAfterViewInit() {
        this.subscribeToClosingActions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options && !changes.options.firstChange) {
            this.formGroup.controls[this.formCtrl].setValue('');
        } else if (!changes.formGroup.firstChange) {
            this.init();
            this.subscribeToClosingActions();
        }
    }

    private subscribeToClosingActions() {
        if (this.autoSelect && !this.autoSelect.closed) {
            this.autoSelect.unsubscribe();
        }

        this.autoSelect = this.trigger.panelClosingActions.subscribe((e) => {
            if (!e || !e.source) {
                if (this.filteredOptions.length === 1) {
                    this.selectionChanged(this.filteredOptions[0]);
                } else {
                    const selectedOption = this.filteredOptions
                        .map((option) => {
                            return option.name;
                        })
                        .find((option) => {
                            return option === this.formGroup.controls[this.formCtrl].value;
                        });

                    if (!selectedOption) {
                        this.formGroup.controls[this.formCtrl].markAsPristine();
                        this.formGroup.controls[this.formCtrl].setValue('');
                        this.selectionChanged({});
                    }
                }
            }
        }, () => {
            return this.subscribeToClosingActions();
        }, () => {
            return this.subscribeToClosingActions();
        });
    }

    filter(value: string): Observable<any[]> {
        const filterValue = value.toLowerCase();
        this.filteredOptions = this.options.filter((option) => {
            return option[this.displayValue].toLowerCase().indexOf(filterValue) >= 0;
        });
        return of(this.filteredOptions);
    }

    getDisplayValue(option: any) {
        if (this.displayValue) {
            return option[this.displayValue];
        }
        return option;
    }

    selectionChanged(selected: any, event?: any) {
        if (!event || event?.isUserInput) {
            this.formGroup.controls[this.formCtrl].markAsDirty();
            this.formGroup.controls[this.formCtrl].setValue(this.getDisplayValue(selected));
            this.formGroup.controls[this.formCtrl].updateValueAndValidity();
            this.selected.emit(selected);
        }
    }

    isSelected() {
        return this.formGroup.controls[this.formCtrl].value;
    }

    isDisabled() {
        return this.formGroup.controls[this.formCtrl].disabled;
    }

    onIconClick(event: any) {
        this.selectionChanged({});
        event.stopPropagation();
        this.trigger.openPanel();
    }
}
