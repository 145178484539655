<div>
    <form [formGroup]="fincenEditNarrativeForm">
        <div>
            <div class="modal-header">
                <label class="form-title">Update Narrative Text</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content">
                <div>
                    <pc-text-area [label]="'Narrative'" [formCtrl]="'narrativeCtrl'"
                        [formGroup]="fincenEditNarrativeForm" [class]="'large mw-100'">
                    </pc-text-area> 
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="isFincenEditNarrativeFormValid" [submit]="onSubmit" [text]="'Update and Regenerate Report'">
                </pc-submit-button>
            </div>
        </div>
    </form>
</div>
