import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import { BankingIdentificationModalComponent } from 'projects/pt/src/app/components/modal';
import { LoaderComponent } from 'projects/components/src/public-api';
import {
    Address, AddressService, AuthService, BankingIdentificationFormService, BankingIntegrationService, BillingProfile, BillingProfileFormService, Configuration, CustomerInfo, MemberAccount,
    MemberAccountService, MemberAccountStatus, MemberAccountType, MemberStatus, MemberType, OnboardingTrackingService, PaymentService, Role, Wallet, WalletFunction, WalletFunctionFormService,
    WalletService
} from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-account-billing-info',
    templateUrl: './member-account-billing-info.component.html',
    styleUrls: ['./member-account-billing-info.component.scss']
})
export class MemberAccountBillingInfoComponent implements OnInit, OnChanges {

    MemberAccountType = MemberAccountType;
    MemberAccountStatus = MemberAccountStatus;
    Role = Role;

    @Input() memberAccountId: string;
    @Input() changeTrigger: number;
    @Input() readOnly = false;
    @Input() customerInfo: CustomerInfo;

    @Output() bankingInfoUpdated: EventEmitter<CustomerInfo> = new EventEmitter<CustomerInfo>();

    walletFunctionForm: UntypedFormGroup;
    memberAccount: MemberAccount;
    isDepositorAccount: boolean;
    wallet: Wallet;
    availableBalance: number;
    fundsPending: number;
    outstandingCheckAmount: number;
    billingProfileForm: UntypedFormGroup;
    bankingIdentificationForm: UntypedFormGroup;
    accountNumber: number;
    billingProfile: BillingProfile;
    accountUnderReview = false;
    memberUnderReview = false;

    @ViewChild('downloadVoidCheckLink') downloadVoidCheckLink: ElementRef;

    constructor(private memberAccountService: MemberAccountService,
                private walletService: WalletService,
                private paymentService: PaymentService,
                private dialog: MatDialog,
                private walletFunctionFormService: WalletFunctionFormService,
                private billingProfileFormService: BillingProfileFormService,
                public authService: AuthService,
                private bankingIdentificationFormService: BankingIdentificationFormService,
                private bankingIntegrationService: BankingIntegrationService,
                private overlay: Overlay,
                private addressService: AddressService) {}

    ngOnInit() {
        this.isBillingProfileDetailsValid = this.isBillingProfileDetailsValid.bind(this);
        this.onSave = this.onSave.bind(this);
        this.initForms();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.changeTrigger && !changes?.changeTrigger.firstChange) ||
            (changes.customerInfo && !changes?.customerInfo.firstChange)) {
            this.initForms();
        }
    }

    initForms(callback?: any) {
        this.memberAccountService.loadMemberAccount(this.memberAccountId).subscribe((memberAccount: MemberAccount) => {
            this.memberAccount = memberAccount;
            this.accountUnderReview = (this.memberAccount.status === MemberAccountStatus.UNDER_REVIEW || this.memberAccount.status === MemberAccountStatus.PENDING);
            this.memberUnderReview = (this.memberAccount.member.status === MemberStatus.APPLIED || this.memberAccount.member.status === MemberStatus.UNDER_REVIEW || this.memberAccount.member.status === MemberStatus.PENDING);
            this.loadAddress(memberAccount);
            this.accountNumber = memberAccount.accountNumber;
            this.isDepositorAccount = !this.memberAccountService.hasWalletFunction(memberAccount, WalletFunction.ACH_DIRECT_PAYMENT);
            this.walletFunctionForm = this.walletFunctionFormService.initializeForm(memberAccount.walletFunctions);
            if (this.readOnly) {
                this.walletFunctionForm.disable();
            }
            this.initBankingIdentification();
            this.paymentService.getBillingProfileByMemberAccountId(this.memberAccount.id).subscribe((billingProfile: BillingProfile) => {
                if (billingProfile) {
                    this.billingProfile = billingProfile;
                    this.billingProfileForm = this.billingProfileFormService.initializeBillingProfile(billingProfile);
                    this.billingProfileForm.markAsPristine();
                    this.updateValidity();
                    if (this.readOnly) {
                        this.billingProfileForm.disable();
                    }
                    this.billingProfileForm.valueChanges.subscribe(() => {
                        this.updateValidity();
                    });
                    if (callback) {
                        callback();
                    }
                } else {
                    // For Fee Account we don't have any billing profiles.
                    if (!this.isFeeAccount()) {
                        throw new Error('Billing profile not found.');
                    }
                    if (callback) {
                        callback();
                    }
                }
            });
            this.walletService.findByMemberAccountId(memberAccount.id).subscribe((wallet: Wallet) => {
                this.wallet = wallet;
                this.availableBalance = wallet?.availableBalance;
                this.fundsPending = wallet?.pendingBalance;
                if (callback) {
                    callback();
                }
            });
            if (this.isDepositorAccount) {
                this.paymentService.getOutstandingCheckAmountForMemberAccount(memberAccount.id).subscribe((outstandingAmount: number) => {
                    this.outstandingCheckAmount = outstandingAmount || 0;
                    if (callback) {
                        callback();
                    }
                });
            }

        });
    }

    loadAddress(org: MemberAccount) {
        this.addressService.getPrimaryAddress(org.id).subscribe((addressResponse: Address) => {
            org.address = addressResponse;
        });
    }

    updateInformation(callback?: any) {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        componentRef.instance.title = 'Updating wallet functions.  Please wait...';

        const updatedFunctions: WalletFunction[] = [];
        if (this.walletFunctionForm.controls['achDepositEnabledCtrl'].value) {
            updatedFunctions.push(WalletFunction.ACH_DEPOSIT);
        }
        if (this.walletFunctionForm.controls['cashDepositEnabledCtrl'].value) {
            updatedFunctions.push(WalletFunction.CASH_DEPOSIT);
        }
        if (this.walletFunctionForm.controls['currencyWithdrawalEnabledCtrl'].value && this.walletFunctionForm.controls['achDepositEnabledCtrl'].value === true) {
            updatedFunctions.push(WalletFunction.ENABLE_CURRENCY_WITHDRAWAL);
        }
        if (this.walletFunctionForm.controls['acceptConsumerCtrl'].value) {
            updatedFunctions.push(WalletFunction.ACCEPT_CONSUMER_PAYMENTS);
        }
        if (this.walletFunctionForm.controls['acceptBusinessCtrl'].value) {
            updatedFunctions.push(WalletFunction.ACCEPT_BUSINESS_PAYMENTS);
        }
        if (this.walletFunctionForm.controls['directPaymentEnabledCtrl'].value) {
            updatedFunctions.push(WalletFunction.ACH_DIRECT_PAYMENT);
        }
        if (this.walletFunctionForm.controls['dailyAchPushEnabledCtrl'].value) {
            updatedFunctions.push(WalletFunction.ACH_BALANCE_PUSH);
        }
        if (this.walletFunctionForm.controls['acceptBNPLPaymentsCtrl'].value === true && (this.walletFunctionForm.controls['acceptConsumerCtrl'].value === true)) {
            updatedFunctions.push(WalletFunction.ACCEPT_BNPL_PAYMENTS);
        }
        if (this.walletFunctionForm.controls['enableDebtCollectionCtrl'].value === true && (this.walletFunctionForm.controls['acceptConsumerCtrl'].value === true)) {
            updatedFunctions.push(WalletFunction.ENABLE_DEBT_COLLECTION);
        }

        this.memberAccountService.updateMemberAccount(this.memberAccountId, { walletFunctions: updatedFunctions }).subscribe((memberAccount: MemberAccount) => {
            this.memberAccount = memberAccount;
            overlayRef.dispose();
            if (this.isBillingProfileDetailsValid()) {
                const billingProfile = this.billingProfileFormService.getBillingProfile(this.billingProfileForm);
                if (isNaN(billingProfile.retailConvenienceFeeAmount)) {
                    throw new Error('Retail Convenience Fee Amount not valid.');
                } else if (isNaN(billingProfile.monthlyAccountFee)) {
                    throw new Error('Monthly Account Fee not valid.');
                } else {
                    this.paymentService.saveBillingProfile(billingProfile).subscribe(() => {
                        this.initForms(callback);
                    });
                }
            } else {
                this.initForms(callback);
            }
        });
    }

    onSave(reset?: any) {
        this.updateInformation(reset);
    }

    updateValidity() {
        OnboardingTrackingService.setBillingProfileInfo(this.billingProfileFormService.getBillingProfile(this.billingProfileForm));
        OnboardingTrackingService.setBillingProfileInfoDirty(this.billingProfileForm.dirty);
    }

    isBillingProfileDetailsValid() {
        if (this.memberAccount?.accountType !== MemberAccountType.BUSINESS) {
            return true;
        }
        return OnboardingTrackingService.isBillingProfileInfoValid() &&
            (!this.isFeeAccount() || (this.billingProfileForm && this.billingProfileForm.valid)) &&
            OnboardingTrackingService.isBillingProfileInfoDirty();
    }

    displayVoidCheckButton() {
        return this.isDepositorAccount
            && this.memberAccount?.status === MemberAccountStatus.ACTIVE
            && this.memberAccount?.member.status === MemberStatus.ACTIVE;
    }

    consumerPaymentsAccepted() {
        return this.memberAccount?.walletFunctions && this.memberAccount?.walletFunctions.indexOf(WalletFunction.ACCEPT_CONSUMER_PAYMENTS) >= 0;
    }

    cashDepositsEnabled() {
        return this.memberAccount?.walletFunctions && this.memberAccount?.walletFunctions.indexOf(WalletFunction.CASH_DEPOSIT) >= 0;
    }

    getVoidCheckForMemberAccount() {
        this.paymentService.getVoidCheckForMemberAccount(this.memberAccountId, this.downloadVoidCheckLink, this.memberAccount.member.name, this.memberAccount.accountName);
    }

    isAmountNegative(amount: number) {
        return amount < 0;
    }

    isOutstandingAmountPresent() {
        return this.outstandingCheckAmount > 0;
    }

    isMember() {
        return this.memberAccount?.member?.memberType === MemberType.BUSINESS;
    }

    isFeeAccount() {
        return this.memberAccount?.member?.memberType === MemberType.FEE_ACCOUNT;
    }

    initBankingIdentification() {
        if (Configuration.getConfig().accountIdsRequired && Configuration.getConfig().bankingIntegrationEnabled) {
            this.bankingIdentificationForm = this.bankingIdentificationFormService.initializeForm(this.customerInfo, this.memberAccount);
            this.bankingIdentificationForm.disable();
        }
    }

    updateBankingId() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            customerInfo: this.bankingIdentificationFormService.getCustomerInfo(this.bankingIdentificationForm),
            memberAccount: this.memberAccount
        };
        const dialog = this.dialog.open(BankingIdentificationModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((customerInfo: CustomerInfo) => {
            if (customerInfo) {
                this.customerInfo = customerInfo;
                this.initForms();
                this.bankingInfoUpdated.emit(customerInfo);
            }
        });
    }
}
