import { Component, OnInit, Inject } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    CustomValidators, MemberAccount, Transaction, TransactionSource, TransactionType, WorkflowService
} from 'projects/services/src/public-api';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { LoaderComponent } from '../../loader';

@Component({
    selector: 'pc-transfer-funds-modal',
    templateUrl: './transfer-funds-modal.component.html'
})
export class TransferFundsModalComponent extends BaseModalComponent<TransferFundsModalComponent> implements OnInit {

    activeBusinessAccounts: MemberAccount[] = [];
    availableTargetAccounts: MemberAccount[] = [];

    transferFundsForm: UntypedFormGroup;
    sourceMemberAccount: MemberAccount;
    targetMemberAccount: MemberAccount;
    transferAmountValid = true;


    constructor(private workflowService: WorkflowService,
                private notifier: NotificationService,
                private overlay: Overlay,
                private formBuilder: UntypedFormBuilder,
                dialogRef: MatDialogRef<TransferFundsModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.activeBusinessAccounts = data.activeBusinessAccounts;
    }

    ngOnInit() {
        this.onSubmit = this.onSubmit.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.transferFundsForm = this.formBuilder.group({
            sourceAccountIdCtrl: new UntypedFormControl('', [Validators.required]),
            targetAccountIdCtrl: new UntypedFormControl('', [Validators.required]),
            amountCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.onlyTwoDecimals, Validators.min(0.01)])
        });
        this.transferFundsForm.controls['targetAccountIdCtrl'].disable();
        this.transferFundsForm.controls['amountCtrl'].disable();
    }

    close() {
        super.close();
    }

    onSubmit() {
        const transaction = new Transaction();
        transaction.submissionReference = uuidv4();
        transaction.payorMemberId = this.sourceMemberAccount.memberId;
        transaction.payorAccountId = this.sourceMemberAccount.id;
        transaction.recipientMemberId = this.targetMemberAccount.memberId;
        transaction.recipientAccountId = this.targetMemberAccount.id;
        transaction.totalAmount = Number(this.transferFundsForm.controls['amountCtrl'].value);
        transaction.source = TransactionSource.NETWORK_TRANSACTION;
        transaction.transactionType = TransactionType.WALLET_TO_WALLET_PAYMENT;
        transaction.transactionDate = null;
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        componentRef.instance.title = 'Submitting transfer...';

        this.workflowService.makePayment(transaction).subscribe((result: Transaction) => {
            super.close(true);
            overlayRef.dispose();
            this.notifier.showSuccess(`A transfer in the amount of <b>$${transaction.totalAmount}</b> has been submitted.`);
        }, (error: any) => {
            overlayRef.dispose();
            if (error.status === 400) {
                super.close(true);
            }
            throw error;
        });
    }

    onSelectSourceAccount(sourceAccount: MemberAccount) {
        if (sourceAccount.id) {
            this.sourceMemberAccount = sourceAccount;
            this.availableTargetAccounts = this.activeBusinessAccounts.filter((availableAccount) => {
                return availableAccount.id !== sourceAccount.id;
            });
            this.transferFundsForm.controls['targetAccountIdCtrl'].enable();
            this.transferFundsForm.controls['amountCtrl'].setValue('');
            this.transferFundsForm.controls['amountCtrl'].enable();
        } else {
            this.sourceMemberAccount = null;
            this.targetMemberAccount = null;
            this.availableTargetAccounts = [];
            this.transferFundsForm.controls['targetAccountIdCtrl'].disable();
            this.transferFundsForm.controls['amountCtrl'].setValue('');
            this.transferFundsForm.controls['amountCtrl'].disable();
        }
    }

    onSelectTargetAccount(targetAccount: MemberAccount) {
        if (targetAccount.id) {
            this.targetMemberAccount = targetAccount;
        } else {
            this.targetMemberAccount = null;
        }
    }

    onAmountChange(amount: string) {
        this.transferAmountValid = this.sourceMemberAccount && Number(amount) <= Number(this.sourceMemberAccount.wallet.availableBalance);
        if (!this.transferAmountValid) {
            this.transferFundsForm.controls['amountCtrl'].setErrors({'incorrect': this.transferAmountValid});
        } else {
            this.transferFundsForm.controls['amountCtrl'].setErrors(null);
        }
    }

    isFormValid() {
        return this.sourceMemberAccount && this.targetMemberAccount && this.transferFundsForm.valid
            && this.transferAmountValid && Number(this.transferFundsForm.controls['amountCtrl'].value) > 0;
    }
}
