import { Component, Input, OnInit } from '@angular/core';
import { Address, AddressService } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-business-address',
    templateUrl: './business-address.component.html'
})
export class BusinessAddressComponent implements OnInit {

    @Input() address: Address;
    @Input() addressId: string;

    constructor(private addressService: AddressService) {
    }

    ngOnInit() {
        if (!this.address) {
            if (this.addressId) {
                this.addressService.loadAddress(this.addressId).subscribe((address: Address) => {
                    this.address = address;
                });
            }
        }
    }
}
