import { Upload } from './upload';

export class License {

    id: string;
    created: Date;

    memberId: string;

    jurisdiction: string;
    regulatoryBody: string;
    type: string;
    segment: string;
    classification: string;

    licenseNo: string;
    license: string;
    holderName: string;
    note: string;

    documentId: string;
    document: Upload;
    startDate: Date;
    endDate: Date;
    expirationDate: Date;
    status: LicenseStatus;
}


export enum LicenseStatus {
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
    EXPIRED = 'EXPIRED'
}
