<div>
    <pt-active-key-party-list
            [memberRegistrationType]="memberRegistrationType"
            [memberId]="memberId"
            [readOnly]="readOnly"
            [lenderViewer]="lenderViewer"
            [allowAdd]="allowAdd"
            [memberType]="memberType"
            [memberStatus]="memberStatus"
            [changeTrigger]="changeTrigger"
            (keyPartiesUpdated)="keyPartiesUpdated()"
            (keyPartyDeleted)="onKeyPartyChange()">
    </pt-active-key-party-list>

    <br/>

    <pt-historical-key-party-list
            [changeTrigger]="changeTrigger"
            [memberType]="memberType"
            [memberId]="memberId"
            (keyPartyAdded)="onKeyPartyChange()">
    </pt-historical-key-party-list>

    <div *ngIf="!readOnly" class="button-panel pull-right">
        <pc-submit-button  [submit]="onNavigate" [isSubmitButton]="true" [text]="'Next'" [rightIcon]="'arrow-right'" *ngIf="task"></pc-submit-button>
    </div>
</div>
