<div>
    <div class="modal-header sticky-top">
        <label class="form-title">Watchlist Search Results</label>
        <i class="modal-close" (click)="close()">&times;</i>
        <div class="float-end px-3">
            <pc-watchlist-badge [parent]="watchlistEntity"></pc-watchlist-badge>
        </div>
    </div>
    <div class="modal-content">
        <div class="tab-row" *ngIf="watchlistEntity.userNotes.length > 0">
            <div [ngClass]="getTabClass('WATCHLIST_DETAILS')" (click)="setActiveTab('WATCHLIST_DETAILS')">
                Watchlist Details
            </div>
            <div [ngClass]="getTabClass('HISTORICAL_NOTES')" (click)="setActiveTab('HISTORICAL_NOTES')">
                Historical Notes
            </div>
        </div>
        <div class="row" *ngIf="activeTab === 'WATCHLIST_DETAILS'">
            <div class="col-12">
                <table mat-table [dataSource]="source">
                    <ng-container matColumnDef="data_name">
                        <th mat-header-cell *matHeaderCellDef class="none"></th>
                        <td mat-cell *matCellDef="let row"> <b>{{ row.dataName }}</b> </td>
                    </ng-container>
                    <ng-container matColumnDef="watchlist_data">
                        <th mat-header-cell *matHeaderCellDef class="half"> Watchlist Entry </th>
                        <td mat-cell *matCellDef="let row"> <div [innerHtml]="row.watchlistData"></div> </td>
                    </ng-container>
                    <ng-container matColumnDef="remarks_data">
                        <th mat-header-cell *matHeaderCellDef class="half"> Watchlist Entry </th>
                        <td colspan="2" mat-cell *matCellDef="let row"> <div [innerHtml]="row.watchlistData"></div> </td>
                    </ng-container>
                    <ng-container matColumnDef="member_data">
                        <th mat-header-cell *matHeaderCellDef class="half"> {{ getWatchlistTitleOrType(true) }} </th>
                        <td mat-cell *matCellDef="let row"> <div [innerHtml]="row.memberData"></div> </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns; when isNotRemarks;"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['data_name', 'remarks_data']; when isRemarks;"></tr>
                </table>
            </div>
        </div>
        <div class="row" *ngIf="activeTab === 'HISTORICAL_NOTES'">
            <div class="col-12">
                <table mat-table [dataSource]="watchlistEntity.userNotes">
                    <ng-container matColumnDef="historical_note">
                        <th mat-header-cell *matHeaderCellDef class="w-100">Note</th>
                        <td mat-cell *matCellDef="let row">{{ row.notes }} </td>
                    </ng-container>
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef class="centered"> Date </th>
                        <td mat-cell *matCellDef="let row" class="centered">
                            <pc-date-time [date]="row.created"></pc-date-time>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef class="centered"> Status </th>
                        <td mat-cell *matCellDef="let row" class="centered">
                            <pc-watchlist-badge [parent]="row"></pc-watchlist-badge>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="user">
                        <th mat-header-cell *matHeaderCellDef class="centered"> User </th>
                        <td mat-cell *matCellDef="let row"> <span class="text-nowrap">{{row.userName}} </span></td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="['historical_note', 'status', 'date', 'user']"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['historical_note', 'status', 'date', 'user'];"></tr>
                </table>
            </div>
        </div>

    </div>
    <div class="modal-footer sticky-bottom" *ngIf="activeTab === 'WATCHLIST_DETAILS'">
        <div class="my-3 col-12" *ngIf="watchlistEntity.userNotes.length && !closingStatus && activeTab === 'WATCHLIST_DETAILS' && authService.isAuthority()">
            <div>
                <strong>Last Note: </strong> {{ watchlistEntity.userNotes[0].notes }}
            </div>
        </div>
        <div class="my-3 col-12" *ngIf="closingStatus">
            <div class="mb-2" *ngIf="watchlistEntity.userNotes.length">
                <strong>Previous Note: </strong> {{ watchlistEntity.userNotes[0].notes }}
            </div>
            <pc-text-area [class]="'full'" [required]="true" [label]="closingStatus === WatchlistStatus.MATCHED ? 'Notes for Matching hit' : 'Notes for Cleared hit'" [placeholder]="watchlistEntity.userNotes.length ? 'Enter updated notes' : 'Enter notes'" [formCtrl]="'notesCtrl'" [formGroup]="watchlistForm">
            </pc-text-area>
        </div>
        <div class="mb-2 col-12" *ngIf="authService.isAuthority()">
            <button pc-no-dbl-click class="blue-button pull-right" type="button" *ngIf="!closingStatus && watchlistEntity.status !== WatchlistStatus.CLEARED && watchlistEntity.status !== WatchlistStatus.AUTO_CLEARED" (click)="clearHit()" submit-button>Clear Hit</button>
            <button pc-no-dbl-click class="blue-button pull-right" type="button" *ngIf="!closingStatus && watchlistEntity.status !== WatchlistStatus.MATCHED" (click)="confirmHit()" submit-button>Confirm Possible Match</button>
            <button pc-no-dbl-click class="blue-button pull-right" type="button" *ngIf="closingStatus" (click)="changeHitStatus()" [disabled]="!formValid()" submit-button>{{closingStatus === WatchlistStatus.MATCHED ? 'Confirm Hit' : 'Clear Hit'}}</button>
            <button pc-no-dbl-click class="blue-button pull-right" type="button" *ngIf="closingStatus" (click)="cancelEdit()" submit-button>Cancel</button>
        </div>
    </div>
    <div class="modal-footer sticky-bottom" *ngIf="activeTab === 'HISTORICAL_NOTES' && authService.isAuthority()">
        <div class="mb-2 col-12">
            <button pc-no-dbl-click class="blue-button pull-right" type="button" (click)="close()" submit-button>Cancel</button>
        </div>
    </div>
</div>
