<fieldset class="form-group" *ngIf="formCtrl">
    <div class="row">
        <div class="col-12">
            <label class="form-label">{{ label | translate }}</label>
        </div>
        <div class="col-12" [formGroup]="formGroup">
            <input #zipCodeField class="form-control form-input-editable" type="text" [formControlName]="formCtrl" [required]="required" (change)="onChange()"
                (keydown)="onInputChanged($event)" (blur)="onBlur($event)"/>
        </div>
    </div>
</fieldset>
