import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators, MemberAccount, MemberAccountService, MemberService, NameChangeType } from 'projects/services/src/public-api';
import { ErrorType } from 'projects/components/src/public-api';

@Component({
    selector: 'pc-name-change-modal',
    templateUrl: './name-change-modal.component.html'
})
export class NameChangeModalComponent extends BaseModalComponent<NameChangeModalComponent> implements OnInit {

    title: string;
    currentName: string;
    id: string;
    type: NameChangeType;
    label: string;
    warning: { message: string, type: ErrorType };
    formGroup: UntypedFormGroup;
    updating = false;

    constructor(dialogRef: MatDialogRef<NameChangeModalComponent>,
                private memberService: MemberService,
                private memberAccountService: MemberAccountService,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.title = data.title;
        this.currentName = data.currentName;
        this.id = data.id;
        this.type = data.type;
        switch (this.type) {
            case NameChangeType.MEMBER_NAME:
                this.label = 'Enter New Member Name';
                break;
            case NameChangeType.ACCOUNT_NAME:
                this.label = 'Enter New Account Name';
                this.warning = {
                    type: ErrorType.WARN,
                    message: 'The account name must be unique across all accounts for this member.<br>This is only for use by the member, and is not visible to other members.'
                };
                break;
            case NameChangeType.ACCOUNT_FRIENDLY_NAME:
                this.label = 'Enter New Friendly Account Name';
                this.warning = {
                    type: ErrorType.WARN,
                    message: 'The friendly name must be unique across all accounts for this member.<br>This is the value that other members will see when transacting with this member.'
                };
                break;
        }
    }

    ngOnInit() {
        this.formGroup = new UntypedFormGroup({
            nameCtrl: new UntypedFormControl(this.currentName, [Validators.required, CustomValidators.memberName, Validators.minLength(3), Validators.maxLength(64)])
        });
        this.confirm = this.confirm.bind(this);
        this.close = this.close.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.notUpdating = this.notUpdating.bind(this);
    }

    confirm(reset: any) {
        this.updating = true;
        const newName = this.formGroup.get('nameCtrl').value.trim();
        const memberNameChangeRequest = {
            memberId: this.id,
            newName: newName
        };
        if (this.type === NameChangeType.MEMBER_NAME) {
            this.memberService.updateMemberName(memberNameChangeRequest).subscribe(() => {
                this.updating = false;
                super.close({value: newName});
            }, (error: any) => {
                this.updating = false;
                reset();
                throw error;
            });
        } else if (this.type === NameChangeType.ACCOUNT_NAME) {
            const body = {
                accountName: this.formGroup.controls['nameCtrl'].value
            };

            this.memberAccountService.updateMemberAccount(this.id, body).subscribe((memberAccount: MemberAccount) => {
                this.updating = false;
                super.close(memberAccount);
            }, (error: any) => {
                this.updating = false;
                reset();
                throw error;
            });
        } else if (this.type === NameChangeType.ACCOUNT_FRIENDLY_NAME) {
            const body = {
                accountFriendlyName: this.formGroup.controls['nameCtrl'].value
            };

            this.memberAccountService.updateMemberAccount(this.id, body).subscribe((memberAccount: MemberAccount) => {
                this.updating = false;
                super.close(memberAccount);
            }, (error: any) => {
                this.updating = false;
                reset();
                throw error;
            });
        }
    }

    close() {
        if (this.notUpdating()) {
            super.close();
        }
    }

    isFormValid() {
        return this.formGroup.valid && this.currentName !== this.formGroup.get('nameCtrl').value.trim();
    }

    notUpdating() {
        return !this.updating;
    }
}
