import { Component, Input } from '@angular/core';

@Component({
    selector: 'pt-home',
    templateUrl: './home.component.html'
})
export class HomeComponent {

    @Input() expandedMenu = true;

    onToggleExpandSideMenu() {
        setTimeout(() => {
            this.expandedMenu = !this.expandedMenu;
        }, 0);
    }

    getWorkSpaceClass() {
        if (this.expandedMenu) {
            return 'workspace-narrow';
        } else {
            return 'workspace-wide';
        }
    }
}
