import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class QrCodeService {

    baseUrl: string;
    apiEndpoint: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.DataroomAPIEndpoint + '/';
        this.apiEndpoint = environment.APIEndpoint;
    }

    createQrCodeFromFragment(urlFragment: string) {
        const url = new URL(`${this.apiEndpoint}${urlFragment}`);
        const params = `?url=${url.href}`;
        return this.http.getDownload(`${this.baseUrl}qr_code${params}`);
    }

    createQrCodeFromUrl(url: string) {
        const params = `?url=${encodeURIComponent(url)}`;
        return this.http.getDownload(`${this.baseUrl}qr_code${params}`);
    }

}
