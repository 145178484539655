import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
    Bill, BusinessClient, DocumentType, Invoice, LabelledPackage, ManifestSelection, MemberAccount, PagedResponse, RecordsService, Shipment,
    SupportingDocument, TransactionSubType, Utils } from 'projects/services/src/public-api';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTable } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'pt-tracking-selection-step',
    templateUrl: './tracking-selection-step.component.html',
    styleUrls: ['./tracking-selection-step.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class TrackingSelectionStepComponent implements OnInit, OnChanges {

    TransactionSubType = TransactionSubType;

    @Input() memberAccount: MemberAccount;
    @Input() currentBusinessClient: BusinessClient<Invoice | Bill>;
    @Input() selectedManifests: ManifestSelection[] = [];
    @Input() manifestSupportingDocs: SupportingDocument[] = [];
    @Input() changeTrigger: number;
    @Input() isRecipient = true;
    @Input() subType: TransactionSubType;
    @Input() formGroup: UntypedFormGroup;

    @Output() manifestSelected: EventEmitter<ManifestSelection> = new EventEmitter<ManifestSelection>();
    @Output() manifestDocumentUploaded: EventEmitter<SupportingDocument> = new EventEmitter<SupportingDocument>();
    @Output() nextStep: EventEmitter<null> = new EventEmitter<null>();
    @Output() closeTransactionModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    manifestsLoading = true;
    customerManifests: ManifestSelection[] = [];

    manifestColumns: string[] = ['manifest_number', 'supplier', 'recipient', 'created', 'received_date', 'package_count', 'included'];

    pageSize = 10;
    pageIndex = 0;
    sortDir = 'DESC';
    resultsLength = 0;
    sort = 'receivedDateTime';
    isDefaultClient = false;

    expandedElement: ManifestSelection;
    counterPartyFormGroup: UntypedFormGroup;
    counterParties: string[] = [];
    placeholder: string;
    source: string;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('manifestUpload') private manifestUploadLink: ElementRef;
    @ViewChild('trackingManifestTable') table: MatTable<any>;

    constructor(private recordsService: RecordsService,
                private formBuilder: UntypedFormBuilder) { }

    ngOnInit() {
        this.next = this.next.bind(this);

        this.placeholder = this.subType === TransactionSubType.DEPOSIT ? 'Select Recipient ' : 'Select Sender';
        this.source = this.subType === TransactionSubType.DEPOSIT ? 'Metrc Outgoing' : 'Metrc Incoming';

        this.loadTrackingCounterParties();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.currentBusinessClient && !changes.currentBusinessClient.firstChange) ||
            (changes.changeTrigger && !changes.changeTrigger.firstChange)) {
            if (changes.currentBusinessClient) {
                this.counterPartySelected(changes.currentBusinessClient.currentValue.name);
            } else {
                this.counterPartySelected(this.currentBusinessClient.name);
            }
        }
    }

    loadTrackingCounterParties() {
        const currentBusinessClientName = this.currentBusinessClient.name;
        this.recordsService.counterPartiesByMemberId(this.memberAccount.id, this.source).subscribe((counterParties: string[]) => {
            this.counterParties = counterParties || [];
            this.counterParties = this.counterParties.filter((counterParty) => {
                return !!counterParty;
            }).sort((a, b) => {
                return a?.toLowerCase() < b?.toLowerCase() ? -1 : 1;
            });

            if (this.counterParties && !this.counterParties.includes(currentBusinessClientName)) {
                this.counterParties.unshift(currentBusinessClientName);
            }

            this.counterPartyFormGroup = this.formBuilder.group({
                trackingCounterPartyCtrl: new UntypedFormControl(currentBusinessClientName)
            });

            this.counterPartySelected(currentBusinessClientName);
        });
    }

    counterPartySelected(counterParty: string) {
        this.resetManifestDetails();
        this.isDefaultClient = counterParty === this.currentBusinessClient.name;
        if (counterParty) {
            this.loadManifests();
        }
    }

    resetManifestDetails() {
        // After loading the counter party resetting the existing manifests and updating the validations.
        this.customerManifests = [];
        this.pageIndex = 0;
    }

    loadManifests() {
        if (this.isDefaultClient) {
            this.loadMatchingManifests();
        } else {
            this.loadCounterPartyMatchingManifests();
        }
    }

    // Loading the matching unused manifests by selected counter party.
    loadCounterPartyMatchingManifests() {
        this.isDefaultClient = false;
        this.manifestsLoading = true;
        this.customerManifests = [];
        this.recordsService.findShipmentsByOwner(this.memberAccount.id,
            this.source,
            this.counterPartyFormGroup.controls['trackingCounterPartyCtrl'].value,
            '',
            '',
            true,
            this.pageIndex,
            this.pageSize,
            this.sort,
            this.sortDir).subscribe((eligibleManifests: PagedResponse<Shipment>) => {
            this.loadEligibleManifests(eligibleManifests);
        });
    }

    loadMatchingManifests() {
        this.isDefaultClient = true;
        this.manifestsLoading = true;
        this.customerManifests = [];

        if (this.isRecipient) {
            this.recordsService.findUnusedShipmentsByOwnerAndRecipient(this.memberAccount.memberId,
                this.currentBusinessClient.memberId,
                this.currentBusinessClient.name,
                this.pageIndex,
                this.pageSize,
                this.sort,
                this.sortDir).subscribe((eligibleManifests: PagedResponse<Shipment>) => {
                this.loadEligibleManifests(eligibleManifests);
            }, (error) => {
                if (error.status === 404) {
                    this.closeTransactionModal.emit(true);
                    throw new Error(`The counter party you have selected has been modified or renamed. Please try re-selecting the correct counter party and try your ${ this.subType === TransactionSubType.PAYMENT ? 'payment' : 'deposit' } again.`);
                }
                throw error;
            });
        } else {
            this.recordsService.findUnusedShipmentsByOwnerAndShipper(this.memberAccount.memberId,
                this.currentBusinessClient.memberId,
                this.currentBusinessClient.name,
                this.pageIndex,
                this.pageSize,
                this.sort,
                this.sortDir).subscribe((eligibleManifests: PagedResponse<Shipment>) => {
                this.loadEligibleManifests(eligibleManifests);
            }, (error) => {
                if (error.status === 404) {
                    this.closeTransactionModal.emit(true);
                    throw new Error(`The counter party you have selected has been modified or renamed. Please try re-selecting the correct counter party and try your ${ this.subType === TransactionSubType.PAYMENT ? 'payment' : 'deposit' } again.`);
                }
                throw error;
            });
        }
    }

    loadEligibleManifests(eligibleManifests: PagedResponse<Shipment>) {
        this.resultsLength = eligibleManifests.totalElements;
        this.manifestsLoading = false;
        for (const manifest of eligibleManifests.content) {
            const manifestEntry = new ManifestSelection();
            manifestEntry.data = manifest;
            if (this.selectedManifests.find((selectedManifest: ManifestSelection) => {
                return manifest.id === selectedManifest.data.id;
            })) {
                manifestEntry.included = true;
            }
            this.loadPackageData(manifestEntry);
            this.customerManifests.push(manifestEntry);
        }
    }

    loadPackageData(manifest: ManifestSelection) {
        this.recordsService.getPackagesForShipment(manifest.data.id, 0, 1000, 'created', this.sortDir).subscribe((packages: PagedResponse<LabelledPackage>) => {
            manifest.data.lineItems = packages.content;
        });
    }

    addManifestSupportingDocument() {
        const link: HTMLElement = this.manifestUploadLink.nativeElement;
        link.click();
    }

    removeManifestSupportingDocument(index: number) {
        this.manifestSupportingDocs.splice(index, 1);
    }

    onSelected(selectedManifest: ManifestSelection) {
        selectedManifest.included = !selectedManifest.included;
        this.manifestSelected.emit(selectedManifest);
    }

    next() {
        this.nextStep.emit();
    }

    public handlePageEvent(event: PageEvent) {
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.loadManifests();
    }

    isCounterPartySelected() {
        return this.counterPartyFormGroup.controls['trackingCounterPartyCtrl'].value;
    }

    selectManifestFile(event: any) {
        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            Utils.validateFile(file, event, true);
            const supportDoc = new SupportingDocument();
            supportDoc.file = file;
            supportDoc.explanation = 'Manifest Support Document';
            supportDoc.documentType = DocumentType.SEEDTOSALE_TRACK_MANIFEST;
            this.manifestDocumentUploaded.emit(supportDoc);
        }
        event.target.value = '';
    }
}
