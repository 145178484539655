import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { KycSearchResultModalComponent } from 'projects/components/src/lib/kyc/kyc-search-result-modal/kyc-search-result-modal.component';
import { KeyParty, SearchResult } from 'projects/services/src/public-api';


@Component({
    selector: 'pc-kyc-search-result-button',
    templateUrl: './kyc-search-result-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KycSearchResultButtonComponent {

    @Input() searchResult: SearchResult;
    @Input() keyParty: KeyParty;

    @Output() updated: EventEmitter<SearchResult> = new EventEmitter<SearchResult>();

    constructor(private dialog: MatDialog) {}

    onClick() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            searchResult: this.searchResult,
            keyParty: this.keyParty
        };

        const dialog = this.dialog.open(KycSearchResultModalComponent, dialogConfig);
        dialog.afterClosed().subscribe((result) => {
            return this.onModalClosed(result);
        });
    }

    onModalClosed(result: any) {
        switch (result?.action) {
            case 'COMPLETE': {
                this.updated.emit(result.search);
                break;
            }
            default:
                break;
        }
    }
}
