<div [ngSwitch]="metadata?.type" *ngIf="metadata?.visible">
    <pc-sc-textinput *ngSwitchCase="FieldDataType.TEXTINPUT" [formGroup]="formGroup" [metadata]="metadata" (changed)="onChanged($event)" [enforceRequired]="enforceRequired">
        render text input
    </pc-sc-textinput>
    <pc-sc-textinput *ngSwitchCase="FieldDataType.LINK" [formGroup]="formGroup" [metadata]="metadata" (changed)="onChanged($event)" [enforceRequired]="enforceRequired">
        render link
    </pc-sc-textinput>
    <pc-sc-textarea *ngSwitchCase="FieldDataType.TEXTAREA" [formGroup]="formGroup" [metadata]="metadata" (changed)="onChanged($event)" [enforceRequired]="enforceRequired">
        render text area
    </pc-sc-textarea>
    <pc-sc-checkbox *ngSwitchCase="FieldDataType.CHECKBOX" [formGroup]="formGroup" [metadata]="metadata" (changed)="onChanged($event)">
        render checkbox input
    </pc-sc-checkbox>
    <pc-sc-date *ngSwitchCase="FieldDataType.DATE" [formGroup]="formGroup" [metadata]="metadata" (changed)="onChanged($event)" [enforceRequired]="enforceRequired">
        render date input
    </pc-sc-date>
    <pc-sc-number *ngSwitchCase="FieldDataType.NUMBER" [formGroup]="formGroup" [metadata]="metadata" (changed)="onChanged($event)" [enforceRequired]="enforceRequired">
        render number input
    </pc-sc-number>
    <pc-sc-enumerated-select *ngSwitchCase="FieldDataType.ENUMERATED" [formGroup]="formGroup" [metadata]="metadata" (changed)="onChanged($event)" [enforceRequired]="enforceRequired">
        render number input
    </pc-sc-enumerated-select>
</div>
