import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { IMyDateModel } from 'angular-mydatepicker';
import { BalanceSheetReport, LenderService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-balance',
    templateUrl: './member-balance.component.html'
})
export class MemberBalanceComponent implements OnInit {

    @Input() memberAccountId: string;

    formGroup: UntypedFormGroup;
    balanceReports: BalanceSheetReport[];
    isAssetsShow = false;
    isLiabilitiesShow = false;
    isEquityShow = false;
    startMonth: string;
    periodLength = 12;
    periodsToCompare = 2;


    constructor(private lenderService: LenderService,
                private formBuilder: UntypedFormBuilder,
                private datePipe: DatePipe) {}

    ngOnInit() {
        const currentDate = new Date();
        this.startMonth = this.datePipe.transform(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'yyyy-MM-dd');

        this.formGroup =  this.formBuilder.group({
            dateCtrl : new UntypedFormControl()
        });
        this.getCodatData();
    }

    getCodatData() {
        this.lenderService.getBalanceSheet(this.memberAccountId, this.startMonth, this.periodLength, this.periodsToCompare)
            .subscribe((data: any) => {
                this.balanceReports = data.reports;
            });
    }

    onDateChanged(event: IMyDateModel) {
        if (event) {
            this.startMonth = this.datePipe.transform(event.singleDate.jsDate, 'yyyy-MM-dd');
            this.getCodatData();
        } else {
            this.startMonth = '';
        }
    }

    changedPeriods() {
        this.getCodatData();
    }

    expandAssets() {
        this.isAssetsShow = !this.isAssetsShow;
    }

    expandLiabilities() {
        this.isLiabilitiesShow = !this.isLiabilitiesShow;
    }

    expandEquity() {
        this.isEquityShow = !this.isEquityShow;
    }
}
