<div class="row mt-3">
    <div class="col-3">
        <div class="data-block">
            <div class="block-header text-center">{{kpi[0]?.name}}</div>
            <div class="text-center h5 mt-3">{{kpi[0]?.value}}{{kpi[0]?.percentage ? '%' : ''}}</div>
        </div>
    </div>
    <div class="col-3">
        <div class="data-block">
            <div class="block-header text-center">{{kpi[1]?.name}}</div>
            <div class="text-center h5 mt-3">{{kpi[1]?.value}}{{kpi[1]?.percentage ? '%' : ''}}</div>
        </div>
    </div>
    <div class="col-3">
        <div class="data-block">
            <div class="block-header text-center">{{kpi[2]?.name}}</div>
            <div class="text-center h5 mt-3">{{kpi[2]?.value}}{{kpi[2]?.percentage ? '%' : ''}}</div>
        </div>
    </div>
    <div class="col-3">
        <div class="data-block">
            <div class="block-header text-center">{{kpi[3]?.name}}</div>
            <div class="text-center h5 mt-3">{{kpi[3]?.value}}{{kpi[3]?.percentage ? '%' : ''}}</div>
        </div>
    </div>
</div>

<div class="row mt-3">
    <div class="col-3">
        <div class="data-block">
            <div class="block-header text-center">{{kpi[4]?.name}}</div>
            <div class="text-center h5 mt-3">{{kpi[4]?.value}}{{kpi[4]?.percentage ? '%' : ''}}</div>
        </div>
    </div>
    <div class="col-3">
        <div class="data-block">
            <div class="block-header text-center">{{kpi[5]?.name}}</div>
            <div class="text-center h5 mt-3">{{kpi[5]?.value}}{{kpi[5]?.percentage ? '%' : ''}}</div>
        </div>
    </div>
    <div class="col-3">
        <div class="data-block">
            <div class="block-header text-center">{{kpi[6]?.name}}</div>
            <div class="text-center h5 mt-3">{{kpi[6]?.value}}{{kpi[6]?.percentage ? '%' : ''}}</div>
        </div>
    </div>
</div>
