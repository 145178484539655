<div>
    <form>
        <div>
            <div class="modal-header">
            <label class="form-title">{{title}}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div class="info-message" [innerHTML]="message"></div>
            </div>
            <div>
                <span class="modal-footer">
                    <pc-submit-button [submit]="close" [text]="'Close'"></pc-submit-button>
                </span>
            </div>
        </div>
    </form>
</div>

