<div class="row mb-2">
    <div class="col-4">
        <span class="form-title">{{title}}</span>
    </div>
    <div class="col-sm-8">
        <button *ngIf="hasEditDocumentPrivilege" class="blue-button pull-right" (click)="onAddNewMemberDocument()" type="button"><i class="fa fa-plus pull-left dashboard-icon"></i>
            <div class="pull-right-button">Add Document</div>
        </button>
    </div>
</div>

<div class="row mb-3">
    <div class="d-flex">
        <p>
            <br>
            <i>{{subtitle}}</i>
            <br>
        </p>
    </div>
</div>

<div class="document-table row">
    <div class="col-lg-6" *ngIf="!allOptional">
        <div class="title">Required Documents</div>
        <div class="row">
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.ARTICLES_OF_INCORPORATION)"></i>Articles of Incorporation
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.AUTHORITY_RESOLUTION)"></i>Authority Resolution
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.OPERATING_AGREEMENT_OR_BYLAWS)"></i>Operating Agreement or Bylaws
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.CORPORATE_STRUCTURE)"></i>Corporate Structure including UBOs
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.PASSPORT_OR_STATE_ISSUED_ID)"></i>Key Party’s Passport or State Issued ID
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.EIN_SUPPORTING_EVIDENCE)"></i>Letter from IRS displaying your Employer Identification Number (EIN)
            </div>
            <div class="d-flex" *ngIf="isATMBusiness">
                <i [ngClass]="getIconClass(true, DocumentType.ATM_LIST_AND_LOCATIONS)"></i>
                Listing of all ATMs, locations, and indication if any are in a cannabis retail location
            </div>
            <div class="d-flex" *ngIf="isATMBusiness">
                <i [ngClass]="getIconClass(true, DocumentType.COPY_OF_SITE_OR_LOCATION_CONTRACT)"></i>A copy of the ATMs site / location contracts
            </div>
            <div class="d-flex" *ngIf="isATMBusiness">
                <i [ngClass]="getIconClass(true, DocumentType.CASH_MGNT_POLICY_AND_PROCEDURE)"></i>Cash Management / Handling Policy and Procedure
            </div>
            <div class="d-flex" *ngIf="isATMBusiness">
                <i [ngClass]="getIconClass(true, DocumentType.FLOW_OF_FUNDS_DESC)"></i>Flow of Funds description / Explanation of ATM funding procedure
            </div>
        </div>
    </div>
    <div class="col-lg-6" *ngIf="!allOptional">
        <div class="title">Optional Documents</div>
        <div class="row">
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.BUSINESS_PLAN)"></i>Business Plan
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.CAPITALIZATION_TABLE)"></i>Capitalization Table
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.SHARE_REGISTRY)"></i>Share Registry
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.AR_AGING_REPORT)"></i>Current A/R Aging Report
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.FINANCIAL_STATEMENTS)"></i>Financial Statements
            </div>
        </div>
        <div class="col-md-6">
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.FINANCIAL_PROJECTIONS)"></i>Financial Projections
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.TAX_RETURN)"></i>Tax Return
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.RECENT_BANK_STATEMENT)"></i>Recent Bank Statement
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.SAMPLE_ACH_INFO)"></i>Sample ACH Info
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.OTHER_CORPORATE_DOCUMENT)"></i>Other Document
            </div>
        </div>
    </div>
</div>
<div class="col-lg-12" *ngIf="allOptional">
    <div class="title">Optional Documents</div>
    <div class="row">
        <div class="col-md-4">
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.ARTICLES_OF_INCORPORATION)"></i>Articles of Incorporation
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.AUTHORITY_RESOLUTION)"></i>Authority Resolution
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.OPERATING_AGREEMENT_OR_BYLAWS)"></i>Operating Agreement or Bylaws
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.CORPORATE_STRUCTURE)"></i>Corporate Structure including UBOs
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.PASSPORT_OR_STATE_ISSUED_ID)"></i>Key Party’s Passport or State Issued ID
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.EIN_SUPPORTING_EVIDENCE)"></i>Letter from IRS displaying your Employer Identification Number (EIN)
            </div>
        </div>
        <div class="col-md-4">
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.BUSINESS_PLAN)"></i>Business Plan
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.CAPITALIZATION_TABLE)"></i>Capitalization Table
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.SHARE_REGISTRY)"></i>Share Registry
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.AR_AGING_REPORT)"></i>Current A/R Aging Report
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.FINANCIAL_STATEMENTS)"></i>Financial Statements
            </div>
        </div>
        <div class="col-md-4">
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.FINANCIAL_PROJECTIONS)"></i>Financial Projections
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.TAX_RETURN)"></i>Tax Return
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.RECENT_BANK_STATEMENT)"></i>Recent Bank Statement
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.SAMPLE_ACH_INFO)"></i>Sample ACH Info
            </div>
            <div class="d-flex">
                <i [ngClass]="getIconClass(true, DocumentType.OTHER_CORPORATE_DOCUMENT)"></i>Other Document
            </div>
        </div>
    </div>
</div>
<div class="col-sm-12 horizontal-scroll mat-table-container pt-4 pb-2">
    <table mat-table #documentsTable [dataSource]="documents">
        <ng-container matColumnDef="document_type">
            <th mat-header-cell *matHeaderCellDef> Document Type</th>
            <td mat-cell *matCellDef="let element">
                {{element.documentType | translate}}
            </td>
        </ng-container>
        <ng-container matColumnDef="file">
            <th mat-header-cell *matHeaderCellDef> File</th>
            <td mat-cell *matCellDef="let element">
                <a *ngIf="element" class="dashboard-list-a" (click)="downloadFile(element)">
                    <i class="fa fa-paperclip"></i>{{element.fileName}}
                </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="date_added">
            <th class="centered" mat-header-cell *matHeaderCellDef> Date Added</th>
            <td class="centered" mat-cell *matCellDef="let element">
                <pc-date-time [date]="element.created"></pc-date-time>
            </td>
        </ng-container>
        <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef> Details</th>
            <td mat-cell *matCellDef="let element">
                {{ element.explanation }}
            </td>
        </ng-container>
        <ng-container matColumnDef="action">
            <th class="centered action" mat-header-cell *matHeaderCellDef> Action</th>
            <td class="centered action" mat-cell *matCellDef="let element">
                <i class="fa fa-times" (click)="deleteDocument(element.id)"></i>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<a class="document-download-link" #downloadDocumentLink></a>
<div class="row mt-3">
    <div class="col-12">
        <i>Here's an example template of an <a href="/static/Authorizing_Resolution_Form.docx" target="_blank" rel="noopener noreferrer">Authority Resolution</a></i>
    </div>
    <div class="col-12">
        <i> Here is an example of a <a href="/static/Corporate_Structure_UBO_Example.pdf" target="_blank" rel="noopener noreferrer">Corporate Structure</a></i>
    </div>
</div>
<div class="row mt-3" *ngIf="authService.isAuthorityOrReviewer()">
    <span class="form-title">Historical Documents</span>
    <div class="col-sm-12 horizontal-scroll mat-table-container pt-4 pb-2">
        <table mat-table #historicalDocTable [dataSource]="historicalDocuments">
            <ng-container matColumnDef="document_type">
                <th mat-header-cell *matHeaderCellDef> Document Type</th>
                <td mat-cell *matCellDef="let element">
                    {{element.documentType | translate}}
                </td>
            </ng-container>
            <ng-container matColumnDef="file">
                <th mat-header-cell *matHeaderCellDef> File</th>
                <td mat-cell *matCellDef="let element">
                    <a *ngIf="element" class="dashboard-list-a" (click)="downloadFile(element)">
                        <i class="fa fa-paperclip"></i>{{element.fileName}}
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="date_added">
                <th class="centered" mat-header-cell *matHeaderCellDef> Date Added</th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <pc-date-time [date]="element.created"></pc-date-time>
                </td>
            </ng-container>
            <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef> Details</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.explanation }}
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th class="centered action" mat-header-cell *matHeaderCellDef> Action</th>
                <td class="centered action" mat-cell *matCellDef="let element">
                    <i class="fa fa-times" (click)="deleteDocument(element.id)"></i>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedHistoricalColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedHistoricalColumns;" [ngClass]="'disabled'"></tr>
        </table>
    </div>
</div>
<div class="row mb-3">
    <div class="col-12">&nbsp;</div>
</div>
<div class="button-panel pull-right">
    <pc-submit-button  [submit]="onNavigate" [isSubmitButton]="true" [text]="'Next'" [rightIcon]="'arrow-right'" *ngIf="task"></pc-submit-button>
</div>
