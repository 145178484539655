<div class="col-12 dashboard-info">
    <div class="row">
        <div class="col-6 dashboard-header">
            {{contractTemplate?.name}} (as {{getViewType() | translate}})
        </div>
        <div class="col-6">
            <button class="blue-button pull-right"  (click)="onMyTemplates()" type="button"><i class="fa fa-arrow-left"></i>Return to My Templates</button>
        </div>
    </div>
    <div *ngIf="contractTemplate" class="row wizard">
        <div class="col-2 dashboard-header">
            <div class="tab-col pull-left">
                <div [ngClass]="getTabClass('PREVIEW_TEMPLATE')" (click)="setActiveTab('PREVIEW_TEMPLATE')">
                    Preview Template
                </div>
                <div [ngClass]="getTabClass('SHARE_TEMPLATE')" (click)="setActiveTab('SHARE_TEMPLATE')">
                    Share Template
                </div>
                <div [ngClass]="getTabClass('DELETE_TEMPLATE')" (click)="deleteTemplate()">
                    Delete Template
                </div>
            </div>
        </div>
        <div class="col-10">
            <div *ngIf="activeTab === 'PREVIEW_TEMPLATE'">
                <pc-sc-section *ngFor="let section of contractTemplate.sections; let i = index" [contractTemplate]="contractTemplate" [index]="i" [formGroup]="formGroup">
                </pc-sc-section>
            </div>
            <div class="col-12 tab-body" *ngIf="activeTab === 'SHARE_TEMPLATE'">
                <pc-sc-share-template [contractTemplate]="contractTemplate"></pc-sc-share-template>
            </div>
        </div>
    </div>
</div>