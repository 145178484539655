import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AuthService, MetrcLicense, MetrcStatus, MetrcService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-metrc-account-details',
    templateUrl: './metrc-account-details.component.html'
})
export class MetrcAccountDetailsComponent implements OnInit, OnChanges {

    @Input() memberId = '';
    @Input() changeTrigger: number;

    @Output() metrcDeactivated: EventEmitter<null> = new EventEmitter<null>();

    isLoadingResults = true;
    displayedColumns: string[] = ['state', 'account', 'action'];
    accounts: MetrcLicense[] = [];

    constructor(public authService: AuthService,
                private metrcService: MetrcService) {}

    ngOnInit() {
        this.getAccounts();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.changeTrigger && !changes.changeTrigger.firstChange) {
            this.getAccounts();
        }
    }

    getAccounts() {
        this.metrcService.getActiveMetrcLicenseInformation(MetrcStatus.ACTIVE, this.memberId).subscribe((response: any) => {
            this.isLoadingResults = false;
            this.accounts = response;
        });
    }

    onUnlinkMrb(id: string) {
        this.metrcService.updateMetrcLicense(id, { status: MetrcStatus.DISABLED }).subscribe(() => {
            this.getAccounts();
            this.metrcDeactivated.emit();
        });
    }

}
