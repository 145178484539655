<div class="col-12" *ngIf="chartData.length > 0">
    <pc-charts-gadgets *ngIf="view !== 'scores'"
        [chartData]="chartData"
        [chartType]="ChartType.LINE_CHART"
        [showLegend]="false"
        [chartContainer]="'chart-container-transactions'"
        [colorScheme]="colorScheme">
    </pc-charts-gadgets>

    <pc-charts-gadgets *ngIf="view === 'scores'"
        [chartData]="chartData"
        [chartType]="ChartType.GROUPED_VERTICAL_BAR_CHART"
        [showLegend]="false"
        [chartContainer]="'chart-container-transactions'"
        [colorScheme]="scoreColorScheme">
    </pc-charts-gadgets>
</div>
<div class="col-12 view_centered">
    <ul class="sc-checkbox" >
        <li class="horizontal_line">
            <label><span>View:</span></label>
        </li>
        <li class="horizontal_line">
            <input type="radio" value="amount" [checked]="view === 'amount'" [disabled]="dataLoading" (change)="viewDecision($event.target.value)">
            <label class="label_margin" [ngClass]="dataLoading ? 'disabled' : ''"> <span><strong>Amount</strong></span> </label>
        </li>
        <li class="horizontal_line">
            <input type="radio" value="transactions" [checked]="view === 'transactions'" [disabled]="dataLoading" (change)="viewDecision($event.target.value)">
            <label class="label_margin" [ngClass]="dataLoading ? 'disabled' : ''"><span><strong># of Transactions </strong></span> </label>
        </li>
        <li class="horizontal_line">
            <input type="radio" value="scores" [checked]="view === 'scores'" [disabled]="dataLoading" (change)="viewDecision($event.target.value)">
            <label class="label_margin" [ngClass]="dataLoading ? 'disabled' : ''"> <span><strong>Scores</strong></span> </label>
        </li>
    </ul>
</div>
