import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http.service';
import { CustomerInfo } from './models/customer-info';

@Injectable({
    providedIn: 'root'
})
export class BankingIntegrationService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.PaymentAPIEndpoint + '/';
    }

    getCustomerInfo(memberAccountId: string) {
        return this.http.get(`${this.baseUrl}customer/${memberAccountId}`);
    }

    setCustomerInfo(customerInfo: CustomerInfo) {
        return this.http.post(`${this.baseUrl}customer`, customerInfo);
    }
}
