export class CustomerInfo {

    id: string;
    created: Date;
    memberId: string;
    memberAccountId: string;
    customerId: string;
    accountId: string;

}
