import { Component } from '@angular/core';
import { User, UserAccount, UserAccountStatus, UserStatus } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-account-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class AccountBadgeComponent extends BaseBadgeComponent<UserAccount | User> {

    setBadgeData() {
        this.badgeToolTip = this.parent.status;
        switch (this.parent.status) {
            case UserAccountStatus.ACTIVE:
            case UserStatus.ACTIVE:
                this.badgeText = 'ACTIVE';
                this.badgeClass = 'green-badge';
                break;
            case UserAccountStatus.PENDING_REGISTRATION:
                this.badgeText = 'PENDING';
                this.badgeClass = 'orange-badge';
                break;
            case UserAccountStatus.INVITED:
                this.badgeText = 'INVITED';
                this.badgeClass = 'grey-badge';
                break;
            case UserAccountStatus.DISABLED:
                this.badgeText = 'DISABLED';
                this.badgeClass = 'red-badge';
                break;
            case UserStatus.LOCKED:
                this.badgeText = 'LOCKED';
                this.badgeClass = 'red-badge';
                break;

        }
    }
}
