<div class="external-system-registration" [class.not-linked]="!connected">
    <div>
        <div class="blue-background">
            <h1>METRC</h1>
        </div>
    </div>
    <div class="content">
        <div class="col-12">
            <h5>METRC information is used to automatically validate transactions and reconcile against activity. Please enter each of your user keys for all licensed facilities in this state.</h5>
        </div>
        <div *ngIf="loading" class="d-flex justify-content-center mt-5">
            <i class="fa fa-spin fa-spinner"></i>Checking status...
        </div>
        <div *ngIf="!loading">
            <pt-metrc-account-details *ngIf="authService.hasAnyRole([Role.ADMIN, Role.AUTHORITY_READONLY])" [memberId]="memberId" [changeTrigger]="changeTrigger" (metrcDeactivated)="onMetrcDeactivated()"></pt-metrc-account-details>
            <div class="button-panel row centered">
                <div *ngIf="authService.isAdmin()">
                    <button pc-no-dbl-click class="blue-button" type="button" (click)="onRegister()" submit-button>Link</button>
                </div>
                <div *ngIf="!connected && !authService.hasAnyRole([Role.ADMIN, Role.AUTHORITY_READONLY])">
                    Please have your administrator link this member.
                </div>
                <div>
                <span class="row" *ngIf="connected">
                    <p><i class="fa fa-check-circle-o"></i>Connected</p>
                </span>
                <span class="row" *ngIf="!connected" >
                    <p><i class="fa fa-times"></i>Not Connected</p>
                </span>
                </div>
            </div>
        </div>
    </div>
</div>
