<div class="top-header">
    <div class="column-centered" [class]="getMenuClass()" (click)="onToggleExpandSideMenu()">
        <div class="top-header-icon-content">
            <img src="assets/images/Confia-icon.png" *ngIf="!expandedMenu">
            <img src="assets/images/confia-logo.svg" *ngIf="expandedMenu">
        </div>
    </div>
    <div [class]="getBodyClass()">
        <div class="top-header-heading">
            <h1 *ngIf="profile">{{profile.memberName}}<span *ngIf="profile.retailLocationId"> ({{profile.retailLocationName}})</span></h1>
        </div>
        <div class="top-header-menu">
            <div class="header-link">
                <i class="fa fa-sign-out" (click)="onLoggedOut()" aria-hidden="true" matTooltip="Log Out"></i>
            </div>
        </div>
    </div>
</div>
