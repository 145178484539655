import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, SidebarTrackingService, Profile, CountNotificationService } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-new-member-header',
    templateUrl: './new-member-header.component.html',
    styleUrls: ['./new-member-header.component.scss']
})
export class NewMemberHeaderComponent implements OnInit {

    @Input() expandedMenu: boolean;

    @Output() toggleExpandSideMenu: EventEmitter<any> = new EventEmitter<any>();

    profile: Profile;
    menuItems: any[] = [];

    constructor(private translate: TranslateService,
                private router: Router,
                private countNotificationService: CountNotificationService,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.profile = this.authService.getProfile();

        const administrationMenu: any = {};
        administrationMenu.icon = 'fa fa-user-o';
        administrationMenu.routerLink = '/administration';
        administrationMenu.linkText = 'Administration';
        this.menuItems.push(administrationMenu);
    }

    getMenuClass() {
        if (this.expandedMenu) {
            return 'top-header-icon-wide';
        } else {
            return 'top-header-icon-narrow';
        }
    }

    getBodyClass() {
        if (this.expandedMenu) {
            return 'top-header-body-narrow';
        } else {
            return 'top-header-body-wide';
        }
    }

    onToggleExpandSideMenu() {
        this.toggleExpandSideMenu.emit('toggle');
    }

    onLoggedout() {
        this.authService.logout();
    }

    getOpenItemCount() {
        return this.countNotificationService.getOpenItemCount();
    }

    isActive(item: string) {
        return SidebarTrackingService.getActiveNavItem() === item;
    }

    isDisabled(link: string) {
        return this.router.url.indexOf(link) >= 0;

    }
}
