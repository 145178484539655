import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { UserAccount } from './models/user-account';
import { Member, Role, UserAccountStatus, UserMemberAccess } from '../public-api';

@Injectable({
    providedIn: 'root'
})
export class UserAccountService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.EntityAPIEndpoint + '/';
    }

    loadUserAccountsByMemberId(memberId: string, start: number, size: number, sort: string, sortDir: string) {
        const params = `?memberId=${memberId}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}user-account${params}`);
    }

    getAllUserAccountsByMemberId(memberId: string) {
        const params = `?memberId=${memberId}&start=${0}&size=${1000}&sort=status&sortDir=ASC`;
        return this.http.get(`${this.baseUrl}user-account${params}`);
    }

    loadAccountsByMemberIdAndStatusesAndRoles(memberId: string, statuses: UserAccountStatus[], roles: Role[], start: number, size: number, sort: string, sortDir: string) {
        const params = `?memberId=${memberId}&statuses=${statuses}&roles=${roles}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}user-account${params}`);
    }

    getAllUserAccountsByMemberIdAndMemberAccountId(memberId: string, memberAccountId, start: number, size: number, sort: string, sortDir: string) {
        const params = `?memberId=${memberId}&memberAccountId=${memberAccountId}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}user-account${params}`);
    }

    getUserAccessByMemberId(memberId: string, start: number, size: number, sort: string, sortDir: string) {
        const params = `?memberId=${memberId}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}user-account/access${params}`);
    }

    getAllUserAccessByMemberId(memberId: string) {
        return this.http.get(`${this.baseUrl}user-account/access?memberId=${memberId}`);
    }

    getMembersAvailableForLogin(userId: string) {
        return this.http.get(`${this.baseUrl}user-account/login/${userId}/members`) as Observable<Member[]>;
    }

    getUserAccountAccessForMemberAndUser(memberId: string, userId: string) {
        return this.http.get(`${this.baseUrl}user-account/login/${userId}/${memberId}/accounts`) as Observable<UserMemberAccess>;
    }

    getUserAccountsAvailableForLogin(userId: string) {
        return this.http.get(`${this.baseUrl}user-account/login/${userId}`) as Observable<UserAccount[]>;
    }

    updateUserAccount(userAccountId: string, body: any) {
        return this.http.put(`${this.baseUrl}user-account/${userAccountId}`, body);
    }

    deleteUserInvitation(userAccountId: string) {
        return this.http.delete(`${this.baseUrl}user-account/${userAccountId}`);
    }

    removeAccess(userAccountId: string) {
        return this.http.delete(`${this.baseUrl}user-account/access/${userAccountId}`);
    }

    addAccess(userAccount: UserAccount) {
        return this.http.post(`${this.baseUrl}user-account`, userAccount);
    }

    getUserAccountById(userAccountId: string) {
        return this.http.get(`${this.baseUrl}user-account/${userAccountId}`);
    }
}
