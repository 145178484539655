<fieldset class="form-group" *ngIf="formCtrl">
  <div>
    <div class="d-flex flex-column" [formGroup]="formGroup">
      <div>
        <div class="pull-left">
          <label class="form-label">{{ label | translate }}</label>
        </div>
      </div>
      <textarea class="form-control form-text-area-editable" [placeholder]="placeholder" [class]="class" [formControlName]="formCtrl" [required]="required">
      </textarea>
    </div>
  </div>
</fieldset>
