import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent, ErrorType } from 'projects/components/src/public-api';
import { IMyDateModel } from 'angular-mydatepicker';
import {
    Address, AddressFormService, AddressType, AuthService, IndividualKeyParty, KeyParty, KeyPartyFormService, KeyPartyService, KeyPartyStatus, KeyPartyType, MemberAccountStatus, MemberType,
    PagedResponse, UserAccount, UserAccountService, CustomValidators
} from 'projects/services/src/public-api';

@Component({
    selector: 'pt-individual-key-party-modal',
    templateUrl: './individual-key-party-modal.component.html'
})
export class IndividualKeyPartyModalComponent extends BaseModalComponent<IndividualKeyPartyModalComponent> implements OnInit {

    AddressType = AddressType;

    memberId: string;
    keyPartyForm: UntypedFormGroup;
    addressForm: UntypedFormGroup;
    individualKeyPartyForm: UntypedFormGroup;
    isOwner = false;
    isUsCitizen = true;
    errorMessage: { message: string, type: ErrorType };
    keyParty: KeyParty;
    isExistingKeyParty = false;
    passportExpirationDate: any;
    isMerchant: false;
    memberType: MemberType;
    memberAccountStatus: MemberAccountStatus;
    disableEmailInputField = true;
    oldEmail: string;
    existingKeyPartyIds: string[];
    // originalAddress is used to store address of key party and replace addressForm with originalAddress in certain scenarios
    originalAddress: Address;

    constructor(private userAccountService: UserAccountService,
                private addressFormService: AddressFormService,
                private keyPartyService: KeyPartyService,
                private keyPartyFormService: KeyPartyFormService,
                public authService: AuthService,
                private datePipe: DatePipe,
                private cdr: ChangeDetectorRef,
                dialogRef: MatDialogRef<IndividualKeyPartyModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberId = data.memberId;
        this.keyParty = data.keyParty;
        this.disableEmailInputField = (!!data.keyParty?.userId || !data.keyParty?.isDeleteable);
        this.isMerchant = data.isMerchant;
        this.memberType = data.memberType;
        this.memberAccountStatus = data.memberAccountStatus;
        this.existingKeyPartyIds = data.existingKeyPartyIds;

        if (this.keyParty) {
            this.keyParty.owner = this.keyParty.percentOwnership > 0;
            this.isOwner = this.keyParty.owner;
            this.isExistingKeyParty = true;
            this.oldEmail = this.keyParty.individualKeyParty.email;
        }
    }

    ngOnInit() {
        this.onSubmit = this.onSubmit.bind(this);
        this.formsValid = this.formsValid.bind(this);
        if (this.isExistingKeyParty) {
            this.keyPartyForm = this.keyPartyFormService.initializeKeyPartyForm(this.keyParty.type, this.keyParty.status, this.keyParty);
            this.addressForm = this.addressFormService.initializeForm(this.keyParty.individualKeyParty.address);
            // Storing the existing address of key party in originalAddress
            this.originalAddress = this.keyParty.individualKeyParty.address;
            this.individualKeyPartyForm = this.keyPartyFormService.initializeIndividualKeyPartyForm(this.keyParty.individualKeyParty);
            this.passportExpirationDate = this.keyParty.individualKeyParty.passportExpirationDate ? new Date(this.keyParty.individualKeyParty.passportExpirationDate) : '';
            this.keyPartyForm.get('percentOwnershipCtrl').updateValueAndValidity();
            this.showWarning(true);
            if (this.keyParty.userId) {
                this.keyPartyForm.disable();
                this.keyPartyForm.get('percentOwnershipCtrl').enable();
                this.keyPartyForm.get('employerCtrl').enable();
                this.keyPartyForm.get('professionalTitleCtrl').enable();
            }
            if (!this.disableEmailInputField) {
                // if keyparty is linked to user account then we are disabling email edit option
                // by default we will disable the email, if individual key party is not linked to user account then we will enable email edit option
                this.individualKeyPartyForm.get('emailCtrl').disable();
                this.keyPartyService.isIndividualKeyPartyLinkedToUserAccount(this.keyParty.individualKeyPartyId).subscribe((result: boolean) => {
                    if (!result) {
                        this.individualKeyPartyForm.get('emailCtrl').enable();
                    }
                });
            } else {
                this.individualKeyPartyForm.get('emailCtrl').disable();
            }
        } else {
            this.keyPartyForm = this.keyPartyFormService.initializeKeyPartyForm(KeyPartyType.INDIVIDUAL, KeyPartyStatus.ACTIVE);
            this.addressForm = this.addressFormService.initializeForm(null);
            this.individualKeyPartyForm = this.keyPartyFormService.initializeIndividualKeyPartyForm();
            // for new key parties, address will not be available, so originalAddress is null
            this.originalAddress = null;
        }
        this.updateFormValidators();
    }

    isUsCitizenShip() {
        return this.individualKeyPartyForm.get('usCitizenCtrl').value;
    }

    updateFormValidators() {
        if (this.isContactOnly()) {
            this.keyPartyForm.patchValue({
                ownerCtrl: false,
                isDirectorCtrl: false,
                isOfficerCtrl: false,
                percentOwnershipCtrl: ''
            });
            this.keyPartyForm.get('ownerCtrl').disable();
            this.keyPartyForm.get('isDirectorCtrl').disable();
            this.keyPartyForm.get('isOfficerCtrl').disable();

            this.keyPartyForm.get('employerCtrl').setValidators([CustomValidators.noLeadingOrTrailingWhitespace]);
            this.keyPartyForm.get('professionalTitleCtrl').setValidators([CustomValidators.noLeadingOrTrailingWhitespace]);
            this.individualKeyPartyForm.get('birthDateCtrl').setValidators(null);
            this.individualKeyPartyForm.get('citizenshipCtrl').setValidators(null);
            this.individualKeyPartyForm.get('passportCtrl').setValidators(null);
            this.individualKeyPartyForm.get('passportExpDateCtrl').setValidators(null);
            this.individualKeyPartyForm.get('taxIdCtrl').setValidators(null);
            this.individualKeyPartyForm.get('passportExpDateCtrl').setValue(this.passportExpirationDate ? new Date(this.passportExpirationDate) : null);

            // When contact-only check box is clicked, we are replacing the addressForm with originalAddress
            // this will ensure that any changes made to addressForm will be undone.
            this.addressForm = this.addressFormService.initializeForm(this.originalAddress);
            this.addressForm.get('streetAddressOneCtrl').setValidators(null);
            this.addressForm.get('cityCtrl').setValidators(null);
            this.addressForm.get('stateProvinceCtrl').setValidators(null);
            this.addressForm.get('countryCtrl').setValidators(null);
            this.addressForm.get('zipPostalCodeCtrl').setValidators(null);
        } else {
            this.isOwner = this.keyPartyForm.get('ownerCtrl').value;
            if (this.isOwner || this.keyPartyForm.get('isDirectorCtrl').value || this.keyPartyForm.get('isOfficerCtrl').value) {
                this.keyPartyForm.get('isContactCtrl').disable();
            } else {
                this.keyPartyForm.get('isContactCtrl').enable();
            }
            this.keyPartyForm.get('ownerCtrl').enable();
            this.keyPartyForm.get('isDirectorCtrl').enable();
            this.keyPartyForm.get('isOfficerCtrl').enable();
            if (this.isOwner) {
                this.keyPartyForm.get('percentOwnershipCtrl').setValidators([Validators.required, Validators.min(1), Validators.max(100), CustomValidators.onlyNumeric]);
            } else {
                this.keyPartyForm.get('percentOwnershipCtrl').setValidators(null);
                this.keyPartyForm.patchValue({percentOwnershipCtrl: ''});
            }

            this.isUsCitizen = this.individualKeyPartyForm.get('usCitizenCtrl').value;
            if (this.isUsCitizen) {
                this.individualKeyPartyForm.get('taxIdCtrl').setValidators([Validators.required, Validators.pattern('^([0-9]{9})$')]);
                this.individualKeyPartyForm.get('citizenshipCtrl').setValidators(null);
                this.individualKeyPartyForm.get('passportCtrl').setValidators(null);
                this.individualKeyPartyForm.get('passportExpDateCtrl').setValidators(null);
            } else {
                this.individualKeyPartyForm.get('taxIdCtrl').setValidators([CustomValidators.noLeadingOrTrailingWhitespace]);
                this.individualKeyPartyForm.get('citizenshipCtrl').setValidators([Validators.required, CustomValidators.noLeadingOrTrailingWhitespace]);
                this.individualKeyPartyForm.get('passportCtrl').setValidators([Validators.required, CustomValidators.noLeadingOrTrailingWhitespace]);
                this.individualKeyPartyForm.get('passportExpDateCtrl').setValue(this.passportExpirationDate ? new Date(this.passportExpirationDate) : null);
                this.individualKeyPartyForm.get('passportExpDateCtrl').setValidators([Validators.required]);
            }

            this.keyPartyForm.get('employerCtrl').setValidators([Validators.required, CustomValidators.noLeadingOrTrailingWhitespace]);
            this.keyPartyForm.get('professionalTitleCtrl').setValidators([Validators.required, Validators.maxLength(24), CustomValidators.noLeadingOrTrailingWhitespace]);
            this.individualKeyPartyForm.get('birthDateCtrl').setValidators([Validators.required, CustomValidators.birthDate]);

            this.addressForm.get('streetAddressOneCtrl').setValidators([Validators.required, Validators.minLength(3), CustomValidators.noLeadingOrTrailingWhitespace]);
            this.addressForm.get('cityCtrl').setValidators([Validators.required, CustomValidators.noLeadingOrTrailingWhitespace]);
            this.addressForm.get('stateProvinceCtrl').setValidators([Validators.required, Validators.minLength(2)]);
            this.addressForm.get('countryCtrl').setValidators([Validators.required]);
            this.addressForm.get('zipPostalCodeCtrl').setValidators([Validators.required]);
        }
        this.keyPartyForm.get('ownerCtrl').updateValueAndValidity();
        this.keyPartyForm.get('isDirectorCtrl').updateValueAndValidity();
        this.keyPartyForm.get('isOfficerCtrl').updateValueAndValidity();
        this.keyPartyForm.get('percentOwnershipCtrl').updateValueAndValidity();
        this.keyPartyForm.get('employerCtrl').updateValueAndValidity();
        this.keyPartyForm.get('professionalTitleCtrl').updateValueAndValidity();
        this.individualKeyPartyForm.get('birthDateCtrl').updateValueAndValidity();
        this.individualKeyPartyForm.get('citizenshipCtrl').updateValueAndValidity();
        this.individualKeyPartyForm.get('passportCtrl').updateValueAndValidity();
        this.individualKeyPartyForm.get('passportExpDateCtrl').updateValueAndValidity();
        this.individualKeyPartyForm.get('taxIdCtrl').updateValueAndValidity();
        this.individualKeyPartyForm.get('phoneCtrl').updateValueAndValidity();
        this.addressForm.get('streetAddressOneCtrl').updateValueAndValidity();
        this.addressForm.get('cityCtrl').updateValueAndValidity();
        this.addressForm.get('stateProvinceCtrl').updateValueAndValidity();
        this.addressForm.get('countryCtrl').updateValueAndValidity();
        this.addressForm.get('zipPostalCodeCtrl').updateValueAndValidity();

        this.cdr.detectChanges();
    }

    isContactOnly() {
        return this.keyPartyForm.get('isContactCtrl').value && !this.isOwner && !this.keyPartyForm.get('isDirectorCtrl').value && !this.keyPartyForm.get('isOfficerCtrl').value;
    }

    onDateChanged(event: IMyDateModel) {
        if (event && event.singleDate) {
            this.passportExpirationDate = this.datePipe.transform(event?.singleDate?.jsDate, 'yyyy-MM-dd');
        } else {
            this.passportExpirationDate = '';
        }
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    onSubmit(reset: any) {
        const address = this.addressFormService.getAddress(this.addressForm);
        const individualKeyParty = this.keyPartyFormService.getIndividualKeyParty(this.individualKeyPartyForm);
        individualKeyParty.address = address;
        // For new contact-only individual key party we should not create an address bean
        // originalAddress will be null only for new key parties
        if (this.isContactOnly() && !this.originalAddress) {
            individualKeyParty.address = null;
        }
        const keyParty = this.keyPartyFormService.getKeyParty(this.keyPartyForm);

        if (individualKeyParty.email) {
            this.validateKeyPartyEmail(keyParty, individualKeyParty, reset);
        } else {
            this.saveKeyParty(keyParty, individualKeyParty);
        }
    }

    validateKeyPartyEmail(keyParty: KeyParty, individualKeyParty: IndividualKeyParty, reset: any) {
        if (this.memberType === MemberType.BUSINESS) {
            this.userAccountService.loadUserAccountsByMemberId(this.memberId, 0, -1, 'created', 'ASC').subscribe((accounts: PagedResponse<UserAccount>) => {
                const user = accounts.content.map((userAccount) => {
                    return userAccount.user;
                }).find((user) => {
                    return user.email === individualKeyParty.email;
                });
                if (user && !this.isExistingKeyParty && (this.memberAccountStatus === MemberAccountStatus.UNDER_REVIEW || this.memberAccountStatus === MemberAccountStatus.APPLIED)) {
                    reset();
                    throw new Error('Key parties with administrator email cannot be added, please select one of the options from the background information in the Administrator section.');
                }
                this.validateAndSaveKeyParty(keyParty, individualKeyParty, reset);
            });
        } else {
            this.validateAndSaveKeyParty(keyParty, individualKeyParty, reset);
        }
    }

    validateAndSaveKeyParty(keyParty: KeyParty, individualKeyParty: IndividualKeyParty, reset: any) {
        this.keyPartyService.getAllIndividualKeyPartiesByEmail(this.memberId, individualKeyParty.email).subscribe((keyParties: PagedResponse<KeyParty>) => {
            if (keyParties.totalElements === 0 || individualKeyParty.email === this.oldEmail && this.isExistingKeyParty) {
                this.saveKeyParty(keyParty, individualKeyParty);
            } else {
                reset();
                throw new Error('This individual is already included as a key party.');
            }
        });
    }

    saveKeyParty(keyParty: KeyParty, individualKeyParty: IndividualKeyParty) {
        if (this.isContactOnly()) {
            keyParty.contactPerson = true;
        } else {
            individualKeyParty.passportExpirationDate = this.passportExpirationDate;
            if (!this.isExistingKeyParty) {
                keyParty.contactPerson = false;
            }
        }
        this.keyPartyService.saveIndividualKeyParty(individualKeyParty).subscribe((individualKeyParty: IndividualKeyParty) => {
            keyParty.individualKeyPartyId = individualKeyParty.id;
            keyParty.memberId = this.memberId;
            this.keyPartyService.saveKeyParty(keyParty).subscribe(() => {
                this.close(true);
            });
        });
    }

    // double check this logic
    formsValid() {
        return this.keyPartyForm.valid && this.addressForm.valid && this.individualKeyPartyForm.valid && this.isRelationshipsValid()
                && (!this.isOwner || this.keyPartyForm.get('percentOwnershipCtrl').value > 0)
                && (this.keyPartyForm.dirty || this.addressForm.dirty || this.individualKeyPartyForm.dirty);
    }

    isRelationshipsValid() {
        return this.keyPartyForm.controls['ownerCtrl'].value === true ||
            this.keyPartyForm.controls['isDirectorCtrl'].value === true ||
            this.keyPartyForm.controls['isOfficerCtrl'].value === true ||
            this.keyPartyForm.controls['isContactCtrl'].value === true;
    }

    handleExistingKeyParty(keyParty: KeyParty) {
        this.keyPartyForm.patchValue({
            ownerCtrl: keyParty.percentOwnership && keyParty.percentOwnership > 0,
            employerCtrl: keyParty.employer,
            professionalTitleCtrl: keyParty.professionalTitle,
            percentOwnershipCtrl: keyParty.percentOwnership,
            isDirectorCtrl: keyParty.director,
            isOfficerCtrl: keyParty.officer,
            isContactCtrl: keyParty.contactPerson
        });
        this.errorMessage = {
            message: 'This individual is already included as a key party.  Please edit the existing owner information.',
            type: ErrorType.ERROR
        };
        this.handleIndividualKeyPartyChange(keyParty.individualKeyParty);
        this.individualKeyPartyForm.disable();
        this.addressForm.disable();
        this.keyPartyForm.disable();
        this.updateFormValidators();
    }

    handleIndividualKeyPartyChange(individualKeyParty: IndividualKeyParty) {
        this.individualKeyPartyForm.patchValue({
            idCtrl: individualKeyParty.id,
            createdCtrl: individualKeyParty.created,
            updatedCtrl: individualKeyParty.updated,

            firstNameCtrl: individualKeyParty.firstName,
            middleNameCtrl: individualKeyParty.middleName,
            lastNameCtrl: individualKeyParty.lastName,
            otherSurnameOrAliasCtrl: individualKeyParty.otherSurnameOrAlias,
            birthDateCtrl: individualKeyParty.birthDate,
            emailCtrl: individualKeyParty.email,
            phoneCtrl: individualKeyParty.phone,

            usCitizenCtrl: individualKeyParty.usCitizen || true,
            taxIdCtrl: individualKeyParty.taxId,
            citizenshipCtrl: individualKeyParty.nationality,
            passportCtrl: individualKeyParty.passportNumber,
            passportExpDateCtrl: individualKeyParty.passportExpirationDate
        }, {emitEvent: true});
        this.addressForm.patchValue({
            idCtrl: individualKeyParty.address?.id,
            createdCtrl: individualKeyParty.address?.created,
            updatedCtrl: individualKeyParty.address?.updated,

            streetAddressOneCtrl: individualKeyParty.address?.streetAddressOne,
            cityCtrl: individualKeyParty.address?.city,
            stateProvinceCtrl: individualKeyParty.address?.stateProvince,
            countryCtrl: individualKeyParty.address?.country || 'United States',
            zipPostalCodeCtrl: individualKeyParty.address?.zipPostalCode
        });
        this.keyPartyForm.patchValue({
            individualKeyPartyIdCtrl: individualKeyParty.id
        });
        this.passportExpirationDate = individualKeyParty.passportExpirationDate;
        // when key party is changed we are updating the originalAddress with the address of changed key party
        this.originalAddress = individualKeyParty.address;
    }

    onChangeEmail(email: string) {
        if (this.individualKeyPartyForm.get('emailCtrl').valid && this.individualKeyPartyForm.get('emailCtrl').value) {
            this.keyPartyService.getActiveIndividualKeyPartiesByEmail(this.memberId, email).subscribe((keyParties: PagedResponse<KeyParty>) => {
                if (keyParties.totalElements > 0) {
                    this.handleExistingKeyParty(keyParties.content[0]);
                } else {
                    this.keyPartyForm.enable();
                }
            });
        }
    }

    setIndividualKeyParty(individualKeyParty) {
        this.errorMessage = null;
        this.handleIndividualKeyPartyChange(individualKeyParty);
        this.keyPartyForm.patchValue({
            ownerCtrl: false,
            percentOwnershipCtrl: null,
            isDirectorCtrl: false,
            isOfficerCtrl: false
        });
        this.updateFormValidators();
        this.keyPartyForm.enable();
        this.showWarning(!!individualKeyParty.id);
    }

    showWarning(existingParty: boolean) {
        if (!existingParty) {
            this.errorMessage = null;
        } else {
            this.errorMessage = {
                message: 'A key party with this email was found in Confia.  Changes to the individual and address information will update anywhere this key party is in use.',
                type: ErrorType.WARN
            };
        }
    }
}
