export class WatchlistAddress {
    id: string;
    created: string;
    updated: string;
    entityId: string;
    addressId: string;
    streetAddress: string;
    fullAddress: string;
    cityStateProvincePostalCode: string;
    country: string;
    addressRemarks: string;
    watchlistEntityId: string;
}
