import { Component, Inject, OnInit } from '@angular/core';
import { BaseModalComponent } from '../base-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LendingFinancingRequest, LenderService, MemberService, Member } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-accept-financing-request-modal',
    templateUrl: './accept-financing-request-modal.component.html'
})
export class AcceptedFinancingRequestModalComponent extends BaseModalComponent<AcceptedFinancingRequestModalComponent> implements OnInit {

    financingRequest: LendingFinancingRequest;
    memberName: string;

    constructor(private lenderService: LenderService,
                private memberService: MemberService,
                dialogRef: MatDialogRef<AcceptedFinancingRequestModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.financingRequest = data.financingRequest;
    }

    ngOnInit() {
        this.memberService.loadMember(this.financingRequest.memberId)
            .subscribe((member: Member) => {
                this.memberName = member.name;
            });
    }

    onProceed() {
        this.lenderService.updateLoanStatus(this.financingRequest.loanId, 'DILIGENCE')
            .subscribe(() => {
                super.close('PROCEED');
            });
    }

    onDecline() {
        super.close('DECLINED');
    }

    close() {
        super.close('CLOSE');
    }
}
