import { AfterViewInit, ViewChild, SimpleChanges, OnDestroy, EventEmitter, Component, Input, OnInit, OnChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { PageTracking } from 'projects/components/src/lib/table-utils.service';
import { PagedResponse, RecordsService, ProductPackageDetails, ProductPackageTransferDetails } from 'projects/services/src/public-api';
import { catchError, map, merge, startWith, switchMap, of as observableOf } from 'rxjs';

@Component({
    selector: 'pc-member-metrc-trace-transfer-row-list',
    templateUrl: './member-metrc-trace-transfer-row-list.component.html'
})
export class MemberMetrcTraceTransferRowListComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @Input() memberId: string;
    @Input() productPackageDetails: ProductPackageDetails;
    @Input() startDate: Date;
    @Input() endDate: Date;

    displayedColumns = ['transferId', 'quantityIn', 'quantityOut', 'quantityUnits', 'transferDate'];
    subscription: any;
    pageTracking: PageTracking;
    isLoadingResults = true;
    resultsLength = 0;

    refreshEvent: EventEmitter<null> = new EventEmitter<null>();
    productPackageTransferDetails: ProductPackageTransferDetails[];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private recordsService: RecordsService) { }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(change: SimpleChanges): void {
        if ((change.startDate && !change.startDate.firstChange) || (change.endDate && !change.endDate.firstChange)
            || (change.entityId && !change.entityId.firstChange) || (change.productBalance && !change.productBalance.firstChange)) {
            this.refreshEvent.emit();
        }
    }

    ngOnInit() {
        this.pageTracking = new PageTracking();
        this.pageTracking.page = 0;
        this.pageTracking.pageSize = 10;
        this.pageTracking.pageSizeOptions = [10, 25, 50, 100];
    }

    ngAfterViewInit() {
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.subscription = merge(this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                this.pageTracking.page = this.paginator.pageIndex;
                this.pageTracking.pageSize = this.paginator.pageSize;
                return this.recordsService.findProductPackageTransferDetails(this.memberId, this.productPackageDetails.packageId, this.startDate, this.endDate, this.pageTracking.page, this.pageTracking.pageSize);
            }),
            map((response: PagedResponse<ProductPackageTransferDetails>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((productPackageTransferDetails: ProductPackageTransferDetails[]) => {
            this.productPackageTransferDetails = productPackageTransferDetails;
        });
    }

}
