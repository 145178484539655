import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { PagedResponse } from './models/paged-response';
import { Rfi, RfiStatus } from './models/rfi';
import { RfiHistory } from './models/rfi-history';
import { TransactionType } from './models/transaction';

@Injectable({
    providedIn: 'root'
})
export class RfiService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.PaymentAPIEndpoint + '/';
    }

    findRfiById(id: string) : Observable<Rfi> {
        return this.http.get(`${this.baseUrl}rfi/${id}`) as Observable<Rfi>;
    }

    countAssignedRfis(status: RfiStatus) {
        return this.http.head(`${this.baseUrl}rfi?status=${status}`);
    }

    getInvolvedRfisByStatus(memberId: string,
        status: RfiStatus,
        types: TransactionType[],
        start: number,
        size: number,
        sort: string,
        sortDir: string) : Observable<PagedResponse<Rfi>> {

        let params = '?';
        if (memberId) {
            params += `memberId=${memberId}&`;
        }
        if (status) {
            params += `status=${status}&`;
        }
        if (types) {
            params += `types=${types}&`;
        }
        params += `start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}rfi${params}`) as Observable<PagedResponse<Rfi>>;
    }

    saveRFIHistory(rfiHistory: RfiHistory) : Observable<RfiHistory> {
        return this.http.post(`${this.baseUrl}rfi/history`, rfiHistory) as Observable<RfiHistory>;
    }

    updateRfiUpdatedDate(id: string) {
        return this.http.put(`${this.baseUrl}rfi/${id}`, {}) as Observable<RfiHistory>;
    }

    getRfiHistoryByRfi(transactionId: string,
        rfiId: string,
        authorityOnly: boolean,
        start: number,
        size: number,
        sort: string,
        sortDir: string) : Observable<PagedResponse<RfiHistory>> {

        const params = `?transactionId=${transactionId}&rfiId=${rfiId}&authorityOnly=${authorityOnly}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}rfi/history${params}`) as Observable<PagedResponse<RfiHistory>>;
    }

    findRfiByTransactionId(transactionId: string) : Observable<PagedResponse<Rfi>> {
        const params = `?transactionId=${transactionId}`;
        return this.http.get(`${this.baseUrl}rfi${params}`) as Observable<PagedResponse<Rfi>>;
    }

}
