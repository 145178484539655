export class FinancialDetails {
    id: string;
    created: Date;
    updated: Date;

    memberId: string;
    businessTypes: string[];
    expectedMonthlyDepositAmount: number;
    expectedMonthlyWithdrawals: number;
    expectedMonthlyNumberOfReceipts: number;
    expectedMonthlyNumberOfPayments: number;
    expectedMonthlyCashDepositAmount: number;
    percentOfRevenueFromCannabis: number;
    percentOfRevenueFromCannabisPartners: number;
    percentOfRevenueFromCash: number;
    projectedAnnualRevenue: number;
    plannedInitialDepositAmount: number;
    initialDepositSource: string;
}
