import { Component } from '@angular/core';
import { Transaction, TransactionStatus } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-transaction-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class TransactionBadgeComponent extends BaseBadgeComponent<Transaction> {

    setBadgeData() {
        this.badgeToolTip = this.parent.status;
        switch (this.parent.status) {
            case TransactionStatus.COMPLETED:
                this.badgeText = 'COMPLETED';
                this.badgeClass = 'green-badge';
                break;
            case TransactionStatus.CANCELLED_INSUFFICIENT_FUNDS:
            case TransactionStatus.CANCELLED_BY_CLIENT:
            case TransactionStatus.CANCELLED_BY_AUTHORITY:
            case TransactionStatus.CANCELLED_BY_SYSTEM:
            case TransactionStatus.CANCELLED_BY_REFUND:
                this.badgeText = 'CANCELLED';
                this.badgeClass = 'red-badge';
                break;
            case TransactionStatus.PENDING_RFI_AUTHORITY_REVIEW:
            case TransactionStatus.PENDING_RFI_WITH_FUNDS:
            case TransactionStatus.PENDING_BALANCE_VERIFICATION:
            case TransactionStatus.PENDING_WATCHLIST_SCREENING:
            case TransactionStatus.PENDING_WATCHLIST_REVIEW:
                this.badgeText = 'REVIEW';
                this.badgeClass = 'orange-badge';
                break;
            case TransactionStatus.SCHEDULED:
                this.badgeText = 'SCHEDULED';
                this.badgeClass = 'dark-grey-badge';
                break;
            default:
                this.badgeText = 'PENDING';
                this.badgeClass = 'grey-badge';
                break;

        }
    }
}
