<div>
    <div>
        <div class="modal-header">
            <label class="form-title">Transfer Funds</label>
            <i class="modal-close" (click)="close()">&times;</i>
        </div>
        <div class="modal-content-small">
            <div class="row mb-3">
                <div class="col-12" >
                    <div *ngIf="activeBusinessAccounts.length > 0">
                        <pc-simple-member-account-selector [label]="'Source Account'" [placeholder]="'Select Source Account'" [formCtrl]="'sourceAccountIdCtrl'" [formGroup]="transferFundsForm"
                            [memberAccounts]="activeBusinessAccounts" (memberAccountSelected)="onSelectSourceAccount($event)">
                        </pc-simple-member-account-selector>
                        <pc-simple-member-account-selector [label]="'Target Account'" [placeholder]="'Select Target Account'" [formCtrl]="'targetAccountIdCtrl'" [formGroup]="transferFundsForm"
                            [memberAccounts]="availableTargetAccounts" (memberAccountSelected)="onSelectTargetAccount($event)">
                        </pc-simple-member-account-selector>
                        <pc-form-amount-input [required]="true" [label]="'Transfer Amount'" [formCtrl]="'amountCtrl'" [formGroup]="transferFundsForm" [placeholder]="'0.00'"
                            (amountChanged)="onAmountChange($event)">
                        </pc-form-amount-input>
                    </div>
                </div>
                <div *ngIf="!transferAmountValid" class="error-message mb-4">
                    <div class="form-error" [innerHTML]="'Transfer amount exceeds available funds.'"></div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="blue-button" (click)="close()">Cancel</button>
            <pc-submit-button [enabled]="isFormValid" [submit]="onSubmit" [text]="'Transfer'"></pc-submit-button>
        </div>
    </div>
</div>
