<div class="horizontal-scroll mat-table-container">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="watchlistSearchResults" multiTemplateDataRows>
        <ng-container matColumnDef="search_date">
            <th mat-header-cell class="centered" *matHeaderCellDef> Search Date </th>
            <td mat-cell class="centered" *matCellDef="let row" [ngClass]="row.matchedCount ? 'matched' : (row.pendingCount ? 'pending' : '')">
                <pc-date-time [date]="row.searchDate"></pc-date-time>
            </td>
        </ng-container>
        <ng-container matColumnDef="search_term">
            <th mat-header-cell *matHeaderCellDef> Search Term </th>
            <td mat-cell *matCellDef="let row" [ngClass]="row.matchedCount ? 'matched' : (row.pendingCount ? 'pending' : '')">{{ row.searchTerm }}</td>
        </ng-container>
        <ng-container matColumnDef="total_count">
            <th mat-header-cell class="centered" *matHeaderCellDef> Result Count </th>
            <td mat-cell class="centered" *matCellDef="let row"><b>{{ row.totalCount }}</b></td>
        </ng-container>
        <ng-container matColumnDef="cleared_count">
            <th mat-header-cell class="centered" *matHeaderCellDef> Cleared </th>
            <td mat-cell class="centered" *matCellDef="let row" [class]="row.clearedCount > 0 ? 'cleared' : ''">{{ row.clearedCount }}</td>
        </ng-container>
        <ng-container matColumnDef="pending_count">
            <th mat-header-cell class="centered" *matHeaderCellDef> Pending </th>
            <td mat-cell class="centered" *matCellDef="let row" [class]="row.pendingCount > 0 ? 'pending' : ''">{{ row.pendingCount }}</td>
        </ng-container>
        <ng-container matColumnDef="matched_count">
            <th mat-header-cell class="centered" *matHeaderCellDef> Matched </th>
            <td mat-cell class="centered" *matCellDef="let row" [class]="row.matchedCount > 0 ? 'matched' : ''">{{ row.matchedCount }}</td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="searchResultsDisplayedColumns.length">
                <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                    <div class="horizontal-scroll mat-table-container">
                        <table #detailsTable mat-table [dataSource]="element.watchlistEntities" class="inner" multiTemplateDataRows>
                            <ng-container matColumnDef="external_watchlist_entity_id">
                                <th mat-header-cell class="centered" *matHeaderCellDef> Watchlist ID </th>
                                <td mat-cell class="centered" *matCellDef="let row"> {{ row.externalWatchlistEntityId }} </td>
                            </ng-container>
                            <ng-container matColumnDef="watchlist_name">
                                <th mat-header-cell class="centered" *matHeaderCellDef> Watchlist Entity Name </th>
                                <td mat-cell *matCellDef="let row"> {{ row.watchlistEntityName }} </td>
                            </ng-container>
                            <ng-container matColumnDef="matched_name">
                                <th mat-header-cell class="centered" *matHeaderCellDef> Matched Name </th>
                                <td mat-cell *matCellDef="let row"> {{ row.matchedName }} </td>
                            </ng-container>
                            <ng-container matColumnDef="match_percentage">
                                <th mat-header-cell class="centered" *matHeaderCellDef> Match % </th>
                                <td mat-cell class="centered" *matCellDef="let row"> {{ row.matchPercentage }} </td>
                            </ng-container>
                            <ng-container matColumnDef="status">
                                <th mat-header-cell class="centered" *matHeaderCellDef> Status </th>
                                <td mat-cell class="centered" *matCellDef="let row"><pc-watchlist-badge [parent]="row"></pc-watchlist-badge></td>
                            </ng-container>
                            <ng-container matColumnDef="notes">
                                <td mat-cell [attr.colspan]="3" *matCellDef="let row"><b>Last Note:</b> {{row.userNotes[0].notes}}</td>
                            </ng-container>
                            <ng-container matColumnDef="user_info">
                                <td mat-cell [attr.colspan]="2" *matCellDef="let row">
                                    <div class="pull-right">
                                        <b>User:</b> {{row.userNotes[0].userName}}
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="watchlistDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: watchlistDisplayedColumns; let i = dataIndex" class="pc-click-row" [ngClass]="row.userNotes?.length ? 'no-bottom-border' : ''" (click)="reviewWatchlistEntry(row, element, i, $event)"></tr>
                            <tr mat-row *matRowDef="let row; columns: ['notes', 'user_info']; let i = dataIndex; when hasNotes;" class="pc-click-row" class="no-top-border" (click)="reviewWatchlistEntry(row, element, i, $event)"></tr>
                        </table>
                    </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="searchResultsDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: searchResultsDisplayedColumns;"
            class="example-expand-row" [class.example-expanded-row]="expandedElement === element" (click)="expandRow(element, $event)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
</div>
