import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DataroomService, DocumentFormService, DocumentType, Utils } from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
import { BaseModalComponent, LoaderComponent } from 'projects/components/src/public-api';
import { Overlay } from '@angular/cdk/overlay';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-other-document-modal',
    templateUrl: './other-document-modal.component.html'
})
export class OtherDocumentModalComponent extends BaseModalComponent<OtherDocumentModalComponent> implements OnInit {

    title: string;
    memberId: string;
    path: string;
    referencedEntityId: string;
    documentTypes: DocumentType[] = [];
    allowMultiple = false;
    hideDescription = false;

    documentForm: UntypedFormGroup;
    files: File[];

    constructor(private documentFormService: DocumentFormService,
                private dataroomService: DataroomService,
                private overlay: Overlay,
                private notificationService: NotificationService,
                dialogRef: MatDialogRef<OtherDocumentModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.title = data.title;
        this.memberId = data.memberId;
        this.path = data.path;
        this.referencedEntityId = data.referencedEntityId;
        this.documentTypes = data.documentTypes;
        this.allowMultiple = data.allowMultiple;
        this.hideDescription = data.hideDescription;
    }

    ngOnInit() {
        this.isDocumentFormValid = this.isDocumentFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.documentForm = this.documentFormService.initializeDocumentForm();
        if (!this.documentTypes.length) {
            this.documentTypes.push(DocumentType.OTHER_CORPORATE_DOCUMENT);
        }
        if (this.documentTypes.length === 1) {
            this.documentForm.controls['documentTypeCtrl'].patchValue(this.documentTypes[0]);
            this.documentForm.controls['documentTypeCtrl'].disable();
        }
    }

    close(saved?: boolean) {
        this.files = null;
        super.close(saved);
    }

    onSubmit(reset: any) {
        const explanation = this.documentForm.get('notesCtrl').value;
        const documentType = this.documentForm.get('documentTypeCtrl').value;
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        componentRef.instance.title = 'Uploading files.  Please wait...';

        this.uploadFiles(this.files, explanation, documentType).subscribe(() => {
            overlayRef.detach();
            this.close(true);
        }, () => {
            reset();
            this.notificationService.showError('Document upload was unsuccessful. Please check your connection and try again.');
        });
    }

    uploadFiles(files: File[], explanation: string, documentType: DocumentType) {
        return forkJoin(files.map((file) => {
            return this.dataroomService.uploadFile(this.memberId, this.path, file, file.name, explanation, documentType, this.referencedEntityId);
        }));
    }

    selectFile(event: any) {
        if (event.target.files && event.target.files.length) {
            for (const file of event.target.files) {
                Utils.validateFile(file, event);
            }
            this.files = Array.from(event.target.files);
            event.target.value = '';
        }
    }

    isDocumentFormValid() {
        return !this.documentForm.invalid && this.files && this.files.length > 0;
    }

    deleteFile(index: number) {
        this.files.splice(index, 1);
    }
}
