 <div class="col-lg-12 col-md-12 col-sm-12 dashboard-info">
    <div class="row">
        <div class="col-sm-3">
            <div class="dashboard-header no-px">
                <i class="fa fa-user-circle dashboard-icon"></i>
                <span>Account</span>
            </div>
            <div class="col-12 tab-col" [class]="getTabClass('myprofile')" (click)="tabChanged('myprofile')">
                My Profile
            </div>
            <div class="col-12 tab-col" *ngIf="authService.isCorporateAdmin()" [class]="getTabClass('corporateprofile')" (click)="tabChanged('corporateprofile')">
                Corporate Profile
            </div>
            <div class="col-12 tab-col" *ngIf="authService.isCorporateAdmin()" [class]="getTabClass('users')" (click)="tabChanged('users')">
                Users
            </div>
        </div>
        <div class="col-sm-9">
            <div class="tab-body" *ngIf="activeTab === 'myprofile'">
                <pc-my-profile></pc-my-profile>
            </div>
            <div class="tab-body" *ngIf="activeTab === 'corporateprofile'">
                <pc-corporate-profile></pc-corporate-profile>
            </div>
            <div class="tab-body" *ngIf="activeTab === 'users'">
                <pc-member-users></pc-member-users>
            </div>
        </div>
    </div>
</div>
