<div>
    <div class="col-12">
        <div class="col-sm-12 horizontal-scroll mat-table-container">
            <div class="mat-table-spinner" *ngIf="isLoadingResults">
                <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
            <table mat-table [dataSource]="bnplLoans" matSort [matSortActive]="pageTracking.sort" matSortDisableClear
                [matSortDirection]="pageTracking.sortDir">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Borrower </th>
                    <td mat-cell *matCellDef="let element">
                        <a *ngIf="element.transaction?.relatedPartyMemberId" [routerLink]="['/member/details/' + element.transaction?.relatedPartyMemberId]" [routerLinkActive]="['router-link-active']">
                            {{ element.transaction.relatedPartyMemberName }}
                        </a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="phone_number">
                    <th class="centered" mat-header-cell *matHeaderCellDef> Phone #</th>
                    <td class="centered" mat-cell *matCellDef="let element"> {{ element.borrowerPhoneNumber | phone }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th class="centered" mat-header-cell *matHeaderCellDef> Status </th>
                    <td class="centered" mat-cell *matCellDef="let element">
                        <pc-bnpl-loan-badge [parent]="element"></pc-bnpl-loan-badge>
                    </td>
                </ng-container>
                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef> Retail Location </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="element.transaction.transactionLocation"> {{
                            element.transaction.transactionLocation.retailLocationName }} </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="total_amount">
                    <th class="centered" mat-header-cell *matHeaderCellDef> Total Amount </th>
                    <td class="centered" mat-cell *matCellDef="let element">
                        <span class="table-amount" *ngIf="element.transaction?.totalAmount">
                                {{ (element.transaction.totalAmount) | currency }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="loan_amount">
                    <th class="centered" mat-header-cell *matHeaderCellDef> Loan Amount </th>
                    <td class="centered" mat-cell *matCellDef="let element">
                        <span class="table-amount">
                            {{ element.bnplAmount | currency }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="refund_amount">
                    <th class="centered" mat-header-cell *matHeaderCellDef> Refund Amount </th>
                    <td class="centered" mat-cell *matCellDef="let element">
                        <span class="table-amount">
                            {{ element.refundAmount | currency }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="amount_paid">
                    <th class="centered" mat-header-cell *matHeaderCellDef> Amount Paid </th>
                    <td class="centered" mat-cell *matCellDef="let element">
                        <span class="table-amount">
                            {{ element.paidAmount | currency }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="amount_outstanding">
                    <th class="centered" mat-header-cell *matHeaderCellDef> Amount Outstanding </th>
                    <td class="centered" mat-cell *matCellDef="let element">
                        <span class="table-amount">
                            {{ (element.outstandingAmount) | currency }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="payments_remaining">
                    <th class="centered" mat-header-cell *matHeaderCellDef> Payments Remaining </th>
                    <td class="centered" mat-cell *matCellDef="let element">
                        {{ element.remainingPaymentCount }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="final_payment_date">
                    <th class="centered" mat-header-cell *matHeaderCellDef> Scheduled Final Payment </th>
                    <td class="centered" mat-cell *matCellDef="let element">
                        <pc-date-time *ngIf="element.finalPaymentDate" [date]="element.finalPaymentDate"></pc-date-time>
                        <span *ngIf="!element.finalPaymentDate"> {{ 'N/A' }}</span>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="pc-click-row" (click)="loadTransactionDetails(row.transaction.id, $event)"></tr>
            </table>
            <mat-paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions"
                [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
        </div>
    </div>
</div>
