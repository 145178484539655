<div class="row mb-3">
    <div class="col-5">
        <span class="form-title">Questions and Responses</span>
    </div>
    <div class="col-7">
        <span *ngIf="isAuthority() && (registrationStatus === MemberStatus.APPLIED || registrationStatus === MemberStatus.UNDER_REVIEW)">
            <button class="blue-button pull-right" (click)="onAddNote()" type="button"><i class="fa fa-plus pull-left dashboard-icon"></i>
                <div class="pull-right-button">Add Question</div></button></span>
        <span *ngIf="allowResponse() && !authService.isAuthorityOrReviewer()"><button class="blue-button pull-right" (click)="onAddNote()" type="button"><i
                class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add Response</div></button></span>
    </div>
</div>
<div>
    <p><i>This section is for any questions we have during onboarding and reviews.</i></p>
    <pc-notes-table [memberId]="memberId" [type]="CommentType.QUESTION" [memberStatus]="registrationStatus" [changeTrigger]="changeTrigger" (notesDetailChange)="notifyOfNotesChange()"></pc-notes-table>

    <div class="button-panel pull-right">
        <pc-submit-button [submit]="onNavigate" [text]="'Next'" [rightIcon]="'arrow-right'" *ngIf="task && (isOnboarding() || isAuthority())"></pc-submit-button>
    </div>
</div>
