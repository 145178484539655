import { Component, OnInit } from '@angular/core';
import { AuthService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-accounts',
    templateUrl: './member-accounts.component.html'
})
export class MemberAccountsComponent implements OnInit {

    memberId: string;

    constructor(private authService: AuthService) {}

    ngOnInit() {
        this.memberId = this.authService.getProfile().memberId;
    }
}
