<div class="col-12 dashboard-info">
    <div class="row">
        <div class="dashboard-header mb-2">
            Search Results
        </div>
    </div>
    <div class="tab-row">
        <div [ngClass]="getTabClass(EntitySearchType.MEMBER)"
             (click)="setSearchType(EntitySearchType.MEMBER)">
            <div matBadgeColor="warn" matBadge="{{memberCount}}" [matBadgeOverlap]="false" [matBadgeHidden]="memberCount === 0" matTooltip="Total Members">Members</div>
        </div>
        <div [ngClass]="getTabClass(EntitySearchType.MEMBER_ACCOUNT)"
            (click)="setSearchType(EntitySearchType.MEMBER_ACCOUNT)">
            <div matBadgeColor="warn" matBadge="{{memberAccountCount}}" [matBadgeOverlap]="false" [matBadgeHidden]="memberAccountCount === 0" matTooltip="Total Accounts">Accounts</div>
        </div>
        <div [ngClass]="getTabClass(EntitySearchType.USERS)"
            (click)="setSearchType(EntitySearchType.USERS)">
            <div matBadgeColor="warn" matBadge="{{userCount}}" [matBadgeOverlap]="false" [matBadgeHidden]="userCount === 0" matTooltip="Total Users">Users</div>
        </div>
        <div [ngClass]="getTabClass(EntitySearchType.KEY_PARTIES)"
             (click)="setSearchType(EntitySearchType.KEY_PARTIES)">
            <div matBadgeColor="warn" matBadge="{{keyPartyCount}}" [matBadgeOverlap]="false" [matBadgeHidden]="keyPartyCount === 0" matTooltip="Total Key Parties">Key Parties</div>
        </div>
        <div [ngClass]="getTabClass(EntitySearchType.MERCHANT)"
             (click)="setSearchType(EntitySearchType.MERCHANT)">
            <div matBadgeColor="warn" matBadge="{{merchantCount}}" [matBadgeOverlap]="false" [matBadgeHidden]="merchantCount === 0" matTooltip="Total Merchants">Merchants</div>
        </div>
        <div [ngClass]="getTabClass(EntitySearchType.CONSUMER)"
            (click)="setSearchType(EntitySearchType.CONSUMER)">
            <div matBadgeColor="warn" matBadge="{{consumerCount}}" [matBadgeOverlap]="false" [matBadgeHidden]="consumerCount === 0" matTooltip="Total Consumers">Consumers</div>
        </div>
        <div [ngClass]="getTabClass(EntitySearchType.BANK_ACCOUNT)"
             (click)="setSearchType(EntitySearchType.BANK_ACCOUNT)">
            <div matBadgeColor="warn" matBadge="{{bankAccountCount}}" [matBadgeOverlap]="false" [matBadgeHidden]="bankAccountCount === 0" matTooltip="Total Bank Accounts">Banking Info</div>
        </div>
    </div>

    <div class="row" *ngIf="searchType === EntitySearchType.MEMBER">
        <div class="col-12">
            <pt-member-search-results [searchType]="searchType" [searchTerm]="searchTerm">
            </pt-member-search-results>
        </div>
    </div>
    <div class="row" *ngIf="searchType === EntitySearchType.MEMBER_ACCOUNT">
        <div class="col-12">
            <pt-member-account-search-results [searchTerm]="searchTerm">
            </pt-member-account-search-results>
        </div>
    </div>
    <div class="row" *ngIf="searchType === EntitySearchType.MERCHANT">
        <div class="col-12">
            <pt-member-search-results [searchType]="searchType" [searchTerm]="searchTerm">
            </pt-member-search-results>
        </div>
    </div>
    <div class="row" *ngIf="searchType === EntitySearchType.CONSUMER">
        <div class="col-12">
            <pt-member-search-results [searchType]="searchType" [searchTerm]="searchTerm">
            </pt-member-search-results>
        </div>
    </div>
    <div class="row" *ngIf="searchType === EntitySearchType.USERS">
        <div class="col-12">
            <pt-user-account-search-results [searchTerm]="searchTerm">
            </pt-user-account-search-results>
        </div>
    </div>
    <div class="row" *ngIf="searchType === EntitySearchType.KEY_PARTIES">
        <div class="col-12">
            <pt-key-party-search-results [searchTerm]="searchTerm">
            </pt-key-party-search-results>
        </div>
    </div>
    <div class="row" *ngIf="searchType === EntitySearchType.BANK_ACCOUNT">
        <div class="col-12">
            <pt-bank-account-search-results [searchTerm]="searchTerm">
            </pt-bank-account-search-results>
        </div>
    </div>
</div>
