<form [formGroup]="formGroup">
    <pc-form-selector *ngIf="paymentMethods.length > 1" [required]="true" [label]="'Payment Method'"  [formCtrl]="'transactionTypeCtrl'" [formGroup]="formGroup" [options]="paymentMethods"
        [idValue]="'id'" [displayValue]="'name'" [defaultValue]="''" (selected)="transactionTypeChanged($event)">
    </pc-form-selector>
    <pc-form-selector [required]="true" [label]="'Select Payment Date'"  [formCtrl]="'paymentDateTypeCtrl'" [formGroup]="formGroup" [options]="PAYMENT_DATE_TYPE_OPTIONS"
        [idValue]="'id'" [displayValue]="'name'" (selected)="onPaymentDateTypeChange($event)">
    </pc-form-selector>
    <div class="onboarding-required" *ngIf="requiresDueDiligence"><i class="fa fa-exclamation"></i>Vendor requires diligence and cannot be scheduled</div>
    <pc-form-date-picker *ngIf="showDatePicker()" [defaultEmpty]="true" [label]="'Payment Date'" [formCtrl]="'transactionDateCtrl'"
        [formGroup]="formGroup" (dateSelected)="dateChanged($event)" >
    </pc-form-date-picker>
    <pc-text-input [required]="true" [label]="'From Account'" [placeholder]="'From Account'"
        [formCtrl]="'memberAccountNameCtrl'" [formGroup]="formGroup">
    </pc-text-input>
    <pc-form-text-area [label]="'Reason for Payment'" maxlength="1024" [formCtrl]="'notesCtrl'" [formGroup]="formGroup">
    </pc-form-text-area>
</form>
