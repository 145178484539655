export class PackageSummary {
    manifestNumber: string;
    packageLabel: string;
    productCategory: string;
    shippedQuantity: number;
    quantityUnits: string;
    source: string;
    receivedDateTime: Date;
    shipperFacilityName: string;
    recipientFacilityName: string;
    value: number;
}