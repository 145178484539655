<div>
    <form [formGroup]="documentForm">
        <div>

            <div class="modal-header">
                <label class="form-title">{{title}}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div>
                    <pc-text-option-selector [required]="true" [label]="'Document Type'"
                        [options]="documentTypes"
                        [translated]="true"
                        [formCtrl]="'documentTypeCtrl'"
                        [formGroup]="documentForm">
                    </pc-text-option-selector>
                    <pc-text-area [required]="false" [hidden]="hideDescription" [label]="'Document Description'" [formCtrl]="'notesCtrl'" [formGroup]="documentForm" [placeholder]="''">
                    </pc-text-area>
                </div>
            </div>
            <div *ngIf="files && files.length > 0">
                <hr class="separator">
                <p *ngFor="let file of files; let i = index">
                    <span class="dashboard-list-a"><i class="fa fa-paperclip"></i>{{file.name}} </span><i class="fa fa-times" (click)="deleteFile(i)"></i>
                </p>
                <hr class="separator">
            </div>
            <div class="modal-footer">
                <button pc-no-dbl-click class="blue-button" type="button" (click)="upload.click()" [disabled]="isDocumentFormValid()" submit-button>{{allowMultiple?'Attach File(s)':'Attach File'}}</button>
                <input hidden type="file" [multiple]="allowMultiple" id="upload" #upload (change)="selectFile($event)" placeholder="Upload file"
                    accept=".pdf,.doc,.docx">
                <pc-submit-button [enabled]="isDocumentFormValid" [submit]="onSubmit" [text]="'Add Document'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
