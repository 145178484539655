<div>
    <form *ngIf="linkUserForm" [formGroup]="linkUserForm">
        <div>
            <div class="modal-header">
                <label class="form-title">{{'Link User to Account'}}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-medium">
                <div *ngIf="eligibleUsers.length">
                    <fieldset class="form-group">
                        <label class="form-label">Select User</label>
                        <div>
                            <select class="form-control" [formControlName]="'userIdCtrl'" [required]="true" type="text">
                                <option *ngFor="let user of eligibleUsers" [value]="user.id">{{user.name}} ({{user.email}})</option>
                            </select>
                        </div>
                    </fieldset>
                    <pc-form-text-input  [label]="'Position'" [formCtrl]="'positionCtrl'" [formGroup]="linkUserForm">
                    </pc-form-text-input>
                </div>
                <pc-error-message [width]="''" [error]="errorMessage"></pc-error-message>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="isLinkUserFormValid" [submit]="onSubmit" [text]="'Link User'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
