import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ContractSectionTerm, FieldMetadata, ContractTemplate, FieldDataType } from 'projects/services/src/public-api';
import { UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'pc-sc-term',
    templateUrl: './term.component.html',
    styleUrls: ['./term.component.scss']
})
export class TermComponent implements OnInit {

    @Input() mode = 'VIEW';
    @Input() contractTemplate: ContractTemplate;
    @Input() term: ContractSectionTerm;

    @Output() termSelected: EventEmitter<ContractSectionTerm> = new EventEmitter<ContractSectionTerm>();

    formGroup = this.formbuilder.group([]);
    termHtml: string;
    orderTerm = false;

    @ViewChild('termText', { read: ViewContainerRef }) container: ViewContainerRef;

    constructor(private formbuilder: UntypedFormBuilder,
                private datePipe: DatePipe) {}

    ngOnInit() {
        if (this.mode === 'SELECT' || (this.mode === 'VIEW' && this.term.included)) {
            this.termHtml = this.term.text;
            Object.keys(this.term.fieldMap).forEach((key: string) => {
                const regex = new RegExp('\\[\\{' + key + '\\}\\]', 'gi');
                const metaData: FieldMetadata = this.term.fieldMap[key];
                if (metaData.type === 'ORDER') {
                    this.orderTerm = true;
                    this.termHtml = this.termHtml.replace(regex, '');
                } else if (this.hasValue(metaData)) {
                    if (metaData.type === 'DATE') {
                        this.termHtml = this.termHtml.replace(regex, '<strong>' + this.datePipe.transform(this.getValue(metaData), 'mediumDate') + '</strong>');
                    } else {
                        this.termHtml = this.termHtml.replace(regex, '<strong>' + this.getValue(metaData) + '</strong>');
                    }
                } else {
                    if (metaData.required) {
                        this.termHtml = this.termHtml.replace(regex, '<strong class="sc-required" ngNonBindable>[' + key + ']</strong>');
                    } else {
                        this.termHtml = this.termHtml.replace(regex, '<strong ngNonBindable>[' + key + ']</strong>');
                    }
                }
            });
        }
    }

    termChanged() {
        this.termSelected.emit(this.term);
    }

    hasValue(metaData: FieldMetadata) {
        switch (metaData.type) {
            case FieldDataType.DATE:
                return !!metaData.dateValue;
            case FieldDataType.ICON:
            case FieldDataType.LINK:
            case FieldDataType.TEXTAREA:
            case FieldDataType.TEXTINPUT:
            case FieldDataType.PROGRAMMATIC:
            case FieldDataType.ENUMERATED:
                return !!metaData.stringValue;

            case FieldDataType.NUMBER:
                // eslint-disable-next-line no-undefined
                return metaData.numericValue !== null && metaData.numericValue !== undefined;

            default:
                return true;
        }
    }

    getValue(metaData: FieldMetadata) {
        switch (metaData.type) {
            case FieldDataType.DATE:
                return metaData.dateValue;

            case FieldDataType.ICON:
            case FieldDataType.LINK:
            case FieldDataType.TEXTAREA:
            case FieldDataType.TEXTINPUT:
            case FieldDataType.PROGRAMMATIC:
            case FieldDataType.ENUMERATED:
                return metaData.stringValue;

            case FieldDataType.NUMBER:
                return metaData.numericValue;

            case FieldDataType.TERMREFERENCE:
                return this.findTermIndex(metaData.id);

            default:
                return 'UNKNOWN';
        }
    }

    findTermIndex(termReference: string) {
        for (const section of this.contractTemplate.sections) {
            for (const term of section.terms) {
                if (term.name === termReference) {
                    return term.index;
                } else {
                    for (const subterm of term.subTerms) {
                        if (subterm.name === termReference) {
                            return term.index;
                        }
                    }
                }
            }
        }
        return 'UNKNOWN';
    }
}
