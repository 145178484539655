import { Component, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription, Observable, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BankAccount, BankAccountStatus } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-bank-account-selector',
    templateUrl: './bank-account-selector.component.html',
    styleUrls: ['./bank-account-selector.component.scss']
})
export class BankAccountSelectorComponent implements OnInit, AfterViewInit, OnChanges {

    BankAccountStatus = BankAccountStatus;

    @Input() bankAccounts: BankAccount[];
    @Input() required: boolean;
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() placeholder = '';

    @Output() bankAccountSelected: EventEmitter<any> = new EventEmitter<any>();

    autoSelect: Subscription;

    autoComplete: Observable<BankAccount[]> = null;
    autoCompleteControl = new UntypedFormControl('');
    filteredOptions: BankAccount[] = [];

    @ViewChild('autoSelect', {read: MatAutocompleteTrigger}) trigger: MatAutocompleteTrigger;

    ngOnInit() {
        this.formGroup.addControl('autoCompleteCtrl', this.autoCompleteControl);
        if (this.required) {
            this.autoCompleteControl.setValidators(Validators.required);
        }

        if (this.formGroup.controls[this.formCtrl].disabled) {
            this.autoCompleteControl.disable();
        } else {
            this.autoComplete = this.autoCompleteControl.valueChanges.pipe(
                startWith(this.formGroup.controls[this.formCtrl].value),
                switchMap((value) => {
                    return this.filter(value || '');
                })
            );
        }
        this.loadAccount();
        this.formGroup.controls[this.formCtrl].valueChanges.subscribe((_value) => {
            this.loadAccount();
        });
    }

    ngAfterViewInit() {
        this.subscribeToClosingActions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.bankAccounts && !changes.bankAccounts.firstChange) {
            this.filteredOptions = this.bankAccounts;
            this.loadAccount();
        }
    }

    loadAccount() {
        if (this.formGroup.controls[this.formCtrl].value) {
            const linkedAccount = this.bankAccounts.find((bankAccount: BankAccount) => {
                return bankAccount.id === this.formGroup.controls[this.formCtrl].value;
            });
            if (linkedAccount) {
                this.setAccountName(linkedAccount);
            }
        }
    }

    onFocus() {
        if (!this.formGroup.controls[this.formCtrl].value) {
            this.autoCompleteControl.setValue('');
        }
    }

    subscribeToClosingActions() {
        if (this.autoSelect && !this.autoSelect.closed) {
            this.autoSelect.unsubscribe();
        }

        this.autoSelect = this.trigger.panelClosingActions.subscribe((e) => {
            if (!e || !e.source) {
                const selectedOption = this.filteredOptions
                    .map((option) => {
                        return option.name;
                    })
                    .find((option) => {
                        return option === this.formGroup.controls[this.formCtrl].value;
                    });

                if (!selectedOption) {
                    this.formGroup.controls[this.formCtrl].markAsPristine();
                    this.formGroup.controls[this.formCtrl].setValue('');
                    this.autoCompleteControl.setValue('');
                    this.selectionChanged(new BankAccount());
                }
            }
        }, () => {
            return this.subscribeToClosingActions();
        }, () => {
            return this.subscribeToClosingActions();
        });
    }

    filter(value: string): Observable<any[]> {
        const filterValue = value.toLowerCase();
        this.filteredOptions = this.bankAccounts.filter((option) => {
            return option.name.toLowerCase().indexOf(filterValue) >= 0;
        });
        return of(this.filteredOptions);
    }

    selectionChanged(bankAccount: BankAccount, event?: any) {
        if (!event || event?.isUserInput) {
            this.formGroup.controls[this.formCtrl].setValue(bankAccount.id);
            this.setAccountName(bankAccount);
            this.bankAccountSelected.emit(bankAccount);
        }
    }

    isSelected() {
        return this.formGroup.controls[this.formCtrl].value;
    }

    isDisabled() {
        return this.formGroup.controls[this.formCtrl].disabled;
    }

    onIconClick(event: any) {
        if (this.isSelected()) {
            this.selectionChanged(new BankAccount());
        }
        event.stopPropagation();
        this.trigger.openPanel();
    }

    setAccountName(bankAccount: BankAccount) {
        if (bankAccount.id) {
            this.autoCompleteControl.setValue(`${bankAccount.name} (${this.getAccountNumber(bankAccount.accountNumber)})`);
        } else {
            this.autoCompleteControl.setValue('');
        }
    }

    getAccountNumber(accountNumber: string) {
        return '*****' + accountNumber.substring(accountNumber.length - 4);
    }
}
