import { Component } from '@angular/core';
import { BusinessClientStatus, MemberAccount, MemberAccountStatus } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-member-account-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class MemberAccountBadgeComponent extends BaseBadgeComponent<MemberAccount> {

    setBadgeData() {
        this.badgeToolTip = this.parent.status;
        switch (this.parent.status) {
            case MemberAccountStatus.ACTIVE:
                this.badgeText = 'ACTIVE';
                this.badgeClass = 'green-badge';
                break;
            case MemberAccountStatus.UNDER_REVIEW:
                this.badgeText = 'UNDER REVIEW';
                this.badgeClass = 'orange-badge';
                break;
            case MemberAccountStatus.PENDING:
                this.badgeText = 'PENDING';
                this.badgeClass = 'orange-badge';
                break;
            case MemberAccountStatus.BLOCKED:
                this.badgeText = 'BLOCKED';
                this.badgeClass = 'red-badge';
                break;
            case MemberAccountStatus.FROZEN:
                this.badgeText = 'FROZEN';
                this.badgeClass = 'red-badge';
                break;
            case BusinessClientStatus.REJECTED:
                this.badgeText = 'REJECTED';
                this.badgeClass = 'red-badge';
                break;
            case MemberAccountStatus.DISABLED:
                this.badgeText = 'DISABLED';
                this.badgeClass = 'red-badge';
                break;
        }
    }
}
