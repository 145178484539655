<div>
    <span *ngIf="member">
        <a *ngIf="isBusinessMember && (!isPending || directLink)" class="member-details-font" [ngClass]="linkType"
           [routerLink]="['/administration/member/' + memberId]"
           [queryParams]="{_activeTab: activeTab}" [routerLinkActive]="['router-link-active']">
            {{ linkDescription }}
        </a>
        <a *ngIf="isBusinessMerchant && (!isPending || directLink)" class="member-details-font" [ngClass]="linkType"
           [routerLink]="['/administration/merchant/' + memberId]"
           [queryParams]="{_activeTab: activeTab}" [routerLinkActive]="['router-link-active']">
            {{ linkDescription }}
        </a>
        <a *ngIf="isIndividualMerchant && (!isPending || directLink)" class="member-details-font" [ngClass]="linkType"
           [routerLink]="['/administration/individual/' + memberId]"
           [queryParams]="{_activeTab: activeTab}" [routerLinkActive]="['router-link-active']">
            {{ linkDescription }}
        </a>
        <a *ngIf="isPending && !directLink" class="member-details-font" [ngClass]="linkType" (click)="pendingMemberDetails()">
            {{ linkDescription }}
        </a>
    </span>
    <span *ngIf="!member" class="member-details-font" [ngClass]="linkType">
        {{ linkDescription }}
    </span>
</div>
