import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableUtils } from 'projects/components/src/lib/table-utils.service';
import { FilingInstitution, ReportStatus } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-authority-ctr-reports',
    templateUrl: './authority-ctr-reports.component.html'
})
export class AuthorityCtrReportsComponent implements OnInit {

    @Input() memberId = '';
    @Input() reportStatus: ReportStatus;
    @Input() filingInstitution: FilingInstitution = FilingInstitution.NBCU;

    submissionType = 'CTR';
    activeTab = '';
    changeTrigger = 1;

    constructor(private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        const queryParams = this.route.snapshot.queryParams;
        this.activeTab = queryParams['_activeTab'] || '';
        this.memberId = queryParams['memberId'] || '';
        this.reportStatus = queryParams['status'] || ReportStatus.ALL;
        this.filingInstitution = queryParams['filingInstitution'] || FilingInstitution.NBCU;
        this.route.queryParams.subscribe((params) => {
            this.memberId = params['memberId'] || '';
            this.activeTab = params['_activeTab'] || '';
            this.reportStatus = params['status'] || ReportStatus.ALL;
            this.filingInstitution = params['filingInstitution'] || FilingInstitution.NBCU;
        });
    }

    setActiveTab(activeTab: string) {
        if (this.activeTab ===  activeTab) {
            return;
        }
        this.activeTab = activeTab;
        this.updateQueryParams(false);
    }

    getTabClass(tabOption: string) {
        if (this.activeTab === tabOption) {
            return 'tab-navigation-selected';
        } else {
            return 'tab-navigation';
        }
    }

    updateQueryParams(replace: boolean) {
        const queryParams = { memberId: this.memberId, _activeTab: this.activeTab, status: this.reportStatus, filingInstitution: this.filingInstitution };
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }
}
