import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
    AuthService, BankingIntegrationProvider, BusinessCategory, CustomValidators, Member, MemberFunction, MemberService, MemberType, OnboardingTrackingService,
    Referral, Signature, WorkflowService
} from 'projects/services/src/public-api';

@Component({
    selector: 'pc-member-application-submit',
    templateUrl: './member-application-submit.component.html',
    styleUrls: ['./member-application-submit.component.scss']
})
export class MemberApplicationSubmitComponent implements OnInit {

    @Input() memberId: string;
    @Input() taskId: string;
    @Input() businessCategory: string;

    @Output() updatedTermsAccepted: EventEmitter<Signature> = new EventEmitter<Signature>();

    memberName: string;
    referringMember: Member;
    termsForm: UntypedFormGroup;
    isATMBusiness = false;
    isUpdatedTerms = false;

    constructor(private workflowService: WorkflowService,
                private authService: AuthService,
                private formbuilder: UntypedFormBuilder,
                private memberService: MemberService) {}

    ngOnInit() {
        this.onComplete = this.onComplete.bind(this);
        this.termsAccepted = this.termsAccepted.bind(this);
        this.acceptUpdatedTerms = this.acceptUpdatedTerms.bind(this);
        this.cancel = this.cancel.bind(this);
        this.isUpdatedTerms = !this.taskId;

        this.memberName = this.authService.getProfile().name;
        if (this.businessCategory === BusinessCategory.ATM_BUSINESS) {
            this.isATMBusiness = true;
        }
        this.memberService.getMemberReferrer(this.memberId).subscribe((referral: Referral) => {
            if (referral) {
                if (referral.refereeMemberId === this.memberId) {
                    if (referral.refererMemberId) {
                        this.memberService.loadMember(referral.refererMemberId).subscribe((member: Member) => {
                            this.referringMember = member;
                        });
                    }
                }
            }
        });

        if (!this.isUpdatedTerms) {
            this.termsForm = this.formbuilder.group({
                memberTruthfulCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                ownersCompleteCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                memberTermsCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                platformTermsCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                privacyTermsCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                mobileAgreementCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                remoteDepositCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                eSignCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                eStatementCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                partnerAttachTermsCtrl: new UntypedFormControl(true),
                atmPaymentProcessorAccessGrantedCtrl: new UntypedFormControl(false),
                atmWithdrawalOnlyAcknowledgedCtrl: new UntypedFormControl(false),
                atmSiteOwnerContractAccessGrantedCtrl: new UntypedFormControl(false),
                nameCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.personName]),
                titleCtrl: new UntypedFormControl('', [Validators.required, Validators.min(3), CustomValidators.noLeadingOrTrailingWhitespace])
            });
            if (this.isATMBusiness) {
                this.termsForm.get('atmPaymentProcessorAccessGrantedCtrl').setValidators([Validators.requiredTrue]);
                this.termsForm.get('atmWithdrawalOnlyAcknowledgedCtrl').setValidators([Validators.requiredTrue]);
                this.termsForm.get('atmSiteOwnerContractAccessGrantedCtrl').setValidators([Validators.requiredTrue]);
            }
        } else {
            this.termsForm = this.formbuilder.group({
                memberTermsCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                platformTermsCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                privacyTermsCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                mobileAgreementCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                remoteDepositCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                eSignCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                eStatementCtrl: new UntypedFormControl(false, [Validators.requiredTrue]),
                nameCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.personName]),
                titleCtrl: new UntypedFormControl('', [Validators.required, Validators.min(3), CustomValidators.noLeadingOrTrailingWhitespace])
            });
        }
    }

    onComplete() {
        let memberCompletionRequired: string;
        /**
         * @deprecated additionalInfoRequired can be removed once there are no more onboarding flows
         */
        let additionalInfoRequired: string;
        if (OnboardingTrackingService.isApplicationValid(MemberType.BUSINESS, this.isAuthority())) {
            additionalInfoRequired = 'true';
            memberCompletionRequired = 'TRUE';
        } else {
            additionalInfoRequired = 'false';
            memberCompletionRequired = 'FALSE';
        }
        const body = {
            variables: {
                additionalInfoRequired : {
                    type: 'String',
                    value: additionalInfoRequired
                },
                memberCompletionRequired : {
                    type: 'String',
                    value: memberCompletionRequired
                }
            }
        };

        this.memberService.saveSignature(this.getSignature()).subscribe(() => {
            this.workflowService.completeTask(this.taskId, body, this.logoutAndMessage).subscribe(() => {
                this.logoutAndMessage();
            });
        });
    }

    logoutAndMessage() {
        this.authService.logoutAndMessage(1);
    }

    isMemberCompletionRequired() {
        return !OnboardingTrackingService.isApplicationValid(MemberType.BUSINESS, this.isAuthority());
    }

    termsAccepted() {
        return this.termsForm.valid && (this.isUpdatedTerms || !this.isMemberCompletionRequired());
    }

    isAuthority() {
        return this.authService.isAuthority();
    }

    isReferredByPartner() {
        return this.referringMember && this.referringMember.memberFunctions.indexOf(MemberFunction.PARTNER) >= 0;
    }

    private getSignature() {
        const signature = new Signature();
        signature.memberId = this.memberId;
        signature.signature = this.termsForm.get('nameCtrl').value;
        signature.title = this.termsForm.get('titleCtrl').value;
        signature.memberTermsReviewed = this.termsForm.get('memberTermsCtrl').value;
        signature.privacyTermsReviewed = this.termsForm.get('privacyTermsCtrl').value;
        signature.platformTermsReviewed = this.termsForm.get('platformTermsCtrl').value;
        signature.mobileAgreementReviewed = this.termsForm.get('mobileAgreementCtrl').value;
        signature.remoteDepositReviewed = this.termsForm.get('remoteDepositCtrl').value;
        signature.eSignReviewed = this.termsForm.get('eSignCtrl').value;
        signature.eStatementReviewed = this.termsForm.get('eStatementCtrl').value;
        if (!this.isUpdatedTerms) {
            signature.warrantTruthful = this.termsForm.get('memberTruthfulCtrl').value;
            signature.ownersComplete = this.termsForm.get('ownersCompleteCtrl').value;
            signature.partnerAttachTermsReviewed = this.termsForm.get('partnerAttachTermsCtrl').value;
            if (this.isATMBusiness) {
                signature.atmPaymentProcessorAccessGranted = this.termsForm.get('atmPaymentProcessorAccessGrantedCtrl').value;
                signature.atmWithdrawalOnlyWarrant = this.termsForm.get('atmWithdrawalOnlyAcknowledgedCtrl').value;
                signature.atmSiteOwnerContractAccessGranted = this.termsForm.get('atmSiteOwnerContractAccessGrantedCtrl').value;
            }
        }
        signature.bankTerms = BankingIntegrationProvider.NBCU;
        return signature;
    }

    acceptUpdatedTerms() {
        this.updatedTermsAccepted.emit(this.getSignature());
    }

    cancel() {
        this.updatedTermsAccepted.emit(null);
    }
}
