import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { Activity, AuthService, LenderService, LoanStatus, LendingDetails } from 'projects/services/src/public-api';
@Component({
    selector: 'pc-lending-gadget',
    templateUrl: './lending-gadget.component.html',
    styleUrls: ['./lending-gadget.component.scss']
})
export class LendingGadgetComponent implements OnInit {

    @Input() changeTrigger: number;

    opportunities: Activity;
    active: Activity;
    closed: Activity;
    lendingDetails: LendingDetails[] = [];
    displayedColumns: string[] = ['label', 'numberofmembers', 'dollarsvaluepotential'];

    @ViewChild('lendingDetailsTable') table: MatTable<LendingDetails>;

    constructor(private lenderService: LenderService,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.lendingDetails = [];
        const memberId = this.authService.getProfile().memberId;
        this.lenderService.activityCountByLenderIdAndStatuses(memberId, [LoanStatus.PENDING, LoanStatus.DILIGENCE]).subscribe((opportunities: Activity) => {
            this.opportunities = opportunities;
            this.lenderService.activityCountByLenderIdAndStatuses(memberId, [LoanStatus.ACTIVE]).subscribe((active: Activity) => {
                this.active = active;
                this.lenderService.activityCountByLenderIdAndStatuses(memberId, [LoanStatus.CLOSED, LoanStatus.DECLINED]).subscribe((closed: Activity) => {
                    this.closed = closed;
                    let opportunitiesDetails = new LendingDetails('Opportunities', opportunities.quantity, opportunities.amount);
                    this.lendingDetails.push(opportunitiesDetails);
                    let activeDetails = new LendingDetails('Active', active.quantity, active.amount);
                    this.lendingDetails.push(activeDetails);
                    let closedDetails = new LendingDetails('Closed', closed.quantity, closed.amount);
                    this.lendingDetails.push(closedDetails);
                    this.table.renderRows();
                });
            });
        });
    }

    checkIfActivityEmpty(value: any) {
        if (!value) {
            return 0;
        } else {
            return value;
        }
    }
}
