import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
    BusinessClient, Invoice, ManifestSelection, TransactionFormService, TransactionEntrySelection, TransactionSource, TransactionType
} from 'projects/services/src/public-api';
import { v4 as uuidv4 } from 'uuid';
import { BaseTransactionComponent } from '../../common/base-transaction-component/base-transaction.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

@Component({
    selector: 'pt-business-non-cash-deposit',
    templateUrl: './business-non-cash-deposit.component.html',
    styleUrls: ['./business-non-cash-deposit.component.scss'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS,
        useValue: { displayDefaultIndicatorType: false }
    }]
})
export class BusinessNonCashDepositComponent extends BaseTransactionComponent<Invoice> implements OnInit {

    readonly NON_CASH_DEPOST_METHODS = [{
        id: TransactionType.INCOMING_WIRE_FROM_MERCHANT,
        name: 'Wire From Merchant'
    }, {
        id: TransactionType.INCOMING_ACH_FROM_MERCHANT,
        name: 'ACH From Merchant'
    }];

    @Output() closeTransactionModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private transactionFormService: TransactionFormService,
                protected breakpointObserver: BreakpointObserver,
                protected cdr: ChangeDetectorRef) {
        super(breakpointObserver, cdr);
    }

    ngOnInit() {
        super.ngOnInit();

        this.formGroup = this.transactionFormService.initializeDepositForm();
        this.formGroup.get('memberAccountNameCtrl').setValue(this.memberAccount.accountName);
        this.formGroup.get('sourceCtrl').setValue(TransactionSource.EXTERNAL_TRANSACTION);
    }

    onSelectBusinessClient(selectedBusinessClient: BusinessClient<Invoice>) {
        if (!this.currentBusinessClient || !selectedBusinessClient || this.currentBusinessClient.memberAccountId !== selectedBusinessClient.memberAccountId) {
            this.currentBusinessClient = selectedBusinessClient;
            this.manifests = [];
            this.manifestSupportingDocs = [];
            this.calculateTotal([]);
        }
        this.stepper.reset();
    }

    calculateTotal(selectedInvoices: TransactionEntrySelection<Invoice>[]) {
        if (!this.currentBusinessClient) {
            this.transactionTotal = '0.00';
        } else if (selectedInvoices && selectedInvoices.length) {
            this.currentBusinessClient.transactionEntries = selectedInvoices;
            this.transactionTotal = this.currentBusinessClient.selectedTotal;
        } else {
            this.currentBusinessClient.transactionEntries = [];
            this.transactionTotal = '0.00';
        }
        this.totalChanged.emit(this.transactionTotal);
    }

    onTransactionReviewed() {
        this.viewTerms = true;
        const contentContainer = document.querySelector('.mat-dialog-container');
        contentContainer.scrollTo(0, 0);
    }

    onSubmit(reset: any) {
        const transaction = this.transactionFormService.getTransaction(this.formGroup);
        transaction.submissionReference = uuidv4();
        transaction.payorAccountId = this.currentBusinessClient.memberAccountId;
        transaction.payorMemberId = this.currentBusinessClient.memberId;
        transaction.totalAmount = Number(this.transactionTotal);
        transaction.source = TransactionSource.EXTERNAL_TRANSACTION;
        transaction.manifestSupportingDocuments = this.manifestSupportingDocs || [];
        transaction.transactionDate = null;
        transaction.incomes = this.currentBusinessClient.transactionEntries.map((transactionEntry: TransactionEntrySelection<Invoice>) => {
            return transactionEntry.data;
        });
        transaction.shipmentIds = this.manifests.map((manifest: ManifestSelection) => {
            return manifest.data.id;
        });
        reset();
        this.viewTerms = false;
        this.transactionReviewed.emit(transaction);
    }

    closeModal(event: any) {
        this.closeTransactionModal.emit(event);
    }
}
