import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'pc-view-decision',
    templateUrl: './view-decision.component.html',
    styleUrls: ['./view-decision.component.scss']
})
export class ViewDecisionComponent {

    @Input() tableView: boolean;

    @Output() viewChanged = new EventEmitter<string>();

    view(viewType: string) {
        this.viewChanged.emit(viewType);
    }
}
