import { Component, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'pc-numeric-input',
    templateUrl: './numeric-input.component.html'
})
export class NumericInputComponent {

    @Input() required: boolean;
    @Input() label: string;
    @Input() placeholder = '';
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;

    @Output() changed: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('inputField') inputField: ElementRef;

    focus() {
        this.inputField.nativeElement.focus();
    }

    onChange() {
        this.changed.emit(this.formGroup.controls[this.formCtrl].value);
    }

    onInputChanged(event: any) {
        if (event.keyCode === 190 || event.keyCode === 110) {
            event.preventDefault();
            return;
        }
    }

}
