import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';

@Component({
    selector: 'pc-info-modal',
    templateUrl: './info-modal.component.html'
})
export class InfoModalComponent extends BaseModalComponent<InfoModalComponent> implements OnInit {

    title: string;
    message: string;

    constructor(dialogRef: MatDialogRef<InfoModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.message = data.message;
        this.title = data.title || 'Info';
    }

    ngOnInit() {
        this.close = this.close.bind(this);
    }

    close() {
        super.close(true);
    }
}
