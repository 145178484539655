import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    Address, AddressService, AuthService, BankAccount, BankAccountService, BankAccountSource, BankAccountStatus, LinkedBankAccount, MemberAccount, MemberAccountService,
    PagedResponse, PaymentService
} from 'projects/services/src/public-api';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderComponent } from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { Overlay } from '@angular/cdk/overlay';


@Component({
    selector: 'pt-merchant-member-account-banking-details',
    templateUrl: './merchant-member-account-banking-details.component.html',
    styleUrls: ['./merchant-member-account-banking-details.component.scss']
})
export class MerchantMemberAccountBankingDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

    BankAccountStatus = BankAccountStatus;

    @Input() memberAccount: MemberAccount;

    bankAccountForm: UntypedFormGroup;
    bankAccounts: BankAccount[] = [];

    addressForm: UntypedFormGroup;
    addresses: Address[] = [];

    pageTracking: PageTracking;
    subscription: any;
    resultsLength = 0;
    isLoadingResults = true;
    linkedBankAccounts: LinkedBankAccount[] = [];
    isRequestingResults = true;

    displayedColumns = ['type', 'account_number', 'ach_routing_number', 'wire_routing_number', 'status', 'name', 'holder', 'source', 'date_added', 'financial_institution'];

    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public authService: AuthService,
                private formBuilder: UntypedFormBuilder,
                private bankAccountService: BankAccountService,
                private memberAccountService: MemberAccountService,
                private paymentService: PaymentService,
                private addressService: AddressService,
                private route: ActivatedRoute,
                private router: Router,
                private notifier: NotificationService,
                private overlay: Overlay,
                private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.loadLinkedBankAccount();
        this.loadAddresses();
        this.bankAccountService.getAllActiveBankAccounts(this.memberAccount.memberId).subscribe((bankAccounts: PagedResponse<BankAccount>) => {
            this.bankAccounts = bankAccounts.content.filter((bankAccount) => {
                return bankAccount.source !== BankAccountSource.EXTERNALLY_PROVIDED_ACCOUNT;
            });
        });
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'created', 'desc');
    }

    loadLinkedBankAccount() {
        this.bankAccountService.getLinkedBankAccount(this.memberAccount.memberId, this.memberAccount.id).subscribe((linkedBankAccount: LinkedBankAccount) => {
            this.memberAccount.linkedBankAccount = linkedBankAccount;
            this.bankAccountForm = this.formBuilder.group({
                bankAccountIdCtrl: new UntypedFormControl(this.memberAccount.linkedBankAccount?.bankAccountId)
            });
            if (!(this.authService.isAuthoritySuperUser() || this.authService.isAuthority())) {
                this.bankAccountForm.disable();
            }
        });
    }

    loadAddresses() {
        this.addressService.getMainAndOperationsAddresses(this.memberAccount.memberId).subscribe((locations: PagedResponse<Address>) => {
            this.addresses = locations.content;
            this.addressForm = this.formBuilder.group({
                addressIdCtrl: new UntypedFormControl(this.memberAccount.addressId)
            });
            if (!(this.authService.isAuthoritySuperUser() || this.authService.isAuthority())) {
                this.addressForm.get('addressIdCtrl').disable();
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.bankAccountService.getLinkedBankAccountsForMerchant(this.memberAccount.memberId, this.memberAccount.id, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<LinkedBankAccount>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((linkedBankAccounts: LinkedBankAccount[]) => {
            const results: LinkedBankAccount[] = [];
            for (const linkedBankAccount of linkedBankAccounts) {
                this.loadAccount(linkedBankAccount);
                results.push(linkedBankAccount);
            }
            this.linkedBankAccounts = results;
            UIUtils.scrollDashboardToTop();
        });
    }

    loadAccount(linkedBankAccount: LinkedBankAccount) {
        if (linkedBankAccount.bankAccount.status === BankAccountStatus.BANK_VALIDATION_REQUIRED || linkedBankAccount.bankAccount.status === BankAccountStatus.USER_VALIDATION_REQUIRED || linkedBankAccount.bankAccount.status === BankAccountStatus.VALIDATION_REQUIRED) {
            linkedBankAccount.bankAccount.status = BankAccountStatus.PENDING;
        }
        if (linkedBankAccount.bankAccount.externalAccountId !== null) {
            // load payee address
            this.paymentService.getExternalAccountDetails(linkedBankAccount.bankAccount.externalAccountId).subscribe((response: any) => {
                linkedBankAccount.bankAccount.externalAccountDetails = response;
            });
        }
    }

    getAccountNumber(accountNumber: string) {
        return '*****' + accountNumber.substring(accountNumber.length - 4);
    }

    getRoutingNumber(routingNumber: string) {
        return '*****' + routingNumber.substring(routingNumber.length - 4);
    }

    isEnabled(linkedBankAccount: LinkedBankAccount) {
        return linkedBankAccount && (linkedBankAccount.bankAccount.status === BankAccountStatus.ACTIVE || linkedBankAccount.bankAccount.status === BankAccountStatus.PENDING);
    }

    onSelectBankAccount(bankAccount: BankAccount) {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        if (bankAccount.id && this.isRequestingResults) {
            this.isRequestingResults = false;
            componentRef.instance.title = 'Linking bank account...';
            const linkedAccount = {
                memberId: this.memberAccount.memberId,
                memberAccountId: this.memberAccount.id,
                bankAccountId: bankAccount.id
            };
            this.bankAccountService.linkBankAccount(linkedAccount).subscribe((linkedAccount: LinkedBankAccount) => {
                this.bankAccountService.getAllActiveBankAccounts(this.memberAccount.memberId).subscribe((bankAccounts: PagedResponse<BankAccount>) => {
                    this.bankAccounts = bankAccounts.content.filter((bankAccount) => {
                        return bankAccount.source !== BankAccountSource.EXTERNALLY_PROVIDED_ACCOUNT;
                    });
                    this.isRequestingResults = true;
                    overlayRef.dispose();
                    this.memberAccount.linkedBankAccount = linkedAccount;
                    this.notifier.showSuccess(`Bank account ${bankAccount.name} successfully linked.`);
                    this.refreshEvent.emit();
                });
            });
        } else if (this.memberAccount.linkedBankAccount && this.isRequestingResults) {
            this.isRequestingResults = false;
            componentRef.instance.title = 'Unlinking bank account...';
            this.bankAccountService.unlinkBankAccount(this.memberAccount.memberId, this.memberAccount.id).subscribe(() => {
                this.isRequestingResults = true;
                overlayRef.dispose();
                this.memberAccount.linkedBankAccount = null;
                this.notifier.showSuccess('Bank account unlinked.');
                this.refreshEvent.emit();
            });
        } else {
            overlayRef.dispose();
        }
    }

    onSelectAddress(event: any) {
        const addressId = event.target.value || null;

        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        componentRef.instance.title = addressId ? 'Linking address...' : 'Unlinking address...';
        this.memberAccountService.updateMemberAccount(this.memberAccount.id, {addressId}).subscribe(() => {
            overlayRef.dispose();
            this.memberAccount.addressId = addressId;
            this.notifier.showSuccess(`Address successfully ${addressId ? 'linked' : 'unlinked'}.`);
        });
    }
}
