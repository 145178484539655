<div>
    <form>
        <div>
            <div class="modal-header">
                <label class="form-title">{{title}}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content" *ngIf="!enableNotes">
                <dl class="row" *ngIf = "labelOneTitle  && labelOneDescription">
                    <dt class="col-sm-4 col-lg-4 text-end mb-0">{{ labelOneTitle }}:</dt>
                    <dd class="col-8">{{ labelOneDescription }}</dd>
                </dl>
                <dl class="row" *ngIf = "labelTwoTitle  && labelTwoDescription">
                    <dt class="col-sm-4 col-lg-4 text-end">{{ labelTwoTitle }}:</dt>
                    <dd class="col-8">{{ labelTwoDescription }}</dd>
                </dl>
                <div class="col-12 mt-2">
                    <p [innerHTML]="description"></p>
                    <p *ngIf="confirmMessage" >{{confirmMessage}}</p>
                </div>
            </div>
            <div *ngIf="enableNotes" class="modal-content-small">
                <pc-text-area [required]="true" [label]="'Notes:'" [placeholder]="''" [formCtrl]="'notesCtrl'" [formGroup]="notesForm">
                </pc-text-area>
            </div>
            <div class="modal-footer">
                <pc-submit-button [submit]="confirm" [text]="confirmText" [enabled]="isNotesValid"></pc-submit-button>
                <pc-submit-button [submit]="reject" [text]="rejectText"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
