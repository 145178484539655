import { Upload } from './upload';
import { Address } from './address';
import { Member } from './member';
import { User } from './user';

export class KeyParty {

    id?: string;
    created?: Date;
    updated?: Date;
    corporateKeyPartyId?: string;
    corporateKeyParty?: CorporateKeyParty;
    individualKeyPartyId?: string;
    individualKeyParty?: IndividualKeyParty;
    memberId: string;
    member?: Member;
    userId?: string;
    user?: User;
    percentOwnership?: number;
    name?: string;
    employer?: string;
    professionalTitle?: string;
    owner?: boolean;
    officer?: boolean;
    director?: boolean;
    contactPerson?: boolean;
    adverseMediaScreen?: Upload[];
    idRiskScreen?: Upload[];
    licenseHolder?: boolean;
    contactOnly?: boolean;
    status?: KeyPartyStatus;
    type?: KeyPartyType;

    // adhoc fields
    isDeleteable?: boolean;
}

export class CorporateKeyParty {

    id: string;
    created: Date;
    updated: Date;

    name: string;
    dbaName: string;
    taxId: string;
    businessType: string;
    email: string;
    officePhone: string;
    address: Address;
}

export class IndividualKeyParty {
    id: string;
    created: Date;
    updated: Date;

    firstName: string;
    middleName: string;
    lastName: string;
    name: string;

    otherSurnameOrAlias: string;
    birthDate: Date;

    email: string;
    phone: string;
    usCitizen: boolean;
    taxId: string;
    nationality: string;
    passportNumber: string;
    passportExpirationDate: Date;

    address: Address;
}

export enum KeyPartyType {
    INDIVIDUAL = 'INDIVIDUAL',
    CORPORATE = 'CORPORATE',
    CORPORATE_ENTITY = 'CORPORATE_ENTITY'
}

export enum KeyPartyStatus {
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED'
}
