import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
    Bill, BusinessClient, BusinessClientStatus, SupportedTransactionType, TransactionType, CorporateEntity, MemberAccountType, MemberAccount
} from 'projects/services/src/public-api';
import { ErrorType } from 'projects/components/src/public-api';

@Component({
    selector: 'pt-confia-client-step',
    templateUrl: './confia-client-step.component.html'
})
export class ConfiaClientStepComponent implements OnInit {

    readonly ACCOUNTING_NOT_LINKED_ERROR = {
        type: ErrorType.WARN,
        message: 'Please link an accounting system to see bills for Confia members'
    };

    TransactionType = TransactionType;
    MemberAccountType = MemberAccountType;
    SupportedTransactionType = SupportedTransactionType;

    @Input() isAccountingLinked = false;
    @Input() formGroup: UntypedFormGroup;
    @Input() achDirectPaymentEnabled = false;

    @Output() businessClientSelected: EventEmitter<BusinessClient<Bill>> = new EventEmitter<BusinessClient<Bill>>();
    @Output() nextStep: EventEmitter<null> = new EventEmitter<null>();

    currentBusinessClient: BusinessClient<Bill>;

    constructor() {}

    ngOnInit() {
        this.next = this.next.bind(this);
    }

    onSelectConfiaMemberAccount(memberAccount: MemberAccount) {
        if (memberAccount.id) {
            this.currentBusinessClient = new BusinessClient<Bill>(memberAccount.id,
                memberAccount.memberId,
                memberAccount.member.name,
                (memberAccount.member as CorporateEntity).dbaName,
                BusinessClientStatus[memberAccount.member.status],
                memberAccount.member.source,
                memberAccount.member.memberType,
                TransactionType.WALLET_TO_WALLET_PAYMENT);
        } else {
            this.currentBusinessClient = null;
        }
        this.businessClientSelected.emit(this.currentBusinessClient);
    }

    next() {
        this.nextStep.emit();
    }

}
