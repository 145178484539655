<ng-container *ngIf="isVisible()">
    <div class="row mb-3">
        <div class="col-5">
            <span class="form-title">Member Communications</span>
        </div>
    </div>

    <pc-my-messages [memberId]="member?.id"
        [memberName]="member?.name"
        [memberStatus]="member?.status">
    </pc-my-messages>
</ng-container>
