import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CustomValidators, Utils } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-phone-input',
    templateUrl: './phone-input.component.html'
})
export class PhoneInputComponent implements AfterViewInit {

    @Input() required: boolean;
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;

    @Output() phoneChanged: EventEmitter<any> = new EventEmitter<any>();

    timeout: any;

    @ViewChild('inputField') inputField: ElementRef;

    ngAfterViewInit() {
        const existing: string = this.formGroup.controls[this.formCtrl].value;
        if (existing) {
            this.inputField.nativeElement.value = this.getViewVal(existing);
        }
        this.formGroup.controls[this.formCtrl].valueChanges.subscribe((result) => {
            this.inputField.nativeElement.value = this.getViewVal(result || '');
            this.formGroup.controls[this.formCtrl].markAsDirty();
        });
    }

    onBlur(event: any) {
        this.updateValue(event.currentTarget);
    }

    updateValue(target: any) {
        let newVal = target.value;
        if (!newVal.match(CustomValidators.PASTABLE_PHONE_REGEXP)) {
            if (newVal === '')  {
                newVal = null;
            }
            this.formGroup.controls[this.formCtrl].patchValue(newVal, {emitEvent: false});
            if (!newVal) {
                this.formGroup.controls[this.formCtrl].updateValueAndValidity();
            }
        } else {
            newVal = newVal.replace(/\D/g, '');
            this.formGroup.controls[this.formCtrl].patchValue(newVal);
            this.formGroup.controls[this.formCtrl].markAsDirty();
            this.inputField.nativeElement.value = this.getViewVal(newVal || '');
            this.formGroup.controls[this.formCtrl].updateValueAndValidity();
        }
    }

    onInputChanged(event: any) {
        if (Utils.isNumericKeyCode(event.keyCode) || event.keyCode === 189 || event.keyCode === 32 || event.keyCode === 37 || event.keyCode === 39 || event.keyCode === 17 || event.keyCode === 9 || event.keyCode === 8) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.updateValue(event.target);
            }, 500);
            return;
        } else if ((event.ctrlKey && event.keyCode === 86) || (event.metaKey && event.keyCode === 86) || (event.ctrlKey && event.keyCode === 65)) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.updateValue(event.target);
            }, 500);
            return;
        }
        event.preventDefault();
    }

    getViewVal(rawValue: any) {
        let viewVal;
        if (rawValue.length === 0 || rawValue === '1' || rawValue === '0') {
            viewVal = '';
        } else if (rawValue.length <= 3) {
            viewVal = rawValue.replace(/^(\d{0,3})/, '($1)');
        } else if (rawValue.length <= 6) {
            viewVal = rawValue.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
        } else if (rawValue.length <= 10) {
            viewVal = rawValue.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        } else {
            viewVal = rawValue.substring(0, 10);
            viewVal = rawValue.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        }
        return viewVal;
    }

    onPhoneChanged() {
        this.phoneChanged.emit(this.formGroup.controls[this.formCtrl].value);
    }

    isValid() {
        return (this.formGroup.controls[this.formCtrl].valid || this.formGroup.controls[this.formCtrl].status === 'DISABLED');
    }

    isDirty() {
        return this.formGroup.controls[this.formCtrl].dirty;
    }

    isDisabled() {
        return this.formGroup.controls[this.formCtrl].disabled;
    }
}
