<div class="payment-stepper" *ngIf="formGroup">
    <mat-stepper [@.disabled]="true" [linear]="true" #stepper [orientation]="(stepperOrientation | async)!">
        <ng-template matStepperIcon="business_client">
            <mat-icon>store</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="bill_selection">
            <mat-icon>request_quote</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="manifest_selection">
            <mat-icon>list_alt</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="review">
            <mat-icon>verified</mat-icon>
        </ng-template>
        <mat-step state="business_client" [completed]="false" [editable]="true" [label]="getBillBusinessClientLabel()">
            <pt-business-client-step [memberAccount]="memberAccount" [formGroup]="formGroup" [currentBusinessClient]="currentBusinessClient" [isAccountingLinked]="isAccountingLinked"
                [subType]="TransactionSubType.PAYMENT" (businessClientSelected)="onSelectBusinessClient($event)" (nextStep)="nextStep()" (closeTransactionModal)="closeModal($event)"></pt-business-client-step>
        </mat-step>
        <mat-step state="bill_selection" [completed]="false" [editable]="isEntryStepEditable()" [label]="getBillLabel()">
            <pt-transaction-entry-selection-step *ngIf="currentBusinessClient" [memberAccount]="memberAccount" [currentBusinessClient]="currentBusinessClient" [isAccountingLinked]="isAccountingLinked"
                [availableBalance]="availableBalance" [subType]="TransactionSubType.PAYMENT" [isUniversalWhiteListedMerchant]="isUniversalWhiteListedMerchant" (transactionEntriesSelected)="onSelectBills($event)" (nextStep)="nextStep()"></pt-transaction-entry-selection-step>
        </mat-step>
        <mat-step *ngIf="isMetrcLinked" state="manifest_selection" [completed]="false" [editable]="isTrackingStepEditable()" [label]="getTrackingLabel()">
            <pt-tracking-selection-step *ngIf="currentBusinessClient?.transactionEntries?.length > 0" [memberAccount]="memberAccount" [currentBusinessClient]="currentBusinessClient"
                [changeTrigger]="changeTrigger" [formGroup]="formGroup" [isRecipient]="false" [subType]="TransactionSubType.PAYMENT" [selectedManifests]="manifests" [manifestSupportingDocs]="manifestSupportingDocs" (manifestSelected)="onSelectManifest($event)"
                (manifestDocumentUploaded)="onSelectManifestDocument($event)" (nextStep)="nextStep()" (closeTransactionModal)="closeModal($event)"></pt-tracking-selection-step>
        </mat-step>
        <mat-step state="review" [completed]="false" [editable]="isReviewStepEditable()" label="Review">
            <pt-review-transaction-step *ngIf="currentBusinessClient?.transactionEntries?.length > 0" [businessClients]="[currentBusinessClient]" [transactionTypes]="paymentMethods"
                [remittanceInfo]="remittanceInfo" [subType]="TransactionSubType.PAYMENT" [selectedManifests]="manifests" [wirePaymentFee]="wirePaymentFee" [achExpressPaymentFee]="achExpressPaymentFee" [manifestSupportingDocs]="manifestSupportingDocs"
                [formGroup]="formGroup" [isReviewStepValid]="isReviewStepValid" [transactionTotal]="transactionTotal" [isUniversalWhiteListedMerchant]="isUniversalWhiteListedMerchant" (transactionReviewed)="onTransactionReviewed($event)"></pt-review-transaction-step>
        </mat-step>
    </mat-stepper>
</div>
