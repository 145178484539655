import { MemberFunction, MemberRegistrationType, MemberStatus, MemberType, RiskTier } from './member';
import { MemberAccountAccess } from './user-member-access';

export class Profile {

    userId: string;

    memberId: string;
    memberName: string;
    memberStatus: MemberStatus;

    access: MemberAccountAccess[];

    memberType: MemberType;
    memberRegistrationType: MemberRegistrationType;

    tier: RiskTier;

    retailLocationId: string;
    retailLocationName: string;
    retailAccountId: string;

    jurisdiction: string;
    jurisdictionName: string;

    email: string;
    name: string;
    mobilePhone: string;
    mfaType: MfaType;
    role: Role;
    authorities: string[];
    multiMember = false;
    memberFunctions: MemberFunction[];

}

export enum MfaType {
    SMS = 'SMS',
    TOTP = 'TOTP',
    NONE = 'NONE'
}

export enum Role {
    AUTHORITY_SUPERUSER = 'AUTHORITY_SUPERUSER',
    AUTHORITY = 'AUTHORITY',
    AUTHORITY_REPORTER = 'AUTHORITY_REPORTER',
    AUTHORITY_READONLY = 'AUTHORITY_READONLY',
    CORPORATE_ADMIN = 'CORPORATE_ADMIN',
    ADMIN = 'ADMIN',
    MEMBER = 'MEMBER',
    MEMBER_READONLY = 'MEMBER_READONLY',
    RETAILER = 'RETAILER',
    CONSUMER = 'CONSUMER',
    NEW_REGISTRANT = 'NEW_REGISTRANT',
    NEW_ADMIN_REGISTRANT = 'NEW_ADMIN_REGISTRANT'
}
