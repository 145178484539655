<div class="horizontal-scroll mat-table-container">
    <div class="table mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="bankAccounts"  matSort matSortDisableClear [matSortActive]="pageTracking.sort" [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="business_name">
            <th mat-header-cell *matHeaderCellDef> Member Name </th>
            <td mat-cell *matCellDef="let element">
                <img *ngIf="element.member?.memberType === MemberType.INDIVIDUAL_MERCHANT || element.member?.memberType === MemberType.CONSUMER"
                     class="member-type-icon" src="assets/images/profile-icon.svg" title="Individual">
                <a (click)="memberDetails($event, element.member)" class="link" *ngIf="element.member?.memberType">
                    <b> {{ element.member?.name }} </b>
                    <br>
                    <b *ngIf="element.member?.dbaName"> DBA: {{ element.member?.dbaName }} </b>
                </a>
                <div *ngIf="!element.member?.memberType">{{element.member?.name}}</div>
                <div *ngIf="!element.member?.memberType">Bank Account ID: {{element.id}}</div>
                <div *ngIf="element.member?.address">
                    <pc-business-address [address]="element.member?.address">
                    </pc-business-address>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="member_status">
            <th class="centered" mat-header-cell  *matHeaderCellDef> Member Status </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <pc-member-badge *ngIf="element.member" [parent]="element.member"></pc-member-badge>
                <div>{{element.member?.memberType | translate}}</div>
                <div *ngIf="element.member?.jurisdiction">{{ Utils.getJurisdictionName(element.member.jurisdiction) }}</div>
                <div *ngIf="element.member?.tier === RiskTier.TIER_1">Tier 1</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="account_number">
            <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header="accountNumber"> Account Number </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <span *ngIf="element.accountNumber"> {{ getBankAccountNumber(element) }}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="ach_routing_number">
            <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header="routingNumber"> ACH Routing Number </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <span *ngIf="element.routingNumber"> {{ getBankRoutingNumber(element) }}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="wire_routing_number">
            <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header="wireRoutingNumber"> Wire Routing Number </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <span *ngIf="element.wireRoutingNumber"> {{ getWireRoutingNumber(element) }}</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header="status"> Account Status </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <pc-bank-account-badge [parent]="element"></pc-bank-account-badge>
            </td>
        </ng-container>
        <ng-container matColumnDef="financial_institution">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="financialInstitution"> Bank </th>
            <td mat-cell *matCellDef="let element">  {{element.financialInstitution}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.disabled]="row.status === BankAccountStatus.DISABLED || (row.member && row.member.status !== MemberStatus.ACTIVE)"></tr>
    </table>
    <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"></mat-paginator>
</div>
