<pt-member-sidebar [expandedMenu]="expandedMenu" [readOnly]="!authService.isMember()"  (toggleExpandSideMenu)="onToggleExpandSideMenu()"
        *ngIf="authService.isMemberOrMemberReviewer() && !authService.isConsumer() && !authService.isNewRegistrant()">
</pt-member-sidebar>
<pt-consumer-sidebar [expandedMenu]="expandedMenu"  (toggleExpandSideMenu)="onToggleExpandSideMenu()"
        *ngIf="authService.isConsumer()">
</pt-consumer-sidebar>
<pt-retailer-sidebar [expandedMenu]="expandedMenu"  (toggleExpandSideMenu)="onToggleExpandSideMenu()"
                     *ngIf="authService.isRetailer()">
</pt-retailer-sidebar>
<pc-new-member-sidebar [expandedMenu]="expandedMenu" (toggleExpandSideMenu)="onToggleExpandSideMenu()"
        *ngIf="authService.isNewRegistrant()">
</pc-new-member-sidebar>
<pt-authority-sidebar [expandedMenu]="expandedMenu" [readOnly]="!authService.isAuthority()" (toggleExpandSideMenu)="onToggleExpandSideMenu()"
        *ngIf="authService.isAuthorityOrReviewer()">
</pt-authority-sidebar>
