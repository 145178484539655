<fieldset class="form-group" *ngIf="formCtrl">
    <div>
        <div class="row">
            <div class="col-12" *ngIf="label">
                <label class="form-label">{{ label | translate }}</label>
            </div>
            <div class="col-12" [formGroup]="formGroup">
                <select class="form-control form-input-editable" [ngClass]="class" [formControlName]="formCtrl" [required]="required" type="text" (change)="onSelected()">
                    <option *ngIf="safariFix" value="" disabled selected></option>
                    <option *ngFor="let option of options" [value]="getValue(option)">{{getDisplayValue(option)}}</option>
                </select>
            </div>
        </div>
    </div>
</fieldset>
