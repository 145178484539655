<div>
    <div>
        <div>
            <div class="modal-header">
                <label class="form-title">{{edit ? 'Edit Scenario' : 'Add Scenario'}}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
        </div>
        <div>
            <div class="modal-content">
                <div class="row">
                    <form [formGroup]="scenarioForm">
                        <div class="col-12">
                            <p><b class="blue"></b>
                                <pc-form-text-input [required]="true" [label]="'Title:'" [formCtrl]="'titleCtrl'" [formGroup]="scenarioForm"
                                    [placeholder]="'Title'">
                                </pc-form-text-input>
                        </div>
                    </form>
                </div>
                <div class="row">
                    <form [formGroup]="scenarioForm">
                        <div class="col-12" formArrayName="kpis" *ngFor="let kpi of scenarioForm.get('kpis')['controls']; let i = index;">
                            <b class="blue">Select KPI:</b>
                            <div [formGroupName]="i">
                                 <span [formGroup]="kpi">
                                    <pc-form-selector [formCtrl]="'nameCtrl'" [formGroup]="kpi" [options]="kpiOptions" [defaultValue]="''">
                                    </pc-form-selector>
                                </span>
                                <span [formGroup]="kpi">
                                    <select [formControlName]="'conditionCtrl'" class="form-control-header form-input-table-header form-select-sm" type="text">
                                        <option [defaultSelected]="true" value="GREATER">&gt;</option>
                                        <option value="LESS">&lt;</option>
                                        <option value="EQUALS">=</option>
                                    </select>
                                </span>
                                #<input  class="form-control-header form-input-table-header form-select-sm" type="number" formControlName="amountCtrl">
                                <i class="fa fa-times" (click)="onDeleteFromArray(i)"></i>
                                <p></p>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="float-end">
                    <span *ngIf="kpiArr.length < 10" class="text-btn pt-1" (click)="addKpi()">
                        <i class="fa fa-plus pull-left blue-button padding-top">Add another KPI</i>
                    </span>
                </div>
            </div>
        </div>
        <div class="modal-footer mt-4">
            <div class="col-4">
                <button class="blue-button" (click)="close(true)">Cancel</button>
            </div>
            <div class="col-8">
                <button class="blue-button pull-right" [disabled]="scenarioForm.invalid" (click)="onSave()">Save </button>
            </div>
        </div>
    </div>
</div>
