export class PartnerKey {

    id: string;
    created: Date;
    memberId: string;
    key: string;
    secret: string;
    status: PartnerKeyStatus;

}

export enum PartnerKeyStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED'
}
