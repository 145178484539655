import { Address } from '../address';

export class LendingCustomer {

    externalId: string;
    name: string;
    dbaName: string;
    contactName: string;
    email: string;
    officePhone: string;
    defaultCurrency: string;
    phone: string;
    billAddress: Address;
    shipAddress: Address;
    registrationNumber?: string;
    taxNumber?: string;
    status?: string;

    avgInvoiceAmount: number;
    avgPaymentTerms: number;
    concentration: number;
    daysSalesOutstanding?: number;
    noInvoicesOutstanding?: number;
    outstanding?: number;
    paid?: number;

}
