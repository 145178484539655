import { Router } from '@angular/router';
import { Transaction, PaymentService, ReportsService, PagedResponse, PostedTransactionType, BsaReport, TransactionSubType, ReportType, FilingInstitution, OnboardingTrackingService } from 'projects/services/src/public-api';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PageTracking } from 'projects/components/src/lib/table-utils.service';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pt-sar-continuing-report-list',
    templateUrl: './sar-continuing-report-list.component.html'
})
export class SarContinuingReportListComponent implements OnInit, OnDestroy {

    PostedTransactionType = PostedTransactionType;
    ReportType = ReportType;
    TransactionSubType = TransactionSubType;
    FilingInstitution = FilingInstitution;

    @Input() report: BsaReport;
    @Input() postedTxnType : PostedTransactionType;
    @Input() subType : TransactionSubType;

    resultsLength = 0;
    transactions: any[] = [];
    pageTracking: PageTracking;
    isLoadingResults = true;
    displayedColumns: string[] = ['transactionType', 'payor', 'payee', 'completionDate', 'score', 'amount'];
    subscription: any;

    constructor(private router: Router,
                private reportsService: ReportsService,
                private paymentService: PaymentService) { }

    ngOnInit() {
        this.addTableLoadListener();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addTableLoadListener() {
        this.subscription =  merge().pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                if (this.report.reportType === ReportType.CTR_REPORT) {
                    return this.reportsService.getCTRReportPostedTransactionsList(this.report.id, this.subType);
                }
                return this.reportsService.getSARContinuingReportPostedTransactionsList(this.report.id, this.postedTxnType);
            }),
            map((response: PagedResponse<Transaction>) => {
                this.resultsLength = response.totalElements || 0;
                this.isLoadingResults = false;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((transactions: Transaction[]) => {
            this.transactions = transactions;
            for (const transaction of this.transactions) {
                this.paymentService.loadTransaction(transaction);
            }
        });
    }

    loadTransactionDetails(transactionId: string, event: any) {
        if (event.target.tagName !== 'A') {
            this.router.navigate(['/transaction/details/', transactionId]);
        }
    }
}
