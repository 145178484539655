import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    selector: 'pc-snackbar-notification',
    templateUrl: './snackbar-notification.component.html',
    styleUrls: ['./snackbar-notification.component.scss']
})
export class SnackbarNotificationComponent {

    constructor(private snackbarRef: MatSnackBarRef<SnackbarNotificationComponent>,
                @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    }

    getClass() {
        switch (this.data.notificationType) {
            case 'SUCCESS':
                return 'fa-check-circle';
            case 'ERROR':
                return 'fa-times-circle';
            case 'INFO':
                return 'fa-info-circle';
        }
    }

    close() {
        this.snackbarRef.dismiss();
    }
}
