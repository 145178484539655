<div *ngFor="let field of getFields()">
    <pc-sc-field *ngIf="field.type !== 'ORDER'" [formGroup]="formGroup" [metadata]="field" (changed)="onChanged($event)" [enforceRequired]="enforceRequired">
        Render content as a field
    </pc-sc-field>
    <div *ngIf="field.type === 'ORDER'" class="row sc-order">
        <div class="row col-12">
            <label class="form-label">{{ field.label | translate }}</label>
        </div>
        <div class="row col-12">
            <pc-sc-sales-order class="sales-order" mode="EDIT" [order]="contractTemplate.purchaseOrder" [frameworkType]="contractTemplate.contractFramework.sellerClassification" [enforceRequired]="enforceRequired" (modified)="onOrderChanged($event)"></pc-sc-sales-order>
        </div>
    </div>
</div>
