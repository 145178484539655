import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ConnectedMemberStatus } from './models/connected-member';
import { MemberAccount, MemberAccountType } from './models/member-account';
import { Observable } from 'rxjs';
import { PagedResponse } from './models/paged-response';

@Injectable({
    providedIn: 'root'
})
export class ConnectedMemberService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.EntityAPIEndpoint + '/';
    }

    getAvailablePartners(memberId: string,
        start: number,
        size: number,
        sort: string,
        sortDir: string) {

        return this.http.get(`${this.baseUrl}connected_member/partner/available?memberId=${memberId}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`);
    }

    getPartnersByMemberId(memberId: string,
        start: number,
        size: number,
        sort: string,
        sortDir: string) {

        const statuses = [ConnectedMemberStatus.ACTIVE];
        return this.http.get(`${this.baseUrl}connected_member?memberId=${memberId}&statuses=${statuses}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`);
    }

    getConnectedMembersForMemberAccount(memberId: string,
        memberAccountId: string) {

        const statuses = [ConnectedMemberStatus.ACTIVE];
        return this.http.get(`${this.baseUrl}connected_member?memberId=${memberId}&memberAccountId=${memberAccountId}&statuses=${statuses}&start=0&size=100&sort=sortName&sortDir=ASC`);
    }

    getConnectedMembersForPartnerAndMember(partnerId: string,
        memberId: string) {

        return this.http.get(`${this.baseUrl}connected_member?partnerId=${partnerId}&memberId=${memberId}&statuses=ACTIVE`);
    }

    searchConnectedMembers(partnerId: string,
        memberId: string,
        memberAccountId: string,
        memberAccountTypes: MemberAccountType[],
        includeAddress: boolean,
        start: number,
        size: number,
        sort: string,
        sortDir: string) {

        const statuses = [ConnectedMemberStatus.ACTIVE];
        return this.http.get(`${this.baseUrl}connected_member/${partnerId}/search?memberId=${memberId}&memberAccountId=${memberAccountId}&memberAccountTypes=${memberAccountTypes}&statuses=${statuses}&includeAddress=${includeAddress}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`);
    }

    searchAvailableConsumers(partnerId: string,
        phone: string,
        start: number,
        size: number,
        sort: string,
        sortDir: string) {

        return this.http.get(`${this.baseUrl}connected_member/${partnerId}/consumer/search?memberPhone=${phone}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`);
    }

    getMatchingConnectedMembers(partnerId: string,
        memberName: string,
        memberAccountTypes: MemberAccountType[],
        statuses: ConnectedMemberStatus[],
        includeAddress: boolean,
        searchLimit?: number) : Observable<PagedResponse<MemberAccount>> {

        return this.http.get(`${this.baseUrl}connected_member/${partnerId}/search?memberAccountTypes=${memberAccountTypes}&memberName=${encodeURI(memberName)}&statuses=${statuses}&includeAddress=${includeAddress}&start=0&size=${searchLimit || ''}&sort=sortName&sortDir=ASC`) as Observable<PagedResponse<MemberAccount>>;
    }

    addConnectedMemberToPartner(partnerId: string,
        memberId: string,
        memberAccountId: string,
        status: ConnectedMemberStatus) {

        return this.http.post(`${this.baseUrl}connected_member`, {
            partnerId,
            memberId,
            memberAccountId,
            status
        });
    }

    disconnectMember(id: string) {
        return this.http.delete(`${this.baseUrl}connected_member/${id}`);
    }
}
