import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'pc-global-search-input',
    templateUrl: './global-search-input.component.html',
    styleUrls: ['./global-search-input.component.scss']
})
export class GlobalSearchInputComponent implements OnInit {

    @Input() placeholder = '';
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;

    @Output() search: EventEmitter<string> = new EventEmitter<string>();

    searchTerm = '';

    constructor(private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            if (this.router.url.indexOf('/administration/search') < 0) {
                this.setSearchTerm('');
            }
            this.setSearchTerm(params.searchTerm);
        });
    }

    setSearchTerm(value: string) {
        this.searchTerm = value;
        this.formGroup.controls[this.formCtrl].setValue(this.searchTerm);
    }

    // It will emit the event on both cases when user hits the enter or when blur events occurs any.
    onSearch() {
        const value = this.formGroup.controls[this.formCtrl].value?.trim();
        if (value && value !== this.searchTerm && value?.replace(/\s+/g, '').length > 2) {
            this.search.emit(this.formGroup.controls[this.formCtrl].value);
        } else if (!value) {
            this.formGroup.controls[this.formCtrl].markAsPristine();
            this.router.navigate(['/dashboard']);
        }
    }

    isSearchTermSet() {
        return this.formGroup.controls[this.formCtrl].value;
    }

    onIconClick(event: any) {
        if (this.formGroup.controls[this.formCtrl].value) {
            this.formGroup.controls[this.formCtrl].setValue('');
            this.search.emit('');
        }
        event.stopPropagation();
        this.router.navigate(['/dashboard']);
    }

    onInputChanged(event: any) {
        if (event.keyCode === 220 || event.keyCode === 222) {
            event.preventDefault();
            return;
        }
    }

}
