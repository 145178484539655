<div class="row" [formGroup]="formGroup" *ngIf="metadata">
    <div class="col-7 sc-input">
        <label class="form-label">{{ metadata.label | translate }}</label>
        <div [formGroup]="formGroup" class="text-input">
            <textarea [id]="metadata.id" class="form-control" [formControlName]="metadata.id" [title]="metadata.tooltip" (change)="fieldChanged($event)"></textarea>
        </div>
    </div>
    <div class="col-5 sc-tooltip">
        <p><strong>{{ metadata.label | translate }}</strong>: {{metadata.tooltip}}</p>
    </div>
</div>
