import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import {
    AuthService, Configuration, ConnectedMemberService, ConnectedMemberStatus, CorporateEntity, MemberAccount, MemberAccountService, MemberAccountStatus, MemberAccountType, MemberFunction,
    PagedResponse, Utils
} from 'projects/services/src/public-api';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'pc-connected-member-selector',
    templateUrl: './connected-member-selector.component.html',
    styleUrls: ['./connected-member-selector.component.scss']
})

export class ConnectedMemberSelectorComponent implements OnInit, OnChanges, AfterViewInit {

    MemberAccountType = MemberAccountType;

    @Input() placeholder = '';
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() displayLabel = true;
    @Input() memberAccountType: MemberAccountType = MemberAccountType.BUSINESS;
    @Input() class = '';
    @Input() includeAddress = false;
    @Input() includeStatus = false;


    @Output() connectedMemberSelected = new EventEmitter<MemberAccount>();

    statuses: MemberAccountStatus[] = [MemberAccountStatus.ACTIVE];
    connectedMemberSelect: Subscription;
    connectedMemberAutoComplete: Observable<MemberAccount[]> = null;
    autoCompleteControl = new UntypedFormControl('');
    connectedMemberAccountOptions: MemberAccount[] = [];
    searching = false;
    searchLimit: number;
    totalResults: number;

    @ViewChild('connectedMemberSelect', { read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;

    constructor(private authService: AuthService,
                private memberAccountService: MemberAccountService,
                private connectedMemberService: ConnectedMemberService) {}

    ngOnInit() {
        this.searchLimit = Configuration.getConfig().autoCompleteSearchLimit;
        this.formGroup.addControl('autoCompleteCtrl', this.autoCompleteControl);

        this.connectedMemberAutoComplete = this.autoCompleteControl.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            debounceTime(1000),
            switchMap((value) => {
                this.searching = true;
                return this.lookup(value || '');
            })
        );
        this.loadMember();
        this.formGroup.controls[this.formCtrl].valueChanges.subscribe((_value) => {
            this.loadMember();
        });
    }

    ngAfterViewInit() {
        this.subscribeToClosingActions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.statuses && !changes.statuses.firstChange) ||
            (changes.memberAccountType && !changes.memberAccountType.firstChange)) {
            if (this.authService.hasMemberFunction(MemberFunction.PARTNER)) {
                this.statuses = [MemberAccountStatus.ACTIVE];
            } else {
                this.statuses = [];
            }
            if (changes.memberAccountType) {
                this.autoCompleteControl.setValue('');
            }
            this.connectedMemberAccountOptions = [];
            this.connectedMemberAutoComplete = this.autoCompleteControl.valueChanges.pipe(
                startWith(''),
                distinctUntilChanged(),
                debounceTime(1000),
                switchMap((value) => {
                    this.searching = true;
                    return this.lookup(value || '');
                })
            );
        }
    }

    private subscribeToClosingActions() {
        if (this.connectedMemberSelect && !this.connectedMemberSelect.closed) {
            this.connectedMemberSelect.unsubscribe();
        }

        this.connectedMemberSelect = this.trigger.panelClosingActions.subscribe((e) => {
            if (!e || !e.source) {
                if (this.connectedMemberAccountOptions.length === 1) {
                    this.connectedMemberChanged(this.connectedMemberAccountOptions[0]);
                } else {
                    const selected = this.connectedMemberAccountOptions
                        .map((option) => {
                            return option.member.name;
                        })
                        .find((option) => {
                            return option === this.autoCompleteControl.value;
                        });

                    if (!selected) {
                        this.formGroup.controls[this.formCtrl].markAsPristine();
                        this.formGroup.controls[this.formCtrl].setValue('');
                        this.autoCompleteControl.setValue('');
                        this.connectedMemberChanged(new MemberAccount());
                    }
                }
            }
        }, () => {
            return this.subscribeToClosingActions();
        }, () => {
            return this.subscribeToClosingActions();
        });
    }

    lookup(value: string) : Observable<MemberAccount[]> {
        this.totalResults = 0;
        if (value?.length <= 1) {
            this.searching = false;
            this.connectedMemberAccountOptions = [];
            return of([]);
        }
        this.autoCompleteControl.disable();
        const memberId = this.authService.getProfile().memberId;
        return this.connectedMemberService.getMatchingConnectedMembers(memberId, value, [this.memberAccountType], [ConnectedMemberStatus.ACTIVE], this.includeAddress, this.searchLimit).pipe(
            switchMap((response: PagedResponse<MemberAccount>) => {
                this.totalResults = response.totalElements;
                this.autoCompleteControl.enable();
                this.connectedMemberAccountOptions = response.content;
                this.connectedMemberAccountOptions.forEach((member) => {
                    if (member.address) {
                        member.address.country = Utils.getCountryCode(member.address.country);
                    }
                });
                this.searching = false;
                return of(this.connectedMemberAccountOptions);
            }), catchError((_error) => {
                this.searching = false;
                return [];
            })
        );
    }

    connectedMemberChanged(memberAccount: MemberAccount) {
        this.formGroup.controls[this.formCtrl].markAsDirty();
        this.connectedMemberSelected.emit(memberAccount);
    }

    onInputChanged(event: any) {
        if (event.keyCode === 220) {
            event.preventDefault();
            return;
        }
    }

    selected() {
        return this.formGroup.controls[this.formCtrl].value;
    }

    loadMember() {
        if (this.selected()) {
            this.memberAccountService.loadMemberAccount(this.formGroup.controls[this.formCtrl].value).subscribe((memberAccount: MemberAccount) => {
                this.autoCompleteControl.setValue(memberAccount.member.name);
            });
        } else {
            this.autoCompleteControl.setValue('');
        }
    }

    onIconClick(event: any) {
        if (this.selected()) {
            this.autoCompleteControl.setValue('');
            this.connectedMemberAccountOptions = [];
            this.connectedMemberSelected.emit(new MemberAccount());
        }
        event.stopPropagation();
        this.trigger.openPanel();
    }

    getMemberName(memberAccount: MemberAccount) {
        if (memberAccount.accountType === MemberAccountType.CONSUMER) {
            return memberAccount.member.name;
        }
        return `${memberAccount.accountFriendlyName} (${memberAccount.member.jurisdiction ? memberAccount.member.jurisdiction : 'All'})`;
    }
}
