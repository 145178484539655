import {
    AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ContentChildren, EventEmitter, Host, Input, OnDestroy, OnInit, Output, QueryList, ViewChild
} from '@angular/core';
import {PageTracking, TableUtils} from 'projects/components/src/lib/table-utils.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatColumnDef, MatTable} from '@angular/material/table';
import {Subscription} from 'rxjs';

/**
 * Handle mat table, sort and pagination all together encapsulated in one single component with only a single
 * responsibility.
 *
 */
@Component({
    selector: 'pc-datatable',
    templateUrl: './datatable.component.html'
})
export class DatatableComponent<T> implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {

    @Input() dataSource: T[] = [];
    @Input() columns: string[] = [];
    @Input() totalElements: number;
    @Input() isLoading: boolean = true;
    @Input() options: DataTableOptions = new DataTableOptions({});

    @Output() paginationChanged: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
    @Output() sortChanged: EventEmitter<Sort> = new EventEmitter<Sort>();

    pageTracking: PageTracking;
    sortListenerSubs: Subscription;

    @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

    @ViewChild(MatTable, {static: true}) private table: MatTable<T>;
    @ViewChild(MatPaginator) private paginator: MatPaginator;

    constructor(@Host() private matSort: MatSort,
                private route: ActivatedRoute,
                private router: Router,
                private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, this.options.sortColumn, this.options.sortDirection, this.options.defaultPageSize);
    }

    ngOnDestroy() {
        if (this.sortListenerSubs) {
            this.sortListenerSubs.unsubscribe();
        }

        TableUtils.clearSubscriptions();
    }

    ngAfterViewInit() {
        if (this.options.urlTracking && this.matSort) {
            TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.matSort);
        }
    }

    /**
     * Angular mat table doesn't read ng-templates, therefore we need to programmatically add the columns declared in
     * parent and add it to mat table.
     *
     * <p>
     *
     * Sort cannot be used inside this component (material lib limitations), we need to configure it programmatically
     * as well as subscribe to its sort listener event.
     */
    ngAfterContentInit() {
        this.columnDefs.forEach((columnDef) => {
            return this.table.addColumnDef(columnDef);
        });

        if (this.matSort) {
            this.matSort.active = this.pageTracking.sort;
            this.matSort.direction = this.pageTracking.sortDir;
            this.sortListenerSubs = this.matSort.sortChange.subscribe((sortEvent: Sort) => {
                this.onSortListener(sortEvent);
            });
        }
    }

    /**
     * Fire event when user interacts with sort elements.
     */
    onSortListener(sortEvent: Sort) {
        this.paginator.pageIndex = 0;
        this.pageTracking.page = 1;
        this.pageTracking.sortDir = sortEvent.direction === 'asc' ? 'asc' : 'desc';
        this.pageTracking.sort = sortEvent.active;
        this.updateQueryParams();
        this.sortChanged.emit(sortEvent);
    }

    /**
     * Fire event when user interacts with pagination elements.
     */
    onPageListener(pageEvent: PageEvent) {
        this.pageTracking.pageSize = pageEvent.pageSize;
        this.pageTracking.page = pageEvent.pageIndex + 1;
        this.updateQueryParams();
        this.paginationChanged.emit(pageEvent);
    }

    /**
     * Updating query params Whenever a page or sort events get triggered.
     */
    private updateQueryParams() {
        if (!this.options.urlTracking) {
            return;
        }
        const queryParams = {page: this.pageTracking.page, num: this.pageTracking.pageSize, sort: this.pageTracking.sort, dir: this.pageTracking.sortDir};
        TableUtils.updateQueryParams(this.route, this.router, queryParams, false);
    }
}

/**
 * Extra configurations for owners table. This way element directive is cleaner than overwhelming the tag with a bunch
 * params.
 *
 */
export class DataTableOptions {

    /**
     * Turn on url tracking and config TableUtils.initializePaginatorAndSort
     */
    urlTracking?: boolean;
    sortColumn?: string;
    sortDirection?: 'desc' | 'asc';
    rowClass?: string;
    defaultPageSize?: number;

    constructor({
        urlTracking = true,
        sortColumn = 'created',
        sortDirection = 'desc',
        rowClass = null,
        defaultPageSize = 25
    }) {
        this.urlTracking = urlTracking;
        this.sortColumn = sortColumn;
        this.sortDirection = sortDirection === 'asc' ? 'asc' : 'desc';
        this.rowClass = rowClass;
        this.defaultPageSize = defaultPageSize;
    }
}
