import { Injectable, Inject } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.EntityAPIEndpoint + '/';
    }

    passwordSettingRequired(accountStatusRequest: any) {
        return this.http.post(`${this.baseUrl}register/password`, accountStatusRequest);
    }

    registerMember(registrationRequest: any) {
        return this.http.post(`${this.baseUrl}register`, registrationRequest);
    }

    registerConsumer(registrationRequest: any) {
        // FIXME: allow for consumer registration - need new workflow
        return this.http.post(`${this.baseUrl}register`, registrationRequest);
    }

    completeRegistration(completionRequest: any) {
        return this.http.post(`${this.baseUrl}register/complete`, completionRequest);
    }

    validateResetLink(accountStatusRequest: any) {
        return this.http.post(`${this.baseUrl}register/validate`, accountStatusRequest);
    }

    resetPassword(completionRequest: any) {
        return this.http.post(`${this.baseUrl}register/reset`, completionRequest);
    }

    getDownloadImage(id: string) {
        return this.http.getDownload(`${this.baseUrl}register/${id}`);
    }

    forgotPassword(forgotPasswordRequest: any) {
        return this.http.post(`${this.baseUrl}register/forgotPassword`, forgotPasswordRequest);
    }

}
