<div class="row mt-2 mb-3">
    <div class="col-12">
        <div *ngIf="memberAccount" class="row">
            <dl class="row">
                <dt class="col-3 mt-2">Account Name:</dt>
                <dd class="col-9">
                    <pc-text-input [required]="true" [placeholder]="'Member Name'" [formCtrl]="'accountNameCtrl'"
                        [formGroup]="memberAccountForm" [inlineEdit]="!readOnly && (authService.isAuthorityOrCorporateAdmin() || authService.isAdmin(memberAccount))
                            && memberAccount.member.memberRegistrationType !== MemberRegistrationType.CONSUMER
                            && memberAccount.member.memberType !== MemberType.FEE_ACCOUNT" [editFn]="updateMemberAccountName">
                    </pc-text-input>
                </dd>
            </dl>
            <dl class="row" *ngIf="memberAccount.accountType !== MemberAccountType.CONSUMER">
                <dt class="col-3 mt-2"></dt>
                <dd class="col-9">
                    <pc-simple-checkbox [label]="'Accept payments from other members'" [formCtrl]="'isPublicCtrl'" [formGroup]="memberAccountForm" [invert]="true" (changed)="onPublicAccountChange()">
                    </pc-simple-checkbox>
                </dd>
            </dl>
            <dl class="row" *ngIf="memberAccount.isPublic">
                <dt class="col-3 mt-2">Friendly Name:</dt>
                <dd class="col-9">
                    <pc-text-input [required]="true" [placeholder]="'Member Name'" [formCtrl]="'accountFriendlyNameCtrl'"
                                   [formGroup]="memberAccountForm" [inlineEdit]="!readOnly && (authService.isAuthorityOrCorporateAdmin() || authService.isAdmin(memberAccount))
                            && memberAccount.member.memberRegistrationType !== MemberRegistrationType.CONSUMER
                            && memberAccount.member.memberType !== MemberType.FEE_ACCOUNT" [editFn]="updateMemberAccountFriendlyName">
                    </pc-text-input>
                </dd>
            </dl>
            <dl class="row" *ngIf="bankAccountForm">
                <dt class="col-3 mt-2">Linked Bank Account:</dt>
                <dd class="col-9">
                    <pc-bank-account-selector [placeholder]="'Select Bank Account'" [formCtrl]="'bankAccountIdCtrl'" [formGroup]="bankAccountForm" [bankAccounts]="bankAccounts"
                        (bankAccountSelected)="onSelectBankAccount($event)" >
                    </pc-bank-account-selector>
                </dd>
            </dl>
            <dl class="row" *ngIf="addressForm">
                <dt class="col-3 mt-2" [ngClass]="memberAccount.addressId ? '' : 'error'">Linked Address:</dt>
                <dd class="col-9">
                    <fieldset class="form-group">
                        <div [formGroup]="addressForm">
                            <select class="form-control" [formControlName]="'addressIdCtrl'" type="text" (change)="onSelectAddress($event)">
                                <option *ngFor="let address of addresses" [value]="address.id">{{address.streetAddressOne}}, {{address.city}}, {{address.stateProvince}}</option>
                            </select>
                        </div>
                    </fieldset>
                </dd>
            </dl>
            <dl class="row" *ngIf="accountingConnected && codatSettingsForm">
                <dt class="col-3 mt-2">Reconciliation Account:</dt>
                <dd class="col-9">
                    <div *ngIf="accountsLoading && pushSettingsAvailable" class="d-flex justify-content-center mt-5">
                        <i class="fa fa-spin fa-spinner"></i>Requesting accounts...
                    </div>
                    <div class="row" *ngIf="!accountsLoading && pushSettingsAvailable">
                        <div [class]="authService.isAuthorityOrReviewer() ? 'col-12': 'col-11'" [formGroup]="codatSettingsForm">
                            <pc-form-selector [formCtrl]="'reconciliationAccountIdCtrl'" [formGroup]="codatSettingsForm" [options]="accounts"
                                [class]="'full'" [idValue]="'id'" [displayValue]="'name'" [defaultValue]="''" (selected)="onAccountChange($event)">
                            </pc-form-selector>
                        </div>
                        <div *ngIf="authService.isAdmin()" class="col-1 add-account">
                            <i class="fa fa-plus" [class.disabled]="confiaAccount" [title]="!confiaAccount ? 'Add New Account' : confiaAccount.name + ' is already registered'" (click)="onCreateReconcilationAccount()"></i>
                        </div>
                    </div>
                    <div class="row" *ngIf="!accountsLoading && pushSettingsAvailable">
                        <pc-simple-checkbox [label]="'Update accounting invoices when deposits finalized'" [formCtrl]="'invoicePushCtrl'" [formGroup]="codatSettingsForm" [invert]="true" (changed)="updateCodatSettings()">
                        </pc-simple-checkbox>
                        <pc-simple-checkbox [label]="'Update accounting bills when payments clear'" [formCtrl]="'billPushCtrl'" [formGroup]="codatSettingsForm" [invert]="true" (changed)="updateCodatSettings()">
                        </pc-simple-checkbox>
                    </div>
                </dd>
            </dl>
            <pc-member-account-user-list [memberAccount]="memberAccount" [readOnly]="readOnly"></pc-member-account-user-list>
        </div>
    </div>
</div>
