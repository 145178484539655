import { Component, Inject, OnInit } from '@angular/core';
import { AccountToken, AuthService, PaymentService, AccountResponse, BankAccountStatus } from 'projects/services/src/public-api';
import { BaseModalComponent, InfoModalComponent } from 'projects/components/src/lib/modal';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay } from '@angular/cdk/overlay';
import { LoaderComponent } from 'projects/components/src/public-api';
import { TranslatePipe } from '@ngx-translate/core';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

declare let fastlink: any;

@Component({
    selector: 'pt-yodlee-fastlink-modal',
    templateUrl: './yodlee-fastlink-modal.component.html',
    styleUrls: ['./yodlee-fastlink-modal.component.scss']
})
export class YodleeFastlinkModalComponent extends BaseModalComponent<YodleeFastlinkModalComponent> implements OnInit {

    accountToken: AccountToken;
    callback: any;

    constructor(public authService: AuthService,
                private paymentService: PaymentService,
                private translatePipe: TranslatePipe,
                private notifier: NotificationService,
                private infoDialog: MatDialog,
                private overlay: Overlay,
                dialogRef: MatDialogRef<YodleeFastlinkModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.accountToken = data.accountToken;
        this.callback = data.callback;
    }

    ngOnInit() {
        this.callFastLink();
        window.onmessage = (event: any) => {
            if (event.data !== null && event.data.sites && event.origin.match(/yodlee.com$/i)) {
                const accountIds = event.data.sites.map((site: any) => {
                    return site.accountId;
                });
                this.saveAccount(accountIds);
            }
        };
    }

    callFastLink() {
        // Open FastLink
        fastlink.open({
            fastLinkURL: this.accountToken.callbackUrl,
            accessToken: 'Bearer ' + this.accountToken.authToken,
            params: {
                configName: 'Verification'
            },
            forceIframe: true,
            // eslint-disable-next-line no-empty-function
            onSuccess(_data: any) {
            },
            onExit(data: any) {
                window.postMessage(data, window.origin);
            }
        },
        'container-fastlink');
    }

    close() {
        super.close('The operation was canceled.');
        fastlink.close();
    }

    saveAccount(accountIds: string[]) {
        super.close();
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        overlayRef.attach(new ComponentPortal(LoaderComponent));
        this.accountToken.accountIds = accountIds;
        this.paymentService.saveExternalAccount(this.accountToken).subscribe((response: AccountResponse) => {
            overlayRef.dispose();
            let message = '<dl>';
            let reviewRequired = false;
            let successful = false;
            let duplicate = false;
            for (const result of response.results) {
                if (result.accountStatus === BankAccountStatus.PENDING
                    || result.accountStatus === BankAccountStatus.BANK_VALIDATION_REQUIRED
                    || result.accountStatus === BankAccountStatus.USER_VALIDATION_REQUIRED
                    || result.accountStatus === BankAccountStatus.VALIDATION_REQUIRED) {
                    reviewRequired = true;
                } else if (result.accountStatus === BankAccountStatus.ACTIVE){
                    successful = true;
                } else if (result.accountStatus === BankAccountStatus.DUPLICATE_BANK_ACCOUNT){
                    duplicate = true;
                }
                message += ('<dt>' + result.accountName + ':</dt><dd>' + this.translatePipe.transform(result.accountStatus) + '</dd>');
            }
            message += '</dl>';
            if (reviewRequired) {
                message += 'Your account(s) or institution information requires review prior to activation. <br>Please allow one business day.';
            } else if (successful) {
                message = 'Your account was successfully linked.';
            } else if (duplicate) {
                message += 'The account(s) already exist.';
            } else {
                message += 'The account linking was cancelled.';
            }

            this.showMessage(message);
        }, (_error: any) => {
            overlayRef.dispose();
            this.notifier.showError('There was an issue linking your account(s). <br>Please contact us for help linking your account.');
        });
    }

    showMessage(message: string) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            message,
            title: 'Account Registration Summary'
        };
        const dialog = this.infoDialog.open(InfoModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.callback();
            }
        });
    }
}
