<div class="terms default-background-color" *ngIf="task">
    <header class="card-header default-color" [ngStyle]="{'background': landingSettings?.primaryColor}">
        <div class="row h-100">
            <div class="col-5 text-left h-100">
                <pc-secure-image class="h-100" [imgSrc]="imageSrc" *ngIf="imageSrc" alt="Partner Logo" [class]="'img-icon-thumbnail'"></pc-secure-image>
            </div>
            <div class="col-7 text-right h-100">
                <img class="logo-header">
            </div>
        </div>
    </header>

    <div id="scrollable" pt-custom-scroll #appScrollElement="custom-scroll"  #scroll [ngStyle]="{'font-family': landingSettings?.primaryFont}" class="default-background-color" [ngbCollapse]="termsAccepted">
        <div *ngIf="!termsAccepted">
            <pt-consumer-terms></pt-consumer-terms>
        </div>
    </div>
    <div class="buttons default-background-color" *ngIf="!termsAccepted">
        <div>
            <button pc-no-dbl-click class="blue-button" (click)="onCancel()" [ngStyle]="buttonStyle()" submit-button>Cancel</button>
            <button pc-no-dbl-click class="blue-button" [disabled]="appScrollElement.disableBtn" (click)="onAcceptTerms(false)" type="button" [ngStyle]="buttonStyle()" submit-button>Accept and Continue</button>
            <button pc-no-dbl-click *ngIf="isQaEnabled" class="blue-button" [disabled]="appScrollElement.disableBtn" (click)="onAcceptTerms(true)" type="button" [ngStyle]="buttonStyle()" submit-button>Accept Using Default Test Account</button>
        </div>
    </div>

    <mat-stepper [@.disabled]="true" *ngIf="termsAccepted" [linear]="true" #stepper [ngStyle]="{'font-family': landingSettings?.primaryFont}" class="default-background-color" [orientation]="stepperOrientation">
        <ng-template matStepperIcon="contact_info">
            <mat-icon>account_circle</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="address">
            <mat-icon>home</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="banking">
            <mat-icon>add_card</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="complete">
            <mat-icon>done</mat-icon>
        </ng-template>
        <mat-step *ngIf="flow === ConsumerOnboardFlow.ONBOARD_REQUIRED" state="contact_info" [completed]="contactInfoForm.valid" [editable]="!consumerValid" label="Contact Information">
            <div class="d-flex justify-content-center">
                <form [formGroup]="contactInfoForm">
                    <pc-text-input [required]="true" [label]="'First Name'" [placeholder]="'First Name'" [formCtrl]="'firstNameCtrl'" [formGroup]="contactInfoForm">
                    </pc-text-input>
                    <pc-text-input [required]="false" [label]="'Middle Name'" [placeholder]="'Middle Name'" [formCtrl]="'middleNameCtrl'" [formGroup]="contactInfoForm">
                    </pc-text-input>
                    <pc-text-input [required]="true" [label]="'Last Name'" [placeholder]="'Last Name'" [formCtrl]="'lastNameCtrl'" [formGroup]="contactInfoForm">
                    </pc-text-input>
                    <pc-phone-input [required]="true" [label]="'Mobile Number'" [formCtrl]="'mobilePhoneCtrl'" [formGroup]="contactInfoForm">
                    </pc-phone-input>
                    <pc-email-input [required]="true" [label]="'Email Address'" [formCtrl]="'emailCtrl'" [formGroup]="contactInfoForm">
                    </pc-email-input>
                    <pc-dob-input [formGroup]="contactInfoForm" [formCtrl]="'birthDateCtrl'" [required]="true">
                    </pc-dob-input>
                    <div class="pull-right mt-2">
                        <button pc-no-dbl-click class="blue-button" mat-button matStepperNext [disabled]="!contactInfoForm.valid" [ngStyle]="buttonStyle()" type="button" submit-button>Next</button>
                    </div>
                </form>
            </div>
        </mat-step>
        <mat-step *ngIf="flow === ConsumerOnboardFlow.ONBOARD_REQUIRED" state="address" [completed]="consumerValid && addressForm.valid" [editable]="!consumerValid" label="Address Information">
            <div class="d-flex justify-content-center">
                <form [formGroup]="addressForm">
                    <pc-address-input [addressTypeOpts]="[AddressType.PRIMARY]" [addressForm]="addressForm"></pc-address-input>
                    <div class="pull-right mt-2">
                        <button pc-no-dbl-click class="blue-button" mat-button matStepperPrevious [ngStyle]="buttonStyle()" submit-button>Previous</button>
                        <button pc-no-dbl-click *ngIf="!consumerValid" class="blue-button" mat-button matStepperNext [disabled]="!addressForm.valid" (click)="onSave()" [ngStyle]="buttonStyle()" type="button" submit-button>Save</button>
                    </div>
                </form>
            </div>
        </mat-step>
        <mat-step *ngIf="(flow === ConsumerOnboardFlow.ONBOARD_REQUIRED || flow === ConsumerOnboardFlow.BANKING_INFO_REQUIRED) && !accountLinked" state="banking" [completed]="accountLinked" [editable]="consumerValid && !accountLinked" label="Banking Information">
            <div class="d-flex justify-content-center">
                <pt-yodlee-onboarding *ngIf="consumerValid" [landingSettings]="landingSettings" [accountToken]="accountToken" [taskId]="task.id" [testAccount]="useTestBankAccount" (resultSelected)="onSelection($event)"></pt-yodlee-onboarding>
            </div>
        </mat-step>
        <mat-step state="complete">
            <ng-template matStepLabel>Complete</ng-template>
            <div class="d-flex justify-content-center">
                <div *ngIf="accountLinkResult">
                    <p *ngIf="!task.variables.transactionVerificationId">Your bank account has been successfully linked.</p>
                    <p *ngIf="task.variables.transactionVerificationId && task.variables.retailPaymentProcessId">You will receive a message with a verification code to complete your transaction.</p>
                    <div class="pull-right mt-2">
                        <button pc-no-dbl-click class="blue-button" (click)="onSuccess()" [ngStyle]="buttonStyle()" submit-button>OK</button>
                    </div>
                </div>
            </div>
        </mat-step>
    </mat-stepper>
    <footer class="card-footer text-center"
            [ngStyle]="{'background': landingSettings?.primaryColor,
            'color':landingSettings?.secondaryColor}" *ngIf="!termsAccepted">
        <p>Copyright &#169; 2024 Confia Financial LLC. All rights reserved.<br>For more
        information about Confia, please see <a [ngStyle]="{'color':landingSettings?.secondaryColor}" href="https://www.confia.fi" target="_blank">www.confia.fi</a>. <br>Please see our
        privacy policy at <a [ngStyle]="{'color':landingSettings?.secondaryColor}" href="https://www.confia.fi/online-privacy-policy/" target="_blank">www.confia.fi/online-privacy-policy/</a></p>
    </footer>
</div>
<div class="splash-bg" *ngIf="!termsAccepted">
    <div class="splash-body" *ngIf="errorMessage">
        <h5>{{errorMessage}}</h5>
    </div>
</div>
