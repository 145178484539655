import { Component, OnInit, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { Reconciliation, ReportsService, DataroomService, PagedResponse, AuthService } from 'projects/services/src/public-api';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
@Component({
    selector: 'pt-authority-daily-reconciliation-report',
    templateUrl: './authority-daily-reconciliation-report.component.html',
    styleUrls: ['./authority-daily-reconciliation-report.component.scss']
})
export class AuthorityDailyReconciliationReportComponent implements OnInit, OnDestroy, AfterViewInit {

    isLoadingResults = true;
    reconciliations: Reconciliation[] = [];
    displayedColumns: string[] = ['generatedDate', 'fboAccountBalance', 'subLedgerBalance', 'status', 'reconFile'];

    pageTracking: PageTracking;
    resultsLength = 0;
    subscription: any;

    @ViewChild('downloadReportLink') downloadReportLink: ElementRef;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private reportService: ReportsService,
                private dataroomService: DataroomService,
                private cdr: ChangeDetectorRef) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'created', 'desc');
    }

    downloadFile(reconciliation: Reconciliation) {
        this.dataroomService.downloadResource(reconciliation.documentDetails, this.downloadReportLink);
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.reportService.getDailyReconciliationReports(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<Reconciliation>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((reports: Reconciliation[]) => {
            const results: Reconciliation[] = [];
            for (const report of reports) {
                this.loadReport(report);
                results.push(report);
            }
            this.reconciliations = results;
            UIUtils.scrollDashboardToTop();
        });
    }

    loadReport(reconciliation: Reconciliation) {
        if (reconciliation.documentId) {
            this.dataroomService.getDocumentById(reconciliation.documentId).subscribe((upload: any) => {
                reconciliation.documentDetails = upload;
            });
        }
    }

}
