import { Component } from '@angular/core';
import { MerchantAccount, MerchantAccountStatus } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-merchant-account-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class MerchantAccountBadgeComponent extends BaseBadgeComponent<MerchantAccount> {

    setBadgeData() {
        this.badgeToolTip = this.parent.status;
        if (this.parent.merchantAccountConfig.status === MerchantAccountStatus.DISABLED) {
            this.badgeToolTip = 'DEACTIVATED';
            this.badgeText = 'DEACTIVATED';
            this.badgeClass = 'red-badge';
        } else if (this.parent.merchantAccountConfig.status === MerchantAccountStatus.PENDING && this.parent.status !== MerchantAccountStatus.REJECTED) {
            this.badgeToolTip = 'PENDING';
            this.badgeText = 'REVIEW';
            this.badgeClass = 'orange-badge';
        } else {
            switch (this.parent.status) {
                case MerchantAccountStatus.ACTIVE:
                    this.badgeText = 'ACTIVE';
                    this.badgeClass = 'green-badge';
                    break;
                case MerchantAccountStatus.DISABLED:
                    this.badgeText = 'DISABLED';
                    this.badgeText = 'DISABLED';
                    this.badgeClass = 'red-badge';
                    break;
                case MerchantAccountStatus.PENDING:
                    this.badgeText = 'REVIEW';
                    this.badgeClass = 'orange-badge';
                    break;
                default:
                    this.badgeText = 'REJECTED';
                    this.badgeClass = 'red-badge';
                    break;
            }
        }
    }
}
