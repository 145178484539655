export const environment = {
    production: true,
    APIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL',
    EntityAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/entities',
    LocationAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/entities',
    SecurityAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/security',
    CommentAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/comments',
    AccountAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/entities',
    RecordsAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/records',
    PaymentAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/payments',
    AccountingAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/accounting',
    MetrcAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/metrc',
    DataroomAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/dataroom',
    ReportsAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/fi-reports',
    SmartContractsAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/smart-contracts',
    WorkflowAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/workflow',
    NotificationsAPIEndpoint: 'ENV_GATEWAY_WS_PROTOCOL://ENV_GATEWAY_URL/ms/ws/notifications',
    PartnerAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/partner',
    AuditAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/auditing',
    LenderAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/lending',
    FinzlyAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/finzly',
    NBCUAPIEndpoint: 'ENV_GATEWAY_PROTOCOL://ENV_GATEWAY_URL/ms/nbcu'
};
