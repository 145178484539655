import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'projects/components/src/public-api';
import {
    TaxInfo, OnboardingTrackingService, FinancialDetails, FinancialDetailsFormService, MemberRegistrationType,
    CustomValidators, MemberService, MemberStatus, MemberFormService, Member
} from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-financial-details',
    templateUrl: './financial-details.component.html',
    styleUrls: ['./financial-details.component.scss']
})
export class MemberFinancialDetailsComponent implements OnInit {

    @Input() memberId: string;
    @Input() userId: string;
    @Input() task: any;
    @Input() readOnly = false;
    @Input() memberStatus: MemberStatus;
    @Input() changeTrigger: number;
    @Input() memberRegistrationType: MemberRegistrationType;

    @Output() next: EventEmitter<string> = new EventEmitter<string>();

    taxForm: UntypedFormGroup;
    financialDetailsForm: UntypedFormGroup;
    initialDepositReadOnly = true;
    nonPremiumMemberReadOnly = true;

    constructor(private memberFormService: MemberFormService,
                private financialDetailsFormService: FinancialDetailsFormService,
                private memberService: MemberService,
                private dialog: MatDialog) {}

    ngOnInit() {
        this.isFinancialDetailsValid = this.isFinancialDetailsValid.bind(this);
        this.onNavigate = this.onNavigate.bind(this);
        this.onSave = this.onSave.bind(this);
        this.nonPremiumMemberReadOnly = this.memberRegistrationType !== MemberRegistrationType.PREMIUM_MEMBER;
        this.initialDepositReadOnly = !((this.memberStatus === MemberStatus.APPLIED || this.memberStatus === MemberStatus.UNDER_REVIEW) && this.memberRegistrationType === MemberRegistrationType.PREMIUM_MEMBER);
        this.initForms();
    }

    initForms(callback?: any) {
        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            this.initTaxInfo(member.taxInfo);
            this.initFinancialDetails(member.financialDetails, callback);
            this.onInitialDepositChange();
        });
    }

    initTaxInfo(taxInfo: TaxInfo) {
        this.taxForm = this.memberFormService.initializeTaxForm(taxInfo);
        if (!taxInfo) {
            this.taxForm.controls['memberIdCtrl'].setValue(this.memberId);
        }
        if (this.readOnly) {
            this.taxForm.disable();
        }
        this.taxForm.markAsPristine();
        this.taxForm.valueChanges.subscribe(() => {
            this.updateValidity();
        });
    }

    initFinancialDetails(financialDetails: FinancialDetails, callback: any) {
        if (financialDetails) {
            this.financialDetailsForm = this.financialDetailsFormService.initializeForm(financialDetails, this.memberRegistrationType === MemberRegistrationType.PREMIUM_MEMBER);
            if (this.memberStatus === MemberStatus.APPLIED && !this.readOnly) {
                // this.syncFinancialDetailsWithRiskProfile();
            }
        } else {
            this.financialDetailsForm = this.financialDetailsFormService.initializeForm(null, this.memberRegistrationType === MemberRegistrationType.PREMIUM_MEMBER);
            this.financialDetailsForm.controls['memberIdCtrl'].setValue(this.memberId);
        }
        if (this.readOnly) {
            this.financialDetailsForm.disable();
        } else if (this.initialDepositReadOnly) {
            this.financialDetailsForm.get('plannedInitialDepositAmountCtrl').disable();
            this.financialDetailsForm.get('initialDepositSourceCtrl').disable();
        }
        if (this.nonPremiumMemberReadOnly) {
            this.financialDetailsForm.get('expectedMonthlyCashDepositCtrl').disable();
        }
        this.updateValidity();
        if (callback) {
            callback();
        }
        this.financialDetailsForm.markAsPristine();
        this.financialDetailsForm.valueChanges.subscribe(() => {
            this.updateValidity();
        });
    }

    onNavigate(reset: any) {
        if (this.isFinancialDetailsValid()) {
            const dialogConfig: MatDialogConfig = {};
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'normal-modal';
            dialogConfig.disableClose = true;

            dialogConfig.data = {
                title: 'You have unsaved changes.',
                description: 'Click Save to save your changes and continue, or click Ignore to continue without saving.',
                confirmText: 'Save',
                confirmAction: 'save',
                rejectText: 'Ignore',
                rejectAction: 'ignore'
            };

            const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

            dialog?.afterClosed().subscribe((confirmAction: any) => {
                if (confirmAction === 'save') {
                    this.updateInformation(() => {
                        reset();
                        this.next.emit();
                    });
                } else if (confirmAction === 'ignore') {
                    this.initForms(() => {
                        reset();
                        this.next.emit();
                    });
                } else {
                    reset();
                }
            });
        } else {
            reset();
            this.next.emit();
        }
    }

    onSave(reset?: any) {
        this.updateInformation(reset);
    }

    updateInformation(callback?: any) {
        const financialInfo = this.financialDetailsFormService.getFinancialDetails(this.financialDetailsForm);
        const taxInfo = this.memberFormService.getTaxInfo(this.taxForm);
        this.memberService.saveTaxInfo(taxInfo).subscribe(() => {
            this.memberService.saveFinancialDetails(financialInfo).subscribe(() => {
                this.initForms(callback);
            });
        });
    }

    // syncFinancialDetailsWithRiskProfile() {
    //     this.memberService.getFinancialDetails(this.memberId).subscribe((financialDetails: FinancialDetails) => {
    //         if (financialDetails) {
    //             this.memberService.getRiskProfile(this.memberId).subscribe((riskProfile: RiskProfile) => {
    //                 riskProfile.expectedMonthlyCashDepositAmount = financialDetails.expectedMonthlyCashDepositAmount;
    //                 riskProfile.expectedMonthlyPaymentAmount = financialDetails.expectedMonthlyWithdrawals;
    //                 riskProfile.expectedMonthlyTransactions = financialDetails.expectedMonthlyNumberOfPayments;
    //                 riskProfile.maxNumberOfTransactionsPerDay = financialDetails.expectedMonthlyNumberOfPayments;
    //                 riskProfile.monthlyCashDepositAmountLimit = financialDetails.expectedMonthlyCashDepositAmount * this.expectationBuffer;
    //                 riskProfile.maxNumberOfTransactionsPerMonth = financialDetails.expectedMonthlyNumberOfPayments * this.expectationBuffer;
    //                 this.memberService.saveRiskProfile(riskProfile).subscribe();
    //             });
    //         }
    //     });
    // }

    updateValidity() {
        OnboardingTrackingService.setFinancialDetails(this.memberFormService.getTaxInfo(this.taxForm), this.financialDetailsFormService.getFinancialDetails(this.financialDetailsForm));
        OnboardingTrackingService.setFinancialInfoDirty(this.taxForm.dirty || this.financialDetailsForm.dirty);
    }

    isFinancialDetailsValid() {
        const formsValid = this.taxForm && this.taxForm.valid && this.financialDetailsForm && this.financialDetailsForm.valid;
        return OnboardingTrackingService.isFinancialDetailsValid() && formsValid && OnboardingTrackingService.isFinancialInfoDirty();
    }

    getBusinessActivities() {
        return this.financialDetailsFormService.getBusinessActivities();
    }

    onInitialDepositChange() {
        if (this.financialDetailsForm.controls['plannedInitialDepositAmountCtrl'].value > 0) {
            this.financialDetailsForm.get('initialDepositSourceCtrl').setValidators([Validators.required, Validators.maxLength(256), CustomValidators.noLeadingOrTrailingWhitespace]);
        } else {
            this.financialDetailsForm.get('initialDepositSourceCtrl').setValidators([Validators.maxLength(256), CustomValidators.noLeadingOrTrailingWhitespace]);
        }
        this.financialDetailsForm.get('initialDepositSourceCtrl').updateValueAndValidity();
    }
}
