import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'pc-form-text-input',
    templateUrl: './form-text-input.component.html'
})
export class FormTextInputComponent {

    @Input() required: boolean;
    @Input() label: string;
    @Input() placeholder = '';
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() class = '';
    @Input() colSize = 8;
    @Input() restrictToDigits: boolean;

    onAmountChanged() {
        if (this.restrictToDigits) {
            const value = this.formGroup.controls[this.formCtrl].value;
            if (value !== null && value !== '') {
                this.formGroup.controls[this.formCtrl].setValue(
                    value.replace(/[^\d]/gi, '')
                );
            }
        }
    }

}
