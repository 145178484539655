import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, WorkflowService } from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-new-member-account-modal',
    templateUrl: './new-member-account-modal.component.html'
})
export class NewMemberAccountModalComponent extends BaseModalComponent<NewMemberAccountModalComponent> implements OnInit {

    newAccountForm: FormGroup;
    memberId: string;

    constructor(private workflowService: WorkflowService,
                public authService: AuthService,
                private formBuilder: FormBuilder,
                private notifier: NotificationService,
                dialogRef: MatDialogRef<NewMemberAccountModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberId = data.memberId;
        this.newAccountForm = this.formBuilder.group({
            accountNameCtrl: new FormControl('', [Validators.required, Validators.maxLength(64)]),
            accountFriendlyNameCtrl: new FormControl('', [Validators.required, Validators.maxLength(64)]),
            isPublicCtrl: new FormControl(true)
        });
        if (!this.authService.isAuthority()) {
            this.newAccountForm.addControl('notesCtrl', new FormControl('', [Validators.required, Validators.maxLength(256)]));
            this.newAccountForm.addControl('acknowledgeCtrl', new FormControl('', [Validators.required]));
        }
    }

    ngOnInit() {
        this.isNewAccountFormValid = this.isNewAccountFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    onSubmit(reset: any) {
        const newAccountRequest = {
            memberId: this.memberId,
            accountName: this.newAccountForm.controls['accountNameCtrl'].value,
            accountFriendlyName: this.newAccountForm.controls['accountFriendlyNameCtrl'].value,
            public: this.newAccountForm.controls['isPublicCtrl'].value,
            notes: ''
        };
        if (this.newAccountForm.controls['notesCtrl']) {
            newAccountRequest.notes = this.newAccountForm.controls['notesCtrl'].value;
        }
        this.workflowService.registerNewMemberAccount(newAccountRequest).subscribe(() => {
            this.close(true);
        }, (errorResponse: any) => {
            reset();
            throw errorResponse;
        });
    }

    isNewAccountFormValid() {
        return !this.newAccountForm.invalid && (!this.newAccountForm.controls['acknowledgeCtrl'] || this.isAcknowledged());
    }

    isAcknowledged() {
        return this.newAccountForm.controls['acknowledgeCtrl'].value;
    }
}
