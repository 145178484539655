import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService, MemberFunction } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-members-table-header',
    templateUrl: './members-table-header.component.html'
})
export class MembersTableHeaderComponent implements OnInit {

    @Input() activeTab: string;

    @Output() tabChanged: EventEmitter<string> = new EventEmitter<string>();

    isPartner: boolean;

    constructor(private authService: AuthService) {}

    ngOnInit() {
        this.isPartner = this.authService.hasMemberFunction(MemberFunction.PARTNER);
    }

    setActiveTab(activeTab: string) {
        this.activeTab = activeTab;
        this.tabChanged.emit(this.activeTab);
    }

    getTabClass(tabOption: string) {
        if (this.activeTab === tabOption) {
            return 'tab-option-selected';
        } else {
            return 'tab-option';
        }
    }

    refreshList() {
        this.tabChanged.emit(this.activeTab);
    }

}
