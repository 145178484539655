<div class="dashboard-info">
    <div class="row no-gutters">
        <div class="col-auto dashboard-header mb-3">
            <img class="dashboard-icon" src="assets/images/report-icon-2.svg" alt="">
            <span>Reports</span>
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <select class="form-control form-input-table-header form-select-sm" type="text" [(ngModel)]="reportType" (change)="reportTypeChange()">
                <option value="SAR">SAR</option>
                <option value="CTR">CTR</option>
                <option *ngIf="!isAuthorityReporter()" value="DAILY_REC_REPORTS">Daily REC Reports</option>
                <option *ngIf="!isAuthorityReporter()" value="FINCEN_INVESTIGATIONS">FinCEN Investigations</option>
                <option *ngIf="!isAuthorityReporter()" value="OPERATIONAL">Operational Reports</option>
            </select>
        </div>
        <div class="col-2" *ngIf="!isAuthorityReporter() && (reportType === 'SAR' || reportType === 'CTR')">
            <select class="form-control form-input-table-header form-select-sm" type="text" [(ngModel)]="filingInstitution" (change)="reportFilingInstitutionChange()">
                <option value="NBCU">NBCU</option>
                <option value="POINTCHAIN">Pointchain</option>
            </select>
        </div>
        <div class="col-4" *ngIf="reportType !== 'DAILY_REC_REPORTS' && reportType !== 'OPERATIONAL' && reportType !== 'FINCEN_INVESTIGATIONS'">
            <pc-member-selector [formGroup]="formGroup" [displayLabel]="false" [formCtrl]="'memberCtrl'" [memberTypes]="types" [statuses]="statuses" [placeholder]="placeHolder"
                [includeAddress]="true" (memberSelected)="memberSelected($event)">
            </pc-member-selector>
        </div>
        <div class="col-3" *ngIf="reportType === 'SAR' || reportType === 'CTR'">
            <select class="form-control form-input-table-header form-select-sm" type="text" [(ngModel)]="reportStatus" (change)="reportStatusChange()">
                <option value="ALL">All Statuses</option>
                <option value="AUTHORITY_REVIEW">Under Review</option>
                <option value="READY_FOR_SUBMISSION">Ready For Submission</option>
                <option value="SUBMISSION_IN_PROGRESS">Submission In Progress</option>
                <option value="SUBMISSION_SUCCESSFUL">Submission Successful</option>
                <option value="SUBMISSION_FAILED">Submission Failed</option>
                <option value="ACCEPTED">Accepted</option>
                <option value="ACCEPTED_WITH_WARNINGS">Accepted With Warnings</option>
                <option value="REJECTED">Rejected</option>
                <option value="ACKNOWLEDGED">Acknowledged</option>
                <option value="ACKNOWLEDGEMENT_FAILED">Acknowledgement Failed</option>
                <option value="WILL_NOT_BE_SUBMITTED">Will Not Be Submitted</option>
            </select>
        </div>
        <div class="col-9" *ngIf="reportType === 'FINCEN_INVESTIGATIONS'">
            <button class="blue-button pull-right" *ngIf="authService.isAuthority()" (click)="uploadInvestigationFiles()" type="button">
                <i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Generate Report</div>
            </button>
        </div>
    </div>

    <div class="row no-gutters">
        <div class="col-sm-12" *ngIf="reportType === 'SAR'">
            <pt-authority-sar-reports [memberId]="memberId" [reportStatus]="reportStatus" [filingInstitution]="filingInstitution">
            </pt-authority-sar-reports>
        </div>
        <div class="col-sm-12" *ngIf="reportType === 'CTR'">
            <pt-authority-ctr-reports [memberId]="memberId" [reportStatus]="reportStatus" [filingInstitution]="filingInstitution">
            </pt-authority-ctr-reports>
        </div>
        <div class="col-sm-12" *ngIf="reportType === 'DAILY_REC_REPORTS'">
            <pt-authority-daily-reconciliation-report>
            </pt-authority-daily-reconciliation-report>
        </div>
        <div class="col-sm-12" *ngIf="reportType === 'FINCEN_INVESTIGATIONS'">
            <pt-authority-fincen-investigation-report [changeTrigger]="changeTrigger">
            </pt-authority-fincen-investigation-report>
        </div>
        <div class="col-sm-12" *ngIf="reportType === 'OPERATIONAL'">
            <pt-authority-operational-reports>
            </pt-authority-operational-reports>
        </div>
    </div>
</div>
