<form [formGroup]="formGroup">
    <pc-form-selector [required]="true" [label]="'Deposit Method'"  [formCtrl]="'transactionTypeCtrl'" [formGroup]="formGroup" [options]="depositMethods"
        [idValue]="'id'" [displayValue]="'name'" [defaultValue]="''">
    </pc-form-selector>

    <pc-form-date-picker [label]="'Deposit Date'" [formCtrl]="'transactionDateCtrl'" [formGroup]="formGroup"></pc-form-date-picker>

    <pc-text-input [required]="true" [label]="'To Account'" [placeholder]="'To Account'" [formCtrl]="'memberAccountNameCtrl'" [formGroup]="formGroup">
    </pc-text-input>

    <pc-form-text-area [label]="'Deposit Notes'" maxlength="1024" [formCtrl]="'notesCtrl'" [formGroup]="formGroup"></pc-form-text-area>
</form>
