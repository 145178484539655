import { Component } from '@angular/core';
import { Bill, BusinessClient, Invoice } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-accounting-party-type-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class AccountingPartyTypeBadgeComponent extends BaseBadgeComponent<BusinessClient<Bill | Invoice>> {

    setBadgeData() {
        this.badgeToolTip = this.parent.accountingData.type;
        this.badgeText = this.parent.accountingData.type;
        this.badgeClass = 'blue-badge';
    }

}
