<div class="col-12">
    <div class="row mb-3">
        <div class="col-5">
            <span class="form-title">Member Accounts</span>
        </div>
    </div>
    <div class="horizontal-scroll mat-table-container">
        <div class="table mat-table-spinner" *ngIf="isLoadingResults">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table [dataSource]="merchantAccounts" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
            <ng-container matColumnDef="merchant_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="member.sortName"> Member </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/administration/member/' + element.member?.id]" [routerLinkActive]="['router-link-active']">
                        <b> {{ element.member.name }} </b>
                        <br>
                        <b *ngIf="element.member?.dbaName"> DBA: {{ element.member.dbaName }} </b>
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Attached </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.created"> {{ element.created | date:'mediumDate' }}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="account_number">
                <th mat-header-cell *matHeaderCellDef> Account Number </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.merchantAccountConfig.defaultOutgoingTransactionType">{{element.outgoingAccountNumber}} ({{ element.merchantAccountConfig.defaultOutgoingTransactionType | translate}})<br></span>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="centered" mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let element" class="centered">
                    <pc-merchant-account-badge [parent]="element"></pc-merchant-account-badge>
                    <div *ngIf="element.member.jurisdiction">{{Utils.getJurisdictionName(element.member.jurisdiction)}}</div>
                    <div *ngIf="element.member.tier === RiskTier.TIER_1">Tier 1</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="centered"> Actions </th>
                <td mat-cell *matCellDef="let element" class="centered">
                    <i class="fa fa-2x" *ngIf="canEnableDisableMerchant(element)" [class.fa-toggle-on]="isEnabled(element)" [class.fa-toggle-off]="!isEnabled(element)" (click)="toggleEnabled($event, element)"></i>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.disabled]="!isEnabled(row)"></tr>
        </table>
        <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>
</div>
