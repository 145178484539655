<div>
    <form [formGroup]="merchantForm">
        <div class="modal-header">
            <div class="pull-left"><img class="dashboard-icon " src="assets/images/account-2.svg" alt=""></div>
            <label class="form-title">Add Accounting Merchant Account</label>
            <i class="modal-close" (click)="close()">&times;</i>
        </div>
        <div class="modal-content">
            <div class="row">
                <div class="col-12">
                    <pc-business-client-selector [formGroup]="merchantForm" [formCtrl]="'merchantCtrl'" [merchantNameFormCtrl]="'merchantNameCtrl'" [placeholder]="'Search Accounting Parties'"
                        [accountingLinked]="isAccountingLinked" [includeConfiaMerchants]="false" (businessClientSelected)="setAccountingBusinessClient($event)" (closeTransactionModal)="close($event)"></pc-business-client-selector>
                </div>
            </div>
            <div *ngIf="verifyBusinessClient" class="row">
                <div class="col-12">
                    <p>This accounting party seems be linked as a merchant, but not linked to your accounting system.</p>
                </div>
                <div class="col-6 d-flex justify-content-center">
                    <div>
                        <label class="form-label mb-2">Merchant Info</label>
                        <div>{{businessClient.merchantData.name}}</div>
                        <div *ngIf="businessClient.merchantData.dbaName">{{businessClient.merchantData.dbaName}}</div>
                        <div *ngIf="businessClient.merchantData.email">{{businessClient.merchantData.email}}</div>
                        <div *ngIf="businessClient.merchantData.officePhone">{{businessClient.merchantData.officePhone}}</div>
                        <div *ngIf="businessClient.merchantData.billAddress" class="mt-2">{{businessClient.merchantData.billAddress.streetAddressOne}}<br/>
                            {{businessClient.merchantData.billAddress.city}}, {{businessClient.merchantData.billAddress.stateProvince}}<br/>
                            {{businessClient.merchantData.billAddress.country}}<br/>
                            {{businessClient.merchantData.billAddress.zipPostalCode}}<br/>
                        </div>
                        <div *ngIf="businessClient.accountingData.description" class="mt-2">Description: {{businessClient.accountingData.description}}</div>
                    </div>
                </div>
                <div class="col-6 d-flex justify-content-center">
                    <div>
                        <label class="form-label mb-2">Accounting Info</label>
                        <div>{{businessClient.accountingData.name}}</div>
                        <div *ngIf="businessClient.accountingData.dbaName">{{businessClient.accountingData.dbaName}}</div>
                        <div *ngIf="businessClient.accountingData.email">{{businessClient.accountingData.email}}</div>
                        <div *ngIf="businessClient.accountingData.officePhone">{{businessClient.accountingData.officePhone}}</div>
                        <div *ngIf="businessClient.accountingData.billAddress" class="mt-2">{{businessClient.accountingData.billAddress.streetAddressOne}}<br/>
                            {{businessClient.accountingData.billAddress.city}}, {{businessClient.accountingData.billAddress.stateProvince}}<br/>
                            {{businessClient.accountingData.billAddress.country}}<br/>
                            {{businessClient.accountingData.billAddress.zipPostalCode}}<br/>
                        </div>
                        <div *ngIf="businessClient.accountingData.contactName" class="mt-2">Contact Name: {{businessClient.accountingData.contactName}}</div>
                        <div *ngIf="businessClient.accountingData.externalId" matTooltipClass="tooltip" matTooltip="{{businessClient.accountingData.externalId}}" class="mt-2 text-truncate">{{businessClient.accountingData.type | translate}} ID: {{businessClient.accountingData.externalId}}</div>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <p>Please confirm the information, and either link your accounting system to this merchant, or create a new merchant if this is not the correct party.</p>
                </div>
            </div>
            <div *ngIf="!verifyBusinessClient" class="row">
                <dl class="row" *ngIf="existingAccount">
                    <div class="col-3"></div>
                    <div class="col-6">
                        <dl class="row">
                            <dt class="col-sm-4">Merchant Name:</dt>
                            <dd class="col-sm-8">{{existingAccount.merchantMemberAccount.member.name}}</dd>

                            <dt class="col-sm-4" *ngIf="getDbaName(existingAccount.merchantMemberAccount.member)">Doing Business As:</dt>
                            <dd class="col-sm-8" *ngIf="getDbaName(existingAccount.merchantMemberAccount.member)">{{getDbaName(existingAccount.merchantMemberAccount.member)}}</dd>
                        </dl>
                    </div>
                    <div class="col-3"></div>
                </dl>
                <div class="row">
                    <div class="col-2" *ngIf="(!showAchInfo && newMerchant) || (existingAccount && showAchInfo)">
                    </div>
                    <div class="col-4" *ngIf="!newMerchant && !showAchInfo">
                    </div>
                    <div class="col-4" *ngIf="newMerchant || existingAccount">
                        <span class="subtitle">Merchant Address Info</span>
                        <pc-address-input [addressForm]="merchantMemberAccountForm" [lockCountry]="true"></pc-address-input>
                    </div>
                    <div class="col-4" *ngIf="newMerchant">
                        <span class="subtitle">Merchant Contact Info</span>
                        <pc-email-input [label]="'Contact Email'"
                            [formCtrl]="'ownerEmailCtrl'" [formGroup]="merchantMemberAccountForm">
                        </pc-email-input>
                        <pc-text-input [label]="'Contact Name'"
                            [formCtrl]="'ownerNameCtrl'" [formGroup]="merchantMemberAccountForm">
                        </pc-text-input>
                        <pc-phone-input [label]="'Contact Phone Number'"
                            [formCtrl]="'ownerPhoneCtrl'" [formGroup]="merchantMemberAccountForm">
                        </pc-phone-input>
                        <pc-text-input [label]="'Nature Of Business'"
                            [formCtrl]="'briefDescriptionCtrl'" [formGroup]="merchantMemberAccountForm">
                        </pc-text-input>
                        <pc-text-input [label]="'Name of License Holder (Tier 1 Only)'" placeholder="Enter license holder, if different from merchant name"
                            [formCtrl]="'licenseHolderCtrl'" [formGroup]="merchantMemberAccountForm">
                        </pc-text-input>
                    </div>
                    <div class="col-4" *ngIf="showAchInfo">
                        <div *ngIf="newMerchant">
                            <span class="subtitle">Accounting Info</span>
                            <div class="mb-2">
                                <div class="d-flex">
                                    <div class="text-nowrap"><label class="form-label">{{businessClient.accountingData.type === AccountingPartyType.SUPPLIER ? 'Supplier ' : 'Customer '}} ID:&nbsp;</label></div>
                                    <div class="text-truncate" matTooltipClass="tooltip" matTooltip="{{businessClient.accountingData.externalId}}">{{businessClient.accountingData.externalId}}</div>
                                </div>
                            </div>
                            <span class="subtitle">Merchant ACH Info</span>
                            <div>
                                <pc-text-input [label]="'Name of Account Holder'" [placeholder]="'Account Holder'"
                                    [formCtrl]="'achAccountHolderCtrl'" [formGroup]="merchantForm"
                                    (changed)="updateBankAccountValidity($event)">
                                </pc-text-input>
                                <pc-text-input [label]="'Account Number'" [placeholder]="'Account Number - 5 to 17 digits'"
                                    [formCtrl]="'achAccountNumberCtrl'" [formGroup]="merchantForm"
                                    (changed)="updateBankAccountValidity($event)">
                                </pc-text-input>
                                <pc-text-input [label]="'ACH Routing Number'" [placeholder]="'9 digit ACH routing number'"
                                    [formCtrl]="'achRoutingNumberCtrl'" [formGroup]="merchantForm"
                                    (changed)="updateBankAccountValidity($event)">
                                </pc-text-input>
                                <pc-text-input [label]="'Wire Routing Number'" [placeholder]="'9 digit Wire routing number'"
                                    [formCtrl]="'wireRoutingNumberCtrl'" [formGroup]="merchantForm"
                                    (changed)="updateBankAccountValidity($event)">
                                </pc-text-input>
                                <a class="dashboard-list-a" (click)="upload.click()">or upload an invoice with ACH info</a>
                                <input hidden type="file" id="upload" #upload (change)="selectFile($event)" placeholder="Upload file"
                                       accept=".pdf,.doc,.docx">
                                <div *ngIf="file">
                                    <hr class="separator">
                                    <i class="fa fa-times" (click)="deleteDocument()"></i>
                                    <a *ngIf="file" class="dashboard-list-a"><i class="fa fa-paperclip"></i>{{file.name}}</a>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!newMerchant">
                            <span class="subtitle">Merchant Payment Options</span>
                            <div *ngIf="existingAccount && (existingAccount.merchantAccountConfig.merchantAccountType === SupportedTransactionType.OUTGOING || existingAccount.merchantAccountConfig.merchantAccountType === SupportedTransactionType.BOTH)">
                                Accepts Payments via:
                                <ul>
                                    <li *ngIf="existingAccount.merchantAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_ACH_TO_MERCHANT">ACH</li>
                                    <li *ngIf="existingAccount.merchantAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_WIRE_TO_MERCHANT">Wire</li>
                                    <li *ngIf="existingAccount.merchantAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_CHECK_TO_MERCHANT">Check</li>
                                    <li *ngIf="existingAccount.merchantAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_DIGITAL_CHECK_TO_MERCHANT">Digital Check</li>
                                </ul>
                            </div>
                            <div *ngIf="existingAccount && (existingAccount.merchantAccountConfig.merchantAccountType === SupportedTransactionType.INCOMING || existingAccount.merchantAccountConfig.merchantAccountType === SupportedTransactionType.BOTH)">
                                Pays via:
                                <ul>
                                    <li *ngIf="existingAccount.merchantAccountConfig.defaultIncomingTransactionType === TransactionType.INCOMING_ACH_FROM_MERCHANT">ACH</li>
                                    <li *ngIf="existingAccount.merchantAccountConfig.defaultIncomingTransactionType === TransactionType.INCOMING_WIRE_FROM_MERCHANT">Wire</li>
                                    <li *ngIf="existingAccount.merchantAccountConfig.defaultIncomingTransactionType === TransactionType.INCOMING_CHECK_FROM_MERCHANT">Check</li>
                                </ul>
                            </div>
                            <div *ngIf="existingAccount">
                                <span *ngIf="getAccountingIds(AccountingPartyType.SUPPLIER).length">Supplier IDs:</span>
                                <ul *ngIf="getAccountingIds(AccountingPartyType.SUPPLIER).length">
                                    <li *ngFor="let supplierId of getAccountingIds(AccountingPartyType.SUPPLIER)" matTooltipClass="tooltip" matTooltip="{{supplierId.accountingExternalId}}" class="text-truncate">{{supplierId.accountingExternalId}}</li>
                                </ul>
                                <span *ngIf="getAccountingIds(AccountingPartyType.CUSTOMER).length">Customer IDs:</span>
                                <ul *ngIf="getAccountingIds(AccountingPartyType.CUSTOMER).length">
                                    <li *ngFor="let supplierId of getAccountingIds(AccountingPartyType.CUSTOMER)" matTooltipClass="tooltip" matTooltip="{{supplierId.accountingExternalId}}" class="text-truncate">{{supplierId.accountingExternalId}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" *ngIf="!newMerchant && !showAchInfo">
                    </div>
                    <div class="col-2" *ngIf="(!showAchInfo && newMerchant) || (existingAccount && showAchInfo)">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div *ngIf="merchantAccountExistsError" class="duplicate-account">
                <pc-error-message [error]="merchantAccountExistsError" [width]="'full'"></pc-error-message>
            </div>
            <pc-submit-button *ngIf="!existingAccount && newMerchant" [submit]="onCancel" [text]="'Cancel'"></pc-submit-button>
            <pc-submit-button *ngIf="!existingAccount && newMerchant" [enabled]="isMerchantFormValid" [submit]="onSubmit" [text]="'Submit Accounting Party for Review'"></pc-submit-button>
            <div *ngIf="verifyBusinessClient" class="d-flex justify-content-end">
                <pc-submit-button [submit]="verifyMerchantLinkage" [text]="'Use Existing Merchant'"></pc-submit-button>
                <button class="blue-button" (click)="createNewMerchant()" type="button">Create New</button>
            </div>
        </div>
    </form>
</div>
