<div [class]="getClass()">
  <a [routerLink]="disabled() ? null : [link]" [routerLinkActive]="['router-link-active']" [queryParams]="queryParams" class="sidenav-a">
    <div class="row" *ngIf="expandedMenu">
      <div class="col-3 pull-right sidenav-icon">
        <img [src]="icon" alt="*">
      </div>
      <div class="col-9 pull-left">
        {{text}}
      </div>
    </div>
    <div class="row" *ngIf="!expandedMenu">
      <div class="col-12">
        <img [src]="icon" [matTooltip]="text">
      </div>
    </div>
  </a>
</div>
