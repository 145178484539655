<div class="col-sm-12 horizontal-scroll mat-table-container mt-3">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="fincenInvestigations" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="submissionDate">
            <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header> Date </th>
            <td mat-cell class="centered" *matCellDef="let element"><pc-date-time [date]="element.submissionDate"></pc-date-time></td>
        </ng-container>
        <ng-container matColumnDef="personDocument">
            <th mat-header-cell *matHeaderCellDef> Source Persons </th>
            <td mat-cell *matCellDef="let element">
                <a *ngIf="element.personsUploadDocumentId" (click)="downloadFile(element.personsUploadDocumentId)">
                    {{element.personsUploadDocumentName}}
                </a>
                <span *ngIf="!element.personsUploadDocumentId">N/A</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="personStatus">
            <th mat-header-cell class="centered" *matHeaderCellDef> Person Report Status </th>
            <td mat-cell class="centered" *matCellDef="let element">
                <pc-fincen-person-investigation-badge *ngIf="element.personsUploadDocumentId" [parent]="element"></pc-fincen-person-investigation-badge>
                <span *ngIf="!element.personsUploadDocumentId">N/A</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="personResults">
            <th mat-header-cell *matHeaderCellDef> Person Results </th>
            <td mat-cell *matCellDef="let element">
                <a *ngIf="element.personsResultsDocumentId" (click)="downloadFile(element.personsResultsDocumentId)">
                    {{element.personsResultsDocumentName}}
                </a>
                <span *ngIf="!element.personsResultsDocumentId">N/A</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="companyDocument">
            <th mat-header-cell *matHeaderCellDef> Source Companies </th>
            <td mat-cell *matCellDef="let element">
                <a *ngIf="element.companiesUploadDocumentId" (click)="downloadFile(element.companiesUploadDocumentId)">
                    {{element.companiesUploadDocumentName}}
                </a>
                <span *ngIf="!element.companiesUploadDocumentId">N/A</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="companyStatus">
            <th mat-header-cell class="centered" *matHeaderCellDef> Company Report Status </th>
            <td mat-cell class="centered" *matCellDef="let element">
                <pc-fincen-company-investigation-badge *ngIf="element.companiesUploadDocumentId" [parent]="element"></pc-fincen-company-investigation-badge>
                <span *ngIf="!element.companiesUploadDocumentId">N/A</span>
            </td>
        </ng-container>
        <ng-container matColumnDef="companyResults">
            <th mat-header-cell *matHeaderCellDef> Company Results </th>
            <td mat-cell *matCellDef="let element">
                <a *ngIf="element.companiesResultsDocumentId" (click)="downloadFile(element.companiesResultsDocumentId)">
                    {{element.companiesResultsDocumentName}}
                </a>
                <span *ngIf="!element.companiesResultsDocumentId">N/A</span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
</div>
<a class="report-download-link" #downloadReportLink></a>
