import { Component, OnInit, Input } from '@angular/core';
import { WorkflowService, ContractTemplate, ContractTemplateVisibility } from 'projects/services/src/public-api';
import { Router } from '@angular/router';

@Component({
    selector: 'pc-sc-share-template',
    templateUrl: './share-template.component.html',
    styleUrls: ['./share-template.component.scss']
})
export class ShareTemplateComponent implements OnInit {

    ContractTemplateVisibility = ContractTemplateVisibility;

    @Input() contractTemplate: ContractTemplate;

    modified = false;
    invitees: string[] = [];
    updatedInvitees: string[] = [];

    constructor(private router: Router,
                private workflowService: WorkflowService) {
    }

    ngOnInit() {
        this.shareTemplate = this.shareTemplate.bind(this);
        this.contractTemplate.templateAccess.forEach((templateAccess) => {
            this.invitees.push(templateAccess.memberId);
        });
    }

    shareTemplate(inviteAll: boolean) {
        this.contractTemplate.templateAccess = [];
        if (inviteAll) {
            this.contractTemplate.templateAccess.push({
                id: null,
                memberId: 'All',
                memberName: null
            });
        } else {
            this.updatedInvitees.forEach((access) => {
                this.contractTemplate.templateAccess.push({
                    id: null,
                    memberId: access,
                    memberName: null
                });
            });
        }
        this.workflowService.shareTemplate(this.contractTemplate).subscribe(() => {
            this.router.navigate(['/smart_contracts/my_templates']);
        });
    }

    updateInvitees(invitees: string[]) {
        this.updatedInvitees = invitees;
        this.modified = !(this.updatedInvitees.every((element) => {
            return this.invitees.includes(element);
        }) && this.invitees.every((element) => {
            return this.updatedInvitees.includes(element);
        }));
    }
}
