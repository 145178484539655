import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Transaction } from '../models/transaction';

@Injectable({
    providedIn: 'root'
})
export class RefundTransactionFormService {

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    initializeForm(transaction: Transaction, reasonMaxLength?: number): UntypedFormGroup {
        const form = this.formBuilder.group({
            reasonCtrl: new UntypedFormControl('', [Validators.required, Validators.maxLength(reasonMaxLength ? reasonMaxLength : 256)]),
            amountCtrl: new UntypedFormControl(0, [Validators.required, Validators.min(1)])
        });
        if (transaction) {
            form.get('amountCtrl').setValidators([Validators.required, Validators.min(1), Validators.max(transaction.totalAmount)]);
            form.patchValue({
                amountCtrl: transaction.totalAmount
            });
        }
        return form;
    }
}
