import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'pc-form-text-area',
    templateUrl: './form-text-area.component.html'
})
export class FormTextAreaComponent {

    @Input() required: boolean;
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() placeholder = '';
    @Input() rows = 4;
    @Input() id: string;

}
