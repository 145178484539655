<div>
    <form [formGroup]="shippingForm">
        <div>
            <div class="modal-header">
                <label class="form-title">Edit Shipping Amount</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content">
                <div>
                    <pc-form-amount-input [label]="'Shipping Total'" [formCtrl]="'shippingCtrl'"
                        [formGroup]="shippingForm" [dollar]="true">
                    </pc-form-amount-input>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="isShippingFormValid" [submit]="onSubmit" [text]="'Update Shipping Cost'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
