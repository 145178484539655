import { Component } from '@angular/core';
import { BaseBadgeComponent } from '../badge.component';
import { SearchMatchStatus } from 'projects/services/src/public-api';

/**
 * Display badge for search match status.
 *
 * @type {SearchMatchStatus} - enum
 * @author David Teles
 */
@Component({
    selector: 'pc-search-match-status-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss', 'search-badge.component.scss']
})
export class SearchMatchStatusBadgeComponent extends BaseBadgeComponent<SearchMatchStatus> {

    setBadgeData() {
        const text = this.parent.replace('_', ' ');
        const type: SearchMatchStatus = SearchMatchStatus[this.parent.toUpperCase()];

        this.badgeToolTip = text;
        this.badgeText = text;

        switch (type) {
            case SearchMatchStatus.NO_MATCH:
            case SearchMatchStatus.FALSE_POSITIVE:
                this.badgeClass = 'green-badge';
                break;
            case SearchMatchStatus.TRUE_POSITIVE_REJECT:
            case SearchMatchStatus.POTENTIAL_MATCH:
                this.badgeClass = 'orange-badge';
                break;
            case SearchMatchStatus.TRUE_POSITIVE:
            case SearchMatchStatus.TRUE_POSITIVE_APPROVE:
                this.badgeClass = 'red-badge';
                break;
            default:
                this.badgeClass = 'grey-badge';
                break;
        }
    }
}
