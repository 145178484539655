import { Component, Input } from '@angular/core';
import { Package } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-member-metrc-package-list',
    templateUrl: './member-metrc-package-list.component.html',
    styleUrls: ['./member-metrc-package-list.component.scss']
})
export class MemberMetrcPackageListComponent {

    @Input() packages: Package[] = [];

    showPackageSummary: boolean[] = [];

}
