import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../validators/custom.validators';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationFormService {

    constructor(private formbuilder: UntypedFormBuilder) {}

    registerNewMemberForm(): UntypedFormGroup {
        return this.formbuilder.group({
            userIdCtrl: new UntypedFormControl(),
            memberNameCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.memberName, Validators.minLength(3), Validators.maxLength(64)]),
            firstNameCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.personName, Validators.maxLength(32)]),
            middleNameCtrl: new UntypedFormControl('', [CustomValidators.middleName, Validators.maxLength(32)]),
            lastNameCtrl: new UntypedFormControl('', [Validators.required,  CustomValidators.personName, Validators.maxLength(32)]),
            titleCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(64), CustomValidators.noLeadingOrTrailingWhitespace]),
            emailCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.email, Validators.maxLength(64)]),
            officePhoneCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.phone]),
            mobilePhoneCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.phone]),
            roleCtrl: new UntypedFormControl(''),
            jurisdictionCtrl: new UntypedFormControl('', [Validators.required]),
            memberAccountTypeCtrl: new UntypedFormControl(''),
            referralCtrl: new UntypedFormControl('', [Validators.minLength(8), Validators.maxLength(8)]),
            mfaCtrl: new UntypedFormControl(''),
            passwordCtrl: new UntypedFormControl('', [Validators.maxLength(128)]),
            memberRegistrationTypeCtrl: new UntypedFormControl('', [Validators.required]),
            partnerReferenceIdCtrl: new UntypedFormControl(''),
            refereeMemberIdCtrl: new UntypedFormControl('')
        });
    }

    registerNewConsumerForm(): UntypedFormGroup {
        return this.formbuilder.group(
            {
                firstNameCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.personName, Validators.maxLength(32)]),
                middleNameCtrl: new UntypedFormControl('', [CustomValidators.middleName, Validators.maxLength(32)]),
                lastNameCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.personName, Validators.maxLength(32)]),
                emailCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.email, Validators.maxLength(64)]),
                mobilePhoneCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.phone]),
                roleCtrl: new UntypedFormControl(''),
                jurisdictionCtrl: new UntypedFormControl('', [Validators.required]),
                mfaCtrl: new UntypedFormControl(''),
                passwordCtrl: new UntypedFormControl('', [Validators.maxLength(128)])
            });
    }

    loginUserForm(): UntypedFormGroup {
        return this.formbuilder.group(
            {
                emailCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.email, Validators.maxLength(64)]),
                verificationCodeCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6), CustomValidators.onlyNumeric]),
                totpCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6), CustomValidators.onlyNumeric]),
                sessionCtrl: new UntypedFormControl('', [Validators.required]),
                passwordCtrl: new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]),
                memberIdCtrl: new UntypedFormControl('', [Validators.required]),
                accountAccessCtrl: new UntypedFormControl('', [Validators.required])
            });
    }

    switchOrgForm(): UntypedFormGroup {
        return this.formbuilder.group(
            {
                memberIdCtrl: new UntypedFormControl('', [Validators.required])
            });
    }

    getAuthRequest(loginUserForm: UntypedFormGroup): any {
        return {
            username: loginUserForm.get('emailCtrl').value,
            password: loginUserForm.get('passwordCtrl').value,
            verificationCode: loginUserForm.get('verificationCodeCtrl').value,
            session: loginUserForm.get('sessionCtrl').value,
            totpCode: loginUserForm.get('totpCtrl').value
        };
    }

    getRegisterMember(registerForm: UntypedFormGroup): any {
        const props: any = {};
        props.userId = registerForm.get('userIdCtrl').value;
        props.memberName = registerForm.get('memberNameCtrl').value ? registerForm.get('memberNameCtrl').value.trim() : null;
        props.firstName = registerForm.get('firstNameCtrl').value ? registerForm.get('firstNameCtrl').value.trim() : null;
        props.middleName = registerForm.get('middleNameCtrl').value ? registerForm.get('middleNameCtrl').value.trim() : null;
        props.lastName = registerForm.get('lastNameCtrl').value ? registerForm.get('lastNameCtrl').value.trim() : null;
        props.jobTitle = registerForm.get('titleCtrl').value;
        props.email = registerForm.get('emailCtrl').value;
        props.officePhone = registerForm.get('officePhoneCtrl').value;
        props.mobilePhone = registerForm.get('mobilePhoneCtrl').value;
        props.mfaType = registerForm.get('mfaCtrl').value;
        props.referralCode = registerForm.get('referralCtrl').value;
        props.password = registerForm.get('passwordCtrl').value;
        const jurisdiction = registerForm.get('jurisdictionCtrl').value || null;
        if (jurisdiction) {
            props.jurisdiction = jurisdiction.id;
        }
        props.memberRegistrationType = registerForm.get('memberRegistrationTypeCtrl').value || null;
        props.partnerReferenceId = registerForm.get('partnerReferenceIdCtrl').value || null;
        props.refereeMemberId = registerForm.get('refereeMemberIdCtrl').value || null;
        return props;
    }

    getRegisterConsumer(registerForm: UntypedFormGroup): any {
        const props: any = {};
        props.firstName = registerForm.get('firstNameCtrl').value ? registerForm.get('firstNameCtrl').value.trim() : null;
        props.middleName = registerForm.get('middleNameCtrl').value ? registerForm.get('middleNameCtrl').value.trim() : null;
        props.lastName = registerForm.get('lastNameCtrl').value ? registerForm.get('lastNameCtrl').value.trim() : null;
        props.email = registerForm.get('emailCtrl').value;
        props.mobilePhone = registerForm.get('mobilePhoneCtrl').value;
        props.jurisdiction = registerForm.get('jurisdictionCtrl').value || null;
        props.mfaType = registerForm.get('mfaCtrl').value;
        props.password = registerForm.get('passwordCtrl').value;
        return props;
    }
}
