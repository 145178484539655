import { Component, Inject, OnInit } from '@angular/core';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { AuthService, LendingKPI, LenderService, Scenario, ScenarioFormService, ScenarioStatus } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-add-scenario-modal',
    templateUrl: './add-scenario-modal.component.html',
    styleUrls: ['./add-scenario-modal.component.scss']
})
export class AddScenarioModalComponent extends BaseModalComponent<AddScenarioModalComponent> implements OnInit {

    scenarioForm: UntypedFormGroup;
    kpiOptions = [
        'Current ratio',
        'Net Cash Ratio',
        'Net Profit Margin'
    ];
    edit: false;
    scenarioId: string;

    constructor(public scenarioFormService: ScenarioFormService,
                private lendingService: LenderService,
                private authService: AuthService,
                dialogRef: MatDialogRef<AddScenarioModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        if (data.edit) {
            this.scenarioId = data.scenarioId;
            this.edit = data.edit;
        }
    }

    ngOnInit() {
        if (this.edit && this.scenarioId) {
            this.loadScenario();
        } else {
            this.scenarioForm = this.scenarioFormService.initializeForm(null);
        }
    }

    loadScenario() {
        this.lendingService.loadScenarioById(this.scenarioId).subscribe((scenario: Scenario) => {
            this.scenarioForm = this.scenarioFormService.initializeForm(scenario);
        });
    }

    onSave() {
        if (this.scenarioForm.valid) {
            const kpis: LendingKPI[] = [];
            for (let i = 0; i < this.kpiArr.length; i++) {
                const kpi: LendingKPI = {
                    name: this.kpiArr.get(i.toString()).get('nameCtrl').value,
                    kpiCondition: this.kpiArr.get(i.toString()).get('conditionCtrl').value,
                    amount: this.kpiArr.get(i.toString()).get('amountCtrl').value
                };
                kpi.id = this.kpiArr.get(i.toString()).get('idCtrl').value ? this.kpiArr.get(i.toString()).get('idCtrl').value : null;
                kpis.push(kpi);
            }
            if (this.edit) {
                const updates = {
                    title: this.scenarioForm.get('titleCtrl').value,
                    active: this.scenarioForm.get('activeCtrl').value ? this.scenarioForm.get('activeCtrl').value : true,
                    kpis
                };
                this.lendingService.updateScenario(this.scenarioId, updates).subscribe(() => {
                    super.close(true);
                });
            } else {
                const profile = this.authService.getProfile();
                const scenario: Scenario = {
                    memberId: profile.memberId,
                    title: this.scenarioForm.get('titleCtrl').value,
                    active: this.scenarioForm.get('activeCtrl').value ? this.scenarioForm.get('activeCtrl').value : true,
                    status: ScenarioStatus.ALL,
                    id: this.scenarioId,
                    kpis
                };
                this.lendingService.createScenario(scenario).subscribe(() => {
                    super.close(true);
                });
            }
        }
    }

    get kpiArr() {
        return this.scenarioForm.get('kpis') as UntypedFormArray;
    }

    addKpi() {
        this.kpiArr.push(this.scenarioFormService.createEmptyKpi());
    }

    onDeleteFromArray(index: number) {
        this.kpiArr.removeAt(index);
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }
}
