import { Component, Input } from '@angular/core';
import { RfiStatus } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-rfi',
    templateUrl: './member-rfi.component.html'
})
export class MemberRfiComponent {

    @Input() memberId: string;
    @Input() changeTrigger: number;

    rfiStatus: RfiStatus = RfiStatus.ALL;

}
