import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableUtils } from 'projects/components/src/lib/table-utils.service';
import { AuthService, ReferencedEntityType } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-authority-audit',
    templateUrl: './authority-audit.component.html'
})
export class AuthorityAuditComponent implements OnInit {

    memberId = '';
    action = '';
    auditEntityType = ReferencedEntityType.ALL;
    formGroup: UntypedFormGroup;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private formBuilder: UntypedFormBuilder,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.formGroup =  this.formBuilder.group({
            memberCtrl : new UntypedFormControl()
        });

        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['entityType'] || queryParams['action'] || queryParams['memberId']) {
            this.auditEntityType = queryParams['entityType'];
            this.action = queryParams['action'];
            this.memberId = queryParams['memberId'];
        } else {
            this.auditEntityType = ReferencedEntityType.ALL;
            this.action = '';
            this.memberId = '';
            this.updateQueryParams(true);
        }

        this.route.queryParams.subscribe((params: any) => {
            this.auditEntityType = params['entityType'] || '';
            this.memberId = params['memberId'] || '';
            this.action = params['action'] || '';
            this.formGroup.controls['memberCtrl'].setValue(this.memberId);
        });
    }

    isAuthority() {
        return this.authService.isAuthorityOrReviewer();
    }

    activeListChanged(activeListChanged: { memberId: string, action: string, auditEntityType: ReferencedEntityType }) {
        this.auditEntityType = activeListChanged.auditEntityType;
        this.action = activeListChanged.action;
        this.memberId = activeListChanged.memberId;
        this.updateQueryParams(false);
    }

    updateQueryParams(replace: boolean) {
        const queryParams = { entityType: this.auditEntityType, memberId: this.memberId, action: this.action, page: 1 };
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }
}
