import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-cancel-transaction-modal',
    templateUrl: './cancel-transaction-modal.component.html'
})
export class CancelTransactionModalComponent extends BaseModalComponent<CancelTransactionModalComponent> implements OnInit{

    transactionCancellationForm: UntypedFormGroup;

    constructor(dialogRef: MatDialogRef<CancelTransactionModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
    }

    ngOnInit() {
        this.transactionCancellationForm = new UntypedFormGroup({
            reasonCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.noLeadingOrTrailingWhitespace, Validators.minLength(2), Validators.maxLength(256)])
        });
    }

    submit() {
        super.close(this.transactionCancellationForm.get('reasonCtrl').value);
    }

    decline() {
        super.close();
    }

    close() {
        super.close();
    }
}
