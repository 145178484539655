import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService, Profile } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-retailer-header',
    templateUrl: './retailer-header.component.html'
})
export class RetailerHeaderComponent implements OnInit {

    @Input() expandedMenu: boolean;

    @Output() toggleExpandSideMenu: EventEmitter<any> = new EventEmitter<any>();

    profile: Profile;

    constructor(private authService: AuthService) {
    }

    ngOnInit() {
        this.profile = this.authService.getProfile();
    }

    getMenuClass() {
        if (this.expandedMenu) {
            return 'top-header-icon-wide';
        } else {
            return 'top-header-icon-narrow';
        }
    }

    getBodyClass() {
        if (this.expandedMenu) {
            return 'top-header-body-narrow';
        } else {
            return 'top-header-body-wide';
        }
    }

    onToggleExpandSideMenu() {
        this.toggleExpandSideMenu.emit('toggle');
    }

    onLoggedOut() {
        this.authService.logout();
    }

}
