<div class="dashboard-list">
    <div class="row">
        <div class="col-12">
            <div class="dashboard-alert-icon">
                <img class="dashboard-icon" src="assets/images/compliance-icon.svg" alt="">
                <a [routerLink]="[link]" [routerLinkActive]="['router-link-active']">{{title}} </a>
                <span class="dashboard-alert-meta" *ngIf="titleMeta"> {{titleMeta}}</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="dashboard-alert-h2" [class]="columnClass">
            <div class="centered">
                <span *ngIf="typeMeta">{{typeMeta}}</span>&nbsp;{{valueOne}}<img class="alignTop" *ngIf="iconSrc" [src]="iconSrc" alt="">
            </div>
        </div>
        <div class="dashboard-alert-h2" [class]="columnClass" *ngIf="valueTwoLabel">
            <div class="centered">
                <span *ngIf="valueTwo">{{typeMeta}}</span><span>&nbsp;{{valueTwo}}</span>
            </div>
        </div>
        <div class="dashboard-alert-h2" [class]="columnClass"  *ngIf="valueThreeLabel">
            <div class="centered">
                <span *ngIf="valueThree">{{typeMeta}}</span><span>&nbsp;{{valueThree}}</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div [class]="columnClass">
            <div class="centered">
                <label>{{valueOneLabel}}</label>
            </div>
        </div>
        <div [class]="columnClass" *ngIf="valueTwoLabel">
            <div class="centered">
                <label *ngIf="valueTwoLabel">{{valueTwoLabel}}</label>
            </div>
        </div>
        <div [class]="columnClass" *ngIf="valueThreeLabel">
            <div class="centered">
                <label>{{valueThreeLabel}}</label>
            </div>
        </div>
    </div>
</div>
