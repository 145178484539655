<div class="row">
    <h6 class="gray mb-3">Year to Date</h6>
    <div *ngFor="let accountYearlySummary of accountSummary; let i = index" class="col-sm-12 col-md-6 col-xl-3">
       <pt-account-summary *ngIf="i < 4" [accountSummary]="accountYearlySummary"></pt-account-summary>
    </div>
</div><br>
<div class="row">
    <h6 class="gray mb-3">Last 30 Days</h6>
    <div *ngFor="let accountMonthlySummary of accountSummary; let i = index" class="col-sm-12 col-md-6 col-xl-3">
        <pt-account-summary *ngIf="i >= 4" [accountSummary]="accountMonthlySummary"></pt-account-summary>
    </div>
</div>
