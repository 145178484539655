<div>
    <form [formGroup]="fincenTrackingReportForm">
        <div>
            <div class="modal-header">
                <label class="form-title">{{ isReporter ? 'Update Notes' : 'Update Tracking Details'}}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-medium">
                <div *ngIf="authService.isAuthority()">
                    <pc-text-option-selector
                        [label]="'Status'"
                        [options]="reportStatuses"
                        [formCtrl]="'statusCtrl'"
                        [defaultValue]="report.status"
                        [formGroup]="fincenTrackingReportForm"
                        [translated]="true">
                    </pc-text-option-selector>
                    <pc-text-input
                        [label]="'BSA Tracking ID'"
                        [formCtrl]="'fincenTrackingCtrl'"
                        [formGroup]="fincenTrackingReportForm">
                    </pc-text-input>
                    <pc-text-input [label]="'BSA ID'"
                        [formCtrl]="'bsaIdCtrl'"
                        [formGroup]="fincenTrackingReportForm">
                    </pc-text-input>
                    <pc-form-date-picker
                        [label]="'Acknowledged Date'"
                        [historical]="true"
                        [defaultEmpty]="!report.acknowledgedDate"
                        [formCtrl]="'acknowledgedDateCtrl'"
                        [formGroup]="fincenTrackingReportForm">
                    </pc-form-date-picker>
                    <pc-form-date-picker
                        [label]="'Submission Date'"
                        [historical]="true"
                        [defaultEmpty]="!report.submissionDate"
                        [formCtrl]="'submissionDateCtrl'"
                        [formGroup]="fincenTrackingReportForm">
                    </pc-form-date-picker>
                </div>
                <pc-form-text-area *ngIf="formNameCtrl"
                    [label]="'Notes'"
                    [formCtrl]="formNameCtrl"
                    [formGroup]="fincenTrackingReportForm"
                    [rows]="15">
                </pc-form-text-area>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="isFincenTrackingReportFormValid" [submit]="onSubmit" [text]="'Update'">
                </pc-submit-button>
            </div>
        </div>
    </form>
</div>
