import { SupportedTransactionType } from './transaction';

export class WhitelistRelationshipParty {

    id: string;
    memberId: string;
    memberName: string;
    whitelistedMemberId: string;
    whitelistedMemberName: string;
    note: string;
    maximumTransactionAmount: number;
    maximumMonthlyNumberOfTransactions: number;
    whitelistedTransactionType: SupportedTransactionType;

}
