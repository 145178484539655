import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { IMyDateModel } from 'angular-mydatepicker';
import { LenderService, ProfitAndLossReport } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-profit',
    templateUrl: './member-profit.component.html'
})
export class MemberProfitComponent implements OnInit {

    @Input() memberAccountId: string;

    formGroup: UntypedFormGroup;
    profitReports: ProfitAndLossReport[] = [];
    isIncomeShow = false;
    isCostOfSalesShow = false;
    isExpensesShow = false;
    isOtherExpensesShow = false;
    startMonth: string;
    periodLength = 12;
    periodsToCompare = 2;

    constructor(private lenderService: LenderService,
                private formBuilder: UntypedFormBuilder,
                private datePipe: DatePipe) {}

    ngOnInit() {
        const currentDate = new Date();
        this.startMonth = this.datePipe.transform(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1), 'yyyy-MM-dd');

        this.formGroup =  this.formBuilder.group({
            dateCtrl : new UntypedFormControl()
        });

        this.getCodatData();
    }

    getCodatData() {
        this.lenderService.getProfitAndLoss(this.memberAccountId, this.startMonth, this.periodLength, this.periodsToCompare)
            .subscribe((data: any) => {
                this.profitReports = data.reports;
            });
    }

    onDateChanged(event: IMyDateModel) {
        if (event) {
            this.startMonth = this.datePipe.transform(new Date(event.singleDate.jsDate.getFullYear(), event.singleDate.jsDate.getMonth(), 1), 'yyyy-MM-dd');
            this.getCodatData();
        } else {
            this.startMonth = '';
        }
    }

    changedPeriods() {
        this.getCodatData();
    }

    expandIncome() {
        this.isIncomeShow = !this.isIncomeShow;
    }

    expandCostOfSales() {
        this.isCostOfSalesShow = !this.isCostOfSalesShow;
    }

    expandExpenses() {
        this.isExpensesShow = !this.isExpensesShow;
    }

    expandOtherExpenses() {
        this.isOtherExpensesShow = !this.isOtherExpensesShow;
    }
}
