import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
    AuthService, BankAccount, BankAccountService, MemberAccount, PagedResponse, TransactionFormService, TransactionSource, TransactionType, WalletService, WorkflowService
} from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent, ErrorType } from 'projects/components/src/public-api';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'pt-withdraw-funds-modal',
    templateUrl: './withdraw-funds-modal.component.html',
    styleUrls: ['./withdraw-funds-modal.component.scss']
})
export class WithdrawFundsModalComponent extends BaseModalComponent<WithdrawFundsModalComponent> implements OnInit {

    memberAccount: MemberAccount;
    withdrawalForm: UntypedFormGroup;
    bankAccounts: BankAccount[] = [];
    totalAmount = 0;
    submissionReference = uuidv4();
    errorMessage: { message: string, type: ErrorType };

    constructor(private authService: AuthService,
                private workflowService: WorkflowService,
                private transactionFormService: TransactionFormService,
                private bankAccountService: BankAccountService,
                private walletService: WalletService,
                private notifier: NotificationService,
                dialogRef: MatDialogRef<WithdrawFundsModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberAccount = data.memberAccount;
    }

    ngOnInit() {
        this.isWithdrawalFormValid = this.isWithdrawalFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.withdrawalForm = this.transactionFormService.initializeDepositWithdrawFundsForm();
        this.withdrawalForm.get('transactionDateCtrl').disable();
        this.updateAccounts(false);
    }

    updateAccounts(reset: boolean) {
        this.bankAccountService.getAllActiveBankAccounts(this.authService.getProfile().memberId).subscribe((bankAccounts: PagedResponse<BankAccount>) => {
            if (reset) {
                this.withdrawalForm.controls['bankAccountIdCtrl'].setValue(null);
            }
            this.bankAccounts = bankAccounts.content;
        });
    }

    onCalculateTotal() {
        this.totalAmount = this.withdrawalForm.controls['amountCtrl'].value;
        if (this.totalAmount > this.memberAccount.wallet.availableBalance) {
            this.errorMessage = {
                message: 'Insufficient Funds in Confia Account',
                type: ErrorType.ERROR
            };
        } else {
            this.errorMessage = null;
        }
    }

    onSubmit(reset: any) {
        // save the transaction
        const transaction = this.transactionFormService.getWithdrawFundsTransaction(this.withdrawalForm);
        transaction.transactionType = TransactionType.ACH_WITHDRAWAL;
        transaction.source = TransactionSource.SYSTEM_TRANSACTION;
        transaction.submissionReference = this.submissionReference;
        transaction.payorAccountId = this.memberAccount.id;

        this.workflowService.withdrawFromWallet(transaction).subscribe(() => {
            this.close(true);
            this.notifier.showSuccess(`Your request to withdraw <b>$${transaction.totalAmount}</b> is being processed.`);
        }, (error: any) => {
            if (error.status === 422) {
                this.updateAccounts(true);
            }
            reset();
            throw error;
        });

    }

    isWithdrawalFormValid() {
        return !this.withdrawalForm.invalid && (this.totalAmount > 0) && (this.totalAmount <= this.memberAccount.wallet.availableBalance);
    }

    close(refresh?: boolean) {
        this.totalAmount = 0;
        super.close(refresh);
    }
}
