import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import {
    Configuration, MerchantAccount, MerchantAccountService, MerchantAccountSource, MemberAccountStatus, SupportedTransactionType, PagedResponse, MemberType, CorporateEntity
} from 'projects/services/src/public-api';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'pc-merchant-selector',
    templateUrl: './merchant-selector.component.html',
    styleUrls: ['./merchant-selector.component.scss']
})

export class MerchantSelectorComponent implements OnInit, OnChanges, AfterViewInit {

    MemberType = MemberType;

    @Input() placeholder = '';
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() includeAddress = false;
    @Input() types = [SupportedTransactionType.OUTGOING, SupportedTransactionType.BOTH];
    @Input() status: MemberAccountStatus = MemberAccountStatus.ACTIVE;
    @Input() sources: MerchantAccountSource[] = [MerchantAccountSource.CONFIA, MerchantAccountSource.ACCOUNTING];
    @Input() memberId = '';
    @Input() includeType = false;
    @Input() changeTrigger: number;

    @Output() merchantSelected = new EventEmitter<MerchantAccount>();

    merchantSelect: Subscription;
    merchantAutoComplete: Observable<MerchantAccount[]> = null;
    autoCompleteControl = new UntypedFormControl('');
    merchantOptions: MerchantAccount[] = [];
    searching = false;
    searchLimit: number;
    totalResults: number;

    @ViewChild('merchantSelect', { read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;

    constructor(private merchantAccountService: MerchantAccountService) {}

    ngOnInit() {
        this.searchLimit = Configuration.getConfig().autoCompleteSearchLimit;
        this.formGroup.addControl('autoCompleteCtrl', this.autoCompleteControl);
        this.merchantAutoComplete = this.autoCompleteControl.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            debounceTime(1000),
            switchMap((value) => {
                this.searching = true;
                return this.lookup(value || '');
            })
        );
    }

    ngAfterViewInit() {
        this.subscribeToClosingActions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.types && !changes.types.firstChange ||
            (changes.status && !changes.status.firstChange) ||
            (changes.sources && !changes.sources.firstChange) ||
            (changes.changeTrigger && !changes.changeTrigger.firstChange)) {
            this.merchantOptions = [];
            this.formGroup.controls[this.formCtrl].setValue('');
            this.autoCompleteControl.setValue('');
            this.autoCompleteControl.markAsPristine();
            this.formGroup.controls[this.formCtrl].markAsPristine();
        }
    }

    private subscribeToClosingActions() {
        if (this.merchantSelect && !this.merchantSelect.closed) {
            this.merchantSelect.unsubscribe();
        }

        this.merchantSelect = this.trigger.panelClosingActions.subscribe((e) => {
            if (!e || !e.source) {
                if (this.merchantOptions.length === 1) {
                    this.merchantChanged(this.merchantOptions[0]);
                } else {
                    const selected = this.merchantOptions
                        .map((option) => {
                            return option.merchantMemberName;
                        })
                        .find((option) => {
                            return option === this.autoCompleteControl.value;
                        });

                    if (!selected && this.selected()) {
                        this.formGroup.controls[this.formCtrl].markAsPristine();
                        this.formGroup.controls[this.formCtrl].setValue('');
                        this.autoCompleteControl.setValue('');
                        this.merchantChanged(new MerchantAccount());
                    }
                }
            }
        }, () => {
            return this.subscribeToClosingActions();
        }, () => {
            return this.subscribeToClosingActions();
        });
    }

    lookup(value: string) : Observable<MerchantAccount[]> {
        this.totalResults = 0;
        if (value?.length <= 1) {
            this.searching = false;
            return of([]);
        }
        return this.merchantAccountService.findMerchantAccounts(value, this.memberId, this.types, this.status, this.sources, null, this.searchLimit).pipe(
            switchMap(
                (response: PagedResponse<MerchantAccount>) => {
                    this.totalResults = response.totalElements;
                    this.merchantOptions = response.content;
                    this.merchantOptions.forEach((option) => {
                        const memberMerchant = option.merchantMemberAccount.member;
                        option.merchantMemberName = memberMerchant.name;
                        if (memberMerchant.memberType === MemberType.BUSINESS_MERCHANT && (memberMerchant as CorporateEntity).dbaName && memberMerchant.name !== (memberMerchant as CorporateEntity).dbaName) {
                            option.merchantMemberName = memberMerchant.name + ' (DBA: ' + (memberMerchant as CorporateEntity).dbaName + ')';
                        }
                    });
                    this.searching = false;
                    return of(this.merchantOptions);
                }
            ), catchError((_error) => {
                this.searching = false;
                return [];
            })
        );
    }

    merchantChanged(merchant: MerchantAccount) {
        this.formGroup.controls[this.formCtrl].markAsDirty();
        this.formGroup.controls[this.formCtrl].setValue(merchant.id);
        if (merchant.merchantMemberAccount?.member) {
            const merchantMember = merchant.merchantMemberAccount?.member;
            if (merchantMember.memberType === MemberType.BUSINESS_MERCHANT && (merchantMember as CorporateEntity).dbaName) {
                this.autoCompleteControl.setValue(merchantMember.name + ' (DBA: ' + (merchantMember as CorporateEntity).dbaName + ')');
            } else {
                this.autoCompleteControl.setValue(merchantMember.name);
            }
        }
        this.merchantSelected.emit(merchant);
    }

    onInputChanged(event: any) {
        if (event.keyCode === 220) {
            event.preventDefault();
            return;
        }
    }

    selected() {
        return this.formGroup.controls[this.formCtrl].value;
    }

    onIconClick(event: any) {
        if (this.selected()) {
            this.formGroup.controls[this.formCtrl].setValue('');
            this.autoCompleteControl.setValue('');
            this.merchantSelected.emit(new MerchantAccount());
        }
        event.stopPropagation();
        this.trigger.openPanel();
    }
}
