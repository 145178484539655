import { Component, Input } from '@angular/core';

@Component({
    selector: 'pc-sc-contract-status',
    templateUrl: './contract-status.component.html',
    styleUrls: ['./contract-status.component.scss']
})
export class ContractStatusComponent {

    @Input() status: string;

    getStatusClass() {
        if (this.status === 'COMPLETED') {
            return 'completed-status';
        } else if (this.status === 'CANCELLED') {
            return 'cancelled-status';
        } else if (this.status === 'EXECUTING') {
            return 'executing-status';
        } else if (this.status === 'SELLER_REVIEW') {
            return 'seller-review-status';
        } else if (this.status === 'BUYER_REVIEW') {
            return 'buyer-review-status';
        }
    }
}
