<form *ngIf="userForm && addressForm" [formGroup]="userForm">
    <div class="row mb-3 col-12">
        <span class="form-title">Administrator</span>
    </div>
    <div class="row">
        <div class="col-6 left-col mb-3">
            <span class="subtitle">User Information</span>
            <pc-text-input [required]="true" [label]="'First Name'" [placeholder]="'First Name'" [formCtrl]="'firstNameCtrl'" [formGroup]="userForm">
            </pc-text-input>
            <pc-text-input [required]="false" [label]="'Middle Name'" [placeholder]="'Middle Name'" [formCtrl]="'middleNameCtrl'" [formGroup]="userForm">
            </pc-text-input>
            <pc-text-input [required]="true" [label]="'Last Name'" [placeholder]="'Last Name'" [formCtrl]="'lastNameCtrl'" [formGroup]="userForm">
            </pc-text-input>
            <pc-phone-input [required]="true" [label]="'Mobile Number'" [formCtrl]="'mobilePhoneCtrl'" [formGroup]="userForm">
            </pc-phone-input>
            <pc-email-input [required]="true" [label]="'Email Address'" [formCtrl]="'emailCtrl'" [formGroup]="userForm">
            </pc-email-input>
            <pc-dob-input [formGroup]="userForm" [formCtrl]="'birthDateCtrl'" [required]="true">
            </pc-dob-input>
        </div>
        <div class="col-6 mb-3">
            <span class="subtitle">Background Information</span>
            <p>You must be at least one of the following to apply on behalf of the business. Select all that apply:</p>
            <pc-simple-checkbox [class]="isBackgroundInfoChecked() ? '' : 'required'" [label]="'I\'m an Owner of the Business'" [formCtrl]="'ownerCtrl'" [formGroup]="keyPartyForm" [invert]="true" (changed)="onOwnershipChange()">
            </pc-simple-checkbox>
            <pc-percentage-input *ngIf="isOwner" [required]="true" [label]="'Ownership %'" [formCtrl]="'percentOwnershipCtrl'" [formGroup]="keyPartyForm">
            </pc-percentage-input>
            <pc-simple-checkbox [class]="isBackgroundInfoChecked() ? '' : 'required'" [label]="'I\'m a Director of the Business'" [formCtrl]="'isDirectorCtrl'" [formGroup]="keyPartyForm" [invert]="true" (changed)="updateValidity()">
            </pc-simple-checkbox>
            <pc-simple-checkbox [class]="isBackgroundInfoChecked() ? '' : 'required'" [label]="'I\'m an Officer and/or Control Person of the Business'" [formCtrl]="'isOfficerCtrl'" [formGroup]="keyPartyForm" [invert]="true" (changed)="updateValidity()">
            </pc-simple-checkbox>
            <pc-simple-checkbox [label]="'US Citizen'" [formCtrl]="'usCitizenCtrl'" [formGroup]="userForm" [invert]="true" (changed)="onCitizenshipChange()">
            </pc-simple-checkbox>
            <pc-form-text-input *ngIf="isUsCitizen" [required]="true" [label]="'Social Security Number'"
                [formCtrl]="'taxIdCtrl'" [formGroup]="userForm" [placeholder]="'numbers only (no hyphens)'" [restrictToDigits]="true">
            </pc-form-text-input>
            <pc-text-input *ngIf="!isUsCitizen" [required]="false" [label]="'Citizenship'" [placeholder]="'Citizenship'"
                [formCtrl]="'citizenshipCtrl'" [formGroup]="userForm">
            </pc-text-input>
            <pc-text-input *ngIf="!isUsCitizen" [required]="false" [label]="'Passport Number'" [placeholder]="'Passport Number'"
                [formCtrl]="'passportCtrl'" [formGroup]="userForm">
            </pc-text-input>
            <div class="col-6 pc-datepicker-padding">
                <pc-form-date-picker *ngIf="!isUsCitizen" [label]="'Passport Expiration Date'" [formCtrl]="'passportExpDateCtrl'" [formGroup]="userForm"
                    [defaultEmpty]="displayDefaultEmptyDate" [type]="'SINGLE'" (dateSelected)="onDateChanged($event)" [historical]="false">
                </pc-form-date-picker>
            </div>
            <span class="subtitle mt-3">Home Address (not work)</span>
            <pc-address-input [addressTypeOpts]="[AddressType.PRIMARY]" [addressForm]="addressForm"></pc-address-input>
        </div>
    </div>

    <div class="button-panel pull-right">
        <pc-submit-button [enabled]="isAdminDetailsValid" [submit]="onSave" [text]="'Save'"></pc-submit-button>
        <pc-submit-button  [submit]="onNavigate" [isSubmitButton]="true" [text]="'Next'" [rightIcon]="'arrow-right'" *ngIf="task"></pc-submit-button>
    </div>
</form>
