import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    Address, DataroomService, DocumentType, AddressService, MerchantAccountConfig, MerchantAccountService, Upload, Utils, RiskTier,
    UploadStatus, MemberService, Member, CorporateEntity, MerchantAccountStatus
} from 'projects/services/src/public-api';

@Component({
    selector: 'pt-merchant-contact-info',
    templateUrl: './merchant-contact-info.component.html',
    styleUrls: ['./merchant-contact-info.component.scss']
})
export class MerchantContactInfoComponent implements OnInit {

    CorporateEntity = CorporateEntity;
    MerchantAccountStatus = MerchantAccountStatus;
    RiskTier = RiskTier;
    Utils = Utils;

    @Input() task: string;
    @Input() taskVars: any;

    @Output() next: EventEmitter<string> = new EventEmitter<string>();

    contactAddress: Address;
    sampleDocuments: Upload[] = [];
    merchantAccountConfig: MerchantAccountConfig;
    requestedBy: Member;

    @ViewChild('downloadDocumentLink') downloadDocumentLink: ElementRef;

    constructor(private addressService: AddressService,
                private merchantAccountService: MerchantAccountService,
                private memberService: MemberService,
                private dataroomService: DataroomService) {}

    ngOnInit() {
        this.onNavigate = this.onNavigate.bind(this);
        const merchantMemberId = this.taskVars.merchantMemberId;
        this.merchantAccountService.getMerchantAccountConfigByMerchantMemberId(merchantMemberId).subscribe((merchantAccountConfig: MerchantAccountConfig) => {
            this.merchantAccountConfig = merchantAccountConfig;
        });
        this.addressService.getPrimaryAddress(merchantMemberId).subscribe((address: Address) => {
            this.contactAddress = address;
        });
        this.dataroomService.getUploads(merchantMemberId, [UploadStatus.ACTIVE], [DocumentType.SAMPLE_ACH_INFO]).subscribe((sampleDocuments: Upload[]) => {
            this.sampleDocuments = sampleDocuments.filter((doc) => {
                return doc.documentType === DocumentType.SAMPLE_ACH_INFO;
            });
        });
        if (this.taskVars.memberId) {
            this.memberService.loadMember(this.taskVars.memberId).subscribe((requestingMember: Member) => {
                this.requestedBy = requestingMember;
            });
        }
    }

    onNavigate() {
        this.next.emit();
    }

    downloadFile(document: any) {
        this.dataroomService.downloadResource(document, this.downloadDocumentLink);
    }

    getDbaName() {
        return (this.merchantAccountConfig?.merchantMemberAccount?.member as CorporateEntity)?.dbaName;
    }
}
