<div class="row col-12">
<div class="package-header title-bold-font" *ngIf="!packages || packages.length === 0"> No Packages Available </div>
<ng-container *ngFor="let package of packages; let i=index">
    <tr class="pc-click-row">
        <td>
            <em class="fa" [class.fa-plus-circle]="!showPackageSummary[i]" [class.fa-minus-circle]="showPackageSummary[i]" (click)="showPackageSummary[i] = !showPackageSummary[i]"></em>&nbsp; &nbsp;
            <span class="package-header">Package: {{package.externalId}} </span>
        </td>
    </tr>
    <tr *ngIf="showPackageSummary[i]">
        <td>
            <div class="row row-padding">
                <div class="col-2">
                    <span class="title-bold-font">Package Id</span>
                </div>
                <div class="col-4">
                    {{package.externalId}}
                </div>
                <div class="col-2">
                    <span class="title-bold-font">Package Category</span>
                </div>
                <div class="col-4">
                    {{package.productCategory}}
                </div>
                <div class="col-2">
                    <span class="title-bold-font">Package Label</span>
                </div>
                <div class="col-4">
                    {{package.packageLabel}}
                </div>
                <div class="col-2">
                    <span class="title-bold-font">Shipped Quantity</span>
                </div>
                <div class="col-4">
                    {{package.shippedQuantity}} - {{package.quantityUnits}}
                </div>
                <div class="col-2">
                    <span class="title-bold-font">Package Type</span>
                </div>
                <div class="col-4">
                    {{package.packageType}}
                </div>
                <div class="col-12">
                </div>
                <div class="col-2 package-margin">
                    <span class="title-bold-font">Product Name</span>
                </div>
                <div class="col-10 package-margin">
                    {{package.productName}}
                </div>
                <div class="col-2">
                    <span class="title-bold-font">Production Batch #</span>
                </div>
                <div class="col-10">
                    {{package.productionBatchNumber}}
                </div>
                <div class="col-2">
                    <span class="title-bold-font">Source Package</span>
                </div>
                <div class="col-10">
                    {{package.sourcePackageLabels}}
                </div>
                <div class="col-2">
                    <span class="title-bold-font">Source Harvest</span>
                </div>
                <div class="col-10">
                    {{package.sourceHarvestNames}}
                </div>
            </div>
        </td>
    </tr>
</ng-container>
</div>