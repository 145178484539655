
export class TransactionScores {
    date: string;
    scoreZero: string;
    scoreOne: string;
    scoreTwo: string;
    scoreThree: string;
    scoreFour: string;
    scoreFive: string;
}
