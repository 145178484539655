import { Component, OnInit } from '@angular/core';
import {TransactionScores, PaymentService, ChartService, TransactionState, TransactionType, TransactionSource, ChartData, ChartType} from 'projects/services/src/public-api';

@Component({
    selector: 'pc-authority-transaction-score-gadget',
    templateUrl: './authority-transaction-score-gadget.component.html'
})
export class AuthorityTransactionScoreGadgetComponent implements OnInit {

    ChartType = ChartType;

    period = 5;
    tableView = false;
    state: TransactionState = TransactionState.ALL;
    labels: string[] = [];
    chartData: ChartData[] = [];
    displayedColumns: string[] = ['date', 'score_0', 'score_1', 'score_2', 'score_3', 'score_4', 'score_5'];
    transactionTypes: TransactionType[] = [];
    transactionSources: TransactionSource[] = [];
    transactionScores: TransactionScores[] = [];
    colorScheme = { domain: ['#612863', '#EBAAEC', '#283363', '#7981A2', '#6178D0', '#A8B5E8', '#00AEB0', '#AEDCDD']};

    constructor(private paymentsService: PaymentService,
                private chartService: ChartService) { }

    ngOnInit() {
        // default checked radio button
        this.paymentsService.getTransactionsScores('', '', this.state, this.transactionTypes, this.transactionSources, this.period, '', '', '', '', '').subscribe((response: any) => {
            this.labels = Object.keys(response);
            this.chartData = this.chartService.getChartTransactionScores(response, this.labels);
            this.transactionScores = this.chartService.getTableTransactionScores(response, this.labels);
        });
    }

    viewChanged(type: string) {
        this.tableView = (type === 'table');
    }
}
