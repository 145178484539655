import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DatatableComponent, DataTableOptions } from 'projects/components/src/lib/tables/datatable/datatable.component';
import { KycExtraSearchModalComponent } from 'projects/components/src/lib/kyc/kyc-extra-search-modal/kyc-extra-search-modal.component';
import { AuthService, KeyParty, KycSearchService, KycSearchParams, OnboardSearchParamsBuilder, SearchResult } from 'projects/services/src/public-api';
import { map } from 'rxjs/operators';
import { finalize, Subject } from 'rxjs';

@Component({
    selector: 'pc-kyc-extra-search',
    templateUrl: './kyc-extra-search.component.html',
    styleUrls: ['./kyc-extra-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KycExtraSearchComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() keyParties: KeyParty[] = [];

    @ViewChild(DatatableComponent) dataTable: DatatableComponent<KeyParty>;

    searchResult: Subject<SearchResult[]> = new Subject<SearchResult[]>();

    displayedColumns: string[] = ['search_term', 'subject_entity', 'comply_advantage'];
    totalElements = 0;
    isLoadingResults = true;

    tableOptions: DataTableOptions = new DataTableOptions({
        sortColumn: 'updated',
        urlTracking: false
    });

    constructor(
        private kycService: KycSearchService,
        private authService: AuthService,
        private dialog: MatDialog) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.keyParties && !changes.keyParties.firstChange) {
            this.loadData();
        }
    }

    ngOnInit() {
        this.onNewSearchClicked = this.onNewSearchClicked.bind(this);
    }

    ngAfterViewInit() {
        this.loadData();
    }

    loadData() {
        const params: KycSearchParams = OnboardSearchParamsBuilder.builder()
            .extra(true)
            .paginated(this.dataTable.pageTracking.page - 1, this.dataTable.pageTracking.pageSize,
                this.dataTable.pageTracking.sort, this.dataTable.pageTracking.sortDir)
            .build();

        params.keyParties = this.keyParties.map((keyParty) => {
            return {
                memberId: keyParty.memberId,
                individualKeyPartyId: keyParty.individualKeyPartyId,
                corporateKeyPartyId: keyParty.corporateKeyPartyId,
                type: keyParty.type
            };
        });

        this.kycService.findSearches(params).pipe(
            map((response) => {
                this.totalElements = response.totalElements;
                return response.content;
            }),
            finalize(() => {
                this.isLoadingResults = false;
            })
        )
            .subscribe((result) => {
                this.searchResult.next(result);
            });
    }

    onNewSearchClicked() {
        const dialogConfig: MatDialogConfig = {
            panelClass: 'normal-modal',
            data: {
                keyParties: this.keyParties
            }
        };

        this.dialog.open(KycExtraSearchModalComponent, dialogConfig)
            .afterClosed()
            .subscribe((result) => {
                this.onModalClosed(result);
            });
    }

    onModalClosed(result: any) {
        switch (result?.action) {
            case 'COMPLETE': {
                this.loadData();
                break;
            }
            default:
                break;
        }
    }

    findKeyPartyBySearchResult(searchResult: SearchResult) {
        return this.keyParties.find((keyParty) => {
            const keyPartyId = keyParty.individualKeyPartyId || keyParty.corporateKeyPartyId;
            return keyPartyId === searchResult.subjectEntityId;
        });
    }

    isNewSearchButtonEnabled() {
        return !this.authService.isAuthority();
    }
}
