import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BsaReport, ReportsFormService, ReportsService, ReportStatus } from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pc-fincen-edit-narrative-modal',
    templateUrl: './fincen-edit-narrative-modal.component.html'
})
export class FincenEditNarrativeModalComponent extends BaseModalComponent<FincenEditNarrativeModalComponent> implements OnInit {

    fincenEditNarrativeForm: UntypedFormGroup;
    report: BsaReport;

    constructor(private reportsService: ReportsService,
                private reportFormService: ReportsFormService,
                dialogRef: MatDialogRef<FincenEditNarrativeModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.report = data.report;
        this.fincenEditNarrativeForm = this.reportFormService.initializeForm(this.report);
    }

    ngOnInit() {
        this.isFincenEditNarrativeFormValid = this.isFincenEditNarrativeFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    onSubmit(reset: any) {
        let body =  {
            reportId: this.report.id,
            status: this.fincenEditNarrativeForm.get('statusCtrl').value,
            narrativeText: this.fincenEditNarrativeForm.get('narrativeCtrl').value
        };
        this.reportsService.updateTrackingDetails(body).subscribe(() => {
            this.reportsService.regenerateReport(this.report.id).subscribe(() => {
                reset();
                this.close(true);
            },
            (error) => {
                reset();
                throw error;
            });
        },
        (error) => {
            reset();
            throw error;
        }
        );
    }

    isFincenEditNarrativeFormValid() {
        return !this.fincenEditNarrativeForm.invalid;
    }
}
