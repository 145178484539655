import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class SmartContractFormService {

    constructor(private formbuilder: UntypedFormBuilder) {}

    newTemplateForm(): UntypedFormGroup {
        return this.formbuilder.group(
            {
                frameworkCtrl: new UntypedFormControl('', [Validators.required]),
                nameCtrl: new UntypedFormControl('', [Validators.required]),
                templateTypeCtrl: new UntypedFormControl('', [Validators.required]),
                descriptionCtrl: new UntypedFormControl('')
            });
    }

    newContractForm(): UntypedFormGroup {
        return this.formbuilder.group(
            {
                nameCtrl: new UntypedFormControl('', [Validators.required]),
                templateCtrl: new UntypedFormControl('', [Validators.required]),
                // businessTaxCtrl: new FormControl('', [Validators.required]),
                salesTaxCtrl: new UntypedFormControl('', [Validators.required])
            });
    }

}
