import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {
    AuthService,
    AuthorizationFormService,
    Configuration,
    CustomValidators,
    RegistrationService,
    MfaType,
    Role,
    Jurisdiction,
    MemberRegistrationType
} from 'projects/services/src/public-api';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';

@Component({
    selector: 'pc-register-consumer-modal',
    templateUrl: './register-consumer-modal.component.html'
})
export class RegisterConsumerModalComponent extends BaseModalComponent<RegisterConsumerModalComponent> implements OnInit {

    jurisdictions: Jurisdiction[] = [];
    registerUserForm: UntypedFormGroup;
    errorMessage: string;
    mfaTypes = [{
        id: MfaType.SMS,
        name: 'SMS Text Message'
    }, {
        id: MfaType.TOTP,
        name: 'Time-based Token (TOTP)'
    }];
    userType: string;

    constructor(private authorizationFormService: AuthorizationFormService,
                private authService: AuthService,
                private registrationService: RegistrationService,
                dialogRef: MatDialogRef<RegisterConsumerModalComponent>) {
        super(dialogRef);
        this.jurisdictions = Configuration.getConfig().jurisdictions.filter((jurisdiction) => {
            return jurisdiction?.supportedMemberRegistrationTypes.length > 0;
        });
    }

    ngOnInit() {
        this.formValid = this.formValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.registerUserForm = this.authorizationFormService.registerNewConsumerForm();

        if (this.isMfaEnabled()) {
            this.registerUserForm.patchValue({
                mfaCtrl: MfaType.SMS
            });
            this.registerUserForm.get('mfaCtrl').setValidators([Validators.required]);
        } else {
            this.registerUserForm.patchValue({
                mfaCtrl: MfaType.NONE
            });
        }
    }

    onSubmit(reset: any) {
        const registrationRequest = this.authorizationFormService.getRegisterConsumer(this.registerUserForm);
        registrationRequest.role = Role.CONSUMER;

        this.registrationService.registerConsumer(registrationRequest).subscribe(
            () => {
                this.close();
                this.authService.logoutAndMessage(0);
            },
            (errorResult: any) => {
                reset();
                if (errorResult.status === 409 || errorResult.status === 400 || errorResult.status === 401) {
                    this.errorMessage = errorResult.error;
                } else if (errorResult.status === 429) {
                    this.close();
                    throw errorResult;
                } else {
                    throw errorResult;
                }
            });
    }

    isMfaEnabled() {
        return Configuration.getConfig().mfaEnabled;
    }

    close() {
        super.close();
    }

    formValid() {
        return !this.registerUserForm.disabled && !this.registerUserForm.invalid;
    }

    nextDisabled() {
        return !this.registerUserForm.get('emailCtrl').valid;
    }

    onEmailChange(email: string) {
        if (this.registerUserForm.get('emailCtrl').valid) {
            this.registerUserForm.disable();
            this.authService.userExists(email)
                .subscribe(
                    (response: any) => {
                        const userCount = Number(response.headers.get('Content-Length'));
                        if (userCount > 0) {
                            this.registerUserForm.get('firstNameCtrl').setValidators(null);
                            this.registerUserForm.get('lastNameCtrl').setValidators(null);
                            this.registerUserForm.get('mobilePhoneCtrl').setValidators(null);
                            this.registerUserForm.get('mfaCtrl').setValidators(null);
                            this.registerUserForm.get('passwordCtrl').setValidators([Validators.required, Validators.maxLength(128)]);
                            this.registerUserForm.patchValue({
                                firstNameCtrl: null,
                                lastNameCtrl: null,
                                mobilePhoneCtrl: null,
                                mfaCtrl: MfaType.NONE,
                                passwordCtrl: null
                            });
                            this.userType = 'EXISTING';
                        } else {
                            this.registerUserForm.get('firstNameCtrl').setValidators([Validators.required, CustomValidators.personName, Validators.maxLength(32)]);
                            this.registerUserForm.get('lastNameCtrl').setValidators([Validators.required,  CustomValidators.personName, Validators.maxLength(32)]);
                            this.registerUserForm.get('mobilePhoneCtrl').setValidators([Validators.required, CustomValidators.phone]);
                            this.registerUserForm.get('passwordCtrl').setValidators(null);
                            this.registerUserForm.patchValue({
                                firstNameCtrl: null,
                                lastNameCtrl: null,
                                mobilePhoneCtrl: null,
                                passwordCtrl: null
                            });
                            if (this.isMfaEnabled()) {
                                this.registerUserForm.patchValue({
                                    mfaCtrl: MfaType.SMS
                                });
                                this.registerUserForm.get('mfaCtrl').setValidators([Validators.required]);
                            } else {
                                this.registerUserForm.patchValue({
                                    mfaCtrl: MfaType.NONE
                                });
                            }
                            this.userType = 'NEW';
                        }
                        this.registerUserForm.enable();
                    }
                );
        } else {
            this.registerUserForm.patchValue({
                firstNameCtrl: null,
                lastNameCtrl: null,
                mobilePhoneCtrl: null,
                passwordCtrl: null
            });
            if (this.isMfaEnabled()) {
                this.registerUserForm.patchValue({
                    mfaCtrl: MfaType.SMS
                });
                this.registerUserForm.get('mfaCtrl').setValidators([Validators.required]);
            } else {
                this.registerUserForm.patchValue({
                    mfaCtrl: MfaType.NONE
                });
            }
            this.userType = '';
        }
    }

}
