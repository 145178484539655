import {Injectable} from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class BrowserUtilsService {

    private readonly USER_AGENT;
    private readonly BROWSER_NOT_SUPPORTED_PAGE_URL = '/browser-not-supported';

    constructor(private router: Router) {
        this.USER_AGENT = navigator.userAgent.toLowerCase();
    }

    checkBrowserCompatibility() {

        if (this.isMobile()) {
            return;
        }

        let result = null;
        const supportedBrowsers = this.getSupportedBrowsers();

        for (const browser of supportedBrowsers) {
            if (!this.USER_AGENT.includes(browser.name) ) {
                continue;
            }

            result = browser;
            result.version = this.getCurrentBrowserVersion(browser.name);
            break;
        }

        if (result === null) {
            this.router.navigateByUrl(this.BROWSER_NOT_SUPPORTED_PAGE_URL);
            return;
        }

        if (result.version < result.minVersion) {
            this.router.navigateByUrl(this.BROWSER_NOT_SUPPORTED_PAGE_URL);
        }
    }

    private getCurrentBrowserVersion(browserName: string) {
        const versionRegExp = new RegExp('version/(\\d+(\\.\\d+)?)', 'i');
        let results = versionRegExp.exec(this.USER_AGENT);

        if (results === null || results.length <= 0 ) {
            const nameVersionRegExp = new RegExp(`${browserName}/(\\d+(\\.\\d+)?)`, 'i');
            results = nameVersionRegExp.exec(this.USER_AGENT);
        }

        if (results === null || results?.length < 1) {
            return 0;
        }

        return Number.isNaN( results[1] ) ? 0 : parseFloat(results[1]);
    }

    private isMobile() {
        const regExpMobile = new RegExp('mobile', 'i');
        let results = regExpMobile.exec(this.USER_AGENT);
        if (results === null || results?.length < 1) {
            return false;
        }
        return true;
    }

    getSupportedBrowsers() {
        return [
            {
                'name': 'chrome',
                'minVersion': 108.0,
                'img': 'assets/images/chrome.png'
            },
            {
                'name': 'firefox',
                'minVersion': 100.3,
                'img': 'assets/images/firefox.png'
            },
            {
                'name': 'safari',
                'minVersion': 15.6,
                'img': 'assets/images/safari.png'
            },
            {
                'name': 'edge',
                'minVersion': 107.0,
                'img': 'assets/images/edge.png'
            }
        ];
    }
}
