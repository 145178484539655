import { Component, OnInit } from '@angular/core';
import { AuthService, MemberType, Role, TransactionTableFilter, MemberService, Member } from 'projects/services/src/public-api';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'pt-transaction-list',
    templateUrl: './transaction-list.component.html'
})
export class TransactionListComponent implements OnInit {

    Role = Role;

    filter: TransactionTableFilter;
    changeTrigger = 1;
    isActivityListAvailable = true;
    memberId: string;
    exportAccountName = false;

    constructor(public authService: AuthService,
                private memberService: MemberService,
                private route: ActivatedRoute) {}

    ngOnInit() {
        const profile = this.authService.getProfile();
        const queryParams = this.route.snapshot.queryParams;
        this.isActivityListAvailable = !this.authService.isAuthorityOrReviewer() && !this.authService.isRetailer() && !this.authService.isCorporateAdmin();
        if (profile.memberType !== MemberType.AUTHORITY) {
            this.memberId = profile.memberId;
            this.exportAccountName = true;
            this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
                this.filter = new TransactionTableFilter(profile.memberId);
                this.filter.memberType = profile.memberType;
                this.filter.memberFunctions = profile.memberFunctions;
                this.filter.retailLocationId = profile.retailLocationId || '';
                if (member) {
                    this.isActivityListAvailable = this.isActivityListAvailable && this.activityListAvailableMembers(member.memberType);
                    this.filter.transactionTab = this.isActivityListAvailable ? 'ACCOUNT_ACTIVITY' : 'TRANSACTION_LIST';
                }
            });
        } else if (queryParams['memberId']) {
            this.memberService.loadMember(queryParams['memberId']).subscribe((member: Member) => {
                if (member) {
                    this.filter = new TransactionTableFilter(member.id);
                    this.filter.memberType = member.memberType;
                    this.filter.memberFunctions = member.memberFunctions;
                    this.isActivityListAvailable = this.isActivityListAvailable && this.activityListAvailableMembers(member.memberType);
                    this.filter.transactionTab = this.isActivityListAvailable ? 'ACCOUNT_ACTIVITY' : 'TRANSACTION_LIST';
                } else {
                    this.filter = new TransactionTableFilter();
                }
            });
        } else {
            this.filter = new TransactionTableFilter();
        }
    }

    activityListAvailableMembers(memberType: MemberType) {
        // FIXME: CN-3880 - wallet functions are not on members
        return memberType === MemberType.BUSINESS;
        // && memberFunctions.includes(WalletFunction.ACH_DEPOSIT);
    }

    filterChanged(filter: TransactionTableFilter) {
        this.filter = filter;
        this.changeTrigger = Math.random();
    }

    transactionSubmitted() {
        this.changeTrigger = Math.random();
    }

}
