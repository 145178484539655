import { WatchlistEntity } from 'projects/services/src/public-api';

export class WatchlistSearchResult {
    id: string;
    created: string;
    updated: string;
    transactionId: string;
    memberId: string;
    searchTerm: string;
    sessionId: string;
    searchDate: Date;
    watchlistEntities: WatchlistEntity[];
    watchListSearchType: WatchlistSearchType;
    // adhoc fields
    totalCount: number;
    clearedCount: number;
    pendingCount: number;
    matchedCount: number;
}

export enum WatchlistSearchType {
    CONFIA_MEMBER = 'CONFIA_MEMBER',
    CONFIA_FINANCIAL_HOLDER = 'CONFIA_FINANCIAL_HOLDER',
    CONFIA_FINANCIAL_INSTITUTION = 'CONFIA_FINANCIAL_INSTITUTION',
    EXTERNAL_PARTY = 'EXTERNAL_PARTY',
    EXTERNAL_FINANCIAL_HOLDER = 'EXTERNAL_FINANCIAL_HOLDER',
    EXTERNAL_FINANCIAL_INSTITUTION = 'EXTERNAL_FINANCIAL_INSTITUTION'
}