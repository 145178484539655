import { Component, Input, OnChanges } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'pc-badge',
    template: ''

})
export abstract class BaseBadgeComponent<T> implements OnChanges {

    @Input() parent: T;

    public badgeToolTip: string;
    public badgeText: string;
    public badgeClass: string;

    abstract setBadgeData();

    constructor(protected translatePipe: TranslatePipe) {}

    ngOnChanges() {
        this.setBadgeData();
    }

}
