import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Profile } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-session-refresh',
    templateUrl: './session-refresh.component.html',
    styleUrls: ['./session-refresh.component.scss']
})
export class SessionRefreshComponent {

    constructor(private bottomSheetRef: MatBottomSheetRef<SessionRefreshComponent>,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    }

    close() {
        this.bottomSheetRef.dismiss();
    }

    refreshSession() {
        this.data.refresh().subscribe(
            (_profile: Profile) => {
                this.bottomSheetRef.dismiss();
            },
            (error: any) => {
                throw error;
            }
        );
    }

}
