import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AccountingLinkedMember, Member, ReconciliationAccount } from 'projects/services/src/public-api';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AccountingService {

    readonly SUPPORTED_PUSH_PLATFORMS = ['QuickBooks Online'];

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.AccountingAPIEndpoint + '/accounting/';
    }

    updateAccountingRecords(memberId: string) {
        this.http.post(`${this.baseUrl}${memberId}/sync`, {}).subscribe();
    }

    getAccountingSystemInfo(memberId: string): Observable<AccountingLinkedMember> {
        return this.http.get(`${this.baseUrl}${memberId}`) as Observable<AccountingLinkedMember>;
    }

    saveAccountingInformation(body: Member) {
        return this.http.post(`${this.baseUrl}register`, body);
    }

    deleteAccountingInformation(memberId: string) {
        return this.http.delete(`${this.baseUrl}${memberId}`);
    }

    updateCodatSettings(memberId: string, memberAccountId: string, updates: any) {
        return this.http.put(`${this.baseUrl}${memberId}/${memberAccountId}/push`, updates);
    }

    getCodatSettings(memberId: string, memberAccountId: string) {
        return this.http.get(`${this.baseUrl}${memberId}/${memberAccountId}/push`);
    }

    fetchAllAccountsByMemberId(memberId: string) : Observable<ReconciliationAccount[]> {
        return this.http.get(`${this.baseUrl}${memberId}/accounts`) as Observable<ReconciliationAccount[]>;
    }

    createReconciliationAccount(memberId: string, memberAccountId: string, body: { accountName }) {
        return this.http.post(`${this.baseUrl}${memberId}/${memberAccountId}/accounts`, body);
    }

    getSupportedPushPlatforms(): string[] {
        return this.SUPPORTED_PUSH_PLATFORMS;
    }
}
