import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AuthService, BNPLLoan, PagedResponse, PaymentService } from 'projects/services/src/public-api';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pc-bnpl-loan-details',
    templateUrl: './bnpl-loan-details.component.html'
})
export class BnplLoanDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

    displayedColumns: string[] = ['name', 'phone_number', 'status', 'location', 'total_amount', 'loan_amount', 'refund_amount', 'amount_paid', 'amount_outstanding', 'payments_remaining', 'final_payment_date'];
    bnplLoans: BNPLLoan[] = [];
    pageTracking: PageTracking;
    resultsLength = 0;
    isLoadingResults = true;
    subscription: any;
    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private paymentsService: PaymentService,
                private cdr: ChangeDetectorRef) { }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'created', 'desc');
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.refreshEvent)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.paymentsService.getBNPLLoanDetails(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
                }),
                map((response: PagedResponse<BNPLLoan>) => {
                    this.isLoadingResults = false;
                    this.resultsLength = response.totalElements || 0;
                    return response.content || [];
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    return observableOf([]);
                })
            ).subscribe((loanDetails: BNPLLoan[]) => {
                this.bnplLoans = loanDetails;
                UIUtils.scrollDashboardToTop();
            });
    }

    loadTransactionDetails(transactionId: string, event: any) {
        if (event.target.tagName !== 'A') {
            this.router.navigate(['/transaction/details/', transactionId]);
        }
    }
}
