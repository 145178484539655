import { MemberAccount } from './member-account';
import { SupportedTransactionType, TransactionType } from './transaction';
import { MerchantAccountStatus } from './merchant-account';

export class MerchantAccountConfig {

    id: string;
    created: Date;
    updated: Date;
    merchantMemberAccount: MemberAccount;
    source: MerchantAccountSource;
    status: MerchantAccountStatus;
    merchantAccountType: SupportedTransactionType;
    defaultIncomingTransactionType: TransactionType;
    defaultOutgoingTransactionType: TransactionType;
    merchantAccountNote: string;
    shared: boolean;
    merchantEmailNotification: boolean;

}

export enum MerchantAccountSource {
    ALL = '',
    CONFIA = 'CONFIA',
    ACCOUNTING = 'ACCOUNTING',
    EXTERNAL_TRANSACTION = 'EXTERNAL_TRANSACTION'
}
