<div>
    <div>
        <div class="modal-header">
            <div class="row no-gutters col-12">
                <div class="col-1 pull-left"><img class="dashboard-icon" src="assets/images/account-2.svg" alt=""></div>
                <div class="col-10">
                    <label class="form-title">{{paymentType === PaymentType.BUSINESS_PAYMENT ? 'Business' : 'Confia'}} Payment from Account: {{memberAccount.accountName}}</label>
                    <div class="row">
                        <div class="row mt-1">
                            <div class="col-4 centered"[ngClass]="errorMessage ? 'error' : ''">Available Balance:<strong>&nbsp;{{memberAccount.wallet.availableBalance | currency}}</strong></div>
                            <div class="col-4 centered">Pending Funds:<strong>&nbsp;{{memberAccount.wallet.pendingBalance | currency}}</strong></div>
                            <div class="col-4 centered" [ngClass]="errorMessage ? 'error' : ''">Payment Total:<strong>&nbsp;{{transactionTotal | currency}}</strong></div>
                        </div>
                    </div>
                </div>
                <div class="col-1 pull-right"><i class="modal-close" (click)="close()">&times;</i></div>
            </div>
            <div class="row no-gutters col-12">
                <pc-error-message [error]="errorMessage" [width]="'full'"></pc-error-message>
            </div>
        </div>

        <div class="modal-content">
            <div *ngIf="!paymentTypeSelected">
                <div class="d-flex justify-content-center">
                    <div class="flex-column">
                        <div class="mt-3 mb-5"><strong>Please select a payment type:</strong></div>
                        <div class="centered mb-5">
                            <mat-button-toggle-group #group="matButtonToggleGroup" #buttongroup class="mb-5" [value]="paymentType" (change)="onTypeChange(group.value)" [vertical]="(buttonOrientation | async)!">
                                <mat-button-toggle [value]="PaymentType.BUSINESS_PAYMENT">
                                    <div class="p-3">Business Payment</div>
                                </mat-button-toggle>
                                <mat-button-toggle [value]="PaymentType.CONFIA_PAYMENT">
                                    <div class="p-3">Confia Payment</div>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div *ngIf="!isAccountingLinked && paymentType === PaymentType.BUSINESS_PAYMENT" class="flex-column">
                            <pc-error-message [error]="ACCOUNTING_NOT_LINKED_ERROR_BUSINESS_PAYMENT" [width]="'full'"></pc-error-message>
                        </div>
                        <div *ngIf="!isAccountingLinked && paymentType === PaymentType.CONFIA_PAYMENT" class="flex-column">
                            <pc-error-message [error]="ACCOUNTING_NOT_LINKED_ERROR_CONFIA_PAYMENT" [width]="'full'"></pc-error-message>
                        </div>
                        <div *ngIf="!isMetrcLinked" class="flex-column">
                            <pc-error-message [error]="METRC_NOT_LINKED_ERROR" [width]="'full'"></pc-error-message>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button class="blue-button" [disabled]="!paymentType" (click)="selectPaymentType()" type="button">Next</button>
                </div>
            </div>
            <div *ngIf="paymentTypeSelected && memberAccount">
                <div *ngIf="paymentType === PaymentType.BUSINESS_PAYMENT">
                    <pt-business-payment [memberAccount]="memberAccount" [isAccountingLinked]="isAccountingLinked" [isMetrcLinked]="isMetrcLinked" [availableBalance]="availableBalance"
                        [achExpressPaymentFee]="achExpressPaymentFee" [wirePaymentFee]="wirePaymentFee" (totalChanged)="onTotalChange($event)" (transactionReviewed)="onSubmit($event)"
                        (closeTransactionModal)="close($event)"></pt-business-payment>
                </div>
                <div *ngIf="paymentType === PaymentType.CONFIA_PAYMENT">
                    <pt-confia-payment [memberAccount]="memberAccount" [isAccountingLinked]="isAccountingLinked" [isMetrcLinked]="isMetrcLinked" [availableBalance]="availableBalance"
                        [achDirectPaymentEnabled]="achDirectPaymentEnabled" (totalChanged)="onTotalChange($event)" (transactionReviewed)="onSubmit($event)"></pt-confia-payment>
                </div>
            </div>
        </div>
    </div>
</div>
