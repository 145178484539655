import { Component, Input } from '@angular/core';

@Component({
    selector: 'pc-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent {

    @Input() error: { message: string, type: ErrorType };
    @Input() width = 'half';

    getClass() {
        if (this.error.type === ErrorType.WARN) {
            return 'warning ' + this.width;
        }
        return 'error ' + this.width;
    }

    getIcon() {
        if (this.error.type === ErrorType.WARN) {
            return 'fa-exclamation-triangle';
        }
        return 'fa-exclamation-circle';
    }

}

export enum ErrorType {
    ERROR = 'ERROR',
    WARN = 'WARN'
}
