<div class="horizontal-scroll mat-table-container">
    <div class="table mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="searchResults" matSort matSortDisableClear [matSortActive]="pageTracking.sort" [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell class="key-party-type" *matCellDef="let row">
                <img *ngIf="row.corporateKeyParty" src="/assets/images/site-icon.svg" title="Corporate">
                <img *ngIf="row.individualKeyParty" src="/assets/images/profile-icon.svg" title="Individual">
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Key Party Name </th>
            <td mat-cell *matCellDef="let element">
                <a class="link" (click)="memberDetails($event, element?.member)">
                    <div *ngIf="element.individualKeyParty">
                        <b> {{element.individualKeyParty.name}} </b>
                    </div>
                    <div *ngIf="element.corporateKeyParty">
                        <b> {{element.corporateKeyParty.name}} </b>
                        <div *ngIf="element.corporateKeyParty.dbaName">
                            DBA: {{element.corporateKeyParty.dbaName}}
                        </div>
                    </div>
                </a>

                <div *ngIf="element.individualKeyParty">
                    <div *ngIf="element.individualKeyParty.professionalTitle">
                        {{element.individualKeyParty.professionalTitle}}
                    </div>
                    <div *ngIf="element.individualKeyParty.phone">
                        {{element.individualKeyParty.phone | phone}}
                    </div>
                    <div *ngIf="element.individualKeyParty.email">
                        {{element.individualKeyParty.email}}
                    </div>
                </div>

                <div *ngIf="element.corporateKeyParty">
                    <div *ngIf="element.corporateKeyParty.officePhone">
                        {{element.corporateKeyParty.officePhone | phone}}
                    </div>
                    <div *ngIf="element.corporateKeyParty.email">
                        {{element.corporateKeyParty.email}}
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef > Address </th>
            <td mat-cell *matCellDef="let element">
                <pc-business-address *ngIf="element.individualKeyParty" [address]="element.individualKeyParty.address">
                </pc-business-address>
                <pc-business-address *ngIf="element.corporateKeyParty" [address]="element.corporateKeyParty.address">
                </pc-business-address>
            </td>
        </ng-container>
        <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef > Details </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="isDetailsVisible(element)">
                    <div *ngIf="element.individualKeyParty.birthDate">
                        DOB: {{element.individualKeyParty.birthDate | date }}
                    </div>
                    <div *ngIf="element.individualKeyParty.usCitizen">
                        US Citizen
                    </div>
                    <div *ngIf="isDetailSsnVisible(element.individualKeyParty)">
                        SSN: {{element.individualKeyParty.taxId}}
                    </div>
                    <div *ngIf="element.individualKeyParty.nationality">
                        Nationality: {{element.individualKeyParty.nationality}}
                    </div>
                    <div *ngIf="isDetailPassportVisible(element.individualKeyParty)">
                        Passport: {{element.individualKeyParty.passportNumber}}
                    </div>
                    <div *ngIf="isDetailPassportExpirationVisible(element.individualKeyParty)">
                        Passport Expiration Date: {{element.individualKeyParty.passportExpirationDate | date}}
                    </div>
                </div>

                <div *ngIf="element.corporateKeyParty">
                    <div *ngIf="element.corporateKeyParty.taxId">
                        EIN/Tax ID: {{element.corporateKeyParty.taxId}}
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="relationship">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="percentOwnership"> Relationship</th>
            <td mat-cell *matCellDef="let row">
                <div *ngIf="row.director || row.officer || row.percentOwnership">
                    Ownership: {{row.percentOwnership / 100 | percent}}
                </div>
                <div *ngIf="row.director">
                    Director
                </div>
                <div *ngIf="row.officer">
                    Officer
                </div>
                <div *ngIf="!row.director && !row.officer && !row.percentOwnership">
                    Contact Info
                </div>
                <div *ngIf="row.licenseHolder">
                    License Holder
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef  class="centered" mat-sort-header> Member Status </th>
            <td mat-cell *matCellDef="let element" class="centered">
                <pc-member-badge [parent]="element.member"></pc-member-badge>
                <div>{{ element.member.name }}</div>
                <div *ngIf="element.member.jurisdiction">{{ Utils.getJurisdictionName(element.member.jurisdiction) }}</div>
                <div *ngIf="element.member.tier === RiskTier.TIER_1">Tier 1</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef  class="centered" mat-sort-header> Created Date </th>
            <td mat-cell *matCellDef="let element" class="centered">
                {{ element.created | date:'mediumDate' }}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns" [class.disabled]="element.status !== UserAccountStatus.ACTIVE"></tr>
    </table>
    <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"></mat-paginator>
</div>
