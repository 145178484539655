import { Component } from '@angular/core';
import { BrowserUtilsService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-browser-not-supported',
    templateUrl: './browser-not-supported.component.html',
    styleUrls: ['./browser-not-supported.component.scss']
})
export class BrowserNotSupportedComponent {

    readonly SUPPORTED_BROWSERS;

    constructor(browserUtilsService: BrowserUtilsService) {
        this.SUPPORTED_BROWSERS = browserUtilsService.getSupportedBrowsers();
    }

}
