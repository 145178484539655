<div class="row">
    <div class="col-12">
        <div [class]="chartContainer">
            <ngx-charts-bar-vertical-2d *ngIf="chartType === ChartType.GROUPED_VERTICAL_BAR_CHART"
                [scheme]="colorScheme"
                [results]="chartData"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legendPosition]="legendPosition"
                [legendTitle]="legendTitle"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [yAxisTickFormatting]="tickformat"
                [barPadding]="4"
                [animations]="false">
            </ngx-charts-bar-vertical-2d>
            <ngx-charts-bar-vertical *ngIf="chartType === ChartType.VERTICAL_BAR_CHART"
                [scheme]="colorScheme"
                [results]="chartData"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legendPosition]="legendPosition"
                [legendTitle]="legendTitle"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [animations]="false"
                [yAxisTickFormatting]="tickformat"
                [rotateXAxisTicks]="false"
                [animations]="false">
            </ngx-charts-bar-vertical>
            <ngx-charts-line-chart *ngIf="chartType === ChartType.LINE_CHART"
                [scheme]="colorScheme"
                [results]="chartData"
                [xAxis]="showXAxis"
                [yAxis]="showYAxis"
                [legendPosition]="legendPosition"
                [legendTitle]="legendTitle"
                [legend]="showLegend"
                [showXAxisLabel]="showXAxisLabel"
                [yAxisTickFormatting]="tickformat"
                [showYAxisLabel]="showYAxisLabel"
                [animations]="false">
            </ngx-charts-line-chart>
        </div>
    </div>
</div>
