import { Component, OnInit } from '@angular/core';
import { BaseModalComponent } from '../base-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import {
    AuthService, FinancingRequestFormService, LenderService, LendingFinancingRequest, MemberFunction, MemberType, PagedResponse, MemberService, MemberStatus, Member
} from 'projects/services/src/public-api';

@Component({
    selector: 'pc-financing-request-modal',
    templateUrl: './financing-request-modal.component.html'
})
export class FinancingRequestModalComponent extends BaseModalComponent<FinancingRequestModalComponent> implements OnInit {

    financingFormGroup: UntypedFormGroup;
    memberId: string;
    companyTypes = [
        'Cultivator',
        'Manufacturer',
        'Distributor',
        'Retailer'
    ];
    lendersMap = new Map<string, string>();
    terms = [
        'WEEKLY',
        'MONTHLY',
        'YEARLY'
    ];

    constructor(private financingRequestFormService: FinancingRequestFormService,
                     private memberService: MemberService,
                     private authService: AuthService,
                     private lenderService: LenderService,
                     dialogRef: MatDialogRef<FinancingRequestModalComponent>) {
        super(dialogRef);
    }


    ngOnInit() {
        this.memberId = this.authService.getProfile().memberId;
        this.financingFormGroup = this.financingRequestFormService.initializeForm();
        this.memberService.getMembersByTypesAndStatus(
            '',
            [MemberType.BUSINESS],
            [MemberStatus.ACTIVE],
            [MemberFunction.LENDER],
            0,
            10,
            'hashName',
            'ASC').subscribe(
            (response: PagedResponse<Member>) => {
                for (const member of response.content) {
                    this.lendersMap.set(member.name, member.id);
                }
            });
    }

    confirm() {
        const viewAccessDate = new Date();
        viewAccessDate.setDate(viewAccessDate.getDate() + 5);
        const financingRequest: LendingFinancingRequest = {
            memberId: this.memberId,
            lenderId: this.lendersMap.get(this.financingFormGroup.get('lenderCtrl').value),
            companyType: this.financingFormGroup.get('companyTypeCtrl').value,
            requestAmount: this.financingFormGroup.get('requestAmountCtrl').value,
            requestTerm: this.financingFormGroup.get('requestTermCtrl').value,
            revenue: this.financingFormGroup.get('revenueCtrl').value,
            arTotal: this.financingFormGroup.get('arTotalCtrl').value,
            note: this.financingFormGroup.get('noteCtrl').value,
            ebitda: this.financingFormGroup.get('ebitdaCtrl').value
        };
        this.lenderService.createFinancingRequest(financingRequest)
            .subscribe(() => {
                super.close(true);
            });
    }

    close() {
        super.close(false);
    }

    iterableIteratorToArray(iterator: IterableIterator<string>): string[] {
        return [...iterator];
    }
}
