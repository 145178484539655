export class Address {
    id: string;
    created: Date;
    updated: Date;
    streetAddressOne: string;
    streetAddressTwo: string;
    city: string;
    stateProvince: string;
    country: string;
    zipPostalCode: string;
    phoneNumber: string;
    type: AddressType;

}

export enum AddressType {
    PRIMARY = 'PRIMARY',
    BILLING = 'BILLING',
    OPERATIONS = 'OPERATIONS',
    RETAIL = 'RETAIL',
    OTHER = 'OTHER'
}
