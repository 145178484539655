import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-text-option-selector',
    templateUrl: './text-option-selector.component.html',
    styleUrls: ['./text-option-selector.component.scss']
})
export class TextOptionSelectorComponent implements OnInit {

    @Input() required: boolean;
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() defaultValue: string;
    @Input() class = '';
    @Input() options: string[];
    @Input() translated = false;
    @Input() id: string;

    @Output() selected: EventEmitter<any> = new EventEmitter<any>();

    safariFix: boolean;

    constructor(private translateService: TranslateService) {}

    ngOnInit() {
        this.safariFix = Utils.isSafari() && !this.defaultValue;
    }

    getClass() {
        return `${this.class}`;
    }

    isDefault(option: string) {
        return (this.defaultValue) && (option === this.defaultValue);
    }

    onSelected() {
        this.selected.emit(this.formGroup.controls[this.formCtrl].value);
    }

    getOptionDisplayValue(option: string) {
        if (this.translated) {
            if (!option) {
                return '';
            }
            return this.translateService.instant(option);
        }
        return option;
    }
}
