<div class="d-flex">
    <table>
        <tr *ngFor="let line of packages">
            <td class="text-left right-pad">{{line.productName}}</td>
            <td class="text-right">{{line.shippedQuantity}}</td>
            <td class="text-left right-pad">{{line.quantityUnits}}</td>
            <td class="text-left">({{line.productCategory}})</td>
        </tr>
    </table>
</div>
