import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountingService, MemberAccount } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-linked-reconciliation-account-modal',
    templateUrl: './linked-reconciliation-account-modal.component.html'
})
export class LinkedReconciliationAccountModalComponent extends BaseModalComponent<LinkedReconciliationAccountModalComponent> implements OnInit {

    memberAccount: MemberAccount;
    reconciliationAccountFormGroup: UntypedFormGroup;

    constructor(private accountingService: AccountingService,
                private dialogRef: MatDialogRef<LinkedReconciliationAccountModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberAccount = data.memberAccount;
    }

    ngOnInit() {
        this.onSubmit = this.onSubmit.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.reconciliationAccountFormGroup  = new UntypedFormGroup({
            nameCtrl: new UntypedFormControl('',
                [Validators.required, Validators.minLength(2), Validators.maxLength(40)])
        });
    }

    onSubmit() {
        super.close(this.reconciliationAccountFormGroup.get('nameCtrl').value);
    }

    isFormValid() {
        return this.reconciliationAccountFormGroup.valid;
    }

    cancel() {
        super.close();
    }

    close() {
        super.close();
    }
}
