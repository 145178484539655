<div class="table-wrapper" [class.required]="isRequired()">
    <table mat-table [dataSource]="order.lineItems" class="mat-elevation-z8">
        <ng-container matColumnDef="item">
            <th mat-header-cell class="center" *matHeaderCellDef> Item </th>
            <td mat-cell class="center" *matCellDef="let element"> {{element.item}} </td>
            <td mat-footer-cell *matFooterCellDef><strong>Total:</strong></td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Quantity </th>
            <td mat-cell class="center" *matCellDef="let element"> {{element.quantity}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="unitOfMeasure">
            <th mat-header-cell *matHeaderCellDef> Unit </th>
            <td mat-cell class="center" *matCellDef="let element"> {{element.unitOfMeasure | translate}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="pricePerUnit">
            <th mat-header-cell *matHeaderCellDef> Price </th>
            <td mat-cell class="center" *matCellDef="let element"> {{element.pricePerUnit | currency}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="discount">
            <th mat-header-cell *matHeaderCellDef> Discount </th>
            <td mat-cell class="center" *matCellDef="let element"> {{element.discount | currency}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="preTaxAmount">
            <th mat-header-cell *matHeaderCellDef> Pre Tax Total </th>
            <td mat-cell class="center" *matCellDef="let element"> {{element.preTaxAmount | currency}} </td>
            <td mat-footer-cell class="center" *matFooterCellDef><strong *ngIf="order.preTaxAmount">{{order.preTaxAmount | currency}}</strong></td>
        </ng-container>
        <ng-container matColumnDef="taxType">
            <th mat-header-cell *matHeaderCellDef> Tax Type </th>
            <td mat-cell class="tax-type center" *matCellDef="let element"> {{(element.exempt ? 'EXEMPT' : element.taxType) | translate}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="taxAmount">
            <th mat-header-cell *matHeaderCellDef> Tax Amount </th>
            <td mat-cell class="center" *matCellDef="let element"> {{(element.taxType === 'EXCISE' ? element.exciseTaxAmount : element.taxAmount) | currency}} </td>
            <td mat-footer-cell class="center" *matFooterCellDef><strong *ngIf="order.taxAmount">{{order.taxAmount | currency}}</strong></td>
        </ng-container>
        <ng-container matColumnDef="totalAmount">
            <th mat-header-cell *matHeaderCellDef> Total Amount </th>
            <td mat-cell class="center" *matCellDef="let element"> {{element.totalAmount | currency}} </td>
            <td mat-footer-cell class="center" *matFooterCellDef><strong *ngIf="order.totalAmount">{{order.totalAmount | currency}}</strong></td>
        </ng-container>
        <ng-container matColumnDef="resale">
            <th mat-header-cell *matHeaderCellDef> Resale </th>
            <td mat-cell class="center" *matCellDef="let element"> {{(element.notForResale && 'No') || ''}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="action">
            <th mat-header-cell class="center" *matHeaderCellDef><i class="fa fa-plus" (click)="onAdd()"></i></th>
            <td mat-cell class="center nowrap" *matCellDef="let element"><i class="fa fa-pencil" (click)="editOrderLine(element)"></i><i class="fa fa-trash" (click)="removeOrderLine(element)"></i></td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="shippingTitle">
            <td mat-footer-cell [class.required]="order.shippingCost === null && enforceRequired" *matFooterCellDef><strong>Shipping:</strong></td>
        </ng-container>
        <ng-container matColumnDef="shippingAmount">
            <td mat-footer-cell class="center" [class.required]="order.shippingCost === null && enforceRequired" *matFooterCellDef><strong>{{order.shippingCost | currency}}</strong></td>
        </ng-container>
        <ng-container matColumnDef="shippingAction">
            <td mat-footer-cell class="center nowrap" [class.required]="order.shippingCost === null && enforceRequired" *matFooterCellDef><i class="fa fa-pencil" (click)="editShippingCost()"></i></td>
        </ng-container>
        <ng-container matColumnDef="empty">
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedShippingColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
</div>
