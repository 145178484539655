<div class="row mb-3">
    <div class="col-12">
        <span class="form-title">Adjudicate</span>
    </div>
</div>

<div class="row">

    <div class="col-sm-12 horizontal-scroll mat-table-container">
        <div class="mat-table-spinner" *ngIf="isLoadingResults">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>

        <table mat-table [dataSource]="history" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Type of Review </th>
                <td mat-cell *matCellDef="let element">
                    <span>{{element.reviewType | translate}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="centered"> Date </th>
                <td mat-cell *matCellDef="let element" class="centered">
                    <span>{{element.reviewedDate | date:'mediumDate'}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="reviewed_by">
                <th mat-header-cell *matHeaderCellDef> Reviewed By </th>
                <td mat-cell *matCellDef="let element">
                    <span>{{element.reviewer?.firstName}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="centered"> Status </th>
                <td mat-cell *matCellDef="let element" class="centered">
                    <pc-adjudicate-badge [parent]="element"></pc-adjudicate-badge>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="centered"> Actions </th>
                <td mat-cell class="action centered" *matCellDef="let element">
                    <div class="button-panel" *ngIf="(taskId && element.status === 'PENDING') && isAuthority()">
                        <pc-submit-button [submit]="onReject" (clickEvent)="onActionClicked(element)" [text]="rejectButtonLabel" [leftIcon]="'times'"></pc-submit-button>
                        <pc-submit-button [enabled]="applicationValid" [submit]="onAccept" (clickEvent)="onActionClicked(element)" [text]="approveButtonLabel" [leftIcon]="'check'"></pc-submit-button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>

</div>
