<div class="col-12">

    <div class="row">
        <div class="col-12 tab-row">
            <div [ngClass]="getTabClass('TRANSMITTER')" (click)="setActiveTab('TRANSMITTER')">
                Transmitter
            </div>
            <div [ngClass]="getTabClass('TRANSMITTER_CONTACT_INFORMATION')" (click)="setActiveTab('TRANSMITTER_CONTACT_INFORMATION')">
                Transmitter Contact Information
            </div>
            <div [ngClass]="getTabClass('REPORTING_FINANCIAL_INSTITUTION')" (click)="setActiveTab('REPORTING_FINANCIAL_INSTITUTION')">
                Reporting Financial Institution
            </div>
            <div [ngClass]="getTabClass('CONTACT_FOR_ASSISTANCE')" (click)="setActiveTab('CONTACT_FOR_ASSISTANCE')">
                Contact For Assistance
            </div>
            <div [ngClass]="getTabClass('TRANSACTION_LOCATION')" (click)="setActiveTab('TRANSACTION_LOCATION')">
                Transaction Location
            </div>
        </div>
    </div>
</div>
<pc-reports-party-information *ngIf="activeTab !== ''"
    [submissionType]="submissionType"
    [partyType]="activeTab"
    [filingInstitution]="filingInstitution">
</pc-reports-party-information>
<pc-reports-list
    [changeTrigger]="changeTrigger"
    [memberId]="memberId"
    [reportStatus]="reportStatus"
    [submissionType]="submissionType"
    [filingInstitution]="filingInstitution">
</pc-reports-list>
