<div>
    <form>
        <div>
            <div class="modal-content">
                <div class="row">
                    <div class="col-6">
                        <p class="blue"><b>Primary Color (for footer and header)</b></p>
                        <input [value]="primaryColor"
                            [style.background]="primaryColor"
                            [(colorPicker)]="primaryColor"/>
                    </div>
                    <div class="col-6">
                        <p class="blue"><b>Color of the buttons</b></p>
                        <input [value]="buttonsColor"
                            [style.background]="buttonsColor"
                            [(colorPicker)]="buttonsColor"/>
                    </div>
                    <div class="col-6">
                        <p class="blue"><b>Primary Font Color</b></p>
                        <input [value]="secondaryColor"
                            [style.background]="secondaryColor"
                            [(colorPicker)]="secondaryColor"/>
                    </div>
                    <div class="col-6">
                        <p class="blue"><b>Color of the text in the buttons</b></p>
                        <input [value]="buttonsTextColor"
                            [style.background]="buttonsTextColor"
                            [(colorPicker)]="buttonsTextColor"/>
                    </div>
                    <div class="col-6">
                        <pc-text-option-selector [required]="true" [label]="'Primary Font (for titles within the footer and header)'"
                            [options]="fontOpts" [formCtrl]="'fontCtrl'" [formGroup]="landingCustomizationForm" [defaultValue]="''">
                        </pc-text-option-selector>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [submit]="confirm" [text]="'Save Changes'"></pc-submit-button>
                <pc-submit-button [submit]="close" [text]="'Cancel'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
