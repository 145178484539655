<div class="dashboard-list">
    <div class="row">
        <div class="col-12">
            <div class="dashboard-info-icon">
                <img class="dashboard-icon" src="assets/images/receipt-icon-2.svg" alt="">
                <a href="#">{{'Transaction Types'}}</a>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-12">
            <div *ngIf="!tableView">
                <pc-charts-gadgets *ngIf="chartData.length > 0"
                    [chartData]="chartData"
                    [chartType]="ChartType.GROUPED_VERTICAL_BAR_CHART"
                    [yAxisTickFormatting]="'whole'">
                </pc-charts-gadgets>
            </div>
        </div>
        <div class="col-12" *ngIf="tableView">
            <div class="col-sm-12 mt-3 mb-3 horizontal-scroll mat-table-container"> 
                <table mat-table [dataSource]="transactionTypeTableData">
                    <ng-container matColumnDef="date">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Date </th>
                        <td class="centered text-nowrap" mat-cell *matCellDef="let element">
                            {{ element.date }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="ach_deposit">
                        <th class="centered" mat-header-cell *matHeaderCellDef> ACH Deposit </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.achDeposit }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="ach_withdrawal">
                        <th class="centered" mat-header-cell *matHeaderCellDef> ACH Withdrawal </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.achWithdrawal }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="cash_deposit">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Cash Deposit </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.cashDeposit }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="cash_withdrawal">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Cash Withdrawal </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.cashWithdrawal }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="wire_deposit">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Wire Deposit </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.wireDeposit }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="wire_withdrawal">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Wire Withdrawal </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.wireWithdrawal }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="check_deposit">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Check Deposit </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.checkDeposit }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="check_withdrawal">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Check Withdrawal </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.checkWithdrawal }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="network">
                        <th class="centered" mat-header-cell *matHeaderCellDef> Network </th>
                        <td class="centered" mat-cell *matCellDef="let element">
                            {{ element.network }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
    <div>
        <pc-view-decision
            [tableView]="tableView"
            (viewChanged)="viewChanged($event)">
        </pc-view-decision>
    </div>
</div>
