import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    DataAccessStatus, User, UserService, LenderService, RequestAccess, MemberService, Member
} from 'projects/services/src/public-api';
import {BaseModalComponent} from '../base-modal.component';

@Component({
    selector: 'pc-approve-access-modal',
    templateUrl: './approve-access-modal.component.html'
})
export class ApproveAccessModalComponent extends BaseModalComponent<ApproveAccessModalComponent> implements OnInit {

    requestAccess: RequestAccess;
    lenderUserName: string;
    lenderName: string;
    clicked = false;
    userId: string;

    constructor(private lenderService: LenderService,
                private memberService: MemberService,
                private userService: UserService,
                dialogRef: MatDialogRef<ApproveAccessModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.requestAccess = data.requestAccess;
        this.userId = data.userId;
    }

    ngOnInit() {
        this.memberService.loadMember(this.requestAccess.lenderId)
            .subscribe((member: Member) => {
                this.lenderName = member.name;
                this.userService.loadUser(this.userId)
                    .subscribe((user: User) => {
                        this.lenderUserName = user.firstName + ' ' + user.lastName;
                    });
            });
    }

    submit() {
        if (!this.clicked) {
            this.clicked = true;
            this.update(DataAccessStatus.APPROVED);
        }
    }

    decline() {
        if (!this.clicked) {
            this.clicked = true;
            this.update(DataAccessStatus.REJECTED);
        }
    }

    update(status: DataAccessStatus) {
        this.lenderService.updateRequestAccess(this.requestAccess.id, {status})
            .subscribe(() => {
                super.close(true);
            });
    }

    close() {
        super.close(false);
    }
}
