import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationLinks } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-generic-sidebar',
    templateUrl: './generic-sidebar.component.html'
})
export class GenericSidebarComponent implements OnInit {

    @Input() expandedMenu: boolean;

    @Output() toggleExpandSideMenu: EventEmitter<any> = new EventEmitter<any>();

    menuActions: any[];

    ngOnInit() {
        this.menuActions = [NavigationLinks.dashboardLink,
            NavigationLinks.transactionLink];
    }

    getMenuClass() {
        if (this.expandedMenu) {
            return 'sidenav-wide';
        } else {
            return 'sidenav-narrow';
        }
    }

    onToggleExpandSideMenu() {
        this.toggleExpandSideMenu.emit('toggle');
    }
}
