<div class="row" *ngFor="let note of noteGroup">
    <div class="col-12">
        <div class="notesIcon">
            <img class="icon" src="assets/images/note-icon.svg" alt="Message">
        </div>
        <div class="inlineBlockTop">
            {{note.comment}}
            <div class="label pull-right">
                <small><i> -- {{note.authorName}}</i></small>
                <br>
                <small><i> -- {{note.created | date: 'medium'}}</i></small>
            </div>
            <br>
        </div>
    </div>
</div>
