import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-confirm-modal',
    templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent extends BaseModalComponent<ConfirmModalComponent> implements OnInit {

    title: string;
    description: string;
    confirmMessage: string;
    confirmText: string;
    confirmAction = 'confirmed';
    rejectText = 'Cancel';
    rejectAction = '';
    labelOneTitle: string;
    labelOneDescription: string;
    labelTwoTitle: string;
    labelTwoDescription: string;
    enableNotes: boolean;

    notesForm: UntypedFormGroup;

    constructor(dialogRef: MatDialogRef<ConfirmModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.title = data.title;
        this.description = data.description;
        this.confirmMessage = data.confirmMessage;
        this.confirmText = data.confirmText;
        this.confirmAction = data?.confirmAction || 'confirmed';
        this.rejectText = data?.rejectText || 'Cancel';
        this.rejectAction = data?.rejectAction || '';
        this.labelOneTitle = data?.labelOneTitle || '';
        this.labelOneDescription = data?.labelOneDescription || '';
        this.labelTwoTitle = data?.labelTwoTitle || '';
        this.labelTwoDescription = data?.labelTwoDescription || '';
        this.enableNotes = data?.enableNotes;
    }

    ngOnInit() {
        this.confirm = this.confirm.bind(this);
        this.reject = this.reject.bind(this);
        this.close = this.close.bind(this);
        this.isNotesValid = this.isNotesValid.bind(this);
        if (this.enableNotes) {
            this.notesForm = new UntypedFormGroup({
                notesCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.noLeadingOrTrailingWhitespace, Validators.minLength(2), Validators.maxLength(256)])
            });
        }
    }

    confirm() {
        if (this.enableNotes) {
            super.close(this.notesForm.get('notesCtrl').value);
        } else {
            super.close(this.confirmAction);
        }
    }

    reject() {
        if (this.enableNotes) {
            super.close();
        } else {
            super.close(this.rejectAction);
        }
    }

    close() {
        super.close();
    }

    isNotesValid(): boolean {
        if (this.enableNotes) {
            return this.notesForm.valid;
        }
        return true;
    }
}
