<div>
    <form [formGroup]="newUserForm">
        <div>
            <div class="modal-header">
                <label class="form-title">{{ getTitle() }}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div>
                    <pc-email-input [required]="true" [label]="'Email'" [placeholder]="'Email'"
                        [formCtrl]="'emailCtrl'" [formGroup]="newUserForm" (changed)="onEmailChange($event)">
                    </pc-email-input>
                    <pc-text-input [required]="true" [label]="'First Name'" [placeholder]="'First Name'"
                        [formCtrl]="'firstNameCtrl'" [formGroup]="newUserForm">
                    </pc-text-input>
                    <pc-text-input [required]="false" [label]="'Middle Name'" [placeholder]="'Middle Name'"
                        [formCtrl]="'middleNameCtrl'" [formGroup]="newUserForm">
                    </pc-text-input>
                    <pc-text-input [required]="true" [label]="'Last Name'" [placeholder]="'Last Name'"
                        [formCtrl]="'lastNameCtrl'" [formGroup]="newUserForm">
                    </pc-text-input>
                    <pc-text-input [required]="true" [label]="'Title'" [placeholder]="'Title'"
                        [formCtrl]="'titleCtrl'" [formGroup]="newUserForm">
                    </pc-text-input>
                    <pc-phone-input [required]="true" [label]="'Office Number'"
                        [formCtrl]="'officePhoneCtrl'" [formGroup]="newUserForm">
                    </pc-phone-input>
                    <pc-phone-input [required]="true" [label]="'Mobile Number'"
                        [formCtrl]="'mobilePhoneCtrl'" [formGroup]="newUserForm">
                    </pc-phone-input>
                    <pc-form-selector *ngIf="isMfaEnabled()" [required]="true" [label]="'Two Factor Authentication'" [formCtrl]="'mfaCtrl'"
                        [formGroup]="newUserForm" [options]="mfaTypes" [idValue]="'id'" [displayValue]="'name'" [defaultValue]="''">
                    </pc-form-selector>
                </div>
            </div>
            <pc-error-message [error]="errorMessage"></pc-error-message>
            <div class="modal-footer">
                <pc-submit-button [enabled]="isNewUserFormValid" [submit]="onSubmit" [text]="role === Role.AUTHORITY_READONLY ? 'Add Authority' : 'Add User'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
