import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { BaseModalComponent } from './base-modal.component';
import { ConfirmModalComponent } from './confirm/confirm-modal.component';

@Injectable({
    providedIn: 'root'
})
export class MatDialogHandler extends MatDialog {

    // This class is to prevent multiple dialogs from opening instead of using a blur() on the buttons, or placing a check
    // everywhere to see if a dialog is already open.  I have excluded the ConfirmModalComponent in the event that we actually
    // try to display that one over top of another modal - shouldn't be happening, but just in case.
    open<T, D = any, R = any>(template: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>): MatDialogRef<T, R> {
        const openDialog = this.openDialogs.find((dialogRef: MatDialogRef<any>) => {
            if (dialogRef.componentInstance instanceof BaseModalComponent && !(dialogRef.componentInstance instanceof ConfirmModalComponent)) {
                return true;
            }
        });
        return openDialog ? null : super.open(template, config);
    }
}
