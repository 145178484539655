import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Loan } from '../models/loan';
import { LoanRequest } from '../models/loan-request';

@Injectable({
    providedIn: 'root'
})
export class LoanFormService {

    constructor(private formBuilder: UntypedFormBuilder) {}

    initializeForm(loan: Loan): UntypedFormGroup {
        const form = this.formBuilder.group({
            idCtrl: new UntypedFormControl(),
            createdCtrl: new UntypedFormControl(),
            memberIdCtrl: new UntypedFormControl(),
            lenderIdCtrl: new UntypedFormControl(),
            nameCtrl: new UntypedFormControl(),
            statusCtrl: new UntypedFormControl(),
            amountCtrl: new UntypedFormControl('', Validators.min(100)),
            interestRateCtrl: new UntypedFormControl('', Validators.max(100)),
            recurringPaymentAmountCtrl: new UntypedFormControl(),
            paymentStartDateCtrl: new UntypedFormControl(),
            paymentFrequencyCtrl: new UntypedFormControl(),
            maturityCtrl: new UntypedFormControl(),
            viewAccessCtrl: new UntypedFormControl(),
            acknowledgedCtrl: new UntypedFormControl(false)
        });
        if (loan) {
            form.patchValue({
                idCtrl: loan.id,
                createdCtrl: loan.created,
                memberIdCtrl: loan.memberId,
                lenderIdCtrl: loan.lenderId,
                nameCtrl: loan.name,
                statusCtrl: loan.status,
                amountCtrl: loan.amount,
                interestRateCtrl: loan.interestRate,
                recurringPaymentAmountCtrl: loan.recurringPaymentAmount,
                paymentStartDateCtrl: loan.paymentStartDate,
                paymentFrequencyCtrl: loan.paymentFrequency,
                maturityCtrl: loan.maturity,
                viewAccessCtrl: loan.financialDataAccess,
                acknowledgedCtrl: false

            });
        }
        return form;
    }

    getLoanRequest(form: UntypedFormGroup): LoanRequest {
        const loanRequest = new LoanRequest();
        loanRequest.status = form.get('statusCtrl').value;
        loanRequest.amount = form.get('amountCtrl').value;
        loanRequest.interestRate = form.get('interestRateCtrl').value;
        loanRequest.recurringPaymentAmount = form.get('recurringPaymentAmountCtrl').value;
        loanRequest.paymentFrequency = form.get('paymentFrequencyCtrl').value;
        return loanRequest;
    }
}
