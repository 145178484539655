<div class="row mb-3">
    <div class="col-12">
        <p class="form-title">Member Overview</p>
        <div class="member_type row">
            <div class="col-12" *ngIf="member">
                <dl class="row">
                    <dt class="col-sm-6 col-lg-6">Member Type:</dt>
                    <dd class="col-6">{{ member.memberRegistrationType | translate }}</dd>
                </dl>
                <dl class="row">
                    <dt class="col-sm-6 col-lg-6">Member Since:</dt>
                    <dd class="col-6">{{ member.approvedDate | date:'mediumDate' }}</dd>
                </dl>
                <dl class="row">
                    <dt class="col-sm-6 col-lg-6">Referral Code:</dt>
                    <dd class="col-6">{{ member.referralCode }}</dd>
                </dl>
            </div>
            <div class="col-12">
                <p class="form-title">Member Agreements</p>
            </div>
            <div class="col-6">
                <div class="terms-link mb-2">
                    <a href="/static/Confia_Financial_Client_Agreement_04-2024.pdf" target="_blank" rel="noopener noreferrer"><i class="fa fa-paperclip"></i>Confia Financial Member Account Terms and Conditions</a>
                </div>
                <div class="terms-link mb-2">
                    <a href="https://www.confia.fi/online-privacy-policy/" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-external-link"></i> Confia Financial Privacy Policy</a>
                </div>
                <div class="terms-link mb-2">
                    <a href="https://confia.fi/mobile-end-user-license-agreement/" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-external-link"></i> Mobile End User License Agreement</a>
                </div>
            </div>
            <div class="col-6">
                <div class="terms-link mb-2">
                    <a href="https://www.confia.fi/platform-use/" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-external-link"></i> Confia Financial Platform Use Agreement</a>
                </div>
                <div class="terms-link mb-2">
                    <a href="https://www.confia.fi/privacy-notice/" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-external-link"></i> Confia Financial Privacy Notice</a>
                </div>
                <div class="terms-link mb-2">
                    <a href="/static/Remote_Deposit_Terms_04-2024.pdf" target="_blank" rel="noopener noreferrer" class="small"><i class="fa fa-paperclip"></i> Remote Deposit Capture</a>
                </div>
            </div>
        </div>
    </div>
</div>
