<div>
    <form>
        <div>
            <div class="modal-header">
                <label class="form-title">Approve Access</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <p>{{lenderUserName + ' from ' + lenderName + ' requested approval access for the following dates.'}}</p>
                    </div>
                    <div class="col-12 justify-content-center">
                        <dl class="row">

                            <dt class="col-sm-4">Start Date:</dt>
                            <dd class="col-sm-8">{{requestAccess.startDate | date: 'medium'}}</dd>

                            <dt class="col-sm-4">End Date:</dt>
                            <dd class="col-sm-8">{{requestAccess.endDate | date: 'medium'}}</dd>

                            <dt class="col-sm-4">Note:</dt>
                            <dd class="col-sm-8">{{requestAccess.note}}</dd>
                        </dl>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="blue-button" (click)="decline()">Decline</button>
                <button class="blue-button" (click)="submit()">Approve</button>
            </div>

        </div>
    </form>
</div>
