import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AlertRoutingModule } from './alert-routing.module';
import { SharedComponentsModule } from 'projects/components/src/lib/shared-components.module';

import { HomeModule } from '../home/home.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedSmartContractsComponentsModule } from 'projects/smart-contract-components/src/public-api';
import { AuthorityAlertComponent, AlertDetailsComponent, AccountThresholdsComponent } from './components';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@NgModule({
    imports: [
        CommonModule,
        AlertRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        SharedComponentsModule,
        TranslateModule,
        HomeModule,
        NgbModule,
        MatProgressBarModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        SharedSmartContractsComponentsModule
    ],

    declarations: [
        AuthorityAlertComponent,
        AlertDetailsComponent,
        AccountThresholdsComponent
    ],

    exports: [
        AuthorityAlertComponent,
        AlertDetailsComponent
    ],

    providers: [
    ]
})
export class AlertModule {
}
