import { Component, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of as observableOf } from 'rxjs';
import { SmartContractService, ContractTemplate, ContractTemplateType, MemberService, Member } from 'projects/services/src/public-api';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'pc-sc-shared-templates-list',
    templateUrl: './shared-templates-list.component.html'
})
export class SharedTemplatesListComponent implements AfterViewInit, OnDestroy {

    ContractTemplateType = ContractTemplateType;

    contractTemplateList: ContractTemplate[];
    displayedColumns: string[] = ['name', 'created', 'creatorName', 'templateType', 'description'];
    resultsLength = 0;
    isLoadingResults = true;
    subscription: any;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private router: Router,
                private smartContractService: SmartContractService,
                private memberService: MemberService) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.smartContractService.getSharedTemplates(this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((data: any) => {
                this.isLoadingResults = false;
                this.resultsLength = data.content.length || 0;
                return data.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((data) => {
            this.contractTemplateList = data;
            this.contractTemplateList.forEach((template) => {
                this.memberService.loadMember(template.creator).subscribe((member: Member) => {
                    template.creatorName = member.name;
                });
            });
        });
    }

    viewTemplate(contractTemplate: ContractTemplate) {
        this.router.navigate(['/smart_contracts/shared_template/', contractTemplate.id]);
    }
}
