<div>
    <div class="modal-header">
        <div class="row no-gutters col-12">
            <div class="col-1 pull-left">
                <img class="dashboard-icon" src="assets/images/account-2.svg" alt="">
            </div>
            <div class="col-10">
                <label class="form-title">Currency Withdrawal from Account: {{memberAccount.accountName}}</label>
                <div class="row mt-1">
                    <div class="col-6 centered" [ngClass]="errorMessage ? 'error' : ''">Available Balance:<strong>&nbsp;{{memberAccount.wallet.availableBalance | currency}}</strong></div>
                    <div class="col-6 centered" [ngClass]="errorMessage ? 'error' : ''">Currency Withdrawal Total:<strong>&nbsp;{{(transactionTotal ? transactionTotal : '0.00') | currency}}</strong></div>
                </div>
            </div>
            <div class="col-1 pull-right"><i class="modal-close" (click)="close()">&times;</i></div>
        </div>
        <div class="row no-gutters col-12">
            <pc-error-message [error]="errorMessage" [width]="'full'"></pc-error-message>
        </div>
    </div>
    <div class="modal-content mt-3">
        <div class="container">
            <div class="row">
                <div class="col">
                    <pc-text-input [required]="true" [label]="'From Account'" [placeholder]="'From Account'"
                        [formCtrl]="'memberAccountNameCtrl'" [formGroup]="currencyWithdrawalForm">
                    </pc-text-input>
                    <pc-form-amount-input [required]="true" [label]="'Total Amount:'"  [formCtrl]="'amountCtrl'" [placeholder]="'0.00'" [formGroup]="currencyWithdrawalForm" [amountAlignmentRight]="true" (amountChanged)="onTotalChange($event)"></pc-form-amount-input>
                    <pc-form-date-picker [defaultEmpty]="true" [label]="'Cash Drop-off Date'" [formCtrl]="'cashDropOffDateCtrl'" [formGroup]="currencyWithdrawalForm"
                        [excludeWeekends]="true" [excludedDates]="disabledDates" (dateSelected)="dateSelected($event)">
                    </pc-form-date-picker>
                    <div>
                        <fieldset class="form-group">
                            <label class="form-label">Cash Drop-off Location</label>
                            <div [formGroup]="currencyWithdrawalForm">
                                <select class="form-control" [formControlName]="'cashDropOffLocationIdCtrl'" [required]="true" type="text"
                                    (change)="initializeCurrencyWithdrawalDetails()">
                                    <option value="" disabled selected></option>
                                    <option *ngFor="let address of addresses" [value]="address.id">{{address.streetAddressOne}}, {{address.city}}, {{address.stateProvince}}</option>
                                </select>
                            </div>
                        </fieldset>
                    </div>
                    <pc-form-text-area [label]="'Notes'" [formCtrl]="'notesCtrl'" [formGroup]="currencyWithdrawalForm" [placeholder]="'Please add in currency denomination requirement details here'">
                    </pc-form-text-area>
                </div>
                <div class="col">
                    <div class="form-label mb-2">Currency Withdrawal Summary:</div>
                    <table mat-table [dataSource]="currencyWithdrawalDetails" class="mb-3">
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef class="centered"> Cash Drop-off Date </th>
                            <td mat-cell *matCellDef="let element" class="centered">{{element.dropOffDate | date:'mediumDate'}}</td>
                        </ng-container>
                        <ng-container matColumnDef="location">
                            <th mat-header-cell *matHeaderCellDef> Cash Drop-off Location </th>
                            <td mat-cell *matCellDef="let element">{{getCashDropOffLocation(element.dropOffLocationId)}}</td>
                        </ng-container>
                        <ng-container matColumnDef="amount">
                            <th mat-header-cell class="centered" *matHeaderCellDef> Total Amount </th>
                            <td mat-cell class="centered" *matCellDef="let element">
                                <span class="table-amount">
                                    {{element.totalAmount | currency}}
                                </span>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="transactionDetailsColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: transactionDetailsColumns;"></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <pc-submit-button [submit]="submit" [enabled]="isFormValid" [text]="'Submit'"></pc-submit-button>
    </div>
    <footer class="centered m-3 text-dark">
        Client services will be in contact to verify the drop-off and the currencies required.
    </footer>
</div>
