import { MemberAccount } from './member-account';
import { Member } from './member';

export class ConnectedMember {

    id: string;
    created: Date;

    partnerId: string;
    partner: Member;

    memberId: string;
    member: Member;

    memberAccountId: string;
    memberAccount: MemberAccount;

    status: ConnectedMemberStatus;

}

export enum ConnectedMemberStatus {
    ALL = '',
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED'
}
