<form *ngIf="memberForm" [formGroup]="memberForm">
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
            <h5>Member Information</h5>
            <pc-text-input [required]="true" [label]="'Member Name'" [placeholder]="'Member Name'"
                [formCtrl]="'nameCtrl'" [formGroup]="memberForm">
            </pc-text-input>
            <pc-text-input [label]="'DBA Name'" [placeholder]="'DBA Name'"
                [formCtrl]="'dbaNameCtrl'" [formGroup]="memberForm">
            </pc-text-input>
            <pc-text-option-selector [required]="true" [label]="'Business Type'"
                [options]="businessTypeOpts" [formCtrl]="'businessTypeCtrl'" [formGroup]="memberForm" [defaultValue]="''">
            </pc-text-option-selector>
            <pc-phone-input [required]="true" [label]="'Phone Number'"
                [formCtrl]="'officePhoneCtrl'" [formGroup]="memberForm">
            </pc-phone-input>
            <pc-email-input [label]="'Email Address'" [placeholder]="'Email Address'"
                [formCtrl]="'emailCtrl'" [formGroup]="memberForm">
            </pc-email-input>
            <pc-text-input [label]="'Company URL'" [placeholder]="'Company URL'"
                [formCtrl]="'websiteCtrl'" [formGroup]="memberForm">
            </pc-text-input>
            <pc-form-text-area [required]="true" [label]="'Brief Description of the Business'" [formCtrl]="'briefDescriptionCtrl'" [formGroup]="memberForm">
            </pc-form-text-area>
            <div class="mt-3">
                <pc-submit-button [enabled]="formEnabled" [submit]="onSaveUpdates" [text]="'Update'"></pc-submit-button>
            </div>
        </div>
        <div class="col-sm-6">
            <h5>Business Logo</h5>
            <div>
                <label class="form-label">Upload a memberAccount logo to be shown to other users when they do business with you:</label>
                <div class="row d-flex flex-wrap align-items-center">
                    <div class="col-sm-6">
                        <div class="col-xs-2 text-center" *ngIf="show && !imageAvailable && !imagePreview">
                            <label class="form-label background-logo-default">150 x 150</label>
                        </div>
                        <div class="col-xs-2 text-center" *ngIf="imagePreview">
                            <label class="form-label">Preview</label>
                        </div>
                        <div class="col-xs-2 text-center" *ngIf="!show">
                            <label class="form-label">Final Logo</label>
                        </div>
                        <pc-secure-image [imgSrc]="imageSrc" *ngIf="imageSrc" alt="Logo" [class]="'img-icon-small'" (imageAvailable)="updateImageAvailability($event)"></pc-secure-image>
                    </div>
                    <div class="col-sm-12">
                        <label class="form-label">Guidelines:
                        </label>
                        <mat-list dense>
                            <mat-list-item class="form-label"> 1. Please upload a PNG or JPEG format logo. </mat-list-item>
                            <mat-list-item class="form-label"> 2. Please limit to 250kb. </mat-list-item>
                            <mat-list-item class="form-label"> 3. The logo will be shown in white background. </mat-list-item>
                            <mat-list-item class="form-label"> 4. The logo will be scaled to 150x150px </mat-list-item>
                        </mat-list>
                    </div>
                </div>
                <div *ngIf="errorSize" class="alert alert-danger">
                    <div>Logo image must be less than 250 kb.</div>
                </div>
                <div *ngIf="success" class="alert alert-info">
                    <div>Logo image successfully uploaded!</div>
                </div>
                <button class="blue-button pull-right" type="button" (click)="upload.click()">Select File</button>
                <input hidden type="file" id="file" #upload (change)="processFile(upload)" placeholder="Upload image"
                       accept="image/*">
                <pc-submit-button [enabled]="logoFormEnabled" [submit]="submit" [text]="'Upload Logo'"></pc-submit-button>
            </div>
        </div>
    </div>
</form>
