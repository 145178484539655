import { ExpenseLine } from './expense-line';
import { Address } from './address';
import { SupportingDocument } from './supporting-document';

export class Invoice {

    id: string;
    created: Date;
    updated: Date;
    source: string;
    systemType: string;
    type: string;
    description: string;

    externalId: string;
    memberId: string;
    memberAccountId: string;

    reasonCode: string;
    status: string;

    submittedAmount: string;
    paidAmount: string;
    unpaidAmount: number;
    totalAmount: number;

    txnDate: Date;
    dueDate: Date;
    lineItems: ExpenseLine[];

    externalReference: string;

    customerName: string;
    customerExternalId: string;
    customerEmail: string;
    invoiceDate: Date;
    shipDate: Date;
    trackingNumber: number;

    billAddress: Address;
    shipAddress: Address;

    memberName: string;
    supportingDocuments: SupportingDocument[] = [];
    lineItemId: string;
    synced: boolean;
}
