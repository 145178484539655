import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewNoteModalComponent, InfoModalComponent } from 'projects/components/src/lib/modal';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import {
    AuthService, CommentService, OnboardingTrackingService, CommentType, MemberService, Member, MemberStatus
} from 'projects/services/src/public-api';

@Component({
    selector: 'pt-onboarding-notes',
    templateUrl: './onboarding-notes.component.html'
})
export class OnboardingNotesComponent implements OnInit {

    CommentType = CommentType;
    MemberStatus = MemberStatus;

    @Input() memberId: string;
    @Input() task: any;
    @Input() applicationComplete = false;
    @Input() changeTrigger: number;

    @Output() notesDetailChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() next: EventEmitter<string> = new EventEmitter<string>();

    comments: number;
    registrationStatus: MemberStatus;

    constructor(public authService: AuthService,
                private memberService: MemberService,
                private commentService: CommentService,
                private dialog: MatDialog) {}

    ngOnInit() {
        this.onNavigate = this.onNavigate.bind(this);
        this.initializeComments();
    }

    onNavigate() {
        this.next.emit();
    }

    onAddNote() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            memberId: this.memberId,
            type: this.isAuthority() ? CommentType.QUESTION : CommentType.USER
        };
        const dialog = this.dialog.open(NewNoteModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (notifyUser: boolean) => {
                if (notifyUser) {
                    // this should be replaced with the snackbar notification work later
                    const infoConfig: MatDialogConfig = {};
                    infoConfig.panelClass = 'normal-modal';

                    infoConfig.data = {
                        message: 'Your response will be reviewed within 1 business day.'
                    };
                    this.dialog.open(InfoModalComponent, infoConfig);

                }
                this.refreshNotes();
            }
        );
    }

    refreshNotes() {
        this.initializeComments();
    }

    notifyOfNotesChange(){
        if (this.isAuthority()){
            this.initializeComments();
        }
    }

    initializeComments(){
        // The logic in this method needs to be fixed.
        this.commentService.countOpenComments(this.memberId, CommentType.QUESTION).subscribe(
            (response: any) => {
                const commentNumber = response.headers.get('Content-Length');
                OnboardingTrackingService.setQuestionsValid(Number(commentNumber));
                if (commentNumber > 0) {
                    if (this.comments && !this.isAuthority()) {
                        if (this.comments < commentNumber){
                            // they have answered at least one question
                            OnboardingTrackingService.setQuestionsValid(0);
                        }
                    }
                }

                this.comments = commentNumber;
                this.changeTrigger = Math.random();
            }
        );
        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            this.registrationStatus = member.status;
        });
    }

    isAuthority() {
        return this.authService.isAuthority();
    }

    isOnboarding() {
        return this.memberId === this.authService.getProfile().memberId && this.task && this.task === 'onboard';
    }

    allowResponse() {
        if (this.isAuthority()) {
            return false;
        }
        return this.comments > 0;
    }

}
