<div>
    <form [formGroup]="extraSearchForm">
        <div class="modal-header">
            <label class="form-title">Extra Compliance Search</label>
            <i class="modal-close" (click)="close()">&times;</i>
        </div>

        <div class="modal-content-small">
            <div class="row">
                <div class="col-12">
                    <pc-auto-complete-selector [label]="'Subject Entity'" [formCtrl]="'keyPartyCtrl'" [formGroup]="extraSearchForm"
                        [options]="keyParties" [displayValue]="'name'" (selected)="onKeyPartySelected($event)">
                    </pc-auto-complete-selector>
                </div>
            </div>

            <div class="row mt-4">
                <pc-text-area [required]="true" [label]="'Search Terms'" [formCtrl]="'searchTerm'" [formGroup]="extraSearchForm">
                </pc-text-area>
            </div>
        </div>

        <div class="modal-footer mt-4">
            <pc-submit-button [text]="'RUN SEARCH'"
                [submit]="onRunSearch"
                [enabled]="isSubmitEnabled">
            </pc-submit-button>
        </div>
    </form>
</div>
