import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
    WhitelistRelationshipParty, WhitelistRelationshipPartyService, PagedResponse, AuthService, MemberService, Member
} from 'projects/services/src/public-api';
import { AddWhitelistRelationshipPartyModalComponent } from 'projects/pt/src/app/components';
import { Component, Input, OnInit, EventEmitter, AfterViewInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pt-whitelist-relationship-party-details',
    templateUrl: './whitelist-relationship-party-details.component.html'
})
export class WhitelistRelationshipPartyDetailsComponent implements OnInit, AfterViewInit, OnDestroy  {

    @Input() memberId: string;

    isLoadingResults = true;
    resultsLength = 0;
    subscription: any;
    member: Member;
    pageTracking: PageTracking;
    whitelistedParties: WhitelistRelationshipParty[] = [];
    refreshEvent: EventEmitter<null> = new EventEmitter<null>();
    displayedColumns: string[] = ['member', 'whiteListedTransactionType', 'maximumTransactionAmount', 'maximumMonthlyNumberOfTransactions', 'note'];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private whitelistRelationshipPartyService : WhitelistRelationshipPartyService,
                private notifier: NotificationService,
                private route: ActivatedRoute,
                private router: Router,
                public authService: AuthService,
                private cdr: ChangeDetectorRef,
                private memberService: MemberService,
                private dialog: MatDialog) {}


    ngOnInit() {
        if (this.authService.isAuthority()) {
            this.displayedColumns = ['member', 'whiteListedTransactionType', 'maximumTransactionAmount', 'maximumMonthlyNumberOfTransactions', 'note', 'action'];
        }
        this.loadMember();
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'created', 'desc');
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addWhiteListParty() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            member:  this.member
        };
        const dialog = this.dialog.open(AddWhitelistRelationshipPartyModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((saved: boolean) => {
            if (saved) {
                this.refresh();
            }
        });
    }

    removeWhitelistedParty(whitelistedParty: WhitelistRelationshipParty) {
        this.whitelistRelationshipPartyService.deleteWhitelistParty(whitelistedParty.id).subscribe(() => {
            this.notifier.showSuccess('Successfully deleted whitelisted relationship party</b>.');
            this.refresh();
        }, (error: any) => {
            throw error;
        });
    }

    loadMember() {
        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            this.member = member;
        });
    }

    refresh() {
        this.refreshEvent.emit();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.whitelistRelationshipPartyService.getWhitelistedMembers(this.memberId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<WhitelistRelationshipParty>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((whitelistRelationshipParties: WhitelistRelationshipParty[]) => {
            this.whitelistedParties = whitelistRelationshipParties;
            UIUtils.scrollDashboardToTop();
        });
    }
}
