<div>
    <form [formGroup]="passwordForm">
        <div>
            <div class="modal-header">
                <label class="form-title">{{passwordReset ? 'Set New Password' : 'Create Password'}}</label>
                <i class="modal-close" (click)="close('CLOSED_BY_USER')">&times;</i>
            </div>
            <div class="modal-content">
                <div class="password-overview">
                    <span>Passwords must have a length of 8 characters or more, and must include at least one of each of the following:</span>
                </div>
                <div>
                    <ul>
                        <li [ngClass]="validUppercase() ? 'valid' : 'invalid'"><i class="fa" [ngClass]="validUppercase() ? 'fa-check' : 'fa-times'"></i>uppercase character</li>
                        <li [ngClass]="validLowercase() ? 'valid' : 'invalid'"><i class="fa" [ngClass]="validLowercase() ? 'fa-check' : 'fa-times'"></i>lowercase character</li>
                        <li [ngClass]="validNumber() ? 'valid' : 'invalid'"><i class="fa" [ngClass]="validNumber() ? 'fa-check' : 'fa-times'"></i>number</li>
                        <li [ngClass]="validSpecial() ? 'valid' : 'invalid'"><i class="fa" [ngClass]="validSpecial() ? 'fa-check' : 'fa-times'"></i>special character ^ $ * . [ ] {{ '{' }} {{ '}' }} ( ) ? " ! @ # % & / \ , &gt; &lt; ' : ; | _ ~</li>
                    </ul>
                </div>
                <pc-form-selector *ngIf="isMfaEnabled() && passwordReset && mfaType !== MfaType.NONE" [required]="true" [label]="'Two Factor Authentication'" [formCtrl]="'mfaCtrl'"
                    [formGroup]="passwordForm" [options]="MFA_TYPES" [idValue]="'id'" [displayValue]="'name'" [defaultValue]="''">
                </pc-form-selector>
                <pc-password-input [required]="true" [label]="'New Password'" [formCtrl]="'passwordOneCtrl'" [formGroup]="passwordForm">
                </pc-password-input>
                <pc-password-input [required]="true" [label]="'Confirm New Password'" [formCtrl]="'passwordTwoCtrl'" [formGroup]="passwordForm">
                </pc-password-input>
                <div *ngIf="passwordMismatch()">
                    <label class="form-error">Passwords must match</label>
                </div>
            </div>
            <div *ngIf="errorMessage" class="error-message">
                <label class="form-error">{{errorMessage}}</label>
            </div>
            <div class="modal-footer">
                <pc-submit-button *ngIf="!linkExpired" [enabled]="isFormValid" [submit]="onRegister" [text]="passwordReset ? 'Set Password' : 'Register'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
