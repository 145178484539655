import { Component, Input, OnInit, AfterViewInit, ViewChild, OnChanges, SimpleChanges, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
    Rfi, PaymentService, Transaction, RfiService, PagedResponse, RfiStatus, CheckDetails, AuthService, TransactionType, MemberService
} from 'projects/services/src/public-api';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pc-rfi-list',
    templateUrl: './rfi-list.component.html',
    styleUrls: ['rfi-list.component.scss']
})
export class RfiListComponent implements  OnInit, AfterViewInit, OnChanges, OnDestroy {

    TransactionType = TransactionType;

    @Input() rfiStatus: RfiStatus;
    @Input() memberId: string;
    @Input() types: TransactionType[] = [TransactionType.ALL];
    @Input() memberPageView = false;

    isLoadingResults = true;
    displayedColumns: string[] = ['type', 'status', 'created', 'updated', 'check', 'transaction_date', 'amount', 'payor', 'recipient'];
    pageTracking: PageTracking;
    resultsLength = 0;
    rfis: Rfi[] = [];
    subscription: any;
    filterEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private router: Router,
                private rfiService: RfiService,
                public authService: AuthService,
                private route: ActivatedRoute,
                private paymentService: PaymentService,
                private memberService: MemberService,
                private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        if (!this.authService.isAuthorityOrReviewer()) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('type'), 1);
        }
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'updated', 'desc', 100);
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.memberId && !changes.memberId.firstChange) || (changes.rfiStatus && !changes.rfiStatus.firstChange) || (changes.types && !changes.types.firstChange)) {
            this.filterEvent.emit();
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    loadRfiDetails(rfiId: string, event: any) {
        if (event.target.tagName !== 'A') {
            const location = window.location.pathname + window.location.search;
            this.router.navigate(['/rfi/details/', rfiId], { state: { returnLocation: location } });
        }
    }

    loadRfi(rfi: Rfi) {
        if (rfi?.transaction) {
            this.paymentService.loadTransaction(rfi.transaction);
            if (rfi.transaction.checkId) {
                this.paymentService.getCheckDetailsById(rfi.transaction.checkId).subscribe((check: CheckDetails) => {
                    rfi.transaction.checkNumber = check.checkNumber;
                });
            }
        }
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                UIUtils.scrollToTop(document.querySelector('.mat-table-container'));
                this.isLoadingResults = true;
                return this.rfiService.getInvolvedRfisByStatus(this.memberId, this.rfiStatus, this.types, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<Rfi>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((rfis: Rfi[]) => {
            this.rfis = rfis;
            for (const rfi of this.rfis) {
                this.loadRfi(rfi);
            }
        });
    }

    navigateToMember(memberId: string) {
        this.memberService.navigateToMember(memberId);
    }

    isPriority(rfi: Rfi) {
        return this.authService.isAuthorityOrReviewer() && (rfi?.transaction?.transactionType === TransactionType.WALLET_TO_EXPRESS_ACH_PAYMENT
            || rfi?.transaction?.transactionType === TransactionType.OUTGOING_WIRE_TO_MERCHANT);
    }
}
