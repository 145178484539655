<div>
    <div class="modal-header">
        <label class="form-title">Search Result</label>
        <i class="modal-close" (click)="close()">&times;</i>
    </div>
    <div class="modal-content container">
        <div class="row">
            <h4>Details</h4>
            <div class="row my-1">
                <div class="col-2">
                    <label class="form-label">Strict:</label>
                </div>
                <div class="col">
                    <pc-boolean-badge class="mx-2" [parent]="search.strict"></pc-boolean-badge>
                </div>
            </div>
            <div class="row my-1">
                <div class="col-2">
                    <label class="form-label">Exact Match:</label>
                </div>
                <div class="col">
                    <pc-boolean-badge class="mx-2" [parent]="search.exactMatch"></pc-boolean-badge>
                </div>
            </div>
            <div class="col-12 my-1">
                <label class="form-label">Referenced Entity:</label>
                <span class="mx-2">{{keyParty.name}}</span>
            </div>
            <div class="col-12 my-1">
                <label class="form-label">Search Term:</label>
                <span class="mx-2">{{search.searchTerm}}</span>
            </div>
            <div class="col-12 my-1">
                <label class="form-label">Search Ref:</label>
                <a class="button-link" href="{{search.searchUrl}}" target="_blank"> {{search.searchRef}} </a>
            </div>
            <div class="col-12 my-1" *ngIf="isIndividualKeyParty">
                <label class="form-label">Birth Year:</label>
                <span class="mx-2">{{search.birthYear}}</span>
            </div>
            <div class="col-12 my-1">
                <label class="form-label">Countries:</label>
                <span class="mx-2">{{search.countries}}</span>
            </div>
            <div class="col-12 my-1">
                <label class="form-label">Created:</label>
                <span class="mx-2">{{search.created | date: 'medium'}}</span>
            </div>
            <div class="col-12 my-1">
                <label class="form-label">Updated:</label>
                <span class="mx-2">{{search.updated | date:'medium'}}</span>
            </div>
        </div>

        <mat-divider class="my-4"></mat-divider>

        <div class="row">
            <h4>Initial Hits</h4>
            <div class="col-12 my-1">
                <label class="form-label">Total:</label>
                <span class="mx-2">{{search.totalHits}}</span>
            </div>
            <div class="col-12 my-1">
                <label class="form-label">Matches:</label>
                <span class="mx-2">{{search.totalMatches}}</span>
            </div>
        </div>

        <mat-divider class="my-4"></mat-divider>

        <h4>Initial Risk</h4>
        <div class="row my-1">
            <div class="col-2">
                <label class="form-label">Match Status:</label>
            </div>
            <div class="col">
                <pc-search-match-status-badge class="mx-2" [parent]="search.matchStatus"></pc-search-match-status-badge>
            </div>
        </div>
        <div class="row my-1">
            <div class="col-2">
                <label class="form-label">Risk Level:</label>
            </div>
            <div class="col">
                <pc-search-risk-level-badge class="mx-2" [parent]="search.riskLevel"></pc-search-risk-level-badge>
            </div>
        </div>
    </div>

    <mat-divider class="my-4"></mat-divider>

    <div class="modal-footer">
        <pc-submit-button [submit]="onToggleMonitoring" [enabled]="isToggleMonitoringEnabled" [text]="monitoringToggleBtnText"></pc-submit-button>
    </div>
</div>
