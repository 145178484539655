<div class="col-12 dashboard-info">
    <div class="row">
        <div class="col-2 dashboard-header">
            <div class="dashboard-header">
                <img class="dashboard-icon" src="assets/images/files-icon-2.svg" alt="">
                <span>Templates</span>
            </div>
            <div class="tab-col pull-left">
                <div *ngIf="contractTemplate?.visibility === ContractTemplateVisibility.DRAFT" [ngClass]="getTabClass('DESIGN_TEMPLATE')" (click)="setActiveTab('DESIGN_TEMPLATE')">
                    Design Template
                </div>
                <div *ngIf="contractTemplate?.visibility === ContractTemplateVisibility.DRAFT" [ngClass]="getTabClass('EDIT_TERMS')" (click)="setActiveTab('EDIT_TERMS')">
                    Edit Terms
                </div>
                <div [ngClass]="getTabClass('PREVIEW_TEMPLATE')" (click)="setActiveTab('PREVIEW_TEMPLATE')">
                    Preview Template
                </div>
                <div *ngIf="contractTemplate?.visibility === ContractTemplateVisibility.SHARING" [ngClass]="getTabClass('SHARE_TEMPLATE')" (click)="setActiveTab('SHARE_TEMPLATE')">
                    Share Template
                </div>
                <div [ngClass]="getTabClass('DELETE_TEMPLATE')" (click)="deleteTemplate()">
                    Delete Template
                </div>
            </div>
        </div>
        <div class="col-10">
            <div class="col-12 tab-body" *ngIf="activeTab === 'DESIGN_TEMPLATE'">
                <pc-sc-select-template-terms [task]="task" (taskChanged)="updateTask($event)"></pc-sc-select-template-terms>
            </div>
            <div class="col-12 tab-body" *ngIf="activeTab === 'EDIT_TERMS'">
                <pc-sc-edit-template-terms [task]="task" (taskChanged)="updateTask($event)"></pc-sc-edit-template-terms>
            </div>
            <div *ngIf="activeTab === 'PREVIEW_TEMPLATE'">
                <pc-sc-preview-base-template [task]="task" (taskChanged)="updateTask($event)" (shareTemplate)="shareTemplate()"></pc-sc-preview-base-template>
            </div>
            <div class="col-12 tab-body" *ngIf="activeTab === 'SHARE_TEMPLATE'">
                <pc-sc-invite-business-parties [task]="task" (taskChanged)="updateTask($event)"></pc-sc-invite-business-parties>
            </div>
        </div>
    </div>
    <router-outlet></router-outlet>
</div>
