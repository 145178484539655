import { Component, OnInit, Input, OnChanges, ChangeDetectorRef, AfterViewInit, ViewChild, SimpleChanges, OnDestroy, EventEmitter } from '@angular/core';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { Invoice, RecordsService, PagedResponse, MetrcSummary, Receipt, MemberService, Member } from 'projects/services/src/public-api';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
@Component({
    selector: 'pt-member-metrc-receipt-list',
    templateUrl: './member-metrc-receipt-list.component.html',
    styleUrls: ['./member-metrc-receipt-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class MemberMetrcReceiptListComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    @Input() memberId: string;
    @Input() startDate: string;
    @Input() endDate: string;

    receipts : Receipt[] = [];
    expandedElement: Invoice;
    displayedColumns = ['id', 'supplier', 'total_amount', 'created', 'txn_date'];
    receiptsSummaryList: MetrcSummary[];
    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    subscription: any;
    pageTracking: PageTracking;
    isLoadingResults = true;
    resultsLength = 0;
    totalReceipts = 0;
    loadSummary = true;

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private recordsService: RecordsService,
                private route: ActivatedRoute,
                private router: Router,
                private memberService: MemberService,
                private cdr: ChangeDetectorRef) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(change: SimpleChanges) {
        if ((change.startDate && !change.startDate.firstChange) || (change.endDate && !change.endDate.firstChange)) {
            this.loadSummary = true;
            this.refreshEvent.emit();
        }
    }

    ngOnInit() {
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'txnDate', 'desc');
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.recordsService.findMetrcReceiptsByOwner(this.memberId, this.startDate, this.endDate, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<Receipt>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((receipts: Receipt[]) => {
            this.receipts =  receipts;
            for (const receipt of this.receipts) {
                this.loadReceipts(receipt);
            }
            if (this.resultsLength >= 0 && this.loadSummary) {
                this.loadReceiptsSummary(this.resultsLength);
            }
            UIUtils.scrollDashboardToTop();
        });
    }

    loadReceiptsSummary(resultsLength :number) {
        this.recordsService.findMetrcReceiptsByOwnerSummary(this.memberId, this.startDate, this.endDate, resultsLength).subscribe((response: any) => {
            const categories = Object.keys(response);
            this.totalReceipts = response['ReceiptsTotalAmount'];
            delete response['ReceiptsTotalAmount'];
            this.receiptsSummaryList = this.recordsService.getMetrcSummary(response, categories);
            this.loadSummary = false;
        });
    }

    loadReceipts(receipt: Receipt) {
        this.memberService.loadMember(receipt.memberId).subscribe((member: Member) => {
            if (member) {
                receipt.memberName = member.name;
            }
        });
    }
}
