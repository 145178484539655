<div class="inlineExpanded">
    <div class="row">
        <div class="col-12 inner-column">
            <div class="row">
                <div class="col-1 expandedLabel">
                    Verified By
                </div>
                <div class="col-11 expandedValue">
                    {{ bankAccount?.verifiedBy }}
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-1 expandedLabel">
                    Verified Date
                </div>
                <div class="col-11 expandedValue">
                    {{ bankAccount?.verifiedDate | date:'mediumDate' }}
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-1 expandedLabel">
                    Notes
                </div>
                <div class="col-11 expandedValue" innerHtml="{{bankAccount?.reviewNotes}}"> 
                </div>
            </div>
        </div>
    </div>
</div>
