import { LendingKPI } from './lending/lending-KPI';

export class Scenario {
    id?: string;
    created?: Date;
    memberId: string;
    title: string;
    active: boolean;
    status: ScenarioStatus;
    kpis?: LendingKPI[];
}

export enum ScenarioStatus {

    ALL = '',
    ACTIVE = 'ACTIVE',
    DISABLE = 'DISABLE'

}
