import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, SidebarTrackingService, Profile, CountNotificationService, CustomValidators } from 'projects/services/src/public-api';
import { MemberSwitchModalComponent } from 'projects/components/src/lib/modal';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'pc-authority-header',
    templateUrl: './authority-header.component.html',
    styleUrls: ['./authority-header.component.scss']
})
export class AuthorityHeaderComponent implements OnInit {

    @Input() expandedMenu: boolean;

    @Output() toggleExpandSideMenu: EventEmitter<any> = new EventEmitter<any>();

    profile: Profile;
    formGroup: UntypedFormGroup;

    constructor(public authService: AuthService,
                private router: Router,
                private route: ActivatedRoute,
                private dialog: MatDialog,
                private countNotificationService: CountNotificationService,
                private formBuilder: UntypedFormBuilder) {
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            entitySearchCtrl: new UntypedFormControl('', [CustomValidators.searchTermValidation])
        });
        this.profile = this.authService.getProfile();
    }

    getMenuClass() {
        if (this.expandedMenu) {
            return 'top-header-icon-wide';
        } else {
            return 'top-header-icon-narrow';
        }
    }

    getBodyClass() {
        if (this.expandedMenu) {
            return 'top-header-body-narrow';
        } else {
            return 'top-header-body-wide';
        }
    }

    onToggleExpandSideMenu() {
        this.toggleExpandSideMenu.emit('toggle');
    }

    onLoggedout() {
        this.authService.logout();
    }

    isActive(item: string) {
        return SidebarTrackingService.getActiveNavItem() === item;
    }

    isMultiMemberUser() {
        return this.authService.getProfile().multiMember;
    }

    switchOrg() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.disableClose = true;
        this.dialog.open(MemberSwitchModalComponent, dialogConfig);

    }

    getUnreadMessageCount() {
        return this.countNotificationService.getUnreadMessageCount();
    }

    getOpenItemCount() {
        return this.countNotificationService.getOpenItemCount();
    }

    getMessageClass() {
        if (this.getUnreadMessageCount() > 0) {
            return 'fa fa-envelope';
        } else {
            return 'fa fa-envelope-o';
        }
    }

    isDisabled(link: string) {
        return this.router.url.indexOf(link) >= 0;

    }

    search(searchTerm: string) {
        if (!(!searchTerm || searchTerm?.match('^\\s+$'))) {
            const searchType = this.route.snapshot.queryParams['_searchType'] || 'MEMBER';
            this.router.navigate(['/administration/search'], { queryParams: {_searchType: searchType, searchTerm: searchTerm?.trim() }});
            this.formGroup.get('entitySearchCtrl').setValue('');
            this.formGroup.get('entitySearchCtrl').markAsPristine();
        }
    }
}
