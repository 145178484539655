import { Directive, HostListener, ElementRef } from '@angular/core';

const DISABLE_TIME = 500;

@Directive({
    selector: 'button[pc-no-dbl-click]'
})
export class NoDoubleClickDirective {

    constructor(private elementRef: ElementRef) { }

    @HostListener('click', ['$event'])
    clickEvent() {
        const isSubmitType = this.elementRef.nativeElement.hasAttribute('submit-button');
        this.elementRef.nativeElement.setAttribute('disabled', 'true');
        setTimeout(() => {
            if (isSubmitType) {
                return this.elementRef.nativeElement.removeAttribute('disabled');
            }
        }, DISABLE_TIME);
    }
}
