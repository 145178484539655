import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { LandingSettings } from './models/landing-settings';
import { AccountToken } from './models/account-token';
import { RetailTransactionInitiationRequest } from './models/retail-customer-initiate-transaction-request';
import { RefundRequest } from '../public-api';

@Injectable({
    providedIn: 'root'
})
export class PartnerService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.PartnerAPIEndpoint + '/api/';
    }

    enablePartnerFeatures(memberId: string) {
        return this.http.put(`${this.baseUrl}partner/${memberId}`, null);
    }

    disablePartnerFeatures(memberId: string) {
        return this.http.delete(`${this.baseUrl}partner/${memberId}`);
    }

    updateCustomizationPageSettings(landingSettings: LandingSettings) {
        return this.http.put(`${this.baseUrl}landing`, landingSettings);
    }

    getCustomizationPageSettings(memberId: string) {
        return this.http.get(`${this.baseUrl}landing?memberId=${memberId}`);
    }

    getDefaultPageSettings() {
        return this.http.get(`${this.baseUrl}landing/default`);
    }

    refund(refundRequest: RefundRequest) {
        return this.http.post(`${this.baseUrl}transaction/refund`, refundRequest);
    }

    getImage(memberId: string) {
        return this.http.getDownload(`${this.baseUrl}landing/image?memberId=${memberId}`);
    }

    getConsumerTaskByPartnerId(partnerId: string, processInstanceId: string) {
        return this.http.get(`${this.baseUrl}workflow/partner/task?partnerId=${partnerId}&processInstanceId=${processInstanceId}`);
    }

    getConsumerTaskByConsumerId(consumerId: string, processInstanceId: string) {
        return this.http.get(`${this.baseUrl}workflow/consumer/task?consumerId=${consumerId}&processInstanceId=${processInstanceId}`);
    }

    getConsumerInstanceTaskVariables(partnerId: string, processInstanceId: string) {
        return this.http.get(`${this.baseUrl}workflow/process-instance?partnerId=${partnerId}&processInstanceId=${processInstanceId}`);
    }

    getConsumerAccessToken(consumerId: string) {
        return this.http.get(`${this.baseUrl}onboard/token?consumerId=${consumerId}`);
    }

    registerAccount(taskId: string, externalAccountToken: AccountToken) {
        return this.http.post(`${this.baseUrl}onboard/${taskId}`, externalAccountToken);
    }

    completeConsumerRegistrationTask(taskId: string, body: any) {
        return this.http.put(`${this.baseUrl}onboard/${taskId}`, body);
    }

    initiateRetailTransaction(request: RetailTransactionInitiationRequest) {
        return this.http.post(`${this.baseUrl}retail/transaction`, request);
    }

    verifyAndInitiateRetailTransaction(processInstanceId: string, request: any) {
        return this.http.post(`${this.baseUrl}retail/process/${processInstanceId}`, request);
    }

    getConsumerExpense(partnerId: string, consumerId: string, expenseId: string) {
        return this.http.get(`${this.baseUrl}workflow/expense?partnerId=${partnerId}&consumerId=${consumerId}&expenseId=${expenseId}`);
    }

    completeConsumerPaymentVerificationTask(taskId: string, body: any) {
        return this.http.post(`${this.baseUrl}workflow/${taskId}`, body);
    }

}
