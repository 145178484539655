import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
    WorkflowService, SmartContractService, PagedResponse, Contract, ContractTemplate, Task, ContractTemplateType, MemberService, Member
} from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { SmartContractFormService } from 'projects/smart-contract-components/src/lib';

@Component({
    selector: 'pc-sc-new-contract-modal',
    templateUrl: './new-contract-modal.component.html',
    styleUrls: ['./new-contract-modal.component.scss']
})
export class NewContractModalComponent extends BaseModalComponent<NewContractModalComponent> implements OnInit {

    ContractTemplateType = ContractTemplateType;

    contractForm: UntypedFormGroup;
    errorMessage: string;
    contractTemplates: ContractTemplate[] = [];
    contractTemplateId: string;
    selectedTemplate: ContractTemplate;
    contractsLoaded = false;

    constructor(private smartContractFormService: SmartContractFormService,
                private smartContractService: SmartContractService,
                private workflowService: WorkflowService,
                private memberService: MemberService,
                dialog: MatDialogRef<NewContractModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialog);
        this.contractTemplateId = data.contractTemplateId;
    }

    ngOnInit() {
        this.isContractFormValid = this.isContractFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.contractForm = this.smartContractFormService.newContractForm();
        this.smartContractService.getSharedTemplates(0, -1, 'name', 'ASC').subscribe((response: PagedResponse<ContractTemplate>) => {
            this.contractTemplates = response.content;
            this.contractsLoaded = true;
            if (this.contractTemplateId) {
                this.contractForm.controls['templateCtrl'].setValue(this.contractTemplateId);
                this.contractForm.get('templateCtrl').disable();
                this.selectTemplate();
            }
            this.contractTemplates.forEach((template) => {
                this.memberService.loadMember(template.creator).subscribe((member: Member) => {
                    template.creatorName = member.name;
                });
            });
        });
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    onSubmit() {
        this.errorMessage = null;
        const contract = new Contract();
        contract.name = this.contractForm.controls['nameCtrl'].value;
        contract.contractTemplateId = this.contractForm.controls['templateCtrl'].value;
        // contract.businessTax = this.contractForm.controls['businessTaxCtrl'].value;
        contract.salesTax = this.contractForm.controls['salesTaxCtrl'].value;
        this.workflowService.createContract(contract).subscribe((response: any) => {
            this.workflowService.getTaskByProcessInstanceId(response.id).subscribe((taskQueryResponse: PagedResponse<Task>) => {
                const taskId = taskQueryResponse.content[0].id;
                this.workflowService.loadTask(taskId);
                this.close(true);
            });
        });
    }

    isContractFormValid() {
        return this.contractForm.valid;
    }

    selectTemplate() {
        this.selectedTemplate = this.contractTemplates.find((template) => {
            return template.id === this.contractForm.controls['templateCtrl'].value;
        });
        this.contractForm.controls['nameCtrl'].setValue(this.selectedTemplate.name);
    }
}
