<div class="dashboard-info">
    <div class="row" *ngIf="member">
        <div class="col-12">
            <div class="row align-items-center">
                <div class="col-4 text-right" *ngIf="showLogo">
                    <div>
                        <pc-secure-image [imgSrc]="imageSrc" *ngIf="imageSrc" alt="Logo" class="img-icon-small" (imageAvailable)="updateImageAvailability($event)"></pc-secure-image>
                    </div>
                </div>
                <div class="col-4 text-center" *ngIf="!showLogo">
                    <div>
                        <label class="form-label background-logo-default">{{member.name}}</label>
                    </div>
                </div>
                <div class="col-8">
                    <div class="row">
                        <div class="col-3 expandedLabel">
                            Company:
                        </div>
                        <div class="col-5 expandedValue">
                            {{member.name}}
                        </div>
                    </div>
                    <div class="row" *ngIf="getDescription()">
                        <div class="col-3 expandedLabel">
                            Description:
                        </div>
                        <div class="col-5 expandedValue">
                            {{getDescription()}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 expandedLabel">
                            Company Email:
                        </div>
                        <div class="col-5 expandedValue">
                            {{member.email}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3 expandedLabel">
                            Phone:
                        </div>
                        <div class="col-5 expandedValue">
                            {{member.phone | phone}}
                        </div>
                    </div>
                    <div class="row" *ngIf="member.address">
                        <div class="col-3 expandedLabel">
                            Address:
                        </div>
                        <div class="col-5 expandedValue">
                            <pc-business-address [address]="member.address"></pc-business-address>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!readOnly && filter.memberType" class="row">
        <div class="col-12 tab-body">
            <pc-transaction-list
                [filter]="filter"
                [defaultSort]="'completionDate'"
                [isMemberListing]="false"
                [readOnly]="isLenderView()">
            </pc-transaction-list>
        </div>
    </div>
</div>
