import { Component } from '@angular/core';
import { BaseBadgeComponent } from '../badge.component';
import { DiligenceHistory, ReviewStatus } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-adjudicate-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class AdjudicateBadgeComponent extends BaseBadgeComponent<DiligenceHistory> {
    setBadgeData() {
        this.badgeToolTip = this.parent.status;
        this.badgeText = this.parent.status;
        switch (this.parent.status) {
            case ReviewStatus.APPROVED:
                this.badgeClass = 'green-badge';
                break;
            case ReviewStatus.PENDING:
                this.badgeClass = 'orange-badge';
                break;
            case ReviewStatus.PENDING_CUSTOMER_SUBMISSION:
                this.badgeClass = 'orange-badge';
                this.badgeText = 'PENDING';
                this.badgeToolTip = 'Pending Customer Submission';
                break;
            default:
                this.badgeClass = 'red-badge';
                break;
        }
    }
}
