<div class="col-sm-12 horizontal-scroll mat-table-container">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="packageSummaries" matSort [matSortActive]="pageTracking.sort" matSortDisableClear
        multiTemplateDataRows>
        <ng-container matColumnDef="manifest#">
            <th mat-header-cell *matHeaderCellDef> Manifest Number </th>
            <td mat-cell *matCellDef="let element"> {{ element.manifestNumber }} </td>
        </ng-container>
        <ng-container matColumnDef="package#">
            <th mat-header-cell *matHeaderCellDef> Package Number </th>
            <td mat-cell *matCellDef="let element"> {{ element.packageLabel }} </td>
        </ng-container>
        <ng-container matColumnDef="productType">
            <th mat-header-cell *matHeaderCellDef> Product Type </th>
            <td mat-cell *matCellDef="let element"> {{ element.productCategory }} </td>
        </ng-container>
        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Quantity </th>
            <td mat-cell *matCellDef="let element"> {{ element.shippedQuantity }} </td>
        </ng-container>
        <ng-container matColumnDef="quantityUnits">
            <th mat-header-cell *matHeaderCellDef> Units </th>
            <td mat-cell *matCellDef="let element"> {{ element.quantityUnits }} </td>
        </ng-container>
        <ng-container matColumnDef="transfer">
            <th mat-header-cell *matHeaderCellDef> Transfer </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.incoming !== null">{{ element.source }} </span>
                <span *ngIf="element.incoming === null"> - </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="transferDate">
            <th mat-header-cell *matHeaderCellDef> Transfer Date </th>
            <td mat-cell *matCellDef="let element"> {{ element.receivedDateTime | date:'mediumDate' }} </td>
        </ng-container>
        <ng-container matColumnDef="sender">
            <th mat-header-cell *matHeaderCellDef> Sender </th>
            <td mat-cell *matCellDef="let element"> {{ element.shipperFacilityName }} </td>
        </ng-container>
        <ng-container matColumnDef="receiver">
            <th mat-header-cell *matHeaderCellDef> Receiver </th>
            <td mat-cell *matCellDef="let element"> {{ element.recipientFacilityName }} </td>
        </ng-container>
        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef> Value </th>
            <td mat-cell *matCellDef="let element"> {{ element.value? (element.value | currency) : 0 }} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
</div>