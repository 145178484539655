<fieldset class="form-group" *ngIf="formCtrl">
    <div class="row">
        <div class="col-12" [formGroup]="formGroup">
            <label class="form-label">{{ label | translate }}</label>
            <div [formGroup]="formGroup" class="inputGroup">
                <input #counterPartySelect [formControl]="autoCompleteControl" [formControlName]="formCtrl" type="text" [placeholder]="placeholder"
                    class="form-control form-input-editable" [matAutocomplete]="auto" (keydown)="onInputChanged($event)" (click)="$event.target.select()">
                <i class="fa" [ngClass]="selected() ? 'fa-times-circle-o' : 'fa-chevron-down'" (click)="onIconClick($event)"></i>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
                    <mat-option [ngClass]="searching ? 'hidden' : 'counter-party-entry'" *ngFor="let counterParty of counterPartyAutoComplete | async;"
                                [value]="counterParty.member.name" [disabled]="isDisabledOrRejected(counterParty)"
                                (onSelectionChange)="counterPartyChanged(counterParty)">
                        <div class="row">
                            <div class="col-8 text-truncate">
                                <img *ngIf="counterParty.member.memberType === MemberType.INDIVIDUAL_MERCHANT" class="member-type-icon" src="assets/images/profile-icon.svg" title="Individual">
                                {{ getCounterPartyName(counterParty) }}
                            </div>
                            <div class="col-4 text-end">{{ counterParty.member.tier | translate }}</div>
                        </div>
                        <div *ngIf="includeAddress && counterParty?.address" class="row address-info">
                            <div class="col-7 text-truncate">{{ (counterParty.address.streetAddressOne || "")}}</div>
                            <div class="col-5 text-end text-truncate">{{ (counterParty.address.city ? counterParty.address.city + ", " : "") + (counterParty.address.stateProvince || "") }}</div>
                        </div>
                    </mat-option>
                    <mat-option *ngIf="searching">
                        <i class="fa fa-spin fa-spinner"></i>Searching
                    </mat-option>
                    <mat-option *ngIf="!searching && totalResults > searchLimit" [disabled]="true">
                        There are {{totalResults - searchLimit}} additional results - please refine your search.
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>
</fieldset>
