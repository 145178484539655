import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { WatchlistSearchResult, PagedResponse, WatchlistEntity, WatchlistUserNote, WatchlistHitCount } from 'projects/services/src/public-api';

@Injectable({
    providedIn: 'root'
})
export class WatchlistService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.NBCUAPIEndpoint + '/watchlist';
    }

    getWatchlistSearches(transactionId: string) {
        return this.http.get(`${this.baseUrl}/${transactionId}`) as Observable<WatchlistSearchResult[]>;
    }

    getWatchlistHitCount(transactionId: string) {
        return this.http.get(`${this.baseUrl}/${transactionId}/count`) as Observable<WatchlistHitCount>;
    }

    addWatchlistUserNote(memberId: string, watchlistEntityId: string, watchlistUserNote: WatchlistUserNote) {
        return this.http.post(`${this.baseUrl}/${memberId}/${watchlistEntityId}`, watchlistUserNote) as Observable<WatchlistEntity>;
    }
}
