import { Component, Input, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'pc-secure-image',
    templateUrl: './secure-image.component.html',
    styleUrls: ['./secure-image.component.scss']
})
export class SecureImageComponent implements OnInit {

    @Input() imgSrc: { key: string, src: Observable<any>};
    @Input() alt: string;
    @Input() class: string;

    @Output() imageAvailable: EventEmitter<boolean> = new EventEmitter<boolean>();

    style: string;
    dataUrl: SafeUrl;

    constructor(private domSanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.loadImage();
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnChanges(changes: SimpleChanges) {
        if (changes.imgSrc.currentValue.key === 'IMAGE_UPLOAD_PREVIEW') {
            this.dataUrl = changes.imgSrc.currentValue.src;
            this.style = 'centered';
        } else if (!changes.imgSrc.previousValue || (changes.imgSrc.previousValue.key !== changes.imgSrc.currentValue.key)) {
            this.loadImage();
        }
    }

    private loadImage() {
        if (!this.dataUrl) {
            this.imgSrc.src.subscribe(
                (resp: Blob) => {
                    if (resp) {
                        this.dataUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(resp));
                        this.imageAvailable.emit(true);
                    } else {
                        this.imageAvailable.emit(false);
                    }
                }, () => {
                    this.imageAvailable.emit(false);
                    this.style = 'hide';
                }
            );
        }
    }

    getClass() {
        if (this.class && this.style) {
            return `${this.style} ${this.class}`;
        } else if (this.class) {
            return `${this.class}`;
        }
        return this.style;
    }
}
