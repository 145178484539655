<div class="col-12 dashboard-info">
    <div class="row no-gutters">
        <div class="col-auto dashboard-header">
            <img class="dashboard-icon" src="assets/images/report-icon-2.svg" alt="">
            <span>Reports</span>
        </div>
        <div class="col-auto mr-2">
            <pc-text-option-selector [required]="true" [label]="''" [options]="reportTypes" [formCtrl]="'reportCtrl'"
                [formGroup]="formGroup" [defaultValue]="'MONTHLY_ACC_REPORT'" [translated]="true"
                (selected)="onSelectReportType($event)">
            </pc-text-option-selector>
        </div>
        &nbsp;&nbsp;&nbsp;
        <div class="col-auto ml-3">
            <select class="form-control form-input-table-header" [(ngModel)]="selectedMemberAccountId" (change)="onAccountChange()">
                <option *ngFor="let memberAccount of memberAccounts" [value]="memberAccount.id">{{ memberAccount.accountName + ' (' +memberAccount.accountNumber+ ')' }}</option>
            </select>
        </div>
    </div>
    <div class="row no-gutters">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div *ngIf="reportType === 'MONTHLY_ACC_REPORT' && selectedMemberAccountId && memberId">
                <pt-member-monthly-statement-report [memberId]="memberId" [memberAccountId]="selectedMemberAccountId"></pt-member-monthly-statement-report>
            </div>
            <div *ngIf="reportType === 'WEEKLY_ACC_REPORT' && selectedMemberAccountId && memberId">
                <pt-member-weekly-activity-report [memberId]="memberId" [memberAccountId]="selectedMemberAccountId"></pt-member-weekly-activity-report>
            </div>
        </div>
    </div>
</div>
