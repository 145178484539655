import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, AfterViewInit, OnDestroy, SimpleChanges, ViewChild} from '@angular/core';
import { AuthService, LenderService, Loan, LoanStatus, MemberAccountStatus, PagedResponse, MemberService, Member } from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LoanModalComponent, RequestAccessModalComponent } from 'projects/components/src/lib/modal';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';

@Component({
    selector: 'pc-lender-lending-list',
    templateUrl: './lender-lending-list.component.html'
})
export class LenderLendingListComponent implements  OnInit, AfterViewInit, OnDestroy, OnChanges {

    @Input() memberId: string;
    @Input() status = LoanStatus.ALL;

    loans: Loan[] = [];
    loanStatusMap = new Map();
    pageTracking: PageTracking;
    resultsLength = 0;
    isLoadingResults = true;
    isAdmin: boolean;
    subscription: any;
    displayedColumns: string[] = ['name', 'status', 'loan_amount', 'interest_rate', 'recurring_payment_amount', 'payment_start_date', 'payment_frequency', 'maturity', 'view_access', 'financial_data', 'action'];
    filterEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private lenderService: LenderService,
                private memberService: MemberService,
                private authService: AuthService,
                private cdr: ChangeDetectorRef,
                private dialog: MatDialog) {}

    ngOnInit() {
        this.isAdmin = this.authService.isAdmin();
        if (!this.isAdmin) {
            this.displayedColumns = ['name', 'status', 'loan_amount', 'interest_rate', 'recurring_payment_amount', 'payment_start_date', 'payment_frequency', 'maturity', 'view_access'];
        }
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'paymentStartDate', 'desc', 100);
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.status && !changes.status.firstChange) || (changes.memberAccountId && !changes.memberAccountId.firstChange)) {
            this.filterEvent.emit();
        }
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.lenderService.loadLoansByLenderIdAndStatuses(this.memberId, this.status ? [this.status] : [], this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<Loan>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((loans: Loan[]) => {
            this.loans = loans;
            this.loadMemberAccountsForLoans();
            UIUtils.scrollDashboardToTop();
        });
    }

    loadMemberAccountsForLoans() {
        this.loans.forEach((loan) => {
            this.memberService.loadMember(loan.memberId)
                .subscribe((member: Member) => {
                    loan.name = member.name;
                    this.loanStatusMap.set(member.id, member.status);
                });
        });
    }

    isEnableFinancing(loan: Loan) {
        return loan.status !== LoanStatus.CLOSED && loan.status !== LoanStatus.DECLINED && !!loan.financialDataAccess;
    }

    isEnableRequestAccess(loan: Loan) {
        return loan.status !== LoanStatus.CLOSED && loan.status !== LoanStatus.DECLINED && this.loanStatusMap.get(loan.memberId) !== MemberAccountStatus.DISABLED && !loan.financialDataAccess;
    }

    financialData(currentLoan: Loan) {
        this.router.navigate(['/lending/member/' + currentLoan.memberId], {queryParams: { _activeTab: 'financial_data' }});
    }

    onOpenLoanEditWindow(currentLoan: Loan) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            loan: currentLoan
        };
        dialogConfig.panelClass = 'normal-modal';
        const dialog = this.dialog.open(LoanModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.refreshPage();
            }
        });
    }

    onRequestAccess(loan: Loan) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            memberName: loan.name,
            memberId: loan.memberId,
            lenderId: loan.lenderId
        };
        dialogConfig.panelClass = 'normal-modal';
        const dialog = this.dialog.open(RequestAccessModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.refreshPage();
            }
        });
    }

    refreshPage() {
        this.filterEvent.emit();
    }

    handlePageBottom(event: PageEvent) {
        this.paginator.pageSize = event.pageSize;
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.page.emit(event);
    }
}
