import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { MerchantAccount, MerchantAccountService, MerchantAccountStatus, MemberAccountStatus, PagedResponse, RiskTier, Utils } from 'projects/services/src/public-api';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pt-authority-member-merchant-list',
    templateUrl: './authority-member-merchant-list.component.html',
    styleUrls: ['./authority-member-merchant-list.component.scss']
})
export class AuthorityMemberMerchantListComponent implements OnInit, AfterViewInit, OnDestroy {

    RiskTier = RiskTier;
    Utils = Utils;

    @Input() merchantMemberId: string;
    @Input() changeTrigger: number;
    @Input() readOnly: boolean;
    @Input() jurisdiction: string;
    @Input() tier: RiskTier;

    displayedColumns = ['merchant_name', 'created', 'account_number', 'status', 'action'];
    isLoadingResults = true;
    pageTracking: PageTracking;
    resultsLength = 0;
    subscription: any;
    filterEvent: EventEmitter<null> = new EventEmitter<null>();

    merchantAccounts: MerchantAccount[];

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private route: ActivatedRoute,
                private merchantAccountService: MerchantAccountService,
                private router: Router,
                private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'member.sortName', 'asc');
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.merchantAccountService.getMerchantAccountsByMerchantMemberId(this.merchantMemberId, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<MerchantAccount>) => {
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((response: MerchantAccount[]) => {
            this.merchantAccounts = response;
            this.isLoadingResults = false;
            UIUtils.scrollDashboardToTop();
        });
    }

    isEnabled(merchantAccount: MerchantAccount) {
        return merchantAccount && merchantAccount.status === MerchantAccountStatus.ACTIVE;
    }

    toggleEnabled(event: any, merchantAccount: MerchantAccount) {
        if (this.isEnabled(merchantAccount)) {
            this.merchantAccountService.updateMerchantAccount(merchantAccount.id, {status : MemberAccountStatus.DISABLED}).subscribe(() => {
                this.filterEvent.emit();
            });
        } else {
            this.merchantAccountService.updateMerchantAccount(merchantAccount.id, {status : MemberAccountStatus.ACTIVE}).subscribe(() => {
                this.filterEvent.emit();
            });
        }
        event.cancelBubble = true;
        if (event.stopImmediatePropagation) {
            event.stopImmediatePropagation();
        }
    }

    canEnableDisableMerchant(merchantAccount: MerchantAccount) {
        if (merchantAccount.member.tier === RiskTier.TIER_1 && this.tier === RiskTier.TIER_1 &&
            this.jurisdiction !== merchantAccount.member.jurisdiction) {
            return false;
        } else {
            return merchantAccount.merchantAccountConfig.status !== MerchantAccountStatus.DISABLED && !this.readOnly;
        }
    }
}
