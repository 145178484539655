import { Component, Inject, OnInit } from '@angular/core';
import { BaseModalComponent } from '../base-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { LandingSettings, PartnerService } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-landing-page-style-modal',
    templateUrl: './landing-page-style-modal.component.html',
    styleUrls: ['./landing-page-style-modal.component.scss']
})
export class LandingPageStyleModalComponent extends BaseModalComponent<LandingPageStyleModalComponent> implements OnInit {

    fontOpts: string[] = ['Verdana', 'Tahoma', 'Arial', 'Courier'];
    primaryColor: string;
    secondaryColor: string;
    primaryFont: string;
    buttonsColor: string;
    buttonsTextColor: string;
    landingCustomizationForm: UntypedFormGroup;
    memberId: string;

    constructor(dialogRef: MatDialogRef<LandingPageStyleModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any,
                private partnerService: PartnerService) {
        super(dialogRef);
        this.landingCustomizationForm = data.landingCustomizationForm;
        this.memberId = data.memberId;
        this.initFieldFromForm(this.landingCustomizationForm);
    }

    ngOnInit() {
        this.confirm = this.confirm.bind(this);
        this.close = this.close.bind(this);
    }

    initFieldFromForm(landingCustomizationForm: UntypedFormGroup) {
        if (landingCustomizationForm) {
            this.primaryColor = landingCustomizationForm.controls['primaryColorCtrl'].value;
            this.secondaryColor = landingCustomizationForm.controls['secondaryColorCtrl'].value;
            this.primaryFont = landingCustomizationForm.controls['fontCtrl'].value;
            this.buttonsColor = landingCustomizationForm.controls['buttonsColorCtrl'].value;
            this.buttonsTextColor = landingCustomizationForm.controls['buttonsTextColorCtrl'].value;
        }
    }

    confirm() {
        const pageSettings: LandingSettings = {
            id: this.landingCustomizationForm.controls['idCtrl'].value,
            memberId: this.memberId,
            primaryColor: this.landingCustomizationForm.controls['primaryColorCtrl'].value,
            secondaryColor: this.landingCustomizationForm.controls['secondaryColorCtrl'].value,
            primaryFont: this.landingCustomizationForm.controls['fontCtrl'].value,
            buttonsColor: this.landingCustomizationForm.controls['buttonsColorCtrl'].value,
            buttonsTextColor: this.landingCustomizationForm.controls['buttonsTextColorCtrl'].value
        };
        this.partnerService.updateCustomizationPageSettings(pageSettings).subscribe(() => {
            super.close(true);
        });
    }

    close() {
        super.close(false);
    }
}
