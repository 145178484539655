import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyDateModel } from 'angular-mydatepicker';
import { TableUtils } from 'projects/components/src/lib/table-utils.service';

@Component({
    selector: 'pt-member-metrc-details',
    templateUrl: './member-metrc-details.component.html'
})
export class MemberMetrcDetailsComponent implements OnInit {

    @Input() memberId: string;

    metrcTab = 'transfers';

    formGroup: UntypedFormGroup;
    changeTrigger = 1;

    startDate = '';
    endDate = '';
    transferType = '';
    counterParty = '';

    constructor(private formBuilder: UntypedFormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private datePipe: DatePipe) {}

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            dateCtrl: new UntypedFormControl(),
            counterPartyCtrl: new UntypedFormControl()
        });

        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['startDate'] || queryParams['endDate'] || queryParams['metrcTab'] || queryParams['source'] || queryParams['counterParty']) {
            this.startDate = queryParams['startDate'];
            this.endDate = queryParams['endDate'];
            this.metrcTab = queryParams['metrcTab'];
            this.transferType = queryParams['source'];
            this.counterParty = queryParams['counterParty'];
        } else {
            this.startDate = '';
            this.endDate = '';
            this.metrcTab = 'transfers';
            this.counterParty = '';
            this.formGroup.get('dateCtrl').setValue(null);
            this.formGroup.get('counterPartyCtrl').setValue('');
            this.updateQueryParams(true);
        }

        this.route.queryParams.subscribe((params: any) => {
            this.startDate = params['startDate'] || '';
            this.endDate = params['endDate'] || '';
            this.metrcTab = params['metrcTab'] || 'transfers';
            this.transferType = params['source'] || '';
            this.counterParty = params['counterParty'] || '';
            this.formGroup.controls['counterPartyCtrl'].setValue(this.counterParty);
            if (this.startDate && this.endDate) {
                this.formGroup.controls['dateCtrl'].setValue({ isRange: true, singleDate: null, dateRange: { beginJsDate: new Date(this.startDate + ' 00:00:00'), endJsDate: new Date(this.endDate + ' 00:00:00') } });
            } else {
                this.formGroup.controls['dateCtrl'].setValue(null);
            }
        });
    }

    search(counterParty: string) {
        this.counterParty = counterParty;
        this.updateQueryParams(false);
    }

    setMetrcTab(metrcTab: string) {
        if (metrcTab === this.metrcTab) {
            return;
        }
        this.startDate = '';
        this.endDate = '';
        this.counterParty = '';
        this.changeTrigger = Math.random();
        this.metrcTab = metrcTab;
        TableUtils.clearSubscriptions();
        this.updateQueryParams(true);
    }

    sourceChanged() {
        this.updateQueryParams(false);
    }

    getTabClass(tabOption: string) {
        if (this.metrcTab === tabOption) {
            return 'tab-navigation-selected';
        } else {
            return 'tab-navigation';
        }
    }

    onDateChanged(event: IMyDateModel) {
        if (event && event.dateRange) {
            this.startDate = this.datePipe.transform(event.dateRange.beginJsDate, 'yyyy-MM-dd');
            const endDateRange = event.dateRange.endJsDate.setDate(event.dateRange.endJsDate.getDate());
            this.endDate = this.datePipe.transform(endDateRange, 'yyyy-MM-dd');
        } else {
            this.startDate = '';
            this.endDate = '';
        }
        this.updateQueryParams(false);
    }

    updateQueryParams(replace: boolean) {
        const queryParams = { metrcTab: this.metrcTab, startDate: this.startDate, endDate: this.endDate, source: (this.metrcTab === 'transfers' ? this.transferType : null), counterParty: (this.metrcTab === 'transfers' || this.metrcTab === 'receipts') ? this.counterParty : null,  page: 1, num: (this.metrcTab === 'tracking') ? 50 : 25, sort: null, dir: null};
        if (this.metrcTab === 'trace') {
            queryParams.page = null;
            queryParams.num = null;
        }
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }
}
