<div>
    <form>
        <div>
            <div class="modal-header">
                <label class="form-title">Financing Request</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content">
                <div class="row">
                    <div class="col-6">
                        <pc-text-option-selector [id]="'company-type-selector'" [required]="false" [label]="'Company Type'"
                            [options]="companyTypes" [formCtrl]="'companyTypeCtrl'" [formGroup]="financingFormGroup">
                        </pc-text-option-selector>
                    </div>
                    <div class="col-6">
                        <pc-text-option-selector [id]="'lender-selector'" [required]="true" [label]="'Select Lender'"
                            [options]="iterableIteratorToArray(lendersMap.keys())" [formCtrl]="'lenderCtrl'"
                            [formGroup]="financingFormGroup">
                        </pc-text-option-selector>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <pc-form-amount-input [id]="'request-amount-input'" [required]="true" [label]="'Request Amount'" [placeholder]="'Amount'"
                            [formCtrl]="'requestAmountCtrl'"
                            [formGroup]="financingFormGroup"></pc-form-amount-input>
                    </div>
                    <div class="col-6">
                            <label class="form-label">{{ 'Request Term (Month)' }}</label>
                            <div [formGroup]="financingFormGroup">
                                <input id="request-term-input" placeholder="Monthly" type="number" formControlName="requestTermCtrl" class="form-control"/>
                            </div>
                    </div>
                    <div class="col-6">
                        <pc-form-amount-input [id]="'revenue-input'" [placeholder]="'Amount'" [required]="false" [label]="'Revenue'" [formCtrl]="'revenueCtrl'"
                            [formGroup]="financingFormGroup"></pc-form-amount-input>
                    </div>
                    <div class="col-6">
                        <pc-form-amount-input [id]="'artotal-input'" [placeholder]="'Total'" [required]="false" [label]="'A/R Total'" [formCtrl]="'arTotalCtrl'"
                            [formGroup]="financingFormGroup"></pc-form-amount-input>
                    </div>
                    <div class="col-6">
                        <pc-form-amount-input [id]="'ebitda-input'" [required]="false" [label]="'EBITDA'" [formCtrl]="'ebitdaCtrl'"
                                              [formGroup]="financingFormGroup" [allowNegative]="true"></pc-form-amount-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <pc-form-text-area [id]="'note-input'" [required]="false" [label]="'Notes'" [formCtrl]="'noteCtrl'"
                            [formGroup]="financingFormGroup">
                        </pc-form-text-area>
                    </div>
                    <div class="col-12">
                        <p>
                            To expedite diligence and the issuance of a term sheet, give
                            the selected lending partners 5 day initial access to your
                            Confia profile for efficient and expedient diligence.
                        </p>
                    </div>
                    <div class="col-12">
                        <pc-simple-checkbox [id]="'access-your-confia-checkbox'" [label]="'Consent selected lender(s) to access your Confia'" [formCtrl]="'accessToConfiaAgreementCtrl'" [formGroup]="financingFormGroup" [invert]="true">
                        </pc-simple-checkbox>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button pc-no-dbl-click class="blue-button" id="submit-btn" (click)="confirm()" [disabled]="financingFormGroup.invalid" submit-button>Submit</button>
            </div>
        </div>
    </form>
</div>
