import { Injectable } from '@angular/core';
import { Address, AddressType } from '../models/address';
import { BillingProfile } from '../models/billing-profile';
import { BankAccount } from '../models/bank-account';
import { FinancialDetails } from '../models/financial-details';
import { User } from '../models/user';
import { License } from '../models/license';
import { BusinessCategory, Consumer, CorporateEntity, Member, MemberType, RiskRating, RiskTier } from '../models/member';
import { KeyParty, KeyPartyStatus } from '../models/key-party';
import { SupportedTransactionType, TransactionType } from '../models/transaction';
import { TaxInfo } from '../models/tax-info';
import { DocumentType, Upload } from '../models/upload';
import { MerchantAccountConfig } from '../models/merchant-account-config';
import { MemberAccount, MemberAccountStatus } from '../models/member-account';

@Injectable({
    providedIn: 'root'
})
export class OnboardingTrackingService {

    // admin section
    private static adminUser: User;
    private static adminAddress: Address;
    private static adminKeyParty: KeyParty;

    private static adminInfoDirty = false;

    // accounts section
    private static memberAccounts: MemberAccount[] = [];

    // billing profile details
    private static billingProfile: BillingProfile;

    private static billingProfileInfoDirty = false;

    // member details
    private static memberAddress: Address;
    private static member: Member;
    private static jurisdiction: string;
    private static sameMembers: Member[];

    private static memberInfoDirty = false;

    // key parties
    private static keyParties: KeyParty[] = [];

    // locations
    private static locations: Address[] = [];

    // licenses
    private static licenses: License[] = [];

    // financial details
    private static taxInfo: TaxInfo;
    private static financialDetails: FinancialDetails;

    private static financialInfoDirty = false;

    // documents
    private static documents: Upload[] = [];
    private static isPrivateMerchant = false;

    // questions
    private static openQuestionCount = 0;

    // internal details
    private static riskTier: RiskTier;
    private static riskRating: RiskRating;
    private static authorityDocuments: Upload[] = [];
    private static merchantAccountConfig: MerchantAccountConfig;

    private static internalInfoDirty = false;

    // alerts
    private static openAlertsCount = 0;
    private static internalAlertsDirty = false;

    // banking details
    private static outgoingMerchantTransactionType: string;
    private static activeBankAccounts: BankAccount[] = [];
    private static pendingBankAccounts: BankAccount[] = [];

    private static task: string;

    static isPublic() {
        return this.member && this.member.memberType === MemberType.BUSINESS && (this.member as CorporateEntity).isPublic;
    }

    static isMrb() {
        return this.member && this.member.businessCategory === BusinessCategory.MRB_LICENSED_BUSINESS;
    }

    static isATMBusiness() {
        return this.member && this.member.businessCategory === BusinessCategory.ATM_BUSINESS;
    }

    static isLinkedBankAccount() {
        return this.member && this.member.metaInfo && this.member.metaInfo.linkBankAccount;
    }

    static isAdminInfoValid() {
        // user fields
        if (!this.adminUser ||
            !this.adminUser.firstName ||
            !this.adminUser.lastName ||
            !this.adminUser.mobilePhone ||
            !this.adminUser.email ||
            !this.adminUser.birthDate) {
            return false;
        }

        // address fields
        if (!this.adminAddress ||
            !this.adminAddress.streetAddressOne ||
            !this.adminAddress.city ||
            !this.adminAddress.stateProvince ||
            !this.adminAddress.zipPostalCode) {
            return false;
        }

        // ownership fields
        if (this.adminKeyParty) {
            return this.adminKeyParty.director || this.adminKeyParty.officer || (this.adminKeyParty.owner && this.adminKeyParty.percentOwnership);
        } else {
            return false;
        }
    }

    static isAdminInfoDirty() {
        return this.adminInfoDirty;
    }

    static setAdminInfoDirty(adminInfoDirty: boolean) {
        this.adminInfoDirty = adminInfoDirty;
    }

    static setAdminInfo(adminUser: User, adminAddress: Address, adminKeyParty: KeyParty) {
        this.adminUser = adminUser;
        this.adminAddress = adminAddress;
        this.adminKeyParty = adminKeyParty;
    }

    static setSameMembers(members: Member[]) {
        this.sameMembers = members;
    }

    static setAccountDetails(memberAccounts: MemberAccount[]) {
        this.memberAccounts = memberAccounts;
    }

    static isAccountDetailsValid() {
        if (!this.memberAccounts.length) {
            return true;
        }
        return !this.memberAccounts.find((memberAccount: MemberAccount) => {
            return memberAccount.status === MemberAccountStatus.UNDER_REVIEW || memberAccount.status === MemberAccountStatus.PENDING;
        });
    }

    static isBillingProfileInfoValid() {
        // billing profile fields
        if (this.member?.memberType === MemberType.FEE_ACCOUNT) {
            return true;
        } else if (!this.billingProfile ||
             !(this.billingProfile.cashDepositFeePercentage >= 0 && this.isValidRange(this.billingProfile.cashDepositFeePercentage, 0, 100)) ||
             !(this.billingProfile.retailTransactionFeePercentage >= 0 && this.isValidRange(this.billingProfile.retailTransactionFeePercentage, 0, 100))) {
            return false;
        }

        return true;
    }

    static isBillingProfileInfoDirty() {
        return this.billingProfileInfoDirty;
    }

    static setBillingProfileInfoDirty(billingProfileInfoDirty: boolean) {
        this.billingProfileInfoDirty = billingProfileInfoDirty;
    }

    static setBillingProfileInfo(billingProfile: BillingProfile) {
        this.billingProfile = billingProfile;
    }

    static isMemberInfoValid() {
        if (this.member?.memberType === MemberType.INDIVIDUAL_MERCHANT || this.member?.memberType === MemberType.CONSUMER) {
            const individualMember = (this.member as Consumer);
            if (individualMember.firstName || individualMember.lastName) {
                return true;
            }
        }
        // member fields
        if (!this.member ||
            (this.member.memberType === MemberType.BUSINESS && !(this.member as CorporateEntity).businessType) ||
            !(this.member as CorporateEntity).businessName ||
            (this.member.memberType === MemberType.BUSINESS && !this.member.phone)) {
            return false;
        }

        // non-merchant member fields
        if (this.member.memberType === MemberType.BUSINESS) {
            if (!this.member.briefDescription ||
                !this.member.businessCategory ||
                !(this.member as CorporateEntity).naicsCode ||
                (this.member as CorporateEntity).naicsCode === 0) {
                return false;
            }
            if (this.member.metaInfo && !this.member.metaInfo.accountingSystem) {
                return false;
            }
        } else if (!this.member.briefDescription) {
            return false;
        }

        // member address fields
        if (!this.memberAddress ||
            !this.memberAddress.streetAddressOne ||
            !this.memberAddress.city ||
            !this.memberAddress.stateProvince ||
            !this.memberAddress.zipPostalCode) {
            return false;
        }

        // jurisdiction
        if (this.member.memberType !== MemberType.FEE_ACCOUNT && this.riskTier === RiskTier.TIER_1 && !this.jurisdiction) {
            return false;
        }
        return !(this.sameMembers && this.sameMembers.length);
    }

    static isMemberInfoDirty() {
        return this.memberInfoDirty;
    }

    static setMemberInfoDirty(businessInfoDirty: boolean) {
        this.memberInfoDirty = businessInfoDirty;
    }

    static setMemberInfo(memberAddress: Address, member: Member) {
        this.memberAddress = memberAddress;
        this.member = member;
    }

    static setMemberAddress(memberAddress: Address) {
        this.memberAddress = memberAddress;
    }

    static setJurisdiction(jurisdiction: string) {
        this.jurisdiction = jurisdiction;
    }

    static isKeyPartyInfoValid() {
        if (this.isPublic() || (this.member && (this.member.memberType === MemberType.BUSINESS_MERCHANT))) {
            return true;
        }
        return this.keyParties.length > 0;
    }

    static setKeyPartyInfo(keyParties: KeyParty[]) {
        this.keyParties = keyParties;
    }

    static isLocationInfoValid() {
        if (this.member?.memberType === MemberType.INDIVIDUAL_MERCHANT) {
            return true;
        }
        if (this.locations.length === 0) {
            return false;
        }
        return !!this.locations.find((location) => {
            return location && location.type === AddressType.PRIMARY;
        });
    }

    static setLocationInfo(locations: Address[]) {
        this.locations = locations;
    }

    static isLicenseInfoValid() {
        if (!this.member) {
            return false;
        }
        if (this.member.memberType === MemberType.BUSINESS_MERCHANT && this.licenses.length > 0) {
            for (const license of this.licenses) {
                if (!license.jurisdiction ||
                    !license.licenseNo) {
                    return false;
                }
            }
            return true;
        } else if (!this.isMrb()) {
            return true;
        }
        return this.licenses.length > 0;
    }

    static setLicenseInfo(licenses: License[]) {
        this.licenses = licenses;
    }

    static isFinancialDetailsValid() {
        if (!this.taxInfo ||
            !this.taxInfo.ein) {
            return false;
        }

        if (!this.financialDetails ||
            !this.isValidRange(this.financialDetails.expectedMonthlyCashDepositAmount, 0) ||
            !this.isValidRange(this.financialDetails.expectedMonthlyWithdrawals, 0) ||
            !this.isValidRange(this.financialDetails.expectedMonthlyDepositAmount, 0) ||
            !this.isValidRange(this.financialDetails.expectedMonthlyNumberOfReceipts, 0, 999999) ||
            !this.isValidRange(this.financialDetails.expectedMonthlyNumberOfPayments, 0, 999999) ||
            !this.isValidRange(this.financialDetails.percentOfRevenueFromCannabis, 0, 100) ||
            !this.isValidRange(this.financialDetails.percentOfRevenueFromCannabisPartners, 0, 100) ||
            !this.isValidRange(this.financialDetails.percentOfRevenueFromCash, 0, 100) ||
            !this.isValidRange(this.financialDetails.plannedInitialDepositAmount, 0) ||
            this.financialDetails.plannedInitialDepositAmount > 0 && !this.financialDetails.initialDepositSource?.length) {
            return false;
        }

        return true;
    }

    static isFinancialInfoDirty() {
        return this.financialInfoDirty;
    }

    static isValidRange(value: any, lowerRange: number, upperRange?: number) {
        if (Object.prototype.toString.call(value).slice(8, -1) === 'Number') {
            if (value >= lowerRange) {
                if (upperRange && value <= upperRange) {
                    return true;
                } else if (!upperRange) {
                    return true;
                }
                return false;
            }
        }
        return false;
    }

    static setFinancialInfoDirty(financialInfoDirty: boolean) {
        this.financialInfoDirty = financialInfoDirty;
    }

    static setFinancialDetails(taxInfo: TaxInfo, financialDetails: FinancialDetails) {
        this.taxInfo = taxInfo;
        this.financialDetails = financialDetails;
    }

    static isDocumentsValid() {
        if (!this.documents.length) {
            return false;
        }
        let hasIncorp = false;
        let hasAuthRes = false;
        let hasCorpStruct = false;
        let hasOperating = false;
        let hasPassportIdOrLicense = false;
        let hasATMListAndLocationDetails = false;
        let hasCashMgntOrHandingProcedureDetails = false;
        let hasFlowOfFundsDescription = false;
        let copyOfSiteOrLocationContract = false;
        let hasEinSupportingEvidence = false;

        for (const document of this.documents) {
            if (document.documentType === DocumentType.ARTICLES_OF_INCORPORATION) {
                hasIncorp = true;
            }
            if (document.documentType === DocumentType.AUTHORITY_RESOLUTION) {
                hasAuthRes = true;
            }
            if (document.documentType === DocumentType.CORPORATE_STRUCTURE) {
                hasCorpStruct = true;
            }
            if (document.documentType === DocumentType.OPERATING_AGREEMENT_OR_BYLAWS) {
                hasOperating = true;
            }
            if (document.documentType === DocumentType.PASSPORT_OR_STATE_ISSUED_ID) {
                hasPassportIdOrLicense = true;
            }

            // ATM BUSINESS DOCUMENTS
            if (document.documentType === DocumentType.ATM_LIST_AND_LOCATIONS) {
                hasATMListAndLocationDetails = true;
            }

            if (document.documentType === DocumentType.CASH_MGNT_POLICY_AND_PROCEDURE) {
                hasCashMgntOrHandingProcedureDetails = true;
            }

            if (document.documentType === DocumentType.FLOW_OF_FUNDS_DESC) {
                hasFlowOfFundsDescription = true;
            }

            if (document.documentType === DocumentType.COPY_OF_SITE_OR_LOCATION_CONTRACT) {
                copyOfSiteOrLocationContract = true;
            }
            if (document.documentType === DocumentType.EIN_SUPPORTING_EVIDENCE) {
                hasEinSupportingEvidence = true;
            }
        }

        if (this.isATMBusiness()) {
            return hasIncorp && hasAuthRes && hasCorpStruct && hasOperating
                && hasPassportIdOrLicense && hasEinSupportingEvidence && hasATMListAndLocationDetails
                && hasCashMgntOrHandingProcedureDetails && hasFlowOfFundsDescription
                && copyOfSiteOrLocationContract;
        }
        return hasIncorp && hasAuthRes && hasCorpStruct && hasOperating && hasPassportIdOrLicense && hasEinSupportingEvidence;
    }

    static setDocuments(documents: Upload[]) {
        this.documents = documents;
    }

    static isQuestionsValid() {
        return this.openQuestionCount === 0;
    }

    static setQuestionsValid(openQuestionCount: number) {
        this.openQuestionCount = openQuestionCount;
    }

    static setInternalAlertsCount(openAlertsCount: number) {
        this.openAlertsCount = openAlertsCount;
    }

    static isInternalAlertsValid() {
        return this.openAlertsCount === 0;
    }

    static isInternalDetailsValid() {
        if (this.member && this.member.memberType === MemberType.FEE_ACCOUNT) {
            return true;
        }

        if (!this.isRiskProfileValid()) {
            return false;
        }

        if (this.member && (this.member.memberType === MemberType.BUSINESS_MERCHANT || this.member.memberType === MemberType.INDIVIDUAL_MERCHANT) && this.merchantAccountConfig) {
            if (this.merchantAccountConfig.merchantAccountType === SupportedTransactionType.BOTH && (
                !this.merchantAccountConfig.defaultOutgoingTransactionType || !this.merchantAccountConfig.defaultIncomingTransactionType)) {
                return false;
            } else if (this.merchantAccountConfig.merchantAccountType === SupportedTransactionType.OUTGOING && !this.merchantAccountConfig.defaultOutgoingTransactionType) {
                return false;
            } else if (this.merchantAccountConfig.merchantAccountType === SupportedTransactionType.INCOMING && !this.merchantAccountConfig.defaultIncomingTransactionType) {
                return false;
            }
        }

        if (!this.isPrivateMerchant) {
            const documents = {};
            this.authorityDocuments.forEach((document) => {
                documents[document.path] = document;
            });
            for (const keyParty of this.keyParties) {
                const keyPartyId = keyParty.corporateKeyPartyId || keyParty.individualKeyPartyId;
                if (!keyParty.individualKeyParty || !keyParty.contactPerson) {
                    if (keyParty.status === KeyPartyStatus.ACTIVE && (!documents[`${keyPartyId}/screen/media/`] || !documents[`${keyPartyId}/screen/idrisk/`])) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    static setPrivateMerchant(isPrivateMerchant: boolean) {
        this.isPrivateMerchant = isPrivateMerchant;
    }

    static isRiskProfileValid() {
        if (!this.riskTier ||
            !this.riskRating ||
            this.riskRating === 'NOT_RATED' ||
            this.riskTier === 'NOT_TIERED') {
            return false;
        }
        return true;
    }

    static isInternalInfoDirty() {
        return this.internalInfoDirty;
    }

    static isInternalAlertsDirty(){
        return this.internalAlertsDirty;
    }

    static setInternalInfoDirty(internalInfoDirty: boolean) {
        this.internalInfoDirty = internalInfoDirty;
    }

    static setInternalAlertsDirty(internalAlertsDirty: boolean) {
        this.internalAlertsDirty = internalAlertsDirty;
    }

    static setInternalDetails(riskTier: RiskTier, riskRating: RiskRating, jurisdiction: string, merchantAccountConfig: MerchantAccountConfig) {
        this.riskTier = riskTier;
        this.riskRating = riskRating;
        this.jurisdiction = jurisdiction;
        this.merchantAccountConfig = merchantAccountConfig;
    }

    static setAuthorityDocuments(authorityDocuments: Upload[]) {
        this.authorityDocuments = authorityDocuments;
    }

    static isBankingDetailsValid() {
        if (this.member) {
            if (this.pendingBankAccounts.length) {
                return false;
            }
            if ((this.member.memberType === MemberType.CONSUMER)  ||
             ((this.member.memberType === MemberType.BUSINESS_MERCHANT || this.member.memberType === MemberType.INDIVIDUAL_MERCHANT) && (this.outgoingMerchantTransactionType === TransactionType.OUTGOING_ACH_TO_MERCHANT ||
            this.outgoingMerchantTransactionType === TransactionType.WALLET_TO_EXPRESS_ACH_PAYMENT || this.outgoingMerchantTransactionType === TransactionType.OUTGOING_WIRE_TO_MERCHANT))) {
                return this.activeBankAccounts.length > 0;
            } else if ((this.member.memberType === MemberType.BUSINESS || this.member.memberType === MemberType.INDIVIDUAL_MERCHANT) && this.isLinkedBankAccount()) {
                return this.activeBankAccounts.length > 0;
            }
        }
        return true;
    }

    static setBankingDetails(outgoingMerchantTransactionType: string, activeBankAccounts: BankAccount[], pendingBankAccounts: BankAccount[]) {
        this.outgoingMerchantTransactionType = outgoingMerchantTransactionType;
        this.activeBankAccounts = activeBankAccounts;
        this.pendingBankAccounts = pendingBankAccounts;
    }

    static isApplicationValid(memberType: MemberType, isAuthority: boolean) {
        if (memberType === MemberType.BUSINESS_MERCHANT) {
            return this.isMemberInfoValid() &&
                this.isKeyPartyInfoValid() &&
                this.isBankingDetailsValid() &&
                this.isLicenseInfoValid() &&
                (!isAuthority || (isAuthority && this.isInternalDetailsValid()));
        } else if (memberType === MemberType.INDIVIDUAL_MERCHANT) {
            return this.isKeyPartyInfoValid() &&
                this.isBankingDetailsValid() &&
                (!isAuthority || (isAuthority && this.isInternalDetailsValid()));
        }
        return (isAuthority || (!isAuthority && this.isAdminInfoValid())) &&
            this.isMemberInfoValid() &&
            this.isKeyPartyInfoValid() &&
            this.isLocationInfoValid() &&
            this.isLicenseInfoValid() &&
            this.isFinancialDetailsValid() &&
            this.isDocumentsValid() &&
            this.isQuestionsValid() &&
            (!isAuthority || (isAuthority && this.isInternalDetailsValid()));
    }

    static setCurrentTask(task: string | null) {
        this.task = task;
    }

    static isAdjudicateValid() {
        if (!this.task) {
            return true;
        }

        return this.task !== 'adjudicate';
    }

}
