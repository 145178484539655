<div>
    <div>
        <div class="modal-header">
            <label class="form-title">QR Tracking Information</label>
            <i class="modal-close" (click)="close()">&times;</i>
        </div>
        <div class="modal-content">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-5">
                            Type
                        </div>
                        <div class="col-7">
                            {{transaction.transactionType | translate}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5">
                            Recipient
                        </div>
                        <div class="col-7">
                            <span *ngIf="transaction.recipientMemberName">{{transaction.recipientMemberName}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5">
                            Payment Amount
                        </div>
                        <div class="col-7">
                            <pc-transaction-amount [transaction]="transaction"></pc-transaction-amount>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5">
                            Date of Payment
                        </div>
                        <div class="col-7">
                            {{transaction.transactionDate | date:'mediumDate'}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">&nbsp;</div>
                    </div>
                    <div class="row">
                        <div class="col-5">
                            Transaction ID
                        </div>
                        <div class="col-7">
                            {{transaction.id}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5">
                            Status
                        </div>
                        <div class="col-7">
                            {{transaction.status | translate}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">&nbsp;</div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="qr-code">
                                <pc-secure-image [imgSrc]="getQrCode()" alt="QR Code" class="centered"></pc-secure-image>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <pc-submit-button [submit]="close" [text]="'Close'"></pc-submit-button>
        </div>
    </div>
</div>
