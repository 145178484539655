<fieldset [ngClass]="getClass()" *ngIf="formCtrl">
    <div *ngIf="!invert">
        <div class="col-12" [formGroup]="formGroup">
            <label class="form-label">{{ label | translate }}</label>&nbsp;<i [attr.id]="id ? id : null" class="fa" [class.fa-check-square-o]="isChecked()" [class.fa-square-o]="!isChecked()" [class.disabled]="isDisabled()" (click)="onChanged()"></i>
        </div>
    </div>
    <div *ngIf="invert" class="inverted">
        <div [formGroup]="formGroup">
            <label class="form-label"><i [attr.id]="id ? id : null" class="fa" [class.fa-check-square-o]="isChecked()" [class.fa-square-o]="!isChecked()" [class.disabled]="isDisabled()" (click)="onChanged()"></i><span [class.disabled]="isDisabled()">{{ label | translate }}</span></label>
        </div>
    </div>
</fieldset>
