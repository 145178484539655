import { Component, Input, OnInit } from '@angular/core';
import {
    AuthService, TransactionTableFilter, MemberService, Member
} from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-transactions',
    templateUrl: './member-transactions.component.html'
})
export class MemberTransactionsComponent implements OnInit {

    @Input() memberId: string;
    @Input() readOnly = false;

    changeTrigger = 1;
    filter: TransactionTableFilter;

    constructor(private authService: AuthService,
        private memberService: MemberService) { }

    ngOnInit() {
        this.memberService.loadMember(this.memberId).subscribe(
            (member: Member) => {
                this.filter = new TransactionTableFilter(member.id);
                this.filter.memberType = member.memberType;
                this.filter.memberFunctions = member.memberFunctions;
                const profile = this.authService.getProfile();
                this.filter.retailLocationId = profile.retailLocationId || '';
                this.filter.transactionTab = 'TRANSACTION_LIST';
            }
        );
    }

    filterChanged(filter: TransactionTableFilter) {
        this.filter = filter;
        this.changeTrigger = Math.random();
    }

    transactionSubmitted() {
        this.changeTrigger = Math.random();
    }
}
