import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {
    PartnerKey, PartnerKeyStatus, AuthService, DataroomService, Domain, DomainFormService, DomainService, LandingSettings, LandingSettingsFormService,
    MemberFunction, PagedResponse, PartnerService, PartnerSettings, PartnerSettingsFormService, PartnerSettingsService
} from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LandingPageStyleModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pt-partner-settings',
    templateUrl: './partner-settings.component.html',
    styleUrls: ['./partner-settings.component.scss']
})
export class PartnerSettingsComponent implements OnInit {

    PartnerKeyStatus = PartnerKeyStatus;

    @Input() memberId: string;
    @Input() changeTrigger: number;
    @Input() activeTab: string;
    @Input() readOnly = false;

    @Output() navigate: EventEmitter<string> = new EventEmitter<string>();

    domainForm: UntypedFormGroup;
    partnerSettingsForm: UntypedFormGroup;
    landingCustomizationForm: UntypedFormGroup;

    keys: PartnerKey[];
    domains: Domain[];

    displayedApiKeysColumns: string[] = ['api_key', 'secret', 'issued_date', 'disable'];
    displayedRedirectingUrlsColumns: string[] = ['redirecting_url', 'issued_date', 'action'];

    imageSrc: any;
    canCreate: boolean;

    constructor(private partnerSettingsFormService: PartnerSettingsFormService,
                private partnerSettingsService: PartnerSettingsService,
                private domainFormService: DomainFormService,
                private domainService: DomainService,
                private authService: AuthService,
                private dialog: MatDialog,
                private landingSettingsFormService: LandingSettingsFormService,
                private partnerService: PartnerService,
                private dataroomService: DataroomService) {}

    ngOnInit() {
        this.onCreateDomain = this.onCreateDomain.bind(this);
        this.domainFormValid = this.domainFormValid.bind(this);
        this.isPartnerWebhooksValid = this.isPartnerWebhooksValid.bind(this);
        this.onCreateKey = this.onCreateKey.bind(this);
        this.canCreatable = this.canCreatable.bind(this);
        this.onPartnerSettingsUpdateUriAndCredentials = this.onPartnerSettingsUpdateUriAndCredentials.bind(this);
        this.initializeForm();
    }

    initializeForm() {
        this.initializePartnerSettings();
        this.initializeDomain();
        this.imageSrc = {
            key: this.memberId,
            src: this.dataroomService.getImage(this.memberId)
        };
    }

    onNavigate(direction: string) {
        this.navigate.emit(direction);
    }

    initializePartnerSettings(partnerSettings?: PartnerSettings) {
        if (partnerSettings) {
            this.partnerSettingsForm = this.partnerSettingsFormService.initializeForm(partnerSettings);
            if (this.readOnly) {
                this.partnerSettingsForm.disable();
            }
        } else {
            this.partnerSettingsService.getByMemberId(this.memberId).subscribe((partnerResp: PartnerSettings) => {
                this.partnerSettingsForm = this.partnerSettingsFormService.initializeForm(partnerResp);
                if (this.readOnly) {
                    this.partnerSettingsForm.disable();
                }
            });
        }
        this.partnerService.getCustomizationPageSettings(this.memberId).subscribe((landingSettingsReq: LandingSettings) => {
            this.landingCustomizationForm = this.landingSettingsFormService.initializeForm(landingSettingsReq);
        });
        this.loadKeys();
    }

    initializeDomain() {
        this.domainForm = this.domainFormService.initializeForm();
        this.domainService.loadDomainsByMemberId(this.memberId).subscribe((response: PagedResponse<Domain>) => {
            this.domains = response.content;
        });
        if (this.readOnly) {
            this.domainForm.disable();
        }
    }

    isEnabled() {
        return this.partnerSettingsForm.contains['enableSMSValidation'];
    }

    onPartnerSettingsUpdate(name: string) {
        const body = this.buildPartnerSettingsUpdateBody(name);
        if (body) {
            this.partnerSettingsService.updatePartnerSettings(this.partnerSettingsForm.controls['idCtrl'].value, body).subscribe((partnersSettings: PartnerSettings) => {
                this.initializePartnerSettings(partnersSettings);
            });
        }
    }

    onPartnerSettingsUpdateUriAndCredentials(reset?: any) {
        const body = {
            memberCallbackUri: this.partnerSettingsForm.get('memberCallbackUriCtrl').value,
            paymentCallbackUri: this.partnerSettingsForm.get('paymentCallbackUriCtrl').value,
            username: this.partnerSettingsForm.get('usernameCtrl').value,
            password: this.partnerSettingsForm.get('passwordCtrl').value
        };
        this.partnerSettingsService.updatePartnerSettings(this.partnerSettingsForm.controls['idCtrl'].value, body).subscribe((partnersSettings: PartnerSettings) => {
            this.initializePartnerSettings(partnersSettings);
            reset();
        }, (error: any) => {
            reset();
            throw error;
        });
    }

    buildPartnerSettingsUpdateBody(name: string) {
        switch (name) {
            case 'enableSMSValidation': {
                return {enableSMSValidation: this.partnerSettingsForm.get(name).value};
            }
            case  'acceptPayments': {
                return {acceptPayments: this.partnerSettingsForm.get(name).value};
            }
            case 'sendPayments': {
                return {sendPayments: this.partnerSettingsForm.get(name).value};
            }
            default: {
                return null;
            }
        }
    }

    onDisableKey(key: PartnerKey) {
        if (!this.readOnly) {
            key.status = key.status === PartnerKeyStatus.ENABLED ? PartnerKeyStatus.DISABLED : PartnerKeyStatus.ENABLED;
            this.partnerSettingsService.updateKey(key).subscribe(() => {
                this.loadKeys();
            });
        }
    }

    loadKeys() {
        this.partnerSettingsService.loadKeysByMemberId(this.memberId).subscribe((apiKeys: PartnerKey[]) => {
            this.keys = apiKeys;
            this.canCreate = apiKeys.length < 10 && !this.authService.isAuthority();
        });
    }

    onDomainDelete(id: string) {
        if (!this.readOnly) {
            this.domainService.deleteDomainById(id).subscribe(() => {
                this.domainService.loadDomainsByMemberId(this.memberId).subscribe((response: PagedResponse<Domain>) => {
                    this.domains = response.content;
                });
            });
        }
    }

    onCreateDomain(reset: any) {
        const newDomain: Domain = {
            id: null,
            memberId: this.memberId,
            domain: this.domainForm.get('domainCtrl').value
        };
        this.domainService.createNewDomain(newDomain).subscribe(() => {
            this.domainService.loadDomainsByMemberId(this.memberId).subscribe((domains: PagedResponse<Domain>) => {
                this.domains = domains.content;
                this.domainForm.reset();
                reset();
            });
        });
    }

    onCreateKey(reset: any) {
        const keyReq = {
            memberId: this.memberId,
            prod: true
        };
        this.partnerSettingsService.createKey(keyReq).subscribe((resp: PartnerKey) => {
            this.keys = [...this.keys, resp];
            this.canCreate = this.keys.length < 10 && !this.authService.isAuthority();
            reset();
        },
        (error: any) => {
            reset();
        });
    }

    isPartner() {
        return this.authService.hasMemberFunction(MemberFunction.PARTNER);
    }

    onShowEditStyleWindow() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            landingCustomizationForm: this.landingCustomizationForm,
            memberId: this.memberId
        };
        const dialog = this.dialog.open(LandingPageStyleModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.partnerService.getCustomizationPageSettings(this.memberId).subscribe((landingSettingsReq: LandingSettings) => {
                    this.landingCustomizationForm = this.landingSettingsFormService.initializeForm(landingSettingsReq);
                });
            }
        });
    }

    domainFormValid() {
        return this.domainForm.valid;
    }

    isPartnerWebhooksValid() {
        return this.partnerSettingsForm.dirty && this.partnerSettingsForm.valid;
    }

    onBasicAuthChanged() {
        const usernameCtrl = this.partnerSettingsForm.get('usernameCtrl');
        const passwordCtrl = this.partnerSettingsForm.get('passwordCtrl');
        if (usernameCtrl.value || passwordCtrl.value) {
            usernameCtrl.setValidators(Validators.required);
            passwordCtrl.setValidators(Validators.required);
        } else {
            usernameCtrl.clearValidators();
            passwordCtrl.clearValidators();
        }
        usernameCtrl.updateValueAndValidity();
        passwordCtrl.updateValueAndValidity();
    }

    canCreatable() {
        return (this.canCreate && !this.readOnly);
    }

}
