import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableUtils } from 'projects/components/src/lib/table-utils.service';
import { BankAccount, BankAccountService, KeyParty, Member, MemberAccount, MemberAccountType, MemberType, PagedResponse, SearchService, UserAccount } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-search-header',
    templateUrl: './search-header.component.html',
    styleUrls: ['./search-header.component.scss']
})
export class SearchHeaderComponent implements OnInit {

    EntitySearchType = EntitySearchType;

    searchType = EntitySearchType.MEMBER;
    searchTerm = '';

    memberCount = 0;
    memberAccountCount = 0;
    merchantCount = 0;
    userCount = 0;
    keyPartyCount = 0;
    consumerCount = 0;
    bankAccountCount = 0;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private searchService: SearchService,
                private bankAccountService: BankAccountService,
                private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['searchTerm'] || queryParams['_searchType']) {
            this.searchType = queryParams['_searchType'] || EntitySearchType.MEMBER;
            this.searchTerm = queryParams['searchTerm'] || this.searchTerm;
        } else {
            this.updateQueryParams(true);
        }
        this.updateCounts();
        this.route.queryParams.subscribe((params) => {
            this.searchType = params['_searchType'] || EntitySearchType.MEMBER;
            const enteredSearch = params['searchTerm'] || this.searchTerm;
            if (this.searchTerm !== enteredSearch) {
                this.searchTerm = enteredSearch;
                this.updateCounts();
                this.updateQueryParams(true);
            }
        });
    }

    setSearchType(searchType: EntitySearchType) {
        if (searchType === this.searchType) {
            return;
        }
        this.searchType = searchType;
        this.cdr.detach();
        TableUtils.clearSubscriptions();
        this.cdr.reattach();
        this.updateQueryParams(false);
    }

    getTabClass(type: EntitySearchType) {
        if (this.searchType === type) {
            return 'tab-navigation-selected';
        } else {
            return 'tab-navigation';
        }
    }

    updateCounts() {
        this.updateMemberCount();
        this.updateMemberAccountCount();
        this.updateMerchantCount();
        this.updateUserCount();
        this.updateKeyPartyCount();
        this.updateConsumerCount();
        this.updateBankAccountCount();
    }

    updateMemberCount() {
        this.searchService.searchMembersBySearchTerm(this.searchTerm, [MemberType.BUSINESS], 0, 10, '', '').subscribe((response: PagedResponse<Member>) => {
            this.memberCount = response.totalElements;
        });
    }

    updateMemberAccountCount() {
        this.searchService.searchMemberAccountsBySearchTerm(this.searchTerm, [MemberAccountType.BUSINESS], 0, 10, '', '').subscribe((response: PagedResponse<MemberAccount>) => {
            this.memberAccountCount = response.totalElements;
        });
    }

    updateMerchantCount() {
        this.searchService.searchMembersBySearchTerm(this.searchTerm, [MemberType.INDIVIDUAL_MERCHANT, MemberType.BUSINESS_MERCHANT], 0, 10, '', '').subscribe((response: PagedResponse<Member>) => {
            this.merchantCount = response.totalElements;
        });
    }

    updateUserCount() {
        this.searchService.searchUserAccountsBySearchTerm(this.searchTerm, 0, 10, '', '').subscribe((response: PagedResponse<UserAccount>) => {
            this.userCount = response.totalElements;
        });
    }

    updateKeyPartyCount() {
        this.searchService.searchKeyPartiesBySearchTerm(this.searchTerm, 0, 10, '', '').subscribe((response: PagedResponse<KeyParty>) => {
            this.keyPartyCount = response.totalElements;
        });
    }

    updateConsumerCount() {
        this.searchService.searchMembersBySearchTerm(this.searchTerm, [MemberType.CONSUMER], 0, 10, '', '').subscribe((response: PagedResponse<Member>) => {
            this.consumerCount = response.totalElements;
        });
    }

    updateBankAccountCount() {
        this.bankAccountService.searchBankAccounts(this.searchTerm, 0, 10, '', '').subscribe((response: PagedResponse<BankAccount>) => {
            this.bankAccountCount = response.totalElements;
        });
    }

    updateQueryParams(replace: boolean) {
        let sort = 'sortName';
        switch (this.searchType) {
            case EntitySearchType.BANK_ACCOUNT:
            case EntitySearchType.KEY_PARTIES:
                sort = 'created';
                break;
            case EntitySearchType.USERS:
                sort = 'lastName';
                break;
            case EntitySearchType.MEMBER_ACCOUNT:
                sort = 'member.sortName';
                break;
        }
        const queryParams = { _searchType: this.searchType || null, searchTerm: this.searchTerm, page: 1, num: 10, sort, dir: 'asc' };
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }
}

export enum EntitySearchType {
    MEMBER = 'MEMBER',
    MEMBER_ACCOUNT = 'MEMBER_ACCOUNT',
    USERS = 'USERS',
    KEY_PARTIES = 'KEY_PARTIES',
    MERCHANT = 'MERCHANT',
    CONSUMER = 'CONSUMER',
    BANK_ACCOUNT = 'BANK_ACCOUNT'

}
