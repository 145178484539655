import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LendingKPI } from '../models/lending/lending-KPI';
import { Scenario } from '../models/scenario';

@Injectable({
    providedIn: 'root'
})
export class ScenarioFormService {

    constructor(private formBuilder: UntypedFormBuilder) {}

    initializeForm(scenario: Scenario): UntypedFormGroup {
        const form = this.formBuilder.group({
            idCtrlCtrl: new UntypedFormControl(),
            createdCtrl: new UntypedFormControl(),
            memberIdCtrl: new UntypedFormControl(),
            titleCtrl: new UntypedFormControl('', Validators.required),
            activeCtrl: new UntypedFormControl(),
            kpis: this.formBuilder.array([this.createEmptyKpi()])
        });
        if (scenario) {
            form.patchValue({
                idCtrlCtrl: scenario.id,
                createdCtrl: scenario.created,
                memberIdCtrl: scenario.memberId,
                titleCtrl: scenario.title,
                activeCtrl: scenario.active,
                kpis: this.createKpiForm(scenario.kpis)
            });
        }
        return form;
    }

    createEmptyKpi(): UntypedFormGroup {
        return this.formBuilder.group({
            idCtrl: new UntypedFormControl(),
            createdCtrl: new UntypedFormControl(),
            nameCtrl: new UntypedFormControl('', Validators.required),
            conditionCtrl: new UntypedFormControl('', Validators.required),
            amountCtrl: new UntypedFormControl('', Validators.required)
        });
    }

    createKpiForm(kpis: LendingKPI[]): UntypedFormArray {
        const form = new UntypedFormArray([]);
        for (const kpi of kpis) {
            form.push(this.formBuilder.group({
                idCtrl: new UntypedFormControl(kpi.id),
                createdCtrl: new UntypedFormControl(kpi.created),
                nameCtrl: new UntypedFormControl(kpi.name, Validators.required),
                conditionCtrl: new UntypedFormControl(kpi.kpiCondition, Validators.required),
                amountCtrl: new UntypedFormControl(kpi.amount, Validators.required)
            }));
        }
        return form;
    }
}
