<div [class.show-wide]="showCompare" class="dashboard-info">
    <div class="row">
        <div *ngIf="contract" class="col-12">
            <div class="pull-right navigation">
                <button *ngIf="contract.sellerAccepted && contract.buyerAccepted" class="blue-button pull-right" type="button" (click)="submit('EXECUTING')" [disabled]="!templateValid()"><i class="fa fa-check pull-left dashboard-icon"></i><div class="pull-right-button">Execute Contract</div></button>
                <button *ngIf="!contract.sellerAccepted && contract.buyerAccepted" class="blue-button pull-right" type="button" (click)="submit('SELLER_REVIEW')" [disabled]="!templateValid()"><i class="fa fa-arrow-right pull-left dashboard-icon"></i><div class="pull-right-button">Propose Changes</div></button>
                <button class="blue-button pull-right" type="button" (click)="submit('CANCELLED')"><i class="fa fa-times pull-left dashboard-icon"></i><div class="pull-right-button">Reject Contract</div></button>
                <button class="blue-button pull-right compare-button" type="button" *ngIf="!showCompare" (click)="onToggleCompare()"><i class="fa fa-file pull-left"></i><i class="fa fa-arrows-h pull-left"></i><i class="fa fa-file pull-left"></i><div class="pull-right-button">Compare</div></button>
                <button class="blue-button pull-right" type="button" *ngIf="showCompare" (click)="onToggleCompare()"><i class="fa fa-arrow-left pull-left dashboard-icon"></i><div class="pull-right-button">View Contract</div></button>
            </div>
            <div class="pull-left">
                <h3>Buyer Review</h3>
            </div>
            <div class="col-12 clear">
                <div class="row" *ngIf="contract && !showCompare">
                    <div class="col-2">
                        <div class="tab-col pull-left">
                            <div [ngClass]="getTabClass('PREVIEW_TEMPLATE')" (click)="setActiveTab('PREVIEW_TEMPLATE')">
                                Preview Template
                            </div>
                            <div [ngClass]="getTabClass('EDIT_TERMS')" (click)="setActiveTab('EDIT_TERMS')">
                                Edit Terms
                            </div>
                        </div>
                    </div>
                    <div class="col-10">
                        <div class="col-12 tab-body" *ngIf="activeTab === 'EDIT_TERMS'">
                            <pc-sc-configure-template [contractTemplate]="contract.contractTemplate" [enforceRequired]="true" (contractTemplateChange)="templateChanged($event)"></pc-sc-configure-template>
                        </div>
                        <div *ngIf="activeTab === 'PREVIEW_TEMPLATE'">
                            <div class="col-12 clear">
                                <pc-sc-section *ngFor="let section of contract.contractTemplate.sections; let i = index" [contractTemplate]="contract.contractTemplate" [index]="i">
                                </pc-sc-section>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row compare" *ngIf="showCompare">
                    <div class="col-6">
                        <h5>Last proposed by me</h5>
                        <hr>
                        <pc-sc-last-proposal [party]="ContractTemplateType.BUYER" [contractTemplateId]="contract.contractTemplate.id">
                        </pc-sc-last-proposal>
                    </div>
                    <div class="col-6">
                        <h5>Amended by counter-party</h5>
                        <hr>
                        <pc-sc-last-proposal [party]="ContractTemplateType.VENDOR" [contractTemplateId]="contract.contractTemplate.id">
                        </pc-sc-last-proposal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
