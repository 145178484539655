<div>
    <form>
        <div>
            <div class="modal-header">
                <label class="form-title">Financing Request</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content">
                <div class="row">
                    <div *ngIf="memberName" class="col-12">
                        <div class="col-12">
                            <label class="form-label">Company Name</label>
                        </div>
                        <div class="col-12 form-input-editable inputGroup">
                            {{memberName}}
                        </div>
                    </div>
                    <div *ngIf="financingRequest.companyType" class="col-6">
                        <div class="col-12">
                            <label class="form-label">Company Type</label>
                        </div>
                        <div class="col-12 form-input-editable inputGroup">
                            {{ financingRequest.companyType}}
                        </div>
                    </div>
                    <div *ngIf="financingRequest.requestAmount" class="col-6">
                        <div class="col-12">
                            <label class="form-label">Request Amount</label>
                        </div>
                        <div class="col-12 form-input-editable inputGroup">
                            ${{ financingRequest.requestAmount}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="financingRequest.requestTerm" class="col-6">
                        <div class="col-12">
                            <label class="form-label"> Request Term (Month) </label>
                        </div>
                        <div class="col-12 form-input-editable inputGroup">
                            {{financingRequest.requestTerm}}
                        </div>
                    </div>
                    <div *ngIf="financingRequest.revenue" class="col-6">
                        <div class="col-12">
                            <label class="form-label"> Revenue </label>
                        </div>
                        <div class="col-12 form-input-editable inputGroup">
                            ${{financingRequest.revenue}}
                        </div>
                    </div>
                    <div *ngIf="financingRequest.arTotal" class="col-6">
                        <div class="col-12">
                            <label class="form-label"> A/R Total </label>
                        </div>
                        <div class="col-12 form-input-editable inputGroup">
                            ${{financingRequest.arTotal}}
                        </div>
                    </div>
                    <div *ngIf="financingRequest.ebitda" class="col-6">
                        <div class="col-12">
                            <label class="form-label"> EBITDA </label>
                        </div>
                        <div class="col-12 form-input-editable inputGroup">
                            {{financingRequest.ebitda}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="financingRequest.note" class="col-12">
                        <label class="form-label"> Notes</label>
                        <p>{{financingRequest.note}}</p>
                    </div>
                    <div class="col-12">
                        <p>
                            <b>
                            Candidate has consented to 5 day access to their Confia Account
                            for expedient diligence.
                            </b>
                        </p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="blue-button" id="decline-btn" (click)="onDecline()">Decline</button>
                <button class="blue-button" id="proceed-btn" (click)="onProceed()">Proceed</button>
            </div>
        </div>
    </form>
</div>
