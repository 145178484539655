<div class="row wizard">
    <div class="pull-right navigation">
        <button class="blue-button pull-right" (click)="shareTemplate(false)" type="button" [disabled]="!updatedInvitees.length || !modified"><i class="fa fa-share-alt pull-left dashboard-icon"></i><div class="pull-right-button">Share With Selected</div></button>
        <button class="blue-button pull-right" *ngIf="contractTemplate.visibility === ContractTemplateVisibility.LIMITED" (click)="shareTemplate(true)" type="button"><i class="fa fa-share-alt pull-left dashboard-icon"></i><div class="pull-right-button">Share Publicly</div></button>
    </div>
    <div class="pull-left">
        <h3>Share Template</h3>
    </div>
    <div class="col-12 clear">
        <div *ngIf="contractTemplate.visibility === ContractTemplateVisibility.PUBLIC">
            This template is current shared publicly.  Select members below to limit the visibility to the members selected below:
        </div>
        <div *ngIf="contractTemplate.visibility === ContractTemplateVisibility.LIMITED">
            This template is limited to the members selected below.  Change the members with which you wish to share the template, or select 'Share Publicly'.
        </div>
        <pc-sc-business-parties-list [invitees]="invitees" (membersInvited)="updateInvitees($event)">
        </pc-sc-business-parties-list>
    </div>
</div>
