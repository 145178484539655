<div class="external-system-registration" [class.not-linked]="!connected">
    <div>
        <div class="blue-background">
            <h1>Accounting</h1>
        </div>
    </div>
    <div class="content">
        <div class="col-12">
            <h5>Connect your accounting system to easily access your vendors and customers. Accounting data can also be used to reconcile transactions against your accounting entries.</h5>
            <h5>Currently supported platforms for reconciliation:</h5>
            <ul>
                <li *ngFor="let pushPlatform of supportedPushPlatforms">{{pushPlatform}}</li>
            </ul>
            <h5>Reconciliation account linking can be found on the Account Settings tab under each
                <a *ngIf="authService.isAuthorityOrReviewer()" [routerLink]="['/administration/member/' + memberId]" [queryParams]="{_activeTab: 'accounts'}" [routerLinkActive]="['router-link-active']">account</a>
                <a *ngIf="!authService.isAuthorityOrReviewer()" [routerLink]="['/member-accounts/list']" [routerLinkActive]="['router-link-active']">account</a>
                .</h5>
        </div>
        <div *ngIf="loading" class="d-flex justify-content-center mt-5">
            <i class="fa fa-spin fa-spinner"></i>Checking status...
        </div>
        <div *ngIf="registering" class="d-flex justify-content-center mt-5">
            <i class="fa fa-spin fa-spinner"></i>Waiting on accounting system...
        </div>
        <div *ngIf="!loading && !registering ">
            <div class="button-panel row centered">
                <div *ngIf="!connected && authService.isAdmin()">
                    <button pc-no-dbl-click class="blue-button" type="button" (click)="registerAccountingSystem(true)" submit-button>Link</button>
                </div>
                <div *ngIf="connected && authService.isAdmin()">
                    <button pc-no-dbl-click class="blue-button" type="button" (click)="registerAccountingSystem(true)" submit-button>Unlink</button>
                </div>
                <div *ngIf="!connected && !authService.isAdmin()">
                    Please have your administrator link this member.
                </div>
                <div>
                <span class="row" *ngIf="connected">
                    <p><i class="fa fa-check-circle-o"></i>Connected to {{accountingSystemDetails.platform}}</p>
                </span>
                    <span class="row" *ngIf="!connected && (!accountingSystemDetails || accountingSystemDetails?.status === 'PendingAuth')" >
                    <p><i class="fa fa-times"></i>Not Connected</p>
                </span>
                    <span class="row" *ngIf="!connected && accountingSystemDetails?.status === 'Deauthorized'" >
                    <p><i class="fa fa-times"></i>Connection Error</p>
                </span>
                </div>
            </div>
        </div>
    </div>
</div>
