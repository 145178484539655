<div>
    <form [formGroup]="contractForm">
        <div>
            <div class="modal-header">
                <label class="form-title">Initiate New Contract</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div *ngIf="contractsLoaded && contractTemplates.length" class="modal-content">
                <div>
                    <pc-form-selector [required]="true" [label]="'Select Contract Template'"  [formCtrl]="'templateCtrl'" (selected)="selectTemplate()"
                        [formGroup]="contractForm" [options]="contractTemplates" [idValue]="'id'" [displayValue]="'name'" [defaultValue]="''">
                    </pc-form-selector>

                    <pc-text-input [required]="true" [label]="'Contract Name'" [placeholder]="'Contract Name'"
                        [formCtrl]="'nameCtrl'" [formGroup]="contractForm">
                    </pc-text-input>

                    <dl *ngIf="selectedTemplate">
                        <dt>Owner:</dt>
                        <dd>{{selectedTemplate.creatorName}}</dd>
                        <dt>Created:</dt>
                        <dd>{{selectedTemplate.created | date}}</dd>
                        <dt>Initiate Contract as:</dt>
                        <dd>{{(selectedTemplate.templateType === ContractTemplateType.VENDOR ? ContractTemplateType.BUYER : ContractTemplateType.VENDOR) | translate}}</dd>
                    </dl>

                    <pc-form-amount-input [required]="true" [label]="'Sales Tax (%)'" [formCtrl]="'salesTaxCtrl'"
                        [formGroup]="contractForm" [dollar]="false" [placeholder]="''">
                    </pc-form-amount-input>

                    <!-- <pc-form-amount-input [required]="true" [label]="'Business Tax'" [formCtrl]="'businessTaxCtrl'"
                        [formGroup]="contractForm" [dollar]="false">
                    </pc-form-amount-input> -->
                </div>
            </div>
            <div *ngIf="contractsLoaded && !contractTemplates.length" class="modal-content">
                <p>No contracts templates have been shared with you.</p>
            </div>
            <div *ngIf="!contractsLoaded" class="modal-content">
                <div class="waiting">
                    <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                </div>
            </div>
            <div *ngIf="errorMessage" class="error-message">
                <label class="form-error">{{errorMessage}}</label>
            </div>
            <div *ngIf="contractTemplates.length" class="modal-footer">
                <pc-submit-button [enabled]="isContractFormValid" [submit]="onSubmit" [text]="'Initiate Contract'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
