<div class="col-sm-12 horizontal-scroll mat-table-container">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <mat-paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    <table mat-table [dataSource]="weeklyReports" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="created_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Date </th>
            <td mat-cell *matCellDef="let element">  {{ element.startDate  | date:'mediumDate' }} </td>
        </ng-container>
        <ng-container matColumnDef="total_debits">
            <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header="totalDebits"> Sum Of All Debits </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <span class="table-amount">
                    {{ element.totalDebits | currency }}
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="total_credits">
            <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header="totalCredits"> Sum Of All Credits </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <span class="table-amount">
                    {{ element.totalCredits | currency }}
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="copy_of_file">
            <th class="centered" mat-header-cell *matHeaderCellDef> Copy Of File </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <a class="dashboard-list-a" (click)="downloadResource(element)">
                    <i class="fontAwesome fa fa-file-excel-o"></i>
                </a>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator (page)="handlePageBottom($event)" [pageIndex]="paginator.pageIndex" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
</div>
<a class="report-download-link" #downloadReportLink></a>
