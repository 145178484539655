import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Configuration, NavigationLinks } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-authority-sidebar',
    templateUrl: './authority-sidebar.component.html'
})
export class AuthoritySidebarComponent implements OnInit {

    @Input() expandedMenu: boolean;
    @Input() readOnly = false;

    @Output() toggleExpandSideMenu: EventEmitter<any> = new EventEmitter<any>();

    menuActions: any[] = [];

    ngOnInit() {
        this.menuActions.push(NavigationLinks.dashboardLink);
        this.menuActions.push(NavigationLinks.memberAdministrationLink);
        if (Configuration.getConfig().lendingEnabled) {
            this.menuActions.push(NavigationLinks.lendingLink);
        }
        this.menuActions.push(NavigationLinks.authorityTransactionLink);
        this.menuActions.push(NavigationLinks.authorityRfiLink);
        this.menuActions.push(NavigationLinks.authorityReportsLink);
        if (!this.readOnly) {
            this.menuActions.push(NavigationLinks.authorityAuditLink);
            this.menuActions.push(NavigationLinks.settingsLink);
        }
    }

    getMenuClass() {
        if (this.expandedMenu) {
            return 'sidenav-wide';
        } else {
            return 'sidenav-narrow';
        }
    }

    onToggleExpandSideMenu() {
        this.toggleExpandSideMenu.emit('toggle');
    }
}
