import { ContractSectionTerm } from './contract-section-term';
import { FieldMetadata } from './field-metadata';

export class ContractSection {

    index: string;
    sectionName: string;
    required: boolean;
    included: boolean;
    satisfied: boolean;
    terms: ContractSectionTerm[];
    fieldMap: { key: string, field: FieldMetadata }[];
    keys: string[];

}
