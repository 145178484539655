import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { FinancialDetails } from '../models/financial-details';
import { CustomValidators } from '../validators/custom.validators';
import { Utils } from '../util.service';

@Injectable({
    providedIn: 'root'
})
export class FinancialDetailsFormService {

    businessActivities: string [];

    constructor(private formbuilder: UntypedFormBuilder) {
        this.businessActivities = [
            'Cultivator',
            'Manufacturer',
            'Microbusiness',
            'Medical Dispensary',
            'Retailer',
            'Distributor',
            'Transportation/Transport-only Distributor',
            'Testing'
        ];
    }

    initializeForm(financialDetails: FinancialDetails, isFinancialMember: boolean): UntypedFormGroup {
        const financialDetailsForm = this.formbuilder.group({
            idCtrl: new UntypedFormControl(),
            createdCtrl: new UntypedFormControl(),
            updatedCtrl: new UntypedFormControl(),
            memberIdCtrl: new UntypedFormControl(),
            expectedMonthlyCashDepositCtrl: new UntypedFormControl(isFinancialMember ? '' : 0, [Validators.required]),
            expectedMonthlyDepositsCtrl: new UntypedFormControl('', [Validators.required]),
            expectedMonthlyPaymentsCtrl: new UntypedFormControl('', [Validators.required]),
            expectedMonthlyNumberOfReceiptsCtrl: new UntypedFormControl('', [Validators.required, Validators.pattern('^([0-9]{1,6})$')]),
            expectedMonthlyNumberOfPaymentsCtrl: new UntypedFormControl('', [Validators.required, Validators.pattern('^([0-9]{1,6})$')]),
            percentOfRevenueFromCannabisCtrl: new UntypedFormControl('', [Validators.required, Validators.min(0), Validators.max(100), CustomValidators.onlyNumeric]),
            percentOfRevenueFromCannabisPartnersCtrl: new UntypedFormControl('', [Validators.required, Validators.min(0), Validators.max(100), CustomValidators.onlyNumeric]),
            percentOfRevenueFromCashCtrl: new UntypedFormControl('', [Validators.required, Validators.min(0), Validators.max(100), CustomValidators.onlyNumeric]),
            projectedAnnualRevenueCtrl: new UntypedFormControl(''),
            plannedInitialDepositAmountCtrl: new UntypedFormControl(isFinancialMember ? '' : 0, [Validators.required]),
            initialDepositSourceCtrl: new UntypedFormControl('', [Validators.maxLength(256), CustomValidators.noLeadingOrTrailingWhitespace])
        });
        if (financialDetails) {
            financialDetailsForm.patchValue({
                idCtrl: financialDetails.id,
                createdCtrl: financialDetails.created,
                updatedCtrl: financialDetails.updated,
                memberIdCtrl: financialDetails.memberId,
                expectedMonthlyCashDepositCtrl: financialDetails.expectedMonthlyCashDepositAmount,
                expectedMonthlyDepositsCtrl: financialDetails.expectedMonthlyDepositAmount,
                expectedMonthlyPaymentsCtrl: financialDetails.expectedMonthlyWithdrawals,
                expectedMonthlyNumberOfReceiptsCtrl: financialDetails.expectedMonthlyNumberOfReceipts,
                expectedMonthlyNumberOfPaymentsCtrl: financialDetails.expectedMonthlyNumberOfPayments,
                percentOfRevenueFromCannabisCtrl: financialDetails.percentOfRevenueFromCannabis,
                percentOfRevenueFromCannabisPartnersCtrl: financialDetails.percentOfRevenueFromCannabisPartners,
                percentOfRevenueFromCashCtrl: financialDetails.percentOfRevenueFromCash,
                projectedAnnualRevenueCtrl: financialDetails.projectedAnnualRevenue,
                plannedInitialDepositAmountCtrl: financialDetails.plannedInitialDepositAmount,
                initialDepositSourceCtrl: financialDetails.initialDepositSource
            });
            financialDetailsForm.addControl('businessActivitiesCtrl', this.getBusinessActivityCtrls(financialDetails.businessTypes));
        } else {
            financialDetailsForm.addControl('businessActivitiesCtrl', this.getBusinessActivityCtrls([]));
        }
        return financialDetailsForm;
    }

    getBusinessActivityCtrls(businessTypes: string[]): UntypedFormArray {
        const activities = new UntypedFormArray([]);
        for (const businessActivity of this.businessActivities) {
            activities.push(new UntypedFormControl(businessTypes.indexOf(businessActivity) > -1));
        }
        return activities;
    }

    getFinancialDetails(form: UntypedFormGroup): FinancialDetails {
        const financialDetails = new FinancialDetails();
        financialDetails.id = form.get('idCtrl').value;
        financialDetails.created = form.get('createdCtrl').value;
        financialDetails.updated = form.get('updatedCtrl').value;
        financialDetails.memberId = form.get('memberIdCtrl').value;
        financialDetails.expectedMonthlyCashDepositAmount = Utils.parseFloatIgnoreCommas(form.get('expectedMonthlyCashDepositCtrl').value);
        financialDetails.expectedMonthlyDepositAmount = Utils.parseFloatIgnoreCommas(form.get('expectedMonthlyDepositsCtrl').value);
        financialDetails.expectedMonthlyWithdrawals = Utils.parseFloatIgnoreCommas(form.get('expectedMonthlyPaymentsCtrl').value);
        financialDetails.expectedMonthlyNumberOfPayments = Utils.parseFloatIgnoreCommas(form.get('expectedMonthlyNumberOfPaymentsCtrl').value);
        financialDetails.expectedMonthlyNumberOfReceipts = Utils.parseFloatIgnoreCommas(form.get('expectedMonthlyNumberOfReceiptsCtrl').value);
        financialDetails.percentOfRevenueFromCannabis = Utils.parseFloatIgnoreCommas(form.get('percentOfRevenueFromCannabisCtrl').value);
        financialDetails.percentOfRevenueFromCannabisPartners = Utils.parseFloatIgnoreCommas(form.get('percentOfRevenueFromCannabisPartnersCtrl').value);
        financialDetails.percentOfRevenueFromCash = Utils.parseFloatIgnoreCommas(form.get('percentOfRevenueFromCashCtrl').value);
        financialDetails.projectedAnnualRevenue = Utils.parseFloatIgnoreCommas(form.get('projectedAnnualRevenueCtrl').value);
        financialDetails.plannedInitialDepositAmount = Utils.parseFloatIgnoreCommas(form.get('plannedInitialDepositAmountCtrl').value);
        financialDetails.initialDepositSource = form.get('initialDepositSourceCtrl').value;
        const businessActivities = form.controls['businessActivitiesCtrl'] as UntypedFormArray;
        const selectedActivities = [];
        for (let i = 0; i < this.businessActivities.length; i++) {
            if (businessActivities.at(i).value) {
                selectedActivities.push(this.businessActivities[i]);
            }
        }
        financialDetails.businessTypes = selectedActivities;
        return financialDetails;
    }

    getBusinessActivities() {
        return this.businessActivities;
    }

}
