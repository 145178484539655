import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Transaction, CustomValidators, CurrencyWithdrawal } from 'projects/services/src/public-api';
@Injectable({
    providedIn: 'root'
})
export class TransactionFormService {

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    initializeDepositForm(): UntypedFormGroup {
        return this.formBuilder.group({
            sourceCtrl: new UntypedFormControl('', [Validators.required]),
            memberAccountNameCtrl: new UntypedFormControl('', [Validators.required]),
            transactionTypeCtrl: new UntypedFormControl('', [Validators.required]),
            transactionDateCtrl: new UntypedFormControl('', [Validators.required]),
            notesCtrl: new UntypedFormControl('', [Validators.maxLength(1000), Validators.minLength(10)]),
            currentBusinessClientCtrl: new UntypedFormControl(),
            currentBusinessClientNameCtrl: new UntypedFormControl('')
        });
    }

    initializePaymentForm(): UntypedFormGroup {
        return this.formBuilder.group({
            sourceCtrl: new UntypedFormControl('', [Validators.required]),
            memberAccountNameCtrl: new UntypedFormControl('', [Validators.required]),
            transactionTypeCtrl: new UntypedFormControl('', [Validators.required]),
            transactionDateCtrl: new UntypedFormControl('', [Validators.required]),
            notesCtrl: new UntypedFormControl('', [Validators.maxLength(1000), Validators.minLength(10)]),
            currentBusinessClientCtrl: new UntypedFormControl(),
            currentBusinessClientNameCtrl: new UntypedFormControl(''),
            paymentDateTypeCtrl: new UntypedFormControl('', [Validators.required])
        });
    }

    initializeWireRemittanceForm(): UntypedFormGroup {
        return this.formBuilder.group({
            notesToReceiverCtrl: new UntypedFormControl('', [Validators.maxLength(135), Validators.pattern('[a-zA-Z\\d\\s]*')])
        });
    }

    getTransaction(form: UntypedFormGroup): Transaction {
        const transaction = new Transaction();
        transaction.source = form.get('sourceCtrl').value;
        transaction.transactionType = form.get('transactionTypeCtrl').value;
        transaction.transactionDate = form.get('transactionDateCtrl').value?.singleDate?.jsDate;
        transaction.notes = form.get('notesCtrl').value;
        return transaction;
    }

    initializeDepositWithdrawFundsForm(): UntypedFormGroup {
        return this.formBuilder.group({
            bankAccountIdCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
            transactionDateCtrl: new UntypedFormControl(),
            amountCtrl: new UntypedFormControl('', [Validators.required, Validators.min(0.01)]),
            notesCtrl: new UntypedFormControl('', [Validators.maxLength(1000)])
        });
    }

    getDepositFundsTransaction(form: UntypedFormGroup): Transaction {
        const transaction = new Transaction();
        transaction.bankAccountId = form.get('bankAccountIdCtrl').value;
        transaction.totalAmount = form.get('amountCtrl').value;
        transaction.notes = form.get('notesCtrl').value;
        return transaction;
    }

    getWithdrawFundsTransaction(transactionForm: UntypedFormGroup): Transaction {
        const transaction = new Transaction();
        transaction.bankAccountId = transactionForm.get('bankAccountIdCtrl').value;
        transaction.totalAmount = transactionForm.get('amountCtrl').value;
        transaction.notes = transactionForm.get('notesCtrl').value;
        return transaction;
    }

    initializeConfirmationForm(transaction: Transaction): UntypedFormGroup {
        return this.formBuilder.group({
            notesCtrl: new UntypedFormControl('', [Validators.maxLength(1000)]),
            requestedAmountCtrl: new UntypedFormControl(transaction.totalAmount),
            actualAmountCtrl: new UntypedFormControl(transaction.totalAmount, [Validators.required])
        });
    }

    initializeCurrencyWithdrawal(memberAccountName: string) {
        return this.formBuilder.group({
            amountCtrl: new UntypedFormControl('', [Validators.required, Validators.min(0.01)]),
            memberAccountNameCtrl: new UntypedFormControl(memberAccountName),
            cashDropOffDateCtrl: new UntypedFormControl('', [Validators.required]),
            cashDropOffLocationIdCtrl: new UntypedFormControl('', [Validators.required]),
            notesCtrl: new UntypedFormControl('', [Validators.maxLength(256), CustomValidators.noLeadingOrTrailingWhitespace])
        });
    }

    getCurrencyWithdrawal(form: UntypedFormGroup): CurrencyWithdrawal {
        const currencyWithdrawal = new CurrencyWithdrawal();
        currencyWithdrawal.totalAmount = form.get('amountCtrl').value;
        currencyWithdrawal.dropOffDate = form.get('cashDropOffDateCtrl').value?.singleDate?.jsDate;
        currencyWithdrawal.dropOffLocationId = form.get('cashDropOffLocationIdCtrl').value;
        currencyWithdrawal.notes = form.get('notesCtrl').value;
        return currencyWithdrawal;
    }
}
