import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaderService } from './header.service';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(private http: HttpClient,
                private headerService: HttpHeaderService) {
    }

    get(url: string, responseType?: string, params?: any) {
        const headers = this.headerService.getAuthHeader();
        const options = {
            headers,
            params
        };
        if (responseType) {
            options['responseType'] = responseType;
        }
        return this.http.get(url, options);
    }

    getDownload(url: string) {
        const headers = this.headerService.getAuthHeader();
        return this.http.get(url, { headers, responseType: 'blob' as 'json' });
    }

    head(url: string) {
        const headers = this.headerService.getAuthHeader();
        return this.http.head(url, { observe: 'response', headers });
    }

    post<T1>(url: string, data: T1): any;
    post<T1, T2>(url: string, data: T1, responseType: T2): any;
    post<T1, T2, T3>(url: string, data: T1, responseType: T2, contentType: T3): any;

    post(url: string, ...args: any[]): any {
        const headers = this.headerService.getAuthHeader();
        if (args.length === 3) {
            headers.set('Content-Type:', args[2]);
        }
        const options = {
            headers
        };

        if (args.length >= 2) {
            options['responseType'] = args[1];
        }
        return this.http.post(url, args[0], options);
    }

    put(url: string, data: any): any {
        return this.http.put(url, data, {
            headers: this.headerService.getAuthHeader()
        });
    }

    delete(url: string) {
        return this.http.delete(url, {
            headers: this.headerService.getAuthHeader()
        });
    }

}
