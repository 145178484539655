import { Inject, Injectable } from '@angular/core';
import { MetrcSummary } from '../public-api';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class RecordsService {

    baseUrl: string;

    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.RecordsAPIEndpoint + '/';
    }

    getExpense(id: string) {
        return this.http.get(`${this.baseUrl}expense/${id}`);
    }

    getInvoice(id: string){
        return this.http.get(`${this.baseUrl}income/${id}`);
    }

    findMetrcReceiptsByOwner(memberId: string, startDate: string, endDate: string,  start: number, size: number, sort: string, sortDir: string) {
        const params = `?memberId=${memberId}&startDate=${startDate}&endDate=${endDate}&timezone=${this.timezone}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}receipt${params}`);
    }

    findInvoicesByOwnerAndCustomer(memberId: string, customerExternalId: string) {
        const params = `?memberId=${memberId}&customerExternalId=${customerExternalId}`;
        return this.http.get(`${this.baseUrl}income${params}`);
    }

    findInvoicesByOwnerAndBusinessClient(memberId: string, businessClientMemberId: string) {
        const params = `?memberId=${memberId}&businessClientId=${businessClientMemberId}`;
        return this.http.get(`${this.baseUrl}income${params}`);
    }

    findExpensesByOwnerAndVendor(memberId: string, vendorExternalId: string) {
        const params = `?memberId=${memberId}&vendorExternalId=${vendorExternalId}`;
        return this.http.get(`${this.baseUrl}expense${params}`);
    }

    findExpensesByOwnerAndBusinessClient(memberId: string, businessClientId: string) {
        const params = `?memberId=${memberId}&businessClientId=${businessClientId}`;
        return this.http.get(`${this.baseUrl}expense${params}`);
    }

    findShipmentsByOwner(memberId: string, source: string, counterParty: string, startDate: string, endDate: string, unusedOnly: boolean, start: number, size: number, sort: string, sortDir: string) {
        const params = `?memberId=${memberId}&source=${source}&counterParty=${encodeURIComponent(counterParty)}&startDate=${startDate}&endDate=${endDate}&timezone=${this.timezone}&unusedOnly=${unusedOnly || false}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}shipment${params}`);
    }

    counterPartiesByMemberId(memberId: string, source: string) {
        return this.http.get(`${this.baseUrl}shipment/${memberId}/counterparties?source=${source}`);
    }

    findUnusedShipmentsByOwnerAndRecipient(memberId: string, recipientId: string, recipientName: string, start: number, size: number, sort: string, sortDir: string) {
        const params = `?memberId=${memberId}&recipientId=${recipientId || ''}&recipientName=${encodeURI(recipientName) || ''}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}shipment/unused${params}`);
    }

    findUnusedShipmentsByOwnerAndShipper(memberId: string, shipperId: string, shipperName: string, start: number, size: number, sort: string, sortDir: string) {
        const params = `?memberId=${memberId}&shipperId=${shipperId || ''}&shipperName=${encodeURI(shipperName) || ''}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}shipment/unused${params}`);
    }

    findAllRelatedShipments(memberId: string, shipperId: string, recipientId: string,  start: number, size: number, sort: string, sortDir: string) {
        const params = `?memberId=${memberId}&shipperId=${shipperId}&recipientId=${recipientId}&&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}shipment/all${params}`);
    }

    getShipmentsForTransaction(transactionId: string, start: number, size: number, sort: string, sortDir: string) {
        const params = `?transactionId=${transactionId}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}shipment${params}`);
    }

    getPackagesForShipment(packageId: string, start: number, size: number, sort: string, sortDir: string) {
        const params = `/${packageId}/packages?start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}shipment${params}`);
    }

    getPackagesByOwnerAndManifestNumber(memberId: string, manifestNumber: string) {
        const params = `?memberId=${memberId}&manifest=${manifestNumber}`;
        return this.http.get(`${this.baseUrl}package${params}`);
    }

    findMetrcReceiptsByOwnerSummary(memberId: string, startDate: string, endDate: string, resultsLength: number) {
        const params = `?memberId=${memberId}&startDate=${startDate}&endDate=${endDate}&resultLength=${resultsLength}&timezone=${this.timezone}`;
        return this.http.get(`${this.baseUrl}receipt/summary${params}`);
    }

    getMetrcSummary(metrcSummaryInformation: Map<string, number>, labels: string[]): MetrcSummary[] {
        const receiptsSummaryList: MetrcSummary[] = [];
        labels.forEach((category: string) => {
            if ('ReceiptsTotalAmount' !== category) {
                let metrcSummary = new MetrcSummary();
                const value =  metrcSummaryInformation[category];
                metrcSummary.category = category;
                metrcSummary.total = value;
                receiptsSummaryList.push(metrcSummary);
            }
        });
        return receiptsSummaryList;
    }

    findShipmentsByOwnerSummary(memberId: string, source: string, counterParty: string, resultsLength: number, startDate: string, endDate: string) {
        const params = `?memberId=${memberId}&source=${source}&counterParty=${encodeURIComponent(counterParty)}&resultLength=${resultsLength}&startDate=${startDate}&endDate=${endDate}&timezone=${this.timezone}`;
        return this.http.get(`${this.baseUrl}shipment/summary${params}`);
    }

    findPossibleShipments(transactionId: string, start: number, size: number, sort: string, sortDir: string) {
        const params = `start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}possible-shipments/${transactionId}?${params}`);
    }

    getTransactionTrackingSupportEvidenceByTransactionId(transactionId: string) {
        return this.http.get(`${this.baseUrl}tracking-evidence/${transactionId}`);
    }

    getSupportEvidenceItemsByTrackingEvidenceId(trackingEvidenceId: string, start: number, size: number, sort: string, sortDir: string) {
        const params = `start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}tracking-evidence/support-evidence-items/${trackingEvidenceId}?${params}`);
    }

    findPackageSummaries(memberId: string, startDate: string, endDate: string, start: number, size: number) {
        const params = `memberId=${memberId}&startDate=${startDate}&endDate=${endDate}&timezone=${this.timezone}&start=${start}&size=${size}`;
        return this.http.get(`${this.baseUrl}package/track?${params}`);
    }

    findProductBalances(memberId: string, startDate: string, endDate: string) {
        const params = `memberId=${memberId}&startDate=${startDate}&endDate=${endDate}&timezone=${this.timezone}`;
        return this.http.get(`${this.baseUrl}package/trace?${params}`);
    }

    findProductPackageDetails(memberId: string, productCategory: string, quantityUnits: string, startDate: Date, endDate: Date, start: number, size: number) {
        const params = `memberId=${memberId}&productCategory=${productCategory}&quantityUnits=${quantityUnits}&startDate=${startDate}&endDate=${endDate}&timezone=${this.timezone}&start=${start}&size=${size}`;
        return this.http.get(`${this.baseUrl}package/product-category?${params}`);
    }

    findProductPackageTransferDetails(memberId: string, packageId: string, startDate: Date, endDate: Date, start: number, size: number) {
        const params = `memberId=${memberId}&packageId=${packageId}&startDate=${startDate}&endDate=${endDate}&timezone=${this.timezone}&start=${start}&size=${size}`;
        return this.http.get(`${this.baseUrl}package/product-category/transfers?${params}`);
    }
}
