import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import {
    AuthService, Configuration, CorporateEntity, MemberAccount, MemberAccountService, MemberAccountStatus, MemberAccountType, MemberType, PagedResponse, Utils, WalletFunction
} from 'projects/services/src/public-api';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'pc-member-account-selector',
    templateUrl: './member-account-selector.component.html',
    styleUrls: ['./member-account-selector.component.scss']
})

export class MemberAccountSelectorComponent implements OnInit, OnChanges, AfterViewInit {

    MemberAccountType = MemberAccountType;

    @Input() placeholder = 'Search Member Accounts';
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() displayLabel = true;
    @Input() accountTypes: MemberAccountType[] = [MemberAccountType.BUSINESS];
    @Input() accountStatuses: MemberAccountStatus[] = [MemberAccountStatus.ACTIVE];
    @Input() walletFunctions: WalletFunction[] = [WalletFunction.ACCEPT_BUSINESS_PAYMENTS];
    @Input() excludeSelf = false;
    @Input() excludedId = '';
    @Input() class = '';
    @Input() jurisdiction = '';
    @Input() includeAddress = false;
    @Input() required = false;


    @Output() memberAccountSelected = new EventEmitter<MemberAccount>();

    memberAccountSelect: Subscription;
    memberAccountAutoComplete: Observable<MemberAccount[]> = null;
    autoCompleteControl = new UntypedFormControl('');
    memberAccountOptions: MemberAccount[] = [];
    searching = false;
    searchLimit: number;
    totalResults: number;

    @ViewChild('memberAccountSelect', { read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;

    constructor(private authService: AuthService,
                private memberAccountService: MemberAccountService) {}

    ngOnInit() {
        this.searchLimit = Configuration.getConfig().autoCompleteSearchLimit;
        this.formGroup.addControl('autoCompleteCtrl', this.autoCompleteControl);
        if (this.required) {
            this.formGroup.controls['autoCompleteCtrl'].setValidators(Validators.required);
        }

        this.memberAccountAutoComplete = this.autoCompleteControl.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            debounceTime(1000),
            switchMap((value) => {
                this.searching = true;
                return this.lookup(value || '');
            })
        );
        this.loadMemberAccount();
        this.formGroup.controls[this.formCtrl].valueChanges.subscribe((_value) => {
            this.loadMemberAccount();
        });
    }

    ngAfterViewInit() {
        this.subscribeToClosingActions();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.statuses && !changes.statuses.firstChange) ||
            (changes.types && !changes.types.firstChange) ||
            (changes.source && !changes.source.firstChange)) {
            this.memberAccountOptions = [];
            this.memberAccountAutoComplete = this.autoCompleteControl.valueChanges.pipe(
                startWith(''),
                distinctUntilChanged(),
                debounceTime(1000),
                switchMap((value) => {
                    this.searching = true;
                    return this.lookup(value || '');
                })
            );
        }
    }

    private subscribeToClosingActions() {
        if (this.memberAccountSelect && !this.memberAccountSelect.closed) {
            this.memberAccountSelect.unsubscribe();
        }

        this.memberAccountSelect = this.trigger.panelClosingActions.subscribe((e) => {
            if (!e || !e.source) {
                if (this.memberAccountOptions.length === 1) {
                    this.memberAccountChanged(this.memberAccountOptions[0]);
                } else {
                    const selected = this.memberAccountOptions
                        .map((option) => {
                            return option.member.name;
                        })
                        .find((option) => {
                            return option === this.autoCompleteControl.value;
                        });

                    if (!selected) {
                        this.formGroup.controls[this.formCtrl].markAsPristine();
                        this.formGroup.controls[this.formCtrl].setValue('');
                        this.autoCompleteControl.setValue('');
                        this.memberAccountChanged(new MemberAccount());
                    }
                }
            }
        }, () => {
            return this.subscribeToClosingActions();
        }, () => {
            return this.subscribeToClosingActions();
        });
    }

    lookup(value: string) : Observable<MemberAccount[]> {
        this.totalResults = 0;
        if (value?.length <= 1) {
            this.searching = false;
            this.memberAccountOptions = [];
            return of([]);
        }
        this.autoCompleteControl.disable();
        return this.memberAccountService.searchMemberAccounts(value, this.accountTypes, this.accountStatuses, this.walletFunctions, this.jurisdiction, this.excludeSelf, true, this.searchLimit).pipe(
            switchMap((response: PagedResponse<MemberAccount>) => {
                this.totalResults = response.totalElements;
                if (this.excludedId) {
                    this.memberAccountOptions = response.content.filter((member) => {
                        return member.id !== this.excludedId;
                    });
                } else {
                    this.memberAccountOptions = response.content;
                }
                this.memberAccountOptions.forEach((member) => {
                    this.setMemberName(member);
                    if (member.address) {
                        member.address.country = Utils.getCountryCode(member.address.country);
                    }
                });
                this.searching = false;
                this.autoCompleteControl.enable();
                return of(this.memberAccountOptions);
            }), catchError((_error) => {
                this.searching = false;
                this.autoCompleteControl.enable();
                return [];
            })
        );
    }

    memberAccountChanged(memberAccount: MemberAccount) {
        this.formGroup.controls[this.formCtrl].markAsDirty();
        this.formGroup.controls[this.formCtrl].setValue(memberAccount.id);
        this.memberAccountSelected.emit(memberAccount);
    }

    onInputChanged(event: any) {
        if (event.keyCode === 220) {
            event.preventDefault();
            return;
        }
    }

    selected() {
        return this.formGroup.controls[this.formCtrl].value;
    }

    loadMemberAccount() {
        if (this.selected()) {
            this.memberAccountService.loadMemberAccount(this.formGroup.controls[this.formCtrl].value).subscribe((memberAccount: MemberAccount) => {
                this.setMemberName(memberAccount);
                this.autoCompleteControl.setValue(memberAccount.member.name);
            });
        } else {
            this.autoCompleteControl.setValue('');
        }
    }

    onIconClick(event: any) {
        if (this.selected()) {
            this.formGroup.controls[this.formCtrl].setValue('');
            this.memberAccountOptions = [];
            this.memberAccountSelected.emit(new MemberAccount());
            this.formGroup.controls['autoCompleteCtrl'].enable();
        }
        event.stopPropagation();
        this.trigger.openPanel();
    }

    setMemberName(memberAccount: MemberAccount) {
        if (memberAccount.member.memberType === MemberType.BUSINESS || memberAccount.member.memberType === MemberType.BUSINESS_MERCHANT) {
            const corporateEntity = (memberAccount.member as CorporateEntity);
            if (corporateEntity.dbaName && corporateEntity.name !== corporateEntity.dbaName) {
                corporateEntity.name = corporateEntity.name + ' (DBA: ' + corporateEntity.dbaName + ')';
            }
        }
    }

    getAccountName(memberAccount: MemberAccount) {
        let name = memberAccount.member.name;
        if (memberAccount.accountFriendlyName && memberAccount.accountFriendlyName.toLowerCase() !== memberAccount.member.name.toLowerCase()) {
            name += ` - ${memberAccount.accountFriendlyName}`;
        } else if (!memberAccount.accountFriendlyName && memberAccount.accountName.toLowerCase() !== memberAccount.member.name.toLowerCase()) {
            name += ` - ${memberAccount.accountName}`;
        }
        return name;
    }
}
