import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BillingProfile } from '../models/billing-profile';
import { Utils } from '../util.service';
import { CustomValidators } from '../validators/custom.validators';

@Injectable({
    providedIn: 'root'
})
export class BillingProfileFormService {

    constructor(private formbuilder: UntypedFormBuilder) { }

    initializeBillingProfile(billingProfile: BillingProfile): UntypedFormGroup {
        return this.formbuilder.group({
            idCtrl: new UntypedFormControl(billingProfile.id),
            createdCtrl: new UntypedFormControl(billingProfile.created),
            memberIdCtrl: new UntypedFormControl(billingProfile.memberId),
            memberAccountIdCtrl: new UntypedFormControl(billingProfile.memberAccountId),
            initialBillingCreditMonthsCtrl: new UntypedFormControl(billingProfile.initialBillingCreditMonths, [Validators.min(0), Validators.max(12)]),
            monthlyAccountFeeAmountCtrl: new UntypedFormControl(billingProfile.monthlyAccountFee, [Validators.min(0), Validators.max(10000000)]),
            retailConvenienceFeeAmountCtrl: new UntypedFormControl(billingProfile.retailConvenienceFeeAmount, [Validators.min(0), Validators.max(10000000)]),
            retailTransactionFeePercentageCtrl: new UntypedFormControl(billingProfile.retailTransactionFeePercentage, [Validators.min(0), Validators.max(100), CustomValidators.onlyTwoDecimals]),
            cashDepositBaseFeeCtrl: new UntypedFormControl(billingProfile.cashDepositBaseFee, [Validators.min(0), Validators.max(10000000)]),
            cashDepositFeePercentageCtrl: new UntypedFormControl(billingProfile.cashDepositFeePercentage, [Validators.min(0), Validators.max(100), CustomValidators.onlyTwoDecimals]),
            achWithdrawalFeeCtrl: new UntypedFormControl(billingProfile.achWithdrawalFee, [Validators.min(0), Validators.max(10000)]),
            achExpressPaymentFeeCtrl: new UntypedFormControl(billingProfile.achExpressPaymentFee, [Validators.min(0), Validators.max(10000)]),
            overdraftFeeCtrl: new UntypedFormControl(billingProfile.overdraftFee, [Validators.min(0), Validators.max(10000)]),
            wirePaymentFeeCtrl: new UntypedFormControl(billingProfile.wirePaymentFee, [Validators.min(0), Validators.max(10000)]),
            incomingWireFeeCtrl: new UntypedFormControl(billingProfile.incomingWireFee, [Validators.min(0), Validators.max(10000)])
        });
    }

    getBillingProfile(form: UntypedFormGroup): BillingProfile {
        const billingProfile = new BillingProfile();
        billingProfile.id = form.get('idCtrl').value;
        billingProfile.created = form.get('createdCtrl').value;
        billingProfile.memberId = form.get('memberIdCtrl').value;
        billingProfile.memberAccountId = form.get('memberAccountIdCtrl').value;
        billingProfile.retailConvenienceFeeAmount = form.get('retailConvenienceFeeAmountCtrl').value || 0;
        billingProfile.retailTransactionFeePercentage = Utils.parseFloatIgnoreCommas(form.get('retailTransactionFeePercentageCtrl').value) || 0;
        billingProfile.monthlyAccountFee = form.get('monthlyAccountFeeAmountCtrl').value || 0;
        billingProfile.cashDepositFeePercentage = Utils.parseFloatIgnoreCommas(form.get('cashDepositFeePercentageCtrl').value) || 0;
        billingProfile.cashDepositBaseFee = form.get('cashDepositBaseFeeCtrl').value || 0;
        billingProfile.achWithdrawalFee = form.get('achWithdrawalFeeCtrl').value || 0;
        billingProfile.initialBillingCreditMonths =  form.get('initialBillingCreditMonthsCtrl').value || 0;
        billingProfile.achExpressPaymentFee = form.get('achExpressPaymentFeeCtrl').value || 0;
        billingProfile.overdraftFee = form.get('overdraftFeeCtrl').value || 0;
        billingProfile.wirePaymentFee = form.get('wirePaymentFeeCtrl').value || 0;
        billingProfile.incomingWireFee = form.get('incomingWireFeeCtrl').value || 0;
        return billingProfile;
    }

}
