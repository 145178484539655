import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import {
    Configuration, CorporateEntity, MemberAccount, MemberAccountStatus, MemberStatus, MemberType, MerchantAccount, MerchantAccountService, PagedResponse, RiskTier
} from 'projects/services/src/public-api';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'pc-merchant-counter-party-searcher',
    templateUrl: './merchant-counter-party-searcher.component.html',
    styleUrls: ['./merchant-counter-party-searcher.component.scss']
})

export class MerchantCounterPartySearcherComponent implements OnInit, AfterViewInit {

    MemberAccountStatus = MemberAccountStatus;
    MemberType = MemberType;

    readonly STATUSES = [MemberAccountStatus.ACTIVE];

    @Input() memberId = '';
    @Input() jurisdiction = '';
    @Input() tier: RiskTier;
    @Input() label: string;
    @Input() placeholder = '';
    @Input() includeAddress = false;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;

    @Output() counterPartySelected = new EventEmitter<MemberAccount>();

    counterPartyAutoComplete: Observable<MemberAccount[]> = null;
    autoCompleteControl = new UntypedFormControl('', [Validators.required]);
    counterPartyOptions: MemberAccount[] = [];
    searching = false;
    counterPartySelect: Subscription;
    searchLimit: number;
    totalResults: number;

    @ViewChild('counterPartySelect', { read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;

    constructor(private merchantAccountService: MerchantAccountService) {}

    ngOnInit() {
        this.searchLimit = Configuration.getConfig().autoCompleteSearchLimit;
        this.formGroup.addControl('autoCompleteCtrl', this.autoCompleteControl);
        this.counterPartyAutoComplete = this.autoCompleteControl.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            debounceTime(1000),
            switchMap((value) => {
                this.searching = true;
                this.counterPartyOptions = [];
                return this.lookup(value || '');
            })
        );
    }

    ngAfterViewInit() {
        this.subscribeToClosingActions();
    }

    private subscribeToClosingActions() {
        if (this.counterPartySelect && !this.counterPartySelect.closed) {
            this.counterPartySelect.unsubscribe();
        }

        this.counterPartySelect = this.trigger.panelClosingActions.subscribe((e) => {
            if (!e || !e.source) {
                if (this.counterPartyOptions.length === 1) {
                    const option = this.counterPartyOptions[0];
                    if (option.status === MemberAccountStatus.DISABLED || option.member.status === MemberStatus.DISABLED) {
                        this.formGroup.controls[this.formCtrl].markAsPristine();
                        this.formGroup.controls[this.formCtrl].setValue('');
                        this.autoCompleteControl.setValue('');
                        this.counterPartyChanged(new MemberAccount());
                    } else {
                        this.counterPartyChanged(this.counterPartyOptions[0]);
                    }
                } else {
                    const selected = this.counterPartyOptions
                        .map((option) => {
                            return option.member.name;
                        })
                        .find((option) => {
                            return option === this.autoCompleteControl.value;
                        });

                    if (!selected && this.selected()) {
                        this.formGroup.controls[this.formCtrl].markAsPristine();
                        this.formGroup.controls[this.formCtrl].setValue('');
                        this.autoCompleteControl.setValue('');
                        this.counterPartyChanged(new MemberAccount());
                    }
                }
            }
        }, () => {
            return this.subscribeToClosingActions();
        }, () => {
            return this.subscribeToClosingActions();
        });
    }

    lookup(value: string): Observable<MemberAccount[]> {
        this.totalResults = 0;
        if (value?.length <= 1) {
            this.searching = false;
            return of([]);
        }
        this.formGroup.controls['autoCompleteCtrl'].disable();
        return this.merchantAccountService.findMerchantAccounts(value, this.memberId, [], this.STATUSES[0], [], null, this.searchLimit).pipe(
            switchMap((merchantAccounts: PagedResponse<MerchantAccount>) => {
                this.totalResults = merchantAccounts.totalElements - 1;
                merchantAccounts.content.forEach((merchantAccount) => {
                    this.counterPartyOptions.push(merchantAccount.merchantMemberAccount);
                });
                this.counterPartyOptions.sort((a: MemberAccount, b: MemberAccount) => {
                    return a.member.name.toLowerCase() < b.member.name.toLowerCase() ? -1 : 1;
                });
                this.searching = false;
                this.formGroup.controls['autoCompleteCtrl'].enable();
                return of(this.counterPartyOptions);
            }), catchError((_error) => {
                this.searching = false;
                this.formGroup.controls['autoCompleteCtrl'].enable();
                return of([]);
            })
        );
    }

    counterPartyChanged(counterParty: MemberAccount) {
        let displayValue = this.getCounterPartyName(counterParty);
        this.formGroup.controls[this.formCtrl].markAsDirty();
        this.formGroup.controls[this.formCtrl].setValue(counterParty.member.name);
        this.autoCompleteControl.setValue(displayValue);
        this.counterPartySelected.emit(counterParty);
    }

    onInputChanged(event: any) {
        if (event.keyCode === 220) {
            event.preventDefault();
            return;
        }
    }

    selected() {
        return this.formGroup.controls[this.formCtrl].value;
    }

    onIconClick(event: any) {
        if (this.selected()) {
            this.searching = true;
            this.counterPartyOptions = [];
            this.formGroup.controls[this.formCtrl].setValue(null);
            this.autoCompleteControl.setValue('');
            this.counterPartySelected.emit(new MemberAccount());
        }
        event.stopPropagation();
        this.trigger.openPanel();
    }

    getCounterPartyName(counterParty: MemberAccount) {
        if (counterParty.member.memberType === MemberType.BUSINESS_MERCHANT) {
            const dbaName = (counterParty.member as CorporateEntity).dbaName;
            if (dbaName && dbaName.toLowerCase() !== counterParty.member.name.toLowerCase()) {
                return counterParty.member.name + (' (DBA: ' + dbaName + ')');
            }
        }
        return counterParty.member.name;
    }

    isDisabledOrRejected(counterParty: MemberAccount) {
        return counterParty.status === MemberAccountStatus.REJECTED || counterParty.status === MemberAccountStatus.DISABLED
            || counterParty.member.status === MemberStatus.DISABLED;
    }
}
