import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-linked-systems',
    templateUrl: './member-linked-systems.component.html'
})
export class MemberLinkedSystemsComponent implements OnInit {

    @Input() memberId: string;
    @Input() requiresMrb = false;
    @Input() requiresAccounting = false;

    @Output() systemsLinked: EventEmitter<any> = new EventEmitter<any>();

    mrbLinked = false;
    accountingLinked = false;

    constructor(private authService: AuthService) {}

    ngOnInit() {
        this.memberId = this.memberId ? this.memberId : this.authService.getProfile().memberId;
    }

    metrcChanged(linked: boolean) {
        this.mrbLinked = linked;
        this.notifyLinkedAccounts();
    }

    accountingChanged(linked: boolean) {
        this.accountingLinked = linked;
        this.notifyLinkedAccounts();
    }

    notifyLinkedAccounts() {
        this.systemsLinked.emit({
            mrbLinked: this.mrbLinked,
            accountingLinked: this.accountingLinked
        });
    }
}
