import { WalletFunction } from './member-account';
import { MemberFunction, MemberType } from './member';
import { TransactionSource, TransactionState, TransactionType } from './transaction';

export class TransactionTableFilter {

    memberId: string;
    memberAccountId: string;
    memberType: MemberType;
    memberFunctions: MemberFunction[];
    walletFunctions: WalletFunction[];
    counterParty: string;
    transactionState: TransactionState;
    transactionTypes: TransactionType[];
    transactionSources: TransactionSource[];
    transactionTypeFilter: TransactionTableTypeFilter;
    startDate: string;
    endDate: string;
    retailLocationId: string;
    minAmount: string;
    maxAmount: string;
    transactionTab: string;

    constructor(memberId?: string) {
        this.memberId = memberId || '';
        this.memberAccountId = '';
        this.memberType = MemberType.ALL;
        this.memberFunctions = [];
        this.walletFunctions = [];
        this.counterParty = '';
        this.transactionState = TransactionState.ALL;
        this.transactionTypes = [];
        this.transactionSources = [];
        this.transactionTypeFilter = TransactionTableTypeFilter.ALL_TYPES;
        this.startDate = '';
        this.endDate = '';
        this.retailLocationId = '';
        this.minAmount = '';
        this.maxAmount = '';
        this.transactionTab = '';
    }
}

export enum TransactionTableTypeFilter {
    ALL_TYPES = 'ALL_TYPES',
    ALL_NON_RETAIL = 'ALL_NON_RETAIL',
    RETAIL_ONLY = 'RETAIL_ONLY',
    DEPOSITS = 'DEPOSITS',
    WITHDRAWALS = 'WITHDRAWALS',
    CONFIA_PAYMENTS = 'CONFIA_PAYMENTS',
    OUT_OF_NETWORK_PAYMENTS = 'OUT_OF_NETWORK_PAYMENTS',
    FEES = 'FEES',
    CREDITS = 'CREDITS',
    REFUNDS = 'REFUNDS',
    ADJUSTMENTS = 'ADJUSTMENTS'
}
