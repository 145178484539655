import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService, AuthorizationFormService, Configuration, CustomValidators, RegistrationService, MemberRegistrationType, MfaType, Role, Jurisdiction } from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';

@Component({
    selector: 'pc-register-member-modal',
    templateUrl: './register-member-modal.component.html'
})
export class RegisterMemberModalComponent extends BaseModalComponent<RegisterMemberModalComponent> implements OnInit {

    jurisdictions: Jurisdiction[] = [];
    memberTypes: string[] = [];
    jurisdiction: string;
    referralCode: string;
    email:string;
    officePhone: string;
    partnerReferenceId: string;
    refereeMemberId: string;
    memberName: string;
    registerMemberForm: UntypedFormGroup;
    errorMessage: string;
    mfaTypes = [{
        id: MfaType.SMS,
        name: 'SMS Text Message'
    }, {
        id: MfaType.TOTP,
        name: 'Time-based Token (TOTP)'
    }];
    memberRegistrationType: string;
    userType: string;
    locked = false;

    constructor(private authorizationFormService: AuthorizationFormService,
                private registrationService: RegistrationService,
                private changeDetectorRef: ChangeDetectorRef,
                private authService: AuthService,
                dialogRef: MatDialogRef<RegisterMemberModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.jurisdictions = Configuration.getConfig().jurisdictions.filter((jurisdiction) => {
            return jurisdiction?.supportedMemberRegistrationTypes.length > 0;
        });
        this.jurisdiction = data.jurisdiction;
        this.memberName = data.memberName;
        this.referralCode = data.referralCode;
        this.memberRegistrationType = data.memberRegistrationType;
        this.email = data.email;
        this.officePhone = data.officePhone;
        this.partnerReferenceId = data.partnerReferenceId;
        this.refereeMemberId = data.refereeMemberId;
    }

    ngOnInit() {
        this.formValid = this.formValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.registerMemberForm = this.authorizationFormService.registerNewMemberForm();

        if (this.jurisdiction) {
            const jurisdiction = this.jurisdictions.find((jurisdiction) => {
                return jurisdiction.id === this.jurisdiction;
            });
            this.registerMemberForm.patchValue({jurisdictionCtrl: jurisdiction.name});
            this.changeDetectorRef.detectChanges();
            this.jurisdictionSelected(jurisdiction);
        }

        // partner company id not mandatory filed
        if (this.partnerReferenceId) {
            this.registerMemberForm.patchValue({
                partnerReferenceIdCtrl: this.partnerReferenceId
            });
        }
        if (this.refereeMemberId) {
            this.registerMemberForm.patchValue({
                refereeMemberIdCtrl: this.refereeMemberId
            });
        }

        if (this.isMfaEnabled()) {
            this.registerMemberForm.patchValue({
                mfaCtrl: MfaType.SMS
            });
            this.registerMemberForm.get('mfaCtrl').setValidators([Validators.required]);
        } else {
            this.registerMemberForm.patchValue({
                mfaCtrl: MfaType.NONE
            });
        }
        if (this.memberName) {
            this.registerMemberForm.patchValue({
                memberNameCtrl: this.memberName
            });
            this.registerMemberForm.controls['memberNameCtrl'].disable();
        }
        if (this.referralCode) {
            this.registerMemberForm.patchValue({
                referralCtrl: this.referralCode
            });
            this.registerMemberForm.controls['referralCtrl'].disable();
        }
        if (this.email) {
            this.registerMemberForm.patchValue({
                emailCtrl: this.email
            });
            this.onEmailChange(this.email);
        }
        if (this.officePhone) {
            this.registerMemberForm.patchValue({
                officePhoneCtrl: this.officePhone
            });
        }
    }

    jurisdictionSelected(selectedAuthority: Jurisdiction) {
        const memberRegistrationCtrl = this.registerMemberForm.get('memberRegistrationTypeCtrl');
        memberRegistrationCtrl.setValidators(null);
        memberRegistrationCtrl.setValue(null);
        memberRegistrationCtrl.enable();
        if (!selectedAuthority.supportedMemberRegistrationTypes || selectedAuthority.supportedMemberRegistrationTypes.length === 0) {
            this.memberTypes = [];
        } else if (selectedAuthority.supportedMemberRegistrationTypes.length === 1) {
            this.memberTypes = [selectedAuthority.supportedMemberRegistrationTypes[0]];
            memberRegistrationCtrl.setValidators(Validators.required);
            memberRegistrationCtrl.setValue(this.memberTypes[0]);
            memberRegistrationCtrl.disable();
            memberRegistrationCtrl.updateValueAndValidity();
        } else if (selectedAuthority.supportedMemberRegistrationTypes.length > 0) {
            memberRegistrationCtrl.setValidators(Validators.required);
            selectedAuthority.supportedMemberRegistrationTypes.sort((a: MemberRegistrationType, b: MemberRegistrationType) => {
                if (a === MemberRegistrationType.PREMIUM_MEMBER) {
                    return -1;
                } else if (b === MemberRegistrationType.PREMIUM_MEMBER) {
                    return 1;
                }
                return 0;
            });
            this.memberTypes = [''].concat(selectedAuthority.supportedMemberRegistrationTypes);
            if (this.memberRegistrationType) {
                memberRegistrationCtrl.setValue(this.memberRegistrationType);
                this.memberRegistrationType = '';
            }
            memberRegistrationCtrl.updateValueAndValidity();
        }
    }

    onSubmit(reset: any) {
        this.errorMessage = '';
        const registrationRequest = this.authorizationFormService.getRegisterMember(this.registerMemberForm);
        registrationRequest.role = Role.NEW_ADMIN_REGISTRANT;
        this.registrationService.registerMember(registrationRequest).subscribe(() => {
            this.close();
            this.authService.logoutAndMessage(0);
        },
        (errorResult: any) => {
            reset();
            if (errorResult.status === 409 || errorResult.status === 400 || errorResult.status === 401) {
                this.errorMessage = errorResult.error;
            } else if (errorResult.status === 429) {
                this.close();
                throw errorResult;
            } else {
                throw errorResult;
            }
        });
    }

    isMfaEnabled() {
        return Configuration.getConfig().mfaEnabled;
    }

    close() {
        super.close();
    }

    formValid() {
        return !this.registerMemberForm.disabled && !this.registerMemberForm.invalid;
    }

    nextDisabled() {
        return !this.registerMemberForm.get('memberNameCtrl').valid || !this.registerMemberForm.get('emailCtrl').valid;
    }

    onEmailChange(email: string) {
        if (this.registerMemberForm.get('emailCtrl').valid) {
            this.registerMemberForm.disable();
            this.authService.userExists(email).subscribe((response: any) => {
                const userCount = Number(response.headers.get('Content-Length'));
                if (userCount > 0) {
                    this.registerMemberForm.get('firstNameCtrl').setValidators(null);
                    this.registerMemberForm.get('middleNameCtrl').setValidators(null);
                    this.registerMemberForm.get('lastNameCtrl').setValidators(null);
                    this.registerMemberForm.get('titleCtrl').setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(64)]);
                    this.registerMemberForm.get('officePhoneCtrl').setValidators(null);
                    this.registerMemberForm.get('mobilePhoneCtrl').setValidators(null);
                    this.registerMemberForm.get('mfaCtrl').setValidators(null);
                    this.registerMemberForm.get('passwordCtrl').setValidators([Validators.required, Validators.maxLength(128)]);
                    this.registerMemberForm.patchValue({
                        firstNameCtrl: null,
                        middleNameCtrl: null,
                        lastNameCtrl: null,
                        titleCtrl: null,
                        officePhoneCtrl: null,
                        mobilePhoneCtrl: null,
                        mfaCtrl: MfaType.NONE,
                        passwordCtrl: null
                    });
                    this.userType = 'EXISTING';
                } else {
                    this.registerMemberForm.get('firstNameCtrl').setValidators([Validators.required, CustomValidators.personName, Validators.maxLength(32)]);
                    this.registerMemberForm.get('middleNameCtrl').setValidators([CustomValidators.middleName, Validators.maxLength(32)]);
                    this.registerMemberForm.get('lastNameCtrl').setValidators([Validators.required,  CustomValidators.personName, Validators.maxLength(32)]);
                    this.registerMemberForm.get('titleCtrl').setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(64)]);
                    this.registerMemberForm.get('officePhoneCtrl').setValidators([Validators.required, CustomValidators.phone]);
                    this.registerMemberForm.get('mobilePhoneCtrl').setValidators([Validators.required, CustomValidators.phone]);
                    this.registerMemberForm.get('passwordCtrl').setValidators(null);
                    if (this.partnerReferenceId && this.officePhone) {
                        this.officePhone = this.registerMemberForm.get('officePhoneCtrl').value;
                    }
                    this.registerMemberForm.patchValue({
                        firstNameCtrl: null,
                        middleNameCtrl: null,
                        lastNameCtrl: null,
                        titleCtrl: null,
                        officePhoneCtrl: null,
                        mobilePhoneCtrl: null,
                        passwordCtrl: null
                    });
                    if (this.isMfaEnabled()) {
                        this.registerMemberForm.patchValue({
                            mfaCtrl: MfaType.SMS
                        });
                        this.registerMemberForm.get('mfaCtrl').setValidators([Validators.required]);
                    } else {
                        this.registerMemberForm.patchValue({
                            mfaCtrl: MfaType.NONE
                        });
                    }
                    this.userType = 'NEW';
                }
                this.registerMemberForm.enable();
                if (this.memberName) {
                    this.registerMemberForm.controls['memberNameCtrl'].disable();
                }
                if (this.referralCode) {
                    this.registerMemberForm.controls['referralCtrl'].disable();
                }
                if (this.officePhone) {
                    this.registerMemberForm.patchValue({
                        officePhoneCtrl: this.officePhone
                    });
                }
            },
            (errorResult: any) => {
                if (errorResult.status === 403) {
                    this.locked = true;
                    this.registerMemberForm.disable();
                    this.errorMessage = 'User is locked. Please contact support.';
                } else {
                    throw errorResult;
                }
            });
        } else {
            this.registerMemberForm.patchValue({
                firstNameCtrl: null,
                middleNameCtrl: null,
                lastNameCtrl: null,
                titleCtrl: null,
                officePhoneCtrl: null,
                mobilePhoneCtrl: null,
                passwordCtrl: null
            });
            if (this.isMfaEnabled()) {
                this.registerMemberForm.patchValue({
                    mfaCtrl: MfaType.SMS
                });
                this.registerMemberForm.get('mfaCtrl').setValidators([Validators.required]);
            } else {
                this.registerMemberForm.patchValue({
                    mfaCtrl: MfaType.NONE
                });
            }
            this.userType = '';
        }
    }
}
