import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

import { SharedComponentsModule } from 'projects/components/src/lib/shared-components.module';
import { ContractTemplateDetailsComponent, FieldComponent, SectionComponent, TemplateComponent, CheckboxComponent, LastProposalComponent, SmartContractUploadComponent,
    NumberComponent, TextAreaComponent, TextInputComponent, TermComponent, NewTemplateModalComponent, SalesOrderComponent, NewItemModalComponent, ShipOrderComponent,
    CheckAvailabilityComponent, ConfirmDeliveryComponent, DateComponent, MyContractsComponent, ListContractTemplatesComponent, SelectTemplateTermsComponent,
    ShippingModalComponent, PreviewBaseTemplateComponent, EnumerationInputComponent, EditTemplateTermsComponent, ConfigureTemplateComponent, PreviewSharedTemplateComponent,
    NewContractModalComponent, InviteBusinessPartiesComponent, BusinessPartiesListComponent, CreateTemplateMainComponent, SharedTemplatesListComponent,
    SellerReviewAgreementComponent, BuyerReviewAgreementComponent, ShareTemplateComponent, ContractStatusComponent } from './index';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NgbModule,
        RouterModule,
        SharedComponentsModule,
        AngularMyDatePickerModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTabsModule
    ],
    declarations: [
        FieldComponent,
        SectionComponent,
        CheckboxComponent,
        DateComponent,
        NumberComponent,
        TextAreaComponent,
        TextInputComponent,
        MyContractsComponent,
        SalesOrderComponent,
        NewItemModalComponent,
        ShipOrderComponent,
        CheckAvailabilityComponent,
        ConfirmDeliveryComponent,
        ListContractTemplatesComponent,
        PreviewBaseTemplateComponent,
        EditTemplateTermsComponent,
        InviteBusinessPartiesComponent,
        BusinessPartiesListComponent,
        ContractTemplateDetailsComponent,
        SharedTemplatesListComponent,
        SellerReviewAgreementComponent,
        BuyerReviewAgreementComponent,
        LastProposalComponent,
        EnumerationInputComponent,
        SmartContractUploadComponent,
        NewTemplateModalComponent,
        CreateTemplateMainComponent,
        PreviewSharedTemplateComponent,
        NewContractModalComponent,
        TemplateComponent,
        TermComponent,
        SelectTemplateTermsComponent,
        ConfigureTemplateComponent,
        ShippingModalComponent,
        ShareTemplateComponent,
        ContractStatusComponent
    ],
    exports: [
        FieldComponent,
        SectionComponent,
        CheckboxComponent,
        DateComponent,
        NumberComponent,
        TextAreaComponent,
        TextInputComponent,
        MyContractsComponent,
        SalesOrderComponent,
        NewItemModalComponent,
        ShipOrderComponent,
        CheckAvailabilityComponent,
        ConfirmDeliveryComponent,
        ListContractTemplatesComponent,
        PreviewBaseTemplateComponent,
        EditTemplateTermsComponent,
        InviteBusinessPartiesComponent,
        BusinessPartiesListComponent,
        ContractTemplateDetailsComponent,
        SharedTemplatesListComponent,
        SellerReviewAgreementComponent,
        BuyerReviewAgreementComponent,
        LastProposalComponent,
        EnumerationInputComponent,
        SmartContractUploadComponent,
        NewTemplateModalComponent,
        CreateTemplateMainComponent,
        PreviewSharedTemplateComponent,
        NewContractModalComponent,
        TemplateComponent,
        TermComponent,
        SelectTemplateTermsComponent,
        ConfigureTemplateComponent,
        ShippingModalComponent,
        ShareTemplateComponent,
        ContractStatusComponent
    ],
    providers: [
    ]
})
export class SharedSmartContractsComponentsModule {
}
