<div class="col-sm-12 horizontal-scroll mat-table-container">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="productBalances" multiTemplateDataRows>
        <ng-container matColumnDef="productType">
            <th mat-header-cell *matHeaderCellDef> Product Type </th>
            <td mat-cell *matCellDef="let element">
                <i class="fa" [class.fa-plus-circle]="expandedElement !== element"
                    [class.fa-minus-circle]="expandedElement === element"
                    (click)="expandedElement = (expandedElement === element) ? null : element"></i> {{ element.productCategory }}
            </td>
        </ng-container>
        <ng-container matColumnDef="quantityUnits">
            <th mat-header-cell *matHeaderCellDef> Quantity Units </th>
            <td mat-cell *matCellDef="let element"> {{ element.quantityUnits }} </td>
        </ng-container>
        <ng-container matColumnDef="quantityIN">
            <th mat-header-cell *matHeaderCellDef> Quantity IN</th>
            <td mat-cell *matCellDef="let element"> {{ element.quantityIn }} </td>
        </ng-container>
        <ng-container matColumnDef="quantityOUT">
            <th mat-header-cell *matHeaderCellDef> Quantity OUT </th>
            <td mat-cell *matCellDef="let element"> {{ element.quantityOut }} </td>
        </ng-container>
        <ng-container matColumnDef="quantityBalance">
            <th mat-header-cell *matHeaderCellDef> Quantity Balance </th>
            <td mat-cell *matCellDef="let element"> {{ element.quantityBalance }} </td>
        </ng-container>
        <ng-container matColumnDef="valueIn">
            <th mat-header-cell *matHeaderCellDef> Value In ($) </th>
            <td mat-cell *matCellDef="let element"> {{ element.valueIn? (element.valueIn | currency) : 0 }} </td>
        </ng-container>
        <ng-container matColumnDef="valueOut">
            <th mat-header-cell *matHeaderCellDef> Value Out ($) </th>
            <td mat-cell *matCellDef="let element"> {{ element.valueOut? (element.valueOut | currency) : 0 }} </td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                    <pc-member-metrc-trace-row-list *ngIf="element === expandedElement" [memberId]="memberId" [startDate]="startDate" [endDate]="endDate" [productBalance]="element"></pc-member-metrc-trace-row-list>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
</div>
