<div>
    <div class="modal-header">
        <div class="pull-left"><img class="dashboard-icon " src="assets/images/account-2.svg" alt=""></div>
        <label class="form-title">Add Confia {{merchantMemberType | translate}}</label>
        <i class="modal-close" (click)="close()">&times;</i>
    </div>
    <div *ngIf="!merchantTypeSelected" class="modal-content">
        <div class="d-flex justify-content-center">
            <div class="flex-column">
                <div class="mt-3 mb-5"><strong>Please select the type of Merchant:</strong></div>
                <div class="centered mb-5">
                    <mat-button-toggle-group #group="matButtonToggleGroup" #buttongroup class="mb-5" [value]="merchantMemberType" (change)="onTypeChange(group.value)" [vertical]="(buttonOrientation | async)!">
                        <mat-button-toggle [value]="MemberType.BUSINESS_MERCHANT">
                            <div class="p-3">Business Merchant</div>
                        </mat-button-toggle>
                        <mat-button-toggle [value]="MemberType.INDIVIDUAL_MERCHANT">
                            <div class="p-3">Individual Merchant</div>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <div *ngIf="isAccountingLinked && !isAuthority" class="flex-column mb-2">
                    <pc-error-message [error]="ACCOUNTING_LINKED_WARNING" [width]="'full'"></pc-error-message>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <button class="blue-button" [disabled]="!merchantMemberType" (click)="selectMerchantType()" type="button">Next</button>
        </div>
    </div>
</div>
<div *ngIf="merchantTypeSelected" class="modal-content">
    <div>
        <form [formGroup]="merchantForm">
            <div>
                <div>
                    <div>
                        <div class="row">
                            <div class="col-12">
                                <pc-merchant-searcher #searcherCtrl [memberId]="memberId" [formGroup]="merchantForm" [label]="searchLabel" [formCtrl]="'merchantNameCtrl'"
                                    [jurisdiction]="jurisdiction" [includeAddress]="true" [merchantMemberType]="merchantMemberType" (merchantSelected)="setMerchant($event)">
                                </pc-merchant-searcher>
                            </div>
                        </div>
                        <dl class="row" *ngIf="existingAccountConfig">
                            <div class="col-3"></div>
                            <div class="col-6">
                                <dl class="row">
                                    <dt class="col-sm-4">Merchant Name:</dt>
                                    <dd class="col-sm-8">{{existingAccountConfig.merchantMemberAccount.member.name}}</dd>

                                    <dt class="col-sm-4" *ngIf="getDbaName(existingAccountConfig.merchantMemberAccount.member)">Doing Business As:</dt>
                                    <dd class="col-sm-8" *ngIf="getDbaName(existingAccountConfig.merchantMemberAccount.member)">{{getDbaName(existingAccountConfig.merchantMemberAccount.member)}}</dd>
                                </dl>
                            </div>
                            <div class="col-3"></div>
                        </dl>
                        <div class="row">
                            <div class="col-2" *ngIf="merchantMemberType === MemberType.BUSINESS_MERCHANT && ((!showAchInfo && newMerchant) || (existingAccountConfig && showAchInfo))">
                            </div>
                            <div class="col-4" *ngIf="merchantMemberType === MemberType.BUSINESS_MERCHANT && !newMerchant && !showAchInfo">
                            </div>
                            <div class="col-4" *ngIf="newMerchant && merchantMemberType === MemberType.INDIVIDUAL_MERCHANT">
                                <span class="subtitle">Individual Merchant Info</span>
                                <pc-text-input [label]="'First Name'"
                                    [formCtrl]="'merchantFirstNameCtrl'" [formGroup]="merchantForm">
                                </pc-text-input>
                                <pc-text-input [label]="'Middle Name'"
                                    [formCtrl]="'merchantMiddleNameCtrl'" [formGroup]="merchantForm">
                                </pc-text-input>
                                <pc-text-input [label]="'Last Name'"
                                    [formCtrl]="'merchantLastNameCtrl'" [formGroup]="merchantForm">
                                </pc-text-input>
                                <pc-dob-input [formGroup]="merchantForm" [formCtrl]="'birthDateCtrl'" [required]="false">
                                </pc-dob-input>
                                <div class="pt-3">
                                    <pc-phone-input [label]="'Phone Number'" [formCtrl]="'ownerPhoneCtrl'" [formGroup]="merchantMemberAccountForm">
                                    </pc-phone-input>
                                </div>
                                <pc-email-input [label]="'Email'"
                                    [formCtrl]="'ownerEmailCtrl'" [formGroup]="merchantMemberAccountForm">
                                </pc-email-input>
                                <pc-text-input [label]="'Relationship To Company'" [required]="false"
                                    [formCtrl]="'briefDescriptionCtrl'" [formGroup]="merchantMemberAccountForm">
                                </pc-text-input>
                                <pc-form-text-input [required]="false" [label]="'SSN'" [placeholder]="'numbers only (no hyphens)'" [formCtrl]="'ssnCtrl'" [formGroup]="merchantForm"
                                    [colSize]="6" [restrictToDigits]="true">
                                </pc-form-text-input>
                            </div>
                            <div class="col-4" *ngIf="newMerchant || existingAccountConfig">
                                <span class="subtitle">Merchant Address Info</span>
                                <pc-address-input [required]="true" [addressForm]="merchantMemberAccountForm" [lockCountry]="true"></pc-address-input>
                            </div>
                            <div class="col-4" *ngIf="newMerchant && merchantMemberType === MemberType.BUSINESS_MERCHANT">
                                <span class="subtitle">Merchant Contact Info</span>
                                <pc-email-input [label]="'Contact Email'"
                                    [formCtrl]="'ownerEmailCtrl'" [formGroup]="merchantMemberAccountForm">
                                </pc-email-input>
                                <pc-text-input [label]="'Contact Name'"
                                    [formCtrl]="'ownerNameCtrl'" [formGroup]="merchantMemberAccountForm">
                                </pc-text-input>
                                <pc-phone-input [label]="'Contact Phone Number'"
                                    [formCtrl]="'ownerPhoneCtrl'" [formGroup]="merchantMemberAccountForm">
                                </pc-phone-input>
                                <pc-text-input [label]="'Nature Of Business'"
                                    [formCtrl]="'briefDescriptionCtrl'" [formGroup]="merchantMemberAccountForm">
                                </pc-text-input>
                                <pc-text-input [label]="'Name of License Holder (MRB Businesses Only)'" placeholder="Enter license holder, if different from merchant name"
                                    [formCtrl]="'licenseHolderCtrl'" [formGroup]="merchantMemberAccountForm">
                                </pc-text-input>
                            </div>
                            <div class="col-4" *ngIf="showAchInfo">
                                <div *ngIf="newMerchant">
                                    <span class="subtitle">Merchant ACH Info</span>
                                    <div>
                                        <pc-text-input [label]="'Name of Account Holder'" [placeholder]="'Account Holder'"
                                            [formCtrl]="'achAccountHolderCtrl'" [formGroup]="merchantForm"
                                            (changed)="updateBankAccountValidity($event)">
                                        </pc-text-input>
                                        <pc-text-input [label]="'Account Number'" [placeholder]="'Account Number - 5 to 17 digits'"
                                            [formCtrl]="'achAccountNumberCtrl'" [formGroup]="merchantForm"
                                            (changed)="updateBankAccountValidity($event)">
                                        </pc-text-input>
                                        <pc-text-input [label]="'ACH Routing Number'" [placeholder]="'9 digit ACH routing number'"
                                            [formCtrl]="'achRoutingNumberCtrl'" [formGroup]="merchantForm"
                                            (changed)="updateBankAccountValidity($event)">
                                        </pc-text-input>
                                        <pc-text-input [label]="'Wire Routing Number'" [placeholder]="'9 digit Wire routing number'"
                                            [formCtrl]="'wireRoutingNumberCtrl'" [formGroup]="merchantForm"
                                            (changed)="updateBankAccountValidity($event)">
                                        </pc-text-input>
                                        <a class="dashboard-list-a" (click)="upload.click()">or upload an invoice with ACH info</a>
                                        <input hidden type="file" id="upload" #upload (change)="selectFile($event)" placeholder="Upload file"
                                            accept=".pdf,.doc,.docx">
                                        <div *ngIf="file">
                                            <hr class="separator">
                                            <i class="fa fa-times" (click)="deleteDocument()"></i>
                                            <a *ngIf="file" class="dashboard-list-a"><i class="fa fa-paperclip"></i>{{file.name}}</a>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!newMerchant">
                                    <span class="subtitle">Merchant Payment Options</span>
                                    <div *ngIf="existingAccountConfig && (existingAccountConfig.merchantAccountType === SupportedTransactionType.OUTGOING || existingAccountConfig.merchantAccountType === SupportedTransactionType.BOTH)">
                                        Accepts Payments via:
                                        <ul>
                                            <li *ngIf="existingAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_ACH_TO_MERCHANT">ACH</li>
                                            <li *ngIf="existingAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_WIRE_TO_MERCHANT">Wire</li>
                                            <li *ngIf="existingAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_CHECK_TO_MERCHANT">Check</li>
                                            <li *ngIf="existingAccountConfig.defaultOutgoingTransactionType === TransactionType.OUTGOING_DIGITAL_CHECK_TO_MERCHANT">Digital Check</li>
                                        </ul>
                                    </div>
                                    <div *ngIf="existingAccountConfig && (existingAccountConfig.merchantAccountType === SupportedTransactionType.INCOMING || existingAccountConfig.merchantAccountType === SupportedTransactionType.BOTH)">
                                        Pays via:
                                        <ul>
                                            <li *ngIf="existingAccountConfig.defaultIncomingTransactionType === TransactionType.INCOMING_ACH_FROM_MERCHANT">ACH</li>
                                            <li *ngIf="existingAccountConfig.defaultIncomingTransactionType === TransactionType.INCOMING_WIRE_FROM_MERCHANT">Wire</li>
                                            <li *ngIf="existingAccountConfig.defaultIncomingTransactionType === TransactionType.INCOMING_CHECK_FROM_MERCHANT">Check</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4" *ngIf="!newMerchant && !showAchInfo">
                            </div>
                            <div class="col-2" *ngIf="(!showAchInfo && newMerchant) || (existingAccountConfig && showAchInfo)">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div *ngIf="merchantAccountExistsError" class="duplicate-account">
                        <pc-error-message [error]="merchantAccountExistsError" [width]="'full'"></pc-error-message>
                    </div>
                    <pc-submit-button *ngIf="merchantMemberType === MemberType.BUSINESS_MERCHANT
                        && ((isAuthority && merchantAccount?.status && !merchantAccountExistsError && !merchantAccountVerificationInProgress)
                            || (!isAuthority && merchantAccount?.status && !merchantAccountExistsError))"
                        [enabled]="isMerchantFormValid" [submit]="onBusinessMerchantSubmit" [text]="(isAuthority && newMerchant) ? 'Create Business Merchant' : (existingAccountConfig) ? 'Link Existing Business Merchant' : 'Submit Business Merchant for Review'"></pc-submit-button>
                    <div *ngIf="merchantMemberType === MemberType.INDIVIDUAL_MERCHANT && ((isAuthority && merchantAccount?.status && !merchantAccountExistsError && !merchantAccountVerificationInProgress)
                            || (!isAuthority && merchantAccount?.status && !merchantAccountExistsError))">
                        <pc-submit-button [submit]="onIndividualMerchantSubmit" [enabled]="isMerchantFormValid" [text]="(isAuthority && newMerchant) ? 'Create Individual Merchant' : (existingAccountConfig) ? 'Link Existing Individual Merchant' : 'Submit Individual Merchant for Review'"></pc-submit-button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
