import { Transaction } from './transaction';

export class Rfi {
    id: string;
    status: RfiStatus;
    dueDate: Date;
    created: Date;
    updated: Date;
    transactionId: string;
    transaction: Transaction;
    memberId: string;
    memberAccountId: string;
    noteCount: number;
}

export enum RfiStatus {
    ALL = '',
    PENDING_DEPOSIT_VERIFICATION = 'PENDING_DEPOSIT_VERIFICATION',
    PENDING_PAYMENT_VERIFICATION = 'PENDING_PAYMENT_VERIFICATION',
    PENDING_CHECK_VERIFICATION = 'PENDING_CHECK_VERIFICATION',
    MEMBER_RECONCILIATION = 'MEMBER_RECONCILIATION',
    AUTHORITY_REVIEW = 'AUTHORITY_REVIEW',
    CLOSED = 'CLOSED'
}
