<div *ngIf="!assigning" [class]="getClass()" [ngClass]="isPriority ? 'priority': ''">
    <b>{{assigneeName}}</b>
    <i class="fa fa-user" role="button" (click)="editAssignee()"></i>
    <i class="fa fa-comments" role="button" (click)="addNote()"></i>
</div>
<div *ngIf="assigning" class="assignee">
    <select class="form-control form-input-table-header form-select-sm" type="text" [(ngModel)]="assignee" (change)="onAssigneeChanged()">
        <option value="">Unassign</option>
        <option *ngFor="let option of authorityUsers" [value]="option.userId">{{option.user.lastName}}, {{option.user.firstName.charAt(0)}}</option>
    </select>
    <i class="fa fa-times" role="button" (click)="clear()"></i>
</div>
