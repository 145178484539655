import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import {
    AuthGuard, AuthService, Configuration, CountNotificationService, HttpHeaderService, HttpService, IDPService, StompService, SidebarTrackingService, NavigationLinks
} from 'projects/services/src/public-api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeModule } from './home/home.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedComponentsModule } from 'projects/components/src/lib/shared-components.module';
import { SmartContractsModule } from './smart-contracts/smart-contracts.module';
import { LoginModule } from './login/login.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { OverlayModule } from '@angular/cdk/overlay';
import { ErrorService } from './error/error-handler';
import { LoggingService } from './error/error-logging.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NotificationService } from './notifications/notification.service';
import { ConsumerModule } from './consumer/consumer.module';
import { DirtyCheckGuard } from 'projects/components/src/lib/administration';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogHandler } from 'projects/components/src/lib/modal';
import { MemberAccountModule } from './member-account/member-account.module';
import { BrowserNotSupportedModule } from 'projects/pt/src/app/browser-not-supported/browser-not-supported.module';
import { WatchlistReviewModalComponent } from './components/modal/watchlist-review-modal/watchlist-review-modal.component';
import { MatTableModule } from '@angular/material/table';

export const translateLoader = (http: HttpClient) => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

export const configLoader = (config: Configuration) => {
    return () => {
        return config.load();
    };
};

export const stompLoader = (stompService: StompService, authService: AuthService) => {
    return () => {
        const token = authService.getToken();
        if (token) {
            stompService.connect(token);
        }
    };
};

@NgModule({
    declarations: [
        AppComponent,
        WatchlistReviewModalComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoader,
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot([], {}),
        FormsModule,
        ReactiveFormsModule,
        HomeModule,
        NgbModule,
        SharedComponentsModule,
        BrowserAnimationsModule,
        SmartContractsModule,
        LoginModule,
        MatSnackBarModule,
        MatBottomSheetModule,
        MatDialogModule,
        OverlayModule,
        ConsumerModule,
        MemberAccountModule,
        BrowserNotSupportedModule,
        MatTableModule
    ],
    providers: [
        AuthGuard,
        DirtyCheckGuard,
        AuthService,
        HttpHeaderService,
        HttpService,
        IDPService,
        Configuration,
        {
            provide: ErrorHandler, useClass: ErrorService
        },
        {
            provide: MatDialog, useClass: MatDialogHandler
        },
        {
            provide: 'environment', useValue: environment
        },
        {
            provide: APP_INITIALIZER, useFactory: configLoader, deps: [Configuration], multi: true
        },
        {
            provide: APP_INITIALIZER, useFactory: stompLoader, deps: [StompService, AuthService], multi: true
        },
        SidebarTrackingService,
        NavigationLinks,
        NotificationService,
        LoggingService,
        CountNotificationService,
        CurrencyPipe,
        DatePipe,
        TranslatePipe,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { maxWidth: 'none', hasBackdrop: true } as MatDialogConfig
        }
    ],
    exports: [
        TranslateModule,
        RouterModule
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
