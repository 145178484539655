import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
    AuthService, BankAccount, BankAccountService, BankAccountSource, CommentType, ConnectedMember, ConnectedMemberService, DataroomService, DocumentType, KeyParty,
    KeyPartyService, KeyPartyStatus, KeyPartyType, LenderService, LinkedBankAccount, Loan, LoanStatus, Member, MemberFormService, MemberFunction, MemberService, MemberStatus,
    MemberType, MerchantAccountConfig, MerchantAccountService, MerchantFormService, OnboardingTrackingService, PagedResponse, PartnerService, RiskRating, RiskTier,
    Role, SupportedTransactionType, TransactionType, Upload, UploadStatus, Utils, Configuration, BankAccountStatus
} from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OtherDocumentModalComponent } from 'projects/pt/src/app/components';
import { catchError, combineLatest, forkJoin, Observable, of, switchMap } from 'rxjs';
import { ConfirmModalComponent, NewNoteModalComponent } from 'projects/components/src/lib/modal';
import { LoaderComponent } from 'projects/components/src/public-api';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { MatTable } from '@angular/material/table';


@Component({
    selector: 'pt-member-internal-details',
    templateUrl: './internal-details.component.html',
    styleUrls: ['./internal-details.component.scss']
})
export class MemberInternalDetailsComponent implements OnInit {

    MemberType = MemberType;
    MemberStatus = MemberStatus;
    CommentType = CommentType;
    KeyPartyStatus = KeyPartyStatus;

    @Input() memberId: string;
    @Input() memberType: MemberType;
    @Input() userId: string;
    @Input() task: any;
    @Input() changeTrigger: number;
    @Input() memberReadOnly = false;

    @Output() next: EventEmitter<string> = new EventEmitter<string>();
    @Output() tierChanged: EventEmitter<RiskTier> = new EventEmitter<RiskTier>();

    isConnectedMember: boolean;
    hasLoans: boolean;
    member: Member;

    merchantForm: UntypedFormGroup;
    memberForm: UntypedFormGroup;
    memberFunctionForm: UntypedFormGroup;

    merchantConfigId: string;
    isLoadingResults = true;
    isPrivateMerchant = false;
    complianceSearchEnabled = false;

    riskTiers: RiskTier[];
    riskRatings: RiskRating[];

    displayedColumns: string[] = ['name', 'owner_percentage', 'adverse_media_screen', 'id_risk_screen', 'comply_advantage'];
    displayedOtherDocColumns: string[] = ['name', 'date', 'action'];

    transactionOptions = [SupportedTransactionType.OUTGOING, SupportedTransactionType.INCOMING, SupportedTransactionType.BOTH, SupportedTransactionType.EXTERNAL_OUTGOING];
    incomingOptions: TransactionType[] = [TransactionType.INCOMING_WIRE_FROM_MERCHANT, TransactionType.INCOMING_ACH_FROM_MERCHANT, TransactionType.INCOMING_CHECK_FROM_MERCHANT];
    outgoingOptions: TransactionType[] = [TransactionType.OUTGOING_CHECK_TO_MERCHANT, TransactionType.OUTGOING_DIGITAL_CHECK_TO_MERCHANT];

    keyParties: KeyParty[] = [];
    authorityDocuments: Upload[] = [];
    otherDocuments: Upload[] = [];

    merchantEmail: string;

    @ViewChild('downloadDocumentLink') private downloadDocumentLink: ElementRef;
    @ViewChild('otherDocumentsTable') documentTable: MatTable<Upload>;

    constructor(private memberService: MemberService,
                public authService: AuthService,
                private formBuilder: UntypedFormBuilder,
                private memberFormService: MemberFormService,
                private keyPartyService: KeyPartyService,
                private dataroomService: DataroomService,
                private bankAccountService: BankAccountService,
                private merchantAccountService: MerchantAccountService,
                private lenderService: LenderService,
                private merchantFormService: MerchantFormService,
                private partnerService: PartnerService,
                private connectedMemberService: ConnectedMemberService,
                private dialog: MatDialog,
                private overlay: Overlay) { }

    ngOnInit() {
        this.complianceSearchEnabled = Configuration.getConfig().complyAdvantageEnabled;
        if (this.authService.getProfile().role === Role.AUTHORITY_READONLY) {
            this.displayedOtherDocColumns.splice(this.displayedOtherDocColumns.indexOf('action'), 1);
        }
        if (!this.complianceSearchEnabled) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('comply_advantage'), 1);
        }
        this.isInternalDetailsValid = this.isInternalDetailsValid.bind(this);
        this.onNavigate = this.onNavigate.bind(this);
        this.onSave = this.onSave.bind(this);
        this.initForms();
    }

    initForms(callback?: any) {
        this.keyParties = [];
        this.riskTiers = Object.values(RiskTier);
        this.riskRatings = Object.values(RiskRating);
        this.riskRatings.splice(this.riskRatings.indexOf(RiskRating.BLOCKED), 1);

        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            this.member = member;
            if (member.memberType === MemberType.CONSUMER || member.memberType === MemberType.INDIVIDUAL_MERCHANT) {
                this.riskTiers.splice(this.riskTiers.indexOf(RiskTier.TIER_1), 1);
                this.riskRatings.splice(this.riskRatings.indexOf(RiskRating.SPECIAL), 1);
            }
            this.initMemberFunctions(this.member);

            if (member.memberType === MemberType.BUSINESS) {
                this.connectedMemberService.getPartnersByMemberId(this.memberId, 0, 1, 'created', 'DESC').subscribe((resp: PagedResponse<ConnectedMember>) => {
                    this.isConnectedMember = resp.totalElements > 0;
                    if (this.isConnectedMember || this.member.status === MemberStatus.PENDING || this.member.status === MemberStatus.APPLIED || this.member.status === MemberStatus.UNDER_REVIEW) {
                        this.memberFunctionForm.controls['partnerCtrl'].disable();
                    } else if (this.authService.isAuthority()) {
                        this.memberFunctionForm.controls['partnerCtrl'].enable();
                    }
                });

                if (!this.isLender() && this.isLendingEnabled()) {
                    this.lenderService.loadLoansByMemberIdAndStatuses(this.memberId, [LoanStatus.ACTIVE, LoanStatus.DILIGENCE], 0, 2, 'created', 'DESC').subscribe((resp: PagedResponse<Loan>) => {
                        this.hasLoans = resp.content.length > 0;
                        if (this.hasLoans || this.member.status === MemberStatus.PENDING || this.member.status === MemberStatus.APPLIED || this.member.status === MemberStatus.UNDER_REVIEW) {
                            this.memberFunctionForm.controls['lenderCtrl'].disable();
                        } else if (this.authService.isAuthority()) {
                            this.memberFunctionForm.controls['lenderCtrl'].enable();
                        }
                    });
                } else if (this.isLender() && this.authService.isAuthority()) {
                    this.memberFunctionForm.controls['lenderCtrl'].enable();
                }
            }

            this.initMember(member);
            if (this.memberType === MemberType.BUSINESS || this.memberType === MemberType.BUSINESS_MERCHANT || this.memberType === MemberType.INDIVIDUAL_MERCHANT) {
                this.loadKeyParties(callback);
            }
        });
    }

    initMember(member: Member) {
        if (this.memberType !== MemberType.BUSINESS_MERCHANT && this.memberType !== MemberType.INDIVIDUAL_MERCHANT) {
            this.initMemberForm(member);
            this.updateValidity();
        } else {
            this.merchantEmail = member.email;
            this.initMerchantForm();
        }
    }

    initMemberFunctions(member: Member) {
        this.memberFunctionForm = this.memberFormService.initializeMemberFunctionForm(member);
        this.memberFunctionForm.disable();
    }

    initEddField() {
        if (this.member.rating === RiskRating.SPECIAL) {
            this.memberForm.controls['eddRiskLevelCtrl'].patchValue('N/A (not within risk tolerance)');
        } else if (this.member.tier === RiskTier.NOT_TIERED || !this.member.tier || this.member.rating === RiskRating.NOT_RATED || !this.member.rating) {
            this.memberForm.controls['eddRiskLevelCtrl'].patchValue('Not Reviewed');
        } else if (this.member.tier === RiskTier.TIER_1 || this.member.rating === RiskRating.HIGH) {
            this.memberForm.controls['eddRiskLevelCtrl'].patchValue('High');
        } else if (this.member.tier === RiskTier.TIER_2 || this.member.rating === RiskRating.MEDIUM) {
            this.memberForm.controls['eddRiskLevelCtrl'].patchValue('Medium');
        } else {
            this.memberForm.controls['eddRiskLevelCtrl'].patchValue('Low');
        }
        this.memberForm.controls['eddRiskLevelCtrl'].disable();
    }

    initMemberForm(member: Member) {
        this.memberForm = this.memberFormService.initializeForm(member);
        this.initEddField();
        if (!this.authService.isAuthority()) {
            this.memberForm.controls['tierCtrl'].disable();
            this.memberForm.controls['ratingCtrl'].disable();
        }
        this.memberForm.markAsPristine();
    }

    initMerchantForm() {
        this.merchantAccountService.getMerchantAccountConfigByMerchantMemberId(this.memberId).subscribe((merchantConfig: MerchantAccountConfig) => {
            this.merchantConfigId = merchantConfig.id;
            this.isPrivateMerchant = !merchantConfig.shared;
            OnboardingTrackingService.setPrivateMerchant(this.isPrivateMerchant);
            this.merchantForm = this.merchantFormService.initializeMerchantConfig(merchantConfig);
            this.memberForm = this.formBuilder.group({
                ratingCtrl: new UntypedFormControl(merchantConfig.merchantMemberAccount.member.rating || RiskRating.NOT_RATED, [Validators.required]),
                tierCtrl: new UntypedFormControl(merchantConfig.merchantMemberAccount.member.tier || RiskTier.NOT_TIERED, [Validators.required]),
                eddRiskLevelCtrl: new UntypedFormControl('')
            });
            this.initEddField();
            if (merchantConfig.merchantAccountType === SupportedTransactionType.INCOMING) {
                this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].setValidators(null);
                this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].disable();
            } else if (merchantConfig.merchantAccountType === SupportedTransactionType.OUTGOING) {
                this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].setValidators(null);
                this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].disable();
            } else if (merchantConfig.merchantAccountType === SupportedTransactionType.EXTERNAL_OUTGOING) {
                this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].setValidators(null);
                this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].setValidators(null);
                this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].disable();
                this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].disable();
            }

            if (!this.merchantEmail) {
                this.merchantForm.controls['merchantNotificationEmailCtrl'].setValue(false);
                this.merchantForm.controls['merchantNotificationEmailCtrl'].disable();
            }
            this.merchantForm.updateValueAndValidity();
            this.merchantForm.markAsPristine();
            if (this.memberReadOnly) {
                this.merchantForm.disable();
            }
            if (!this.authService.isAuthority()) {
                this.memberForm.controls['tierCtrl'].disable();
                this.memberForm.controls['ratingCtrl'].disable();
            }
            this.merchantForm.valueChanges.subscribe(() => {
                this.updateValidity();
            });
            this.updateValidity();
            if (merchantConfig.merchantAccountType !== SupportedTransactionType.EXTERNAL_OUTGOING) {
                this.updateBankingOptions(merchantConfig.merchantMemberAccount.memberId, merchantConfig.merchantMemberAccount.id);
            }
        });
    }

    updateBankingOptions(merchantMemberId: string, merchantMemberAccountId: string) {
        this.bankAccountService.getLinkedBankAccountsForMerchant(merchantMemberId, merchantMemberAccountId, 0, -1, 'created', 'DESC').subscribe((response: PagedResponse<LinkedBankAccount>) => {
            const linkedBankAccounts = response.content.filter((linkedBankAccount) => {
                return linkedBankAccount.bankAccount.source !== BankAccountSource.EXTERNALLY_PROVIDED_ACCOUNT;
            });
            if (linkedBankAccounts.length) {
                const defaultBankAccount = linkedBankAccounts[0].bankAccount;
                if (defaultBankAccount.wireRoutingNumber && this.outgoingOptions.indexOf(TransactionType.OUTGOING_WIRE_TO_MERCHANT) === -1) {
                    this.outgoingOptions.unshift(TransactionType.OUTGOING_WIRE_TO_MERCHANT);
                }
                if (defaultBankAccount.routingNumber && this.outgoingOptions.indexOf(TransactionType.OUTGOING_ACH_TO_MERCHANT) === -1) {
                    this.outgoingOptions.unshift(TransactionType.OUTGOING_ACH_TO_MERCHANT);
                }
            }
        });
    }

    updateTier() {
        const tier = this.memberForm.controls['tierCtrl'].value;

        if (this.member.jurisdiction && tier === RiskTier.TIER_1) {
            const dialogConfig: MatDialogConfig = {};
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'normal-modal';
            dialogConfig.disableClose = true;

            const jurisdiction = Utils.getJurisdictionName(this.member.jurisdiction);

            dialogConfig.data = {
                title: `Tier Change for ${this.memberType === MemberType.BUSINESS ? 'Member' : 'Business Merchant'}`,
                description: `<p>You are about to change the tier to <b>Tier 1</b>.<p><b>Any existing Tier 1 members outside of ${jurisdiction} will `
                    + `no longer be able to transact with this ${this.memberType === MemberType.BUSINESS ? 'Member' : 'Business Merchant'}.</b></p>`
                    + '<p>Click Change to if you are certain you want to update the tier.</p>',
                confirmText: 'Change',
                confirmAction: 'change',
                rejectText: 'Cancel',
                rejectAction: 'cancel'
            };

            const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

            dialog?.afterClosed().subscribe((confirmAction: any) => {
                if (confirmAction === 'change') {
                    this.handleTierUpdate(tier);
                } else {
                    this.memberForm.controls['tierCtrl'].patchValue(this.member.tier);
                    this.memberForm.controls['tierCtrl'].markAsPristine();
                    this.memberForm.controls['tierCtrl'].updateValueAndValidity();
                }
            });

        } else {
            this.handleTierUpdate(tier);
        }
    }

    handleTierUpdate(tier: RiskTier) {
        const updates = {
            tier
        };
        this.updateMember(updates);
        this.tierChanged.emit(tier);
    }

    updateRating() {
        const updates = {
            rating: this.memberForm.controls['ratingCtrl'].value
        };
        this.updateMember(updates);
    }

    updateMember(updates: any) {
        const overlayRef = this.showLoader();
        this.memberService.updateMember(this.memberId, updates).subscribe((member: Member) => {
            this.member = member;
            this.initMember(member);
            overlayRef.dispose();
        }, (error: any) => {
            // reintializing the member form controls with details present in member object
            // if an error occurs while updating the company details, we should show the old data
            this.initMember(this.member);
            this.handleErrors(error, overlayRef);
        });
    }

    handleErrors(error: any, overlayRef?: OverlayRef) {
        overlayRef?.dispose();
        throw error;
    }

    loadKeyParties(callback: any) {
        combineLatest([this.keyPartyService.getAllActiveKeyParties(this.memberId),
            this.keyPartyService.getKeyPartiesByStatus(this.memberId, 0, 100, 'percentOwnership', 'DESC', KeyPartyStatus.DISABLED)]).pipe(
            switchMap(([activeKeyPartyList, disabledKeyPartyList]) => {
                // Adding two result arrays Active owners and Hidden owners to a single array ownersList
                const keyPartiesList = [...activeKeyPartyList['content'], ...disabledKeyPartyList['content']];
                return of(keyPartiesList);
            }), catchError((_error) => {
                return of([]);
            })
        ).subscribe((keyParties) => {
            if (keyParties.length) {
                const corpEntityIndex = keyParties.findIndex((keyParty) => {
                    return keyParty.type === KeyPartyType.CORPORATE_ENTITY;
                });
                if (corpEntityIndex >= 0) {
                    const corpEntity = keyParties.splice(corpEntityIndex, 1)[0];
                    keyParties.unshift(corpEntity);
                }
                keyParties = keyParties.filter((keyParty) => {
                    return !keyParty.contactPerson;
                });
                this.loadKeyPartyInfo(keyParties).subscribe((results: KeyParty[]) => {
                    this.keyParties = results;
                    this.isLoadingResults = false;
                    this.keyParties = this.keyParties.slice(0, 1).concat(this.keyParties.slice(1, this.keyParties.length).sort((a: any, b: any) => {
                        return b.percentOwnership - a.percentOwnership;
                    }));
                    this.loadKeyPartyDocuments();
                    this.updateValidity();
                    if (callback) {
                        callback();
                    }
                });
                if (!keyParties.length) {
                    this.loadOtherDocuments();
                    this.keyParties = [];
                    this.isLoadingResults = false;
                }
            } else {
                this.loadOtherDocuments();
                this.keyParties = [];
                this.isLoadingResults = false;
            }
        });
    }

    loadKeyPartyInfo(keyParties: KeyParty[]): Observable<KeyParty[]> {
        return forkJoin(keyParties.map((keyParty: KeyParty) => {
            if (keyParty.individualKeyParty) {
                return <Observable<KeyParty>>of({
                    memberId: this.memberId,
                    name: keyParty.individualKeyParty.firstName + ' ' + (keyParty.individualKeyParty.lastName || ''),
                    adverseMediaScreen: [],
                    idRiskScreen: [],
                    percentOwnership: keyParty.percentOwnership,
                    status: keyParty.status,
                    individualKeyPartyId: keyParty.individualKeyParty.id,
                    type: keyParty.type,
                    contactOnly: keyParty.contactPerson
                });
            } else if (keyParty.corporateKeyParty) {
                return <Observable<KeyParty>>of({
                    memberId: this.memberId,
                    name: keyParty.corporateKeyParty.name,
                    adverseMediaScreen: [],
                    idRiskScreen: [],
                    percentOwnership: keyParty.percentOwnership,
                    status: keyParty.status,
                    corporateKeyPartyId: keyParty.corporateKeyParty.id,
                    type: keyParty.type
                });
            }
        }));
    }

    loadKeyPartyDocuments() {
        this.authorityDocuments = [];
        this.keyParties.forEach((keyParty) => {
            this.dataroomService.getUploads('', [UploadStatus.ACTIVE], [DocumentType.ID_RISK_SCREEN, DocumentType.MEDIA_SCREEN],
                keyParty.individualKeyPartyId || keyParty.corporateKeyPartyId).subscribe((screeningDocuments: Upload[]) => {
                screeningDocuments.forEach((document) => {
                    if (document.documentType === DocumentType.MEDIA_SCREEN) {
                        keyParty.adverseMediaScreen.push(document);
                    } else {
                        keyParty.idRiskScreen.push(document);
                    }
                });
                this.authorityDocuments.push(...screeningDocuments);
                OnboardingTrackingService.setAuthorityDocuments(this.authorityDocuments);
            });
        });
        this.loadOtherDocuments();
    }

    loadOtherDocuments() {
        this.otherDocuments = [];
        this.dataroomService.getUploads(this.memberId, [UploadStatus.ACTIVE], [DocumentType.OTHER_DILIGENCE], this.memberId).subscribe((otherDocuments: Upload[]) => {
            this.otherDocuments.push(...otherDocuments);
            this.documentTable?.renderRows();
        });
    }

    onNavigate(reset: any) {
        if (this.isInternalDetailsValid()) {
            const dialogConfig: MatDialogConfig = {};
            dialogConfig.autoFocus = true;
            dialogConfig.panelClass = 'normal-modal';
            dialogConfig.disableClose = true;

            dialogConfig.data = {
                title: 'You have unsaved changes.',
                description: 'Click Save to save your changes and continue, or click Ignore to continue without saving.',
                confirmText: 'Save',
                confirmAction: 'save',
                rejectText: 'Ignore',
                rejectAction: 'ignore'
            };

            const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

            dialog?.afterClosed().subscribe((confirmAction: any) => {
                if (confirmAction === 'save') {
                    this.updateInformation(() => {
                        reset();
                        this.next.emit();
                    });
                } else if (confirmAction === 'ignore') {
                    this.initForms(() => {
                        reset();
                        this.next.emit();
                    });
                } else {
                    reset();
                }
            });
        } else {
            reset();
            this.next.emit();
        }
    }

    onSave(reset?: any) {
        this.updateInformation(reset);
    }

    updatePartnerFunction() {
        this.showLoader();
        if (this.memberFunctionForm.controls['partnerCtrl'].value) {
            this.partnerService.enablePartnerFeatures(this.memberId).subscribe(() => {
                this.reload();
            });
        } else {
            this.partnerService.disablePartnerFeatures(this.memberId).subscribe(() => {
                this.reload();
            });
        }
    }

    updateLenderFunctions() {
        const memberFunctions = [];
        if (this.memberFunctionForm.controls['partnerCtrl'].value) {
            memberFunctions.push(MemberFunction.PARTNER);
        }
        if (this.memberFunctionForm.controls['lenderCtrl'].value) {
            memberFunctions.push(MemberFunction.LENDER);
        }
        this.showLoader();
        this.memberService.updateMember(this.memberId, { memberFunctions: memberFunctions }).subscribe(() => {
            this.reload();
        });
    }

    updateInformation(callback?: any) {
        if (this.merchantConfigId) {
            const updates = {
                merchantAccountNote: this.merchantForm.controls['merchantAccountNoteCtrl'].value
            };
            this.merchantAccountService.updateMerchantAccountConfig(this.merchantConfigId, updates).subscribe(() => {
                this.initForms(callback);
            });
        } else {
            this.initForms(callback);
        }
    }

    updateValidity() {
        if (this.member.memberType !== MemberType.FEE_ACCOUNT) {
            OnboardingTrackingService.setInternalDetails(this.member.tier, this.member.rating, this.member.jurisdiction,
                ((this.memberType === MemberType.BUSINESS_MERCHANT || this.memberType === MemberType.INDIVIDUAL_MERCHANT) && this.merchantForm) ? this.merchantFormService.getMerchantConfig(this.merchantForm) : null);
            OnboardingTrackingService.setInternalInfoDirty(
                this.memberForm?.get('ratingCtrl').dirty ||
                this.memberForm?.get('tierCtrl').dirty ||
                ((this.memberType === MemberType.BUSINESS_MERCHANT || this.memberType === MemberType.INDIVIDUAL_MERCHANT) && this.merchantForm?.dirty));
        }
    }

    isInternalDetailsValid() {
        return OnboardingTrackingService.isRiskProfileValid() &&
            this.memberForm &&
            this.memberForm.get('ratingCtrl').valid &&
            this.memberForm.get('tierCtrl').valid &&
            ((this.memberType !== MemberType.BUSINESS_MERCHANT && this.memberType !== MemberType.INDIVIDUAL_MERCHANT) ||
                ((this.memberType === MemberType.BUSINESS_MERCHANT || this.memberType === MemberType.INDIVIDUAL_MERCHANT) && this.merchantForm && this.merchantForm.valid)) &&
            OnboardingTrackingService.isInternalInfoDirty();
    }

    isTierValid() {
        return this.memberForm?.controls['tierCtrl'].value !== RiskTier.NOT_TIERED;
    }

    isRatingValid() {
        return this.memberForm?.controls['ratingCtrl'].value !== RiskRating.NOT_RATED;
    }

    getIconClass(keyParty: KeyParty) {
        if (keyParty.contactOnly || (keyParty.adverseMediaScreen.length && keyParty.idRiskScreen.length)) {
            return 'fa-check-circle-o';
        }
        return 'fa-question-circle-o';
    }

    uploadMediaScreen(keyParty: KeyParty) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'Media Screen for ' + keyParty.name,
            memberId: this.memberId,
            path: `${keyParty.individualKeyPartyId || keyParty.corporateKeyPartyId}/screen/media/`,
            referencedEntityId: keyParty.individualKeyPartyId || keyParty.corporateKeyPartyId,
            documentTypes: [DocumentType.MEDIA_SCREEN],
            allowMultiple: true,
            hideDescription: true
        };
        const dialog = this.dialog.open(OtherDocumentModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((saved: boolean) => {
            if (saved) {
                this.initForms();
            }
        });
    }

    uploadIdRiskScreen(keyParty: KeyParty) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'ID/Risk Screen for ' + keyParty.name,
            memberId: this.memberId,
            path: `${keyParty.individualKeyPartyId || keyParty.corporateKeyPartyId}/screen/idrisk/`,
            referencedEntityId: keyParty.individualKeyPartyId || keyParty.corporateKeyPartyId,
            documentTypes: [DocumentType.ID_RISK_SCREEN],
            allowMultiple: true,
            hideDescription: true
        };
        const dialog = this.dialog.open(OtherDocumentModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((saved: boolean) => {
            if (saved) {
                this.initForms();
            }
        });
    }

    uploadOtherDiligenceDocument() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'Upload Other Diligence Document',
            memberId: this.memberId,
            path: `${this.memberId}/screen/other/`,
            referencedEntityId: this.memberId,
            documentTypes: [DocumentType.OTHER_DILIGENCE],
            allowMultiple: true,
            hideDescription: true
        };
        const dialog = this.dialog.open(OtherDocumentModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((saved: boolean) => {
            if (saved) {
                this.initForms();
            }
        });
    }

    downloadFile(document: any) {
        this.dataroomService.downloadResource(document, this.downloadDocumentLink);
    }

    isMember() {
        return this.memberType === MemberType.BUSINESS;
    }

    onAddNote() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            memberId: this.memberId,
            userId: this.authService.getProfile().userId,
            type: CommentType.INTERNAL
        };
        const dialog = this.dialog.open(NewNoteModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.refreshNotes();
            }
        });
    }

    refreshNotes() {
        this.changeTrigger = Math.random();
    }

    deleteDocument(documentId: string) {
        this.dataroomService.deleteDocument(documentId).subscribe(() => {
            this.initForms();
        });
    }

    onAllowedTransactionsValueChange(_event: any) {
        const merchantAccountType = this.merchantForm.controls['merchantAccountTypeCtrl'].value;
        if (merchantAccountType === SupportedTransactionType.INCOMING) {
            this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].setValidators(null);
            this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].setValue(null);
            this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].disable();
            this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].setValidators([Validators.required]);
            this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].enable();
        } else if (merchantAccountType === SupportedTransactionType.OUTGOING) {
            this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].setValidators(null);
            this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].setValue(null);
            this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].disable();
            this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].setValidators([Validators.required]);
            this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].enable();
        } else if (merchantAccountType === SupportedTransactionType.BOTH) {
            this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].setValidators([Validators.required]);
            this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].enable();
            this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].setValidators([Validators.required]);
            this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].enable();
        } else if (merchantAccountType === SupportedTransactionType.EXTERNAL_OUTGOING) {
            this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].setValidators(null);
            this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].setValidators(null);
            this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].setValue(null);
            this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].setValue(null);
            this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].disable();
            this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].disable();
        }

        this.merchantForm.updateValueAndValidity();
        const updates = {
            merchantAccountType: this.merchantForm.controls['merchantAccountTypeCtrl'].value,
            defaultOutgoingTransactionType: this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].value,
            defaultIncomingTransactionType: this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].value
        };
        this.updateMerchantConfig(updates);
    }

    onTransactionTypeChange(_event: any) {
        let merchantAccountType = this.merchantForm.controls['merchantAccountTypeCtrl'].value;
        const updates = {
            merchantAccountType: merchantAccountType,
            defaultIncomingTransactionType: merchantAccountType !== SupportedTransactionType.EXTERNAL_OUTGOING ? this.merchantForm.controls['defaultIncomingTransactionTypeCtrl'].value : '',
            defaultOutgoingTransactionType: merchantAccountType !== SupportedTransactionType.EXTERNAL_OUTGOING ? this.merchantForm.controls['defaultOutgoingTransactionTypeCtrl'].value : ''
        };
        this.updateMerchantConfig(updates);
    }

    updateMerchantConfig(updates: any) {
        this.merchantAccountService.updateMerchantAccountConfig(this.merchantConfigId, updates).subscribe((merchantConfig: MerchantAccountConfig) => {
            this.bankAccountService.getBankAccounts(merchantConfig.merchantMemberAccount.memberId, [BankAccountStatus.ACTIVE, BankAccountStatus.PENDING], 0, -1, 'created', 'DESC').subscribe((response: PagedResponse<BankAccount>) => {
                const activeBankAccounts = response.content.filter((bankAccount: BankAccount) => {
                    return bankAccount.status === BankAccountStatus.ACTIVE;
                });
                const pendingBankAccounts = response.content.filter((bankAccount: BankAccount) => {
                    return bankAccount.status === BankAccountStatus.PENDING;
                });
                OnboardingTrackingService.setBankingDetails(merchantConfig.defaultOutgoingTransactionType, activeBankAccounts, pendingBankAccounts);
            });
            this.initForms();
        });
    }

    isLender() {
        return this.member.memberFunctions.indexOf(MemberFunction.LENDER) >= 0;
    }

    isFeeAccount() {
        return this.memberType === MemberType.FEE_ACCOUNT;
    }

    onMerchantEmailNotificationChange() {
        const updates = {
            merchantEmailNotification: this.merchantForm.controls['merchantNotificationEmailCtrl'].value
        };
        this.updateMerchantConfig(updates);
    }

    showLoader() {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        overlayRef.attach(new ComponentPortal(LoaderComponent));
        return overlayRef;
    }

    reload() {
        window.location.reload();
    }

    toggleBlocklistConsumer() {
        if (!this.authService.isAuthority()) {
            return;
        }

        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
        if (this.member.status !== MemberStatus.BLOCKED) {
            componentRef.instance.title = 'Blocklisting member and accounts...';
            this.memberService.blocklistConsumer(this.memberId).subscribe(() => {
                overlayRef.dispose();
                window.location.reload();
            });
        } else {
            componentRef.instance.title = 'Removing blocklist...';
            this.memberService.unblockConsumer(this.memberId).subscribe(() => {
                overlayRef.dispose();
                window.location.reload();
            });
        }
    }

    getClass() {
        if (this.authService.isAuthority()) {
            return '';
        }
        return 'fa-disabled';
    }

    getPartnerFeatureLabel() {
        if (this.isConnectedMember) {
            return 'Partner Features (unavailable - already connected)';
        } else if (this.member.status === MemberStatus.PENDING || this.member.status === MemberStatus.APPLIED || this.member.status === MemberStatus.UNDER_REVIEW) {
            return 'Partner Features (unavailable - application pending)';
        } else {
            return 'Partner Features';
        }
    }

    getLenderFeatureLabel() {
        if (this.hasLoans) {
            return 'Lender Features (unavailable - has loans)';
        } else if (this.member.status === MemberStatus.PENDING || this.member.status === MemberStatus.APPLIED || this.member.status === MemberStatus.UNDER_REVIEW) {
            return 'Lender Features (unavailable - application pending)';
        } else {
            return 'Lender Features';
        }
    }

    isLendingEnabled() {
        return Configuration.getConfig().lendingEnabled;
    }
}
