import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import {
    Country, AdminDivision, AddressType, Configuration, CustomValidators, MemberAccount
} from 'projects/services/src/public-api';

@Component({
    selector: 'pc-address-input',
    templateUrl: './address-input.component.html'
})
export class AddressInputComponent implements OnInit {

    @Input() addressTypeOpts: AddressType[] = [];
    @Input() addressForm: UntypedFormGroup;
    @Input() lockCountry = false;
    @Input() required = true;
    @Input() showLocationName = false;
    @Input() showAccountLink = false;
    @Input() memberAccountOptions: MemberAccount[] = [];

    adminDivisionLabel = 'State';
    countries: Country[];
    adminDivisions: AdminDivision[] = [];
    countryCode: string;
    currentCountry: string;

    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.countries = Configuration.getConfig().countries;
        if (this.lockCountry) {
            this.addressForm.get('countryCtrl').disable();
        } else {
            this.addressForm.controls['countryCtrl'].valueChanges.subscribe((country) => {
                if (this.currentCountry !== country) {
                    this.changeCountry(country, true);
                }
            });
        }
        this.changeCountry(this.addressForm.controls['countryCtrl'].value, false);
        if (this.addressTypeOpts.length === 1) {
            this.addressForm.patchValue({typeCtrl: this.addressTypeOpts[0]});
        }
    }

    changeCountry(country: string, reset: boolean) {
        this.countryCode = '';
        if (country && this.currentCountry !== country) {
            const countryDefinition = this.countries.find((c) => {
                return c.name === country;
            });
            if (countryDefinition) {
                this.currentCountry = country;
                this.countryCode = countryDefinition.code;
                this.adminDivisions = countryDefinition.divisions || [];
                this.adminDivisionLabel = countryDefinition.divisionLabel || 'State';
            } else {
                this.currentCountry = '';
                this.adminDivisions = [];
                this.adminDivisionLabel = 'State';
            }
            if (reset) {
                this.clearRelatedFields();
            }
        } else if (!country) {
            this.currentCountry = '';
            this.adminDivisions = [];
            this.adminDivisionLabel = 'State';
            this.clearRelatedFields();
        }
    }

    clearRelatedFields() {
        this.addressForm.patchValue({stateProvinceCtrl: ''});
        this.addressForm.patchValue({zipPostalCodeCtrl: ''});
        this.addressForm.updateValueAndValidity();
    }

    addressTypeChanged(event: any) {
        if (event === AddressType.RETAIL) {
            this.addressForm.get('nicknameCtrl').setValidators([Validators.required, Validators.maxLength(128), CustomValidators.noLeadingOrTrailingWhitespace]);
            this.addressForm.get('accountIdCtrl').setValidators([Validators.required]);
        } else {
            this.addressForm.get('nicknameCtrl').setValidators([Validators.maxLength(128), CustomValidators.noLeadingOrTrailingWhitespace]);
            this.addressForm.get('accountIdCtrl').setValidators(null);
            this.addressForm.patchValue({accountIdCtrl: ''});
        }
        this.addressForm.updateValueAndValidity();
        this.changeDetectorRef.detectChanges();
    }

    isRetail() {
        return this.addressForm.controls['typeCtrl'] && this.addressForm.controls['typeCtrl'].value === AddressType.RETAIL;
    }

    // Parse the Google api results
    setAddress(place: object) {
        const streetAddress = this.getStreetAddress(place);
        const city = this.getCity(place);
        const postalCode = this.getPostalCode(place);
        const stateProvince = this.getState(place);
        if (!this.addressForm.get('countryCtrl').value) {
            this.addressForm.patchValue({countryCtrl : this.getCountry(place) });
        }
        if (streetAddress) {
            this.addressForm.patchValue({streetAddressOneCtrl : streetAddress });
            this.addressForm.patchValue({cityCtrl : city });
            this.addressForm.patchValue({zipPostalCodeCtrl: postalCode });
            this.addressForm.patchValue({stateProvinceCtrl: stateProvince});
            this.addressForm.updateValueAndValidity();
            this.addressForm.markAsTouched();
        } else {
            this.addressForm.patchValue({streetAddressOneCtrl: ''});
        }
        this.updateValidations();
    }

    getPostalCode(place: object) {
        const postCodeSuffix = this.getPostCodeSuffix(place);
        if (postCodeSuffix) {
            return this.getPostCode(place) + '-' + postCodeSuffix;
        }
        return this.getPostCode(place);
    }

    getAddrComponent(place: any, componentTemplate: any) {
        const components: any[] = place.address_components;
        for (let component of components) {
            const types: any[] = component.types;
            for (let type of types) {
                if (componentTemplate[type]) {
                    return component[componentTemplate[type]];
                }
            }
        }
        return;
    }

    getStreetNumber(place: any) {
        // eslint-disable-next-line camelcase
        const streetNumber = this.getAddrComponent(place, { street_number: 'short_name' });
        if (!streetNumber) {
            return '';
        }
        return streetNumber;
    }

    getStreet(place: any) {
        // eslint-disable-next-line camelcase
        return this.getAddrComponent(place, { route: 'long_name' });
    }

    getCity(place: any) {
        // eslint-disable-next-line camelcase
        return this.getAddrComponent(place, { locality: 'long_name', sublocality: 'long_name' });
    }

    getState(place: any) {
        // eslint-disable-next-line camelcase
        return this.getAddrComponent(place, { administrative_area_level_1: 'long_name' });
    }

    getCountry(place: any) {
        // eslint-disable-next-line camelcase
        return this.getAddrComponent(place, { country: 'long_name' });
    }

    getPostCode(place: any) {
        // eslint-disable-next-line camelcase
        return this.getAddrComponent(place, { postal_code: 'long_name' });
    }

    getPostCodeSuffix(place: any) {
        // eslint-disable-next-line camelcase
        return this.getAddrComponent(place, { postal_code_suffix: 'long_name' });
    }

    getStreetAddress(place: object) {
        const streetNumber = this.getStreetNumber(place);
        if (streetNumber) {
            return this.getStreetNumber(place) + ' ' + this.getStreet(place);
        }
        return this.getStreet(place);
    }

    updateValidations() {
        if (this.required) {
            return;
        }
        if (this.addressForm.get('streetAddressOneCtrl').value || this.addressForm.get('cityCtrl').value || this.addressForm.get('stateProvinceCtrl').value || this.addressForm.get('zipPostalCodeCtrl').value) {
            this.addressForm.get('streetAddressOneCtrl').setValidators([Validators.required, Validators.minLength(3), CustomValidators.noLeadingOrTrailingWhitespace]);
            this.addressForm.get('cityCtrl').setValidators([Validators.required, CustomValidators.noLeadingOrTrailingWhitespace]);
            this.addressForm.get('stateProvinceCtrl').setValidators([Validators.required, Validators.minLength(2)]);
            this.addressForm.get('zipPostalCodeCtrl').setValidators([Validators.required, CustomValidators.zipCode]);
        } else {
            this.addressForm.get('streetAddressOneCtrl').setValidators([Validators.minLength(3), CustomValidators.noLeadingOrTrailingWhitespace]);
            this.addressForm.get('cityCtrl').setValidators([CustomValidators.noLeadingOrTrailingWhitespace]);
            this.addressForm.get('stateProvinceCtrl').setValidators([Validators.minLength(2)]);
            this.addressForm.get('zipPostalCodeCtrl').setValidators([CustomValidators.zipCode]);
        }
        this.addressForm.get('streetAddressOneCtrl').updateValueAndValidity();
        this.addressForm.get('cityCtrl').updateValueAndValidity();
        this.addressForm.get('stateProvinceCtrl').updateValueAndValidity();
        this.addressForm.get('zipPostalCodeCtrl').updateValueAndValidity();
    }
}
