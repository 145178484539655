import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { WorkflowService, SmartContractService, ContractFramework, PagedResponse, ContractTemplate, Task, ContractTemplateType } from 'projects/services/src/public-api';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { SmartContractFormService } from 'projects/smart-contract-components/src/lib';

@Component({
    selector: 'pc-sc-new-template-modal',
    templateUrl: './new-template-modal.component.html'
})
export class NewTemplateModalComponent extends BaseModalComponent<NewTemplateModalComponent> implements OnInit {

    templateForm: UntypedFormGroup;
    errorMessage: string;
    roles = [{
        id: ContractTemplateType.BUYER,
        name: 'Buyer'
    }, {
        id: ContractTemplateType.VENDOR,
        name: 'Seller'
    }];
    contractFrameworks: ContractFramework[];

    constructor(private smartContractFormService: SmartContractFormService,
                private smartContractService: SmartContractService,
                private workflowService: WorkflowService,
                dialog: MatDialogRef<NewTemplateModalComponent>) {
        super(dialog);
    }

    ngOnInit() {
        this.isTemplateFormValid = this.isTemplateFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.templateForm = this.smartContractFormService.newTemplateForm();
        this.smartContractService.getAllContractFrameworks(0, -1, 'name', 'ASC').subscribe(
            (response: PagedResponse<ContractFramework>) => {
                this.contractFrameworks = response.content;
            }
        );
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    onSubmit() {
        this.errorMessage = null;
        const framework = new ContractFramework();
        framework.id = this.templateForm.value.frameworkCtrl;
        const createTemplateRequest = new ContractTemplate();
        createTemplateRequest.name = this.templateForm.value.nameCtrl;
        createTemplateRequest.description = this.templateForm.value.descriptionCtrl;
        createTemplateRequest.contractFramework = framework;
        createTemplateRequest.templateType = this.templateForm.value.templateTypeCtrl;

        this.workflowService.createTemplate(createTemplateRequest)
            .subscribe((response: any) => {
                this.workflowService.getTaskByProcessInstanceId(response.id)
                    .subscribe(
                        (taskQueryResponse: PagedResponse<Task>) => {
                            const taskId = taskQueryResponse.content[0].id;
                            this.workflowService.loadTask(taskId);
                            this.close(true);
                        }
                    );
            });

    }

    isTemplateFormValid() {
        return this.templateForm.valid;
    }
}
