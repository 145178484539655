<div>
    <div class="col-12">
        <div class="row mb-3">
            <div class="col-8">
                <span class="form-title">{{title}}</span>
            </div>
            <div class="col-4">
                <button *ngIf="authService.isAuthorityOrAdmin() && allowAdd" class="blue-button pull-right" (click)="onAddNew()" type="button">
                    <i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add Location</div>
                </button>
            </div>
        </div>

        <div *ngIf="subtitle" class="row mb-3">
            <div class="d-flex">
                <p>
                    <br>
                    <i>{{subtitle}}</i>
                    <br>
                </p>
            </div>
        </div>

        <div class="col-sm-12 horizontal-scroll mat-table-container">
            <div class="mat-table-spinner" *ngIf="isLoadingResults">
                <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
            </div>
            <table mat-table [dataSource]="addressOccupants" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir" [ngClass]="isDetailView() ? 'detail-view': ''">
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="type"> Location Type </th>
                    <td mat-cell *matCellDef="let element"> {{element.type | translate}} </td>
                </ng-container>
                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef> Address </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="px-1">
                            <pc-business-address [address]="element.address"></pc-business-address>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="nickname">
                    <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header="nickname"> Name </th>
                    <td mat-cell class="centered" *matCellDef="let element">
                        <div class="px-1">
                            <div><b>{{element.nickname}}</b></div>
                            <div class="text-nowrap" *ngIf="isDetailView() && element.type === AddressType.RETAIL"><b>Account:</b> {{element.accountName}}</div>
                            <div class="text-nowrap" *ngIf="isDetailView() && element.type === AddressType.RETAIL"><b>ID:</b> {{element.id}}</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="date_added">
                    <th mat-header-cell *matHeaderCellDef class="centered" mat-sort-header="created"> Date Added </th>
                    <td mat-cell class="centered" *matCellDef="let element"> {{ element.created | date:'mediumDate'}} </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell class="centered" *matHeaderCellDef> Action </th>
                    <td mat-cell  class="action centered" *matCellDef="let element">
                        <i *ngIf="canToggle(element)" class="fa fa-2x" [class.fa-toggle-on]="isEnabled(element)" [class.fa-toggle-off]="!isEnabled(element)" (click)="toggleEnabled($event, element)"></i>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.status === AddressStatus.DISABLED ? 'disabled' : ''"></tr>
            </table>
            <mat-paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
        </div>
    </div>
</div>
