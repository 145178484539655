<div class="col-12">
    <div class="row mb-3">
        <div class="col-5">
            <span class="form-title">
                <span>Historical </span>
                {{ pageTitle(isMerchant()) }}
            </span>
        </div>
    </div>

    <pc-datatable matSort matSortDisableClear
        [dataSource]="keyParties"
        [columns]="displayedColumns"
        [isLoading]="isLoadingResults"
        [totalElements]="resultsLength"
        [options]="tableOptions"
        (paginationChanged)="loadHistoricalKeyParties()"
        (sortChanged)="loadHistoricalKeyParties()">

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <img *ngIf="row.corporateKeyParty" src="/assets/images/site-icon.svg" alt="Corporate">
                <img *ngIf="row.individualKeyParty" src="/assets/images/profile-icon.svg" alt="Individual">
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name</th>
            <td mat-cell *matCellDef="let row">
                <div *ngIf="row.individualKeyParty">
                    <b>{{row.individualKeyParty.name}}</b>
                </div>
                <div *ngIf="row.corporateKeyParty">
                    <b>{{row.corporateKeyParty.name}}</b>
                    <div *ngIf="row.corporateKeyParty.dbaName">
                        <b>DBA: {{row.corporateKeyParty.dbaName}}</b>
                    </div>
                </div>

                <div *ngIf="row.individualKeyParty">
                    <div *ngIf="row.individualKeyParty.professionalTitle">
                        {{row.individualKeyParty.professionalTitle}}
                    </div>
                    <div *ngIf="row.individualKeyParty.phone">
                        {{row.individualKeyParty.phone | phone}}
                    </div>
                    <div *ngIf="row.individualKeyParty.email">
                        {{row.individualKeyParty.email}}
                    </div>
                </div>

                <div *ngIf="row.corporateKeyParty">
                    <div *ngIf="row.corporateKeyParty.officePhone">
                        {{row.corporateKeyParty.officePhone | phone}}
                    </div>
                    <div *ngIf="row.corporateKeyParty.email">
                        {{row.corporateKeyParty.email}}
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> Address</th>
            <td mat-cell *matCellDef="let row">
                <div class="px-1">
                    <pc-business-address *ngIf="row.individualKeyParty?.address" [address]="row.individualKeyParty?.address"></pc-business-address>
                    <pc-business-address *ngIf="row.corporateKeyParty?.address" [address]="row.corporateKeyParty?.address"></pc-business-address>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="details">
            <th mat-header-cell *matHeaderCellDef> Details</th>
            <td mat-cell *matCellDef="let row">
                <div *ngIf="isDetailsVisible(row)">
                    <div *ngIf="row.individualKeyParty.birthDate">
                        DOB: {{row.individualKeyParty.birthDate | date }}
                    </div>
                    <div *ngIf="row.individualKeyParty.usCitizen">
                        US Citizen
                    </div>
                    <div *ngIf="isDetailSsnVisible(row.individualKeyParty)">
                        SSN: {{row.individualKeyParty.taxId}}
                    </div>
                    <div *ngIf="row.individualKeyParty.nationality">
                        Nationality: {{row.individualKeyParty.nationality}}
                    </div>
                    <div *ngIf="isDetailPassportVisible(row.individualKeyParty)">
                        Passport: {{row.individualKeyParty.passportNumber}}
                    </div>
                    <div *ngIf="isDetailPassportExpirationVisible(row.individualKeyParty)">
                        Passport Expiration Date: {{row.individualKeyParty.passportExpirationDate | date}}
                    </div>
                </div>

                <div *ngIf="row.corporateKeyParty">
                    <div *ngIf="row.corporateKeyParty.taxId">
                        EIN/Tax ID: {{row.corporateKeyParty.taxId}}
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="relationship">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="percentOwnership"> Relationship</th>
            <td mat-cell *matCellDef="let row">
                <div *ngIf="isOwnershipVisible(row)">
                    Ownership: {{row.percentOwnership / 100 | percent}}
                </div>
                <div *ngIf="row.director">
                    Director
                </div>
                <div *ngIf="row.officer">
                    Officer
                </div>
                <div *ngIf="row.contactPerson">
                    Contact Info
                </div>
                <div *ngIf="row.licenseHolder">
                    License Holder
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="date_added">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="created"> Date Added</th>
            <td mat-cell *matCellDef="let row"> {{ row.created | date:'mediumDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="date_changed">
            <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header="updated"> Date Changed</th>
            <td mat-cell class="centered" *matCellDef="let row"> {{ row.updated | date:'mediumDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell class="centered" *matHeaderCellDef> Action</th>
            <td mat-cell class="action centered" *matCellDef="let row">
                <i class="fa fa-plus" (click)="readdKeyParty(row.id)"></i>
            </td>
        </ng-container>

    </pc-datatable>
</div>
