<div class="top-header">
    <div [class]="getMenuClass()" (click)="onToggleExpandSideMenu()">
        <div class="top-header-icon-content">
            <img src="assets/images/Confia-icon.png" *ngIf="!expandedMenu">
            <img src="assets/images/confia-logo.svg" *ngIf="expandedMenu">
        </div>
    </div>
    <div [class]="getBodyClass()">
        <div class="top-header-heading col-md-6">
            <h1 *ngIf="member">{{member.name}}</h1>
        </div>
        <div class="top-header-menu col-md-6">
            <div class="header-link" [class.active]="isActive('Notifications')">
                <a [routerLink]="isDisabled('/communication/notifications') ? null : ['/communication/notifications']"
                [routerLinkActive]="['router-link-active']"><i class="fa fa-bell-o" matTooltip="Open Items"></i></a>
            </div>
            <i class="fa fa-sign-out" (click)="onLoggedout()" aria-hidden="true" title="Log Out"></i>
        </div>
    </div>
</div>
