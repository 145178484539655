export class TransactionTypeTableData {

    date: string;
    wireDeposit: number;
    wireWithdrawal: number;
    achDeposit: number;
    achWithdrawal: number;
    cashDeposit: number;
    cashWithdrawal: number;
    checkDeposit: number;
    checkWithdrawal: number;
    network: number;

}