import { Component, Input, OnInit } from '@angular/core';
import { LendingKPIValues, LenderService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-kpi',
    templateUrl: './member-kpi.component.html',
    styleUrls: ['./member-kpi.component.scss']
})
export class MemberKpiComponent implements OnInit {

    @Input() memberAccountId: string;

    kpi: LendingKPIValues[] = [];

    constructor(private lenderService: LenderService) {}

    ngOnInit() {
        this.lenderService.getKPI(this.memberAccountId).subscribe(
            (data: LendingKPIValues[]) => {
                this.kpi = data;
            }
        );
    }

}
