import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../validators/custom.validators';

@Injectable({
    providedIn: 'root'
})
export class ReferralFormService {

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    initializeForm(): UntypedFormGroup {
        return this.formBuilder.group({
            emailCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.email, Validators.maxLength(64)]),
            memberNameCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(3), CustomValidators.memberName, Validators.maxLength(64)]),
            messageCtrl: new UntypedFormControl('', [Validators.maxLength(5000)]),
            referralCodeCtrl: new UntypedFormControl('', [Validators.required])
        });
    }
}
