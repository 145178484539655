import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { AuthService, BankingIntegrationProvider, BusinessCategory, MemberService, Signature } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-updated-terms-modal',
    templateUrl: './updated-terms-modal.component.html'
})
export class UpdatedTermsModalComponent extends BaseModalComponent<UpdatedTermsModalComponent> implements OnInit {

    memberId: string;
    businessCategory = BusinessCategory.OTHER;

    constructor(private authService: AuthService,
                private memberService: MemberService,
                dialogRef: MatDialogRef<UpdatedTermsModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.memberId = data.memberId;
    }


    ngOnInit(){
        this.close = this.close.bind(this);
    }

    updatedTermsAccepted(signature: Signature) {
        if (signature) {
            this.memberService.saveSignature(signature).subscribe(() => {
                this.close(true);
            });
        } else {
            this.close(false);
            this.authService.clear();
        }
    }

    close(termsAccepted: boolean) {
        super.close(termsAccepted);
    }
}
