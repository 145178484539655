import { Component, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Utils } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-form-amount-input',
    templateUrl: './form-amount-input.component.html',
    styleUrls: ['./form-amount-input.component.scss']
})
export class FormAmountInputComponent implements AfterViewInit, OnChanges {

    @Input() required: boolean;
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() placeholder = '0';
    @Input() dollar = true;
    @Input() includeCents = true;
    @Input() allowNegative = false;
    @Input() id: string;
    @Input() maxValueAllowed: string;
    @Input() amountAlignmentRight = false;

    @Output() amountChanged: EventEmitter<any> = new EventEmitter<any>();

    constructor() { }

    ngOnChanges() {
        this.transformTotal();
    }

    ngAfterViewInit() {
        this.transformTotal();
    }

    onAmountChanged() {
        this.transformTotal();
        if (!Utils.isNaN(this.formGroup.controls[this.formCtrl].value)) {
            this.amountChanged.emit(this.formGroup.controls[this.formCtrl].value);
        } else {
            this.amountChanged.emit('0.00');
        }
    }

    transformTotal() {
        const value = this.formGroup.controls[this.formCtrl].value;
        if (value !== null && value !== '' && !Utils.isNaN(value)) {
            if (this.allowNegative) {
                this.formGroup.controls[this.formCtrl].setValue(
                    Utils.formatCurrency(value.toString(), this.includeCents),
                    {emitEvent: false}
                );
            } else {
                this.formGroup.controls[this.formCtrl].setValue(
                    Utils.formatNonNegativeCurrency(value.toString(), this.includeCents),
                    {emitEvent: false}
                );
            }
        } else {
            this.formGroup.controls[this.formCtrl].setValue('');
        }
    }

    getClass() {
        if (this.amountAlignmentRight && this.dollar) {
            return 'dollar align-amount-right';
        } else if (this.dollar) {
            return 'dollar';
        }
        return '';
    }

    updateValidators() {
        if (!this.maxValueAllowed) {
            return;
        }
        const formControlValue = this.formGroup.controls[this.formCtrl].value;
        let formattedCurrentValue;
        if (formControlValue !== null && formControlValue !== '' && !Utils.isNaN(formControlValue)) {
            if (this.allowNegative) {
                formattedCurrentValue = Utils.formatCurrency(formControlValue.toString(), this.includeCents);
            } else {
                formattedCurrentValue = Utils.formatNonNegativeCurrency(formControlValue.toString(), this.includeCents);
            }
        }
        if (formattedCurrentValue && Number(formattedCurrentValue.toString().replaceAll(',', '')) > Number(this.maxValueAllowed)) {
            this.formGroup.controls[this.formCtrl].setErrors({ invalid: true });
        }
    }
}
