export class BillingProfile {

    id: string;
    memberId: string;
    memberAccountId: string;
    retailConvenienceFeeAmount: number;
    retailTransactionFeePercentage: number;
    monthlyAccountFee: number;
    cashDepositFeePercentage: number;
    cashDepositBaseFee: number;
    initialBillingCreditMonths: number;
    created: Date;
    achWithdrawalFee: number;
    achExpressPaymentFee: number;
    overdraftFee: number;
    wirePaymentFee: number;
    incomingWireFee: number;
}
