import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SmartContractService, ContractFramework, PagedResponse, Utils, ContractPartyClassification } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-sc-smart-contract-upload',
    templateUrl: './smart-contract-upload.component.html'
})
export class SmartContractUploadComponent implements OnInit {

    files = {};
    uploadForm: UntypedFormGroup;
    submitting = false;

    frameworkTypes = ['Producer -> Distributor', 'Distributor -> Retailer'];
    displayedColumns: string[] = ['name', 'description', 'version', 'type', 'last_updated'];
    contractFrameworks: ContractFramework[];

    @ViewChild('uploadFramework') uploadFrameworkInput: ElementRef;
    @ViewChild('uploadSections') uploadSectionsInput: ElementRef;
    @ViewChild('uploadTerms') uploadTermsInput: ElementRef;
    @ViewChild('uploadVariables') uploadVariablesInput: ElementRef;

    constructor(private formbuilder: UntypedFormBuilder,
                private smartContractService: SmartContractService) {}

    ngOnInit() {
        this.onSubmit = this.onSubmit.bind(this);
        this.isUploadFormValid = this.isUploadFormValid.bind(this);

        this.uploadForm = this.formbuilder.group({
            nameCtrl: new UntypedFormControl('', [Validators.required]),
            frameworkCtrl: new UntypedFormControl('', [Validators.required]),
            sectionsCtrl: new UntypedFormControl('', [Validators.required]),
            termsCtrl: new UntypedFormControl('', [Validators.required]),
            variablesCtrl: new UntypedFormControl('', [Validators.required]),
            typeCtrl: new UntypedFormControl('', [Validators.required])
        });
        this.uploadForm.get('frameworkCtrl').disable();
        this.uploadForm.get('sectionsCtrl').disable();
        this.uploadForm.get('termsCtrl').disable();
        this.uploadForm.get('variablesCtrl').disable();

        this.updateContractList();
    }

    updateContractList() {
        this.smartContractService.getAllContractFrameworks(0, 0, 'created', 'DESC').subscribe((frameworks: PagedResponse<ContractFramework>) => {
            return this.contractFrameworks = frameworks.content;
        });
    }

    selectFile(type: string, event: any) {
        if (event.target.files.length) {
            const file = event.target.files[0];
            Utils.validateFile(file, event);
            this.files[type] = file;
            this.uploadForm.controls[type + 'Ctrl'].setValue(file.name);
            this.uploadForm.controls[type + 'Ctrl'].markAsDirty();
        } else {
            delete this.files[type];
            this.uploadForm.controls[type + 'Ctrl'].setValue(null);
            this.uploadForm.controls[type + 'Ctrl'].markAsPristine();
        }
    }

    onSubmit(reset: any) {
        if (this.files && Object.keys(this.files).length === 4) {
            this.submitting = true;
            this.smartContractService.uploadFramework(this.uploadForm.value.nameCtrl, this.uploadForm.value.typeCtrl, this.files['framework'], this.files['sections'], this.files['terms'], this.files['variables']).subscribe(() => {
                this.uploadForm.reset();
                this.files = {};
                this.uploadFrameworkInput.nativeElement.value = '';
                this.uploadSectionsInput.nativeElement.value = '';
                this.uploadTermsInput.nativeElement.value = '';
                this.uploadVariablesInput.nativeElement.value = '';
                this.submitting = false;
                reset();
                this.updateContractList();
            }, () => {
                reset();
            });
        }
    }

    isUploadFormValid() {
        return !this.uploadForm.invalid && this.files && Object.keys(this.files).length === 4;
    }

    getType(framework: ContractFramework) {
        if (framework.buyerClassification === ContractPartyClassification.DISTRIBUTOR && framework.sellerClassification === ContractPartyClassification.PRODUCER) {
            return 'Producer -> Distributor';
        } else if (framework.buyerClassification === ContractPartyClassification.RETAILER && framework.sellerClassification === ContractPartyClassification.DISTRIBUTOR) {
            return 'Distributor -> Retailer';
        }
        return 'Unknown';
    }
}
