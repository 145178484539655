import { Component, OnInit } from '@angular/core';
import { AuthService } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-consumer-setting',
    templateUrl: './consumer-setting.component.html'
})
export class ConsumerSettingComponent implements OnInit {

    activeTab = 'bank_accounts';
    memberId: string;

    constructor(private authService: AuthService) {}

    ngOnInit() {
        this.memberId = this.authService.getProfile().memberId;
    }

    getTabClass(tabOption: string) {
        if (this.activeTab === tabOption) {
            return 'tab-option-selected';
        } else {
            return 'tab-option';
        }
    }

}
