import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RiskProfile, Utils } from 'projects/services/src/public-api';

@Injectable({
    providedIn: 'root'
})
export class RiskProfileFormService {

    constructor(private formbuilder: UntypedFormBuilder) { }

    initializeForm(riskProfile: RiskProfile): UntypedFormGroup {
        return this.formbuilder.group({
            idCtrl: new UntypedFormControl(riskProfile.id),
            createdCtrl: new UntypedFormControl(riskProfile.created),
            memberIdCtrl: new UntypedFormControl(riskProfile.memberId),
            memberAccountIdCtrl: new UntypedFormControl(riskProfile.memberAccountId),
            expectedMonthlyCashDepositAmountCtrl: new UntypedFormControl(riskProfile.expectedMonthlyCashDepositAmount),
            monthlyCashDepositAmountLimitCtrl: new UntypedFormControl(riskProfile.monthlyCashDepositAmountLimit),
            accountAmountLimitCtrl: new UntypedFormControl(riskProfile.accountAmountLimit),
            expectedMonthlyPaymentAmountCtrl: new UntypedFormControl(riskProfile.expectedMonthlyPaymentAmount),
            expectedMonthlyTransactionsCtrl: new UntypedFormControl(riskProfile.expectedMonthlyTransactions),
            maxNumberOfTransactionsPerMonthCtrl: new UntypedFormControl(riskProfile.maxNumberOfTransactionsPerMonth),
            maxTransactionTotalPerMonthCtrl: new UntypedFormControl(riskProfile.maxTransactionTotalPerMonth),
            maxNumberOfTransactionsPerDayCtrl: new UntypedFormControl(riskProfile.maxNumberOfTransactionsPerDay),
            maxTransactionTotalCtrl: new UntypedFormControl(riskProfile.maxTransactionTotal)
        });
    }

    getRiskProfile(form: UntypedFormGroup): RiskProfile {
        const riskProfile = new RiskProfile();
        riskProfile.id = form.get('idCtrl').value;
        riskProfile.created = form.get('createdCtrl').value;
        riskProfile.memberId = form.get('memberIdCtrl').value;
        riskProfile.memberAccountId = form.get('memberAccountIdCtrl').value;

        riskProfile.expectedMonthlyCashDepositAmount = Utils.parseFloatIgnoreCommas(form.get('expectedMonthlyCashDepositAmountCtrl').value);
        riskProfile.monthlyCashDepositAmountLimit = Utils.parseFloatIgnoreCommas(form.get('monthlyCashDepositAmountLimitCtrl').value);
        riskProfile.accountAmountLimit = Utils.parseFloatIgnoreCommas(form.get('accountAmountLimitCtrl').value);
        riskProfile.expectedMonthlyPaymentAmount = Utils.parseFloatIgnoreCommas(form.get('expectedMonthlyPaymentAmountCtrl').value);
        riskProfile.expectedMonthlyTransactions = Utils.parseFloatIgnoreCommas(form.get('expectedMonthlyTransactionsCtrl').value);
        riskProfile.maxNumberOfTransactionsPerMonth = Utils.parseFloatIgnoreCommas(form.get('maxNumberOfTransactionsPerMonthCtrl').value);
        riskProfile.maxTransactionTotalPerMonth = Utils.parseFloatIgnoreCommas(form.get('maxTransactionTotalPerMonthCtrl').value);
        riskProfile.maxNumberOfTransactionsPerDay = Utils.parseFloatIgnoreCommas(form.get('maxNumberOfTransactionsPerDayCtrl').value);
        riskProfile.maxTransactionTotal = Utils.parseFloatIgnoreCommas(form.get('maxTransactionTotalCtrl').value);

        return riskProfile;
    }

}
