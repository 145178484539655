import { ReceiptLineItem } from './receipt-line-item';

export class Receipt {
    id: string;
    created: Date;
    updated: Date;
    source: string;
    description: string;

    externalId: string;
    memberId: string;

    totalAmount: number;
    memberName: string;

    txnDate: Date;
    dueDate: Date;
    lineItems: ReceiptLineItem[];
    trackingNumber: number;
}
