<div class="col-12 dashboard-info">
    <div class="row">
        <div class="col-6 dashboard-header">
            <img class="dashboard-icon" src="assets/images/files-icon-2.svg" alt="">
            <span>My Templates</span>
        </div>
        <div class="col-6">
            <button class="blue-button pull-right" (click)="onNewTemplate()" type="button"><i class="fa fa-file-text-o pull-left dashboard-icon"></i><div class="pull-right-button">New Template</div></button>
            <button class="blue-button pull-right" (click)="cancel()" type="button"><i class="fa fa-arrow-left pull-left dashboard-icon"></i><div class="pull-right-button">Back</div></button>
        </div>
    </div>
    <div class="row col-12">
        <table mat-table [dataSource]="contractTemplateList" matSort matSortActive="created" matSortDisableClear matSortDirection="desc">

            <ng-container matColumnDef="framework">
                <th mat-header-cell *matHeaderCellDef> Framework </th>
                <td mat-cell *matCellDef="let element"> {{element.contractFramework.name }} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">
                    <a class="template-link" (click)="loadDetails(element)">{{element.name}}</a>
                </td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
                <td mat-cell *matCellDef="let element"> {{element.created | date:'medium'}} </td>
            </ng-container>

            <ng-container matColumnDef="templateType">
                <th mat-header-cell *matHeaderCellDef> Template Type </th>
                <td mat-cell *matCellDef="let element"> {{element.templateType}} </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            </ng-container>

            <ng-container matColumnDef="sharedWith">
                <th mat-header-cell *matHeaderCellDef> Shared With </th>
                <td mat-cell *matCellDef="let element">
                    <div [ngSwitch]="element.visibility">
                        <span *ngSwitchCase="ContractTemplateVisibility.PUBLIC">Everyone</span>
                        <span *ngSwitchCase="ContractTemplateVisibility.DRAFT">In Draft</span>
                        <div *ngSwitchCase="ContractTemplateVisibility.LIMITED">
                            <ul *ngFor="let access of element.templateAccess">
                                <li>{{access.memberName}}</li>
                            </ul>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [length]="resultsLength" [pageSize]="20"></mat-paginator>
    </div>
</div>
