import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService, MemberAccount, MemberStatus, PagedResponse, Profile, Role, Task, WorkflowService } from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NewUserModalComponent } from 'projects/components/src/lib/modal';
import { AccountSelectorService } from 'projects/components/src/lib/account-selector.service';

@Component({
    selector: 'pc-user-table-header',
    templateUrl: './user-table-header.component.html'
})
export class UserTableHeaderComponent implements OnInit {

    @Input() readOnly = false;
    @Input() status: MemberStatus;
    @Input() memberId: string;

    @Output() filterChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() userChanged: EventEmitter<void> = new EventEmitter<void>();

    profile: Profile;
    addUserAvailable = false;

    constructor(public authService: AuthService,
                private accountSelectorService: AccountSelectorService,
                private workflowService: WorkflowService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.profile = this.authService.getProfile();
        this.openNewUser = this.openNewUser.bind(this);
        if (this.status) {
            if (this.authService.isAuthority()) {
                if (this.status === MemberStatus.ACTIVE || this.status === MemberStatus.DISABLED) {
                    this.addUserAvailable = true;
                } else if (this.status === MemberStatus.APPLIED || this.status === MemberStatus.UNDER_REVIEW) {
                    this.workflowService.findTaskByTaskDefinitionsAndProcessVariable(['user_verify_email'], 'memberId', `${this.memberId}`).subscribe((response: PagedResponse<Task>) => {
                        this.addUserAvailable = (response.content.length === 0);
                    });
                }
            } else {
                this.addUserAvailable = this.authService.isCorporateAdmin() && !this.readOnly && this.status === MemberStatus.ACTIVE;
            }
        } else {
            this.addUserAvailable = ((this.authService.isCorporateAdmin() && !this.readOnly) || this.authService.isAuthority());
        }
    }

    onAddNewUser() {
        const includeCorpAdmin = this.authService.isAuthority();
        this.accountSelectorService.selectAccountAndPerform(this.memberId, includeCorpAdmin, this.openNewUser);
    }

    openNewUser(memberAccount: MemberAccount) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        let role = memberAccount.id ? Role.MEMBER_READONLY : Role.CORPORATE_ADMIN;
        if (!memberAccount.id && this.status === MemberStatus.APPLIED) {
            role = Role.NEW_ADMIN_REGISTRANT;
        }
        dialogConfig.data = {
            memberId: this.memberId,
            memberAccountId: memberAccount.id,
            role
        };
        const dialog = this.dialog.open(NewUserModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (refresh) => {
                if (refresh) {
                    this.userChanged.emit();
                }
            }
        );
    }
}
