import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { WatchlistSearchResult, WatchlistService, WatchlistStatus, WatchlistEntity, WatchlistUserNote, Transaction, CustomValidators } from 'projects/services/src/public-api';
import { catchError, merge, of as observableOf, startWith, switchMap } from 'rxjs';
import { WatchlistReviewModalComponent } from 'projects/pt/src/app/components/modal/watchlist-review-modal/watchlist-review-modal.component';
import { MatTable } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderComponent } from 'projects/components/src/lib/loader';
import { Overlay } from '@angular/cdk/overlay';

@Component({
    selector: 'pt-watchlist-search-details',
    templateUrl: './watchlist-search-details.component.html',
    styleUrls: ['./watchlist-search-details.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class WatchlistSearchDetailsComponent implements AfterViewInit, OnDestroy {

    readonly searchResultsDisplayedColumns = ['search_date', 'search_term', 'total_count', 'cleared_count', 'pending_count', 'matched_count'];
    readonly watchlistDisplayedColumns = ['external_watchlist_entity_id', 'watchlist_name', 'matched_name', 'match_percentage', 'status'];

    @Input() transaction: Transaction;

    @Output() watchlistUpdated: EventEmitter<null> = new EventEmitter<null>();

    watchlistSearchResults: WatchlistSearchResult[] = [];
    expandedElement: WatchlistSearchResult;
    isLoadingResults = true;
    subscription: any;
    filterEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild('detailsTable') detailsTable: MatTable<any>;

    constructor(private watchlistService: WatchlistService,
                private dialog: MatDialog,
                private formBuilder: UntypedFormBuilder,
                private overlay: Overlay) { }

    ngAfterViewInit() {
        this.addTableLoadListener();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addTableLoadListener() {
        this.subscription = merge(this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.watchlistService.getWatchlistSearches(this.transaction.id);
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((watchlistSearchResults: WatchlistSearchResult[]) => {
            this.watchlistSearchResults = watchlistSearchResults;
            for (const watchlistSearchResult of this.watchlistSearchResults) {
                this.updateCount(watchlistSearchResult);
            }
            this.isLoadingResults = false;
            UIUtils.scrollDashboardToTop();
        });
    }

    updateCount(watchlistSearchResult: WatchlistSearchResult) {
        watchlistSearchResult.clearedCount = 0;
        watchlistSearchResult.pendingCount = 0;
        watchlistSearchResult.matchedCount = 0;
        watchlistSearchResult.totalCount = watchlistSearchResult.watchlistEntities?.length || 0;
        watchlistSearchResult.watchlistEntities.forEach((watchlistEntity) => {
            switch (watchlistEntity.status) {
                case WatchlistStatus.MATCHED:
                    watchlistSearchResult.matchedCount++;
                    break;
                case WatchlistStatus.PENDING:
                    watchlistSearchResult.pendingCount++;
                    break;
                default:
                    watchlistSearchResult.clearedCount++;
            }
        });
    }

    expandRow(element: WatchlistSearchResult, event: any) {
        if (element.totalCount) {
            if (event.target.tagName !== 'SPAN') {
                this.expandedElement = this.expandedElement === element ? null : element;
            }
        }
    }

    reviewWatchlistEntry(watchlistEntity: WatchlistEntity, watchlistSearchResult: WatchlistSearchResult, index: number, event: any) {
        const watchlistForm = this.formBuilder.group({
            notesCtrl: new UntypedFormControl('', [Validators.required, Validators.maxLength(500), CustomValidators.noLeadingOrTrailingWhitespace])
        });

        const memberId = watchlistSearchResult.memberId;
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.panelClass = 'full-modal';
        dialogConfig.data = {
            bankAccountId: this.transaction.bankAccountId,
            watchlistSearchType: watchlistSearchResult.watchListSearchType,
            watchlistEntity: watchlistEntity,
            memberId,
            watchlistForm,
            role: (memberId === this.transaction.payorMemberId ? 'Payor' : 'Recipient')
        };
        const dialogRef = this.dialog.open(WatchlistReviewModalComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((status: WatchlistStatus) => {
            if (status) {
                const overlayRef = this.overlay.create({
                    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                    hasBackdrop: true
                });
                overlayRef.attach(new ComponentPortal(LoaderComponent));

                const watchlistUserNote = new WatchlistUserNote();
                watchlistUserNote.status = status;
                watchlistUserNote.notes = watchlistForm.controls['notesCtrl'].value;

                this.watchlistService.addWatchlistUserNote(memberId, watchlistEntity.id, watchlistUserNote).subscribe((response: WatchlistEntity) => {
                    if (this.expandedElement) {
                        watchlistSearchResult.watchlistEntities = [...watchlistSearchResult.watchlistEntities];
                        watchlistSearchResult.watchlistEntities[index] = response;
                        this.updateCount(this.expandedElement);
                        this.detailsTable.renderRows();
                        this.watchlistUpdated.emit();
                        overlayRef.dispose();
                    }
                });
            }
        });
    }

    hasNotes(index: number, watchlistEntity: WatchlistEntity) {
        return watchlistEntity.userNotes?.length;
    };

}
