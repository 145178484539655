import { Component, OnInit } from '@angular/core';
import { Configuration, WorkflowService, Task } from 'projects/services/src/public-api';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pt-password-reset-task',
    templateUrl: './password-reset-task.component.html'
})
export class PasswordResetTaskComponent implements OnInit {

    task: Task;
    taskVars: any;

    constructor(private router: Router,
                private workflowService: WorkflowService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        if (this.workflowService.getCurrentTask()) {
            this.task = this.workflowService.getCurrentTask();
            this.taskVars = this.task.variables;
        }
    }

    isExpired() {
        const diff = Math.abs(new Date().getTime() - new Date(this.task.created).getTime());
        return Math.round(diff / 60000) > Configuration.getConfig().passwordLinkExpirationInMinutes;
    }

    terminateProcess() {
        if (this.dialog.openDialogs?.length) {
            return;
        }
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'Revoke Reset Link',
            description: `This will revoke the password reset link sent to ${this.taskVars.firstName} ${this.taskVars.lastName}.  The user will no longer be able to reset their password without a new link being sent.`,
            confirmMessage: 'Do you want to revoke this reset link?',
            confirmText: 'Revoke'
        };
        const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe((confirmAction: any) =>  {
            if (confirmAction === 'confirmed') {
                this.workflowService.deleteProcessInstance(this.task.processInstanceId).subscribe(() => {
                    this.returnToTasks();
                });
            }
        });
    }

    returnToTasks() {
        this.router.navigate(['/communication/notifications']);
    }
}
