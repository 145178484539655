<div class="top-header">
    <div class="column-centered" [class]="getMenuClass()" (click)="onToggleExpandSideMenu()">
        <div class="top-header-icon-content">
            <img src="assets/images/Confia-icon.png" *ngIf="!expandedMenu">
            <img src="assets/images/confia-logo.svg" *ngIf="expandedMenu">
        </div>
    </div>
    <div [class]="getBodyClass()">
        <div class="top-header-heading col-md-6">
            <h1 *ngIf="profile">{{profile.memberName}}</h1>
        </div>
        <div class="top-header-menu col-md-6">
            <div class="header-link" [class.active]="isActive('Notifications')">
                <a [routerLink]="isDisabled('/communication/notifications') ? null : ['/communication/notifications']" [routerLinkActive]="['router-link-active']">
                    <span matBadgeColor="warn" matBadge="{{getOpenItemCount()}}" [matBadgeHidden]="getOpenItemCount() === 0" matTooltip="Open Items">
                        <img src="assets/images/task2-icon-2.svg" alt="Open Items" class="dashboard-icon header-open-item">
                    </span>
                </a>
            </div>
            <div class="header-link" [class.active]="isActive('Log Out')">
                <i class="fa fa-sign-out" (click)="onLoggedout()" aria-hidden="true" matTooltip="Log Out"></i>
            </div>
        </div>
    </div>
</div>
