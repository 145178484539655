import { Tax } from './tax';
import { ContractTemplate } from './contract-template';
import { Transaction } from '../transaction';
import { Member } from '../member';

export class Contract {

    id: string;
    name: string;
    contractTemplateId: string;
    contractTemplate: ContractTemplate;
    currentState: string;

    buyerId: string;
    buyer: Member;
    buyerAccepted: boolean;
    sellerId: string;
    seller: Member;
    sellerAccepted: boolean;

    businessTax: number;
    salesTax: number;
    tax: Tax;
    transaction: Transaction;
}
