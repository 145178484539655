<div>
    <form>
        <div>
            <div class="modal-header">
                <label class="form-title">Allow Partner Access</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small" *ngIf="memberAccountForm">
                <div *ngIf="memberAccounts.length > 0">
                    <pc-auto-complete-selector *ngIf="memberAccounts.length > 1" [label]="'Select Account'" [formCtrl]="'memberAccountCtrl'" [formGroup]="memberAccountForm"
                        [options]="memberAccounts" [displayValue]="'accountName'" (selected)="selectMemberAccount($event)">
                    </pc-auto-complete-selector>
                    <div *ngIf="selectedMemberAccount">
                        <p>
                            This will allow <b>{{partnerName}}</b> partner access to <b>{{selectedMemberAccount.accountName}} ({{selectedMemberAccount.accountFriendlyName}})</b>.
                        </p>
                        <p>
                            Click 'Grant Access' to allow access.
                        </p>
                    </div>
                </div>
                <div *ngIf="memberAccounts.length === 0">
                    <p>
                        This partner has already been given access to all active, public accounts.
                    </p>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button *ngIf="memberAccounts.length > 0" [submit]="onSubmit" [text]="'Grant Access'" [enabled]="connectedMemberFormValid"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
