import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AlertFormService {

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    initializeForm(): UntypedFormGroup {
        return this.formBuilder.group({
            noteCtrl: new UntypedFormControl('', [Validators.maxLength(4000)])
        });
    }

}
