<div>
    <form [formGroup]="explanationForm">
        <div>

            <div class="modal-header">
                <label class="form-title"> Add Explanation </label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content">
                <div>
                    <pc-text-area [required]="true" [label]="'Explanation'" [placeholder]="'Please provide a reason for this transaction.'"
                                    [formCtrl]="'notesCtrl'" [formGroup]="explanationForm" [class]="'large mw-100'">
                    </pc-text-area>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="isExplanationFormValid" [submit]="onSubmit" [text]="'Add Explanation'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
