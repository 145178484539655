import { LendingSupplier } from './lending-supplier';

export class LendingSupplierSummary {

    pageNumber: number;
    pageSize: number;
    totalResults: number;
    results: LendingSupplier[];
    totalOwed: number;
    noBillsOutstanding: number;
    daysPayableOutstanding: number;

}
