import { MemberAccount } from './member-account';
import { MerchantAccountConfig } from './merchant-account-config';
import { AccountingPartyType } from './accounting/accounting-party';
import { Member } from './member';

export class MerchantAccount {

    id: string;
    created: Date;
    merchantMemberAccount: MemberAccount;
    member: Member;
    merchantAccountConfig: MerchantAccountConfig;
    memberId: string;
    status: MerchantAccountStatus;
    accountingExternalIds: ExternalAccountingId[];

    merchantMemberName: string;
}

export class ExternalAccountingId {

    constructor(accountingExternalId: string, accountingPartyType: AccountingPartyType) {
        this.accountingExternalId = accountingExternalId;
        this.accountingPartyType = accountingPartyType;
    }

    accountingExternalId: string;
    accountingPartyType: AccountingPartyType;
}

export enum MerchantAccountStatus {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    DISABLED = 'DISABLED'
}
