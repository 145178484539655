import { Component } from '@angular/core';
import { WatchlistStatus, WatchlistEntity } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-watchlist-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class WatchlistBadgeComponent extends BaseBadgeComponent<WatchlistEntity> {

    setBadgeData() {
        this.badgeToolTip = this.parent.status;
        switch (this.parent.status) {
            case WatchlistStatus.PENDING:
                this.badgeText = 'PENDING';
                this.badgeClass = 'orange-badge';
                break;
            case WatchlistStatus.CLEARED:
                this.badgeText = 'CLEARED';
                this.badgeClass = 'green-badge';
                break;
            case WatchlistStatus.AUTO_CLEARED:
                this.badgeText = 'AUTO CLEARED';
                this.badgeClass = 'green-badge';
                break;
            case WatchlistStatus.MATCHED:
                this.badgeText = 'MATCHED';
                this.badgeClass = 'red-badge';
                break;
        }
    }
}
