import { Component, Input, OnInit } from '@angular/core';


@Component({
    selector: 'pc-dashboard-alert',
    templateUrl: './dashboard-alert.component.html'
})
export class DashboardAlertComponent implements OnInit {

    @Input() icon: string;
    @Input() title: string;
    @Input() titleMeta: string;
    @Input() typeMeta: string;
    @Input() link = '/app';
    @Input() iconSrc: string;
    @Input() valueOne: string | number;
    @Input() valueOneLabel: string;
    @Input() valueTwo: string | number;
    @Input() valueTwoLabel: string;
    @Input() valueThree: string | number;
    @Input() valueThreeLabel: string;

    columnClass = 'col-12';

    ngOnInit() {
        if (this.valueThreeLabel) {
            this.columnClass = 'col-4';
        } else if (this.valueTwoLabel) {
            this.columnClass = 'col-6';
        }
    }

}
