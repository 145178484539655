import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
    AuthorityTransactionFormService, AuthService, DataroomService, DocumentType, MemberAccount, MemberAccountService, MemberType, SupportingDocument, Transaction,
    TransactionSource, TransactionType, Upload, Utils, Wallet, WorkflowService
} from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { BaseModalComponent, LoaderComponent } from 'projects/components/src/public-api';
import { forkJoin } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'pt-authority-payment-modal',
    templateUrl: './authority-payment-modal.component.html'
})
export class AuthorityPaymentModalComponent extends BaseModalComponent<AuthorityPaymentModalComponent> implements OnInit {

    TransactionType = TransactionType;
    MemberType = MemberType;

    memberAccount: MemberAccount;
    paymentForm: UntypedFormGroup;
    transaction: Transaction;
    transactionType: TransactionType;
    wallet: Wallet;
    isGeneralPayment = true;
    adjustmentAmount = 0.0;
    transactionTotal = 0;
    error = '';
    header = '';

    counterPartyForm: UntypedFormGroup;
    counterParty: MemberAccount;
    counterPartyLabel: string;

    submissionReference = uuidv4();
    submitter: string;
    supportingDocuments: SupportingDocument[] = [];
    reasonOptions: string[] = [];

    constructor(private workflowService: WorkflowService,
                private memberAccountService: MemberAccountService,
                private authorityTransactionFormService: AuthorityTransactionFormService,
                private dataroomService: DataroomService,
                private authService: AuthService,
                private notifier: NotificationService,
                private formBuilder: UntypedFormBuilder,
                public overlay: Overlay,
                private translateService: TranslateService,
                private notificationService: NotificationService,
                dialogRef: MatDialogRef<AuthorityPaymentModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.transactionType = data.transactionType;
        this.header = data.header;
        this.memberAccount = data.memberAccount;
        this.transaction =  data.transaction || new Transaction();
        this.paymentForm = this.authorityTransactionFormService.initializeForm(this.transactionType);
        this.paymentForm.patchValue({
            memberAccountIdCtrl: this.memberAccount.id,
            memberAccountNameCtrl: this.memberAccount.accountName,
            memberNameCtrl:  this.memberAccount.member.name
        });
        this.paymentForm.get('transactionDateCtrl').disable();
        this.paymentForm.get('memberNameCtrl').disable();
        this.paymentForm.get('memberAccountNameCtrl').disable();

        if (this.transactionType === TransactionType.DEPOSIT_ADJUSTMENT_ADDITION) {
            this.counterPartyForm =  this.formBuilder.group({counterPartyCtrl: new UntypedFormControl(),
                counterPartyTierCtrl: new UntypedFormControl(),
                counterPartyDescriptionCtrl: new UntypedFormControl()});
            this.counterPartyForm.get('counterPartyTierCtrl').disable();
            this.counterPartyForm.get('counterPartyDescriptionCtrl').disable();

            this.counterPartyLabel = 'Payor';
            this.reasonOptions = [
                '',
                'CHECK_RETURNED',
                'CHECK_CANCELLED',
                'REVERSED_PAYMENT',
                'NON_SYSTEM_DEPOSIT'
            ];
        } else if (this.transactionType === TransactionType.DEPOSIT_ADJUSTMENT_DEDUCTION) {
            this.counterPartyForm =  this.formBuilder.group({counterPartyCtrl: new UntypedFormControl(),
                counterPartyTierCtrl: new UntypedFormControl(),
                counterPartyDescriptionCtrl: new UntypedFormControl()});
            this.counterPartyForm.get('counterPartyTierCtrl').disable();
            this.counterPartyForm.get('counterPartyDescriptionCtrl').disable();

            this.counterPartyLabel = 'Recipient';
            this.reasonOptions = [
                '',
                'TAXES',
                'PAYROLL',
                'NON_SYSTEM_ACH',
                'NON_SYSTEM_WIRE',
                'NON_SYSTEM_CHECK',
                'PREAUTHORIZED_ACH'
            ];
        }
    }

    ngOnInit() {
        this.submitter = this.authService.getProfile().userId;
        this.isPaymentFormValid = this.isPaymentFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        if (this.transaction.totalAmount) {
            this.transactionTotal = this.transaction.totalAmount;
            this.isGeneralPayment = false;
        }
    }

    onSubmit(reset: any) {
        const transaction = this.authorityTransactionFormService.getTransaction(this.paymentForm, this.counterParty);
        transaction.transactionType = this.transactionType;
        transaction.submissionReference = this.submissionReference;
        transaction.source = TransactionSource.SYSTEM_TRANSACTION;
        if (this.transaction) {
            if (this.transaction.relatedTransactionId) {
                transaction.relatedTransactionId = this.transaction.relatedTransactionId;
            } else {
                transaction.relatedTransactionId = this.transaction.id;
            }
        }

        if (this.transactionType === TransactionType.ACCOUNT_CREDIT || this.transactionType === TransactionType.DEPOSIT_ADJUSTMENT_ADDITION ||  this.transactionType === TransactionType.TRANSACTION_CREDIT) {
            transaction.recipientMemberId = this.memberAccount.memberId;
            transaction.recipientAccountId = this.memberAccount.id;
            transaction.recipientMemberName = this.memberAccount.member.name;
        } else if (this.transactionType === TransactionType.ACCOUNT_FEE || this.transactionType === TransactionType.DEPOSIT_ADJUSTMENT_DEDUCTION ||  this.transactionType === TransactionType.TRANSACTION_FEE) {
            transaction.payorMemberId = this.memberAccount.memberId;
            transaction.payorAccountId = this.memberAccount.id;
            transaction.payorMemberName = this.memberAccount.member.name;
        }
        const filesToUpload: SupportingDocument[] = [];
        this.supportingDocuments.forEach((supportingDocument) => {
            if (!supportingDocument.documentId) {
                supportingDocument.memberId = this.memberAccount.memberId;
                filesToUpload.push(supportingDocument);
            }
        });
        transaction.supportingDocuments = this.supportingDocuments;
        if (filesToUpload.length) {
            const overlayRef = this.overlay.create({
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
                hasBackdrop: true
            });
            const componentRef = overlayRef.attach(new ComponentPortal(LoaderComponent));
            componentRef.instance.title = 'Uploading files.  Please wait...';
            this.uploadFiles(filesToUpload).subscribe((results: Upload[]) => {
                for (let i = 0; i < results.length; i++) {
                    filesToUpload[i].documentId = results[i].id;
                    filesToUpload[i].documentType = DocumentType.OTHER_TRANSACTION_DOCUMENT;
                    filesToUpload[i].file = null;
                }
                overlayRef.dispose();
                this.submitTransaction(transaction, reset);
            }, () => {
                overlayRef.dispose();
                reset();
                this.notificationService.showError('Document upload was unsuccessful. Please check your connection and try again.');
            });
        } else {
            this.submitTransaction(transaction, reset);
        }
    }

    submitTransaction(transaction: Transaction, reset: any) {
        if (this.transactionType === TransactionType.ACCOUNT_CREDIT || this.transactionType === TransactionType.TRANSACTION_CREDIT || this.transactionType === TransactionType.DEPOSIT_ADJUSTMENT_ADDITION) {
            this.workflowService.issueCredit(transaction).subscribe(() => {
                this.notifier.showSuccess(`A credit in the amount of <b>$${transaction.totalAmount}</b> has successfully been applied for <b>${this.memberAccount.member.name}</b> to account <b>${this.memberAccount.accountName}</b>.`);
                this.close(true);
            }, (error: any) => {
                reset();
                throw error;
            });
        } else {
            this.workflowService.issueCharge(transaction).subscribe(() => {
                this.notifier.showSuccess(`A charge in the amount of <b>$${transaction.totalAmount}</b> has successfully been applied for <b>${this.memberAccount.member.name}</b> to account <b>${this.memberAccount.accountName}</b>.`);
                this.close(true);
            }, (error: any) => {
                reset();
                throw error;
            });
        }
    }

    uploadFiles(supportingDocuments: SupportingDocument[]) {
        const memberId = this.memberAccount.memberId;
        return forkJoin(supportingDocuments.map((supportingDocument) => {
            return this.dataroomService.uploadFile(memberId, `${memberId}/${this.submitter}/transaction/${this.submissionReference}/${uuidv4()}`,
                supportingDocument.file, supportingDocument.file.name, null, DocumentType.AUTHORITY_TRANSACTION_SUPPORTING_DOCUMENT, memberId);
        }));
    }

    isPaymentFormValid() {
        if (!this.isGeneralPayment) {
            return !this.paymentForm.invalid && (this.adjustmentAmount > 0) && (this.adjustmentAmount <= this.transaction.totalAmount);
        }
        return !this.paymentForm.invalid && (this.adjustmentAmount > 0);
    }

    onCalculateTotal() {
        this.error = '';
        const amount = this.paymentForm.controls['amountCtrl'].value;
        if (amount > this.transaction.totalAmount && !this.isGeneralPayment) {
            this.error = 'Exceeds Amount Owed';
            this.adjustmentAmount = amount;
        } else if (!isNaN(amount)) {
            this.adjustmentAmount = amount;
        }
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    removeSupportingDocument(index: number) {
        this.supportingDocuments.splice(index, 1);
    }

    selectFile(event: any) {
        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            Utils.validateFile(file, event);
            const supportingDocument = new SupportingDocument();
            supportingDocument.file = file;
            this.supportingDocuments.push(supportingDocument);
        }
        event.target.value = '';
    }

    counterPartyMemberAccountSelected (counterPartyMemberAccount: MemberAccount) {
        this.counterParty = counterPartyMemberAccount.id ? counterPartyMemberAccount : null;
        this.updatePartyDetails(counterPartyMemberAccount);
    }

    updatePartyDetails(memberAccount: MemberAccount) {
        if (memberAccount.id) {
            this.counterPartyForm.patchValue({counterPartyTierCtrl: this.getRiskTierDisplayValue(memberAccount.member.tier)});
            this.counterPartyForm.patchValue({counterPartyDescriptionCtrl: memberAccount.member.briefDescription});
        } else {
            this.counterPartyForm.patchValue({counterPartyTierCtrl: null});
            this.counterPartyForm.patchValue({counterPartyDescriptionCtrl: null});
        }
    }

    getRiskTierDisplayValue(riskTier: string) {
        if (!riskTier) {
            return riskTier;
        }
        return this.translateService.instant(riskTier);
    }
}
