<div class="inlineExpanded">
    <div class="row" *ngFor="let line of expense.lineItems">
        <div class="col-12" *ngIf="line.description">
            <div class="row">
                <div class="col-9 expandedLabelRight">
                    {{line.description}}
                </div>
                <div class="col-3 expandedLabel">
                    {{line.amount | currency}}
                </div>
            </div>
        </div>
    </div>
</div>
