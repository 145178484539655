import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { ContractTemplate, FieldMetadata, SmartContractService, SalesOrder } from 'projects/services/src/public-api';
import { UntypedFormBuilder } from '@angular/forms';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderComponent } from 'projects/components/src/public-api';
import { Overlay } from '@angular/cdk/overlay';

@Component({
    selector: 'pc-sc-configure-template',
    templateUrl: './configure-template.component.html',
    styleUrls: ['./configure-template.component.scss']
})
export class ConfigureTemplateComponent implements OnInit {

    @Input() enforceRequired = true;
    @Input() contractTemplate: ContractTemplate;

    @Output() contractTemplateChange: EventEmitter<ContractTemplate> = new EventEmitter<ContractTemplate>();

    formGroup = this.formBuilder.group({});
    fieldMap = {};

    constructor(private smartContractService: SmartContractService,
                private formBuilder: UntypedFormBuilder,
                private overlay: Overlay,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.collateFields();
        this.changeDetectorRef.detectChanges();
    }

    collateFields() {
        this.contractTemplate.sections.forEach((section) => {
            if (section.included) {
                section.terms.forEach((term) => {
                    if (term.included) {
                        Object.keys(term.fieldMap).forEach((key) => {
                            this.fieldMap[key] = term.fieldMap[key];
                        });
                        term.subTerms.forEach((subTerm) => {
                            if (subTerm.included) {
                                Object.keys(subTerm.fieldMap).forEach((key) => {
                                    this.fieldMap[key] = term.fieldMap[key];
                                });
                            }
                        });
                    }
                });
            }
        });
    }

    getFields(): any {
        return Object.values(this.fieldMap);
    }

    onChanged(field: FieldMetadata) {
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true
        });
        overlayRef.attach(new ComponentPortal(LoaderComponent));
        this.fieldMap[field.id] = field;
        this.smartContractService.saveTemplate(this.contractTemplate).subscribe((contractTemplate: ContractTemplate) => {
            const activeElement = document.activeElement.id;
            this.contractTemplate = contractTemplate;
            this.collateFields();
            this.changeDetectorRef.detectChanges();
            if (activeElement) {
                setTimeout(() => {
                    document.getElementById(activeElement).focus();
                }, 100);
            }
            this.contractTemplateChange.emit(contractTemplate);
            overlayRef.dispose();
        });
    }

    onOrderChanged(order: SalesOrder) {
        this.contractTemplate.purchaseOrder = order;
        this.smartContractService.saveTemplate(this.contractTemplate).subscribe((contractTemplate: ContractTemplate) => {
            this.contractTemplate = contractTemplate;
            this.contractTemplateChange.emit(this.contractTemplate);
        });
    }
}
