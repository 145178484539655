import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CountNotificationService {

    private unreadMessageCount = 0;
    private openItemCount = 0;

    setUnreadMessageCount(count: number) {
        this.unreadMessageCount = count;
    }

    getUnreadMessageCount() {
        return this.unreadMessageCount;
    }

    setOpenItemCount(count: number) {
        this.openItemCount = count;
    }

    getOpenItemCount() {
        return this.openItemCount;
    }

    clear() {
        this.unreadMessageCount = 0;
        this.openItemCount = 0;
    }
}