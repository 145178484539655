<div class="col-sm-12 horizontal-scroll mat-table-container">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="receipts" matSort [matSortActive]="pageTracking.sort" matSortDisableClear
        [matSortDirection]="pageTracking.sortDir" multiTemplateDataRows>
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="externalId"> Sales ID </th>
            <td mat-cell *matCellDef="let element"> {{element.externalId}} </td>
        </ng-container>
        <ng-container matColumnDef="supplier">
            <th mat-header-cell *matHeaderCellDef> Supplier </th>
            <td mat-cell *matCellDef="let element"> {{ element.memberName }} </td>
        </ng-container>
        <ng-container matColumnDef="total_amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="totalAmount" class="centered"> Total Amount </th>
            <td mat-cell *matCellDef="let element" class="centered">
                <span class="table-amount">
                    {{element.totalAmount | currency}}
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="created">
            <th mat-header-cell  *matHeaderCellDef mat-sort-header class="centered"> Created Date </th>
            <td mat-cell *matCellDef="let element" class="centered"> {{ element.created | date:'mediumDate'}} </td>
        </ng-container>
        <ng-container matColumnDef="txn_date">
            <th mat-header-cell  *matHeaderCellDef mat-sort-header="txnDate" class="centered"> Transaction Date </th>
            <td mat-cell *matCellDef="let element" class="centered"> {{ element.txnDate | date:'mediumDate'}} </td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                    <pt-member-metrc-receipt-summary [receipt]="element"></pt-member-metrc-receipt-summary>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
            class="example-expand-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
</div>
<div>
    <dl class="row">
        <div class="title-bold-font">
            Summary Statistics :
        </div>
        <div *ngIf="receipts?.length > 0 && receiptsSummaryList?.length === 0"> Filter more results to display total summary</div>
        <div class="row" *ngFor="let receiptsSummary of receiptsSummaryList">
            <dt class="col-sm-4 expandedLabel">{{ receiptsSummary.category }}:</dt>
            <dd class="col-sm-8 expandedValue">{{ receiptsSummary.total | currency }}</dd>
        </div>
        <div class="row" *ngIf="totalReceipts > 0">
            <dt class="col-sm-4 expandedLabel">{{ 'Receipts Total Amount'}}:</dt>
            <dd class="col-sm-8 expandedValue"> {{ totalReceipts   | currency }}</dd>
        </div>
    </dl>
</div>
