<div>
    <form>
        <div>
            <div class="modal-header">
                <label class="form-title">Add New Account</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content">
                <div class="col-12 mt-2">
                    <p>This will create an account in your accounting system with a nominal code/account number of {{memberAccount.accountNumber}}.</p>
                    <p>If you no longer wish to use this account once created, you will need to deactivate the account in your accounting system in order to create a different one.</p>
                </div>
                <div class="col-12 justify-content-center modal-content-small">
                    <pc-text-input [required]="true" [label]="'Name'" [formCtrl]="'nameCtrl'" [formGroup]="reconciliationAccountFormGroup">
                    </pc-text-input>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="isFormValid" [submit]="onSubmit" [text]="'Add Account'"></pc-submit-button>
            </div>

        </div>
    </form>
</div>
