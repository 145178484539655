import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal/index';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { AuthService, KeyPartyType, KycSearchService, SearchResult } from 'projects/services/src/public-api';
import { finalize } from 'rxjs';


@Component({
    selector: 'pc-kyc-search-result-modal',
    templateUrl: './kyc-search-result-modal.component.html',
    styleUrls: ['./kyc-search-result-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KycSearchResultModalComponent extends BaseModalComponent<KycSearchResultModalComponent> implements OnInit {

    search: SearchResult;

    keyParty: {keyPartyId: string, type: KeyPartyType, name: string};

    monitoringToggleBtnText = 'DISABLE MONITORING';

    toggleMonitoringEnabled = true;

    isIndividualKeyParty = false;

    constructor(private kycSearchService: KycSearchService,
                private authService: AuthService,
                private notificationService: NotificationService,
                dialogRef: MatDialogRef<KycSearchResultModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);

        this.keyParty = data.keyParty;
        if (data?.keyParty?.individualKeyParty) {
            this.isIndividualKeyParty = true;
        }
        this.search = data.searchResult;
    }

    ngOnInit() {
        this.onToggleMonitoring = this.onToggleMonitoring.bind(this);
        this.isToggleMonitoringEnabled = this.isToggleMonitoringEnabled.bind(this);

        if (!this.search.monitored) {
            this.monitoringToggleBtnText = 'ENABLE MONITORING';
        }
    }

    onToggleMonitoring() {
        this.toggleMonitoringEnabled = false;
        this.search.monitored = !this.search.monitored;

        this.kycSearchService.updateMonitor(this.search.id, {monitored: this.search.monitored}).pipe(
            finalize(() => {
                this.toggleMonitoringEnabled = true;
            })
        )
            .subscribe(() => {
                const msg = this.search.monitored ? `Enabled monitoring for ${this.search.searchTerm}` : `Disabled monitoring for ${this.search.searchTerm}`;
                this.notificationService.showSuccess(msg);
                super.close({
                    action: 'COMPLETE',
                    search: this.search
                });
            });
    }

    close() {
        super.close({
            action: 'CLOSED',
            search: this.search
        });
    }

    isToggleMonitoringEnabled() {
        if (!this.search) {
            return false;
        }

        return this.toggleMonitoringEnabled && this.authService.isAuthority();
    }

}
