import { DocumentType, Upload } from './upload';

export class SupportingDocument {
    id: string;
    memberId: string;
    documentId: string;
    lineItemId: string;
    rfiHistoryId: string;
    authorityOnly: boolean;
    documentType: DocumentType;
    document: Upload;
    file: File;
    created: Date;
    updated: Date;
    explanation: string;
}
