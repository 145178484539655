import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
    BankAccount, AuthService, BankAccountService, PaymentService, PagedResponse, OnboardingTrackingService, MerchantAccountService, MerchantAccountConfig,
    MemberType, BankAccountStatus, Role, TransactionType, Member, MemberService, LinkedBankAccount
} from 'projects/services/src/public-api';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddMemberBankAccountModalComponent } from './add-member-bank-account-modal/add-member-bank-account-modal.component';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'pt-member-banking-details',
    templateUrl: './member-banking-details.component.html',
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class MemberBankingDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

    Role = Role;
    MemberType = MemberType;
    BankAccountStatus = BankAccountStatus;

    @Input() memberId: string;
    @Input() task: any;
    @Input() changeTrigger: number;
    @Input() allowAddBankAccount = false;
    @Input() accountNameRequired = false;
    @Input() isMerchantBankDetails = false;

    @Output() next: EventEmitter<string> = new EventEmitter<string>();

    filter: string;
    member: Member;
    accounts: BankAccount[] = [];
    resultsLength = 0;
    subscription: any;
    isLoadingResults = true;
    expandedElement: any;

    statuses = [BankAccountStatus.ACTIVE, BankAccountStatus.VALIDATION_REQUIRED, BankAccountStatus.DISABLED, BankAccountStatus.DELETED, BankAccountStatus.PENDING, BankAccountStatus.BANK_VALIDATION_REQUIRED, BankAccountStatus.USER_VALIDATION_REQUIRED, BankAccountStatus.REJECTED];
    displayedColumns: string[] = ['type', 'account_number', 'ach_routing_number', 'wire_routing_number', 'status', 'name', 'holder', 'financial_institution', 'date_added', 'action'];
    pageTracking: PageTracking;
    defaultOutgoingTransactionType: TransactionType;

    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(public authService: AuthService,
                private bankAccountService: BankAccountService,
                private merchantAccountService: MerchantAccountService,
                private paymentService: PaymentService,
                private route: ActivatedRoute,
                private router: Router,
                private cdr: ChangeDetectorRef,
                private memberService: MemberService,
                private notificationService: NotificationService,
                private dialog: MatDialog) {}

    ngOnInit() {
        this.onNavigate = this.onNavigate.bind(this);
        this.refreshList = this.refreshList.bind(this);
        if (this.authService.isAuthorityOrReviewer()) {
            this.displayedColumns = ['type', 'account_number', 'ach_routing_number', 'wire_routing_number', 'status', 'name', 'holder', 'source', 'financial_institution', 'date_added', 'action'];
        }
        if (this.isMerchantBankDetails) {
            this.statuses = [BankAccountStatus.ACTIVE, BankAccountStatus.VALIDATION_REQUIRED, BankAccountStatus.DISABLED, BankAccountStatus.DELETED, BankAccountStatus.PENDING, BankAccountStatus.BANK_VALIDATION_REQUIRED, BankAccountStatus.USER_VALIDATION_REQUIRED];
        }
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'created', 'desc', 10);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort);
        this.addTableLoadListener();
    }

    getBankAccountNumber(bankAccountNumber: string) {
        if (this.member?.memberType !== MemberType.BUSINESS_MERCHANT && this.member?.memberType !== MemberType.INDIVIDUAL_MERCHANT) {
            return '*****' + bankAccountNumber.substring(bankAccountNumber.length - 4);
        }
        // unmasked number;
        return bankAccountNumber;
    }

    getBankRoutingNumber(bankRoutingNumber: string) {
        if (this.member?.memberType !== MemberType.BUSINESS_MERCHANT && this.member?.memberType !== MemberType.INDIVIDUAL_MERCHANT) {
            return '*****' + bankRoutingNumber.substring(bankRoutingNumber.length - 4);
        }
        // unmasked number;
        return bankRoutingNumber;
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.bankAccountService.getBankAccounts(this.memberId, this.statuses, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<BankAccount>) => {
                this.isLoadingResults = false;
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((financialAccounts: BankAccount[]) => {
            const results: BankAccount[] = [];
            for (const financialAccount of financialAccounts) {
                this.loadAccount(financialAccount);
                results.push(financialAccount);
            }
            this.accounts = results;
            this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
                this.member = member;
                this.setBankingDetails();
            });
            UIUtils.scrollDashboardToTop();
        });
    }

    setBankingDetails() {
        const activeFinancialAccounts = this.accounts.filter((financialAccount: BankAccount) => {
            return financialAccount.status === BankAccountStatus.ACTIVE;
        });
        if (this.member?.memberType === MemberType.BUSINESS_MERCHANT || this.member?.memberType === MemberType.INDIVIDUAL_MERCHANT) {
            const pendingFinancialAccounts = this.accounts.filter((financialAccount: BankAccount) => {
                return financialAccount.status === BankAccountStatus.PENDING;
            });
            this.merchantAccountService.getMerchantAccountConfigByMerchantMemberId(this.memberId).subscribe((merchantConfig: MerchantAccountConfig) => {
                this.defaultOutgoingTransactionType = merchantConfig.defaultOutgoingTransactionType;
                OnboardingTrackingService.setBankingDetails(this.defaultOutgoingTransactionType, activeFinancialAccounts, pendingFinancialAccounts);
            });
        } else {
            OnboardingTrackingService.setBankingDetails('', activeFinancialAccounts, []);
        }
    }

    loadAccount(account: BankAccount) {
        if (account.status === BankAccountStatus.BANK_VALIDATION_REQUIRED || account.status === BankAccountStatus.USER_VALIDATION_REQUIRED || account.status === BankAccountStatus.VALIDATION_REQUIRED) {
            account.status = BankAccountStatus.PENDING;
        }
        if (account.externalAccountId) {
            // load payee address
            this.paymentService.getExternalAccountDetails(account.externalAccountId).subscribe((response: any) => {
                account.externalAccountDetails = response;
            });
        }
    }

    refreshList() {
        this.refreshEvent.emit();
    }

    onNavigate() {
        this.next.emit();
    }

    addBankingInformation(account?: BankAccount) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            memberId: this.memberId,
            memberType: this.member.memberType,
            accountNameRequired: this.accountNameRequired,
            account
        };
        const dialogref = this.dialog.open(AddMemberBankAccountModalComponent, dialogConfig);
        dialogref?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.refreshList();
            }
        });
    }

    onDeleteAccount(account: BankAccount) {
        if (this.member?.memberType === MemberType.BUSINESS_MERCHANT || this.member?.memberType === MemberType.INDIVIDUAL_MERCHANT) {
            this.bankAccountService.deleteMerchantAccount(account.id).subscribe(() => {
                this.refreshList();
            });
        } else {
            this.bankAccountService.deleteAccount(account.id).subscribe(() => {
                this.refreshList();
            });
        }
    }

    isEnabled(account: BankAccount) {
        return account && (account.status === BankAccountStatus.ACTIVE || account.status === BankAccountStatus.PENDING);
    }

    toggleEnabled(event: any, account: BankAccount) {
        if (this.isEnabled(account)) {
            this.bankAccountService.updateBankAccount(account.id, {status : BankAccountStatus.DISABLED}).subscribe(() => {
                if (this.member.memberType === MemberType.BUSINESS_MERCHANT || this.member.memberType === MemberType.INDIVIDUAL_MERCHANT) {
                    this.merchantAccountService.getMerchantAccountConfigByMerchantMemberId(this.memberId).subscribe((merchantConfig: MerchantAccountConfig) => {
                        if (this.defaultOutgoingTransactionType !== merchantConfig.defaultOutgoingTransactionType) {
                            this.notificationService.showSuccessCloseRequired('Default outgoing transaction type got updated for this merchant, please review in Internal KYC section.');
                        }
                        this.refreshList();
                    });
                } else {
                    this.refreshList();
                }
            });
        } else {
            this.bankAccountService.updateBankAccount(account.id, {status : BankAccountStatus.ACTIVE}).subscribe(() => {
                if (this.member.memberType === MemberType.BUSINESS_MERCHANT || this.member.memberType === MemberType.INDIVIDUAL_MERCHANT) {
                    // TODO CN-4466: We need to change this logic when multiple merchant member accounts gets introduced.
                    this.merchantAccountService.getMerchantAccountConfigByMerchantMemberId(this.memberId).subscribe((merchantAccountConfig: MerchantAccountConfig) => {
                        const linkedAccount = {
                            memberId: this.memberId,
                            memberAccountId: merchantAccountConfig.merchantMemberAccount.id,
                            bankAccountId: account.id
                        };
                        this.bankAccountService.linkBankAccount(linkedAccount).subscribe((linkedAccount: LinkedBankAccount) => {
                            this.refreshList();
                            this.notificationService.showSuccess(`Bank account for ${account.holder} successfully added and linked.`);
                        }, (error: any) => {
                            this.refreshList();
                            throw error;
                        });
                    });
                } else {
                    this.refreshList();
                }
            });
        }
        event.cancelBubble = true;
        if (event.stopImmediatePropagation) {
            event.stopImmediatePropagation();
        }
    }

    public handlePageBottom(event: PageEvent) {
        this.paginator.pageSize = event.pageSize;
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.page.emit(event);
    }
}
