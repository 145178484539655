import { Component } from '@angular/core';
import { Rfi, RfiStatus } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-rfi-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class RfiBadgeComponent extends BaseBadgeComponent<Rfi> {

    setBadgeData() {
        this.badgeToolTip = this.parent.status;
        switch (this.parent.status) {
            case RfiStatus.AUTHORITY_REVIEW:
            case RfiStatus.PENDING_CHECK_VERIFICATION:
                this.badgeText = 'REVIEW';
                this.badgeClass = 'orange-badge';
                break;
            case RfiStatus.PENDING_DEPOSIT_VERIFICATION:
            case RfiStatus.PENDING_PAYMENT_VERIFICATION:
                this.badgeText = 'PENDING';
                this.badgeClass = 'grey-badge';
                break;
            case RfiStatus.MEMBER_RECONCILIATION:
                this.badgeText = 'RECONCILING';
                this.badgeClass = 'green-badge';
                break;
            default:
                this.badgeText = 'CLOSED';
                this.badgeClass = 'grey-badge';
                break;
        }
    }
}