import { Component, Input } from '@angular/core';
import { AuthService, PostedTransactionType, Transaction, TransactionSubType, TransactionType } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-transaction-amount',
    templateUrl: './transaction-amount.component.html',
    styleUrls: ['./transaction-amount.component.scss']
})
export class TransactionAmountComponent {

    @Input() transaction: Transaction;
    @Input() memberId = '';
    @Input() memberAccountId = '';
    @Input() postedTxnType: PostedTransactionType;

    constructor(private authService: AuthService) {
    }

    getTransactionTypeClass() {
        if (this.isDebit()) {
            return 'debit';
        }
        return 'credit';
    }

    // this will likely need more logic at some point as other transaction types/subtypes come in
    isDebit() {
        if (this.isTransfer() && this.memberAccountId) {
            return this.memberAccountId === this.transaction.payorAccountId;
        } else if (this.postedTxnType === PostedTransactionType.TRANSFER && this.transaction.transactionType === TransactionType.WALLET_TO_WALLET_PAYMENT) {
            if (this.transaction.legacyPayorMemberId && !this.transaction.legacyRecipientMemberId) {
                return this.memberId !== this.transaction.recipientMemberId;
            }
            return (this.memberId === this.transaction.payorMemberId || this.memberId === this.transaction.legacyPayorMemberId);
        } else if (this.postedTxnType === PostedTransactionType.CREDIT && this.transaction.transactionType === TransactionType.WALLET_TO_WALLET_PAYMENT &&
            (this.memberId === this.transaction.recipientMemberId || this.memberId === this.transaction.legacyRecipientMemberId)) {
            return false;
        } else if (this.postedTxnType === PostedTransactionType.DEBIT &&
            (this.memberId === this.transaction.payorMemberId || this.memberId === this.transaction.legacyPayorMemberId)) {
            return true;
        }
        return (this.transaction.subType === TransactionSubType.WITHDRAWAL || this.transaction.subType === TransactionSubType.CREDIT ||
            this.transaction.subType === TransactionSubType.PAYMENT ||
            this.transaction.subType === TransactionSubType.FEE ||
            this.transaction.subType === TransactionSubType.REFUND ||
            this.transaction.transactionType === TransactionType.DEPOSIT_ADJUSTMENT_DEDUCTION) &&
            (this.authService.getProfile().memberId === this.transaction.payorMemberId || (this.memberId === this.transaction.payorMemberId));
    }

    isTransfer() {
        return this.transaction.transactionType === TransactionType.WALLET_TO_WALLET_PAYMENT &&
            this.transaction.payorMemberId === this.transaction.recipientMemberId &&
            this.transaction.payorAccountId !== this.transaction.recipientAccountId;
    }
}
