export class MetrcLicense {
    id: string;
    userKey: string;
    state: string;
    stateCode: string;
    status: string;
    memberId: string;
}

export enum MetrcStatus {
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
}
