export class ReceiptLineItem {
    amount: number;
    externalId: string;
    category: string;
    quantity: number;
    productName: string;
    itemWeight: number;
    measure: string;
    unit: string;
    label: string;
}
