import {Component} from '@angular/core';
import {BaseBadgeComponent} from './badge.component';

@Component({
    selector: 'pc-boolean-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss']
})
export class BooleanBadgeComponent extends BaseBadgeComponent<Boolean> {

    setBadgeData() {

        this.badgeClass = 'green-badge';
        this.badgeToolTip = 'Yes';
        this.badgeText = 'Yes';

        if (this.parent !== true) {
            this.badgeClass = 'red-badge';
            this.badgeToolTip = 'No';
            this.badgeText = 'No';
        }
    }
}
