import { Component } from '@angular/core';
import { Loan, LoanStatus } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-leading-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class LendingBadgeComponent extends BaseBadgeComponent<Loan> {

    setBadgeData() {
        this.badgeToolTip = this.parent.status;
        switch (this.parent.status) {
            case LoanStatus.PENDING:
                this.badgeText = 'PENDING';
                this.badgeClass = 'grey-badge';
                break;
            case LoanStatus.DECLINED:
                this.badgeText = 'DECLINED';
                this.badgeClass = 'grey-badge';
                break;
            case LoanStatus.CLOSED:
                this.badgeText = 'CLOSED';
                this.badgeClass = 'red-badge';
                break;
            case LoanStatus.CANCELLED:
                this.badgeText = 'CANCELLED';
                this.badgeClass = 'red-badge';
                break;
            case LoanStatus.DILIGENCE:
                this.badgeText = 'DILIGENCE';
                this.badgeClass = 'orange-badge';
                break;
            default:
                this.badgeText = 'ACTIVE';
                this.badgeClass = 'green-badge';
                break;
        }
    }
}