import { Component, OnInit } from '@angular/core';
import { SmartContractService, ContractTemplate, AuthService, ContractTemplateVisibility, ContractTemplateType } from 'projects/services/src/public-api';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NewContractModalComponent } from 'projects/smart-contract-components/src/lib/contracts/new-contract-modal/new-contract-modal.component';

@Component({
    selector: 'pc-sc-preview-shared-template',
    templateUrl: './preview-shared-template.component.html',
    styleUrls: ['./preview-shared-template.component.scss']
})
export class PreviewSharedTemplateComponent implements OnInit {

    ContractTemplateVisibility = ContractTemplateVisibility;

    contractTemplate: ContractTemplate;
    contractTemplateId: string;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private authService: AuthService,
                private smartContractService: SmartContractService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.contractTemplateId = this.route.snapshot.params['contractTemplateId'];

        this.smartContractService.getTemplateById(this.contractTemplateId)
            .subscribe(
                (template: ContractTemplate) => {
                    this.contractTemplate = template;
                }
            );
    }

    initializeContract() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            contractTemplateId: this.contractTemplateId
        };

        this.dialog.open(NewContractModalComponent, dialogConfig);
    }

    cancelToTemplates() {
        this.router.navigate(['/smart_contracts'], {queryParams: { _activeTab: 'shared_templates' }});
    }

    cancelToContracts() {
        this.router.navigate(['/smart_contracts'], {queryParams: { _activeTab: 'my_contracts' }});
    }

    getViewType() {
        if (this.contractTemplate) {
            if (this.authService.getProfile().memberId === this.contractTemplate.creator) {
                return this.contractTemplate.templateType;
            } else {
                return this.contractTemplate.templateType === ContractTemplateType.BUYER ? 'SELLER' : 'BUYER';
            }
        }
    }
}
