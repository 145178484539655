import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ConsumerOnboardingComponent } from './consumer-onboarding/consumer-onboarding.component';
import { RetailPaymentComponent } from './retail-payment/retail-payment.component';

const routes: Routes = [
    {
        path: 'consumer/:partnerId/:processInstanceId',
        component: ConsumerOnboardingComponent,
        pathMatch: 'full'
    },
    {
        path: 'retail/:partnerId/:processInstanceId/:paymentProcessInstanceId',
        component: RetailPaymentComponent,
        pathMatch: 'full'
    },
    {
        path: 'consumer-debt/:consumerId/:processInstanceId',
        component: ConsumerOnboardingComponent,
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ConsumerRoutingModule { }
