import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableUtils } from 'projects/components/src/lib/table-utils.service';
import { AuthService, MemberAccount, MemberAccountService, PagedResponse } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-reports-table-header',
    templateUrl: './member-reports-table-header.component.html'
})
export class MemberReportsTableHeaderComponent implements OnInit, OnDestroy {

    @Input() memberId: string;

    reportType = 'MONTHLY_ACC_REPORT';
    selectedMemberAccountId = '';
    reportTypes = ['MONTHLY_ACC_REPORT', 'WEEKLY_ACC_REPORT'];
    formGroup: UntypedFormGroup;
    memberAccounts: MemberAccount[] = [];
    subscription: any;

    constructor(private route: ActivatedRoute,
                private formBuilder: UntypedFormBuilder,
                private router: Router,
                private memberAccountService: MemberAccountService,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            reportCtrl: new UntypedFormControl()
        });
        if (!this.memberId) {
            this.memberId = this.authService.getProfile().memberId;
        }
        this.loadMemberAccountDetails();
        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['reportType'] && queryParams['memberAccountId']) {
            this.reportType = queryParams['reportType'] || 'MONTHLY_ACC_REPORT';
            this.selectedMemberAccountId = queryParams['memberAccountId'] || this.selectedMemberAccountId;
        } else {
            this.reportType = 'MONTHLY_ACC_REPORT';
            this.updateQueryParams(true);
        }

        this.subscription = this.route.queryParams.subscribe((params) => {
            this.reportType = params['reportType'] || 'MONTHLY_ACC_REPORT';
            this.formGroup.controls['reportCtrl'].setValue(this.reportType);
            if (params['memberAccountId']) {
                this.selectedMemberAccountId = params['memberAccountId'];
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    loadMemberAccountDetails() {
        this.memberAccountService.getAllMemberBusinessAccounts(this.memberId).subscribe((result: PagedResponse<MemberAccount>) => {
            if (result.content && result.content.length > 0) {
                this.memberAccounts = result.content;
                this.selectedMemberAccountId = this.memberAccounts[0]['id'];
                this.updateQueryParams(true);
            }
        });
        this.updateQueryParams(false);
    }

    onSelectReportType(reportType: string) {
        this.reportType = reportType;
        TableUtils.clearSubscriptions();
        this.updateQueryParams(false);
    }

    onAccountChange() {
        this.updateQueryParams(false);
    }

    updateQueryParams(replace: boolean) {
        const queryParams = { reportType: this.reportType, memberAccountId: this.selectedMemberAccountId, memberId: this.memberId, page: 1, sort: 'created', dir: 'desc', num: 100 };
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }
}
