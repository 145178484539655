<div>
    <form>
        <div>
            <div class="modal-header">
                <label class="form-title">{{ title }}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content">
                <div class="modal-content-small">
                    <pc-form-text-input  [label]="label" [formCtrl]="'nameCtrl'" [formGroup]="formGroup">
                    </pc-form-text-input>
                </div>
                <div *ngIf="warning">
                    <pc-error-message [error]="warning" [width]="'full'"></pc-error-message>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [submit]="close" [text]="'Cancel'" [enabled]="notUpdating"></pc-submit-button>
                <pc-submit-button [submit]="confirm" [text]="'Change'" [enabled]="isFormValid"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
