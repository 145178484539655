import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {AuthGuard, Role} from 'projects/services/src/public-api';
import { AlertDetailsComponent, AuthorityAlertComponent } from './components';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'details/transaction/:alertId',
        component: AlertDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Alerts', roles: [Role.AUTHORITY_READONLY] }
    },
    {
        path: 'details/:alertId',
        component: AlertDetailsComponent,
        canActivate: [AuthGuard],
        data: { title: 'Alerts', roles: [Role.AUTHORITY_READONLY] }
    },
    {
        path: 'list',
        component: AuthorityAlertComponent,
        canActivate: [AuthGuard],
        data: { title: 'Alerts', roles: [Role.AUTHORITY_READONLY] }
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AlertRoutingModule {
}
