import { KeyParty, SearchOrigin, SearchMatchStatus, SearchRiskLevel, Builder } from 'projects/services/src/public-api';

/**
 * Kyc search query params used to interact with service.
 * @type {KycSearchService}
 *
 * @author David Teles
 */
export class KycSearchParams {

    keyParties: KeyParty[];

    searchTerm: string;

    origin: SearchOrigin;

    matchStatus: SearchMatchStatus;

    riskLevel: SearchRiskLevel;

    extra: boolean;

    start: number = 0;

    size:number = -1;

    sort: string = 'created';

    sortDir: string = 'DESC';

    constructor() {
        this.keyParties = [];
    }

}

/**
 * Utility builder to help build SearchParams data to retrieve onboarding searches.
 *
 * @author David Teles
 */
export class OnboardSearchParamsBuilder implements Builder<KycSearchParams> {

    private params: KycSearchParams;

    private constructor() {
        this.params = new KycSearchParams();
        this.params.origin = SearchOrigin.ONBOARDING;
    }

    static builder() : OnboardSearchParamsBuilder {
        return new OnboardSearchParamsBuilder();
    }

    keyParty(keyParty: KeyParty): OnboardSearchParamsBuilder {
        this.params.keyParties.push(keyParty);
        return this;
    }

    extra(extra: boolean): OnboardSearchParamsBuilder {
        this.params.extra = extra;
        return this;
    }

    paginated(page: number, pageSize: number, sort: string, sortDir: 'asc' | 'desc' | ''): OnboardSearchParamsBuilder {
        this.params.start = page;
        this.params.size = pageSize;
        this.params.sort = sort;
        this.params.sortDir = sortDir;
        return this;
    }

    build(): KycSearchParams {
        return this.params;
    }
}
