import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { AuthService, KeyParty, KycSearchService, SearchResult } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-kyc-run-search-button',
    templateUrl: './kyc-run-search-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KycRunSearchButtonComponent implements OnInit {

    @Input() keyParty: KeyParty;

    @Output() created: EventEmitter<SearchResult> = new EventEmitter<SearchResult>();

    enabled = true;

    constructor(private kycService: KycSearchService,
                private authService: AuthService,
                private notificationService: NotificationService) {}

    ngOnInit() {
        this.isEnabled = this.isEnabled.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        this.enabled = false;

        const body = {
            keyParties: [{
                memberId: this.keyParty.individualKeyPartyId,
                individualKeyPartyId: this.keyParty.individualKeyPartyId,
                corporateKeyPartyId: this.keyParty.corporateKeyPartyId,
                type: this.keyParty.type
            }]
        };

        this.kycService.createSearch(body).subscribe((result) => {
            this.notificationService.showSuccess(`A compliance search for ${this.keyParty.name} has been successfully registered.`);
            this.enabled = true;
            this.created.emit(result);
        });
    }

    isEnabled() {
        return this.enabled && this.authService.isAuthority();
    }
}
