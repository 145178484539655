<div>
    <form [formGroup]="newLineForm">
        <div>
            <div class="modal-header">
                <label class="form-title">Add New Order Line</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content">
                <div>
                    <pc-text-input [label]="'Description'" [formCtrl]="'descriptionCtrl'" [formGroup]="newLineForm">
                    </pc-text-input>
                    <pc-form-amount-input [label]="'Quantity'" [formCtrl]="'quantityCtrl'"
                        [formGroup]="newLineForm" [dollar]="false">
                    </pc-form-amount-input>
                    <pc-form-selector [required]="true" [label]="'Unit Of Measure'" [formCtrl]="'unitOfMeasureCtrl'"
                        [formGroup]="newLineForm" [options]="unitOfMeasureOpts" [idValue]="'id'" [displayValue]="'name'" [defaultValue]="''">
                    </pc-form-selector>
                    <pc-form-amount-input [label]="'Price per Unit'" [formCtrl]="'pricePerUnitCtrl'"
                        [formGroup]="newLineForm" [dollar]="true">
                    </pc-form-amount-input>
                    <pc-form-amount-input [label]="'Discount'" [formCtrl]="'discountCtrl'"
                        [formGroup]="newLineForm" dollar]="true">
                    </pc-form-amount-input>
                    <pc-simple-checkbox *ngIf="!isNotForResale()" [label]="'Tax Exempt?'" [formCtrl]="'exemptCtrl'" [formGroup]="newLineForm" [invert]="true" (changed)="onExemptChanged()">
                    </pc-simple-checkbox>
                    <pc-simple-checkbox *ngIf="frameworkType !== ContractPartyClassification.PRODUCER && !isTaxExempt()" [label]="'Not for Resale?'" [formCtrl]="'notForResaleCtrl'" [formGroup]="newLineForm" [invert]="true" (changed)="onResaleChanged()">
                    </pc-simple-checkbox>
                    <pc-form-selector *ngIf="!isTaxExempt()" [required]="true" [label]="'Tax Type'" [formCtrl]="'taxTypeCtrl'"
                        [formGroup]="newLineForm" [options]="taxTypeOpts" [idValue]="'id'" [displayValue]="'name'" [defaultValue]="''">
                    </pc-form-selector>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [enabled]="isNewItemFormValid" [submit]="onSubmit" [text]="buttonText"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
