<div>
    <form [formGroup]="alertForm">
        <div>
            <div class="modal-header">
                <label class="form-title">Review Alert</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div class="row">
                    <div class="col-12">
                        <pc-text-area [required]="false" [label]="'Note'" [placeholder]="''" [formCtrl]="'noteCtrl'" [formGroup]="alertForm">
                        </pc-text-area>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <pc-submit-button [submit]="confirm" [text]="'Submit'" [enabled]="formValid"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
