import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import {
    AccountingLinkedMember, AccountingService, BusinessClientStatus, Configuration, CorporateEntity, MemberAccount, MemberAccountStatus, MemberType, MerchantAccountService,
    MerchantAccountSource, MerchantAccountStatus, SupportedTransactionType
} from 'projects/services/src/public-api';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'pc-merchant-searcher',
    templateUrl: './merchant-searcher.component.html',
    styleUrls: ['./merchant-searcher.component.scss']
})

export class MerchantSearcherComponent implements OnInit, AfterViewInit {

    MemberAccountStatus = MemberAccountStatus;
    MemberType = MemberType;

    @Input() memberId = '';
    @Input() jurisdiction = '';
    @Input() label: string;
    @Input() placeholder = '';
    @Input() includeAddress = false;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() merchantMemberType = MemberType.BUSINESS_MERCHANT;

    @Output() merchantSelected = new EventEmitter<MemberAccount>();

    merchantAutoComplete: Observable<MemberAccount[]> = null;
    autoCompleteControl = new UntypedFormControl('', [Validators.required]);
    merchantAccountOptions: MemberAccount[] = [];
    searching = false;
    emptyResults = false;
    merchantSelect: Subscription;
    accountingSystem: AccountingLinkedMember;
    searchLimit: number;
    totalResults: number;

    @ViewChild('merchantSelect', { read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;
    @ViewChild('merchantSelect') inputField: ElementRef;

    constructor(private merchantAccountService: MerchantAccountService,
                private accountingService: AccountingService) {}

    ngOnInit() {
        this.searchLimit = Configuration.getConfig().autoCompleteSearchLimit;
        this.formGroup.addControl('autoCompleteCtrl', this.autoCompleteControl);
        this.accountingService.getAccountingSystemInfo(this.memberId).subscribe((accountingSystem) => {
            this.accountingSystem = accountingSystem;
            this.merchantAutoComplete = this.autoCompleteControl.valueChanges.pipe(
                startWith(''),
                distinctUntilChanged(),
                debounceTime(1000),
                switchMap((value) => {
                    this.searching = true;
                    this.merchantAccountOptions = [];
                    return this.lookup(value || '');
                })
            );
        });
    }

    ngAfterViewInit() {
        this.subscribeToClosingActions();
    }

    focus() {
        this.inputField.nativeElement.focus();
    }

    private subscribeToClosingActions() {
        if (this.merchantSelect && !this.merchantSelect.closed) {
            this.merchantSelect.unsubscribe();
        }

        this.merchantSelect = this.trigger.panelClosingActions.subscribe((e) => {
            if (!e || !e.source) {
                if (this.merchantAccountOptions.length === 1) {
                    const option = this.merchantAccountOptions[0];
                    if (option.status === MemberAccountStatus.DISABLED) {
                        this.formGroup.controls[this.formCtrl].markAsPristine();
                        this.formGroup.controls[this.formCtrl].setValue('');
                        this.autoCompleteControl.setValue('');
                        this.merchantChanged(new MemberAccount());
                    } else {
                        this.merchantChanged(this.merchantAccountOptions[0]);
                    }
                } else {
                    const selected = this.merchantAccountOptions
                        .map((option) => {
                            return option.member.name;
                        })
                        .find((option) => {
                            return option === this.autoCompleteControl.value;
                        });

                    if (!selected && this.selected()) {
                        this.formGroup.controls[this.formCtrl].markAsPristine();
                        this.formGroup.controls[this.formCtrl].setValue('');
                        this.autoCompleteControl.setValue('');
                        this.merchantChanged(new MemberAccount());
                    }
                }
            }
        }, () => {
            return this.subscribeToClosingActions();
        }, () => {
            return this.subscribeToClosingActions();
        });
    }

    lookup(value: string) : Observable<MemberAccount[]> {
        this.totalResults = 0;
        if (value?.length <= 1) {
            this.searching = false;
            return of([]);
        }
        this.emptyResults = false;
        this.formGroup.controls['autoCompleteCtrl'].disable();
        return combineLatest([this.merchantAccountService.findMerchantsByFuzzyName(value, this.jurisdiction, this.merchantMemberType, this.searchLimit),
            this.merchantAccountService.findMerchantAccounts(value, this.memberId, [], null, [], this.merchantMemberType, this.searchLimit)]).pipe(
            switchMap(([merchantAccountConfigs, linkedMerchantAccounts]) => {
                this.totalResults = merchantAccountConfigs.totalElements;
                this.formGroup.controls['autoCompleteCtrl'].enable();
                // Merchants who onboarded through ACH pull or ACH deposit in PENDING state should be skipped, as pull merchants are not allowed for payments
                // and deposits and pending deposit merchants will not have complete details
                this.merchantAccountOptions = merchantAccountConfigs.content.filter((accountConfig) => {
                    return !(accountConfig.source === MerchantAccountSource.EXTERNAL_TRANSACTION
                        && (accountConfig.status === MerchantAccountStatus.PENDING || accountConfig.merchantAccountType === SupportedTransactionType.EXTERNAL_OUTGOING));
                }).map((merchantAccountConfig) => {
                    const merchantMemberAccount = merchantAccountConfig.merchantMemberAccount;
                    if (merchantAccountConfig.status !== MerchantAccountStatus.ACTIVE) {
                        merchantMemberAccount.status = MemberAccountStatus[merchantAccountConfig.status];
                    }
                    merchantMemberAccount.member.platform = 'CONFIA';
                    return merchantMemberAccount;
                });
                if (this.merchantAccountOptions.length === 0) {
                    this.emptyResults = true;
                }
                if (this.memberId) {
                    this.merchantAccountOptions.forEach((merchantAccount: MemberAccount) => {
                        const matchedMerchantAccount = linkedMerchantAccounts.content.find((linkedMerchantAccount) => {
                            return merchantAccount.id === linkedMerchantAccount.merchantMemberAccount.id;
                        });
                        if (matchedMerchantAccount) {
                            if (matchedMerchantAccount.status === MerchantAccountStatus.REJECTED) {
                                merchantAccount.status = BusinessClientStatus.REJECTED;
                            } else if (merchantAccount.status === MemberAccountStatus.ACTIVE) {
                                merchantAccount.status = BusinessClientStatus.LINKED;
                            } else if (merchantAccount.status === MemberAccountStatus.PENDING) {
                                merchantAccount.status = BusinessClientStatus.LINKED_PENDING;
                            }
                            if (matchedMerchantAccount.accountingExternalIds.length) {
                                merchantAccount.member.platform = this.accountingSystem.platform;
                            } else {
                                merchantAccount.member.platform = 'CONFIA';
                            }
                        } else {
                            if (merchantAccount.status === MemberAccountStatus.ACTIVE || merchantAccount.status === MemberAccountStatus.PENDING) {
                                merchantAccount.status = BusinessClientStatus.AVAILABLE;
                            }
                            merchantAccount.member.platform = 'CONFIA';
                        }
                    });
                }
                this.merchantAccountOptions.sort((a: MemberAccount, b: MemberAccount) => {
                    return a.member.name.toLowerCase() < b.member.name.toLowerCase() ? -1 : 1;
                });
                this.searching = false;
                if (!this.exactMatch(value) && value && value.length >= 2) {
                    const newMerchantAccount = new MemberAccount();
                    newMerchantAccount.member = new CorporateEntity();
                    newMerchantAccount.member.memberType = MemberType.BUSINESS_MERCHANT;
                    (newMerchantAccount.member as CorporateEntity).name = value;
                    (newMerchantAccount.member as CorporateEntity).businessName = value;
                    newMerchantAccount.status = MemberAccountStatus.NEW;
                    this.merchantAccountOptions.unshift(newMerchantAccount);
                }
                return of(this.merchantAccountOptions);
            }), catchError((_error) => {
                this.searching = false;
                this.formGroup.controls['autoCompleteCtrl'].enable();
                return of([]);
            })
        );
    }

    exactMatch(value: string) {
        return this.merchantAccountOptions.filter((memberAccount) => {
            const isBusiness = memberAccount.member.memberType === MemberType.BUSINESS_MERCHANT;
            return (!memberAccount.member.jurisdiction && isBusiness) && ((memberAccount.member.name.trim().toUpperCase() === value.trim().toUpperCase())
                || (isBusiness && (memberAccount.member as CorporateEntity).dbaName && (memberAccount.member as CorporateEntity).dbaName.trim().toUpperCase() === value.trim().toUpperCase()));
        }).length > 0;
    }

    merchantChanged(memberAccount: MemberAccount, event?: any) {
        if (!event || (event.isUserInput && event.source.value.trim().length > 1)) {
            this.emptyResults = false;
            let merchantMemberName = '';
            let displayValue = '';
            if (memberAccount.member) {
                merchantMemberName = memberAccount.member.name;
                displayValue = merchantMemberName;
                if (memberAccount.member.memberType === MemberType.BUSINESS_MERCHANT && (memberAccount.member as CorporateEntity).dbaName) {
                    displayValue = `${merchantMemberName} (DBA: ${(memberAccount.member as CorporateEntity).dbaName})`;
                }
            }
            this.formGroup.controls[this.formCtrl].markAsDirty();
            this.formGroup.controls[this.formCtrl].setValue(merchantMemberName);
            this.autoCompleteControl.setValue(displayValue);
            this.merchantSelected.emit(memberAccount);
        }
    }

    onInputChanged(event: any) {
        if (event.keyCode === 220) {
            event.preventDefault();
            return;
        }
    }

    selected() {
        return this.formGroup.controls[this.formCtrl].value;
    }

    onIconClick(event: any) {
        if (this.selected()) {
            this.searching = true;
            this.merchantAccountOptions = [];
            this.formGroup.controls[this.formCtrl].setValue(null);
            this.autoCompleteControl.setValue('');
            this.merchantSelected.emit(new MemberAccount());
        }
        event.stopPropagation();
        this.trigger.openPanel();
    }

    getMerchantName(memberAccount: MemberAccount) {
        if (memberAccount?.member?.name.trim().length < 2) {
            return;
        }
        if (memberAccount.status === MemberAccountStatus.NEW) {
            return memberAccount.member.name + ' (create new merchant)';
        }
        if (memberAccount.member.memberType === MemberType.INDIVIDUAL_MERCHANT) {
            return memberAccount.member.name;
        }
        const jurisdiction = memberAccount.member.jurisdiction ? memberAccount.member.jurisdiction : 'All';
        if (memberAccount.member.memberType === MemberType.BUSINESS_MERCHANT && (memberAccount.member as CorporateEntity).dbaName
            && (memberAccount.member as CorporateEntity).dbaName.toLowerCase() !== memberAccount.member.name.toLowerCase()) {
            return `${memberAccount.member.name} (${jurisdiction}) (DBA: ${(memberAccount.member as CorporateEntity).dbaName})`;
        }
        return `${memberAccount.member.name} (${jurisdiction})`;
    }

}
