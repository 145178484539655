import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { RecordsService, ProductBalance, ProductSummary } from 'projects/services/src/public-api';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { catchError, merge, startWith, switchMap, of as observableOf } from 'rxjs';

@Component({
    selector: 'pc-member-metrc-trace',
    templateUrl: './member-metrc-trace.component.html',
    styleUrls: ['./member-metrc-trace.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class MemberMetrcTraceComponent implements AfterViewInit, OnChanges, OnDestroy {

    @Input() memberId: string;
    @Input() startDate = '';
    @Input() endDate = '';

    expandedElement: ProductBalance;
    productBalances: ProductBalance[] = [];
    productSummaries: ProductSummary[] = [];
    displayedColumns = ['productType', 'quantityUnits', 'quantityIN', 'quantityOUT', 'quantityBalance', 'valueIn', 'valueOut'];
    refreshEvent: EventEmitter<null> = new EventEmitter<null>();

    isLoadingResults = true;
    subscription: any;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private recordsService: RecordsService) { }

    ngOnChanges(change: SimpleChanges): void {
        if ((change.startDate && !change.startDate.firstChange) || (change.endDate && !change.endDate.firstChange)) {
            this.refreshEvent.emit();
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.subscription = merge(this.refreshEvent).pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.recordsService.findProductBalances(this.memberId, this.startDate, this.endDate);
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((ProductBalances: ProductBalance[]) => {
            this.isLoadingResults = false;
            this.productBalances = ProductBalances;
            UIUtils.scrollDashboardToTop();
        });
    }

}
