<fieldset class="form-group" *ngIf="formCtrl">
    <div class="row">
        <div class="col-12" [formGroup]="formGroup">
            <label class="form-label">{{ label | translate }}</label>
            <div [formGroup]="formGroup" class="inputGroup">
                <input #merchantSelect [formControl]="autoCompleteControl" [formControlName]="formCtrl" type="text" [placeholder]="placeholder"
                    class="form-control form-input-editable" [class.empty-results]="emptyResults" [matAutocomplete]="auto" (keydown)="onInputChanged($event)" (click)="$event.target.select()">
                <i class="fa" [ngClass]="selected() ? 'fa-times-circle-o' : 'fa-chevron-down'" (click)="onIconClick($event)"></i>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
                    <mat-option [ngClass]="searching ? 'hidden' : 'merchant-entry'" *ngFor="let merchant of merchantAutoComplete | async;"
                                [value]="merchant.member.name" [disabled]="merchant.status === MemberAccountStatus.DISABLED || merchant.status === MemberAccountStatus.REJECTED"
                                (onSelectionChange)="merchantChanged(merchant, $event)">
                        <div class="row">
                            <div class="col-7">
                                <img *ngIf="merchant.member.memberType === MemberType.INDIVIDUAL_MERCHANT" class="member-type-icon" src="assets/images/profile-icon.svg" title="Individual">
                                {{ getMerchantName(merchant) }}
                            </div>
                            <div class="col-2 text-center"><pc-member-badge [parent]="merchant.member"></pc-member-badge></div>
                            <div class="col-3 text-end"><pc-merchant-source-badge [parent]="merchant.member"></pc-merchant-source-badge></div>
                        </div>
                        <div *ngIf="includeAddress && merchant?.address" class="row address-info">
                            <div class="col-7">{{ (merchant.address.streetAddressOne || "") + " " +  (merchant.address.streetAddressTwo || "")}}</div>
                            <div class="col-2 text-center">{{ (merchant.address.city ? merchant.address.city + ", " : "") + (merchant.address.stateProvince || "") }}</div>
                            <div class="col-3 text-end">{{ merchant.address.country || "" }}</div>
                        </div>
                    </mat-option>
                    <mat-option *ngIf="searching">
                        <i class="fa fa-spin fa-spinner"></i>Searching
                    </mat-option>
                    <mat-option *ngIf="!searching && totalResults > searchLimit" [disabled]="true">
                        There are {{totalResults - searchLimit}} additional results - please refine your search.
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    </div>
</fieldset>
