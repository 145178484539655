import { Component } from '@angular/core';

@Component({
    selector: 'pt-consumer-terms',
    templateUrl: './consumer-terms.component.html',
    styleUrls: ['./consumer-terms.component.scss']
})
export class ConsumerTermsComponent {
    constructor() {}

}
