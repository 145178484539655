<fieldset class="form-group" *ngIf="formCtrl">
    <div class="row">
        <div class="col-12" *ngIf="label">
            <label class="form-label">{{ label | translate }}</label>
        </div>
        <div class="inputGroup col-12" [formGroup]="formGroup">
            <input #autoSelect [formControl]="autoCompleteControl" type="text" [placeholder]="placeholder" (focusin)="onFocus()"
                   class="form-control form-input-editable" [matAutocomplete]="auto" (click)="$event.target.select()">
            <i *ngIf="!isDisabled()" class="fa" [ngClass]="isSelected() ? 'fa-times-circle-o' : 'fa-chevron-down'" (click)="onIconClick($event)"></i>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let bankAccount of autoComplete | async;" [value]="bankAccount.id" (onSelectionChange)="selectionChanged(bankAccount, $event)"
                            [disabled]="bankAccount.status !== BankAccountStatus.ACTIVE">
                    <div class="row">
                        <div class="col-sm-6">
                            {{ bankAccount.name }}
                        </div>
                        <div class="col-sm-3">
                            {{ bankAccount.type }}
                        </div>
                        <div class="col-sm-3 text-end">
                            {{ getAccountNumber(bankAccount.accountNumber) }}
                        </div>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </div>
    </div>
</fieldset>
