<div>
    <div class="my-2" *ngIf="currentBusinessClient">
        Select Retail Receipt Listings:
    </div>
    <div>
        <table mat-table #customerInvoicesTable [dataSource]="customerInvoices" multiTemplateDataRows>
            <ng-container matColumnDef="other_invoice_description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let element">
                    <textarea maxlength="1000" [ngModelOptions]="{standalone: true}" [(ngModel)]="element.data.description" [class.error]="!element.data.description && element.data.paidAmount" (change)="onInvoiceChange(element)"></textarea>
                </td>
                <td mat-footer-cell class="text-right" *matFooterCellDef><strong>Total:</strong></td>
            </ng-container>
            <ng-container matColumnDef="other_invoice_amount">
                <th mat-header-cell class="centered" *matHeaderCellDef>Amount</th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <span class="dollar">
                        <input [ngModelOptions]="{standalone: true}" [(ngModel)]="element.data.paidAmount" type="text" oninput="value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" placeholder="0.00" (change)="onInvoiceChange(element)">
                    </span>
                </td>
                <td mat-footer-cell class="centered" *matFooterCellDef>
                    <span class="dollar">
                        <input [ngModelOptions]="{standalone: true}" [(ngModel)]="currentBusinessClient.selectedTotal" type="text" oninput="value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" disabled="true" readonly="true">
                    </span>
                </td>
            </ng-container>
            <ng-container matColumnDef="other_invoice_include">
                <th mat-header-cell class="centered"  *matHeaderCellDef>Include</th>
                <td mat-cell class="centered" *matCellDef="let element; let invoiceIndex = dataIndex;">
                    <i class="fa fa-2x fa-minus" (click)="removeOtherAmount(invoiceIndex)"></i>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
            <ng-container matColumnDef="other_invoice_document">
                <th mat-header-cell *matHeaderCellDef>Documents</th>
                <td mat-cell *matCellDef="let element">
                    <button class="blue-button button-small" [disabled]="!element.data.paidAmount" [class]="(element.data.paidAmount && !element.data.supportingDocuments.length) ? 'required': ''" (click)="addSupportingDocument(element.data)" type="button"><i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add Receipt Listing</div></button>
                    <div *ngIf="element.data.supportingDocuments.length > 0">
                        <div class="supporting-document" *ngFor="let supportingDocument of element.data.supportingDocuments; let documentNumber = index">
                            <i class="fa fa-2x fa-times" (click)="removeSupportingDocument(element.data, documentNumber)"></i>{{supportingDocument.file?.name}}
                        </div>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef="let element">
                    <button class="blue-button button-small" (click)="addNewInvoiceRow()" type="button"><i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add Row</div></button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="otherInvoiceColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: otherInvoiceColumns;"></tr>
            <tr mat-footer-row class="pt-2" *matFooterRowDef="otherInvoiceColumns"></tr>
        </table>
        <div class="mt-3 row">
            <div class="col-12 d-flex justify-content-end">
                <button class="blue-button"  [disabled]="!invoicesValid()" (click)="next()" type="button">Next</button>
            </div>
        </div>
    </div>

    <input hidden type="file" id="upload" #upload (change)="selectFile($event)" placeholder="Upload file" accept=".pdf,.doc,.docx">

</div>
