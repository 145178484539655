import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MetrcLicense } from '../models/metrc-license';
import { CustomValidators } from '../validators/custom.validators';


@Injectable({
    providedIn: 'root'
})
export class MetrcAccountFormService {

    constructor(private formbuilder: UntypedFormBuilder) {
    }

    initializeForm(metrcLicense: MetrcLicense): UntypedFormGroup {
        const form = this.formbuilder.group({
            userKeyCtrl: new UntypedFormControl('', [Validators.required,  CustomValidators.noWhitespace, Validators.minLength(48), Validators.maxLength(48)]),
            memberIdCtrl: new UntypedFormControl()
        });
        if (metrcLicense) {
            form.patchValue({
                userKeyCtrl: metrcLicense.userKey,
                memberIdCtrl: metrcLicense.memberId
            });
        }
        return form;
    }

    getMetrcLicense(form: UntypedFormGroup): MetrcLicense {
        const metrcLicense = new MetrcLicense();
        metrcLicense.userKey = form.get('userKeyCtrl').value;
        metrcLicense.memberId = form.get('memberIdCtrl').value;
        return metrcLicense;
    }
}
