import {
    MerchantAccount, WhitelistRelationshipPartyFormService, WhitelistRelationshipPartyService, MemberType, SupportedTransactionType, MerchantAccountSource, Member
} from 'projects/services/src/public-api';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { Component, Inject, OnInit } from '@angular/core';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';

@Component({
    selector: 'pt-add-whitelist-relationship-party-modal',
    templateUrl: './add-whitelist-relationship-party-modal.component.html',
    styleUrls: ['./add-whitelist-relationship-party-modal.component.scss']
})
export class AddWhitelistRelationshipPartyModalComponent extends BaseModalComponent<AddWhitelistRelationshipPartyModalComponent> implements OnInit {

    MerchantAccountSource = MerchantAccountSource;

    whitelistPartyForm: UntypedFormGroup;
    memberId: string;
    whitelistedMemberId: string;
    member: Member;
    whitelistPartyType = 'MEMBERS';
    whitelistTxnTypes = [SupportedTransactionType.OUTGOING, SupportedTransactionType.BOTH, SupportedTransactionType.EXTERNAL_OUTGOING];
    whitelistTxnType = SupportedTransactionType.OUTGOING;
    whitelistTypes = [SupportedTransactionType.NONE, SupportedTransactionType.OUTGOING];
    searchWhitelistTypes = ['MEMBERS', 'MERCHANTS'];
    types: MemberType[] = [MemberType.BUSINESS];
    changeTrigger = 1;

    constructor(private whitelistRelationshipPartyFormService : WhitelistRelationshipPartyFormService,
                private whitelistRelationshipPartyService : WhitelistRelationshipPartyService,
                private notifier: NotificationService,
                protected dialogRef: MatDialogRef<AddWhitelistRelationshipPartyModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.member = data.member;
        this.memberId = data.member.id;
    }

    ngOnInit() {
        this.onSubmit = this.onSubmit.bind(this);
        this.isWhitelistedFormValid = this.isWhitelistedFormValid.bind(this);
        this.validateCurrentMember = this.validateCurrentMember.bind(this);
        this.whitelistPartyForm = this.whitelistRelationshipPartyFormService.initializeForm(this.member, this.whitelistPartyType, this.whitelistTxnType);
    }

    setWhitelistedMember(member : Member) {
        this.whitelistPartyForm.controls['whitelistedMemberNameCtrl'].setValue('');
        if (member) {
            this.whitelistedMemberId = member.id;
            this.whitelistPartyForm.controls['whitelistedMemberNameCtrl'].setValue(member.name);
        }
    }

    setWhitelistedMerchant (merchantAccount : MerchantAccount) {
        this.whitelistPartyForm.controls['whitelistedMemberNameCtrl'].setValue('');
        if (merchantAccount) {
            this.whitelistedMemberId =  (merchantAccount.merchantMemberAccount || {}).memberId;
            this.whitelistPartyForm.controls['whitelistedMemberNameCtrl'].setValue(merchantAccount.merchantMemberName);
        }
    }

    validateCurrentMember() {
        return (this.whitelistPartyForm.controls['whitelistedMemberIdCtrl'].value === this.memberId);
    }

    selectedPartyType (type : string) {
        this.whitelistPartyForm = this.whitelistRelationshipPartyFormService.initializeForm(this.member, type, this.whitelistTxnType);
        this.whitelistPartyType = type;
        if (type === 'MEMBERS') {
            this.whitelistTypes = [SupportedTransactionType.NONE, SupportedTransactionType.OUTGOING];
        } else {
            this.whitelistTypes = [SupportedTransactionType.NONE, SupportedTransactionType.INCOMING, SupportedTransactionType.OUTGOING, SupportedTransactionType.BOTH];
        }
    }

    selectedWhitelistTxnType (txnType : SupportedTransactionType) {
        this.whitelistPartyForm = this.whitelistRelationshipPartyFormService.initializeForm(this.member, this.whitelistPartyType, txnType);
        this.changeTrigger = Math.random();
        if (txnType === SupportedTransactionType.BOTH) {
            this.whitelistTxnTypes = [SupportedTransactionType.INCOMING, SupportedTransactionType.OUTGOING, SupportedTransactionType.BOTH,  SupportedTransactionType.EXTERNAL_OUTGOING ];
        } else if (txnType === SupportedTransactionType.OUTGOING) {
            this.whitelistTxnTypes = [SupportedTransactionType.OUTGOING, SupportedTransactionType.BOTH, SupportedTransactionType.EXTERNAL_OUTGOING];
        } else if (txnType === SupportedTransactionType.INCOMING) {
            this.whitelistTxnTypes = [SupportedTransactionType.INCOMING, SupportedTransactionType.BOTH];
        }
    }

    close(response?: any) {
        super.close(response);
    }

    isWhitelistedFormValid() {
        return !this.whitelistPartyForm.invalid && !this.validateCurrentMember() && this.whitelistPartyForm.get('whitelistTypeCtrl').value;
    }

    onSubmit(reset : any) {
        const whitelistRelationshipParty = this.whitelistRelationshipPartyFormService.getWhitelistRelationShipParty(this.whitelistPartyForm, this.whitelistedMemberId);
        this.whitelistRelationshipPartyService.saveWhitelistedParty(whitelistRelationshipParty).subscribe(() => {
            this.notifier.showSuccess('Successfully added whitelisted relationship party</b>.');
            this.close(true);
        }, (error: any) => {
            reset();
            throw error;
        });
    }

    amountChanged(_event: any) {
        if (!this.whitelistPartyForm.controls['maximumMonthlyTransactionAmountCtrl'].valid) {
            this.whitelistPartyForm.controls['maximumMonthlyTransactionAmountCtrl'].setValue('');
            this.whitelistPartyForm.controls['maximumMonthlyTransactionAmountCtrl'].updateValueAndValidity();
        }
        const maxMonthlyTxnsCount = this.whitelistPartyForm.controls['maximumMonthlyNumberOfTransactionsCtrl'].value;
        if (maxMonthlyTxnsCount) {
            const maxMonthlyTxnsCountLength = maxMonthlyTxnsCount.replaceAll(',', '').length;
            if (maxMonthlyTxnsCountLength > 6  || Number(maxMonthlyTxnsCount.replaceAll(',', '')) > 100000) {
                this.whitelistPartyForm.controls['maximumMonthlyNumberOfTransactionsCtrl'].setValue('');
                this.whitelistPartyForm.controls['maximumMonthlyNumberOfTransactionsCtrl'].setValidators([Validators.minLength(0), Validators.maxLength(6)]);
            } else if (maxMonthlyTxnsCountLength === 6) {
                this.whitelistPartyForm.controls['maximumMonthlyNumberOfTransactionsCtrl'].setValidators([Validators.minLength(0), Validators.maxLength(7)]);
            }
            this.whitelistPartyForm.controls['maximumMonthlyNumberOfTransactionsCtrl'].updateValueAndValidity();
        }
    }
}
