<div class="row">
    <div class="col-12">
        <label class="form-label">Date of Birth</label>
    </div>
    <form class="col-12" [formGroup]="internalForm">
        <input #monthField class="form-control form-tel-input-narrow" placeholder="MM" [required]="required"
            (input)="onChange('birthMonthCtrl', 2, 12)" formControlName="birthMonthCtrl" type="number" [class.ng-invalid]="!isAgeValid()" (blur)="onBlur()">
        <input #dayField class="form-control form-tel-input-narrow" placeholder="DD" [required]="required"
            (input)="onChange('birthDayCtrl', 2, 31)" formControlName="birthDayCtrl" type="number" [class.ng-invalid]="!isAgeValid()" (blur)="onBlur()">
        <input #yearField class="form-control form-tel-input-wide" placeholder="YYYY" [required]="required"
            (input)="onChange('birthYearCtrl', 4, cutoffYear)" formControlName="birthYearCtrl" type="number" [class.ng-invalid]="!isAgeValid()">
    </form>
</div>
<div class="row error" *ngIf="age > 0 && age < 18">
    <div class="col-12">
        <span>Must be at least 18 years old</span>
    </div>
</div>
