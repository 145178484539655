import { Injectable, Inject } from '@angular/core';
import { BankAccountStatus } from '../public-api';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class BankAccountService {

    baseUrl: string;

    constructor(private http: HttpService,
                @Inject('environment') environment: any) {
        this.baseUrl = environment.PaymentAPIEndpoint + '/bank-account';
    }

    deleteAccount(id:string) {
        return this.http.delete(`${this.baseUrl}/${id}`);
    }

    getBankAccounts(memberId: string, statuses: BankAccountStatus[], start: number, size: number, sort: string, sortDir: string) {
        const params = `?memberId=${memberId}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}&statuses=${statuses || BankAccountStatus.ALL}`;
        return this.http.get(`${this.baseUrl}${params}`);
    }

    getAllActiveBankAccounts(memberId: string) {
        const params = `?memberId=${memberId}&statuses=ACTIVE`;
        return this.http.get(`${this.baseUrl}${params}`);
    }

    updateBankAccount(id: string, body: any) {
        return this.http.put(`${this.baseUrl}/${id}`, body);
    }

    getBankAccountById(id: string) {
        return this.http.get(`${this.baseUrl}/${id}`);
    }

    saveAndRegisterBankAccount(body: any) {
        return this.http.post(`${this.baseUrl}/register`, body);
    }

    deleteMerchantAccount(id: string) {
        return this.http.delete(`${this.baseUrl}/merchant/${id}`);
    }

    getBankRoutingNumbers(merchantMemberId: string, merchantMemberAccountId: string) {
        return this.http.get(`${this.baseUrl}/${merchantMemberId}/${merchantMemberAccountId}/routing`);
    }

    getLinkedBankAccount(memberId: string, memberAccountId: string) {
        return this.http.get(`${this.baseUrl}/${memberId}/${memberAccountId}`);
    }

    linkBankAccount(linkedAccount: any) {
        return this.http.post(`${this.baseUrl}/link`, linkedAccount);
    }

    unlinkBankAccount(memberId: string, memberAccountId: string) {
        return this.http.delete(`${this.baseUrl}/${memberId}/${memberAccountId}/unlink`);
    }

    getLinkedBankAccountsForMerchant(memberId: string, memberAccountId: string, start: number, size: number, sort: string, sortDir: string) {
        const params = `?&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}/${memberId}/${memberAccountId}/merchant${params}`);
    }

    searchBankAccounts(searchTerm:string, start: number, size: number, sort: string, sortDir: string) {
        const params = `?searchTerm=${encodeURIComponent(searchTerm)}&start=${start}&size=${size}&sort=${sort}&sortDir=${sortDir}`;
        return this.http.get(`${this.baseUrl}/search${params}`);
    }
}
