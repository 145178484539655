export class WatchlistAlternateName {
    id: string;
    created: string;
    updated: string;
    alternateId: string;
    alternateName: string;
    alternateRemarks: string;
    alternateType: string;
    watchlistEntityId: string;
}
