<div class="horizontal-scroll mat-table-container">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <mat-paginator #paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    <table mat-table [dataSource]="monthlyReports" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="month">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="reportEndDate"> Statement Month </th>
            <td mat-cell *matCellDef="let element">  {{element.reportEndDate | date: 'MMMM, yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="statement">
            <th class="centered" mat-header-cell *matHeaderCellDef> Statement </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <a class="dashboard-list-a" (click)="viewPdf(element)">
                    <i class="fa fa-file-pdf-o pdf_icon"></i>
                </a>
                <a class="dashboard-list-a" (click)="viewCsv(element)">
                    <i class="fa fa-file-excel-o csv_icon"></i>
                </a>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator (page)="handlePageBottom($event)" [pageIndex]="paginator.pageIndex" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
</div>
