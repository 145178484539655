import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'login',
        // eslint-disable-next-line arrow-body-style
        loadChildren: () => import('./login/login.module').then((m) => m.LoginModule)
    },
    {
        path: 'pending',
        // eslint-disable-next-line arrow-body-style
        loadChildren: () => import('./pending/pending.module').then((m) => m.PendingModule)
    },
    {
        path: 'signup',
        // eslint-disable-next-line arrow-body-style
        loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule)
    },
    {
        path: 'reset',
        // eslint-disable-next-line arrow-body-style
        loadChildren: () => import('./reset/reset.module').then((m) => m.ResetModule)
    },
    {
        path: 'browser-not-supported',
        // eslint-disable-next-line arrow-body-style
        loadChildren: () => import('./browser-not-supported/browser-not-supported.module').then((m) => m.BrowserNotSupportedModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        TranslateModule
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
