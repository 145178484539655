import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, Profile } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-generic-header',
    templateUrl: './generic-header.component.html'
})
export class GenericHeaderComponent implements OnInit {

    @Input() myTaskCount: number;
    @Input() expandedMenu: boolean;

    @Output() toggleExpandSideMenu: EventEmitter<any> = new EventEmitter<any>();

    menuItems: any[] = [];
    profile: Profile;

    constructor(private authService: AuthService,
                private router: Router) {
    }

    ngOnInit() {
        this.profile = this.authService.getProfile();
        const administrationMenu: any = {};
        administrationMenu.icon = 'fa fa-user-o';
        administrationMenu.routerLink = '/administration';
        administrationMenu.linkText = 'Administration';
        this.menuItems.push(administrationMenu);
    }

    getMenuClass() {
        if (this.expandedMenu) {
            return 'top-header-icon-wide';
        } else {
            return 'top-header-icon-narrow';
        }
    }

    getBodyClass() {
        if (this.expandedMenu) {
            return 'top-header-body-narrow';
        } else {
            return 'top-header-body-wide';
        }
    }

    onToggleExpandSideMenu() {
        this.toggleExpandSideMenu.emit('toggle');
    }

    onLoggedout() {
        this.authService.logout();
    }

    isDisabled(link: string) {
        return this.router.url.indexOf(link) >= 0;

    }
}
