<div class="col-12">
    <div class="row mb-3">
        <div class="col-5">
            <span class="form-title">Partner Management</span>
        </div>
        <div class="col-7">
            <mat-button-toggle-group #group="matButtonToggleGroup" class="mb-15 float-right"
                    [value]="partnerType" (change)="onValueChange(group.value)">
                <mat-button-toggle value="linked">
                    <div>My Partners</div>
                </mat-button-toggle>
                <mat-button-toggle value="available">
                    <div>Available Partners</div>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <p><i>This section allows you to enable/disable partners to perform certain actions, such as accept consumer payments, on your behalf.</i></p>
    <div class="col-sm-12 horizontal-scroll mat-table-container">
        <div class="mat-table-spinner" *ngIf="isLoadingResults">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table [dataSource]="connectedMembers" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="sortName"> Partner Name </th>
                <td mat-cell *matCellDef="let element"> {{element.partner.name}} </td>
            </ng-container>
            <ng-container matColumnDef="account">
                <th mat-header-cell *matHeaderCellDef> Account </th>
                <td mat-cell *matCellDef="let element"> {{element.memberAccount.accountName }} </td>
            </ng-container>
            <ng-container matColumnDef="created">
                <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header> Date Added </th>
                <td mat-cell class="centered" *matCellDef="let element"> {{element.partner.created | date:'mediumDate' }} </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell class="centered" *matHeaderCellDef> Action </th>
                <td mat-cell  class="centered" *matCellDef="let element">
                    <span *ngIf="isAuthorityOrAdmin(element.memberAccount) && element.status === MemberStatus.ACTIVE && partnerType === 'linked'">
                        <i class="fa fa-times" id="delete_member_btn" (click)="onDeleteConnectedMember(element)"></i>
                    </span>
                    <span *ngIf="isAuthorityOrAdmin(element.memberAccount) && element.status === MemberStatus.ACTIVE && partnerType === 'available'">
                        <i class="fa fa-plus" id="add_member_btn" (click)="onAddConnectedMember(element)"></i>
                    </span>
                    <span *ngIf="element.status === MemberStatus.DISABLED">Disabled</span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>

</div>
