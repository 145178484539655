import { Component, OnInit } from '@angular/core';
import { PaymentService } from 'projects/services/src/lib/payment.service';

@Component({
    selector: 'pt-authority-operational-reports',
    templateUrl: './authority-operational-reports.component.html'
})
export class AuthorityOperationalReportsComponent implements OnInit {

    cashDeposits = 0;

    constructor(private paymentService: PaymentService) { }

    ngOnInit() {
        this.paymentService.getCashDeposits().subscribe((response: number) => {
            this.cashDeposits = response;
        });
    }
}
