<div class="row">
    <div *ngIf="isPartner" class="col-12 dashboard-header">
        Connected Members
    </div>
    <div *ngIf="!isPartner" class="col-12 dashboard-header">
        <img class="dashboard-icon" src="assets/images/member-icon-2.svg" alt="">
        <span>Members</span>
    </div>
    <div class="row">
        <div class="col-12">
                <div class="col-12 tab-col" *ngIf="!isPartner" [ngClass]="getTabClass('members')" (click)="setActiveTab('members')">
                    Members
                </div>
                <div class="col-12 tab-col" *ngIf="isPartner" [ngClass]="getTabClass('connected_members')" (click)="setActiveTab('connected_members')">
                    Members
                </div>
                <div class="col-12 tab-col" *ngIf="!isPartner" [ngClass]="getTabClass('consumers')" (click)="setActiveTab('consumers')">
                    Consumers
                </div>
                <div class="col-12 tab-col" *ngIf="isPartner" [ngClass]="getTabClass('partner_consumers')" (click)="setActiveTab('partner_consumers')">
                    Consumers
                </div>
                <div class="col-12 tab-col" *ngIf="!isPartner" [ngClass]="getTabClass('under_review')" (click)="setActiveTab('under_review')">
                    Under Review
                </div>
                <div class="col-12 tab-col" *ngIf="!isPartner" [ngClass]="getTabClass('applied')" (click)="setActiveTab('applied')">
                    Applying
                </div>
                <div class="col-12 tab-col" *ngIf="!isPartner" [ngClass]="getTabClass('merchants')" (click)="setActiveTab('merchants')">
                    Merchants
                </div>
                <div class="col-12 tab-col" *ngIf="!isPartner" [ngClass]="getTabClass('fee_accounts')" (click)="setActiveTab('fee_accounts')">
                    Fee Accounts
                </div>
        </div>
    </div>
</div>
