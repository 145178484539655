<div class="col-12 dashboard-info" *ngIf="account && authService.isAuthority()">
  <div class="row mb-3">
    <div class="col-6">
      <span class="form-title">Bank Account Review</span>
    </div>
    <div class="col-6 button-div pull-right">
        <pc-submit-button class="pull-right" [enabled]="notSubmitting" [submit]="approveAccount" [text]="'Approve Bank Account'"></pc-submit-button>
        <pc-submit-button class="pull-right" [enabled]="notSubmitting" [submit]="rejectAccount" [text]="'Reject Bank Account'"></pc-submit-button>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-6">
        <p class="section-header">Member Information</p>
        <dl class="row">
            <dt class="col-sm-4 expandedLabel">Name:</dt>
            <dd class="col-sm-8 expandedValue">{{member?.name}}</dd>

            <dt *ngIf="getDbaName()" class="col-sm-4 expandedLabel">DBA Name:</dt>
            <dd *ngIf="getDbaName()" class="col-sm-8 expandedValue">{{getDbaName()}}</dd>

            <dt class="col-sm-4 expandedLabel" *ngIf="member?.address">Address:</dt>
            <dd class="col-sm-8 expandedValue" *ngIf="member?.address"><pc-business-address [address]="member.address"></pc-business-address></dd>

            <dt class="col-sm-4 expandedLabel">Email:</dt>
            <dd class="col-sm-8 expandedValue">{{member?.email}}</dd>

            <dt class="col-sm-4 expandedLabel" *ngIf="member?.phone">Phone:</dt>
            <dd class="col-sm-8 expandedValue" *ngIf="member?.phone">{{member?.phone | phone}}</dd>
        </dl>

        <p class="section-header" *ngIf="keyParties.length">Key Parties</p>
        <dl class="row" *ngFor="let keyParty of keyParties">
            <dt class="col-sm-4 expandedLabel">Key Party:</dt>
            <dd class="col-sm-8 expandedValue">{{keyParty.individualKeyParty ? (keyParty.individualKeyParty?.firstName + ' ' + keyParty.individualKeyParty?.lastName) : keyParty.corporateKeyParty?.name}}</dd>

            <dt class="col-sm-4 expandedLabel">Key Party Type:</dt>
            <dd class="col-sm-8 expandedValue">{{keyParty.individualKeyPartyId ? 'INDIVIDUAL' : 'CORPORATE' | translate}}</dd>

            <dt class="col-sm-4 expandedLabel">Percent Ownership:</dt>
            <dd class="col-sm-8 expandedValue">{{keyParty.percentOwnership}}</dd>

            <dt class="col-sm-4 expandedLabel" *ngIf="keyParty.individualKeyParty?.address">Address:</dt>
            <dd class="col-sm-8 expandedValue" *ngIf="keyParty.individualKeyParty?.address"><pc-business-address [address]="keyParty.individualKeyParty.address"></pc-business-address></dd>

            <dt class="col-sm-4 expandedLabel" *ngIf="keyParty.corporateKeyParty?.address">Address:</dt>
            <dd class="col-sm-8 expandedValue" *ngIf="keyParty.corporateKeyParty?.address"><pc-business-address [address]="keyParty.corporateKeyParty.address"></pc-business-address></dd>
        </dl>
    </div>
    <div class="col-6" *ngIf="account.externalAccountDetails">
        <p class="section-header">Account Information</p>
        <dl class="row">
            <dt class="col-sm-4 expandedLabel">Source:</dt>
            <dd class="col-sm-8 expandedValue">{{account.source}}</dd>

            <dt class="col-sm-4 expandedLabel">Status:</dt>
            <dd class="col-sm-8 expandedValue">{{account.status | translate}}</dd>
        </dl>

        <dl class="row">
            <dt class="col-sm-4 expandedLabel">Provider:</dt>
            <dd class="col-sm-8 expandedValue">{{account.externalAccountDetails.providerName}}</dd>

            <dt class="col-sm-4 expandedLabel">Account Name:</dt>
            <dd class="col-sm-8 expandedValue">{{account.externalAccountDetails.accountName}}</dd>

            <dt class="col-sm-4 expandedLabel">Account Type:</dt>
            <dd class="col-sm-8 expandedValue">{{account.externalAccountDetails.accountType}}</dd>

            <dt *ngIf="account.externalAccountDetails.accountClassification" class="col-sm-4 expandedLabel">Account Classification:</dt>
            <dd *ngIf="account.externalAccountDetails.accountClassification" class="col-sm-8 expandedValue">{{account.externalAccountDetails.accountClassification}}</dd>
        </dl>

        <dl class="row">
            <dt class="col-sm-4 expandedLabel">Account Number:</dt>
            <dd class="col-sm-8 expandedValue">{{account.externalAccountDetails.accountNumber}}</dd>

            <dt class="col-sm-4 expandedLabel">ACH Routing Number:</dt>
            <dd class="col-sm-8 expandedValue">{{account.externalAccountDetails.routingNumber}}</dd>

            <dt class="col-sm-4 expandedLabel">Currency:</dt>
            <dd class="col-sm-8 expandedValue">{{account.externalAccountDetails.accountCurrency}}</dd>
        </dl>

        <p class="section-header">Account Holders</p>
        <dl class="row" *ngFor="let holder of account.externalAccountDetails.holders">
            <dt class="col-sm-4 expandedLabel">Holder Name:</dt>
            <dd class="col-sm-8 expandedValue">{{holder.fullName}}</dd>

            <dt class="col-sm-4 expandedLabel">Holder Type:</dt>
            <dd class="col-sm-8 expandedValue">{{holder.holderType}}</dd>
        </dl>
    </div>
  </div>

</div>
