import { Injectable, NgZone } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SessionRefreshComponent } from 'projects/components/src/lib/session-refresh/session-refresh.component';

@Injectable({
    providedIn: 'root'
})
export class RefreshService {

    bottomSheetRef: MatBottomSheetRef<SessionRefreshComponent>;

    constructor(private zone: NgZone,
                public bottomSheet: MatBottomSheet) {
    }

    showExpiringSession(refresh: any) {
        const config = {
            closeOnNavigation: false,
            disableClose: true,
            data: {
                refresh
            }
        };
        this.zone.run(() => {
            this.bottomSheetRef = this.bottomSheet.open(SessionRefreshComponent, config);
        });
    }

    clear() {
        this.bottomSheet.dismiss();
    }
}