<div class="col-sm-12 horizontal-scroll mat-table-container">
    <div class="mat-table-spinner" *ngIf="isLoading">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>

    <table mat-table [dataSource]="dataSource">

        <ng-content></ng-content>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;" [class]="options.rowClass"></tr>
    </table>

    <mat-paginator (page)="onPageListener($event)"
                   [pageSize]="pageTracking?.pageSize"
                   [pageSizeOptions]="pageTracking?.pageSizeOptions"
                   [length]="totalElements"
                   showFirstLastButtons
                   aria-label="Select page">
    </mat-paginator>
</div>