import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FieldMetadata, Utils } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-sc-number',
    templateUrl: './number.component.html'
})
export class NumberComponent implements OnInit {

    @Input() enforceRequired = true;
    @Input() formGroup: UntypedFormGroup;
    @Input() metadata: FieldMetadata;

    @Output() changed: EventEmitter<FieldMetadata> = new EventEmitter<FieldMetadata>();

    fieldControl: UntypedFormControl;

    ngOnInit() {
        const validators = [];
        if (this.enforceRequired && this.metadata.required) {
            validators.push(Validators.required);
        }
        this.fieldControl = new UntypedFormControl('', validators);
        this.formGroup.addControl(this.metadata.id, this.fieldControl);
        this.fieldControl.setValue(this.metadata.numericValue);
    }

    fieldChanged(e: any) {
        this.metadata.numericValue = e.target.value;
        this.changed.emit(this.metadata);
    }

    validate(event: any) {
        if (Utils.isNavigationKeyCode(event.keyCode)) {
            return;
        }
        if (event.keyCode === 190 || Utils.isNumericKeyCode(event.keyCode)) {
            const target = event.target;
            return target.value + event.key !== '00';

        }
        return false;
    }
}
