import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {LandingSettings} from '../models/landing-settings';

@Injectable({
    providedIn: 'root'
})
export class LandingSettingsFormService {

    constructor(private formbuilder: UntypedFormBuilder) {
    }

    initializeForm(landingSettings: LandingSettings): UntypedFormGroup {
        return this.formbuilder.group({
            idCtrl: new UntypedFormControl(landingSettings.id),
            primaryColorCtrl: new UntypedFormControl(landingSettings.primaryColor),
            secondaryColorCtrl: new UntypedFormControl(landingSettings.secondaryColor),
            fontCtrl: new UntypedFormControl(landingSettings.primaryFont),
            buttonsColorCtrl: new UntypedFormControl(landingSettings.buttonsColor),
            buttonsTextColorCtrl: new UntypedFormControl(landingSettings.buttonsTextColor)
        });
    }
}
