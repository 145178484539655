import { Component, Output, EventEmitter, Input } from '@angular/core';
import { AuthService, Member, MemberType, ReferencedEntityType } from 'projects/services/src/public-api';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'pt-audit-table-header',
    templateUrl: './audit-table-header.component.html'
})
export class AuditTableHeaderComponent {

    MemberType = MemberType;

    @Input() action: string;
    @Input() auditEntityType: ReferencedEntityType;
    @Input() memberId: string;
    @Input() formGroup: UntypedFormGroup;

    @Output() activeListChanged: EventEmitter<{memberId: string, action: string, auditEntityType: ReferencedEntityType}> =
        new EventEmitter<{memberId: string, action: string, auditEntityType: ReferencedEntityType}>();

    constructor(public authService: AuthService) {}

    changedStatus() {
        this.refreshList(this.memberId, this.action, this.auditEntityType);
    }

    refreshList(memberId: string, action: string, auditEntityType: ReferencedEntityType) {
        this.activeListChanged.emit({
            memberId,
            action,
            auditEntityType
        });
    }

    memberSelected(member: Member) {
        if (member && member.id) {
            this.memberId = member.id;
        } else {
            this.memberId = '';
        }
        this.changedStatus();
    }
}
