import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TableUtils } from 'projects/components/src/lib/table-utils.service';
import { AuthService, Member, MemberAccount, MemberAccountType, MemberFunction, MemberStatus, MemberType } from 'projects/services/src/public-api';
import { AddMerchantModalComponent } from 'projects/pt/src/app/components';

@Component({
    selector: 'pt-member-management',
    templateUrl: './member-management.component.html'
})
export class MemberManagementComponent implements OnInit {

    readonly PARTNER_TABS = ['connected_members', 'partner_consumers'];

    memberId = '';
    memberAccountId = '';
    activeTab: string = 'members';
    changeTrigger = 1;
    placeholder = 'Search Members';
    memberTypes: MemberType[] = [];
    memberAccountType: MemberAccountType = MemberAccountType.BUSINESS;
    statuses: MemberStatus[];
    formGroup: UntypedFormGroup;
    connectedMembers = false;

    constructor(private formBuilder: UntypedFormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                public authService: AuthService,
                private cdr: ChangeDetectorRef,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            memberCtrl: new UntypedFormControl(),
            memberAccountCtrl: new UntypedFormControl()
        });
        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['memberId'] || queryParams['memberAccountId'] || queryParams['_activeTab']) {
            this.memberId = queryParams['memberId'] || '';
            this.memberAccountId = queryParams['memberAccountId'] || '';
            this.activeTab = queryParams['_activeTab'] || 'members';
            this.formGroup.controls['memberCtrl'].setValue(this.memberId);
            this.formGroup.controls['memberAccountCtrl'].setValue(this.memberAccountId);
        } else {
            this.updateQueryParams(true);
        }
        this.route.queryParams.subscribe((params) => {
            const activeTab = params['_activeTab'] || 'members';
            if (activeTab === 'merchants' && this.activeTab !== 'merchants' ||
                activeTab !== 'merchants' && this.activeTab === 'merchants') {
                TableUtils.clearSubscriptions();
            }
            this.memberId = params['memberId'] || '';
            this.memberAccountId = params['memberAccountId'] || '';
            this.activeTab = activeTab;
            this.formGroup.controls['memberCtrl'].setValue(this.memberId);
            this.formGroup.controls['memberAccountCtrl'].setValue(this.memberAccountId);
            this.updateMemberTypeAndStatus(this.activeTab);
        });
        this.onTabChange(this.activeTab, true);
    }

    onTabChange(activeTab: string, keepMemberId?: boolean) {
        if (this.activeTab === activeTab) {
            return;
        }
        if ((this.PARTNER_TABS.includes(activeTab) && !this.authService.hasMemberFunction(MemberFunction.PARTNER)) ||
            (!this.PARTNER_TABS.includes(activeTab) && !this.authService.isAuthorityOrReviewer())) {
            this.router.navigate(['/dashboard']);
        } else {
            this.cdr.detach();
            if (activeTab === 'merchants' && this.activeTab !== 'merchants' ||
                activeTab !== 'merchants' && this.activeTab === 'merchants') {
                TableUtils.clearSubscriptions();
            }
            this.activeTab = activeTab;
            this.updateMemberTypeAndStatus(activeTab);
            if (!keepMemberId) {
                this.formGroup.controls['memberCtrl'].setValue('');
                this.formGroup.controls['memberAccountCtrl'].setValue('');
                this.memberId = '';
                this.memberAccountId = '';
            }
            this.cdr.reattach();
        }
        this.updateQueryParams(false);
    }

    memberSelected(member: Member) {
        this.memberAccountId = '';
        if (member.id) {
            this.memberId = member.id;
        } else {
            this.memberId = '';
        }
        this.updateQueryParams(false);
    }

    connectedMemberSelected(memberAccount: MemberAccount) {
        if (memberAccount.id) {
            this.memberAccountId = memberAccount.id;
            this.memberId = memberAccount.memberId;
        } else {
            this.memberAccountId = '';
            this.memberId = '';
        }
        this.updateQueryParams(false);
    }

    updateMemberTypeAndStatus(activeTab: string) {
        this.connectedMembers = false;
        if (activeTab === 'members') {
            this.memberTypes = [MemberType.BUSINESS];
            this.statuses = [MemberStatus.ACTIVE, MemberStatus.DISABLED];
            this.placeholder = 'Search Members';
        } else if (activeTab === 'consumers') {
            this.memberTypes = [MemberType.CONSUMER];
            this.statuses = [MemberStatus.ACTIVE, MemberStatus.DISABLED, MemberStatus.BLOCKED, MemberStatus.FROZEN];
            this.placeholder = 'Search Consumers';
        } else if (activeTab === 'merchants') {
            this.memberTypes = [MemberType.BUSINESS_MERCHANT, MemberType.INDIVIDUAL_MERCHANT];
            this.statuses = [MemberStatus.ACTIVE, MemberStatus.UNDER_REVIEW, MemberStatus.DISABLED];
            this.placeholder = 'Search Merchants';
        } else if (activeTab === 'under_review') {
            this.memberTypes = [MemberType.BUSINESS, MemberType.CONSUMER];
            this.statuses = [MemberStatus.UNDER_REVIEW];
            this.placeholder = 'Search Applicants Under Review';
        } else if (activeTab === 'applied') {
            this.memberTypes = [MemberType.BUSINESS, MemberType.CONSUMER];
            this.statuses = [MemberStatus.APPLIED, MemberStatus.PENDING];
            this.placeholder = 'Search Applications in Progress';
        } else if (activeTab === 'fee_accounts') {
            this.memberTypes = [MemberType.FEE_ACCOUNT];
            this.statuses = [MemberStatus.ACTIVE];
            this.placeholder = 'Search Fee Account';
        } else if (activeTab === 'connected_members') {
            this.connectedMembers = true;
            this.memberAccountType = MemberAccountType.BUSINESS;
            this.placeholder = 'Search Connected Members';
        } else if (activeTab === 'partner_consumers') {
            this.connectedMembers = true;
            this.memberAccountType = MemberAccountType.CONSUMER;
            this.placeholder = 'Search Consumers';
        }
    }

    onRequestNewMerchant() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'full-modal';
        dialogConfig.data = {
            shared: true
        };
        const dialogRef = this.dialog.open(AddMerchantModalComponent, dialogConfig);

        dialogRef?.afterClosed().subscribe((refresh: boolean) => {
            if (refresh) {
                this.changeTrigger = Math.random();
            }
        });
    }

    updateQueryParams(replace: boolean) {
        const sort = this.activeTab === 'merchants' ? 'merchantMemberAccount.member.sortName' : 'sortName';
        const queryParams = { memberId: this.memberId || null, memberAccountId: this.memberAccountId || null, _activeTab: this.activeTab || null, page: 1, sort, dir: 'asc' };
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }

}
