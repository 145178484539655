import { Component, OnInit } from '@angular/core';
import { AuthService, Configuration, Member, MemberService } from 'projects/services/src/public-api';
import { TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'pt-authority-setting',
    templateUrl: './authority-setting.component.html'
})
export class AuthoritySettingComponent implements OnInit {

    activeTab = 'authority_users';
    member: Member;

    constructor(private memberService: MemberService,
        public authService: AuthService,
        private route: ActivatedRoute,
        private router: Router) {
    }

    ngOnInit() {
        this.memberService.loadMember(this.authService.getProfile().memberId).subscribe((member: Member) => {
            this.member = member;
        });

        const queryParams = this.route.snapshot.queryParams;
        if (queryParams['_activeTab']) {
            this.activeTab = queryParams['_activeTab'];
        } else {
            this.activeTab = 'authority_users';
            this.updateQueryParams(true);
        }

        this.route.queryParams.subscribe((params) => {
            this.activeTab = params['_activeTab'] || 'authority_users';
        });
    }

    changedTab(tab: string) {
        if (this.activeTab === tab) {
            return;
        }
        this.activeTab = tab;
        this.updateQueryParams(false);
    }

    getTabClass(tabOption: string) {
        if (this.activeTab === tabOption) {
            return 'tab-option-selected';
        } else {
            return 'tab-option';
        }
    }

    smartContractsEnabled() {
        return Configuration.getConfig() && Configuration.getConfig().smartContractsEnabled;
    }

    updateQueryParams(replace: boolean) {
        const queryParams = { _activeTab: this.activeTab, page: 1, num: 100 };
        TableUtils.updateQueryParams(this.route, this.router, queryParams, replace);
    }

}
