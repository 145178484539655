import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '../models/user';
import { CustomValidators } from '../validators/custom.validators';
import { NewUserRequest } from '../models/new-user-request';
import { Role } from '../models/profile';

@Injectable({
    providedIn: 'root'
})
export class UserFormService {

    constructor(private formbuilder: UntypedFormBuilder) { }

    initializeForm(user: User): UntypedFormGroup {
        const form = this.formbuilder.group({
            idCtrl: new UntypedFormControl(),
            createdCtrl: new UntypedFormControl(),
            updatedCtrl: new UntypedFormControl(),

            professionalTitleCtrl: new UntypedFormControl('', [CustomValidators.noLeadingOrTrailingWhitespace]),
            employerCtrl: new UntypedFormControl('', [CustomValidators.noLeadingOrTrailingWhitespace]),
            firstNameCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.personName]),
            middleNameCtrl: new UntypedFormControl(null, [CustomValidators.middleName]),
            lastNameCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.personName]),
            otherSurnameOrAliasCtrl: new UntypedFormControl('', [CustomValidators.personName]),
            sexCtrl: new UntypedFormControl(),
            usCitizenCtrl: new UntypedFormControl(true),
            citizenshipCtrl: new UntypedFormControl(),

            passportCtrl: new UntypedFormControl(),
            passportExpDateCtrl: new UntypedFormControl(),
            emailCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.email]),
            homePhoneCtrl: new UntypedFormControl(null, [CustomValidators.phone]),
            mobilePhoneCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.phone]),
            officePhoneCtrl: new UntypedFormControl(null, [CustomValidators.phone]),
            birthDateCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.birthDate]),
            statusCtrl: new UntypedFormControl(),

            taxIdCtrl: new UntypedFormControl('', [Validators.required, Validators.pattern('^([0-9]{9})$')]),
            accountRoleCtrl: new UntypedFormControl()
        });
        if (user) {
            form.patchValue({
                idCtrl: user.id,
                createdCtrl: user.created,
                updatedCtrl: user.updated,

                professionalTitleCtrl: user.professionalTitle || '',
                employerCtrl: user.employer || '',
                firstNameCtrl: user.firstName,
                middleNameCtrl: user.middleName,
                lastNameCtrl: user.lastName,
                otherSurnameOrAliasCtrl: user.otherSurnameOrAlias,
                sexCtrl: user.sex,
                usCitizenCtrl: user.usCitizen,
                citizenshipCtrl: user.nationality,

                passportCtrl: user.passportNumber,
                passportExpDateCtrl: user.passportExpirationDate,
                emailCtrl: user.email,
                homePhoneCtrl: user.homePhone,
                mobilePhoneCtrl: user.mobilePhone,
                officePhoneCtrl: user.officePhone,
                birthDateCtrl: user.birthDate,
                statusCtrl: user.status,

                taxIdCtrl: user.taxId,
                accountRoleCtrl: user.accountRole

            });
            if (!user.usCitizen) {
                form.controls['citizenshipCtrl'].setValidators([Validators.required]);
                form.controls['passportCtrl'].setValidators([Validators.required]);
                form.controls['passportExpDateCtrl'].setValidators([Validators.required]);
            }
        }
        return form;
    }

    getUser(form: UntypedFormGroup): User {
        const user = new User();
        user.id = form.controls['idCtrl'].value;
        user.created = form.controls['createdCtrl'].value;
        user.updated = form.controls['updatedCtrl'].value;

        user.professionalTitle = form.controls['professionalTitleCtrl'].value;
        user.employer = form.controls['employerCtrl'].value;
        user.firstName = form.controls['firstNameCtrl'].value;
        user.middleName = form.controls['middleNameCtrl'].value;
        user.lastName = form.controls['lastNameCtrl'].value;
        user.otherSurnameOrAlias = form.controls['otherSurnameOrAliasCtrl'].value;
        user.sex = form.controls['sexCtrl'].value;
        user.usCitizen = form.controls['usCitizenCtrl'].value;
        user.nationality = form.controls['citizenshipCtrl'].value;

        user.passportNumber = form.controls['passportCtrl'].value;
        if (form.controls['passportExpDateCtrl']) {
            user.passportExpirationDate = form.controls['passportExpDateCtrl'].value;
        }
        user.email = form.controls['emailCtrl'].value;
        user.homePhone = form.controls['homePhoneCtrl'].value;
        user.mobilePhone = form.controls['mobilePhoneCtrl'].value;
        user.officePhone = form.controls['officePhoneCtrl'].value;
        user.birthDate = form.controls['birthDateCtrl'].value;
        user.status = form.controls['statusCtrl'].value;

        user.taxId = form.controls['taxIdCtrl'].value;
        return user;
    }

    newUserForm(memberId: string, memberAccountId: string): UntypedFormGroup {
        return this.formbuilder.group({
            memberIdCtrl: new UntypedFormControl(memberId, [Validators.required]),
            memberAccountIdCtrl: new UntypedFormControl(memberAccountId),
            userIdCtrl: new UntypedFormControl(),
            emailCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.email, Validators.maxLength(64)]),
            firstNameCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.personName, Validators.maxLength(32)]),
            middleNameCtrl: new UntypedFormControl('', [CustomValidators.middleName, Validators.maxLength(32)]),
            lastNameCtrl: new UntypedFormControl('', [Validators.required,  CustomValidators.personName, Validators.maxLength(32)]),
            titleCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(64), CustomValidators.noLeadingOrTrailingWhitespace]),
            officePhoneCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.phone]),
            mobilePhoneCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.phone]),
            mfaCtrl: new UntypedFormControl('')
        });
    }

    getNewUserRequest(form: UntypedFormGroup, role: Role): NewUserRequest {
        const newUserRequest = new NewUserRequest();
        newUserRequest.role = role;
        newUserRequest.memberId = form.controls['memberIdCtrl'].value;
        newUserRequest.memberAccountId = form.controls['memberAccountIdCtrl'].value;
        newUserRequest.userId = form.controls['userIdCtrl'].value;

        newUserRequest.firstName = form.controls['firstNameCtrl'].value;
        newUserRequest.middleName = form.controls['middleNameCtrl'].value;
        newUserRequest.lastName = form.controls['lastNameCtrl'].value;

        newUserRequest.email = form.controls['emailCtrl'].value;
        newUserRequest.jobTitle = form.controls['titleCtrl'].value;
        newUserRequest.officePhone = form.controls['officePhoneCtrl'].value;
        newUserRequest.mobilePhone = form.controls['mobilePhoneCtrl'].value;
        newUserRequest.mfaType = form.controls['mfaCtrl'].value;

        return newUserRequest;
    }
}
