import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UIUtils {

    static scrollDashboardToTop() {
        const contentContainer = document.querySelector('#workspace');
        if (contentContainer) {
            contentContainer.scrollTo(0, 0);
        }
    }

    static scrollToTop(contentContainer: Element) {
        if (contentContainer) {
            contentContainer.scrollTop = 0;
        }
    }

}
