<div class="horizontal-scroll mat-table-container">
    <div class="table mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="members"  matSort matSortDisableClear [matSortActive]="pageTracking.sort" [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="business_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="sortName"> {{ getNameHeader() }} </th>
            <td mat-cell *matCellDef="let element">
                <img *ngIf="element.memberType === MemberType.INDIVIDUAL_MERCHANT || element.memberType === MemberType.CONSUMER"
                     class="member-type-icon" src="assets/images/profile-icon.svg" title="Individual">
                <a (click)="memberDetails($event, element)" class="link" *ngIf="searchType === EntitySearchType.CONSUMER">
                    <b> {{ getConsumerName(element) }} </b>
                </a>
                <a (click)="memberDetails($event, element)" class="link" *ngIf="searchType !== EntitySearchType.CONSUMER">
                    <b> {{ element.name }} </b>
                    <br>
                    <b *ngIf="element.dbaName"> DBA: {{ element.dbaName }} </b>
                </a>
                <div>
                    <pc-business-address *ngIf="element.address" [address]="element.address">
                    </pc-business-address>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="contact_info">
            <th mat-header-cell *matHeaderCellDef> Contact Info </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element.email"><b>Email:</b> {{ element.email }}</div>
                <div *ngIf="element.phone"><b>Office Phone:</b> {{ element.phone | phone }} </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="functions">
            <th mat-header-cell *matHeaderCellDef class="centered" > Functions </th>
            <td mat-cell *matCellDef="let element" class="centered" >
                <div *ngIf="element.memberFunctions.length === 0">{{'MEMBER' | translate}}</div>
                <div *ngIf="element.memberFunctions.length > 0">
                    <div *ngFor="let memberFunction of element.memberFunctions">
                        <div>{{memberFunction | translate}}</div>
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="centered" mat-sort-header> Member Status </th>
            <td mat-cell *matCellDef="let element" class="centered">
                <pc-member-badge [parent]="element"></pc-member-badge>
                <div *ngIf="element?.jurisdiction">{{ Utils.getJurisdictionName(element.jurisdiction) }}</div>
                <div *ngIf="element.tier === RiskTier.TIER_1">Tier 1</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef class="centered" mat-sort-header> Created Date </th>
            <td mat-cell *matCellDef="let element" class="centered"> {{ element.created | date:'mediumDate' }} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.disabled]="row.status === MemberStatus.DISABLED"></tr>
    </table>
    <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"></mat-paginator>
</div>
