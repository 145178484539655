import { AfterViewInit, Component, Input, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'pc-tracking-counter-party-selector',
    templateUrl: './tracking-counter-party-selector.component.html',
    styleUrls: ['./tracking-counter-party-selector.component.scss']
})
export class TrackingCounterPartySelectorComponent implements OnInit, AfterViewInit {

    @Input() placeholder = '';
    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() activeTab: string;
    @Input() counterParties = [];
    @Input() class = '';

    @Output() counterPartySelected = new EventEmitter<string>();

    counterPartySelect: Subscription;
    counterPartiesAutoComplete: Observable<string[]> = null;
    autoCompleteControl = new UntypedFormControl('');
    searching = false;
    filteredCounterParties = [];

    @ViewChild('counterPartySelect', { read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;

    ngOnInit() {
        const formValue = this.formGroup.controls[this.formCtrl].value;
        this.formGroup.addControl('autoCompleteCtrl', this.autoCompleteControl);
        this.autoCompleteControl.setValue(formValue);
        this.counterPartiesAutoComplete = this.autoCompleteControl.valueChanges.pipe(
            startWith(''),
            distinctUntilChanged(),
            debounceTime(100),
            switchMap((value) => {
                this.searching = true;
                return this.lookup(value || '');
            })
        );
    }

    ngAfterViewInit() {
        this.subscribeToClosingActions();
    }

    subscribeToClosingActions() {
        if (this.counterPartySelect && !this.counterPartySelect.closed) {
            this.counterPartySelect.unsubscribe();
        }

        this.counterPartySelect = this.trigger.panelClosingActions.subscribe((e) => {
            if (!e || !e.source) {
                if (this.filteredCounterParties.length === 1) {
                    const value = this.filteredCounterParties[0];
                    this.autoCompleteControl.setValue(value);
                    this.valueChanged(value);
                } else {
                    const selected = this.filteredCounterParties
                        .map((option) => {
                            return option;
                        })
                        .find((option) => {
                            return option === this.autoCompleteControl.value;
                        });
                    if (!selected && this.selected()) {
                        this.formGroup.controls[this.formCtrl].markAsPristine();
                        this.autoCompleteControl.setValue('');
                        this.valueChanged('');
                    }
                }
            }
        }, () => {
            return this.subscribeToClosingActions();
        }, () => {
            return this.subscribeToClosingActions();
        });
    }

    lookup(value: string): Observable<string[]> {
        const filterValue = value.toLowerCase();
        this.searching = false;
        if (filterValue) {
            this.filteredCounterParties = this.counterParties.filter((option) => {
                return option.toLowerCase().includes(filterValue);
            });
            return of(this.filteredCounterParties);
        } else {
            this.filteredCounterParties = this.counterParties;
            return of(this.filteredCounterParties);
        }
    }

    counterPartyChanged(event: MatAutocompleteSelectedEvent) {
        this.valueChanged(event.option.value);
    }

    valueChanged(value: any) {
        this.formGroup.controls[this.formCtrl].markAsDirty();
        if (value) {
            this.formGroup.controls[this.formCtrl].setValue(value);
            this.counterPartySelected.emit(value);
        } else {
            this.formGroup.controls[this.formCtrl].setValue('');
            this.counterPartySelected.emit('');
        }
    }

    onInputChanged(event: any) {
        if (event.keyCode === 220) {
            event.preventDefault();
            return;
        }
    }

    selected() {
        return this.formGroup.controls[this.formCtrl].value;
    }

    onIconClick(event: any) {
        if (this.selected()) {
            this.trigger.closePanel();
            this.formGroup.controls[this.formCtrl].setValue('');
            this.autoCompleteControl.setValue('');
            this.counterPartySelected.emit('');
        }
        event.stopPropagation();
        this.trigger.openPanel();
    }
}
