import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AuthService, CommentService, PagedResponse, Comment, CommentType, MemberStatus } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-notes-table',
    templateUrl: './notes-table.component.html',
    styleUrls: ['./notes-table.component.scss']
})
export class NotesTableComponent implements OnInit, OnChanges {

    CommentType = CommentType;

    @Input() memberId: string;
    @Input() memberStatus: MemberStatus;
    @Input() changeTrigger: number;
    @Input() type: CommentType;

    @Output() notesDetailChange: EventEmitter<any> = new EventEmitter<any>();

    noteGroup: Comment[];
    isLoadingResults = true;
    displayedColumns = ['comment', 'author', 'created'];

    constructor(private authService: AuthService,
                private commentService: CommentService) {
    }

    ngOnInit() {
        this.refresh();
        if (this.isAuthority() && this.type === CommentType.QUESTION && this.memberStatus !== MemberStatus.ACTIVE) {
            this.displayedColumns = ['comment', 'author', 'created', 'completed'];
        }
    }

    ngOnChanges() {
        this.refresh();
    }

    refresh() {
        this.noteGroup = [];
        this.commentService.loadComments(this.memberId, this.type).subscribe((response: PagedResponse<Comment>) => {
            this.noteGroup = response.content;
            this.isLoadingResults = false;
            if (!this.isAuthority() && !this.isAuthorityReviewer()) {
                this.noteGroup = this.noteGroup.filter((note) => {
                    return !note.closed;
                });
            }
        });
    }

    isAuthority() {
        return this.authService.isAuthority();
    }

    isAuthorityReviewer() {
        return this.authService.isAuthorityOrReviewer();
    }

    onEdit(note: Comment) {
        note.closed = !note.closed;
        this.commentService.saveComment(note).subscribe(() => {
            this.notesDetailChange.emit();
        });
    }
}
