<div class="col-12">
    <div class="row mb-3">
        <div class="col-4">
            <span class="form-title">Lending Management</span>
        </div>
        <div *ngIf="scenarios? scenarios.length<10: false" class="col-8">
            <button class="blue-button pull-right" (click)="openAddScenarioWindow()">+ Add Scenario</button>
        </div>
    </div>
    <div class="col-sm-12 mt-3 mb-3 horizontal-scroll mat-table-container">
        <div class="mat-table-spinner" *ngIf="isLoadingResults">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table [dataSource]="scenarios">
            <ng-container matColumnDef="scenario">
                <th mat-header-cell *matHeaderCellDef> <b>Scenario</b></th>
                <td mat-cell *matCellDef="let element">
                    {{ element.title }}
                </td>
            </ng-container>
            <ng-container matColumnDef="kpi_list">
                <th  mat-header-cell *matHeaderCellDef> <b>KPI List</b> </th>
                <td class="text-nowrap" mat-cell *matCellDef="let element">
                    {{ arrToString(element.kpis) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th class="centered" mat-header-cell *matHeaderCellDef><b> Status </b> </th>
                <td class="centered" mat-cell *matCellDef="let element">
                   <pc-scenario-badge [parent]="element"></pc-scenario-badge>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th class="centered" mat-header-cell *matHeaderCellDef>  <b> Action </b> </th>
                <td class="centered text-nowrap" mat-cell *matCellDef="let element">
                    <span>
                        <i class="fa fa-2x" [class.fa-toggle-on]="element.active" [class.fa-toggle-off]="!element.active" (click)="onUpdateScenario(element)"></i>
                        <i *ngIf="element.active" class="fa fa-pencil fa-2x" (click)="onEditScenario(element.id)"></i>
                        <i class="fa fa-times fa-2x" (click)="onDeleteScenario(element.id)"></i>
                    </span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
