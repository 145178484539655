<div class="dashboard-list">
    <div class="row">
        <div class="col-12">
            <div class="dashboard-info-icon">
                <img class="dashboard-icon" src="assets/images/dollars-money-bag-on-a-hand_blue.png" alt="">
                <a [routerLink]="['/lending/list']"
                   [routerLinkActive]="['router-link-active']">Lending Activity</a>
            </div>
        </div>
    </div>
    <hr class="separator-thick">
    <div class="row mt-3">
        <div class="col-sm-12 mb-3 horizontal-scroll mat-table-container">
            <table #lendingDetailsTable mat-table [dataSource]="lendingDetails">
                <ng-container matColumnDef="label">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                       {{ element.label }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="numberofmembers">
                    <th class="centered" mat-header-cell *matHeaderCellDef>Number of Members</th>
                    <td class="centered" mat-cell *matCellDef="let element">
                        {{ checkIfActivityEmpty(element?.numberOfMembers) }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="dollarsvaluepotential">
                    <th class="centered" mat-header-cell *matHeaderCellDef>Dollar Value Potential</th>
                    <td class="centered" mat-cell *matCellDef="let element">
                        <span class="table-amount">
                            {{ checkIfActivityEmpty(element?.dollarsValuePotential) | currency}}
                        </span>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>
