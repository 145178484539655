import { Component, Input } from '@angular/core';
import { Member, MemberStatus } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-member-message',
    templateUrl: './member-message.component.html'
})
export class MemberMessageComponent {

    @Input() member: Member;

    constructor() {}

    isVisible() {
        if (!this.member) {
            return false;
        }

        switch (this.member.status) {
            case MemberStatus.APPLIED:
            case MemberStatus.UNDER_REVIEW:
                return false;

            default:
                return true;
        }
    }
}
