import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { WorkflowService, Task } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-sc-preview-base-template',
    templateUrl: './preview-base-template.component.html'
})
export class PreviewBaseTemplateComponent implements OnInit {

    @Input() task: Task;

    @Output() taskChanged: EventEmitter<Task> = new EventEmitter<Task>();
    @Output() shareTemplate: EventEmitter<Task> = new EventEmitter<Task>();

    constructor(private workflowService: WorkflowService) {}

    ngOnInit() {
        if (this.workflowService.getCurrentTask()) {
            this.task = this.workflowService.getCurrentTask();
            this.taskChanged.emit(this.task);
        }
    }

    shareClicked() {
        this.shareTemplate.emit(this.task);
    }
}
