<form *ngIf="taxForm && financialDetailsForm" [formGroup]="taxForm">
    <div class="row mb-3">
        <div class="col-12">
            <span class="form-title">Financial</span>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <span class="subtitle">Tax Information</span>
        </div>
        <div class="col-lg-4">
            <pc-form-text-input [required]="true" [label]="'EIN'" [placeholder]="'numbers only (no hyphens)'" [formCtrl]="'einCtrl'" [formGroup]="taxForm"
                [colSize]="6" [restrictToDigits]="true">
            </pc-form-text-input>
        </div>
        <div class="col-lg-4">
            <pc-form-text-input [label]="'State Tax ID'" [placeholder]="'State Tax ID'" [formCtrl]="'stateTaxIdCtrl'"
                [formGroup]="taxForm" [colSize]="6">
            </pc-form-text-input>
        </div>
        <div class="col-lg-4">
            <pc-form-text-input [label]="'Tax&nbsp;Permit&nbsp;#'" [placeholder]="'Tax Permit #'" [formCtrl]="'taxPermitNoCtrl'"
                [formGroup]="taxForm" [colSize]="6">
            </pc-form-text-input>
        </div>
    </div>
    <div class="row mb-3" [formGroup]="financialDetailsForm">
        <div class="col-lg-7">
            <span class="subtitle">Financial Details</span>
            <pc-form-amount-input [required]="true" [label]="'Projected Total Annual Revenue'" [formCtrl]="'projectedAnnualRevenueCtrl'" [formGroup]="financialDetailsForm"
                [placeholder]="''" [includeCents]="false">
            </pc-form-amount-input>
            <pc-percentage-input [required]="true" [label]="'Revenue % from Cannabis (Plant Touching) Sales'"
                [formCtrl]="'percentOfRevenueFromCannabisCtrl'" [formGroup]="financialDetailsForm">
            </pc-percentage-input>
            <pc-percentage-input [required]="true" [label]="'Revenue % from Cannabis-Related Businesses'"
                [formCtrl]="'percentOfRevenueFromCannabisPartnersCtrl'" [formGroup]="financialDetailsForm">
            </pc-percentage-input>
            <pc-percentage-input [required]="true" [label]="'Revenue % in Cash'"
                [formCtrl]="'percentOfRevenueFromCashCtrl'" [formGroup]="financialDetailsForm">
            </pc-percentage-input>
            <pc-form-amount-input [required]="true" [label]="'Planned Initial Deposit Amount'" [formCtrl]="'plannedInitialDepositAmountCtrl'" [formGroup]="financialDetailsForm"
                [placeholder]="''" [includeCents]="false" (input)="onInitialDepositChange()">
            </pc-form-amount-input>
            <pc-text-area [label]="'Source of Initial Deposit'" [placeholder]="'Source of Initial Deposit'"
                [formCtrl]="'initialDepositSourceCtrl'" [formGroup]="financialDetailsForm" (input)="onInitialDepositChange()">
            </pc-text-area>
            <span class="subtitle mt-3">Estimate of Your Monthly Confia Account Activity:</span>
            <pc-form-amount-input [required]="true" [label]="'Total Amount of Outgoing Funds (e.g. payments, withdrawals)'" [formCtrl]="'expectedMonthlyPaymentsCtrl'" [formGroup]="financialDetailsForm"
                [placeholder]="'payments made from Confia'" [includeCents]="false">
            </pc-form-amount-input>
            <pc-form-amount-input [required]="true" [label]="'Total Amount of Incoming Funds (e.g. deposits, incoming wires)'" [formCtrl]="'expectedMonthlyDepositsCtrl'" [formGroup]="financialDetailsForm"
                [placeholder]="'all deposits into Confia'" [includeCents]="false">
            </pc-form-amount-input>
            <pc-form-amount-input [required]="true" [label]="'Total Amount of Cash Deposited'" [formCtrl]="'expectedMonthlyCashDepositCtrl'" [formGroup]="financialDetailsForm"
                [placeholder]="'cash deposits into Confia'" [includeCents]="false">
            </pc-form-amount-input>
            <pc-form-text-input [required]="true" [label]="'Number of Incoming Transactions'" [formCtrl]="'expectedMonthlyNumberOfReceiptsCtrl'" [formGroup]="financialDetailsForm"
                [placeholder]="'# of payments received and deposits into Confia'" [colSize]="3">
            </pc-form-text-input>
            <pc-form-text-input [label]="'Number of Outgoing Transactions'" [formCtrl]="'expectedMonthlyNumberOfPaymentsCtrl'" [formGroup]="financialDetailsForm"
                [placeholder]="'# of payments and withdrawals from Confia'" [colSize]="3">
            </pc-form-text-input>
        </div>
        <div class="col-lg-5">
            <span class="subtitle">Cannabis Business Activities</span>
            <div class="business-activities">
                <pc-simple-checkbox *ngFor="let businessActivity of getBusinessActivities(); index as i;"
                    [label]="businessActivity" [formCtrl]="'businessActivitiesCtrl'" [formCtrlIndex]="i" [formGroup]="financialDetailsForm" [invert]="true" [class]="'no-margin-bottom'" (changed)="updateValidity()">
                </pc-simple-checkbox>
            </div>
        </div>
    </div>
</form>

<div *ngIf="!readOnly" class="button-panel pull-right">
    <pc-submit-button [enabled]="isFinancialDetailsValid" [submit]="onSave" [text]="'Save'"></pc-submit-button>
    <pc-submit-button  [submit]="onNavigate" [isSubmitButton]="true" [text]="'Next'" [rightIcon]="'arrow-right'" *ngIf="task"></pc-submit-button>
</div>
