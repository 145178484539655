<div class="inlineExpanded" *ngIf="receipt">
    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-4 title-bold-font">
                    Source
                </div>
                <div class="col-8">
                    {{ receipt.source }}
                </div>
            </div>
            <div class="row">
                <div class="col-4 title-bold-font">
                    Tracking Number
                </div>
                <div class="col-8">
                    {{ receipt.trackingNumber }}
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-4 title-bold-font">
                    Total Amount
                </div>
                <div class="col-8">
                    {{ receipt.totalAmount ? (receipt.totalAmount | currency) : '0' }}
                </div>
            </div>
            <div class="row">
                <div class="col-4 title-bold-font">
                    Transaction Date
                </div>
                <div class="col-8">
                    {{ receipt.txnDate | date:'mediumDate' }}
                </div>
            </div>
            <div class="row">
                <div class="col-4 title-bold-font">
                    Created
                </div>
                <div class="col-8">
                    {{ receipt.created | date:'mediumDate' }}
                </div>
            </div>
            <div class="row">
                <div class="col-4 title-bold-font">
                    Updated
                </div>
                <div class="col-8">
                    {{ receipt.updated | date:'mediumDate' }}
                </div>
            </div>
        </div>
    </div>
    <pt-member-metrc-receipt-line-item-row [lineItems]="receipt.lineItems"></pt-member-metrc-receipt-line-item-row>
</div>