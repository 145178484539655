<fieldset class="form-group" *ngIf="formCtrl">
  <div class="row">
    <div class="col-12" [formGroup]="formGroup">
        <div class="form-input-editable">
            <div class="pull-left">
            <label class="form-label">{{ label | translate }}</label>
            </div>
        </div>
        <input #inputField class="form-control form-input-editable" [placeholder]="placeholder" (change)="onChange()"
               [formControlName]="formCtrl" [required]="required" type="number" (keydown)="onInputChanged($event)">
    </div>
  </div>
</fieldset>
