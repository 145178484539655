import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {CustomValidators} from '../validators/custom.validators';

@Injectable({
    providedIn: 'root'
})
export class RequestAccessFormService {

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    initializeForm(): UntypedFormGroup {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 7);

        return this.formBuilder.group({
            idCtrl: new UntypedFormControl(),
            lenderIdCtrl: new UntypedFormControl(),
            memberIdCtrl: new UntypedFormControl(),
            startDateCtrl: new UntypedFormControl('', [Validators.required, CustomValidators.fromToDate('startDateCtrl', 'endDateCtrl')] ),
            endDateCtrl: new UntypedFormControl(endDate, [Validators.required, CustomValidators.fromToDate('startDateCtrl', 'endDateCtrl')]),
            noteCtrl: new UntypedFormControl('', Validators.max(512))
        });
    }
}
