export class FincenInvestigation {
    id: string;
    created: Date;
    submissionDate: Date;

    personsUploadDocumentId: string;
    personsUploadDocumentName: string;
    personsStatus: FincenInvestigationStatus;
    personsResultsDocumentId: string;
    personsResultsDocumentName: string;

    companiesUploadDocumentId: string;
    companiesUploadDocumentName: string;
    companiesStatus: FincenInvestigationStatus;
    companiesResultsDocumentId: string;
    companiesResultsDocumentName: string;
}

export enum FincenInvestigationStatus {
    PENDING = 'PENDING',
    NO_MATCHES = 'NO_MATCHES',
    MATCHES = 'MATCHES',
    FAILED = 'FAILED',
    UNPARSEABLE = 'UNPARSEABLE'
}
