import { Component, Inject, OnInit } from '@angular/core';
import { ConnectedMember, ConnectedMemberService, ConnectedMemberStatus, MemberAccount, PagedResponse } from 'projects/services/src/public-api';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';
import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'pt-add-connected-member-modal',
    templateUrl: './add-connected-member-modal.component.html'
})
export class AddConnectedMemberModalComponent extends BaseModalComponent<AddConnectedMemberModalComponent> implements OnInit {

    partnerId: string;
    partnerName: string;
    memberId: string;
    memberAccounts: MemberAccount[];
    selectedMemberAccount: MemberAccount;

    memberAccountForm: UntypedFormGroup;

    constructor(private connectedMemberService: ConnectedMemberService,
                private formbuilder: UntypedFormBuilder,
                private notifier: NotificationService,
                dialogRef: MatDialogRef<AddConnectedMemberModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.partnerId = data.partnerId;
        this.partnerName = data.partnerName;
        this.memberId = data.memberId;
        this.memberAccounts = data.memberAccounts;
    }

    ngOnInit() {
        this.onSubmit = this.onSubmit.bind(this);
        this.connectedMemberFormValid = this.connectedMemberFormValid.bind(this);
        this.connectedMemberService.getConnectedMembersForPartnerAndMember(this.partnerId, this.memberId).subscribe((connectedMembers: PagedResponse<ConnectedMember>) => {
            this.memberAccounts = this.memberAccounts.filter((memberAccount: MemberAccount) => {
                return !connectedMembers.content.find((connectedMember: ConnectedMember) => {
                    return connectedMember.memberAccountId === memberAccount.id;
                });
            });
            if (this.memberAccounts.length === 1) {
                this.selectedMemberAccount = this.memberAccounts[0];
            }
            this.memberAccountForm = this.formbuilder.group({
                memberAccountCtrl: new UntypedFormControl(this.selectedMemberAccount?.accountName || '', Validators.required)
            });
        });
    }

    close(refresh?: boolean) {
        super.close(refresh);
    }

    onSubmit(_reset: any) {
        this.connectedMemberService.addConnectedMemberToPartner(this.partnerId, this.memberId, this.selectedMemberAccount.id, ConnectedMemberStatus.ACTIVE).subscribe(() => {
            this.close(true);
            this.notifier.showSuccess('Success! <b>' + this.partnerName + '</b> has been <b>added</b> as a partner.<br> You will now see them under the My Partners table.');
        });
    }

    selectMemberAccount(memberAccount: MemberAccount) {
        if (memberAccount.id) {
            this.selectedMemberAccount = memberAccount;
        } else {
            this.selectedMemberAccount = null;
        }
    }

    connectedMemberFormValid() {
        return this.memberAccountForm && !this.memberAccountForm.invalid;
    }

}
