import { NotificationService } from 'projects/pt/src/app/notifications/notification.service';
import { Component, ElementRef, Inject, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
    AuthorizationFormService, AuthService, BankingIntegrationProvider, CustomValidators, Member, MemberAccountAccess, MemberService, MemberStatus, MemberType, MfaType,
    Profile, QrCodeService, RegistrationService, Role, Signature, UserAccount, UserAccountService, UserMemberAccess, Utils
} from 'projects/services/src/public-api';
import { TextInputComponent } from 'projects/components/src/lib/input';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BaseModalComponent } from '../base-modal.component';

@Component({
    selector: 'pc-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent extends BaseModalComponent<LoginModalComponent> implements OnInit, OnChanges {

    MfaType = MfaType;

    loginData: any;
    loginForm: UntypedFormGroup;
    loginStage = 1;
    profile: Profile;
    error: any;
    redirectUrl: string;
    verificationError = false;
    mfaType: MfaType;
    totpUrl: URL;
    codeFailures = 0;
    membersForUser: Member[] = [];
    accountAccesses: MemberAccountAccess[] = [];
    selectedMember: '';
    title = 'Login';

    safariFix: boolean;
    isForgotPassword = false;

    @ViewChild('smsCodeCtrl') smsCodeField: TextInputComponent;
    @ViewChild('totpCodeCtrl') totpCodeField: TextInputComponent;
    @ViewChild('memberIdCtrl') memberField: ElementRef;
    @ViewChild('accountAccessesCtrl') accountAccessField: ElementRef;

    constructor(private route: ActivatedRoute,
                private authorizationFormService: AuthorizationFormService,
                private authService: AuthService,
                private userAccountService: UserAccountService,
                private qrCodeService: QrCodeService,
                private registrationService: RegistrationService,
                private notifier: NotificationService,
                private memberService: MemberService,
                private matDialog: MatDialog,
                dialogRef: MatDialogRef<LoginModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.loginData = data;
    }

    ngOnInit() {
        this.safariFix = Utils.isSafari();
        this.route.queryParams.subscribe((params: any) => {
            if (params['redirectUrl']) {
                this.redirectUrl = params['redirectUrl'];
            }
            // FIXME: CN-3880 - eventually we can remove the org param, and old emails will just need to select the member
            if (params['org'] || params['memberId']) {
                this.selectedMember = params['org'] || params['memberId'];
            }
        });

        this.verifyEmailPassword = this.verifyEmailPassword.bind(this);
        this.isValidLogin = this.isValidLogin.bind(this);
        this.enableTotpVerification = this.enableTotpVerification.bind(this);
        this.validateLoginCode = this.validateLoginCode.bind(this);
        this.isValidCode = this.isValidCode.bind(this);
        this.close = this.close.bind(this);
        this.cancel = this.cancel.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.isValidForgotPassword = this.isValidForgotPassword.bind(this);

        this.loginForm = this.authorizationFormService.loginUserForm();
        if (this.loginData) {
            this.loginForm.patchValue({
                emailCtrl: this.loginData.email,
                passwordCtrl: this.loginData.password,
                sessionCtrl: this.loginData.mfaData.session
            });
            if (this.loginData.mfaData.mfaType === MfaType.SMS) {
                this.mfaType = MfaType.SMS;
                this.loginStage = 3;
                this.ngOnChanges();
            } else if (this.loginData.mfaData.mfaType === MfaType.TOTP) {
                this.mfaType = MfaType.TOTP;
                if (this.loginData.mfaData.url) {
                    this.totpUrl = new URL(this.loginData.mfaData.url);
                    this.loginStage = 2;
                } else {
                    this.loginStage = 3;
                    this.ngOnChanges();
                }
            }
        }
    }

    ngOnChanges() {
        if (this.loginStage === 3) {
            setTimeout(() => {
                if (this.mfaType === MfaType.SMS) {
                    this.smsCodeField.focus();
                } else if (this.mfaType === MfaType.TOTP) {
                    this.totpCodeField.focus();
                }
            }, 500);
        } else if (this.loginStage === 4) {
            setTimeout(() => {
                if (this.accountAccesses.length > 0) {
                    this.accountAccessField.nativeElement.focus();
                } else {
                    this.memberField.nativeElement.focus();
                }
            }, 500);
        }
    }

    verifyEmailPassword(reset: any) {
        this.error = null;
        this.validate(reset);
    }

    validateLoginCode(reset: any) {
        this.error = null;
        this.validate(reset);
    }

    enableTotpVerification(_reset: any) {
        this.loginStage = 3;
        this.ngOnChanges();
    }

    getQrCode() {
        return {
            key: this.totpUrl.href,
            src: this.qrCodeService.createQrCodeFromUrl(this.totpUrl.href)
        };
    }

    validate(reset: any) {
        const authRequest = this.authorizationFormService.getAuthRequest(this.loginForm);
        this.authService.verifyEmailPassword(authRequest, (token: string) => {
            this.error = null;
            this.authService.setToken(token);
            this.authService.getAuthenticatedProfile().subscribe((profile: any) => {
                this.profile = profile;
                this.onUserValidated(reset);
            },
            () => {
                this.error = 'You have entered an invalid username or password.';
                reset();
            });
        }, (errorResponse: any) => {
            if (errorResponse.status === 429) {
                this.closeAndRedirectToLogin();
                throw errorResponse;
            } else if (errorResponse.status === 403) {
                const authResponse = errorResponse.error;
                if (authResponse.accountLocked) {
                    this.error = 'Account is locked.  Please contact support to unlock your account.';
                    this.verificationError = true;
                } else {
                    this.loginForm.patchValue({
                        sessionCtrl: authResponse.session
                    });
                    if (authResponse.mfaType === MfaType.SMS) {
                        this.mfaType = MfaType.SMS;
                        this.loginStage = 3;
                        this.ngOnChanges();
                    } else if (authResponse.mfaType === MfaType.TOTP) {
                        this.mfaType = MfaType.TOTP;
                        if (authResponse.url) {
                            this.totpUrl = new URL(authResponse.url);
                            this.loginStage = 2;
                        } else {
                            this.loginStage = 3;
                            this.ngOnChanges();
                        }
                    }
                }
            } else if (errorResponse.status === 400) {
                this.error = errorResponse.error;
                this.codeFailures++;
                if (this.codeFailures >= 3) {
                    this.error = 'Maximum tries exceeded.  Please try again later or contact support.';
                    this.verificationError = true;
                } else {
                    reset();
                }
            } else if (errorResponse.status === 401 || errorResponse.status === 403) {
                this.error = 'Invalid username or password.';
                reset();
            } else if (errorResponse.status === 500) {
                this.error = 'An unknown error occurred. Please contact support.';
                reset();
            } else if (errorResponse.status === 0) {
                this.error = 'Unable to contact login server.  Please try again later or contact support.';
                reset();
            } else {
                this.error = errorResponse.error;
                reset();
            }
        });
    }

    onUserValidated(reset: any) {
        if (this.selectedMember) {
            this.authService.updateAuthentication(this.selectedMember, false).subscribe((updatedProfile: Profile) => {
                this.initializeApplication(updatedProfile);
            });
            return;
        }

        this.membersForUser = [];
        this.userAccountService.getMembersAvailableForLogin(this.profile.userId).subscribe((members: Member[]) => {
            if (members.length === 0) {
                this.error = 'You have entered an invalid username or password.';
                this.authService.clear();
                reset();
            } else if (members.length > 1) {
                this.handleMultipleMembers(members);
            } else {
                const memberId = members[0].id;
                this.authService.updateAuthentication(memberId, false).subscribe((updatedProfile: Profile) => {
                    if (updatedProfile.memberType === MemberType.BUSINESS && updatedProfile.memberStatus !== MemberStatus.APPLIED) {
                        this.memberService.getMostRecentSignatureOfMember(memberId).subscribe((response: Signature) => {
                            if (updatedProfile.role !== Role.CORPORATE_ADMIN && updatedProfile.role !== Role.NEW_ADMIN_REGISTRANT && response.bankTerms !== BankingIntegrationProvider.NBCU) {
                                this.error = 'Our Terms of Service have changed.<p>Please have your corporate administrator sign in to accept the new Terms of Service to provide you access this account.</p>';
                                reset();
                            } else if (response.bankTerms !== BankingIntegrationProvider.NBCU && (updatedProfile.role === Role.CORPORATE_ADMIN || updatedProfile.role === Role.NEW_ADMIN_REGISTRANT)) {
                                this.handleTermsUpdate(updatedProfile);
                            } else {
                                this.profile = updatedProfile;
                                this.userAccountService.getUserAccountAccessForMemberAndUser(memberId, this.profile.userId).subscribe((userMemberAccess: UserMemberAccess) => {
                                    this.handleAccounts(userMemberAccess);
                                });
                            }
                        });
                    } else {
                        this.profile = updatedProfile;
                        this.userAccountService.getUserAccountAccessForMemberAndUser(memberId, this.profile.userId).subscribe((userMemberAccess: UserMemberAccess) => {
                            this.handleAccounts(userMemberAccess);
                        });
                    }
                });
            }
        });
    }

    handleMultipleMembers(members: Member[]) {
        for (const member of members) {
            if (member.memberType === MemberType.CONSUMER) {
                member.name = member.name + ' (Consumer)';
            }
        }
        this.membersForUser = members;
        this.loginStage = 4;
        this.ngOnChanges();
    }

    onAccountAccessSelected() {
        const retailLocationId = this.loginForm.controls['accountAccessCtrl'].value;
        if (retailLocationId !== 'MEMBER') {
            this.authService.selectRetailLocation(this.profile.memberId, retailLocationId).subscribe((updatedProfile: any) => {
                updatedProfile.multiMember = this.profile.multiMember;
                this.initializeApplication(updatedProfile);
            });
        } else {
            this.initializeApplication(this.profile);
        }
    }

    onBusinessSelected() {
        const memberId = this.loginForm.controls['memberIdCtrl'].value;
        this.authService.updateAuthentication(memberId, false).subscribe((updatedProfile: Profile) => {
            if (updatedProfile.memberType === MemberType.BUSINESS && updatedProfile.memberStatus !== MemberStatus.APPLIED) {
                this.memberService.getMostRecentSignatureOfMember(memberId).subscribe((response: Signature) => {
                    updatedProfile.multiMember = true;
                    if (updatedProfile.role !== Role.CORPORATE_ADMIN && updatedProfile.role !== Role.NEW_ADMIN_REGISTRANT && response.bankTerms !== BankingIntegrationProvider.NBCU) {
                        this.error = 'Our Terms of Service have changed.<p>Please have your corporate administrator sign in to accept the new Terms of Service to provide you access this account.</p>';
                    } else if (response.bankTerms !== BankingIntegrationProvider.NBCU && (updatedProfile.role === Role.CORPORATE_ADMIN || updatedProfile.role === Role.NEW_ADMIN_REGISTRANT)) {
                        this.handleTermsUpdate(updatedProfile);
                    } else {
                        this.profile = updatedProfile;
                        this.userAccountService.getUserAccountAccessForMemberAndUser(memberId, this.profile.userId).subscribe((userMemberAccess: UserMemberAccess) => {
                            this.handleAccounts(userMemberAccess);
                        });
                    }
                });
            } else {
                this.profile = updatedProfile;
                this.userAccountService.getUserAccountsAvailableForLogin(updatedProfile.userId).subscribe((userAccounts: UserAccount[]) => {
                    let members = [...new Set(userAccounts.filter((userAccount: UserAccount) => {
                        if (userAccount.role === Role.RETAILER) {
                            // do not allow switch to member where the user only has RETAILER access
                            return false;
                        }
                        return true;
                    }).map((userAccount: UserAccount) => {
                        return userAccount.member;
                    }))];

                    if (members.length > 1) {
                        this.profile.multiMember = true;
                    }
                    this.userAccountService.getUserAccountAccessForMemberAndUser(memberId, this.profile.userId).subscribe((userMemberAccess: UserMemberAccess) => {
                        this.handleAccounts(userMemberAccess);
                    });
                });
            }
        });
    }

    handleAccounts(userMemberAccess: UserMemberAccess) {
        if (userMemberAccess.accountAccess.length === 1) {
            const accountAccess = userMemberAccess.accountAccess[0];
            if (accountAccess.role !== Role.RETAILER) {
                this.initializeApplication(this.profile);
            } else {
                // handle the single retail account case
                this.authService.selectRetailLocation(this.profile.memberId, accountAccess.retailLocationId).subscribe((updatedProfile: any) => {
                    updatedProfile.multiMember = this.profile.multiMember;
                    this.initializeApplication(updatedProfile);
                });
            }
        } else {
            this.handleRetailLocations(userMemberAccess);
        }
    }

    handleRetailLocations(userMemberAccess: UserMemberAccess) {
        userMemberAccess.accountAccess.sort((a, b) => {
            if (!a.retailLocationId) {
                return -1;
            } else if (!b.retailLocationId) {
                return 1;
            }
            return ('' + a.retailLocation.nickname).localeCompare(b.retailLocation.nickname);
        });
        if (userMemberAccess.accountAccess.length === 0) {
            this.error = 'There are no active retail locations for this member.<p>Please contact your administrator or customer support.</p>';
        } else {
            this.membersForUser = [];
            this.accountAccesses = userMemberAccess.accountAccess;
            this.loginStage = 4;
            this.ngOnChanges();
        }
    }

    handleTermsUpdate(updatedProfile: Profile) {
        this.authService.setProfile(updatedProfile);
        this.close(updatedProfile);
    }

    initializeApplication(updatedProfile: Profile) {
        this.profile = updatedProfile;
        this.authService.initializeApplication(this.profile, this.close, this.redirectUrl, true);
    }

    isValidCode() {
        return this.loginForm.controls['verificationCodeCtrl'].valid || this.loginForm.controls['totpCtrl'].valid;
    }

    isValidLogin() {
        return this.loginForm.controls['emailCtrl'].valid && this.loginForm.controls['passwordCtrl'].valid;
    }

    isValidForgotPassword() {
        return this.loginForm.controls['emailCtrl'].valid && this.isForgotPassword;
    }

    cancel() {
        this.authService.clear();
        this.closeAndRedirectToLogin();
    }

    closeAndRedirectToLogin() {
        this.close();
        this.router.navigate(['/login']);
    }

    close(profile?: Profile) {
        this.error = null;
        this.verificationError = null;
        this.loginForm.reset();
        this.matDialog.closeAll();
        super.close(profile);
    }

    forgotPassword() {
        this.title = 'Password Reset';
        this.isForgotPassword = true;
    }

    resetPassword(reset: any) {
        this.error = null;
        const email =  this.loginForm.controls['emailCtrl'].value;
        if (email) {
            const forgotPasswordRequest = {
                email
            };
            this.registrationService.forgotPassword(forgotPasswordRequest).subscribe((_data: any) => {
                this.notifier.showSuccessCloseRequired('Password reset link has been sent, and will expire in 8 hours.');
                this.closeAndRedirectToLogin();
            },
            (errorResponse: any) => {
                this.error = errorResponse.error;
                this.verificationError = true;
                reset();
            });
        }
    };

    preventKeyDown(event: any) {
        if (event.keyCode !== 13) {
            event.preventDefault();
        }
    }

    trimSpaces(event: string, formCtrl: string) {
        let trimmed = event.replace(CustomValidators.ONLY_SPACES, '');
        this.loginForm.controls[formCtrl].setValue(trimmed);
    }

    hasCorporateAccess() {
        return this.accountAccesses.find((accountAccess) => {
            return accountAccess.role !== Role.RETAILER;
        });
    }
}
