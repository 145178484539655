<div>
    <form [formGroup]="loginForm">
        <div class="login-modal">
            <div class="modal-header">
                <div class="row no-gutters col-12 d-flex align-items-center">
                    <div class="col-5"><img class="logo-header logo_position" src="assets/images/Confia.png"></div>
                    <div class="col-5"><label class="form-title label_position"> {{ title }} </label></div>
                    <div class="col-2"><i class="modal-close" (click)="cancel()">&times;</i></div>
                </div>
            </div>
            <div class="modal-content-small">
                <div *ngIf="loginStage === 1" class="form-content">
                    <pc-email-input [class]="'full'" [required]="true" [label]="'Email'" [formCtrl]="'emailCtrl'" [formGroup]="loginForm">
                    </pc-email-input>
                    <pc-password-input [class]="'full'" [required]="true" [label]="'Password'"
                        [formCtrl]="'passwordCtrl'" [formGroup]="loginForm"
                        [hidden]="isForgotPassword">
                    </pc-password-input>
                </div>
                <div *ngIf="loginStage === 2" class="form-content">
                    <span>Please scan the QR code in to your authenticator app, then click 'Continue':</span>
                    <div class="row">
                        <div class="col-12">
                            <div class="qr-code">
                                <pc-secure-image [imgSrc]="getQrCode()" alt="QR Code" class="centered full"></pc-secure-image>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="loginStage === 3" class="form-content">
                    <pc-text-input *ngIf="mfaType === MfaType.SMS" #smsCodeCtrl [required]="true" (changed)="trimSpaces($event, 'verificationCodeCtrl')" [label]="'SMS Verification Code'" [placeholder]="'SMS Verification Code'"
                        [formCtrl]="'verificationCodeCtrl'" [formGroup]="loginForm">
                    </pc-text-input>
                    <pc-text-input *ngIf="mfaType === MfaType.TOTP" #totpCodeCtrl [required]="true" (changed)="trimSpaces($event, 'totpCtrl')" [label]="'TOTP Verification Code'" [placeholder]="'TOTP Verification Code'"
                        [formCtrl]="'totpCtrl'" [formGroup]="loginForm">
                    </pc-text-input>
                </div>
                <div *ngIf="loginStage === 4" class="form-content">
                    <fieldset *ngIf="membersForUser.length" class="form-group">
                        <div class="row">
                            <div class="col-12">
                                <label class="form-label">Select Member</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" [formGroup]="loginForm">
                                <select #memberIdCtrl class="form-control form-input-editable" [formControlName]="'memberIdCtrl'" [required]="true" type="text" (change)="onBusinessSelected()" (keydown)="preventKeyDown($event)">
                                    <option *ngIf="safariFix" value="" disabled selected></option>
                                    <option *ngFor="let member of membersForUser; let i = index;" [value]="member.id">{{member.name}}</option>
                                </select>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset *ngIf="accountAccesses.length" class="form-group">
                        <div class="row">
                            <div class="col-12">
                                <label class="form-label">Select Retail Location <span *ngIf="hasCorporateAccess()"> (or Corporate Access)</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" [formGroup]="loginForm">
                                <select #accountAccessesCtrl class="form-control form-input-editable" [formControlName]="'accountAccessCtrl'" [required]="true" type="text" (change)="onAccountAccessSelected()" (keydown)="preventKeyDown($event)">
                                    <option *ngIf="safariFix" value="" disabled selected></option>
                                    <option *ngFor="let accountAccess of accountAccesses; let i = index;" [value]="accountAccess.retailLocationId || 'MEMBER'">{{accountAccess.retailLocationId ? accountAccess.retailLocation.nickname : 'Corporate Access'}}</option>
                                </select>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <div *ngIf="error" class="error-message mb-4">
                <div class="form-error" [innerHTML]="error"></div>
            </div>
            <div *ngIf="!verificationError" class="modal-footer modal-footer-login">
                <div *ngIf="loginStage === 1" class="modal-content-small">
                    <div class="cursorPoint" (click)="forgotPassword()" [hidden]="isForgotPassword">Forgot your password?</div>
                </div>
                <div *ngIf="loginStage === 1">
                    <pc-submit-button [enabled]="isValidLogin" [submit]="verifyEmailPassword" [text]="'Login'" [hidden]="isForgotPassword"></pc-submit-button>
                    <pc-submit-button [enabled]="isValidForgotPassword" [submit]="resetPassword" [text]="'Reset Password'" [hidden]="!isForgotPassword"></pc-submit-button>
                </div>
                <div *ngIf="loginStage === 2">
                    <pc-submit-button [submit]="enableTotpVerification" [text]="'Continue'"></pc-submit-button>
                </div>
                <div *ngIf="loginStage === 3">
                    <pc-submit-button [enabled]="isValidCode" [submit]="validateLoginCode" [text]="'Validate Code'"></pc-submit-button>
                </div>
            </div>
        </div>
    </form>
</div>
