<div *ngIf="mode === 'SELECT' || (mode === 'VIEW' && term.included)">
    <div class="term" [class.excluded]="!term.included">
        <pc-sc-checkbox *ngIf="mode === 'SELECT'" [formGroup]="formGroup" [metadata]="term" (changed)="termChanged()"></pc-sc-checkbox><strong class="term-index" *ngIf="term.index">{{term.index}}:</strong> 
        <p *ngIf="termHtml" [innerHTML]="termHtml"></p>
        <div *ngIf="orderTerm">
            <pc-sc-sales-order class="sales-order" [order]="contractTemplate.purchaseOrder"></pc-sc-sales-order>
        </div>
    </div>
    <div class="sub-term" *ngIf="term.subTerms.length">
        <pc-sc-term *ngFor="let subterm of term.subTerms" [term]="subterm" [formGroup]="formGroup" [mode]="mode" (termSelected)="termChanged()">
        </pc-sc-term>
    </div>
</div>
