export class Upload {
    id: string;
    memberId: string;
    created: Date;
    updated: Date;
    authorId: string;
    fileName: string;
    explanation: string;
    documentType: DocumentType;
    path: string;
    referencedEntityId: string;
    uri: string;
    documentHash: string;
    version: number;
    status: UploadStatus;
}

export enum DocumentType {
    ALL = '',
    AR_AGING_REPORT = 'AR_AGING_REPORT',
    ARTICLES_OF_INCORPORATION = 'ARTICLES_OF_INCORPORATION',
    AUTHORITY_RESOLUTION = 'AUTHORITY_RESOLUTION',
    BUSINESS_PLAN = 'BUSINESS_PLAN',
    CAPITALIZATION_TABLE = 'CAPITALIZATION_TABLE',
    CORPORATE_STRUCTURE = 'CORPORATE_STRUCTURE',
    OPERATING_AGREEMENT_OR_BYLAWS = 'OPERATING_AGREEMENT_OR_BYLAWS',
    PASSPORT_OR_STATE_ISSUED_ID = 'PASSPORT_OR_STATE_ISSUED_ID',
    EIN_SUPPORTING_EVIDENCE = 'EIN_SUPPORTING_EVIDENCE',
    SHARE_REGISTRY = 'SHARE_REGISTRY',
    FINANCIAL_STATEMENTS = 'FINANCIAL_STATEMENTS',
    FINANCIAL_PROJECTIONS = 'FINANCIAL_PROJECTIONS',
    TAX_RETURN = 'TAX_RETURN',
    RECENT_BANK_STATEMENT = 'RECENT_BANK_STATEMENT',
    LICENSE = 'LICENSE',
    OTHER_CORPORATE_DOCUMENT = 'OTHER_CORPORATE_DOCUMENT',
    SAMPLE_ACH_INFO = 'SAMPLE_ACH_INFO',
    DEPOSIT_CONFIRMATION = 'DEPOSIT_CONFIRMATION',
    WITHDRAWAL_CONFIRMATION = 'WITHDRAWAL_CONFIRMATION',
    DEPOSIT_SUPPORTING_DOCUMENT = 'DEPOSIT_SUPPORTING_DOCUMENT',
    DIGITAL_CHECK_TO_MERCHANT = 'DIGITAL_CHECK_TO_MERCHANT',
    PAYMENT_SUPPORTING_DOCUMENT = 'PAYMENT_SUPPORTING_DOCUMENT',
    AUTHORITY_TRANSACTION_SUPPORTING_DOCUMENT = 'AUTHORITY_TRANSACTION_SUPPORTING_DOCUMENT',
    ID_RISK_SCREEN = 'ID_RISK_SCREEN',
    MEDIA_SCREEN = 'MEDIA_SCREEN',
    MESSAGE_ATTACHMENT = 'MESSAGE_ATTACHMENT',
    DAILY_REC_REPORT = 'DAILY_REC_REPORT',
    INITIAL_REPORT = 'INITIAL_REPORT',
    CONTINUING_REPORT = 'CONTINUING_REPORT',
    CTR_REPORT = 'CTR_REPORT',
    TRANSACTION_COMPLIANCE_DOCUMENT = 'TRANSACTION_COMPLIANCE_DOCUMENT',
    OTHER_DILIGENCE = 'OTHER_DILIGENCE',
    REMITTANCE_FORM = 'REMITTANCE_FORM',
    SEEDTOSALE_TRACK_MANIFEST = 'SEEDTOSALE_TRACK_MANIFEST',
    OTHER_TRANSACTION_DOCUMENT = 'OTHER_TRANSACTION_DOCUMENT',
    COPY_OF_SITE_OR_LOCATION_CONTRACT = 'COPY_OF_SITE_OR_LOCATION_CONTRACT',
    FLOW_OF_FUNDS_DESC = 'FLOW_OF_FUNDS_DESC',
    COPY_OF_LOCATION_CONTRACT = 'COPY_OF_LOCATION_CONTRACT',
    CASH_MGNT_POLICY_AND_PROCEDURE = 'CASH_MGNT_POLICY_AND_PROCEDURE',
    ATM_LIST_AND_LOCATIONS = 'ATM_LIST_AND_LOCATIONS',
    RAW_FRONT_CHECK_IMAGE = 'RAW_FRONT_CHECK_IMAGE',
    RAW_REAR_CHECK_IMAGE = 'RAW_REAR_CHECK_IMAGE',
    PROCESSED_FRONT_CHECK_IMAGE = 'PROCESSED_FRONT_CHECK_IMAGE',
    PROCESSED_REAR_CHECK_IMAGE = 'PROCESSED_REAR_CHECK_IMAGE'
}

export enum UploadStatus {
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED'
}
