import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AuthService, Rfi, RfiHistory, DocumentFormService, RfiService } from 'projects/services/src/public-api';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pt-rfi-explanation-modal',
    templateUrl: './rfi-explanation-modal.component.html'
})
export class RfiExplanationModalComponent extends BaseModalComponent<RfiExplanationModalComponent> implements OnInit {

    rfi: Rfi;
    authorityOnly = false;
    explanationForm: UntypedFormGroup;
    buttonText = 'Add Explanation';

    constructor(private documentFormService: DocumentFormService,
                private authService: AuthService,
                private rfiService: RfiService,
                dialogRef: MatDialogRef<RfiExplanationModalComponent>,
                @Inject(MAT_DIALOG_DATA) data: any) {
        super(dialogRef);
        this.rfi = data.rfi;
        this.authorityOnly = data.authorityOnly;
    }

    ngOnInit() {
        this.isExplanationFormValid = this.isExplanationFormValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        if (this.authService.isAuthority()) {
            this.buttonText = 'Add Note to Member';
        }
        this.explanationForm = this.documentFormService.initializeExplanationForm();
        this.explanationForm.controls['documentTypeCtrl'].patchValue('Other');
    }

    close(rfiHistory?: RfiHistory) {
        super.close(rfiHistory);
    }

    onSubmit() {
        const rfiHistory = new RfiHistory();
        let notes = this.explanationForm.controls['notesCtrl'].value;
        // maintain the line feeds and tabs, and save as HTML
        notes = notes.replace(/(\r\n|\n|\r)/g, '<br />');
        notes = notes.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
        rfiHistory.notes = notes;
        rfiHistory.transactionId = this.rfi.transactionId;
        rfiHistory.status = this.rfi.status;
        rfiHistory.rfiId = this.rfi.id;
        rfiHistory.authorityOnly = this.authorityOnly;
        rfiHistory.performedBy = this.authService.getProfile().userId;
        this.rfiService.saveRFIHistory(rfiHistory).subscribe((explanation: RfiHistory) => {
            this.close(explanation);
        });
    }

    isExplanationFormValid() {
        return !this.explanationForm.invalid;
    }
}
