import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phone'
})

export class PhonePipe implements PipeTransform {
    transform(tel: string) {
        if (!tel) {
            return '';
        }
        const value = tel.toString().trim().replace(/^\+/, '');

        if (value.match(/[^0-9]/)) {
            return tel;
        }

        let countryCode: string;
        let areaCode: string;
        let lineNumber: string;

        switch (value.length) {
            case 10: // +1PPP####### -> C (PPP) ###-####
                countryCode = '1';
                areaCode = value.slice(0, 3);
                lineNumber = value.slice(3);
                break;

            case 11: // +CPPP####### -> CCC (PP) ###-####
                countryCode = value[0];
                areaCode = value.slice(1, 4);
                lineNumber = value.slice(4);
                break;

            case 12: // +CCCPP####### -> CCC (PP) ###-####
                countryCode = value.slice(0, 3);
                areaCode = value.slice(3, 5);
                lineNumber = value.slice(5);
                break;

            default:
                return tel;
        }

        if (countryCode === '1') {
            countryCode = '';
        }

        lineNumber = lineNumber.slice(0, 3) + '-' + lineNumber.slice(3);

        return (countryCode + ' (' + areaCode + ') ' + lineNumber).trim();
    }
}