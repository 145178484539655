import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggingService } from './error-logging.service';
import { AuthService } from 'projects/services/src/public-api';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../notifications/notification.service';
import { RefreshService } from '../refresh/refresh.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorService implements ErrorHandler {

    readonly CHUNK_FAILED_MESSAGE = /Loading chunk [\d]+ failed/;
    readonly ROUTE_FAILED_MESSAGE = /Cannot match any routes./;

    constructor(private authService: AuthService,
                private ngZone: NgZone,
                private router: Router,
                private notifier: NotificationService,
                private refresher: RefreshService,
                private logger: LoggingService,
                private dialogRef: MatDialog) {
    }

    handleError(errorResult: any) {

        if (this.CHUNK_FAILED_MESSAGE.test(errorResult.message)) {
            window.location.reload();
            return;
        }

        if (!navigator.onLine) {
            this.notifier.showError('Browser offline...');
        } else {
            if (errorResult instanceof HttpErrorResponse) {
                // server error
                if (errorResult.status === 401) {
                    this.ngZone.run(() => {
                        if (this.authService.getProfile() !== null) {
                            this.dialogRef.closeAll();
                            if (errorResult.error && errorResult.error.sessionExpired) {
                                this.authService.logout();
                            } else {
                                this.authService.logout(this.router.url);
                            }
                        } else {
                            this.authService.logout();
                        }
                    });
                    return;
                } else if (errorResult.status === 403) {
                    if (errorResult.error && !errorResult.error.message) {
                        this.notifier.showError(errorResult.error);
                    } else {
                        this.notifier.showError('You are not authorized to access this resource');
                    }
                    this.router.navigate(['/dashboard']);
                    return;
                } else if (errorResult.status === 429) {
                    this.notifier.showError('You have been temporarily restricted from accessing this site due to rate limitations.  Please try again later.');
                    return;
                } else if (errorResult.status === 426) {
                    this.refresher.showExpiringSession(() => {
                        return this.authService.refreshToken();
                    });
                    return;
                } else if (errorResult.error instanceof ProgressEvent) {
                    const url = new URL(errorResult.url);
                    const service = url.hostname + (url.port ? ':' + url.port : '');
                    if (errorResult.error.loaded === 0 && errorResult.error.type === 'error') {
                        this.notifier.showError('Unable to contact Confia. Please check your connection and try again.');
                    } else {
                        this.notifier.showError('An unspecified error requesting: ' + service);
                    }
                } else {
                    if (errorResult.error && errorResult.error.message){
                        this.notifier.showError(errorResult.error.message);
                    } else if (errorResult.error) {
                        this.notifier.showError(errorResult.error);
                    } else {
                        this.notifier.showError(errorResult.status + ' Error: ' + 'An unknown error occurred.  Please contact support.');
                    }
                }
            } else {
                if (errorResult.name === 'NO_CURRENT_TASK') {
                    this.ngZone.run(() => {
                        this.dialogRef.closeAll();
                        this.router.navigate(['/dashboard']);
                    });
                    return;
                } else if (this.ROUTE_FAILED_MESSAGE.test(errorResult?.rejection?.message)) {
                    this.dialogRef.closeAll();
                    this.router.navigate(['/dashboard']);
                } else {
                    // client error
                    this.notifier.showError(errorResult.message);
                }
            }
        }

        this.logger.logError(errorResult);
    }

}
