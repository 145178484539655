import { WatchlistStatus } from './watchlist-entity';

export class WatchlistUserNote {
    id: string;
    created: string;
    updated: string;
    notes: string;
    userId: string;
    userName: string;
    status: WatchlistStatus;
    watchlistEntityId: string;
}
