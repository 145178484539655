<div *ngIf="accounts && accounts.length > 0">
    <p><span class="form-title">Metrc Keys</span></p>
    <div class="col-sm-12 horizontal-scroll mat-table-container">
        <div class="mat-table-spinner" *ngIf="isLoadingResults">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table [dataSource]="accounts">
            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef> State </th>
                <td mat-cell *matCellDef="let element">
                    {{element.state}}
                </td>
            </ng-container>
            <ng-container matColumnDef="account">
                <th class="centered" mat-header-cell *matHeaderCellDef> Account # </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    *****{{element.userKey.substring(element.userKey.length-4)}}
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th class="centered" mat-header-cell *matHeaderCellDef> Action </th>
                <td class="centered" mat-cell *matCellDef="let element">
                    <span class="d-flex justify-content-center" *ngIf="authService.isAdmin()" (click)="onUnlinkMrb(element.id)">
                        <i class="fa fa-times"></i>
                    </span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
