<form *ngIf="riskProfileForm" [formGroup]="riskProfileForm">
    <div class="row">
        <div class="col-lg-12">
            <p class="form-title">Account Limits <span class="pull-right"><a href="/static/EDD_Guidelines_Latest.xlsx" target="_blank" rel="noopener noreferrer" class="small">Summary Guidelines</a> </span></p>
            <pt-account-summary-list *ngIf="status !== MemberAccountStatus.UNDER_REVIEW && status !== MemberAccountStatus.PENDING" [changeTrigger]="changeTrigger" [memberAccountId]="memberAccountId"></pt-account-summary-list>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-lg-12" *ngIf="memberType !== MemberType.CONSUMER">
            <div class="row">
                <div class="col-6">
                    <pc-form-amount-input [label]="'Expected Monthly Cash Deposit'" [formCtrl]="'expectedMonthlyCashDepositAmountCtrl'" [formGroup]="riskProfileForm"
                        [includeCents]="false">
                    </pc-form-amount-input>
                    <pc-form-amount-input [label]="'Expected Monthly Payments'" [formCtrl]="'expectedMonthlyPaymentAmountCtrl'" [formGroup]="riskProfileForm"
                        [includeCents]="false">
                    </pc-form-amount-input>
                    <pc-form-amount-input [label]="'Expected Monthly # of Transactions'" [formCtrl]="'expectedMonthlyTransactionsCtrl'" [formGroup]="riskProfileForm"
                        [dollar]="false" [includeCents]="false">
                    </pc-form-amount-input>
                    <pc-form-amount-input [label]="'Monthly Cash Deposit Limit'" [formCtrl]="'monthlyCashDepositAmountLimitCtrl'" [formGroup]="riskProfileForm"
                        [includeCents]="false">
                    </pc-form-amount-input>
                </div>
                <div class="col-6">
                    <pc-form-amount-input [label]="'Maximum Number of Daily Transactions'" [formCtrl]="'maxNumberOfTransactionsPerDayCtrl'" [formGroup]="riskProfileForm"
                        [dollar]="false" [includeCents]="false">
                    </pc-form-amount-input>
                    <pc-form-amount-input [label]="'Maximum Number of Monthly Transactions'" [formCtrl]="'maxNumberOfTransactionsPerMonthCtrl'" [formGroup]="riskProfileForm"
                        [dollar]="false" [includeCents]="false">
                    </pc-form-amount-input>
                    <pc-form-amount-input [label]="'Maximum Transaction Total'" [formCtrl]="'maxTransactionTotalCtrl'" [formGroup]="riskProfileForm"
                        [includeCents]="false">
                    </pc-form-amount-input>
                    <pc-form-amount-input [label]="'Maximum Total of Monthly Transactions'" [formCtrl]="'maxTransactionTotalPerMonthCtrl'" [formGroup]="riskProfileForm"
                        [includeCents]="false">
                    </pc-form-amount-input>
                    <pc-form-amount-input [label]="'Maximum Account Size'" [formCtrl]="'accountAmountLimitCtrl'" [formGroup]="riskProfileForm"
                        [includeCents]="false">
                    </pc-form-amount-input>
                </div>
            </div>
        </div>
        <div class="col-6" *ngIf="memberType === MemberType.CONSUMER">
            <p class="form-title">Account Limits </p>
            <div class="row">
                <div class="col-12">
                    <pc-form-amount-input [label]="'Maximum Number of Daily Transactions'" [formCtrl]="'maxNumberOfTransactionsPerDayCtrl'" [formGroup]="riskProfileForm"
                        [dollar]="false" [includeCents]="false">
                    </pc-form-amount-input>
                    <pc-form-amount-input [label]="'Maximum Number of Monthly Transactions'" [formCtrl]="'maxNumberOfTransactionsPerMonthCtrl'" [formGroup]="riskProfileForm"
                        [dollar]="false" [includeCents]="false">
                    </pc-form-amount-input>
                    <pc-form-amount-input [label]="'Maximum Transaction Total'" [formCtrl]="'maxTransactionTotalCtrl'" [formGroup]="riskProfileForm"
                        [includeCents]="false">
                    </pc-form-amount-input>
                    <pc-form-amount-input [label]="'Maximum Total of Monthly Transactions'" [formCtrl]="'maxTransactionTotalPerMonthCtrl'" [formGroup]="riskProfileForm"
                        [includeCents]="false">
                    </pc-form-amount-input>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="button-panel pull-right">
    <pc-submit-button [enabled]="isInternalAlertsValid" [submit]="onSave" [text]="'Save'"></pc-submit-button>
</div>
