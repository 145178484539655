import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, SidebarTrackingService, MemberService, Member } from 'projects/services/src/public-api';

@Component({
    selector: 'pt-new-member-header',
    templateUrl: './pt-new-member-header.component.html'
})
export class NewMemberHeaderComponent implements OnInit {

    @Input() expandedMenu: boolean;

    @Output() toggleExpandSideMenu: EventEmitter<any> = new EventEmitter<any>();

    member: Member;

    constructor(private translate: TranslateService,
                private authService: AuthService,
                private router: Router,
                private memberService: MemberService) {}

    ngOnInit() {
        this.memberService.loadMember(this.authService.getProfile().memberId).subscribe((member: Member) => {
            this.member = member;
        });
    }

    getMenuClass() {
        if (this.expandedMenu) {
            return 'top-header-icon-wide';
        } else {
            return 'top-header-icon-narrow';
        }
    }

    getBodyClass() {
        if (this.expandedMenu) {
            return 'top-header-body-narrow';
        } else {
            return 'top-header-body-wide';
        }
    }

    onToggleExpandSideMenu() {
        this.toggleExpandSideMenu.emit('toggle');
    }

    onLoggedout() {
        this.authService.logout();
    }

    isActive(item: string) {
        return SidebarTrackingService.getActiveNavItem() === item;
    }

    isDisabled(link: string) {
        return this.router.url.indexOf(link) >= 0;

    }
}
