export class SalesOrderLine {

    item: number;
    description: string;
    unitOfMeasure: string;
    quantity: number;
    pricePerUnit: number;
    discount: number;
    preTaxAmount: number;
    exempt = false;
    taxType: string;
    taxAmount: number;
    totalAmount: number;
    notForResale = false;

}
