import { FieldMetadata } from './field-metadata';

export class ContractSectionTerm {

    category: string;
    index: string;
    name: string;
    subTerms: ContractSectionTerm[];
    text: string;
    parent: string;
    required: boolean;
    included: boolean;

    fieldMap: { key: string, field: FieldMetadata };
    keys: string[];

}
