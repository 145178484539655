import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'projects/services/src/public-api';
import { SmartContractsMainComponent } from './components';
import { CheckAvailabilityComponent, ConfirmDeliveryComponent, ShipOrderComponent, ContractTemplateDetailsComponent, BuyerReviewAgreementComponent,
    ListContractTemplatesComponent, CreateTemplateMainComponent, PreviewSharedTemplateComponent, SellerReviewAgreementComponent } from 'projects/smart-contract-components/src/lib';

const routes: Routes = [
    {
        path: '',
        component: SmartContractsMainComponent,
        canActivate: [AuthGuard],
        data: { title: 'Smart Contracts' }
    },
    {
        path: 'my_template', children: [
            { path: ':contractTemplateId', component: ContractTemplateDetailsComponent, canActivate: [AuthGuard] }
        ]
    },
    {
        path: 'shared_template', children: [
            { path: ':contractTemplateId', component: PreviewSharedTemplateComponent, canActivate: [AuthGuard] }
        ]
    },
    {
        path: 'wizard', children: [
            {
                path: 'create_template/:activeTab',
                component: CreateTemplateMainComponent,
                canActivate: [AuthGuard],
                data: { title: 'Smart Contracts' }
            },
            {
                path: 'contract_negotiation', children: [
                    { path: 'buyer_review', component: BuyerReviewAgreementComponent },
                    { path: 'seller_review', component: SellerReviewAgreementComponent }
                ]
            }
        ]
    },
    {
        path: 'purchase_agreement', children: [
            { path: 'check_availability', component: CheckAvailabilityComponent },
            { path: 'ship_order', component: ShipOrderComponent },
            { path: 'confirm_delivery', component: ConfirmDeliveryComponent }
        ]
    },
    {
        path: 'my_templates',
        component: ListContractTemplatesComponent,
        canActivate: [AuthGuard],
        data: { title: 'Smart Contracts' }
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SmartContractsRoutingModule {
}
