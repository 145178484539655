import { LendingCustomer } from './lending-customer';

export class LendingCustomerSummary {

    pageNumber: number;
    pageSize: number;
    totalResults: number;
    results: LendingCustomer[];
    totalOwed: number;
    noInvoicesOutstanding: number;
    daysSalesOutstanding: number;

}
