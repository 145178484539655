import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import {
    BusinessCategory, DiligenceService, MemberRegistrationType, MemberService, OnboardingTrackingService, FinancialDetails, FinancialDetailsFormService, WorkflowService, Task,
    PagedResponse, ReviewStatus, Member, MemberStatus, MemberType
} from 'projects/services/src/public-api';
import { mergeMap, switchMap } from 'rxjs';

/**
 * A vertical scroll page with refresh tables and ability to add Documents, Key Parties, Locations, Licenses and
 * Financial.
 *
 * When an admin member clicks on his Account review task.
 * When a member clicks on his periodic review email button.
 *
 * Member will be redirected to this component to complete the periodic review.
 *
 */
@Component({
    selector: 'pt-periodic-review',
    templateUrl: './periodic-review.component.html'
})
export class PeriodicReviewComponent implements OnInit {

    memberId: string;
    memberType: MemberType = MemberType.BUSINESS;
    memberRegistrationType: MemberRegistrationType;
    businessCategory: BusinessCategory;
    financialDetailsForm: UntypedFormGroup;
    memberStatus: MemberStatus;
    taskId: string;
    historyId: string;
    nonPremiumMemberReadOnly = true;

    constructor(private workflowService: WorkflowService,
                private memberService: MemberService,
                private diligenceService: DiligenceService,
                private financialDetailsFormService: FinancialDetailsFormService,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.isFinancialDetailsValid = this.isFinancialDetailsValid.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSave = this.onSave.bind(this);
        this.isPeriodicReviewValid = this.isPeriodicReviewValid.bind(this);

        this.loadMember();
        this.loadTask();
    }

    loadMember() {
        this.memberId = this.activatedRoute.snapshot.params['memberId'];

        this.memberService.loadMember(this.memberId).subscribe((member: Member) => {
            // For verifying the periodic review document required added member.
            OnboardingTrackingService.setMemberInfo(null, member);
            this.memberType = member.memberType;
            this.memberStatus = member.status;
            this.businessCategory = member.businessCategory;
            this.memberRegistrationType = member.memberRegistrationType;
            this.nonPremiumMemberReadOnly = member.memberRegistrationType !== MemberRegistrationType.PREMIUM_MEMBER;
            this.initFinancialDetails(member.financialDetails);
        });
    }

    loadTask() {
        this.workflowService.findTaskByTaskDefinitionsAndProcessVariable(['user_admin_periodic_review'], 'memberId', `${this.memberId}`).subscribe((tasks: PagedResponse<Task>) => {
            if (tasks.content.length <= 0) {
                this.router.navigate(['/dashboard'], {replaceUrl: true});
                return;
            }
            const task = tasks.content[0];
            this.taskId = task.id;
            this.historyId = task.variables.historyId;
        });
    }

    initFinancialDetails(financialDetails: FinancialDetails, callback?: any) {
        this.financialDetailsForm = this.financialDetailsFormService.initializeForm(financialDetails, this.memberRegistrationType === MemberRegistrationType.PREMIUM_MEMBER);
        if (this.nonPremiumMemberReadOnly) {
            this.financialDetailsForm.get('expectedMonthlyCashDepositCtrl').disable();

        }
        if (callback) {
            callback();
        }
        this.financialDetailsForm.markAsPristine();
    }

    onSave(reset?: any) {
        this.saveFinancialDetails().subscribe((financialDetails: FinancialDetails) => {
            this.initFinancialDetails(financialDetails, reset);
        });
    }

    onSubmit() {
        const body = {
            variables: {}
        };

        this.saveFinancialDetails().pipe(
            switchMap(() => {
                return this.updatePeriodicReviewStatus();
            }),
            mergeMap(() => {
                return this.workflowService.completeTask(this.taskId, body);
            })
        ).subscribe(() => {
            this.router.navigate(['/dashboard']);
        }, (error: any) => {
            throw error;
        });
    }

    saveFinancialDetails() {
        const financialInfo = this.financialDetailsFormService.getFinancialDetails(this.financialDetailsForm);
        return this.memberService.saveFinancialDetails(financialInfo);
    }

    updatePeriodicReviewStatus() {
        return this.diligenceService.updateDiligenceHistory(this.historyId, { status: ReviewStatus.PENDING });
    }

    isFinancialDetailsValid() {
        return this.financialDetailsForm && this.financialDetailsForm.valid;
    }

    isPeriodicReviewValid() {
        return OnboardingTrackingService.isDocumentsValid() && this.isFinancialDetailsValid();
    }
}
