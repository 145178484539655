import { Component, OnInit } from '@angular/core';
import { MessageService, Message, MessageType } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-messages-gadget',
    templateUrl: './messages-gadget.component.html'
})
export class MessagesGadgetComponent implements OnInit {
    messages: Message[];
    messageCount = 0;

    constructor(private messageService: MessageService) {
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.messageService.loadReceivedMessages('',
            '',
            '',
            MessageType.RECEIVED,
            0,
            5,
            'created',
            'DESC', false)
            .subscribe(
                (response: any) => {
                    this.messages = response.content;
                    this.messageCount = response.totalElements;
                }
            );
    }
}
