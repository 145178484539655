<div class="col-sm-12 horizontal-scroll mat-table-container pt-2 pb-2">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <table mat-table [dataSource]="whitelistedParties" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="member">
            <th mat-header-cell *matHeaderCellDef> Member </th>
            <td mat-cell *matCellDef="let element"> {{element.whitelistedMemberName}} </td>
        </ng-container>
        <ng-container matColumnDef="whiteListedTransactionType">
            <th mat-header-cell *matHeaderCellDef> (Transaction)Txn Type </th>
            <td mat-cell *matCellDef="let element">
                <span> {{ element.whitelistedTransactionType | translate }} </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="maximumTransactionAmount">
            <th mat-header-cell class="centered" *matHeaderCellDef> Max Txn Amount ($) </th>
            <td mat-cell class="centered" *matCellDef="let element">
                <span class="table-amount">
                    {{ element.maximumTransactionAmount ? (element.maximumTransactionAmount | currency) : 'N/A' }}
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="maximumMonthlyNumberOfTransactions">
            <th mat-header-cell class="centered" *matHeaderCellDef>Max Txn Volume (last 30 days) </th>
            <td mat-cell class="centered" *matCellDef="let element">
                {{ element.maximumMonthlyNumberOfTransactions }}
            </td>
        </ng-container>
        <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef> Notes </th>
            <td mat-cell *matCellDef="let element"> {{element.note}} </td>
        </ng-container>
        <ng-container matColumnDef="action">
            <th mat-header-cell class="centered" *matHeaderCellDef> Action </th>
            <td mat-cell  class="centered" *matCellDef="let element">
                <i class="fa fa-trash" pc-no-dbl-click aria-hidden="true" (click)="removeWhitelistedParty(element)"></i>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
</div>
<div class="col-12 add-party-position">
  <button class="blue-button pull-right" [disabled]="!authService.isAuthority()"  (click)="addWhiteListParty()" type="button"><i class="fa fa-plus pull-left dashboard-icon"></i><div class="pull-right-button">Add Party</div></button>
</div>
