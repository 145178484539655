import { LendingFinancialItem } from './lending/lending-financial-item';

export class ProfitAndLossReport {
    fromDate: string;
    toDate: string;

    income: LendingFinancialItem;
    costOfSales: LendingFinancialItem;
    expenses: LendingFinancialItem;
    otherIncome: LendingFinancialItem;
    otherExpenses: LendingFinancialItem;

    netOtherIncome: number;
    netProfit: number;
    grossProfit: number;
    netOperatingProfit: number;
}
