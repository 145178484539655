export class ProcessedCheckDetails {
    id: string;
    created: Date;
    updated: Date;
    memberId: string;
    memberAccountId: string;
    checkNumber : string;
    accountNumber : string;
    routingNumber: string;
    micr: string;
    checkDate: Date;
    checkAmount: number;
    rawFrontImageUploadId: string;
    rawRearImageUploadId: string;
    processedFrontImageUploadId: string;
    processedRearImageUploadId: string;
    transactionId: string;
    externalTransferId: number;
    checkDetailsValid: boolean;
}