import { Task, WorkflowService, LendingFinancingRequest, RequestAccess, LenderService, PagedResponse, TaskCategory, AuthService, User, Role } from 'projects/services/src/public-api';
import { AcceptedFinancingRequestModalComponent, ApproveAccessModalComponent, ConfirmModalComponent } from 'projects/components/src/lib/modal';
import { Component, Input,  OnInit, AfterViewInit, ViewChild, OnDestroy, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UIUtils } from 'projects/components/src/lib/ui-utils.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageTracking, TableUtils } from 'projects/components/src/lib/table-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
@Component({
    selector: 'pc-work-list',
    templateUrl: './work-list.component.html',
    styleUrls: ['./work-list.component.scss']
})
export class WorkListComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() taskCategory = TaskCategory.ALL;
    @Input() assignee = '';
    @Input() authorityUsers: {userId: string, user: User}[] = [];

    tasks: Task[];
    taskDefinitions: string[] = [];

    isLoadingResults = true;
    displayedColumns: string[] = ['priority', 'name', 'date', 'process', 'assignee'];

    pageTracking: PageTracking;
    resultsLength = 0;
    subscription: any;
    filterEvent: EventEmitter<null> = new EventEmitter<null>();

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private workflowService: WorkflowService,
                public authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private lenderService: LenderService,
                private dialog: MatDialog,
                private cdr: ChangeDetectorRef) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnInit() {
        this.updateTaskDefinitions();
        if (!this.authService.isAuthorityOrReviewer()) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('priority'), 1);
            this.displayedColumns.splice(this.displayedColumns.indexOf('assignee'), 1);
        }
        if (this.authService.getProfile().role === Role.NEW_ADMIN_REGISTRANT) {
            this.displayedColumns.splice(this.displayedColumns.indexOf('process'), 1);

        }
        this.pageTracking = TableUtils.initializeTableValues(this.route, this.router, 'created', 'desc', 100);

    }

    ngAfterViewInit() {
        TableUtils.initializePaginatorAndSort(this.route, this.router, this.cdr, this.pageTracking, this.paginator, this.sort, this.filterEvent);
        this.addTableLoadListener();
    }

    addTableLoadListener() {
        this.sort.sortChange.subscribe(() => {
            this.paginator.firstPage();
        });
        this.subscription = merge(this.sort.sortChange, this.paginator.page, this.filterEvent).pipe(
            startWith({}),
            switchMap(() => {
                UIUtils.scrollToTop(document.querySelector('.mat-table-container'));
                this.isLoadingResults = true;
                this.updateTaskDefinitions();
                // NOTE: The Camunda API does not query by pages - it uses start and ends.  So the pageable needs to take this in to account.
                const currentPage = ((this.paginator.pageIndex + 1) - 1) * this.paginator.pageSize;
                return this.workflowService.getTasksSorted(this.taskDefinitions, this.assignee, currentPage, this.paginator.pageSize, this.sort.active, this.sort.direction);
            }),
            map((response: PagedResponse<Task>) => {
                this.resultsLength = response.totalElements || 0;
                return response.content || [];
            }),
            catchError(() => {
                this.isLoadingResults = false;
                return observableOf([]);
            })
        ).subscribe((tasks: Task[]) => {
            this.tasks = [];
            for (const task of tasks) {
                this.tasks.push(task);
                // I don't think we actually need to do this...
                this.workflowService.getProcessDefinition(task.processDefinitionId).subscribe((procDef: any) => {
                    task.processDefinitionName = procDef.name;
                    task.processDefinitionDescription = procDef.description;
                });
            }
            this.isLoadingResults = false;
        });
    }

    loadTask(taskId: string) {
        this.workflowService.getTask(taskId).subscribe((task: Task) => {
            if (task.formKey === 'request_financing') {
                this.lenderService.getFinancingById(task.variables.financingId).subscribe((financingResponse: LendingFinancingRequest) => {
                    this.openAcceptedFinancingRequest(taskId, financingResponse);
                });
            } else if (task.formKey === 'request_access') {
                this.openApproveAccessRequest(task);
            } else {
                this.workflowService.setCurrentTask(task);
            }
        });
    }

    updateTaskDefinitions() {
        switch (this.taskCategory) {
            case TaskCategory.ALL:
                this.taskDefinitions = [];
                break;

            case TaskCategory.MEMBER_ONBOARDING:
                this.taskDefinitions = ['user_pt_onboarding_review', 'user_authority_member_review', 'user_verify_email'];
                break;

            case TaskCategory.ACCOUNT_ONBOARDING:
                this.taskDefinitions = ['user_account_registration_review', 'user_account_number_registration'];
                break;

            case TaskCategory.MERCHANT_ONBOARDING:
                this.taskDefinitions = ['user_review_individual_merchant', 'user_review_business_merchant'];
                break;

            case TaskCategory.RFI_REVIEW:
                this.taskDefinitions = ['user_adjudicate_rfi'];
                break;

            case TaskCategory.WATCHLIST_REVIEW:
                this.taskDefinitions = ['user_review_watchlist_hits'];
                break;

            case TaskCategory.CHECK_VERIFICATION:
                this.taskDefinitions = ['user_verify_check'];
                break;

            case TaskCategory.TRANSACTION_RECONCILIATION:
                this.taskDefinitions = ['user_confirm_check_withdrawal', 'user_confirm_deposit', 'user_confirm_currency_withdrawal'];
                break;

            case TaskCategory.BANK_ACCOUNT_REVIEW:
                this.taskDefinitions = ['user_bank_account_review'];
                break;

            case TaskCategory.USER_INVITATIONS:
                this.taskDefinitions = ['user_create_account', 'user_verify_link'];
                break;

            case TaskCategory.USER_PASSWORD_RESET:
                this.taskDefinitions = ['user_reset_password'];
                break;

            case TaskCategory.PERIODIC_REVIEW:
                this.taskDefinitions = ['user_authority_periodic_review'];
                break;

            default:
                break;
        }
    }

    openAcceptedFinancingRequest(taskId: string, financingResponse: LendingFinancingRequest) {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.data = {
            financingRequest: financingResponse
        };
        const dialog = this.dialog.open(AcceptedFinancingRequestModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((result: 'DECLINED' | 'CLOSE' | 'PROCEED') => {
            if (result === 'DECLINED') {
                const confirmDialogConfig: MatDialogConfig = {};
                confirmDialogConfig.panelClass = 'normal-modal';
                confirmDialogConfig.data = {
                    title: 'Are you sure to decline this financing request?',
                    confirmText: 'Confirm'
                };
                const confirmDialog = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
                confirmDialog?.afterClosed().subscribe((confirmAction: any) => {
                    if (confirmAction === 'confirmed') {
                        this.lenderService.updateLoanStatus(financingResponse.loanId, 'DECLINED').subscribe(() => {
                            this.completeTask(taskId);
                        });
                    }
                });
            } else if (result === 'PROCEED') {
                this.completeTask(taskId);
            } else {
                this.workflowService.clearCurrentTask();
            }
        });
    }

    openApproveAccessRequest(task: Task) {
        const access: RequestAccess = {
            id: task.variables.requestId,
            memberId: task.variables.memberId,
            lenderId: task.variables.lenderId,
            startDate: task.variables.startDate,
            endDate: task.variables.endDate
        };
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        let userId = task.variables.userId;
        // deprecated - remove after MSO - legacy workflow variable
        if (!userId) {
            userId = task.variables.individualId;
        }
        dialogConfig.data = {
            requestAccess: access,
            lenderName: task.variables.lenderName,
            userId
        };
        const dialog = this.dialog.open(ApproveAccessModalComponent, dialogConfig);
        dialog?.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.completeTask(task.id);
            } else {
                this.workflowService.clearCurrentTask();
            }
        });
    }

    completeTask(taskId: string) {
        this.workflowService.completeTask(taskId, {}, this.filterEvent.emit).subscribe(() => {
            this.filterEvent.emit();
        });
    }

    isPriority(task: Task) {
        return this.authService.isAuthorityOrReviewer() && task.priority === 80;
    }
}
