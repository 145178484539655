import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SmartContractService, ContractTemplate, AuthService, ContractTemplateType } from 'projects/services/src/public-api';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from 'projects/components/src/lib/modal';

@Component({
    selector: 'pc-sc-contract-template-details',
    templateUrl: './contract-template-details.component.html'
})
export class ContractTemplateDetailsComponent implements OnInit {

    @Input() activeTab = 'PREVIEW_TEMPLATE';

    contractTemplate: ContractTemplate;
    contractTemplateId: string;
    formGroup = this.formbuilder.group([]);

    constructor(private formbuilder: UntypedFormBuilder,
                private router: Router,
                private route: ActivatedRoute,
                private authService: AuthService,
                private smartContractService: SmartContractService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.contractTemplateId = this.route.snapshot.params['contractTemplateId'];
        this.smartContractService.getTemplateById(this.contractTemplateId)
            .subscribe(
                (contractTemplate: ContractTemplate) => {
                    this.contractTemplate = contractTemplate;
                }
            );
    }

    getViewType() {
        if (this.contractTemplate) {
            if (this.authService.getProfile().memberId === this.contractTemplate.creator) {
                return this.contractTemplate.templateType;
            } else {
                return this.contractTemplate.templateType === ContractTemplateType.BUYER ? 'SELLER' : 'BUYER';
            }
        }
    }

    onMyTemplates() {
        this.router.navigate(['/smart_contracts/my_templates']);
    }

    setActiveTab(activeTab: string) {
        this.activeTab = activeTab;
    }

    getTabClass(tabOption: string) {
        if (this.activeTab === tabOption) {
            return 'tab-option-selected';
        } else {
            return 'tab-option';
        }
    }

    deleteTemplate() {
        const originalTab = this.activeTab;
        this.activeTab = 'DELETE_TEMPLATE';
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';

        dialogConfig.data = {
            title: 'Delete Template',
            description: 'This will delete the current template.  NOTE: existing contracts will not be affected.',
            confirmMessage: 'Do you want to delete this template?',
            confirmText: 'Delete'
        };
        const dialog = this.dialog.open(ConfirmModalComponent, dialogConfig);

        dialog?.afterClosed().subscribe(
            (confirmAction: any) =>  {
                if (confirmAction === 'confirmed') {
                    this.smartContractService.deleteTemplate(this.contractTemplate.id).subscribe(
                        () => {
                            this.router.navigate(['/smart_contracts/my_templates']);
                        }
                    );
                } else {
                    this.activeTab = originalTab;
                }
            }
        );

    }
}
