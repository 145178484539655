<div class="col-sm-12 horizontal-scroll mat-table-container pt-3">
    <div class="mat-table-spinner" *ngIf="isLoadingResults">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
    <mat-paginator [pageIndex]="paginator.pageIndex" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    <table mat-table [dataSource]="loans" matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{ element.name ?  element.name : 'N/A' }} </td>
        </ng-container>
        <ng-container matColumnDef="lenderName">
            <th mat-header-cell *matHeaderCellDef> Lender Name </th>
            <td mat-cell *matCellDef="let element"> {{ element.lenderName ?  element.lenderName : 'N/A'}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th class="centered" mat-header-cell *matHeaderCellDef> Status </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <pc-leading-badge [parent]="element"></pc-leading-badge>
            </td>
        </ng-container>
        <ng-container matColumnDef="loan_amount">
            <th class="centered" mat-header-cell *matHeaderCellDef> Loan Amount </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <span class="table-amount">
                    {{ element.amount ? (element.amount | currency) : 'N/A' }}
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="interest_rate">
            <th class="centered" mat-header-cell *matHeaderCellDef> Interest Rate </th>
            <td class="centered" mat-cell *matCellDef="let element"> {{element.interestRate ? element.interestRate + '%': 'N/A'}} </td>
        </ng-container>
        <ng-container matColumnDef="recurring_payment_amount">
            <th class="centered fit-content" mat-header-cell *matHeaderCellDef> Reoccurring Payment Amount </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <span class="table-amount">
                    {{ element.recurringPaymentAmount ? (element.recurringPaymentAmount | currency) : 'N/A' }}
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="payment_start_date">
            <th class="centered" mat-header-cell *matHeaderCellDef> Payment Start Date </th>
            <td class="centered" mat-cell *matCellDef="let element"> {{ element.paymentStartDate ? (element.paymentStartDate | date: 'mediumDate') : 'N/A'}} </td>
        </ng-container>
        <ng-container matColumnDef="payment_frequency">
            <th class="centered" mat-header-cell *matHeaderCellDef> Payment Frequency </th>
            <td class="centered" mat-cell *matCellDef="let element"> {{ element.paymentFrequency ? (element.paymentFrequency| titlecase) : 'N/A'}} </td>
        </ng-container>
        <ng-container matColumnDef="maturity">
            <th class="centered" mat-header-cell *matHeaderCellDef> Maturity </th>
            <td class="centered" mat-cell *matCellDef="let element"> {{element.maturity ? (element.maturity | date: 'mediumDate') : 'N/A'}} </td>
        </ng-container>
        <ng-container matColumnDef="view_access">
            <th class="centered" mat-header-cell *matHeaderCellDef> View Access </th>
            <td class="centered" mat-cell *matCellDef="let element"> {{ element.financialDataAccess ? (element.financialDataAccess | date: 'mediumDate') : 'N/A' }} </td>
        </ng-container>
        <ng-container matColumnDef="financial_data">
            <th class="centered" mat-header-cell *matHeaderCellDef> Financial Data </th>
            <td class="centered" mat-cell *matCellDef="let element">
                <i *ngIf="isEnableFinancing(element) && element.financialDataAccess" class="fa fa-bar-chart link" (click)="financialData(element)" matTooltip="View Financial Data"></i>
                <i *ngIf="!isEnableFinancing(element)" class="fa fa-bar-chart disabled" matTooltip="Access Required"></i>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator (page)="handlePageBottom($event)" [pageIndex]="paginator.pageIndex" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
</div>
