<div>
    <form [formGroup]="paymentForm">
        <div>
            <div class="modal-header">
                <div class="row no-gutters col-12">
                    <div class="col-11">
                        <label class="form-title">{{ header }} Member Account: {{memberAccount.accountName}}</label>
                        <div class="row">
                            <div class="row mt-1">
                                <div class="col-6 centered">Available Balance:<strong>&nbsp;{{memberAccount.wallet.availableBalance | currency}}</strong></div>
                                <div class="col-6 centered">Pending Funds:<strong>&nbsp;{{memberAccount.wallet.pendingBalance | currency}}</strong></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 pull-right"><i class="modal-close" (click)="close()">&times;</i></div>
                </div>
            </div>
            <div class="modal-content-medium">
                <div class="col-md-12">
                    <pc-text-input [required]="true" [label]="'Member Name'" [placeholder]="'Member Name'" [formCtrl]="'memberNameCtrl'" [formGroup]="paymentForm">
                    </pc-text-input>
                </div>
                <div class="col-md-12">
                    <pc-text-input [required]="true" [label]="'Account Name'" [placeholder]="'Account Name'" [formCtrl]="'memberAccountNameCtrl'" [formGroup]="paymentForm">
                    </pc-text-input>
                </div>
                <div class="col-md-12">
                    <pc-form-date-picker [label]="'Payment Date'" [formCtrl]="'transactionDateCtrl'" [formGroup]="paymentForm">
                    </pc-form-date-picker>
                </div>
                <div class="col-md-12" *ngIf="transactionType === TransactionType.DEPOSIT_ADJUSTMENT_ADDITION || transactionType === TransactionType.DEPOSIT_ADJUSTMENT_DEDUCTION">
                    <pc-text-option-selector  [label]="'Reason'" [translated]="true"
                        [options]="reasonOptions" [formCtrl]="'reasonCodeCtrl'" [formGroup]="paymentForm" [defaultValue]="''">
                    </pc-text-option-selector>
                </div>
                <div class="col-md-12" *ngIf="transactionType === TransactionType.DEPOSIT_ADJUSTMENT_ADDITION || transactionType === TransactionType.DEPOSIT_ADJUSTMENT_DEDUCTION">
                    <pc-merchant-counter-party-searcher [formGroup]="counterPartyForm" [label]="counterPartyLabel" [formCtrl]="'counterPartyCtrl'" [placeholder]="'Search ' + counterPartyLabel" [memberId]="memberAccount.memberId"
                        [tier]="memberAccount.member.tier" [jurisdiction]="memberAccount.member.jurisdiction" [includeAddress]="true" (counterPartySelected)="counterPartyMemberAccountSelected($event)">
                    </pc-merchant-counter-party-searcher>
                </div>
                <div class="col-md-12" *ngIf="(transactionType === TransactionType.DEPOSIT_ADJUSTMENT_ADDITION || transactionType === TransactionType.DEPOSIT_ADJUSTMENT_DEDUCTION) && counterParty">
                    <pc-text-input [required]="false" [label]="counterPartyLabel + ' MRB Tier'"
                        [formCtrl]="'counterPartyTierCtrl'" [formGroup]="counterPartyForm">
                    </pc-text-input>
                </div>
                <pc-form-text-area *ngIf="(transactionType === TransactionType.DEPOSIT_ADJUSTMENT_ADDITION || transactionType === TransactionType.DEPOSIT_ADJUSTMENT_DEDUCTION) && counterParty"
                    [label]="counterPartyLabel + ' Business Description'"
                    [formCtrl]="'counterPartyDescriptionCtrl'" [formGroup]="counterPartyForm">
                </pc-form-text-area>
                <div class="col-md-12">
                    <pc-form-amount-input [required]="true" [label]="'Amount'" [formCtrl]="'amountCtrl'"
                        [formGroup]="paymentForm" [dollar]="true" [placeholder]="'0.00'" (change)="onCalculateTotal()">
                    </pc-form-amount-input>
                </div>
                <div class="col-md-12" *ngIf="supportingDocuments.length">
                    <label class="form-label">Supporting Documents</label>
                    <div *ngFor="let supportingDocument of supportingDocuments; let documentNumber = index">
                        <i class="fa fa-times" (click)="removeSupportingDocument(documentNumber)"></i>{{supportingDocument.file?.name}}
                    </div>
                </div>
                <div class="error" *ngIf="!!error"> {{ error }} </div>
                <div class="col-md-12">
                    <pc-form-text-area [label]="'Reason for Payment'"  [formCtrl]="'notesCtrl'" [formGroup]="paymentForm">
                    </pc-form-text-area>
                </div>
                <div class="col-12 centered">
                    <strong class="form-title"> Payment Amount: {{ adjustmentAmount | currency}}</strong>
                </div>
            </div>
            <div class="modal-footer">
                <button class="blue-button" type="button" (click)="upload.click()">Attach File</button>
                <pc-submit-button class="centered" [enabled]="isPaymentFormValid" [submit]="onSubmit" [text]="'Submit'"></pc-submit-button>
            </div>
        </div>
    </form>
    <input hidden type="file" id="upload" #upload (change)="selectFile($event)" placeholder="Upload file" accept=".pdf,.doc,.docx">
</div>
