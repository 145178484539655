<div class="row dashboard-info justify-content-center col-12" *ngIf="taskVars">

    <div class="col-12 dashboard-header">
        <span>Merchant Contact Information</span>
    </div>
    <div class="col-12 justify-content-center">
        <dl class="row">
            <dt class="col-sm-4" *ngIf="requestedBy">Requested By:</dt>
            <dd class="col-sm-8" *ngIf="requestedBy">
                <a [routerLink]="['/administration/member/' + requestedBy.id]" [routerLinkActive]="['router-link-active']">{{requestedBy.name}}</a>
            </dd>

            <dt class="col-sm-4">Submitted Name:</dt>
            <dd class="col-sm-8">{{taskVars.merchantName}}</dd>

            <dt class="col-sm-4" *ngIf="getDbaName()">Merchant DBA Name:</dt>
            <dd class="col-sm-8" *ngIf="getDbaName()">{{getDbaName()}}</dd>

            <dt *ngIf="merchantAccountConfig?.merchantMemberAccount.member.jurisdiction" class="col-sm-4">Jurisdiction of Business:</dt>
            <dd *ngIf="merchantAccountConfig?.merchantMemberAccount.member.jurisdiction" class="col-sm-8"><b>{{ Utils.getJurisdictionName(merchantAccountConfig?.merchantMemberAccount.member.jurisdiction) }}</b></dd>

            <dt *ngIf="merchantAccountConfig?.merchantMemberAccount.member.tier === RiskTier.TIER_1" class="col-sm-4"></dt>
            <dd *ngIf="merchantAccountConfig?.merchantMemberAccount.member.tier === RiskTier.TIER_1" class="col-sm-8"><b>{{ RiskTier.TIER_1 | translate }}</b></dd>

            <dt class="col-sm-4">Merchant Type:</dt>
            <dd class="col-sm-8"><b>{{merchantAccountConfig?.shared ? 'Public' : 'Private'}}</b></dd>

            <dt class="col-sm-4" *ngIf="taskVars.contactInfoName">Contact Name:</dt>
            <dd class="col-sm-8" *ngIf="taskVars.contactInfoName">{{taskVars.contactInfoName}}</dd>

            <dt class="col-sm-4" *ngIf="taskVars.contactInfoEmail">Contact Email:</dt>
            <dd class="col-sm-8" *ngIf="taskVars.contactInfoEmail">{{taskVars.contactInfoEmail}}</dd>

            <dt class="col-sm-4" *ngIf="taskVars.contactInfoOfficePhone">Contact Phone:</dt>
            <dd class="col-sm-8" *ngIf="taskVars.contactInfoOfficePhone">{{taskVars.contactInfoOfficePhone | phone}}</dd>

            <dt class="col-sm-4" *ngIf="contactAddress">Address:</dt>
            <dd class="col-sm-8" *ngIf="contactAddress">
                {{contactAddress.streetAddressOne}}<br/>
                {{contactAddress.city}}<br/>
                {{contactAddress.stateProvince}}<br/>
                {{contactAddress.country}}<br/>
                {{contactAddress.zipPostalCode}}<br/>
            </dd>

            <dt class="col-sm-4" *ngIf="sampleDocuments.length">Sample ACH Info:</dt>
            <dd class="col-sm-8" *ngIf="sampleDocuments.length">
                <a class="dashboard-list-a" (click)="downloadFile(sampleDocuments[0])">
                    <i class="fa fa-paperclip"></i>{{sampleDocuments[0].fileName}}
                </a>
            </dd>
        </dl>
    </div>

    <pt-merchant-administration *ngIf="merchantAccountConfig?.status === MerchantAccountStatus.PENDING" [merchantAccountConfig]="merchantAccountConfig">
    </pt-merchant-administration>
</div>

<a class="document-download-link" #downloadDocumentLink></a>

<div class="button-panel">
    <pc-submit-button class="pull-right" [submit]="onNavigate" [text]="'Next'" [rightIcon]="'arrow-right'"></pc-submit-button>
</div>
