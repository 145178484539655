import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { AuthService, Member, MemberAccount, MemberService, PagedResponse } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-sc-business-parties-list',
    templateUrl: './business-parties-list.component.html'
})
export class BusinessPartiesListComponent implements OnInit {

    @Input() invitees: string[] = [];

    @Output() membersInvited: EventEmitter<string[]> = new EventEmitter<string[]>();

    displayedColumns: string[] = ['selected', 'name'];
    memberAccounts: MemberAccount[];

    constructor(private authService: AuthService,
                private memberService: MemberService) {
    }

    ngOnInit() {
        this.memberService.getActiveMembers().subscribe((result: PagedResponse<Member>) => {
            const members = [];
            for (const member of result.content) {
                if (member.id !== this.authService.getProfile().memberId) {
                    members.push(member);
                    if (this.invitees.indexOf(member.id) > -1) {
                        member.selected = true;
                    }
                }
            }
            members.sort((a, b) => {
                return a.name.localeCompare(b.name);
            }
            );
            this.memberAccounts = members;
        });
    }

    selectMemberAccount(memberAccount: MemberAccount) {
        memberAccount.selected = !memberAccount.selected;
        const invitees = [];
        for (const memberAccount of this.memberAccounts) {
            if (memberAccount.selected) {
                invitees.push(memberAccount.id);
            }
        }
        this.membersInvited.emit(invitees);
    }
}
