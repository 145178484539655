import { Component } from '@angular/core';
import { BankAccountStatus, BankAccount } from 'projects/services/src/public-api';
import { BaseBadgeComponent } from '../badge.component';

@Component({
    selector: 'pc-bank-account-badge',
    templateUrl: '../badge.component.html',
    styleUrls: ['../badge.component.scss']
})
export class BankAccountBadgeComponent extends BaseBadgeComponent<BankAccount> {

    setBadgeData() {
        this.badgeToolTip = this.parent.status;
        switch (this.parent.status) {
            case BankAccountStatus.ACTIVE:
                this.badgeText = 'ACTIVE';
                this.badgeClass = 'green-badge';
                break;
            case BankAccountStatus.BANK_VALIDATION_REQUIRED:
            case BankAccountStatus.USER_VALIDATION_REQUIRED:
            case BankAccountStatus.VALIDATION_REQUIRED:
            case BankAccountStatus.PENDING:
                this.badgeText = 'PENDING';
                this.badgeClass = 'grey-badge';
                break;
            case BankAccountStatus.UNSUPPORTED:
                this.badgeText = 'UNSUPPORTED';
                this.badgeClass = 'red-badge';
                break;
            case BankAccountStatus.DELETED:
                this.badgeText = 'DELETED';
                this.badgeClass = 'red-badge';
                break;
            case BankAccountStatus.DISABLED:
                this.badgeText = 'DISABLED';
                this.badgeClass = 'red-badge';
                break;
            case BankAccountStatus.DUPLICATE_BANK_ACCOUNT:
                this.badgeText = 'DUPLICATE';
                this.badgeClass = 'dark-grey-badge';
                break;
            case BankAccountStatus.SYSTEM_ERROR:
                this.badgeText = 'SYSTEM ERROR';
                this.badgeClass = 'red-badge';
                break;
            default:
                this.badgeText = 'REJECTED';
                this.badgeClass = 'red-badge';
                break;
        }
    }
}
