export class PagedResponse<T> {

    content: T[];
    totalElements: number;
    totalPages: number;
    number: number;
    empty: boolean;
    first: boolean;
    last: boolean;

    constructor() {
        this.content = [];
        this.totalElements = 0;
        this.totalPages = 0;
        this.number = 0;
        this.empty = true;
        this.first = false;
        this.last = false;
    }
}
