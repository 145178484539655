import { Component, Input } from '@angular/core';
import { LabelledPackage } from 'projects/services/src/public-api';

@Component({
    selector: 'pc-manifest-line',
    templateUrl: './manifest-row.component.html',
    styleUrls: ['./manifest-row.component.scss']
})
export class ManifestRowComponent {

    @Input() packages: LabelledPackage[];

}
