<div class="row dashboard-info" *ngIf="memberAccount && filter">
    <div class="row">
        <div class="col-12">
            <pt-transaction-table-header [filter]="filter" [isActivityListAvailable]="true" [memberId]="memberAccount.memberId" [memberAccount]="memberAccount" [taskId]="taskId"
                [customerInfo]="customerInfo"
                (filterChanged)="filterChanged($event)" (transactionSubmitted)="transactionSubmitted()" [readOnly]="readOnly">
            </pt-transaction-table-header>
        </div>
    </div>

    <div class="row mt-3" *ngIf="filter.transactionTab === 'TRANSACTION_LIST' && authService.isAuthorityOrReviewer()">
        <span class="col-12 span-header"> <strong>Transactions by Date</strong></span>
        <div class="col-12">
            <pc-transaction-charts-gadgets [filter]="filter" [changeTrigger]="changeTrigger">
            </pc-transaction-charts-gadgets>
        </div>
    </div>

    <div class="row">
        <div class="col-12" *ngIf="filter.transactionTab === 'ACCOUNT_ACTIVITY'">
            <pt-account-activity-details [memberId]="memberAccount.memberId" [memberAccountId]="memberAccount.id" [changeTrigger]="changeTrigger"></pt-account-activity-details>
        </div>
        <div class="col-12" *ngIf="filter.transactionTab === 'TRANSACTION_LIST'">
            <pc-transaction-list [filter]="filter" [memberId]="memberAccount.memberId" [changeTrigger]="changeTrigger">
            </pc-transaction-list>
        </div>
        <div class="col-12" *ngIf="filter.transactionTab === 'BILLING_INFO' && authService.isAuthorityOrReviewer()">
            <pt-member-account-billing-info [memberAccountId]="memberAccount.id" [changeTrigger]="changeTrigger" [readOnly]="readOnly" [customerInfo]="customerInfo"
                (bankingInfoUpdated)="bankingInfoUpdated($event)">
            </pt-member-account-billing-info>
        </div>
        <div class="col-12" *ngIf="filter.transactionTab === 'ACCOUNT_LIMITS' && authService.isAuthorityOrReviewer()">
            <pt-member-account-limits [memberAccountId]="memberAccount.id" [changeTrigger]="changeTrigger" [readOnly]="readOnly" [status]="memberAccount.status">
            </pt-member-account-limits>
        </div>
        <div class="col-12" *ngIf="filter.transactionTab === 'ACCOUNT_SETTINGS' && authService.isAuthorityOrAdmin()">
            <pt-member-account-settings [memberAccount]="memberAccount" (memberAccountChanged)="onMemberAccountUpdate($event)" [readOnly]="readOnly">
            </pt-member-account-settings>
        </div>
        <div class="col-12" *ngIf="filter.transactionTab === 'ACCOUNT_OVERVIEW' && authService.isAdmin()">
            <pt-member-account-overview [memberAccount]="memberAccount">
            </pt-member-account-overview>
        </div>
        <div class="col-12" *ngIf="filter.transactionTab === 'BANKING_DETAILS' && authService.isAuthorityOrReviewer()">
            <pt-merchant-member-account-banking-details [memberAccount]="memberAccount">
            </pt-merchant-member-account-banking-details>
        </div>
    </div>
</div>
