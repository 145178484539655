import { Component, Input, ViewChild, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'pc-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent {

    @Input() required: boolean;
    @Input() label: string;
    @Input() placeholder = '';
    @Input() formCtrl: string;
    @Input() formGroup: UntypedFormGroup;
    @Input() inlineEdit = false;
    @Input() fullClickable = false;
    @Input() editFn: any;

    @Output() changed: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('inputField') inputField: ElementRef;

    getClass(){
        let clazz = this.fullClickable ? 'click-through ' : '';
        if (this.inlineEdit) {
            return clazz += 'inline-edit';
        }
        return clazz += 'no-inline-edit';
    }

    focus() {
        this.inputField.nativeElement.focus();
    }

    onChange() {
        this.changed.emit(this.formGroup.controls[this.formCtrl].value);
    }

    edit() {
        this.editFn();
    }

}
