<div>
    <div>
        <pc-member-account-selector [class]="'full'" [formGroup]="formGroup" [formCtrl]="'currentBusinessClientCtrl'" [placeholder]="'Search Confia Members'"
            [excludeSelf]="true" [includeAddress]="true" (memberAccountSelected)="onSelectConfiaMemberAccount($event)"></pc-member-account-selector>
        <div *ngIf="!isAccountingLinked" class="flex-column">
            <pc-error-message [error]="ACCOUNTING_NOT_LINKED_ERROR" [width]="'full'"></pc-error-message>
        </div>
        <div class="d-flex justify-content-end">
            <button class="blue-button" [disabled]="!currentBusinessClient" (click)="next()" type="button">Next</button>
        </div>
    </div>
</div>
