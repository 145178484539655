<div>
    <form [formGroup]="documentForm">
        <div>
            <div class="modal-header">
                <label class="form-title">{{isCurrencyWithdrawal ? 'Confirm Withdrawal' : 'Confirm Deposit'}}</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <div>
                    <pc-form-amount-input [required]="true" [label]="'Requested Amount'" [formCtrl]="'requestedAmountCtrl'" [formGroup]="documentForm">
                    </pc-form-amount-input>
                    <pc-form-amount-input [required]="true" [label]="'Actual Amount'" [formCtrl]="'actualAmountCtrl'" [formGroup]="documentForm" [placeholder]="'0.00'" (amountChanged)="onAmountChanged($event)">
                    </pc-form-amount-input>
                    <div class="error" *ngIf="error">
                        {{error}}
                    </div>
                    <pc-text-area [required]="false" [label]="'Confirmation Notes'" [formCtrl]="'notesCtrl'" [formGroup]="documentForm"
                                [placeholder]="'Please provide any notes pertaining to the confirmation of this transaction.  Required when there is a discrepancy between the requested and actual transaction amounts.'">
                    </pc-text-area>
                </div>
            </div>

            <div *ngIf="files && files.length > 0">
                <hr class="separator">
                <p *ngFor="let file of files; let i = index">
                    <span class="dashboard-list-a">
                        <i class="fa fa-paperclip"></i>{{file.name}}
                    </span>
                    <i class="fa fa-times" (click)="deleteFile(i)"></i>
                </p>
                <hr class="separator">
            </div>

            <div class="modal-footer">
                <button class="blue-button" type="button" (click)="upload.click()" [disabled]="hasFile()">Attach File(s)</button>
                <input hidden type="file" id="upload" #upload multiple="true" (change)="selectFile($event)" placeholder="Upload file"
                    accept=".pdf,.doc,.docx">
                <pc-submit-button [enabled]="isDocumentFormValid" [submit]="onSubmit" [text]="isCurrencyWithdrawal ? 'Confirm Withdrawal' : 'Confirm Deposit'"></pc-submit-button>
            </div>
        </div>
    </form>
</div>
