<div class="col-12">
    <div class="col-sm-12 horizontal-scroll mat-table-container">
        <div class="mat-table-spinner" *ngIf="isLoadingResults">
            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        </div>
        <table mat-table [dataSource]="auditRecords" multiTemplateDataRows matSort [matSortActive]="pageTracking.sort" matSortDisableClear [matSortDirection]="pageTracking.sortDir">
            <ng-container matColumnDef="performed">
                <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header="created"> Performed </th>
                <td mat-cell class="centered" *matCellDef="let element"><pc-date-time [date]="element.created"></pc-date-time></td>
            </ng-container>
            <ng-container matColumnDef="memberAccount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="performedByMember"> Member </th>
                <td mat-cell *matCellDef="let element">
                    <a *ngIf="element.memberAccount && authService.getProfile().memberId !== element.performedByMemberId"
                        [routerLink]="['/administration/member/' + element.performedByMemberId]" [routerLinkActive]="['router-link-active']">
                        <span *ngIf="element.performedByMember"> {{element.performedByMember}} </span>
                        <span *ngIf="!element.performedByMember"> {{element.memberAccount.name}} </span>
                    </a>
                    <div *ngIf="element.memberAccount && authService.getProfile().memberId === element.performedByMemberId">
                        {{element.memberAccount.name}}
                    </div>
                    <div *ngIf="!element.memberAccount">{{element.performedByMember}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="performedByUser"> User </th>
                <td mat-cell  *matCellDef="let element">
                    <div *ngIf="element.performedByUser">{{element.performedByUser}}</div>
                    <div *ngIf="!element.performedByUser">{{element.user?.name}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="partner">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="performedByPartner"> Partner </th>
                <td mat-cell *matCellDef="let element">
                    <a *ngIf="element.partner" [routerLink]="['/administration/member/' + element.performedByPartnerId]" [routerLinkActive]="['router-link-active']">
                        <span *ngIf="element.performedByPartner">{{element.performedByPartner}}</span>
                        <span *ngIf="!element.performedByPartner">{{element.partner.name}}</span>
                    </a>
                    <div *ngIf="!element.partner">{{element.performedByPartner}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="ipAddress">
                <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header> IP Address </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <div>{{element.ipAddress}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell class="centered"  *matHeaderCellDef  mat-sort-header> Action </th>
                <td mat-cell class="centered" *matCellDef="let element">
                    <div>{{element.action | translate}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="auditEntityType">
                <th mat-header-cell class="centered" *matHeaderCellDef mat-sort-header="referencedEntityType"> Entity Type </th>
                <td mat-cell  class="centered" *matCellDef="let element">
                    <div> {{element.referencedEntityType | translate}} </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="referencedEntity">
                <th mat-header-cell  *matHeaderCellDef> Referenced Entity </th>
                <td mat-cell  *matCellDef="let element">
                    <a *ngIf="element.referencedEntityLink"
                       [routerLink]="[element.referencedEntityLink]"
                       [routerLinkActive]="['router-link-active']"
                       [queryParams]="element.referencedEntityLinkParams">
                            <span>{{element.referencedEntity}}</span>
                    </a>
                    <div *ngIf="!element.referencedEntityLink">{{element.referencedEntity}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                        <dl class="row" *ngIf="element.description">
                            <dt class="col-sm-2 expandedLabel"> <b>Description: </b></dt>
                            <dd class="col-sm-10 expandedValue"> {{ element.description }}</dd>
                        </dl>
                        <dl class="row" *ngIf="element.jsonData">
                            <dt class="col-sm-2 expandedLabel"> <b>JSON:</b></dt>
                            <dd class="col-sm-10 expandedValue"> <pre class="break-json-whitespace"> {{ element.jsonData | json }} </pre></dd>
                        </dl>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="example-expand-row" [class.example-expanded-row]="expandedElement === element" (click)="loadData(element, $event)">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
        <mat-paginator class="mat-paginator-sticky" [pageSize]="pageTracking.pageSize" [pageSizeOptions]="pageTracking.pageSizeOptions" [length]="resultsLength" showFirstLastButtons aria-label="Select page"> </mat-paginator>
    </div>
</div>
