<div>
    <form [formGroup]="registerMemberForm">
        <div>
            <div class="modal-header">
                <label class="form-title">New Registration</label>
                <i class="modal-close" (click)="close()">&times;</i>
            </div>
            <div class="modal-content-small">
                <pc-auto-complete-selector *ngIf="jurisdictions.length" [required]="true" [label]="'State'"  [formCtrl]="'jurisdictionCtrl'"
                    [formGroup]="registerMemberForm" [options]="jurisdictions" [displayValue]="'name'" (selected)="jurisdictionSelected($event)">
                </pc-auto-complete-selector>
                <pc-text-option-selector *ngIf="memberTypes.length > 0" [required]="true" [label]="'Account Type'" [formCtrl]="'memberRegistrationTypeCtrl'"
                    [formGroup]="registerMemberForm" [options]="memberTypes" [translated]="true">
                </pc-text-option-selector>
                <pc-text-input [required]="true" [label]="'Member Name'" [placeholder]="'Member Name'"
                    [formCtrl]="'memberNameCtrl'" [formGroup]="registerMemberForm">
                </pc-text-input>
                <pc-email-input [required]="true" [label]="'Email'" [placeholder]="'Email'"
                    [formCtrl]="'emailCtrl'" [formGroup]="registerMemberForm" (changed)="onEmailChange($event)">
                </pc-email-input>
                <pc-text-input *ngIf="userType === 'NEW'" [required]="true" [label]="'First Name'" [placeholder]="'First Name'"
                    [formCtrl]="'firstNameCtrl'" [formGroup]="registerMemberForm">
                </pc-text-input>
                <pc-text-input *ngIf="userType === 'NEW'" [required]="false" [label]="'Middle Name'" [placeholder]="'Middle Name'"
                    [formCtrl]="'middleNameCtrl'" [formGroup]="registerMemberForm">
                </pc-text-input>
                <pc-text-input *ngIf="userType === 'NEW'" [required]="true" [label]="'Last Name'" [placeholder]="'Last Name'"
                    [formCtrl]="'lastNameCtrl'" [formGroup]="registerMemberForm">
                </pc-text-input>
                <pc-text-input *ngIf="userType === 'NEW' || userType === 'EXISTING'" [required]="true" [label]="'Title'" [placeholder]="'Title'"
                    [formCtrl]="'titleCtrl'" [formGroup]="registerMemberForm">
                </pc-text-input>
                <pc-phone-input *ngIf="userType === 'NEW'" [required]="true" [label]="'Office Number'"
                    [formCtrl]="'officePhoneCtrl'" [formGroup]="registerMemberForm">
                </pc-phone-input>
                <pc-phone-input *ngIf="userType === 'NEW'" [label]="'Mobile Number'"
                    [formCtrl]="'mobilePhoneCtrl'" [formGroup]="registerMemberForm">
                </pc-phone-input>
                <pc-form-selector *ngIf="isMfaEnabled() && userType === 'NEW'" [required]="true" [label]="'Two Factor Authentication'" [formCtrl]="'mfaCtrl'"
                    [formGroup]="registerMemberForm" [options]="mfaTypes" [idValue]="'id'" [displayValue]="'name'" [defaultValue]="''">
                </pc-form-selector>
                <pc-text-input [label]="'Referral Code (optional)'" [required]="false" [formCtrl]="'referralCtrl'"
                    [formGroup]="registerMemberForm">
                </pc-text-input>
                <pc-password-input *ngIf="userType === 'EXISTING'" [required]="true" [label]="'Password'" [formCtrl]="'passwordCtrl'" [formGroup]="registerMemberForm">
                </pc-password-input>
                <div *ngIf="errorMessage" class="error-message">
                    <label class="form-error">{{errorMessage}}</label>
                </div>
            </div>
            <div class="modal-footer" *ngIf="!locked">
                <pc-submit-button *ngIf="userType" [enabled]="formValid" [submit]="onSubmit" [text]="'Begin Application'"></pc-submit-button>
                <button *ngIf="!userType" type="button" class="blue-button" [disabled]="nextDisabled()">Next</button>
            </div>
        </div>
    </form>
</div>
