<div class="inlineExpanded">
    <div *ngIf="message.senderMemberId !== currentMemberId" class="reply">
        <a *ngIf="authService.hasAnyRole([Role.AUTHORITY, Role.MEMBER])" class="dashboard-list-a" (click)="replyToMessage()"><i class="fa fa-reply"></i> Reply</a>
    </div>
    <div class="row">
        <div class="col-5 inner-column">
            <div class="row">
                <div class="col-3 expandedLabel">
                    Date
                </div>
                <div class="col-9 expandedValue">
                    {{message.created | date:'medium'}}
                </div>
            </div>
            <div class="row">
                <div class="col-3 expandedLabel">
                    Sender
                </div>
                <div class="col-4 expandedValue">
                    {{message.senderMemberName}}
                </div>
                <div *ngIf="message.senderName" class="col-4 expandedValue">
                    ({{message.senderName}})
                </div>
            </div>
            <div class="row">
                <div class="col-3 expandedLabel">
                    Recipient
                </div>
                <div class="col-4 expandedValue">
                    {{message.recipientMemberName}}
                </div>
                <div *ngIf="message.recipientName" class="col-4 expandedValue">
                    ({{message.recipientName}})
                </div>
            </div>
            <div class="row" *ngIf="message.document">
                <div class="col-3 expandedLabel">
                    Attachment
                </div>
                <div class="col-9 expandedValue">
                    <a *ngIf="message.document" class="dashboard-list-a"
                        (click)="downloadFile(message.document)">
                        <i class="fa fa-paperclip"></i>{{message.document.fileName}}
                    </a>
                </div>
            </div>
        </div>
        <div class="col-7">
            <div class="row">
                <div class="col-12 expandedSubject">
                    {{message.subject}}
                </div>
                <div class="col-12 expandedBodyText">
                    {{message.body}}
                </div>
            </div>
        </div>
    </div>
</div>
<a class="message-download-link" #downloadMessageLink></a>
