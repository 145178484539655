import { Component, Input } from '@angular/core';

@Component({
    selector: 'pc-date-time',
    templateUrl: './date-time.component.html',
    styleUrls: ['./date-time.component.scss']
})
export class DateTimeComponent {

    @Input() date: Date;

}
