import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import {
    LoginModalComponent, RegisterMemberModalComponent, RegisterConsumerModalComponent, UpdatedTermsModalComponent
} from 'projects/components/src/lib/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, Configuration, Profile } from 'projects/services/src/public-api';
import { Subscription } from 'rxjs';

@Component({
    selector: 'pt-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    constructor(private route: ActivatedRoute,
                private authService: AuthService,
                private router: Router,
                private dialog: MatDialog) { }

    ngOnInit() {
        this.subscription = this.route.queryParams.subscribe((params) => {
            // FIXME: CN-3880 - eventually we can remove the org param, and old emails will just need to select the member
            if (params['org'] || params['memberId'] || params['redirectUrl']) {
                const profile = this.authService.getProfile();
                if (profile && (profile.memberId === params['org'] || profile.memberId === params['memberId'])) {
                    this.router.navigateByUrl(params['redirectUrl']);
                } else if (!profile || ((params['org'] || params['memberId']) && !(profile.memberId === params['org'] || profile.memberId === params['memberId']))) {
                    this.authService.clear();
                    this.login();
                } else {
                    this.router.navigateByUrl(params['redirectUrl']);
                }
            } else if (params['open']) {
                this.login();
            } else {
                this.authService.clear();
            }
        });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    login() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.disableClose = true;
        const loginDialog = this.dialog.open(LoginModalComponent, dialogConfig);
        loginDialog?.afterClosed().subscribe((updatedProfile: Profile) => {
            if (updatedProfile) {
                this.handleTermsUpdate(updatedProfile);
            }
        });
    }

    registerMemberAccount() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.disableClose = true;
        dialogConfig.data = {};
        this.dialog.open(RegisterMemberModalComponent, dialogConfig);
    }

    registerConsumer() {
        const dialogConfig: MatDialogConfig = {};
        dialogConfig.panelClass = 'normal-modal';
        dialogConfig.disableClose = true;
        this.dialog.open(RegisterConsumerModalComponent, dialogConfig);
    }

    handleTermsUpdate(updatedProfile: Profile) {
        const updatedTermsDialogConfig: MatDialogConfig = {};
        updatedTermsDialogConfig.panelClass = 'full-modal';
        updatedTermsDialogConfig.data = {
            memberId: updatedProfile.memberId
        };
        const confirmDialog = this.dialog.open(UpdatedTermsModalComponent, updatedTermsDialogConfig);
        confirmDialog?.afterClosed().subscribe((accepted: boolean) => {
            if (accepted) {
                this.authService.initializeApplication(updatedProfile, null);
            }
        });
    }

    consumerEnabled() {
        return Configuration.getConfig() && Configuration.getConfig().consumerEnabled;
    }

}
