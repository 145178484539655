<div>
    <form>
        <div class="modal-header">
            <label class="form-title">{{pageTitle}}</label>
            <i class="modal-close" (click)="close()">&times;</i>
        </div>
        <div [ngClass]="pageNumber === 2 ? 'modal-content' : 'modal-content-small'">
            <div *ngIf="pageNumber === 1">
                <div class="row">
                    <div class="col-12">
                        <pc-form-amount-input [placeholder]="'Amount'" [required]="false" [label]="'Payment Amount'"
                            [formCtrl]="'amountCtrl'"
                            [formGroup]="newPaymentForm">
                        </pc-form-amount-input>
                    </div>
                    <div class="col-12">
                        <pc-phone-input [required]="true" [label]="'Phone Number'"
                            [formCtrl]="'phoneCtrl'" [formGroup]="newPaymentForm">
                        </pc-phone-input>
                    </div>
                </div>
            </div>
            <div *ngIf="pageNumber === 2">
                <div class="row consumer-container">
                    <div class="col-12 centered">
                        <p class="retail-header">Payment Amount</p>
                    </div>
                    <div class="col-12 centered">
                        <p class="retail-amount">{{amount | currency}}</p>
                    </div>
                    <div class="col-12 centered" *ngIf="searching || consumerAccounts.length">
                        <p>Results for: <b>{{ phone | phone }}</b></p>
                    </div>
                    <div class="col-12 centered" *ngIf="!searching && consumerAccounts.length === 0">
                        <p>No existing customers found for: <b>{{ phone | phone }}</b></p>
                    </div>
                    <div class="mat-table-container" *ngIf="searching || consumerAccounts.length">
                        <div class="mat-table-spinner" *ngIf="searching">
                            <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
                        </div>
                        <table mat-table [dataSource]="consumerAccounts" matSort matSortActive="sortName" matSortDisableClear matSortDirection="asc">
                            <ng-container matColumnDef="name">
                                <th mat-header-cell mat-sort-header="sortName" *matHeaderCellDef> Name </th>
                                <td mat-cell *matCellDef="let element" (click)="selectMemberAccount(element)">
                                    {{element.member.name}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="email">
                                <th mat-header-cell mat-sort-header="email" *matHeaderCellDef> Email </th>
                                <td mat-cell *matCellDef="let element" (click)="selectMemberAccount(element)">
                                    {{element.member.email}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="address">
                                <th mat-header-cell *matHeaderCellDef> Address </th>
                                <td mat-cell *matCellDef="let element" (click)="selectMemberAccount(element)">
                                    <pc-business-address *ngIf="element.address" [address]="element.address"></pc-business-address>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.selected ? 'selected' : ''"></tr>
                        </table>
                        <mat-paginator [length]="resultsLength" [pageSize]="paginationSize"></mat-paginator>
                    </div>
                </div>
            </div>
            <div *ngIf="pageNumber === 3">
                <div class="row">
                    <div class="col-12 centered">
                        <p class="retail-header">Payment Amount</p>
                    </div>
                    <div class="col-12 centered">
                        <p class="retail-amount">{{ amount | currency }}</p>
                    </div>
                    <div class="col-12">
                        <div class="col-12 mt-3 onboarding-message">
                            <div class="centered mb-3">
                                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                            </div>
                            <p><i class="fa fa-exclamation-circle"></i>Please wait for this customer to enter their details.</p>
                            <p>Please do not close this dialog.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="pageNumber === 4">
                <div class="row">
                    <div class="col-12 centered">
                        <p class="retail-header">Payment Amount</p>
                    </div>
                    <div class="col-12 centered">
                        <p class="retail-amount">{{amount | currency}}</p>
                    </div>
                    <div class="col-12">
                        <div class="col-12 mt-3" *ngIf="consumer">
                            <div class="row">
                                <div class="col-4 expandedLabel">
                                    Customer:
                                </div>
                                <div class="col-8 expandedValue">
                                    {{consumer.name}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 expandedLabel">
                                    Mobile Phone:
                                </div>
                                <div class="col-8 expandedValue">
                                    {{consumer.phone | phone}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 expandedLabel">
                                    Email:
                                </div>
                                <div class="col-8 expandedValue">
                                    {{consumer.email}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4 expandedLabel">
                                    Address:
                                </div>
                                <div class="col-8 expandedValue">
                                    <pc-business-address *ngIf="consumer.address" [address]="consumer.address"></pc-business-address>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <pc-numeric-input #verificationCodeCtrl [label]="'Transaction Verification Code'" [placeholder]="'123456'" [formCtrl]="'codeCtrl'" [formGroup]="verificationForm">
                            </pc-numeric-input>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="pageNumber === 5">
                <div class="row">
                    <div class="col-12 centered">
                        <p class="retail-header">Payment Amount</p>
                    </div>
                    <div class="col-12 centered">
                        <p class="retail-amount">{{amount | currency}}</p>
                    </div>
                    <div class="col-12 centered payment-success" *ngIf="status === 'COMPLETED' || status === 'PENDING_ACH_CALLBACK'">
                        <i class="fa fa-check-circle-o"></i>
                        <p>Payment Successful</p>
                    </div>
                    <div class="col-12 centered payment-failure" *ngIf="status === 'CANCELLED' || status === 'CANCELLED_BY_SYSTEM'">
                        <i class="fa fa-times-circle-o"></i>
                        <p>Payment Failed</p>
                    </div>
                    <div class="col-12 centered payment-failure" *ngIf="status === 'CANCELLED_BY_USER'">
                        <i class="fa fa-times-circle-o"></i>
                        <p>Payment Cancelled by User</p>
                    </div>
                    <div class="col-12 centered payment-failure" *ngIf="status === 'TIMEOUT'">
                        <i class="fa fa-times-circle-o"></i>
                        <p>Verification code is no longer valid.  Please resubmit the transaction.</p>
                    </div>
                    <div class="col-12 centered payment-failure" *ngIf="status === 'CONSUMER_BLOCKLISTED'">
                        <i class="fa fa-times-circle-o"></i>
                        <p>Failure to onboard customer due to payment history.</p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!status" class="modal-footer">
            <pc-submit-button pc-no-dbl-click *ngIf="pageNumber === 1 && !processInstanceId" [enabled]="isPaymentFormValid" [submit]="searchConsumers" [text]="'Next'"></pc-submit-button>
            <button class="blue-button" *ngIf="pageNumber === 2 && !processInstanceId" (click)="pageNumber = 1"><i class="fa fa-angle-double-left"></i>Back</button>
            <pc-submit-button pc-no-dbl-click *ngIf="pageNumber === 2 && !processInstanceId" [enabled]="isConsumerNotSelected" [submit]="initiateRetailFlow" [text]="'Create New Customer'"></pc-submit-button>
            <pc-submit-button pc-no-dbl-click *ngIf="pageNumber === 2 && !processInstanceId" [enabled]="isConsumerSelected" [submit]="initiateRetailFlow" [text]="'Submit Transaction'"></pc-submit-button>
            <pc-submit-button pc-no-dbl-click *ngIf="processInstanceId && pageNumber === 4" [enabled]="isVerificationFormValid" [submit]="verifyAndInitiateTransaction" [text]="'Verify Code'"></pc-submit-button>
            <pc-submit-button pc-no-dbl-click *ngIf="processInstanceId" [enabled]="isNotSubmitting" [submit]="cancelTransaction" [text]="'Cancel'"></pc-submit-button>
        </div>
        <div *ngIf="status" class="modal-footer">
            <button class="blue-button" (click)="close()">Close</button>
        </div>
    </form>
</div>
