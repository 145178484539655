<div>
    <div>
        <div class="modal-header">
            <label class="form-title">Terms of Services Changes</label>
            <i class="modal-close" (click)="close(false)">&times;</i>
        </div>
        <div class="modal-content">
            <pc-member-application-submit *ngIf="memberId" [memberId]="memberId" [businessCategory]="businessCategory" (updatedTermsAccepted)="updatedTermsAccepted($event)">
            </pc-member-application-submit>
        </div>
    </div>
</div>
