import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

@Component({
    selector: 'pc-simple-checkbox',
    templateUrl: './simple-checkbox.component.html',
    styleUrls: ['./simple-checkbox.component.scss']
})
export class SimpleCheckboxComponent {

    @Input() label: string;
    @Input() formCtrl: string;
    @Input() formCtrlIndex: number = null;
    @Input() formGroup: UntypedFormGroup;
    @Input() invert = false;
    @Input() class = '';
    @Input() id: string;

    @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();

    isChecked() {
        return this.formGroup && this.getControl().value;
    }

    onChanged() {
        if (!this.isDisabled()) {
            const formControl = this.getControl();
            formControl.setValue(!formControl.value);
            formControl.markAsDirty();
            this.changed.emit(this.getControl().value);
        }
    }

    getControl() {
        let ctrl = this.formGroup.controls[this.formCtrl];
        if (this.formCtrlIndex !== null) {
            ctrl = (ctrl as UntypedFormArray).controls[this.formCtrlIndex];
        }
        return ctrl;
    }

    getClass() {
        return `form-group ${this.class} ${this.isDisabled() ? 'disabled' : ''}`;
    }

    isDisabled() {
        return this.formGroup.controls[this.formCtrl].disabled;
    }

}
