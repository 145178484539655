import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'pc-sortable-column',
    templateUrl: './sortable-column.component.html'
})
export class SortableColumnComponent {

    @Input() sortColumnName: string;
    @Input() sortColumnDirection = 'DESC';
    @Input() sorted = false;

    @Output() sortColumnChanged: EventEmitter<{ sortColumnName: string, sortColumnDirection: string, sorted: boolean }> =
        new EventEmitter<{ sortColumnName: string, sortColumnDirection: string, sorted: boolean }>();

    onSortChange() {
        this.sortColumnChanged.emit({
            sortColumnName: this.sortColumnName,
            sortColumnDirection: this.sortColumnDirection,
            sorted: this.sorted
        });
    }

    toggleSortDirection() {
        if (this.sortColumnDirection === 'ASC') {
            this.sortColumnDirection = 'DESC';
        } else {
            this.sortColumnDirection = 'ASC';
        }
        this.onSortChange();
    }

    sortColumn() {
        this.sorted = true;
        this.onSortChange();
    }
}
